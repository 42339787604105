export type Orientation = 'portrait' | 'landscape';

export type ImageDimension = { width: number; height: number };

export function getOrientationFromImageDimension({
  width,
  height,
}: ImageDimension): Orientation | null {
  if (width === height) {
    return null;
  }
  return width > height ? 'landscape' : 'portrait';
}

export function getRotatedImageDimensions(
  dimension: ImageDimension,
  targetOrientation: Orientation | null
) {
  const currentOrientation = getOrientationFromImageDimension(dimension);

  if (!currentOrientation || !targetOrientation) {
    return dimension;
  }

  if (currentOrientation !== targetOrientation) {
    const w = dimension.width;
    const h = dimension.height;
    return { width: h, height: w };
  }

  return dimension;
}

export function getFlippedOrientation(
  orientation: Orientation | null
): Orientation | null {
  switch (orientation) {
    case 'portrait':
      return 'landscape';
    case 'landscape':
      return 'portrait';
    default:
      return null;
  }
}
