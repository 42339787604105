import { Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type EmailVerifiedSectionProps = {
  email?: string;
};

const EmailVerifiedSection: React.FC<EmailVerifiedSectionProps> = (props) => {
  const { t } = useTranslation(['home']);

  return (
    <VStack spacing="2" align="start" pt="6">
      <Heading
        as="h4"
        size="lg"
        mb="3"
        children={t('home:heading.EMailAdresseVerifizieren')}
      />
      <Text
        textStyle={'bodyMd'}
        textAlign="left"
        children={t(
          'home:text.UmUnsereEMailsErhaltenZuKonnenKlickeBitteAufDenAktivierungslinkDenDuXX',
          {
            emailAddress: props.email ?? '-',
          }
        )}
      />
    </VStack>
  );
};

export default EmailVerifiedSection;
