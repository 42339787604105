import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

const StatisticListItemIcon: React.FC<
  { icon: ReactElement; onClick: any } & IconButtonProps
> = ({ icon, onClick, ...props }) => {
  return (
    <IconButton
      icon={icon}
      borderRadius={'full'}
      mr={'2'}
      size={'xs'}
      color={'onPrimary.highEmphasis'}
      shadow={'none'}
      {...props}
      onClick={onClick}
    />
  );
};

export default StatisticListItemIcon;
