export type ConsentType = {
  level?: [CookieCategory];
};
export type CookieCategory = 'necessary' | 'functional' | 'analytics';
export const getCookie = (cookieName: string) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (const cookie of cookieArray) {
    let cookieCopy = cookie.slice();

    while (cookieCopy.charAt(0) === ' ') {
      cookieCopy = cookieCopy.substring(1);
    }

    if (cookieCopy.indexOf(name) === 0) {
      return cookieCopy.substring(name.length, cookie.length);
    }
  }
  return '';
};

export const isCookieCategoryAllowed = (
  categoryToCheck: CookieCategory
): boolean => {
  const cookie = getCookie('cc_cookie');

  if (!cookie) {
    return false;
  }

  const consent = JSON.parse(cookie) as ConsentType;

  if (
    !consent?.level ||
    !Array.isArray(consent.level) ||
    !consent?.level.includes(categoryToCheck)
  ) {
    return false;
  }

  return true;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};
