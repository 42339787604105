import { isBlobImage, isBlobVideo } from '../media';

interface MediaMetaData {
  filename: string;
  mimeType: string;
  dimension: {
    width: number;
    height: number;
    aspectRatio: number;
  };
  sizeInBytes: number;
}

interface VideoThumbnail {
  blob: Blob;
  takenAtSecond: number;
}

export interface ImageMetaData extends MediaMetaData {
  type: 'image';
}

export interface VideoMetaData extends MediaMetaData {
  type: 'video';
  thumbnail: VideoThumbnail;
  duration: number;
}

export function loadImageMetaData(file?: File): Promise<ImageMetaData | null> {
  if (!file) {
    return Promise.resolve(null);
  }
  return new Promise((resolve, reject) => {
    const imageElement = document.createElement('img');
    imageElement.setAttribute('src', URL.createObjectURL(file));
    imageElement.addEventListener('error', (ex) => {
      resolve(null);
    });
    imageElement.addEventListener('load', function () {
      this.remove();
      resolve({
        type: 'image',
        filename: file.name,
        mimeType: file.type,
        dimension: {
          width: this.naturalWidth,
          height: this.naturalHeight,
          aspectRatio: this.naturalWidth / this.naturalHeight,
        },
        sizeInBytes: file.size,
      });
    });
  });
}

//https://stackoverflow.com/a/63474748
export function loadVideoMetaData(file?: File): Promise<VideoMetaData | null> {
  const seekTo = 0.1;
  if (!file) {
    return Promise.resolve(null);
  }
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      reject(null);
    });

    //https://forums.developer.apple.com/forums/thread/129377
    //this makes thumbnails for safari (+chrome) work on mobile
    videoPlayer.preload = 'metadata';

    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      const duration = videoPlayer.duration;

      if (duration < seekTo) {
        reject('video is too short.');
        return;
      }

      const metaDataWithoutThumbnail: Omit<VideoMetaData, 'thumbnail'> = {
        type: 'video',
        filename: file.name,
        mimeType: file.type,
        dimension: {
          width: videoPlayer.videoWidth,
          height: videoPlayer.videoHeight,
          aspectRatio: videoPlayer.videoWidth / videoPlayer.videoHeight,
        },
        duration,
        sizeInBytes: file.size,
      };

      videoPlayer.onseeking = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 70;
        canvas.height = 100;

        // draw the video frame to canvas
        // for some reason the timeout is needed to get the first video frame to canvas
        setTimeout(() => {
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

          // return the canvas image as a blob
          ctx?.canvas.toBlob(
            (blob) => {
              blob
                ? resolve({
                    ...metaDataWithoutThumbnail,
                    thumbnail: { blob, takenAtSecond: 0.1 },
                  })
                : reject(null);
            },
            'image/jpeg',
            0.75 /* quality */
          );
        }, 200);
      };

      // seek to the first frame of the video
      videoPlayer.currentTime = seekTo;
    });
  });
}

export async function loadMediaMetaData(file: File) {
  if (typeof file !== 'object') {
    return null;
  }

  if (isBlobImage(file)) {
    return await loadImageMetaData(file);
  }

  if (isBlobVideo(file)) {
    return await loadVideoMetaData(file);
  }

  return null;
}

const commonAspectRatioCollection = [
  {
    label: '6:13',
    aspectRatio: 6 / 13,
  },
  {
    label: '9:16',
    aspectRatio: 9 / 16,
  },
  {
    label: '3:5',
    aspectRatio: 3 / 5,
  },
  {
    label: '2:3',
    aspectRatio: 2 / 3,
  },
  {
    label: '1:1',
    aspectRatio: 1 / 1,
  },
  {
    label: '19:16',
    aspectRatio: 19 / 16,
  },
  {
    label: '5:4',
    aspectRatio: 5 / 4,
  },
  {
    label: '4:3',
    aspectRatio: 4 / 3,
  },
  {
    label: '11:8',
    aspectRatio: 11 / 8,
  },
  {
    label: 'IMAX',
    aspectRatio: 1.43 / 1,
  },
  {
    label: '3:2',
    aspectRatio: 3 / 2,
  },
  {
    label: '14:9',
    aspectRatio: 14 / 9,
  },
  {
    label: '16:10',
    aspectRatio: 16 / 10,
  },
  {
    label: '1.6180',
    aspectRatio: 1.618 / 1,
  },
  {
    label: '5:3',
    aspectRatio: 5 / 3,
  },
  {
    label: '16:9',
    aspectRatio: 16 / 9,
  },
  {
    label: '1.85',
    aspectRatio: 1.85 / 1,
  },
  {
    label: '1.9',
    aspectRatio: 1.9 / 1,
  },
  {
    label: '2:1',
    aspectRatio: 2 / 1,
  },
  {
    label: '2.2:1',
    aspectRatio: 2.2 / 1,
  },
  {
    label: '2.35:1',
    aspectRatio: 2.35 / 1,
  },
  {
    label: '2.39:1',
    aspectRatio: 2.39 / 1,
  },
  {
    label: '2.4:1',
    aspectRatio: 2.4 / 1,
  },
  {
    label: '2.414:1',
    aspectRatio: 2.2 / 1,
  },
  {
    label: '2.76:1',
    aspectRatio: 2.76 / 1,
  },
  {
    label: '32:9',
    aspectRatio: 32 / 9,
  },
  {
    label: '18:5',
    aspectRatio: 18 / 5,
  },
  {
    label: '4:1',
    aspectRatio: 4 / 1,
  },
] as const;

/**
 * Find best matching standard aspect ratio out of predefined collection
 * @see commonAspectRatioCollection
 */
export function findMatchingStandardAspectRatio(aspectRatio: number) {
  const orientation = aspectRatio > 1 ? 'portrait' : 'landscape';

  const landscapeAspectRatio = aspectRatio < 1 ? 1 / aspectRatio : aspectRatio;

  let bestError = Number.POSITIVE_INFINITY;
  let bestLandscapeError = Number.POSITIVE_INFINITY;
  let bestMatch = null;
  let bestLandscapeMatch = null;
  for (const entry of commonAspectRatioCollection) {
    const error = Math.abs(entry.aspectRatio - aspectRatio);
    if (error < bestError) {
      bestError = error;
      bestMatch = entry;
    }
    const landscapeError = Math.abs(entry.aspectRatio - landscapeAspectRatio);
    if (landscapeError < bestLandscapeError) {
      bestLandscapeError = landscapeError;
      bestLandscapeMatch = entry;
    }
  }

  return {
    aspectRatio,
    orientation,
    error: bestError,
    match: bestMatch,
    landscapeError: bestMatch,
    landscapeMatch: bestLandscapeMatch,
  };
}

/**
 * Format byte size to human-readable string (1024 based)
 * @example
 * 1_363_149 (bytes) -> '1.3MB'
 * @see https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
 */
export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 Bytes';
  var k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
