import { CountryEnum, ProfileFieldsEnum } from '../../../../generated/graphql';

export namespace WPAddress {
  export const firstNameName =
    ProfileFieldsEnum.PayoutAddressFirstname as const;
  export const lastNameName = ProfileFieldsEnum.PayoutAddressLastname as const;
  export const companyNameName =
    ProfileFieldsEnum.PayoutAddressCompanyname as const;
  export const streetName = ProfileFieldsEnum.PayoutAddressStreet as const;
  export const houseNumberName =
    ProfileFieldsEnum.PayoutAddressStreetNumber as const;
  export const cityName = ProfileFieldsEnum.PayoutAddressCity as const;
  export const postalCodeName =
    ProfileFieldsEnum.PayoutAddressPostalCode as const;
  export const countryName = ProfileFieldsEnum.PayoutAddressCountry as const;
  export const documentName = ProfileFieldsEnum.PayoutAddressDocument as const;
  export const documentIdName =
    ProfileFieldsEnum.PayoutAddressDocumentId as const;

  type SharedAddressInputs = {
    [streetName]: string;
    [houseNumberName]: string;
    [cityName]: string;
    [postalCodeName]: string;
    [countryName]: CountryEnum;
    [documentName]?: number;
    [documentIdName]?: number;
  };

  export type PersonalAddressInputs = {
    [firstNameName]: string;
    [lastNameName]: string;
  } & SharedAddressInputs;

  export type CompanyAddressInputs = {
    [companyNameName]: string;
  } & SharedAddressInputs;

  export const personalAddressInit: PersonalAddressInputs = {
    [firstNameName]: '',
    [lastNameName]: '',
    [streetName]: '',
    [houseNumberName]: '',
    [cityName]: '',
    [postalCodeName]: '',
    [countryName]: '' as CountryEnum,
  };

  export const companyAddressInit: CompanyAddressInputs = {
    [companyNameName]: '',
    [streetName]: '',
    [houseNumberName]: '',
    [cityName]: '',
    [postalCodeName]: '',
    [countryName]: '' as CountryEnum,
  };
}
