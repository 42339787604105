import { IconButton, IconButtonProps } from '@chakra-ui/react';

export const ImageGalleryButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    variant={'unstyled'}
    display={'inline-flex'}
    minW={'unset'}
    w={'6'}
    h={'6'}
    bg={'blackAlpha.600'}
    color={'white'}
    _hover={{ color: 'primary.300' }}
    {...props}
  />
);
