import { InputElementProps, forwardRef } from '@chakra-ui/react';

import { useContainerWidth } from '../../../../hooks/useContainerWidth';
import { ClearableInput } from './ClearableInput';
import { ClearableInputClearElement } from './ClearableInputClearElement';
import {
  ClearableInputControl,
  ClearableInputControlProps,
} from './ClearableInputControl';
import { ClearableInputRightElement } from './ClearableInputRightElement';

export const ClearableNumberInputWithUnits = forwardRef<
  ClearableInputControlProps & { units: string; unitProps?: InputElementProps },
  'input'
>(({ inputProps, unitProps, units, isDisabled, ...rest }, ref) => {
  const { ref: addonRef, width: addonWidth } = useContainerWidth();
  const { ref: clearableRef, width: clearableWidth } = useContainerWidth();

  return (
    <ClearableInputControl isDisabled={isDisabled} {...rest}>
      <ClearableInput
        ref={ref}
        type={'number'}
        autoComplete={'off'}
        pr={`${addonWidth + clearableWidth}px`}
        {...inputProps}
      />
      <ClearableInputClearElement
        ref={clearableRef}
        right={`${addonWidth}px`}
      />
      <ClearableInputRightElement
        ref={addonRef}
        isDisabled={isDisabled}
        children={units}
      />
    </ClearableInputControl>
  );
});
