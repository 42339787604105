import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  MediaCommentFragment,
  useGetPhotoCommentingProviderLazyQuery,
} from '../../generated/graphql';
import Logger from '../../utils/Logger';

// Assuming you have a separate module for toast

export const useFetchAndLoadMoreComments = (
  fetchMoreCommentCount: number,
  onClose: () => void
) => {
  const { t } = useTranslation(['general']);

  const [
    getCommentingData,
    {
      data: commentData,
      loading: commentsLoading,
      error: commentsError,
      fetchMore: fetchMoreComments,
    },
  ] = useGetPhotoCommentingProviderLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t('general:toast.KommentareKonntenNichtGeladenWerden'),
      });
      onClose();
    },
  });

  const comments = useMemo(() => {
    return (commentData?.media?.comments?.edges?.map((e) => e?.node) ??
      []) as MediaCommentFragment[];
  }, [commentData?.media?.comments?.edges]);

  const loadMoreComments = useCallback(() => {
    const hasNextPage = commentData?.media.comments.pageInfo?.hasNextPage;

    if (hasNextPage && !commentsError) {
      const lastComment =
        commentData?.media?.comments?.edges[
          commentData?.media?.comments?.edges?.length - 1
        ];
      fetchMoreComments({
        variables: {
          first: fetchMoreCommentCount,
          cursor: lastComment?.cursor ?? '',
        },
      });
    }
  }, [
    commentData?.media.comments.pageInfo?.hasNextPage,
    commentData?.media.comments?.edges,
    commentsError,
    fetchMoreComments,
    fetchMoreCommentCount,
  ]);

  return {
    getCommentingData,
    comments,
    commentsLoading,
    commentsError,
    loadMoreComments,
  };
};
