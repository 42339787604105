import { Schedule } from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  HStack,
  Heading,
  Icon,
  VStack,
  VisuallyHidden,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../../../components/Layout/ResponsiveModal';
import { ChevronRightIcon } from '../../../../../components/shared/icon/ChevronRightIcon';

interface PayoutPendingAlertProps {
  alertProps?: AlertProps;
  alertDescription: React.ReactNode;
  modalHeading: React.ReactNode;
  modalTextSlot: React.ReactNode;
  modalHelpLinkSlot?: React.ReactNode;
}

export const PayoutPendingAlert: React.FC<PayoutPendingAlertProps> = ({
  alertProps,
  alertDescription,
  modalHeading,
  modalTextSlot,
  modalHelpLinkSlot,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Alert
        status={'warning'}
        onClick={onOpen}
        cursor={'pointer'}
        {...alertProps}
      >
        <AlertIcon>
          <Icon as={Schedule} boxSize={'icon.md'} />
        </AlertIcon>
        <AlertDescription flexGrow={1}>{alertDescription}</AlertDescription>
        <ChevronRightIcon />
      </Alert>
      <ResponsiveModal isOpen={isOpen} onClose={onClose}>
        <ResponsiveModalOverlay />
        <ResponsiveModalContent
          maxW={'567px'}
          minH={{ base: '-webkit-fill-available', sm: 'min(100vh, 24rem)' }}
        >
          <ResponsiveModalStickyHeaderBox>
            <VisuallyHidden>{modalHeading}</VisuallyHidden>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>
          <ResponsiveModalBodyBox>
            <VStack alignItems={'start'} spacing={'22px'}>
              <HStack alignItems={'baseline'}>
                <Icon as={Schedule} boxSize={'icon.md'} color={'warning.500'} />
                <Heading size={'2xl'}>{modalHeading}</Heading>
              </HStack>
              {modalTextSlot}
              {modalHelpLinkSlot}
            </VStack>
          </ResponsiveModalBodyBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </>
  );
};
