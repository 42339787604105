import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useVxLiveLinkProviderQuery } from '../generated/graphql';
import {
  getVxLiveCurrentStep,
  getVxLiveRedirectUrl,
} from '../pages/VxLivePage/VxLivePage';
import { externalRoutes } from '../routes/routesConfig';
import { noop } from '../utils';
import { windowOpen } from '../utils/utils';
import { useAccountStatus } from './AccountStatusProvider/AccountStatusProvider';
import { useAuth } from './AuthProvider';

export type VxLiveLinkContext = {
  isLoading: boolean;
  actions: {
    redirectToVxLive: (target?: string) => void;
    openVxLiveInNewTab: (path?: string) => void;
    openVxLiveInPlace: (path?: string) => void;
  };
};

export const initVxLiveLinkContext: VxLiveLinkContext = {
  isLoading: true,
  actions: {
    redirectToVxLive: noop,
    openVxLiveInNewTab: noop,
    openVxLiveInPlace: noop,
  },
};

export const vxLiveLinkContext = React.createContext<VxLiveLinkContext>(
  initVxLiveLinkContext
);

export const VxLiveLinkProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const history = useHistory();
  const authCtx = useAuth();
  const {
    isAllowedToGoOnlineOnVXLive,
    wasOnlineOnVXLive,
    isAccountStatusLoading,
  } = useAccountStatus();
  const { data, loading } = useVxLiveLinkProviderQuery({
    skip: !authCtx.isAuthenticated,
    pollInterval: 60_000 * 15,
  });

  const context = React.useMemo<VxLiveLinkContext>(() => {
    const link = data?.app?.autologinLinkVXLive ?? externalRoutes.vxLive();
    return {
      isLoading: loading || isAccountStatusLoading,
      actions: {
        redirectToVxLive: (target: string = '_blank') => {
          const currentStep = getVxLiveCurrentStep(
            isAllowedToGoOnlineOnVXLive,
            wasOnlineOnVXLive
          );
          if (currentStep === 'CHAT') {
            windowOpen(
              data?.app.autologinLinkVXLive ?? externalRoutes.vxLive(),
              target
            );
          } else {
            history.push(getVxLiveRedirectUrl(currentStep));
          }
        },
        openVxLiveInNewTab: (path?: string) => {
          const linkObject = new URL(link);
          linkObject.pathname = path ?? '/';
          debugger;
          windowOpen(link, '_blank');
        },
        openVxLiveInPlace: (path?: string) => {
          const linkObject = new URL(link);
          linkObject.pathname = path ?? '/';
          windowOpen(link, '_self');
        },
      },
    };
  }, [
    loading,
    isAccountStatusLoading,
    data,
    isAllowedToGoOnlineOnVXLive,
    wasOnlineOnVXLive,
    history,
  ]);

  return (
    <vxLiveLinkContext.Provider value={context}>
      {children}
    </vxLiveLinkContext.Provider>
  );
};

export function useVxLiveLink() {
  return React.useContext(vxLiveLinkContext);
}
