import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

export type WizardInstructionProps = {
  header: string;
  noticeAboveHeader?: React.ReactNode;
  children?: React.ReactNode;
};
export const WizardInstruction: React.FC<WizardInstructionProps> = ({
  noticeAboveHeader,
  header,
  children,
}) => {
  return (
    <Box flexGrow={1}>
      {!!noticeAboveHeader && noticeAboveHeader}
      <Heading size={'md'}>{header}</Heading>
      {children}
    </Box>
  );
};
