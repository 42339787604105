import { Text, keyframes } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useActiveApiLanguage } from '../../../hooks/useActiveApiLanguage';

const animation = keyframes`
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
        `;

export const VXMChatbotThinkingText: React.FC = () => {
  const lang = useActiveApiLanguage();
  const loadingTexts = lang === 'de' ? loadingTextsDE : loadingTextsEN;
  const [currentText, setCurrentText] = useState(
    loadingTexts[Math.floor(Math.random() * loadingTexts.length)]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText(
        loadingTexts[Math.floor(Math.random() * loadingTexts.length)]
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [loadingTexts]);

  return (
    <Text
      style={{
        textAlign: 'left',
        position: 'relative',
        display: 'inline-block',
        marginLeft: '0px',
        fontSize: '14px',
        background:
          'linear-gradient(to right, gray 0%, gray 40%, var(--odi-colors-primary-500) 50%, gray 60%, gray 100%)',
        backgroundSize: '200% 100%',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
      color={'gray.500'}
      animation={`${animation} 2s infinite`}
    >
      {currentText}
    </Text>
  );
};

const loadingTextsDE = [
  '...',
  'Fast fertig. Ich habe gleich deine personalisierte Antwort für dich.',
  'Ich arbeite an der besten Antwort für dich.',
  'Generiere Antwort',
  'Verarbeite Anfrage',
  'Ich verarbeite deine Anfrage',
];

const loadingTextsEN = [
  '...',
  'Almost done. I will have your personalized answer in a moment.',
  'I am working on the best answer for you.',
  'Generating answer',
  'Processing request',
  'I am processing your request',
];
