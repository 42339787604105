import { StackProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DocumentStatusEnum,
  MediaPictureStatusEnum,
} from '../../../generated/graphql';

export type ImageStatusProps = {
  status?: MediaPictureStatusEnum | DocumentStatusEnum.Missing;
} & StackProps;

export const ImageStatus: React.FC<ImageStatusProps> = ({
  status,
  ...rest
}) => {
  const { t } = useTranslation(['general']);

  const statusText = React.useMemo(() => {
    switch (status) {
      case MediaPictureStatusEnum.Rejected:
        return t('general:status.Abgelehnt');
      case MediaPictureStatusEnum.Pending:
        return t('general:status.Prufung');
      case DocumentStatusEnum.Missing:
        return t('general:status.Fehlend');
      default:
        return null;
    }
  }, [status, t]);

  // If the status is ok, then no status symbol needs to be displayed.
  if (status === MediaPictureStatusEnum.Ok) {
    return <></>;
  }

  const image =
    status === MediaPictureStatusEnum.Pending ? (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#FF933C" />
        <path
          d="M15.99 6C10.47 6 6 10.48 6 16C6 21.52 10.47 26 15.99 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 15.99 6ZM16 24C11.58 24 8 20.42 8 16C8 11.58 11.58 8 16 8C20.42 8 24 11.58 24 16C24 20.42 20.42 24 16 24ZM15.78 11H15.72C15.32 11 15 11.32 15 11.72V16.44C15 16.79 15.18 17.12 15.49 17.3L19.64 19.79C19.98 19.99 20.42 19.89 20.62 19.55C20.83 19.21 20.72 18.76 20.37 18.56L16.5 16.26V11.72C16.5 11.32 16.18 11 15.78 11Z"
          fill="white"
        />
      </svg>
    ) : (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#EB0000" />
        <path
          d="M16 11C16.55 11 17 11.45 17 12V16C17 16.55 16.55 17 16 17C15.45 17 15 16.55 15 16V12C15 11.45 15.45 11 16 11ZM15.99 6C10.47 6 6 10.48 6 16C6 21.52 10.47 26 15.99 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 15.99 6ZM16 24C11.58 24 8 20.42 8 16C8 11.58 11.58 8 16 8C20.42 8 24 11.58 24 16C24 20.42 20.42 24 16 24ZM17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20C15 19.4477 15.4477 19 16 19C16.5523 19 17 19.4477 17 20Z"
          fill="white"
        />
      </svg>
    );

  return (
    <VStack spacing={1} {...rest}>
      {image}
      <Text
        children={statusText}
        color={'whiteAlpha.900'}
        textStyle={{
          base: 'labelSm',
          lg: 'labelMd',
        }}
      />
    </VStack>
  );
};
