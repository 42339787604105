import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  MediaLikeFragment,
  useGetVideoLibraryUploadListEntryLikesLazyQuery,
} from '../../generated/graphql';
import Logger from '../../utils/Logger';

//used in video & photo statistics modals in video library
export const useFetchAndLoadMoreLikes = (
  fetchMoreLikesCount: number,
  onClose: () => void
) => {
  const { t } = useTranslation(['general']);

  const [
    refetchLikes,
    {
      data: likesData,
      loading: likesLoading,
      error: likesError,
      fetchMore: fetchMoreLikes,
    },
  ] = useGetVideoLibraryUploadListEntryLikesLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
      });
      onClose();
    },
    notifyOnNetworkStatusChange: true,
  });

  const likes = useMemo(() => {
    return (likesData?.media?.likes?.edges?.map((e) => e?.node) ??
      []) as MediaLikeFragment[];
  }, [likesData?.media?.likes?.edges]);

  const loadMoreLikes = useCallback(() => {
    const hasNextPage = likesData?.media.likes.pageInfo?.hasNextPage;

    if (hasNextPage && !likesError) {
      const lastLike =
        likesData?.media?.likes?.edges[
          likesData?.media?.likes?.edges?.length - 1
        ];
      fetchMoreLikes({
        variables: {
          count: fetchMoreLikesCount,
          after: lastLike?.cursor ?? '',
        },
      });
    }
  }, [
    likesData?.media.likes.pageInfo?.hasNextPage,
    likesData?.media.likes?.edges,
    likesError,
    fetchMoreLikes,
    fetchMoreLikesCount,
  ]);

  return {
    refetchLikes,
    likes,
    likesLoading,
    likesError,
    loadMoreLikes,
  };
};
