import { Button } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStack } from '../../../../components/Layout/ButtonStack';

export const wizardStepFooterTestingIds = {
  continueButton: 'wizard-step-footer-continue-button',
  skipButton: 'wizard-step-footer-skip-button',
  skipButtonContainer: 'wizard-step-footer-skip-button-container',
};

type WizardStepFooterProps = {
  /**
   * Optional continue button text
   * @default 't("general.buttonContinue")'
   */
  continueButtonText?: string;
  /**
   * Whether the continue button should be disabled
   * @default false
   */
  disableContinueButton?: boolean;
  /**
   * Whether the skip button should be disabled
   * @default false
   */
  disableSkipButton?: boolean;
  /**
   * Whether the skip button should be hidden
   * @default false
   */
  hideSkipButton?: boolean;
  onContinueButtonClick: () => void;
  onSkipButtonClick?: () => void;
  /**
   * Optional continue button text
   * @default 't("general.buttonSkip")'
   */
  skipButtonText?: string;

  /**
   * Whether the skip button should be omitted
   * @default false
   */
  omitSkipButton?: boolean;
};

export const WizardStepFooter: React.FC<WizardStepFooterProps> = ({
  continueButtonText,
  disableContinueButton = false,
  disableSkipButton = false,
  hideSkipButton = false,
  omitSkipButton = false,
  onContinueButtonClick = () => {
    // Initially  must be empty
  },
  onSkipButtonClick = () => {
    // Initially  must be empty
  },
  skipButtonText,
}) => {
  const { t } = useTranslation('general');
  continueButtonText = continueButtonText ?? t('button.continue');
  skipButtonText = skipButtonText ?? t('button.skip');
  return (
    <ButtonStack>
      <Button
        minW={'12rem'}
        variant={'solid'}
        children={continueButtonText}
        onClick={onContinueButtonClick}
        isDisabled={disableContinueButton}
      />
      {!omitSkipButton && (
        <Button
          minW={'12rem'}
          visibility={hideSkipButton ? 'hidden' : 'unset'}
          variant={'outline'}
          children={skipButtonText}
          onClick={onSkipButtonClick}
          disabled={disableSkipButton}
        />
      )}
    </ButtonStack>
  );
};
