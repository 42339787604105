import { ApolloError } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Maybe,
  MediaLikeFragment,
  MediaSaleFragment,
  PhotoalbumFragment,
  PhotoalbumTypeEnum,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { useMediaModalQueryParams } from '../../../hooks/useQueryParamState';
import { VideoLibraryPhotoStatisticModal } from '../../../pages/VideoLibraryPage/VideoLibraryPhotoStatisticModal';
import { useAuth } from '../../AuthProvider';
import { useFetchAndLoadMoreLikes } from '../useFetchAndLoadMoreLikes';
import { useFetchAndLoadMoreSales } from '../useFetchAndLoadMoreSales';
import { useFetchPhotoalbum } from '../useFetchPhotoalbum';

interface VideoLibraryPhotoStatisticModalContext {
  photoalbum: Maybe<PhotoalbumFragment> | undefined;
  loading: boolean;
  likes: MediaLikeFragment[];
  likesLoading: boolean;
  likesError: ApolloError | undefined;
  loadMoreLikes: () => void;
  sales: MediaSaleFragment[];
  salesLoading: boolean;
  salesError: ApolloError | undefined;
  loadMoreSales: () => void;
  isOpen: boolean;
  actions: {
    showModal: (albumId: string) => void;
    closeModal: () => void;
  };
}

const photoStatisticsModal = 'photoStatistics';

export const [
  ,
  useVideoLibraryPhotoStatisticModalContext,
  videoLibraryPhotoStatisticModalContext,
] = createContext<VideoLibraryPhotoStatisticModalContext>({
  name: 'VideoLibraryPhotoStatisticModalContext',
  errorMessage:
    'useVideoLibraryPhotoStatisticModalContext: `VideoLibraryPhotoStatisticModalContext` is undefined. Seems you forgot to wrap component within the Provider',
  strict: true,
});

export const VideoLibraryPhotoStatisticModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [modal, albumId, setVideoQueryParams] = useMediaModalQueryParams();
  const { onClose } = useDisclosure();
  const { t } = useTranslation(['general']);
  const { isAuthenticated } = useAuth();
  const isPhotoStatisticModal = modal === photoStatisticsModal;

  /**
   * Get Upload List Entry data
   */
  const { photoalbum, loading } = useFetchPhotoalbum(
    Number(albumId),
    onClose,
    !isPhotoStatisticModal
  );

  /**
   * Get Upload List likes data
   */
  const fetchMoreLikesCount = 10;

  const { refetchLikes, likes, likesError, likesLoading, loadMoreLikes } =
    useFetchAndLoadMoreLikes(fetchMoreLikesCount, onClose);

  /**
   * Get Upload List sales data
   */
  const fetchMoreSalesCount = 10;

  const { refetchSales, sales, salesLoading, salesError, loadMoreSales } =
    useFetchAndLoadMoreSales(fetchMoreSalesCount, onClose);

  /**
   * Load initial stats data
   */
  const initialQuery = React.useCallback(() => {
    refetchLikes({
      variables: {
        albumId: Number(albumId),
        count: fetchMoreLikesCount,
        after: '',
      },
    });
    refetchSales({
      variables: {
        albumId: Number(albumId),
        count: fetchMoreSalesCount,
        after: '',
      },
    });
  }, [albumId, refetchLikes, refetchSales]);

  React.useEffect(() => {
    if (!albumId || !photoalbum) return;

    if (
      photoalbum.type === PhotoalbumTypeEnum.Shop &&
      isPhotoStatisticModal &&
      isAuthenticated
    ) {
      initialQuery();
    } else {
      setVideoQueryParams({ modal: null, albumId: null });
    }
  }, [
    initialQuery,
    albumId,
    setVideoQueryParams,
    modal,
    t,
    isPhotoStatisticModal,
    isAuthenticated,
    photoalbum,
  ]);

  const actions: VideoLibraryPhotoStatisticModalContext['actions'] =
    React.useMemo(() => {
      return {
        showModal: (albumId: string) => {
          setVideoQueryParams({ modal: photoStatisticsModal, albumId });
        },
        closeModal: () => {
          setVideoQueryParams({ modal: null, albumId: null });
          onClose();
        },
      };
    }, [onClose, setVideoQueryParams]);

  const context: VideoLibraryPhotoStatisticModalContext = React.useMemo(() => {
    return {
      photoalbum: photoalbum,
      loading,
      likes,
      likesLoading,
      likesError,
      loadMoreLikes,
      sales,
      salesLoading,
      salesError,
      loadMoreSales,
      isOpen: !!albumId && isPhotoStatisticModal,
      actions,
    };
  }, [
    photoalbum,
    loading,
    likes,
    likesLoading,
    likesError,
    loadMoreLikes,
    sales,
    salesLoading,
    salesError,
    loadMoreSales,
    albumId,
    isPhotoStatisticModal,
    actions,
  ]);

  return (
    <videoLibraryPhotoStatisticModalContext.Provider value={context}>
      {children}
      <VideoLibraryPhotoStatisticModal />
    </videoLibraryPhotoStatisticModalContext.Provider>
  );
};
