import { getProcessEnv } from '../utils/environmentValidator';

const allowedEnvironmentTypes = ['local', 'staging', 'production'] as const;

const ENV = {
  HOST: getProcessEnv('REACT_APP_HOST').asString(''),

  BEVELOP_VXMODELS_API_URL: getProcessEnv(
    'REACT_APP_BEVELOP_VXMODELS_API_URL'
  ).asString(),

  BEVELOP_VXFEED_API_URL: getProcessEnv(
    'REACT_APP_BEVELOP_VXFEED_API_URL'
  ).asString(),

  ROLLBAR_SECRET: getProcessEnv('REACT_APP_ROLLBAR_SECRET').asString(''),

  BEVELOP_NOTIFICATIONS_API_URL: getProcessEnv(
    'REACT_APP_BEVELOP_NOTIFICATIONS_API_URL'
  ).asString(),

  BEVELOP_NOTIFICATIONS_API_SCHEMA_TOKEN: getProcessEnv(
    'REACT_APP_BEVELOP_NOTIFICATIONS_API_SCHEMA_TOKEN'
  ).asString(''),

  GOOGLE_TAG_MANAGER_ID: getProcessEnv(
    'REACT_APP_GOOGLE_TAG_MANAGER_ID'
  ).asString(),

  GOOGLE_TAG_MANAGER_ENV: getProcessEnv(
    'REACT_APP_GOOGLE_TAG_MANAGER_ENV'
  ).asString(''),

  GOOGLE_TAG_MANAGER_AUTH: getProcessEnv(
    'REACT_APP_GOOGLE_TAG_MANAGER_AUTH'
  ).asString(''),

  ONBOARDING_TASK_STATUS_POLLING_IS_DISABLED: getProcessEnv(
    'REACT_APP_ONBOARDING_TASK_STATUS_POLLING_IS_DISABLED'
  ).asBoolean(),

  // Runtime environment
  ENVIRONMENT: getProcessEnv('REACT_APP_ENV').asOneOfStrings(
    allowedEnvironmentTypes,
    'local'
  ),
  get PRODUCTION() {
    return this.ENVIRONMENT === 'production';
  },

  //#region Feature Flag
  FEATURE_WIZARD_PAYOUT_V2: getProcessEnv(
    'REACT_APP_FEATURE_WIZARD_PAYOUT_V2'
  ).asBoolean(false),
  FEATURE_FEED: getProcessEnv('REACT_APP_FEATURE_FEED').asBoolean(false),
  FEATURE_FEED_FILTER: getProcessEnv('REACT_APP_FEATURE_FEED_FILTER').asBoolean(
    false
  ),
  FEATURE_FEED_PAID_CONTENT: getProcessEnv(
    'REACT_APP_FEATURE_FEED_PAID_CONTENT'
  ).asBoolean(false),
  FEATURE_FEED_STATISTICS: getProcessEnv(
    'REACT_APP_FEATURE_FEED_STATISTICS'
  ).asBoolean(false),
  FEATURE_FEED_TIPPING_GOAL: getProcessEnv(
    'REACT_APP_FEATURE_FEED_TIPPING_GOAL'
  ).asBoolean(false),
  FEATURE_FEED_POST_SCHEDULING: getProcessEnv(
    'REACT_APP_FEATURE_FEED_POST_SCHEDULING'
  ).asBoolean(false),
  FEATURE_FEED_VXLIVE_CHAT_BUTTON: getProcessEnv(
    'REACT_APP_FEATURE_FEED_VXLIVE_CHAT_BUTTON'
  ).asBoolean(false),
  FEATURE_NEWSCENTER: getProcessEnv('REACT_APP_FEATURE_NEWSCENTER').asBoolean(
    false
  ),
  FEATURE_DOCUMENT: getProcessEnv('REACT_APP_FEATURE_DOCUMENT').asBoolean(
    false
  ),
  FEATURE_DOCUMENT_FINANCE_EDIT_ALLOWED: getProcessEnv(
    'REACT_APP_FEATURE_DOCUMENT_FINANCE_EDIT_ALLOWED'
  ).asBoolean(false),
  FEATURE_DOCUMENT_PREFERRED_FOR_FINANCE_DOCUMENT_EDITS: getProcessEnv(
    'REACT_APP_FEATURE_DOCUMENT_PREFERRED_FOR_FINANCE_DOCUMENT_EDITS'
  ).asBoolean(false),
  FEATURE_VIDEO_LIBRARY: getProcessEnv(
    'REACT_APP_FEATURE_VIDEO_LIBRARY'
  ).asBoolean(false),
  FEATURE_FINANCE_PAGE_NEW: getProcessEnv(
    'REACT_APP_FEATURE_FINANCE_PAGE_NEW'
  ).asBoolean(false),
  FEATURE_DEEPL_IMPLEMENTATION: getProcessEnv(
    'REACT_APP_FEATURE_DEEPL_IMPLEMENTATION'
  ).asBoolean(false),
  FEATURE_CONTEST: getProcessEnv('REACT_APP_FEATURE_CONTEST').asBoolean(false),
  FEATURE_CHATBOT: getProcessEnv('REACT_APP_FEATURE_CHATBOT').asBoolean(false),

  //#endregion

  PACKAGE_VERSION: getProcessEnv('REACT_APP_PACKAGE_VERSION').asString(''),
  GIT_SHA: getProcessEnv('REACT_APP_GIT_SHA').asString(''),
  GIT_BRANCH: getProcessEnv('REACT_APP_GIT_BRANCH').asString(''),
};

export default ENV;
