import {
  Box,
  Checkbox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  ToastProps,
} from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import ScrollToTopOnMount from '../../../../../components/Layout/ScrollToTopOnMount';
import { InputList } from '../../../../../components/Spacer/InputsList/InputList';
import { ReadOnlyInputList } from '../../../../../components/Spacer/InputsList/ReadOnlyInputList';
import {
  DocumentPicker,
  PickedDocument,
} from '../../../../../components/shared/DocumentPicker/DocumentPicker';
import {
  ClearableInputControl,
  PromotingSelectControl,
} from '../../../../../components/shared/FormikFormElements';
import {
  LabeledReadonlyFieldSelect,
  LabeledReadonlyFieldText,
} from '../../../../../components/shared/LabeledReadonlyField';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { CountryEnum } from '../../../../../generated/graphql';
import { useDebouncedFormikValidation } from '../../../../../hooks/useDebouncedFormikValidation';
import { useDocumentUpload } from '../../../../../hooks/useDocumentUpload';
import { useFormikErrorSetter } from '../../../../../hooks/useFormikErrorSetter';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { noop } from '../../../../../utils';
import { onFocusScrollIntoCenter } from '../../../../../utils/onFocusScrollIntoCenter';
import { validateAndTouchFormik } from '../../../../../utils/utils';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';
import { WPAddress } from './WizardPayoutAddressDataTypes';

const isIdenticalToModelAddressName = 'isIdenticalToModelAddress' as const;
const personalAddressTabValue = 'personalAddress';
const companyAddressTabValue = 'companyAddress';
const tabs = [personalAddressTabValue, companyAddressTabValue] as const;
type TabId = (typeof tabs)[number];

const initialTab = tabs[0];

const prefix = 'wizardpayout-address';
const [tabId1, tabId2] = [`${prefix}-tab-1`, `${prefix}-tab-2`] as const;

export const WizardPayoutAddressData: React.FC = () => {
  const { t } = useTranslation(['general', 'wizardPayout']);
  const wizardPayoutCtx = useWizardPayout();

  const [selectedTab, setSelectedTab] = useState<TabId>(initialTab);

  const [isIdenticalToModelAddress, setIsIdenticalToModelAddress] =
    useState(false);

  const { currentFieldError, accountPrefillField } = wizardPayoutCtx;

  const { currentField } = useWizardPayout();

  const prefillAddress = useMemo<WPAddress.PersonalAddressInputs>(
    () => ({
      [WPAddress.firstNameName]:
        accountPrefillField?.accountFirstname?.value ?? '',
      [WPAddress.lastNameName]:
        accountPrefillField?.accountLastname?.value ?? '',
      [WPAddress.streetName]:
        accountPrefillField?.accountAddressStreet?.value ?? '',
      [WPAddress.houseNumberName]:
        accountPrefillField?.accountAddressStreetNumber?.value ?? '',
      [WPAddress.cityName]:
        accountPrefillField?.accountAddressCity?.value ?? '',
      [WPAddress.postalCodeName]:
        accountPrefillField?.accountAddressPostalCode?.value ?? '',
      [WPAddress.countryName]: (accountPrefillField?.accountAddressCountry
        ?.value ?? '') as CountryEnum,
    }),
    [accountPrefillField]
  );

  const sharedFieldsValidation = useMemo(
    () =>
      Yup.object().shape({
        [WPAddress.streetName]: createStringValidationSchema({
          minLength: currentField?.payoutAddressStreet?.minLength,
          maxLength: currentField?.payoutAddressStreet?.maxLength,
        }),
        [WPAddress.houseNumberName]: createStringValidationSchema({
          minLength: currentField?.payoutAddressStreetNumber?.minLength,
          maxLength: currentField?.payoutAddressStreetNumber?.maxLength,
        }),
        [WPAddress.cityName]: createStringValidationSchema({
          minLength: currentField?.payoutAddressCity?.minLength,
          maxLength: currentField?.payoutAddressCity?.maxLength,
        }),
        [WPAddress.postalCodeName]: createStringValidationSchema({
          minLength: currentField?.payoutAddressPostalCode?.minLength,
          maxLength: currentField?.payoutAddressPostalCode?.maxLength,
        }),
        [WPAddress.countryName]: createStringValidationSchema({}),
      }),
    [currentField]
  );

  const personalAddressValidationSchema = useMemo(
    () =>
      Yup.object()
        .shape({
          [WPAddress.firstNameName]: createStringValidationSchema({
            minLength: currentField?.payoutAddressFirstname?.minLength,
            maxLength: currentField?.payoutAddressFirstname?.maxLength,
          }),
          [WPAddress.lastNameName]: createStringValidationSchema({
            minLength: currentField?.payoutAddressLastname?.minLength,
            maxLength: currentField?.payoutAddressLastname?.maxLength,
          }),
        })
        .concat(sharedFieldsValidation),
    [currentField, sharedFieldsValidation]
  );

  const companyAddressValidationSchema = useMemo(
    () =>
      Yup.object()
        .shape({
          [WPAddress.companyNameName]: createStringValidationSchema({
            minLength: currentField?.payoutAddressCompanyname?.minLength,
            maxLength: currentField?.payoutAddressCompanyname?.maxLength,
          }),
        })
        .concat(sharedFieldsValidation),
    [currentField, sharedFieldsValidation]
  );

  const personalAddressFormik = useFormik({
    initialValues: WPAddress.personalAddressInit,
    validationSchema: personalAddressValidationSchema,
    validateOnChange: false,
    onSubmit: noop,
  });

  useDebouncedFormikValidation(personalAddressFormik);

  const companyAddressFormik = useFormik({
    initialValues: WPAddress.companyAddressInit,
    validationSchema: companyAddressValidationSchema,
    validateOnChange: false,
    onSubmit: noop,
  });

  useDebouncedFormikValidation(companyAddressFormik);

  const [pickedPersonalDocument, setPickedPersonalDocument] =
    useState<PickedDocument | null>(null);

  const [pickedCompanyDocument, setPickedCompanyDocument] =
    useState<PickedDocument | null>(null);

  const { isValid: personalAddressIsValid, dirty: personalAddressIsDirty } =
    personalAddressFormik;

  const { isValid: companyAddressIsValid, dirty: companyAddressIsDirty } =
    companyAddressFormik;

  const currentFormIsValid = useMemo(() => {
    switch (selectedTab) {
      case personalAddressTabValue:
        return (
          pickedPersonalDocument &&
          (isIdenticalToModelAddress ||
            (personalAddressIsValid && personalAddressIsDirty))
        );
      case companyAddressTabValue:
        return (
          pickedCompanyDocument &&
          companyAddressIsValid &&
          companyAddressIsDirty
        );
    }
    return false;
  }, [
    isIdenticalToModelAddress,
    selectedTab,
    personalAddressIsValid,
    personalAddressIsDirty,
    pickedPersonalDocument,
    pickedCompanyDocument,
    companyAddressIsValid,
    companyAddressIsDirty,
  ]);

  const setPersonalAddressErrors = useFormikErrorSetter(personalAddressFormik);
  const setCompanyAddressErrors = useFormikErrorSetter(companyAddressFormik);

  useEffect(() => {
    if (Object.keys(currentFieldError).length > 0) {
      switch (selectedTab) {
        case personalAddressTabValue:
          setPersonalAddressErrors(currentFieldError as any).then();
          return;
        case companyAddressTabValue:
          setCompanyAddressErrors(currentFieldError as any).then();
          return;
      }
    }
  }, [
    selectedTab,
    currentFieldError,
    setPersonalAddressErrors,
    setCompanyAddressErrors,
  ]);

  const uploadUrl =
    wizardPayoutCtx.currentField?.payoutAddressDocument?.upload?.url ??
    undefined;

  const { upload, isUploading } = useDocumentUpload(uploadUrl);

  const onContinue = useCallback(async () => {
    const errorToast: ToastProps = {
      status: 'error',
      description: t('general:toast.EsGabEinenFehlerBeimHochladenDesBildes'),
    };

    switch (selectedTab) {
      case 'personalAddress': {
        if (!pickedPersonalDocument) {
          issueChakraToast(errorToast);
          return;
        }
        const docId = await upload(pickedPersonalDocument);
        if (!Number.isInteger(docId)) {
          issueChakraToast(errorToast);
          return;
        }

        if (isIdenticalToModelAddress) {
          wizardPayoutCtx.wizardNextStepCallback({
            payoutIsModelOriginator: true,
            payoutAddressCompanyname: '',
            ...prefillAddress,
            // keep setting to prevent errors payoutAddressDocument
            payoutAddressDocument: docId,
            // let payoutAddressDocumentId do the actual job
            payoutAddressDocumentId: docId,
          });
          return;
        }
        await validateAndTouchFormik(personalAddressFormik).then((isValid) => {
          if (isValid) {
            wizardPayoutCtx.wizardNextStepCallback({
              payoutIsModelOriginator: false,
              payoutAddressCompanyname: '',
              ...personalAddressFormik.values,
              // keep setting to prevent errors payoutAddressDocument
              payoutAddressDocument: docId,
              // let payoutAddressDocumentId do the actual job
              payoutAddressDocumentId: docId,
            });
          }
        });
        return;
      }
      case 'companyAddress': {
        if (!pickedCompanyDocument) {
          issueChakraToast(errorToast);
          return;
        }

        const docId = await upload(pickedCompanyDocument);
        if (!Number.isInteger(docId)) {
          issueChakraToast(errorToast);
          return;
        }

        await validateAndTouchFormik(companyAddressFormik).then((isValid) => {
          if (isValid) {
            wizardPayoutCtx.wizardNextStepCallback({
              payoutIsModelOriginator: false,
              payoutAddressFirstname: '',
              payoutAddressLastname: '',
              ...companyAddressFormik.values,
              // keep setting to prevent errors payoutAddressDocument
              payoutAddressDocument: docId,
              // let payoutAddressDocumentId do the actual job
              payoutAddressDocumentId: docId,
            });
          }
        });
        return;
      }
      default:
        return;
    }
  }, [
    t,
    isIdenticalToModelAddress,
    upload,
    selectedTab,
    wizardPayoutCtx,
    prefillAddress,
    companyAddressFormik,
    personalAddressFormik,
    pickedPersonalDocument,
    pickedCompanyDocument,
  ]);

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t(
            'wizardPayout:heading.MitWelchenDatenSollenWirDieRechnungAusstellen'
          )}
          children={
            <Tabs
              defaultIndex={0}
              onChange={(index) => setSelectedTab(tabs[index])}
              isFitted
            >
              <ScrollToTopOnMount />
              <TabList>
                <Tab id={tabId1}>{t('wizardPayout:heading.Einzelperson')}</Tab>
                <Tab id={tabId2}>{t('wizardPayout:heading.Firma')}</Tab>
              </TabList>
              <TabPanels pt={6} mb={8}>
                <TabPanel p={0} aria-labelledby={tabId1}>
                  <Checkbox
                    mb={6}
                    name={isIdenticalToModelAddressName}
                    checked={isIdenticalToModelAddress}
                    onChange={(e) =>
                      setIsIdenticalToModelAddress(e.target.checked)
                    }
                    children={t(
                      'wizardPayout:text.RechnungsdatenEntsprechenModeldaten'
                    )}
                  />

                  <form
                    aria-busy={
                      isUploading || personalAddressFormik.isValidating
                    }
                    aria-label={t('wizardPayout:heading.Einzelperson')}
                  >
                    <FormikProvider value={personalAddressFormik}>
                      <PersonalAddressFields
                        isUsingPrefilled={isIdenticalToModelAddress}
                      />
                    </FormikProvider>
                  </form>
                  <Box mt={4} w={'full'}>
                    <MediaPropertiesProvider
                      targetDimensions={{ width: 1000, height: 1400 }}
                      enforceTargetDimension={false}
                      determineAspectRatioByInput={true}
                      isOrientationFlipAllowed={true}
                    >
                      <DocumentPicker
                        label={t('wizardPayout:label.AdressnachweisHochladen')}
                        cancelCropButtonLabel={t(
                          'wizardPayout:button.Abbrechen'
                        )}
                        cropButtonLabel={t('wizardPayout:button.Zuschneiden')}
                        onDocument={setPickedPersonalDocument}
                        allowOrientationFlip={true}
                        enforceTargetImageDimensions={false}
                        targetImageDimensions={{ width: 1000, height: 1400 }}
                        uploadingLabel={t(
                          'wizardPayout:text.DokumentWirdHochgeladen'
                        )}
                        isUploading={isUploading}
                        errorMessage={undefined}
                      />
                    </MediaPropertiesProvider>
                  </Box>

                  <Box mt={6}>
                    <TipCard
                      text={t(
                        'wizardPayout:text.LadeEinDokumentHochAufDemDeineAdresseZuSehenIstZBDeinenLichtbildauswXX'
                      )}
                    />
                  </Box>
                </TabPanel>
                <TabPanel p={0} aria-labelledby={tabId2}>
                  <form
                    aria-busy={isUploading || companyAddressFormik.isValidating}
                    aria-label={t('wizardPayout:heading.Firma')}
                  >
                    <FormikProvider value={companyAddressFormik}>
                      <CompanyAddressFields />
                    </FormikProvider>
                  </form>

                  <Box mt={4} width={'100%'}>
                    <MediaPropertiesProvider
                      targetDimensions={{ width: 1000, height: 1400 }}
                      enforceTargetDimension={false}
                      determineAspectRatioByInput={true}
                      isOrientationFlipAllowed={true}
                    >
                      <DocumentPicker
                        label={t('wizardPayout:label.AdressnachweisHochladen')}
                        cancelCropButtonLabel={t(
                          'wizardPayout:button.Abbrechen'
                        )}
                        cropButtonLabel={t('wizardPayout:button.Zuschneiden')}
                        onDocument={setPickedCompanyDocument}
                        allowOrientationFlip={true}
                        enforceTargetImageDimensions={false}
                        targetImageDimensions={{ width: 1000, height: 1400 }}
                        uploadingLabel={t(
                          'wizardPayout:text.DokumentWirdHochgeladen'
                        )}
                        isUploading={isUploading}
                        errorMessage={undefined}
                      />
                    </MediaPropertiesProvider>
                  </Box>

                  <Box mt={6}>
                    <TipCard
                      text={t(
                        'wizardPayout:text.LadeEinDokumentHochAufDemDieAdresseDeinerFirmaZuSehenIstZBDieRechnunXX'
                      )}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          }
        />
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.Weiter')}
            onClick={onContinue}
            isDisabled={!currentFormIsValid || isUploading}
          />
        </ButtonStack>
      }
    />
  );
};

const SharedAddressFields: React.FC = () => {
  const { t } = useTranslation(['general', 'payout']);
  const { currentField } = useWizardPayout();
  const validValues = currentField?.payoutAddressCountry?.validValues;
  const quickpickValues = currentField?.payoutAddressCountry?.quickpickValues;
  const optionListProps = useMemo(
    () => ({
      options: validValues ?? [],
      groupLabel: t('general:optgroup.Lander'),
      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
      placeholder: t('payout:placeholder.LandEingeben'),
      promotedValues: (quickpickValues ?? []).map(({ value }) => value),
    }),
    [t, validValues, quickpickValues]
  );
  return (
    <>
      <ClearableInputControl
        onFocus={onFocusScrollIntoCenter}
        name={WPAddress.streetName}
        label={t('payout:label.Strasse')}
        inputProps={{
          placeholder: t('payout:placeholder.StrasseEingeben'),
        }}
      />

      <ClearableInputControl
        onFocus={onFocusScrollIntoCenter}
        name={WPAddress.houseNumberName}
        label={t('payout:label.Hausnummer')}
        inputProps={{
          placeholder: t('payout:placeholder.HausnummerEingeben'),
        }}
      />

      <ClearableInputControl
        onFocus={onFocusScrollIntoCenter}
        name={WPAddress.cityName}
        label={t('payout:label.Stadt')}
        inputProps={{
          placeholder: t('payout:placeholder.StadtEingeben'),
        }}
      />

      <ClearableInputControl
        onFocus={onFocusScrollIntoCenter}
        name={WPAddress.postalCodeName}
        label={t('payout:label.Postleitzahl')}
        inputProps={{
          placeholder: t('payout:placeholder.PostleitzahlEingeben'),
        }}
      />

      <PromotingSelectControl
        onFocus={onFocusScrollIntoCenter}
        name={WPAddress.countryName}
        label={t('payout:label.Land')}
        optionListProps={optionListProps}
        selectProps={useMemo(
          () => ({
            autoComplete: 'country',
          }),
          []
        )}
      />
    </>
  );
};

const PersonalAddressFields: React.FC<{ isUsingPrefilled: boolean }> = ({
  isUsingPrefilled,
}) => {
  const { t } = useTranslation(['payout']);
  const { accountPrefillField } = useWizardPayout();
  return isUsingPrefilled ? (
    <ReadOnlyInputList>
      <LabeledReadonlyFieldText
        label={t('payout:label.Vorname')}
        field={accountPrefillField?.accountFirstname}
      />
      <LabeledReadonlyFieldText
        label={t('payout:label.Nachname')}
        field={accountPrefillField?.accountLastname}
      />
      <LabeledReadonlyFieldText
        label={t('payout:label.Strasse')}
        field={accountPrefillField?.accountAddressStreet}
      />
      <LabeledReadonlyFieldText
        label={t('payout:label.Hausnummer')}
        field={accountPrefillField?.accountAddressStreetNumber}
      />
      <LabeledReadonlyFieldText
        label={t('payout:label.Stadt')}
        field={accountPrefillField?.accountAddressCity}
      />
      <LabeledReadonlyFieldText
        label={t('payout:label.Postleitzahl')}
        field={accountPrefillField?.accountAddressPostalCode}
      />
      <LabeledReadonlyFieldSelect
        label={t('payout:label.Land')}
        field={accountPrefillField?.accountAddressCountry}
      />
    </ReadOnlyInputList>
  ) : (
    <>
      <InputList>
        <ClearableInputControl
          onFocus={onFocusScrollIntoCenter}
          name={WPAddress.firstNameName}
          label={t('payout:label.Vorname')}
          inputProps={{
            placeholder: t('payout:placeholder.VornamenEingeben'),
          }}
        />
        <ClearableInputControl
          onFocus={onFocusScrollIntoCenter}
          name={WPAddress.lastNameName}
          label={t('payout:label.Nachname')}
          inputProps={{
            placeholder: t('payout:placeholder.NachnamenEingeben'),
          }}
        />
        <SharedAddressFields />
      </InputList>
    </>
  );
};

const CompanyAddressFields: React.FC = () => {
  const { t } = useTranslation(['payout']);
  return (
    <InputList>
      <ClearableInputControl
        onFocus={onFocusScrollIntoCenter}
        name={WPAddress.companyNameName}
        label={t('payout:label.Firmenname')}
        inputProps={{
          placeholder: t('payout:placeholder.FirmennamenEingeben'),
        }}
      />
      <SharedAddressFields />
    </InputList>
  );
};
