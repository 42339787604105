import { Error as ErrorIcon, Info, Schedule } from '@campoint/odi-ui-icons';
import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export type IconStatus = 'error' | 'hint' | 'warning';
export const StatusIcon: React.FC<{
  status: IconStatus;
  iconProps?: IconProps;
}> = ({ status, iconProps }) => {
  if (status === 'error') {
    return (
      <Icon
        as={ErrorIcon}
        boxSize={'icon.md'}
        color={'error.500'}
        {...iconProps}
      />
    );
  }

  if (status === 'warning') {
    return (
      <Icon
        as={Schedule}
        boxSize={'icon.md'}
        color={'warning.500'}
        {...iconProps}
      />
    );
  }

  if (status === 'hint') {
    return (
      <Icon as={Info} boxSize={'icon.md'} color={'error.500'} {...iconProps} />
    );
  }

  return null;
};
