import { Button, Heading, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import updateTaxDataRejected from '../../../../assets/images/shared/update-data-reject.svg';
import { FeatureName, useFeature } from '../../../../flags';
import { routes } from '../../../../routes/routesConfig';
import { UrlFragment } from '../../../Layout/UrlFragmentScrollToTarget';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../cards/UniversalCard/UniversalCard';

export const RejectedTaxInformationCard: React.FC<{
  rejectedElement: string;
  reason?: string;
}> = ({ rejectedElement, reason }) => {
  const { t } = useTranslation(['general', 'payout']);
  const documentFlag = useFeature(FeatureName.document);
  const documentPreferredForFinanceDocumentFlag = useFeature(
    FeatureName.documentPreferredForFinanceDocument
  );
  const history = useHistory();
  const navigateToPayoutTaxSection = () => {
    if (documentFlag && documentPreferredForFinanceDocumentFlag) {
      history.push(routes.documentsPayout.path);
    } else {
      history.push(
        `${routes.finance.path}?tapId=${'vat'}#${UrlFragment.TaxEditSection}`
      );
    }
  };
  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={updateTaxDataRejected}
              alt={t('general:img.AbgelehntesDokument')}
            />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t('payout:headline.DeinElementWurdeAbgelehnt', {
                  rejectedElement,
                })}
              </Heading>
              {reason && (
                <Text
                  size={{ base: 'sm', lg: 'md' }}
                  textAlign={'center'}
                  color={'gray.500'}
                  p={0}
                >
                  {t('payout:text.GrundReason', { reason })}
                </Text>
              )}
            </VStack>
          </>
        }
        cardFooter={
          <VStack gap={4} spacing={0}>
            <Button
              variant={'solid'}
              children={t('payout:button.NochEinmalVersuchen')}
              onClick={navigateToPayoutTaxSection}
            />
          </VStack>
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
