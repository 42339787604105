import { PrivacyTip } from '@campoint/odi-ui-icons';
import { Alert, AlertIcon, AlertProps, AlertTitle } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyHint: React.FC<AlertProps> = (props) => {
  const { t } = useTranslation('general');

  return (
    <Alert
      maxW={'container.lg'}
      bg={'coldGray.100'}
      borderColor={'coldGray.900'}
      color={'coldGray.900'}
      mx={'auto'}
      mb={4}
      {...props}
    >
      <AlertIcon as={PrivacyTip} color={'onSurface.highEmphasis'} />
      <AlertTitle>{t('privacy.message')}</AlertTitle>
    </Alert>
  );
};
