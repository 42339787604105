import { Box, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';

import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import first from '../assets/1st_Trophy.png';
import second from '../assets/2nd_Trophy.png';
import third from '../assets/3rd_Trophy.png';
import { ContestTileBase, ContestTileHeaderImage } from './ContestTileBase';

export const ContestTilePrizes: typeof ContestTileBase = (props) => {
  const contest = props.contest;
  return (
    <ContestTileBase {...props}>
      <ContestTileHeaderImage contest={props.contest} />
      <Box p={3}>
        <Heading size="sm">
          {t('contest:heading.GewinnePreiseImGesamtwertVonXXX')}
        </Heading>
        <HStack lineHeight={0.8} gap={'20px'} justifyContent={'center'}>
          <VStack fontSize={'14px'}>
            <Image height={'55px'} src={second} alt="" />
            <Text>{t('contest:label.Platz2')}</Text>
            <Text fontWeight={600} fontSize={'1.1em'}>
              {contest.prizes.find((prize) => prize?.place === 2)?.amount} €
            </Text>
          </VStack>
          <VStack fontSize={'18px'} mt={'20px'}>
            <Image height={'70px'} src={first} alt="" />
            <Text>{t('contest:label.Platz1')}</Text>
            <Text fontWeight={600} fontSize={'1.3em'}>
              {contest.prizes.find((prize) => prize?.place === 1)?.amount} €
            </Text>
          </VStack>
          <VStack fontSize={'14px'}>
            <Image height={'55px'} src={third} alt="" />
            <Text>{t('contest:label.Platz3')}</Text>
            <Text fontWeight={600} fontSize={'1.13em'}>
              {contest.prizes.find((prize) => prize?.place === 3)?.amount} €
            </Text>
          </VStack>
        </HStack>
        <Text textAlign={'center'} mt={'30px'}>
          {t(
            'contest:text.AufDieTop10FotosWartenWeitereAttracktiveGeldpreise',
            {
              count: contest.prizes.length,
              type:
                props.contest.type === MediaContestTypeEnum.Photo
                  ? t('contest:label.Photos')
                  : t('contest:label.Videos'),
            }
          )}
        </Text>
      </Box>
    </ContestTileBase>
  );
};
