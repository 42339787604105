import { useCallback, useEffect, useRef } from 'react';

/**
 * Useful to prevent state update calls to components,
 * which get unmounted during the execution of an asynchronous task
 * {@link https://usehooks-ts.com/react-hook/use-is-mounted stolen from}
 *
 * @example
 * import { useIsMounted } from 'usehooks-ts'
 *
 * const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
 *
 * function Child() {
 *   const [data, setData] = useState('loading')
 *   const isMounted = useIsMounted()
 *
 *   // simulate an api call and update state
 *   useEffect(() => {
 *     void delay(3000).then(() => {
 *       if (isMounted()) setData('OK')
 *     })
 *   }, [isMounted])
 *
 *   return <p>{data}</p>
 * }
 */
function useIsMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

export default useIsMounted;
