import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  UseRadioProps,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export interface RadioGroupHookControlProps
  extends Omit<RadioGroupProps, 'children'> {
  label?: React.ReactNode;

  /** List of options pick able by radio buttons */
  options: Array<UseRadioProps & { children?: React.ReactNode }>;

  name: string;
}

export const RadioGroupHookControl = forwardRef<
  RadioGroupHookControlProps,
  'div'
>(({ label, name, options, children, ...radioGroupProps }, ref) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors, touchedFields, isSubmitted },
  } = useFormContext();

  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);
  const value = watch(name);

  const handleChange = (value: string) => {
    setValue(name, value, { shouldTouch: true, shouldDirty: true });
  };

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {isInvalid && (
        <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
      )}

      {children ?? (
        <RadioGroup
          {...register(name)}
          ref={ref}
          w={'full'}
          role="group"
          value={value}
          onChange={handleChange}
        >
          <VStack align="flex-start" spacing={4}>
            {options.map((option) => (
              <Radio key={option.value} size="lg" {...option}>
                {option.children ?? null}
              </Radio>
            ))}
          </VStack>
        </RadioGroup>
      )}
    </FormControl>
  );
});
