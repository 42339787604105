import { Box, Heading, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useActiveApiLanguage } from '../../../../../hooks/useActiveApiLanguage';
import { externalRoutes, routes } from '../../../../../routes/routesConfig';

export const GMUModalStep2: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslation(['gmumodal']);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const lang = useActiveApiLanguage();
  const history = useHistory();

  return (
    <>
      <Heading as="h2" size="xl" textAlign={'center'}>
        {t('gmumodal:heading.dieFAQsZuDenNeuerungen')}
      </Heading>
      <Text textAlign={'center'} color={'gray.700'} fontSize={'14px'} mt={3}>
        {t('gmumodal:text.anderungenWelcheDuWissenSolltest')}
      </Text>

      <Box px={isMobile ? 2 : 10} mt={4}>
        <Heading
          as="h3"
          color={'gray.900'}
          fontSize={'16px'}
          my={2}
          lineHeight={1}
        >
          {t('gmumodal:text.features')}
        </Heading>
        <Text color={'gray.700'} fontSize={'14px'} mb={isMobile ? 6 : 10}>
          {t('gmumodal:text.neueToolsZurSkalierung')}
        </Text>
        <Heading
          as="h3"
          color={'gray.900'}
          fontSize={'16px'}
          my={2}
          lineHeight={1}
        >
          {t('gmumodal:text.performanceBasiertesSharing')}
        </Heading>
        <Text color={'gray.700'} fontSize={'14px'} mb={isMobile ? 6 : 10}>
          {t('gmumodal:text.dasNeueSharingModell')}
        </Text>
        <Heading
          as="h3"
          color={'gray.900'}
          fontSize={'16px'}
          my={2}
          lineHeight={1}
        >
          {t('gmumodal:text.vxCoins')}
        </Heading>
        <Text color={'gray.700'} fontSize={'14px'} mb={isMobile ? 6 : 10}>
          {t('gmumodal:text.dieNeuePlatformWahrung')}
        </Text>

        <Trans
          t={t}
          ns={'gmumodal'}
          i18nKey={'text.mehrInformationenFindestDuImHelpcenter'}
          components={{
            Helpcenter: (
              <Link
                href={externalRoutes.helpCenter(lang)}
                isExternal
                color={'primary.500'}
              />
            ),
            FAQPage: (
              <Link
                href={
                  externalRoutes.helpCenter(lang) +
                  '/categories/22304223656978-FAQ-VXModels-goes-2025'
                }
                isExternal
                color={'primary.500'}
              />
            ),
            KI: (
              <Link
                href={routes.help.path}
                color={'primary.500'}
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                  history.push(routes.help.path + '?openChat=true');
                }}
              />
            ),
          }}
        />
      </Box>
    </>
  );
};
