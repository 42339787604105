import { Box, ToastProps } from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import passportBackImagePlaceholderImage from '../../../../../assets/images/imageUpload/passport-back-placeholder.svg';
import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import { CheckboxSingleControl } from '../../../../../components/shared/FormikFormElements';
import { ImagePickerV2 } from '../../../../../components/shared/ImagePickerV2/ImagePickerV2';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { useSkipVerificationBacksideMutation } from '../../../../../generated/graphql';
import { MediaInputProvider } from '../../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { CroppedImage } from '../../../../../types/CroppedImage';
import { uploadDocument } from '../../../../../utils/media';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';

export const WizardPayoutOriginatorIDCardBack: React.FC = () => {
  const wizardPayoutCtx = useWizardPayout();
  const uploadUrl =
    wizardPayoutCtx.currentField?.originatorIdentityProofBack?.upload?.url ??
    undefined;
  const { t } = useTranslation([
    'general',
    'wizardVerification',
    'imagePicker',
    'wizardPayout',
  ]);
  const [image, setImage] = useState<null | CroppedImage>();
  const [isUploading, setIsUploading] = useState(false);
  const { wizardNextStepCallback } = wizardPayoutCtx;
  const [skipUpload, { loading: skipLoading }] =
    useSkipVerificationBacksideMutation({
      onCompleted: () => {
        wizardNextStepCallback();
      },
      onError: (e) => {
        setIsUploading(false);
        issueChakraToast({
          status: 'error',
          description: t('wizardVerification:uploadError'),
        });
      },
    });

  const formik = useFormik({
    initialValues: { isEmpty: false },
    onSubmit: () => {
      // Do nothing
    },
  });

  const uploadImage = React.useCallback(async () => {
    if (!!uploadUrl && image?.blob) {
      const errorToast: ToastProps = {
        status: 'error',
        description: t('wizardVerification:uploadError'),
      };
      try {
        setIsUploading(true);
        const result = await uploadDocument(uploadUrl, image.blob);
        if (result.id === null) {
          issueChakraToast(errorToast);
        } else {
          wizardNextStepCallback({
            originatorIdentityProofBack: result.id,
          });
        }
      } catch {
        issueChakraToast(errorToast);
      } finally {
        setIsUploading(false);
      }
    }
  }, [image, t, uploadUrl, setIsUploading, wizardNextStepCallback]);

  const handleContinue = React.useCallback(async () => {
    if (formik.values.isEmpty) {
      await skipUpload();
    } else {
      await uploadImage();
    }
  }, [formik, uploadImage, skipUpload]);

  const isLoading = isUploading || skipLoading;

  return (
    <WizardParentModalStepLayout
      contentSection={
        <>
          <WizardSubHeader
            headerText={t('wizardVerification:backImage.subHeader')}
          />
          <MediaPropertiesProvider
            targetDimensions={{
              height: 864,
              width: 1368,
            }}
            isOrientationFlipAllowed={true}
            determineAspectRatioByInput={true}
            placeholderSrc={passportBackImagePlaceholderImage}
          >
            <MediaInputProvider
              accept={'image/png,image/jpeg,image/webp,application/pdf'}
            >
              <ImagePickerV2
                cancelCropButtonLabel={t('imagePicker:cancel')}
                cropButtonLabel={t('imagePicker:crop')}
                onImage={setImage}
                status={isLoading ? 'loading' : 'add'}
                placeholder={passportBackImagePlaceholderImage}
                targetImageDimensions={{
                  height: 864,
                  width: 1368,
                }}
                allowOrientationFlip={true}
                accept={'image/png,image/jpeg,image/webp,application/pdf'}
              />
            </MediaInputProvider>
          </MediaPropertiesProvider>
          <FormikProvider value={formik}>
            <Box mt={12}>
              <CheckboxSingleControl name="isEmpty">
                {t('wizardVerification:backImage.backEmpty')}
              </CheckboxSingleControl>
            </Box>
          </FormikProvider>

          <Box mt={48}>
            <TipCard text={t('wizardVerification:backImage.hint')} />
          </Box>
        </>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.Weiter')}
            onClick={handleContinue}
            isDisabled={(!image && !formik.values.isEmpty) || isLoading}
          />
        </ButtonStack>
      }
    />
  );
};
