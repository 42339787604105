import { Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/react';
import React from 'react';

const gridArea = {
  input: 'input',
  error: 'error',
  count: 'count',
  submit: 'submit',
  slot: 'slot',
} as const;

export const FeedCommentCreateGrid: React.FC<GridProps> = (props) => (
  <Grid
    templateAreas={`
"${gridArea.input} ${gridArea.input} ${gridArea.submit}"
"${gridArea.count} ${gridArea.count} ${gridArea.slot}"
"${gridArea.error} ${gridArea.error} empty"
  `}
    gridTemplateRows={'auto auto auto'}
    gridTemplateColumns={'auto auto 40px'}
    {...props}
  />
);

export const FeedCommentEditGrid: React.FC<GridProps> = (props) => (
  <Grid
    templateAreas={`
"${gridArea.input} ${gridArea.input}"
"${gridArea.slot} ${gridArea.count}"
"${gridArea.error} ${gridArea.error}"
  `}
    gridTemplateRows={'auto auto auto'}
    gridTemplateColumns={'auto auto'}
    {...props}
  />
);

export const FeedCommentGridItemInput: React.FC<GridItemProps> = (props) => (
  <GridItem area={gridArea.input} {...props} />
);
export const FeedCommentGridItemError: React.FC<GridItemProps> = (props) => (
  <GridItem area={gridArea.error} {...props} />
);
export const FeedCommentGridItemSubmit: React.FC<GridItemProps> = (props) => (
  <GridItem area={gridArea.submit} alignSelf={'start'} {...props} />
);
export const FeedCommentGridItemCount: React.FC<GridItemProps> = (props) => (
  <GridItem area={gridArea.count} justifySelf={'end'} {...props} />
);

export const FeedCommentGridItemSlot: React.FC<GridItemProps> = (props) => (
  <GridItem area={gridArea.slot} {...props} />
);
