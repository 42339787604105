import { useAbortAll } from '@rpldy/tus-uploady';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ResponsiveModalProps } from '../../../Layout/ResponsiveModal';
import { DialogModal } from '../../DialogModal/DialogModal';

export type ConfirmLogoutOnUploadDialogProps = {
  confirmLogout: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const ConfirmLogoutOnUploadDialog: React.FC<
  ConfirmLogoutOnUploadDialogProps
> = ({ confirmLogout, onClose, ...rest }) => {
  const { t } = useTranslation(['videoLibrary']);
  const abortAll = useAbortAll();
  const leastDestructiveRef = useRef(null);
  return (
    <DialogModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      headline={t('videoLibrary:heading.LaufendeUploads')}
      text={t('videoLibrary:text.DuHastNochLaufendeUploads')}
      confirmButtonText={t('videoLibrary:button.UploadsVerwerfen')}
      onConfirm={() => {
        abortAll();
        confirmLogout();
      }}
      closeButtonText={t('videoLibrary:button.HochladenFortfuhren')}
      onCloseComplete={onClose}
      {...rest}
    />
  );
};
