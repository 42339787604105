export interface UseCheckboxGroupControlProps {
  /**
   * Name of the form element
   */
  name: string;
}

/**
 * Logic for the checkbox group control
 *
 * @param props
 */

export function useCheckboxGroupControl({
  name,
}: UseCheckboxGroupControlProps) {
  const checkboxGroupName = name;

  return {
    checkboxGroupName,
  };
}

export type UseCheckboxGroupControlReturn = ReturnType<
  typeof useCheckboxGroupControl
>;
