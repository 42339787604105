import {
  Button,
  ButtonProps,
  Heading,
  VStack,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../components/Layout/ResponsiveModal';
import { ExperienceIntentionTagGroup } from '../components/shared/ExperienceIntentionTagGroup/ExperienceIntentionTagGroup';
import { IllustrationBox } from '../components/shared/IllustrationBox/IllustrationBox';
import { IncomeSourceActiveSwitch } from '../components/shared/IncomeSource/IncomeSourceActiveSwitch';
import Markdown from '../components/shared/Markdown/Markdown';
import { SkeletonTipModalContent } from '../components/shared/SkeletonTipModalContent/SkeletonTipModalContent';
import {
  GetIncomeSourceDetailDataDocument,
  GetIncomeSourceSectionDataDocument,
  GetIncomeSourcesDataDocument,
  IncomeSourceWithBodyEntryFragment,
  useGetIncomeSourceDetailDataLazyQuery,
  useMarkIncomeSourceAsReadMutation,
} from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import { useQueryParamState } from '../hooks/useQueryParamState';
import Logger from '../utils/Logger';
import { getApolloContext } from '../utils/apollo';

type IncomeSourceDetailModalContextOptions = {
  modalStructure?: React.ReactNode;
  children?: React.ReactNode;
};

type IncomeSourceDetailModalContext = {
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly incomeSource: Maybe<IncomeSourceWithBodyEntryFragment>;
  readonly action: {
    readonly showIncomeSource: (incomeSourceId: string) => void;
    readonly closeModal: () => void;
  };
};

const queryParamIncomeSourceId = 'incomeSourceId' as const;

export const [, useIncomeSourceDetailModal, incomeSourceDetailModalContext] =
  createContext<IncomeSourceDetailModalContext>({
    name: 'IncomeSourceDetailModalContext',
  });

const IncomeSourceDetailModal = () => {
  const incomeSourceModalCtx = useIncomeSourceDetailModal();
  const { isLoading, incomeSource, isOpen } = incomeSourceModalCtx;
  const { i18n } = useTranslation();
  const apolloContext = getApolloContext(i18n);

  const [markAsRead] = useMarkIncomeSourceAsReadMutation({
    refetchQueries: [
      {
        query: GetIncomeSourceSectionDataDocument,
        context: apolloContext,
      },
      {
        query: GetIncomeSourceDetailDataDocument,
        variables: {
          id: incomeSource?.id ?? '',
        },
        context: apolloContext,
      },
      {
        query: GetIncomeSourcesDataDocument,
        context: apolloContext,
      },
    ],
    onError: (error) => Logger.error(error),
  });

  useEffect(() => {
    if (isLoading || !isOpen || !incomeSource || incomeSource.readAt) {
      return;
    }

    const { id } = incomeSource;
    markAsRead({ variables: { id } }).then();
  }, [markAsRead, incomeSource, isOpen, isLoading]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={incomeSourceModalCtx.action.closeModal}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent maxW={'567px'}>
        <SkeletonTipModalContent isLoading={isLoading}>
          <ResponsiveModalStickyHeaderBox p={0}>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>
          <IllustrationBox image={incomeSource?.headerImage} />
          <ResponsiveModalBodyBox>
            {incomeSource && (
              <VStack gap={4} alignItems="start">
                <ExperienceIntentionTagGroup from={incomeSource} />
                <IncomeSourceActiveSwitch incomeSource={incomeSource} />
                <Heading size={'lg'} mt={4}>
                  {incomeSource?.title}
                </Heading>
                <Markdown markdown={incomeSource?.body} />
              </VStack>
            )}
          </ResponsiveModalBodyBox>
        </SkeletonTipModalContent>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

export const IncomeSourceDetailModalProvider: React.FC<
  IncomeSourceDetailModalContextOptions
> = ({ modalStructure = <IncomeSourceDetailModal />, children }) => {
  const [incomeSourceId, setIncomeSourceId] = useQueryParamState(
    queryParamIncomeSourceId
  );
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: !!incomeSourceId,
  });

  const [refetch, { data, loading }] = useGetIncomeSourceDetailDataLazyQuery({
    fetchPolicy: 'cache-first',
    canonizeResults: true,
    onCompleted: () => onOpen(),
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        //fixme! translate toast message
        description: 'Income Source konnte nicht geladen werden',
      });
      onClose();
    },
    variables: { id: incomeSourceId ?? '' },
  });

  // Only once on mount
  useEffect(() => {
    // load incomeSource from incomeSourceId probably initialized by query parameter
    !!incomeSourceId && refetch({ variables: { id: incomeSourceId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const incomeSource = React.useMemo(
    () => data?.helpcenter.incomeSources?.incomeSourceById,
    [data]
  );

  const action = React.useMemo<IncomeSourceDetailModalContext['action']>(
    () => ({
      showIncomeSource: (id: string) => {
        setIncomeSourceId(id);
        refetch({ variables: { id } }).then();
      },
      closeModal: () => {
        setIncomeSourceId(null);
        onClose();
      },
    }),
    [onClose, setIncomeSourceId, refetch]
  );

  const context = React.useMemo<IncomeSourceDetailModalContext>(
    () => ({
      isLoading: loading,
      isOpen,
      incomeSourceId,
      incomeSource,
      action,
    }),
    [action, loading, isOpen, incomeSourceId, incomeSource]
  );

  return (
    <incomeSourceDetailModalContext.Provider value={context}>
      {children}
      {modalStructure}
    </incomeSourceDetailModalContext.Provider>
  );
};

export const ShowIncomeSourceButton = forwardRef<
  ButtonProps & { incomeSourceId: string },
  'button'
>(({ incomeSourceId, ...boxProps }, ref) => {
  const IncomeSourceModalCtx = useIncomeSourceDetailModal();
  return (
    <Button
      ref={ref}
      isLoading={
        IncomeSourceModalCtx.isLoading && !IncomeSourceModalCtx.incomeSource
      }
      onClick={() =>
        IncomeSourceModalCtx.action.showIncomeSource(incomeSourceId)
      }
      {...boxProps}
    />
  );
});
