import { useField, useFormikContext } from 'formik';
import React from 'react';

import { BaseProps, FormControl } from '../../FormControl';
import {
  RadioOptionButtonGroup,
  RadioOptionButtonGroupProps,
} from './RadioOptionButtonGroup';

export type RadioOptionButtonGroupControlProps = BaseProps & {
  /** List of options pick able by radio buttons */
  options: RadioOptionButtonGroupProps['options'];
  radioGroupProps?: Omit<RadioOptionButtonGroupProps, 'options'>;
};

export const RadioOptionButtonGroupControl: React.FC<
  RadioOptionButtonGroupControlProps
> = (props) => {
  const { name, label, options, children, radioGroupProps, ...rest } = props;
  const [field] = useField(name);

  const { setFieldValue } = useFormikContext();
  const handleChange = (value: string) => {
    setFieldValue(name, value);
  };

  return (
    <FormControl name={name} label={label} {...rest}>
      <RadioOptionButtonGroup
        {...radioGroupProps}
        {...field}
        w={'full'}
        role="group"
        onChange={handleChange}
        options={options}
      />
    </FormControl>
  );
};
