import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  UseDisclosureProps,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  NoticeModal,
  NoticeModalConfig,
} from '../../../../../../components/shared/NoticeModal/NoticeModal';
import { ChevronRightIcon } from '../../../../../../components/shared/icon/ChevronRightIcon';

export const PaymentRejectedAlert: React.FC<
  {
    text?: string;
    noticeModalConfig?: NoticeModalConfig | null;
    disclosureProps?: UseDisclosureProps;
  } & AlertProps
> = ({ text, noticeModalConfig, disclosureProps, ...alertProps }) => {
  const { t } = useTranslation(['payout']);
  const disc = useDisclosure(disclosureProps);
  const isInteractive = !!noticeModalConfig || !!alertProps.onClick;
  return (
    <Alert
      status={'error'}
      bg={'error.100'}
      cursor={isInteractive ? 'pointer' : 'unset'}
      onClick={isInteractive ? disc.onOpen : undefined}
      {...alertProps}
    >
      <AlertIcon />
      <AlertTitle flexGrow={1} fontWeight={'500'}>
        {text ?? t('payout:text.DeineAnderungenWurdenAbgelehnt')}
      </AlertTitle>
      {isInteractive && <ChevronRightIcon />}
      {!!noticeModalConfig && (
        <NoticeModal
          isOpen={disc.isOpen}
          onClose={disc.onClose}
          {...noticeModalConfig}
        />
      )}
    </Alert>
  );
};
