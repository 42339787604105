import {
  RadioGroupProps,
  Wrap,
  WrapItem,
  WrapProps,
  isStyleProp,
  useRadioGroup,
} from '@chakra-ui/react';
import { objectFilter } from '@chakra-ui/utils';

import { OptionButton } from '../../OptionButton';
import { RadioOptionButtonProps } from '../../OptionButton/OptionButton';

export type RadioOptionButtonGroupProps = {
  options: Array<Omit<RadioOptionButtonProps, 'multiple'>>;
} & Omit<RadioGroupProps, 'children'> &
  Pick<WrapProps, 'spacing' | 'spacingY' | 'spacingX'>;

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export function RadioOptionButtonGroup({
  options,
  ...radioGroupProps
}: RadioOptionButtonGroupProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    ...radioGroupProps,
  });

  const styleProps = objectFilter(radioGroupProps, (_, prop) =>
    isStyleProp(prop)
  );

  const group = getRootProps();

  const { spacing, spacingX, spacingY } = radioGroupProps;
  const wrapProps = { spacing, spacingX, spacingY };

  return (
    <Wrap {...styleProps} {...group} {...wrapProps}>
      {options.map((value) => {
        // we work only with string here, so we don't need to handle the event case
        // @ts-ignore
        const radio = getRadioProps(value);
        return (
          <WrapItem key={value.value}>
            <OptionButton {...radio} multiple={false}>
              {value.children}
            </OptionButton>
          </WrapItem>
        );
      })}
    </Wrap>
  );
}
