import { Button, FormControl, Heading, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { usePasswordResetMutation } from '../../../../generated/graphql';
import { createStringValidationSchema } from '../../../../utils/validation';
import { PasswordResetShared as S } from '../shared';

const emailInputName = 'email';
const modelnameInputName = 'modelname';

interface FormValues {
  [emailInputName]: string;
  [modelnameInputName]: string;
}

const PasswordResetForm: React.FC<{ showHint: () => void }> = ({
  showHint,
}) => {
  const { t } = useTranslation(['passwordReset']);

  const [triggerPasswordReset] = usePasswordResetMutation({
    fetchPolicy: 'no-cache',
  });

  const customHandleSubmit: SubmitHandler<FormValues> = (data) => {
    triggerPasswordReset({
      variables: {
        email: data[emailInputName],
        modelname: data[modelnameInputName] || null,
      },
    });

    showHint();
  };

  const passwordResetSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [emailInputName]: createStringValidationSchema({
          isEmail: true,
        }),
        [modelnameInputName]: Yup.string()
          .notRequired()
          .min(4)
          .nullable()
          .transform((value) => (!!value ? value : null)),
      }),
    []
  );

  const hookForm = useForm({
    defaultValues: { [emailInputName]: '', [modelnameInputName]: '' },
    resolver: yupResolver(passwordResetSchema),
    mode: 'all',
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = hookForm;

  return (
    <div>
      <Heading size={'xl'} mb={'4'}>
        {t('passwordReset:headline')}
      </Heading>
      <S.Caption mb="8" children={t('passwordReset:hint')} />
      <FormProvider {...hookForm}>
        <FormControl as={'form'} onSubmit={handleSubmit(customHandleSubmit)}>
          <VStack spacing={'2'}>
            <ClearableInputControl
              label={t('passwordReset:label.EMail')}
              name={emailInputName}
              placeholder={t('passwordReset:placeholder.EMailEingeben')}
              autoComplete={'off'}
            />
            <ClearableInputControl
              label={t('passwordReset:label.Modelname')}
              isRequired={false}
              name={modelnameInputName}
              placeholder={t('passwordReset:placeholder.ModelnameEingeben')}
              autoComplete={'off'}
            />
            <Button
              mt="8"
              type="submit"
              variant={'solid'}
              isLoading={isSubmitting}
              children={t('passwordReset:button.PasswortZurucksetzen')}
            />
          </VStack>
        </FormControl>
      </FormProvider>
    </div>
  );
};

export default PasswordResetForm;
