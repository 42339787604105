import { useTranslation } from 'react-i18next';

import illustrationImageAccepted from '../../../../assets/images/shared/model-profile-verified.svg';
import { ModelProfileSimpleInfoPage } from '../../layout/ModelProfileSimpleInfoPage/ModelProfileSimpleInfoPage';

export const ModelProfileCompleteProfileAcceptedImageInfoPage: React.FC =
  () => {
    const { t } = useTranslation(['modelProfile']);
    return (
      <ModelProfileSimpleInfoPage
        heading={t('modelProfile:heading.DeinBildWurdeUberpruft')}
        description={t(
          'modelProfile:text.UmDeineSeiteZuErstellenFehlenNochEinPaarAngaben'
        )}
        buttonText={t('modelProfile:button.ProfilVervollstandigen')}
        image={illustrationImageAccepted}
        imgAlt={t('modelProfile:imgAlt.ModelAvatarWurdeAkzeptiert')}
      />
    );
  };
