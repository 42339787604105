import React from 'react';
import { useTranslation } from 'react-i18next';

export const useNumberShortFormatter = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return React.useCallback(
    (number: number, digits = 1) => {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: lang === 'de' ? 'Mio.' : 'M' },
        { value: 1e9, symbol: lang === 'de' ? 'Mrd.' : 'B' },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return number >= item.value;
        });
      return item
        ? (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : '0';
    },
    [lang]
  );
};
