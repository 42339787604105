import { LocalStorageKeys } from '../types';

/**
 * Service that handles local storage. Ensures consistent keys
 * @use LocalStorage.add(...)
 */
export class LocalStorage {
  /**
   * @param {LocalStorageKeys} key OnboardingV1Username of key
   * @param {string | number | boolean} value Value of item
   */
  static add(key: LocalStorageKeys, value: string | number | boolean) {
    localStorage.setItem(key, String(value));
  }

  /**
   * Removes key and value from local storage
   * @param key OnboardingV1Username of key
   */
  static remove(key: LocalStorageKeys) {
    localStorage.removeItem(key);
  }

  /**
   * Returns string value of given key
   * @param key OnboardingV1Username of key
   */
  static getString(key: LocalStorageKeys): string | null {
    const value = localStorage.getItem(key);
    return value === null ? null : value;
  }

  /**
   * Returns number value of given key
   * @param key OnboardingV1Username of key
   */
  static getNumber(key: LocalStorageKeys): number | null {
    const value = localStorage.getItem(key);
    return value === null ? null : Number.parseFloat(value);
  }

  /**
   * Returns boolean value of given key
   * @param key OnboardingV1Username of key
   */
  static getBoolean(key: LocalStorageKeys): boolean | null {
    const value = localStorage.getItem(key);
    return value === null ? null : value === 'true';
  }
}
