import { Box, FormErrorIcon } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @example Using a custom icon, make it yourself with:
 *  import { FormErrorIcon } from '@chakra-ui/react';
 *  // ...
 *  <>
 *     <FormErrorIcon as={CustomIcon} />
 *     {text}
 *  </>
 */
export const ErrorMessageWithLeadingIcon: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <>
    <FormErrorIcon />
    {children}
  </>
);
ErrorMessageWithLeadingIcon.displayName = 'ErrorMessageWithLeadingIcon';

export const CheckboxErrorMessageWithLeadingIcon: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => (
  <Box pb={4}>
    <FormErrorIcon />
    {children}
  </Box>
);

export const MandatoryErrorMessageForCheckBox = () => {
  const { t } = useTranslation(['validation']);
  return (
    <CheckboxErrorMessageWithLeadingIcon
      children={t('validation:error.Pflichtfeld')}
    />
  );
};

export const RequiredErrorMessage = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.BitteAusfullen')}
    />
  );
};
RequiredErrorMessage.displayName = 'RequiredErrorMessage';

export const UniquePasswordErrorMessage = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t(
        'validation:error.DasNeuePasswortDarfNichtDasselbeSeinWieDasAlte'
      )}
    />
  );
};
UniquePasswordErrorMessage.displayName = 'UniquePasswordErrorMessage';

export const PasswordMatchErrorMessage = () => {
  const { t } = useTranslation(['signUpPage']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('signUpPage:formValidation.passwordsDoNotMatch')}
    />
  );
};
PasswordMatchErrorMessage.displayName = 'PasswordMatchErrorMessage';

export const EmptyOptionCantBeMixedErrorMessage: React.FC<{
  optionLabel: string;
}> = ({ optionLabel: option }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t(
        'validation:error.DieOptionOptionDarfNichtMitAnderenVermischtSein',
        { option }
      )}
    />
  );
};
EmptyOptionCantBeMixedErrorMessage.displayName =
  'EmptyOptionCantBeMixedErrorMessage';

export const MinChoicesErrorMessage: React.FC<{
  choices: number;
}> = ({ choices: count }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.BitteWahleMindestensCountOptionen', {
        count,
      })}
    />
  );
};
MinChoicesErrorMessage.displayName = 'MinChoicesErrorMessage';

export const MaxChoicesErrorMessage: React.FC<{
  choices: number;
}> = ({ choices: count }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.BitteWahleHochstensCountOptionen', {
        count,
      })}
    />
  );
};
MaxChoicesErrorMessage.displayName = 'MaxChoicesErrorMessage';

export const MinOneFieldFilledErrorMessage: React.FC = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.BitteFulleMindestensEinFeldAus')}
    />
  );
};
MinOneFieldFilledErrorMessage.displayName = 'MinOneFieldFilledErrorMessage';

export const TooYoungErrorMessage: React.FC<{
  years: number;
}> = ({ years }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.DuMusstMindestensYearsJahreAltSein', {
        years,
      })}
    />
  );
};
TooYoungErrorMessage.displayName = 'TooYoungErrorMessage';

export const TooOldErrorMessage: React.FC<{
  years: number;
}> = ({ years }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.DuDarfstHochstensYearsJahreAltSein', {
        years,
      })}
    />
  );
};
TooOldErrorMessage.displayName = 'TooOldErrorMessage';

export const RequiredWithLabelReferenceErrorMessage: React.FC<{
  labeled: string;
}> = ({ labeled }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.LabeledMussAusgefulltWerden', { labeled })}
    />
  );
};
RequiredWithLabelReferenceErrorMessage.displayName =
  'RequiredWithLabelReferenceErrorMessage';

export const RequiredUploadErrorMessage = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.BitteHochladen')}
    />
  );
};
RequiredUploadErrorMessage.displayName = 'RequiredUploadErrorMessage';

export const MinLengthErrorMessage: React.FC<{ min: number }> = ({ min }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.VerwendeMindestensMinZeichen', { min })}
    />
  );
};
MinLengthErrorMessage.displayName = 'MinLengthErrorMessage';

export const MaxCharacterCountErrorMessage: React.FC = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.MaxZeichenanzahl')}
    />
  );
};
MaxCharacterCountErrorMessage.displayName = 'MaxCharacterCountErrorMessage';

export const MissingTextErrorMessage: React.FC = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.FehlenderText')}
    />
  );
};
MissingTextErrorMessage.displayName = 'MissingTextErrorMessage';

export const MaxLengthErrorMessage: React.FC<{ max: number }> = ({ max }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.VerwendeHochstensMaxZeichen', { max })}
    />
  );
};
MaxLengthErrorMessage.displayName = 'MaxLengthErrorMessage';

export const MinValueErrorMessage: React.FC<{ min: number }> = ({ min }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.DarfMinNichtUnterschreiten', { min })}
    />
  );
};
MinValueErrorMessage.displayName = 'MinValueErrorMessage';

export const MaxValueErrorMessage: React.FC<{ max: number }> = ({ max }) => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.DarfMaxNichtUberschreiten', { max })}
    />
  );
};
MaxValueErrorMessage.displayName = 'MaxValueErrorMessage';

export const MinDateErrorMessage: React.FC<{ min: string | Date }> = ({
  min,
}) => {
  const { t } = useTranslation(['validation']);

  const minDate = new Date(min);

  const text = t('validation:error.FruhestensMinDateErlaubt', {
    minDate: minDate,
  });

  return <ErrorMessageWithLeadingIcon children={text} />;
};
MinDateErrorMessage.displayName = 'MinDateErrorMessage';

export const MaxDateErrorMessage: React.FC<{ max: string | Date }> = ({
  max,
}) => {
  const { t } = useTranslation(['validation']);

  const maxDate = new Date(max);

  const text = t('validation:error.SpatestensMaxDateErlaubt', {
    maxDate: maxDate,
  });

  return <ErrorMessageWithLeadingIcon children={text} />;
};
MaxDateErrorMessage.displayName = 'MaxDateErrorMessage';

export const EmailMalformedErrorMessage: React.FC = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.EmailFormatierungUnbekannt')}
    />
  );
};
EmailMalformedErrorMessage.displayName = 'EmailMalformedErrorMessage';

export const UnClearableErrorMessage: React.FC = () => {
  const { t } = useTranslation(['validation']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('validation:error.MussAusgefulltSein')}
    />
  );
};
UnClearableErrorMessage.displayName = 'UnClearableErrorMessage';
