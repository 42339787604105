import { Box } from '@chakra-ui/react';
import * as React from 'react';

export const FullscreenPageLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box height={'var(--full-h, 100vh)'} overflow={'hidden'}>
      {children}
    </Box>
  );
};
