import * as React from 'react';

import { useScrollToElementOnHashMatch } from '../../hooks/useScrollToElementOnHashMatch';
import {
  ScrollToTargetProps,
  UrlFragmentScrollToTarget,
} from './UrlFragmentScrollToTarget';

export const ScrollToTargetInline: React.FC<ScrollToTargetProps> = ({
  id,
  duration = undefined,
  children,
}) => {
  const scrollTargetId = id;
  const ref = useScrollToElementOnHashMatch<HTMLSpanElement>({
    scrollTargetId,
    duration,
  });

  return (
    <UrlFragmentScrollToTarget
      id={scrollTargetId}
      ref={ref}
      style={{
        // give some headroom to the top navbar
        scrollMarginTop: '100px',
        scrollBehavior: 'smooth',
      }}
    >
      {children}
    </UrlFragmentScrollToTarget>
  );
};
