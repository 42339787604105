import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';

import { ErrorMessageWithLeadingIcon } from '../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import {
  MediaPictureStatusEnum,
  ProfileFieldValidationSetFragment,
  ProfileFieldsEnum,
} from '../../../generated/graphql';
import { ImageStatusEnum } from './types';

export const getImageStatus = (
  status?: MediaPictureStatusEnum
): ImageStatusEnum => {
  let imageStatus: ImageStatusEnum = ImageStatusEnum.pending;
  if (status === MediaPictureStatusEnum.Ok) {
    imageStatus = ImageStatusEnum.ok;
  } else if (status === MediaPictureStatusEnum.Rejected) {
    imageStatus = ImageStatusEnum.rejected;
  }

  return imageStatus;
};

export const getFieldInitialErrors = (
  collectionError: Maybe<ProfileFieldValidationSetFragment>,
  unknownError: string,
  isTranslated?: boolean
) => {
  return collectionError?.field?.reduce((acc, field) => {
    if (field.isValid) {
      return acc;
    }

    const fieldName = field.name;

    // Special multilang handling
    if (field?.__typename === 'ProfileFieldTypeValidationMultiLangString') {
      if (isTranslated) {
        return {
          ...acc,
          [fieldName]: (
            <ErrorMessageWithLeadingIcon>
              {field.multiLangErrors?.[0]?.message ?? unknownError}
            </ErrorMessageWithLeadingIcon>
          ),
        };
      }
      const multiLangErrors = field?.multiLangErrors ?? [];
      // todo: consider impact of multiple entries per lang for now last error of a lang wins
      const errorsMessages = multiLangErrors.reduce(
        (multiLangAcc, multiLangError) => ({
          ...multiLangAcc,
          [`${fieldName}-${multiLangError.lang}`]: (
            <ErrorMessageWithLeadingIcon>
              {multiLangError.message ?? unknownError}
            </ErrorMessageWithLeadingIcon>
          ),
        }),
        {}
      );

      return { ...acc, ...errorsMessages };
    }

    return {
      ...acc,
      [fieldName]: (
        <ErrorMessageWithLeadingIcon>
          {field.errors?.[0]?.message ?? unknownError}
        </ErrorMessageWithLeadingIcon>
      ),
    };
  }, {} as Record<ProfileFieldsEnum, React.ReactNode>);
};

export const useFieldInitialErrors = (
  collectionError: Maybe<ProfileFieldValidationSetFragment>,
  unknownError: string,
  isTranslated?: boolean
) => {
  return React.useMemo(() => {
    return getFieldInitialErrors(collectionError, unknownError, isTranslated);
  }, [collectionError, unknownError, isTranslated]);
};

const getFieldInitialTouched = (
  collectionError: Maybe<ProfileFieldValidationSetFragment>
) => {
  return collectionError?.field?.reduce((acc, field) => {
    if (field.isValid) {
      return acc;
    }
    const fieldName = field.name;

    // Special multilang handling
    if (field?.__typename === 'ProfileFieldTypeValidationMultiLangString') {
      const multiLangErrors = field?.multiLangErrors ?? [];
      // todo: consider impact of multiple entries per lang for now last error of a lang wins
      const errorsMessages = multiLangErrors.reduce(
        (multiLangAcc, multiLangError) => ({
          ...multiLangAcc,
          [`${fieldName}-${multiLangError.lang}`]: !!multiLangError.message,
        }),
        {}
      );

      return { ...acc, ...errorsMessages };
    }

    return {
      ...acc,
      [fieldName]: true,
    };
  }, {} as Record<ProfileFieldsEnum, boolean>);
};

export const useFieldInitialTouched = (
  collectionError: Maybe<ProfileFieldValidationSetFragment>
) => {
  return React.useMemo(() => {
    return getFieldInitialTouched(collectionError);
  }, [collectionError]);
};
