import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  HStack,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { EncodedControlledCharacterCount } from '../../EncodedControlledCharacterCount/EncodedControlledCharacterCount';

export const TextAreaWithMaxCharCount: React.FC<{
  name: string;
  label: React.ReactNode;
  labelProps?: FormLabelProps;
  textAriaProps?: TextareaProps;
  maxCharacterCount?: number;
}> = ({ name, label, maxCharacterCount, labelProps, textAriaProps = {} }) => {
  const { register, getFieldState, formState } = useFormContext();
  const { error, isTouched } = getFieldState(name, formState);
  const value = useWatch({ name });
  const { ref } = register(name);
  const textareaRef = React.useRef<HTMLTextAreaElement>();

  React.useLayoutEffect(() => {
    const adjustTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };
    textareaRef.current?.addEventListener('input', adjustTextareaHeight);
  }, [textareaRef]);

  return (
    <FormControl isInvalid={error && isTouched}>
      <FormLabel children={label} pb={3} {...labelProps} />
      <Textarea
        rows={3}
        resize={'none'}
        {...textAriaProps}
        {...register(name)}
        ref={mergeRefs(ref, textareaRef)}
      />
      <HStack minH={'8'}>
        <Box flexGrow={1}>
          {error && isTouched && (
            <FormErrorMessage>{(error?.message as any) ?? ''}</FormErrorMessage>
          )}
        </Box>
        <EncodedControlledCharacterCount
          value={value}
          maxCharacterCount={maxCharacterCount}
        />
      </HStack>
    </FormControl>
  );
};
