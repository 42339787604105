import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { FormikHelpers } from 'formik/dist/types';
import React from 'react';

import { noop } from '../../../utils';
import { EncodedControlledCharacterCount } from '../EncodedControlledCharacterCount/EncodedControlledCharacterCount';
import {
  FeedCommentCreateGrid,
  FeedCommentGridItemCount,
  FeedCommentGridItemError,
  FeedCommentGridItemInput,
  FeedCommentGridItemSubmit,
} from './FeedCommentGrid';
import { FeedCommentTextarea } from './FeedCommentTextarea';
import {
  CommentAvatar,
  CommentBox,
  CommentContent,
  CommentUsername,
  FeedCommentSubmitIconButton,
} from './shared';
import {
  CommentTextFormikValues,
  useCommentTextFormik,
} from './useCommentTextFormik';

export type CommentCreateProps = {
  avatarSrc?: string;
  showAvatar?: boolean;
  username?: string;
  initialText?: string;
  repliesToCommentId?: string;
  commentAutoFocus?: boolean;
  placeholder?: string;
  onClose: () => void;
  onSubmit: (
    values: CommentTextFormikValues,
    formikHelpers: FormikHelpers<CommentTextFormikValues>
  ) => void | Promise<void>;
  registerOpenComment: () => void;
};

export const CommentCreate: React.FC<CommentCreateProps> = ({
  initialText = '',
  placeholder,
  avatarSrc,
  showAvatar = true,
  username,
  onSubmit,
  commentAutoFocus = true,
  registerOpenComment,
}) => {
  const {
    currentCharacterCount,
    currentValue,
    maxCharacterCount,
    textareaFieldError,
    textareaFieldProps,
    formik,
  } = useCommentTextFormik({
    initialText,
    onSubmit,
  });

  const isDirty = formik.dirty;

  React.useEffect(
    () => (!isDirty ? noop : registerOpenComment()),
    [isDirty, registerOpenComment]
  );

  return (
    <CommentBox>
      {showAvatar && <CommentAvatar src={avatarSrc} />}
      <CommentContent>
        {showAvatar && <CommentUsername children={username} />}
        <FormControl isInvalid={!!textareaFieldError}>
          <FeedCommentCreateGrid>
            <FeedCommentGridItemInput>
              <FeedCommentTextarea
                placeholder={placeholder}
                commentAutoFocus={commentAutoFocus}
                {...textareaFieldProps}
              />
            </FeedCommentGridItemInput>
            <FeedCommentGridItemError>
              <FormErrorMessage>{textareaFieldError}</FormErrorMessage>
            </FeedCommentGridItemError>
            <FeedCommentGridItemCount>
              <EncodedControlledCharacterCount
                value={currentValue}
                maxCharacterCount={maxCharacterCount}
              />
            </FeedCommentGridItemCount>
            <FeedCommentGridItemSubmit>
              <FeedCommentSubmitIconButton
                isDisabled={!formik.isValid || currentCharacterCount === 0}
                isLoading={formik.isSubmitting}
                onClick={formik.submitForm}
              />
            </FeedCommentGridItemSubmit>
          </FeedCommentCreateGrid>
        </FormControl>
      </CommentContent>
    </CommentBox>
  );
};
