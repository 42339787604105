import { ApolloError } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { DialogModal } from '../../components/shared/DialogModal/DialogModal';
import { ConfirmMediaDeletionDialog } from '../../components/shared/dialogs/ConfirmMediaDeletionDialog/ConfirmMediaDeletionDialog';
import {
  FeedNodeFragment,
  useDeleteFeedPostMutation,
  useDeleteImageFeedPostMutation,
  useGetFeedPostLazyQuery,
  usePinFeedPostMutation,
  useReplaceImageFeedPostUploadUrlLazyQuery,
  useUnpinFeedPostMutation,
} from '../../generated/feed';
import { useCallbackRegister } from '../../hooks/useCallbackRegister';
import { createContext } from '../../hooks/useContext';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { routes } from '../../routes/routesConfig';
import { scrollToElement } from '../../utils/utils';
import { useAuth } from '../AuthProvider';
import { ReplaceImageHandlerProps } from '../FeedService/FeedService';
import {
  UploadJobEntityType,
  useUploadManager,
} from '../UploadManagerProvider';

export interface FeedServiceProviderProps {
  children?: React.ReactNode;
}

export interface FeedPostServiceContextAction {
  deleteClickHandler: (postId: string) => void;
  deletePostHandler: () => void;
  deleteImageHandler: (imageId: string) => void;
  replaceImageHandler: (props: ReplaceImageHandlerProps) => void;
  pinPostHandler: (postId?: string) => void;
  pinClickHandler: (postId: string, isPinned: boolean) => void;
  removePinHandler: () => void;
  deletePost: (postId: string) => void;
  loadSinglePost: (postId: string) => void;
}

export interface FeedPostServiceContext {
  action: FeedPostServiceContextAction;
  post: FeedNodeFragment;
  loading: boolean;
  registerRefresh: (fn: () => void) => () => boolean;
  error: ApolloError | undefined;
  isPostOwnedByUser: () => boolean;
  isPostEdited: boolean;
}
export const [, useFeedPostService, feedPostServiceContext] =
  createContext<FeedPostServiceContext>({
    name: 'FeedPostServiceContext',
    errorMessage:
      'useFeedService: `feedPostServiceContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export const FeedPostServiceProvider: React.FC<FeedServiceProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation(['general', 'feed']);
  const history = useHistoryWithStorage();
  const { authUser } = useAuth();
  const uploadManagerCtx = useUploadManager();

  const [isPostEdited, setIsPostEdited] = React.useState<boolean>(false);

  const [fetch, { data, loading, error, refetch }] = useGetFeedPostLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const post = React.useMemo(() => {
    return data?.post ?? ({} as FeedNodeFragment);
  }, [data]);

  const loadSinglePost = React.useCallback(
    (postId: string) => {
      if (postId) {
        fetch({ variables: { postId: postId } });
      }
    },
    [fetch]
  );

  const postModelId = data?.post?.model.id;
  const userId = authUser?.userId;

  const isPostOwnedByUser = React.useCallback(() => {
    return postModelId === userId;
  }, [postModelId, userId]);

  const [registerRefresh] = useCallbackRegister();

  const [targetDeletePostId, setTargetDeletePostId] = React.useState('');
  const [deleteFeedPostMutation] = useDeleteFeedPostMutation();
  const [deleteImageMutation] = useDeleteImageFeedPostMutation();
  const [replaceImageUploadUrl] = useReplaceImageFeedPostUploadUrlLazyQuery();

  const postDeletionModal = useDisclosure();

  const unguardedDeleteClickHandler = React.useCallback(
    (postId: string) => {
      deleteFeedPostMutation({ variables: { postId: postId } })
        .then(() => {
          issueChakraToast({
            description: t('feed:toast.DerPostWurdeErfolgreichGeloescht'),
            status: 'success',
          });
          history.push(routes.feed.path);
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.BeimLoeschenIstEinFehlerAufgetreten'),
            status: 'error',
          });
        });
    },
    [deleteFeedPostMutation, t, history]
  );

  const unguardedDeleteImageHandler = React.useCallback(
    (imageId: string) => {
      deleteImageMutation({ variables: { photoId: imageId } })
        .then(() => {
          issueChakraToast({
            description: t('feed:toast.DasFotoWurdeErfolgreichGeloescht'),
            status: 'success',
          });
          history.push(routes.home.path);
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.BeimLoeschenIstEinFehlerAufgetreten'),
            status: 'error',
          });
        });
    },
    [deleteImageMutation, history, t]
  );

  const unguardedReplaceImageHandler = React.useCallback(
    (props: ReplaceImageHandlerProps) => {
      const { postId, photoId, mediaFile: photo } = props;
      replaceImageUploadUrl({
        variables: { postId: postId, photoId: photoId },
      })
        .then((data) => {
          const uploadUrl = data.data?.uploadUrl;

          if (uploadUrl) {
            const uploadJobPromise = uploadManagerCtx.action.queueUploadJob({
              entity: {
                type: UploadJobEntityType.IMAGE,
                id: postId,
              },
              payload: {
                thumbnail: photo,
                mediaFiles: [
                  {
                    uploadUrl,
                    blob: photo,
                  },
                ],
              },
            });

            // Wait for the upload to finish before deleting the old image
            if (uploadJobPromise instanceof Promise) {
              uploadJobPromise.then(() => {
                deleteImageMutation({ variables: { photoId: photoId } })
                  .then(() => {
                    issueChakraToast({
                      description: t(
                        'feed:toast.DasFotoWurdeErfolgreichErsetzt'
                      ),
                      status: 'success',
                    });
                  })
                  .catch(() => {
                    issueChakraToast({
                      description: t(
                        'feed:toast.DasZuErsetzendeFotoKonnteNichtGeloschtWerden'
                      ),
                      status: 'error',
                    });
                  });
              });
            }
          }
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.DasFotoKonnteNichtErsetztWerden'),
            status: 'error',
          });
        })
        .finally(() => {
          //for some reason BE is not quick enough to update the post
          setTimeout(() => {
            setIsPostEdited(false);
            window.location.reload();
          }, 5000);
        });
    },
    [deleteImageMutation, replaceImageUploadUrl, t, uploadManagerCtx.action]
  );

  const replaceImageHandler = React.useCallback(
    (props: ReplaceImageHandlerProps) => {
      setIsPostEdited(true);
      unguardedReplaceImageHandler(props);
    },
    [unguardedReplaceImageHandler]
  );

  const deleteClickHandler = React.useCallback(
    (postId: string) => {
      setTargetDeletePostId(postId);
      postDeletionModal.onOpen();
    },
    [postDeletionModal]
  );

  const deletePostHandler = React.useCallback(() => {
    postDeletionModal.onClose();
    unguardedDeleteClickHandler(targetDeletePostId);
  }, [postDeletionModal, targetDeletePostId, unguardedDeleteClickHandler]);

  const deleteImageHandler = React.useCallback(
    (imageId: string) => {
      postDeletionModal.onClose();
      unguardedDeleteImageHandler(imageId);
    },
    [postDeletionModal, unguardedDeleteImageHandler]
  );

  const [pinFeedPostMutation] = usePinFeedPostMutation();
  const [unpinFeedPostMutation] = useUnpinFeedPostMutation();
  const [targetPinPostId, setTargetPinPostId] = React.useState('');
  const feedPostContainerId = 'feedPostContainer';

  const pinModal = useDisclosure();
  const unpinModal = useDisclosure();

  const pinPostHandler = React.useCallback(
    (postId?: string) => {
      if (pinModal.isOpen) {
        pinModal.onClose();
      }

      pinFeedPostMutation({ variables: { postId: postId ?? targetPinPostId } })
        .then(() => {
          refetch()
            .then(() => {
              scrollToElement(feedPostContainerId);
              issueChakraToast({
                description: t('feed:toast.SuccessfulPinned'),
                status: 'success',
              });
            })
            .catch(() => {
              issueChakraToast({
                description: t(
                  'feed:toast.BeimNeuladenDerPostsIstEinFehlerAufgetreten'
                ),
                status: 'error',
              });
            });
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.BeimPinnenIstEinFehlerAufgetreten'),
            status: 'error',
          });
        });
    },
    [pinModal, pinFeedPostMutation, targetPinPostId, refetch, t]
  );

  const pinClickHandler = React.useCallback(
    (postId: string, isPinned: boolean) => {
      setTargetPinPostId(postId);

      if (isPinned) {
        unpinModal.onOpen();
        return;
      }

      if (post?.pinned) {
        pinModal.onOpen();
        return;
      }

      pinPostHandler(postId);
    },
    [post?.pinned, pinPostHandler, unpinModal, pinModal]
  );

  const removePinHandler = React.useCallback(() => {
    unpinModal.onClose();

    unpinFeedPostMutation({ variables: { postId: targetPinPostId } })
      .then(() => {
        refetch()
          .then(() => {
            scrollToElement(feedPostContainerId);
          })
          .catch(() => {
            issueChakraToast({
              description: t(
                'feed:toast.BeimNeuladenDerPostsIstEinFehlerAufgetreten'
              ),
              status: 'error',
            });
          });
      })
      .catch(() => {
        issueChakraToast({
          description: t('feed:toast.BeimPinnenIstEinFehlerAufgetreten'),
          status: 'error',
        });
      });
  }, [refetch, t, targetPinPostId, unpinFeedPostMutation, unpinModal]);

  const action = React.useMemo<FeedPostServiceContextAction>(() => {
    return {
      deletePost: unguardedDeleteClickHandler,
      deleteClickHandler,
      deletePostHandler,
      deleteImageHandler,
      replaceImageHandler,
      pinPostHandler,
      pinClickHandler,
      removePinHandler,
      loadSinglePost,
    };
  }, [
    unguardedDeleteClickHandler,
    deleteClickHandler,
    deletePostHandler,
    deleteImageHandler,
    replaceImageHandler,
    pinPostHandler,
    pinClickHandler,
    removePinHandler,
    loadSinglePost,
  ]);

  const context = React.useMemo<FeedPostServiceContext>(() => {
    return {
      action,
      post,
      loading,
      registerRefresh,
      error,
      isPostOwnedByUser,
      isPostEdited,
    };
  }, [
    action,
    error,
    isPostEdited,
    isPostOwnedByUser,
    loading,
    post,
    registerRefresh,
  ]);

  return (
    <feedPostServiceContext.Provider value={context}>
      {children}

      <ConfirmMediaDeletionDialog
        mediaType={'post'}
        isOpen={postDeletionModal.isOpen}
        deleteMedia={deletePostHandler}
        onClose={postDeletionModal.onClose}
      />

      <DialogModal
        isOpen={pinModal.isOpen!}
        headline={t('feed:heading.BestehendenPinErsetzen')}
        text={t(
          'feed:text.DuHastBereitsEinenBeitragGepinntMochtestDuIhnDurchDiesenErsetzen'
        )}
        confirmButtonText={t('feed:button.PinErsetzen')}
        closeButtonText={t('feed:button.Abbrechen')}
        onConfirm={() => pinPostHandler()}
        onClose={pinModal.onClose}
      />

      <DialogModal
        isOpen={unpinModal.isOpen}
        headline={t('feed:heading.PinEntfernen')}
        text={t(
          'feed:text.DerBeitragWirdDannNichtMehrGanzObenInDeinemFeedAngezeigt'
        )}
        confirmButtonText={t('feed:label.PinEntfernen')}
        closeButtonText={t('feed:button.Abbrechen')}
        onConfirm={removePinHandler}
        onClose={unpinModal.onClose}
      />

      {/* modal order */}
    </feedPostServiceContext.Provider>
  );
};
