import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useRef } from 'react';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';

export type VideoLibraryEntryDialogProps = {
  // videoId: string;
  // deleteVideo: (videoId: string) => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const VideoLibraryEntryDialog: React.FC<
  VideoLibraryEntryDialogProps
> = ({ onClose, ...rest }) => {
  // const { t } = useTranslation(['general']);
  const leastDestructiveRef = useRef(null);

  return (
    <ResponsiveModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      {...rest}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <Heading size="md" as="h2" children={'Video Einstellungen'} />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox py={0}>
          <Text>{}</Text>
        </ResponsiveModalBodyBox>
        <ResponsiveModalStickyFooterBox>
          <VStack minH="5.5rem">
            <Button
              width={'full'}
              variant={'solid'}
              children={'Video löschen'}
              onClick={() => {}}
            />
            <Button
              width={'full'}
              children={'Abbrechen'}
              onClick={onClose}
              ref={leastDestructiveRef}
            />
          </VStack>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
