import {
  Section,
  SectionBody,
  SectionDescription,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Help } from '@campoint/odi-ui-icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import Markdown from '../../../../components/shared/Markdown/Markdown';
import { SupportCard } from '../../../../components/shared/cards/SupportCard/SupportCard';
import { HelpcenterFaqEntry } from '../../../../generated/graphql';

type HelpCenterSectionProps = {
  entries: Maybe<HelpcenterFaqEntry>[];
};
export const HelpCenterSection: React.FC<HelpCenterSectionProps> = ({
  entries = [],
}) => {
  const { t } = useTranslation(['home']);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionCenterContainer>
            <SectionTitleRow>
              <SectionIcon as={Help} />
              <SectionTitle children={t('home:heading.Fragen')} />
            </SectionTitleRow>
            <SectionDescription
              children={t(
                'home:text.HaufigGestellteFragenAmAnfangDerVXModelsKarriere'
              )}
            />
          </SectionCenterContainer>
        </SectionHeader>
        <SectionBody p={isMobile ? 0 : 4}>
          <SectionCenterContainer
            divider={<Divider color={'steel'} w={'full'} />}
          >
            <Accordion allowMultiple>
              {entries.map((item, index) => (
                <AccordionItem key={`list-item-${index}`}>
                  <AccordionButton py={'6'}>
                    <Box as={'span'} flex={'1'} textAlign={'left'}>
                      {item?.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <Markdown markdown={item?.answer} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </SectionCenterContainer>
        </SectionBody>
        <SectionFooter>
          <SectionCenterContainer>
            <SupportCard />
          </SectionCenterContainer>
        </SectionFooter>
      </Section>
    </>
  );
};
