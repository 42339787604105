import { Maybe } from 'graphql/jsutils/Maybe';

import { UpdatePayoutCollectionMutation } from '../generated/graphql';
import { extractFromUnion } from './extractor';

export function extractPayoutV1ValidationErrors(
  data: Maybe<UpdatePayoutCollectionMutation>
) {
  const collection = extractFromUnion(
    data?.profile?.collection,
    'ProfileCollectionPayoutV1Mutation'
  );
  const errorSet = extractFromUnion(
    collection?.update,
    'ProfileFieldValidationSet'
  );
  return errorSet?.hasErrors ? errorSet : null;
}
