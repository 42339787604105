import { OdiCard, OdiCardIcon } from '@campoint/odi-ui';
import { LightBulbOutlined } from '@campoint/odi-ui-icons';
import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';

export type TipBannerProps = {
  text: string;
  buttonFooter?: React.ReactNode;
};

export const TipBanner: React.FC<TipBannerProps> = ({ text, buttonFooter }) => {
  return (
    <OdiCard
      flexDir={'row'}
      justifyContent={'space-between'}
      px={4}
      borderColor={'steel'}
    >
      <HStack spacing={2} color={'caribbeanGreen.500'}>
        <OdiCardIcon as={LightBulbOutlined} />
        <Text children={text} />
      </HStack>
      {buttonFooter && <Box ml={4}>{buttonFooter}</Box>}
    </OdiCard>
  );
};
