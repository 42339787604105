import React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import {
  TermsAcceptanceTypeEnum,
  useGetLegalTermMarkdownQuery,
} from '../generated/graphql';

interface UseTermMarkdownOptions {
  type: TermsAcceptanceTypeEnum;
}

export const useTermMarkdown = (options: UseTermMarkdownOptions) => {
  const { t } = useTranslation(['general']);
  const { loading, data } = useGetLegalTermMarkdownQuery({
    fetchPolicy: 'no-cache',
    variables: {
      type: options.type,
    },
  });

  return React.useMemo(() => {
    const pdfDownloadUrl =
      data?.terms?.acceptance?.cmsArticle?.revision?.pdfUrl;
    return {
      loading,
      title: data?.terms?.acceptance?.cmsArticle?.title,
      markdownString: data?.terms?.acceptance?.cmsArticle?.content,
      pdfDownloadUrl,
      action: {
        openPdfInNewWindow: () => {
          if (!pdfDownloadUrl) {
            issueChakraToast({
              status: 'error',
              description: t('general:toast.DatenKonntenNichtGeladenWerden'),
            });
            return;
          }
          window.open(pdfDownloadUrl, '_blank');
        },
      },
    };
  }, [loading, data, t]);
};
