import {
  BoxProps,
  Center,
  Heading,
  HeadingProps,
  chakra,
  useId,
} from '@chakra-ui/react';
import { keyframes } from '@chakra-ui/system';

import { ZStack } from '../../Layout/ZStack';

/**
 * Referenced sources
 * @link https://github.com/chakra-ui/chakra-ui/blob/eb7cab94cc6e762e96c61c63c17d3414b9c71ed6/packages/components/spinner/src/spinner.tsx#L90
 *
 */
const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

interface GradientSpinnerProps {
  /**
   * The speed of the spinner.
   * @default "2.0s"
   * @example
   * ```jsx
   * <GradientSpinner speed="0.2s"/>
   * ```
   */
  speed?: string;
  desiredSizeInPixel?: number;
  strokeWidthInPixel?: number;
}

/**
 * Referenced sources
 * @link https://www.benmvp.com/blog/how-to-create-circle-svg-gradient-loading-spinner/
 * @link https://github.com/chakra-ui/chakra-ui/blob/eb7cab94cc6e762e96c61c63c17d3414b9c71ed6/packages/components/spinner/src/spinner.tsx#L90
 *
 */
export const GradientSpinner: React.FC<GradientSpinnerProps> = ({
  speed = '2.0s',
  desiredSizeInPixel = 130,
  strokeWidthInPixel = 10,
}) => {
  const radius = Math.floor(desiredSizeInPixel / 2 - strokeWidthInPixel / 2);
  const halfSize = desiredSizeInPixel / 2;

  const id = useId();
  const spinnerFirstHalfId = `spinner-firstHalf-${id}`;
  const spinnerSecondHalfId = `spinner-secondHalf-${id}`;

  return (
    <chakra.svg
      width={desiredSizeInPixel}
      height={desiredSizeInPixel}
      viewBox={`${-halfSize} ${-halfSize} ${desiredSizeInPixel} ${desiredSizeInPixel}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      animation={`${spin} ${speed} linear infinite`}
      aria-hidden
      role="presentation"
    >
      <defs>
        <linearGradient id={spinnerSecondHalfId}>
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id={spinnerFirstHalfId}>
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth={strokeWidthInPixel}>
        <path
          stroke={`url(#${spinnerSecondHalfId})`}
          d={`M -${radius} 0 A -${radius} -${radius} 0 0 1 ${radius} 0`}
        />
        <path
          stroke={`url(#${spinnerFirstHalfId})`}
          d={`M ${radius} 0 A ${radius} ${radius} 0 0 1 -${radius} 0`}
        />

        {/* <!-- 1deg extra path to have the round end cap --> */}
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d={`M -${radius} 0 A ${radius} ${radius} 0 0 1 -${radius} 0`}
        />
      </g>
    </chakra.svg>
  );
};

interface PercentProgressSpinnerProps extends BoxProps {
  /**
   * [0, 100]
   */
  percentProgress?: number;
  percentProgressLabelColor?: string;
  desiredSizeInPixel?: number;
  strokeWidthInPixel?: number;
}

const PercentProgressSpinnerLabel: React.FC<HeadingProps> = (props) => (
  <Heading
    as={'span'}
    size={'3xl'}
    fontWeight={'medium'}
    color={'gray.900'}
    {...props}
  />
);

export const PercentProgressSpinner: React.FC<PercentProgressSpinnerProps> = ({
  percentProgress,
  percentProgressLabelColor,
  desiredSizeInPixel,
  strokeWidthInPixel,
  children,
  ...boxProps
}) => {
  const hasProgress = typeof percentProgress === 'number';
  const clampedProgess = Math.min(Math.max(percentProgress ?? 0, 0), 100);

  return (
    <ZStack
      w={'min-content'}
      role={'progressbar'}
      aria-valuenow={!hasProgress ? undefined : clampedProgess}
      aria-valuemin={0}
      aria-valuemax={100}
      userSelect={'none'}
      {...boxProps}
    >
      <Center>
        <GradientSpinner
          desiredSizeInPixel={desiredSizeInPixel}
          strokeWidthInPixel={strokeWidthInPixel}
        />
      </Center>
      {!hasProgress ? (
        <></>
      ) : (
        <Center>
          {children ?? (
            <PercentProgressSpinnerLabel>
              {clampedProgess.toFixed().padStart(2, '0')} %
            </PercentProgressSpinnerLabel>
          )}
        </Center>
      )}
    </ZStack>
  );
};
