import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ExperienceIntentionFilterSheet } from '../../../components/shared/FilterSheet/FilterSheet';
import { FilterableIncomeSourceListContext } from '../../../provider/IncomeSourceProvider';

export const IncomeSourcesPageFilter: React.FC<{
  context: FilterableIncomeSourceListContext;
}> = ({ context }) => {
  const { t } = useTranslation(['incomeSourcesPage', 'general']);
  return (
    <ExperienceIntentionFilterSheet
      context={context}
      renderCountDisplay={(count) =>
        t('incomeSourcesPage:button.CountVerdienstmoglichkeitenAnzeigen', {
          count,
        })
      }
      renderCountDisplayReset={t('general:button.FilterZurucksetzen')}
      experienceHeading={t(
        'incomeSourcesPage:headline.WieErfahrenBistDuBereits'
      )}
      experienceTabTitle={t('incomeSourcesPage:headline.Erfahrung')}
      intentionHeading={t('incomeSourcesPage:headline.WasMochtestDuErreichen')}
      intentionTabTitle={t('incomeSourcesPage:headline.Ziel')}
    />
  );
};
