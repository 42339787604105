import * as React from 'react';

import { FeatureName, useFeature } from '../flags';
import {
  ContestFragment,
  MediaContestTypeEnum,
  useGetContestDataQuery,
} from '../generated/graphql';
import { LocalStorageKeys } from '../types';
import { LocalStorage, noop } from '../utils';
import { useAuth } from './AuthProvider';

export type ContestProviderType = {
  videoContest: ContestFragment | null;
  photoContest: ContestFragment | null;
  initialVideoContestId: number | null;
  initialPhotoContestId: number | null;
  isContestLoading: boolean;
  refetchVideoContest: () => void;
  refetchPhotoContest: () => void;
};

export const initValues: ContestProviderType = {
  videoContest: null,
  photoContest: null,
  initialVideoContestId: null,
  initialPhotoContestId: null,
  isContestLoading: true,
  refetchVideoContest: noop,
  refetchPhotoContest: noop,
};

export const contestContext =
  React.createContext<ContestProviderType>(initValues);

export const ContestProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const contestFeature = useFeature(FeatureName.contest);
  const authCtx = useAuth();

  const initialVideoContestId = React.useMemo(() => {
    return LocalStorage.getNumber(
      LocalStorageKeys.PARTICIPATED_IN_VIDEO_CONTSEST_ID
    );
  }, []);

  const initialPhotoContestId = React.useMemo(() => {
    return LocalStorage.getNumber(
      LocalStorageKeys.PARTICIPATED_IN_PHOTO_CONTSEST_ID
    );
  }, []);

  const {
    loading: videoContestLoading,
    data: videoContestData,
    refetch: refetchVideoContest,
  } = useGetContestDataQuery({
    variables: {
      contestId: initialVideoContestId,
      type: MediaContestTypeEnum.Video,
    },
    skip: !contestFeature || !authCtx.isAuthenticated,
  });
  const {
    loading: photoContestLoading,
    data: photoContestData,
    refetch: refetchPhotoContest,
  } = useGetContestDataQuery({
    variables: {
      contestId: initialPhotoContestId,
      type: MediaContestTypeEnum.Photo,
    },
    skip: !contestFeature || !authCtx.isAuthenticated,
  });

  return (
    <contestContext.Provider
      value={{
        videoContest: videoContestData?.media.contest || null,
        photoContest: photoContestData?.media.contest || null,
        initialVideoContestId,
        initialPhotoContestId,
        isContestLoading: videoContestLoading || photoContestLoading,
        refetchVideoContest: React.useCallback(() => {
          refetchVideoContest().then();
        }, [refetchVideoContest]),
        refetchPhotoContest: React.useCallback(() => {
          refetchPhotoContest().then();
        }, [refetchPhotoContest]),
      }}
    >
      {children}
    </contestContext.Provider>
  );
};

export function useContest() {
  return React.useContext(contestContext);
}
