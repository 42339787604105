import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  chakra,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';

import { useMediaInput } from '../../../provider/MediaInputProvider';
import { isFileAccepted } from '../../../utils/media/IsFileAccepted';
import { SHOULD_INSERT_CUSTOM_MEDIA_PICKER_MENU } from '../../../utils/media/constant';

export const MediaPickerBox = forwardRef<
  BoxProps & {
    isDisabled?: boolean;
    isLoading?: boolean;
    isDropzoneEnabled?: boolean;
    openMediaFlowOnFilePick?: boolean;
  },
  'label'
>(({ children, isDisabled, isLoading, isDropzoneEnabled, ...rest }, ref) => {
  const { inputProps, pickFileLabel, isProcessingInput, triggerMenu } =
    useMediaInput();

  const [isCurrentDropFileTarget, setIsCurrentDropFileTarget] =
    React.useState(false);

  function dropHandler(ev: any) {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    const firstItem = ev.dataTransfer.items[0];

    if (firstItem && firstItem?.kind === 'file') {
      const file = firstItem.getAsFile();
      if (file) {
        const mimeType = file.type;
        const isAcceptableFile = isFileAccepted(mimeType, inputProps.accept);
        if (!isAcceptableFile) {
          setIsCurrentDropFileTarget(false);
          return;
        }

        inputProps?.onChange?.({ target: { files: [file] } } as any);
      }
    } else {
      const firstFile = ev.dataTransfer.files[0];
      if (firstFile) {
        const mimeType = firstFile.type;
        const isAcceptableFile = isFileAccepted(mimeType, inputProps.accept);
        if (!isAcceptableFile) {
          setIsCurrentDropFileTarget(false);
          return;
        }

        inputProps?.onChange?.({ target: { files: [firstFile] } } as any);
      }
    }
  }

  function dragOverHandler(ev: any) {
    // Prevent default behavior (Prevent file from being opened)
    setIsCurrentDropFileTarget(true);
    ev.preventDefault();
  }

  return (
    <Box
      as={chakra.label}
      role={'button'}
      ref={ref}
      aria-label={pickFileLabel}
      onDrop={!isDropzoneEnabled ? undefined : dropHandler}
      onDragOver={!isDropzoneEnabled ? undefined : dragOverHandler}
      onDragLeave={
        !isDropzoneEnabled
          ? undefined
          : () => {
              setIsCurrentDropFileTarget(false);
            }
      }
      onClick={(e) => {
        if (SHOULD_INSERT_CUSTOM_MEDIA_PICKER_MENU) {
          e.preventDefault();
          triggerMenu();
        }
      }}
      {...rest}
      borderColor={isCurrentDropFileTarget ? 'primary.500' : 'steel'}
    >
      {children}
      <Input
        {...inputProps}
        capture={false}
        isDisabled={isLoading || isDisabled || isProcessingInput}
      />
    </Box>
  );
});

export const MediaPickerButton = forwardRef<ButtonProps, 'label'>(
  ({ children, isDisabled, isLoading, ...rest }, ref) => {
    const { inputProps, pickFileLabel, isProcessingInput, triggerMenu } =
      useMediaInput();

    return (
      <Button
        as={chakra.label}
        role={'button'}
        isDisabled={isDisabled}
        isLoading={isLoading || isProcessingInput}
        ref={ref}
        aria-label={pickFileLabel}
        onClick={(e) => {
          if (SHOULD_INSERT_CUSTOM_MEDIA_PICKER_MENU) {
            e.preventDefault();
            triggerMenu();
          }
        }}
        {...rest}
        variant={'solid'}
      >
        {children}
        <Input
          {...inputProps}
          capture={false}
          isDisabled={isLoading || isDisabled}
        />
      </Button>
    );
  }
);

export const MediaPickerIconButton = forwardRef<
  Partial<IconButtonProps>,
  'label'
>(({ isDisabled, isLoading, icon, ...rest }, ref) => {
  const { inputProps, pickFileLabel, triggerMenu, isProcessingInput } =
    useMediaInput();

  return (
    <IconButton
      as={chakra.label}
      role={'button'}
      isDisabled={isDisabled}
      isLoading={isLoading || isProcessingInput}
      ref={ref}
      icon={
        <>
          <Input
            {...inputProps}
            capture={false}
            isDisabled={isLoading || isDisabled}
          />
          {icon ?? <Icon as={icons.AddPhoto} boxSize={'icon.md'} />}
        </>
      }
      aria-label={pickFileLabel}
      onClick={(e) => {
        if (SHOULD_INSERT_CUSTOM_MEDIA_PICKER_MENU) {
          e.preventDefault();
          triggerMenu();
        }
      }}
      {...rest}
      children={
        <Input
          {...inputProps}
          capture={false}
          isDisabled={isLoading || isDisabled}
        />
      }
    />
  );
});
