import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Circle,
  Heading,
  Icon,
  IconButton,
  Text,
  VStack,
  Wrap,
  WrapItem,
  forwardRef,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../components/Layout/ResponsiveModal';
import {
  MediaFilePicked,
  PostMediaPickerPreviewBox,
  PostMediaPreviewBox,
} from '../../../../components/shared/FeedPostCreate/FeedPostCreate';
import { MediaPreviewBox } from '../../../../components/shared/FeedPostEditModal/FeedPostEditModal';
import { GradientSpinner } from '../../../../components/shared/GradientSpinner/GradientSpinner';
import { MediaPickerBox } from '../../../../components/shared/MediaPickerButton/MediaPickerButton';
import { MediaUploadSkipCropper } from '../../../../components/shared/MediaUpload/MediaUpload';
import { ConfirmMediaDeletionDialog } from '../../../../components/shared/dialogs/ConfirmMediaDeletionDialog/ConfirmMediaDeletionDialog';
import {
  MediaContestTypeEnum,
  MediaPictureStatusEnum,
} from '../../../../generated/graphql';
import { usePhotoContest } from '../../../../provider/ContestPhotoProvider';
import { useContest } from '../../../../provider/ContestProvider';
import { MediaInputProvider } from '../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../provider/MediaPropertiesProvider';
import {
  MediaContext,
  MediaProvider,
} from '../../../../provider/MediaProvider';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { UploadDropZoneContent } from '../../../VideoLibraryPage/VideoLibraryPage';

const UploadButton = forwardRef<
  {
    children: React.ReactNode;
    onMedia: (media: MediaContext['media']) => void;
  },
  'li'
>(({ children, onMedia }, ref) => {
  return (
    <MediaProvider>
      <MediaFilePicked onMedia={onMedia}>
        <WrapItem>
          <MediaInputProvider
            issueWrongDimensionToast={true}
            accept={'DEFAULT_FOR_PICTURE'}
            capture={'user'}
          >
            <MediaPickerBox
              isDropzoneEnabled={true}
              children={children}
              ref={ref}
            ></MediaPickerBox>
            <MediaUploadSkipCropper />
          </MediaInputProvider>
        </WrapItem>
      </MediaFilePicked>
    </MediaProvider>
  );
});

const StatusBox: typeof Box = (props) => {
  return (
    <Box
      color={'white'}
      background={'rgba(0,0,0,0.5)'}
      width={'100%'}
      height={'100%'}
      textAlign={'center'}
      marginTop={'-87.5px'}
      position={'relative'}
      fontSize={'12px'}
      {...props}
    />
  );
};

export const ContestPhotoUpload: React.FC<{
  mode: 'modal' | 'tile';
}> = ({ mode }) => {
  const { t } = useTranslation(['feed', 'contest', 'mediaUpload', 'general']);
  const { photoContest: contest, isContestLoading } = useContest();
  const {
    setPendingUploads,
    submit,
    photoModalDisclosure,
    isWorking,
    photoAlbumData,
    pendingUploads,
    pendingDeletions,
    setPendingDeletions,
  } = usePhotoContest();

  // delete modal
  const [modalDeletingId, setModalDeletingId] = React.useState<
    number | undefined
  >(undefined);
  const deletePhoto = React.useCallback(
    (id: number | undefined = modalDeletingId) => {
      if (!id) return;
      if (mode === 'tile') {
        // no save needed
        submit(undefined, [id!]);
      } else {
        setPendingDeletions((prevState) => [...prevState, id]);
      }
      setModalDeletingId(undefined);
    },
    [modalDeletingId, mode, setPendingDeletions, submit]
  );

  // automatically open file picker when we're opening a modal
  const uploadDropzoneRef = React.useRef<any>(null);

  // open modal if we're on a tile
  const onMedia = (m: MediaContext['media']) => {
    setPendingUploads((prevState) => [...prevState, m]);
    if (mode === 'tile') {
      photoModalDisclosure.onOpen();
    }
  };

  // compute media
  const existingMedia = React.useMemo(() => {
    if (!photoAlbumData) return [];
    return photoAlbumData.media.photoalbums.pictureList.edges
      .filter(
        (edge) =>
          edge !== null &&
          edge.node !== null &&
          !pendingDeletions.includes(edge.node.umpId)
      )
      .map((edge) => edge!.node);
  }, [photoAlbumData, pendingDeletions]);
  const mediaCount = React.useMemo(
    () => existingMedia.length + pendingUploads.length,
    [existingMedia, pendingUploads]
  );

  // loading indicator
  if (isContestLoading || isWorking || !contest) {
    return (
      <Center color="primary.500">
        <VStack>
          <GradientSpinner desiredSizeInPixel={50} strokeWidthInPixel={5} />
        </VStack>
      </Center>
    );
  }

  return (
    <MediaPropertiesProvider.ForFeed enforceTargetDimension={true}>
      <ConfirmMediaDeletionDialog
        mediaType={'photo'}
        isOpen={!!modalDeletingId}
        onClose={() => setModalDeletingId(undefined)}
        deleteMedia={() => deletePhoto(modalDeletingId)}
        descriptionOverride={t(
          'contest:text.FotoWirklichUnwiederruflichLoschenDiesKannNichtRuckgangigGemachtWerdXX'
        ).toString()}
      />
      {mediaCount === 0 ? (
        // ===== Upload Dropzone Button =====
        <VStack alignItems={'center'} spacing={0} alignSelf={'center'}>
          <Heading size="sm">
            {t('contest:heading.JetztVideoHochladen', {
              type:
                contest.type === MediaContestTypeEnum.Photo
                  ? t('contest:label.Photo_s')
                  : t('contest:label.Video'),
            })}
          </Heading>
          <Text
            fontWeight={'500'}
            color={'gray.300'}
            mx={10}
            my={2}
            maxWidth={'200px'}
            textAlign={'center'}
          >
            {t('contest:text.DuKannst1FotoProWettbewerbEinreichen', {
              count: contest.uploadLimit,
            })}
          </Text>
          <UploadButton onMedia={onMedia} ref={uploadDropzoneRef}>
            <UploadDropZoneContent
              text={t('contest:text.DragDropOderFotosAuswahlen')}
            />
          </UploadButton>
        </VStack>
      ) : (
        // ===== Media List =====
        <VStack alignItems={'start'} spacing={0}>
          {mode === 'modal' && (
            <Heading size="xl" mb={6}>
              {t('mediaUpload:heading.PasstAlles')}
            </Heading>
          )}
          <Heading size="sm">
            <Icon as={icons.CheckCircle} mt={'-3px'} mr={1} />
            {t('contest:heading.FotoHinzugefugt')} ({mediaCount ?? 0}/
            {contest.uploadLimit})
          </Heading>
          <Text>
            {t('contest:text.DuKannstNochMaxXFotosEinreichen', {
              count: contest.uploadLimit - (mediaCount ?? 0),
            })}
          </Text>
          <Wrap direction={'row'} flexWrap={'wrap'} spacing={'4'} mt={4}>
            {mediaCount < contest.uploadLimit && (
              // ===== Add Button =====
              <UploadButton onMedia={onMedia} ref={uploadDropzoneRef}>
                <PostMediaPickerPreviewBox />
              </UploadButton>
            )}
            {mediaCount < 1
              ? null
              : pendingUploads.map((media) => {
                  // ===== Current Selection =====
                  return (
                    <WrapItem key={media.key}>
                      <PostMediaPreviewBox
                        media={media}
                        onClear={() => {
                          setPendingUploads((prevState) =>
                            prevState.filter(
                              (prevMedia) => prevMedia.key !== media.key
                            )
                          );
                        }}
                      />
                    </WrapItem>
                  );
                })}
            {existingMedia.map((media, index) => {
              // ===== Existing Media =====
              return (
                <WrapItem key={`media-preview-item-${index}`}>
                  <MediaPreviewBox imageProps={{ src: media.image.src }}>
                    {(media.status === MediaPictureStatusEnum.Rejected ||
                      media.status === MediaPictureStatusEnum.Broken) && (
                      <StatusBox>
                        <Circle
                          backgroundColor={'red.500'}
                          p={1}
                          mt={5}
                          display={'inline-block'}
                        >
                          <Icon as={icons.ErrorOutline} boxSize={'icon.lg'} />
                        </Circle>
                        <Text>{t('general:status.Abgelehnt')}</Text>
                      </StatusBox>
                    )}
                    {media.status === MediaPictureStatusEnum.Pending && (
                      <StatusBox>
                        <Circle
                          backgroundColor={'warning.500'}
                          p={1}
                          mt={5}
                          display={'inline-block'}
                        >
                          <Icon as={icons.Schedule} boxSize={'icon.lg'} />
                        </Circle>
                        <Text>{t('general:status.Prufung')}</Text>
                      </StatusBox>
                    )}
                    <IconButton
                      size={'xs'}
                      position={'absolute'}
                      top={'1px'}
                      right={'1px'}
                      color={'whiteAlpha.900'}
                      filter={'drop-shadow(0 0 2px rgba(0, 0, 0, 0.6))'}
                      variant={'angel'}
                      aria-label="Delete"
                      onClick={() => {
                        if (
                          media.status === MediaPictureStatusEnum.Rejected ||
                          media.status === MediaPictureStatusEnum.Broken
                        ) {
                          deletePhoto(media.umpId);
                        } else {
                          setModalDeletingId(media.umpId);
                        }
                      }}
                      icon={<Icon as={icons.Close} boxSize={'icon.md'} />}
                    />
                  </MediaPreviewBox>
                </WrapItem>
              );
            })}
          </Wrap>
          {mode === 'modal' && (
            <Center width={'100%'} mt={8}>
              <Button onClick={() => submit()} variant={'solid'}>
                {existingMedia.length === 0
                  ? t('contest:button.AnWettbewerbTeilnehmen')
                  : t('general:button.save')}
              </Button>
            </Center>
          )}
        </VStack>
      )}
    </MediaPropertiesProvider.ForFeed>
  );
};

export const ContestPhotoUploadModal: React.FC = () => {
  const { photoModalDisclosure, abort, pendingDeletions, pendingUploads } =
    usePhotoContest();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const {
    action: { registerDirtyFlag, showConfirmChangesDialog },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    photoModalDisclosure.onClose();
    abort();
  }, [abort, photoModalDisclosure]);
  React.useEffect(() => {
    return registerDirtyFlag(
      pendingUploads.length > 0 || pendingDeletions.length > 0,
      onOmitChanges
    );
  }, [
    onOmitChanges,
    pendingDeletions.length,
    pendingUploads.length,
    registerDirtyFlag,
  ]);

  return (
    <MediaPropertiesProvider.ForFeed enforceMinimalDimension={true}>
      <ResponsiveModal
        preferredSize="xl"
        isOpen={photoModalDisclosure.isOpen}
        onClose={() => {
          if (pendingUploads.length > 0 || pendingDeletions.length > 0) {
            showConfirmChangesDialog(onOmitChanges);
          } else {
            photoModalDisclosure.onClose();
          }
        }}
      >
        <ResponsiveModalOverlay />
        <ResponsiveModalContent minHeight={isDesktop ? 'unset !important' : ''}>
          <ResponsiveModalCloseButton />
          <ResponsiveModalBodyBox
            p={14}
            justifyContent={'center'}
            display={'flex'}
          >
            <ContestPhotoUpload mode="modal" />
          </ResponsiveModalBodyBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </MediaPropertiesProvider.ForFeed>
  );
};
