import { ToastProps } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { uploadDocument } from '../utils/media';

export const useDocumentUpload = (uploadUrl?: string) => {
  const { t } = useTranslation(['general']);
  const [isUploading, setIsUploading] = useState(false);

  const upload = useCallback(
    async (document: { blob: Blob }) => {
      const errorToast: ToastProps = {
        status: 'error',
        description: t(
          'general:toast.EsGabEinenFehlerBeimHochladenDesDokuments'
        ),
      };

      if (!uploadUrl) {
        issueChakraToast(errorToast);
        return null;
      }

      try {
        setIsUploading(true);
        const { id } = await uploadDocument(uploadUrl, document.blob);
        if (id === null) {
          issueChakraToast(errorToast);
        }
        return id;
      } catch {
        issueChakraToast(errorToast);
        return null;
      } finally {
        setIsUploading(false);
      }
    },
    [uploadUrl, setIsUploading, t]
  );

  return {
    upload,
    isUploading,
  };
};
