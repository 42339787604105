import { Tab, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabListWithOverflowScroll } from '../../../../components/Layout/TabListWithOverflowScroll';
import { useVideoLibraryPhotoContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryPhotoProvider/VideoLibraryPhotoProvider';
import { useUploadyService } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { VideoLibraryPageTabSectionPhoto } from '../VideoLibraryPageTabSectionPhoto/VideoLibraryPageTabSectionPhoto';
import { VideoLibraryPageTabSectionUnused } from '../VideoLibraryPageTabSectionUnused/VideoLibraryPageTabSectionUnused';
import { VideoLibraryPageTabSectionVideo } from '../VideoLibraryPageTabSectionVideo/VideoLibraryPageTabSectionVideo';

export const VideoLibraryPageTabSection: React.FC<{
  playingVideoId: string | null;
  setPlayingVideoId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ playingVideoId, setPlayingVideoId }) => {
  const { photos } = useVideoLibraryPhotoContext();
  const { tabsAction } = useUploadyService();
  const { tabIds, tabId, setTabId } = tabsAction;
  const isPhotosEmpty = (photos?.length ?? 0) === 0;

  const { t } = useTranslation(['videoLibrary']);

  const tabIndex = React.useMemo(() => {
    if (!tabId) {
      return 0;
    }
    const index = tabIds.indexOf(tabId);
    return index >= 0 ? index : 0;
  }, [tabId, tabIds]);

  const handleTabChange = React.useCallback(
    (index: number) => {
      const newTabId = tabIds[index] ?? tabIds[0];
      setTabId(newTabId);
    },
    [tabIds, setTabId]
  );

  return (
    <>
      <Tabs
        defaultIndex={0}
        index={tabIndex}
        isFitted
        w={'full'}
        onChange={handleTabChange}
      >
        <TabListWithOverflowScroll>
          <Tab
            fontSize={{ base: 'sm', lg: 'xl' }}
            children={t('videoLibrary:tabs.Unused')}
          />
          <Tab
            fontSize={{ base: 'sm', lg: 'xl' }}
            children={t('videoLibrary:tabs.Videos')}
          />
          {!isPhotosEmpty && (
            <Tab
              fontSize={{ base: 'sm', lg: 'xl' }}
              children={t('videoLibrary:tabs.Photos')}
            />
          )}
        </TabListWithOverflowScroll>
        <TabPanels>
          <TabPanel p={0}>
            <VideoLibraryPageTabSectionUnused />
          </TabPanel>
          <TabPanel p={0}>
            <VideoLibraryPageTabSectionVideo
              playingVideoId={playingVideoId}
              setPlayingVideoId={setPlayingVideoId}
            />
          </TabPanel>
          {!isPhotosEmpty && (
            <TabPanel p={0}>
              <VideoLibraryPageTabSectionPhoto />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};
