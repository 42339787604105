import { ToastProps } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';

import theme from '../../theme';
import { gtag } from '../../utils/gtag';

const { ToastContainer, toast } = createStandaloneToast({ theme });

export const ChakraToastContainer = ToastContainer;

export const issueChakraToast = (props: ToastProps) => {
  toast({
    position: 'top',
    containerStyle: {
      pt: { base: 4, lg: 8 },
      px: { base: 4, lg: 8 },
      m: 0,
      maxWidth: '768px',
      w: 'full',
    },
    ...props,
  });
  gtag('event', 'toast', props);
};
