import {
  OdiCard,
  OdiCardBody,
  OdiCardHeader,
  OdiCardIcon,
  OdiCardTitle,
} from '@campoint/odi-ui';
import {
  EmojiEvents,
  Groups,
  MonetizationOn,
  PieChart,
  Star,
  VxModelsLogo,
} from '@campoint/odi-ui-icons';
import * as icons from '@campoint/odi-ui-icons';
import { Icon as ChakraIcon } from '@chakra-ui/icon';
import {
  Box,
  Center,
  HStack,
  Heading,
  Icon,
  IconProps,
  Text,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { StatisticsEntryCategoryEnum } from '../../../../../generated/graphql';
import useFormat from '../../../../../hooks/useFormat';

const GetStatsTileIcon = (
  category: StatisticsEntryCategoryEnum
): EmotionIcon => {
  const icons: { [key in StatisticsEntryCategoryEnum]: EmotionIcon } = {
    customerLoyalty: Groups,
    income: MonetizationOn,
    ranking: EmojiEvents,
    serviceQuality: Star,
    vxfeed: VxModelsLogo,
  };

  if (icons[category] !== undefined) {
    return icons[category];
  } else {
    return PieChart;
  }
};

const ArrowIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <ChakraIcon
    focusable="false"
    aria-hidden
    viewBox="0 0 24 24"
    {...props}
    ref={ref}
  >
    <path
      fill="currentColor"
      d="m8.33 11.347 2.885 2.886a1.11 1.11 0 0 0 1.571 0l2.886-2.886c.702-.702.201-1.906-.791-1.906H9.11c-.991 0-1.482 1.204-.78 1.906Z"
    />
  </ChakraIcon>
));

export const StatsTileWithIcon: React.FC<{
  category: StatisticsEntryCategoryEnum;
  unit?: string;
  headline: string;
  trend?: number | null;
  type: string;
  value?: string;
}> = ({ category, unit, headline, trend, type, value = '0' }) => {
  const { t } = useTranslation(['statistic']);
  const [statValue, setStatValue] = useState('');

  const { formatValue } = useFormat();

  const indicatorIcon = GetStatsTileIcon(category);

  const arrowStyling =
    trend &&
    (trend > 0
      ? {
          color: 'success.highEmphasis',
          transform: 'scaleY(-1)',
          'aria-label': t('statistic:label.PfeilNachOben'),
        }
      : {
          color: 'error.highEmphasis',
          'aria-label': t('statistic:label.PfeilNachUnten'),
        });

  const contentRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<SVGSVGElement>(null);
  const [availableWidth, setAvailableWidth] = React.useState(40);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      const arrowWidth = arrowRef.current ? arrowRef.current.clientWidth : 0;

      const maxSpaceForNumber = contentRef.current
        ? contentRef.current?.clientWidth - arrowWidth
        : 0;
      setAvailableWidth(maxSpaceForNumber);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setAvailableWidth]);

  useEffect(() => {
    const formattedValue = formatValue(
      value,
      availableWidth,
      type,
      unit ?? undefined
    );

    setStatValue(formattedValue);
  }, [availableWidth, value, type, unit, formatValue]);

  return (
    <OdiCard borderColor={'steel'} overflow={'hidden'} p={'4'} gap={'4'}>
      <OdiCardHeader gap={'4'}>
        <Center backgroundColor={'steel'} borderRadius={'5px'} p={1}>
          <OdiCardIcon
            color={'darkSteel'}
            aria-label={category}
            as={indicatorIcon}
            boxSize={'1rem'}
          />
        </Center>
        <OdiCardTitle
          color={'onSurface.mediumEmphasis'}
          letterSpacing={'0.03em'}
          p={0}
          fontSize={'md'}
        >
          {headline}
        </OdiCardTitle>
      </OdiCardHeader>
      <OdiCardBody p={0} h={'full'} w={'full'}>
        <HStack
          spacing={0}
          maxW={'full'}
          w={'full'}
          justifyContent={'center'}
          ref={contentRef}
          display={'flex'}
          alignItems={'end'}
        >
          <Heading as={'p'} size={'2xl'} isTruncated>
            {statValue}
          </Heading>
          {trend && (
            <ArrowIcon boxSize={'6'} {...arrowStyling} ref={arrowRef} />
          )}
        </HStack>
      </OdiCardBody>
    </OdiCard>
  );
};

type IncomeStatsTileProps = {
  /** Card headline shown at top */
  headline: string;
  /** Number that is displayed as income */
  payableAmount: number;
  /** Show green/red arrow up/down */
  showIncomeIndicator: boolean;
  /** Shows either green/red arrow up/down */
  showPositiveIncomeIndicator?: boolean;
};

export const IncomeStatsTile = ({ ...props }: IncomeStatsTileProps) => {
  const { t } = useTranslation(['statistic']);
  const intl = useIntl();

  const income = props.payableAmount ?? 0;

  const currency = 'EUR';

  const formattedAmount = intl
    .formatNumberToParts(income, {
      currency,
      style: 'currency',
    })
    .map(({ type, value }) => {
      if (type === 'fraction' && income === 0) {
        return '–';
      }
      return value;
    })
    .join('');

  return (
    <StatsTile
      headline={props.headline}
      stat={formattedAmount}
      subline={t('statistic:text.HeuteUmTimeAktualisiert', {
        time: Date.now(),
      })}
    />
  );
};

export const StatsTile: React.FC<{
  headline: string;
  stat: string;
  subline: string;
  showPositiveIncomeIndicator?: boolean;
  showIncomeIndicator?: boolean;
}> = ({ headline, stat, subline, ...props }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <OdiCard borderColor={'steel'} overflow={'hidden'} p={'4'} gap={'4'}>
      <OdiCardHeader>
        {/* text */}
        <OdiCardTitle
          color={'onSurface.mediumEmphasis'}
          letterSpacing={'0.03em'}
          p={0}
          fontSize={'md'}
        >
          {headline}
        </OdiCardTitle>
      </OdiCardHeader>
      <OdiCardBody p={0} h={'full'} w={'full'}>
        <VStack spacing={4}>
          <HStack
            spacing={0}
            maxW={'full'}
            ref={contentRef}
            display={'flex'}
            alignItems={'center'}
          >
            <Heading as={'p'} size={'2xl'} isTruncated>
              {stat}
            </Heading>
            {/* Todo refactor with trend prop like in StatsTileWithIcon */}
            {props.showIncomeIndicator && (
              <Box boxSize={6}>
                {props.showPositiveIncomeIndicator && (
                  <Icon
                    as={icons.ArrowDropUp}
                    boxSize={'icon.md'}
                    color={'success.highEmphasis'}
                  />
                )}
                {!props.showPositiveIncomeIndicator && (
                  <Icon
                    as={icons.ArrowDropDown}
                    boxSize={'icon.md'}
                    color={'error.highEmphasis'}
                  />
                )}
              </Box>
            )}
          </HStack>
          <Text fontSize={'sm'} p={0} color={'gray.300'} isTruncated>
            {subline}
          </Text>
        </VStack>
      </OdiCardBody>
    </OdiCard>
  );
};
