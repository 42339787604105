import * as icon from '@campoint/odi-ui-icons';
import { ChatbotMessage, useChatbot } from '@campoint/vxmodels-chatbot';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveApiLanguage } from '../../../hooks/useActiveApiLanguage';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { useAccountStatus } from '../../../provider/AccountStatusProvider/AccountStatusProvider';
import { useAuth } from '../../../provider/AuthProvider';
import { VXMChatbotFAQs } from './VXMChatbotFAQs';
import {
  ChatbotHistoryEntry,
  ChatbotIsThinking,
  ChatbotNeedHelp,
  VXMChatbotHistoryEntry,
} from './VXMChatbotHistoryEntry';
import { errorHandler, getHumanURL } from './VXMChatbotUtils';

export const VXMChatbot: React.FC<{
  modelPictureFSK16Avatar: string;
}> = ({ modelPictureFSK16Avatar }) => {
  const { t } = useTranslation(['chatbot']);
  const {
    sendAsk,
    isProcessing,
    isConnected,
    messages,
    setShouldConnect,
    setErrorHandler,
    lastPong,
  } = useChatbot();
  const inputRef = useRef<HTMLInputElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);
  const [openChat] = useQueryParamState('openChat');
  const { isOpen, onToggle, onOpen } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { authUser } = useAuth();
  const lang = useActiveApiLanguage();
  const { hasSetUsername } = useAccountStatus();

  // default open state
  useEffect(() => {
    if (openChat === 'true') {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // connect when chatbot is opened
  useEffect(() => {
    if (isOpen) {
      setShouldConnect(true);
    }
  }, [isOpen, setErrorHandler, setShouldConnect]);

  // error handling
  useEffect(() => {
    setErrorHandler(() => errorHandler(t));
  }, [setErrorHandler, t]);

  // update ping continuously
  const [slowCoonection, setSlowConnection] = useState<boolean>(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setSlowConnection(Date.now() - lastPong > 5000);
    }, 100);
    return () => clearInterval(interval);
  }, [lastPong]);

  // needed to force a rerender when the user types
  const [inputText, setInputText] = useState<string>('');
  const disableInput = useMemo(() => {
    return isProcessing || !isConnected;
  }, [isProcessing, isConnected]);
  const canSend = useMemo(() => {
    return !disableInput && inputRef.current?.value.trim() !== '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableInput, inputText]);

  // send the current input message
  const send = useCallback(() => {
    if (!canSend) {
      return;
    }
    sendAsk(inputRef.current!.value);
    inputRef.current!.value = '';
    // wait for messages to rerender
    setTimeout(() => {
      historyRef.current!.scrollTo(0, historyRef.current!.scrollHeight);
    }, 0);
  }, [canSend, sendAsk]);

  // send FAQ question
  const sendFaq = useCallback(
    (ask: string) => {
      sendAsk(ask);
      // wait for messages to rerender
      setTimeout(() => {
        historyRef.current!.scrollTo(0, historyRef.current!.scrollHeight);
      }, 0);
    },
    [sendAsk]
  );

  // calculate history
  // TODO clean up
  const history: ChatbotHistoryEntry[] = useMemo(() => {
    const ret: ChatbotHistoryEntry[] = [];

    // insert timestamps between days
    let lastDate = 0;
    [
      {
        isBot: true,
        text: hasSetUsername
          ? t('chatbot:text.welcomeMessage', {
              username: authUser?.username!,
            })
          : t('chatbot:text.welcomeMessageNoName'),
        timestamp: Date.now(),
      } as ChatbotMessage,
      ...messages,
    ].forEach((message) => {
      const { DateTime } = require('luxon');
      const messageDate = DateTime.fromMillis(message.timestamp);
      const lastMessageDate = DateTime.fromMillis(lastDate);

      if (!lastMessageDate.hasSame(messageDate, 'day')) {
        lastDate = message.timestamp;
        ret.push({
          timestamp: message.timestamp,
        });
      }
      ret.push({
        isBot: message.isBot,
        text: message.text,
        timestamp: message.timestamp,
      } as ChatbotMessage);
    });

    if (isProcessing) {
      ret.push({
        type: 'isThinking',
      } as ChatbotIsThinking);
    }

    if (messages.length > 0) {
      const last = messages[messages.length - 1].text.toLocaleLowerCase();
      if (last.includes('mensch') || last.includes('human')) {
        ret.push({
          type: 'needHelp',
        } as ChatbotNeedHelp);
      }
    }

    return ret;
  }, [authUser?.username, hasSetUsername, isProcessing, messages, t]);

  // scroll to new message
  useEffect(() => {
    if (
      messages.length > 0 &&
      messages[messages.length - 1].isBot &&
      historyRef.current
    ) {
      historyRef.current.children[
        historyRef.current.children.length - 1
      ].scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // redirect when trigger phrase is detected
  useEffect(() => {
    if (messages.length > 0) {
      const last = messages[messages.length - 1].text.toLocaleLowerCase();
      if (
        messages[messages.length - 1].isBot &&
        (last.includes('ich leite dich') ||
          last.includes('i will transfer you') ||
          last.includes('5 sekunden') ||
          last.includes('5 seconds'))
      ) {
        setTimeout(() => {
          window.location.href = getHumanURL(lang);
        }, 5000);
      }
    }
  }, [lang, messages]);

  // focus input
  useEffect(() => {
    if (isOpen && !disableInput && !isMobile) {
      inputRef.current?.focus();
    }
  }, [isOpen, history, disableInput, isMobile]);

  return (
    <Box
      style={{
        position: 'fixed',
        zIndex: 999,
        right: isMobile && isOpen ? '0px' : '20px',
        bottom: isMobile ? (isOpen ? '0px' : '70px') : '20px',
        width: isOpen ? (isMobile ? '100%' : '400px') : '60px',
        height: isOpen ? (isMobile ? '100dvh' : '700px') : '60px',
        maxHeight: isMobile ? 'calc(100dvh - 58px)' : 'calc(100vh - 100px)',
        borderRadius: isOpen ? (isMobile ? '0px' : '10px') : '30px',
        boxShadow: isMobile && isOpen ? 'none' : '0 0 5px rgba(0, 0, 0, 0.4)',
        overflow: 'hidden',
        transition: 'all 0.3s',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Headline */}
      <Button
        variant={'solid'}
        onClick={onToggle}
        style={{
          display: 'flex',
          flex: isOpen ? '0 0 50px' : '0 0 60px',
          width: '100%',
          transition: 'all 0.3s',
          textAlign: 'left',
          borderRadius: '0',
        }}
        _hover={{
          background: '',
        }}
        justifyContent={'start'}
      >
        <Icon as={icon.Forum} w={6} h={6} />
        <Box
          style={{
            flex: 1,
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: '500',
            margin: '0 20px',
          }}
        >
          <Icon as={icon.Sparkle} />
          &nbsp;{t('chatbot:heading.kiModelCoach')}&nbsp;
          <Icon
            as={icon.Sparkle}
            style={{
              transform: 'rotate(180deg)',
            }}
          />
        </Box>
        <Icon as={icon.Close} w={6} h={6} />
      </Button>

      {/* Wrapper */}
      <Box
        width={isMobile ? '100%' : '400px'}
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        overflow={'hidden'}
      >
        {/* Chat History */}
        <Box flex={1} overflowY={'auto'} ref={historyRef} p={4}>
          {history.map((historyEntry, index) => (
            <VXMChatbotHistoryEntry
              key={index}
              entry={historyEntry}
              modelPictureFSK16Avatar={modelPictureFSK16Avatar}
            />
          ))}
        </Box>

        {/* Footer */}
        <Box margin={4}>
          {isConnected && (
            <VXMChatbotFAQs disableInput={disableInput} sendFaq={sendFaq} />
          )}
          {(!isConnected || slowCoonection) && (
            <Alert status="error" p={2} mb={2}>
              <AlertIcon as={icon.Sync} />
              <AlertDescription lineHeight={1.1}>
                {t(
                  'chatbot:label.esWirdVersuchtEineVerbindungWiederherzustellen'
                )}
              </AlertDescription>
            </Alert>
          )}
          <Input
            placeholder={t('chatbot:label.fragMichGerne')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                send();
              }
            }}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            ref={inputRef}
            pr={'40px'}
            disabled={disableInput}
          ></Input>
          <IconButton
            icon={
              <Icon
                as={icon.Send}
                color={canSend ? 'primary.500' : 'gray.200'}
              />
            }
            onClick={send}
            aria-label=""
            variant={'ghost'}
            style={{
              position: 'absolute',
              marginLeft: '-40px',
              cursor: canSend ? 'pointer' : 'not-allowed',
              zIndex: 1,
            }}
            disabled={!canSend}
          />
        </Box>
      </Box>
    </Box>
  );
};
