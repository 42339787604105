import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InputTourPayoutV1,
  TourDataWizardPayoutV2Fragment,
  useGetWizardPayoutDataV2Query,
  useUpdateWizardPayoutDataV2Mutation,
} from '../../../generated/graphql';
import Logger from '../../../utils/Logger';
import { WizardParentModalLayout } from '../components/WizardParentModalLayout/WizardParentModalLayout';
import { useWizardNavigateToNextStepCallback } from '../utils/hooks';
import { extractCurrentFieldError } from '../utils/utils';
import {
  extractFields,
  extractTourStepField,
  paperlessOriginatorMrfRedirectUrl,
} from './WizardPayout.util';
import {
  WizardPayoutContextProvider,
  WizardPayoutTypes,
  possibleStepIds,
} from './WizardPayoutContext';
import { WizardPayoutAddressData } from './step/AddressData/WizardPayoutAddressData';
import WizardPayoutFinishedPending from './step/FinishedPending/WizardPayoutFinishedPending';
import { WizardPayoutOriginatorBirthdata } from './step/OriginatorBirthdata/WizardPayoutOriginatorBirthdata';
import { WizardPayoutOriginatorIDCardBack } from './step/OriginatorIDCardBack/WizardPayoutOriginatorIDCardBack';
import { WizardPayoutOriginatorIDCardFront } from './step/OriginatorIDCardFront/WizardPayoutOriginatorIDCardFront';
import { WizardPayoutOriginatorInfo } from './step/OriginatorInfo/WizardPayoutOriginatorInfo';
import { WizardPayoutOriginatorMrfForm } from './step/OriginatorMrfForm/WizardPayoutOriginatorMrfForm';
import { WizardPayoutPayoutData } from './step/PayoutData/WizardPayoutPayoutData';
import { WizardPayoutTaxData } from './step/TaxData/WizardPayoutTaxData';
import WizardPayoutWelcome from './step/Welcome/WizardPayoutWelcome';

export const wizardPayoutSteps: WizardPayoutTypes.StepsConfig = {
  Welcome: {
    element: <WizardPayoutWelcome />,
  },
  PayoutData: {
    element: <WizardPayoutPayoutData />,
  },
  OriginatorInfo: {
    element: <WizardPayoutOriginatorInfo />,
  },
  OriginatorIDCardFront: {
    element: <WizardPayoutOriginatorIDCardFront />,
  },
  OriginatorIDCardBack: {
    element: <WizardPayoutOriginatorIDCardBack />,
  },
  OriginatorBirthdata: {
    element: <WizardPayoutOriginatorBirthdata />,
  },
  OriginatorMrfForm: {
    element: <WizardPayoutOriginatorMrfForm />,
  },
  AddressData: {
    element: <WizardPayoutAddressData />,
  },
  TaxData: {
    element: <WizardPayoutTaxData />,
  },
  FinishedAccepted: {
    element: <WizardPayoutFinishedPending />,
  },
  FinishedPending: {
    element: <WizardPayoutFinishedPending />,
  },
  FinishedRejected: {
    element: <WizardPayoutFinishedPending />,
  },
};
type State = {
  totalSteps: number;
  currentStep: WizardPayoutTypes.Step | null;
  currentField: Partial<WizardPayoutTypes.Field>;
  currentFieldError: Partial<WizardPayoutTypes.FieldError>;
  isVisible: boolean;
  onFinished?: () => void;
} & WizardPayoutTypes.Addition;

const defaultState = {
  totalSteps: possibleStepIds.length,
  currentStep: null,
  currentField: {},
  currentFieldError: {},
  isAccountFromGermany: true,
  isAccountFromEU: true,
  accountPrefillField: {},
  isVisible: false,
  onFinished: undefined,
};

export const WizardPayout: React.FC<{
  children?: React.ReactNode;
  isVisibleDefault?: boolean;
}> = ({ children, isVisibleDefault = false }) => {
  const isMounted = useRef(true);
  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);
  const { t } = useTranslation(['wizardPayout', 'validation']);

  const [state, setState] = useState<State>({
    ...defaultState,
    isVisible: isVisibleDefault,
  });
  const { totalSteps, currentStep, isVisible, onFinished } = state;

  const wizardCloseCallback = useCallback(() => {
    setState(() => defaultState);
  }, [setState]);
  const navigateToNextWizardStepCallback = useWizardNavigateToNextStepCallback(
    wizardPayoutSteps,
    () => {
      wizardCloseCallback();
      onFinished?.();
    }
  );

  const setVisible = React.useCallback(
    (isVisible: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isVisible,
      }));
    },
    [setState]
  );

  const setOnFinished = React.useCallback(
    (onFinished?: () => void) => {
      setState((prevState) => ({
        ...prevState,
        onFinished,
      }));
    },
    [setState]
  );

  const setTourStepData = useCallback(
    (
      tourData?: TourDataWizardPayoutV2Fragment | null,
      addition?: Partial<WizardPayoutTypes.Addition>
    ) => {
      const step = tourData?.currentStep ?? null;

      setState((prevState) => ({
        ...prevState,
        totalSteps: tourData?.totalSteps ?? possibleStepIds.length,
        currentStep: step,
        currentField: extractTourStepField(tourData),
        currentFieldError: extractCurrentFieldError(
          tourData?.validate?.data,
          t('validation:error.UnbekannterFehler')
        ),
        ...addition,
      }));

      if (tourData) {
        navigateToNextWizardStepCallback(tourData.currentStep?.id ?? null);
      }
    },
    [t, setState, navigateToNextWizardStepCallback]
  );

  const { loading } = useGetWizardPayoutDataV2Query({
    variables: {
      redirectUrl: paperlessOriginatorMrfRedirectUrl,
    },
    onCompleted: (data) => {
      if (!isMounted.current) {
        return;
      }
      if (data?.tour?.__typename !== 'TourPayoutV2') {
        return;
      }
      const accountPrefillField = extractFields(data);

      setTourStepData(data?.tour, { ...data?.account, accountPrefillField });
    },
    skip: !isVisible,
  });

  const [updateStepData] = useUpdateWizardPayoutDataV2Mutation({
    onCompleted: (data) => {
      if (!isMounted.current) {
        return;
      }
      if (data?.tour?.__typename !== 'TourPayoutV2Mutation') {
        return;
      }
      setTourStepData(data?.tour?.update);
    },
  });

  const wizardNextStepCallback = React.useCallback(
    async (fields?: InputTourPayoutV1) => {
      if (!currentStep?.id) {
        return;
      }
      try {
        await updateStepData({
          variables: {
            step: currentStep.id,
            data: fields ?? {},
            redirectUrl: paperlessOriginatorMrfRedirectUrl,
          },
        });
      } catch (error) {
        Logger.error(error);
      }
    },
    [updateStepData, currentStep]
  );

  const contextForSteps = React.useMemo<WizardPayoutTypes.Context>(() => {
    return {
      ...state,
      wizardCloseCallback,
      wizardNextStepCallback,
      setVisible,
      setOnFinished,
    };
  }, [
    state,
    wizardCloseCallback,
    wizardNextStepCallback,
    setVisible,
    setOnFinished,
  ]);

  return (
    <WizardPayoutContextProvider value={contextForSteps}>
      {isVisible && (
        <WizardParentModalLayout
          headerText={t('wizardPayout:heading.AuszahlungEinrichten')}
          totalSteps={totalSteps}
          currentStep={currentStep?.pos ?? 1}
          loading={loading}
          onCloseClick={wizardCloseCallback}
        >
          {currentStep &&
            wizardPayoutSteps[currentStep!.id as keyof typeof wizardPayoutSteps]
              ?.element}
        </WizardParentModalLayout>
      )}
      {children}
    </WizardPayoutContextProvider>
  );
};
