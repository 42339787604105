import { Maybe } from 'graphql/jsutils/Maybe';
import React, { createContext, useContext, useMemo, useState } from 'react';

import {
  AffiliateSalesListFragment,
  AffiliateSalesListsFragment,
  FeedSalesListsFragment,
  LivechatSalesListsFragment,
  MessengerSalesListsFragment,
  OtherSalesListsFragment,
  OverallSaleListFragment,
  OverallSalesListsFragment,
  PhoneServiceSalesListsFragment,
  SalesListFragment,
  TelegramSalesListsFragment,
  VideoLibrarySalesListsFragment,
} from '../../../../generated/graphql';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../TabContext';
import { useBarChartBarContext } from './BarChartBarProvider';

type BarChartBarTooltipContextType = {
  barChartBarTooltipData: Maybe<
    | SalesListFragment[]
    | AffiliateSalesListFragment[]
    | (SalesListFragment | AffiliateSalesListFragment)[]
  >;
  tooltipTotalTurnover: number;
  tooltipsLoading: boolean;
};

const BarChartBarTooltipContext = createContext<
  BarChartBarTooltipContextType | undefined
>(undefined);

export const useBarChartBarTooltipContext = () => {
  const context = useContext(BarChartBarTooltipContext);
  if (!context) {
    throw new Error(
      'useBarChartBarTooltipContext must be used within a BarChartBarTooltipProvider'
    );
  }
  return context;
};

const calculateOverallTotalTurnover = (
  salesLists: Maybe<OverallSaleListFragment[]>
) => {
  if (salesLists && salesLists.length > 0) {
    return salesLists.reduce((totalSum: number, salesItem: any) => {
      const itemSum = Object.entries(salesItem)
        .filter(([key, _]) => key !== '__typename')
        .reduce(
          (sum: number, entry: [string, any]) => sum + (entry[1] as number),
          0
        );
      return totalSum + itemSum;
    }, 0);
  }
  return 0;
};

const transformOverallSalesLists = (salesLists: any) => {
  if (!salesLists || salesLists.length === 0) return [];

  const transformedArray = [];

  for (const salesItem of salesLists) {
    for (const [key, value] of Object.entries(salesItem)) {
      if (key !== '__typename') {
        transformedArray.push({
          type: key,
          amount: value as number,
          amountCoins: value as number, // Assuming amountCoins is the same as amount for this example
        });
      }
    }
  }

  return transformedArray;
};

const calculateTotalTurnover = (
  salesLists: Maybe<SalesListFragment[] | AffiliateSalesListFragment[]>
) => {
  return (
    salesLists?.reduce(
      (sum: any, item: any) => sum + ((item?.sharing || item?.amount) ?? 0),
      0
    ) || 0
  );
};

type BarChartBarTooltipDataType =
  | LivechatSalesListsFragment
  | TelegramSalesListsFragment
  | VideoLibrarySalesListsFragment
  | FeedSalesListsFragment
  | MessengerSalesListsFragment
  | PhoneServiceSalesListsFragment
  | OtherSalesListsFragment
  | OverallSalesListsFragment
  | AffiliateSalesListsFragment;

export const BarChartBarTooltipProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];
  const { salesList, affiliateSalesList, salesListDataLoading } =
    useBarChartBarContext();
  const [tooltipTotalTurnover, settooltipTotalTurnover] = useState(0);

  const barChartBarTooltipData: Maybe<
    | SalesListFragment[]
    | AffiliateSalesListFragment[]
    | (SalesListFragment | AffiliateSalesListFragment)[]
  > = React.useMemo(() => {
    let data: Maybe<BarChartBarTooltipDataType>;
    let tooltipTotalTurnover: Maybe<number>;
    let tooltipData: Maybe<
      | SalesListFragment[]
      | AffiliateSalesListFragment[]
      | (SalesListFragment | AffiliateSalesListFragment)[]
    >;
    switch (tabId) {
      case StatisticsDetailedCategoryExtEnum.Feed:
        data = salesList?.feedResponse;
        const tipsTotal = calculateTotalTurnover(data?.tipsLists);
        const salesTotal = calculateTotalTurnover(data?.salesLists);
        tooltipTotalTurnover = tipsTotal + salesTotal;
        tooltipData = (data?.tipsLists ?? [])?.concat(data?.salesLists ?? []);
        break;
      case StatisticsDetailedCategoryExtEnum.LiveChat:
        data = salesList?.liveChatResponse;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      case StatisticsDetailedCategoryExtEnum.Messenger:
        data = salesList?.messengerResponse;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      case StatisticsDetailedCategoryExtEnum.VideoLibrary:
        data = salesList?.videoLibraryResponse;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      case StatisticsDetailedCategoryExtEnum.PhoneService:
        data = salesList?.phoneServiceResponse;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      case StatisticsDetailedCategoryExtEnum.Misc:
        data = salesList?.miscResponse;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      case StatisticsDetailedCategoryExtEnum.Telegram:
        data = salesList?.telegramResponse;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      case StatisticsDetailedCategoryExtEnum.OverAll:
        data = salesList?.overallResponse;
        const totalTurnover = calculateOverallTotalTurnover(data?.salesLists);
        const affiliateTotal = calculateTotalTurnover(
          affiliateSalesList?.salesLists
        );
        tooltipTotalTurnover = totalTurnover + affiliateTotal;
        const affiliateData = {
          type: 'Affiliate',
          amount: affiliateTotal,
          amountCoins: 0,
        };
        tooltipData = transformOverallSalesLists(data?.salesLists ?? []).concat(
          affiliateData
        );
        break;
      case StatisticsDetailedCategoryExtEnum.Affiliate:
        data = affiliateSalesList;
        tooltipTotalTurnover = calculateTotalTurnover(data?.salesLists);
        tooltipData = data?.salesLists;
        break;
      default:
        break;
    }

    settooltipTotalTurnover(tooltipTotalTurnover ?? 0);

    const sortedTooltipData =
      tooltipData
        ?.filter((value: any) => value?.amount > 0 || value?.sharing > 0)
        ?.sort((a: any, b: any) => {
          if (tabId !== StatisticsDetailedCategoryExtEnum.Affiliate) {
            return b?.amount - a?.amount;
          } else {
            return b?.sharing - a?.sharing;
          }
        }) ?? [];

    return sortedTooltipData;
  }, [
    affiliateSalesList,
    salesList?.feedResponse,
    salesList?.liveChatResponse,
    salesList?.messengerResponse,
    salesList?.miscResponse,
    salesList?.overallResponse,
    salesList?.phoneServiceResponse,
    salesList?.telegramResponse,
    salesList?.videoLibraryResponse,
    tabId,
  ]);

  const value = useMemo(
    () => ({
      barChartBarTooltipData,
      tooltipTotalTurnover,
      tooltipsLoading: salesListDataLoading,
    }),
    [barChartBarTooltipData, salesListDataLoading, tooltipTotalTurnover]
  );

  return (
    <BarChartBarTooltipContext.Provider value={value}>
      {children}
    </BarChartBarTooltipContext.Provider>
  );
};
