import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { useWizardOnContinueCallback } from '../../../../../hooks/useWizardOnContinueCallback';
import { noop } from '../../../../../utils';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';
import { WPPayoutData } from './WizardPayoutPayoutDataTypes';
import WizardPayoutPayoutDataBankTransferTab from './tabs/bankTransfer/WizardPayoutPayoutDataBankTransferTab';
import WizardPayoutPayoutDataPaxumTab from './tabs/paxum/WizardPayoutPayoutDataPaxumTab';

const bankTransferTabValue = 'bankTransferTab' as const;
const paxumTabValue = 'paxumTab' as const;

const tabs = [bankTransferTabValue, paxumTabValue] as const;
type TabId = (typeof tabs)[number];

export const WizardPayoutPayoutData: React.FC = () => {
  const { t } = useTranslation(['general', 'wizardPayout']);
  const wizard = useWizardPayout();

  const isAccountFromGermany = wizard.isAccountFromGermany ?? false;

  const isBankTransferDisabled = false;
  const isPaxumDisabled = isAccountFromGermany;
  const isPaxumPreselected = !isAccountFromGermany;
  // BE sends isAccountFromEU as false when isAccountFromGermany true
  // As a workaround always enable autofill, when a matching iban was found
  const isAutoQueryBicEnabled = true;

  const isSomePayoutMethodDisabled = isPaxumDisabled || isBankTransferDisabled;

  const [selectedTab, setSelectedTab] = useState<TabId>(
    isPaxumPreselected ? paxumTabValue : bankTransferTabValue
  );

  const bankTransferFormik = useFormik({
    initialValues: WPPayoutData.bankTransferFormInit,
    onSubmit: noop,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      [WPPayoutData.accountHolderInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutBankaccountOwner?.minLength ?? 1,
        maxLength: wizard.currentField?.payoutBankaccountOwner?.maxLength ?? 70,
      }),
      [WPPayoutData.ibanInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutIban?.minLength ?? 5,
        maxLength: wizard.currentField?.payoutIban?.maxLength ?? 34,
      }),
      [WPPayoutData.bicInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutBic?.minLength ?? 8,
        maxLength: wizard.currentField?.payoutBic?.maxLength ?? 11,
      }),
    }),
  });

  const paxumFormik = useFormik({
    initialValues: WPPayoutData.paxumFormInit,
    validateOnBlur: true,
    onSubmit: noop,
    validationSchema: Yup.object().shape({
      [WPPayoutData.paxumEmailInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutPaxumEmailAccount?.minLength ?? 1,
        maxLength:
          wizard.currentField?.payoutPaxumEmailAccount?.maxLength ?? 60,
        isEmail: true,
      }),
    }),
  });

  const onContinue = useWizardOnContinueCallback({
    wizard,
    currentSelected: selectedTab,
    formiks: {
      [bankTransferTabValue]: bankTransferFormik,
      [paxumTabValue]: paxumFormik,
    },
  });

  return (
    <WizardParentModalStepLayout
      isContentLoading={false}
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t(
            'wizardPayout:heading.AufWelchesKontoSollenWirDeinGeldUberweisen'
          )}
          children={
            <Tabs
              defaultIndex={isPaxumPreselected ? 1 : 0}
              onChange={(index) => {
                setSelectedTab(tabs[index]);
              }}
              isFitted
            >
              <TabList
                display={isSomePayoutMethodDisabled ? 'none' : undefined}
              >
                <Tab isDisabled={isBankTransferDisabled}>
                  {t('wizardPayout:heading.Bankuberweisung')}
                </Tab>
                <Tab isDisabled={isPaxumDisabled}>
                  {t('wizardPayout:heading.Paxum')}
                </Tab>
              </TabList>
              <TabPanels pt={6} mb={8}>
                <TabPanel p={0}>
                  <WizardPayoutPayoutDataBankTransferTab
                    isIbanEnforced={isAccountFromGermany}
                    form={bankTransferFormik}
                    autoQueryBicEnabled={isAutoQueryBicEnabled}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <WizardPayoutPayoutDataPaxumTab form={paxumFormik} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          }
        />
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.Weiter')}
            onClick={onContinue}
          />
        </ButtonStack>
      }
    />
  );
};
