import { Heading, Img, StackProps, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import payoutImage from '../../../../../../../assets/images/finance/setup-payout.svg';

const StyledEmptyState: React.FC<StackProps> = (props) => (
  <VStack
    alignItems="center"
    justifyItems="center"
    textAlign="center"
    p="4"
    {...props}
  />
);

export const FinanceYourWithdrawalsEmptyYear: React.FC = () => {
  const { t } = useTranslation(['payout', 'financePage']);

  return (
    <StyledEmptyState>
      <Img
        src={payoutImage}
        alt={t('financePage:img.KundeZahltWahrendEinerShow')}
      />
      <Heading
        as="h4"
        size="xl"
        children={t(
          'payout:headline.InDiesemJahrHastDuKeineAuszahlungAusgefuhrt'
        )}
      />
    </StyledEmptyState>
  );
};
