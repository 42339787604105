import { ContainerProps } from '@chakra-ui/react';
import React from 'react';

import {
  FeedPost,
  FeedPostBody,
  FeedPostButtonWrapper,
  FeedPostComments,
  FeedPostDate,
  FeedPostFooter,
  FeedPostFooterMetrics,
  FeedPostHeader,
  FeedPostHeaderStack,
  FeedPostHeaderTitle,
  FeedPostHeadline,
  FeedPostMenu,
  FeedPostModelImage,
  FeedPostPin,
  FeedPostStatisticsBanner,
  FeedPostText,
} from '../../../../../components/shared/FeedPost/FeedPost';
import { ScheduledForDateTimeBatch } from '../../../../../components/shared/ScheduledForDateTimeBatch/ScheduledForDateTimeBatch';
import {
  FeedTextPostFragment,
  PostStatusEnum,
} from '../../../../../generated/feed';
import { useFeedPostStatistics } from '../../../../../provider/FeedPostStatisticsProvider';
import { usePostCommenting } from '../../../../../provider/PostCommentingProvider';
import { useFeedPostUploadProgress } from '../../../hooks/useFeedPostUploadProgress';
import { useFeedPostWrapper } from '../../../hooks/useFeedPostWrapper';
import { PostFunctionProps } from '../../../posts';
import { useGetRejectionReason } from '../../../utils';
import { TempFeedPostScheduleProp } from '../component/types';

export const TextPost: React.FC<
  {
    containerProps?: ContainerProps;
    tags?: string[];
    isPostOwnedByUser?: boolean;
  } & TempFeedPostScheduleProp &
    FeedTextPostFragment &
    PostFunctionProps
> = ({
  id,
  status,
  model,
  created,
  pinned,
  title,
  text,
  likes,
  comments,
  tips,
  isPublished,
  publicationScheduledFor,
  price,
  rejected,
  deRejection,
  enRejection,
  onClickOnPin,
  onClickOnLikes,
  onClickOnTips,
  onClickOnEdit,
  onClickOnDelete,
  containerProps,
  isPostOwnedByUser = true,
}) => {
  const uploadProgressElement = useFeedPostUploadProgress({
    status,
    postId: id,
  });
  const feedPostStatisticsCtx = useFeedPostStatistics();
  const commentingCtx = usePostCommenting();

  const isScheduled = status === PostStatusEnum.Scheduled;

  const rejectionReason = useGetRejectionReason(deRejection, enRejection);

  const { highlightColor, labelElement } = useFeedPostWrapper({
    status,
    onDeletePost: () => onClickOnDelete(id),
    rejectionReason,
  });

  return (
    <FeedPost
      postId={id}
      highlightColor={highlightColor}
      labelElement={labelElement}
      {...containerProps}
    >
      <FeedPostHeader>
        <FeedPostModelImage
          src={model.avatar?.url}
          srcSet={model.avatar?.srcSet}
        />
        <FeedPostHeaderStack>
          <FeedPostHeaderTitle children={model.name} />
          {isScheduled && publicationScheduledFor ? (
            <ScheduledForDateTimeBatch dateTime={publicationScheduledFor} />
          ) : (
            <FeedPostDate children={created} />
          )}
        </FeedPostHeaderStack>
        {isPostOwnedByUser && (
          <FeedPostButtonWrapper>
            <FeedPostPin
              isPinned={pinned}
              onClickOnPin={() => onClickOnPin(id, pinned)}
            />
            <FeedPostMenu
              onClickOnEdit={onClickOnEdit}
              onClickOnDelete={() => onClickOnDelete(id)}
            />
          </FeedPostButtonWrapper>
        )}
      </FeedPostHeader>
      <FeedPostBody py={'2'} pl={'4'} pr={'4'}>
        {!uploadProgressElement ? <></> : <>{uploadProgressElement}</>}
        {title && <FeedPostHeadline children={title} />}
        {text && <FeedPostText children={text} />}
      </FeedPostBody>
      <FeedPostStatisticsBanner
        status={status}
        onClick={() => feedPostStatisticsCtx.action.showModal(id)}
      />
      <FeedPostFooter>
        <FeedPostFooterMetrics
          likes={likes}
          comments={comments.totalCount}
          tips={tips.count}
          isPaidContent={!!price}
          purchaseCount={0}
          onClickOnLikes={onClickOnLikes}
          onClickOnComments={() => commentingCtx.action.showModal(id)}
          onClickOnTips={onClickOnTips}
        />
        <FeedPostComments
          commentCount={comments.totalCount}
          onClickOnCommentButton={() => commentingCtx.action.showModal(id)}
        />
      </FeedPostFooter>
    </FeedPost>
  );
};
