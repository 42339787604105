import * as React from 'react';

export type SafeAreaInsetSurroundOption =
  | 'x'
  | 'y'
  | 'l'
  | 'r'
  | 't'
  | 'b'
  | 'xb'
  | 'xt'
  | 'yl'
  | 'yr'
  | 'full'
  | 'none';
export type SafeAreaInsetOptions = {
  surroundPadding: SafeAreaInsetSurroundOption;
  surroundMargin: SafeAreaInsetSurroundOption;
};
export const useSafeAriaInset = ({
  surroundPadding = 'none',
  surroundMargin = 'none',
}: Partial<SafeAreaInsetOptions>) => {
  const paddings = React.useMemo(
    () => ({
      pl: ['l', 'x', 'xb', 'xt', 'yl', 'full'].includes(surroundPadding)
        ? 'env(safe-area-inset-left, 0px)'
        : undefined,
      pr: ['r', 'x', 'xb', 'xt', 'yr', 'full'].includes(surroundPadding)
        ? 'env(safe-area-inset-right, 0px)'
        : undefined,
      pt: ['t', 'y', 'yl', 'yr', 'xt', 'full'].includes(surroundPadding)
        ? 'env(safe-area-inset-top, 0px)'
        : undefined,
      pb: ['b', 'y', 'yl', 'yr', 'xb', 'full'].includes(surroundPadding)
        ? 'env(safe-area-inset-bottom, 0px)'
        : undefined,
    }),
    [surroundPadding]
  );

  const margins = React.useMemo(
    () => ({
      ml: ['l', 'x', 'xb', 'xt', 'yl', 'full'].includes(surroundMargin)
        ? 'env(safe-area-inset-left, 0px)'
        : undefined,
      mr: ['r', 'x', 'xb', 'xt', 'yr', 'full'].includes(surroundMargin)
        ? 'env(safe-area-inset-right, 0px)'
        : undefined,
      mt: ['t', 'y', 'yl', 'yr', 'xt', 'full'].includes(surroundMargin)
        ? 'env(safe-area-inset-top, 0px)'
        : undefined,
      mb: ['b', 'y', 'yl', 'yr', 'xb', 'full'].includes(surroundMargin)
        ? 'env(safe-area-inset-bottom, 0px)'
        : undefined,
    }),
    [surroundMargin]
  );

  return { paddings, margins } as const;
};
