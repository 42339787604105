import React from 'react';
import { useTranslation } from 'react-i18next';

import verificationWaitStateImage from '../../../../../assets/images/wizard/verification-wait-state.svg';
import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { WizardIllustratedInstruction } from '../../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { WizardVerificationBasicProps } from '../../WizardVerification';

export const wizardVerificationFinishedTestingIds = {
  continueButton: 'wizard-verification-finished-continue-button',
};

type WizardVerificationFinishedProps = Required<
  Pick<WizardVerificationBasicProps, 'closeWizard'>
>;

export const WizardVerificationFinished: React.FC<
  WizardVerificationFinishedProps
> = ({ closeWizard }) => {
  const { t } = useTranslation(['wizardVerification', 'general']);
  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardIllustratedInstruction
          illustrationSrc={verificationWaitStateImage}
          illustrationAlt={t('general:img.ZuBearbeitendesDokument')}
          header={t('wizardVerification:finished.subHeader')}
        />
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardVerification:finished.continueButtonText')}
            onClick={closeWizard}
          />
        </ButtonStack>
      }
    />
  );
};
