import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const ZStack: React.FC<BoxProps> = (props) => (
  <Box
    display={'grid'}
    gridTemplateAreas={'stack'}
    sx={{ '&>*': { gridArea: 'stack' } }}
    flexShrink={0}
    {...props}
  />
);
