import * as icons from '@campoint/odi-ui-icons';
import { Box, Button, Heading, Icon, Text } from '@chakra-ui/react';
import { t } from 'i18next';

import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import { ContestTileBase, ContestTileHeaderImage } from './ContestTileBase';

export const ContestTileUploadApproved: typeof ContestTileBase = (props) => {
  return (
    <ContestTileBase {...props}>
      <ContestTileHeaderImage contest={props.contest} />
      <Box p={5} textAlign={'center'}>
        <Icon
          as={icons.CheckCircle}
          boxSize="icon.xl"
          color={'lime.500'}
          fontSize={'1.2em'}
          mt={8}
          mb={3}
        />
        <Heading size="sm">{t('contest:heading.TeilnahmeErfolgreich')}</Heading>
        <Text lineHeight={1.2} color={'gray.300'}>
          {t(
            'contest:text.DeinVideoFindestDuInDerVideothekWahrendDesWettbewerbsKannstDuDasVideXX',
            {
              type:
                props.contest.type === MediaContestTypeEnum.Photo
                  ? t('contest:label.Photo')
                  : t('contest:label.Video'),
            }
          )}
        </Text>
        <Button mt={3} onClick={props.goToMedia}>
          {t('contest:button.ZumVideo', {
            type:
              props.contest.type === MediaContestTypeEnum.Photo
                ? t('contest:label.Photo')
                : t('contest:label.Video'),
          })}
        </Button>
      </Box>
    </ContestTileBase>
  );
};
