export const getLengthInMinutes = (length: number | undefined): string => {
  if (length === undefined) {
    return '';
  }

  const zeroPadding = (num: number) => num.toString().padStart(2, '0');

  const minutes = Math.floor(length / 60);
  const seconds = length - minutes * 60;

  return `${zeroPadding(minutes)}:${zeroPadding(seconds)} min`;
};
