import {
  FinancesPayoutMethodEnum,
  ProfileFieldsEnum,
} from '../../../../generated/graphql';

export namespace WPBankData {
  export const accountHolderInputName =
    ProfileFieldsEnum.PayoutBankaccountOwner;
  export const ibanInputName = ProfileFieldsEnum.PayoutIban;
  export const bicInputName = ProfileFieldsEnum.PayoutBic;
  export const paxumEmailInputName = ProfileFieldsEnum.PayoutPaxumEmailAccount;
  export const transactionTypeName = ProfileFieldsEnum.PayoutTransactionType;

  export type BankTransferFormValues = {
    [transactionTypeName]: FinancesPayoutMethodEnum;
    [accountHolderInputName]: string;
    [ibanInputName]: string;
    [bicInputName]: string;
  };

  export type PaxumFormValues = {
    [transactionTypeName]: FinancesPayoutMethodEnum;
    [paxumEmailInputName]: string;
  };

  export const bankTransferFormInit: WPBankData.BankTransferFormValues = {
    [transactionTypeName]: FinancesPayoutMethodEnum.Bank,
    [accountHolderInputName]: '',
    [ibanInputName]: '',
    [bicInputName]: '',
  };

  export const paxumFormInit: WPBankData.PaxumFormValues = {
    [transactionTypeName]: FinancesPayoutMethodEnum.Paxum,
    [paxumEmailInputName]: '',
  };

  export type TabComponentHandle = {
    saveDataHandler: () => Promise<any>;
  };
}
