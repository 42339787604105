import React from 'react';

import { InputList, InputListProps } from './InputList';

export const ReadOnlyInputList: React.FC<InputListProps> = ({
  children,
  ...props
}) => {
  return (
    <InputList gap={4} columnGap={0} {...props}>
      {children}
    </InputList>
  );
};
