import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Group, Info, Movie } from '@campoint/odi-ui-icons';
import { Divider, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { FetchMoreIndicator } from '../../../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import { UserListPurchasesFragment } from '../../../../generated/graphql';
import {
  getFormattedAmount,
  getFormattedCoins,
} from '../../../../utils/formattedAmount';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../../provider/TabContext';
import { useUserListContext } from '../../provider/UserListContext';

type DetailedStatisticsUserListProps = {};

export const DetailedStatisticsUserList: React.FC<
  DetailedStatisticsUserListProps
> = () => {
  const { t } = useTranslation(['statistic']);
  const { tabIds, tabIndex } = useTabsContext();
  const { data, loading, error, handleLoadMore } = useUserListContext();
  const locale = useIntl().locale;

  const groupPurchasesByDate = (
    purchases: Maybe<UserListPurchasesFragment>[]
  ): Record<string, Maybe<UserListPurchasesFragment>[]> => {
    const groupedByDate: Record<string, Maybe<UserListPurchasesFragment>[]> =
      {};
    purchases?.forEach((purchase) => {
      if (purchase?.date) {
        const dateKey = DateTime.fromISO(purchase.date).toISODate();
        groupedByDate[dateKey] = [...(groupedByDate[dateKey] || []), purchase];
      }
    });
    return groupedByDate;
  };

  const userList = groupPurchasesByDate(data);

  const userListItems = Object.keys(userList);
  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Group} />
            <SectionTitle>{t('statistic:heading.Userliste')}</SectionTitle>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted={true} />
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          <VStack
            gap={4}
            divider={
              userListItems.length === 0 ? (
                <></>
              ) : (
                <Divider size={'s'} color={'steel'} />
              )
            }
          >
            {userListItems.length === 0 ? (
              <VStack gap={4}>
                <Icon as={Info} boxSize={'icon.md'} color={'steel'} />
                <Text
                  fontWeight={'bold'}
                  color={'steel'}
                  lineHeight={'32px'}
                  fontSize={'xl'}
                >
                  {t('statistic:text.KeineDatenVorhanden')}
                </Text>
              </VStack>
            ) : (
              userListItems?.map((date, index) => {
                const formattedDate = date
                  ? DateTime.fromISO(date).toFormat('dd.MM.yyyy')
                  : '-';

                return (
                  <VStack
                    key={`${index}-${formattedDate}`}
                    gap={2}
                    w={'full'}
                    divider={<Divider size={'s'} color={'steel'} gap={0} />}
                  >
                    <>
                      <Text
                        p={'2px 8px'}
                        lineHeight={'20px'}
                        border={'1px'}
                        borderRadius={'46px'}
                        borderColor={'darkSteel'}
                        fontSize={{ base: '12px', lg: 'sm' }}
                        fontWeight={'medium'}
                      >
                        {formattedDate}
                      </Text>
                    </>
                    {userList[date]?.map((purchase, index) => {
                      const key = `${index}+${purchase?.transactionId}`;

                      const amount =
                        (purchase?.amountCoins ?? 0) > 0
                          ? `${getFormattedCoins(
                              purchase?.amountCoins ?? 0
                            )} VXC`
                          : getFormattedAmount(purchase?.amount ?? 0, locale);
                      //render list item dependend on tab
                      const tab = tabIds[tabIndex];
                      let listItem = null;
                      if (
                        tab ===
                          StatisticsDetailedCategoryExtEnum.VideoLibrary ||
                        tab === StatisticsDetailedCategoryExtEnum.Feed
                      ) {
                        listItem = (
                          <DetailedStatisticsVideoPurchaseListItem
                            purchase={purchase}
                            amount={amount}
                            icon={
                              tab ===
                              StatisticsDetailedCategoryExtEnum.VideoLibrary
                                ? Movie
                                : null
                            }
                          />
                        );
                      } else if (
                        tab === StatisticsDetailedCategoryExtEnum.Messenger
                      ) {
                        listItem = (
                          <DetailedStatisticsMessengerPurchaseListItem
                            purchase={purchase}
                            amount={amount}
                          />
                        );
                      } else {
                        listItem = (
                          <DetailedStatisticsPurchaseListItem
                            purchase={purchase}
                            amount={amount}
                          />
                        );
                      }

                      return <Fragment key={key}>{listItem}</Fragment>;
                    })}
                  </VStack>
                );
              })
            )}
            <FetchMoreIndicator
              mt={{ base: '8', lg: '12' }}
              loading={loading}
              error={error}
              onIsVisible={handleLoadMore}
            />
          </VStack>
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

const DetailedStatisticsPurchaseListItem: React.FC<{
  amount: string;
  purchase: Maybe<UserListPurchasesFragment>;
}> = ({ amount, purchase }) => {
  const minutes = Math.floor((purchase?.duration ?? 0) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = ((purchase?.duration ?? 0) % 60).toString().padStart(2, '0');
  const formattedMinutesSeconds = `${minutes}m ${seconds}s`;
  return (
    <HStack alignSelf={'start'} w={'full'} spacing={4}>
      <Text fontWeight={'normal'} lineHeight={'20px'} fontSize={'sm'} flex={1}>
        {purchase?.guestName}
      </Text>
      <Text fontWeight={'normal'} lineHeight={'20px'} fontSize={'sm'}>
        {formattedMinutesSeconds}
      </Text>
      <Text
        fontWeight={'medium'}
        lineHeight={'20px'}
        fontSize={'md'}
        minW={'70px'}
        textAlign={'right'}
      >
        {amount}
      </Text>
    </HStack>
  );
};

const DetailedStatisticsMessengerPurchaseListItem: React.FC<{
  amount: string;
  purchase: Maybe<UserListPurchasesFragment>;
}> = ({ amount, purchase }) => {
  const { t } = useTranslation(['statisticsPage']);
  const type =
    purchase?.type === 'PROV_GIFT' ? t('text.Geschenk') : t('text.Messenger');
  return (
    <HStack alignSelf={'start'} w={'full'} spacing={4}>
      <Text fontWeight={'normal'} lineHeight={'20px'} fontSize={'sm'} flex={1}>
        {purchase?.guestName}
      </Text>
      <Text fontWeight={'normal'} lineHeight={'20px'} fontSize={'sm'}>
        {type}
      </Text>
      <Text
        fontWeight={'medium'}
        lineHeight={'20px'}
        fontSize={'md'}
        minW={'70px'}
        textAlign={'right'}
      >
        {amount}
      </Text>
    </HStack>
  );
};

const DetailedStatisticsVideoPurchaseListItem: React.FC<{
  amount: string;
  purchase: Maybe<UserListPurchasesFragment>;
  icon?: any;
}> = ({ amount, purchase, icon }) => {
  return (
    <HStack alignSelf={'start'} w={'full'} spacing={4}>
      <VStack flex={1} alignItems={'flex-start'}>
        <Text
          fontWeight={'normal'}
          lineHeight={'20px'}
          fontSize={'sm'}
          flex={1}
        >
          {purchase?.guestName}
        </Text>
        <HStack>
          {icon && <Icon as={icon} color={'gray.300'} />}
          <Text
            fontWeight={'normal'}
            lineHeight={'20px'}
            fontSize={'sm'}
            color={'gray.300'}
            noOfLines={1}
            overflow={'hidden'}
          >
            {purchase?.contentTitle}
          </Text>
        </HStack>
      </VStack>
      <Text
        fontWeight={'medium'}
        lineHeight={'20px'}
        fontSize={'md'}
        minW={'70px'}
        textAlign={'right'}
      >
        {amount}
      </Text>
    </HStack>
  );
};
