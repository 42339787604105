import { DateTime, Interval } from 'luxon';

/**
 * Prepares a list of relevant year numbers
 * In case of no invoices from year of today till year of signup
 * In case of invoices the above and possibly extended by min/max dates of invocies
 *
 * @example
 * [2020, 2021, 2022]
 */
export const prepareYearBuckets = (
  today: DateTime,
  signupDate?: Date | null,
  minInvoicePayoutDate?: Date | null,
  maxInvoicePayoutDate?: Date | null
) => {
  // min Date
  const minDateCandidates = [today];
  if (signupDate) {
    const date = DateTime.fromJSDate(signupDate);
    minDateCandidates.push(date);
  }
  if (minInvoicePayoutDate) {
    const date = DateTime.fromJSDate(minInvoicePayoutDate);
    minDateCandidates.push(date);
  }

  const todayMinusThreeYears = today.startOf('year').minus({ year: 3 });
  const min = DateTime.min(...minDateCandidates);

  const calculatedMinYear = DateTime.max(min, todayMinusThreeYears).startOf(
    'year'
  );

  // max Date
  const maxDateCandidates = [today];
  if (maxInvoicePayoutDate) {
    const date = DateTime.fromJSDate(maxInvoicePayoutDate);
    maxDateCandidates.push(date);
  }

  const max = DateTime.max(...maxDateCandidates);

  // Luxons Intervals are half open = [)
  const totalTimespan = Interval.fromDateTimes(
    calculatedMinYear.startOf('year'),
    max.startOf('year').plus({ year: 1 })
  );

  const yearlyTimespans = totalTimespan.splitBy({ year: 1 });
  return yearlyTimespans.map((interval) => interval.start.year);
};
