import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Icon,
} from '@chakra-ui/react';
import { __DEV__ } from '@chakra-ui/utils';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import * as React from 'react';

export type IconNavButtonGroupProps = {
  isMobile?: boolean;
} & ButtonGroupProps;

export type IconNavButtonProps = {
  isMobile?: boolean;
  /**
   * Whether the Nav item is active (Current Url === IconNavButton Url)
   * @default false
   */
  isActiveRoute?: boolean;
  /**
   * Icon
   */
  icon?: EmotionIcon;
  /**
   * Icon Size (number * 4px)
   */
  iconSize?: number;
  /**
   * Set new Route
   */
  onClick?: () => void;
} & ButtonProps;

/* -------------------------------------------------------------------------------------------------
 * Icon Nav Button Group
 * -----------------------------------------------------------------------------------------------*/

export const IconNavButtonGroup: React.FC<IconNavButtonGroupProps> = ({
  children,
  ...props
}) => {
  const { isMobile, ...rest } = props;

  return (
    <ButtonGroup
      spacing={4}
      {...(isMobile && {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
      })}
      {...rest}
    >
      {children}
    </ButtonGroup>
  );
};

if (__DEV__) {
  IconNavButtonGroup.displayName = 'IconNavButtonGroup';
}

/* -------------------------------------------------------------------------------------------------
 * Icon Nav Button
 * -----------------------------------------------------------------------------------------------*/

export const IconNavButton: React.FC<IconNavButtonProps> = ({
  children,
  ...props
}) => {
  const { isMobile, isActiveRoute, icon, iconSize, onClick, ...rest } = props;
  return (
    <Button
      {...(isActiveRoute ? { color: `gray.900` } : { color: 'whiteAlpha.600' })}
      {...(icon &&
        !isMobile && {
          leftIcon: <Icon as={icon} color={'gray.900'} />,
        })}
      fontWeight="400"
      variant="ghost"
      size="md"
      position="relative"
      borderRadius={'none'}
      {...(isMobile ? { height: '50px' } : { height: '58px' })}
      borderBottom="3px solid transparent"
      borderTop="3px solid transparent"
      sx={{
        '&:hover::before': {
          transform: 'scaleX(1)',
        },
      }}
      _hover={{
        cursor: 'pointer',
        bg: 'transparent',
        color: 'white',
      }}
      _active={{
        bg: 'transparent',
      }}
      _before={{
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 'full',
        height: '4px',
        bottom: isMobile ? 'initial' : '-3px',
        top: isMobile ? '-3px' : 'initial',
        left: '0',
        bgGradient: 'linear(to-r, primary.from, primary.to)',
        transform: isActiveRoute ? 'scaleX(1)' : 'scaleX(0)',
        transition: 'transform 0.3s ease',
      }}
      onClick={onClick}
      {...rest}
    >
      {isMobile && (
        <Icon
          as={icon}
          boxSize={iconSize}
          marginBottom={`${iconSize}px`}
          color={isActiveRoute ? 'primary.500' : 'gray.900'}
        />
      )}
      {children}
    </Button>
  );
};

if (__DEV__) {
  IconNavButton.displayName = 'IconNavButton';
}
