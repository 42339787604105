import { useState } from 'react';

export const useCyclicIndex = (maxRange: number, startingIndex = 0) => {
  const [index, setIndex] = useState(startingIndex);

  const incrementIndex = () =>
    setIndex((prevState) => keepInRange(prevState + 1, maxRange));

  const decrementIndex = () =>
    setIndex((prevState) => keepInRange(prevState - 1, maxRange));

  return { index, incrementIndex, decrementIndex, setIndex };
};

const keepInRange = (_: number, maxRange: number) => {
  const n = Math.max(1, maxRange);
  return (((_ + n) % n) + n) % n;
};
