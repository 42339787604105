import { Heading } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileFieldTypePictureFragment } from '../../../../../generated/graphql';
import { CommonMediaFlowModal } from '../../../../../pages/ModelProfileV2Page/components/CommonMediaFlowModal/CommonMediaFlowModal';
import { useModelProfile } from '../../../../../pages/ModelProfileV2Page/provider/ModelProfileProvider';
import { MediaFlowProvider } from '../../../../../provider/MediaFlowProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { MediaUploadProvider } from '../../../../../provider/MediaUploadProvider';
import { InlineGuideDot } from '../../../GuideDot/GuideDot';
import { MediaPreviewFlowTriggerBox } from '../../../MediaPreviewBox/MediaPreviewBox';

const TitlePictureHeading = () => {
  const { t } = useTranslation(['modelProfile']);
  const { isGuideDotVisible } = useModelProfile();
  return (
    <Heading size={{ base: 'xl', lg: '3xl' }}>
      {t('modelProfile:headerSection.Titelbildvideo')}
      <InlineGuideDot isShown={isGuideDotVisible} />
    </Heading>
  );
};

const TitlePictureMediaPreviewBox = () => {
  return (
    <MediaPreviewFlowTriggerBox
      aspectRatioProps={{ w: 'full' }}
      imageProps={{ sizes: '(max-width: 1280px) 100vw, 1280px' }}
    >
      <TitlePictureHeading />
    </MediaPreviewFlowTriggerBox>
  );
};

const TitlePictureMediaFlowModal = () => {
  return (
    <CommonMediaFlowModal
      mediaPickerPreviewBoxProps={{
        imageProps: {
          sizes: '600px',
        },
      }}
    />
  );
};

export function TitlePicture(props: {
  field?: ProfileFieldTypePictureFragment | null;
  isEditable?: boolean;
}) {
  const {
    actions: { saveProfileData },
  } = useModelProfile();

  const onPersist = React.useCallback(
    async (id: number) => {
      await saveProfileData({
        mediaData: {
          modelPictureTitleLandscape: id,
        },
      });
    },
    [saveProfileData]
  );

  return (
    <MediaPropertiesProvider.ForTitlePicture>
      <MediaProvider.FromMediaPicture
        picture={props.field?.value}
        isEditable={props.isEditable}
        initialSize={{ width: 500, height: 200 }}
      >
        <MediaFlowProvider>
          <MediaUploadProvider.BasedOnNamedProperty
            fieldName={props.field?.name}
            onPersist={onPersist}
          >
            <TitlePictureMediaPreviewBox />
            <TitlePictureMediaFlowModal />
          </MediaUploadProvider.BasedOnNamedProperty>
        </MediaFlowProvider>
      </MediaProvider.FromMediaPicture>
    </MediaPropertiesProvider.ForTitlePicture>
  );
}
