import {
  Home,
  PermContactCalendar,
  Savings,
  VxLive,
} from '@campoint/odi-ui-icons';
import { Box, Center, Icon, IconButton, Spacer, Text } from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthUserPermissionEnum } from '../../generated/graphql';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { useAuth } from '../../provider/AuthProvider';
import { ShowCreateFeedPostModalNavButton } from '../../provider/FeedPostCreateModalProvider';
import {
  WelcomeTourStep,
  createWelcomeTourDataProps,
} from '../../provider/TourProvider/tours';
import { useVxLiveLink } from '../../provider/VxLiveLinkProvider';
import { routes } from '../../routes/routesConfig';
import { IconNavButton, IconNavButtonGroup } from '../shared/IconNav/iconNav';

interface NavBarItem {
  icon: EmotionIcon;
  openInNewTab: boolean;
  url: string;
}

type DesktopNavButtonGroupProps = {
  feedFlag: boolean;
  isAllowedToUseFeed: boolean;
  navBarItems: {
    home: NavBarItem;
    vxLive: NavBarItem;
    finance: NavBarItem;
    profile: NavBarItem;
  };
  isCurrentUrl(url: string): boolean;
};

type NavButton = {
  id: number;
  onClick(): void;
  icon: EmotionIcon;
  isActiveRoute: boolean;
  buttonText: string;
  isHidden?: boolean;
};

export const DesktopNavButtonGroup: React.FC<DesktopNavButtonGroupProps> = ({
  feedFlag,
  isAllowedToUseFeed,
  navBarItems,
  isCurrentUrl,
}: DesktopNavButtonGroupProps) => {
  const { t } = useTranslation(['navigation', 'home', 'general']);
  const historyWithStorage = useHistoryWithStorage();
  const {
    actions: { redirectToVxLive },
  } = useVxLiveLink();
  const authCtx = useAuth();

  const navButtons: NavButton[] = [
    {
      id: 1,
      onClick: () => historyWithStorage.push(routes.home.path),
      icon: Home,
      isActiveRoute: isCurrentUrl(routes.home.path),
      buttonText: t('navigation:iconNav.home'),
    },
    {
      id: 2,
      onClick: () => historyWithStorage.push(routes.finance.path),
      icon: Savings,
      isActiveRoute: isCurrentUrl(routes.finance.path),
      buttonText: t('navigation:iconNav.finance'),
      isHidden: !authCtx.authUser?.permissions.includes(
        AuthUserPermissionEnum.FinanceView
      ),
    },
    {
      id: 3,
      onClick: () => historyWithStorage.push(navBarItems.profile.url),
      icon: PermContactCalendar,
      isActiveRoute:
        isCurrentUrl(routes.feed.path) || isCurrentUrl(routes.profileV2.path),
      buttonText: t('navigation:iconNav.profile'),
    },
  ];

  return (
    <>
      <IconNavButtonGroup>
        {navButtons.map((navBtn) => {
          const { isHidden, id, onClick, icon, isActiveRoute, buttonText } =
            navBtn;
          if (isHidden) {
            return <React.Fragment key={id} />;
          }
          return (
            <IconNavButton
              key={id}
              onClick={onClick}
              icon={icon}
              isActiveRoute={isActiveRoute}
            >
              <Text color={'gray.900'}>{buttonText}</Text>
            </IconNavButton>
          );
        })}
      </IconNavButtonGroup>
      <Spacer />
      {feedFlag && (
        <>
          <Center w={'58px'} h={'58px'}>
            <ShowCreateFeedPostModalNavButton
              size="xs"
              canUseFeed={isAllowedToUseFeed}
              {...createWelcomeTourDataProps(WelcomeTourStep.CreatePost)}
            />
          </Center>
          <Box w={'16px'} />
        </>
      )}
      <Center w={'58px'} h={'58px'} onClick={() => redirectToVxLive()}>
        <IconButton
          aria-label="VXLive Desktop Nav Icon"
          icon={<Icon as={VxLive} boxSize={'icon.lg'} />}
          border={'none'}
          size="xs"
          colorScheme="gray.900"
          {...createWelcomeTourDataProps(WelcomeTourStep.LiveCam)}
        />
      </Center>
      <Box w={'16px'} />
    </>
  );
};
