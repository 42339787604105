import { useColorMode } from '@chakra-ui/react';
import de from 'date-fns/locale/de';
import React, { HTMLAttributes } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './date-picker.css';

// the locale you want
registerLocale('de', de); // register it with the name you want

const DatePicker = ({
  ...props
}: ReactDatePickerProps & HTMLAttributes<HTMLElement>) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark'; //you can check what theme you are using right now however you want
  return (
    // if you don't want to use chakra's colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div className={!isDark ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        className="react-datapicker__input-text" //input is white by default and there is no already defined class for it so I created a new one
        {...props}
      />
    </div>
  );
};

export default DatePicker;
