import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../../assets/images/shared/payout.svg';
import { WizardIllustratedInstruction } from '../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../components/WizardStepFooter/WizardStepFooter';
import { useWizardPayout } from '../WizardPayoutContext';

const WizardPayoutWelcome: React.FC = () => {
  const { t } = useTranslation(['wizardPayout', 'payout']);
  const wizardPayoutCtx = useWizardPayout();

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardIllustratedInstruction
          header={t('wizardPayout:heading.BereitDeinErstesGeldZuErhalten')}
          body={t('wizardPayout:text.UnsFehlenNochDeineAuszahlungsdaten')}
          illustrationSrc={image}
          illustrationAlt={t(
            'payout:img.MadchenMitGeldInDenHandenSiehtWachsendeEinnahmen'
          )}
          withBottomMargin
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardPayout:button.Weiter')}
          onContinueButtonClick={() => {
            wizardPayoutCtx.wizardNextStepCallback();
          }}
          omitSkipButton
        />
      }
    />
  );
};

export default WizardPayoutWelcome;
