import {
  OdiCard,
  OdiCardBody,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardProps,
} from '@campoint/odi-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonGroup } from '../../../Layout/ButtonGroup';
import { SectionCenterContainer } from '../../../Layout/SectionCenterContainer';
import { UniversalCard } from '../UniversalCard/UniversalCard';
import {
  ModelProfileProgressBar,
  ModelProfileProgressCardDescription,
  ModelProfileProgressCardTitle,
  ModelProfileProgressGoToProfileButton,
  ModelProfileProgressGuideToggleButton,
  ModelProfileProgressShowAdvantagesButton,
} from './shared';

export type ModelProfileProgressCardGoToProfileProps = {
  progress: number;
  onGoToProfile: () => void;
};
export const ModelProfileProgressCardGoToProfile: React.FC<
  ModelProfileProgressCardGoToProfileProps
> = ({ progress, onGoToProfile }) => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <UniversalCard
      heading={t('modelProfile:heading.DeinModelprofilIstZuPercentAusgefullt', {
        percent: progress,
      })}
      text={t(
        'modelProfile:text.FulleDeinProfilVollstandigAusUmBesonderesAttraktivFurKundenZuSein'
      )}
      indicator={<ModelProfileProgressBar progress={progress} />}
      primaryButton={
        <ModelProfileProgressGoToProfileButton onClick={onGoToProfile} />
      }
    />
  );
};

export type ModelProfileProgressCardGuideToggleProps = {
  progress: number;
  isActivated: boolean;
  onToggleGuideDots: () => void;
} & OdiCardProps;
export const ModelProfileProgressCardGuideToggle: React.FC<
  ModelProfileProgressCardGuideToggleProps
> = ({ progress, isActivated, onToggleGuideDots, ...cardProps }) => {
  return (
    <OdiCard borderColor={'primary.200'} {...cardProps}>
      <OdiCardHeader>
        <SectionCenterContainer>
          <ModelProfileProgressCardTitle progress={progress} />
          <ModelProfileProgressCardDescription />
        </SectionCenterContainer>
      </OdiCardHeader>
      <OdiCardBody>
        <SectionCenterContainer>
          <ModelProfileProgressBar progress={progress} />
        </SectionCenterContainer>
      </OdiCardBody>
      <OdiCardFooter>
        <ButtonGroup flexDir={'column'}>
          <ModelProfileProgressGuideToggleButton
            isActivated={isActivated}
            onClick={onToggleGuideDots}
          />
          <ModelProfileProgressShowAdvantagesButton />
        </ButtonGroup>
      </OdiCardFooter>
    </OdiCard>
  );
};
