import { Input, InputProps, Portal, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  MenuModal,
  MenuModalContent,
  MenuModalItem,
  MenuModalItemList,
  MenuModalOverlay,
} from '../components/shared/FeedPostMenu/FeedPostMenu';
import { createContext } from '../hooks/useContext';
import { useMediaPicker } from '../hooks/useMediaPicker';
import { MediaFile } from '../types/media';
import {
  imageMimeTypes,
  videoMimeTypes,
} from '../utils/media/supportedMimeTypes';

export type MediaInputContext = {
  inputProps: InputProps;
  mediaFile: MediaFile | null;
  pickFileLabel: string;
  readonly isCropperShown: boolean;
  readonly isProcessingInput: boolean;
  readonly triggerMenu: () => void;
  readonly triggerFilePicker: () => void;
  readonly triggerImageCapture: () => void;
  readonly triggerVideoCapture: () => void;
  readonly clearMediaFile: () => void;
  readonly closeCropper: () => void;
  readonly error: boolean;
};

type InputAcceptWithDefaults =
  | 'DEFAULT_FOR_DOCUMENT'
  | 'DEFAULT_FOR_PICTURE'
  | 'DEFAULT_FOR_VIDEO'
  | 'DEFAULT_FOR_PICTURE_AND_VIDEO'
  | string;

export const [, useMediaInput, mediaInputContext] =
  createContext<MediaInputContext>({
    name: 'MediaInputContext',
    errorMessage:
      'useMediaInput: `MediaInputContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });
type MediaInputProviderOptions = Pick<InputProps, 'accept' | 'capture'> & {
  accept?: InputAcceptWithDefaults;
  uploadLabel?: string;
  children?: React.ReactNode;
  onUnacceptedFileType?: () => void;
  issueWrongDimensionToast?: boolean;
};

const useMappedAccept = (accept?: InputAcceptWithDefaults) => {
  return React.useMemo(() => {
    switch (accept) {
      case 'DEFAULT_FOR_DOCUMENT':
        return [...imageMimeTypes, 'application/pdf'].join(',');
      case 'DEFAULT_FOR_PICTURE':
        return [...imageMimeTypes].join(',');
      case 'DEFAULT_FOR_VIDEO':
        return [...videoMimeTypes].join(',');
      case 'DEFAULT_FOR_PICTURE_AND_VIDEO':
        return [...imageMimeTypes, ...videoMimeTypes].join(',');
      default:
        return `${accept}`;
    }
  }, [accept]);
};

/**
 * It is useful for file inputs that can be triggered from multiple elements
 *
 *
 * Handles shared `<input type="file">` props.
 * May be used to trigger a file upload via a button,
 * some custom shaped box etc. all at once
 *
 * uses {@link useMediaPicker} to forward and process the picked files
 * @see useMediaPicker
 */
export const MediaInputProvider: React.FC<MediaInputProviderOptions> = ({
  onUnacceptedFileType,
  uploadLabel,
  accept = 'DEFAULT_FOR_PICTURE',
  children,
  issueWrongDimensionToast = true,
  ...inputProps
}) => {
  const { t } = useTranslation(['mediaUpload']);
  const mappedAccept = useMappedAccept(accept);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    onFileChange,
    mediaFile,
    setMediaFile,
    isCropperShown,
    isProcessingInput,
    closeCropper,
    error,
  } = useMediaPicker({
    accept: mappedAccept,
    onUnacceptedFileType,
    issueWrongDimensionToast,
  });

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const fileImageCaptureInputRef = React.useRef<HTMLInputElement>(null);
  const fileVideoCaptureInputRef = React.useRef<HTMLInputElement>(null);

  const triggerMenu = React.useCallback(() => {
    onOpen();
  }, [onOpen]);

  const triggerFilePicker = React.useCallback(() => {
    fileInputRef?.current?.click();
  }, []);

  const triggerImageCapture = React.useCallback(() => {
    fileImageCaptureInputRef?.current?.click();
  }, []);

  const triggerVideoCapture = React.useCallback(() => {
    fileVideoCaptureInputRef?.current?.click();
  }, []);

  const clearMediaFile = React.useCallback(() => {
    setMediaFile(null);
  }, [setMediaFile]);

  const context: MediaInputContext = {
    inputProps: {
      display: 'none',
      type: 'file',
      // bind an empty array to value,
      // so that we can easily pick the same file again,
      // if we want to re-crop it for example
      value: [],
      ...inputProps,
      accept: mappedAccept,
      onChange: onFileChange,
    },
    pickFileLabel: uploadLabel ?? t('mediaUpload:button.DateiAuswahlen'),
    mediaFile,
    isCropperShown,
    isProcessingInput,
    closeCropper,
    triggerMenu,
    triggerFilePicker,
    triggerImageCapture,
    triggerVideoCapture,
    clearMediaFile,
    error,
  };

  const acceptsImages = mappedAccept.includes('image/');
  const acceptsVideos = mappedAccept.includes('video/');

  return (
    <mediaInputContext.Provider value={context}>
      {children}
      <MenuModal isOpen={isOpen} onClose={onClose}>
        <MenuModalOverlay
          bg="blackAlpha.300"
          backdropFilter="auto"
          backdropBlur={'base'}
        />
        <MenuModalContent>
          <MenuModalItemList>
            {!acceptsVideos ? null : (
              <MenuModalItem
                onClick={() => {
                  onClose();
                  triggerVideoCapture();
                }}
              >
                {t('mediaUpload:menuItem.VideoAufnehmen')}
              </MenuModalItem>
            )}
            {!acceptsImages ? null : (
              <MenuModalItem
                onClick={() => {
                  onClose();
                  triggerImageCapture();
                }}
              >
                {t('mediaUpload:menuItem.FotoAufnehmen')}
              </MenuModalItem>
            )}
            <MenuModalItem
              onClick={() => {
                onClose();
                triggerFilePicker();
              }}
            >
              {t('mediaUpload:menuItem.DateiAuswahlen')}
            </MenuModalItem>
            <MenuModalItem onClick={onClose}>Abbrechen</MenuModalItem>
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
      <Portal>
        <Input
          ref={fileInputRef}
          {...context.inputProps}
          // avoid capture here, as this should not open the camera directly
          capture={false}
          style={{ visibility: 'hidden' }}
        />
        {!acceptsImages ? null : (
          <Input
            ref={fileImageCaptureInputRef}
            // enforce capture here, as this should open the camera directly
            capture={'environment'}
            {...context.inputProps}
            accept={mappedAccept
              .split(',')
              .filter((entry) => entry.includes('image/'))
              .join(',')}
            style={{ visibility: 'hidden' }}
          />
        )}
        {!acceptsVideos ? null : (
          <Input
            ref={fileVideoCaptureInputRef}
            // enforce capture here, as this should open the camera directly
            capture={'environment'}
            {...context.inputProps}
            accept={mappedAccept
              .split(',')
              .filter((entry) => entry.includes('video/'))
              .join(',')}
            style={{ visibility: 'hidden' }}
          />
        )}
      </Portal>
    </mediaInputContext.Provider>
  );
};
