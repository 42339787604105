import { HStack, StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ExperienceIntentionStatusIndicator: React.FC<StackProps> = (
  props
) => {
  return (
    <HStack
      position={'absolute'}
      spacing={'1.5'}
      p={'6px 6px 4px 6px'}
      top={0}
      left={0}
      backgroundColor={props.backgroundColor ?? 'success.highEmphasis'}
      borderBottomEndRadius={4}
      {...props}
    />
  );
};

type ExperienceIntentionStatusIndicatorType =
  | 'active'
  | 'pending'
  | 'available';

export const ExperienceIntentionStatusIndicatorIncomeSources: React.FC<{
  status: ExperienceIntentionStatusIndicatorType;
}> = ({ status }) => {
  const { t } = useTranslation(['incomeSourcesPage']);

  const statusToColor: Record<ExperienceIntentionStatusIndicatorType, string> =
    {
      active: 'lime.500',
      pending: 'warning.highEmphasis',
      available: 'caribbeanGreen.500',
    };

  const statusToText: Record<ExperienceIntentionStatusIndicatorType, string> = {
    active: t('incomeSourcesPage:label.Aktiv'),
    pending: t('incomeSourcesPage:label.InPrufung'),
    available: t('incomeSourcesPage:label.Verfugbar'),
  };

  return (
    <ExperienceIntentionStatusIndicator backgroundColor={statusToColor[status]}>
      <Text
        fontWeight={'bold'}
        fontSize={'13px'}
        lineHeight={'14px'}
        color={'white'}
      >
        {statusToText[status]}
      </Text>
    </ExperienceIntentionStatusIndicator>
  );
};
