import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { Container } from '@chakra-ui/react';
import React from 'react';

export const PageSubHeader: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <SubHeader px={0}>
    <Container maxW={'container.xl'}>
      <SubHeaderTitle maxW={'container.xl'}>{children}</SubHeaderTitle>
    </Container>
  </SubHeader>
);
