import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { RadioOptionButtonGroupControl } from '../../../../components/shared/FormikFormElements';
import { ProfileFieldTypeEnumFragment } from '../../../../generated/graphql';
import { LabelTextWithGuideDot } from '../LabelTextWithGuideDot/LabelTextWithGuideDot';

export const EnumRadioField: React.FC<{
  field: Maybe<ProfileFieldTypeEnumFragment>;
  label: string;
  helperText?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}> = ({ field, label, isDisabled, isReadOnly, helperText }) => {
  return !field ? null : (
    <RadioOptionButtonGroupControl
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      helperText={helperText}
      options={
        field.validValues?.map(({ value, label: children }) => ({
          value,
          children,
        })) ?? []
      }
    />
  );
};
