import React from 'react';

import { useAuth } from '../../provider/AuthProvider';
import LoadingPage from '../LoadingPage/LoadingPage';

/**
 * This page can be navigated to in case other pages, form, process
 * should have a chance to block the navigation and thus the logout
 */
export const LogoutPage: React.FC = () => {
  const authCtx = useAuth();

  React.useEffect(() => {
    authCtx.actions.logout().then();
  }, [authCtx.actions]);
  return <LoadingPage />;
};
