import * as icon from '@campoint/odi-ui-icons';
import {
  BoxProps,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  chakra,
} from '@chakra-ui/react';
import React from 'react';

import LoadingPage from '../../pages/LoadingPage/LoadingPage';

export interface PublicPageLayoutProps {
  pageTitle: string;
  onClose?: () => void;
  isContentLoading?: boolean;
  children?: React.ReactNode;
}

export const PublicPageHeader: React.FC<BoxProps> = (props) => (
  <Flex
    as={'header'}
    color={'gray.900'}
    bg={'white'}
    minH={'14'}
    position={'sticky'}
    top={0}
    w={'full'}
    justifyContent={'center'}
    alignItems={'center'}
    /**
     * Prevents that the input fields are above the header if the page is scrollable.
     * Uses the same Z-Index as the FluentPageLayout page header.
     */
    zIndex={3}
    {...props}
  />
);
export const PublicPageHeaderBox: React.FC<BoxProps> = (props) => (
  <Container maxW={'container.xl'} px={{ base: 4, lg: 8 }} {...props} />
);
export const PublicPageMain: React.FC<BoxProps> = (props) => (
  <Container as={'main'} maxW={'container.xl'} p={0} {...props} />
);

export const PublicPageMainSection: React.FC<BoxProps> = (props) => (
  <chakra.section
    bg={'surface'}
    maxW={'container.xl'}
    minH={{ base: 'calc(100vh - 56px)', md: 'inherit' }}
    px={{ base: 4, md: 8 }}
    pt={8}
    pb={{ base: 4, md: 8 }}
    {...props}
  />
);

export const PublicPageMainSectionBox: React.FC<BoxProps> = (props) => (
  <Container maxW={'container.md'} p={0} {...props} />
);

export const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({
  pageTitle,
  isContentLoading,
  onClose,
  children,
}) => {
  return (
    <>
      <PublicPageHeader>
        <PublicPageHeaderBox>
          <HStack>
            <Icon
              as={icon.VxModelsLogo}
              color={'vxModels.500'}
              boxSize={'icon.md'}
            />
            <Heading size={'xs'} flexGrow={1}>
              {pageTitle}
            </Heading>
            {!onClose ? (
              <></>
            ) : (
              <IconButton
                color={'gray.900'}
                variant={'angel'}
                aria-label={'close'}
                icon={<Icon as={icon.Close} boxSize={'icon.md'} />}
                onClick={onClose}
              />
            )}
          </HStack>
        </PublicPageHeaderBox>
      </PublicPageHeader>
      <PublicPageMain>
        {isContentLoading ? <LoadingPage /> : children}
      </PublicPageMain>
    </>
  );
};
