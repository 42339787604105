import { HStack, Skeleton, SkeletonCircle, VStack } from '@chakra-ui/react';

import { ResponsiveModalBodyBox } from '../../Layout/ResponsiveModal';

export const LoadingState: React.FC = () => {
  const skeletonConfig = {
    startColor: 'gray.200',
    endColor: 'gray.400',
    speed: 1,
  };

  return (
    <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
      <VStack spacing={0}>
        <Skeleton {...skeletonConfig} h={'14'} w={'full'} />
        <Skeleton
          {...skeletonConfig}
          h={'125px'}
          w={'288px'}
          mt={'5'}
          mb={'7'}
          borderRadius={'lg'}
        />
        <HStack w={'full'} spacing={0}>
          {[...Array(2)].map((_, i) => (
            <VStack key={i} w={'full'}>
              <Skeleton {...skeletonConfig} h={'5'} w={'20'} />
              <Skeleton {...skeletonConfig} h={'2px'} w={'full'} />
            </VStack>
          ))}
        </HStack>
        <VStack spacing={'5'} w={'full'} py={'6'} px={'4'}>
          {[...Array(3)].map((_, i) => (
            <HStack key={i} spacing={'2'} w={'full'} alignItems={'start'}>
              <SkeletonCircle
                {...skeletonConfig}
                size={'30px'}
                flexShrink={0}
              />
              <VStack spacing={'1'} alignItems={'start'} w={'full'}>
                <Skeleton {...skeletonConfig} h={'5'} w={'36'} />
                <Skeleton {...skeletonConfig} h={'4'} w={'56'} />
                <Skeleton {...skeletonConfig} h={'4'} w={'20'} />
              </VStack>
            </HStack>
          ))}
        </VStack>
      </VStack>
    </ResponsiveModalBodyBox>
  );
};
