export function loadPicture(
  src: string | null
): Promise<{ width: number; height: number; aspectRatio: number } | null> {
  if (!src) {
    return Promise.resolve(null);
  }
  return new Promise((resolve, reject) => {
    const imageElement = document.createElement('img');
    imageElement.setAttribute('src', src);
    imageElement.addEventListener('error', (ex) => {
      reject(ex);
    });
    imageElement.addEventListener('load', function () {
      const dimensions = {
        width: this.naturalWidth,
        height: this.naturalHeight,
        aspectRatio: this.naturalWidth / this.naturalHeight,
      };
      resolve(dimensions);
    });
  });
}
