import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '../graphql/ApolloHooksWithContext';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {"context":{"clientName":"NOTIFICATIONS"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Dict: { input: any; output: any; }
  SrcSet: { input: any; output: any; }
  Uri: { input: any; output: any; }
};

export type ApiInfo = {
  __typename?: 'ApiInfo';
  cbaseEnv: Scalars['String']['output'];
  environment: Scalars['String']['output'];
  gitCommitHash: Scalars['String']['output'];
  host: Scalars['String']['output'];
  isDebug: Scalars['Boolean']['output'];
  phpVersion: Scalars['String']['output'];
  version: Scalars['String']['output'];
  vxfeedApiKeyHash?: Maybe<Scalars['String']['output']>;
};

/** Available API languages */
export enum ApiLangEnum {
  /** German */
  De = 'de',
  /** Englisch */
  En = 'en'
}

export type AuthInfo = {
  __typename?: 'AuthInfo';
  user?: Maybe<AuthUser>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  avatar?: Maybe<Image>;
  lang: ApiLangEnum;
  roles: Array<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type Button = {
  __typename?: 'Button';
  action: Scalars['Uri']['output'];
  label: Scalars['String']['output'];
};

/** like HTML `img`, see https://html.spec.whatwg.org/multipage/embedded-content.html#the-img-element */
export type Image = {
  __typename?: 'Image';
  alt?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
  src?: Maybe<Scalars['Uri']['output']>;
  srcset?: Maybe<Scalars['SrcSet']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

export enum ImageSizeEnum {
  /** max. 40px box (any value) */
  B40 = 'b40',
  /** max. 60px box (any value) */
  B60 = 'b60',
  /** max. 140px box (any value) */
  B140 = 'b140',
  /** max. 160px box (any value) */
  B160 = 'b160',
  /** max. 320px box (any value) */
  B320 = 'b320',
  /** max. 640px box (any value) */
  B640 = 'b640',
  /** max. 1024px box (any value) */
  B1024 = 'b1024',
  /** Original size */
  Orig = 'orig'
}

export type Link = {
  __typename?: 'Link';
  label: Scalars['String']['output'];
  uri: Scalars['Uri']['output'];
};

export type Notification = NotificationInterface & {
  __typename?: 'Notification';
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  groupId?: Maybe<NotificationGroupEnum>;
  icon?: Maybe<Image>;
  id: Scalars['ID']['output'];
  link?: Maybe<Link>;
  params?: Maybe<Scalars['Dict']['output']>;
  picture?: Maybe<Image>;
  primaryButton?: Maybe<Button>;
  priority: NotificationPriorityEnum;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  secondaryButton?: Maybe<Button>;
  shortInfo?: Maybe<Scalars['String']['output']>;
  showFrom: Scalars['DateTime']['output'];
  showUntil: Scalars['DateTime']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: NotificationTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  uri?: Maybe<Scalars['Uri']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};


export type NotificationPictureArgs = {
  size?: InputMaybe<ImageSizeEnum>;
  sizes?: Array<ImageSizeEnum>;
};

/** A connection to a list of items. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<NotificationInterface>;
};

/** NotificationGroupEnum */
export enum NotificationGroupEnum {
  /** All models, not restricted, last login < 90d */
  AllModelsNotRestrictedLastLogin_90D = 'ALL_MODELS_NOT_RESTRICTED_LAST_LOGIN_90D',
  /** All models not company without TIN */
  AllModelsWithoutTin = 'ALL_MODELS_WITHOUT_TIN',
  /** VXModels Developer and Testers */
  DeveloperVxmodels = 'DEVELOPER_VXMODELS',
  /** Models, displayed on VISIT-X, sending allowed, last login < 90d */
  VisitxFemaleModelsSendingAllowedLastLogin_90D = 'VISITX_FEMALE_MODELS_SENDING_ALLOWED_LAST_LOGIN_90D',
  /** Models, displayed on VISIT-X, sending allowed, last login < 90d */
  VisitxModelsSendingAllowedLastLogin_90D = 'VISITX_MODELS_SENDING_ALLOWED_LAST_LOGIN_90D',
  /** Models, displayed on VISIT-X in VIP30 next month */
  VisitxModelsWithVip30VideosNextMonth = 'VISITX_MODELS_WITH_VIP30_VIDEOS_NEXT_MONTH',
  /** Models, displayed on VISIT-X in VIP30 this month */
  VisitxModelsWithVip30VideosThisMonth = 'VISITX_MODELS_WITH_VIP30_VIDEOS_THIS_MONTH',
  /** Models with rejected actor documents */
  VxmodelsUsersWithRejectedActorDocuments = 'VXMODELS_USERS_WITH_REJECTED_ACTOR_DOCUMENTS'
}

export type NotificationInterface = {
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  groupId?: Maybe<NotificationGroupEnum>;
  id: Scalars['ID']['output'];
  params?: Maybe<Scalars['Dict']['output']>;
  priority: NotificationPriorityEnum;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  showFrom: Scalars['DateTime']['output'];
  showUntil: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  type: NotificationTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
};

/** Priority of notification */
export enum NotificationPriorityEnum {
  /** High priority */
  High = 'HIGH',
  /** Higher priority */
  Higher = 'HIGHER',
  /** Highest priority */
  Highest = 'HIGHEST',
  /** Low priority */
  Low = 'LOW',
  /** Lower priority */
  Lower = 'LOWER',
  /** Lowest priority */
  Lowest = 'LOWEST',
  /** Normal priority */
  Normal = 'NORMAL'
}

/** NotificationTypeEnum */
export enum NotificationTypeEnum {
  /** Host actor feature was accepted */
  ActorFeatureAccountingAccepted = 'ACTOR_FEATURE_ACCOUNTING_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureAccountingRejected = 'ACTOR_FEATURE_ACCOUNTING_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureBonuscodecreationAccepted = 'ACTOR_FEATURE_BONUSCODECREATION_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureBonuscodecreationRejected = 'ACTOR_FEATURE_BONUSCODECREATION_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureInstagramadlinkAccepted = 'ACTOR_FEATURE_INSTAGRAMADLINK_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureInstagramadlinkRejected = 'ACTOR_FEATURE_INSTAGRAMADLINK_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureMobilevideocallAccepted = 'ACTOR_FEATURE_MOBILEVIDEOCALL_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureMobilevideocallRejected = 'ACTOR_FEATURE_MOBILEVIDEOCALL_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureNewslettervxAccepted = 'ACTOR_FEATURE_NEWSLETTERVX_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureNewslettervxRejected = 'ACTOR_FEATURE_NEWSLETTERVX_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureRefercustomersAccepted = 'ACTOR_FEATURE_REFERCUSTOMERS_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureRefercustomersRejected = 'ACTOR_FEATURE_REFERCUSTOMERS_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureTicketshowAccepted = 'ACTOR_FEATURE_TICKETSHOW_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureTicketshowRejected = 'ACTOR_FEATURE_TICKETSHOW_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureTopcamAccepted = 'ACTOR_FEATURE_TOPCAM_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureTopcamRejected = 'ACTOR_FEATURE_TOPCAM_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureToycontrolAccepted = 'ACTOR_FEATURE_TOYCONTROL_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureToycontrolRejected = 'ACTOR_FEATURE_TOYCONTROL_REJECTED',
  /** Host actor feature was accepted */
  ActorFeatureVxliveAccepted = 'ACTOR_FEATURE_VXLIVE_ACCEPTED',
  /** Host actor feature was accepted */
  ActorFeatureVxsocialpageAccepted = 'ACTOR_FEATURE_VXSOCIALPAGE_ACCEPTED',
  /** Host actor feature was rejected */
  ActorFeatureVxsocialpageRejected = 'ACTOR_FEATURE_VXSOCIALPAGE_REJECTED',
  /** Notification for a contest */
  AnnouncementContest = 'ANNOUNCEMENT_CONTEST',
  /** Direct Message with free text, to a user or a group of users */
  AnnouncementDirectMessage = 'ANNOUNCEMENT_DIRECT_MESSAGE',
  /** Higher priority announcement for role `VX_USER` */
  AnnouncementHigherPriority = 'ANNOUNCEMENT_HIGHER_PRIORITY',
  /** Higher priority announcement only for role `VX_USER_MASTER` */
  AnnouncementHigherPriorityUserMaster = 'ANNOUNCEMENT_HIGHER_PRIORITY_USER_MASTER',
  /** Notification for marketing purposes */
  AnnouncementMarketing = 'ANNOUNCEMENT_MARKETING',
  /** Custom free text notification */
  AnnouncementSystemMessage = 'ANNOUNCEMENT_SYSTEM_MESSAGE',
  /** Notification for TV purposes */
  AnnouncementTelevision = 'ANNOUNCEMENT_TELEVISION',
  /** Notification for VXGames winners */
  AnnouncementVxgames = 'ANNOUNCEMENT_VXGAMES',
  /** Notification for VXGames winners */
  AnnouncementVxgamesWinner = 'ANNOUNCEMENT_VXGAMES_WINNER',
  /** The payout was locked */
  HostPayoutLocked = 'HOST_PAYOUT_LOCKED',
  /** The payout was approved */
  HostPayoutUnlocked = 'HOST_PAYOUT_UNLOCKED',
  /** The avatar picture was accepted by support */
  MediaPictureAvatarAccepted = 'MEDIA_PICTURE_AVATAR_ACCEPTED',
  /** The avatar picture was rejected by support */
  MediaPictureAvatarRejected = 'MEDIA_PICTURE_AVATAR_REJECTED',
  /** A sedcard picture was accepted by support */
  MediaPictureSedcardAccepted = 'MEDIA_PICTURE_SEDCARD_ACCEPTED',
  /** A sedcard picture was rejected by support */
  MediaPictureSedcardRejected = 'MEDIA_PICTURE_SEDCARD_REJECTED',
  /** The title picture was accepted by support */
  MediaPictureTitleAccepted = 'MEDIA_PICTURE_TITLE_ACCEPTED',
  /** The title picture was rejected by support */
  MediaPictureTitleRejected = 'MEDIA_PICTURE_TITLE_REJECTED',
  /** Shop video bought by guest */
  MediaVideoShopTransaction = 'MEDIA_VIDEO_SHOP_TRANSACTION',
  /** Video is selected as VIP30 next month */
  MediaVideoVip30NextMonth = 'MEDIA_VIDEO_VIP30_NEXT_MONTH',
  /** Video is selected as VIP30 this month */
  MediaVideoVip30ThisMonth = 'MEDIA_VIDEO_VIP30_THIS_MONTH',
  /** Documents of a linked actor were rejected */
  PaymentActorDocumentsRejected = 'PAYMENT_ACTOR_DOCUMENTS_REJECTED',
  /** Payment address data accepted */
  PaymentAddressDataAccepted = 'PAYMENT_ADDRESS_DATA_ACCEPTED',
  /** Address data rejected */
  PaymentAddressDataRejected = 'PAYMENT_ADDRESS_DATA_REJECTED',
  /** Address Proof accepted */
  PaymentAddressProofAccepted = 'PAYMENT_ADDRESS_PROOF_ACCEPTED',
  /** Address proof missing */
  PaymentAddressProofMissing = 'PAYMENT_ADDRESS_PROOF_MISSING',
  /** Address Proof rejected */
  PaymentAddressProofRejected = 'PAYMENT_ADDRESS_PROOF_REJECTED',
  /** Address Proof accepted */
  PaymentHostDocumentAddressProofAccepted = 'PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_ACCEPTED',
  /** Address proof missing */
  PaymentHostDocumentAddressProofMissing = 'PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_MISSING',
  /** Address Proof rejected */
  PaymentHostDocumentAddressProofRejected = 'PAYMENT_HOST_DOCUMENT_ADDRESS_PROOF_REJECTED',
  /** Business registration accepted */
  PaymentHostDocumentBusinessRegAccepted = 'PAYMENT_HOST_DOCUMENT_BUSINESS_REG_ACCEPTED',
  /** Business registration missing */
  PaymentHostDocumentBusinessRegMissing = 'PAYMENT_HOST_DOCUMENT_BUSINESS_REG_MISSING',
  /** Business registration rejected */
  PaymentHostDocumentBusinessRegRejected = 'PAYMENT_HOST_DOCUMENT_BUSINESS_REG_REJECTED',
  /** Identity Proof accepted */
  PaymentHostDocumentIdentityProofAccepted = 'PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_ACCEPTED',
  /** Identity proof missing */
  PaymentHostDocumentIdentityProofMissing = 'PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_MISSING',
  /** Identity Proof rejected */
  PaymentHostDocumentIdentityProofRejected = 'PAYMENT_HOST_DOCUMENT_IDENTITY_PROOF_REJECTED',
  /** ID Shot accepted */
  PaymentHostDocumentIdShotAccepted = 'PAYMENT_HOST_DOCUMENT_ID_SHOT_ACCEPTED',
  /** ID Shot missing */
  PaymentHostDocumentIdShotMissing = 'PAYMENT_HOST_DOCUMENT_ID_SHOT_MISSING',
  /** ID Shot rejected */
  PaymentHostDocumentIdShotRejected = 'PAYMENT_HOST_DOCUMENT_ID_SHOT_REJECTED',
  /** Model Release Form accepted */
  PaymentHostDocumentMrfAccepted = 'PAYMENT_HOST_DOCUMENT_MRF_ACCEPTED',
  /** VAT document missing */
  PaymentHostDocumentMrfMissing = 'PAYMENT_HOST_DOCUMENT_MRF_MISSING',
  /** Model Release Form rejected */
  PaymentHostDocumentMrfRejected = 'PAYMENT_HOST_DOCUMENT_MRF_REJECTED',
  /** VAT Document accepted */
  PaymentHostDocumentVatAccepted = 'PAYMENT_HOST_DOCUMENT_VAT_ACCEPTED',
  /** VAT document missing */
  PaymentHostDocumentVatMissing = 'PAYMENT_HOST_DOCUMENT_VAT_MISSING',
  /** VAT Document rejected */
  PaymentHostDocumentVatRejected = 'PAYMENT_HOST_DOCUMENT_VAT_REJECTED',
  /** Payout data accepted */
  PaymentPayoutDataAccepted = 'PAYMENT_PAYOUT_DATA_ACCEPTED',
  /** Payout data rejected */
  PaymentPayoutDataRejected = 'PAYMENT_PAYOUT_DATA_REJECTED',
  /** Sharing step accepted */
  PaymentSharingStepAccepted = 'PAYMENT_SHARING_STEP_ACCEPTED',
  /** ID Shot rejected */
  PaymentSharingStepRejected = 'PAYMENT_SHARING_STEP_REJECTED',
  /** Tax data accepted */
  PaymentTaxDataAccepted = 'PAYMENT_TAX_DATA_ACCEPTED',
  /** Tax data will expire soon */
  PaymentTaxDataExpireSoon = 'PAYMENT_TAX_DATA_EXPIRE_SOON',
  /** Tax data rejected */
  PaymentTaxDataRejected = 'PAYMENT_TAX_DATA_REJECTED',
  /** Taxpayer Identification Number (TIN) is missing */
  PaymentTaxDataTinMissing = 'PAYMENT_TAX_DATA_TIN_MISSING',
  /** A guest added the model to his favorites */
  VxfeedFavoriteAdded = 'VXFEED_FAVORITE_ADDED',
  /** A featured post was published */
  VxfeedFeaturedPostPublished = 'VXFEED_FEATURED_POST_PUBLISHED',
  /** A guest commented on a post */
  VxfeedPostCommented = 'VXFEED_POST_COMMENTED',
  /** A guest replied to a comment */
  VxfeedPostCommentReplied = 'VXFEED_POST_COMMENT_REPLIED',
  /** A guest liked a post */
  VxfeedPostLiked = 'VXFEED_POST_LIKED',
  /** A post was rated FSK 18+ */
  VxfeedPostRatedFsk18 = 'VXFEED_POST_RATED_FSK18',
  /** A post was rejected */
  VxfeedPostRejected = 'VXFEED_POST_REJECTED',
  /** A feed post has been successfully scheduled */
  VxfeedPostScheduled = 'VXFEED_POST_SCHEDULED',
  /** A customer bought feed post content */
  VxfeedPostSold = 'VXFEED_POST_SOLD',
  /** A guest tipped a post */
  VxfeedPostTipped = 'VXFEED_POST_TIPPED',
  /** A feed post Tipping Goal was reached */
  VxfeedPostTippingGoalAchieved = 'VXFEED_POST_TIPPING_GOAL_ACHIEVED'
}

export type Notifications = {
  __typename?: 'Notifications';
  /** Filter for notifications */
  filter?: Maybe<NotificationsFilter>;
  pagination?: Maybe<NotificationConnection>;
  /** @deprecated About to remove due to performance reasons here. Use query > types instead. */
  types: Array<NotificationsType>;
  /** @deprecated Use . > filter > userId instead. */
  userId?: Maybe<Scalars['ID']['output']>;
};


export type NotificationsPaginationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type NotificationsFilter = {
  __typename?: 'NotificationsFilter';
  /** Filter preset notifications */
  preset?: Maybe<NotificationsFilterPresetEnum>;
  /** Sort order for notifications */
  sort?: Maybe<NotificationsSortEnum>;
  /** Notifications by userId */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type NotificationsFilterInput = {
  /** Filter notifications */
  preset?: InputMaybe<NotificationsFilterPresetEnum>;
  /** Sort order for notifications */
  sort?: InputMaybe<NotificationsSortEnum>;
};

/** NotificationsFilterPresetEnum */
export enum NotificationsFilterPresetEnum {
  /**
   * All notifications
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   *
   */
  All = 'ALL',
  /**
   * All unread notifications
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   *
   */
  AllUnread = 'ALL_UNREAD',
  /**
   * Important notifications only
   *  - Priority:
   *    - min: `HIGH`
   *    - max: `HIGHEST`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   *
   */
  ImportantAll = 'IMPORTANT_ALL',
  /**
   * Unread important notifications only
   *  - Priority:
   *    - min: `HIGH`
   *    - max: `HIGHEST`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   *
   */
  ImportantUnread = 'IMPORTANT_UNREAD',
  /**
   * Info notifications only
   *  - Priority:
   *    - min: `LOWEST`
   *    - max: `NORMAL`
   *  - Types:
   *    - include: `.+`
   *    - exclude: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   *
   */
  InfoAll = 'INFO_ALL',
  /**
   * Unread info notifications only
   *  - Priority:
   *    - min: `LOWEST`
   *    - max: `NORMAL`
   *  - Types:
   *    - include: `.+`
   *    - exclude: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   *
   */
  InfoUnread = 'INFO_UNREAD',
  /**
   * All announcement notifications (manager view)
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `null`
   *  - Show until: `null`
   *  - Unread only: `false`
   *
   */
  ManagerAnnouncementAll = 'MANAGER_ANNOUNCEMENT_ALL',
  /**
   * All past notifications (manager view)
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `null`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   *
   */
  ManagerAnnouncementPast = 'MANAGER_ANNOUNCEMENT_PAST',
  /**
   * All upcoming notifications (manager view)
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `null`
   *  - Unread only: `false`
   *
   */
  ManagerAnnouncementUpcoming = 'MANAGER_ANNOUNCEMENT_UPCOMING',
  /**
   * All news center notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `null`
   *  - Unread only: `false`
   *
   * @deprecated Use NEWS_CENTER_ALL instead
   */
  NewsCenter = 'NEWS_CENTER',
  /**
   * All news center notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `false`
   *
   */
  NewsCenterAll = 'NEWS_CENTER_ALL',
  /**
   * Unread news center notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   *
   */
  NewsCenterUnread = 'NEWS_CENTER_UNREAD',
  /**
   * Snag bar notifications
   *  - Types:
   *    - include: `ANNOUNCEMENT_.+`
   *  - Show from: `NOW()`
   *  - Show until: `NOW()`
   *  - Unread only: `true`
   *
   */
  SnagBar = 'SNAG_BAR'
}

export type NotificationsMutation = {
  __typename?: 'NotificationsMutation';
  markRead?: Maybe<NotificationInterface>;
};


export type NotificationsMutationMarkReadArgs = {
  id: Scalars['ID']['input'];
};

/** Sort order for notifications */
export enum NotificationsSortEnum {
  /** Updated at descending */
  UpdatedAtDesc = 'updated_at_desc'
}

export type NotificationsType = {
  __typename?: 'NotificationsType';
  category: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  id: NotificationTypeEnum;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type RootApiMutation = {
  __typename?: 'RootApiMutation';
  notifications?: Maybe<NotificationsMutation>;
};

export type RootApiQuery = {
  __typename?: 'RootApiQuery';
  api?: Maybe<ApiInfo>;
  auth?: Maybe<AuthInfo>;
  notifications?: Maybe<Notifications>;
};


export type RootApiQueryNotificationsArgs = {
  filter?: InputMaybe<NotificationsFilterInput>;
  lang: ApiLangEnum;
};

export type GetImportantNotificationsQueryVariables = Exact<{
  lang: ApiLangEnum;
  count: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetImportantNotificationsQuery = { __typename?: 'RootApiQuery', notifications?: { __typename?: 'Notifications', filter?: { __typename?: 'NotificationsFilter', preset?: NotificationsFilterPresetEnum | null } | null, pagination?: { __typename?: 'NotificationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges?: Array<{ __typename?: 'NotificationEdge', cursor: string, node?: { __typename?: 'Notification', id: string, category: string, title: string, subtitle?: string | null, createdAt: any, updatedAt: any, readAt?: any | null, type: NotificationTypeEnum, shortInfo?: string | null, uri?: any | null, link?: { __typename?: 'Link', uri: any, label: string } | null, picture?: { __typename?: 'Image', src?: any | null, alt?: string | null, srcset?: any | null, height?: string | null, width?: string | null } | null, icon?: { __typename?: 'Image', src?: any | null, alt?: string | null, height?: string | null, width?: string | null } | null, primaryButton?: { __typename?: 'Button', label: string, action: any } | null, secondaryButton?: { __typename?: 'Button', label: string, action: any } | null } | null } | null> | null } | null } | null };

export type GetAllNotificationsQueryVariables = Exact<{
  lang: ApiLangEnum;
  count: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllNotificationsQuery = { __typename?: 'RootApiQuery', notifications?: { __typename?: 'Notifications', filter?: { __typename?: 'NotificationsFilter', preset?: NotificationsFilterPresetEnum | null } | null, pagination?: { __typename?: 'NotificationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges?: Array<{ __typename?: 'NotificationEdge', cursor: string, node?: { __typename?: 'Notification', id: string, category: string, title: string, subtitle?: string | null, createdAt: any, updatedAt: any, readAt?: any | null, type: NotificationTypeEnum, shortInfo?: string | null, uri?: any | null, link?: { __typename?: 'Link', uri: any, label: string } | null, picture?: { __typename?: 'Image', src?: any | null, alt?: string | null, srcset?: any | null, height?: string | null, width?: string | null } | null, icon?: { __typename?: 'Image', src?: any | null, alt?: string | null, height?: string | null, width?: string | null } | null, primaryButton?: { __typename?: 'Button', label: string, action: any } | null, secondaryButton?: { __typename?: 'Button', label: string, action: any } | null } | null } | null> | null } | null } | null };

export type GetNewsNotificationsQueryVariables = Exact<{
  lang: ApiLangEnum;
  count: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetNewsNotificationsQuery = { __typename?: 'RootApiQuery', notifications?: { __typename?: 'Notifications', filter?: { __typename?: 'NotificationsFilter', preset?: NotificationsFilterPresetEnum | null } | null, pagination?: { __typename?: 'NotificationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges?: Array<{ __typename?: 'NotificationEdge', cursor: string, node?: { __typename?: 'Notification', id: string, category: string, title: string, subtitle?: string | null, createdAt: any, updatedAt: any, readAt?: any | null, type: NotificationTypeEnum, shortInfo?: string | null, uri?: any | null, link?: { __typename?: 'Link', uri: any, label: string } | null, picture?: { __typename?: 'Image', src?: any | null, alt?: string | null, srcset?: any | null, height?: string | null, width?: string | null } | null, icon?: { __typename?: 'Image', src?: any | null, alt?: string | null, height?: string | null, width?: string | null } | null, primaryButton?: { __typename?: 'Button', label: string, action: any } | null, secondaryButton?: { __typename?: 'Button', label: string, action: any } | null } | null } | null> | null } | null } | null };

export type GetNotificationsCountQueryVariables = Exact<{
  preset: NotificationsFilterPresetEnum;
}>;


export type GetNotificationsCountQuery = { __typename?: 'RootApiQuery', notifications?: { __typename?: 'Notifications', pagination?: { __typename?: 'NotificationConnection', totalCount?: number | null } | null } | null };

export type NotificationFragment = { __typename?: 'Notification', id: string, category: string, title: string, subtitle?: string | null, createdAt: any, updatedAt: any, readAt?: any | null, type: NotificationTypeEnum, shortInfo?: string | null, uri?: any | null, link?: { __typename?: 'Link', uri: any, label: string } | null, picture?: { __typename?: 'Image', src?: any | null, alt?: string | null, srcset?: any | null, height?: string | null, width?: string | null } | null, icon?: { __typename?: 'Image', src?: any | null, alt?: string | null, height?: string | null, width?: string | null } | null, primaryButton?: { __typename?: 'Button', label: string, action: any } | null, secondaryButton?: { __typename?: 'Button', label: string, action: any } | null };

export type MarkReadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MarkReadMutation = { __typename?: 'RootApiMutation', notifications?: { __typename?: 'NotificationsMutation', markRead?: { __typename: 'Notification', id: string, readAt?: any | null } | null } | null };

export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  id
  category
  title
  subtitle
  createdAt
  updatedAt
  readAt
  type
  shortInfo
  uri
  link {
    uri
    label
  }
  picture(size: b160) {
    src
    alt
    srcset
    height
    width
  }
  icon {
    src
    alt
    height
    width
  }
  primaryButton {
    label
    action
  }
  secondaryButton {
    label
    action
  }
}
    `;
export const GetImportantNotificationsDocument = gql`
    query GetImportantNotifications($lang: ApiLangEnum!, $count: Int!, $cursor: String) {
  notifications(
    lang: $lang
    filter: {preset: IMPORTANT_ALL, sort: updated_at_desc}
  ) {
    filter {
      preset
    }
    pagination(first: $count, after: $cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...Notification
        }
      }
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetImportantNotificationsQuery__
 *
 * To run a query within a React component, call `useGetImportantNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportantNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportantNotificationsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetImportantNotificationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>(GetImportantNotificationsDocument, options);
      }
export function useGetImportantNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>(GetImportantNotificationsDocument, options);
        }
export function useGetImportantNotificationsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>(GetImportantNotificationsDocument, options);
        }
export type GetImportantNotificationsQueryHookResult = ReturnType<typeof useGetImportantNotificationsQuery>;
export type GetImportantNotificationsLazyQueryHookResult = ReturnType<typeof useGetImportantNotificationsLazyQuery>;
export type GetImportantNotificationsSuspenseQueryHookResult = ReturnType<typeof useGetImportantNotificationsSuspenseQuery>;
export type GetImportantNotificationsQueryResult = Apollo.QueryResult<GetImportantNotificationsQuery, GetImportantNotificationsQueryVariables>;
export const GetAllNotificationsDocument = gql`
    query GetAllNotifications($lang: ApiLangEnum!, $count: Int!, $cursor: String) {
  notifications(lang: $lang, filter: {preset: ALL, sort: updated_at_desc}) {
    filter {
      preset
    }
    pagination(first: $count, after: $cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...Notification
        }
      }
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetAllNotificationsQuery__
 *
 * To run a query within a React component, call `useGetAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotificationsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAllNotificationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(GetAllNotificationsDocument, options);
      }
export function useGetAllNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(GetAllNotificationsDocument, options);
        }
export function useGetAllNotificationsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(GetAllNotificationsDocument, options);
        }
export type GetAllNotificationsQueryHookResult = ReturnType<typeof useGetAllNotificationsQuery>;
export type GetAllNotificationsLazyQueryHookResult = ReturnType<typeof useGetAllNotificationsLazyQuery>;
export type GetAllNotificationsSuspenseQueryHookResult = ReturnType<typeof useGetAllNotificationsSuspenseQuery>;
export type GetAllNotificationsQueryResult = Apollo.QueryResult<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>;
export const GetNewsNotificationsDocument = gql`
    query GetNewsNotifications($lang: ApiLangEnum!, $count: Int!, $cursor: String) {
  notifications(
    lang: $lang
    filter: {preset: NEWS_CENTER_ALL, sort: updated_at_desc}
  ) {
    filter {
      preset
    }
    pagination(first: $count, after: $cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...Notification
        }
      }
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetNewsNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNewsNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsNotificationsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNewsNotificationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>(GetNewsNotificationsDocument, options);
      }
export function useGetNewsNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>(GetNewsNotificationsDocument, options);
        }
export function useGetNewsNotificationsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>(GetNewsNotificationsDocument, options);
        }
export type GetNewsNotificationsQueryHookResult = ReturnType<typeof useGetNewsNotificationsQuery>;
export type GetNewsNotificationsLazyQueryHookResult = ReturnType<typeof useGetNewsNotificationsLazyQuery>;
export type GetNewsNotificationsSuspenseQueryHookResult = ReturnType<typeof useGetNewsNotificationsSuspenseQuery>;
export type GetNewsNotificationsQueryResult = Apollo.QueryResult<GetNewsNotificationsQuery, GetNewsNotificationsQueryVariables>;
export const GetNotificationsCountDocument = gql`
    query GetNotificationsCount($preset: NotificationsFilterPresetEnum!) {
  notifications(lang: de, filter: {preset: $preset}) {
    pagination {
      totalCount
    }
  }
}
    `;

/**
 * __useGetNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsCountQuery({
 *   variables: {
 *      preset: // value for 'preset'
 *   },
 * });
 */
export function useGetNotificationsCountQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>(GetNotificationsCountDocument, options);
      }
export function useGetNotificationsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>(GetNotificationsCountDocument, options);
        }
export function useGetNotificationsCountSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>(GetNotificationsCountDocument, options);
        }
export type GetNotificationsCountQueryHookResult = ReturnType<typeof useGetNotificationsCountQuery>;
export type GetNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetNotificationsCountLazyQuery>;
export type GetNotificationsCountSuspenseQueryHookResult = ReturnType<typeof useGetNotificationsCountSuspenseQuery>;
export type GetNotificationsCountQueryResult = Apollo.QueryResult<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>;
export const MarkReadDocument = gql`
    mutation markRead($id: ID!) {
  notifications {
    markRead(id: $id) {
      __typename
      id
      readAt
    }
  }
}
    `;
export type MarkReadMutationFn = Apollo.MutationFunction<MarkReadMutation, MarkReadMutationVariables>;

/**
 * __useMarkReadMutation__
 *
 * To run a mutation, you first call `useMarkReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadMutation, { data, loading, error }] = useMarkReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkReadMutation, MarkReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<MarkReadMutation, MarkReadMutationVariables>(MarkReadDocument, options);
      }
export type MarkReadMutationHookResult = ReturnType<typeof useMarkReadMutation>;
export type MarkReadMutationResult = Apollo.MutationResult<MarkReadMutation>;
export type MarkReadMutationOptions = Apollo.BaseMutationOptions<MarkReadMutation, MarkReadMutationVariables>;