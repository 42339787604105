import React from 'react';
import { Redirect } from 'react-router-dom';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { routes } from '../../routes/routesConfig';
import { LocalStorageKeys } from '../../types';

type VxLiveStep = 'CHAT' | 'MISSING_REQUIREMENTS' | 'READY_TO_GO_ONLINE';

/**
 * @returns current vxlive step that should be used
 */
export function getVxLiveCurrentStep(
  isAllowedToGoOnlineOnVXLive: boolean,
  wasOnlineOnVXLive: boolean
): VxLiveStep {
  if (!isAllowedToGoOnlineOnVXLive) {
    return 'MISSING_REQUIREMENTS';
  }

  if (wasOnlineOnVXLive) {
    return 'CHAT';
  }

  // if model was already online in this session,
  // redirect to chat instead of goOnline
  const alreadyWentOnlineLocaly = sessionStorage.getItem(
    LocalStorageKeys.MODEL_ACCESSED_CHAT
  );
  return alreadyWentOnlineLocaly ? 'CHAT' : 'READY_TO_GO_ONLINE';
}

/**
 * @param currentStep step where redirect url should be retrieved for
 * @returns redirect url
 */
export function getVxLiveRedirectUrl(currentStep: VxLiveStep): string {
  switch (currentStep) {
    case 'CHAT':
      return routes.vxLiveChat.path;
    case 'MISSING_REQUIREMENTS':
      return routes.vxLiveMissingRequirements.path;
    case 'READY_TO_GO_ONLINE':
      return routes.vxLiveGoOnline.path;
    default:
      return routes.error.path;
  }
}

const VxLivePage: React.FC = () => {
  const {
    isAccountStatusLoading,
    isAllowedToGoOnlineOnVXLive,
    wasOnlineOnVXLive,
    incompletedOnboardingTaskCount,
  } = useAccountStatus();

  const contentLoading = isAccountStatusLoading;

  const currentStep = getVxLiveCurrentStep(
    isAllowedToGoOnlineOnVXLive,
    wasOnlineOnVXLive
  );

  const redirectRoute = getVxLiveRedirectUrl(currentStep);

  return (
    <FluentPageLayout isContentLoading={contentLoading}>
      <Redirect
        to={{
          pathname: redirectRoute,
          state: { stepCount: incompletedOnboardingTaskCount },
        }}
      />
    </FluentPageLayout>
  );
};

export default VxLivePage;
