import { useBreakpointValue } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { ProfileFieldTypePictureFragment } from '../../../../../generated/graphql';
import { ImageRatings } from '../../../../../pages/ModelProfileV2Page/ModelProfilePage/types';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { ProfilePictureContainerBox } from "../ProfilePictureContainerBox/ProfilePictureContainerBox";

const ProfilePictureMediaPreviewBox: React.FC = () => {
  const buttonBreakpointSizes = useBreakpointValue({ base: 'sm', lg: 'md' });

  return (
    <ProfilePictureContainerBox
      aspectRatioProps={{ w: { base: '50px', lg: '108px' } }}
      iconButtonProps={{ size: buttonBreakpointSizes }}
      imageProps={{ sizes: '200px' }}
    />
  );
};

type ProfilePictureProps = {
  field?: Maybe<ProfileFieldTypePictureFragment>;
  isEditable?: boolean;
};

export const ProfilePicture: React.FC<ProfilePictureProps> = (props) => {

  return (
    <MediaPropertiesProvider.ForProfilePicture imageRating={ImageRatings.FSK16}>
      <MediaProvider.FromMediaPicture
        picture={props.field?.value}
        isEditable={props.isEditable}
        initialSize={{ width: 520, height: 520 }}
      >
        <ProfilePictureMediaPreviewBox />
      </MediaProvider.FromMediaPicture>
    </MediaPropertiesProvider.ForProfilePicture>
  );
};
