import { Box, VStack } from '@chakra-ui/react';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { TextareaControl } from '../../../../../components/shared/FormikFormElements';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { LanguagesEnum } from '../../../../../generated/graphql';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton, SecondaryButton } from '../../WizardProfile';
import { useWizardProfile } from '../../WizardProfileContext';

const mottoInputName = 'motto';

type ContentSectionProps = {
  formik: FormikContextType<any>;
};

const ContentSection: React.FC<ContentSectionProps> = ({ formik }) => {
  const { t } = useTranslation(['modelProfile']);

  const wizardCtx = useWizardProfile();
  const { modelMotto } = wizardCtx?.currentField ?? {};

  return (
    <FormikProvider value={formik}>
      <WizardSubHeader
        bottomMarginEnabled={false}
        headerText={t('modelProfile:fields.welcomeText.wizardPageTitle')}
      />
      <Form>
        <Box mt={8} mb={4}>
          <TextareaControl
            label={t('modelProfile:fields.welcomeText.label')}
            name={mottoInputName}
            textareaProps={{
              placeholder: t('modelProfile:fields.welcomeText.placeholder'),
              autoComplete: 'off',
            }}
            maxCharacterCount={modelMotto?.multiLangMaxLength ?? undefined}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const HintSection: React.FC = () => {
  const { t } = useTranslation(['general', 'modelProfile']);
  return <TipCard text={t('modelProfile:fields.welcomeText.hint')} />;
};

export const WizardProfileOnboardingV1Motto: React.FC = () => {
  const wizardCtx = useWizardProfile();
  const { t, i18n } = useTranslation([
    'modelProfile',
    'wizardProfile',
    'general',
  ]);
  const { modelMotto } = wizardCtx?.currentField ?? {};

  const currentLang = React.useMemo(() => {
    // Hacky workaround for 'cimode' language in tests
    if (i18n.language === 'cimode') {
      return LanguagesEnum.De;
    }
    return i18n.language as LanguagesEnum;
  }, [i18n]);

  const initialValues = React.useMemo(
    () => ({
      [mottoInputName]:
        modelMotto?.multiLangValue?.texts?.find(
          (entry) => entry.lang === currentLang
        )?.text ?? '',
    }),
    [modelMotto, currentLang]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [mottoInputName]: createStringValidationSchema({
          minLength: modelMotto?.multiLangMinLength,
          maxLength: modelMotto?.multiLangMaxLength,
        }),
      }),
    [modelMotto]
  );

  const onSubmit = React.useCallback(
    (values: typeof initialValues) => {
      wizardCtx.wizardNextStepCallback({
        modelMotto: [{ lang: currentLang, text: values[mottoInputName] }],
      });
    },
    [wizardCtx, currentLang]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <WizardParentModalStepLayout
      contentSection={<ContentSection formik={formik} />}
      hintSection={<HintSection />}
      footerSection={
        <VStack spacing={'2'}>
          <PrimaryButton
            isDisabled={!(formik.isValid && formik.dirty)}
            children={t('general:button.continue')}
            onClick={formik.submitForm}
          />
          <SecondaryButton
            children={t('general:button.skip')}
            onClick={() => wizardCtx.wizardNextStepCallback()}
          />
        </VStack>
      }
    />
  );
};
