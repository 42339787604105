import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import {
  DocumentUploadAssistantStepEnum,
  useWizardVerificationCreateTourLazyQuery,
  useWizardVerificationQuery,
} from '../../../generated/graphql';
import { routes } from '../../../routes/routesConfig';
import Logger from '../../../utils/Logger';
import { WizardParentModalLayout } from '../components/WizardParentModalLayout/WizardParentModalLayout';
import { WizardVerificationBackImage } from './step/BackImage/WizardVerificationBackImage';
import { WizardVerificationFinished } from './step/Finished/WizardVerificationFinished';
import { WizardVerificationFrontImage } from './step/FrontImage/WizardVerificationFrontImage';
import { WizardVerificationNameBirthday } from './step/NameBirthday/WizardVerificationNameBirthday';
import { WizardVerificationSelfie } from './step/Selfie/WizardVerificationSelfie';

export type WizardVerificationBasicProps = {
  /** Callback to go to the next step */
  goToNextStep: () => void;
  /** Whether to show the page-specific loader */
  showLoader: boolean;
  /** URL to upload the image to */
  uploadUrl: string;
  /** Closes the wizard and navigates back to the dashboard */
  closeWizard?: () => void;
  /** we only need this for the new onboarding flow */
  tourUserId?: number | null;

  nextStepLoading?: boolean;
};

export const WizardVerificationContext = React.createContext({
  setVisible: (isVisible: boolean): void => {},
  setOnFinished: (onFinished?: () => void): void => {},
});
export const useWizardVerification = () =>
  React.useContext(WizardVerificationContext);

export const WizardVerification: React.FC<{
  children?: React.ReactNode;
  isVisibleDefault?: boolean;
}> = ({ children, isVisibleDefault = false }) => {
  const { t } = useTranslation('wizardVerification');
  // Hard coding the total steps because the totalSteps from the query is not accurate.
  // This is due to the first name/last name/birthday step not being included in the tour itself
  const totalSteps = 5;
  const [isVisible, setVisible] = React.useState(isVisibleDefault);
  const [onFinished, setOnFinished] = React.useState<(() => void) | undefined>(
    undefined
  );

  const closeWizardHandler = () => {
    setVisible(false);
    setOnFinished(undefined);
  };

  const [tourUserId, setTourUserId] = React.useState<number | null>(null);
  const [showNameBirthdayStep, setShowNameBirthdayStep] =
    React.useState<boolean>(true);

  const [
    getTourData,
    {
      data: dataTour,
      loading: loadingTour,
      error: errorTour,
      refetch: refetchTour,
    },
  ] = useWizardVerificationCreateTourLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  // If the actor has a birthday, first name or last name, we don't need to show the first step
  const shouldShowNameBirthdayStep = (actor: any) => {
    if (
      actor?.birthday &&
      actor?.address?.firstname &&
      actor?.address?.lastname
    ) {
      setShowNameBirthdayStep(false);
    }
  };

  const { data, loading, error, refetch } = useWizardVerificationQuery({
    onCompleted: (data) => {
      // We need to find the oldest unverified actor in the list, as that is the first actor created on the account.
      const oldestUnverifiedActor = data.account.actors.reduceRight(
        (acc, actor) => {
          if (acc === null && actor?.personalDocumentsStatus === 'incomplete') {
            return actor;
          }
          return acc;
        },
        null
      );

      const isStudio = data.account.isStudio;
      const isOriginatorActor = data.account.originator?.isActor;
      const actor = data.account.actors[0];
      const userId = data.account.userId;

      if (isStudio || !!actor) {
        setTourUserId(actor?.userId ?? null);
        shouldShowNameBirthdayStep(actor);
      }

      if (isOriginatorActor) {
        setTourUserId(userId);
        shouldShowNameBirthdayStep(oldestUnverifiedActor);
      }

      getTourData({
        variables: {
          userId: tourUserId,
        },
      });
    },
    notifyOnNetworkStatusChange: true,
    skip: !isVisible,
  });

  const tourData = React.useMemo(() => {
    const tourCurrentStepPosition =
      dataTour?.documents.tour.currentStep?.position;
    const tourCurrentStepUrl = String(
      dataTour?.documents.tour.currentStep?.uploadUrl
    );
    const tourCurrentStepName = dataTour?.documents.tour.currentStep?.name;
    return {
      tourCurrentStepPosition,
      tourCurrentStepUrl,
      tourCurrentStepName,
    };
  }, [dataTour]);

  // An error occurred or we have no data
  if ((error || !tourData || !data || errorTour) && !loading && isVisible) {
    Logger.log('An error occurred or we have no data');
    return <Redirect to={{ pathname: routes.error.path }} push={true} />;
  }

  let pageContent: JSX.Element | null = null;
  if (!loading && data && !loadingTour && dataTour) {
    // Show first name/last name/birthday step if the tour step position is 1, as that means that the first step has not been done yet.
    if (showNameBirthdayStep) {
      pageContent = (
        <WizardVerificationNameBirthday
          userId={tourUserId}
          goToNextStep={refetch}
          showLoader={loadingTour}
        />
      );
    } else {
      switch (tourData.tourCurrentStepName ?? null) {
        case DocumentUploadAssistantStepEnum.IdentityProofFrontside: {
          pageContent = (
            <>
              <WizardVerificationFrontImage
                goToNextStep={refetchTour}
                uploadUrl={tourData.tourCurrentStepUrl}
                showLoader={loadingTour}
              />
            </>
          );
          break;
        }
        case DocumentUploadAssistantStepEnum.IdentityProofBackside: {
          pageContent = (
            <>
              <WizardVerificationBackImage
                goToNextStep={refetchTour}
                uploadUrl={tourData.tourCurrentStepUrl}
                showLoader={loadingTour}
                tourUserId={tourUserId}
              />
            </>
          );
          break;
        }
        case DocumentUploadAssistantStepEnum.IdShot: {
          pageContent = (
            <>
              <WizardVerificationSelfie
                goToNextStep={refetchTour}
                uploadUrl={tourData.tourCurrentStepUrl}
                showLoader={loadingTour}
              />
            </>
          );
          break;
        }
        case null: {
          pageContent = (
            <>
              <WizardVerificationFinished
                closeWizard={() => {
                  closeWizardHandler();
                  onFinished?.();
                }}
              />
            </>
          );
          break;
        }
      }
    }
  }

  return (
    <WizardVerificationContext.Provider value={{ setVisible, setOnFinished }}>
      {isVisible && (
        <WizardParentModalLayout
          headerText={t('headerText')}
          totalSteps={totalSteps}
          currentStep={
            // If currentStepPosition does exist, check if tourCurrentStepPosition is equal to 1
            tourData.tourCurrentStepPosition
              ? // If currentStepPosition is 1, show the first name/last name/birthday step (which is always step 1) if it has not been done yet
                tourData.tourCurrentStepPosition === 1
                ? // If the name/birthday step is done, show the next step, that being tourCurrentStepPosition + 1 as the name step is not included in the query.
                  showNameBirthdayStep
                  ? 1
                  : tourData.tourCurrentStepPosition + 1
                : // If currentStepPosition is not 1, also show tourCurrentStepPosition + 1 as the name step is not included in the query.
                  tourData.tourCurrentStepPosition + 1
              : // If nothing applies, set currentStep to totalSteps as there is an error somewhere.
                totalSteps
          }
          onCloseClick={closeWizardHandler}
          loading={loading}
        >
          {pageContent}
        </WizardParentModalLayout>
      )}
      {children}
    </WizardVerificationContext.Provider>
  );
};
