import {
  Section,
  SectionBody,
  SectionDescription,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icon from '@campoint/odi-ui-icons';
import { Button, Divider, VStack } from '@chakra-ui/react';
import type { FormikConfig } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SectionCenterContainer } from '../../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../../components/Layout/SectionDivider';
import {
  BodyModificationsEnum,
  GenderEnum,
} from '../../../../../generated/graphql';
import { EnumListField } from '../../../components/field/EnumListField';
import { EnumRadioField } from '../../../components/field/EnumRadioField';
import { NumberField } from '../../../components/field/NumberField';
import { useModelProfile } from '../../../provider/ModelProfileProvider';
import {
  extractInitialValuesFromFields,
  extractValidationSchema,
} from '../../../provider/utils';
import { useFieldInitialErrors, useFieldInitialTouched } from '../../utils';

export const AppearanceSection: React.FC = () => {
  const { t } = useTranslation(['modelProfile']);
  const unknownError = t('modelProfile:fields.unknownError.unknownError');

  const {
    appearanceCollection,
    appearanceCollectionError,
    aboutMeCollection,
    isSaveDataLoading,
    actions: { saveProfileData, setIsDataDirty },
  } = useModelProfile();

  const fields = React.useMemo(
    () => appearanceCollection?.fields ?? {},
    [appearanceCollection]
  );

  const initialErrors = useFieldInitialErrors(
    appearanceCollectionError,
    unknownError
  );
  const initialTouched = useFieldInitialTouched(appearanceCollectionError);

  const { isMaleOrTransgender, isFemaleOrTransgender } = React.useMemo(() => {
    const gender: GenderEnum = (aboutMeCollection?.fields?.modelGender?.value ??
      GenderEnum.Transgender) as GenderEnum;
    const maleSet = [GenderEnum.Male, GenderEnum.Transgender];
    const femaleSet = [GenderEnum.Female, GenderEnum.Transgender];
    return {
      isMaleOrTransgender: maleSet.includes(gender),
      isFemaleOrTransgender: femaleSet.includes(gender),
    };
  }, [aboutMeCollection]);

  const {
    modelBodyHeight,
    modelBodyWeight,
    modelBodyFigure,
    modelBodyModifications,
    modelPenisSize,
    modelPubicHair,
    modelHairColor,
    modelSkinType,
    modelBreastSize,
    modelEyeColor,
  } = fields;

  const validationSchema = React.useMemo(
    () =>
      extractValidationSchema(fields, {
        modelBodyHeight: {
          isOptional: true,
          isUnClearableOnceSet: true,
        },
        modelBodyWeight: {
          isOptional: true,
          isUnClearableOnceSet: true,
        },
        modelBreastSize: {
          isOptional: true,
        },
        modelHairColor: {
          isOptional: true,
        },
        modelEyeColor: {
          isOptional: true,
        },
        modelPenisSize: {
          isOptional: true,
        },
        modelSkinType: {
          isOptional: true,
        },
        modelBodyFigure: {
          isOptional: true,
        },
        modelPubicHair: {
          isOptional: true,
        },
        modelBodyModifications: {
          isOptional: true,
          emptyOption: BodyModificationsEnum.NoModifications,
        },
      }),
    [fields]
  );

  const initialValues = React.useMemo(
    () => extractInitialValuesFromFields(fields),
    [fields]
  );

  const onSubmitHandler = React.useCallback<
    FormikConfig<typeof initialValues>['onSubmit']
  >(
    async (values) => {
      if (isSaveDataLoading) {
        return;
      }
      const dirtyValues = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => initialValues[key] !== value
        )
      );
      saveProfileData({
        appearanceData: dirtyValues,
      }).then();
    },
    [initialValues, saveProfileData, isSaveDataLoading]
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    //@ts-ignore
    initialErrors,
    initialTouched,
    onSubmit: onSubmitHandler,
  });

  React.useEffect(() => {
    setIsDataDirty(formik.dirty);
    return () => {
      setIsDataDirty(false);
    };
  }, [formik.dirty, setIsDataDirty]);

  return (
    <Section aria-busy={isSaveDataLoading}>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={icon.Face} />
            <SectionTitle fontSize={'xl'}>
              {t('modelProfile:heading.Aussehen')}
            </SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t(
              'modelProfile:text.BeschreibeDeinAussehenSoGutWieMoglichDamitDuInDieRichtigenKategorienXX'
            )}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody>
        <SectionCenterContainer>
          <FormikProvider value={formik}>
            <VStack spacing={8} divider={<Divider size={'s'} />}>
              {modelBodyHeight && (
                <NumberField
                  field={modelBodyHeight}
                  label={t('modelProfile:label.Korpergrosse')}
                  placeholder={t(
                    'modelProfile:placeholder.KorpergrosseEingeben'
                  )}
                  rightAddon={'cm'}
                />
              )}
              {modelBodyWeight && (
                <NumberField
                  field={modelBodyWeight}
                  label={t('modelProfile:label.MeinGewicht')}
                  placeholder={t('modelProfile:placeholder.GewichtEingeben')}
                  rightAddon={'kg'}
                />
              )}
              {modelBodyFigure && (
                <EnumRadioField
                  field={modelBodyFigure}
                  label={t('modelProfile:label.Figur')}
                />
              )}
              {isFemaleOrTransgender && modelBreastSize && (
                <EnumRadioField
                  isDisabled={modelBreastSize?.disabled ?? false}
                  isReadOnly={modelBreastSize?.readOnly ?? false}
                  field={modelBreastSize}
                  label={t('modelProfile:label.Korbchengrosse')}
                />
              )}
              {isMaleOrTransgender && modelPenisSize && (
                <EnumRadioField
                  field={modelPenisSize}
                  isDisabled={modelPenisSize?.disabled ?? false}
                  isReadOnly={modelPenisSize?.readOnly ?? false}
                  label={t('modelProfile:label.Penisgrosse')}
                />
              )}
              {modelSkinType && (
                <EnumRadioField
                  field={modelSkinType}
                  label={t('modelProfile:label.MeinHauttyp')}
                />
              )}
              {modelEyeColor && (
                <EnumRadioField
                  field={modelEyeColor}
                  label={t('modelProfile:label.MeineAugenfarbe')}
                />
              )}
              {modelHairColor && (
                <EnumRadioField
                  field={modelHairColor}
                  isDisabled={modelHairColor?.disabled ?? false}
                  isReadOnly={modelHairColor?.readOnly ?? false}
                  label={t('modelProfile:label.Haarfarbe')}
                />
              )}
              {modelPubicHair && (
                <EnumRadioField
                  field={modelPubicHair}
                  label={t('modelProfile:label.Intimrasur')}
                />
              )}
              {modelBodyModifications && (
                <EnumListField
                  field={modelBodyModifications}
                  label={t('modelProfile:label.Korperschmuck')}
                  emptyValue={BodyModificationsEnum.NoModifications}
                  placeholder={t(
                    'modelProfile:placeholder.KorperschmuckAuswahlen'
                  )}
                />
              )}
            </VStack>
          </FormikProvider>
        </SectionCenterContainer>
      </SectionBody>
      <SectionDivider isWidthRestricted />
      <SectionFooter>
        <SectionCenterContainer>
          <Button
            alignSelf={'center'}
            variant={'solid'}
            onClick={() => formik.submitForm()}
            children={t('modelProfile:button.SpeichernVeroffentlichen')}
            isDisabled={!formik.isValid || !formik.dirty}
            isLoading={formik.isSubmitting || isSaveDataLoading}
          />
        </SectionCenterContainer>
      </SectionFooter>
    </Section>
  );
};
