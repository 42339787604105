import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetWizardPayoutDataQuery,
  InputTourPayoutV1,
  ProfileFieldsEnum,
  TourDataWizardPayoutFragment,
  WizardStepFieldFragment,
  useGetWizardPayoutDataQuery,
  useUpdateWizardPayoutDataMutation,
} from '../../../generated/graphql';
import Logger from '../../../utils/Logger';
import { WizardParentModalLayout } from '../components/WizardParentModalLayout/WizardParentModalLayout';
import { useWizardNavigateToNextStepCallback } from '../utils/hooks';
import { extractCurrentFieldError } from '../utils/utils';
import { WizardPayoutAddress } from './Address/WizardPayoutAddress';
import { WizardPayoutBankData } from './BankData/WizardPayoutBankData';
import WizardPayoutFinished from './Finished/WizardPayoutFinished';
import { WizardPayoutTaxDetails } from './TaxDetails/WizardPayoutTaxDetails';
import WizardPayoutWelcome from './Welcome/WizardPayoutWelcome';
import {
  WizardPayoutContextProvider,
  WizardPayoutTypes,
  possibleStepIds,
} from './WizardPayoutContext';

export const wizardPayoutSteps: WizardPayoutTypes.StepsConfig = {
  PayoutV1Welcome: {
    element: <WizardPayoutWelcome />,
  },
  PayoutV1BankInformation: {
    element: <WizardPayoutBankData />,
  },
  PayoutV1ProofOfAddress: {
    element: <WizardPayoutAddress />,
  },
  PayoutV1SalesTaxObligation: {
    element: <WizardPayoutTaxDetails />,
  },
  PayoutV1Finish: {
    element: <WizardPayoutFinished />,
  },
};
type State = {
  totalSteps: number;
  currentStep: WizardPayoutTypes.Step | null;
  currentField: Partial<WizardPayoutTypes.Field>;
  currentFieldError: Partial<WizardPayoutTypes.FieldError>;
  isVisible: boolean;
  onFinished?: () => void;
} & WizardPayoutTypes.Addition;

const defaultState = {
  totalSteps: possibleStepIds.length,
  currentStep: null,
  currentField: {},
  currentFieldError: {},
  isAccountFromGermany: true,
  isAccountFromEU: true,
  isAccountFromCH: false,
  accountPrefillField: {},
  isVisible: false,
  onFinished: undefined,
};

export const WizardPayout: React.FC<{
  children?: React.ReactNode;
  isVisibleDefault?: boolean;
}> = ({ children, isVisibleDefault = false }) => {
  const { t } = useTranslation(['wizardPayout', 'validation']);

  const [state, setState] = useState<State>({
    ...defaultState,
    isVisible: isVisibleDefault,
  });
  const { totalSteps, currentStep, isVisible, onFinished } = state;

  const wizardCloseCallback = useCallback(() => {
    setState(() => defaultState);
  }, [setState]);
  const navigateToNextWizardStepCallback = useWizardNavigateToNextStepCallback(
    wizardPayoutSteps,
    () => {
      wizardCloseCallback();
      onFinished?.();
    }
  );

  const setVisible = React.useCallback(
    (isVisible: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isVisible,
      }));
    },
    [setState]
  );

  const setOnFinished = React.useCallback(
    (onFinished?: () => void) => {
      setState((prevState) => ({
        ...prevState,
        onFinished,
      }));
    },
    [setState]
  );

  const setTourStepData = useCallback(
    (
      tourData?: TourDataWizardPayoutFragment | null,
      addition?: Partial<WizardPayoutTypes.Addition>
    ) => {
      const step = tourData?.currentStep ?? null;

      // payout v1 inconsistency workaround
      // API returns Finish as the next step after Finish
      if (
        currentStep?.id === 'PayoutV1Finish' &&
        step?.id === 'PayoutV1Finish'
      ) {
        navigateToNextWizardStepCallback(null);
        return;
      }

      setState((prevState) => ({
        ...prevState,
        totalSteps: tourData?.totalSteps ?? possibleStepIds.length,
        currentStep: step,
        currentField: extractTourStepField(tourData),
        currentFieldError: extractCurrentFieldError(
          tourData?.validate?.data,
          t('validation:error.UnbekannterFehler')
        ),
        ...addition,
      }));

      navigateToNextWizardStepCallback(tourData?.currentStep?.id ?? null);
    },
    [currentStep?.id, navigateToNextWizardStepCallback, t]
  );

  const { loading } = useGetWizardPayoutDataQuery({
    onCompleted: (data) => {
      if (data?.tour?.__typename !== 'TourPayoutV1') {
        return;
      }
      const accountPrefillField = extractFields(data);

      setTourStepData(data?.tour, { ...data?.account, accountPrefillField });
    },
    skip: !isVisible,
  });

  const [updateStepData] = useUpdateWizardPayoutDataMutation({
    onCompleted: (data) => {
      if (data?.tour?.__typename !== 'TourPayoutV1Mutation') {
        return;
      }

      setTourStepData(data?.tour?.update);
    },
  });

  const wizardNextStepCallback = React.useCallback(
    async (fields?: InputTourPayoutV1) => {
      if (!currentStep?.id) {
        return;
      }

      try {
        await updateStepData({
          variables: {
            step: currentStep.id,
            data: fields ?? {},
          },
        });
      } catch (error) {
        Logger.error(error);
      }
    },
    [updateStepData, currentStep]
  );

  const contextForSteps = React.useMemo<WizardPayoutTypes.Context>(() => {
    return {
      ...state,
      wizardCloseCallback,
      wizardNextStepCallback,
      setVisible,
      setOnFinished,
    };
  }, [
    state,
    wizardCloseCallback,
    wizardNextStepCallback,
    setVisible,
    setOnFinished,
  ]);

  return (
    <WizardPayoutContextProvider value={contextForSteps}>
      {isVisible && (
        <WizardParentModalLayout
          headerText={t('wizardPayout:heading.AuszahlungEinrichten')}
          totalSteps={totalSteps}
          currentStep={currentStep?.pos ?? 1}
          loading={loading}
          onCloseClick={wizardCloseCallback}
          isFooterSticky={false}
        >
          {currentStep &&
            wizardPayoutSteps[currentStep!.id as keyof typeof wizardPayoutSteps]
              ?.element}
        </WizardParentModalLayout>
      )}
      {children}
    </WizardPayoutContextProvider>
  );
};

export function extractTourStepField<
  F extends {
    [key in ProfileFieldsEnum[number]]: Partial<WizardStepFieldFragment>;
  }
>(_: TourDataWizardPayoutFragment | null | undefined): Partial<F> {
  const list = _?.currentStep?.fields ?? [];
  if (list.length <= 0) {
    return {};
  }

  return list.reduce<Partial<F>>((acc, field) => {
    if (!field?.name) {
      return acc;
    }
    return { ...acc, [field?.name]: field };
  }, {});
}

function extractFields(data?: GetWizardPayoutDataQuery) {
  const collection = data?.profile.collection;
  return collection?.__typename === 'ProfileCollectionAccountV1'
    ? collection.fields
    : null;
}
