import { Spacer, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AgeIndicator } from '../AgeIndicator/AgeIndicator';
import {
  MenuModal,
  MenuModalContent,
  MenuModalItem,
  MenuModalItemList,
  MenuModalOverlay,
} from '../FeedPostMenu/FeedPostMenu';
import { CommentHighlightMentions } from './CommentHighlightMentions';
import {
  CommentAvatar,
  CommentBox,
  CommentContent,
  CommentFooter,
  CommentText,
  CommentUsername,
  FeedActionMenuIconButton,
} from './shared';

export type CommentFromUserProps = {
  avatarSrc?: string;
  username: string;
  text?: string;
  created?: string;
  commentId: string;
  onEdit: () => void;
  openConfirmDeletionDialog: (commentId: string) => void;
  isCommentEditable?: boolean;
};

export const CommentFromUser: React.FC<CommentFromUserProps> = ({
  avatarSrc,
  username,
  text = '',
  created,
  commentId,
  onEdit,
  openConfirmDeletionDialog,
  isCommentEditable = true,
}) => {
  const actionMenu = useDisclosure();
  const actionMenuTriggerButtonRef = React.useRef(null);
  const { t } = useTranslation(['feed']);

  return (
    <CommentBox>
      <CommentAvatar src={avatarSrc} />
      <CommentContent>
        <CommentUsername children={username} />
        <CommentText>
          <CommentHighlightMentions children={text} />
        </CommentText>
        <CommentFooter>
          <AgeIndicator created={created} />
          <Spacer />
          {isCommentEditable && (
            <FeedActionMenuIconButton
              onClick={actionMenu.onOpen}
              ref={actionMenuTriggerButtonRef}
            />
          )}
        </CommentFooter>
      </CommentContent>
      {isCommentEditable && (
        <MenuModal
          isOpen={actionMenu.isOpen}
          onClose={actionMenu.onClose}
          finalFocusRef={actionMenuTriggerButtonRef}
        >
          <MenuModalOverlay />
          <MenuModalContent>
            <MenuModalItemList>
              <MenuModalItem
                onClick={() => {
                  actionMenu.onClose();
                  onEdit();
                }}
              >
                {t('feed:menuItem.KommentarBearbeiten')}
              </MenuModalItem>
              <MenuModalItem
                onClick={() => {
                  actionMenu.onClose();
                  openConfirmDeletionDialog(commentId);
                }}
                color={'error.500'}
              >
                {t('feed:menuItem.KommentarLoschen')}
              </MenuModalItem>
            </MenuModalItemList>
          </MenuModalContent>
        </MenuModal>
      )}
    </CommentBox>
  );
};
