import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  UseDisclosureProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  NoticeModal,
  NoticeModalConfig,
} from '../../../../../../components/shared/NoticeModal/NoticeModal';
import { ChevronRightIcon } from '../../../../../../components/shared/icon/ChevronRightIcon';

export const PaymentExpiredAlert: React.FC<
  {
    text?: string;
    noticeModalConfig?: NoticeModalConfig | null;
    disclosureProps?: UseDisclosureProps;
  } & AlertProps
> = ({ text, noticeModalConfig, disclosureProps, ...alertProps }) => {
  const { t } = useTranslation(['general']);
  const disc = useDisclosure(disclosureProps);
  const isInteractive = !!noticeModalConfig || !!alertProps.onClick;
  return (
    <Alert
      status={'error'}
      cursor={isInteractive ? 'pointer' : 'unset'}
      onClick={isInteractive ? disc.onOpen : undefined}
      {...alertProps}
    >
      <VStack justifyContent={'center'} textAlign={'center'}>
        <AlertIcon />
        <AlertTitle flexGrow={1} fontWeight={'500'}>
          {text ?? t('general:status.Abgelaufen')}
        </AlertTitle>
        {isInteractive && <ChevronRightIcon />}
        {!!noticeModalConfig && (
          <NoticeModal
            isOpen={disc.isOpen}
            onClose={disc.onClose}
            {...noticeModalConfig}
          />
        )}
      </VStack>
    </Alert>
  );
};
