import { Error as ErrorIcon } from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  HStack,
  Heading,
  Icon,
  VStack,
  VisuallyHidden,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../../components/Layout/ResponsiveModal';
import { ChevronRightIcon } from '../../../../../components/shared/icon/ChevronRightIcon';

interface PayoutRejectAlertProps {
  alertProps?: AlertProps;
  alertDescription: React.ReactNode;
  modalHeading: React.ReactNode;
  modalTextSlot: React.ReactNode;
  modalHelpLinkSlot?: React.ReactNode;
  modalFooterSlot?:
    | React.ReactNode
    | ((onClose: () => void) => React.ReactNode);
}

export const PayoutRejectAlert: React.FC<PayoutRejectAlertProps> = ({
  alertProps,
  alertDescription,
  modalHeading,
  modalTextSlot,
  modalHelpLinkSlot,
  modalFooterSlot,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Alert
        status={'error'}
        onClick={onOpen}
        cursor={'pointer'}
        {...alertProps}
      >
        <AlertIcon />
        <AlertDescription flexGrow={1}>{alertDescription}</AlertDescription>
        <ChevronRightIcon />
      </Alert>
      <ResponsiveModal isOpen={isOpen} onClose={onClose} isCentered>
        <ResponsiveModalOverlay />
        <ResponsiveModalContent
          maxW={'567px'}
          minH={{ base: '-webkit-fill-available', sm: 'min(100vh, 24rem)' }}
        >
          <ResponsiveModalStickyHeaderBox shadow={'unset'} bg={'transparent'}>
            <VisuallyHidden>{modalHeading}</VisuallyHidden>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>
          <ResponsiveModalBodyBox>
            <VStack alignItems={'start'} spacing={'22px'}>
              <HStack alignItems={'baseline'}>
                <Icon as={ErrorIcon} boxSize={'icon.md'} color={'error.500'} />
                <Heading size={'2xl'}>{modalHeading}</Heading>
              </HStack>
              {modalTextSlot}
              {modalHelpLinkSlot}
            </VStack>
          </ResponsiveModalBodyBox>
          <ResponsiveModalStickyFooterBox>
            {typeof modalFooterSlot === 'function'
              ? modalFooterSlot(onClose)
              : modalFooterSlot}
          </ResponsiveModalStickyFooterBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </>
  );
};
