import { CroppedImage } from '../../components/shared/ImageCropper/ImageCropper';

export function readFile(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const createImage = async (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });
};

/**
 * To work around firefox down-sampling implementation,
 * introduce a intermediate image buffer, that holds a
 * dynamically blurred version of the image, to help
 * pre-filter pixels for the down-sampling.
 *
 * As the canvas can not be arbitrary big,
 * the canvas is capped in size to 4049 px
 *
 * In case firefox will support `imageSmoothingQuality = 'high';`
 * this step won't be necessary anymore
 *
 * @see https://stackoverflow.com/a/17862644
 */
const getDownSampledImageForFirefox = (
  image: HTMLImageElement,
  sourceWidth: number,
  targetWidth: number
) => {
  const longestImageDimension = Math.max(
    1, // must be at least 1x1
    Math.max(image.width, image.height)
  );

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
  const maxSizeDownscaleFactor =
    Math.min(4096, longestImageDimension) / longestImageDimension;
  const downsizeCanvas = document.createElement('canvas');
  downsizeCanvas.width = Math.min(image.width * maxSizeDownscaleFactor, 4096);
  downsizeCanvas.height = Math.min(image.height * maxSizeDownscaleFactor, 4096);
  const downsizeCtx = downsizeCanvas.getContext('2d');

  if (!downsizeCtx) {
    return null;
  }

  const isCanvasFilterSupported = typeof downsizeCtx.filter !== 'undefined';
  const useManualDownSampling =
    isCanvasFilterSupported && /firefox/i.test(navigator.userAgent);
  if (!useManualDownSampling) {
    return null;
  }
  // Draw blurred image based on resolution differences
  // This basically pre-filters the data for the a down-sampling step
  const steps = ((sourceWidth * maxSizeDownscaleFactor) / targetWidth) >> 1;

  downsizeCtx.filter = `blur(${steps}px)`;
  downsizeCtx.drawImage(
    image,
    0,
    0,
    downsizeCanvas.width,
    downsizeCanvas.height
  );
  downsizeCtx.filter = 'none';

  return downsizeCanvas;
};

export const getCroppedImage = async (
  imageSrc: string,
  enforceTargetImageDimensions: boolean,
  targetImageDimension: { width: number; height: number },
  crop: { x: number; y: number },
  pixelCrop: { width: number; height: number; x: number; y: number },
  rotation = 0
): Promise<CroppedImage | null> => {
  const image = await createImage(imageSrc);

  if (!enforceTargetImageDimensions) {
    const largestDimension = Math.max(pixelCrop.width, pixelCrop.height);
    const maxAllowedExtend = 3840;
    const downscaleFactor =
      Math.min(largestDimension, maxAllowedExtend) / largestDimension;
    targetImageDimension = {
      width: Math.round(pixelCrop.width * downscaleFactor),
      height: Math.round(pixelCrop.height * downscaleFactor),
    };
  }

  const downsizeCanvas = getDownSampledImageForFirefox(
    image,
    pixelCrop.width,
    targetImageDimension.width
  );

  const targetCanvas = document.createElement('canvas');
  targetCanvas.width = targetImageDimension.width;
  targetCanvas.height = targetImageDimension.height;
  const targetCtx = targetCanvas.getContext('2d');

  if (!targetCtx) {
    return null;
  }

  const tx = pixelCrop.x;
  const ty = pixelCrop.y;
  const s = targetImageDimension.width / pixelCrop.width;

  // Transformation order is important!
  // Adjust dimensions
  targetCtx.scale(s, s);
  // Move into place
  targetCtx.translate(-tx, -ty);

  // Breaking Change in react-easy-crop@4 altered crop area calculation in respect to rotations
  // https://github.com/ValentinH/react-easy-crop/releases/tag/v4.0.0

  // Rotate into place
  const rotationInRadians = (rotation / 180) * Math.PI;
  const bBoxWidth =
    Math.abs(Math.cos(rotationInRadians) * image.width) +
    Math.abs(Math.sin(rotationInRadians) * image.height);
  const bBoxHeight =
    Math.abs(Math.sin(rotationInRadians) * image.width) +
    Math.abs(Math.cos(rotationInRadians) * image.height);

  const imageCenter = { x: image.width / 2, y: image.height / 2 };
  targetCtx.translate(+bBoxWidth / 2, +bBoxHeight / 2);
  targetCtx.rotate((rotation / 180) * Math.PI);
  targetCtx.translate(-imageCenter.x, -imageCenter.y);

  // Enable higher quality interpolation
  targetCtx.imageSmoothingEnabled = true;
  targetCtx.imageSmoothingQuality = 'high';

  // Draw image
  if (downsizeCanvas) {
    targetCtx.drawImage(downsizeCanvas, 0, 0, image.width, image.height);
  } else {
    targetCtx.drawImage(image, 0, 0, image.width, image.height);
  }

  const type = 'image/jpeg';

  // As a blob
  const blob = await getCanvasBlob(targetCanvas, type);

  // As object Url string
  const blobSrc = !blob ? null : URL.createObjectURL(blob);

  const size = {
    width: targetCanvas.width,
    height: targetCanvas.height,
  };

  // Cleanup created elements
  image.remove();
  downsizeCanvas?.remove();
  targetCanvas.remove();

  if (!blob || !blobSrc) {
    return null;
  }

  return { blob, blobSrc, type, size };
};

async function getCanvasBlob(
  canvas: HTMLCanvasElement,
  type: string = 'image/png',
  quality?: any
): Promise<Blob | null> {
  return new Promise((resolve) => canvas.toBlob(resolve, type, quality));
}
