// Based on https://github.com/kgnugur/formik-chakra-ui/blob/master/src/select-control/index.tsx
import { Select, SelectProps, forwardRef } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { BaseProps, FormControl } from '../FormControl';
import { SelectOptionList, SelectOptionListProps } from './SelectOptionList';

export type SelectControlProps = BaseProps & {
  selectProps?: SelectProps;
  optionListProps?: SelectOptionListProps;
  children?: React.ReactNode;
};

export const SelectControl = forwardRef<SelectControlProps, 'select'>(
  (props, ref) => {
    const { name, label, selectProps, optionListProps, children, ...rest } =
      props;
    const [field] = useField(name);

    return (
      <FormControl name={name} label={label} {...rest}>
        {children ?? (
          <Select {...field} ref={ref} {...selectProps}>
            {optionListProps && <SelectOptionList {...optionListProps} />}
          </Select>
        )}
      </FormControl>
    );
  }
);

export default SelectControl;
