//we need this enum because the pending state does not exist in the backend image status.
// If it is not ok and not rejected, then it is indirectly pending.
// To map this, we need this type
export enum ImageStatusEnum {
  ok = 'OK',
  pending = 'PENDING',
  rejected = 'REJECTED',
}

export enum ImageRatings {
  FSK12 = 'FSK12',
  FSK16 = 'FSK16',
  FSK18 = 'FSK18',
}
