import {
  OdiCard,
  OdiCardDescription,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardTitle,
} from '@campoint/odi-ui';
import { BoxProps, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { routes } from '../../../../routes/routesConfig';

export const SupportCard: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation(['general']);
  const navigateToHelp = () => {
    window.open(routes.help.path, '_blank');
  };
  return (
    <OdiCard border={'none'} {...props}>
      <OdiCardHeader>
        <OdiCardTitle
          fontSize={'md'}
          children={t('general:heading.DuHastWeitereFragen')}
        />
        <OdiCardDescription
          children={
            <Text
              fontSize={'md'}
              children={t('general:text.UnserSupportHilftDirGerneWeiter')}
            />
          }
        />
      </OdiCardHeader>
      <OdiCardFooter>
        <Button
          variant="outline"
          children={t('general:button.SupportKontaktieren')}
          onClick={navigateToHelp}
        />
      </OdiCardFooter>
    </OdiCard>
  );
};
