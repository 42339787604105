import * as React from 'react';

import {
  StatisticsEntryFragment,
  useGetStatisticProviderLazyQuery,
} from '../generated/graphql';
import { asyncNoop, noop } from '../utils';
import Logger from '../utils/Logger';
import { useAuth } from './AuthProvider';

type StatisticContext = {
  update: () => Promise<void>;
  /**
   * Note! it is important, that the returned cleanup fn is returned within useEffect,
   * this unsubscribes the callee on unmount
   *
   * Lets users of this context raise interest in having the data polled regularly.
   * If there are no mounted uses of this context, then we don't need to poll.
   * (that is: `pollSubscribers.current.size === 0`)
   *
   * @see useStatistic
   * @example
   * const context = React.useContext(statisticContext);
   * const { raiseInterestInPolling } = context;
   * React.useEffect(() => raiseInterestInPolling(), [raiseInterestInPolling]);
   *
   * @example
   * // useStatistic does this internally
   * const context = React.useContext(statisticContext);
   * const { raiseInterestInPolling } = context;
   * React.useEffect(() => {
   *   if (shouldPoll) {
   *     return raiseInterestInPolling();
   *   }
   * }, [shouldPoll, raiseInterestInPolling]);
   */
  raiseInterestInPolling: () => () => void;
  setSelected: (list: string[]) => void;
  isPolling: boolean;
  setIsPolling: React.Dispatch<React.SetStateAction<boolean>>;
  entries: StatisticsEntryFragment[];
  lastUpdatedAt: Date | null;
};

const initialValue: StatisticContext = {
  isPolling: false,
  setIsPolling: noop,
  setSelected: noop,
  update: asyncNoop,
  raiseInterestInPolling: () => () => {
    // This is intentional
  },
  entries: [],
  lastUpdatedAt: null,
};

export const statisticContext =
  React.createContext<StatisticContext>(initialValue);
export const StatisticProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const pollSubscribers = React.useRef(new Set());

  const [, { refetch }] = useGetStatisticProviderLazyQuery({
    // https://github.com/apollographql/apollo-client/issues/5531
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const statisticData = data.statistics;

      setValue((prevState) => ({
        ...prevState,
        ...statisticData,
        lastUpdatedAt: new Date(),
      }));
    },
    onError: (error) => {
      Logger.error({ error });
      setValue((prevState) => ({
        ...prevState,
        lastUpdatedAt: new Date(),
      }));
    },
  });

  const update = React.useCallback(async () => {
    await refetch();
  }, [refetch]);

  const raiseInterestInPolling = React.useCallback(() => {
    const subscribers = pollSubscribers.current;
    const uniqueObject = {};
    if (subscribers) {
      subscribers.add(uniqueObject);
      // Trigger an update for the first subscriber
      if (subscribers.size === 1) {
        update().then();
      }
    }
    return () => subscribers.delete(uniqueObject);
  }, [pollSubscribers, update]);

  const pollInterval = 60_000;
  React.useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const interval = setInterval(() => {
      const hasPollSubscribers = pollSubscribers.current.size > 0;
      const browserTabIsVisible = !document.hidden;
      if (hasPollSubscribers && browserTabIsVisible) {
        update().then();
      }
    }, pollInterval);
    return () => clearInterval(interval);
  }, [pollSubscribers, update, pollInterval, isAuthenticated]);

  const [value, setValue] = React.useState<StatisticContext>({
    ...initialValue,
    raiseInterestInPolling,
    update,
  });

  return <statisticContext.Provider value={value} children={children} />;
};

export const useStatistic = (shouldPoll = false) => {
  const context = React.useContext(statisticContext);
  const { raiseInterestInPolling } = context;
  React.useEffect(() => {
    if (shouldPoll) {
      return raiseInterestInPolling();
    }
    return noop;
  }, [shouldPoll, raiseInterestInPolling]);
  return context;
};
