import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import Logger from '../utils/Logger';

export const useDefaultApolloErrorHandler = () => {
  const { t } = useTranslation(['general']);

  const onQueryError = React.useCallback(
    (error: ApolloError) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
        status: 'error',
      });
    },
    [t]
  );

  const onMutationError = React.useCallback(
    (error: ApolloError) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        status: 'error',
      });
    },
    [t]
  );

  return {
    onQueryError,
    onMutationError,
  };
};
