import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureName, useFeature } from '../../../flags';
import { useGetVxLiveChannelDeeplinkLazyQuery } from '../../../generated/graphql';
import { useIsVisibleInViewport } from '../../../hooks/useIsVisibleInViewport';
import Logger from '../../../utils/Logger';
import { issueChakraToast } from '../../Layout/ChakraToastContainer';

interface ChatWithUserButtonProps extends ButtonProps {
  guestUserId: string;
}

/**
 * in milliseconds
 * `sid` (session id) used in the link will be valid for ~15min,
 * so try to refresh before that
 */
const VXLIVE_CHAT_DEEPLINK_REFRESH_INTERVAL = 14 * 60 * 1000;

export const ChatWithUserButton: React.FC<ChatWithUserButtonProps> = ({
  guestUserId,
  ...buttonProps
}) => {
  const feedVxLiveChatButtonFlag = useFeature(FeatureName.feedVxLiveChatButton);

  const { t } = useTranslation(['feed', 'general']);

  const ref = React.useRef(null);
  const isVisibleInViewport = useIsVisibleInViewport(ref);

  const [getVxLiveChannelDeeplink, { data, loading, refetch }] =
    useGetVxLiveChannelDeeplinkLazyQuery({
      variables: {
        guestUserId,
      },
      fetchPolicy: 'no-cache',
      onError: (error) => {
        Logger.error(error);
        issueChakraToast({
          description: t('general:toast.DatenKonntenNichtGeladenWerden'),
          status: 'error',
        });
      },
    });

  // using refetch here leaves loading as false during the update
  const triggerDeepLinkUpdate = React.useCallback(() => {
    refetch().then();
  }, [refetch]);

  // Only fetch url when button is visible in viewport
  // and try to keep it valid by refreshin before it runs out
  React.useEffect(() => {
    const intervalhandler = () => {
      if (isVisibleInViewport) {
        getVxLiveChannelDeeplink().then();
      }
    };
    intervalhandler();
    const interval = setInterval(
      intervalhandler,
      VXLIVE_CHAT_DEEPLINK_REFRESH_INTERVAL
    );
    return () => clearInterval(interval);
  }, [isVisibleInViewport, getVxLiveChannelDeeplink]);

  const url = data?.vxlive?.deepLinkUrl ?? undefined;

  return !feedVxLiveChatButtonFlag ? null : (
    <Button
      ref={ref}
      isDisabled={!loading && !url}
      isLoading={loading}
      // Try to update the deeplLink url at the beginning of the interaction
      // so in case the url `sid` expired, hopefully, the link works on the second try
      onTouchStart={triggerDeepLinkUpdate}
      onMouseDown={triggerDeepLinkUpdate}
      onClick={() => {
        // must stay synchronous to avoid pop-up blockers
        // triggered by imediate user action
        window.open(url, '_blank');
      }}
      // anticipate behaviour in flex situations
      flexShrink={0}
      {...buttonProps}
    >
      {t('feed:button.Chatten')}
    </Button>
  );
};
