// Base on https://github.com/kgnugur/formik-chakra-ui/blob/master/src/form-checkbox/checkbox-single.tsx
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export type CheckboxSingleControlProps = {
  name: string;
  label?: React.ReactNode;
  checkBoxProps?: CheckboxProps;
  children?: React.ReactNode;
};

export const CheckboxSingleControl: React.FC<CheckboxSingleControlProps> = ({
  name,
  label,
  children,
  checkBoxProps,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl isInvalid={!!errors[name]}>
      <Checkbox
        alignItems={'start'}
        {...register(name)}
        id={name}
        {...checkBoxProps}
      >
        {label}
        {children}
      </Checkbox>
      <Box minH={5} mt={1}>
        {errors[name] && (
          <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
        )}
      </Box>
    </FormControl>
  );
};
