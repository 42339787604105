/**
 * Enum with valid keys for local storage
 */
export enum LocalStorageKeys {
  SELECTED_THEME = 'selectedTheme',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  LANGUAGE = 'lang',
  HIDE_WELCOME_MODAL = 'hideWelcomeModal',
  HIDE_WELCOME_TOUR = 'hideWelcomeTour',
  HIDE_PROFILE_COMPLETION_MODAL = 'hideProfileCompletionModal',
  MODEL_ACCESSED_CHAT = 'modelAccessedChat',
  FEED_FILTER = 'feedFilter',
  PARTICIPATED_IN_VIDEO_CONTSEST_ID = 'participatedInVideoContestId',
  PARTICIPATED_IN_PHOTO_CONTSEST_ID = 'participatedInPhotoContestId',
  GMU_MODAL_SHOWN = 'gmuModal',
}
