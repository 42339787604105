import React from 'react';

import { ApiLangEnum, useGetCmsMarkdownQuery } from '../generated/graphql';
import { useActiveApiLanguage } from './useActiveApiLanguage';

type KnownCmsArticleId =
  /** Impressum */
  | 'imprint'
  /** Hausordnung */
  | 'houserules'
  /** Datenschutzerklärung / Privacy-Policy */
  | 'gdpr'
  /** AGB / Terms ans conditions */
  | 'terms-models';
interface UseCmsMarkdownOptions {
  id: KnownCmsArticleId;
  lang?: ApiLangEnum;
}

export const useCmsMarkdown = (options: UseCmsMarkdownOptions) => {
  const lang = useActiveApiLanguage();
  const { loading, data } = useGetCmsMarkdownQuery({
    variables: {
      lang: options.lang ?? lang,
      id: options.id,
    },
  });
  return React.useMemo(() => {
    return {
      loading,
      markdownString: data?.cms?.article?.content,
    };
  }, [loading, data]);
};
