import * as icons from '@campoint/odi-ui-icons';
import { Box, Button, Icon, Input, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import React from 'react';

import { Countdown } from '../../../../../components/shared/Countdown';
import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import { ContestIcon, ContestTileBase } from './ContestTileBase';

export const ContestTileVoting: typeof ContestTileBase = (props) => {
  const contest = props.contest;
  const shareUrl = 'https://www.visit-x.net/de/wettbewerbe/' + contest.id;
  const [copied, setCopied] = React.useState(false);
  const copy = () => {
    navigator.clipboard.writeText(shareUrl);
    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  return (
    <ContestTileBase
      color={'white'}
      gap={0}
      {...props}
      contestBackground={contest.images.payturSignupImage}
      contestBackgroundGradient={true}
    >
      <Box p={'10px'} textAlign={'left'} width={'inherit'}>
        <ContestIcon contest={contest} />
      </Box>
      <Text
        textAlign={'center'}
        textTransform={'uppercase'}
        fontWeight={500}
        fontSize={'16px'}
        mt={6}
        mb={4}
      >
        {t('contest:heading.UserVotingLauftNoch')}
      </Text>
      <Countdown time={DateTime.fromISO(contest.votingEnd)} fontSize={'16px'} />
      <Text mt={4} fontSize={'16px'}>
        {t('contest:text.AnimiereUserFurDichZuVoten')}
      </Text>
      <Box mt={6} px={4} width={'inherit'}>
        <Input
          variant={'outline'}
          style={{
            cursor: 'pointer',
            background: 'transparent',
            color: 'white',
          }}
          textOverflow={'ellipsis'}
          readOnly
          value={shareUrl}
          pr={'40px'}
          onClick={(e) => e.currentTarget.select()}
          zIndex={10}
        />
        <Icon
          as={icons.ContentCopy}
          style={{
            color: 'white',
            opacity: copied ? 0 : 1,
            transition: 'opacity 0.2s',
          }}
          boxSize="icon.md"
          cursor={'pointer'}
          onClick={copy}
          position={'absolute'}
          right={'25px'}
          mt={'8px'}
          zIndex={11}
        />
        <Icon
          as={icons.Check}
          style={{
            color: 'lime',
            opacity: copied ? 1 : 0,
            transition: 'opacity 0.2s',
          }}
          boxSize="icon.md"
          cursor={'pointer'}
          onClick={copy}
          position={'absolute'}
          right={'25px'}
          mt={'8px'}
          zIndex={11}
        />
      </Box>
      <Button
        variant={'ghost'}
        color={'white'}
        mt={2}
        _hover={{
          background: 'transparent',
        }}
        onClick={props.goToMedia}
      >
        {t('contest:button.ZumVideo', {
          type:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Post')
              : t('contest:label.Video'),
        })}
        <Icon as={icons.ArrowOutward} ml={2} mt={'-4px'} size={'md'} />
      </Button>
    </ContestTileBase>
  );
};
