import * as icon from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  Divider,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { AnimatedVXLogo } from '../../../../components/shared/AnimatedVXLogo/AnimatedVXLogo';
import { CurrencyAmount } from '../../../../components/shared/CurrencyAmount/CurrencyAmount';
import { getFormattedStatisticsAmount } from '../../../../utils/formattedAmount';
import { useBarChartBarContext } from '../../provider/BarChart/BarChartBarProvider';
import { useBarChartBarTooltipContext } from '../../provider/BarChart/BarChartBarTooltipProvider';
import { useBarChartContext } from '../../provider/BarChart/BarChartContext';
import { useStatisticsPageStatistics } from '../../provider/StatisticsPageProvider';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../../provider/TabContext';
import './StackedBarChart.css';

export type BarChartDataPoint = {
  category: string;
  firstAmount: number;
  secondAmount?: number;
  barsColor: string;
  stackedBarsColor?: string;
};

interface StackedBarChartProps {}

interface BarTooltipProps {
  tooltipHeader: string;
  tooltipExtraHeader?: React.ReactNode;
  tooltipDataDate: string;
  tooltipData: any;
  tooltipLoading: boolean;
  tooltipDataTotalTurnover: number;
  outerBoxProps?: BoxProps;
  innerBoxProps?: BoxProps;
}

export const BarTooltip: React.FC<BarTooltipProps> = ({
  tooltipHeader,
  tooltipExtraHeader,
  tooltipDataDate,
  tooltipData,
  tooltipLoading,
  tooltipDataTotalTurnover,
  outerBoxProps,
  innerBoxProps,
}) => {
  const { t } = useTranslation('statisticsPage');

  const mappedTooltipTranslation = (key: string) => {
    return t(`tooltip.${key}` as any);
  };

  const locale = useIntl().locale;
  const formattedIntlDate = (dateString: string, locale: string) => {
    const date = dateString ? new Date(dateString) : new Date();
    if (isNaN(date.getTime())) {
      return '-';
    }
    return new Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }).format(date);
  };

  return (
    <Box
      id="barTooltip"
      position={'absolute'}
      visibility={'hidden'}
      bg={'surface'}
      borderColor={'steel'}
      borderWidth={'1px'}
      borderRadius={'6px'}
      alignContent={tooltipLoading ? 'center' : 'unset'}
      minW={'200px'}
      fontSize={'14px'}
      lineHeight={'16px'}
      p={2}
      {...outerBoxProps}
    >
      {!tooltipLoading ? (
        <>
          {tooltipExtraHeader}
          <VStack align={'left'} gap={3} {...innerBoxProps}>
            <Text py={2}>{`${formattedIntlDate(
              tooltipDataDate,
              locale
            )}`}</Text>
            <HStack
              justifyContent="space-between"
              w="100%"
              fontWeight={'semibold'}
              pb={2}
            >
              <Text>{tooltipHeader}</Text>
              <Text>
                {getFormattedStatisticsAmount(tooltipDataTotalTurnover, locale)}
              </Text>
            </HStack>
            <Divider />
            {tooltipData?.map((value: any, index: number) => {
              const amount = (value?.sharing || value?.amount) ?? 0;
              return (
                <HStack key={index} justifyContent="space-between" w="100%">
                  <Text>
                    {mappedTooltipTranslation(value.type?.toUpperCase())}
                  </Text>
                  <Text fontWeight={'500'}>
                    <CurrencyAmount amount={amount} />
                  </Text>
                </HStack>
              );
            })}
          </VStack>
        </>
      ) : (
        <Box
          w={'96px'}
          h={'96px'}
          alignContent={'center'}
          justifySelf={'center'}
          ml={'auto'}
          mr={'auto'}
        >
          <AnimatedVXLogo />
        </Box>
      )}
    </Box>
  );
};

const StackedBarChart: React.FC<StackedBarChartProps> = () => {
  const { t } = useTranslation('statisticsPage');
  const svgRef = useRef<SVGSVGElement | null>(null);
  const keys = ['firstAmount', 'secondAmount'];

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { tabIds, tabIndex } = useTabsContext();
  const { loading } = useStatisticsPageStatistics();
  const { data } = useBarChartContext();
  const {
    pickedTooltipDate,
    handleBarClick,
    salesListDataLoading: tooltipDataLoading,
  } = useBarChartBarContext();

  const { barChartBarTooltipData, tooltipTotalTurnover } =
    useBarChartBarTooltipContext();

  const tabId = tabIds[tabIndex];
  const isAffiliate = tabId === StatisticsDetailedCategoryExtEnum.Affiliate;

  const locale = useIntl().locale;

  const handleResize = () => {
    if (svgRef.current) {
      const { width, height } = svgRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  };

  useEffect(() => {
    handleResize(); // Initial call to set dimensions
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize(); // Call handleResize whenever data changes in order to correctly display the bar chart upon tab change
  }, [data]);

  useEffect(() => {
    if (!data || data.length === 0) return;
    if (!svgRef.current) return;

    const { width, height } = dimensions;
    if (width === 0 || height === 0) return;

    // Set up margins and dimensions
    const margin = { top: 20, right: 30, bottom: 40, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Define grid dimensions
    const gridColumns = 6;
    const gridRows = 4;
    const gridSquareWidth = innerWidth / (gridColumns + 1); // +1 for half squares on both sides
    const gridSquareHeight = innerHeight / gridRows;

    // Select SVG
    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${width} ${height}`);

    // Clear previous content
    svg.selectAll('*').remove();

    // Append group element
    const content = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Append grid lines
    const gridGroup = content.append('g').attr('class', 'grid');

    // Vertical grid lines
    for (let i = 0; i <= gridColumns + 1; i++) {
      const x = i * gridSquareWidth;
      if (i === 0 || i === gridColumns + 1) {
        // Skip the leftmost and rightmost sides
        continue;
      }
      gridGroup
        .append('line')
        .attr('x1', x)
        .attr('y1', 0)
        .attr('x2', x)
        .attr('y2', innerHeight)
        .attr('stroke', '#e0e0e0')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '4 2'); // Make the lines dotted
    }

    // Horizontal grid lines
    for (let i = 0; i <= gridRows; i++) {
      const y = i * gridSquareHeight;
      gridGroup
        .append('line')
        .attr('x1', 0)
        .attr('y1', y)
        .attr('x2', innerWidth)
        .attr('y2', y)
        .attr('stroke', '#e0e0e0')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', '4 2'); // Make the lines dotted
    }

    // Stack the data
    const stackGenerator = d3.stack<BarChartDataPoint>().keys(keys);
    const stackedData = stackGenerator(data);

    // Format dates according to design
    const parseDate = d3.timeParse('%Y-%m-%d');
    const categories = data.map((d: BarChartDataPoint) => d?.category);

    function formatDateWithLineBreak(date: any, locale: string) {
      const tempDate = parseDate(date);
      const formattedDate = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }).format(tempDate as Date);

      const [day, month, year] = formattedDate.split(' ');
      return `${day} ${month} \n${year}`; // Line break before the year
    }

    // Function to create tspan elements for line breaks
    function wrapTextWithLineBreaks(
      selection: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>,
      locale: string
    ) {
      selection.each(function (d) {
        const text = d3.select(this);
        const lines = formatDateWithLineBreak(d as Date, locale).split('\n');
        text.text(null);
        lines.forEach((line, i) => {
          text
            .append('tspan')
            .attr('x', 0)
            .attr('dy', i === 0 ? 0 : '1.2em')
            .text(line);
        });
      });
    }
    // Create xScale using scaleBand
    const xScale: d3.ScaleBand<string> = d3
      .scaleBand()
      .domain(categories)
      .range([0, innerWidth])
      .paddingInner(0.1); // Adjust padding as needed

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(stackedData, (layer) => d3.max(layer, (d) => d[1])) || 0,
      ])
      .range([innerHeight, 0]);

    const secondaryColor =
      tabId === StatisticsDetailedCategoryExtEnum.Affiliate
        ? data[0].barsColor
        : data[0].stackedBarsColor;

    const colorScale = d3
      .scaleOrdinal<string, string>()
      .domain(keys)
      .range([data[0].barsColor, secondaryColor ?? '#FFFFFF']); // Adjust colors as needed

    // Create clip path
    content
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', innerWidth)
      .attr('height', innerHeight);

    // Append chart area group with clipping
    const chartArea = content
      .append('g')
      .attr('class', 'chart-area')
      .attr('clip-path', 'url(#clip)');

    // Draw layers with custom colors
    const layer = chartArea
      .selectAll<SVGGElement, d3.Series<BarChartDataPoint, string>>('.layer')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', (d) => `${d.key.replace(/\s+/g, '-')}`) // Replace spaces with hyphens
      .attr('fill', (d) => colorScale(d.key));

    // Draw bars
    layer
      .selectAll<SVGRectElement, d3.SeriesPoint<BarChartDataPoint>>('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.data.category)!)
      .attr('width', xScale.bandwidth())
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .attr('class', 'bar')
      .attr('transition', '0.25s')
      .on('mouseover', function (d, i) {
        d3.select(this).attr('opacity', 0.7);
      })
      .on('mouseout', function (d, i) {
        d3.select(this).attr('opacity', 1);
      })
      .on('click', function (event, d) {
        const parentElement = d3.select(this.parentElement);
        const key = parentElement.attr('class');
        handleClick.call(this, event, d, key);
      });

    // Create axes
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d) => formatDateWithLineBreak(d, locale))
      .tickValues(calculateXTickValues(xScale));

    const xAxisGroup = content
      .append('g')
      .attr('class', 'x-axis axis')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(xAxis);

    xAxisGroup
      .selectAll('.tick text')
      .call((d) => wrapTextWithLineBreaks(d, locale));

    // Add padding to the X axis labels
    xAxisGroup.selectAll('.tick text').attr('y', 25);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(4)
      .tickFormat((d) => getFormattedStatisticsAmount(d as number, locale)); // Adjust the number of ticks here

    const yAxisGroup = content
      .append('g')
      .attr('class', 'y-axis axis')
      .call(yAxis);

    // Zoom behavior
    zoom(svg);

    // Handle clicking on bar to get a tooltip
    function handleClick(
      this: SVGRectElement,
      event: MouseEvent,
      d: d3.SeriesPoint<BarChartDataPoint>,
      key: string
    ) {
      event.stopPropagation(); // Prevent the SVG click event from firing

      // Get mouse position relative to the SVG container
      const [mouseX, mouseY] = d3.pointer(event, svg.node() as any);

      // Calculate the position of the tooltip
      let xPosition = mouseX + 15; // Offset 10 pixels to the right of the mouse
      const yPosition = mouseY - 15; // Offset 10 pixels above the mouse

      // Get the tooltip element
      const tooltip = d3.selectAll('*').selectChild('#barTooltip');

      // Cast the tooltip node to HTMLElement
      const tooltipNode = tooltip.node() as HTMLElement;

      // Calculate the tooltip's width
      const tooltipWidth = tooltipNode?.getBoundingClientRect().width || 0;

      // Calculate the scroll bar width
      const scrollBarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      // Calculate the maximum allowable xPosition
      const maxXPosition =
        window.innerWidth - tooltipWidth - scrollBarWidth - 15;

      // Check if the tooltip goes beyond the window's width
      if (xPosition > maxXPosition) {
        xPosition = maxXPosition; // Move the tooltip to the left just enough to fit within the screen
      }

      tooltip
        .style('left', `${xPosition}px`)
        .style('top', `${yPosition}px`)
        .style('transform', 'translate(0, -50%)') // Adjust the tooltip to appear to the right and vertically centered
        .style('visibility', 'visible');

      handleBarClick(d.data.category, key);
    }

    // Hide tooltip on clicking outside a bar
    svg.on('click', () => {
      const tooltip = d3.selectAll('*').selectChild('#barTooltip');
      tooltip.style('visibility', 'hidden');
    });

    // Define the calculateXTickValues function
    function calculateXTickValues(scale: d3.ScaleBand<string>): string[] {
      const categories = scale.domain();
      const totalVisibleWidth = Math.abs(scale.range()[1] - scale.range()[0]);

      const minLabelSpacing = 160; // Minimum space in pixels between labels
      const minLabelSpacingAdjusted = minLabelSpacing * 0.6; // 30% buffer for hysteresis
      const maxLabels = Math.floor(totalVisibleWidth / minLabelSpacingAdjusted);

      // Ensure the first and last categories are always included
      const firstCategory = categories[0];
      const lastCategory = categories[categories.length - 1];

      let tickValues: string[] = [firstCategory];

      if (maxLabels > 2 && categories.length > 2) {
        const step = Math.ceil((categories.length - 2) / (maxLabels - 2));
        if (step > 0 && step < categories.length) {
          for (let i = step; i < categories.length - 1; i += step) {
            tickValues.push(categories[i]);
          }
        }
      }

      tickValues.push(lastCategory);

      // Ensure no overlapping ticks by checking the spacing
      const filteredTickValues: string[] = [tickValues[0]];
      for (let i = 1; i < tickValues.length; i++) {
        const currentTick = tickValues[i];
        const previousTick = filteredTickValues[filteredTickValues.length - 1];
        if (scale(currentTick)! - scale(previousTick)! >= minLabelSpacing) {
          filteredTickValues.push(currentTick);
        }
      }

      // Ensure the last tick is always included and properly spaced
      if (!filteredTickValues.includes(lastCategory)) {
        const secondToLastTick =
          filteredTickValues[filteredTickValues.length - 1];
        if (scale(lastCategory)! - scale(secondToLastTick)! < minLabelSpacing) {
          filteredTickValues.pop();
        }
        filteredTickValues.push(lastCategory);
      }

      return filteredTickValues;
    }

    function zoom(svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) {
      const extent: [[number, number], [number, number]] = [
        [0, 0],
        [innerWidth, innerHeight],
      ];

      svg.call(
        d3
          .zoom<SVGSVGElement, unknown>()
          .scaleExtent([1, 8])
          .translateExtent(extent)
          .extent(extent)
          .on('zoom', zoomed)
      );

      function zoomed(event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
        const t = event.transform;

        const tooltip = d3.selectAll('*').selectChild('#barTooltip');

        if (tooltip.style('visibility') === 'visible') {
          tooltip.style('visibility', 'hidden');
        }

        const xScaleRange = xScale.range().map((d) => t.applyX(d)) as [
          number,
          number
        ];
        const newXScale: d3.ScaleBand<string> = xScale
          .copy()
          .range(xScaleRange);

        const newYScale = t.rescaleY(yScale);

        const tickValues = calculateXTickValues(newXScale);

        layer
          .selectAll<SVGRectElement, d3.SeriesPoint<BarChartDataPoint>>('rect')
          .attr('x', (d) => newXScale(d.data.category)!)
          .attr('width', newXScale.bandwidth())
          .attr('y', (d) => newYScale(d[1]))
          .attr('height', (d) => {
            const height = newYScale(d[0]) - newYScale(d[1]);
            return height > 0 ? height : 0;
          });

        xAxisGroup.call(
          d3
            .axisBottom(newXScale)
            .tickValues(tickValues)
            .tickFormat((d) => formatDateWithLineBreak(d, locale))
        );

        xAxisGroup
          .selectAll('.tick text')
          .call((d) => wrapTextWithLineBreaks(d, locale));
        xAxisGroup.selectAll('.tick text').attr('y', 25);

        yAxisGroup.call(
          d3
            .axisLeft(newYScale)
            .ticks(4)
            .tickFormat((d) =>
              getFormattedStatisticsAmount(d as number, locale)
            )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, keys, dimensions]);

  return (
    <Box
      position={'relative'}
      width="100%"
      height={{ base: '250px', md: '500px' }}
    >
      {loading ? (
        <AbsoluteFullCenterLoadingSpinner />
      ) : !data || data.length === 0 ? (
        <VStack
          w={'100%'}
          h={'100%'}
          p={'16px'}
          gap={'16px'}
          align={'center'}
          justify={'center'}
        >
          <Icon as={icon.Info} w={'20px'} h={'20px'} color={'steel'} />
          <Text
            fontWeight={'700'}
            fontSize={'24px'}
            lineHeight={'32px'}
            color={'steel'}
          >
            {t('text.KeineDatenVorhanden')}
          </Text>
        </VStack>
      ) : (
        <>
          <svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
          <BarTooltip
            tooltipHeader={
              !isAffiliate ? t('text.Verdienst') : t('text.Affiliate')
            }
            tooltipExtraHeader={
              isAffiliate && (
                <Box
                  bg={'#F6D173'}
                  justifySelf={'flex-start'}
                  borderTopRadius={'6px'}
                  w={'100%'}
                  h={'24px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignContent={'center'}
                >
                  <Text fontSize={'15px'} fontWeight={'800'} m={'auto'}>
                    {t('text.BisZu80Sharing')}
                  </Text>
                </Box>
              )
            }
            tooltipDataDate={pickedTooltipDate ?? '-'}
            tooltipData={barChartBarTooltipData}
            tooltipLoading={tooltipDataLoading}
            tooltipDataTotalTurnover={tooltipTotalTurnover}
            outerBoxProps={{
              borderColor: !isAffiliate ? 'steel' : '#F6D173',
              p: !isAffiliate ? 2 : 0,
            }}
            innerBoxProps={{
              p: isAffiliate ? 2 : 0,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default StackedBarChart;
