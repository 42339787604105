import {
  Box,
  BoxProps,
  Center,
  Circle,
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  SquareProps,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import { ScaleLinear, scaleLinear } from 'd3-scale';
import * as React from 'react';

type Value = { label: React.ReactNode; value: number };

type BarChartContext = {
  y: ScaleLinear<number, number>;
  color: {
    future: string;
    base: string;
    best: string;
    debt: string;
    current: string;
  };
  smallestValue: number;
  biggestValue: number;
  indexOfCurrent: number;
  hasNegativeValues: boolean;
  values: Value[];
};

export const [BarChartContextProvider, useBarChart, barChartContext] =
  createContext<BarChartContext>({
    strict: true,
    errorMessage:
      'useBarChart: `barChartContext` is undefined. Seems you forgot to wrap component within the Provider',
    name: 'barChartContext',
  });

type BarChartBarProps = {
  value: Value;
  relativePosToCurrent?: number;
};
export const BarChartBar: React.FC<BarChartBarProps> = ({
  value: { value },
  relativePosToCurrent,
}) => {
  const { y, color, biggestValue } = useBarChart();
  const top = y(Math.max(value, 0)) * 100;
  const bottom = y(Math.min(value, 0)) * 100;
  const diff = Math.max(bottom - top, 0);
  const height = diff <= 0 ? '0.075rem' : `${diff}%`;

  const positioning: Partial<BoxProps> = {
    top: value >= 0 ? undefined : `${top}%`,
    bottom: value < 0 ? undefined : `${100 - bottom}%`,
  };

  /**
   *
   * @example add these to have the bars sharp touching the 0 line
   * borderTopRadius: value >= 0 ? undefined : 'none',
   * borderBottomRadius: value < 0 ? undefined : 'none',
   */
  const cornerRounding: Partial<BoxProps> = {
    borderRadius: 'base',
  };

  let fillColor = color.base;
  if (relativePosToCurrent !== undefined && relativePosToCurrent > 0) {
    fillColor = color.future;
  }
  if (value > 0 && value >= biggestValue) {
    fillColor = color.best;
  }
  if (value < 0) {
    fillColor = color.debt;
  }

  return (
    <GridItem position={'relative'} minW={0} minH={0}>
      <Box
        bg={fillColor}
        {...cornerRounding}
        height={height}
        {...positioning}
        position="absolute"
        width="full"
      />
    </GridItem>
  );
};

type BarChartBarLabelProps = { relativePosToCurrent?: number } & GridItemProps;
export const BarChartBarLabel: React.FC<BarChartBarLabelProps> = ({
  relativePosToCurrent,
  ...rest
}) => {
  const { color } = useBarChart();
  let textColor = color.base;
  if (relativePosToCurrent !== undefined) {
    if (relativePosToCurrent > 0) {
      textColor = color.future;
    }
    if (relativePosToCurrent === 0) {
      textColor = color.current;
    }
  }
  return (
    <GridItem
      textStyle={'labelXs'}
      minW={0}
      minH={0}
      color={textColor}
      {...rest}
    />
  );
};

export const BarChartBarRow: React.FC = () => {
  const { values, indexOfCurrent } = useBarChart();
  return (
    <>
      {values.map((value, index) => {
        const relativePosToCurrent = index - indexOfCurrent;
        return (
          <BarChartBar
            key={relativePosToCurrent}
            value={value}
            relativePosToCurrent={relativePosToCurrent}
          />
        );
      })}
    </>
  );
};

export type BarChartLabelFilterFn = (_: {
  index: number;
  relativePosToCurrent: number;
  total: number;
}) => boolean;
const filterEverySecondLabelFor20BarsOrMore: BarChartLabelFilterFn = ({
  index,
  relativePosToCurrent,
  total,
}) => {
  if (total < 20) {
    return true;
  }
  if (Math.abs(relativePosToCurrent) < 2) {
    return relativePosToCurrent === 0;
  }
  return index % 2 === 0;
};

type BarChartLabelRowProps = {
  /**
   * @default filterEverySecondLabelFor20BarsOrMore
   * @example
   * const filterEverySecondLabelFor20BarsOrMore: BarChartLabelFilterFn = ({
   *   index,
   *   relativePosToCurrent,
   *   total,
   * }) => {
   *   if (total < 20) {
   *     return true;
   *   }
   *   if (Math.abs(relativePosToCurrent) < 2) {
   *     return relativePosToCurrent === 0;
   *   }
   *   return index % 2 === 0;
   * };
   */
  filterFn?: null | BarChartLabelFilterFn;
};
export const BarChartLabelRow: React.FC<BarChartLabelRowProps> = ({
  filterFn = filterEverySecondLabelFor20BarsOrMore,
}) => {
  const { values, indexOfCurrent } = useBarChart();
  return (
    <>
      {values.map(({ label }, index) => {
        const relativePosToCurrent = index - indexOfCurrent;
        const isLabelShown =
          filterFn?.({ index, relativePosToCurrent, total: values.length }) ??
          true;
        return (
          <BarChartBarLabel
            key={relativePosToCurrent}
            children={isLabelShown ? label : null}
            as={Center}
            relativePosToCurrent={relativePosToCurrent}
          />
        );
      })}
    </>
  );
};

export const BarChartCaption: React.FC<BoxProps> = (props) => {
  const { color } = useBarChart();
  return (
    <Box textStyle={'labelXs'} as={Center} color={color.current} {...props} />
  );
};

type BarChartLegendDotProps = {
  colorKey?: keyof BarChartContext['color'];
  colorOverride?: string;
} & SquareProps;
export const BarChartLegendDot: React.FC<BarChartLegendDotProps> = ({
  colorKey,
  colorOverride,
  ...rest
}) => {
  const { color } = useBarChart();
  const fillColor =
    colorOverride ?? color[colorKey as keyof BarChartContext['color']];
  return <Circle bg={fillColor} size={3} {...rest} />;
};

export const BarChartLegendLabel: React.FC<BoxProps> = (props) => (
  <Box textStyle={'caption'} color={'onSurface.lowEmphasis'} {...props} />
);

export const BarChartLegendEntry: React.FC<StackProps> = (props) => (
  <Stack alignItems="center" spacing={0} {...props} />
);

export const BarChartGrid: React.FC<GridProps> = (props) => {
  const { values } = useBarChart();
  const barCount = values.length;
  return (
    <Grid
      templateColumns={`repeat(${barCount}, 1fr)`}
      templateRows={'1fr'}
      rowGap={2}
      columnGap={barCount > 20 ? '0.5' : 2}
      width="full"
      height="full"
      minH={'16rem'}
      {...props}
    />
  );
};

export const BarChartLegend: React.FC<{
  baseLabel: React.ReactNode;
  bestLabel: React.ReactNode;
  debtLabel: React.ReactNode;
  futureLabel: React.ReactNode;
}> = ({
  baseLabel = 'Einnahmen',
  bestLabel = 'Meiste Einnahmen',
  debtLabel = 'Defizit',
  futureLabel = 'Zukunft',
}) => {
  const { hasNegativeValues } = useBarChart();
  return (
    <>
      <BarChartLegendEntry>
        <BarChartLegendDot colorKey="base" />
        <BarChartLegendLabel children={baseLabel} />
      </BarChartLegendEntry>
      <BarChartLegendEntry>
        <BarChartLegendDot colorKey="best" />
        <BarChartLegendLabel children={bestLabel} />
      </BarChartLegendEntry>
      {hasNegativeValues && (
        <BarChartLegendEntry>
          <BarChartLegendDot colorKey="debt" />
          <BarChartLegendLabel children={debtLabel} />
        </BarChartLegendEntry>
      )}
      <BarChartLegendEntry>
        <BarChartLegendDot colorKey="future" />
        <BarChartLegendLabel children={futureLabel} />
      </BarChartLegendEntry>
    </>
  );
};

export type BarChartProps = {
  indexOfCurrent: number;
  minValue?: number;
  maxValue?: number;
  values: Value[];
} & BoxProps;
export const BarChart: React.FC<BarChartProps> = (props) => {
  const { indexOfCurrent, minValue, maxValue, values, children, ...rest } =
    props;

  const smallestValue = values.reduce(
    (min, { value }) => Math.min(min, value),
    0
  );
  const biggestValue = values.reduce(
    (max, { value }) => Math.max(max, value),
    0
  );
  const revenueDomain = [
    Math.min(0, minValue ?? smallestValue),
    // don't allow max less than 1
    Math.max(1, maxValue ?? biggestValue),
  ];

  const hasNegativeValues = smallestValue < 0;

  const y = scaleLinear().domain(revenueDomain).range([1, 0]); // 1 is bottom, 0 is top (as html has its origin in top left corner with y pointing down)

  const color = {
    future: 'onSurface.layoutHelperLight',
    base: 'onSurface.lowEmphasis',
    best: 'secondary.highEmphasis',
    debt: 'error.highEmphasis',
    current: 'onSurface.highEmphasis',
  };

  return (
    <Box {...rest}>
      <BarChartContextProvider
        value={{
          y,
          values,
          smallestValue,
          biggestValue,
          color,
          hasNegativeValues,
          indexOfCurrent,
        }}
      >
        {children}
      </BarChartContextProvider>
    </Box>
  );
};
