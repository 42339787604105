import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';

import { useNumberShortFormatter } from '../../../../hooks/useNumberShortFormatter';

export const MetricCountButton: React.FC<
  { icon: EmotionIcon; count: number | string } & ButtonProps
> = ({ icon, count, ...props }) => {
  const numberFormatter = useNumberShortFormatter();

  return (
    <Button
      leftIcon={<Icon as={icon} boxSize={'6'} />}
      variant={'ghost'}
      p={'1'}
      height={'auto'}
      display={'flex'}
      fontSize={'sm'}
      fontWeight={'medium'}
      color={'coldGray.900'}
      _disabled={{ cursor: 'default' }}
      _hover={props.onClick && { color: 'primary.500', background: 'steel' }}
      isDisabled={!props.onClick}
      children={typeof count === 'string' ? count : numberFormatter(count)}
      {...props}
    />
  );
};
