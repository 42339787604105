import { DragHandle, Remove } from '@campoint/odi-ui-icons';
import { Container, Divider, HStack, Icon } from '@chakra-ui/react';
import { Reorder } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StatisticListItemIcon from '../StatisticListItemIcon/StatisticListItemIcon';

const StatisticRemovableListItem: React.FC<{
  value: any;
  displayName: string;
  firstItem: boolean;
  lastItem: boolean;
  onClick: () => void;
  disabled: boolean;
}> = ({ value, displayName, firstItem, lastItem, onClick, disabled }) => {
  const { t } = useTranslation('statistic');

  return (
    <Reorder.Item value={value}>
      <HStack
        justifyContent={'space-between'}
        h={'14'}
        w={'full'}
        p={'4'}
        bgColor={'lightBackground'}
        borderTopRadius={firstItem ? '10px' : 0}
        borderBottomRadius={lastItem ? '10px' : 0}
        shadow={'md'}
      >
        <Container
          maxWidth={'full'}
          p={0}
          color={disabled ? 'onSurface.lowEmphasis' : 'gray.800'}
        >
          <StatisticListItemIcon
            icon={<Icon as={Remove} boxSize={'5'} />}
            bgColor={disabled ? 'error.lowEmphasis' : 'error.highEmphasis'}
            pointerEvents={disabled ? 'none' : 'auto'}
            _hover={{ cursor: 'pointer' }}
            onClick={onClick}
            aria-label={t('button.StatistikEntfernen')}
            isDisabled={disabled}
          />
          {displayName}
        </Container>
        <Icon
          as={DragHandle}
          boxSize={'8'}
          _hover={{ cursor: 'grab' }}
          _active={{ cursor: 'grabbing' }}
        />
      </HStack>
      {!lastItem && <Divider borderColor={'onPrimary.lowEmphasis'} />}
    </Reorder.Item>
  );
};

export default StatisticRemovableListItem;
