import React from 'react';

import { ClearableInputControl } from '../../FormikFormElements';

export type FormikDateInputProps = {
  isReadOnly?: boolean;
  label: string;
  name: string;
  placeholder?: string;
  min?: string;
  max?: string;
};

export const FormikDateInput: React.FC<FormikDateInputProps> = ({
  isReadOnly = false,
  label,
  name,
  placeholder = '',
  min = '',
  max = '',
}) => {
  return (
    <ClearableInputControl
      name={name}
      label={label}
      isReadOnly={isReadOnly}
      inputProps={{
        sx: {
          '&::-webkit-date-and-time-value': {
            textAlign: 'left',
          },
        },
        type: 'date',
        min,
        max,
        role: 'input',
        placeholder: isReadOnly ? undefined : placeholder,
      }}
    />
  );
};
