import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../../../assets/images/wizard/model-release-form-finished.svg';
import { useUpdateWizardMrfFinishedMutation } from '../../../../../generated/graphql';
import { useAccountStatus } from '../../../../../provider/AccountStatusProvider/AccountStatusProvider';
import { WizardIllustratedInstruction } from '../../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../../components/WizardStepFooter/WizardStepFooter';
import { useWizardMRF } from '../../WizardMRFContext';

export const WizardMRFModelReleaseFormV1Finish: React.FC = () => {
  const { t } = useTranslation(['wizardMRF', 'general']);
  const wizardMRFCtx = useWizardMRF();
  const accountStatusCtx = useAccountStatus();

  useUpdateWizardMrfFinishedMutation();

  sessionStorage.setItem(
    'mrfWizardFinished',
    DateTime.now().toUnixInteger().toString()
  );

  React.useEffect(() => {
    // Trigger accountStatusRefresh,
    // so the set sessionStorage entry can affect MRFWizrad status
    accountStatusCtx.actions.refresh();
  }, [accountStatusCtx]);

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardIllustratedInstruction
          illustrationSrc={image}
          illustrationAlt={t('general:img.ZuBearbeitendesDokument')}
          header={t('wizardMRF:heading.VielenDankDeineAngabenWerdenUberpruft')}
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardMRF:button.AllesKlar')}
          onContinueButtonClick={() => wizardMRFCtx.wizardNextStepCallback()}
          omitSkipButton
        />
      }
    />
  );
};
