import { MilitaryTech, MyLocation } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  StackProps,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  ExperienceIntentionFilterConfig,
  ExperienceIntentionFilterableListContext,
} from '../../../provider/FilterablListProvider';
import { noop } from '../../../utils';
import {
  CheckboxGroupControl,
  CheckboxGroupControlContainer,
} from '../FormikFormElements';

const ListDivider = () => <Divider my={'2'} />;

export const FilterSheetTabPanelHeadline: React.FC<{
  icon: EmotionIcon;
  heading: React.ReactNode;
}> = ({ icon, heading }) => (
  <Heading as="h4" size="md" color="onSurface.highEmphasis" mt="8">
    <Icon as={icon} boxSize={'icon.md'} color={'black'} marginInlineEnd={'1'} />
    {heading}
  </Heading>
);

const CheckboxList: React.FC<StackProps> = (props) => (
  <VStack
    spacing={0}
    justifyContent={'space-around'}
    alignItems={'stretch'}
    mx={-4}
    mb={4}
    p={4}
    pt={8}
    minH={'192px'}
    maxH={'192px'}
    overflowY={'scroll'}
    {...props}
  />
);

export type FilterSheetProps = {
  onClose: () => void;
  children?: React.ReactNode;
};

export const FilterSheetTabPanel: React.FC<
  {
    name: string;
    optionList: {
      value?: React.ReactNode;
      id: string;
    }[];
    heading: React.ReactNode;
  } & TabPanelProps
> = ({ name, optionList, heading, ...rest }) => {
  return (
    <TabPanel {...rest}>
      {heading}
      <CheckboxGroupControlContainer name={name}>
        <CheckboxList>
          {optionList.map(({ value: label, id }, i) => (
            <Fragment key={i}>
              {i !== 0 && <ListDivider />}

              <CheckboxGroupControl pl={4} value={id} children={label ?? ''} />
            </Fragment>
          ))}
        </CheckboxList>
      </CheckboxGroupControlContainer>
    </TabPanel>
  );
};

export const FilterSheetTabPanelDesktop: React.FC<
  {
    name: string;
    optionList: {
      value?: React.ReactNode;
      id: string;
    }[];
    heading: React.ReactNode;
  } & TabPanelProps
> = ({ name, optionList, heading, ...rest }) => {
  return (
    <Flex flexDirection={'column'}>
      {heading}
      <Flex flexDirection={'row'} maxWidth={'300px'}>
        <CheckboxGroupControlContainer name={name}>
          {optionList.map(({ value: label, id }, i) => (
            <Fragment key={i}>
              <CheckboxGroupControl
                value={id}
                children={label ?? ''}
                mb={2}
                pl={3}
                w={'full'}
                justifyContent={'start'}
              />
            </Fragment>
          ))}
        </CheckboxGroupControlContainer>
      </Flex>
    </Flex>
  );
};

type FormName = keyof ExperienceIntentionFilterConfig;
export const experienceCategoryName: FormName = 'experience';
export const intentionCategoryName: FormName = 'intention';

const FilterSheet: React.FC<FilterSheetProps> = ({
  onClose = noop,
  children,
}) => {
  const ref = React.useRef(null);

  const swipeDownHandler = useSwipeable({
    onSwipedDown: () => onClose(),
  });

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      initialFocusRef={ref}
      placement={'bottom'}
    >
      <DrawerOverlay />
      <DrawerContent borderTopRadius={'2xl'}>
        <DrawerHeader {...swipeDownHandler}>
          <Center>
            <Box
              bg={'onSurface.lowEmphasis'}
              cursor={'pointer'}
              w={'40px'}
              h={'5px'}
              borderRadius={'full'}
              onClick={onClose}
            />
          </Center>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export type ExperienceIntentionFilterSheetProps = {
  context: ExperienceIntentionFilterableListContext<any>;
  renderCountDisplay: (count: number) => React.ReactNode;
  renderCountDisplayReset: string;
  experienceTabTitle: React.ReactNode;
  experienceHeading: React.ReactNode;
  intentionTabTitle: React.ReactNode;
  intentionHeading: React.ReactNode;
};
export const ExperienceIntentionFilterSheet: React.FC<
  ExperienceIntentionFilterSheetProps
> = ({
  context,
  renderCountDisplay,
  renderCountDisplayReset,
  experienceTabTitle,
  experienceHeading,
  intentionTabTitle,
  intentionHeading,
}) => {
  const {
    filter,
    predictEntryCountForFilter,
    setFilter,
    toggleFilterSheet,
    resetFilter,
    hasActiveFilter,
    filterCategory,
  } = context;
  const { experienceTags, intentionTags } = filterCategory;
  const isDesktopViewport = useBreakpointValue({ base: false, md: true });

  const resetFormToInitAndClose = () => {
    resetFilter();
    toggleFilterSheet();
  };

  const formik = useFormik({
    initialValues: filter,
    onSubmit: (values) => {
      setFilter(values);
      toggleFilterSheet();
    },
  });
  const predictedEntryCount = useMemo(
    () => predictEntryCountForFilter(formik.values),
    [formik.values, predictEntryCountForFilter]
  );

  return (
    <>
      {isDesktopViewport ? (
        <Flex
          flexDirection={'row'}
          flexWrap={'wrap'}
          width={'full'}
          mb={1}
          mt={1}
        >
          <Divider />
          <FormikProvider value={formik}>
            <FilterSheetTabPanelDesktop
              name={experienceCategoryName}
              heading={
                <FilterSheetTabPanelHeadline
                  icon={MilitaryTech}
                  heading={experienceHeading}
                />
              }
              optionList={experienceTags}
            />
            <FilterSheetTabPanelDesktop
              name={intentionCategoryName}
              heading={
                <FilterSheetTabPanelHeadline
                  icon={MyLocation}
                  heading={intentionHeading}
                />
              }
              optionList={intentionTags}
            />
            <Flex flexDirection={'row'} w={'full'}>
              <Button
                children={renderCountDisplay(predictedEntryCount)}
                type="submit"
                disabled={!formik.dirty}
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
              <Button
                isDisabled={!hasActiveFilter}
                variant="outline"
                ml={2}
                onClick={() => resetFormToInitAndClose()}
              >
                {renderCountDisplayReset}
              </Button>
            </Flex>
          </FormikProvider>
        </Flex>
      ) : (
        <FilterSheet onClose={toggleFilterSheet}>
          <FormikProvider value={formik}>
            <Tabs isFitted>
              <TabList>
                <Tab children={experienceTabTitle} />
                <Tab children={intentionTabTitle} />
              </TabList>
              <TabPanels>
                <FilterSheetTabPanel
                  p={0}
                  name={experienceCategoryName}
                  heading={
                    <FilterSheetTabPanelHeadline
                      icon={MilitaryTech}
                      heading={experienceHeading}
                    />
                  }
                  optionList={experienceTags}
                />
                <FilterSheetTabPanel
                  p={0}
                  name={intentionCategoryName}
                  heading={
                    <FilterSheetTabPanelHeadline
                      icon={MyLocation}
                      heading={intentionHeading}
                    />
                  }
                  optionList={intentionTags}
                />
              </TabPanels>
            </Tabs>
            <Button
              children={renderCountDisplay(predictedEntryCount)}
              width={'full'}
              mb={2}
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
            />
            <Button
              isDisabled={!hasActiveFilter}
              variant="outline"
              width={'full'}
              onClick={() => resetFormToInitAndClose()}
            >
              {renderCountDisplayReset}
            </Button>
          </FormikProvider>
        </FilterSheet>
      )}
    </>
  );
};
