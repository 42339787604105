import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MissingRequirementsImage from '../../../../assets/images/shared/missing-requirements-blocker.svg';
import { routes } from '../../../../routes/routesConfig';
import { UrlFragment } from '../../../Layout/UrlFragmentScrollToTarget';
import { BlockerCard } from '../../BlockerCard/BlockerCard';

export const CompleteOpenTasksCard: React.FC<{
  remainingStepsCount: number;
}> = ({ remainingStepsCount }) => {
  const history = useHistory();
  const { t } = useTranslation(['vxLive']);

  const navigateToTaskSectionOnHome = () => {
    history.push({
      pathname: routes.home.path,
      hash: UrlFragment.Tasks,
    });
  };

  return (
    <BlockerCard
      isBordered
      image={MissingRequirementsImage}
      heading={t('vxLive:missingRequirements.headline')}
      text={t('vxLive:missingRequirements.bodyText', {
        count: remainingStepsCount,
      })}
      primaryButtonText={t('vxLive:missingRequirements.buttonText')}
      onClickPrimary={navigateToTaskSectionOnHome}
    />
  );
};
