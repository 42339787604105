import { Select, SelectProps, forwardRef } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { BaseProps, FormControl } from '../FormControl';
import {
  PromotingOptionList,
  PromotingOptionListProps,
} from './PromotingSelectOptionList';

export type PromotingSelectControlProps = BaseProps & {
  selectProps?: SelectProps;
  optionListProps?: PromotingOptionListProps;
};

export const PromotingSelectControl = forwardRef<
  PromotingSelectControlProps,
  'select'
>((props, ref) => {
  const { name, label, selectProps, optionListProps, children, ...rest } =
    props;

  const [field] = useField(name);
  return (
    <FormControl name={name} label={label} {...rest}>
      {children ?? (
        <Select {...field} ref={ref} {...selectProps}>
          {optionListProps && <PromotingOptionList {...optionListProps} />}
        </Select>
      )}
    </FormControl>
  );
});
