import * as icon from '@campoint/odi-ui-icons';
import { Heading, Icon, IconProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import {
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
} from '../../../../../components/Layout/ResponsiveModal';
import { MediaFilePicked } from '../../../../../components/shared/FeedPostCreate/FeedPostCreate';
import { MediaUploadCropperPortal } from '../../../../../components/shared/MediaUpload/MediaUpload';
import { ReplaceImageHandlerProps } from '../../../../../provider/FeedService/FeedService';
import { MediaInputProvider } from '../../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import {
  MediaContext,
  MediaProvider,
} from '../../../../../provider/MediaProvider';
import { HelpcenterLink } from './HelpcenterLink/HelpcenterLink';

const ErrorIcon: React.FC<IconProps> = (props) => (
  <Icon
    as={icon.Error}
    boxSize={'icon.md'}
    color={'error.500'}
    style={{ transform: 'translate(0, 0.2em)' }}
    {...props}
  />
);

export const ErrorModalContent: React.FC<{
  buttonLabel?: string;
  footerContent: React.ReactNode;
  onClick?: () => void;
  text: React.ReactNode;
  heading: React.ReactNode;
}> = ({ buttonLabel, onClick, footerContent, heading, text }) => {
  return (
    <ResponsiveModalContent justifyContent={'center'} p={'24px 16px'}>
      <ResponsiveModalCloseButton />
      <VStack
        alignItems={'center'}
        textAlign={'center'}
        spacing={'12px'}
        p={'16px 0 32px 0'}
      >
        <VStack alignItems={'center'}>
          <ErrorIcon boxSize={'icon.lg'} />
          <Heading size={'2xl'}>{heading}</Heading>
        </VStack>
        <Text>{text}</Text>
        <HelpcenterLink />
      </VStack>
      <VStack width={'fit-content'} m={'0 auto'} gap={'16px'}>
        {footerContent}
      </VStack>
    </ResponsiveModalContent>
  );
};

export const ErrorModalContentReplaceImageButtonContainer: React.FC<{
  children: React.ReactNode;
  onReplaceImage: ((props: ReplaceImageHandlerProps) => void) | undefined;
  postId?: string;
  visiblePhotoId?: string;
  onClose: () => void;
}> = ({ onReplaceImage, postId, visiblePhotoId, onClose, children }) => {
  const [selectedMedia, setSelectedMedia] =
    React.useState<MediaContext['media']>();

  React.useEffect(() => {
    const handleOnReplace = (media: MediaContext['media']) => {
      if (!postId || !visiblePhotoId || !media.blob) return;
      onReplaceImage?.({
        postId,
        photoId: visiblePhotoId,
        mediaFile: media.blob,
      });
    };

    if (selectedMedia) {
      handleOnReplace(selectedMedia);
      onClose?.();
    }
    // causes rerender loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedia]);

  return (
    <MediaPropertiesProvider
      targetDimensions={{ width: 56, height: 70 }} // 4:5 aspect ratio
      enforceMinimalDimension={true}
    >
      <MediaProvider>
        <MediaFilePicked
          onMedia={(m) => {
            setSelectedMedia(() => m);
          }}
        >
          <MediaInputProvider
            issueWrongDimensionToast={false}
            accept={'DEFAULT_FOR_PICTURE'}
            capture={'user'}
          >
            {children}
            <MediaUploadCropperPortal />
          </MediaInputProvider>
        </MediaFilePicked>
      </MediaProvider>
    </MediaPropertiesProvider>
  );
};
