import { Box, forwardRef } from '@chakra-ui/react';
import React from 'react';

export type MentionProps = {};
export const Mention = forwardRef<MentionProps, 'mark'>(function Mark(
  props,
  ref
) {
  return (
    <Box
      ref={ref}
      color={'caribbeanGreen.500'}
      as="mark"
      {...props}
      __css={{ bg: 'transparent', whiteSpace: 'nowrap' }}
    />
  );
});

type FeedCommentHighlightMentionsProps = {
  children?: string;
};

/**
 * `Highlight` allows you to highlight substrings of a text.
 *
 * @see Docs https://chakra-ui.com/docs/components/highlight
 */
export function CommentHighlightMentions(
  props: FeedCommentHighlightMentionsProps
): JSX.Element {
  const { children } = props;

  if (typeof children !== 'string') {
    throw new Error('The children prop of Highlight must be a string');
  }

  const text = children;

  const chunks = React.useMemo(() => {
    // https://regex101.com/r/IVBuug/1
    // Safari does not support look-around in regex,
    // so we need to use a simple, less robust regex
    const regex = /(@[A-Za-z]+[A-Za-z0-9_\-_]+)/g;
    const result = text.split(regex).filter(Boolean);
    return result.map((str) => ({ text: str, match: regex.test(str) }));
  }, [text]);

  return (
    <>
      {chunks.map((chunk, index) => {
        return chunk.match ? (
          <Mention key={index}>{chunk.text}</Mention>
        ) : (
          <React.Fragment key={index}>{chunk.text}</React.Fragment>
        );
      })}
    </>
  );
}
