import { HStack, Heading, Spacer } from '@chakra-ui/react';
import * as React from 'react';

import { GuideDot } from '../GuideDot/GuideDot';

export const HeadingWithOptionalToggleAndGuideDot: React.FC<{
  heading: React.ReactNode;
  isRequired: boolean;
  isRequiredButMissing: boolean;
}> = ({ heading, isRequired, isRequiredButMissing }) => (
  <HStack alignItems={'start'}>
    <Heading size={'sm'}>
      {heading}
      {!isRequired ? ' (optional)' : ''}
    </Heading>
    <Spacer />
    <GuideDot bg={'error.500'} isShown={isRequiredButMissing} />
  </HStack>
);
