import {
  Section,
  SectionBody,
  SectionDescription,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { SectionCenterContainer } from '../../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../../components/Layout/SectionDivider';
import {
  MinChoicesErrorMessage,
  MinOneFieldFilledErrorMessage,
} from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import {
  ClearableInputControl,
  FormControlHeaderStack,
  InputControl,
} from '../../../../../components/shared/FormikFormElements';
import { InlineGuideDot } from '../../../../../components/shared/GuideDot/GuideDot';
import { TipBanner } from '../../../../../components/shared/cards/TipBanner/TipBanner';
import {
  ProfileFieldTypeDateFragment,
  ProfileFieldTypeStringFragment,
} from '../../../../../generated/graphql';
import { multiTextareaDataForBackendV2 } from '../../../../../hooks/useMultiTextareaData';
import { calculateAge } from '../../../../../test/date-utils';
import { useDateFromTodayByYears } from '../../../../../utils/utils';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { LabelTextWithGuideDot } from '../../../components/LabelTextWithGuideDot/LabelTextWithGuideDot';
import {
  EnumListField,
  EnumListFieldWithGuideDot,
} from '../../../components/field/EnumListField';
import { EnumRadioField } from '../../../components/field/EnumRadioField';
import { EnumSelectField } from '../../../components/field/EnumSelectField';
import { OldMultiLangFieldWithGuideDotV2 } from '../../../components/field/OldMultiLangFieldV2';
import { useModelProfile } from '../../../provider/ModelProfileProvider';
import {
  extractDirtyValues,
  extractInitialValuesFromFields,
  extractValidationSchema,
} from '../../../provider/utils';
import { useFieldInitialErrors, useFieldInitialTouched } from '../../utils';

export const OldModelProfileAboutMeSection: React.FC = () => {
  const [t] = useTranslation(['general', 'modelProfile', 'validation']);
  const unknownError = t('modelProfile:fields.unknownError.unknownError');

  const {
    aboutMeCollection,
    aboutMeCollectionError,
    isGuideDotVisible,
    isSaveDataLoading,
    actions: { saveProfileData, setIsDataDirty },
  } = useModelProfile();

  const birthdateMin = useDateFromTodayByYears(75);

  const fields = useMemo(
    () => aboutMeCollection?.fields ?? {},
    [aboutMeCollection]
  );

  const initialErrors = useFieldInitialErrors(
    aboutMeCollectionError,
    unknownError
  );
  const initialTouched = useFieldInitialTouched(aboutMeCollectionError);

  const {
    modelChatPractices,
    modelLanguage,
    modelGender,
    modelSexuality,
    modelRelationshipState,
    modelCountry,
    modelPostalCode,
    modelCity,
    modelUsername,
    modelBirthdate,
    modelMotto,
    modelAboutMe,
  } = fields;

  const initialValues = useMemo(
    () => extractInitialValuesFromFields(fields),
    [fields]
  );

  const validationSchema = useMemo(() => {
    /**
     * The implementation of the 'createEnumListValidationSchema' function currently results in a buggy validation.
     * Therefore, the modelLanguage field validation isn't created via the 'extractValidationSchema' function.
     */
    const { modelLanguage: _, ...rest } = fields;

    /*
     * Jira Task https://campoint.atlassian.net/browse/VXMB-858
     * TODO Backend has to define valide Validation length for modelMotto, modelAboutMe and username
     * it is just a work around to extract some field and set minLength etc.
     */
    const overallSchema = extractValidationSchema(rest, {
      modelMotto: {
        isOptional: true,
        minLength: 1,
      },
      modelAboutMe: {
        isOptional: true,
      },
      modelChatPractices: {
        isOptional: true,
      },
      modelPostalCode: {
        isOptional: true,
        isUnClearableOnceSet: true,
      },
      modelCity: {
        isOptional: true,
        isUnClearableOnceSet: true,
      },
      modelRelationshipState: {
        isOptional: true,
      },
      modelUsername: { isOptional: true, minLength: 4 },
    });

    const modelMottoDE = `${modelMotto?.name}-de`;
    const modelMottoEN = `${modelMotto?.name}-en`;

    const modelMottoSchema = Yup.object().shape(
      {
        [modelMottoDE]: createStringValidationSchema({
          isOptional: true,
          minLength: modelMotto?.minLength,
          maxLength: modelMotto?.maxLength,
        }).when(modelMottoEN, {
          is: (englishMotto: string) =>
            englishMotto === undefined || englishMotto.length === 0,
          then: Yup.string().required(() => <MinOneFieldFilledErrorMessage />),
        }),
        [modelMottoEN]: createStringValidationSchema({
          isOptional: true,
          minLength: modelMotto?.minLength,
          maxLength: modelMotto?.maxLength,
        }).when(modelMottoDE, {
          is: (germanMotto: string) =>
            germanMotto === undefined || germanMotto.length === 0,
          then: Yup.string().required(() => <MinOneFieldFilledErrorMessage />),
        }),
        modelLanguage: Yup.array().min(1, ({ min }) => (
          <MinChoicesErrorMessage choices={min} />
        )),
      },
      [[modelMottoDE, modelMottoEN]]
    );

    return overallSchema.concat(modelMottoSchema);
  }, [fields, modelMotto]);

  const onSubmitHandler = useCallback<
    FormikConfig<typeof initialValues>['onSubmit']
  >(
    async (values) => {
      if (isSaveDataLoading) {
        return;
      }

      const multiLangFields = multiTextareaDataForBackendV2({ values, fields });

      const modelAboutMeData = {
        modelBirthdate: values.modelBirthdate,
        modelLanguage: values.modelLanguage,
        modelChatPractices: values.modelChatPractices,
        modelSexuality: values.modelSexuality,
        modelGender: values.modelGender,
        modelRelationshipState: values.modelRelationshipState,
        modelCountry: values.modelCountry,
        modelCity: values.modelCity,
        modelPostalCode: values.modelPostalCode,
        ...multiLangFields,
      };

      const dirtyValues = extractDirtyValues(modelAboutMeData, initialValues);

      saveProfileData({
        aboutMeData: dirtyValues,
      }).then();
    },
    [initialValues, saveProfileData, isSaveDataLoading, fields]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    //@ts-ignore
    initialErrors,
    initialTouched,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
  });

  useEffect(() => {
    setIsDataDirty(formik.dirty);
    return () => {
      setIsDataDirty(false);
    };
  }, [formik.dirty, setIsDataDirty]);

  const hasSomeAddressField =
    !!modelCity || !!modelCountry || !!modelPostalCode;

  return (
    <Section aria-busy={isSaveDataLoading}>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={icons.AccountCircle} />
            <SectionTitle fontSize={'xl'}>
              {t('modelProfile:heading.UberMich')}
            </SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t(
              'modelProfile:text.DieseInformationenSiehtDeinKundeAufDenErstenBlickWennErDeinProfilBesXX'
            )}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody pb={0}>
        <SectionCenterContainer>
          <FormikProvider value={formik}>
            <VStack spacing={8} divider={<Divider />}>
              {modelUsername && (
                <VStack w={'full'} alignItems={'stretch'}>
                  <StringField
                    field={modelUsername}
                    label={t('modelProfile:label.MeinNameAlsModel')}
                    placeholder={t(
                      'modelProfile:placeholder.ModelnameEingeben'
                    )}
                    isReadOnly={true}
                  />
                  <TipBanner
                    text={t(
                      'modelProfile:text.WillstDuDeinenNamenAndernBitteWendeDichAnDenSupport'
                    )}
                  />
                </VStack>
              )}
              <EnumRadioField
                field={modelGender}
                label={t('modelProfile:label.IchBin')}
              />
              <EnumRadioField
                field={modelSexuality}
                label={t('modelProfile:label.MeineOrientierung')}
              />
              {modelBirthdate && (
                <VStack w={'full'} alignItems={'stretch'}>
                  <DateField
                    field={{ ...modelBirthdate, minDate: birthdateMin }}
                    label={t('modelProfile:label.MeinGeburtsdatumAlsModel')}
                    placeholder={t('modelProfile:placeholder.DatumAuswahlen')}
                    helperText={t(
                      'modelProfile:text.WahleAmBestenDeinEchtesGeburtsdatumFallsDuDasNichtMochtestWeicheNichXX'
                    )}
                  />
                  <Text>
                    {Trans({
                      t,
                      i18nKey: 'modelProfile:text.DeinModelalter',
                      values: {
                        age: formik.values.modelBirthdate
                          ? calculateAge(formik.values.modelBirthdate)
                          : '--',
                      },
                      components: {
                        strong: <Text as={'strong'} fontWeight={'medium'} />,
                      },
                    })}
                  </Text>
                </VStack>
              )}
              <OldMultiLangFieldWithGuideDotV2
                field={modelMotto}
                label={t(
                  'modelProfile:label.SoBegrusseIchBesucherAufMeinerVISITXSeite'
                )}
                placeholder={t('modelProfile:placeholder.BegrussungEingeben')}
                helperText={t(
                  'modelProfile:text.MitWelchemSatzKonntestDuPotentielleKundenAufDeinerSeiteWillkommenHeiXX'
                )}
              />
              <OldMultiLangFieldWithGuideDotV2
                field={modelAboutMe}
                label={t('modelProfile:label.EinPaarSatzeUberMich')}
                placeholder={t('modelProfile:placeholder.ErzahleEtwasUberDich')}
                helperText={t(
                  'modelProfile:text.UberlegeDirWieDuDichAlsModelPrasentierenMochtestBeschreibeDichSoDassXX'
                )}
              />
              <EnumRadioField
                field={modelRelationshipState}
                label={t('modelProfile:label.MeinBeziehungsstatus')}
                helperText={t(
                  'modelProfile:text.DuMusstNichtZwingendDieWahrheitSagenWichtigIstNurDassDichKundenNichtXX'
                )}
              />
              {hasSomeAddressField && (
                <VStack w={'full'} alignItems={'stretch'}>
                  <FormControl>
                    <FormControlHeaderStack>
                      <FormLabel
                        children={
                          <>
                            {t('modelProfile:label.MeinArbeitsplatz')}
                            <InlineGuideDot
                              ml={1}
                              isShown={
                                isGuideDotVisible &&
                                (!formik.values.modelCountry ||
                                  !formik.values.modelPostalCode ||
                                  !formik.values.modelCity)
                              }
                            />
                          </>
                        }
                      />
                      <FormHelperText
                        children={t(
                          'modelProfile:text.WoWohnstDuAlsModelMancheKundenStellenSichGerneVorDichInEchtZuTreffen'
                        )}
                      />
                    </FormControlHeaderStack>
                  </FormControl>
                  <EnumSelectField
                    field={modelCountry}
                    label={t('modelProfile:label.Land')}
                    placeholder={t('modelProfile:placeholder.LandAuswahlen')}
                    optionLabels={{
                      groupLabel: t('general:optgroup.Lander'),
                      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
                    }}
                  />
                  <StringFieldClearable
                    field={modelPostalCode}
                    label={t('modelProfile:label.Postleitzahl')}
                    placeholder={t(
                      'modelProfile:placeholder.PostleitzahlEingeben'
                    )}
                  />
                  <StringFieldClearable
                    field={modelCity}
                    label={t('modelProfile:label.Stadt')}
                    placeholder={t('modelProfile:placeholder.StadtEingeben')}
                  />
                  <TipBanner
                    text={t(
                      'modelProfile:text.ZuDeinerEigenenSicherheitEmpfehlenWirDirNichtDeinenWahrenStandortPreXX'
                    )}
                  />
                </VStack>
              )}
              <EnumListFieldWithGuideDot
                field={modelLanguage}
                label={t('modelProfile:label.IchSprecheFolgendeSprachen')}
                placeholder={t('modelProfile:placeholder.SpracheAuswahlen')}
                helperText={t(
                  'modelProfile:text.SprachenDieDuHierAuswahlstSolltestDuImLivechatUndImGesprachMitDemKunXX'
                )}
              />
              <EnumListField
                field={modelChatPractices}
                label={t('modelProfile:label.ChatPraktiken')}
                placeholder={t(
                  'modelProfile:placeholder.WahleDeineChatPraktikenAus'
                )}
                helperText={t(
                  'modelProfile:text.WasKannManBeiDirImChatErlebenDenkDaranDassMancheKundenDichDanachAussXX'
                )}
              />
            </VStack>
          </FormikProvider>
        </SectionCenterContainer>
      </SectionBody>
      <SectionDivider isWidthRestricted />
      <SectionFooter>
        <SectionCenterContainer>
          <Button
            alignSelf={'center'}
            onClick={() => formik.submitForm()}
            children={t('modelProfile:button.SpeichernVeroffentlichen')}
            isDisabled={!(formik.isValid && formik.dirty)}
            isLoading={formik.isSubmitting || isSaveDataLoading}
          />
        </SectionCenterContainer>
      </SectionFooter>
    </Section>
  );
};

export const StringField: React.FC<{
  field: Maybe<ProfileFieldTypeStringFragment>;
  label: string;
  placeholder: string;
  isReadOnly?: boolean;
}> = ({ field, label, placeholder, ...rest }) => {
  return !field ? null : (
    <InputControl
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      inputProps={{
        autoComplete: 'off',
        type: 'string',
        placeholder,
      }}
      {...rest}
    />
  );
};

export const StringFieldClearable: React.FC<{
  field: Maybe<ProfileFieldTypeStringFragment>;
  label: string;
  placeholder: string;
}> = ({ field, label, placeholder, ...rest }) => {
  return !field ? null : (
    <ClearableInputControl
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      inputProps={{
        autoComplete: 'off',
        type: 'string',
        placeholder,
      }}
      {...rest}
    />
  );
};

export const DateField: React.FC<{
  field: Maybe<ProfileFieldTypeDateFragment>;
  label: string;
  placeholder: string;
  helperText?: string;
}> = ({ field, label, placeholder, helperText }) => {
  return !field ? null : (
    <InputControl
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      inputProps={{
        type: 'date',
        placeholder,
        min: field.minDate,
        max: field.maxDate,
      }}
      helperText={helperText}
    />
  );
};
