import { ChatbotMessage, removeSources } from '@campoint/vxmodels-chatbot';
import { Box, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Markdown from '../../../components/shared/Markdown/Markdown';
import { useAccountStatus } from '../../../provider/AccountStatusProvider/AccountStatusProvider';
import { useAuth } from '../../../provider/AuthProvider';
import { formatDETimeShort } from '../../../utils/customTranslationFormatting';
import VXIcon from './visit-x-icon.svg';

export const VXMChatbotMessageEntry: React.FC<{
  message: ChatbotMessage;
  modelPictureFSK16Avatar: string;
}> = ({ message, modelPictureFSK16Avatar }) => {
  const { t } = useTranslation(['chatbot']);
  const { authUser } = useAuth();
  const { hasSetUsername } = useAccountStatus();

  return (
    <Box
      style={{
        margin: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: message.isBot ? 'flex-start' : 'flex-end',
      }}
    >
      <Text
        style={{
          display: 'flex',
          marginBottom: '2px',
          color: 'gray.900',
          fontSize: '14px',
        }}
      >
        {
          <>
            <Image
              src={message.isBot ? VXIcon : modelPictureFSK16Avatar}
              boxSize={'20px'}
              borderRadius={'50%'}
              mr={1}
            />
            {message.isBot
              ? t('chatbot:label.kiModelCoachDisplayName')
              : hasSetUsername
              ? authUser?.username
              : 'Model'}
          </>
        }
      </Text>
      <Box width={'100%'}>
        <Markdown
          markdown={removeSources(message.text)}
          containerProps={{
            style: {
              display: 'block',
              maxWidth: '80%',
              width: 'fit-content',
              background: message.isBot ? '#e8f0f5' : '#f5f6fa',
              borderRadius: '20px',
              borderBottomLeftRadius: message.isBot ? '0px' : '20px',
              borderBottomRightRadius: message.isBot ? '20px' : '0px',
              padding: '16px 20px 16px 16px',
              margin: '2px 0',
              fontSize: '16px',
              float: message.isBot ? 'left' : 'right',
            },
          }}
        />
      </Box>
      <Text
        style={{
          fontSize: '12px',
        }}
        color={'gray.500'}
        whiteSpace={'nowrap'}
      >
        {formatDETimeShort(new Date(message.timestamp))}
      </Text>
    </Box>
  );
};
