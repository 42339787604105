import { ApolloClient } from '@apollo/client';
import React from 'react';

import {
  apolloClient,
  feedClient,
  notificationsClient,
} from '../graphql/ApolloClient';
import { Client } from '../graphql/ApolloClient.types';

export const apolloMultiClientContext = React.createContext<{
  getClient(key: string | undefined): ApolloClient<any> | undefined;
}>({
  getClient() {
    return undefined;
  },
});

export function useApolloMultiClient() {
  return React.useContext(apolloMultiClientContext);
}

export const ApolloMultiClientProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const getClient = (key: string) => {
    return (
      {
        [Client.FEED]: feedClient,
        [Client.NOTIFICATIONS]: notificationsClient,
      }[key] ?? apolloClient
    );
  };
  return <apolloMultiClientContext.Provider value={{ getClient }} {...props} />;
};
