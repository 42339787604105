import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InputTourModelReleaseFormV1,
  TourDataWizardMrfFragment,
  useGetWizardMrfDataQuery,
  useUpdateWizardMrfDataMutation,
} from '../../../generated/graphql';
import { WizardParentModalLayout } from '../components/WizardParentModalLayout/WizardParentModalLayout';
import { useWizardNavigateToNextStepCallback } from '../utils/hooks';
import { extractCurrentFieldError, extractTourStepField } from '../utils/utils';
import {
  WizardMRFContext,
  WizardMRFContextProvider,
  paperlessMrfRedirectUrl,
  possibleStepIds,
} from './WizardMRFContext';
import { WizardMRFModelReleaseFormV1Finish } from './step/ModelReleaseFormV1Finish/WizardMRFModelReleaseFormV1Finish';
import { WizardMRFModelReleaseFormV1FullAddress } from './step/ModelReleaseFormV1FullAddress/WizardMRFModelReleaseFormV1FullAddress';
import { WizardMRFModelReleaseFormV1PersonalData } from './step/ModelReleaseFormV1PersonalData/WizardMRFModelReleaseFormV1PersonalData';
import { WizardMRFModelReleaseFormV1Signing } from './step/ModelReleaseFormV1Signing/WizardMRFModelReleaseFormV1Signing';
import { WizardMRFModelReleaseFormV1Start } from './step/ModelReleaseFormV1Start/WizardMRFModelReleaseFormV1Start';

export const wizardMRFSteps: WizardMRFContext.StepsConfig = {
  ModelReleaseFormV1Start: {
    element: <WizardMRFModelReleaseFormV1Start />,
  },
  ModelReleaseFormV1PersonalData: {
    element: <WizardMRFModelReleaseFormV1PersonalData />,
  },
  ModelReleaseFormV1FullAddress: {
    element: <WizardMRFModelReleaseFormV1FullAddress />,
  },
  ModelReleaseFormV1Signing: {
    element: <WizardMRFModelReleaseFormV1Signing />,
  },
  ModelReleaseFormV1Finish: {
    element: <WizardMRFModelReleaseFormV1Finish />,
  },
};

type State = {
  totalSteps: number;
  currentStep: WizardMRFContext.Step | null;
  currentField: Partial<WizardMRFContext.Field>;
  currentFieldError: Partial<WizardMRFContext.FieldError>;
  isVisible: boolean;
  onFinished?: () => void;
} & WizardMRFContext.Addition;

const defaultState = {
  totalSteps: possibleStepIds.length,
  currentStep: null,
  currentField: {},
  currentFieldError: {},
  accountPrefillField: {},
  isVisible: false,
  onFinished: undefined,
};

export const WizardMRF: React.FC<{
  children?: React.ReactNode;
  isVisibleDefault?: boolean;
}> = ({ children, isVisibleDefault = false }) => {
  const { t } = useTranslation(['wizardMRF', 'validation']);

  const [state, setState] = useState<State>({
    ...defaultState,
    isVisible: isVisibleDefault,
  });
  const { totalSteps, currentStep, isVisible, onFinished } = state;

  const setVisible = React.useCallback(
    (isVisible: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isVisible,
      }));
    },
    [setState]
  );

  const setOnFinished = React.useCallback(
    (onFinished?: () => void) => {
      setState((prevState) => ({
        ...prevState,
        onFinished,
      }));
    },
    [setState]
  );

  const wizardCloseCallback = useCallback(() => {
    window.location.hash = '';
    setState(defaultState);
  }, [setState]);
  const navigateToNextWizardStepCallback = useWizardNavigateToNextStepCallback(
    wizardMRFSteps,
    () => {
      wizardCloseCallback();
      onFinished?.();
    }
  );

  const setTourStepData = useCallback(
    (
      tourData?: TourDataWizardMrfFragment | null,
      addition?: Partial<WizardMRFContext.Addition>
    ) => {
      const step = tourData?.currentStep ?? null;

      setState((prevState) => ({
        ...prevState,
        totalSteps: tourData?.totalSteps ?? possibleStepIds.length,
        currentStep: step,
        currentField: extractTourStepField(step?.fields),
        currentFieldError: extractCurrentFieldError(
          tourData?.validate?.data,
          t('validation:error.UnbekannterFehler')
        ),
        ...addition,
      }));

      if (tourData) {
        navigateToNextWizardStepCallback(tourData.currentStep?.id ?? null);
      }
    },
    [navigateToNextWizardStepCallback, t]
  );

  const { loading } = useGetWizardMrfDataQuery({
    variables: {
      redirectUrl: paperlessMrfRedirectUrl,
    },
    onCompleted: (data) => {
      if (data?.tour?.__typename !== 'TourModelReleaseFormV1') {
        return;
      }

      //we decided to not prefill any fields because studio models
      //should not see data of the studio originator
      //Todo: fix related tests
      const accountPrefillField = null;

      setTourStepData(data?.tour, { accountPrefillField });
    },
    skip: !isVisible,
  });

  const [updateStepData] = useUpdateWizardMrfDataMutation({
    onCompleted: (data) => {
      if (data?.tour?.__typename !== 'TourModelReleaseFormV1Mutation') {
        return;
      }
      setTourStepData(data?.tour?.update);
    },
  });

  const wizardNextStepCallback = React.useCallback(
    async (fields?: InputTourModelReleaseFormV1) => {
      if (!currentStep?.id) {
        return;
      }
      await updateStepData({
        variables: {
          step: currentStep.id,
          data: fields ?? {},
          redirectUrl: paperlessMrfRedirectUrl,
        },
      });
    },
    [updateStepData, currentStep]
  );

  const context = React.useMemo(() => {
    return {
      ...state,
      wizardCloseCallback,
      wizardNextStepCallback,
      setVisible,
      setOnFinished,
    };
  }, [
    state,
    wizardCloseCallback,
    wizardNextStepCallback,
    setVisible,
    setOnFinished,
  ]);

  return (
    <WizardMRFContextProvider value={context}>
      {isVisible && (
        <WizardParentModalLayout
          headerText={t('wizardMRF:heading.ModelReleaseForm')}
          totalSteps={totalSteps}
          currentStep={currentStep?.pos ?? 1}
          loading={loading}
          onCloseClick={wizardCloseCallback}
        >
          {currentStep &&
            wizardMRFSteps[currentStep!.id as keyof typeof wizardMRFSteps]
              ?.element}
        </WizardParentModalLayout>
      )}
      {children}
    </WizardMRFContextProvider>
  );
};
