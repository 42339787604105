import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { PaymentsOutlined } from '@campoint/odi-ui-icons';
import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import React, { Fragment } from 'react';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { getFormattedAmount } from '../../../../utils/formattedAmount';
import { SharingInfosEnum, useGetPhoneTelegramSharingStatisticsQuery } from '../../../../generated/graphql';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { DateTime } from 'luxon';

type DetailedStatisticsPhoneIncomeProps = {};

export const DetailedStatisticsPhoneIncome: React.FC<
  DetailedStatisticsPhoneIncomeProps
> = () => {
  const { t } = useTranslation(['statisticsPage']);
  const locale = useIntl().locale;
  const IncomeText: React.FC<{ text: string }> = ({ text }) => {
    return <Text flex={1} fontWeight={'normal'} fontSize={'sm'} textAlign={'left'}>{text}</Text>
  }

  const { data, loading } = useGetPhoneTelegramSharingStatisticsQuery({ variables: { type: SharingInfosEnum.PhoneService } });

  const sharingInfo = React.useMemo(() => data?.sharing?.informations, [data]);

  const currentDate = DateTime.now().toFormat('MM / yyyy');

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow>
            <SectionIcon as={PaymentsOutlined} />
            <SectionTitle flex={1}>{t('header.Verdienst')}</SectionTitle>
            <Text alignSelf={'center'} color={'darkSteel'} fontWeight={'medium'} fontSize={'xs'}>{currentDate}</Text>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          {loading ? <AbsoluteFullCenterLoadingSpinner /> : (
            <VStack
              gap={4}
            >
              <Box p={4} minH={'310px'} w={'full'} border={'1px'} borderColor={'steel'} borderRadius={'8px'} >
                <HStack m={4} minH={'20px'} >
                  <Text flex={1} fontWeight={'medium'}>{t('text.Service')}</Text>
                  <Text flex={1} fontWeight={'medium'}>{t('text.Verdienst')}</Text>
                </HStack>
                <Divider size={'s'} color={'steel'} />
                {sharingInfo?.map((info, index) => {

                  const fallbackmapping = [
                    { label: t('text.Nummer0900'), text: t('text.XXMinProAnrufer', { price: getFormattedAmount(0.61, locale) }) },
                    { label: t('text.ImTV0900'), text: t('text.XXMinProAnrufer', { price: getFormattedAmount(0.40, locale) }) },
                    { label: t('text.BeiAnrufSex'), text: t('text.XXMinProAnrufer', { price: getFormattedAmount(0.46, locale) }) }
                  ]

                  return (
                    <Fragment key={index}>
                      <HStack m={4} minH={'20px'} key={index} >
                        <IncomeText text={info?.label ?? fallbackmapping[index].label} />
                        <IncomeText text={info?.text ?? fallbackmapping[index].text} />
                      </HStack>
                      <Divider size={'s'} color={'steel'} />
                    </Fragment>
                  )
                }
                )}
                <Text textAlign={'center'} fontWeight={'normal'} color={'darkSteel'} fontSize={'xs'} px={4} py={6}>
                  {t('text.GegebenenfallsZuzuglichUmsatzsteuer')}
                </Text>
              </Box>
            </VStack>
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};