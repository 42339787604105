import { ChevronRight } from '@campoint/odi-ui-icons';
import { Icon, Link, LinkProps, chakra } from '@chakra-ui/react';
import React from 'react';

export const HelpLink: React.FC<LinkProps> = ({ children, ...props }) => {
  return (
    <Link
      isExternal
      rel="noreferrer"
      textColor={'secondary.highEmphasis'}
      display={'inline-flex'}
      alignItems={'center'}
      {...props}
    >
      <chakra.span flexGrow={1}>{children}</chakra.span>
      <Icon as={ChevronRight} ps={'2.5'} boxSize={'icon.lg'} />
    </Link>
  );
};
