import { useDisclosure } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import FeedPostEditModal from '../components/shared/FeedPostEditModal/FeedPostEditModal';
import {
  FeedClipPostFragment,
  FeedPhotoPostFragment,
  FeedTextPostFragment,
  useEditFeedPostMutation,
} from '../generated/feed';
import { createContext } from '../hooks/useContext';
import { FeedConfig, useFeedConfig } from '../hooks/useFeedConfig';
import { TempFeedPostScheduleProp } from '../pages/FeedPage/components/post/component/types';
import Logger from '../utils/Logger';

type EditPostData = {
  pinned: boolean;
  title?: string;
  text?: string;
  publicationScheduledFor?: Maybe<string>;
};

export type InitPostData = (
  | FeedTextPostFragment
  | FeedClipPostFragment
  | FeedPhotoPostFragment
) &
  TempFeedPostScheduleProp;

type FeedPostEditContext = {
  readonly initPostDataValues: InitPostData | null;
  readonly isOpen: boolean;
  readonly isEditPostLoading: boolean;
  isSucessfullySubmitted: boolean;
  feedConfig: FeedConfig;
  readonly action: {
    readonly openModal: (data: InitPostData) => void;
    readonly editPost: (data: EditPostData) => Promise<void>;
    readonly closeModal: () => void;
    setIsSucessfullySubmitted: (arg: boolean) => void;
  };
};

export const [, useFeedPostEditModalContext, feedPostEditModalContext] =
  createContext<FeedPostEditContext>({
    name: 'FeedPostEditModalProvider',
    errorMessage:
      'useFeedPostEditModalContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const FeedPostEditModalProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['feed']);
  const [initPostDataValues, setInitPostDataValues] =
    useState<InitPostData | null>(null);

  const { data: feedConfig } = useFeedConfig();

  const [isSucessfullySubmitted, setSucessfullySubmitted] = useState(false);

  const [editFeedPost, { loading: isEditPostLoading }] =
    useEditFeedPostMutation();

  const onOpenModal = useCallback(
    (data: InitPostData) => {
      setInitPostDataValues(data);
      onOpen();
    },
    [onOpen]
  );

  const onCloseModal = useCallback(() => {
    setInitPostDataValues(null);
    onClose();
  }, [onClose]);

  const onEditPost = useCallback(
    async (editedData: EditPostData) => {
      try {
        if (!initPostDataValues) {
          //TODO Throw error if there is no initial post
          return;
        }

        await editFeedPost({
          variables: {
            postId: initPostDataValues.id,
            fsk: initPostDataValues.fsk,
            pinned: editedData.pinned,
            text: editedData.text,
            title: editedData.title,
            // todo: send publicationScheduledFor once, backend processes it
            // publicationScheduledFor:
            //  editedData?.publicationScheduledFor ?? null,
          },
        });
        setSucessfullySubmitted(true);
        issueChakraToast({
          description: t('feed:modal.successMessage'),
          status: 'success',
        });
        onClose();
      } catch (error) {
        Logger.error(error);
        issueChakraToast({
          description: t('feed:modal.successMessage'),
          status: 'error',
        });
      }
    },
    [initPostDataValues, t, editFeedPost, onClose]
  );

  const action = React.useMemo<FeedPostEditContext['action']>(
    () => ({
      openModal: onOpenModal,
      closeModal: onCloseModal,
      editPost: onEditPost,
      setIsSucessfullySubmitted: setSucessfullySubmitted,
    }),
    [onOpenModal, onCloseModal, onEditPost, setSucessfullySubmitted]
  );

  const context = React.useMemo<FeedPostEditContext>(
    () => ({
      initPostDataValues,
      isEditPostLoading,
      isSucessfullySubmitted,
      isOpen,
      action,
      feedConfig,
    }),
    [
      initPostDataValues,
      isEditPostLoading,
      isSucessfullySubmitted,
      isOpen,
      action,
      feedConfig,
    ]
  );

  return (
    <feedPostEditModalContext.Provider value={context}>
      {children}
      <FeedPostEditModal />
    </feedPostEditModalContext.Provider>
  );
};
