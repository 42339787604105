import { DateTime } from 'luxon';
import * as React from 'react';

import { useMinuteStamp } from '../provider/MinuteStampProvider';

export const useFeedPostSchedulableTimeframe = () => {
  const { minuteStamp } = useMinuteStamp();

  return React.useMemo(() => {
    const currentMinute = DateTime.fromISO(minuteStamp);
    return {
      timezone: currentMinute.zone,
      min: currentMinute
        .plus({ hour: 1 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      minEdit: currentMinute
        .plus({ hour: 1 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      max: currentMinute
        .plus({ months: 3 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      checkDateEditable: (isoTimeStamp: string) => {
        return (
          (DateTime.fromISO(isoTimeStamp).diff(currentMinute, 'hours')?.hours ??
            0) > 1
        );
      },
    };
  }, [minuteStamp]);
};
