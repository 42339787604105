import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActorFragment,
  DocumentSideEnum,
  DocumentsOfHostAccount,
  DocumentsPersonalRealPerson,
  Maybe,
  ModelReleaseFormLanguageEnum,
  ModelReleaseFormRequestContextEnum,
  useDocumentDetailPageMrfLazyQuery,
  useDocumentDetailPageQuery,
} from '../../generated/graphql';
import { createContext } from '../../hooks/useContext';
import { extractDisplayNameFromAddress } from '../../pages/DocumentPages/utils/helper';

export type DocumentDetailContext = {
  requestContext: ModelReleaseFormRequestContextEnum;
  isOriginatorAndActor: boolean;
  mappedAgeVerificationData: any;
  ageVerificationDataLoading: boolean;
  ageVerficicationDataRefetch: () => void;
  mappedMRFData: any;
  actor?: ActorFragment | null | undefined;
  actorForMRF?: ActorFragment | null | undefined;
  idShotRequired: (forActorPage: boolean) => boolean;
};

export const [, useDocumentDetailContext, documentDetailContext] =
  createContext<DocumentDetailContext>({
    name: 'DocumentDetailProvider',
    errorMessage:
      'useDocumentDetailContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const DocumentDetailProvider: React.FC<{
  children: React.ReactNode;
  userIdNumber: number;
}> = ({ children, userIdNumber }) => {
  const { t } = useTranslation('realPersonList');

  const { data, loading, refetch } = useDocumentDetailPageQuery({
    variables: {
      userId: userIdNumber,
    },
  });

  const isOriginatorAndActor = React.useMemo(() => {
    return data?.account.originator?.isActor ?? false;
  }, [data?.account.originator?.isActor]);

  const requestContext = React.useMemo(() => {
    const originator = data?.account.originator;
    const isOriginator = originator?.userId === userIdNumber;
    if (isOriginator) return ModelReleaseFormRequestContextEnum.Originator;

    const actor = data?.account.actor;
    const isActor = actor?.userId === userIdNumber;
    if (isActor) return ModelReleaseFormRequestContextEnum.Actor;

    return ModelReleaseFormRequestContextEnum.Model;
  }, [data?.account.actor, data?.account.originator, userIdNumber]);

  const actorForMRF = React.useMemo(() => {
    return data?.account?.actors?.at(0);
  }, [data?.account?.actors]);

  type AgeVerificationDocumentsProps = {
    documents:
      | Partial<DocumentsPersonalRealPerson>
      | Partial<DocumentsOfHostAccount>
      | undefined;
    name: Maybe<string> | undefined;
  };

  const ageVerficicationDocumentsFromContext = React.useMemo(() => {
    const originator = data?.account.originator;
    const actor = data?.account.actor;
    const personData: {
      [context in ModelReleaseFormRequestContextEnum]: AgeVerificationDocumentsProps;
    } = {
      originator: {
        documents: originator?.personalDocuments,
        name: extractDisplayNameFromAddress(originator?.address),
      },
      actor: {
        documents: actor?.personalDocuments,
        name: extractDisplayNameFromAddress(actor?.address),
      },
      model: {
        documents: data?.account?.personalDocuments,
        name: t('heading.PersonenUndDokumente'),
      },
    };

    return personData[requestContext];
  }, [
    data?.account.actor,
    data?.account.originator,
    data?.account?.personalDocuments,
    requestContext,
    t,
  ]);

  const mappedAgeVerificationData = React.useMemo(() => {
    const { documents, name } = ageVerficicationDocumentsFromContext;

    // Always assume to be required
    const isIdentityProofFrontRequired = true;

    // Assume back to be missing if front exists as Frontside and not EntireDocument
    const isIdentityProofBackRequired =
      documents?.identityProofFront?.side === DocumentSideEnum.Frontside;

    const isIdShotRequired = isOriginatorAndActor;

    const isOriginator =
      requestContext === ModelReleaseFormRequestContextEnum.Originator;
    const isActor = requestContext === ModelReleaseFormRequestContextEnum.Actor;
    const isModel = requestContext === ModelReleaseFormRequestContextEnum.Model;
    const wizardFinishedIfModel = isModel
      ? (documents?.identityProofFront?.side ===
          DocumentSideEnum.EntireDocument ||
          (documents?.identityProofFront?.side === DocumentSideEnum.Frontside &&
            documents?.identityProofBack?.side ===
              DocumentSideEnum.Backside)) &&
        !!documents.idShot
      : false;
    const isAgeVerificationWizardFinished =
      isOriginator || isActor || wizardFinishedIfModel;

    return {
      documents,
      name,
      isIdentityProofFrontRequired,
      isIdentityProofFrontRequiredButMissing:
        isIdentityProofFrontRequired && !documents?.identityProofFront,
      isIdentityProofBackRequired,
      isIdentityProofBackRequiredButMissing:
        isIdentityProofBackRequired && !documents?.identityProofBack,
      isIdShotRequired,
      isIdShotRequiredButMissing: isIdShotRequired && !documents?.idShot,
      isAgeVerificationWizardFinished2:
        (data?.documents?.ageVerificationTour?.completenessPercent ?? 0) >= 100,
      isAgeVerificationWizardFinished,
    };
  }, [
    ageVerficicationDocumentsFromContext,
    data?.documents?.ageVerificationTour?.completenessPercent,
    isOriginatorAndActor,
    requestContext,
  ]);

  const [getMRFData, { data: mrfData }] = useDocumentDetailPageMrfLazyQuery();

  React.useEffect(() => {
    getMRFData({
      variables: {
        userId: userIdNumber,
        lang: ModelReleaseFormLanguageEnum.De,
        requestContext,
      },
    });
  }, [getMRFData, requestContext, userIdNumber]);

  const mappedMRFData = React.useMemo(() => {
    const modelReleaseForm = mrfData?.documents?.modelReleaseForm;
    const isMRFWizardFinished = true;

    return {
      modelReleaseForm,
      isMRFWizardFinished,
    };
  }, [mrfData?.documents?.modelReleaseForm]);

  const actor = React.useMemo(() => {
    return data?.account?.actor;
  }, [data]);

  const idShotRequired = React.useCallback(
    (forActorPage: boolean) => {
      const idShotRequired = forActorPage
        ? data?.account.actor?.personalDocuments.isIdShotRequired
        : data?.account.originator?.personalDocuments.isIdShotRequired;
      return idShotRequired ?? false;
    },
    [
      data?.account.actor?.personalDocuments.isIdShotRequired,
      data?.account.originator?.personalDocuments.isIdShotRequired,
    ]
  );

  const context: DocumentDetailContext = React.useMemo(() => {
    return {
      requestContext,
      isOriginatorAndActor,
      mappedAgeVerificationData,
      ageVerificationDataLoading: loading,
      ageVerficicationDataRefetch: refetch,
      mappedMRFData,
      actor,
      actorForMRF,
      idShotRequired,
    };
  }, [
    actor,
    actorForMRF,
    idShotRequired,
    isOriginatorAndActor,
    loading,
    mappedAgeVerificationData,
    mappedMRFData,
    refetch,
    requestContext,
  ]);

  return (
    <documentDetailContext.Provider value={context}>
      {children}
    </documentDetailContext.Provider>
  );
};
