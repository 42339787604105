import * as icon from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  Image,
  ImageProps,
  Skeleton,
  Text,
  VStack,
  Wrap,
  WrapItem,
  chakra,
} from '@chakra-ui/react';
import { useFeature } from 'flagged';
import { FormikProvider, useFormik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import backgroundShape from '../../../assets/images/feed/background.svg';
import { FeatureName } from '../../../flags';
import {
  FeedClipPostFragment,
  FeedPhotoPostFragment,
  FeedTextPostFragment,
  FskEnum,
} from '../../../generated/feed';
import { useFeedPostSchedulableTimeframe } from '../../../hooks/useFeedPostSchedulableTimeframe';
import { useFeedPostEditModalContext } from '../../../provider/FeedPostEditModalProvider';
import { useFlagsProviderV2 } from '../../../provider/FlagsProviderV2';
import { coinsToEuro } from '../../../utils/coinsToEuro';
import { getFormattedAmount } from '../../../utils/formattedAmount';
import {
  createDateValidationSchema,
  createStringValidationSchema,
} from '../../../utils/validation';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../Layout/ResponsiveModal';
import { contentType } from '../FeedPostCreate/FeedPostCreate';
import { PriceSliderParent } from '../FormElements/PriceSlider/PriceSlider';
import SwitchInlineControl from '../FormElements/SwitchInlineControl/SwitchInlineControl';
import {
  InputControl,
  SelectControl,
  TextareaControl,
} from '../FormikFormElements';
import VideoLengthFromSeconds from '../VideoLengthFromSeconds/VideoLengthFromSeconds';

const fieldName = {
  title: 'title',
  description: 'description',
  postShouldBePinned: 'postShouldBePinned',
  postIsGRated: 'postIsGRated',
  postIsScheduled: 'postIsScheduled',
  postShouldBeSold: 'postShouldBeSold',
  postPrice: 'postPrice',
  publicationScheduledFor: 'publicationScheduledFor',
  tippingGoal: 'tippingGoal',
  contentType: 'contentType',
} as const;

type MediaPreviewBoxProps = {
  imageProps: ImageProps;
  children?: React.ReactNode;
};

// Needs to be revised anyway once you can edit the images and clips
export const MediaPreviewBox: React.FC<MediaPreviewBoxProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  // TODO Possibly outsource it to a separate component so that we can reuse the framework with the loading and error state, as we use it in several places.
  // TODO Missing error state. -> Meeting with cg: Error States not final, will be implemented later

  return (
    <Box width={'70px'} height={'87.5px'} position={'relative'}>
      {isLoading && (
        <Skeleton
          h={'full'}
          w={'full'}
          startColor={'steel'}
          endColor={'primary.200'}
        />
      )}
      <Image
        hidden={isLoading}
        onLoad={() => setIsLoading(false)}
        width={'full'}
        height={'full'}
        objectFit={'cover'}
        {...props.imageProps}
      />

      {props.children}
    </Box>
  );
};

function extractMediaCount(
  data:
    | FeedTextPostFragment
    | FeedClipPostFragment
    | FeedPhotoPostFragment
    | null
) {
  switch (data?.__typename) {
    case 'ClipPost':
      return 1;
    case 'PhotosPost':
      return data?.photos?.length ?? 0;
    default:
      return 0;
  }
}

const FeedPostEditModal: React.FC = () => {
  const scheduableTimeframe = useFeedPostSchedulableTimeframe();
  const { coinsEnabled: coinsEnabledFlag } = useFlagsProviderV2();
  const feedPostSchedulingFlag = useFeature(FeatureName.feedPostScheduling);
  const feedPaidContentFlag = useFeature(FeatureName.feedPaidContent);
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);

  const ctx = useFeedPostEditModalContext();
  const { t } = useTranslation(['feed']);
  const locale = useIntl().locale;
  const postData = ctx.initPostDataValues;
  const showMediaSection =
    (postData?.__typename === 'PhotosPost' && postData?.photos) ||
    (postData?.__typename === 'ClipPost' && postData?.clip?.poster);

  const mediaCount = extractMediaCount(postData);

  const maxLength = 200;
  // dummy variable, not deliverd from backend yet
  const publicationScheduledFor: Maybe<string> =
    postData?.publicationScheduledFor; // '2023-04-01T13:37'

  const isScheduleStillEditable = React.useMemo(() => {
    if (!publicationScheduledFor) {
      return false;
    }
    return scheduableTimeframe.checkDateEditable(publicationScheduledFor);
  }, [scheduableTimeframe, publicationScheduledFor]);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [fieldName.title]: createStringValidationSchema({
        isOptional: true,
        maxLength,
      }),
      [fieldName.description]: createStringValidationSchema({
        isOptional: true,
        maxLength,
      }),
      [fieldName.publicationScheduledFor]: createDateValidationSchema({
        isOptional: false,
        minDate: scheduableTimeframe.min,
        maxDate: scheduableTimeframe.max,
      }).when([fieldName.postIsScheduled], (postIsScheduled, schema) =>
        !isScheduleStillEditable || !postIsScheduled ? Yup.date() : schema
      ),
    });
  }, [scheduableTimeframe, isScheduleStillEditable]);

  const initialValues = React.useMemo(() => {
    const postShouldBeSold =
      coinsEnabledFlag && postData?.priceCoins > 0
        ? !!postData?.priceCoins
        : !!postData?.price;
    const postPrice =
      coinsEnabledFlag && postData?.priceCoins > 0
        ? postData?.priceCoins
        : postData?.price;
    const tippingGoal =
      coinsEnabledFlag && postData?.tippingGoal?.goalCoins > 0
        ? postData?.tippingGoal?.goalCoins
        : postData?.tippingGoal?.goal;

    return {
      [fieldName.title]: postData?.title ?? '',
      [fieldName.description]: postData?.text ?? '',
      [fieldName.postShouldBePinned]: postData?.pinned ?? false,
      [fieldName.postIsGRated]: postData?.fsk === FskEnum.Fsk18,
      [fieldName.postShouldBeSold]: postShouldBeSold,
      [fieldName.postPrice]: postPrice,
      [fieldName.postIsScheduled]: !!publicationScheduledFor,
      [fieldName.publicationScheduledFor]: publicationScheduledFor ?? '',
      [fieldName.tippingGoal]: tippingGoal,
      [fieldName.contentType]: postShouldBeSold
        ? contentType.PaidContent
        : !!postData?.tippingGoal?.goal || postData?.tippingGoal?.goalCoins
        ? contentType.TippingGoal
        : contentType.Free,
    };
  }, [
    coinsEnabledFlag,
    postData?.priceCoins,
    postData?.price,
    postData?.tippingGoal?.goalCoins,
    postData?.tippingGoal?.goal,
    postData?.title,
    postData?.text,
    postData?.pinned,
    postData?.fsk,
    publicationScheduledFor,
  ]);

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await ctx.action.editPost({
        pinned: values[fieldName.postShouldBePinned],
        text: values[fieldName.description],
        title: values[fieldName.title],
        publicationScheduledFor:
          values[fieldName.publicationScheduledFor] || null,
      });
    },
  });

  return (
    <ResponsiveModal
      isOpen={ctx.isOpen}
      onClose={ctx.action.closeModal}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        maxW={'567px'}
        bgColor={'surface'}
        bgImage={`url(${backgroundShape})`}
        bgPos={'top center'}
        bgSize={'auto 255px'}
        bgRepeat={'no-repeat'}
      >
        <ResponsiveModalStickyHeaderBox bg={'surface'}>
          <Heading as="h1" children={t('feed:modal.heading')} size={'sm'} />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'transparent'}>
          <FormikProvider value={formik}>
            <VStack alignItems={'stretch'}>
              {showMediaSection && (
                <VStack spacing={3} alignItems={'stretch'} mb={4}>
                  <Box>
                    <Heading size={'sm'}>
                      <HStack spacing={'1'}>
                        <Icon as={icon.CheckCircle} boxSize={'icon.sm'} />
                        <span>{t('feed:heading.ContentHinzugefugt')}</span>
                        <chakra.span aria-hidden>{`(${mediaCount}/${
                          postData?.__typename === 'ClipPost' ? 1 : 10
                        })`}</chakra.span>
                      </HStack>
                    </Heading>
                  </Box>

                  <Wrap direction={'row'} flexWrap={'wrap'} spacing={'4'}>
                    {postData.__typename === 'PhotosPost' &&
                      postData.photos?.map((item, index) => (
                        <WrapItem key={`media-preview-item-${index}`}>
                          <MediaPreviewBox imageProps={{ src: item.url }} />
                        </WrapItem>
                      ))}
                    {postData.__typename === 'ClipPost' &&
                      postData.clip?.poster && (
                        <WrapItem>
                          <MediaPreviewBox
                            imageProps={{
                              src: postData.clip.poster.url,
                              objectFit: 'contain',
                              backgroundColor: 'black',
                            }}
                            children={
                              <VideoLengthFromSeconds
                                durationInSeconds={postData.clip.length}
                                position={'absolute'}
                                bottom={0}
                                right={0}
                              />
                            }
                          />
                        </WrapItem>
                      )}
                  </Wrap>
                </VStack>
              )}

              <Box>
                <TextareaControl
                  maxCharacterCount={maxLength}
                  name={fieldName.title}
                  textareaProps={{
                    rows: 1,
                    placeholder: t('feed:placeholder.GibEinenTitelEin'),
                  }}
                  label={t('feed:label.Titel')}
                  // fixme! currently overridden from TextareaControl
                  minH={'unset'}
                />
              </Box>
              <Box pb={4}>
                <TextareaControl
                  maxCharacterCount={maxLength}
                  name={fieldName.description}
                  textareaProps={{
                    placeholder: t(
                      'feed:placeholder.ErzahleEtwasUberDeinenBeitrag'
                    ),
                  }}
                  label={t('feed:label.Beschreibung')}
                />
              </Box>
              {feedTippingGoalFlag && (
                <>
                  <SelectControl
                    isDisabled
                    name={fieldName.contentType}
                    optionListProps={{
                      options: [
                        {
                          label: t('feed:label.Kostenlos'),
                          value: contentType.Free,
                        },
                        {
                          label: t('feed:label.ContentVerkaufen'),
                          value: contentType.PaidContent,
                        },
                        {
                          label: t('feed:label.TippingGoal'),
                          value: contentType.TippingGoal,
                        },
                      ],
                    }}
                  />

                  {/* A negative margin top is used to counteract the
                    existing reserved error space of the select control */}
                  <Box px={'4'} mt={'-2'}>
                    {formik.getFieldProps(fieldName.contentType).value ===
                      contentType.PaidContent && (
                      <>
                        <Heading
                          mb={'1'}
                          fontWeight={'normal'}
                          fontSize={'md'}
                          lineHeight={'6'}
                          color={'coldGray.900'}
                          children={t('feed:label.ContentVerkaufen')}
                        />
                        <Text
                          fontSize={'sm'}
                          lineHeight={'6'}
                          color={'gray.500'}
                          children={t('feed:switch.paidContent')}
                        />
                        {(!!postData?.price || !!postData?.priceCoins) && (
                          <>
                            <PriceSliderParent
                              isDisabled
                              name={fieldName.postPrice}
                              steps={
                                coinsEnabledFlag
                                  ? ctx.feedConfig.priceCoins.steps
                                  : ctx.feedConfig.price.steps
                              }
                              isCoinPost={!!postData?.priceCoins}
                            />
                            {coinsEnabledFlag && (
                              <Text
                                fontSize={'12px'}
                                lineHeight={'16px'}
                                color={'darkSteel'}
                                fontWeight={'light'}
                                pb={4}
                              >
                                <Trans
                                  t={t}
                                  i18nKey={'feed:text.EntsprichtCaXEuro'}
                                  components={{
                                    price: (
                                      <chakra.span fontWeight={'medium'}>
                                        {`${getFormattedAmount(
                                          coinsToEuro(
                                            Number(formik.values['postPrice'])
                                          ),
                                          locale
                                        )}`}
                                      </chakra.span>
                                    ),
                                  }}
                                />
                              </Text>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {formik.getFieldProps(fieldName.contentType).value ===
                      contentType.TippingGoal && (
                      <>
                        <Heading
                          mb={'1'}
                          fontWeight={'normal'}
                          fontSize={'md'}
                          lineHeight={'6'}
                          color={'coldGray.900'}
                          children={t('feed:label.TippingGoal')}
                        />
                        <Text
                          fontSize={'sm'}
                          lineHeight={'6'}
                          color={'gray.500'}
                          children={t('feed:switch.tippingGoal')}
                        />
                        {(!!postData?.tippingGoal?.goal ||
                          postData?.tippingGoal?.goalCoins) && (
                          <>
                            <PriceSliderParent
                              isDisabled
                              name={fieldName.tippingGoal}
                              steps={
                                coinsEnabledFlag
                                  ? ctx.feedConfig.tippingGoalCoins.steps
                                  : ctx.feedConfig.tippingGoal.steps
                              }
                              isCoinPost={!!postData?.priceCoins}
                            />
                            {coinsEnabledFlag && (
                              <Text
                                fontSize={'12px'}
                                lineHeight={'16px'}
                                color={'darkSteel'}
                                fontWeight={'light'}
                                pb={4}
                              >
                                <Trans
                                  t={t}
                                  i18nKey={'feed:text.EntsprichtCaXEuro'}
                                  components={{
                                    price: (
                                      <chakra.span fontWeight={'medium'}>
                                        {`${getFormattedAmount(
                                          coinsToEuro(
                                            Number(formik.values['tippingGoal'])
                                          ),
                                          locale
                                        )}`}
                                      </chakra.span>
                                    ),
                                  }}
                                />
                              </Text>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}
              {feedTippingGoalFlag || !feedPaidContentFlag ? null : (
                <>
                  <Divider />
                  <Box py={2}>
                    <SwitchInlineControl
                      name={fieldName.postShouldBeSold}
                      label={t('feed:label.ContentVerkaufen')}
                      labelProps={{
                        color: 'coldGray.900',
                      }}
                      description={t('feed:switch.paidContent')}
                      switchProps={{
                        isDisabled: true,
                      }}
                    />
                    {!!postData?.price && (
                      <>
                        <PriceSliderParent
                          name={fieldName.postPrice}
                          steps={
                            coinsEnabledFlag
                              ? ctx.feedConfig.priceCoins.steps
                              : ctx.feedConfig.price.steps
                          }
                          isCoinPost={!!postData?.priceCoins}
                        />
                        {coinsEnabledFlag && (
                          <Text
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color={'darkSteel'}
                            fontWeight={'light'}
                            pb={4}
                          >
                            <Trans
                              t={t}
                              i18nKey={'feed:text.EntsprichtCaXEuro'}
                              components={{
                                price: (
                                  <chakra.span fontWeight={'medium'}>
                                    {`${getFormattedAmount(
                                      coinsToEuro(
                                        Number(formik.values['postPrice'])
                                      ),
                                      locale
                                    )}`}
                                  </chakra.span>
                                ),
                              }}
                            />
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}
              {!feedPostSchedulingFlag || !publicationScheduledFor ? null : (
                <>
                  <Divider />
                  <Box py={2}>
                    <SwitchInlineControl
                      name={fieldName.postIsScheduled}
                      switchProps={{
                        isDisabled: true,
                        isReadOnly: !isScheduleStillEditable,
                      }}
                      label={t('feed:label.BeitragPlanen')}
                      labelProps={{
                        color: 'coldGray.900',
                      }}
                      description={t(
                        'feed:text.DieZeitzoneRichtetSichNachDeinenBrowsereinstellungen'
                      )}
                    />
                    {!!formik.values[fieldName.postIsScheduled] && (
                      <InputControl
                        name={fieldName.publicationScheduledFor}
                        isReadOnly={!isScheduleStillEditable}
                        inputProps={{
                          'aria-label': t(
                            'feed:label.Veroffentlichungzeitpunkt'
                          ),
                          formNoValidate: true,
                          type: 'datetime-local',
                          sx: {
                            '&::-webkit-date-and-time-value': {
                              textAlign: 'left',
                            },
                          },
                          min: scheduableTimeframe.min,
                          max: scheduableTimeframe.max,
                        }}
                      />
                    )}
                  </Box>
                </>
              )}
              <Divider />
              <Box py={2}>
                <SwitchInlineControl
                  name={fieldName.postShouldBePinned}
                  label={t('feed:label.BeitragPinnen')}
                />
              </Box>
              <Divider />
              <Box py={2}>
                <SwitchInlineControl
                  name={fieldName.postIsGRated}
                  label={t('feed:label.Als18Kennzeichnen')}
                  switchProps={{ isDisabled: true }}
                />
              </Box>
              <Divider />
            </VStack>
          </FormikProvider>
        </ResponsiveModalBodyBox>
        <ResponsiveModalStickyFooterBox>
          <Button
            variant={'solid'}
            isLoading={formik.isSubmitting || ctx.isEditPostLoading}
            isDisabled={!formik.isValid || ctx.isEditPostLoading}
            onClick={formik.submitForm}
          >
            {t('feed:modal.button')}
          </Button>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

export default FeedPostEditModal;
