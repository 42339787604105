interface UploadDocumentJSONResponseError {
  success: false;
  message: string | undefined;
  exception: string | undefined;
}

interface UploadDocumentJSONResponseSuccess {
  success: true;
  documentId: number;
  message: string | undefined;
}

type UploadDocumentJSONResponse =
  | UploadDocumentJSONResponseSuccess
  | UploadDocumentJSONResponseError;

export function checkProperlyFormattedUploadResponse(
  _: any
): _ is UploadDocumentJSONResponse {
  if (!_ || typeof _ !== 'object') {
    return false;
  }

  const res = _ as UploadDocumentJSONResponse;
  if (!res.hasOwnProperty('success') || typeof res.success !== 'boolean') {
    return false;
  }

  if (
    res.success === true &&
    (!res.hasOwnProperty('success') || typeof res.documentId !== 'number')
  ) {
    return false;
  }

  if (
    res.success === false &&
    (!res.hasOwnProperty('message') || typeof res.message !== 'string')
  ) {
    return false;
  }

  return true;
}
