import {
  OdiCardDescription,
  OdiCardDescriptionProps,
  OdiCardTitle,
  OdiCardTitleProps,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  ButtonProps,
  Heading,
  Icon,
  Progress,
  ProgressProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShowTipButton } from '../../../../provider/TipDetailModalProvider';

export const ModelProfileProgressGoToProfileButton = forwardRef<
  ButtonProps,
  'button'
>((props, ref) => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <Button
      w={'full'}
      rightIcon={<Icon as={icons.ChevronRight} boxSize={'icon.md'} />}
      ref={ref}
      {...props}
    >
      {t('modelProfile:button.ProfilVollstandigAusfullen')}
    </Button>
  );
});

export const ModelProfileProgressGuideToggleButton = forwardRef<
  { isActivated?: boolean } & ButtonProps,
  'button'
>(({ isActivated = false, ...props }, ref) => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <Button
      w={'full'}
      leftIcon={
        <Icon
          as={isActivated ? icons.VisibilityOff : icons.Visibility}
          boxSize={'icon.md'}
        />
      }
      variant={'solid'}
      ref={ref}
      {...props}
    >
      {t(
        isActivated
          ? 'modelProfile:button.MarkierungenAusblenden'
          : 'modelProfile:button.FehlendeFelderMarkieren'
      )}
    </Button>
  );
});

export const ModelProfileProgressShowAdvantagesButton = forwardRef<
  ButtonProps,
  'button'
>((props, ref) => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <ShowTipButton
      variant={'ghost'}
      w={'full'}
      //
      tipId={'tip-16'}
      ref={ref}
      {...props}
    >
      {t('modelProfile:button.DieseVorteileErwartenDich')}
    </ShowTipButton>
  );
});

export const ModelProfileProgressCardTitle = forwardRef<
  { progress: number } & OdiCardTitleProps,
  'h3'
>(({ progress, ...props }, ref) => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <Heading as={OdiCardTitle} size={'sm'} ref={ref} {...props}>
      {t('modelProfile:heading.DeinModelprofilIstZuPercentAusgefullt', {
        percent: progress,
      })}
    </Heading>
  );
});

export const ModelProfileProgressCardDescription = forwardRef<
  OdiCardDescriptionProps,
  'div'
>((props, ref) => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <OdiCardDescription ref={ref} {...props}>
      {t(
        'modelProfile:text.FulleDeinProfilVollstandigAusUmBesonderesAttraktivFurKundenZuSein'
      )}
    </OdiCardDescription>
  );
});

export const ModelProfileProgressBar: React.FC<
  { progress: number } & ProgressProps
> = ({ progress, ...props }) => (
  <Progress
    value={progress}
    min={0}
    max={1}
    size={'sm'}
    w={'full'}
    {...props}
  />
);
