import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputList } from '../../../../../components/Spacer/InputsList/InputList';
import {
  ClearableInputControl,
  PromotingSelectControl,
} from '../../../../../components/shared/FormikFormElements';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import {
  CountryEnum,
  ProfileFieldsEnum,
} from '../../../../../generated/graphql';
import { useFormikInitialProps } from '../../../../../hooks/useFormikInitialProps';
import { useWizardOnContinueCallback } from '../../../../../hooks/useWizardOnContinueCallback';
import { noop } from '../../../../../utils';
import { onFocusScrollIntoCenter } from '../../../../../utils/onFocusScrollIntoCenter';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../../components/WizardStepFooter/WizardStepFooter';
import { useWizardMRF } from '../../WizardMRFContext';

const streetName = ProfileFieldsEnum.MrfStreet as const;
const houseNumberName = ProfileFieldsEnum.MrfStreetNumber as const;
const cityName = ProfileFieldsEnum.MrfCity as const;
const postalCodeName = ProfileFieldsEnum.MrfPostalCode as const;
const countryName = ProfileFieldsEnum.MrfCountry as const;

type Inputs = {
  [streetName]: string;
  [houseNumberName]: string;
  [cityName]: string;
  [postalCodeName]: string;
  [countryName]: CountryEnum;
};

export const WizardMRFModelReleaseFormV1FullAddress: React.FC = () => {
  const { t } = useTranslation(['wizardMRF', 'general']);
  const wizard = useWizardMRF();
  const { currentField, accountPrefillField } = wizard;

  const prefillValues = React.useMemo<Inputs>(
    () => ({
      [streetName]: accountPrefillField?.accountAddressStreet?.value ?? '',
      [houseNumberName]:
        accountPrefillField?.accountAddressStreetNumber?.value ?? '',
      [cityName]: accountPrefillField?.accountAddressCity?.value ?? '',
      [postalCodeName]:
        accountPrefillField?.accountAddressPostalCode?.value ?? '',
      [countryName]: (accountPrefillField?.accountAddressCountry?.value ??
        '') as CountryEnum,
    }),
    [accountPrefillField]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [streetName]: createStringValidationSchema({
          ...currentField[streetName],
        }),
        [houseNumberName]: createStringValidationSchema({
          ...currentField[houseNumberName],
        }),
        [cityName]: createStringValidationSchema({ ...currentField[cityName] }),
        [postalCodeName]: createStringValidationSchema({
          ...currentField[postalCodeName],
        }),
        [countryName]: createStringValidationSchema({}),
      }),
    [currentField]
  );

  const { initialValues, initialTouched } =
    useFormikInitialProps(prefillValues);
  const formik = useFormik<Inputs>({
    initialValues,
    initialTouched,
    onSubmit: noop,
    validationSchema,
    enableReinitialize: true,
  });

  const onContinue = useWizardOnContinueCallback({
    wizard,
    currentSelected: 'formik',
    formiks: { formik },
  });

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t('wizardMRF:heading.WoWohnstDu')}
          children={
            <form
              aria-busy={formik.isValidating}
              aria-label={t('wizardMRF:heading.WoWohnstDu')}
            >
              <FormikProvider value={formik}>
                <InputList>
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    label={t('wizardMRF:label.Strasse')}
                    placeholder={t('wizardMRF:placeholder.StrasseEingeben')}
                    name={streetName}
                  />
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    label={t('wizardMRF:label.Hausnummer')}
                    placeholder={t('wizardMRF:placeholder.HausnummerEingeben')}
                    name={houseNumberName}
                  />
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    label={t('wizardMRF:label.Stadt')}
                    placeholder={t('wizardMRF:placeholder.StadtEingeben')}
                    name={cityName}
                  />
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    label={t('wizardMRF:label.Postleitzahl')}
                    placeholder={t(
                      'wizardMRF:placeholder.PostleitzahlEingeben'
                    )}
                    name={postalCodeName}
                  />
                  <PromotingSelectControl
                    onFocus={onFocusScrollIntoCenter}
                    name={countryName}
                    label={t('wizardMRF:label.Land')}
                    optionListProps={{
                      placeholder: t('wizardMRF:placeholder.LandEingeben'),
                      options:
                        currentField[countryName]?.validValues?.map(
                          ({ value, label: fieldLabel }) => ({
                            value,
                            label: fieldLabel ?? `${value}`,
                          })
                        ) ?? [],
                      groupLabel: t('general:optgroup.Lander'),
                      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
                      promotedValues:
                        currentField[countryName]?.quickpickValues?.map(
                          ({ value }) => value
                        ) ?? [],
                    }}
                    selectProps={{}}
                  />
                </InputList>
              </FormikProvider>
            </form>
          }
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardMRF:button.Weiter')}
          onContinueButtonClick={onContinue}
          disableContinueButton={!formik.isValid}
          omitSkipButton
        />
      }
    />
  );
};
