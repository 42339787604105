import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { OriginatorFragment } from '../../../../../generated/graphql';
import useHistoryWithStorage from '../../../../../hooks/useHistoryWithStorage';
import { routes } from '../../../../../routes/routesConfig';
import { extractDisplayNameFromAddress } from '../../../utils/helper';
import {
  RealPersonListItem,
  RealPersonListItemProps,
} from '../RealPersonListItem/RealPersonListItem';

export const OriginatorListItem: React.FC<
  RealPersonListItemProps & { originator: OriginatorFragment }
> = ({ originator, ...realPersonListItemProps }) => {
  const { t } = useTranslation(['realPerson']);
  const history = useHistoryWithStorage();
  const userId = originator.userId.toString();
  const intl = useIntl();
  const name = React.useMemo(() => {
    return extractDisplayNameFromAddress(originator?.address);
  }, [originator]);
  const description = React.useMemo(() => {
    const descriptionEntries = [t('realPerson:text.AccountInhaber')];
    if (originator.isActor) {
      descriptionEntries.push(t('realPerson:text.Model'));
    }

    return intl.formatList(descriptionEntries, {
      style: 'short',
      type: 'conjunction',
    });
  }, [intl, originator.isActor, t]);
  return (
    <RealPersonListItem
      onClick={() => {
        history.push(
          routes.documentsActorsDetail.path.replace(':userId', userId)
        );
      }}
      name={name}
      status={originator.personalDocumentsStatus}
      description={description}
      {...realPersonListItemProps}
    />
  );
};
