import { OdiCardProps } from '@campoint/odi-ui';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';

import { noop } from '../../../utils';
import {
  LargeIconCard,
  LargeIconCardDescription,
  LargeIconCardIcon,
  LargeIconCardSubTitle,
  LargeIconCardTitle,
  LargeIconTextContainer,
} from '../cards/LargeIconCard/LargeIconCard';

export type ActionTileProps = {
  onClick: () => void;
  icon: EmotionIcon | undefined;
  title: string;
  subtitle?: Maybe<string>;
  description?: Maybe<string>;
  subDescription?: Maybe<string>;
  isAvailableIndicator?: React.ReactNode;
};

export const ActionTile: React.FC<OdiCardProps & ActionTileProps> = ({
  onClick,
  isAvailableIndicator,
  icon,
  title,
  subtitle,
  description,
  subDescription,
  ...props
}) => {
  return (
    <LargeIconCard
      onClick={onClick}
      cursor={onClick !== noop ? 'pointer' : 'unset'}
      {...props}
    >
      {/* could use CornerGuideDot here */}
      {isAvailableIndicator}
      <LargeIconCardIcon icon={icon} />
      <LargeIconTextContainer>
        <LargeIconCardSubTitle children={subtitle} />
        <LargeIconCardTitle children={title} />
        {description && <LargeIconCardDescription children={description} />}
        {subDescription && (
          <LargeIconCardDescription children={subDescription} />
        )}
      </LargeIconTextContainer>
    </LargeIconCard>
  );
};
