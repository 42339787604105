import { Box, Button, VStack, chakra } from '@chakra-ui/react';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  RequiredErrorMessage,
  TooOldErrorMessage,
  TooYoungErrorMessage,
} from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { FormikDateInput } from '../../../../../components/shared/FormikInput/Date/FormikDateInput';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { useToday } from '../../../../../provider/TodayProvider';
import { calculateAge } from '../../../../../test/date-utils';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { useWizardProfile } from '../../WizardProfileContext';

const ageInputName = 'modelBirthdate';

type ContentSectionProps = {
  formik: FormikContextType<any>;
};

const minimumAge = 18;
const maximumAge = 75;

const ContentSection: React.FC<ContentSectionProps> = ({ formik }) => {
  const { t } = useTranslation('modelProfile', { keyPrefix: 'fields.age' });
  const { todayDayStamp } = useToday();

  const maxDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: minimumAge }).toISODate(),
    [todayDayStamp]
  );

  const minDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: maximumAge }).toISODate(),
    [todayDayStamp]
  );

  const { [ageInputName]: currentValue } = formik.values;

  const age = React.useMemo(
    () => (currentValue ? calculateAge(currentValue) : '--'),
    [currentValue]
  );

  return (
    <FormikProvider value={formik}>
      <WizardSubHeader
        bottomMarginEnabled={false}
        headerText={t('wizardPageTitle')}
      />
      <Form>
        <Box mt={8}>
          <FormikDateInput
            label={t('label')}
            max={maxDate}
            min={minDate}
            name={ageInputName}
            placeholder={t('placeholder') ?? undefined}
          />
        </Box>

        <chakra.span my={4} textStyle={'bodyMd'}>
          {Trans({
            t,
            i18nKey: `currentAge`,
            components: { styledAge: <chakra.span fontWeight={500} /> },
            values: { age },
          })}
        </chakra.span>
      </Form>
    </FormikProvider>
  );
};

const HintSection: React.FC = () => {
  const { t } = useTranslation(['general', 'modelProfile']);

  return <TipCard text={t('modelProfile:fields.age.hint')} />;
};

export const WizardProfileOnboardingV1Birthdate: React.FC = () => {
  const { t } = useTranslation(['modelProfile', 'wizardProfile', 'general']);

  const wizardCtx = useWizardProfile();
  const { modelBirthdate } = wizardCtx?.currentField ?? {};

  const initialValues = React.useMemo(
    () => ({
      [ageInputName]: modelBirthdate?.value ?? '',
    }),
    [modelBirthdate]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [ageInputName]: Yup.string()
          .required(() => <RequiredErrorMessage />)
          .test((value, testContext) => {
            if (value && calculateAge(value) < minimumAge) {
              return testContext.createError({
                message: () => <TooYoungErrorMessage years={minimumAge} />,
              });
            }
            if (value && calculateAge(value) > maximumAge) {
              return testContext.createError({
                message: () => <TooOldErrorMessage years={maximumAge} />,
              });
            }
            return true;
          }),
      }),
    []
  );

  const onSubmit = React.useCallback(
    (values: typeof initialValues) => {
      wizardCtx.wizardNextStepCallback({
        modelBirthdate: values?.[ageInputName],
      });
    },
    [wizardCtx]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <WizardParentModalStepLayout
      contentSection={<ContentSection formik={formik} />}
      hintSection={<HintSection />}
      footerSection={
        <VStack spacing={'2'}>
          <Button
            isDisabled={!(formik.isValid && formik.dirty)}
            w={'full'}
            variant={'solid'}
            shadow={'none'}
            children={t('general:button.continue')}
            onClick={formik.submitForm}
          />
          <Button
            w={'full'}
            shadow={'none'}
            children={t('general:button.skip')}
            onClick={() => wizardCtx.wizardNextStepCallback()}
          />
        </VStack>
      }
    />
  );
};
