import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetIncomeSourceDetailDataDocument,
  GetIncomeSourceSectionDataDocument,
  GetIncomeSourcesDataDocument,
  IncomeSourceWithBodyEntryFragment,
  useActivateIncomeSourceMutation,
  useDeactivateIncomeSourceMutation,
} from '../../../generated/graphql';
import { getApolloContext } from '../../../utils/apollo';

interface IncomeSourceActiveSwitchProps {
  incomeSource: Maybe<
    Pick<IncomeSourceWithBodyEntryFragment, 'id' | 'activatedAt'>
  >;
}

export function IncomeSourceActiveSwitch(props: IncomeSourceActiveSwitchProps) {
  const { incomeSource } = props;
  const { t, i18n } = useTranslation(['incomeSourcesPage']);
  const apolloContext = getApolloContext(i18n);
  const [activate, { loading: activationIsLoading }] =
    useActivateIncomeSourceMutation({
      refetchQueries: [
        {
          query: GetIncomeSourceSectionDataDocument,
          context: apolloContext,
        },
        {
          query: GetIncomeSourceDetailDataDocument,
          variables: {
            id: incomeSource?.id ?? '',
          },
          context: apolloContext,
        },
        {
          query: GetIncomeSourcesDataDocument,
          context: apolloContext,
        },
      ],
    });
  const [deactivate, { loading: deactivationIsLoading }] =
    useDeactivateIncomeSourceMutation({
      refetchQueries: [
        {
          query: GetIncomeSourceSectionDataDocument,
          context: apolloContext,
        },
        {
          query: GetIncomeSourceDetailDataDocument,
          variables: {
            id: incomeSource?.id ?? '',
          },
          context: apolloContext,
        },
        {
          query: GetIncomeSourcesDataDocument,
          context: apolloContext,
        },
      ],
    });

  const handleStatusChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!incomeSource) {
        return;
      }

      const { id } = incomeSource;

      if (event.target.checked) {
        activate({ variables: { id } }).then();
      } else {
        deactivate({ variables: { id } }).then();
      }
    },
    [incomeSource, activate, deactivate]
  );
  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel
        htmlFor="activate"
        mb="0"
        mr={'5'}
        color={'onSurface.highEmphasis'}
        fontSize={'xs'}
        fontWeight={'medium'}
      >
        {t('incomeSourcesPage:text.DuHastDiesesFeatureBereitsAktiviert')}
      </FormLabel>
      <Switch
        id="activate"
        size={'lg'}
        isChecked={!!incomeSource?.activatedAt}
        onChange={handleStatusChange}
        isDisabled={
          !incomeSource || activationIsLoading || deactivationIsLoading
        }
      />
    </FormControl>
  );
}
