import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './assets/i18n/de.json';
import en from './assets/i18n/en.json';
import { LocalStorageKeys } from './types';
import { TranslationKeyStructure } from './types/translationKeyStructure';
import { LocalStorage } from './utils';
import { customTranslationFormatting } from './utils/customTranslationFormatting';

export type TranslationNamespaceStructure = TranslationKeyStructure.Namespaces; //Omit<typeof de, '$schema'>;
export const defaultNS = 'general';
export const resources = {
  en: { ...en },
  de: { ...de },
};

export const defaultLanguage = 'de';
const supportedLanguages = ['de', 'en'];
export const languageQueryKey = 'lang';

/** Get the preferred language stored in the local storage */
const getLocalStorageLanguage = (): string | null => {
  if (typeof localStorage === 'undefined') {
    // skip in node environments
    return null;
  }
  const language = LocalStorage.getString(LocalStorageKeys.LANGUAGE);

  if (language && !supportedLanguages.includes(language)) {
    return null;
  }

  return language;
};

/** Get the language passed as a query parameter */
export const getQueryLanguage = (): string | null => {
  if (typeof window === 'undefined') {
    // skip in node environments
    return null;
  }
  const url = new URL(window.location.href);
  //const queryParams = new URLSearchParams(window.location.search);
  const language: string | null =
    url.searchParams.get(languageQueryKey)?.toLowerCase() ?? null;

  if (language && !supportedLanguages.includes(language)) {
    return null;
  }

  return language;
};

/** Get the preferred language provided by the browser */
export const getBrowserLanguage = (): string | null => {
  if (typeof navigator === 'undefined') {
    // skip in node environments
    return null;
  }
  // Only get the first two letters in case it is e.g. de-DE
  const language = navigator.language.substr(0, 2).toLowerCase();

  return supportedLanguages.includes(language) ? language : null;
};

/**
 * Determine default language `lng` based on
 * 1. searchParams
 * 2. localStoraghe
 * 3. browserLanguage
 * 4. 'de'
 * After login this the language might be changed by the user language through a call to
 * @see setUserLanguage
 */
export const initLanguage = () => {
  if (typeof localStorage === 'undefined') {
    // skip in node environments
    return 'de';
  }
  const lng =
    getQueryLanguage() ??
    getLocalStorageLanguage() ??
    getBrowserLanguage() ??
    'de';
  LocalStorage.add(LocalStorageKeys.LANGUAGE, lng);
  return lng;
};

// DISCLAIMER: i18next's standard formatting does NOT work here. We need to use our custom formatting found in the customTranslationFormatting.ts file in the src/utils folder.
export const i18nConfig: InitOptions = {
  lng: initLanguage(),
  fallbackLng: 'en',
  supportedLngs: supportedLanguages,
  defaultNS,
  resources,
  ns: Object.keys(de),
  interpolation: {
    escapeValue: false,
    format: customTranslationFormatting,
  },
  appendNamespaceToCIMode: true,
  pluralSeparator: '-',
};

i18n.use(initReactI18next).init(i18nConfig);

export const changeLanguage = async (language: string) => {
  await i18n.changeLanguage(language);
  localStorage.setItem(LocalStorageKeys.LANGUAGE, language);
};

export const setUserLanguage = async (language: string) => {
  if (!supportedLanguages.includes(language)) {
    return;
  }
  await changeLanguage(language);
};

export default i18n;
