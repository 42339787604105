import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { TagEntryFragment } from '../../../generated/graphql';
import TagGroup from '../TagGroup/TagGroup';

type ExperienceIntentionTagGroupProps = {
  from: Maybe<{
    experienceTags?: Maybe<Maybe<TagEntryFragment>[]>;
    intentionTags?: Maybe<Maybe<TagEntryFragment>[]>;
  }>;
};
export const ExperienceIntentionTagGroup: React.FC<
  ExperienceIntentionTagGroupProps
> = ({ from }) => (
  <TagGroup
    primary={from?.experienceTags ?? []}
    secondary={from?.intentionTags ?? []}
  />
);
