import { FormikTouched, FormikValues } from 'formik';
import React from 'react';

import { propertiesToBoolean2 } from './useFormikErrorSetter';

export function useFormikInitialProps<
  Values extends FormikValues = FormikValues
>(values: Values) {
  const initialValues = React.useMemo<Values>(() => values, [values]);
  const initialTouched = React.useMemo<FormikTouched<Values>>(
    () => propertiesToBoolean2(values),
    [values]
  );
  return { initialValues, initialTouched };
}
