import { Maybe } from 'graphql/jsutils/Maybe';

import {
  LangText,
  LanguagesEnum,
  ProfileFieldTypeDateFragment,
  ProfileFieldTypeEnumFragment,
  ProfileFieldTypeEnumListFragment,
  ProfileFieldTypeIntFragment,
  ProfileFieldTypeMultilangStringFragment,
  ProfileFieldTypeStringFragment,
  ProfileFieldsEnum,
} from '../generated/graphql';
import { multiLangFieldStrings } from '../utils/utils';

type MultiTextareaData = {
  de: string;
  en: string;
  rest: { [key in string]: string };
};

export const useMultiTextareaData = (
  data: LangText[] | undefined | null,
  name: ProfileFieldsEnum
) => {
  const multiTextareaData: MultiTextareaData = { de: '', en: '', rest: {} };

  (data ?? []).forEach((element) => {
    if (element.lang === 'de') {
      multiTextareaData.de = element.text;
    } else if (element.lang === 'en') {
      multiTextareaData.en = element.text;
    } else {
      multiTextareaData.rest[element.lang] = element.text;
    }
  });

  return {
    data: multiTextareaData,
    names: multiLangFieldStrings(name),
  };
};

export const multiTextareaDataForBackend = (data: MultiTextareaData) => {
  const dataForBackend: { lang: LanguagesEnum; text: string }[] = [];

  Object.keys(data).forEach((key) =>
    key !== 'rest'
      ? dataForBackend.push({
          lang: key as LanguagesEnum,
          text: (data as any)[key],
        })
      : Object.keys(data.rest).map((restKey) =>
          dataForBackend.push({
            lang: restKey as LanguagesEnum,
            text: data.rest[restKey],
          })
        )
  );

  return dataForBackend.filter((entry) => entry.text !== undefined);
};

type ProfileFieldTypeFragmentUnion =
  | ProfileFieldTypeEnumListFragment
  | ProfileFieldTypeEnumFragment
  | ProfileFieldTypeDateFragment
  | ProfileFieldTypeIntFragment
  | ProfileFieldTypeStringFragment
  | ProfileFieldTypeMultilangStringFragment;
type ProfileFieldRecord = Partial<
  Record<ProfileFieldsEnum, Maybe<ProfileFieldTypeFragmentUnion>>
>;

export const multiTextareaDataForBackendV2 = ({
  values,
  fields,
}: {
  values: { key: string; value: string };
  fields: Maybe<ProfileFieldRecord>;
}) => {
  if (!fields) {
    return {};
  }

  const multiLangFieldKeys = Object.entries(fields)
    .filter(
      ([_, value]) => value?.__typename === 'ProfileFieldTypeMultiLangString'
    )
    .map(([key]) => key);

  const dataForBackend: {
    [key: string]: { lang: LanguagesEnum; text: string }[];
  } = {};

  multiLangFieldKeys.forEach((fieldKey) => {
    Object.entries(values)
      .filter(([key]) => key.startsWith(fieldKey))
      .forEach(([key, value]) => {
        const langData = {
          lang: key.replace(`${fieldKey}-`, '') as LanguagesEnum,
          text: value,
        };

        if (fieldKey in dataForBackend) {
          dataForBackend[fieldKey].push(langData);
        } else {
          Object.assign(dataForBackend, {
            [fieldKey]: [langData],
          });
        }
      });
  });

  return dataForBackend;
};
