import { Box, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const VXMChatbotFAQs: React.FC<{
  disableInput: boolean;
  sendFaq: (message: string) => void;
}> = ({ disableInput, sendFaq }) => {
  const { t } = useTranslation(['chatbot']);
  const faqs = [
    // t('chatbot:label.wasSindVXCoins'),
    // t('chatbot:label.wasIstDasSharingSystem'),
    t('chatbot:label.wieSteigeIchAuf'),
    t('chatbot:label.wieSteigereIchDenUmsatz'),
  ];

  return (
    <Box
      style={{
        marginBottom: '10px',
      }}
    >
      <Text
        style={{
          fontSize: '12px',
          fontWeight: '400',
          color: 'darkSteel',
          marginLeft: '3px',
        }}
      >
        {t('chatbot:label.faqVorschlageFurDich')}
      </Text>
      {faqs.map((faq, index) => (
        <Button
          key={index}
          variant={'outline'}
          onClick={
            disableInput
              ? undefined
              : () => {
                  sendFaq(faq);
                }
          }
          style={{
            fontSize: '12px',
            fontWeight: '400',
            padding: '5px',
            margin: '3px',
            borderRadius: '6px',
            height: 'auto',
          }}
          cursor={disableInput ? 'not-allowed' : 'pointer'}
        >
          {faq}
        </Button>
      ))}
    </Box>
  );
};
