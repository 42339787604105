import { AssignmentInd, Description, Error } from '@campoint/odi-ui-icons';
import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  StatusDone,
  StatusError,
  StatusPending,
} from '../../../../../components/shared/StatusIndicator/StatusIndicator';

export type OnboardingTaskState = 'todo' | 'pending' | 'error' | 'done';

type StateColorSet = {
  /**
   * Optional color for text content active state.
   */
  textContentActive?: string;
  /**
   * Optional color for sub text content active state.
   */
  subTextContentActive?: string;
  /**
   * Optional color for text content disabled state.
   */
  textContentDisabled?: string;
  /**
   * Optional color for sub text content disabled state.
   */
  subTextContentDisabled?: string;
  /**
   * Optional color for pressed state.
   */
  pressed?: string;
};

type ColorSet = {
  stateColors?: StateColorSet;
  /**
   * Optional icon colors
   */
  iconColors?: {
    leadingIcon?: string;
    trailingIcon?: string;
  };
  /**
   * Optional border color
   */
  borderColor?: string;
};

const getListItemColorTheme = (state: OnboardingTaskState) => {
  const colorSetTodo: ColorSet = {
    borderColor: 'steel',
  };
  const colorSetDone: ColorSet = {
    borderColor: 'success.highEmphasis',
  };
  const colorSetPending: ColorSet = {
    borderColor: 'warning.highEmphasis',
  };
  const colorSetError: ColorSet = {
    borderColor: 'error.highEmphasis',
  };

  switch (state) {
    case 'todo': {
      return colorSetTodo;
    }
    case 'pending': {
      return colorSetPending;
    }
    case 'error': {
      return colorSetError;
    }
    case 'done': {
      return colorSetDone;
    }
    default: {
      return colorSetTodo;
    }
  }
};

const getListItemLeadingIcon = (
  category: HomeTaskCardItemProps['category']
) => {
  switch (category) {
    case 'age': {
      return AssignmentInd;
    }
    case 'profile': {
      return AssignmentInd;
    }
    case 'modelReleaseForm': {
      return Description;
    }
    default: {
      return undefined;
    }
  }
};

export type HomeTaskCardItemProps = {
  category: 'age' | 'profile' | 'modelReleaseForm';
  state: OnboardingTaskState;
  isClickable?: boolean;
  onClick?: () => void;
  onErrorClick?: () => void;
  text: string;
  active?: boolean;
};

/**
 * @param props.category allowed categories
 * @param props.state allowed states
 * @returns TaskCardItem
 */
const HomeTaskCardItem: React.FC<HomeTaskCardItemProps> = ({
  isClickable = false,
  onClick = () => {
    // initially must be empty
  },
  onErrorClick = () => {},
  text,
  active = true,
  ...props
}) => {
  const { t } = useTranslation(['home', 'general']);

  const getTrailingStateComponent = (
    state: HomeTaskCardItemProps['state'],
    category: HomeTaskCardItemProps['category']
  ) => {
    switch (state) {
      case 'todo':
        const text =
          category !== 'profile'
            ? t('home:cards.tasks.button.Hinzufugen')
            : t('home:cards.tasks.button.Ausfullen');
        return (
          <Button
            variant={'outline'}
            fontSize={'12px'}
            lineHeight={'18px'}
            h={'30px'}
            borderRadius={'8px'}
            onClick={onClick}
            isDisabled={!active}
          >
            {text}
          </Button>
        );

      case 'pending':
        return (
          <StatusPending
            text={t('general:status.InPrufung')}
            stackProps={{ pr: '10px' }}
          />
        );

      case 'error':
        return (
          <Box
            onClick={onErrorClick}
            cursor={!!onErrorClick ? 'pointer' : 'auto'}
          >
            <StatusError
              text={t('general:status.Abgelehnt')}
              icon={Error}
              stackProps={{ pr: '10px' }}
            />
          </Box>
        );

      case 'done':
        return (
          <StatusDone
            text={t('general:status.Akzeptiert')}
            stackProps={{ pr: '10px' }}
          />
        );

      default:
        return <></>;
    }
  };

  const colorSet = getListItemColorTheme(props.state);
  const leadingIcon = getListItemLeadingIcon(props.category);
  const trailingStateComp = getTrailingStateComponent(
    props.state,
    props.category
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      w={['full', 'full', 'full', 'full', 'auto']}
      borderColor={'steel'}
      borderWidth={'1px'}
      minHeight={'62px'}
      borderRadius={'8px'}
      alignItems={'center'}
      px={3}
      {...colorSet}
      color={active ? 'gray.900' : 'gray.200'}
      css={{
        borderColor: active ? colorSet.borderColor : 'gray.200',
      }}
    >
      <HStack gap={0} flex={1}>
        {leadingIcon && <Icon as={leadingIcon} boxSize={'icon.md'} mr={2} />}
        <Text>{text}</Text>
      </HStack>
      {trailingStateComp}
    </Box>
  );
};

export default HomeTaskCardItem;
