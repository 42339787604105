import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { FeatureName, useFeature } from '../../flags';
import { useGetVideoLibraryVideoUploadListEntryQuery } from '../../generated/graphql';
import Logger from '../../utils/Logger';
import { useAuth } from '../AuthProvider';

export const useFetchVideo = (albumId: number, onClose: () => void) => {
  const { t } = useTranslation(['general']);
  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  const { isAuthenticated } = useAuth();
  const skip = !albumId || !videoLibraryFeatureFlag || !isAuthenticated;

  const { data, loading, error } = useGetVideoLibraryVideoUploadListEntryQuery({
    variables: {
      albumId: albumId ?? undefined,
    },
    skip: skip,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
      });
      onClose();
    },
    notifyOnNetworkStatusChange: true,
  });

  const video = useMemo(() => {
    return data?.media?.videos?.videoById;
  }, [data?.media?.videos?.videoById]);

  return { video, loading, error };
};
