import * as React from 'react';
import { useEffect } from 'react';

export const useIntersectionObserver = <Element extends HTMLElement>(
  observer: IntersectionObserver,
  ref: React.MutableRefObject<Element | null>
) => {
  useEffect(() => {
    const element = ref?.current;

    if (element) {
      observer.observe(element);
    }

    // clean up the observer
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [ref, observer]);
};
