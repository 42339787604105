import {
  MonetizationOn,
  Payments,
  StarHalf,
  ThumbUp,
} from '@campoint/odi-ui-icons';
import {
  Box,
  HStack,
  Heading,
  Image,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import avatarPlaceholder from '../../../assets/images/feed/AvatarPlaceholder.svg';
import { FeatureName, useFeature } from '../../../flags';
import { PostTipsFragment, TippingGoalFragment } from '../../../generated/feed';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { useFeedPostStatistics } from '../../../provider/FeedPostStatisticsProvider';
import { useFlagsProviderV2 } from '../../../provider/FlagsProviderV2';
import {
  getFormattedAmount,
  useFormattedAmount,
} from '../../../utils/formattedAmount';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../Layout/ResponsiveModal';
import { AgeIndicator } from '../AgeIndicator/AgeIndicator';
import { ChatWithUserButton } from '../ChatWithUserButton/ChatWithUserButton';
import { FeedPostRef } from '../FeedCommentPostPreview/FeedCommentPostPreview';
import { LoadingState } from '../StatisticModal/LoadingState';
import {
  StatisticOverviewHeader,
  StatisticOverviewHeaderProps,
} from '../StatisticModal/StatisticOverviewHeader';
import { NoInteractionList } from '../StatisticModal/Tabs/EmptyState';
import { LikesTab } from '../StatisticModal/Tabs/LikesTab';
import { SalesTab } from '../StatisticModal/Tabs/SalesTab';

const TipsTab: React.FC<{ tips: PostTipsFragment }> = ({ tips }) => {
  const { t } = useTranslation(['feed']);
  const locale = useIntl().locale;
  const { coinsEnabled } = useFlagsProviderV2();
  if (tips.totalCount === 0) return <NoInteractionList type="Tips" />;
  return (
    <VStack spacing={'4'}>
      {tips.tips.map((tip) => {
        const tipAmount = tip.amountCoins > 0 && coinsEnabled ? `${tip.amountCoins} VXC` : `${getFormattedAmount(tip.amount ?? 0, locale)} €`
        return (
          <Box width={'full'} key={tip.tippedAt + tip.user?.id}>
            <HStack spacing={'2.5'} alignItems={'start'}>
              <Image
                borderRadius={'full'}
                borderColor={'steel'}
                w={'30px'}
                h={'30px'}
                src={tip.user?.avatar?.url || avatarPlaceholder}
              />
              <VStack
                spacing={0}
                lineHeight={'6'}
                flexGrow={1}
                alignItems={'start'}
              >
                <Text
                  color={'coldGray.900'}
                  fontSize={'md'}
                  fontWeight={'medium'}
                >
                  {tip.user?.name}
                </Text>
                <Text
                  color={'coldGray.900'}
                  fontSize={'sm'}
                  fontWeight={'normal'}
                >
                  {t('feed:postStatistic.HatAmountBeigetragen', {
                    amount: tipAmount,
                  })}
                </Text>
                <AgeIndicator created={tip.tippedAt} />
              </VStack>
              {tip.user?.id && <ChatWithUserButton guestUserId={tip.user.id} />}
            </HStack>
          </Box>
        );
      })}
    </VStack>
  );
};

/**
 * Use this component as the new `TipsTab` component and rename it,
 * once the Tipping Goal feature flag was removed and the necessary
 * data is delivered by the backend.
 */
const TippingGoalTipsTab: React.FC<{ tips: PostTipsFragment }> = ({ tips }) => {
  const { t } = useTranslation(['feed']);
  const { coinsEnabled } = useFlagsProviderV2();
  const locale = useIntl().locale;
  if (tips.totalCount === 0) return <NoInteractionList type="Tips" />;
  return (
    <VStack spacing={'4'}>
      {tips.tips.map((tip) => {
        const tipAmount = tip.amountCoins && coinsEnabled
          ? `${tip.amountCoins} VXC`
          : getFormattedAmount(tip.amount ?? 0, locale);
        return (
          <Box width={'full'} key={tip.tippedAt + tip.user?.id}>
            <HStack spacing={'2.5'} alignItems={'start'}>
              <Image
                borderRadius={'full'}
                borderColor={'steel'}
                w={'30px'}
                h={'30px'}
                src={tip.user?.avatar?.url || avatarPlaceholder}
              />
              <VStack
                spacing={0}
                lineHeight={'6'}
                flexGrow={1}
                alignItems={'start'}
              >
                <HStack spacing={0} alignItems={'start'}>
                  {/* TODO:
                      Show this icon only, if the tip contributed to the tipping goal.
                      Needs a flag delivered by the backend, which will be implemented in this ticket: VXM-1736
                  */}
                  {/* <Icon as={StarHalf} color={'primary.500'} /> */}
                  <Text
                    color={'coldGray.900'}
                    fontSize={'md'}
                    fontWeight={'medium'}
                  >
                    {tip.user?.name}
                  </Text>
                </HStack>
                <Text
                  color={'coldGray.900'}
                  fontSize={'sm'}
                  fontWeight={'normal'}
                >
                  {t('feed:postStatistic.HatAmountBeigetragen', {
                    amount: tipAmount,
                  })}
                  {/* TODO:
                      Show this text instead, if the tip contributed to the tipping goal.
                      Needs a flag delivered by the backend, which will be implemented in this ticket: VXM-1736
                  */}
                  {/* {t('feed:postStatistic.HatAmountAufDeinTippingGoalEingezahlt', {
                    amount: getFormattedAmount(tip.amount ?? 0, locale),
                  })} */}
                </Text>
                <AgeIndicator created={tip.tippedAt} />
              </VStack>
              {tip.user?.id && <ChatWithUserButton guestUserId={tip.user.id} />}
            </HStack>
          </Box>
        );
      })}
    </VStack>
  );
};

const TippingGoalCard: React.FC<{
  tippingGoal: TippingGoalFragment | undefined | null;
}> = ({ tippingGoal }) => {
  const { t } = useTranslation(['feed']);
  const { coinsEnabled } = useFlagsProviderV2();
  const currentTippingAmount = coinsEnabled
    ? tippingGoal?.tipsSumCoins
    : tippingGoal?.tipsSum ?? 0;
  const formattedCurrentTippingAmount =
    useFormattedAmount(currentTippingAmount);
  const currentTippingGoalAmountString = coinsEnabled
    ? `${currentTippingAmount} VXC`
    : formattedCurrentTippingAmount;

  const tippingGoalAmount = coinsEnabled
    ? tippingGoal?.goalCoins
    : tippingGoal?.goal ?? 5;
  const formattedTippingGoalAmount = useFormattedAmount(tippingGoalAmount);
  const tippingGoalAmountString = coinsEnabled
    ? `${tippingGoalAmount} VXC`
    : formattedTippingGoalAmount;

  return (
    <StatisticOverviewHeader
      icon={StarHalf}
      text={<Trans t={t} i18nKey="feed:postStatistic.TippingGoalGesamt" />}
    >
      {!tippingGoal?.achieved ? (
        <>
          <Box>
            <Text
              as={'span'}
              fontSize={{ base: 'xl' }}
              fontWeight={'bold'}
              color={'gray.300'}
              children={currentTippingGoalAmountString}
            />
            <Text
              as={'span'}
              fontSize={{ base: 'xl' }}
              fontWeight={'bold'}
              color={'coldGray.900'}
              children={` / ${tippingGoalAmountString}`}
            />
          </Box>
          <Progress
            w={'full'}
            value={currentTippingAmount}
            min={0}
            max={tippingGoalAmount}
          />
        </>
      ) : (
        <Text
          as={'span'}
          fontSize={{ base: 'xl' }}
          fontWeight={'bold'}
          color={'caribbeanGreen.500'}
          children={
            /* todo: change back to tippingGoal?.tipsSum
             * once it represents the sum that contributed
             * to reaching the tipping goal and excludes tips comming after
             */
            tippingGoalAmountString
          }
        />
      )}
    </StatisticOverviewHeader>
  );
};

export const FeedPostStatisticsModal: React.FC = () => {
  const { t } = useTranslation(['feed']);
  const { isOpen, action, post, postLikes, postTips, postSales } =
    useFeedPostStatistics();
  const locale = useIntl().locale;

  const { coinsEnabled } = useFlagsProviderV2();
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);

  const [tabId, setTabId] = useQueryParamState('modalTab');

  //shouldnt be touched -> needed for notification anchor
  const tabIds = React.useMemo(() => {
    return [
      'postLikes',
      'postTipped',
      ...(post?.paidContent ? ['postSales'] : []),
    ];
  }, [post?.paidContent]);

  const tabIndex = React.useMemo(() => {
    if (!tabId) {
      return 0;
    }
    const index = tabIds.indexOf(tabId);
    return index >= 0 ? index : 0;
  }, [tabId, tabIds]);

  const handleTabChange = React.useCallback(
    (index: number) => {
      const newtabId = tabIds[index] ?? tabIds[0];
      setTabId(newtabId);
    },
    [tabIds, setTabId]
  );

  const tabIndexToOverview: Array<
    StatisticOverviewHeaderProps & { count: string }
  > = [
      {
        icon: ThumbUp,
        text: <Trans t={t} i18nKey={'feed:postStatistic.LikesGesamt'} />,
        count:
          post && postLikes.totalCount ? postLikes.totalCount.toString() : '0',
      },
      {
        icon: MonetizationOn,
        text: <Trans t={t} i18nKey={'feed:postStatistic.TippingBetragGesamt'} />,
        count: coinsEnabled
          ? `${post?.tips?.amountCoins ?? 0} VXC`
          : getFormattedAmount(post?.tips?.amount ?? 0, locale),
      },
      {
        icon: Payments,
        text: <Trans t={t} i18nKey={'feed:postStatistic.VerkaufeGesamt'} />,
        count:
          post && postSales.totalCount ? postSales.totalCount.toString() : '0',
      },
    ];

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={() => {
        setTabId(null);
        action.closeModal();
      }}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <Heading
            children={t('feed:heading.Statistiken')}
            size={'md'}
            letterSpacing={'wide'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        {post ? (
          <>
            <FeedPostRef post={post} />
            <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
              <VStack bg={'lightBackground'}>
                <HStack
                  spacing={'2'}
                  m={'4'}
                  minW={'288px'}
                  justifyContent={'center'}
                  alignItems={'stretch'}
                  w={'full'}
                  px={4}
                >
                  {feedTippingGoalFlag ? (
                    <>
                      {tabIndex === 1 && post.tippingGoal && (
                        <TippingGoalCard tippingGoal={post.tippingGoal} />
                      )}
                      {(tabIndex === 0 ||
                        (post.tippingGoal && post.tippingGoal.achieved) ||
                        !post.tippingGoal) && (
                          <StatisticOverviewHeader
                            icon={tabIndexToOverview[tabIndex].icon}
                            text={tabIndexToOverview[tabIndex].text}
                          >
                            <Text
                              fontSize={{ base: 'xl' }}
                              fontWeight={'700'}
                              color={'coldGray.900'}
                            >
                              {tabIndexToOverview[tabIndex].count ?? ''}
                            </Text>
                          </StatisticOverviewHeader>
                        )}
                    </>
                  ) : (
                    <StatisticOverviewHeader
                      icon={tabIndexToOverview[tabIndex].icon}
                      text={tabIndexToOverview[tabIndex].text}
                    >
                      <Text
                        fontSize={{ base: 'lg', md: '2xl' }}
                        fontWeight={'700'}
                        color={'coldGray.900'}
                      >
                        {tabIndexToOverview[tabIndex].count ?? ''}
                      </Text>
                    </StatisticOverviewHeader>
                  )}
                </HStack>
              </VStack>
              <Tabs isFitted isLazy index={tabIndex} onChange={handleTabChange}>
                <TabList bg={'lightBackground'}>
                  <Tab children={t('feed:tabs.Likes')} />
                  <Tab children={t('feed:tabs.Tips')} />
                  {post.paidContent && (
                    <Tab children={t('feed:tabs.Verkaufe')} />
                  )}
                </TabList>
                <TabPanels py={'6'} px={'4'}>
                  <TabPanel
                    p={0}
                    children={
                      <LikesTab
                        likes={postLikes.likes.map((e) => {
                          return {
                            userId: e.user?.id ?? '',
                            username: e.user?.name ?? '',
                            avatarUrl: e.user?.avatar?.url ?? '',
                            likedAt: e.likedAt ?? '',
                          };
                        })}
                      />
                    }
                  />
                  <TabPanel
                    p={0}
                    children={
                      feedTippingGoalFlag ? (
                        <TippingGoalTipsTab tips={postTips} />
                      ) : (
                        <TipsTab tips={postTips} />
                      )
                    }
                  />
                  {post.paidContent && (
                    <TabPanel
                      p={0}
                      children={
                        <SalesTab
                          sales={postSales.sales.map((e) => {
                            return {
                              avatarUrl: e.user?.avatar?.url,
                              soldAt: e.salesAt,
                              userId: e.user?.id,
                              userName: e.user?.name,
                              type: 'POST',
                            };
                          })}
                          showChat={true}
                        />
                      }
                    />
                  )}
                </TabPanels>
              </Tabs>
            </ResponsiveModalBodyBox>
          </>
        ) : (
          <LoadingState />
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
