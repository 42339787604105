import { BoxProps, Flex, VStack, forwardRef } from '@chakra-ui/react';
import * as React from 'react';

import { PageSafeAreaInset, PageSafeAreaInsetProps } from './PageSafeAreaInset';

export const PopoverContainer: React.FC<BoxProps> = (props) => (
  <Flex
    bg={'cold'}
    zIndex={'popover'}
    position={'fixed'}
    top={0}
    bottom={0}
    left={0}
    right={0}
    flexDirection={'column'}
    {...props}
  />
);
const PopoverBox = forwardRef<PageSafeAreaInsetProps, 'div'>((props, ref) => (
  <PageSafeAreaInset
    w={'full'}
    as={VStack}
    spacing={'0'}
    alignItems={'center'}
    ref={ref}
    {...props}
  />
));
export const PopoverContainerTopBox: React.FC<BoxProps> = (props) => (
  <PopoverBox surroundPadding={'xt'} {...props} />
);
export const PopoverContainerMidBox = forwardRef<BoxProps, 'div'>(
  (props, ref) => (
    <PopoverBox surroundPadding={'x'} flexGrow={1} ref={ref} {...props} />
  )
);
export const PopoverContainerBottomBox: React.FC<BoxProps> = (props) => (
  <PopoverBox surroundPadding={'xb'} {...props} />
);
