import { AttachFile, Close, Upload } from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HTMLChakraProps,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  Portal,
  Stack,
  StackProps,
  chakra,
  forwardRef,
  useId,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { ImageCropperProvider } from '../../../provider/ImageCropperProvider';
import { readFile } from '../../../utils/media/imagePickerHelper';
import { CroppedImage, ImageCropper } from '../ImageCropper/ImageCropper';

export type PickedDocument = {
  type: string;
  blob: Blob;
  file?: File;
  size?: {
    width: number;
    height: number;
  };
};

export type DocumentPickerProps = {
  allowOrientationFlip?: boolean;
  enforceTargetImageDimensions?: boolean;
  targetImageDimensions: { width: number; height: number };
  isCircular?: boolean;
  onDocument?: (document: PickedDocument | null) => void;
  onCancelUpload?: () => void;
  label: string;
  isUploading?: boolean;
  uploadingLabel: string;
  filenameLabel?: string;
  errorMessage?: string;
  cropButtonLabel: string;
  cancelCropButtonLabel: string;
  acceptedFileFormat?: string;
  enableCroppingForImageFormat?: string;
  initialFile?: File | null;
  cancelFileUploadAriaLabel?: string;
  clearFileSelectionAriaLabel?: string;
  ariaLabelFlipOrientation?: string;
  ariaLabelRotateImage?: string;
};

const Content: React.FC<StackProps> = (props) => (
  <Stack
    w={'full'}
    h={'full'}
    direction={'row'}
    p={2}
    alignItems={'center'}
    {...props}
  />
);

const Txt = forwardRef<HTMLChakraProps<'span'>, 'span'>((props, ref) => (
  <chakra.span flexGrow={1} textStyle={'caption'} ref={ref} {...props} />
));

const Label = forwardRef<HTMLChakraProps<'label'>, 'label'>((props, ref) => (
  <chakra.label
    display={'flex'}
    w={'full'}
    h={'full'}
    textStyle={'caption'}
    cursor={'pointer'}
    ref={ref}
    {...props}
  />
));

const ContainerBox = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    borderRadius={'md'}
    border={'1px'}
    borderColor={'secondary.lowEmphasis'}
    w={'full'}
    h={16}
    maxH={16}
    color={'secondary.highEmphasis'}
    bg={'secondary.lowEmphasis'}
    ref={ref}
    {...props}
  />
));

const XButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    icon={<Icon as={Close} boxSize="icon.md" />}
    variant="daemon"
    {...props}
  />
);

export const DocumentPicker: React.FC<DocumentPickerProps> = ({
  allowOrientationFlip = false,
  enforceTargetImageDimensions = true,
  targetImageDimensions,
  label,
  uploadingLabel,
  filenameLabel,
  isUploading = false,
  isCircular = false,
  errorMessage = null,
  onDocument,
  onCancelUpload,
  cropButtonLabel = 'Crop',
  cancelCropButtonLabel = 'Cancel',
  acceptedFileFormat = 'application/pdf,image/png,image/jpeg,image/webp',
  enableCroppingForImageFormat = 'image/png,image/jpeg,image/webp',
  initialFile = null,
  cancelFileUploadAriaLabel = 'cancel upload',
  clearFileSelectionAriaLabel = 'clear file selection',
  ariaLabelFlipOrientation = 'Flip crop orientation',
  ariaLabelRotateImage = 'Rotate image counter clockwise 90°',
}) => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);
  const [pickedFile, setPickedFile] = React.useState<File | null>(initialFile);
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);

  const prevPickedFile = usePrevious(pickedFile);

  const enabledCroppingForImageFormats = useMemo(
    () => enableCroppingForImageFormat.split(',').map((f) => f.trim()),
    [enableCroppingForImageFormat]
  );

  const onImageHandler = useCallback(
    async (croppedImage: CroppedImage | undefined | null) => {
      if (croppedImage) {
        onDocument?.(croppedImage);
      } else {
        if (mounted.current && pickedFile !== null) {
          setPickedFile(null);
        }
      }
      if (mounted.current) {
        setImageSrc(null);
      }
    },
    [onDocument, setPickedFile, pickedFile, setImageSrc]
  );

  const onClear = useCallback(() => {
    setPickedFile(null);
  }, [setPickedFile]);

  const onCancelFileUpload = useCallback(async () => {
    if (mounted.current) {
      await onCancelUpload?.();
    }
    if (mounted.current) {
      onClear();
    }
  }, [onCancelUpload, onClear]);

  const onPickedFile = useCallback(
    async (file: File) => {
      // Use cropper for enabled picture formats
      const { type } = file;
      if (enabledCroppingForImageFormats.includes(type)) {
        const imageDataUrl = await readFile(file);
        if (mounted.current && typeof imageDataUrl === 'string') {
          setImageSrc(imageDataUrl);
        }
        return;
      }

      const blob = new Blob([file], { type });
      if (mounted.current) {
        onDocument?.({
          blob,
          type,
        });
      }
    },
    [onDocument, enabledCroppingForImageFormats, setImageSrc]
  );

  useEffect(() => {
    if (pickedFile) {
      onPickedFile(pickedFile).then();
    } else if (prevPickedFile !== undefined && prevPickedFile !== pickedFile) {
      onDocument?.(null);
    }
  }, [onDocument, onPickedFile, prevPickedFile, pickedFile]);

  const onFileChange = useCallback(
    async (e: any) => {
      const [file] = e?.target?.files ?? [];
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (!file) {
        return;
      }
      setPickedFile(file);
    },
    [inputRef]
  );

  const uploadLabelId = useId();

  return (
    <FormControl isInvalid={!!errorMessage}>
      <ContainerBox
        borderColor={
          !errorMessage ? 'secondary.lowEmphasis' : 'error.highEmphasis'
        }
      >
        {!pickedFile && (
          <Label>
            <Content direction={'column'} justify={'center'} spacing={0}>
              <Icon as={Upload} boxSize="icon.md" />
              <Txt id={uploadLabelId}>{label}</Txt>
            </Content>
            <Input
              aria-labelledby={uploadLabelId}
              ref={inputRef}
              type="file"
              onChange={onFileChange}
              accept={acceptedFileFormat}
              display={'none'}
            />
          </Label>
        )}
        {!!pickedFile &&
          (isUploading ? (
            <Content>
              <Txt>{uploadingLabel}</Txt>
              {!!onCancelUpload && (
                <XButton
                  aria-label={cancelFileUploadAriaLabel}
                  onClick={onCancelFileUpload}
                />
              )}
            </Content>
          ) : (
            <Content>
              <Icon as={AttachFile} boxSize="icon.md" />
              <Txt>{pickedFile?.name ?? filenameLabel}</Txt>
              <XButton
                aria-label={clearFileSelectionAriaLabel}
                onClick={onClear}
              />
            </Content>
          ))}
      </ContainerBox>
      {!!errorMessage && (
        <FormErrorMessage>
          <FormErrorIcon />
          {errorMessage}
        </FormErrorMessage>
      )}
      {imageSrc && (
        <Portal>
          <ImageCropperProvider src={imageSrc} onImage={onImageHandler}>
            <ImageCropper />
          </ImageCropperProvider>
        </Portal>
      )}
    </FormControl>
  );
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
