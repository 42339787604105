import React from 'react';

export type MultiSelectOption = { label: string; value: string };

export const MultiSelectOptionList: React.FC<{
  options: MultiSelectOption[];
}> = ({ options }) => {
  const multiSelectOptions = options.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

  return <>{multiSelectOptions}</>;
};
