import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Phone } from '@campoint/odi-ui-icons';
import { Button, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  StatusDone,
  StatusError,
} from '../../../../components/shared/StatusIndicator/StatusIndicator';
import {
  MobileNumberSectionDocument,
  useMobileNumberSectionNumberUpdateMutation,
  useMobileNumberSectionNumberVerifyMutation,
  useMobileNumberSectionQuery,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { extractFromUnion } from '../../../../utils/extractor';
import {
  isMutationResultError,
  mutationResultErrorHandling,
} from '../../../../utils/utils';
import LoadingPage from '../../../LoadingPage/LoadingPage';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const fieldName = {
  currentMobileNumber: 'currentMobileNumber',
  verificationCode: 'mobileNumberVerification',
} as const;

interface FormValues {
  [fieldName.currentMobileNumber]: string;
  [fieldName.verificationCode]: string;
}

export const MobileNumberSection: React.FC<{}> = () => {
  const { t } = useTranslation(['accountPage', 'general']);

  const { data, loading, error } = useMobileNumberSectionQuery();

  const mappedData = React.useMemo(() => {
    const fields = extractFromUnion(
      data?.profile?.collection,
      'ProfileCollectionAccountV1'
    )?.fields;

    const currentMobileNumber =
      data?.account.contact.currentMobileNumber?.fullPhoneNumber;
    const isNumberVerified =
      data?.account.contact.isCurrentMobileNumberVerified ?? false;
    return {
      currentMobileNumber,
      isNumberVerified,
      fields,
    };
  }, [data]);

  //Mutations
  const [saveNumber, { loading: saveNumberLoading }] =
    useMobileNumberSectionNumberUpdateMutation({
      refetchQueries: [MobileNumberSectionDocument],
    });

  const [verifyNumber] = useMobileNumberSectionNumberVerifyMutation({
    refetchQueries: [MobileNumberSectionDocument],
  });

  // Disable verification field if currently input number is verified
  React.useEffect(() => {
    mappedData.isNumberVerified
      ? setIsVerificationFieldDisabled(true)
      : setIsVerificationFieldDisabled(false);
  }, [mappedData]);

  // Initialize state for verification field
  const [isVerificationFieldDisabled, setIsVerificationFieldDisabled] =
    React.useState(true);

  const initialValues = React.useMemo<FormValues>(() => {
    return {
      [fieldName.currentMobileNumber]: mappedData.currentMobileNumber ?? '',
      [fieldName.verificationCode]: '',
    };
  }, [mappedData]);

  const getMinLengthValidation = React.useCallback(
    (minLength: number | null | undefined) => {
      if (!minLength) return Yup.string();
      return Yup.string().min(
        minLength,
        t('accountPage:error.minLength', { minLength })
      );
    },
    [t]
  );

  const getMaxLengthValidation = React.useCallback(
    (maxLength: number | null | undefined) => {
      if (!maxLength) return Yup.string();
      return Yup.string().nullable(true).max(
        maxLength,
        t('accountPage:error.maxLength', {
          maxLength,
        })
      );
    },
    [t]
  );

  const numberValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [fieldName.currentMobileNumber]: Yup.string()
          .matches(phoneRegExp, t('accountPage:error.format'))
          .concat(
            getMinLengthValidation(
              mappedData.fields?.accountContactMobilePhone?.minLength
            )
          )
          .concat(
            getMaxLengthValidation(
              mappedData.fields?.accountContactMobilePhone?.maxLength
            )
          )
          .nullable()
          .transform((value) => (value === '' ? null : value)),
        [fieldName.verificationCode]: Yup.string()
          .concat(getMinLengthValidation(6))
          .concat(getMaxLengthValidation(6))
          .nullable()
          .transform((value) => (value === '' ? null : value)),
      }),
    [getMaxLengthValidation, getMinLengthValidation, mappedData, t]
  );

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(numberValidationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const mobilePhoneFormValue = useWatch({
    name: fieldName.currentMobileNumber,
    control: hookForm.control,
  });

  const verificationCodeFormValue = useWatch({
    name: fieldName.verificationCode,
    control: hookForm.control,
  });

  const isValueEqualToCurrentNumber =
    mobilePhoneFormValue === mappedData.currentMobileNumber;

  const trySaveNumber = React.useCallback(
    async (data: any) => {
      hookForm.setFocus(fieldName.verificationCode);
      try {
        const res = await saveNumber({
          variables: {
            mobileNumber: data[fieldName.currentMobileNumber],
          },
        });

        if (
          isMutationResultError(
            res.data?.account?.changePhoneNumberAndSendVerificationCode
          )
        ) {
          mutationResultErrorHandling(
            res.data?.account?.changePhoneNumberAndSendVerificationCode
          );
          setIsVerificationFieldDisabled(true);
          return;
        }

        issueChakraToast({
          description: t('accountPage:toast.CoderPerSmsGesendet'),
          status: 'success',
        });
      } catch (error) {
        return issueChakraToast({
          description: t(
            'accountPage:toast.HandynummerKonnteNichtGeandertWerden'
          ),
          status: 'error',
        });
      }
    },
    [hookForm, saveNumber, t]
  );

  const tryVerifyNumber = React.useCallback(async () => {
    try {
      const res = await verifyNumber({
        variables: {
          mobileNumber: hookForm.getValues(fieldName.currentMobileNumber),
          verificationCode: hookForm.getValues(fieldName.verificationCode),
        },
      });

      if (isMutationResultError(res.data?.account?.verifyPhoneNumber)) {
        hookForm.resetField(fieldName.verificationCode);
        mutationResultErrorHandling(res.data?.account?.verifyPhoneNumber);
        return;
      }

      hookForm.reset({ mobileNumberVerification: '' });

      issueChakraToast({
        description: t('accountPage:toast.HandynummerBestätigt'),
        status: 'success',
      });
      setIsVerificationFieldDisabled(true);
    } catch (error) {
      hookForm.resetField(fieldName.verificationCode);
      issueChakraToast({
        description: t(
          'accountPage:toast.HandynummerKonnteNichtVerifiziertWerden'
        ),
        status: 'error',
      });
    }
  }, [hookForm, t, verifyNumber]);

  React.useEffect(() => {
    if ((verificationCodeFormValue?.length ?? 0) === 6) {
      tryVerifyNumber();
    }
  }, [tryVerifyNumber, verificationCodeFormValue]);

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const disableVerification =
    (!hookForm.formState.isDirty && mappedData.isNumberVerified) ||
    !mobilePhoneFormValue ||
    !!verificationCodeFormValue;

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);
  React.useEffect(() => {
    return registerDirtyFlag(hookForm.formState.isDirty, onOmitChanges);
  }, [
    hookForm,
    hookForm.formState,
    hookForm.formState.isDirty,
    onOmitChanges,
    registerDirtyFlag,
  ]);

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.MobileNumberSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Phone} />
            <SectionTitle children={t('accountPage:headline.Handynummer')} />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form
          onSubmit={(e) => {
            setIsVerificationFieldDisabled(false);
            hookForm.handleSubmit(trySaveNumber)(e);
          }}
        >
          <SectionBody>
            <SectionCenterContainer>
              {(loading || error) && <LoadingPage />}
              {!loading && (
                <VStack spacing={6}>
                  <ClearableInputControl
                    name={fieldName.currentMobileNumber}
                    label={t('accountPage:label.Handynummer')}
                    labelTrailingHint={
                      isValueEqualToCurrentNumber ? (
                        mappedData.isNumberVerified ? (
                          <StatusDone text={t('general:status.Verifiziert')} />
                        ) : (
                          <StatusError
                            text={t('general:status.NichtVerifiziert')}
                          />
                        )
                      ) : null
                    }
                    placeholder={t(
                      'accountPage:placeholder.HandynummerEingeben'
                    )}
                    isReadOnly={
                      mappedData.fields?.accountContactMobilePhone?.readOnly
                    }
                    type="tel"
                    autoComplete="tel"
                  />
                  <ClearableInputControl
                    name={fieldName.verificationCode}
                    label={t('accountPage:label.Bestatigungscode')}
                    text={
                      isVerificationFieldDisabled
                        ? t(
                            `accountPage:text.ErhalteEinenCodePerSMSZurVerifizierungDeinerHandynummer`
                          )
                        : t(
                            `accountPage:text.WirHabenEinenBestatigungscodeAnDeineHandynummerGeschickt`
                          )
                    }
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="[0-9]*"
                    isReadOnly={isVerificationFieldDisabled}
                    placeholder={t('accountPage:placeholder.CodeEingeben')}
                    readOnlyPlaceholder={t(
                      'accountPage:placeholder.HandynummerErforderlich'
                    )}
                  />

                  <Button
                    alignSelf={'center'}
                    children={t('accountPage:button.CoderPerSMSAnfordern')}
                    variant={'solid'}
                    type="submit"
                    isDisabled={disableVerification}
                    isLoading={saveNumberLoading}
                  />
                </VStack>
              )}
            </SectionCenterContainer>
          </SectionBody>
        </form>
      </FormProvider>
    </Section>
  );
};
