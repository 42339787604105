import * as icons from '@campoint/odi-ui-icons';
import { Box, Divider, HStack, Heading, Icon, Text } from '@chakra-ui/react';
import { t } from 'i18next';

import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import { ContestTileBase } from './ContestTileBase';

const Tip = ({ icon, text }: { icon: any; text: any }) => (
  <HStack>
    <Box
      style={{
        padding: '7px',
        borderRadius: '50%',
        borderWidth: '1px',
        borderColor: 'gray.300',
      }}
    >
      <Icon as={icon} boxSize="icon.md" color={'gray.900'} />
    </Box>
    <Text>{text}</Text>
  </HStack>
);

export const ContestTileEndedParticipatedTips: typeof ContestTileBase = (
  props
) => {
  if (props.contest.type === MediaContestTypeEnum.Photo) {
    return (
      <ContestTileBase textAlign={'left'} {...props}>
        <Box p={3} lineHeight={1.2}>
          <Heading size="sm" mb={8}>
            {t('contest:heading.WieBekommeIchMehrLikes')}
          </Heading>
          <Tip
            icon={icons.Movie}
            text={t('contest:text.ErstelleEinTeaserclipVorDemVotingzeitraum')}
          />
          <Divider my={6} color={'steel'} />
          <Tip
            icon={icons.Schedule}
            text={t('contest:text.MachVorDemVotingAufDeineTeilnahmeAufmerksam')}
          />
          <Divider my={6} color={'steel'} />
          <Tip
            icon={icons.Forum}
            text={t('contest:text.AntworteAufDieKommentareDeinerFans')}
          />
        </Box>
      </ContestTileBase>
    );
  }
  return (
    <ContestTileBase textAlign={'left'} {...props}>
      <Box p={3} lineHeight={1.2}>
        <Heading size="sm" mb={8}>
          {t('contest:heading.WieBekommeIchMehrLikes')}
        </Heading>
        <Tip
          icon={icons.Groups}
          text={t('contest:text.PosteFeedbeitrageFurDeineCommunity')}
        />
        <Divider my={6} color={'steel'} />
        <Tip
          icon={icons.LightBulbOutlined}
          text={t('contest:text.BeachteDasMottoUndDasDuImFokusBist')}
        />
        <Divider my={6} color={'steel'} />
        <Tip
          icon={icons.AddPhoto}
          text={t('contest:text.ErhoheDeineChanceMitSpannendenVorschaubildern')}
        />
      </Box>
    </ContestTileBase>
  );
};
