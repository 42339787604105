import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';

import { NotificationFragment } from '../../../../generated/notifications';
import { RelativeBox } from '../../../Layout/RelativeBox/RelativeBox';
import { DateDisplay } from '../../DateDisplay/DateDisplay';
import { CornerGuideDot } from '../../GuideDot/GuideDot';
import { getIconAndColor } from '../utils';

export type NotificationItemProps = {
  notification: NotificationFragment;
};

export const NotificationItem: React.FC<NotificationItemProps> = (
  props: NotificationItemProps
) => {
  const { title, updatedAt, readAt, picture, shortInfo, icon } =
    props.notification;

  const { iconType, color } = getIconAndColor(icon?.src);
  return (
    <Flex
      w="full"
      h="full"
      direction="row"
      p={4}
      overflow={'hidden'}
      textOverflow={'ellipsis'}
      gap={2}
    >
      <Flex w="full" direction="column" align="start" justify="center" gap={2}>
        <Flex
          direction="row"
          w={'full'}
          justify={'start'}
          align={'center'}
          fontSize={'sm'}
        >
          {iconType && color && (
            <Icon as={iconType} color={color} boxSize={4} mr={'6px'} />
          )}
          <Text
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            noOfLines={1}
            size={'sm'}
            color={'darkSteel'}
          >
            {shortInfo && (
              <>
                {shortInfo} <span> | </span>{' '}
              </>
            )}
            <DateDisplay date={updatedAt} format={'dd.MM.yy'} />
          </Text>
        </Flex>
        {title && (
          <Text
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            noOfLines={2}
            alignSelf={'start'}
            size={'sm'}
            color={'coldGray.9000'}
          >
            {title}
          </Text>
        )}
      </Flex>
      {picture && (
        <Box
          h={'auto'}
          maxH={'60px'}
          w={'48px'}
          bgImage={picture?.src ?? ''}
          aria-label={picture.alt ?? ''}
          bgSize={'cover'}
          bgPosition={'center'}
          mr={2}
        />
      )}
      <RelativeBox
        attachment={<CornerGuideDot isShown={readAt == null} top={'0'} />}
        w={'min-content'}
        flex={1}
      />
    </Flex>
  );
};
