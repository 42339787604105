import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ResponsiveModalProps } from '../../../Layout/ResponsiveModal';
import { DialogModal } from '../../DialogModal/DialogModal';

export type ConfirmCommentEditChangesDialogProps = {
  navigateBack: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const ConfirmCommentEditChangesDialog: React.FC<
  ConfirmCommentEditChangesDialogProps
> = ({ navigateBack, onClose, ...rest }) => {
  const { t } = useTranslation(['general']);
  const leastDestructiveRef = useRef(null);
  return (
    <DialogModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      headline={t('general:heading.AnderungenVerwerfen')}
      confirmButtonText={t('general:button.Verwerfen')}
      onConfirm={() => {
        navigateBack();
        onClose();
      }}
      closeButtonText={t('general:button.Abbrechen')}
      onCloseComplete={onClose}
      {...rest}
    />
  );
};
