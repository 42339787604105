// Base on https://github.com/kgnugur/formik-chakra-ui/blob/master/src/form-checkbox/checkbox-container.tsx
import { Checkbox, CheckboxProps, forwardRef } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';

import { BaseProps, FormControl } from '../../FormControl';
import {
  UseCheckboxGroupControlReturn,
  useCheckboxGroupControl,
} from './use-checkboxGroupControl';

const [CheckboxGroupControlProvider, useCheckboxGroupControlContext] =
  createContext<UseCheckboxGroupControlReturn>({
    strict: true,
    name: 'CheckboxGroupControlContext',
    errorMessage:
      'checkboxGroupControlContext: `context` is undefined. Seems you forgot to wrap CheckboxGroupControl components in `<CheckboxGroupControlContainer />`',
  });

/* -------------------------------------------------------------------------------------------------
 * Checkbox group control container
 * -----------------------------------------------------------------------------------------------*/

export type CheckboxGroupControlContainerProps = BaseProps & {
  children: ReactNode;
};

export const CheckboxGroupControlContainer: FC<
  CheckboxGroupControlContainerProps
> = (props: CheckboxGroupControlContainerProps) => {
  const { name, label, children, ...rest } = props;

  const context = useCheckboxGroupControl({ name });

  return (
    <CheckboxGroupControlProvider value={context}>
      <FormControl name={name} label={label} {...rest}>
        {children}
      </FormControl>
    </CheckboxGroupControlProvider>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Checkbox group control
 * -----------------------------------------------------------------------------------------------*/

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type CheckboxGroupControlProps = Omit<
  Overwrite<CheckboxProps, { value: string | number }> & { label?: string },
  'name'
>;

export const CheckboxGroupControl = forwardRef<
  CheckboxGroupControlProps,
  'input'
>((props, ref) => {
  const { checkboxGroupName } = useCheckboxGroupControlContext();
  const { label, children, ...rest } = props;
  const [field, { error, touched }] = useField(checkboxGroupName);

  let isChecked;
  if (field.value instanceof Array) {
    isChecked = field.value.includes(props.value) ?? false;
  }

  return (
    <Checkbox
      {...field}
      isInvalid={!!error && touched}
      isChecked={isChecked}
      ref={ref}
      {...rest}
    >
      {label}
      {children}
    </Checkbox>
  );
});
