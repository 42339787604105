import { useFormikContext } from 'formik';
import React from 'react';
import { useWatch } from 'react-hook-form';

import { MultiTextareaV2TabProps } from '../../../../components/shared/FormElements/MultiTextareaV2/MultiTextareaV2';
import { InlineGuideDot } from '../../../../components/shared/GuideDot/GuideDot';
import { useModelProfile } from '../../provider/ModelProfileProvider';

export const LabelTextWithGuideDot: React.FC<{
  name: string;
  children?: React.ReactNode;
}> = ({ children, name }) => {
  const { isGuideDotVisible } = useModelProfile();
  const formik = useFormikContext<Record<string, string | []>>();

  const value = formik?.values?.[name];
  const valueIsEmpty = Array.isArray(value) ? value.length <= 0 : !value;
  return (
    <>
      {children}
      <InlineGuideDot ml={1} isShown={isGuideDotVisible && valueIsEmpty} />
    </>
  );
};

export const LabelTextWithGuideDotHookForm: React.FC<{
  name: string;
  children?: React.ReactNode;
}> = ({ children, name }) => {
  const { isGuideDotVisible } = useModelProfile();

  const value = useWatch({ name });
  const valueIsEmpty = Array.isArray(value) ? value.length <= 0 : !value;
  return (
    <>
      {children}
      <InlineGuideDot ml={1} isShown={isGuideDotVisible && valueIsEmpty} />
    </>
  );
};

export const MultiLangLabelTextWithGuideDot: React.FC<{
  names: { de: string; en: string };
  children?: React.ReactNode;
}> = ({ children, names }) => {
  const { isGuideDotVisible } = useModelProfile();
  const formik = useFormikContext<Record<string, string | []>>();

  const valueDe = formik.values[names.de];
  const valueEn = formik.values[names.en];
  const valueIsEmpty = !valueDe && !valueEn;
  return (
    <>
      {children}
      <InlineGuideDot ml={1} isShown={isGuideDotVisible && valueIsEmpty} />
    </>
  );
};

export const MultiLangLabelTextWithGuideDotV2: React.FC<{
  fields: MultiTextareaV2TabProps[];
  children?: React.ReactNode;
}> = ({ children, fields }) => {
  const { isGuideDotVisible } = useModelProfile();
  const formik = useFormikContext<Record<string, string | []>>();
  const valueIsEmpty = fields.every((test) => formik.values[test.name] === '');

  return (
    <>
      {children}
      <InlineGuideDot ml={1} isShown={isGuideDotVisible && valueIsEmpty} />
    </>
  );
};
