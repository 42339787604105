import React from 'react';

import { useGetFeatureFlagsV2Query } from '../generated/graphql';
import { createContext } from '../hooks/useContext';

interface FlagsProviderV2Context {
  coinsEnabled: boolean;
}

export const [, useFlagsProviderV2, flagsProviderV2Ctx] =
  createContext<FlagsProviderV2Context>();

export const FlagsProviderV2Provider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { data } = useGetFeatureFlagsV2Query();

  const coinsEnabled = React.useMemo(() => {
    return data?.app.featureFlags?.coinsEnabled ?? false;
  }, [data]);

  const context: FlagsProviderV2Context = React.useMemo(() => {
    return {
      coinsEnabled,
    };
  }, [coinsEnabled]);
  return <flagsProviderV2Ctx.Provider value={context} children={children} />;
};
