import { useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import { CommentCreate } from '../../../components/shared/FeedComment/CommentCreate';
import { CommentFromUser } from '../../../components/shared/FeedComment/CommentFromUser';
import { ConversationBox } from '../../../components/shared/FeedComment/FeedConversation';
import {
  CommentReplyList,
  itemVariants,
  listVariants,
} from '../../../components/shared/FeedComment/shared';
import { MediaCommentFragment } from '../../../generated/graphql';
import { useAuth } from '../../../provider/AuthProvider';
import Logger from '../../../utils/Logger';
import { CommentFromOtherUser } from './CommentFromOtherUser';

export const VideoLibraryCommentConversation: React.FC<{
  comment: MediaCommentFragment;
  addReply: (text: string, commentId: number) => Promise<any>;
  registerOpenComment: () => void;
}> = ({ comment, addReply, registerOpenComment }) => {
  const { authUser } = useAuth();
  const replyTo = useDisclosure();
  const { id, avatarUrl, userName, text, date, reply } = comment;
  return (
    <ConversationBox>
      <CommentFromOtherUser
        key={id}
        avatarSrc={avatarUrl ?? ''}
        username={userName ?? ''}
        text={text}
        created={date}
        hasOpenReplyTo={replyTo.isOpen || !!reply}
        onReplyTo={replyTo.onOpen}
      />
      <CommentReplyList>
        {replyTo.isOpen && !reply && (
          <CommentCreate
            avatarSrc={''}
            username={authUser?.username}
            repliesToCommentId={id.toString()}
            onClose={replyTo.onClose}
            onSubmit={async (values, formikHelpers) => {
              const text = values.text;
              try {
                const result = await addReply(text, comment.id);
                if (result.errors) {
                  Logger.error(result.errors);
                } else {
                  replyTo.onClose();
                }
              } catch (error) {
                Logger.error(error);
                formikHelpers.setSubmitting(false);
              }
            }}
            registerOpenComment={registerOpenComment}
          />
        )}
        <AnimatePresence initial={true}>
          <motion.div
            key={id + 'replylist'}
            initial="inactive"
            animate="active"
            variants={listVariants}
            exit="exit"
          >
            <AnimatePresence initial={true}>
              {reply && (
                <motion.div
                  variants={itemVariants}
                  key={reply.id + 'motion-div'}
                >
                  <CommentFromUser
                    avatarSrc={''}
                    username={authUser?.username ?? ''}
                    text={reply.text}
                    created={reply.date}
                    onEdit={() => {}}
                    commentId={reply.id.toString()}
                    openConfirmDeletionDialog={() => {}}
                    isCommentEditable={false}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </AnimatePresence>
      </CommentReplyList>
    </ConversationBox>
  );
};
