import {
  OdiCard,
  OdiCardBody,
  OdiCardDescription,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardTitle,
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { AccountBalance, AttachFile } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import {
  DocumentPicker,
  PickedDocument,
} from '../../../../components/shared/DocumentPicker/DocumentPicker';
import { HelpLink } from '../../../../components/shared/HelpLink/HelpLink';
import {
  ProfileFieldsEnum,
  ProfileStatusEnum,
  UploadDocument,
  useGetUploadInfoForFieldLazyQuery,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { useRegionNames } from '../../../../hooks/useRegionNames';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { MediaPropertiesProvider } from '../../../../provider/MediaPropertiesProvider';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { externalRoutes } from '../../../../routes/routesConfig';
import { uploadDocument } from '../../../../utils/media';
import { PayoutPendingAlert } from '../../components/alert/PayoutPendingAlert/PayoutPendingAlert';
import { PayoutRejectAlert } from '../../components/alert/PayoutRejectAlert/PayoutRejectAlert';

export type PayoutAddressSectionProps = {
  payoutAddress?: Maybe<{
    status: Maybe<ProfileStatusEnum>;
    rejectReason: Maybe<string>;
  }>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  city: Maybe<string>;
  company: Maybe<string>;
  country: Maybe<string>;
  houseNumber: Maybe<string>;
  street: Maybe<string>;
  zip: Maybe<string>;
  document: Maybe<Partial<UploadDocument>>;
  isCompany: Maybe<boolean>;
};

const ReadOnlyInputControl: React.FC<{
  label: React.ReactNode;
  value?: Maybe<string>;
}> = ({ label, value }) => {
  return (
    <FormControl isReadOnly>
      <FormLabel children={label} mb={3} />
      <Input value={value ?? ''} mb={6} />
    </FormControl>
  );
};

const PayoutAddressDocumentPendingAlert = () => {
  const { t } = useTranslation(['payout']);
  const lang = useActiveApiLanguage();

  return (
    <PayoutPendingAlert
      alertDescription={t('payout:text.DeineNeueRechnungsadresseWirdUberpruft')}
      modalHeading={t('payout:headline.DeineNeueRechnungsadresseWirdUberpruft')}
      modalTextSlot={
        <Text>
          {t('payout:text.WirMeldenUnsBeiDirSobaldEsNeuigkeitenGibt')}
        </Text>
      }
      modalHelpLinkSlot={
        <HelpLink
          // todo: Link propper Zendesk article
          href={externalRoutes.vxModelsZendeskOverview(lang)}
        >
          {t('payout:text.WieLangeDauertDieUberprufungMeinerRechnungsadresse')}
        </HelpLink>
      }
    />
  );
};

const PayoutAddressDocumentRejectAlert: React.FC<{
  rejectReason?: Maybe<string>;
}> = ({ rejectReason }) => {
  const { t } = useTranslation(['payout']);
  const lang = useActiveApiLanguage();

  return (
    <PayoutRejectAlert
      alertDescription={t(
        'payout:text.DeineNeueRechnungsadresseWurdeAbgelehnt'
      )}
      modalHeading={t('payout:headline.DeinAdressnachweisWurdeAbgelehnt')}
      modalTextSlot={
        <Text>{t('payout:text.GrundReason', { reason: rejectReason })}</Text>
      }
      modalHelpLinkSlot={
        <HelpLink
          // todo: Link propper Zendesk article
          href={externalRoutes.vxModelsZendeskOverview(lang)}
        >
          {t('payout:text.WiesoWurdeMeineRechnungsadresseAbgelehnt')}
        </HelpLink>
      }
      modalFooterSlot={(onClose) => (
        <Button
          children={t('payout:button.ErneutHochladen')}
          onClick={onClose}
        />
      )}
    />
  );
};

const PayoutAddressSection: React.FC = () => {
  const { t } = useTranslation(['payout', 'wizardPayout', 'imagePicker']);
  const lang = useActiveApiLanguage();

  const regionNames = useRegionNames();

  const financeServiceCtx = useFinanceService();
  const {
    values,
    isPayoutAddressUpdateEditAllowed,
    action: { updatePayoutCollection },
  } = financeServiceCtx;

  const {
    onOmitChangesTriggerCount,
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const [pickedDocument, setPickedDocument] =
    React.useState<PickedDocument | null>(null);

  const onOmitChanges = React.useCallback(() => {
    setPickedDocument(null);
  }, [setPickedDocument]);

  const isDirty = !!pickedDocument;

  React.useEffect(() => {
    return registerDirtyFlag(isDirty, onOmitChanges);
  }, [isDirty, registerDirtyFlag, onOmitChanges]);

  const [getUploadInfo] = useGetUploadInfoForFieldLazyQuery({
    variables: {
      name: ProfileFieldsEnum.PayoutAddressDocument,
    },
  });

  const [isProcessing, setIsProcessing] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const submit = React.useCallback(async () => {
    try {
      if (!pickedDocument) {
        return;
      }
      setIsProcessing(true);
      const res = await getUploadInfo();

      if (
        res.data?.profile?.field?.__typename !==
        'ProfileFieldTypeUploadDocument'
      ) {
        return;
      }
      const uploadUrl = res.data?.profile?.field.upload?.url;
      if (!uploadUrl) {
        return;
      }

      const uploadResponse = await uploadDocument(
        uploadUrl,
        pickedDocument.blob
      );

      const docId = uploadResponse.id;
      if (!docId) {
        return;
      }

      const errors = await updatePayoutCollection({
        [ProfileFieldsEnum.PayoutAddressDocument]: docId,
      });

      if (!errors) {
        setPickedDocument(null);
      }
    } finally {
      setIsProcessing(false);
    }
  }, [
    getUploadInfo,
    pickedDocument,
    setPickedDocument,
    updatePayoutCollection,
  ]);

  const isRejected = financeServiceCtx.isPayoutAddressUpdateRejected;
  const isPending =
    !isRejected && financeServiceCtx.isPayoutAddressUpdatePending;

  const isReadOnly = !isPayoutAddressUpdateEditAllowed || isPending;

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={AccountBalance} />
            <SectionTitle children={t('payout:headline.Rechnungsadresse')} />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          <VStack spacing={4}>
            <>
              {isPending && <PayoutAddressDocumentPendingAlert />}
              {isRejected && (
                <PayoutAddressDocumentRejectAlert
                  rejectReason={
                    financeServiceCtx.payoutAddressUpdateRejectReason
                  }
                />
              )}
            </>
            <OdiCard borderRadius={'2xl'}>
              <OdiCardHeader>
                <OdiCardTitle>
                  {t('payout:headline.RechnungsadresseAndern')}
                </OdiCardTitle>
                <OdiCardDescription>
                  {t(
                    'payout:text.LadeEinenAktuellenAdressnachweisHochNachDerPrufungAndernWirDeineAngaXX'
                  )}
                </OdiCardDescription>
              </OdiCardHeader>
              <OdiCardBody>
                {!isReadOnly ? (
                  <MediaPropertiesProvider
                    targetDimensions={{ width: 520, height: 520 }}
                    determineAspectRatioByInput={true}
                  >
                    <DocumentPicker
                      // Force remount to clear internal state onOmitChanges
                      // https://stackoverflow.com/a/48451229
                      key={onOmitChangesTriggerCount}
                      onDocument={setPickedDocument}
                      targetImageDimensions={{ width: 520, height: 520 }}
                      label={t('wizardPayout:label.AdressnachweisHochladen')}
                      uploadingLabel={t(
                        'wizardPayout:text.DokumentWirdHochgeladen'
                      )}
                      cropButtonLabel={t('imagePicker:crop')}
                      cancelCropButtonLabel={t('imagePicker:cancel')}
                      isUploading={isProcessing}
                    />
                  </MediaPropertiesProvider>
                ) : (
                  <Box
                    w={'full'}
                    p={2}
                    border={'1px'}
                    borderRadius={'md'}
                    borderColor={'gray.500'}
                  >
                    <HStack minH={'10'}>
                      <Icon as={AttachFile} boxSize={'icon.md'} />
                      <chakra.span flexGrow={1}>
                        {t('payout:text.Adressnachweis')}
                      </chakra.span>
                    </HStack>
                  </Box>
                )}
              </OdiCardBody>
              <OdiCardFooter>
                <VStack w={'full'}>
                  <HelpLink
                    alignSelf={'start'}
                    // todo: Link propper Zendesk article
                    href={externalRoutes.vxModelsZendeskOverview(lang)}
                  >
                    {t('payout:text.WasIstEinAdressnachweis')}
                  </HelpLink>
                  {!pickedDocument ? (
                    <></>
                  ) : (
                    <>
                      <Button
                        alignSelf={{ base: 'stretch', md: 'center' }}
                        isLoading={isProcessing}
                        isDisabled={!pickedDocument || isPending}
                        onClick={onOpen}
                      >
                        {t('payout:button.AdressnachweisEinreichen')}
                      </Button>
                      <ResponsiveModal isOpen={isOpen} onClose={onClose}>
                        <ResponsiveModalOverlay />
                        <ResponsiveModalContent>
                          <ResponsiveModalStickyHeaderBox>
                            <Heading size={'xl'}>
                              {t(
                                'payout:headline.MochtestDuDeineAnderungenZurUberprufungEinreichen'
                              )}
                            </Heading>
                          </ResponsiveModalStickyHeaderBox>
                          <ResponsiveModalBodyBox>
                            <Text>
                              {t(
                                'payout:text.WennDuFortfahrstStartetEinUberprufungsprozessDerEtwasZeitInAnspruchNXX'
                              )}
                            </Text>
                            <Text>
                              {t(
                                'payout:text.DeineAltenAngabenBleibenErhaltenSolangeDeineAnderungenNichtAkzeptierXX'
                              )}
                            </Text>
                          </ResponsiveModalBodyBox>
                          <ResponsiveModalStickyFooterBox>
                            <VStack>
                              <Button
                                onClick={() => {
                                  onClose();
                                  submit();
                                }}
                              >
                                {t('payout:button.SpeichernEinreichen')}
                              </Button>
                              <Button onClick={onClose} flexShrink={1}>
                                {t(
                                  'payout:button.AnderungenNochEinmalKontrollieren'
                                )}
                              </Button>
                            </VStack>
                          </ResponsiveModalStickyFooterBox>
                        </ResponsiveModalContent>
                      </ResponsiveModal>
                    </>
                  )}
                </VStack>
              </OdiCardFooter>
            </OdiCard>
          </VStack>
          <VStack spacing={4} divider={<Divider />} alignItems={'stretch'}>
            {values.payoutAddressIsCompany ? (
              <ReadOnlyInputControl
                label={t('payout:label.Firmenname')}
                value={values.payoutAddressCompanyname}
              />
            ) : (
              <VStack spacing={4} divider={<Divider />}>
                <ReadOnlyInputControl
                  label={t('payout:label.Vorname')}
                  value={values.payoutAddressFirstname}
                />
                <ReadOnlyInputControl
                  label={t('payout:label.Nachname')}
                  value={values.payoutAddressLastname}
                />
              </VStack>
            )}

            <ReadOnlyInputControl
              label={t('payout:label.Strasse')}
              value={values.payoutAddressStreet}
            />
            <ReadOnlyInputControl
              label={t('payout:label.Hausnummer')}
              value={values.payoutAddressStreetNumber}
            />
            <ReadOnlyInputControl
              label={t('payout:label.Postleitzahl')}
              value={values.payoutAddressPostalCode}
            />
            <ReadOnlyInputControl
              label={t('payout:label.Stadt')}
              value={values.payoutAddressCity}
            />
            <ReadOnlyInputControl
              label={t('payout:label.Land')}
              value={
                !values.payoutAddressCountry
                  ? values.payoutAddressCountry
                  : regionNames.of(values.payoutAddressCountry) ??
                    values.payoutAddressCountry
              }
            />
          </VStack>
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

export default PayoutAddressSection;
