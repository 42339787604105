const roundToHundredPercent = (object: Record<string, number>) => {
  const total = Object.values(object).reduce((sum, value) => sum + value, 0);
  const percentages = Object.entries(object).map(
    ([key, value], index, array) => {
      const percentage = (value / total) * 100;
      if (total === 0) return 0;
      return index === array.length - 1
        ? 100 -
            array
              .slice(0, -1)
              .reduce((sum, [, val]) => sum + (val / total) * 100, 0)
        : Math.round(percentage * 100) / 100;
    }
  );
  return percentages;
};

export default roundToHundredPercent;
