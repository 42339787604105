import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { Countdown } from '../../../../../components/shared/Countdown';
import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import { ContestIcon, ContestTileBase } from './ContestTileBase';

export const ContestTileUploadVoteStartsIn: typeof ContestTileBase = (
  props
) => {
  return (
    <ContestTileBase
      fontSize={'14px'}
      color={'white'}
      {...props}
      contestBackground={props.contest.images.payturSignupImage}
      contestBackgroundGradient={true}
    >
      <Box p={'10px'} textAlign={'left'} width={'inherit'}>
        <ContestIcon contest={props.contest} />
        {props.contest.type === MediaContestTypeEnum.Photo &&
          props.contest.photoalbum?.pictureCountTotal +
            '/' +
            props.contest.uploadLimit +
            ' ' +
            t('contest:label.PhotoEingereicht')}
      </Box>
      <VStack flex="1 0" justifyContent={'center'} mb={8}>
        <Text
          textAlign={'center'}
          textTransform={'uppercase'}
          fontWeight={500}
          fontSize={'16px'}
          mb={4}
        >
          {t('contest:heading.UserVotingStartetIn')}
        </Text>
        <Countdown
          time={DateTime.fromISO(props.contest.uploadEnd)}
          fontSize={'16px'}
        />
        {props.contest.type === MediaContestTypeEnum.Video && (
          <Button
            colorScheme="gray"
            color={'white'}
            mt={10}
            onClick={props.goToMedia}
          >
            {t('contest:button.ZumVideo', {
              type: t('contest:label.Video'),
            })}
          </Button>
        )}
      </VStack>
    </ContestTileBase>
  );
};
