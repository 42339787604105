import {
  Section,
  SectionBody,
  SectionButton,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { TipsAndUpdates } from '@campoint/odi-ui-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  HSnapScrollContainer,
  SnapScrollContainerEnd,
} from '../../../../components/Layout/HSnapScrollContainer';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import {
  UrlFragment,
  UrlFragmentScrollToTarget,
} from '../../../../components/Layout/UrlFragmentScrollToTarget';
import ExperienceIntentionImageCard from '../../../../components/shared/ExperienceIntentionImageCard/ExperienceIntentionImageCard';
import {
  TipEntryFragment,
  useGetTipSectionDataQuery,
} from '../../../../generated/graphql';
import {
  TipDetailModalProvider,
  useTipDetailModal,
} from '../../../../provider/TipDetailModalProvider';
import { routes } from '../../../../routes/routesConfig';
import { extractDefinedEntries } from '../../../../utils/utils';
import { TipsStatusIndicator } from '../../../TipsPage/TipsPage';

export const TipCard: React.FC<{ entry: TipEntryFragment }> = ({ entry }) => {
  const tipDetailModalCtx = useTipDetailModal();
  return (
    <ExperienceIntentionImageCard
      key={entry.id}
      entry={entry}
      id={`tip-image-card-${entry.id}`}
      onClick={() => {
        tipDetailModalCtx.action.showTip(entry.id);
      }}
      sx={{
        scrollSnapAlign: 'center',
        verticalAlign: 'top',
      }}
      statusIndicator={entry.readAt && <TipsStatusIndicator />}
    />
  );
};

export const TipSection: React.FC = () => {
  const { t } = useTranslation(['home', 'general']);
  const history = useHistory();

  const { data } = useGetTipSectionDataQuery({
    fetchPolicy: 'cache-first',
  });

  const tipEntries: TipEntryFragment[] = extractDefinedEntries(
    data?.helpcenter?.tips?.entries
  );

  return (
    <TipDetailModalProvider>
      <Section>
        <SectionHeader>
          <SectionCenterContainer>
            <UrlFragmentScrollToTarget id={UrlFragment.Tips} />
            <SectionTitleRow>
              <SectionIcon as={TipsAndUpdates} />
              <SectionTitle>{t('home:heading.Tipps')}</SectionTitle>
              <SectionButton
                children={t('general:button.all')}
                onClick={() => {
                  history.push(routes.tips.path);
                }}
              />
            </SectionTitleRow>
            <SectionDescription>
              {t('home:text.MitUnserenTippsStartestDuReibungslosDurch')}
            </SectionDescription>
          </SectionCenterContainer>
        </SectionHeader>
        <SectionBody px={0}>
          <SectionCenterContainer>
            <HSnapScrollContainer>
              {tipEntries.map((entry) => (
                <TipCard key={entry.id} entry={entry} />
              ))}
              <SnapScrollContainerEnd />
            </HSnapScrollContainer>
          </SectionCenterContainer>
        </SectionBody>
      </Section>
    </TipDetailModalProvider>
  );
};
