import {
  Batch,
  BatchItem,
  UPLOADER_EVENTS,
  UploaderEnhancer,
} from '@rpldy/uploady';

import Logger from '../../../utils/Logger';

export const tusVideoUploadLogEnhancer: UploaderEnhancer = (uploader) => {
  uploader.on(UPLOADER_EVENTS.BATCH_ADD, (batch: Batch) => {
    Logger.log({ ev: UPLOADER_EVENTS.BATCH_ADD, batch });
  });

  uploader.on(UPLOADER_EVENTS.ITEM_START, (item: BatchItem) => {
    Logger.log({ ev: UPLOADER_EVENTS.ITEM_START, item });
  });

  uploader.on(UPLOADER_EVENTS.ITEM_ABORT, (item: BatchItem) => {
    Logger.log({ ev: UPLOADER_EVENTS.ITEM_ABORT, item });
  });

  uploader.on(UPLOADER_EVENTS.ITEM_ERROR, (item: BatchItem) => {
    Logger.log({ ev: UPLOADER_EVENTS.ITEM_ERROR, item });
  });

  // uploader.on(UPLOADER_EVENTS.ITEM_PROGRESS, (item: BatchItem) => {
  //   Logger.log({ ev: UPLOADER_EVENTS.ITEM_PROGRESS, item });
  // });

  uploader.on(UPLOADER_EVENTS.ITEM_FINISH, (item: BatchItem) => {
    Logger.log({ ev: UPLOADER_EVENTS.ITEM_FINISH, item });
  });

  uploader.on(UPLOADER_EVENTS.BATCH_FINISH, (batch: Batch) => {
    Logger.log({ ev: UPLOADER_EVENTS.BATCH_FINISH, batch });
  });

  uploader.on(UPLOADER_EVENTS.REQUEST_PRE_SEND, ({ items }) => {
    Logger.log({ ev: UPLOADER_EVENTS.REQUEST_PRE_SEND, items });
  });

  return uploader;
};
