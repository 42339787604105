import * as icons from '@campoint/odi-ui-icons';
import {
  HStack,
  Icon,
  ListItem,
  ListItemProps,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileStatusEnum } from '../../../../../generated/graphql';

interface RealPersonListItemStatusProps {
  status?: Maybe<ProfileStatusEnum>;
}

interface RealPersonListItemStatusIndicatorProps extends StackProps {
  icon: EmotionIcon;
}

const RealPersonListItemStatusIndicator: React.FC<
  RealPersonListItemStatusIndicatorProps
> = ({ icon, children, ...stackProps }) => (
  <HStack
    fontWeight={'500'}
    fontSize={'12px'}
    lineHeight={'20px'}
    as={'span'}
    alignItems={'center'}
    spacing={'1'}
    {...stackProps}
  >
    <Icon as={icon} boxSize={'15px'} />
    <span>{children}</span>
  </HStack>
);

const RealPersonListItemStatus: React.FC<RealPersonListItemStatusProps> = ({
  status,
}) => {
  const { t } = useTranslation(['general']);

  switch (status) {
    case ProfileStatusEnum.Pending:
      return (
        <RealPersonListItemStatusIndicator
          textColor={'warning.500'}
          icon={icons.Schedule}
        >
          {t('general:status.InPrufung')}
        </RealPersonListItemStatusIndicator>
      );
    case ProfileStatusEnum.Rejected:
      return (
        <RealPersonListItemStatusIndicator
          textColor={'error.500'}
          icon={icons.Error}
        >
          {t('general:status.Abgelehnt')}
        </RealPersonListItemStatusIndicator>
      );

    case ProfileStatusEnum.Incomplete:
      return (
        <RealPersonListItemStatusIndicator
          textColor={'error.500'}
          icon={icons.Error}
        >
          {t('general:status.Unvollstandig')}
        </RealPersonListItemStatusIndicator>
      );
    case ProfileStatusEnum.Accepted:
      return (
        <RealPersonListItemStatusIndicator
          textColor={'lime.500'}
          icon={icons.DoneAll}
        >
          {t('general:status.Akzeptiert')}
        </RealPersonListItemStatusIndicator>
      );
    default:
      return null;
  }
};

export interface RealPersonListItemProps extends ListItemProps {
  name?: Maybe<string>;
  status?: Maybe<ProfileStatusEnum>;
  description?: Maybe<string>;
}

export const RealPersonListItem: React.FC<RealPersonListItemProps> = ({
  name,
  status,
  description,
  ...listItemProps
}) => {
  return (
    <ListItem
      p={4}
      // dirty workaround to have line item content align with container
      // But still have padding for the background color on _hover, _active etc.
      mx={-4}
      bg={'surface'}
      cursor={'pointer'}
      _hover={{
        bg: 'coldGray.50',
      }}
      _focus={{
        bg: 'coldGray.100',
      }}
      _active={{
        bg: 'coldGray.100',
      }}
      _disabled={{
        opacity: 0.4,
        cursor: 'not-allowed',
      }}
      {...listItemProps}
    >
      <HStack alignItems={'start'} width={'full'}>
        <Icon as={icons.AccountCircle} boxSize={'icon.md'} />
        <VStack flexGrow={1} alignItems={'stretch'} overflow={'hidden'}>
          <HStack fontSize={'md'}>
            <Text
              as={'span'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              whiteSpace={'nowrap'}
            >
              {name}
            </Text>
            <RealPersonListItemStatus status={status} />
          </HStack>
          <Text color={'gray.500'} fontSize={'sm'}>
            {description}
          </Text>
        </VStack>
        <Icon as={icons.ChevronRight} boxSize={'icon.md'} />
      </HStack>
    </ListItem>
  );
};
