import { CallMade } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ProfileFieldTypePictureFragment,
  StatisticsEntryFragment,
  useGetProfileHeaderStatisticsQuery,
} from '../../../generated/graphql';
import i18n from '../../../i18n';
import { useAccountStatus } from '../../../provider/AccountStatusProvider/AccountStatusProvider';
import { externalRoutes } from '../../../routes/routesConfig';
import { windowOpen } from '../../../utils/utils';
import { ProfilePicture } from './components/ProfilePicture/ProfilePicture';
import { TitlePicture } from './components/TitlePicture/TitlePicture';

export const ProfileHeaderGoToVXId = 'visit-x-profile-button';

export type ProfileHeaderProps = {
  modelPictureTitleLandscape:
    | ProfileFieldTypePictureFragment
    | undefined
    | null;
  isEditable?: boolean;
  modelPictureFSK16Avatar: ProfileFieldTypePictureFragment | undefined | null;
  username: string;
};

/**
 * This component is needed for the model profile and the feed.
 * @constructor
 */
const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  modelPictureTitleLandscape,
  modelPictureFSK16Avatar,
  username,
  isEditable = true,
}) => {
  const { t } = useTranslation(['modelProfile']);

  const { data } = useGetProfileHeaderStatisticsQuery();

  const statistics = React.useMemo(() => {
    return data?.statistics.entries.map(
      (entry: StatisticsEntryFragment) => entry.values[0]?.value
    );
  }, [data?.statistics.entries]);

  const completeness = Number(statistics?.at(1) ?? 0) / 100;

  const navigateToProfileVisitX = React.useCallback(() => {
    windowOpen(
      externalRoutes.vxModelsProfile(i18n.language, username),
      '_blank'
    );
  }, [username]);

  const { isModelVerified } = useAccountStatus();

  return (
    <Container maxW={'full'} p={0} bgColor={'surface'}>
      <TitlePicture
        field={modelPictureTitleLandscape}
        isEditable={isEditable}
      />
      <Flex
        w={'full'}
        flexWrap={'wrap'}
        flexDirection={'column'}
        alignItems={'start'}
      >
        <HStack
          maxW={'full'}
          mt={{ base: '-50px', lg: '-75px' }}
          ml={{ base: '4', lg: '12' }}
          alignItems={'flex-end'}
          gap={'7'}
          flexGrow={1}
        >
          <Box flexShrink={0}>
            <ProfilePicture
              field={modelPictureFSK16Avatar}
              isEditable={isEditable}
            />
          </Box>
          <Flex overflowX={'hidden'} flexGrow={1}>
            <Heading fontSize={'2xl'} noOfLines={1} wordBreak={'break-word'}>
              {username}
            </Heading>
          </Flex>
        </HStack>
        <Container minH={{ base: '124px', lg: '68px' }} minW={'full'} p={0}>
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={'stretch'}
            justifyContent={'stretch'}
            gap={0}
            ml={{ base: '0', lg: '200' }}
          >
            <HStack
              minW={{ base: 'full', lg: '320px' }}
              minH={'68px'}
              justifyContent={'space-evenly'}
              divider={
                <Divider
                  orientation={'vertical'}
                  border={'1px'}
                  h={'36px'}
                  color={'steel'}
                />
              }
            >
              {statistics && (
                <>
                  <ProfileHeaderInfoTiles
                    value={statistics[2] ?? '0'}
                    name={t('modelProfile:headerSection.Beitrage')}
                  />
                  <ProfileHeaderInfoTiles
                    value={statistics[0] ?? '0'}
                    name={t('modelProfile:headerSection.Follower')}
                  />
                  <ProfileHeaderInfoTiles
                    value={t('modelProfile:headerSection.ModelprofilPercent', {
                      percent: completeness,
                    })}
                    name={t('modelProfile:headerSection.Modelprofil')}
                  />
                </>
              )}
            </HStack>
            {isModelVerified && (
              <HStack
                minH={'56px'}
                w={'full'}
                justifyContent={{ base: 'center', lg: 'end' }}
                pr={{ base: '0', lg: '48px' }}
              >
                <Button
                  variant={'link'}
                  lineHeight={'24px'}
                  rightIcon={<Icon as={CallMade} />}
                  onClick={navigateToProfileVisitX}
                >
                  {t('modelProfile:headerSection.VisitXProfilAnsehen')}
                </Button>
              </HStack>
            )}
          </Flex>
        </Container>
      </Flex>
    </Container>
  );
};

export default ProfileHeader;

const ProfileHeaderInfoTiles: React.FC<{
  value: string | undefined;
  name: string;
}> = ({ value, name }) => {
  return (
    <VStack gap={0}>
      <Text as={Heading} fontSize={'md'} lineHeight={'20px'}>
        {value}
      </Text>
      <Text fontSize={'12px'} lineHeight={'14px'} fontWeight={'light'}>
        {name}
      </Text>
    </VStack>
  );
};
