import { useTranslation } from 'react-i18next';

import VXFeedEmptyStateImage from '../../../../../../../assets/images/shared/VX-Feed-Empty-State.svg';
import { BlockerCard } from '../../../../../../../components/shared/BlockerCard/BlockerCard';
import { useCreateFeedPostContext } from '../../../../../../../provider/FeedPostCreateModalProvider';

type NoPostsBlockedCardType = {
  openUspModal: () => void;
};

export const NoPostsBlockedCard: React.FC<NoPostsBlockedCardType> = ({
  openUspModal,
}) => {
  const { t } = useTranslation(['feed']);
  const feedPostCreateCtx = useCreateFeedPostContext();

  return (
    <BlockerCard
      isBordered
      image={VXFeedEmptyStateImage}
      heading={t('feed:noPostsBlockerCard.heading')}
      text={t('feed:noPostsBlockerCard.text')}
      primaryButtonText={t('feed:noPostsBlockerCard.button.ErfahreMehr')}
      onClickPrimary={openUspModal}
      secondaryButtonText={t('feed:noPostsBlockerCard.button.ProbiereEsAus')}
      onClickSecondary={feedPostCreateCtx.action.openModal}
    />
  );
};
