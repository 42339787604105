import { HStack, Image, Spacer, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AgeIndicator } from '../AgeIndicator/AgeIndicator';
import {
  MenuModal,
  MenuModalContent,
  MenuModalItem,
  MenuModalItemList,
  MenuModalOverlay,
} from '../FeedPostMenu/FeedPostMenu';
import { CommentHighlightMentions } from './CommentHighlightMentions';
import {
  CommentAvatar,
  CommentBox,
  CommentContent,
  CommentFooter,
  CommentReplyToActionLink,
  CommentText,
  CommentUsername,
  FeedActionMenuIconButton,
} from './shared';
import goldDashCoin from '../../../assets/images/feed/GoldDashCoin.svg';

export type FeedCommentFromOtherUserProps = {
  avatarSrc?: string;
  username: string;
  text?: string;
  created?: string;
  hasOpenReplyTo?: boolean;
  commentId: string;
  onReplyTo: () => void;
  openConfirmDeletionDialog: (commentId: string) => void;
  tipped: boolean;
};

/**
 * Display comments not created by the viewing user
 * So it can be
 * * replied to
 * * reported
 */
export const FeedCommentFromOtherUser: React.FC<
  FeedCommentFromOtherUserProps
> = ({
  avatarSrc,
  username,
  text = '',
  created = '',
  hasOpenReplyTo = false,
  commentId,
  onReplyTo,
  openConfirmDeletionDialog,
  tipped
}) => {
  const actionMenu = useDisclosure();
  const actionMenuTriggerButtonRef = React.useRef(null);
  const { t } = useTranslation(['feed']);
  return (
    <CommentBox>
      <CommentAvatar src={avatarSrc} />
      <CommentContent>
        <HStack>
          <CommentUsername children={username} />
          {tipped && <Image src={goldDashCoin} fit={'cover'} alt={"GoldCoin"} w={"18px"} h={"18px"} mb={"2px"}/>}
        </HStack>
        <CommentText>
          <CommentHighlightMentions children={text} />
        </CommentText>
        <CommentFooter>
          <AgeIndicator created={created} />
          {!hasOpenReplyTo && <CommentReplyToActionLink onClick={onReplyTo} />}
          <Spacer />
          <FeedActionMenuIconButton
            onClick={actionMenu.onOpen}
            ref={actionMenuTriggerButtonRef}
          />
        </CommentFooter>
      </CommentContent>
      <MenuModal
        isOpen={actionMenu.isOpen}
        onClose={actionMenu.onClose}
        finalFocusRef={actionMenuTriggerButtonRef}
      >
        <MenuModalOverlay />
        <MenuModalContent>
          <MenuModalItemList>
            <MenuModalItem
              onClick={() => {
                actionMenu.onClose();
                openConfirmDeletionDialog(commentId);
              }}
              color={'error.500'}
            >
              {t('feed:menuItem.KommentarLoschen')}
            </MenuModalItem>
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
    </CommentBox>
  );
};
