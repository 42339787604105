import {
  Section,
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { DocumentPickerV3 } from '../../../../components/shared/DocumentPickerV3/DocumentPickerV3';
import { DocumentPickerV3Status } from '../../../../components/shared/DocumentPickerV3/DocumentPickerV3Status';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { FormControlHeaderStack } from '../../../../components/shared/FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { PromotingOptionList } from '../../../../components/shared/FormikFormElements';
import { HelpLink } from '../../../../components/shared/HelpLink/HelpLink';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { RadioGroupHookControl } from '../../../../components/shared/HookFormForms/RadioGroupHookCrontrol/RadioGroupHookControl';
import {
  StatusDone,
  StatusPending,
} from '../../../../components/shared/StatusIndicator/StatusIndicator';
import { TipBanner } from '../../../../components/shared/cards/TipBanner/TipBanner';
import {
  GetPayoutDocumentsTaxSectionDocument,
  InputPaymentTaxData,
  TaxCountryEnum,
  useGetPayoutDocumentsTaxSectionQuery,
  useUpdatePayoutDocumentsTaxSectionMutation,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { useExpiresInDays } from '../../../../hooks/useExpiresInDays';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';
import { MediaFlowProvider } from '../../../../provider/MediaFlowProvider';
import { MediaInputProvider } from '../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../provider/MediaProvider';
import { MediaUploadProvider } from '../../../../provider/MediaUploadProvider';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { externalRoutes } from '../../../../routes/routesConfig';
import Logger from '../../../../utils/Logger';
import { createStringValidationSchema } from '../../../../utils/validation';
import { getFieldInitialErrors } from '../../../ModelProfileV2Page/ModelProfilePage/utils';
import { DocumentMediaFlow } from '../components/DocumentMediaFlow/DocumentMediaFlow';
import { SubmitChangesButtonWithConfirmation } from '../components/SubmitChangesButtonWithConfirmation/SubmitChangesButtonWithConfirmation';
import { PaymentExpiredAlert } from '../components/alert/PaymentExpiredAlert/PaymentExpiredAlert';
import { PaymentRejectedAlert } from '../components/alert/PaymentRejectedAlert/PaymentRejectedAlert';

const fieldName = {
  isTaxable: 'paymentTaxDataIsTaxable',
  taxNumber: 'paymentTaxDataTaxNumber',
  tin: 'paymentTaxDataTin',
  tinCountry: 'paymentTaxDataTinCountry',
  document: 'paymentTaxDataDocument',
} as const;

interface FormValues {
  [fieldName.isTaxable]: 'yes' | 'no';
  [fieldName.taxNumber]: string;
  [fieldName.tin]: string;
  [fieldName.tinCountry]: TaxCountryEnum | '';
  [fieldName.document]: number | null;
}

const PayoutDocumentsTaxSection: React.FC = () => {
  const { t } = useTranslation([
    'general',
    'imagePicker',
    'payout',
    'wizardPayout',
    'validation',
    'document',
  ]);
  const { coinsEnabled } = useFlagsProviderV2();
  const lang = useActiveApiLanguage();

  const issueSuccesToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.AnderungenWurdenGespeichert'),
      status: 'success',
    });
  }, [t]);

  const issueErrorToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      status: 'error',
    });
  }, [t]);

  const {
    onOmitChangesTriggerCount,
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const { data, loading } = useGetPayoutDocumentsTaxSectionQuery();
  const [updatePayoutDocumentsTaxSection] =
    useUpdatePayoutDocumentsTaxSectionMutation({
      refetchQueries: [GetPayoutDocumentsTaxSectionDocument],
    });

  const showTin = React.useMemo(() => {
    return data?.payment?.taxData?.showTin ?? false;
  }, [data]);

  const showDocument = data?.payment?.taxData?.isDocumentRequired ?? false;

  const documentExpiration = useExpiresInDays({
    expireAt: data?.payment?.taxData?.document?.value?.expireDate,
  });

  const initialValues = React.useMemo<FormValues>(() => {
    const fields = data?.payment?.taxData;
    return {
      [fieldName.isTaxable]: fields?.isTaxable?.value ? 'yes' : 'no',
      [fieldName.taxNumber]: fields?.taxNumber?.value ?? '',
      [fieldName.document]: fields?.document?.value?.docId ?? null,
      [fieldName.tin]: fields?.tin?.value ?? '',
      [fieldName.tinCountry]:
        ((fields?.tinCountry?.value ?? '') as TaxCountryEnum) || '',
    };
  }, [data]);

  const validationSchema = React.useMemo(() => {
    const fields = data?.payment?.taxData;

    return Yup.object().shape({
      [fieldName.taxNumber]: createStringValidationSchema({
        ...fields?.taxNumber,
      }).when(fieldName.isTaxable, (isTaxable, prevSchema) => {
        return isTaxable !== 'yes' ? Yup.string() : prevSchema;
      }),
      [fieldName.tin]: createStringValidationSchema({
        ...fields?.tin,
      }).when(fieldName.isTaxable, (isTaxableValue, prevSchema) => {
        if (showTin || isTaxableValue !== 'yes') {
          return prevSchema;
        }
        return Yup.string();
      }),
      [fieldName.tinCountry]: createStringValidationSchema({}).when(
        fieldName.isTaxable,
        (isTaxableValue, prevSchema) => {
          if (showTin || isTaxableValue !== 'yes') {
            return prevSchema;
          }
          return Yup.string();
        }
      ),
    });
  }, [data, showTin]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const currentIsTaxableValue =
    useWatch({ name: fieldName.isTaxable, control: hookForm.control }) ===
    'yes';

  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);

  const isFormDirty = currentIsTaxableValue
    ? !!hookForm.formState.dirtyFields[fieldName.isTaxable] ||
      !!hookForm.formState.dirtyFields[fieldName.taxNumber] ||
      !!hookForm.formState.dirtyFields[fieldName.document] ||
      (showTin &&
        (!!hookForm.formState.dirtyFields[fieldName.tin] ||
          !!hookForm.formState.dirtyFields[fieldName.tinCountry]))
    : !!hookForm.formState.dirtyFields[fieldName.isTaxable] ||
      !!hookForm.formState.dirtyFields[fieldName.tin] ||
      !!hookForm.formState.dirtyFields[fieldName.tinCountry];

  React.useEffect(() => {
    return registerDirtyFlag(isFormDirty, onOmitChanges);
  }, [isFormDirty, onOmitChanges, registerDirtyFlag]);

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const onInvalidSubmit: SubmitErrorHandler<FormValues> = (errors) => {
    Logger.error(errors);
  };

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const isTaxableValue = data[fieldName.isTaxable] === 'yes';

        const tinPart: Partial<InputPaymentTaxData> =
          !showTin && isTaxableValue
            ? {}
            : {
                tin: data[fieldName.tin],
                tinCountry: data[fieldName.tinCountry] as TaxCountryEnum,
              };

        const documentPart: Partial<InputPaymentTaxData> = !showDocument
          ? {}
          : {
              document: data[fieldName.document],
            };

        const result = await updatePayoutDocumentsTaxSection({
          variables: {
            data: isTaxableValue
              ? {
                  isTaxable: true,
                  taxNumber: data[fieldName.taxNumber],
                  ...tinPart,
                  ...documentPart,
                }
              : {
                  isTaxable: false,
                  ...tinPart,
                },
          },
        });

        const errors = getFieldInitialErrors(
          (result?.data?.payment?.taxData?.updateTaxData as any) ?? null,
          'unkown error'
        );

        if (!!errors) {
          Object.entries(errors).forEach(([key, entry]) => {
            hookForm.setError(key as any, {
              type: 'manual',
              message: entry as any,
            });
          });
          issueErrorToast();
        } else {
          issueSuccesToast();
        }
      } catch (error) {
        Logger.error(error);
        issueErrorToast();
      }
    },
    [
      updatePayoutDocumentsTaxSection,
      hookForm,
      showTin,
      showDocument,
      issueSuccesToast,
      issueErrorToast,
    ]
  );

  const validValues = data?.payment?.taxData?.tinCountry?.validValues;
  const quickpickValues = data?.payment?.taxData?.tinCountry?.quickpickValues;
  const optionListProps = useMemo(
    () => ({
      options: validValues ?? [],
      groupLabel: t('general:optgroup.Lander'),
      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
      placeholder: t('payout:placeholder.AusstellungslandAuswahlen'),
      promotedValues: (quickpickValues ?? []).map(({ value }) => value),
    }),
    [t, validValues, quickpickValues]
  );

  const overallStatus = React.useMemo<string | undefined>(() => {
    if (isFormDirty) {
      return 'dirty';
    }
    const statusIsOk = data?.payment?.taxData?.status === 'accepted';
    if (statusIsOk && documentExpiration.isExpired) {
      return 'expired';
    }
    if (statusIsOk && documentExpiration.isExpiring) {
      return 'expiring';
    }
    return data?.payment?.taxData?.status ?? undefined;
  }, [data, documentExpiration, isFormDirty]);

  const isDirtyButMissingDocument =
    isFormDirty &&
    !!hookForm.formState.dirtyFields[fieldName.taxNumber] &&
    !hookForm.formState.dirtyFields[fieldName.document] &&
    !!currentIsTaxableValue &&
    !!showDocument;
  const currentDocIdValue = hookForm.watch(fieldName.document);
  const [lastUploadedFile, setLastUploadedFile] = React.useState<null | {
    id: string | number;
    filename?: string;
  }>(null);

  const uploadedFilename = React.useMemo(() => {
    if (
      lastUploadedFile?.id !== currentDocIdValue ||
      initialValues[fieldName.document] === currentDocIdValue
    ) {
      return null;
    }
    return lastUploadedFile?.filename ?? null;
  }, [lastUploadedFile, currentDocIdValue, initialValues]);

  const [uploadError, setUploadError] = React.useState<string | null>(null);

  //the status can be okay but the tax id needs to be submitted again
  const { isPayoutTaxDocumentExpiringSoon, isPayoutTaxDocumentExpired } =
    useFinanceService();
  const allowedToReSubmit =
    isPayoutTaxDocumentExpiringSoon || isPayoutTaxDocumentExpired;

  const isSubmittingDisabled =
    (!isFormDirty ||
      !hookForm.formState.isValid ||
      !!isDirtyButMissingDocument) &&
    !allowedToReSubmit;

  if (loading || !data?.payment?.taxData?.isRequired) {
    return null;
  }

  const statusPending = overallStatus === 'pending';
  const isDisabled = loading || statusPending;

  const statusIndicator = statusPending ? (
    <StatusPending text={t('general:status.InPrufung')} />
  ) : overallStatus === 'accepted' ? (
    <StatusDone text={t('general:status.Verifiziert')} />
  ) : null;

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.TaxEditSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <HStack w={'full'}>
              <SectionIcon as={icons.GppMaybe} />
              <SectionTitle>
                {t('payout:headline.SteuerlicheDaten')}
              </SectionTitle>
              {statusIndicator}
            </HStack>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}>
          <SectionBody>
            <SectionCenterContainer spacing={4}>
              {!coinsEnabled && overallStatus === 'rejected' && (
                <PaymentRejectedAlert
                  // || use intentional, as title can come as "",
                  // which should also fallback to predefined generic title
                  text={data?.payment?.taxData?.rejectReasonTitle || undefined}
                  noticeModalConfig={{
                    status: 'error',
                    // || use intentional, as title can come as "",
                    // which should also fallback to predefined generic title
                    modalHeading:
                      data?.payment?.taxData?.rejectReasonTitle ||
                      t(
                        'payout:text.DeineAngabenZurSteuerlichenEinordnungWurdenAbgelehnt'
                      ),
                    modalTextSlot: t('payout:text.GrundReason', {
                      reason: data?.payment?.taxData?.rejectReason ?? '',
                    }),
                  }}
                />
              )}
              {!coinsEnabled && overallStatus === 'expired' && (
                <PaymentExpiredAlert
                  noticeModalConfig={{
                    status: 'error',
                    modalHeading: t('payout:headline.NeuerNachweisNotwendig'),
                    modalTextSlot: t(
                      'payout:text.BitteAktualisiereDeinUmsatzsteuernachweisDieserIstVorDaysExpiredTageXX',
                      {
                        daysExpired:
                          (documentExpiration?.daysTillExpired ?? 0) * -1,
                      }
                    ),
                  }}
                />
              )}
              <VStack spacing={6} divider={<Divider />} alignItems={'stretch'}>
                {!coinsEnabled && (
                  <VStack spacing={6} alignItems={'stretch'}>
                    <RadioGroupHookControl
                      name={fieldName.isTaxable}
                      options={[
                        {
                          isDisabled,
                          children: t('payout:radio.IchBinKleinunternehmer'),
                          value: 'no',
                        },
                        {
                          isDisabled,
                          children: t(
                            'payout:radio.IchBinUmsatzsteuerpflichtig'
                          ),
                          value: 'yes',
                        },
                      ]}
                    />
                    {currentIsTaxableValue ? (
                      <HelpLink
                        alignSelf={'start'}
                        href={externalRoutes.helpCenterArticleAllesZurUmsatzsteuerpflicht(
                          lang
                        )}
                      >
                        {t('payout:text.AllesZurUmsatzsteuerpflicht')}
                      </HelpLink>
                    ) : (
                      <HelpLink
                        alignSelf={'start'}
                        href={externalRoutes.helpCenterArticleWannBinIchKleinunternehmer(
                          lang
                        )}
                      >
                        {t('payout:text.WannBinIchKleinunternehmer')}
                      </HelpLink>
                    )}
                  </VStack>
                )}
                {!coinsEnabled && !!currentIsTaxableValue && (
                  <VStack spacing={6} alignItems={'stretch'}>
                    <ClearableInputControl
                      label={t('payout:label.SteuernummerUmsatzsteuerID')}
                      placeholder={t(
                        'payout:placeholder.SteuernummerUstIdNrEingeben'
                      )}
                      name={fieldName.taxNumber}
                      isDisabled={isDisabled}
                    />
                    {showDocument && (
                      <FormControl>
                        <FormControlHeaderStack>
                          <FormLabel pointerEvents={'none'} cursor={'default'}>
                            {t('payout:label.Umsatzsteuernachweis')}
                          </FormLabel>
                          <FormHelperText>
                            {t(
                              'payout:text.OffiziellesDokumentDesSteuerberatersFinanzamtsDasUmsatzsteuerpflichtXX'
                            )}
                          </FormHelperText>
                        </FormControlHeaderStack>
                        <MediaPropertiesProvider
                          targetDimensions={{ width: 520, height: 520 }}
                          determineAspectRatioByInput={true}
                          named={t('document:named.Umsatzsteuernachweis')}
                        >
                          <MediaProvider.FromUploadDocument
                            key={`${onOmitChangesTriggerCount}|${
                              initialValues?.[fieldName.document]
                            }`}
                            document={data?.payment?.taxData?.document}
                          >
                            <MediaFlowProvider clearReplacementsOnClose={true}>
                              <MediaUploadProvider.ForPaymentDocument
                                fieldName={'paymentTaxDataDocument'}
                                onUploadSuccess={(id, filename) => {
                                  hookForm.setValue(fieldName.document, id, {
                                    shouldDirty: true,
                                    shouldTouch: true,
                                  });
                                  setLastUploadedFile({ id, filename });
                                  setUploadError(null);
                                }}
                                onUploadStart={() => {
                                  setUploadError(null);
                                }}
                                onUploadError={(error) => {
                                  setUploadError(error.description);
                                }}
                              >
                                <MediaInputProvider
                                  accept={'DEFAULT_FOR_DOCUMENT'}
                                >
                                  <VStack alignItems={'stretch'} w={'full'}>
                                    <DocumentPickerV3
                                      uploadedFilename={
                                        uploadedFilename ?? undefined
                                      }
                                      isReadOnly={isDisabled}
                                      isNewProofRequired={
                                        isDirtyButMissingDocument
                                      }
                                      errorMessage={uploadError ?? undefined}
                                      named={t(
                                        'document:named.Umsatzsteuernachweis'
                                      )}
                                      expiresInDays={
                                        documentExpiration.daysTillExpired
                                      }
                                      expiringSoonStatusRender={(
                                        onOpenMediaFlow
                                      ) => (
                                        <DocumentPickerV3Status
                                          icon={icons.Error}
                                          color={'caribbeanGreen.500'}
                                          text={t(
                                            'general:status.LauftInCountTagenAb',
                                            {
                                              count:
                                                documentExpiration.daysTillExpired ??
                                                0,
                                            }
                                          )}
                                          noticeModalConfig={{
                                            status: 'hint',
                                            modalHeading: t(
                                              'general:status.LauftInCountTagenAb',
                                              {
                                                count:
                                                  documentExpiration.daysTillExpired ??
                                                  0,
                                              }
                                            ),
                                            modalTextSlot: t(
                                              'payout:text.DamitWirDeineSteuerdatenFurDieAbrechnungKorrektHinterlegenKonnenBenoXX'
                                            ),
                                            modalBodyBottomSlot: (
                                              onCloseNoticeModal: () => void
                                            ) => (
                                              <Button
                                                onClick={() => {
                                                  onCloseNoticeModal();
                                                  onOpenMediaFlow();
                                                }}
                                              >
                                                {t(
                                                  'document:button.ErneutHochladen'
                                                )}
                                              </Button>
                                            ),
                                          }}
                                        />
                                      )}
                                      expiredStatusRender={(
                                        onOpenMediaFlow
                                      ) => (
                                        <DocumentPickerV3Status
                                          icon={icons.Error}
                                          color={'error.500'}
                                          text={t(
                                            'general:status.NeuerNachweisNotwendig'
                                          )}
                                          noticeModalConfig={{
                                            status: 'error',
                                            modalHeading: t(
                                              'payout:headline.NeuerNachweisNotwendig'
                                            ),
                                            modalTextSlot: t(
                                              'payout:text.DamitWirDeineSteuerdatenFurDieAbrechnungKorrektHinterlegenKonnenBenoXX'
                                            ),
                                            modalBodyBottomSlot: (
                                              onCloseNoticeModal: () => void
                                            ) => (
                                              <Button
                                                alignSelf={'center'}
                                                onClick={() => {
                                                  onCloseNoticeModal();
                                                  onOpenMediaFlow();
                                                }}
                                              >
                                                {t(
                                                  'document:button.ErneutHochladen'
                                                )}
                                              </Button>
                                            ),
                                          }}
                                        />
                                      )}
                                      newProofRequiredStatusRender={(
                                        onOpenMediaFlow
                                      ) => (
                                        <DocumentPickerV3Status
                                          icon={icons.Error}
                                          color={'error.500'}
                                          text={t(
                                            'general:status.NeuerNachweisNotwendig'
                                          )}
                                          noticeModalConfig={{
                                            status: 'error',
                                            modalHeading: t(
                                              'payout:headline.NeuerNachweisNotwendig'
                                            ),
                                            modalTextSlot: t(
                                              'payout:text.DamitWirDeineSteuerdatenFurDieAbrechnungKorrektHinterlegenKonnenBenoXX'
                                            ),
                                            modalBodyBottomSlot: (
                                              onCloseNoticeModal: () => void
                                            ) => (
                                              <Button
                                                alignSelf={'center'}
                                                onClick={() => {
                                                  onCloseNoticeModal();
                                                  onOpenMediaFlow();
                                                }}
                                              >
                                                {t(
                                                  'document:button.ErneutHochladen'
                                                )}
                                              </Button>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                  </VStack>
                                  <DocumentMediaFlow />
                                </MediaInputProvider>
                              </MediaUploadProvider.ForPaymentDocument>
                            </MediaFlowProvider>
                          </MediaProvider.FromUploadDocument>
                        </MediaPropertiesProvider>
                      </FormControl>
                    )}
                  </VStack>
                )}
                {(!currentIsTaxableValue || showTin) && (
                  <VStack spacing={6} alignItems={'stretch'}>
                    <TipBanner
                      text={t(
                        'payout:text.NurBeiNaturlichenPersonenMitWohnsitzInDerEU'
                      )}
                    />
                    <ClearableInputControl
                      label={t('payout:label.SteuerIdentifikationsnummerTIN')}
                      text={t(
                        'payout:text.ZuFindenObenRechtsAufDerLohnsteuerbescheinigungOderDemEinkommensteueXX'
                      )}
                      placeholder={t('payout:placeholder.SteuerIDEingeben')}
                      name={fieldName.tin}
                      isDisabled={isDisabled}
                    />
                    <EnumSelectFieldHookForm
                      label={t(
                        'payout:label.InWelchemLandWurdeDieSteuerIdentifikationsnummerTINAusgestellt'
                      )}
                      name={fieldName.tinCountry}
                      isDisabled={isDisabled}
                    >
                      <PromotingOptionList {...optionListProps} />
                    </EnumSelectFieldHookForm>
                  </VStack>
                )}
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          <SectionDivider isWidthRestricted />
          <SectionFooter>
            <SectionCenterContainer>
              <SubmitChangesButtonWithConfirmation
                variant={'solid'}
                onConfirm={allowedToReSubmit && !isFormDirty}
                onConfirmedSubmit={(e) => {
                  hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)(e);
                }}
                isDisabled={isSubmittingDisabled}
                isLoading={hookForm.formState.isSubmitting}
              />
            </SectionCenterContainer>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};

export default PayoutDocumentsTaxSection;
