import {
  AspectRatio,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';

import {
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalStickyHeaderBox,
} from '../../Layout/ResponsiveModal';

const transitionDuration = 0.3;
const skeletonConfig = {
  startColor: 'gray.200',
  endColor: 'gray.400',
  speed: 1,
};

export const SkeletonTipModalContent: React.FC<{
  isLoading: boolean;
  children: React.ReactNode;
}> = ({ isLoading, children }) => {
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {isLoading ? (
        <motion.div
          role={'skeleton'}
          key={'skeleton'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: transitionDuration, ease: 'easeInOut' }}
        >
          <ResponsiveModalStickyHeaderBox p={0}>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>
          <SkeletonImage />
          <ResponsiveModalBodyBox>
            <VStack alignItems={'start'} spacing={'8'}>
              <HStack>
                <SkeletonTag />
                <SkeletonTag />
                <SkeletonTag />
              </HStack>
              <SkeletonHeading />
              <SkeletonText
                {...skeletonConfig}
                width={'full'}
                noOfLines={8}
                skeletonHeight={'4'}
              />
            </VStack>
          </ResponsiveModalBodyBox>
        </motion.div>
      ) : (
        <motion.div
          key={'realContent'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: transitionDuration,
            ease: 'easeIn',
          }}
          style={{ borderRadius: 'inherit' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const SkeletonTag = () => {
  return (
    <SkeletonCircle {...skeletonConfig} size={'6'} style={{ width: '60px' }} />
  );
};

const SkeletonHeading = () => {
  return (
    <Skeleton {...skeletonConfig} height={'5'} width={'full'} maxWidth={'96'} />
  );
};

const SkeletonImage = () => {
  return (
    <AspectRatio ratio={16 / 9}>
      <Skeleton {...skeletonConfig} />
    </AspectRatio>
  );
};
