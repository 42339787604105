import { Replay, Tune } from '@campoint/odi-ui-icons';
import { Box, Circle, Icon, IconButton } from '@chakra-ui/react';

const FilterIcon: React.FC<{
  hasActiveFilter?: boolean;
  onFilterClick?: () => any;
  onResetClick?: () => any;
  ariaLabelAddFilter?: string;
  ariaLabelResetFilter?: string;
  ariaLabelEditFilter?: string;
  isDisabled?: boolean;
}> = ({
  hasActiveFilter = false,
  isDisabled = false,
  onFilterClick,
  onResetClick,
  ariaLabelAddFilter = 'Add filter',
  ariaLabelResetFilter = 'Reset filter',
  ariaLabelEditFilter = 'Edit filter',
}) => {
  return (
    <Box display={'inline-flex'} position={'relative'}>
      {hasActiveFilter && (
        <IconButton
          aria-label={ariaLabelResetFilter}
          icon={<Icon as={Replay} boxSize="6" />}
          variant="ghost"
          onClick={onResetClick}
          isDisabled={isDisabled}
        />
      )}
      <IconButton
        aria-label={hasActiveFilter ? ariaLabelEditFilter : ariaLabelAddFilter}
        icon={<Icon as={Tune} boxSize="6" />}
        variant="ghost"
        onClick={onFilterClick}
      />
      {hasActiveFilter && (
        <Circle
          pointerEvents="none"
          size="3"
          bg={'secondary.highEmphasis'}
          border={'2px solid white'}
          position={'absolute'}
          right={1}
          top={1}
        />
      )}
    </Box>
  );
};

export default FilterIcon;
