import {
  Box,
  Button,
  ButtonProps,
  Heading,
  Text,
  VStack,
  VisuallyHidden,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import {
  PrimaryButton,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../../components/Layout/ResponsiveModal';
import { MediaPickerButton } from '../../../../../components/shared/MediaPickerButton/MediaPickerButton';
import {
  MediaPickerPreviewBox,
  MediaUploadCropperPortal,
} from '../../../../../components/shared/MediaUpload/MediaUpload';
import { useMediaFlow } from '../../../../../provider/MediaFlowProvider';
import { useMedia } from '../../../../../provider/MediaProvider';
import { useMediaUpload } from '../../../../../provider/MediaUploadProvider';
import { MediaFlowModal } from '../../../../ModelProfileV2Page/components/MediaFlowModal/MediaFlowModal';

/**
 * !Must be enclosed by:
 * - **{@link MediaProvider}**
 * - **{@link MediaUploadProvider}**
 */
const MediaUploadButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { media } = useMedia();
  const { onClose } = useMediaFlow();
  const { isUploading, action } = useMediaUpload();

  const blob = media?.blob;
  const filename = media?.filename;
  const upload = React.useCallback(async () => {
    if (!blob) {
      return;
    }
    await action.upload(blob, filename ?? undefined);
    onClose();
  }, [action, blob, filename, onClose]);

  return (
    <Button
      variant={'solid'}
      onClick={upload}
      isLoading={isUploading}
      ref={ref}
      {...props}
    />
  );
});

const MediaFlowCloseButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { onClose } = useMediaFlow();
  return <Button onClick={onClose} ref={ref} {...props} />;
});

const PickedFile = () => {
  const { t } = useTranslation(['document', 'general', 'mediaUpload']);
  return (
    <MediaFlowModal preferredSize="md" preferredSizeBreakpoint="sm">
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox>
          <MediaPickerPreviewBox
            boxProps={{ borderRadius: 'lg' }}
            centerProps={{ bg: 'unset', pt: 'unset', p: 'unset' }}
          />
          <VStack px={4} py={4} spacing={4} alignItems={'stretch'}>
            <Box>
              <VisuallyHidden>
                {t('document:heading.NeuesDokumentHochladen')}
              </VisuallyHidden>
              <Heading size={'md'}>
                {t('mediaUpload:heading.PasstAlles')}
              </Heading>
              <Text whiteSpace={'pre-wrap'}>
                {t(
                  'document:text.IstAllesLesbarUndImAusgewahltenAusschnittSichtbarDannLadeDasNeueDokuXX'
                )}
              </Text>
            </Box>
          </VStack>
        </ResponsiveModalBodyBox>
        <ResponsiveModalStickyFooterBox>
          <ButtonStack
            p={4}
            direction={'column'}
            w={'unset'}
            alignSelf={'center'}
          >
            <PrimaryButton as={MediaUploadButton}>
              {t('document:button.Hochladen')}
            </PrimaryButton>
          </ButtonStack>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </MediaFlowModal>
  );
};

const InformBeforePick = () => {
  const { t } = useTranslation(['document', 'general', 'mediaUpload']);
  return (
    <MediaFlowModal>
      <ResponsiveModalOverlay />
      <ResponsiveModalContent justifyContent={'center'} p={'24px 16px'}>
        <Heading size={'xl'} textAlign={'center'}>
          {t('document:heading.MochtestDuEinNeuesDokumentHochladen')}
        </Heading>
        <VisuallyHidden>
          {t('document:heading.NeuesDokumentHochladen')}
        </VisuallyHidden>
        <Text textAlign={'center'} p={'16px 0 32px 0'}>
          {t(
            'document:text.DeineVorhandenenDokumenteWerdenNachFreigabeDerNeuenNichtMehrAngezeigXX'
          )}
        </Text>
        <ButtonStack
          direction={'column'}
          w={'fit-content'}
          m={'0 auto'}
          gap={'16px'}
        >
          <PrimaryButton as={MediaPickerButton}>
            {t('document:button.NeuesDokumentHochladen')}
          </PrimaryButton>
          <MediaFlowCloseButton>
            {t('general:button.Abbrechen')}
          </MediaFlowCloseButton>
        </ButtonStack>
      </ResponsiveModalContent>
    </MediaFlowModal>
  );
};

export const DocumentMediaFlow = () => {
  const { hasReplacement } = useMedia();

  return (
    <>
      {hasReplacement ? <PickedFile /> : <InformBeforePick />}
      <MediaUploadCropperPortal />
    </>
  );
};
