import { Box, VStack } from '@chakra-ui/react';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  RadioOptionButtonGroupControl,
  RadioOptionButtonGroupProps,
} from '../../../../../components/shared/FormikFormElements';
import { GenderEnum, SexualityEnum } from '../../../../../generated/graphql';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton, SecondaryButton } from '../../WizardProfile';
import { useWizardProfile } from '../../WizardProfileContext';

export const wizardProfileAboutMeTestingIds = {
  aboutMeButtonGroup: 'wizard-profile-about-me-button-group',
  sexualityButtonGroup: 'wizard-profile-about-me-sexuality-button-group',
};

const genderButtonGroupName = 'gender';
const sexualityButtonGroupName = 'sexuality';
type ContentSectionProps = {
  formik: FormikContextType<any>;
};

const ContentSection: React.FC<ContentSectionProps> = ({ formik }) => {
  const { t } = useTranslation(['modelProfile']);
  const wizardCtx = useWizardProfile();

  const genderOptions: RadioOptionButtonGroupProps['options'] = (
    wizardCtx.currentField?.modelGender?.validValues ?? []
  ).map(({ label, value }) => ({
    children: label,
    value,
  }));

  const sexualityOptions: RadioOptionButtonGroupProps['options'] = (
    wizardCtx.currentField?.modelSexuality?.validValues ?? []
  ).map(({ label, value }) => ({
    children: label,
    value,
  }));

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box mb={8}>
          <WizardSubHeader
            bottomMarginEnabled={false}
            headerText={t('modelProfile:fields.gender.label')}
          />
          <RadioOptionButtonGroupControl
            name={genderButtonGroupName}
            options={genderOptions}
          />
        </Box>
        <Box mb={8}>
          <WizardSubHeader
            bottomMarginEnabled={false}
            headerText={t('modelProfile:fields.sexuality.label')}
          />
          <RadioOptionButtonGroupControl
            name={sexualityButtonGroupName}
            options={sexualityOptions}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

export const WizardProfileOnboardingV1Gender: React.FC = () => {
  const { t } = useTranslation(['general']);
  const wizardCtx = useWizardProfile();

  const initialValues = React.useMemo(
    () => ({
      [genderButtonGroupName]: wizardCtx.currentField?.modelGender?.value ?? '',
      [sexualityButtonGroupName]:
        wizardCtx.currentField?.modelSexuality?.value ?? '',
    }),
    [wizardCtx.currentField]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [genderButtonGroupName]: createStringValidationSchema({}),
        [sexualityButtonGroupName]: createStringValidationSchema({}),
      }),
    []
  );

  const onSubmit = React.useCallback<
    (values: typeof initialValues) => Promise<void>
  >(
    async (values) => {
      wizardCtx.wizardNextStepCallback({
        modelGender: values[genderButtonGroupName] as GenderEnum,
        modelSexuality: values[sexualityButtonGroupName] as SexualityEnum,
      });
    },
    [wizardCtx]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <WizardParentModalStepLayout
      contentSection={<ContentSection formik={formik} />}
      footerSection={
        <VStack spacing={'2'}>
          <PrimaryButton
            isDisabled={!(formik.isValid && formik.dirty)}
            isLoading={wizardCtx.isUpdateMutationLoading}
            children={t('general:button.continue')}
            onClick={formik.submitForm}
          />
          <SecondaryButton
            isDisabled={wizardCtx.isUpdateMutationLoading}
            children={t('general:button.skip')}
            onClick={() => wizardCtx.wizardNextStepCallback()}
          />
        </VStack>
      }
    />
  );
};
