import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import {
  getClassicPathFromUri,
  getExternalUrlFromUri,
  getInternalPathFromUri,
} from '../components/shared/NewsCenterPopper/utils';
import { useGetVxModelsClassicAutoLoginUrlWithPathLazyQuery } from '../generated/graphql';
import { useNewsCenter } from '../provider/NewsCenterProvider';
import Logger from '../utils/Logger';
import useHistoryWithStorage from './useHistoryWithStorage';

/**
 * Defines an action handler to be put on a Button based on the received actionUri
 *
 * @param actionUri
 */
export const useNewsCenterActionHandler = ({
  actionUri,
}: {
  actionUri?: Maybe<string>;
}) => {
  const history = useHistoryWithStorage();
  const { onClose } = useNewsCenter();

  const [actionOnClick, setActionOnClick] = React.useState<(() => void) | null>(
    null
  );

  const [queryClassicAutoLoginUrl] =
    useGetVxModelsClassicAutoLoginUrlWithPathLazyQuery();

  React.useEffect(() => {
    if (!!actionOnClick || !actionUri) {
      // Do not attempt to define a action handler once one already exists,
      // or there is no action uri to extract it from
      return;
    }
    const internal = getInternalPathFromUri(actionUri);
    if (internal?.isInternalRedirect) {
      setActionOnClick(() => {
        return () => {
          onClose();
          history.push(internal.path);
        };
      });
      return;
    }

    const classic = getClassicPathFromUri(actionUri);
    if (classic?.isClassicRedirectWihtAutoLogin) {
      queryClassicAutoLoginUrl({
        variables: {
          path: classic.path,
        },
      }).then(({ data, error }) => {
        if (error) {
          Logger.error(error);
          return;
        }

        const autoLoginUrl = data?.app?.autologinLinkVXModels ?? null;
        if (!autoLoginUrl) {
          Logger.error(`Did not receive autoLoginUrl`);
          return;
        }

        setActionOnClick(() => {
          return () => {
            window.open(autoLoginUrl, '_blank');
          };
        });
      });
      return;
    }

    const external = getExternalUrlFromUri(actionUri);
    if (external?.isExternalUrl) {
      setActionOnClick(() => {
        return () => {
          window.open(external.externalUrl, '_blank');
        };
      });
      return;
    }
  }, [
    actionOnClick,
    actionUri,
    setActionOnClick,
    onClose,
    history,
    queryClassicAutoLoginUrl,
  ]);

  return actionOnClick;
};
