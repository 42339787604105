import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { Container } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../../components/Layout/LayoutCenteringVStack';
import { CompleteOpenTasksCard } from '../../../components/shared/cards/CompleteOpenTasksCard/CompleteOpenTasksCard';

type RouterState = {
  state: {
    stepCount?: number;
  };
};
const VXLiveMissingRequirementsPage: React.FC = () => {
  const location = useLocation<RouterState>();
  const remainingStepsCount = (location as RouterState).state?.stepCount ?? 0;

  return (
    <FluentPageLayout>
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <SubHeaderTitle p={0}>{'VXLive'}</SubHeaderTitle>
        </Container>
      </SubHeader>
      <Container maxW="container.xl" px={0} py={{ base: 4, lg: 8 }}>
        <LayoutCenteringVStack>
          <CompleteOpenTasksCard remainingStepsCount={remainingStepsCount} />
        </LayoutCenteringVStack>
      </Container>
    </FluentPageLayout>
  );
};

export default VXLiveMissingRequirementsPage;
