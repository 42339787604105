import { Box, Center, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PercentProgressSpinner } from '../../../../../../components/shared/GradientSpinner/GradientSpinner';

export const FeedTranscodingProgress: React.FC = () => {
  const { t } = useTranslation(['feed']);
  return (
    <Center bg={'steel'} color={'caribbeanGreen.500'}>
      <VStack>
        <PercentProgressSpinner percentProgressLabelColor={'gray.900'} />
        <Box>{t('feed:text.QualitatWirdOptimiert')}</Box>
      </VStack>
    </Center>
  );
};
