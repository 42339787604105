import { Fullscreen, FullscreenExit } from '@campoint/odi-ui-icons';
import { Icon, IconButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ImageGalleryButton } from './ImageGalleryButton';

export const EnterFullscreenButton: React.FC<Partial<IconButtonProps>> = (
  props
) => {
  const { t } = useTranslation(['general']);

  return (
    <ImageGalleryButton
      aria-label={t('general:button.Fullscreen')}
      icon={<Icon as={Fullscreen} boxSize={'6'} />}
      borderRadius={4}
      {...props}
    />
  );
};

export const ExitFullscreenButton: React.FC<Partial<IconButtonProps>> = (
  props
) => {
  const { t } = useTranslation(['general']);
  return (
    <ImageGalleryButton
      aria-label={t('general:button.FullscreenSchliessen')}
      icon={<Icon as={FullscreenExit} boxSize={'6'} />}
      borderRadius={4}
      {...props}
    />
  );
};
