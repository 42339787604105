import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';

import Markdown from '../../../../../components/shared/Markdown/Markdown';
import { routes } from '../../../../../routes/routesConfig';
import { ContestTileBase, ContestTileHeaderImage } from './ContestTileBase';

export const ContestTileDescription: typeof ContestTileBase = (props) => {
  const history = useHistory();

  return (
    <ContestTileBase {...props}>
      <ContestTileHeaderImage contest={props.contest} />
      <Box p={3}>
        <Heading size="sm">{props.contest.title}</Heading>
        <Markdown
          markdown={props.contest.description}
          containerProps={{
            css: {
              maxHeight: '190px',
              overflow: 'hidden',
              WebkitLineClamp: '8',
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              textOverflow: 'ellipsis',
            },
          }}
        />
        <Text fontWeight={'500'} py={5}>
          {t('contest:text.AchteAufUnsere')}
          <Button
            variant={'link'}
            fontSize={'1em'}
            onClick={() => history.push(routes.houseRules.path)}
          >
            {t('contest:button.ContentRegeln')}
          </Button>
          .
        </Text>
      </Box>
    </ContestTileBase>
  );
};
