import React from 'react';

import { SelectOption, SelectOptionList } from '../SelectControl';

export type PromotingOptionListProps = {
  groupLabel: string;
  unPromotedGroupLabel: string;
  options: SelectOption[];
  promotedValues: string[];
  placeholder?: string;
};

export const PromotingOptionList: React.FC<PromotingOptionListProps> = ({
  groupLabel,
  unPromotedGroupLabel,
  options,
  promotedValues,
  placeholder = '',
}: PromotingOptionListProps) => {
  const optionCollection = React.useMemo(
    () => prepareOptionCollection({ options, promotedValues }),
    [options, promotedValues]
  );

  const hasPromotedOptions = optionCollection.promotedOptions.length > 0;

  return (
    <>
      <EmptyOption label={placeholder} />
      {hasPromotedOptions && (
        <OptGroup
          label={groupLabel}
          options={optionCollection.promotedOptions}
        />
      )}
      <OptGroup
        label={hasPromotedOptions ? unPromotedGroupLabel : groupLabel}
        options={optionCollection.options}
      />
    </>
  );
};

const EmptyOption: React.FC<{ label?: string }> = ({ label = '' }) => (
  <option value="" disabled hidden children={label} />
);

const OptGroup: React.FC<{ label: string; options: SelectOption[] }> = ({
  label,
  options,
}) => {
  return (
    <optgroup label={label}>
      <SelectOptionList options={options} />
    </optgroup>
  );
};

function prepareOptionCollection({
  options,
  promotedValues = [],
}: {
  options: SelectOption[];
  promotedValues: string[];
}) {
  const o: {
    options: SelectOption[];
    promotedOptions: (null | SelectOption)[];
  } = !promotedValues?.length
    ? { options, promotedOptions: [] }
    : options.reduce(
        (acc, option) => {
          const indexOfPromotedValue = promotedValues.indexOf(option.value);
          if (indexOfPromotedValue >= 0) {
            acc.promotedOptions[indexOfPromotedValue] = option;
          } else {
            acc.options = [...acc.options, option];
          }
          return acc;
        },
        {
          options: [] as SelectOption[],
          promotedOptions: Array(promotedValues.length).fill(null),
        }
      );

  return {
    options: o.options,
    promotedOptions: o.promotedOptions.filter((p) => !!p) as SelectOption[],
  };
}
