import { Link } from '@chakra-ui/react';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Trans, useTranslation } from 'react-i18next';
import CookieConsent from 'vanilla-cookieconsent';

import { externalRoutes } from '../routes/routesConfig';

export enum ConsentCategoryEnum {
  necessary = 'necessary',
  functional = 'functional',
  analytics = 'analytics',
}

const useLanugageSetting = (lng: 'de' | 'en' | 'cimode') => {
  const { t, i18n } = useTranslation(['cookieBanner']);

  // `ReactDOMServer.renderToStaticMarkup` can not nbe used inside of a `useMemo` callback,
  // so we render it each time and use the resulting string instead
  const weUseCookiesLink = ReactDOMServer.renderToStaticMarkup(
    Trans({
      t,
      i18nKey: `cookieBanner:text.WirVerwendenCookiesUmIhrErlebnisAufUnsererWebsiteSoAngenehmWieMoglicXX`,
      tOptions: { lng },
      components: {
        privacy: (
          <Link
            aria-label="privacy policy"
            href={externalRoutes.privacy()}
            rel="noreferrer"
            target="_blank"
          />
        ),
      },
    })
  );

  // `ReactDOMServer.renderToStaticMarkup` can not nbe used inside of a `useMemo` callback,
  // so we render it each time and use the resulting string instead
  const moreinformationLink = ReactDOMServer.renderToStaticMarkup(
    Trans({
      t,
      i18nKey: `cookieBanner:text.FurWeitereInformationenZumThemaDatenschutzLiesUnserePrivacyDatenschuXX`,
      tOptions: { lng },
      components: {
        privacy: (
          <Link
            aria-label="privacy policy"
            href={externalRoutes.privacy()}
            rel="noreferrer"
            target="_blank"
          />
        ),
      },
    })
  );

  // Workaround for test environments,
  // so translations can are mapped to their tKey
  // and can be found that way
  if (i18n.language === 'cimode') {
    lng = 'cimode';
  }

  const languageSetting = React.useMemo(() => {
    return {
      consentModal: {
        title: t('cookieBanner:heading.WirNutzenCookies', { lng }),
        description: weUseCookiesLink,
        acceptAllBtn: t('cookieBanner:button.AlleAkzeptieren', { lng }),
        showPreferencesBtn: t('cookieBanner:button.Einstellungen', { lng }),
      },
      preferencesModal: {
        title: t('cookieBanner:heading.CookieEinstellungen', { lng }),
        acceptAllBtn: t('cookieBanner:button.AlleAkzeptieren', { lng }),
        savePreferencesBtn: t('cookieBanner:button.Speichern', { lng }),
        acceptNecessaryBtn: t('cookieBanner:button.AlleAblehnen', { lng }),
        sections: [
          {
            title: t('cookieBanner:heading.CookieNutzung', { lng }),
            description: t(
              'cookieBanner:text.UnsereCookiesFallenInFolgendeKategorien',
              { lng }
            ),
          },
          {
            title: t('cookieBanner:heading.EssentielleCookies', { lng }),
            description: t(
              'cookieBanner:text.DieseCookiesSindNotwendigFurDenEinwandfreienTechnischenBetriebDerWebXX',
              { lng }
            ),

            //this field will generate a toggle linked to the 'necessary' category
            linkedCategory: ConsentCategoryEnum.necessary,
          },
          {
            title: t('cookieBanner:heading.FunktionaleCookies', { lng }),
            description: t(
              'cookieBanner:text.DieseCookiesVerbessernDieNutzererfahrungAufUnsererWebsiteIndemSieSicXX',
              { lng }
            ),
            linkedCategory: ConsentCategoryEnum.functional,
          },
          {
            title: t('cookieBanner:heading.AnalyseUndOptimierung', { lng }),
            description: t(
              'cookieBanner:text.DieseCookiesWerdenVerwendetUmDieNutzungUnsererWebsiteMitZuAnalysiereXX',
              { lng }
            ),
            linkedCategory: ConsentCategoryEnum.analytics,
          },
          {
            title: t('cookieBanner:heading.MehrInformationen', { lng }),
            description: moreinformationLink,
          },
        ],
      },
    } as CookieConsent.Translation;
  }, [t, lng, weUseCookiesLink, moreinformationLink]);

  return languageSetting;
};
export const useCookieConsentLanguageOptions = () => {
  const { i18n } = useTranslation(['cookieBanner']);

  const de = useLanugageSetting('de');
  const en = useLanugageSetting('en');

  const current_lang = i18n.language;

  return React.useMemo(
    () => ({
      languages: {
        de,
        en,
      },
      current_lang,
    }),
    [de, en, current_lang]
  );
};
