import { ApolloError } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { DialogModal } from '../../components/shared/DialogModal/DialogModal';
import { ConfirmMediaDeletionDialog } from '../../components/shared/dialogs/ConfirmMediaDeletionDialog/ConfirmMediaDeletionDialog';
import {
  FeedNodeFragment,
  FeedPostCategoriesEnum,
  FeedPostFragment,
  useDeleteFeedPostMutation,
  useDeleteImageFeedPostMutation,
  useGetFeedPostsQuery,
  useGetFeedTotalCountQuery,
  usePinFeedPostMutation,
  useReplaceImageFeedPostUploadUrlLazyQuery,
  useUnpinFeedPostMutation,
} from '../../generated/feed';
import { useCallbackRegister } from '../../hooks/useCallbackRegister';
import { createContext } from '../../hooks/useContext';
import { LocalStorageKeys } from '../../types';
import { LocalStorage, noop } from '../../utils';
import { scrollToElement } from '../../utils/utils';
import { useAuth } from '../AuthProvider';
import {
  UploadJobEntityType,
  useUploadManager,
} from '../UploadManagerProvider';

export interface FeedServiceProviderProps {
  children?: React.ReactNode;
}

export type ReplaceImageHandlerProps = {
  postId: string;
  photoId: string;
  mediaFile: Blob;
};

export interface FeedServiceContextAction {
  deleteClickHandler: (postId: string) => void;
  deletePostHandler: () => void;
  deleteImageHandler: (imageId: string, postId: string) => void;
  replaceImageHandler: (props: ReplaceImageHandlerProps) => void;
  pinPostHandler: (postId?: string) => void;
  pinClickHandler: (postId: string, isPinned: boolean) => void;
  removePinHandler: () => void;
  feedCommentModalOnClose: () => void;
  deletePost: (postId: string) => void;
  pinPost: (postId: string) => void;
  unpinPost: (postId: string) => void;
  showCreatePost: () => void;
  showEditForPost: (postId: string) => void;
  showStatisticsForPost: (postId: string) => void;
  showCommentsForPost: (postId: string) => void;
  showRejectionInfoForPost: (postId: string) => void;
  showBrokenInfoForPost: (postId: string) => void;
  onPlayVideo: (videoId: string) => void;
  isVideoPaused: (videoId: string) => boolean;
}
export interface FeedServiceContext {
  action: FeedServiceContextAction;
  feedPosts: FeedNodeFragment[];
  totalFeedPostsCount: number;
  loadMorePosts: () => void;
  triggerFeedRefresh: (addPostCount?: number) => void;
  postToCommentOn: FeedPostFragment | null;
  setPostToCommentOnId: React.Dispatch<React.SetStateAction<string | null>>;
  registerRefresh: (fn: () => void) => () => boolean;
  feedIsLoading: boolean;
  feedPostContainerId: string;
  error: ApolloError | undefined;
  feedFilters: FeedPostCategoriesEnum[];
  setFilters: (filters: FeedPostCategoriesEnum[]) => void;
  postsEditing: Set<string>;
}

export const [, useFeedService, feedServiceContext] =
  createContext<FeedServiceContext>({
    name: 'FeedServiceContext',
    errorMessage:
      'useFeedService: `feedServiceContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export const FeedServiceProvider: React.FC<FeedServiceProviderProps> = ({
  children,
}) => {
  const { t } = useTranslation(['general', 'feed']);
  const authCtx = useAuth();
  const modelId = authCtx.authUser?.userId!;
  const loadPostCount = 8;
  const uploadManagerCtx = useUploadManager();

  const [postsEditing, setPostsEditing] = React.useState<Set<string>>(
    new Set()
  );

  const [playingVideoId, setPlayingVideoId] = React.useState<string | null>(
    null
  );

  const [feedFilters, setFeedFilters] = React.useState<
    FeedPostCategoriesEnum[]
  >([]);

  React.useEffect(() => {
    const feedFiltersString = LocalStorage.getString(
      LocalStorageKeys.FEED_FILTER
    );
    const feedFilters = feedFiltersString ? JSON.parse(feedFiltersString) : {};
    const userId = authCtx.authUser?.userId;
    const feedFilterForUser =
      feedFiltersString && userId && feedFilters[userId];
    if (feedFilterForUser) {
      setFeedFilters(feedFilters[userId]);
    } else {
      if (userId) {
        LocalStorage.add(
          LocalStorageKeys.FEED_FILTER,
          JSON.stringify({ ...feedFilters, [userId]: [] })
        );
      }
      setFeedFilters([]);
    }
  }, [authCtx.authUser?.userId, setFeedFilters]);

  const setFilters = React.useCallback(
    (filters: FeedPostCategoriesEnum[]) => {
      setFeedFilters(filters);

      const feedFiltersString = LocalStorage.getString(
        LocalStorageKeys.FEED_FILTER
      );
      const userId = authCtx.authUser?.userId;
      const feedFilters = feedFiltersString
        ? JSON.parse(feedFiltersString)
        : [];
      if (userId) {
        LocalStorage.add(
          LocalStorageKeys.FEED_FILTER,
          JSON.stringify({ ...feedFilters, [userId]: filters })
        );
      }
    },
    [authCtx.authUser?.userId]
  );

  const {
    data: feedData,
    fetchMore,
    refetch,
    loading: feedIsLoading,
    error,
  } = useGetFeedPostsQuery({
    variables: {
      modelId: modelId ?? '',
      count: loadPostCount,
      cursor: '',
      categories: feedFilters,
    },
    skip: !authCtx.isAuthenticated,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true, // updates loading state when fetchMore is called
  });

  const feedPostsEdges = React.useMemo(() => {
    return feedData?.feed?.posts.edges;
  }, [feedData]);

  const feedPosts = React.useMemo(() => {
    return feedPostsEdges?.map((el) => el.node) ?? [];
  }, [feedPostsEdges]);

  const { data: totalCountData } = useGetFeedTotalCountQuery({
    variables: {
      modelId: modelId ?? '',
    },
    skip: !authCtx.isAuthenticated,
    fetchPolicy: 'network-only',
  });

  const totalFeedPostsCount = React.useMemo(() => {
    return totalCountData?.feedTotalCounts?.totalCount ?? 0;
  }, [totalCountData?.feedTotalCounts.totalCount]);

  const loadMorePosts = React.useCallback(() => {
    const lastPost =
      feedData?.feed?.posts.edges[feedData?.feed?.posts.edges.length - 1];
    const hasNextPage = feedData?.feed?.posts.pageInfo.hasNextPage;

    if (hasNextPage && !error) {
      fetchMore({
        variables: {
          count: loadPostCount,
          cursor: lastPost?.cursor ?? '',
        },
      });
    }
  }, [error, feedData?.feed.posts, fetchMore]);

  const [registerRefresh, notifyRefresh] = useCallbackRegister();

  const triggerFeedRefresh = React.useCallback(
    (addPostCount: number = 0) => {
      refetch({
        count:
          (feedData?.feed?.posts.edges.length ?? loadPostCount) + addPostCount,
      }).then();
      notifyRefresh();
    },
    [refetch, feedData, loadPostCount, notifyRefresh]
  );

  const [postToCommentOnId, setPostToCommentOnId] = React.useState<
    string | null
  >(null);

  const postToCommentOn = React.useMemo(() => {
    return (
      feedData?.feed?.posts.edges?.find(
        (edge) => edge?.node.id === postToCommentOnId
      )?.node ?? null
    );
  }, [feedData, postToCommentOnId]);

  const [targetDeletePostId, setTargetDeletePostId] = React.useState('');
  const [deleteFeedPostMutation] = useDeleteFeedPostMutation();
  const [deleteImageMutation] = useDeleteImageFeedPostMutation();
  const [replaceImageUploadUrl] = useReplaceImageFeedPostUploadUrlLazyQuery();

  const postDeletionModal = useDisclosure();

  const unguardedDeleteClickHandler = React.useCallback(
    (postId: string) => {
      deleteFeedPostMutation({ variables: { postId: postId } })
        .then(() => {
          issueChakraToast({
            description: t('feed:toast.DerPostWurdeErfolgreichGeloescht'),
            status: 'success',
          });
          triggerFeedRefresh();
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.BeimLoeschenIstEinFehlerAufgetreten'),
            status: 'error',
          });
        });
    },
    [t, triggerFeedRefresh, deleteFeedPostMutation]
  );
  const unguardedDeleteImageHandler = React.useCallback(
    (imageId: string, postId: string) => {
      deleteImageMutation({ variables: { photoId: imageId } })
        .then(() => {
          issueChakraToast({
            description: t('feed:toast.DasFotoWurdeErfolgreichGeloescht'),
            status: 'success',
          });
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.BeimLoeschenIstEinFehlerAufgetreten'),
            status: 'error',
          });
        })
        .finally(() => {
          triggerFeedRefresh();
          handleEditingPosts(postId);
        });
    },
    [deleteImageMutation, t, triggerFeedRefresh]
  );

  const unguardedReplaceImageHandler = React.useCallback(
    (props: ReplaceImageHandlerProps) => {
      const { postId, photoId, mediaFile: photo } = props;
      replaceImageUploadUrl({
        variables: { postId: postId, photoId: photoId },
      })
        .then((data) => {
          const uploadUrl = data.data?.uploadUrl;

          if (uploadUrl) {
            const uploadJobPromise = uploadManagerCtx.action.queueUploadJob({
              entity: {
                type: UploadJobEntityType.IMAGE,
                id: postId,
              },
              payload: {
                thumbnail: photo,
                mediaFiles: [
                  {
                    uploadUrl,
                    blob: photo,
                  },
                ],
              },
            });

            // Wait for the upload to finish before deleting the old image
            if (uploadJobPromise instanceof Promise) {
              uploadJobPromise.then(() => {
                deleteImageMutation({ variables: { photoId: photoId } })
                  .then(() => {
                    issueChakraToast({
                      description: t(
                        'feed:toast.DasFotoWurdeErfolgreichErsetzt'
                      ),
                      status: 'success',
                    });
                  })
                  .catch(() => {
                    issueChakraToast({
                      description: t(
                        'feed:toast.DasZuErsetzendeFotoKonnteNichtGeloschtWerden'
                      ),
                      status: 'error',
                    });
                  });
              });
            }
          }
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.DasFotoKonnteNichtErsetztWerden'),
            status: 'error',
          });
        })
        .finally(() => {
          //for some reason BE is not quick enough to update the post
          setTimeout(() => {
            handleEditingPosts(postId);
            triggerFeedRefresh();
          }, 5000);
        });
    },
    [
      deleteImageMutation,
      replaceImageUploadUrl,
      t,
      triggerFeedRefresh,
      uploadManagerCtx.action,
    ]
  );

  const replaceImageHandler = React.useCallback(
    (props: ReplaceImageHandlerProps) => {
      handleEditingPosts(props.postId);
      unguardedReplaceImageHandler(props);
    },
    [unguardedReplaceImageHandler]
  );

  const deleteClickHandler = React.useCallback(
    (postId: string) => {
      setTargetDeletePostId(postId);
      postDeletionModal.onOpen();
    },
    [postDeletionModal]
  );

  const deletePostHandler = React.useCallback(() => {
    postDeletionModal.onClose();
    unguardedDeleteClickHandler(targetDeletePostId);
  }, [postDeletionModal, targetDeletePostId, unguardedDeleteClickHandler]);

  const deleteImageHandler = React.useCallback(
    (imageId: string, postId: string) => {
      handleEditingPosts(postId);
      unguardedDeleteImageHandler(imageId, postId);
    },
    [unguardedDeleteImageHandler]
  );

  const [pinFeedPostMutation] = usePinFeedPostMutation();
  const [unpinFeedPostMutation] = useUnpinFeedPostMutation();
  const [targetPinPostId, setTargetPinPostId] = React.useState('');
  const feedPostContainerId = 'feedPostContainer';

  const pinModal = useDisclosure();
  const unpinModal = useDisclosure();

  const pinPostHandler = React.useCallback(
    (postId?: string) => {
      if (pinModal.isOpen) {
        pinModal.onClose();
      }

      pinFeedPostMutation({ variables: { postId: postId ?? targetPinPostId } })
        .then(() => {
          refetch()
            .then(() => {
              scrollToElement(feedPostContainerId);
              issueChakraToast({
                description: t('feed:toast.SuccessfulPinned'),
                status: 'success',
              });
            })
            .catch(() => {
              issueChakraToast({
                description: t(
                  'feed:toast.BeimNeuladenDerPostsIstEinFehlerAufgetreten'
                ),
                status: 'error',
              });
            });
        })
        .catch(() => {
          issueChakraToast({
            description: t('feed:toast.BeimPinnenIstEinFehlerAufgetreten'),
            status: 'error',
          });
        });
    },
    [pinModal, pinFeedPostMutation, targetPinPostId, refetch, t]
  );

  const pinClickHandler = React.useCallback(
    (postId: string, isPinned: boolean) => {
      setTargetPinPostId(postId);
      const firstPost = feedData?.feed?.posts.edges[0];

      if (isPinned) {
        unpinModal.onOpen();
        return;
      }

      if (firstPost?.node.pinned) {
        pinModal.onOpen();
        return;
      }

      pinPostHandler(postId);
    },
    [feedData?.feed?.posts.edges, pinPostHandler, unpinModal, pinModal]
  );

  const removePinHandler = React.useCallback(() => {
    unpinModal.onClose();

    unpinFeedPostMutation({ variables: { postId: targetPinPostId } })
      .then(() => {
        refetch()
          .then(() => {
            scrollToElement(feedPostContainerId);
          })
          .catch(() => {
            issueChakraToast({
              description: t(
                'feed:toast.BeimNeuladenDerPostsIstEinFehlerAufgetreten'
              ),
              status: 'error',
            });
          });
      })
      .catch(() => {
        issueChakraToast({
          description: t('feed:toast.BeimPinnenIstEinFehlerAufgetreten'),
          status: 'error',
        });
      });
  }, [refetch, t, targetPinPostId, unpinFeedPostMutation, unpinModal]);

  const feedCommentModalOnClose = React.useCallback(() => {
    setPostToCommentOnId(null);
    refetch({
      count: feedPosts?.length ?? 0,
    }).then();
  }, [feedPosts.length, refetch]);

  const handleEditingPosts = (postId: string) => {
    setPostsEditing((prevPostsEditing) => {
      const newPostsEditing = new Set(prevPostsEditing); // Create a copy to avoid direct mutation
      if (newPostsEditing.has(postId)) {
        newPostsEditing.delete(postId); // Remove the id if it's already in the set
      } else {
        newPostsEditing.add(postId); // Add the id if it's not in the set
      }
      return newPostsEditing;
    });
  };

  const action = React.useMemo<FeedServiceContextAction>(() => {
    return {
      deletePost: unguardedDeleteClickHandler,
      deleteClickHandler: deleteClickHandler,
      deletePostHandler: deletePostHandler,
      deleteImageHandler: deleteImageHandler,
      replaceImageHandler: replaceImageHandler,
      pinPostHandler: pinPostHandler,
      pinClickHandler: pinClickHandler,
      removePinHandler: removePinHandler,
      feedCommentModalOnClose: feedCommentModalOnClose,
      showCreatePost: noop,
      showEditForPost: noop,
      showCommentsForPost: noop,
      showBrokenInfoForPost: noop,
      showRejectionInfoForPost: noop,
      showStatisticsForPost: noop,
      pinPost: noop,
      unpinPost: noop,
      onPlayVideo: (id) => setPlayingVideoId(id),
      isVideoPaused: (id) => playingVideoId !== id,
    };
  }, [
    deleteClickHandler,
    deleteImageHandler,
    deletePostHandler,
    feedCommentModalOnClose,
    pinClickHandler,
    pinPostHandler,
    playingVideoId,
    removePinHandler,
    replaceImageHandler,
    unguardedDeleteClickHandler,
  ]);

  const context = React.useMemo<FeedServiceContext>(() => {
    return {
      action,
      feedPosts,
      totalFeedPostsCount,
      loadMorePosts,
      triggerFeedRefresh,
      feedIsLoading,
      feedPostContainerId,
      postToCommentOn,
      setPostToCommentOnId,
      registerRefresh,
      error,
      feedFilters,
      setFilters,
      postsEditing,
    };
  }, [
    action,
    feedPosts,
    totalFeedPostsCount,
    loadMorePosts,
    triggerFeedRefresh,
    feedIsLoading,
    postToCommentOn,
    registerRefresh,
    error,
    feedFilters,
    setFilters,
    postsEditing,
  ]);

  return (
    <feedServiceContext.Provider value={context}>
      {children}

      <ConfirmMediaDeletionDialog
        mediaType={'post'}
        isOpen={postDeletionModal.isOpen}
        deleteMedia={deletePostHandler}
        onClose={postDeletionModal.onClose}
      />

      <DialogModal
        isOpen={pinModal.isOpen!}
        headline={t('feed:heading.BestehendenPinErsetzen')}
        text={t(
          'feed:text.DuHastBereitsEinenBeitragGepinntMochtestDuIhnDurchDiesenErsetzen'
        )}
        confirmButtonText={t('feed:button.PinErsetzen')}
        closeButtonText={t('feed:button.Abbrechen')}
        onConfirm={() => pinPostHandler()}
        onClose={pinModal.onClose}
      />

      <DialogModal
        isOpen={unpinModal.isOpen}
        headline={t('feed:heading.PinEntfernen')}
        text={t(
          'feed:text.DerBeitragWirdDannNichtMehrGanzObenInDeinemFeedAngezeigt'
        )}
        confirmButtonText={t('feed:label.PinEntfernen')}
        closeButtonText={t('feed:button.Abbrechen')}
        onConfirm={removePinHandler}
        onClose={unpinModal.onClose}
      />
    </feedServiceContext.Provider>
  );
};
