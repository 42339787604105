import {
  Button,
  ButtonGroup,
  Heading,
  Img,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import updateDataDefault from '../../../../assets/images/shared/update-data-approved-all.svg';
import { FeatureName, useFeature } from '../../../../flags';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { externalRoutes, routes } from '../../../../routes/routesConfig';
import { UrlFragment } from '../../../Layout/UrlFragmentScrollToTarget';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../cards/UniversalCard/UniversalCard';

export const UpdateTaxInformationCard: React.FC<{
  reason?: string;
  daysLeft: number | null;
}> = ({ reason = '', daysLeft }) => {
  const { t } = useTranslation(['general', 'payout']);
  const history = useHistory();
  const lang = useActiveApiLanguage();
  const documentFlag = useFeature(FeatureName.document);
  const documentPreferredForFinanceDocumentFlag = useFeature(
    FeatureName.documentPreferredForFinanceDocument
  );

  const openHelpCenter = () => {
    window.open(externalRoutes.helpCenter(lang), '_blank');
  };

  const navigateToPayoutTaxSection = () => {
    if (documentFlag && documentPreferredForFinanceDocumentFlag) {
      history.push(
        `${routes.documentsPayout.path}#${UrlFragment.TaxEditSection}`
      );
    } else {
      history.push(
        `${routes.finance.path}?tapId=${'vat'}#${UrlFragment.TaxEditSection}`
      );
    }
  };

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={updateDataDefault}
              alt={t('general:img.AbgelehntesDokument')}
            />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t(
                  'payout:headline.BitteAktualisiereDeineAngabenZurUmsatzsteuerpflicht'
                )}
              </Heading>
              <Text
                size={{ base: 'sm', lg: 'md' }}
                textAlign={'center'}
                color={'gray.500'}
                p={0}
              >
                {t(
                  'payout:text.BitteAktualisiereDeineAngabenZurUmsatzsteuerpflichtBeschreibung',
                  { daysLeft }
                )}
              </Text>
            </VStack>
          </>
        }
        cardFooter={
          <ButtonGroup flexDir={'column'} gap={4} spacing={0}>
            <Button
              variant={'solid'}
              children={t('payout:button.AngabenAktualisieren')}
              onClick={navigateToPayoutTaxSection}
            />
            <Button
              children={t('payout:button.MehrErfahren')}
              onClick={openHelpCenter}
            />
          </ButtonGroup>
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
