import { useCallback } from 'react';

import { gtag } from '../../../utils/gtag';
import { WizardStepsConfig } from './types';

export const useWizardNavigateToNextStepCallback = <
  StepId extends Readonly<string>
>(
  steps: WizardStepsConfig<StepId>,
  onFinish?: () => void
) => {
  const isStepId = useCallback(
    (stepId: Readonly<string>): stepId is StepId => stepId in steps,
    [steps]
  );

  return useCallback(
    (nextStepId: Readonly<string> | StepId | null) => {
      if (nextStepId === null || !isStepId(nextStepId)) {
        gtag('event', 'wizard-completed');
        onFinish?.();
        return;
      }

      gtag('event', 'wizard-step-completed', {
        nextStepId,
      });
    },
    [isStepId, onFinish]
  );
};
