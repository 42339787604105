import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  UseRadioProps,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import React from 'react';

export type RadioButtonGroupProps = {
  label?: string;

  /** List of options pick able by radio buttons */
  options: Array<UseRadioProps & { children?: React.ReactNode }>;

  name: string;

  formControlProps?: FormControlProps;
} & Omit<RadioGroupProps, 'children'>;

export const RadioGroupControl = forwardRef<RadioButtonGroupProps, 'div'>(
  (
    { label, name, options, formControlProps, children, ...radioGroupProps },
    ref
  ) => {
    const [fieldProps, { error, touched }] = useField(name);

    const { setFieldValue } = useFormikContext();
    const handleChange = (value: string) => {
      setFieldValue(name, value);
    };
    return (
      <FormControl isInvalid={!!error && touched} {...formControlProps}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}

        {children ?? (
          <RadioGroup
            {...fieldProps}
            ref={ref}
            w={'full'}
            role="group"
            onChange={handleChange}
          >
            <VStack align="flex-start" spacing={4}>
              {options.map((option) => (
                <Radio key={option.value} size="lg" {...option}>
                  {option.children ?? null}
                </Radio>
              ))}
            </VStack>
          </RadioGroup>
        )}
      </FormControl>
    );
  }
);
