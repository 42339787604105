import React from 'react';

import {
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../components/Layout/ResponsiveModal';
import {
  MediaPickerPreviewBoxNoBackground,
  MediaPickerPreviewBoxProps,
  MediaUploadBodyContent,
  MediaUploadBodyContentProps,
  MediaUploadCropperPortal,
  ToggleStencilButton,
} from '../../../../components/shared/MediaUpload/MediaUpload';
import { MediaInputProvider } from '../../../../provider/MediaInputProvider';
import { MediaFlowModal } from '../MediaFlowModal/MediaFlowModal';

export const CommonMediaFlowModal: React.FC<{
  mediaPickerPreviewBoxProps?: MediaPickerPreviewBoxProps;
  mediaUploadBodyContentProps?: MediaUploadBodyContentProps;
}> = (props) => (
  <MediaInputProvider>
    <MediaFlowModal preferredSize="xl">
      <ResponsiveModalOverlay />
      <ResponsiveModalContent justifyContent={'center'} p={'24px 16px'}>
        <MediaPickerPreviewBoxNoBackground
          {...props.mediaPickerPreviewBoxProps}
        />
        <ResponsiveModalCloseButton />
        <MediaUploadBodyContent {...props.mediaUploadBodyContentProps} />
        <ToggleStencilButton />
      </ResponsiveModalContent>
    </MediaFlowModal>
    <MediaUploadCropperPortal />
  </MediaInputProvider>
);
