import React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalProps,
} from '../../../../components/Layout/ResponsiveModal';
import { useMediaFlow } from '../../../../provider/MediaFlowProvider';
import { useMedia } from '../../../../provider/MediaProvider';
import {
  NavigationBlockProvider,
  useNavigationBlock,
} from '../../../../provider/NavigationBlockProvider';

/**
 * !Must be enclosed by:
 * - **{@link MediaFlowProvider}**
 *
 * Responsive Modal which is controlled by a `MediaFlowProvider`
 * @see ResponsiveModal
 * @see MediaFlowProvider
 * @see useMediaFlow
 */
export const MediaFlowModal: React.FC<
  Partial<ResponsiveModalProps> & {
    children?: React.ReactNode;
  }
> = (props) => {
  return (
    <NavigationBlockProvider>
      <MediaFlowModalInner {...props} />
    </NavigationBlockProvider>
  );
};

const MediaFlowModalInner: React.FC<
  Partial<ResponsiveModalProps> & {
    children?: React.ReactNode;
  }
> = (props) => {
  const { isOpen, onClose } = useMediaFlow();
  const { hasReplacement, action } = useMedia();

  const {
    action: { registerDirtyFlag, showConfirmChangesDialog },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    action.clearReplacement();
  }, [action]);
  React.useEffect(() => {
    return registerDirtyFlag(hasReplacement, onOmitChanges);
  }, [hasReplacement, onOmitChanges, registerDirtyFlag]);

  return (
    <>
      <ResponsiveModal
        isOpen={isOpen}
        onClose={() => {
          if (hasReplacement) {
            showConfirmChangesDialog(onOmitChanges);
          } else {
            onClose();
          }
        }}
        children={props.children}
        {...props}
      />
    </>
  );
};
