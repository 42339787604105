import { Email, Phone } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  SimpleGrid,
  SimpleGridProps,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionTile } from '../../../../components/shared/ActionTile/ActionTile';
import { LargeIconCardDot } from '../../../../components/shared/cards/LargeIconCard/LargeIconCard';
import { useGetUnauthorizedHelpCenterPageDataQuery } from '../../../../generated/graphql';
import { PasswordResetShared as S } from '../shared';

const HelpCenterActionTiles: React.FC<SimpleGridProps> = (props) => {
  const { t } = useTranslation(['helpPage']);

  const { data: supportData, loading } =
    useGetUnauthorizedHelpCenterPageDataQuery();

  const phoneNumber = supportData?.support?.tel ?? '';
  const supportMail = supportData?.support?.mailto ?? '';

  // support.available is an array of all support instances that are currently
  // online. Therefor checking if array.length is the easiest solution here
  const supportAvailable =
    supportData?.support?.available &&
    supportData?.support?.available.length > 0;

  const openPhone = () => {
    window.open(`tel:${phoneNumber}`, '_self');
  };
  const openMail = () => {
    window.open(`mailto:${supportMail}`, '_blank');
  };

  return (
    <Center>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} {...props}>
        {loading ? (
          <Skeleton />
        ) : (
          <ActionTile
            title={t('helpPage:cards.callUs')}
            onClick={openPhone}
            isAvailableIndicator={
              <LargeIconCardDot
                bg={
                  supportAvailable
                    ? 'success.highEmphasis'
                    : 'error.highEmphasis'
                }
              />
            }
            icon={Phone}
            description={supportData?.support?.tel}
          />
        )}
        {loading ? (
          <Skeleton />
        ) : (
          <ActionTile
            title={t('helpPage:cards.contactUs')}
            onClick={openMail}
            icon={Email}
            description={supportData?.support?.mailto}
          />
        )}
      </SimpleGrid>
    </Center>
  );
};

const PasswordResetTriggeredHint: React.FC<{ hideHint: () => void }> = ({
  hideHint,
}) => {
  const { t } = useTranslation(['general', 'helpPage', 'passwordReset']);

  return (
    <Box>
      <VStack alignItems={'stretch'} spacing={'4'}>
        <Heading size={'xl'}>
          {t('passwordReset:finishScreen.headline')}
        </Heading>
        <S.Caption children={t('passwordReset:finishScreen.hint')} />
        <Button
          alignSelf={'center'}
          children={t('passwordReset:finishScreen.tryAgain')}
          onClick={hideHint}
        />
      </VStack>
      <>
        <Divider my={'8'} />
        <HelpCenterActionTiles />
      </>
    </Box>
  );
};

export default PasswordResetTriggeredHint;
