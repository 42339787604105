import * as icon from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  AspectRatioProps,
  Box,
  BoxProps,
  Center,
  Icon,
  IconButton,
  IconButtonProps,
  Image,
  ImageProps,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DocumentStatusEnum,
  MediaPictureStatusEnum,
} from '../../../generated/graphql';
import { useMediaFlow } from '../../../provider/MediaFlowProvider';
import { useMediaProperties } from '../../../provider/MediaPropertiesProvider';
import { useMedia } from '../../../provider/MediaProvider';
import { AbsoluteFullCenter } from '../../Layout/AbsoluteFullCenter';
import { RelativeBox } from '../../Layout/RelativeBox/RelativeBox';
import { AnimatedVXLogo } from '../AnimatedVXLogo/AnimatedVXLogo';
import { ImageStatus } from '../ImageStatus/ImageStatus';
import {
  MediaPickerBox,
  MediaPickerIconButton,
} from '../MediaPickerButton/MediaPickerButton';
import { MediaStencilOverlay } from '../MediaUpload/MediaUpload';

export interface MediaPreviewBoxProps {
  aspectRatioProps?: AspectRatioProps;
  boxProps?: BoxProps;
  iconButtonProps?: Partial<IconButtonProps>;
  title?: string;
  children?: React.ReactNode;
  imageProps?: ImageProps;
  restrictToHeight?: AspectRatioProps['maxHeight'] | null;
  isAspectRatioDynamic?: boolean;
  isUploading?: boolean;
}
export const MediaPreviewBox: React.FC<MediaPreviewBoxProps> = ({
  aspectRatioProps,
  boxProps,
  iconButtonProps,
  imageProps,
  title,
  children,
  restrictToHeight = null,
  isAspectRatioDynamic = false,
  isUploading = false,
}) => {
  const {
    media: currentMedia,
    currentSrc,
    isInitialMediaLoading,
    status,
    initialSrc,
    initialSrcSet,
    isEditable,
    hasReplacement,
  } = useMedia();

  const { t } = useTranslation(['imageUpload']);

  const {
    isCircular,
    placeholderSrc,
    aspectRatio: aspectRatioFromProperties,
    named,
  } = useMediaProperties();

  const aspectRatio = React.useMemo(() => {
    const size = currentMedia?.size ?? null;

    if (!size || !isAspectRatioDynamic) {
      return aspectRatioFromProperties;
    }

    return Math.max((size?.width ?? 1) / (size?.height ?? 1), 1.0);
  }, [currentMedia, aspectRatioFromProperties, isAspectRatioDynamic]);

  const loading = isUploading || (isInitialMediaLoading && !!initialSrc);

  const isMissing = status === DocumentStatusEnum.Missing;
  const isPendingOrRejected = [
    MediaPictureStatusEnum.Pending,
    MediaPictureStatusEnum.Rejected,
  ].includes(status as MediaPictureStatusEnum);

  // @ts-ignore
  const maxHightProps: Partial<AspectRatioProps> = React.useMemo(() => {
    if (!restrictToHeight) {
      return {};
    }
    return {
      sx: {
        '--max-height': { base: '8.5rem', lg: '8.5rem' },
      },
      width: 'full',
      maxW: `calc(var(--max-height) * ${aspectRatio})`,
      maxH: 'var(--max-height)',
    };
  }, [restrictToHeight, aspectRatio]);

  const { as: iconButtonPropsAs, ...iconButtonPropsRest } = (iconButtonProps ??
    {}) as any;

  return (
    <Center>
      <AspectRatio
        width={'full'}
        ratio={aspectRatio}
        {...maxHightProps}
        {...aspectRatioProps}
        title={title ?? named}
        data-status={status}
        aria-busy={loading}
      >
        <RelativeBox
          attachment={
            isEditable ? (
              <MediaPickerIconButton
                variant={'solid'}
                aria-label={t('imageUpload:button.BildAuswahlen')}
                position={'absolute'}
                right={isCircular ? { base: 0 } : 2}
                bottom={isCircular ? { base: '5%' } : 2}
                {...iconButtonPropsRest}
              />
            ) : (
              <></>
            )
          }
        >
          <MediaPickerBox
            role={'group'}
            position={'absolute'}
            w={'full'}
            h={'full'}
            // unfortunatly we can't use inset: '0' here yet
            left={0}
            right={0}
            top={0}
            bottom={0}
            cursor={!isEditable ? 'unset' : 'pointer'}
            backgroundColor={!currentSrc ? 'steel' : 'gray.200'}
            backgroundSize={'contain'}
            backgroundPosition={'center'}
            border={!currentSrc ? 'dashed' : 'unset'}
            borderWidth={'1px'}
            borderRadius={isCircular ? 'full' : 'unset'}
            clipPath={isCircular ? 'circle()' : 'unset'}
            backgroundImage={
              !!currentSrc || !placeholderSrc
                ? 'unset'
                : `url(${placeholderSrc})`
            }
            filter={'auto'}
            brightness={
              isUploading ||
                (!hasReplacement && (isPendingOrRejected || isMissing))
                ? '40%'
                : 'unset'
            }
            sx={{ contain: 'none' }}
            {...boxProps}
          >
            {currentSrc ? (
              <>
                <Image
                  objectFit={'contain'}
                  src={
                    !hasReplacement && initialSrcSet ? undefined : currentSrc
                  }
                  srcSet={
                    !hasReplacement ? initialSrcSet ?? undefined : undefined
                  }
                  userSelect={'none'}
                  w={'full'}
                  h={'full'}
                  {...imageProps}
                />
                <MediaStencilOverlay />
              </>
            ) : (
              <AbsoluteFullCenter
                userSelect={'none'}
                pointerEvents={'none'}
                children={children}
              />
            )}
          </MediaPickerBox>
          {(loading || isPendingOrRejected || isMissing) && (
            <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
              <Box w={12}>
                {loading ? (
                  <AnimatedVXLogo />
                ) : (
                  <> {!hasReplacement && <ImageStatus status={status} />}</>
                )}
              </Box>
            </AbsoluteFullCenter>
          )}
        </RelativeBox>
      </AspectRatio>
    </Center>
  );
};

export const MediaPreviewFlowTriggerBox: React.FC<MediaPreviewBoxProps> = ({
  aspectRatioProps,
  boxProps,
  iconButtonProps,
  imageProps,
  title,
  children,
  restrictToHeight = null,
  isAspectRatioDynamic = false,
  isUploading = false,
}) => {
  const {
    media: currentMedia,
    currentSrc,
    isInitialMediaLoading,
    status,
    initialSrc,
    initialSrcSet,
    isEditable,
    hasReplacement,
  } = useMedia();

  const { t } = useTranslation(['imageUpload']);

  const {
    isCircular,
    placeholderSrc,
    aspectRatio: aspectRatioFromProperties,
    named,
  } = useMediaProperties();
  const mediaFlowCtx = useMediaFlow();

  const aspectRatio = React.useMemo(() => {
    const size = currentMedia?.size ?? null;

    if (!size || !isAspectRatioDynamic) {
      return aspectRatioFromProperties;
    }

    return Math.max((size?.width ?? 1) / (size?.height ?? 1), 1.0);
  }, [currentMedia, aspectRatioFromProperties, isAspectRatioDynamic]);

  const loading = isUploading || (isInitialMediaLoading && !!initialSrc);

  const isMissing = status === DocumentStatusEnum.Missing;
  const isPendingOrRejected = [
    MediaPictureStatusEnum.Pending,
    MediaPictureStatusEnum.Rejected,
  ].includes(status as MediaPictureStatusEnum);

  // @ts-ignore
  const maxHightProps: Partial<AspectRatioProps> = React.useMemo(() => {
    if (!restrictToHeight) {
      return {};
    }
    return {
      sx: {
        '--max-height': { base: '8.5rem', lg: '8.5rem' },
      },
      width: 'full',
      maxW: `calc(var(--max-height) * ${aspectRatio})`,
      maxH: 'var(--max-height)',
    };
  }, [restrictToHeight, aspectRatio]);

  const { as: iconButtonPropsAs, ...iconButtonPropsRest } = (iconButtonProps ??
    {}) as any;

  return (
    <Center>
      <AspectRatio
        width={'full'}
        ratio={aspectRatio}
        {...maxHightProps}
        {...aspectRatioProps}
        title={title ?? named}
        data-status={status}
        aria-busy={loading}
      >
        <RelativeBox
          attachment={
            isEditable ? (
              <IconButton
                onClick={mediaFlowCtx?.onOpen}
                as={iconButtonPropsAs ?? undefined}
                aria-label={t('imageUpload:button.BildAuswahlen')}
                position={'absolute'}
                variant={'solid'}
                right={isCircular ? { base: 0 } : 2}
                bottom={isCircular ? { base: '5%' } : 2}
                icon={<Icon as={icon.AddPhoto} boxSize={'icon.md'} />}
                {...iconButtonPropsRest}
              />
            ) : (
              <></>
            )
          }
        >
          <Box
            role={'group'}
            position={'absolute'}
            w={'full'}
            h={'full'}
            // unfortunatly we can't use inset: '0' here yet
            left={0}
            right={0}
            top={0}
            bottom={0}
            cursor={!isEditable ? 'unset' : 'pointer'}
            backgroundColor={!currentSrc ? 'steel' : 'gray.200'}
            backgroundSize={'contain'}
            backgroundPosition={'center'}
            border={!currentSrc ? 'dashed' : 'unset'}
            borderWidth={'1px'}
            borderRadius={isCircular ? 'full' : 'unset'}
            clipPath={isCircular ? 'circle()' : 'unset'}
            backgroundImage={
              !!currentSrc || !placeholderSrc
                ? 'unset'
                : `url(${placeholderSrc})`
            }
            filter={'auto'}
            brightness={
              isUploading ||
                (!hasReplacement && (isPendingOrRejected || isMissing))
                ? '40%'
                : 'unset'
            }
            onClick={!isEditable ? undefined : mediaFlowCtx?.onOpen}
            sx={{ contain: 'none' }}
            {...boxProps}
          >
            {currentSrc ? (
              <>
                <Image
                  objectFit={'contain'}
                  src={
                    !hasReplacement && initialSrcSet ? undefined : currentSrc
                  }
                  srcSet={
                    !hasReplacement ? initialSrcSet ?? undefined : undefined
                  }
                  userSelect={'none'}
                  w={'full'}
                  h={'full'}
                  {...imageProps}
                />
                <MediaStencilOverlay />
              </>
            ) : (
              <AbsoluteFullCenter
                userSelect={'none'}
                pointerEvents={'none'}
                children={children}
              />
            )}
          </Box>
          {(loading || isPendingOrRejected || isMissing) && (
            <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
              <Box w={12}>
                {loading ? (
                  <AnimatedVXLogo />
                ) : (
                  <> {!hasReplacement && <ImageStatus status={status} />}</>
                )}
              </Box>
            </AbsoluteFullCenter>
          )}
        </RelativeBox>
      </AspectRatio>
    </Center>
  );
};
