import { ContainerProps } from '@chakra-ui/react';
import React from 'react';

import { ImageGallery } from '../../../../../components/shared/ImageGallery/ImageGallery';
import { FeatureName, useFeature } from '../../../../../flags';
import { FeedPhotoPostFragment } from '../../../../../generated/feed';
import { useCyclicIndex } from '../../../../../hooks/useCyclicIndex';
import { PostFunctionProps } from '../../../posts';
import { PhotoPostBaseStructure } from '../component/PhotoPostBaseStructure';
import { TempFeedPostScheduleProp } from '../component/types';

export const PhotoGalleryPost: React.FC<
  {
    containerProps?: ContainerProps;
    children?: React.ReactNode;
    tags?: string[];
    formattedPrice?: string;
    isPostOwnedByUser?: boolean;
    isEditing?: boolean;
  } & TempFeedPostScheduleProp &
    FeedPhotoPostFragment &
    PostFunctionProps
> = ({
  containerProps,
  tags = [],
  formattedPrice = '',
  isPostOwnedByUser = true,
  isEditing,
  ...props
}) => {
  const firstRejected = props.photos
    ? props.photos.findIndex((photo) => photo.rejected) !== -1
      ? props.photos.findIndex((photo) => photo.rejected)
      : 0
    : 0;
  const {
    index: currentImageIndex,
    incrementIndex: incrementImageIndex,
    decrementIndex: decrementImageIndex,
    setIndex: setImageIndex,
  } = useCyclicIndex(props.photos?.length ?? 0, firstRejected);
  const currentVisiblePhoto = React.useMemo(
    () => props.photos?.at(currentImageIndex),
    [currentImageIndex, props.photos]
  );
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);
  const isMediaDimmedDown =
    feedTippingGoalFlag &&
    !!props.tippingGoal &&
    props.tippingGoal?.achieved === false;

  const hasPhoto = (props.photos?.length ?? 0) > 0;
  return hasPhoto && currentVisiblePhoto ? (
    <PhotoPostBaseStructure
      {...props}
      containerProps={containerProps}
      tags={tags}
      formattedPrice={formattedPrice}
      isPostOwnedByUser={isPostOwnedByUser}
      visiblePhoto={currentVisiblePhoto}
      setImageIndex={() => {
        setImageIndex(firstRejected);
      }}
      isEditing={isEditing}
    >
      <ImageGallery
        tags={tags}
        isDimmedDown={isMediaDimmedDown}
        images={props.photos ?? []}
        incrementImageIndex={incrementImageIndex}
        decrementImageIndex={decrementImageIndex}
        imageIndex={currentImageIndex}
        visiblePhoto={currentVisiblePhoto}
      />
    </PhotoPostBaseStructure>
  ) : (
    <></>
  );
};
