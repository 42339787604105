import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Flex,
  GridItem,
  Icon,
  SimpleGrid,
  SimpleGridProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RelativeBox } from '../../../../../components/Layout/RelativeBox/RelativeBox';
import { SectionCenterContainer } from '../../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../../components/Layout/SectionDivider';
import { CornerGuideDot } from '../../../../../components/shared/GuideDot/GuideDot';
import { MediaPreviewFlowTriggerBox } from '../../../../../components/shared/MediaPreviewBox/MediaPreviewBox';
import {
  MediaPictureStatusEnum,
  ProfileFieldTypePictureListFragment,
} from '../../../../../generated/graphql';
import { MediaFlowProvider } from '../../../../../provider/MediaFlowProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { MediaUploadProvider } from '../../../../../provider/MediaUploadProvider';
import { CommonMediaFlowModal } from '../../../components/CommonMediaFlowModal/CommonMediaFlowModal';
import { useModelProfile } from '../../../provider/ModelProfileProvider';
import { ImageRatings } from '../../types';

const SedcardPreviewBox: React.FC = () => {
  const { t } = useTranslation(['imageUpload']);
  return (
    <MediaPreviewFlowTriggerBox
      boxProps={{
        borderRadius: 'lg',
        border: 'solid',
        borderColor: 'steel',
      }}
      iconButtonProps={{
        display: 'none',
      }}
      imageProps={{ borderRadius: 'lg', sizes: '300px' }}
    >
      <Flex flexDir={'column'} alignItems={'center'} color={'gray.900'}>
        <Icon as={icons.AddPhoto} boxSize="icon.md" />

        <Box>
          <Text textStyle={'bodySm'} as={'span'}>
            {t('imageUpload:text.BildHochladen')}
          </Text>
        </Box>
      </Flex>
    </MediaPreviewFlowTriggerBox>
  );
};

const slots = [0, 1, 2, 3] as const;

const SedcardSimpleGrid: React.FC<SimpleGridProps> = (props) => (
  <SimpleGrid
    columns={{ base: 2, md: 4 }}
    columnGap={4}
    rowGap={4}
    {...props}
  />
);

const SedcardMediaFlowModal: React.FC = () => {
  return (
    <CommonMediaFlowModal
      mediaPickerPreviewBoxProps={{
        boxProps: {
          borderRadius: 'lg',
        },
        imageProps: {
          sizes: '300px',
        },
      }}
    />
  );
};

const preparePictureListPositioning = (
  field: Maybe<ProfileFieldTypePictureListFragment>
) => {
  const pictures = field?.value ?? [];
  const slotSet = new Set(slots);
  // Use last slot, when all slots are taken
  const maxSlot = Math.max(...Array.from(slotSet));
  for (const picture of pictures) {
    slotSet.delete(picture.position as any);
  }
  return {
    minimumNonTakenPosition: Math.min(...Array.from(slotSet), maxSlot),
    slotMappedPictures: slots.map(
      (slot) => pictures?.find(({ position }) => slot === position) ?? null
    ),
  };
};

const SedcardFSK16Group: React.FC<{
  field: Maybe<ProfileFieldTypePictureListFragment>;
}> = ({ field }) => {
  const {
    isGuideDotVisible,
    actions: { saveProfileData },
  } = useModelProfile();

  const onPersist = React.useCallback(
    async (id: number) => {
      await saveProfileData({
        mediaData: {
          modelPictureFSK16Sedcard: id,
        },
      });
    },
    [saveProfileData]
  );

  const { minimumNonTakenPosition, slotMappedPictures } = React.useMemo(
    () => preparePictureListPositioning(field),
    [field]
  );

  return (
    <MediaPropertiesProvider.ForSedcardPicture imageRating={ImageRatings.FSK16}>
      <SedcardSimpleGrid>
        {slotMappedPictures.map((picture, index) => {
          const position = picture?.position ?? minimumNonTakenPosition;
          return (
            <GridItem key={index} data-picture-position={position}>
              <MediaProvider.FromMediaPicture
                picture={picture}
                initialSize={{ width: 400, height: 300 }}
              >
                <MediaFlowProvider>
                  <MediaUploadProvider.ForSedcard
                    field={field}
                    position={position}
                    onPersist={onPersist}
                  >
                    <RelativeBox
                      attachment={
                        <CornerGuideDot
                          isShown={
                            isGuideDotVisible &&
                            calcGuideDotVisibility(position, field)
                          }
                        />
                      }
                    >
                      <SedcardPreviewBox />
                    </RelativeBox>
                    <SedcardMediaFlowModal />
                  </MediaUploadProvider.ForSedcard>
                </MediaFlowProvider>
              </MediaProvider.FromMediaPicture>
            </GridItem>
          );
        })}
      </SedcardSimpleGrid>
    </MediaPropertiesProvider.ForSedcardPicture>
  );
};

const SedcardFSK18Group: React.FC<{
  field: Maybe<ProfileFieldTypePictureListFragment>;
}> = ({ field }) => {
  const {
    actions: { saveProfileData },
  } = useModelProfile();

  const onPersist = React.useCallback(
    async (id: number) => {
      await saveProfileData({
        mediaData: {
          modelPictureFSK18Sedcard: id,
        },
      });
    },
    [saveProfileData]
  );

  const { minimumNonTakenPosition, slotMappedPictures } = React.useMemo(
    () => preparePictureListPositioning(field),
    [field]
  );

  return (
    <MediaPropertiesProvider.ForSedcardPicture imageRating={ImageRatings.FSK18}>
      <SedcardSimpleGrid>
        {slotMappedPictures.map((picture, index) => {
          const position = picture?.position ?? minimumNonTakenPosition;
          return (
            <GridItem key={index} data-picture-position={position}>
              <MediaProvider.FromMediaPicture picture={picture}>
                <MediaFlowProvider>
                  <MediaUploadProvider.ForSedcard
                    field={field}
                    position={position}
                    onPersist={onPersist}
                  >
                    <SedcardPreviewBox />
                    <SedcardMediaFlowModal />
                  </MediaUploadProvider.ForSedcard>
                </MediaFlowProvider>
              </MediaProvider.FromMediaPicture>
            </GridItem>
          );
        })}
      </SedcardSimpleGrid>
    </MediaPropertiesProvider.ForSedcardPicture>
  );
};

const usePictureListCount = (
  field: Maybe<ProfileFieldTypePictureListFragment>
) => {
  const goal = React.useMemo(
    () => Math.max(field?.value?.length ?? 0, 4),
    [field]
  );
  const count = React.useMemo(
    () =>
      Math.min(
        field?.value?.reduce(
          (acc, curr) =>
            [
              MediaPictureStatusEnum.Ok,
              MediaPictureStatusEnum.Pending,
            ].includes(curr?.status as MediaPictureStatusEnum)
              ? acc + 1
              : acc,
          0
        ) ?? 0,
        goal
      ),
    [field, goal]
  );

  return { goal, count };
};

const SectionTitleFSK16: React.FC<{
  field: Maybe<ProfileFieldTypePictureListFragment>;
}> = ({ field }) => {
  const { t } = useTranslation(['modelProfile']);
  const { goal, count } = usePictureListCount(field);

  return (
    <SectionTitle fontSize={'xl'} lineHeight={'8'}>
      {t(
        'modelProfile:heading.U16AufreizendSoftCoreSedcardBilderCountVonGoal',
        { count, goal }
      )}
    </SectionTitle>
  );
};

const SectionTitleFSK18: React.FC<{
  field: Maybe<ProfileFieldTypePictureListFragment>;
}> = ({ field }) => {
  const { t } = useTranslation(['modelProfile']);
  const { goal, count } = usePictureListCount(field);

  return (
    <SectionTitle fontSize={'xl'} lineHeight={'8'}>
      {t('modelProfile:heading.18BlankHardCoreSedcardBilderCountVonGoal', {
        count,
        goal,
      })}
    </SectionTitle>
  );
};

export const SedcardSection: React.FC = () => {
  const { t } = useTranslation(['modelProfile']);
  const { isSaveDataLoading, mediaCollection } = useModelProfile();

  const fields = React.useMemo(
    () => mediaCollection?.fields ?? {},
    [mediaCollection]
  );

  return (
    <VStack w={'full'} spacing={{ base: 4, lg: 8 }} alignItems={'Stretch'}>
      <Section aria-busy={isSaveDataLoading}>
        <SectionHeader pb={'4'}>
          <SectionCenterContainer>
            <SectionTitleRow>
              <SectionIcon as={icons.AssignmentInd} />
              <SectionTitleFSK16 field={fields.modelPictureFSK16Sedcard} />
            </SectionTitleRow>
            <SectionDescription fontSize={'md'}>
              {t('modelProfile:text.ZeigeDichHierMaxObenOhne')}
            </SectionDescription>
          </SectionCenterContainer>
        </SectionHeader>
        <SectionDivider isWidthRestricted />
        <SectionBody>
          <SectionCenterContainer>
            <SedcardFSK16Group field={fields.modelPictureFSK16Sedcard} />
          </SectionCenterContainer>
        </SectionBody>
      </Section>
      <Section aria-busy={isSaveDataLoading}>
        <SectionHeader pb={'4'}>
          <SectionCenterContainer>
            <SectionTitleRow>
              <SectionIcon as={icons.AssignmentInd} />
              <SectionTitleFSK18 field={fields.modelPictureFSK18Sedcard} />
            </SectionTitleRow>
            <SectionDescription fontSize={'md'}>
              {t(
                'modelProfile:text.ZeigeSoVielDuWillstLasseDabeiEtwasFreiraumFurNeugierUndFantasieSodasXX'
              )}
            </SectionDescription>
          </SectionCenterContainer>
        </SectionHeader>
        <SectionDivider isWidthRestricted />
        <SectionBody>
          <SectionCenterContainer>
            <SedcardFSK18Group field={fields.modelPictureFSK18Sedcard} />
          </SectionCenterContainer>
        </SectionBody>
      </Section>
    </VStack>
  );
};

const calcGuideDotVisibility = (
  position: number,
  field?: ProfileFieldTypePictureListFragment | null
) =>
  ![MediaPictureStatusEnum.Ok, MediaPictureStatusEnum.Pending].includes(
    field?.value?.[position]?.status as MediaPictureStatusEnum
  );
