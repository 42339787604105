import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { Upload } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Circle,
  Container,
  Heading,
  Icon,
  Img,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import UploadButton from '@rpldy/upload-button';
import UploadDropZone from '@rpldy/upload-drop-zone';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PostOnFeedImage from '../../assets/images/shared/feed-preview.svg';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutHintsVStack } from '../../components/Layout/LayoutHintsVStack';
import { AnimatedVXLogo } from '../../components/shared/AnimatedVXLogo/AnimatedVXLogo';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import {
  ChakraUniversalCard,
  UniversalCardSection,
} from '../../components/shared/cards/UniversalCard/UniversalCard';
import { VideoTypeEnum } from '../../generated/graphql';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { useCreateFeedPostContext } from '../../provider/FeedPostCreateModalProvider';
import { useFlagsProviderV2 } from '../../provider/FlagsProviderV2';
import { useVideoLibraryPhotoContext } from '../../provider/VideoLibraryProvider/VideoLibraryPhotoProvider/VideoLibraryPhotoProvider';
import {
  photosTabId,
  useUploadyService,
} from '../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { useVideoLibraryVideoContext } from '../../provider/VideoLibraryProvider/VideoLibraryVideoProvider/VideoLibraryVideoProvider';
import { VideoLibraryEntryDialog } from './VideoLibraryEntryDialog';
import { MarketingVideosSection } from './components/MarketingVideoSection/MarketingVideoSection';
import { VideoLibraryPageTabSection } from './components/VideoLibraryPageTabSection/VideoLibraryPageTabSection';

const VideoLibraryPage: React.FC = () => {
  const { t } = useTranslation(['videoLibrary']);
  const {
    isUploadAllowed,
    initialLoading: unusedLoading,
    tabsAction,
  } = useUploadyService();
  const { initialLoading: videosLoading, marketingVideos } =
    useVideoLibraryVideoContext();
  const { initialLoading: photosLoading } = useVideoLibraryPhotoContext();
  const videoLibraryEntryDialog = useDisclosure({ defaultIsOpen: false });
  const [playingVideoId, setPlayingVideoId] = React.useState<string | null>(
    null
  );
  const { coinsEnabled } = useFlagsProviderV2();

  const spacing = { base: 4, lg: 8 };
  return (
    <FluentPageLayout isContentLoading={false}>
      <VideoLibraryEntryDialog
        isOpen={videoLibraryEntryDialog.isOpen}
        onClose={videoLibraryEntryDialog.onClose}
      />
      <Box>
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle p={0}>
              {t('videoLibrary:heading.Videothek')}
            </SubHeaderTitle>
          </Container>
        </SubHeader>
        <Container maxW="container.xl" p={0}>
          <VStack spacing={spacing} w={'full'} mb={spacing}>
            <LayoutHintsVStack>
              <UpdateTaxInformationExpiredHintSlot
                outerStackProps={{ pt: spacing }}
              />
            </LayoutHintsVStack>
            {isUploadAllowed && tabsAction.tabId !== photosTabId && (
              <VideoLibraryPageUploadVideoCard />
            )}
            {!coinsEnabled && tabsAction.tabId === photosTabId && (
              <VideoLibraryPageUploadPhotoCard />
            )}
            {marketingVideos.length > 0 && (
              <MarketingVideosSection
                videos={marketingVideos}
                playingVideoId={playingVideoId}
                setPlayingVideoId={setPlayingVideoId}
              />
            )}
            {unusedLoading || videosLoading || photosLoading ? (
              <Center>
                <Box w={'96px'} h={'96px'}>
                  <AnimatedVXLogo />
                </Box>
              </Center>
            ) : (
              <VideoLibraryPageTabSection
                playingVideoId={playingVideoId}
                setPlayingVideoId={setPlayingVideoId}
              />
            )}
          </VStack>
        </Container>
      </Box>
    </FluentPageLayout>
  );
};

export default VideoLibraryPage;

export const UploadDropZoneContent: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Box
      borderWidth={'1px'}
      borderColor={`steel`}
      borderStyle={'dashed'}
      borderRadius={'md'}
      bg={'surface'}
      w={'min-content'}
      sx={{
        '.drag-over &': {
          borderColor: `primary.500`,
        },
      }}
      cursor={'pointer'}
    >
      <VStack textAlign={'center'} px={'40px'} py={'16px'}>
        <Circle size={'32px'} border={'1px'} borderColor={'primary.500'}>
          <Icon
            as={Upload}
            height={'15px'}
            width={'15px'}
            color={`primary.500`}
          />
        </Circle>
        <Text fontSize={'12px'} color={`primary.500`} minW={'100px'}>
          {text}
        </Text>
      </VStack>
    </Box>
  );
};

export const UploadButtonDropZone: typeof UploadButton = (props) => {
  const { t } = useTranslation(['videoLibrary']);
  return (
    <UploadButton {...props}>
      <UploadDropZone {...props} onDragOverClassName="drag-over">
        <UploadDropZoneContent
          text={t('videoLibrary:text.DragDropOderVideoAuswahlen')}
        />
      </UploadDropZone>
    </UploadButton>
  );
};

const VideoLibraryPageUploadVideoCard: React.FC<{}> = () => {
  const { t } = useTranslation(['videoLibrary']);
  return (
    <UniversalCardSection>
      <ChakraUniversalCard
        py={6}
        px={4}
        minH={'248px'}
        //this content is like in BlockerCard.tsx for example -> for consistency reasons
        //should also have consistent style with upload box in VideoLibraryUploadListEntryPreviewPicture
        cardBody={
          <VStack gap={0}>
            <Heading
              fontSize={'16px'}
              fontWeight={'medium'}
              h={'min'}
              p={0}
              size={'sm'}
              textAlign={'center'}
            >
              {t('videoLibrary:heading.VideoHochladen')}
            </Heading>
            <Text
              color={'gray.500'}
              fontSize={'14px'}
              p={0}
              textAlign={'center'}
            >
              {t('videoLibrary:text.NichtKurzerAls1MinutenUndMax50GB')}
            </Text>
          </VStack>
        }
        cardFooter={
          <VStack gap={4} pb={2}>
            <UploadButtonDropZone params={{ type: VideoTypeEnum.VideoShop }} />
          </VStack>
        }
      />
    </UniversalCardSection>
  );
};

const VideoLibraryPageUploadPhotoCard: React.FC<{}> = () => {
  const { action } = useCreateFeedPostContext();
  const { isAllowedToPostOnFeed } = useAccountStatus();
  const { t } = useTranslation(['videoLibrary']);
  return (
    <UniversalCardSection>
      <ChakraUniversalCard
        py={6}
        px={4}
        minH={'248px'}
        overflow={'hidden'}
        //this content is like in BlockerCard.tsx for example -> for consistency reasons
        //should also have consistent style with upload box in VideoLibraryUploadListEntryPreviewPicture
        cardBody={
          <VStack>
            <Img
              position={'relative'}
              overflow={'hidden'}
              src={PostOnFeedImage}
              alt={t('videoLibrary:text.FrauMachtSelfie')}
              h={'90px'}
            />
            <VStack gap={0}>
              <Heading
                fontSize={'16px'}
                fontWeight={'medium'}
                h={'min'}
                p={0}
                size={'sm'}
                textAlign={'center'}
              >
                {t(
                  'videoLibrary:heading.DuMochtestDeineFotosTeilenUndVerkaufen'
                )}
              </Heading>
              <Text
                color={'gray.500'}
                fontSize={'14px'}
                p={0}
                textAlign={'center'}
              >
                {t('videoLibrary:text.DannNutzeJetztDeinenFeed')}
              </Text>
            </VStack>
          </VStack>
        }
        cardFooter={
          <VStack gap={0}>
            <Button
              w={'64'}
              maxW={'full'}
              variant={'solid'}
              shadow={'none'}
              children={t('videoLibrary:button.BeitragErstellen')}
              onClick={() => {
                if (!isAllowedToPostOnFeed) return;
                action.openModal();
              }}
            />
          </VStack>
        }
      />
    </UniversalCardSection>
  );
};
