// SharingContext.tsx
import { DateTime } from 'luxon';
import React, { createContext, useContext, useMemo } from 'react';

import {
  SharingStatisticsFragment,
  SharingTypeEnum,
  useGetSharingStatisticsQuery,
} from '../../../generated/graphql';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from './TabContext';

type SharingContextType = {
  sharingStatistics: Partial<SharingStatisticsFragment>;
  loading?: boolean;
};

const SharingContext = createContext<SharingContextType | undefined>(undefined);

export const useSharingContext = () => {
  const context = useContext(SharingContext);
  if (!context) {
    throw new Error('useSharingContext must be used within a SharingProvider');
  }
  return context;
};

export const SharingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];

  const sharingType = useMemo(() => {
    return tabId === StatisticsDetailedCategoryExtEnum.LiveChat ||
      tabId === StatisticsDetailedCategoryExtEnum.Messenger
      ? SharingTypeEnum.Chat
      : SharingTypeEnum.Shop;
  }, [tabId]);

  const skipRequestWhen =
    !tabId ||
    tabId === StatisticsDetailedCategoryExtEnum.OverAll ||
    tabId === StatisticsDetailedCategoryExtEnum.Affiliate ||
    tabId === StatisticsDetailedCategoryExtEnum.PhoneService ||
    tabId === StatisticsDetailedCategoryExtEnum.Telegram ||
    tabId === StatisticsDetailedCategoryExtEnum.Misc;
  const memoizedDate = useMemo(() => DateTime.now(), []);
  const { data: sharingData, loading } = useGetSharingStatisticsQuery({
    variables: {
      date: memoizedDate,
      type: sharingType,
    },
    skip: skipRequestWhen,
  });

  const sharingStatistics = useMemo(() => {
    return sharingData?.sharing?.status ?? {};
  }, [sharingData]);

  return (
    <SharingContext.Provider value={{ sharingStatistics, loading }}>
      {children}
    </SharingContext.Provider>
  );
};
