import {
  Section,
  SectionBody,
  SectionDescription,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Divider,
  Heading,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import { FormikConfig } from 'formik/dist/types';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import {
  ClearableInputControl,
  RadioGroupControl,
} from '../../../../components/shared/FormikFormElements';
import { HelpLink } from '../../../../components/shared/HelpLink/HelpLink';
import { TipCard } from '../../../../components/shared/cards/TipCard/TipCard';
import {
  FinancesPayoutMethodEnum,
  ProfileFieldsEnum,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { useBicAutocomplete } from '../../../../hooks/useBicAutocomplete';
import { propertiesToBoolean2 } from '../../../../hooks/useFormikErrorSetter';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { externalRoutes } from '../../../../routes/routesConfig';
import { noop } from '../../../../utils';
import { createStringValidationSchema } from '../../../../utils/validation';
import { extractCurrentFieldError } from '../../../Wizard/utils/utils';
import { PayoutPendingAlert } from '../../components/alert/PayoutPendingAlert/PayoutPendingAlert';
import { PayoutRejectAlert } from '../../components/alert/PayoutRejectAlert/PayoutRejectAlert';

const PayoutMethodPendingAlert = () => {
  const { t } = useTranslation(['payout']);
  const lang = useActiveApiLanguage();
  return (
    <PayoutPendingAlert
      alertDescription={t(
        'payout:text.DeineNeueAuszahlungsmethodeWirdUberpruft'
      )}
      modalHeading={t('payout:headline.DeineAnderungenWerdenUberpruft')}
      modalTextSlot={
        <Text>
          {t('payout:text.WirMeldenUnsBeiDirSobaldEsNeuigkeitenGibt')}
        </Text>
      }
      modalHelpLinkSlot={
        <HelpLink
          // todo: Link propper Zendesk article
          href={externalRoutes.vxModelsZendeskOverview(lang)}
        >
          {t(
            'payout:text.WieLangeDauertDieUberprufungMeinerAuszahlungsmethode'
          )}
        </HelpLink>
      }
    />
  );
};

const PayoutMethodRejectAlert: React.FC<{
  rejectReason?: Maybe<string>;
}> = ({ rejectReason }) => {
  const { t } = useTranslation(['payout']);
  const lang = useActiveApiLanguage();
  return (
    <PayoutRejectAlert
      alertDescription={t(
        'payout:text.DeineNeueAuszahlungsmethodeWurdeAbgelehnt'
      )}
      modalHeading={t('payout:headline.DeineAuszahlungsmethodeWurdeAbgelehnt')}
      modalTextSlot={
        <Text>{t('payout:text.GrundReason', { reason: rejectReason })}</Text>
      }
      modalHelpLinkSlot={
        <HelpLink
          // todo: Link propper Zendesk article
          href={externalRoutes.vxModelsZendeskOverview(lang)}
        >
          {t('payout:text.WiesoWurdeMeineAuszahlungsmethodeAbgelehnt')}
        </HelpLink>
      }
      modalFooterSlot={(onClose) => (
        <Button onClick={onClose}>
          {t('payout:button.NochEinmalVersuchen')}
        </Button>
      )}
    />
  );
};

const fieldName = {
  transationType: ProfileFieldsEnum.PayoutTransactionType,
  bankaccountOwner: ProfileFieldsEnum.PayoutBankaccountOwner,
  iban: ProfileFieldsEnum.PayoutIban,
  bic: ProfileFieldsEnum.PayoutBic,
  paxumEmail: ProfileFieldsEnum.PayoutPaxumEmailAccount,
};

export const PayoutMethodSection: React.FC<{}> = (props) => {
  const { t } = useTranslation(['payout', 'general', 'wizardPayout']);

  const financeServiceCtx = useFinanceService();
  const {
    fields,
    isPayoutMethodUpdateEditAllowed,
    forcedPayoutMethod,
    isPayoutMethodActiveTransactionTypeUnallowd,
    action: { updatePayoutCollection },
  } = financeServiceCtx;

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  // Beware! These might be still loading
  const isBankTransferDisabled =
    financeServiceCtx.isPayoutMethodBanktransferDisabled;
  const isPaxumDisabled = financeServiceCtx.isPayoutMethodPaxumDisabled;

  const confirmDialogDisc = useDisclosure();

  const initialValues = React.useMemo(() => {
    return {
      [fieldName.transationType]:
        forcedPayoutMethod ??
        fields.payoutTransactionType?.value ??
        FinancesPayoutMethodEnum.Bank,
      [fieldName.bankaccountOwner]: fields.payoutBankaccountOwner?.value ?? '',
      [fieldName.iban]: fields.payoutIban?.value ?? '',
      [fieldName.bic]: fields.payoutBic?.value ?? '',
      [fieldName.paxumEmail]: fields.payoutPaxumEmailAccount?.value ?? '',
    };
  }, [fields, forcedPayoutMethod]);

  const onSubmit = React.useCallback<
    FormikConfig<typeof initialValues>['onSubmit']
  >(
    async (values, formikHelpers) => {
      switch (values[fieldName.transationType]) {
        case FinancesPayoutMethodEnum.Bank:
          {
            const errors = await updatePayoutCollection({
              [ProfileFieldsEnum.PayoutTransactionType]: values[
                fieldName.transationType
              ] as FinancesPayoutMethodEnum,
              [ProfileFieldsEnum.PayoutBankaccountOwner]:
                values[fieldName.bankaccountOwner],
              [ProfileFieldsEnum.PayoutIban]: values[fieldName.iban],
              [ProfileFieldsEnum.PayoutBic]: values[fieldName.bic],
            });
            if (!errors) {
              return;
            }
            const e = extractCurrentFieldError(errors.field);

            formikHelpers.setErrors(e as any);
            formikHelpers.setTouched(propertiesToBoolean2(e), false);
          }
          break;
        case FinancesPayoutMethodEnum.Paxum:
          {
            const errors = await updatePayoutCollection({
              [ProfileFieldsEnum.PayoutTransactionType]: values[
                fieldName.transationType
              ] as FinancesPayoutMethodEnum,
              [ProfileFieldsEnum.PayoutPaxumEmailAccount]:
                values[fieldName.paxumEmail],
            });

            if (!errors) {
              return;
            }
            const e = extractCurrentFieldError(errors.field);

            formikHelpers.setErrors(e as any);
            formikHelpers.setTouched(propertiesToBoolean2(e), false);
          }
          break;
        default:
      }
    },
    [updatePayoutCollection]
  );

  const initialTransactionTypesDiffer =
    isPayoutMethodActiveTransactionTypeUnallowd;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: noop,
  });

  const banktransferFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      [fieldName.transationType]: FinancesPayoutMethodEnum.Bank,
    },
    onSubmit: onSubmit,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      [fieldName.bankaccountOwner]: createStringValidationSchema({
        minLength: fields?.payoutBankaccountOwner?.minLength ?? 1,
        maxLength: fields?.payoutBankaccountOwner?.maxLength ?? 70,
      }),
      [fieldName.iban]: createStringValidationSchema({
        minLength: fields?.payoutIban?.minLength ?? 5,
        maxLength: fields?.payoutIban?.maxLength ?? 34,
      }),
      [fieldName.bic]: createStringValidationSchema({
        minLength: fields?.payoutBic?.minLength ?? 8,
        maxLength: fields?.payoutBic?.maxLength ?? 11,
      }),
    }),
  });

  const {
    values: { [fieldName.iban]: currentIban },
    setFieldValue,
  } = banktransferFormik;

  useBicAutocomplete({
    disabled: !banktransferFormik.touched[fieldName.iban],
    iban: currentIban,
    onMatch: ({ iban, bic }) => {
      if (iban !== currentIban || !bic) {
        return;
      }
      setFieldValue(fieldName.bic, bic, true);
    },
  });

  const paxumFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      [fieldName.transationType]: FinancesPayoutMethodEnum.Paxum,
    },
    validateOnBlur: true,
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      [fieldName.paxumEmail]: createStringValidationSchema({
        minLength: fields?.payoutPaxumEmailAccount?.minLength ?? 1,
        maxLength: fields?.payoutPaxumEmailAccount?.maxLength ?? 60,
        isEmail: true,
      }),
    }),
  });

  const { resetForm } = formik;
  const { resetForm: resetBanktransferForm } = banktransferFormik;
  const { resetForm: resetPaxumForm } = paxumFormik;

  const onOmitChanges = React.useCallback(() => {
    resetForm();
    resetBanktransferForm();
    resetPaxumForm();
  }, [resetForm, resetBanktransferForm, resetPaxumForm]);

  const isDirty =
    formik.dirty ||
    (formik.values[fieldName.transationType] === FinancesPayoutMethodEnum.Bank
      ? banktransferFormik.dirty
      : paxumFormik.dirty);

  React.useEffect(() => {
    return registerDirtyFlag(isDirty, onOmitChanges);
  }, [isDirty, registerDirtyFlag, onOmitChanges]);

  const isReadOnly =
    !isPayoutMethodUpdateEditAllowed ||
    (!financeServiceCtx.isPayoutMethodUpdateRejected &&
      financeServiceCtx.isPayoutMethodUpdatePending);

  const loading = banktransferFormik.isSubmitting || paxumFormik.isSubmitting;

  const isSubmittingDisabled =
    isReadOnly ||
    !formik.isValid ||
    (!isDirty && !initialTransactionTypesDiffer);

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={icons.AccountBalance} />
            <SectionTitle children={t('payout:headline.DahinGehtDeinGeld')} />
          </SectionTitleRow>
          <SectionDescription
            children={
              formik.values[ProfileFieldsEnum.PayoutTransactionType] ===
              FinancesPayoutMethodEnum.Bank
                ? t(
                    'payout:text.DuHastDieAuszahlungsmethodeBankuberweisungGewahlt'
                  )
                : t('payout:text.DuHastDieAuszahlungsmethodePaxumGewahlt')
            }
          />
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody>
        <SectionCenterContainer>
          <VStack alignItems={'stretch'} spacing={8}>
            {!financeServiceCtx.isPayoutMethodUpdateRejected &&
              financeServiceCtx.isPayoutMethodUpdatePending && (
                <PayoutMethodPendingAlert />
              )}
            {financeServiceCtx.isPayoutMethodUpdateRejected && (
              <PayoutMethodRejectAlert
                rejectReason={financeServiceCtx.payoutMethodUpdateRejectReason}
              />
            )}
            <VStack divider={<Divider />} spacing={8}>
              {!financeServiceCtx.forcedPayoutMethod && (
                <FormikProvider value={formik}>
                  <RadioGroupControl
                    name={ProfileFieldsEnum.PayoutTransactionType}
                    options={[
                      {
                        isDisabled: isBankTransferDisabled,
                        isReadOnly,
                        children: t('payout:radio.Bankuberweisung'),
                        value: FinancesPayoutMethodEnum.Bank,
                      },
                      {
                        isDisabled: isPaxumDisabled,
                        isReadOnly,
                        children: t('payout:radio.Paxum'),
                        value: FinancesPayoutMethodEnum.Paxum,
                      },
                    ]}
                  />
                </FormikProvider>
              )}
              {formik.values[ProfileFieldsEnum.PayoutTransactionType] ===
              FinancesPayoutMethodEnum.Bank ? (
                <FormikProvider value={banktransferFormik}>
                  <VStack
                    w={'full'}
                    key={FinancesPayoutMethodEnum.Bank}
                    divider={<Divider />}
                  >
                    <ClearableInputControl
                      name={fieldName.bankaccountOwner}
                      label={t('payout:label.Kontoinhaber')}
                      inputProps={{
                        placeholder: t('payout:placeholder.NamenEingeben'),
                      }}
                      isReadOnly={isReadOnly}
                    />
                    <ClearableInputControl
                      name={fieldName.iban}
                      label={t('payout:label.IBAN')}
                      inputProps={{
                        placeholder: t('payout:placeholder.IBANEingeben'),
                      }}
                      isReadOnly={isReadOnly}
                    />
                    <ClearableInputControl
                      name={fieldName.bic}
                      label={t('payout:label.BIC')}
                      inputProps={{
                        placeholder: t('payout:placeholder.BICEingeben'),
                      }}
                      isReadOnly={isReadOnly}
                    />
                  </VStack>
                </FormikProvider>
              ) : (
                <FormikProvider value={paxumFormik}>
                  <VStack
                    w={'full'}
                    key={FinancesPayoutMethodEnum.Paxum}
                    divider={<Divider />}
                  >
                    <ClearableInputControl
                      helperText={t(
                        'wizardPayout:text.VergewissereDichBitteKeinenTippfehlerGemachtZuHaben'
                      )}
                      label={t(`payout:label.EMailAdresseDeinesPaxumAccounts`)}
                      name={fieldName.paxumEmail}
                      inputProps={{
                        autoComplete: 'off',
                        placeholder: t(`payout:placeholder.PaxumEMailEingeben`),
                        type: 'email',
                      }}
                      isReadOnly={isReadOnly}
                    />
                    <Box w={'full'} mt={12}>
                      <TipCard
                        text={t(
                          'wizardPayout:text.WennSieNichtInDerSEPAZoneLebenEmpfehlenWirIhnenPaxumZuWahlenAufDieseXX'
                        )}
                      />
                    </Box>
                  </VStack>
                </FormikProvider>
              )}
            </VStack>
          </VStack>
        </SectionCenterContainer>
      </SectionBody>
      <SectionDivider isWidthRestricted />
      <SectionFooter>
        <SectionCenterContainer>
          <Button
            alignSelf={'center'}
            isDisabled={isSubmittingDisabled}
            isLoading={loading}
            onClick={confirmDialogDisc.onOpen}
          >
            {t('payout:button.Speichern')}
          </Button>
          <ResponsiveModal
            isOpen={confirmDialogDisc.isOpen}
            onClose={confirmDialogDisc.onClose}
          >
            <ResponsiveModalOverlay />
            <ResponsiveModalContent>
              <ResponsiveModalStickyHeaderBox>
                <Heading size={'xl'}>
                  {t(
                    'payout:headline.MochtestDuDeineAnderungenZurUberprufungEinreichen'
                  )}
                </Heading>
              </ResponsiveModalStickyHeaderBox>
              <ResponsiveModalBodyBox>
                <Text>
                  {t(
                    'payout:text.WennDuFortfahrstStartetEinUberprufungsprozessDerEtwasZeitInAnspruchNXX'
                  )}
                </Text>
                <Text>
                  {t(
                    'payout:text.DeineAltenAngabenBleibenErhaltenSolangeDeineAnderungenNichtAkzeptierXX'
                  )}
                </Text>
              </ResponsiveModalBodyBox>
              <ResponsiveModalStickyFooterBox>
                <VStack>
                  <Button
                    onClick={() => {
                      confirmDialogDisc.onClose();
                      switch (formik.values[fieldName.transationType]) {
                        case FinancesPayoutMethodEnum.Bank:
                          banktransferFormik.handleSubmit();
                          break;
                        case FinancesPayoutMethodEnum.Paxum:
                          paxumFormik.handleSubmit();
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    {t('payout:button.SpeichernEinreichen')}
                  </Button>
                  <Button onClick={confirmDialogDisc.onClose} flexShrink={1}>
                    {t('payout:button.AnderungenNochEinmalKontrollieren')}
                  </Button>
                </VStack>
              </ResponsiveModalStickyFooterBox>
            </ResponsiveModalContent>
          </ResponsiveModal>
        </SectionCenterContainer>
      </SectionFooter>
    </Section>
  );
};
