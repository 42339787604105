import { Error as ErrorIcon } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  ButtonProps,
  Center,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import {
  PrimaryButton,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../../components/Layout/ResponsiveModal';
import { MediaPickerButton } from '../../../../../components/shared/MediaPickerButton/MediaPickerButton';
import {
  MediaPickerPreviewBox,
  MediaUploadCropperPortal,
} from '../../../../../components/shared/MediaUpload/MediaUpload';
import {
  DocumentStatusEnum,
  MediaPictureStatusEnum,
} from '../../../../../generated/graphql';
import { useMediaFlow } from '../../../../../provider/MediaFlowProvider';
import { useMediaProperties } from '../../../../../provider/MediaPropertiesProvider';
import { MediaContext, useMedia } from '../../../../../provider/MediaProvider';
import { useMediaUpload } from '../../../../../provider/MediaUploadProvider';
import { MediaFlowModal } from '../../../../ModelProfileV2Page/components/MediaFlowModal/MediaFlowModal';

/**
 * !Must be enclosed by:
 * - **{@link MediaProvider}**
 * - **{@link MediaUploadProvider}**
 */
const MediaUploadButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { media } = useMedia();
  const { onClose } = useMediaFlow();
  const { isUploading, action } = useMediaUpload();

  const blob = media?.blob;
  const upload = React.useCallback(async () => {
    if (!blob) {
      return;
    }
    await action.upload(blob);
    onClose();
  }, [action, blob, onClose]);

  return (
    <Button onClick={upload} isLoading={isUploading} ref={ref} {...props} />
  );
});

const MediaFlowCloseButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { onClose } = useMediaFlow();
  return <Button onClick={onClose} ref={ref} {...props} />;
});

export const IdentityMediaFlow: React.FC<{
  skipSelfUpload?: boolean;
  onMediaPick?: (media: MediaContext['media'] | null) => void;
}> = ({ skipSelfUpload, onMediaPick }) => {
  const { t } = useTranslation(['document', 'general', 'mediaUpload']);
  const { named } = useMediaProperties();
  const { media, hasReplacement, rejectionReason, status } = useMedia();

  const { onClose } = useMediaFlow();

  return (
    <>
      <MediaFlowModal>
        <ResponsiveModalOverlay />
        <ResponsiveModalContent justifyContent={'center'} p={'24px 16px'}>
          {(hasReplacement || status === MediaPictureStatusEnum.Rejected) && (
            <ResponsiveModalCloseButton />
          )}
          {hasReplacement && (
            <MediaPickerPreviewBox
              boxProps={{ borderRadius: 'lg' }}
              centerProps={{ bg: 'unset', pt: 'unset', p: 'unset' }}
            />
          )}
          <VStack spacing={'8px'}>
            {hasReplacement && (
              <Box>
                <Heading size={'md'}>
                  {t('mediaUpload:heading.PasstAlles')}
                </Heading>
                <Text whiteSpace={'pre-wrap'}>
                  {t(
                    'document:text.IstAllesLesbarUndImAusgewahltenAusschnittSichtbarDannLadeDasNeueDokuXX'
                  )}
                </Text>
              </Box>
            )}
            {!hasReplacement &&
              status !== MediaPictureStatusEnum.Rejected &&
              status === DocumentStatusEnum.Missing && (
                <>
                  <HStack alignItems={'baseline'}>
                    <Icon
                      as={ErrorIcon}
                      boxSize={'icon.md'}
                      color={'error.500'}
                    />
                    <Heading size={'2xl'}>
                      {t('document:heading.NamedFehltNoch', {
                        named,
                      })}
                    </Heading>
                  </HStack>
                  <Text>
                    {t('document:text.BitteLadeDasFehlendeDokumentHoch')}
                  </Text>
                </>
              )}
            {!hasReplacement &&
              status !== MediaPictureStatusEnum.Rejected &&
              status !== DocumentStatusEnum.Missing && (
                <>
                  <Heading size={'xl'} textAlign={'center'}>
                    {t('document:heading.MochtestDuEinNeuesDokumentHochladen')}
                  </Heading>
                  <Text textAlign={'center'} pb={'32px'}>
                    {t(
                      'document:text.DeineVorhandenenDokumenteWerdenNachFreigabeDerNeuenNichtMehrAngezeigXX'
                    )}
                  </Text>
                </>
              )}
            {!hasReplacement && status === MediaPictureStatusEnum.Rejected && (
              <>
                <HStack alignItems={'baseline'}>
                  <Icon
                    as={ErrorIcon}
                    boxSize={'icon.md'}
                    color={'error.500'}
                  />
                  <Heading size={'2xl'}>
                    {t('document:heading.DeinNamedWurdeAbgelehnt', {
                      named,
                    })}
                  </Heading>
                </HStack>
                <Text>
                  {t('document:text.GrundReason', {
                    reason: rejectionReason ?? '',
                  })}
                </Text>
              </>
            )}
          </VStack>
          {!hasReplacement && status !== MediaPictureStatusEnum.Rejected && (
            <Center>
              <ButtonStack direction={'column'} w={'unset'} gap={'16px'}>
                <PrimaryButton as={MediaPickerButton}>
                  {status === DocumentStatusEnum.Missing
                    ? t('document:button.DokumentHochladen')
                    : t('document:button.NeuesDokumentHochladen')}
                </PrimaryButton>
                <MediaFlowCloseButton>
                  {t('general:button.Abbrechen')}
                </MediaFlowCloseButton>
              </ButtonStack>
            </Center>
          )}
          {!hasReplacement && status === MediaPictureStatusEnum.Rejected && (
            <Center>
              <ButtonStack p={4} direction={'column'} w={'unset'}>
                <PrimaryButton as={MediaPickerButton}>
                  {t('document:button.ErneutHochladen')}
                </PrimaryButton>
              </ButtonStack>
            </Center>
          )}
          {hasReplacement && (
            <>
              <Center>
                <ButtonStack p={4} direction={'column'} w={'unset'}>
                  {skipSelfUpload ? (
                    <PrimaryButton
                      onClick={() => {
                        onClose();
                        onMediaPick?.(media ?? null);
                      }}
                    >
                      {t('general:button.Hinzufugen')}
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton as={MediaUploadButton}>
                      {t('document:button.Hochladen')}
                    </PrimaryButton>
                  )}
                </ButtonStack>
              </Center>
            </>
          )}
        </ResponsiveModalContent>
      </MediaFlowModal>
      <MediaUploadCropperPortal />
    </>
  );
};
