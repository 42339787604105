import * as icons from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  Box,
  BoxProps,
  Center,
  HTMLChakraProps,
  Icon,
  IconButton,
  Image,
  Input,
  InputProps,
  Portal,
  chakra,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImageCropperProvider } from '../../../provider/ImageCropperProvider';
import { useMediaProperties } from '../../../provider/MediaPropertiesProvider';
import { CroppedImage } from '../../../types/CroppedImage';
import { ImageStatus } from '../../../types/ImageStatus';
import { readFile } from '../../../utils/media/imagePickerHelper';
import { RelativeBox } from '../../Layout/RelativeBox/RelativeBox';
import { ImageCropper } from '../ImageCropper/ImageCropper';
import { ImageStatusIcon } from '../ImageStatusIcon/ImageStatusIcon';

export type ImagePickerProps = {
  allowOrientationFlip?: boolean;
  targetImageDimensions: { width: number; height: number };
  placeholder: string;
  isCircular?: boolean;
  onImage?: (image: null | CroppedImage) => void;
  cropButtonLabel?: string;
  cancelCropButtonLabel?: string;
  disabled?: boolean;
  status?: ImageStatus;
  statusLabel?: string;
  ariaLabel?: string;
  ariaLabelFlipOrientation?: string;
  ariaLabelRotateImage?: string;
  imgAlt?: string;
} & Pick<InputProps, 'accept'> &
  BoxProps;

const InputLabel = forwardRef<HTMLChakraProps<'label'>, 'label'>(
  (props, ref) => (
    <chakra.label position="relative" display={'flex'} ref={ref} {...props} />
  )
);

export const ImagePickerV2: React.FC<ImagePickerProps> = ({
  allowOrientationFlip = false,
  targetImageDimensions,
  placeholder,
  isCircular = false,
  onImage,
  cropButtonLabel = 'Crop',
  cancelCropButtonLabel = 'Cancel',
  disabled = false,
  status = 'add',
  statusLabel,
  ariaLabel = 'Pick image',
  ariaLabelFlipOrientation = 'Flip crop orientation',
  ariaLabelRotateImage = 'Rotate image counter clockwise 90°',
  imgAlt = 'cropped',
  accept = 'image/png,image/jpeg,image/webp',
  ...boxProps
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const inputButtonRef = React.useRef<any>(null);
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [croppedImage, setCroppedImage] = React.useState<CroppedImage | null>(
    null
  );

  const isInteractive = status === 'edit' || status === 'add';

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);

      if (typeof imageDataUrl === 'string') {
        setImageSrc(imageDataUrl);
      }
    }
  };

  const onImageHandler = React.useCallback(
    (image: CroppedImage | undefined | null) => {
      if (image !== undefined) {
        setCroppedImage(image);
        onImage?.(image);
      }
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setImageSrc(null);
    },
    [setImageSrc, onImage]
  );

  const aspectRatio = React.useMemo(() => {
    if (croppedImage) {
      return croppedImage.size.width / croppedImage.size.height;
    }
    return targetImageDimensions.width / targetImageDimensions.height;
  }, [croppedImage, targetImageDimensions]);

  const isBlurred = !isInteractive;

  return (
    <Box {...boxProps}>
      <Center>
        <RelativeBox
          attachment={
            !disabled && (
              <IconButton
                as="div"
                ref={inputButtonRef}
                aria-label={ariaLabel}
                icon={
                  <Icon
                    as={status === 'edit' ? icons.Edit : icons.AddPhoto}
                    boxSize="icon.md"
                  />
                }
                onClick={() => inputRef?.current?.click()}
                position="absolute"
                right={isCircular ? '0' : '-2'}
                bottom={isCircular ? '2' : '-2'}
              />
            )
          }
          w={`min(100%, calc(12rem * ${aspectRatio}))`}
          maxH={'12rem'}
        >
          <AspectRatio ratio={aspectRatio} w={`full`}>
            <InputLabel
              color={
                isInteractive
                  ? 'onSurface.lowEmphasis'
                  : 'onPrimary.highEmphasis'
              }
              bg={'surface'}
              overflow={'visible'}
              aria-label={ariaLabel}
            >
              <Image
                alt={imgAlt}
                src={croppedImage ? croppedImage.blobSrc : placeholder}
                width={'full'}
                htmlWidth={
                  (croppedImage as any)?.size?.width ??
                  targetImageDimensions.width
                }
                htmlHeight={
                  (croppedImage as any)?.size?.height ??
                  targetImageDimensions.height
                }
                clipPath={isCircular ? 'circle()' : 'inset(0)'}
                sx={{
                  filter: isBlurred ? 'brightness(66%) blur(8px);' : undefined,
                }}
              />
              <ImageStatusIcon status={status ?? null} label={statusLabel} />
              <Input
                display={'none'}
                ref={inputRef}
                type="file"
                value={[]}
                onChange={onFileChange}
                accept={accept}
                disabled={disabled}
              />
            </InputLabel>
          </AspectRatio>
        </RelativeBox>
      </Center>
      {imageSrc && (
        <Portal appendToParentPortal={true}>
          <ImageCropperProvider src={imageSrc} onImage={onImageHandler}>
            <ImageCropper />
          </ImageCropperProvider>
        </Portal>
      )}
    </Box>
  );
};
export const ImagePickerUsingHooks: React.FC<Partial<ImagePickerProps>> = (
  props
) => {
  const { t } = useTranslation(['general', 'mediaUpload']);

  const { named, isOrientationFlipAllowed, targetDimensions, placeholderSrc } =
    useMediaProperties();

  return (
    <ImagePickerV2
      w={'full'}
      placeholder={placeholderSrc ?? ''}
      targetImageDimensions={targetDimensions}
      allowOrientationFlip={isOrientationFlipAllowed}
      ariaLabel={t('mediaUpload:button.NamedAuswahlen', { named })}
      {...props}
    />
  );
};
