import { chakra } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from 'react-joyride';

export enum WelcomeTourStep {
  LiveCam = 'liveCam',
  CreatePost = 'createPost',
}
export const welcomeTourStepsDataName = 'data-tour-welcome';

export const createWelcomeTourDataProps = (
  welcomeTourStep: WelcomeTourStep
) => {
  return { [welcomeTourStepsDataName]: welcomeTourStep };
};

export const useWelcomeTourSteps = () => {
  const { t } = useTranslation(['tour']);
  return React.useMemo<Step[]>(() => {
    return [
      {
        disableBeacon: true,
        target: 'html',
        title: t('tour:heading.WillkommenZuruck'),
        content: (
          <>
            <span>
              {t(
                'tour:text.DuHastAlleNotigenSchritteAbgeschlossenDuBistBereitGeldZuVerdienen'
              )}
            </span>
            <chakra.span display={'block'} mt={1} color={'primary.300'}>
              {t('tour:text.BeYourOwnBoss')}
            </chakra.span>
          </>
        ),
        placement: 'center',
      },
      {
        disableBeacon: true,
        target: `[${welcomeTourStepsDataName}="${WelcomeTourStep.LiveCam}"]`,
        title: t('tour:heading.LiveCam'),
        content: (
          <>
            <span>
              {t('tour:text.VerdieneJetztDeinErstesGeldInDerCamAufVISITX')}
            </span>
          </>
        ),
        placement: 'auto',
        disableScrolling: true,
        styles: {
          spotlight: {
            borderRadius: '100%',
          },
        },
      },
      {
        disableBeacon: true,
        target: `[${welcomeTourStepsDataName}="${WelcomeTourStep.CreatePost}"]`,
        title: t('tour:heading.FeedVerfugbar'),
        content: (
          <>
            <span>
              {t(
                'tour:text.ErstelleJetztDeinenErstenBeitragUmAufVISITXPrasentZuSein'
              )}
            </span>
          </>
        ),
        placement: 'auto',
        disableScrolling: true,
        styles: {
          spotlight: {
            borderRadius: '100%',
          },
        },
      },
    ];
  }, [t]);
};
