import { useField } from 'formik';
import * as React from 'react';

import { Maybe } from '../../generated/graphql';
import {
  ClearableInputControl,
  ClearableInputControlProps,
} from './FormikFormElements';

type CharacterCountInputControlProps = {
  field?: Maybe<
    Partial<{
      minLength?: Maybe<number>;
      maxLength?: Maybe<number>;
    }>
  >;
} & ClearableInputControlProps;

export const CharacterCountInputControl: React.FC<
  CharacterCountInputControlProps
> = ({ helperText: propsHelperText, name, field: fieldMeta, ...rest }) => {
  const { maxLength } = fieldMeta ?? {};

  const [field] = useField({
    name,
  });
  const ClearableHelperText: React.FC = () => {
    return maxLength ? (
      <div style={{ display: 'flex', width: '100%' }}>
        <span style={{ flex: '1' }}>{propsHelperText}</span>
        <span>
          {field.value.length}/{maxLength}
        </span>
      </div>
    ) : null;
  };

  return (
    <ClearableInputControl
      name={name}
      {...rest}
      // @ts-ignore
      helperText={<ClearableHelperText />}
      helperTextPositionBottom={true}
      helperTextProps={{ textAlign: 'end' }}
    />
  );
};
