import React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { FeatureName, useFeature } from '../../flags';
import {
  Maybe,
  PhotoalbumFragment,
  useGetVideoLibraryPhotoUploadListEntryQuery,
} from '../../generated/graphql';
import Logger from '../../utils/Logger';
import { useAuth } from '../AuthProvider';

export const useFetchPhotoalbum = (
  albumId: number,
  onClose: () => void,
  shouldSkip: boolean
) => {
  const { t } = useTranslation(['general']);
  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  const { isAuthenticated } = useAuth();
  const skip =
    !albumId || !videoLibraryFeatureFlag || !isAuthenticated || shouldSkip;

  const { data, loading, error } = useGetVideoLibraryPhotoUploadListEntryQuery({
    variables: {
      albumId: albumId ?? undefined,
    },
    skip: skip,
    fetchPolicy: 'no-cache',
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
      });
      onClose();
    },
    notifyOnNetworkStatusChange: true,
  });

  const photoalbum: Maybe<PhotoalbumFragment> | undefined =
    React.useMemo(() => {
      return data?.media?.photoalbums?.photoalbum;
    }, [data?.media?.photoalbums?.photoalbum]);

  return { photoalbum, loading, error };
};
