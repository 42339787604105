import { CheckCircle, Error, Info } from '@campoint/odi-ui-icons';
import { EmotionIcon } from '@emotion-icons/emotion-icon';

export const showNotificationDetails = (uri: string) => {
  return uri.match('notification');
};

export const getPathFromUri = (uri: string) => {
  return uri.substring(5); //uri has this format (app://{path}) (classic://)
};

export const getClassicPathFromUri = (uri: string) => {
  const prefix = 'classic://';
  if (!uri.startsWith(prefix)) {
    return null;
  }

  return {
    isClassicRedirectWihtAutoLogin: true,
    path: uri.substring(prefix.length),
  } as const;
};

export const getExternalUrlFromUri = (uri: string) => {
  try {
    const parsedUrl = new URL(uri);
    if (['http:', 'https:'].includes(parsedUrl.protocol)) {
      return {
        isExternalUrl: true,
        externalUrl: parsedUrl.href,
      } as const;
    }
    return null;
  } catch {
    return null;
  }
};

export const getInternalPathFromUri = (uri: string) => {
  const prefix = 'app://';
  if (!uri.startsWith(prefix)) {
    return null;
  }

  const notificationIdPrefix = `${prefix}notification/id/`;
  if (uri.startsWith(notificationIdPrefix)) {
    return {
      isNotificationReference: true,
      notificationId: uri.substring(notificationIdPrefix.length),
    } as const;
  }

  return {
    isInternalRedirect: true,
    path: uri.substring(prefix.length),
  } as const;
};

export const mapActionUrl = (actionUrl: string) => {
  try {
    const parsedUrl = new URL(actionUrl);
    if (['http:', 'https:'].includes(parsedUrl.protocol)) {
      return parsedUrl;
    }
    return false;
  } catch {
    return false;
  }
};

export const getExternalURLFromUri = (uri: string) => {
  try {
    const parsedUrl = new URL(uri);
    if (['http:', 'https:'].includes(parsedUrl.protocol)) {
      return parsedUrl;
    }
    return null;
  } catch {
    return null;
  }
};

const possibleNotifcationIcons: {
  [key: string]: EmotionIcon;
} = {
  Error: Error,
  CheckCircle: CheckCircle,
  Info: Info,
};

const getIconTypeAsString = (str: string, startIdx: number, endIdx: number) => {
  if (startIdx === -1) return '';
  else {
    if (endIdx === -1) {
      return str.slice(startIdx + 5);
    }
    return str.slice(startIdx + 5, endIdx);
  }
};

export const getIconAndColor = (
  str: string
): { iconType: EmotionIcon | null; color: string | null } => {
  if (!str) return { iconType: null, color: null };
  const iconIndex = str.indexOf('icon/');
  const iconEndIndex = str.indexOf('?', iconIndex);
  const colorIndex = str.indexOf('?color=');
  const colorEndIndex = str.length;

  const iconType = getIconTypeAsString(str, iconIndex, iconEndIndex);

  const color =
    colorIndex !== -1 ? str.slice(colorIndex + 7, colorEndIndex) : '';

  return { iconType: possibleNotifcationIcons[iconType], color };
};
