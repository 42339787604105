import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import * as React from 'react';

import {
  SafeAreaInsetSurroundOption,
  useSafeAriaInset,
} from '../../hooks/useSafeAriaInset';

export interface PageSafeAreaInsetProps extends BoxProps {
  surroundPadding?: SafeAreaInsetSurroundOption;
  surroundMargin?: SafeAreaInsetSurroundOption;
}
export const PageSafeAreaInset = forwardRef<PageSafeAreaInsetProps, 'div'>(
  ({ surroundPadding = 'none', surroundMargin = 'none', ...boxProps }, ref) => {
    const { paddings, margins } = useSafeAriaInset({
      surroundPadding,
      surroundMargin,
    });
    return <Box {...paddings} {...margins} ref={ref} {...boxProps} />;
  }
);
