import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';

import { Maybe } from '../../../generated/graphql';

export type LabeledReadonlyFieldTextProps = {
  label: string;
  field?: Maybe<{ value?: Maybe<string> }>;
};

export const LabeledReadonlyFieldText: React.FC<
  LabeledReadonlyFieldTextProps
> = ({ label, field }) => (
  <FormControl isReadOnly>
    <FormLabel mb={3} children={label} />
    <Input value={field?.value ?? ''} mb={6} />
  </FormControl>
);
