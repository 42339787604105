import React from 'react';

import { usePaidContentConfigQuery } from '../generated/feed';
import { useAuth } from '../provider/AuthProvider';
import Logger from '../utils/Logger';

export interface FeedConfig {
  price: {
    min: number;
    max: number;
    step: number;
    steps: number[];
  };
  priceCoins: {
    min: number;
    max: number;
    steps: number[];
  };
  tippingGoal: {
    min: number;
    max: number;
    steps: number[];
  };
  tippingGoalCoins: {
    min: number;
    max: number;
    steps: number[];
  };
}
export const useFeedConfig = () => {
  const authCtx = useAuth();

  const { data, ...rest } = usePaidContentConfigQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => Logger.error(error),
    skip: !authCtx.isAuthenticated,
  });

  const mappedData = React.useMemo<FeedConfig>(() => {
    return {
      price: {
        min: data?.config?.paidContent?.min ?? 1,
        max: data?.config?.paidContent?.max ?? 20,
        step: data?.config?.paidContent?.step ?? 0.5,
        steps: data?.config?.paidContent?.steps?.filter(Number) ?? [
          1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
          9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16,
          16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20,
        ],
      },
      priceCoins: {
        min: data?.config?.paidContentCoins?.min ?? 5,
        max: data?.config?.paidContentCoins?.max ?? 99,
        steps: (data?.config?.paidContentCoins?.steps?.filter(
          Number
        ) as number[]) ?? [
          5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
          41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
          58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
          75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91,
          92, 93, 94, 95, 96, 97, 98, 99,
        ],
      },
      tippingGoal: {
        min: data?.config?.tippingGoal?.min ?? 5,
        max: data?.config?.tippingGoal?.max ?? 150,
        steps: (data?.config?.tippingGoal?.steps?.filter(
          Number
        ) as number[]) ?? [
          5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35,
          40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130,
          140, 150,
        ],
      },
      tippingGoalCoins: {
        min: data?.config?.tippingGoalCoins?.min ?? 10,
        max: data?.config?.tippingGoalCoins?.max ?? 300,
        steps: (data?.config?.tippingGoalCoins?.steps?.filter(
          Number
        ) as number[]) ?? [
          10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 50,
          60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
          220, 240, 260, 280, 300,
        ],
      },
    };
  }, [data]);

  return { data: mappedData, ...rest };
};
