import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { windowOpen } from '../../../../../utils/utils';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../../components/WizardStepFooter/WizardStepFooter';
import { useWizardMRF } from '../../WizardMRFContext';

export const WizardMRFModelReleaseFormV1Signing: React.FC = () => {
  const { t } = useTranslation(['wizardMRF']);
  const wizardMRFCtx = useWizardMRF();

  const url = wizardMRFCtx.currentField.mrfForm?.form?.formUrl;

  const navigateToPaperlessIo = React.useCallback(() => {
    if (url) {
      windowOpen(url, '_self');
    }
  }, [url]);

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t('wizardMRF:heading.FehltNurNochEineUnterschrift')}
          children={t(
            'wizardMRF:text.KlickeAufDenButtonUmDeineAngabenMitDeinerUnterschriftZuBestatigenDafXX'
          )}
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardMRF:button.Unterschreiben')}
          onContinueButtonClick={navigateToPaperlessIo}
          omitSkipButton
        />
      }
    />
  );
};
