import {
  Button,
  ButtonGroup,
  Heading,
  Img,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useFeature } from 'flagged';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import dataCheckRejectedImage from '../../../../assets/images/finance/get-paid-rejected.svg';
import { FeatureName } from '../../../../flags';
import { useWizardPayout as useWizardPayoutV1 } from '../../../../pages/Wizard/Payout/WizardPayoutContext';
import { useWizardPayout as useWizardPayoutV2 } from '../../../../pages/Wizard/PayoutV2/WizardPayoutContext';
import { useAccountStatus } from '../../../../provider/AccountStatusProvider/AccountStatusProvider';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { routes } from '../../../../routes/routesConfig';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../cards/UniversalCard/UniversalCard';

const PayoutDataCheckRejectedCard: React.FC<{ reason?: string }> = ({
  reason = '',
}) => {
  const { t } = useTranslation(['payout', 'general']);
  const history = useHistory();
  const wizardPayoutV2Flag = useFeature(FeatureName.wizardPayoutV2);
  const documentFinanceEditAllowedFlag = useFeature(
    FeatureName.documentFinanceEditAllowed
  );
  const wizardPayoutV1 = useWizardPayoutV1();
  const wizardPayoutV2 = useWizardPayoutV2();
  const { action } = useFinanceService();
  const { actions } = useAccountStatus();
  const onFinish = () => {
    action.triggerRefetch();
    actions.refresh();
  };

  const navigateToWizard = () => {
    if (wizardPayoutV2Flag) {
      wizardPayoutV2.setOnFinished(onFinish);
      wizardPayoutV2.setVisible(true);
    } else {
      wizardPayoutV1.setOnFinished(onFinish);
      wizardPayoutV1.setVisible(true);
    }
  };

  const navigateToPayoutDocuments = () => {
    history.push(routes.documentsPayout.path);
  };

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={dataCheckRejectedImage}
              alt={t('general:img.AbgelehntesDokument')}
            />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t('payout:headline.DeineAuszahlungsdatenWurdenAbgelehnt')}
              </Heading>
              {reason && (
                <Text
                  size={{ base: 'sm', lg: 'md' }}
                  textAlign={'center'}
                  color={'gray.500'}
                  p={0}
                >
                  {t('payout:text.GrundReason', { reason })}
                </Text>
              )}
            </VStack>
          </>
        }
        cardFooter={
          <ButtonGroup flexDir={'column'} gap={4} spacing={0}>
            <Button
              variant={'solid'}
              children={t('payout:button.NochEinmalVersuchen')}
              onClick={
                documentFinanceEditAllowedFlag
                  ? navigateToPayoutDocuments
                  : navigateToWizard
              }
            />
          </ButtonGroup>
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
export default PayoutDataCheckRejectedCard;
