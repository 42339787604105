import { ApolloError } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Maybe,
  MediaLikeFragment,
  MediaSaleFragment,
  VideoFragment,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { useMediaModalQueryParams } from '../../../hooks/useQueryParamState';
import { VideoLibraryVideoStatisticModal } from '../../../pages/VideoLibraryPage/VideoLibraryVideoStatisticModal';
import { isVideoTypeShopOrContest } from '../../../utils/isVideoTypeShopOrContest';
import { useAuth } from '../../AuthProvider';
import { useFetchAndLoadMoreLikes } from '../useFetchAndLoadMoreLikes';
import { useFetchAndLoadMoreSales } from '../useFetchAndLoadMoreSales';
import { useFetchVideo } from '../useFetchVideo';

interface VideoLibraryVideoStatisticModalContext {
  video: Maybe<VideoFragment> | undefined;
  loading: boolean;
  likes: MediaLikeFragment[];
  likesLoading: boolean;
  likesError: ApolloError | undefined;
  loadMoreLikes: () => void;
  sales: MediaSaleFragment[];
  salesLoading: boolean;
  salesError: ApolloError | undefined;
  loadMoreSales: () => void;
  isOpen: boolean;
  actions: {
    showModal: (albumId: string) => void;
    closeModal: () => void;
  };
}

const videoStatisticsModal = 'videoStatistics';

export const [
  ,
  useVideoLibraryVideoStatisticModalContext,
  videoLibraryVideoStatisticModalContext,
] = createContext<VideoLibraryVideoStatisticModalContext>({
  name: 'VideoLibraryVideoStatisticModalContext',
  errorMessage:
    'useVideoLibraryVideoStatisticModalContext: `VideoLibraryVideoStatisticModalContext` is undefined. Seems you forgot to wrap component within the Provider',
  strict: true,
});

export const VideoLibraryVideoStatisticModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [modal, albumId, setVideoQueryParams] = useMediaModalQueryParams();
  const { onClose } = useDisclosure();
  const { t } = useTranslation(['general']);
  const { isAuthenticated } = useAuth();

  /**
   * Get Upload List Entry data
   */
  const { video, loading } = useFetchVideo(Number(albumId), onClose);

  /**
   * Get Upload List likes data
   */
  const fetchMoreLikesCount = 10;

  const { refetchLikes, likes, likesError, likesLoading, loadMoreLikes } =
    useFetchAndLoadMoreLikes(fetchMoreLikesCount, onClose);

  /**
   * Get Upload List sales data
   */
  const fetchMoreSalesCount = 10;

  const { refetchSales, sales, salesLoading, salesError, loadMoreSales } =
    useFetchAndLoadMoreSales(fetchMoreSalesCount, onClose);

  /**
   * Load initial stats data
   */
  const initialQuery = React.useCallback(() => {
    refetchLikes({
      variables: {
        albumId: Number(albumId),
        count: fetchMoreLikesCount,
        after: '',
      },
    });
    refetchSales({
      variables: {
        albumId: Number(albumId),
        count: fetchMoreSalesCount,
        after: '',
      },
    });
  }, [albumId, refetchLikes, refetchSales]);

  const isVideoStatisticsModal = modal === videoStatisticsModal;

  React.useEffect(() => {
    if (!albumId || !video) return;

    if (
      isVideoTypeShopOrContest(video) &&
      isVideoStatisticsModal &&
      isAuthenticated
    ) {
      initialQuery();
    } else {
      setVideoQueryParams({ modal: null, albumId: null });
    }
  }, [
    initialQuery,
    albumId,
    video,
    setVideoQueryParams,
    modal,
    t,
    isVideoStatisticsModal,
    isAuthenticated,
  ]);

  const actions: VideoLibraryVideoStatisticModalContext['actions'] =
    React.useMemo(() => {
      return {
        showModal: (albumId: string) => {
          setVideoQueryParams({ modal: videoStatisticsModal, albumId });
        },
        closeModal: () => {
          setVideoQueryParams({ modal: null, albumId: null });
          onClose();
        },
      };
    }, [onClose, setVideoQueryParams]);

  const context: VideoLibraryVideoStatisticModalContext = React.useMemo(() => {
    return {
      video: video,
      loading,
      likes,
      likesLoading,
      likesError,
      loadMoreLikes,
      sales,
      salesLoading,
      salesError,
      loadMoreSales,
      isOpen: !!albumId && isVideoStatisticsModal,
      actions,
    };
  }, [
    video,
    loading,
    likes,
    likesLoading,
    likesError,
    loadMoreLikes,
    sales,
    salesLoading,
    salesError,
    loadMoreSales,
    albumId,
    isVideoStatisticsModal,
    actions,
  ]);

  return (
    <videoLibraryVideoStatisticModalContext.Provider value={context}>
      {children}
      <VideoLibraryVideoStatisticModal />
    </videoLibraryVideoStatisticModalContext.Provider>
  );
};
