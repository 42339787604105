import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { PublicPageLayout } from '../../../components/Layout/PublicPageLayout';
import Markdown from '../../../components/shared/Markdown/Markdown';
import { TermsAcceptanceTypeEnum } from '../../../generated/graphql';
import { useCmsMarkdown } from '../../../hooks/useCmsMarkdown';
import { useTermMarkdown } from '../../../hooks/useTermMarkdown';
import { useAuth } from '../../../provider/AuthProvider';
import { useCookieConsent } from '../../../provider/CookieConsentProvider';
import { routes } from '../../../routes/routesConfig';
import { TermsPDFDownloadButton } from '../components/FileDownloadButton/TermsPDFDownloadButton';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { PageSection } from '../components/PageSection/PageSection';
import { PageSubHeader } from '../components/PageSubHeader/PageSubHeader';

const PublicPrivacyPolicyPage = () => {
  const { t } = useTranslation(['legal']);
  const history = useHistory();
  const ctx = useCookieConsent();
  const { loading, markdownString } = useCmsMarkdown({
    id: 'gdpr',
  });
  const navigateToLogin = () => history.push(routes.login.path);

  return (
    <PublicPageLayout
      pageTitle={t('legal:heading.Datenschutz')}
      isContentLoading={loading}
      onClose={navigateToLogin}
    >
      <PageContainer>
        <PageSection>
          <Markdown
            markdown={markdownString}
            linkFunctionRegister={{
              gaOptout: () => ctx.action.decline(['analytics']),
            }}
          />
        </PageSection>
      </PageContainer>
    </PublicPageLayout>
  );
};

const AuthenticatedPrivacyPolicyPage = () => {
  const { t } = useTranslation(['legal']);
  const ctx = useCookieConsent();
  const { loading, markdownString, action } = useTermMarkdown({
    type: TermsAcceptanceTypeEnum.VxmodelsPrivacyPolicy,
  });

  return (
    <FluentPageLayout isContentLoading={loading}>
      <PageSubHeader children={t('legal:heading.Datenschutz')} />
      <PageContainer>
        <PageSection>
          <Markdown
            markdown={markdownString}
            linkFunctionRegister={{
              gaOptout: () => ctx.action.decline(['analytics']),
            }}
          />
          <TermsPDFDownloadButton
            mt={'4'}
            onClick={() => action.openPdfInNewWindow()}
          />
        </PageSection>
      </PageContainer>
    </FluentPageLayout>
  );
};

export const PrivacyPolicyPage = () => {
  const authCtx = useAuth();

  return !authCtx.isAuthenticated ? (
    <PublicPrivacyPolicyPage />
  ) : (
    <AuthenticatedPrivacyPolicyPage />
  );
};
