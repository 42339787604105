import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { stringCharCount } from '../../../utils/stringCharCount';
import { CharacterCountIndicator } from '../CharacterCountIndicator/CharacterCountIndicator';

export const EncodedControlledCharacterCount: React.FC<{
  value: Maybe<string>;
  maxCharacterCount?: number;
}> = ({ value, maxCharacterCount }) => {
  const charCount = React.useMemo(() => {
    return stringCharCount(value);
  }, [value]);
  return !maxCharacterCount ? null : (
    <CharacterCountIndicator count={charCount} maxCount={maxCharacterCount} />
  );
};
