import { DateTime } from 'luxon';

/**
 * Calculates the age in full years based on a birthdate. Throws an error if the birthdate is in an incorrect format
 * @param date The birthdate in format yyyy-MM-dd or as a luxon DateTime
 */
export function calculateAge(date: string | DateTime): number {
  const parsedDate =
    date instanceof DateTime ? date : DateTime.fromFormat(date, 'yyyy-MM-dd');

  if (!parsedDate.isValid) {
    throw new Error(`Invalid birthdate '${date}' received!`);
  }

  return Math.floor(-parsedDate.diffNow('years').years);
}
