import { Button, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ResendMailSectionProps = {
  actionButtonDisabled: boolean;
  buttonAction: () => void;
};

const ResendMailSection: React.FC<ResendMailSectionProps> = (props) => {
  const { t } = useTranslation(['home']);
  return (
    <VStack pt="6" spacing="2" w="full" align="start">
      <Text
        textStyle={'bodyMd'}
        fontWeight="medium"
        children={t('home:text.DuHastKeineEMailErhalten')}
      />
      <Text
        textStyle={'bodySm'}
        children={t('home:text.SchauInDeinenSpamOrdnerNachOder')}
      />
      <Button
        mt="6"
        variant="outline"
        isDisabled={props.actionButtonDisabled}
        width={'full'}
        children={t('home:button.LinkErneutSenden')}
        onClick={props.buttonAction}
      />
    </VStack>
  );
};

export default ResendMailSection;
