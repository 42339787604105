import { Box, ToastProps } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import { MediaUploadCropperPortal } from '../../../../../components/shared/MediaUpload/MediaUpload';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { MediaInputProvider } from '../../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { uploadDocument } from '../../../../../utils/media';
import { WizardIdentityProofPreviewBox } from '../../../components/WizardIdentityProofPreviewBox/WizardIdentityProofPreviewBox';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton } from '../../../components/styled';
import { WizardVerificationBasicProps } from '../../WizardVerification';

type WizardVerificationSelfieProps = WizardVerificationBasicProps;

export const WizardVerificationSelfie: React.FC<
  WizardVerificationSelfieProps
> = ({ goToNextStep, uploadUrl, showLoader }) => {
  const { t } = useTranslation([
    'general',
    'wizardVerification',
    'imagePicker',
    'mediaUpload',
  ]);
  const [blob, setBlob] = useState<null | Blob>(null);
  const [isUploading, setIsUploading] = useState(false);

  const uploadImage = React.useCallback(async () => {
    if (!!uploadUrl && !!blob) {
      const errorToast: ToastProps = {
        status: 'error',
        description: t('wizardVerification:uploadError'),
      };
      try {
        setIsUploading(true);
        const result = await uploadDocument(uploadUrl, blob);
        if (result.id === null) {
          issueChakraToast(errorToast);
        } else {
          goToNextStep();
        }
      } catch {
        issueChakraToast(errorToast);
      } finally {
        setIsUploading(false);
      }
    }
  }, [blob, t, uploadUrl, setIsUploading, goToNextStep]);

  const isLoading = isUploading || showLoader;

  return (
    <WizardParentModalStepLayout
      contentSection={
        <>
          <WizardSubHeader
            headerText={t('wizardVerification:selfie.subHeader')}
          />
          <MediaPropertiesProvider.ForIdShotDocument>
            <MediaProvider
              onReplacement={(m) => {
                setBlob(m?.blob ?? null);
              }}
            >
              <MediaInputProvider
                accept={'DEFAULT_FOR_PICTURE'}
                capture={'user'}
              >
                <WizardIdentityProofPreviewBox isUploading={isLoading} />
                <MediaUploadCropperPortal />
              </MediaInputProvider>
            </MediaProvider>
          </MediaPropertiesProvider.ForIdShotDocument>

          <Box mt={'12'}>
            <TipCard text={t('wizardVerification:selfie.hint')} />
          </Box>
        </>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('general:button.Weiter')}
            isDisabled={!blob || isLoading}
            isLoading={isLoading}
            onClick={uploadImage}
          />
        </ButtonStack>
      }
    />
  );
};
