import { Box, Flex } from '@chakra-ui/react';
import { t } from 'i18next';

import { ContestIcon, ContestTileBase } from './ContestTileBase';

export const ContestTileUploadStarted: typeof ContestTileBase = (props) => {
  return (
    <ContestTileBase
      {...props}
      contestBackground={props.contest.images.comingSoonImage}
      backgroundBlendMode={'normal'}
    >
      <Flex
        color={'white'}
        fontSize={'12px'}
        direction={'row'}
        justifyContent={'space-between'}
        padding={'7px'}
        width={'100%'}
      >
        <ContestIcon contest={props.contest} />
        <Box
          background={'rgba(0, 0, 0, 0.48)'}
          borderColor={'white'}
          borderWidth={1}
          borderRadius={'5px'}
          padding={'5px 7px'}
          whiteSpace={'nowrap'}
          lineHeight={'1'}
        >
          {t('contest:label.UploadBis', {
            date: props.contest.uploadEnd,
          })}
        </Box>
      </Flex>
    </ContestTileBase>
  );
};
