// 00 00 00 14 66 74 79 70 71 74 20 20 'video/quicktime' .mov
const quicktimeFileStartSequence = Uint8Array.from([
  0x00, 0x00, 0x00, 0x14, 0x66, 0x74, 0x79, 0x70, 0x71, 0x74, 0x20, 0x20,
]);
function byteSequenceIndicatesQuickTimeFile(bytes: Uint8Array) {
  if (
    quicktimeFileStartSequence.every((byte, index) => byte === bytes[index])
  ) {
    return true;
  }
  return false;
}

// 00 00 00 18 66 74 79 70 6d 70 34 32 'video/mp4' .mp4
const mp4FileStartSequence = Uint8Array.from([
  0x00, 0x00, 0x00, 0x18, 0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32,
]);
function byteSequenceIndicatesMpeg4File(bytes: Uint8Array) {
  if (mp4FileStartSequence.every((byte, index) => byte === bytes[index])) {
    return true;
  }
  return false;
}

export async function verifyJpegFormat(file: File) {
  // https://stackoverflow.com/a/61680553
  return new Promise<{
    isValidJpeg: boolean;
    estimatedMimeType?: string;
    estimatedFileExtension?: string;
  }>((resolve) => {
    var reader = new FileReader();
    reader.onload = (ev) => {
      if (!(ev.target?.result instanceof ArrayBuffer)) {
        resolve({ isValidJpeg: false });
        return;
      }
      var bytes = new Uint8Array(ev.target.result);
      // https://en.wikipedia.org/wiki/List_of_file_signatures
      if (bytes[0] === 0xff && bytes[1] === 0xd8) {
        resolve({
          isValidJpeg: true,
          estimatedMimeType: 'image/jpeg',
          estimatedFileExtension: '.jpeg',
        });
      } else if (byteSequenceIndicatesQuickTimeFile(bytes)) {
        resolve({
          isValidJpeg: false,
          estimatedMimeType: 'video/quicktime',
          estimatedFileExtension: '.mov',
        });
      } else if (byteSequenceIndicatesMpeg4File(bytes)) {
        resolve({
          isValidJpeg: false,
          estimatedMimeType: 'video/mp4',
          estimatedFileExtension: '.mp4',
        });
      } else {
        resolve({ isValidJpeg: false });
      }
    };
    reader.readAsArrayBuffer(file);
  });
}
