import * as icon from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  AspectRatioProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Center,
  CenterProps,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  Icon,
  IconButtonProps,
  Image,
  ImageProps,
  Portal,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { MediaPictureStatusEnum } from '../../../generated/graphql';
import { ImageCropperProvider } from '../../../provider/ImageCropperProvider';
import { useMediaInput } from '../../../provider/MediaInputProvider';
import { useMediaProperties } from '../../../provider/MediaPropertiesProvider';
import { useMedia } from '../../../provider/MediaProvider';
import { useMediaUpload } from '../../../provider/MediaUploadProvider';
import { createStencilCssProps } from '../../../utils/media';
import { createImage } from '../../../utils/media/imagePickerHelper';
import { AbsoluteFullCenter } from '../../Layout/AbsoluteFullCenter';
import { ButtonStack } from '../../Layout/ButtonStack';
import { RelativeBox } from '../../Layout/RelativeBox/RelativeBox';
import { PrimaryButton, SecondaryButton } from '../../Layout/ResponsiveModal';
import { ImageCropper } from '../ImageCropper/ImageCropper';
import { ImageStatus } from '../ImageStatus/ImageStatus';
import {
  MediaPickerBox,
  MediaPickerButton,
  MediaPickerIconButton,
} from '../MediaPickerButton/MediaPickerButton';
import {
  FaqLink,
  MediaUploadModalHeading,
  MediaUploadModalText,
} from './shared';

/**
 * !Must be enclosed by:
 * - **{@link MediaProvider}**
 */
export const MediaStencilOverlay: React.FC<BoxProps> = (props) => {
  const { isStencilVisible, orientedStencil } = useMediaProperties();
  return !isStencilVisible ? null : (
    <Box
      pointerEvents={'none'}
      position={'absolute'}
      // unfortunatly we can't use inset: '0' here yet
      left={0}
      right={0}
      top={0}
      bottom={0}
      {...createStencilCssProps(orientedStencil, isStencilVisible)}
      {...props}
    />
  );
};

export const MediaUploadSkipCropper: React.FC = () => {
  const { mediaFile, isCropperShown, closeCropper } = useMediaInput();
  const {
    action: { setReplacement },
  } = useMedia();

  React.useEffect(() => {
    if (isCropperShown && !!mediaFile && !!mediaFile.originalFile) {
      const _ = async () => {
        const htmlImage = await createImage(mediaFile.originalSrc);
        setReplacement({
          src: htmlImage.src,
          blob: mediaFile.originalFile,
          filename: mediaFile.originalFile.name,
          key: uuidv4(),
          size: {
            width: htmlImage.width,
            height: htmlImage.height,
          },
        });
        closeCropper();
      };

      _();
    }
  }, [isCropperShown, mediaFile, closeCropper, setReplacement]);

  return null;
};

/**
 * !Must be enclosed by:
 * - **{@link MediaInputProvider}**
 */
export const MediaUploadCropperPortal: React.FC<{
  closeModal?: () => void;
}> = ({ closeModal }) => {
  const {
    action: { setReplacement },
  } = useMedia();
  const { mediaFile, isCropperShown, closeCropper } = useMediaInput();

  React.useEffect(() => {
    if (
      !isCropperShown &&
      !!mediaFile &&
      mediaFile?.originalFile.type === 'application/pdf'
    ) {
      setReplacement({
        src: mediaFile?.originalSrc,
        blob: mediaFile?.originalFile,
        filename: mediaFile?.originalFile?.name ?? null,
        key: uuidv4(),
        size: undefined,
      });
    }
  }, [setReplacement, isCropperShown, mediaFile]);

  return mediaFile?.originalSrc && isCropperShown ? (
    <Portal>
      <ImageCropperProvider
        src={mediaFile?.originalSrc}
        onImage={(c) => {
          if (c) {
            setReplacement({
              src: c.blobSrc,
              blob: c.blob,
              filename: mediaFile?.originalFile?.name ?? null,
              key: uuidv4(),
              size: c.size,
            });
          }
          closeCropper();
          if (closeModal) {
            closeModal();
          }
        }}
      >
        <ImageCropper />
      </ImageCropperProvider>
    </Portal>
  ) : null;
};

export interface MediaPickerPreviewBoxProps {
  aspectRatioProps?: AspectRatioProps;
  iconButtonProps?: Partial<IconButtonProps>;
  boxProps?: BoxProps;
  centerProps?: CenterProps;
  imageProps?: ImageProps;
  children?: React.ReactNode;
}

/**
 * !Must be enclosed by:
 * - **{@link MediaPropertiesProvider}**
 * - **{@link MediaProvider}**
 */
export const MediaPickerPreviewBox: React.FC<MediaPickerPreviewBoxProps> = ({
  aspectRatioProps,
  iconButtonProps,
  boxProps,
  centerProps,
  imageProps,
  children,
}) => {
  const {
    media: currentMedia,
    currentSrc,
    status,
    hasReplacement,
    initialSrcSet,
  } = useMedia();
  const {
    aspectRatio: aspectRatioFromProperties,
    isCircular,
    placeholderSrc,
  } = useMediaProperties();

  const aspectRatio = React.useMemo(() => {
    const size = currentMedia?.size ?? null;

    if (!size) {
      return aspectRatioFromProperties;
    }
    return Math.max(size.width / size.height, 1.0);
  }, [currentMedia, aspectRatioFromProperties]);

  const placeholderImage = !placeholderSrc ? 'unset' : `url(${placeholderSrc})`;

  const isRejected = status === MediaPictureStatusEnum.Rejected;

  return (
    <Center bg={'steel'} p={4} pt={12} {...centerProps}>
      <AspectRatio
        sx={{
          '--max-height': { base: '10rem', lg: '10rem' },
        }}
        ratio={aspectRatio}
        maxW={`calc(var(--max-height) * ${aspectRatio})`}
        w={'full'}
        maxH={'var(--max-height)'}
        {...aspectRatioProps}
      >
        <RelativeBox
          attachment={
            <MediaPickerIconButton
              position={'absolute'}
              variant={'solid'}
              right={isCircular ? { base: 0, lg: '10%' } : 4}
              bottom={isCircular ? { base: '5%', lg: '10%' } : 4}
              icon={<Icon as={icon.AddPhoto} boxSize={'icon.md'} />}
              {...iconButtonProps}
            />
          }
        >
          <MediaPickerBox
            position={'absolute'}
            left={0}
            right={0}
            top={0}
            bottom={0}
            cursor={'pointer'}
            bg={!!currentSrc ? 'gray.100' : 'steel'}
            backgroundSize={'contain'}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            border={!currentSrc ? 'dashed' : 'unset'}
            borderWidth={'1px'}
            borderRadius={isCircular ? 'full' : 'unset'}
            clipPath={isCircular ? 'circle()' : 'unset'}
            filter="auto"
            brightness={!hasReplacement && isRejected ? '40%' : 'unset'}
            overflow={'hidden'}
            style={{
              // Setting `backgroundImage` directly has no effect,
              // we need to use the detour over `style`
              // https://github.com/chakra-ui/chakra-ui/issues/7548#issuecomment-1498757142
              backgroundImage: !currentSrc ? placeholderImage : undefined,
            }}
            {...boxProps}
          >
            {currentSrc && (
              <Image
                objectFit={'contain'}
                src={!hasReplacement && initialSrcSet ? undefined : currentSrc}
                srcSet={
                  !hasReplacement ? initialSrcSet ?? undefined : undefined
                }
                userSelect={'none'}
                w={'full'}
                h={'full'}
                {...imageProps}
              />
            )}

            {!currentSrc && children && (
              <AbsoluteFullCenter children={children} />
            )}
            <MediaStencilOverlay />
          </MediaPickerBox>
          {isRejected && !hasReplacement && (
            <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
              <ImageStatus status={status} />
            </AbsoluteFullCenter>
          )}
        </RelativeBox>
      </AspectRatio>
    </Center>
  );
};

/**
 * !Must be enclosed by:
 * - **{@link MediaPropertiesProvider}**
 * - **{@link MediaProvider}**
 */
export const MediaPickerPreviewBoxNoBackground: React.FC<
  MediaPickerPreviewBoxProps
> = ({
  aspectRatioProps,
  iconButtonProps,
  boxProps,
  centerProps,
  imageProps,
  children,
}) => {
  const {
    media: currentMedia,
    currentSrc,
    status,
    hasReplacement,
    initialSrcSet,
  } = useMedia();
  const {
    aspectRatio: aspectRatioFromProperties,
    isCircular,
    placeholderSrc,
  } = useMediaProperties();

  const aspectRatio = React.useMemo(() => {
    const size = currentMedia?.size ?? null;

    if (!size) {
      return aspectRatioFromProperties;
    }
    return Math.max(size.width / size.height, 1.0);
  }, [currentMedia, aspectRatioFromProperties]);

  const placeholderImage = !placeholderSrc ? 'unset' : `url(${placeholderSrc})`;

  const showStatusOverlay = status !== MediaPictureStatusEnum.Ok && currentSrc;

  return (
    <Center p={4} pt={12} {...centerProps}>
      <AspectRatio
        sx={{
          '--max-height': { base: '10rem', lg: '10rem' },
        }}
        ratio={aspectRatio}
        maxW={`calc(var(--max-height) * ${aspectRatio})`}
        w={'full'}
        maxH={'var(--max-height)'}
        {...aspectRatioProps}
      >
        <RelativeBox
          attachment={
            <MediaPickerIconButton
              position={'absolute'}
              variant={'solid'}
              right={isCircular ? { base: 0, lg: '10%' } : 4}
              bottom={isCircular ? { base: '5%', lg: '10%' } : 4}
              icon={<Icon as={icon.AddPhoto} boxSize={'icon.md'} />}
              {...iconButtonProps}
            />
          }
        >
          <MediaPickerBox
            position={'absolute'}
            left={0}
            right={0}
            top={0}
            bottom={0}
            cursor={'pointer'}
            bg={!!currentSrc ? 'gray.100' : 'steel'}
            backgroundSize={'contain'}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            border={!currentSrc ? 'dashed' : 'unset'}
            borderWidth={'1px'}
            borderRadius={isCircular ? 'full' : 'unset'}
            clipPath={isCircular ? 'circle()' : 'unset'}
            filter="auto"
            brightness={!hasReplacement && showStatusOverlay ? '40%' : 'unset'}
            overflow={'hidden'}
            style={{
              // Setting `backgroundImage` directly has no effect,
              // we need to use the detour over `style`
              // https://github.com/chakra-ui/chakra-ui/issues/7548#issuecomment-1498757142
              backgroundImage: !currentSrc ? placeholderImage : undefined,
            }}
            {...boxProps}
          >
            {currentSrc && (
              <Image
                objectFit={'contain'}
                src={!hasReplacement && initialSrcSet ? undefined : currentSrc}
                srcSet={
                  !hasReplacement ? initialSrcSet ?? undefined : undefined
                }
                userSelect={'none'}
                w={'full'}
                h={'full'}
                {...imageProps}
              />
            )}

            {!currentSrc && children && (
              <AbsoluteFullCenter children={children} />
            )}
            <MediaStencilOverlay />
          </MediaPickerBox>
          {showStatusOverlay && !hasReplacement && (
            <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
              <ImageStatus status={status} />
            </AbsoluteFullCenter>
          )}
        </RelativeBox>
      </AspectRatio>
    </Center>
  );
};

/**
 * !Must be enclosed by:
 * - **{@link MediaPropertiesProvider}**
 */
export const ToggleStencilButton: React.FC<Partial<IconButtonProps>> = (
  props
) => {
  const { isStencilVisible, orientedStencil, action } = useMediaProperties();
  const { t } = useTranslation(['mediaCropper']);

  const label = isStencilVisible
    ? t('mediaCropper:button.SchabloneAusblenden')
    : t('mediaCropper:button.SchabloneAnzeigen');
  return !orientedStencil ? null : (
    <SecondaryButton
      onClick={action.toggleStencil}
      leftIcon={<Icon as={icon.Texture} boxSize="icon.md" />}
      children={label}
      {...props}
    />
  );
};

/**
 * !Must be enclosed by:
 * - **{@link MediaUploadProvider}**
 */
const MediaUploadError = () => {
  const { uploadError } = useMediaUpload();
  return !uploadError ? null : (
    <FormControl isInvalid={!!uploadError}>
      <FormErrorMessage m={0} p={0}>
        <FormErrorIcon />
        {uploadError.description}
      </FormErrorMessage>
    </FormControl>
  );
};

/**
 * !Must be enclosed by:
 * - **{@link MediaProvider}**
 * - **{@link MediaUploadProvider}**
 */
const MediaUploadButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { media } = useMedia();
  const { isUploading, action } = useMediaUpload();
  const upload = React.useCallback(async () => {
    if (!media.blob) {
      return;
    }
    await action.upload(media.blob);
  }, [action, media.blob]);

  return (
    <Button
      onClick={upload}
      isLoading={isUploading}
      ref={ref}
      {...props}
      variant={'solid'}
    />
  );
});
export const MediaUploadFooterButtonStack: React.FC<{
  firstPickLabel?: string;
  replaceLabel?: string;
  uploadLabel?: string;
}> = ({ firstPickLabel, replaceLabel, uploadLabel }) => {
  const { hasReplacement, initialSrc } = useMedia();

  const { named } = useMediaProperties();
  const { t } = useTranslation(['mediaUpload']);

  if (!hasReplacement && initialSrc) {
    return (
      <ButtonStack px={4} py={2}>
        <PrimaryButton as={MediaPickerButton}>
          {replaceLabel ?? t('mediaUpload:button.NamedErsetzen', { named })}
        </PrimaryButton>
      </ButtonStack>
    );
  }

  if (!hasReplacement) {
    return (
      <ButtonStack>
        <PrimaryButton as={MediaPickerButton}>
          {firstPickLabel ?? t('mediaUpload:button.NamedHochladen', { named })}
        </PrimaryButton>
      </ButtonStack>
    );
  }

  return (
    <ButtonStack>
      <PrimaryButton as={MediaUploadButton}>
        {uploadLabel ?? t('mediaUpload:button.NamedHochladen', { named })}
      </PrimaryButton>
      <MediaUploadError />
    </ButtonStack>
  );
};

export interface MediaUploadBodyContentProps {
  firstPickContent?: React.ReactNode;
  replaceContent?: React.ReactNode;
  rejectedContent?: React.ReactNode;
  checkContent?: React.ReactNode;
}

/**
 * !Must be enclosed by:
 * - **{@link MediaPropertiesProvider}**
 * - **{@link MediaProvider}**
 * - **{@link MediaUploadProvider}**
 */
export const MediaUploadBodyContent: React.FC<MediaUploadBodyContentProps> = ({
  firstPickContent,
  checkContent,
  rejectedContent,
  replaceContent,
}) => {
  const { hasReplacement, initialSrc, rejectionReason } = useMedia();

  if (!hasReplacement && !!rejectionReason) {
    return (
      <>
        {rejectedContent ?? (
          <VStack textAlign={'center'} gap={'24px'}>
            <VStack>
              <MediaUploadModalHeading variant={'rejected'} />
              <MediaUploadModalText variant={'rejected'} />
            </VStack>
            <VStack gap={'16px'}>
              <MediaUploadFooterButtonStack />
              <FaqLink />
            </VStack>
          </VStack>
        )}
      </>
    );
  }

  if (!hasReplacement && !!initialSrc) {
    return (
      <>
        {replaceContent ?? (
          <VStack textAlign={'center'} gap={'24px'}>
            <VStack>
              <MediaUploadModalHeading variant={'replace'} />
              <MediaUploadModalText variant={'replace'} />
            </VStack>
            <VStack gap={'16px'}>
              <MediaUploadFooterButtonStack />
              <FaqLink />
            </VStack>
          </VStack>
        )}
      </>
    );
  }

  if (!hasReplacement) {
    return (
      <>
        {firstPickContent ?? (
          <VStack textAlign={'center'} gap={'24px'}>
            <VStack>
              <MediaUploadModalHeading variant={'firstPick'} />
              <MediaUploadModalText variant={'firstPick'} />
            </VStack>
            <VStack gap={'16px'}>
              <MediaUploadFooterButtonStack />
              <FaqLink />
            </VStack>
          </VStack>
        )}
      </>
    );
  }

  return (
    <>
      {checkContent ?? (
        <VStack textAlign={'center'} gap={'24px'}>
          <VStack>
            <MediaUploadModalHeading variant={'check'} />
            <MediaUploadModalText variant={'check'} />
          </VStack>
          <VStack gap={'16px'}>
            <MediaUploadFooterButtonStack />
          </VStack>
        </VStack>
      )}
    </>
  );
};
