import * as icons from '@campoint/odi-ui-icons';
import { Button, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { LayoutCenteringVStack } from '../../../../components/Layout/LayoutCenteringVStack';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { DateDisplay } from '../../../../components/shared/DateDisplay/DateDisplay';
import { FeesAndConditionsModalButton } from '../../../../components/shared/FeesAndConditionsModalButton/FeesAndConditionsModalButton';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../../../components/shared/cards/UniversalCard/UniversalCard';
import { FinancesPayoutStatusEnum } from '../../../../generated/graphql';
import { noop } from '../../../../utils';

type NextPayoutCardSectionProps = {
  isFirstPayout?: boolean;
  nextPaymentDate?: Date | null;
  status?: FinancesPayoutStatusEnum | null;
  hasEnoughAmountForPayout: boolean;
  minimalPayableAmount: number;
  onRequestWithdrawal?: () => void;
  onCancelWithdrawal?: () => void;
};

export const NextPayoutCardSection: React.FC<NextPayoutCardSectionProps> = ({
  isFirstPayout,
  nextPaymentDate,
  status,
  hasEnoughAmountForPayout,
  minimalPayableAmount,
  onRequestWithdrawal = noop,
  onCancelWithdrawal = noop,
}) => {
  const { t } = useTranslation(['payout', 'financePage']);
  const intl = useIntl();

  const minimalPayableAmountText = intl.formatNumber(
    Math.round(minimalPayableAmount),
    {
      currency: 'EUR',
      currencySign: 'standard',
      style: 'currency',
    }
  );

  const location = useLocation();
  const history = useHistory();

  const handleNavigateToAssistant = React.useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tabId', 'assistant');
    history.replace({
      search: queryParams.toString(),
      hash: UrlFragment.PayoutAssistant,
    });
  }, [location, history]);

  if (!nextPaymentDate) {
    return null;
  }

  const date = nextPaymentDate;
  switch (status) {
    case FinancesPayoutStatusEnum.ManualPayoutAllowed:
      return (
        <LayoutCenteringVStack>
          <UniversalCardSectionWithBorder>
            <ChakraUniversalCard
              cardBody={
                <>
                  <Icon as={icons.Info} boxSize={'icon.md'} />
                  <VStack mt="4" spacing="4">
                    <Heading size={'xl'} p={0} textAlign={'center'}>
                      {isFirstPayout
                        ? t(
                            'financePage:headline.EsIstSoweitDuKannstDenVerdienstAuszahlen'
                          )
                        : t(
                            'financePage:headline.EsIstWiederSoweitDuKannstDenVerdienstAuszahlen'
                          )}
                    </Heading>
                    <Text
                      size={{ base: 'sm', lg: 'md' }}
                      textAlign={'center'}
                      color={'gray.500'}
                      p={0}
                    >
                      <Trans
                        t={t}
                        i18nKey={
                          'financePage:text.AuszahlungVoraussichtlichAmDateComponent'
                        }
                        components={{
                          date: <DateDisplay date={date} />,
                        }}
                      />
                    </Text>
                  </VStack>
                </>
              }
              cardFooter={
                <VStack spacing={4}>
                  <Button
                    variant={'solid'}
                    children={t('payout:button.AuszahlungBeantragen')}
                    onClick={onRequestWithdrawal}
                  />
                  <FeesAndConditionsModalButton />
                </VStack>
              }
            />
          </UniversalCardSectionWithBorder>
        </LayoutCenteringVStack>
      );
    case FinancesPayoutStatusEnum.PayoutAlreadyRequested:
      return (
        <LayoutCenteringVStack>
          <UniversalCardSectionWithBorder>
            <ChakraUniversalCard
              cardBody={
                <>
                  <Icon as={icons.Info} boxSize={'icon.md'} />
                  <VStack mt="4" spacing="4">
                    <Heading size={'xl'} p={0} textAlign={'center'}>
                      {t(
                        'financePage:headline.DeinAuszahlungsantragWirdBearbeitet'
                      )}
                    </Heading>
                    <Text
                      size={{ base: 'sm', lg: 'md' }}
                      textAlign={'center'}
                      color={'gray.500'}
                      p={0}
                    >
                      <Trans
                        t={t}
                        i18nKey={
                          'financePage:text.AuszahlungVoraussichtlichAmDateComponent'
                        }
                        components={{
                          date: <DateDisplay date={date} />,
                        }}
                      />
                    </Text>
                  </VStack>
                </>
              }
              cardFooter={
                <VStack spacing={4}>
                  <Button
                    variant="solid"
                    children={t('payout:button.AuszahlungAbbrechen')}
                    onClick={onCancelWithdrawal}
                  />
                  <FeesAndConditionsModalButton />
                </VStack>
              }
            />
          </UniversalCardSectionWithBorder>
        </LayoutCenteringVStack>
      );
    case FinancesPayoutStatusEnum.AutomaticPayoutCreated:
      return (
        <LayoutCenteringVStack>
          <UniversalCardSectionWithBorder>
            <ChakraUniversalCard
              cardBody={
                <>
                  <Icon as={icons.Info} boxSize={'icon.md'} />
                  <VStack mt="4" spacing="4">
                    <Heading size={'xl'} p={0} textAlign={'center'}>
                      {t('financePage:headline.AutomatischeAuszahlungAktiv')}
                    </Heading>
                    <Text
                      size={{ base: 'sm', lg: 'md' }}
                      textAlign={'center'}
                      color={'gray.500'}
                      p={0}
                    >
                      <Trans
                        t={t}
                        i18nKey={
                          'financePage:text.AuszahlungVoraussichtlichAmDateComponent'
                        }
                        components={{
                          date: <DateDisplay date={date} />,
                        }}
                      />
                    </Text>
                  </VStack>
                </>
              }
              cardFooter={
                <VStack spacing={4}>
                  <Button
                    variant="solid"
                    children={t('payout:button.ZurAutomAuszahlung')}
                    onClick={handleNavigateToAssistant}
                  />
                  <FeesAndConditionsModalButton />
                </VStack>
              }
            />
          </UniversalCardSectionWithBorder>
        </LayoutCenteringVStack>
      );
    default:
      if (!hasEnoughAmountForPayout) {
        return (
          <LayoutCenteringVStack>
            <UniversalCardSectionWithBorder>
              <ChakraUniversalCard
                cardBody={
                  <>
                    <Icon as={icons.Info} boxSize={'icon.md'} />
                    <VStack mt="4" spacing="4">
                      <Heading size={'xl'} p={0} textAlign={'center'}>
                        {t(
                          'financePage:headline.AkutellKeineAuszahlungMoglich'
                        )}
                      </Heading>
                      <Text
                        size={{ base: 'sm', lg: 'md' }}
                        textAlign={'center'}
                        color={'gray.500'}
                        p={0}
                      >
                        {t(
                          'financePage:text.VerdieneUberAmountUmEineAuszahlungZuErmoglichen',
                          { amount: minimalPayableAmountText }
                        )}
                      </Text>
                    </VStack>
                  </>
                }
                cardFooter={
                  <VStack spacing={4}>
                    <Button
                      variant={'solid'}
                      children={t('payout:button.AuszahlungBeantragen')}
                      isDisabled
                    />
                    <FeesAndConditionsModalButton />
                  </VStack>
                }
              />
            </UniversalCardSectionWithBorder>
          </LayoutCenteringVStack>
        );
      }
      return null;
  }
};
