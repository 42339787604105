import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  IncomeSourceEntryFragment,
  TipEntryFragment,
} from '../generated/graphql';
import { ExperienceIntentionFilterConfig } from '../provider/FilterablListProvider';

type UseFilterProps<Entry, FilterConfig> = {
  /**
   * Key used for the local storage to persist the selected filters.
   */
  localStorageKey: string;
  initialFilterConfig: FilterConfig;
  entries: Entry[];
  filterFn: (entry: Entry, filter: FilterConfig) => boolean;
  hasActiveFilterFn: (filter: FilterConfig) => boolean;
};

type UseFilterHelperReturnType<Entry, FilterConfig> = {
  readonly filter: FilterConfig;
  setFilter: React.Dispatch<React.SetStateAction<FilterConfig>>;
  resetFilter: () => void;
  readonly entries: Entry[];
  readonly filteredEntries: Entry[];
  predictEntryCountForFilter: (filter: FilterConfig) => number;
  readonly hasActiveFilter: boolean;
};

export function useFilterHelper<Entry, FilterConfig>({
  localStorageKey,
  entries,
  filterFn,
  initialFilterConfig,
  hasActiveFilterFn,
}: UseFilterProps<Entry, FilterConfig>): UseFilterHelperReturnType<
  Entry,
  FilterConfig
> {
  /**
   * Make the selected filter available via state.
   * Filters will be initialized from local storage
   * to restore previously selected filters.
   */
  const [filter, setFilter] = useState<FilterConfig>(() => {
    const item = localStorage.getItem(localStorageKey);
    return item ? JSON.parse(item) : initialFilterConfig;
  });

  /**
   * Filters will be persisted in local storage to be able
   * to restore them after component unmount.
   */
  useEffect(
    () => localStorage.setItem(localStorageKey, JSON.stringify(filter)),
    [filter, localStorageKey]
  );

  const resetFilter = useCallback(
    () => setFilter(initialFilterConfig),
    [initialFilterConfig, setFilter]
  );

  const filterEntries = useCallback(
    (filterConfig: FilterConfig) =>
      entries.filter((entry) => filterFn(entry, filterConfig)),
    [entries, filterFn]
  );

  const filteredEntries = useMemo(
    () => filterEntries(filter),
    [filter, filterEntries]
  );

  const predictEntryCountForFilter = useCallback(
    (filterConfig: FilterConfig) => filterEntries(filterConfig).length,
    [filterEntries]
  );

  const hasActiveFilter = useMemo(
    () => hasActiveFilterFn(filter),
    [filter, hasActiveFilterFn]
  );

  return {
    filter,
    setFilter,
    resetFilter,
    entries,
    filteredEntries,
    predictEntryCountForFilter,
    hasActiveFilter,
  };
}

export function useExperienceIntentionFilterHelper<
  Entry extends TipEntryFragment | IncomeSourceEntryFragment
>({ localStorageKey, entries }: { localStorageKey: string; entries: Entry[] }) {
  return useFilterHelper<Entry, ExperienceIntentionFilterConfig>({
    localStorageKey,
    entries,
    initialFilterConfig: {
      experience: [],
      intention: [],
    },
    filterFn: (entry, filter) =>
      !!(
        (!filter.experience.length ||
          entry?.experienceTags?.filter((tag) =>
            filter.experience.includes(tag?.id!)
          ).length) &&
        (!filter.intention.length ||
          entry?.intentionTags?.filter((tag) =>
            filter.intention.includes(tag?.id!)
          ).length)
      ),
    hasActiveFilterFn: (filter) =>
      !!Object.values(filter).reduce((acc, curr) => acc + curr?.length ?? 0, 0),
  });
}
