import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { Box, Container, SimpleGrid, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import ExperienceIntentionImageCard from '../../components/shared/ExperienceIntentionImageCard/ExperienceIntentionImageCard';
import { ExperienceIntentionStatusIndicatorIncomeSources } from '../../components/shared/ExperienceIntentionStatusIndicator/ExperienceIntentionStatusIndicator';
import { FilterToolRow } from '../../components/shared/FilterToolRow/FilterToolRow';
import { IncomeSourceEntryFragment } from '../../generated/graphql';
import {
  IncomeSourceDetailModalProvider,
  useIncomeSourceDetailModal,
} from '../../provider/IncomeSourceDetailModalProvider';
import {
  IncomeSourceProvider,
  filteredIncomeSourceListContext,
} from '../../provider/IncomeSourceProvider';
import { IncomeSourcesPageFilter } from './IncomeSourcesPageFilter/IncomeSourcesPageFilter';

const sortEntries = (
  a: IncomeSourceEntryFragment,
  b: IncomeSourceEntryFragment
) => {
  if (a.activatedAt === null) {
    return -1;
  }

  if (b.activatedAt === null) {
    return 1;
  }

  if (a.activatedAt === b.activatedAt) {
    return 0;
  }

  return a.activatedAt < b.activatedAt ? -1 : 1;
};

const EntryCard: React.FC<{ entry: IncomeSourceEntryFragment }> = ({
  entry,
}) => {
  const incomeSourceDetailModalCtx = useIncomeSourceDetailModal();

  return (
    <ExperienceIntentionImageCard
      key={entry.id}
      id={entry.id}
      entry={entry}
      onClick={() =>
        incomeSourceDetailModalCtx.action.showIncomeSource(entry.id)
      }
      width={'full'}
      statusIndicator={
        entry.activatedAt && (
          <ExperienceIntentionStatusIndicatorIncomeSources status={'active'} />
        )
      }
    />
  );
};

const IncomeSourcesPage: React.FC = () => {
  const { t } = useTranslation(['incomeSourcesPage']);

  return (
    <IncomeSourceProvider>
      <filteredIncomeSourceListContext.Consumer>
        {(incomeSourceContext) => (
          <IncomeSourceDetailModalProvider>
            <FluentPageLayout isContentLoading={incomeSourceContext.loading}>
              <SubHeader>
                <Container px={0} maxW={'container.xl'}>
                  <SubHeaderTitle>
                    {t('incomeSourcesPage:headline.AlleVerdienstmoglichkeiten')}
                  </SubHeaderTitle>
                </Container>
              </SubHeader>
              <ScrollToTopOnMount />
              <Container maxW="container.xl" p={0}>
                <VStack alignItems={'start'} spacing={'4'}>
                  <Box bg={'white'} w={'full'} p={3}>
                    <FilterToolRow
                      context={incomeSourceContext}
                      renderCountDisplay={(count) =>
                        t(
                          'incomeSourcesPage:text.CountVerdienstmoglichkeiten',
                          {
                            count,
                          }
                        )
                      }
                    />
                    {incomeSourceContext.isFilterSheetOpen && (
                      <IncomeSourcesPageFilter context={incomeSourceContext} />
                    )}
                  </Box>
                  <SimpleGrid
                    px={'4'}
                    pb={'6'}
                    gap={'4'}
                    columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  >
                    {incomeSourceContext.filteredEntries
                      .sort((a, b) => sortEntries(a, b))
                      .map((entry) => (
                        <EntryCard key={entry.id} entry={entry} />
                      ))}
                  </SimpleGrid>
                </VStack>
              </Container>
            </FluentPageLayout>
          </IncomeSourceDetailModalProvider>
        )}
      </filteredIncomeSourceListContext.Consumer>
    </IncomeSourceProvider>
  );
};

export default IncomeSourcesPage;
