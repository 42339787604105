import * as icons from '@campoint/odi-ui-icons';
import { HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const MediaStatisticBanner: React.FC<StackProps> = ({ ...rest }) => {
  const { t } = useTranslation(['videoLibrary']);

  return (
    <HStack
      as={'button'}
      role={'button'}
      justifyContent={'space-between'}
      h={'10'}
      w={'full'}
      pl={'4'}
      pr={'2'}
      fontWeight={'medium'}
      bg={'steel'}
      color={'gray.900'}
      {...rest}
    >
      <Text as={'span'}>{t('videoLibrary:button.StatistikAnsehen')}</Text>
      <Icon as={icons.ChevronRight} boxSize={'icon.md'} />
    </HStack>
  );
};
