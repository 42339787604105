import {
  Section,
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import {
  AttachFile,
  ChevronRight,
  Error,
  GppMaybe,
} from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { LayoutCenteringVStack } from '../../../../components/Layout/LayoutCenteringVStack';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import {
  DocumentPicker,
  PickedDocument,
} from '../../../../components/shared/DocumentPicker/DocumentPicker';
import {
  ClearableInputControl,
  RadioGroupControl,
} from '../../../../components/shared/FormikFormElements';
import { HelpLink } from '../../../../components/shared/HelpLink/HelpLink';
import { FeatureName, useFeature } from '../../../../flags';
import {
  ProfileFieldsEnum,
  useCheckTaxIdValidityMutation,
  useGetUploadInfoForFieldLazyQuery,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { propertiesToBoolean2 } from '../../../../hooks/useFormikErrorSetter';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';
import { MediaPropertiesProvider } from '../../../../provider/MediaPropertiesProvider';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { externalRoutes, routes } from '../../../../routes/routesConfig';
import { extractFromUnion } from '../../../../utils/extractor';
import { uploadDocument } from '../../../../utils/media';
import { createStringValidationSchema } from '../../../../utils/validation';
import { extractCurrentFieldError } from '../../../Wizard/utils/utils';
import { PayoutPendingAlert } from '../../components/alert/PayoutPendingAlert/PayoutPendingAlert';
import { PayoutRejectAlert } from '../../components/alert/PayoutRejectAlert/PayoutRejectAlert';

export const InfoPayoutWillBeBlockedAlert: React.FC<AlertProps> = ({
  ...alertProps
}) => {
  const { t } = useTranslation(['payout']);
  const history = useHistory();
  const { coinsEnabled } = useFlagsProviderV2();
  const documentFlag = useFeature(FeatureName.document);
  const documentPreferredForFinanceDocumentFlag = useFeature(
    FeatureName.documentPreferredForFinanceDocument
  );

  const {
    isPayoutTaxUpdateVisible,
    isPayoutTaxUpdatePending,
    isPayoutTaxUpdateRejected,
    isPayoutTaxDocumentExpiringSoon,
    daysTillPayoutTaxDocumentExpires,
  } = useFinanceService();

  const showPayoutTaxDocumentExpiresHint =
    isPayoutTaxUpdateVisible &&
    !isPayoutTaxUpdatePending &&
    !isPayoutTaxUpdateRejected &&
    isPayoutTaxDocumentExpiringSoon &&
    !coinsEnabled;

  const navigateToPayoutTaxSection = () => {
    if (documentFlag && documentPreferredForFinanceDocumentFlag) {
      history.push(
        `${routes.documentsPayout.path}#${UrlFragment.TaxEditSection}`
      );
    } else {
      history.push(
        `${routes.finance.path}?tapId=${'vat'}#${UrlFragment.TaxEditSection}`
      );
    }
  };

  return !showPayoutTaxDocumentExpiresHint ? null : (
    <LayoutCenteringVStack>
      <Alert
        status={'success'}
        backgroundColor={'caribbeanGreen.100'}
        borderColor={'caribbeanGreen.300'}
        cursor={'pointer'}
        p={{ base: 2, lg: 4 }}
        onClick={navigateToPayoutTaxSection}
        {...alertProps}
      >
        <AlertIcon>
          <Icon as={Error} boxSize={'icon.md'} color={'caribbeanGreen.500'} />
        </AlertIcon>
        <AlertDescription
          flexGrow={1}
          color={'coldGray.700'}
          fontWeight={'medium'}
        >
          {t(
            'payout:text.AktualisiereInnerhalbDerNachstenCountTageDeineAngabenZurSteuerlichenXX',
            {
              count: daysTillPayoutTaxDocumentExpires ?? 0,
            }
          )}
        </AlertDescription>
        <Icon
          as={ChevronRight}
          boxSize={'icon.md'}
          color={'caribbeanGreen.500'}
        />
      </Alert>
    </LayoutCenteringVStack>
  );
};

const PayoutTaxPendingAlert = () => {
  const { t } = useTranslation(['payout']);
  const lang = useActiveApiLanguage();

  return (
    <PayoutPendingAlert
      alertDescription={t(
        'payout:text.DeineAngabenZurSteuerlichenEinordnungWerdenUberpruft'
      )}
      modalHeading={t('payout:headline.DeineAnderungenWerdenUberpruft')}
      modalTextSlot={
        <Text>
          {t('payout:text.WirMeldenUnsBeiDirSobaldEsNeuigkeitenGibt')}
        </Text>
      }
      modalHelpLinkSlot={
        <HelpLink
          // todo: Link propper Zendesk article
          href={externalRoutes.helpCenter(lang)}
        >
          {t(
            'payout:text.WieLangeDauertDieUberprufungMeinerAngabenZurSteuerlichenEinordnung'
          )}
        </HelpLink>
      }
    />
  );
};

const PayoutTaxRejectedAlert: React.FC<{ rejectReason: string }> = ({
  rejectReason,
}) => {
  const { t } = useTranslation(['payout']);
  const lang = useActiveApiLanguage();

  return (
    <PayoutRejectAlert
      alertDescription={t(
        'payout:text.DeineAngabenZurSteuerlichenEinordnungWurdenAbgelehnt'
      )}
      modalHeading={t(
        'payout:headline.DeineAngabenZurSteuerlichenEinordnungWurdenAbgelehnt'
      )}
      modalTextSlot={
        <Text>{t('payout:text.GrundReason', { reason: rejectReason })}</Text>
      }
      modalHelpLinkSlot={
        <HelpLink
          // todo: Link propper Zendesk article
          href={externalRoutes.helpCenter(lang)}
        >
          {t(
            'payout:text.WiesoWerdenMeineAngabenZurSteuerlichenEinordnungAbgelehnt'
          )}
        </HelpLink>
      }
      modalFooterSlot={(onClose) => (
        <Button onClick={onClose}>
          {t('payout:button.NochEinmalVersuchen')}
        </Button>
      )}
    />
  );
};

const fieldName = {
  isTaxable: ProfileFieldsEnum.PayoutIsTaxable,
  taxId: ProfileFieldsEnum.PayoutTaxId,
} as const;

interface Values {
  [fieldName.isTaxable]: 'yes' | 'no';
  [fieldName.taxId]: string;
}

const PayoutTaxSection: React.FC = () => {
  // const accountStatusCtx = useAccountStatus();
  const [pickedDocument, setPickedDocument] = useState<PickedDocument | null>(
    null
  );
  const { t } = useTranslation([
    'general',
    'imagePicker',
    'payout',
    'wizardPayout',
    'validation',
  ]);
  const lang = useActiveApiLanguage();

  const {
    onOmitChangesTriggerCount,
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const {
    fields,
    isPayoutTaxUpdatePending,
    isPayoutTaxUpdateRejected,
    isPayoutTaxUpdateEditAllowed,
    payoutTaxUpdateRejectReason,
    isPayoutTaxDocumentUploadRequiredWhenTaxable,
    action: { updatePayoutCollection },
  } = useFinanceService();

  const isSomeFieldPending = isPayoutTaxUpdatePending;
  const isSomeFieldRejected = isPayoutTaxUpdateRejected;

  const rejectReason = payoutTaxUpdateRejectReason;

  const taxable = fields?.payoutIsTaxable?.value ?? false;
  const taxID = fields?.payoutTaxId?.value ?? '';
  const isTaxDocumentRequired = isPayoutTaxDocumentUploadRequiredWhenTaxable;

  const [getUploadInfo] = useGetUploadInfoForFieldLazyQuery({
    variables: {
      name: ProfileFieldsEnum.PayoutTaxDocument,
    },
  });

  const [checkTaxIdValidity] = useCheckTaxIdValidityMutation();

  const submit = React.useCallback<FormikConfig<Values>['onSubmit']>(
    async (values, formikHelpers) => {
      const isTaxable = values[fieldName.isTaxable] === 'yes';

      if (!isTaxable) {
        const errors = await updatePayoutCollection({
          [ProfileFieldsEnum.PayoutIsTaxable]: false,
        });

        if (errors && errors.hasErrors) {
          const extractedErrors = extractCurrentFieldError(errors.field);
          formikHelpers.setErrors(extractedErrors as any);
          formikHelpers.setTouched(
            propertiesToBoolean2(extractedErrors as any),
            false
          );
          return;
        }
        return;
      }

      const taxId = values[fieldName.taxId];

      let docId = null;
      if (isTaxDocumentRequired) {
        if (!pickedDocument) {
          return;
        }

        const taxIdCheckResult = await checkTaxIdValidity({
          variables: {
            taxId,
          },
        });
        const taxIdCheckField = extractFromUnion(
          taxIdCheckResult.data?.profile?.validate,
          'ProfileFieldTypeValidationString'
        );

        // Check taxId prior to upload,
        // to avoid having the document enter pending mode which blocks further edits
        if (taxIdCheckField && !taxIdCheckField?.isValid) {
          const extractedErrors = extractCurrentFieldError([taxIdCheckField]);
          formikHelpers.setErrors(extractedErrors as any);
          formikHelpers.setTouched(
            propertiesToBoolean2(extractedErrors as any),
            false
          );
          return;
        }

        const res = await getUploadInfo();

        if (
          res.data?.profile?.field?.__typename !==
          'ProfileFieldTypeUploadDocument'
        ) {
          return;
        }

        const uploadUrl = res.data?.profile?.field.upload?.url;
        if (!uploadUrl) {
          return;
        }

        const uploadResponse = await uploadDocument(
          uploadUrl,
          pickedDocument.blob
        );
        docId = uploadResponse.id;
      }

      const errors = await updatePayoutCollection({
        [ProfileFieldsEnum.PayoutIsTaxable]: true,
        [ProfileFieldsEnum.PayoutTaxId]: taxId,
        [ProfileFieldsEnum.PayoutTaxDocument]: docId,
      });

      if (errors && errors.hasErrors) {
        const extractedErrors = extractCurrentFieldError(errors.field);
        formikHelpers.setErrors(extractedErrors as any);
        formikHelpers.setTouched(
          propertiesToBoolean2(extractedErrors as any),
          false
        );
        return;
      }

      setPickedDocument(null);
    },
    [
      getUploadInfo,
      pickedDocument,
      setPickedDocument,
      updatePayoutCollection,
      isTaxDocumentRequired,
      checkTaxIdValidity,
    ]
  );
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      [fieldName.taxId]: createStringValidationSchema({
        ...fields?.[ProfileFieldsEnum.PayoutTaxId],
      }),
    }),
    initialValues: {
      [fieldName.isTaxable]: taxable ? 'yes' : 'no',
      [fieldName.taxId]: taxID ?? '',
    },
    onSubmit: submit,
  });

  const { resetForm, dirty, isValid } = formik;

  const onOmitChanges = React.useCallback(() => {
    resetForm();
    setPickedDocument(null);
  }, [setPickedDocument, resetForm]);

  const isDirty = !!pickedDocument || dirty;

  React.useEffect(() => {
    return registerDirtyFlag(isDirty, onOmitChanges);
  }, [isDirty, onOmitChanges, registerDirtyFlag]);

  const isReadOnly =
    !isPayoutTaxUpdateEditAllowed ||
    (isSomeFieldPending && !isSomeFieldRejected);

  const isTaxable = formik.values[fieldName.isTaxable] === 'yes';

  const hasPickedDocument = !!pickedDocument;

  const isSubmittingDisabled = React.useMemo(() => {
    if (isReadOnly) {
      return true;
    }
    if (!isTaxable) {
      return !dirty || !isValid;
    }

    if (isTaxable && !isTaxDocumentRequired) {
      return !dirty || !isValid;
    }

    if (isTaxable && isTaxDocumentRequired) {
      // taxId might stay as is
      return !hasPickedDocument || !isValid;
    }
  }, [
    isReadOnly,
    isTaxable,
    isTaxDocumentRequired,
    hasPickedDocument,
    dirty,
    isValid,
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.TaxEditSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={GppMaybe} />
            <SectionTitle>
              {t('payout:headline.SteuerlicheAngaben')}
            </SectionTitle>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody>
        <SectionCenterContainer spacing={8}>
          {!isSomeFieldRejected && isSomeFieldPending && (
            <PayoutTaxPendingAlert />
          )}
          {isSomeFieldRejected && (
            <PayoutTaxRejectedAlert rejectReason={rejectReason ?? ''} />
          )}
          {
            <>
              <FormikProvider value={formik}>
                <RadioGroupControl
                  name={fieldName.isTaxable}
                  options={[
                    {
                      isReadOnly,
                      children: t('payout:radio.IchBinKleinunternehmer'),
                      value: 'no',
                    },
                    {
                      isReadOnly,
                      children: t('payout:radio.IchBinUmsatzsteuerpflichtig'),
                      value: 'yes',
                    },
                  ]}
                />
                <VStack spacing={4}>
                  {formik.values[fieldName.isTaxable] !== 'yes' ? (
                    <></>
                  ) : (
                    <>
                      <ClearableInputControl
                        name={fieldName.taxId}
                        label={t('payout:label.SteuernummerUmsatzsteuerID')}
                        isReadOnly={isReadOnly}
                        inputProps={{
                          placeholder: t('payout:placeholder.NummerEingeben'),
                        }}
                      />
                    </>
                  )}

                  <Flex
                    display={
                      formik.values[fieldName.isTaxable] !== 'yes' ||
                      !isTaxDocumentRequired
                        ? 'none'
                        : 'inherit'
                    }
                    flexDir={'column'}
                    alignItems={'stretch'}
                    w={'full'}
                  >
                    <Heading
                      as={'label'}
                      fontSize={'xl'}
                      fontWeight={'medium'}
                      children={t('payout:label.Umsatzsteuernachweis')}
                    />
                    <Text
                      pb={'3'}
                      fontSize={'sm'}
                      lineHeight={'5'}
                      color={'gray.500'}
                      children={t(
                        'payout:text.OffiziellesDokumentDesSteuerberatersFinanzamtsDasUmsatzsteuerpflichtXX'
                      )}
                    />
                    {!isReadOnly &&
                    (!isSomeFieldPending || isSomeFieldRejected) ? (
                      <MediaPropertiesProvider
                        targetDimensions={{
                          width: 520,
                          height: 520,
                        }}
                        determineAspectRatioByInput={true}
                      >
                        <DocumentPicker
                          key={onOmitChangesTriggerCount}
                          targetImageDimensions={{
                            width: 520,
                            height: 520,
                          }}
                          label={t(
                            'payout:label.UmsatzsteuernachweisHochladen'
                          )}
                          isUploading={formik.isSubmitting}
                          uploadingLabel={t(
                            'wizardPayout:text.DokumentWirdHochgeladen'
                          )}
                          cropButtonLabel={t('imagePicker:crop')}
                          cancelCropButtonLabel={t('imagePicker:cancel')}
                          onDocument={setPickedDocument}
                        />
                      </MediaPropertiesProvider>
                    ) : (
                      <Box
                        w={'full'}
                        p={2}
                        border={'1px'}
                        borderRadius={'md'}
                        borderColor={'gray.500'}
                      >
                        <HStack minH={'10'}>
                          <Icon as={AttachFile} boxSize={'icon.md'} />
                          <chakra.span flexGrow={1}>
                            {t('payout:text.Umsatzsteuernachweis')}
                          </chakra.span>
                        </HStack>
                      </Box>
                    )}
                  </Flex>
                </VStack>
              </FormikProvider>
              <Box>
                <Heading size={'sm'}>
                  {t('payout:headline.DuHastFragenZurUmsatzsteuer')}
                </Heading>
                <HelpLink
                  href={externalRoutes.helpCenterArticleAllesZurUmsatzsteuerpflicht(
                    lang
                  )}
                >
                  {t('payout:text.AllesZurUmsatzsteuerpflicht')}
                </HelpLink>
              </Box>
            </>
          }
        </SectionCenterContainer>
      </SectionBody>
      <SectionDivider isWidthRestricted />
      <SectionFooter>
        <SectionCenterContainer alignItems={'center'}>
          <Button
            isDisabled={isSubmittingDisabled}
            onClick={onOpen}
            isLoading={formik.isSubmitting}
          >
            {t('payout:button.AngabenAktualisieren')}
          </Button>
          <ResponsiveModal isOpen={isOpen} onClose={onClose} isCentered>
            <ResponsiveModalOverlay />
            <ResponsiveModalContent>
              <ResponsiveModalStickyHeaderBox>
                <Heading size={'xl'}>
                  {t(
                    'payout:headline.MochtestDuDeineAnderungenZurUberprufungEinreichen'
                  )}
                </Heading>
              </ResponsiveModalStickyHeaderBox>
              <ResponsiveModalBodyBox>
                <Text>
                  {t(
                    'payout:text.WennDuFortfahrstStartetEinUberprufungsprozessDerEtwasZeitInAnspruchNXX'
                  )}
                </Text>
                <Text>
                  {t(
                    'payout:text.DeineAltenAngabenBleibenErhaltenSolangeDeineAnderungenNichtAkzeptierXX'
                  )}
                </Text>
              </ResponsiveModalBodyBox>
              <ResponsiveModalStickyFooterBox>
                <VStack>
                  <Button
                    onClick={() => {
                      onClose();
                      formik.handleSubmit();
                    }}
                  >
                    {t('payout:button.SpeichernEinreichen')}
                  </Button>
                  <Button onClick={onClose} flexShrink={1}>
                    {t('payout:button.AnderungenNochEinmalKontrollieren')}
                  </Button>
                </VStack>
              </ResponsiveModalStickyFooterBox>
            </ResponsiveModalContent>
          </ResponsiveModal>
        </SectionCenterContainer>
      </SectionFooter>
    </Section>
  );
};

export default PayoutTaxSection;
