import { ApolloError } from '@apollo/client';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Spinner,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOnScreen } from '../../../hooks/useOnScreen';

export const FetchMoreIndicator: React.FC<
  {
    loading: boolean;
    error: ApolloError | undefined;
    onIsVisible: () => void;
  } & StackProps
> = ({ loading, error, onIsVisible, ...props }) => {
  const { t } = useTranslation(['feed']);
  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);

  React.useEffect(() => {
    if (isVisible) {
      onIsVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <VStack {...props} ref={ref}>
      {!error && loading && <Spinner size={'lg'} />}
      {error && !loading && (
        <Alert status={'info'} variant={'outline'}>
          <AlertIcon />
          <AlertTitle>
            {t('feed:text.BeimLadenIstEinFehlerAufgetreten')}:
          </AlertTitle>
          <AlertDescription>
            <Button variant={'link'} onClick={onIsVisible}>
              {t('feed:button.ErneutLaden')}
            </Button>
          </AlertDescription>
        </Alert>
      )}
    </VStack>
  );
};
