import * as icons from '@campoint/odi-ui-icons';
import { Box } from '@chakra-ui/layout';
import { useTranslation } from 'react-i18next';

import {
  StatusDone,
  StatusError,
  StatusPending,
} from '../../../components/shared/StatusIndicator/StatusIndicator';
import {
  PhotoalbumReleasedStatusEnum,
  VideoReleasedStatusEnum,
} from '../../../generated/graphql';
import { albumRejected } from './VideoLibraryPageTabSectionPhoto/VideoLibraryPageTabSectionPhoto';

type TileStatusIndicatorStatusType =
  | VideoReleasedStatusEnum
  | PhotoalbumReleasedStatusEnum
  | string
  | null
  | undefined;

export const TileStatusIndicator: React.FC<{
  plannedReleaseDate?: any;
  status: TileStatusIndicatorStatusType;
  contestStartForStatus?: any;
}> = ({ plannedReleaseDate, status, contestStartForStatus }) => {
  const { t } = useTranslation(['general']);
  const isContestStartInFuture = contestStartForStatus
    ? new Date(contestStartForStatus) > new Date()
    : false;

  const getStatusIndicatorByVideoStatus = (
    status: TileStatusIndicatorStatusType
  ) => {
    if (status === null || status === undefined) return null;
    if (
      (status === VideoReleasedStatusEnum.Online ||
        status === PhotoalbumReleasedStatusEnum.Online) &&
      !isContestStartInFuture
    ) {
      return <StatusDone text={t('general:status.Online')} />;
    } else if (
      status === VideoReleasedStatusEnum.Planned ||
      isContestStartInFuture
    ) {
      return (
        <StatusPending
          text={t('general:status.Geplant', {
            date: isContestStartInFuture
              ? contestStartForStatus
              : plannedReleaseDate,
          })}
        />
      );
    } else if (
      status === VideoReleasedStatusEnum.Offline ||
      status === PhotoalbumReleasedStatusEnum.Offline
    ) {
      return (
        <StatusError
          text={t('general:status.Offline')}
          icon={icons.VisibilityOff}
        />
      );
    } else if (albumRejected) {
      return <StatusError text={t('general:status.Abgelehnt')} />;
    } else {
      return null;
    }
  };

  return <Box flex={1}>{getStatusIndicatorByVideoStatus(status)}</Box>;
};
