import { InputProps, forwardRef } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { useContainerWidth } from '../../../../hooks/useContainerWidth';
import { BaseProps, FormControl } from '../FormControl';
import { ClearableInput } from './ClearableInput';
import { ClearableInputClearElement } from './ClearableInputClearElement';
import {
  ClearableInputGroup,
  ClearableInputGroupProps,
} from './ClearableInputGroup';

export type ClearableInputControlProps = BaseProps & {
  inputGroupProps?: ClearableInputGroupProps;
  /**
   * input Props are only set if you've defined no children
   */
  inputProps?: InputProps;
  children?: React.ReactNode;
};

export const ClearableInputControl = forwardRef<
  ClearableInputControlProps,
  'input'
>((props, ref) => {
  const {
    name,
    label,
    inputGroupProps,
    inputProps,
    children,
    placeholder,
    ...rest
  } = props;
  const [field] = useField(name);
  const { ref: clearableRef, width: clearableWidth } = useContainerWidth();

  // We need to declare this function here, because Input does return the changed
  // value as a parameter and the change event optionally
  // this is because the clearable action does not emit an event
  const onChange: Required<ClearableInputGroupProps>['onChange'] =
    React.useCallback(
      (value, event) => {
        if (event) {
          field.onChange(event);
        } else {
          field.onChange({
            target: {
              name,
              value,
            },
          });
        }
      },
      [field, name]
    );

  return (
    <FormControl name={name} label={label} {...rest}>
      <ClearableInputGroup {...field} onChange={onChange} {...inputGroupProps}>
        {children ?? [
          <ClearableInput
            key="input"
            {...field}
            placeholder={placeholder}
            {...inputProps}
            ref={ref}
            pr={`${clearableWidth}px`}
          />,
          <ClearableInputClearElement key="right-element" ref={clearableRef} />,
        ]}
      </ClearableInputGroup>
    </FormControl>
  );
});
