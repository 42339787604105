import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FeedReplyFragment } from '../../../generated/feed';
import { usePostCommenting } from '../../../provider/PostCommentingProvider';
import Logger from '../../../utils/Logger';
import { CommentCreate } from './CommentCreate';
import { CommentFromUser } from './CommentFromUser';
import { FeedCommentEdit } from './FeedCommentEdit';
import { FeedCommentFromOtherUser } from './FeedCommentFromOtherUser';

export type FeedCommentReplyProps = {
  reply: FeedReplyFragment;
  openConfirmDeletionDialog: (commentId: string) => void;
};

/**
 * Manages the several states of a reply
 * * referencing reply is open
 * * is in edit mode *
 */
export const FeedCommentReply: React.FC<FeedCommentReplyProps> = ({
  reply,
  openConfirmDeletionDialog,
}) => {
  const ctx = usePostCommenting();
  const replyTo = useDisclosure();
  const edit = useDisclosure();
  const { t } = useTranslation(['feed']);

  const replyIsFromAccount = reply.user.id === ctx.account?.id;

  return (
    <>
      {!replyIsFromAccount && (
        <FeedCommentFromOtherUser
          avatarSrc={reply.user.avatar?.url}
          username={reply.user.name ?? ''}
          text={reply.text}
          created={reply.created}
          hasOpenReplyTo={replyTo.isOpen}
          onReplyTo={replyTo.onOpen}
          commentId={reply.id}
          openConfirmDeletionDialog={openConfirmDeletionDialog}
          tipped={reply.tipped}
        />
      )}
      {replyIsFromAccount &&
        (!edit.isOpen ? (
          <CommentFromUser
            avatarSrc={reply.user.avatar?.url}
            username={reply.user.name ?? ''}
            text={reply.text}
            created={reply.created}
            onEdit={edit.onOpen}
            commentId={reply.id}
            openConfirmDeletionDialog={openConfirmDeletionDialog}
          />
        ) : (
          <FeedCommentEdit
            avatarSrc={reply.user.avatar?.url}
            username={reply.user.name ?? ''}
            initialText={reply.text}
            onDiscard={edit.onClose}
            onSubmit={async (values, formikHelpers) => {
              const text = values.text;
              try {
                await ctx.action.editComment(text, reply.id);
                edit.onClose();
              } catch (error) {
                Logger.error(error);
                formikHelpers.setSubmitting(false);
              }
            }}
          />
        ))}
      {replyTo.isOpen && (
        <CommentCreate
          placeholder={t('feed:placeholder.Antworten')}
          initialText={!reply.user?.name ? undefined : `@${reply.user.name} `}
          username={ctx.account?.name}
          avatarSrc={ctx.account?.avatar?.url}
          repliesToCommentId={reply.id}
          onClose={replyTo.onClose}
          onSubmit={async (values, formikHelpers) => {
            const text = values.text;
            try {
              const result = await ctx.action.addReply(text, reply.id);
              if (result.errors) {
                Logger.error(result.errors);
              } else {
                replyTo.onClose();
              }
            } catch (error) {
              Logger.error(error);
              formikHelpers.setSubmitting(false);
            }
          }}
          registerOpenComment={ctx.action.registerOpenComment}
        />
      )}
    </>
  );
};
