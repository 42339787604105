import {
  ChatbotConfig,
  ChatbotCouldNotProcessRequestError,
  ChatbotError,
  ChatbotGenericWebsocketError,
  ChatbotInactivityTimeoutError,
  ChatbotUnknownBackendError,
} from '@campoint/vxmodels-chatbot';
import { TFunction } from 'i18next';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';

export const VXMChatbotConfig: ChatbotConfig = {
  getWebsocketUrl: () => 'wss://aicoach.services.vxmodels.com/assistant',
  getHeartbeatInterval: () => 3000,
};

export const getHumanURL = (lang: string) => {
  return (
    'https://helpcenter.vxmodels.com/hc/' + lang + '/requests/new?openChat=true'
  );
};

export const errorHandler =
  (t: TFunction<['chatbot'], undefined>) => (error: ChatbotError) => {
    console.error('VXMChatbot Error', error);
    if (error instanceof ChatbotGenericWebsocketError) {
      issueChakraToast({
        status: 'error',
        description: t('chatbot:error.ChatbotGenericWebsocketError'),
      });
    } else if (error instanceof ChatbotCouldNotProcessRequestError) {
      issueChakraToast({
        status: 'error',
        description: t('chatbot:error.ChatbotCouldNotProcessRequestError'),
      });
    } else if (error instanceof ChatbotInactivityTimeoutError) {
      issueChakraToast({
        status: 'error',
        description: t('chatbot:error.ChatbotInactivityTimeoutError'),
      });
    } else if (error instanceof ChatbotUnknownBackendError) {
      issueChakraToast({
        status: 'error',
        description: t('chatbot:error.ChatbotUnknownBackendError', {
          error: error.message,
        }),
      });
    } else {
      // do not show error
    }
  };
