import { Add } from '@campoint/odi-ui-icons';
import { Divider, Icon, ListItem } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StatisticListItemIcon from '../StatisticListItemIcon/StatisticListItemIcon';

const StatisticAddableListItem: React.FC<{
  value: any;
  displayName: string;
  firstItem: boolean;
  lastItem: boolean;
  onClick: () => void;
  disabled: boolean;
}> = ({ value, displayName, firstItem, lastItem, onClick, disabled }) => {
  const { t } = useTranslation('statistic');

  return (
    <>
      <ListItem
        h={'14'}
        w={'full'}
        p={'4'}
        bgColor={'lightBackground'}
        color={disabled ? 'onSurface.lowEmphasis' : 'gray.800'}
        borderTopRadius={firstItem ? '10px' : 0}
        borderBottomRadius={lastItem ? '10px' : 0}
        shadow={'md'}
        _hover={{ cursor: disabled ? 'not-allowed' : 'auto' }}
      >
        <StatisticListItemIcon
          icon={<Icon as={Add} boxSize={'5'} />}
          bgColor={disabled ? 'success.lowEmphasis' : 'success.highEmphasis'}
          pointerEvents={disabled ? 'none' : 'auto'}
          _hover={{ cursor: 'pointer' }}
          onClick={onClick}
          aria-label={t('button.StatistikHinzufugen')}
          isDisabled={disabled}
        />
        {displayName}
      </ListItem>
      {!lastItem && <Divider borderColor={'onPrimary.lowEmphasis'} />}
    </>
  );
};

export default StatisticAddableListItem;
