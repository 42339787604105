import * as React from 'react';

/**
 * @example
 * ```
 * // Provider
 * const [registerMyCallback, notifyMyCallbacks] = useCallbackRegister<(someArgument: string) => void>()
 * const context = {
 *   action: {
 *     registerMyCallback
 *   }
 * }
 *
 * // Consumer
 * // const {refetch, ...} = someQueryHook(...)
 *
 * React.useEffect(() => ctx.action.registerMyCallback((someArgument) => {
 *   refetch({
 *     variable: {
 *       myVar: someArgument
 *     }
 *   }).then
 * }), [refetch])
 * ```
 */
export const useCallbackRegister = <
  CallbackFn extends (args?: any) => void = () => void
>() => {
  const subscriberSet = React.useRef<Set<CallbackFn>>(new Set());

  /**
   * @returns useEffect cleanup function
   * @example ```
   * React.useEffect(() => {
   *   return register((args) => {
   *     // perform some effect
   *     // you can use `args`, if the callback is defined to have some
   *     // trigger some refetch etc.
   *     refetch().then()
   *   })
   * }, [register, refetch])
   *
   * ```
   */
  const register = React.useCallback(
    (fn: CallbackFn) => {
      subscriberSet.current?.add(fn);
      return () => subscriberSet.current?.delete(fn);
    },
    [subscriberSet]
  );

  // eslint-disable-next-line
  const notify = React.useCallback(
    function (...args) {
      setTimeout(() => {
        subscriberSet.current?.forEach((fn) => {
          fn?.(...args);
        });
      }, 0);
    } as CallbackFn,
    [subscriberSet]
  );

  return [register, notify, subscriberSet] as const;
};
