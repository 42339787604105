import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { routes } from '../routes/routesConfig';

/**
 * Local-Storage key prefix intended to highlight
 * routing related storage entries.
 */
const keyPrefix = 'path:';

/**
 * Custom hook which connects history changes to the local storage.
 * It is intended to be used for routes that have no clear navigation tree parent
 * and are supposed to provide a go back to where you came from functionallity.
 */
const useHistoryWithStorage = () => {
  const history = useHistory();
  const location = useLocation();

  return React.useMemo(
    () => ({
      /**
       * Looks if there is a go back to path in the local storage for the
       * current location. If not, a default path will be used.
       */
      goBack: () => {
        const item = localStorage.getItem(`${keyPrefix}${location.pathname}`);
        localStorage.removeItem(`${keyPrefix}${location.pathname}`);
        history.push(item ?? routes.home.path);
      },
      /**
       * Routes to a new page and sets a go back to path for it.
       * @param target - Route to be navigated to
       */
      push: (target: string) => {
        localStorage.setItem(`${keyPrefix}${target}`, location.pathname);
        history.push(target);
      },
    }),
    [history, location]
  );
};

export default useHistoryWithStorage;

/**
 * Cleanup all previously set pathes on intial or reload.
 */
(() => {
  for (const key of Object.keys(localStorage)) {
    if (key.includes(keyPrefix)) {
      localStorage.removeItem(key);
    }
  }
})();
