import {
  DMenuBackButton,
  DMenuItem,
  DMenuList,
  DMenuSubList,
  useDropdownContext,
} from '@campoint/odi-ui';
import {
  AccountCircle,
  Assignment,
  CustomSettings,
  Description,
  ExitToApp,
  Help,
  Movie,
  Paid,
  Payments,
  Statistics,
  VxModelsLogo,
} from '@campoint/odi-ui-icons';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FeatureName, useFeature } from '../../flags';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { useAuth } from '../../provider/AuthProvider';
import { useFlagsProviderV2 } from '../../provider/FlagsProviderV2';
import { useTour } from '../../provider/TourProvider/TourProvider';
import { routes } from '../../routes/routesConfig';

type DMenuItemProps = {
  id: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  name?: string;
  text: string;
  isHidden?: boolean;
  highlighted?: boolean;
};

type DMenuItemPropsWithIcon = DMenuItemProps & {
  as?: EmotionIcon;
  openSubMenu?: string;
  wHMr: [number, number, number];
  highlighted?: boolean;
};

enum SubMenus {
  Legal = 'Legal',
  Account = 'Account',
}

export const DropdownMenuList: React.FC<{
  routeToVxDesktop: () => void;
  onClose: () => void;
}> = ({ routeToVxDesktop, onClose }) => {
  const historyWithStorage = useHistoryWithStorage();
  const location = useLocation();
  const { t } = useTranslation(['navigation', 'home', 'general', 'tour']);
  const authCtx = useAuth();
  const { hasAllOnboardingTasksCompleted } = useAccountStatus();
  const documentFlag = useFeature(FeatureName.document);
  const videoLibraryFlag = useFeature(FeatureName.videoLibrary);
  const { coinsEnabled } = useFlagsProviderV2();
  const { setActiveMenu } = useDropdownContext();

  const tourCtx = useTour();

  const defaultIconValues: [number, number, number] = React.useMemo(
    () => [6, 6, 2],
    []
  );

  const mainMenuItems: DMenuItemPropsWithIcon[] = React.useMemo(
    () => [
      {
        id: 1,
        onClick: () => historyWithStorage.push(routes.account.path),
        as: AccountCircle,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.DeineAccountDatenPrivat'),
        isHidden: !authCtx.isMasterAccount || documentFlag,
      },
      {
        id: 2,
        openSubMenu: SubMenus.Account,
        as: CustomSettings,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.MeinAccount'),
        isHidden: !authCtx.isMasterAccount || !documentFlag,
      },
      {
        id: 3,
        onClick: () => historyWithStorage.push(routes.videoLibrary.path),
        as: Movie,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.Videothek'),
        isHidden: !videoLibraryFlag,
        highlighted: location.pathname === routes.videoLibrary.path,
      },
      {
        id: 4,
        onClick: () => historyWithStorage.push(routes.statistics.path),
        as: Statistics,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.Statistiken'),
        isHidden: !authCtx.isMasterAccount || !coinsEnabled,
        highlighted: location.pathname === routes.statistics.path,
      },
      {
        id: 5,
        onClick: () => historyWithStorage.push(routes.featureRequest.path),
        as: Paid,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.Features'),
        isHidden: !authCtx.isMasterAccount || !coinsEnabled,
        highlighted: location.pathname === routes.featureRequest.path,
      },
      {
        id: 6,
        onClick: routeToVxDesktop,
        as: VxModelsLogo,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.VXModelsClassic'),
      },
      {
        id: 7,
        onClick: () => historyWithStorage.push(routes.help.path),
        as: Help,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.HilfeSupport'),
        highlighted: location.pathname === routes.help.path,
      },
      {
        id: 8,
        openSubMenu: SubMenus.Legal,
        as: Description,
        wHMr: defaultIconValues,
        text: t('navigation:listItem.Rechtliches'),
      },
    ],
    [
      defaultIconValues,
      t,
      authCtx.isMasterAccount,
      documentFlag,
      videoLibraryFlag,
      location.pathname,
      coinsEnabled,
      routeToVxDesktop,
      historyWithStorage,
    ]
  );

  const legalSublistMenuItems: DMenuItemProps[] = React.useMemo(
    () => [
      {
        id: 1,
        onClick: () => historyWithStorage.push(routes.privacyPolicy.path),
        text: t('navigation:listItem.Datenschutz'),
        highlighted: location.pathname === routes.privacyPolicy.path,
      },
      {
        id: 2,
        onClick: () => historyWithStorage.push(routes.imprint.path),
        text: t('navigation:listItem.Impressum'),
        highlighted: location.pathname === routes.imprint.path,
      },
      {
        id: 3,
        onClick: () => historyWithStorage.push(routes.termsAndConditions.path),
        text: t('navigation:listItem.AGB'),
        highlighted: location.pathname === routes.termsAndConditions.path,
      },
      {
        id: 4,
        onClick: () => historyWithStorage.push(routes.houseRules.path),
        text: t('navigation:listItem.Hausordnung'),
        highlighted: location.pathname === routes.houseRules.path,
      },
    ],
    [historyWithStorage, location, t]
  );

  const accountSublistMenuItems: DMenuItemPropsWithIcon[] = React.useMemo(
    () => [
      {
        id: 1,
        onClick: () => historyWithStorage.push(routes.account.path),
        text: t('navigation:listItem.PersoenlicheDaten'),
        as: AccountCircle,
        wHMr: defaultIconValues,
        highlighted: location.pathname === routes.account.path,
      },
      {
        id: 2,
        onClick: () => historyWithStorage.push(routes.documentsActors.path),
        text: t('navigation:listItem.PersonenUndDokumente'),
        isHidden: !authCtx.isMasterAccount,
        as: Assignment,
        wHMr: defaultIconValues,
        highlighted: location.pathname.startsWith(routes.documentsActors.path),
      },
      {
        id: 3,
        onClick: () => historyWithStorage.push(routes.documentsPayout.path),
        text: t('navigation:listItem.Auszahlungsdokumente'),
        isHidden: !authCtx.isMasterAccount,
        as: Payments,
        wHMr: defaultIconValues,
        highlighted: location.pathname === routes.documentsPayout.path,
      },
    ],
    [
      authCtx.isMasterAccount,
      defaultIconValues,
      historyWithStorage,
      location,
      t,
    ]
  );

  // set submenus depending on the current location
  React.useEffect(() => {
    legalSublistMenuItems.forEach((item) => {
      if (item.highlighted) {
        setActiveMenu(SubMenus.Legal);
      }
    });
    accountSublistMenuItems.forEach((item) => {
      if (item.highlighted) {
        setActiveMenu(SubMenus.Account);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <DMenuList>
      {/* Render Main Menu */}
      <DMenuSubList>
        {mainMenuItems.map((wrapper) => {
          const {
            id,
            onClick,
            as,
            wHMr,
            text,
            isHidden,
            openSubMenu,
            highlighted,
          } = wrapper;
          if (isHidden) {
            return <React.Fragment key={id} />;
          }
          const _onClick = !!onClick ? { onClick } : {};
          return (
            <DMenuItem
              key={id}
              openSubMenu={openSubMenu}
              highlighted={highlighted}
              {..._onClick}
            >
              <Icon as={as} w={wHMr[0]} h={wHMr[1]} mr={wHMr[2]} />
              {text}
            </DMenuItem>
          );
        })}
        {hasAllOnboardingTasksCompleted && (
          <DMenuItem
            onClick={() => {
              onClose();
              tourCtx.actions.startWelcomeTour({
                skipIfAlreadFinishedOnce: false,
              });
            }}
          >
            <Text color={'primary.500'} fontWeight={'500'}>
              {t('tour:showTour')}
            </Text>
          </DMenuItem>
        )}
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            variant={'ghost'}
            my={'4'}
            onClick={() =>
              historyWithStorage.push(
                authCtx.isAuthenticated ? '/logout' : '/login'
              )
            }
          >
            <Icon as={ExitToApp} w={6} h={6} mr={1} />
            {authCtx.isAuthenticated ? t('general:logout') : t('general:login')}
          </Button>
        </Box>
      </DMenuSubList>
      {/* Render Legal Menu */}
      <DMenuSubList subMenuName={SubMenus.Legal}>
        <DMenuBackButton activeMenuText={t('navigation:listItem.Rechtliches')}>
          {t('navigation:dropdownMenu.back')}
        </DMenuBackButton>
        {legalSublistMenuItems.map((menuItem) => {
          const { id, onClick, text, highlighted } = menuItem;
          return (
            <DMenuItem key={id} onClick={onClick} highlighted={highlighted}>
              {text}
            </DMenuItem>
          );
        })}
      </DMenuSubList>
      {/* Render Account Menu */}
      <DMenuSubList subMenuName={SubMenus.Account}>
        <DMenuBackButton activeMenuText={t('navigation:listItem.MeinAccount')}>
          {t('navigation:dropdownMenu.back')}
        </DMenuBackButton>
        {accountSublistMenuItems.map((menuItem) => {
          const { id, onClick, as, wHMr, isHidden, text, highlighted } =
            menuItem;
          if (isHidden) {
            return <React.Fragment key={id} />;
          }
          return (
            <DMenuItem key={id} onClick={onClick} highlighted={highlighted}>
              <Icon as={as} w={wHMr[0]} h={wHMr[1]} mr={wHMr[2]} />
              {text}
            </DMenuItem>
          );
        })}
      </DMenuSubList>
    </DMenuList>
  );
};
