import { useTranslation } from 'react-i18next';

import illustrationImageInReview from '../../../../assets/images/shared/model-profile-review.svg';
import { ModelProfileSimpleInfoPage } from '../../layout/ModelProfileSimpleInfoPage/ModelProfileSimpleInfoPage';

export const ModelProfileCompleteProfilePendingImageInfoPage: React.FC = () => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <ModelProfileSimpleInfoPage
      heading={t('modelProfile:heading.DeinBildWirdGeradeGepruft')}
      description={t(
        'modelProfile:text.WahrenddessenFulleDochNochDieFehlendenAngabenAus'
      )}
      buttonText={t('modelProfile:button.ProfilVervollstandigen')}
      image={illustrationImageInReview}
      imgAlt={t('modelProfile:imgAlt.ModelAvatarWirdUberpruft')}
    />
  );
};
