import { FormikProvider, useFormik } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { InputList } from '../../../../../components/Spacer/InputsList/InputList';
import {
  ErrorMessageWithLeadingIcon,
  TooOldErrorMessage,
  TooYoungErrorMessage,
} from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { ClearableInputControl } from '../../../../../components/shared/FormikFormElements';
import { FormikDateInput } from '../../../../../components/shared/FormikInput/Date/FormikDateInput';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { ProfileFieldsEnum } from '../../../../../generated/graphql';
import { useFormikInitialProps } from '../../../../../hooks/useFormikInitialProps';
import { useWizardOnContinueCallback } from '../../../../../hooks/useWizardOnContinueCallback';
import { useToday } from '../../../../../provider/TodayProvider';
import { calculateAge } from '../../../../../test/date-utils';
import { noop } from '../../../../../utils';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';

const birthdateFieldName = ProfileFieldsEnum.MrfOriginatorBirthdate;
const birthplaceFieldName = ProfileFieldsEnum.MrfOriginatorBirthplace;

const RequiredAgeErrorMessage = () => {
  const { t } = useTranslation(['wizardMRF']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('wizardMRF:error.BitteGibDeinAlterAn')}
    />
  );
};

const minimumAge = 18;
const maximumAge = 75;

export const WizardPayoutOriginatorBirthdata: React.FC = () => {
  const { t } = useTranslation(['wizardPayout', 'wizardMRF']);
  const { todayDayStamp } = useToday();
  const wizard = useWizardPayout();
  const { currentField } = wizard;

  const maxDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: minimumAge }).toISODate(),
    [todayDayStamp]
  );

  const minDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: maximumAge }).toISODate(),
    [todayDayStamp]
  );

  const prefillValues = React.useMemo(() => {
    return {
      [birthdateFieldName]: '',
      [birthplaceFieldName]: '',
    };
  }, []);

  const { initialValues, initialTouched } =
    useFormikInitialProps(prefillValues);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [birthdateFieldName]: Yup.string()
        .required(() => <RequiredAgeErrorMessage />)
        .test((value, testContext) => {
          if (value && calculateAge(value) < minimumAge) {
            return testContext.createError({
              message: () => <TooYoungErrorMessage years={minimumAge} />,
            });
          }
          if (value && calculateAge(value) > maximumAge) {
            return testContext.createError({
              message: () => <TooOldErrorMessage years={maximumAge} />,
            });
          }
          return true;
        }),
      [birthplaceFieldName]: createStringValidationSchema({
        ...currentField[birthplaceFieldName],
      }),
    });
  }, [currentField]);

  const formik = useFormik({
    initialTouched,
    initialValues,
    onSubmit: noop,
    enableReinitialize: true,
    validationSchema,
  });

  const onContinue = useWizardOnContinueCallback({
    wizard,
    currentSelected: 'formik',
    formiks: { formik },
  });

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t('wizardPayout:heading.WirBenotigenNochDeineGeburtsdaten')}
          children={
            <form
              aria-busy={formik.isValidating}
              aria-label={t(
                'wizardPayout:heading.WirBenotigenNochDeineGeburtsdaten'
              )}
            >
              <FormikProvider value={formik}>
                <InputList mt={0}>
                  <FormikDateInput
                    name={birthdateFieldName}
                    label={t('wizardPayout:label.DeinGeburtstag')}
                    placeholder={t('wizardMRF:placeholder.GeburtstagEingeben')}
                    min={minDate}
                    max={maxDate}
                  />
                  <ClearableInputControl
                    name={birthplaceFieldName}
                    label={t('wizardPayout:label.Geburtsort')}
                    placeholder={t('wizardMRF:placeholder.GeburtsortEingeben')}
                  />
                </InputList>
              </FormikProvider>
            </form>
          }
        />
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.Weiter')}
            onClick={onContinue}
            isDisabled={!formik.isValid}
          />
        </ButtonStack>
      }
    />
  );
};
