import { Maybe } from 'graphql/jsutils/Maybe';
import React, { createContext, useContext, useMemo, useState } from 'react';

import {
  FeedStatisticsFragment,
  LivechatStatisticsFragment,
  MessengerStatisticsFragment,
  OtherStatisticsFragment,
  OverallStatisticsFragment,
  PhoneServiceStatisticsFragment,
  StatisticsDetailedTypeSales,
  TelegramStatisticsFragment,
  VideoLibraryStatisticsFragment,
} from '../../../../generated/graphql';
import { BarChartDataPoint } from '../../components/DetailedStatisticsBarChart/StackedBarChart';
import { useStatisticsPageStatistics } from '../StatisticsPageProvider';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../TabContext';
import { BarChartBarProvider } from './BarChartBarProvider';

type BarChartContextType = {
  data: BarChartDataPoint[];
  totalTurnover: number;
};

type BarChartDataType =
  | FeedStatisticsFragment
  | LivechatStatisticsFragment
  | TelegramStatisticsFragment
  | VideoLibraryStatisticsFragment
  | FeedStatisticsFragment
  | MessengerStatisticsFragment
  | PhoneServiceStatisticsFragment
  | OtherStatisticsFragment
  | OverallStatisticsFragment;

const BarChartContext = createContext<BarChartContextType | undefined>(
  undefined
);

export const useBarChartContext = () => {
  const context = useContext(BarChartContext);
  if (!context) {
    throw new Error(
      'useBarChartContext must be used within a BarChartProvider'
    );
  }
  return context;
};

export const BarChartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: barChartDetailedData, affiliateData } =
    useStatisticsPageStatistics();
  const { tabIds, tabIndex } = useTabsContext();
  const [totalTurnover, setTotalTurnover] = useState(0);
  const tabId = tabIds[tabIndex];

  const barChartDataPointsPerTabId: Maybe<BarChartDataPoint[]> = useMemo(() => {
    let data: Maybe<BarChartDataType>;
    let barChartDataPointsPerTabId: Maybe<BarChartDataPoint[]>;
    let totalTurnoverForTab: Maybe<number>;

    switch (tabId) {
      case StatisticsDetailedCategoryExtEnum.Feed:
        data = barChartDetailedData.feed;

        const tips = data?.tips ?? [];
        const sales = data?.sales ?? [];
        const longerList: any = tips.length > sales.length ? tips : sales;

        barChartDataPointsPerTabId = longerList?.map(
          (item: StatisticsDetailedTypeSales, index: number) => ({
            category: item.date ?? '',
            firstAmount:
              (tips?.at(index)?.amount ?? 0) + (sales?.at(index)?.amount ?? 0),
            secondAmount: 0,
            barsColor: '#8481DD',
            stackedBarsColor: '#8481DD',
          })
        );
        break;
      case StatisticsDetailedCategoryExtEnum.LiveChat:
        data = barChartDetailedData.liveChat;
        barChartDataPointsPerTabId = data?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: 0,
          barsColor: '#75E0A3',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.Messenger:
        data = barChartDetailedData.messenger;
        barChartDataPointsPerTabId = data?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: 0,
          barsColor: '#F86893',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.VideoLibrary:
        data = barChartDetailedData.videoLibrary;
        barChartDataPointsPerTabId = data?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: 0,
          barsColor: '#00AAE7',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.PhoneService:
        data = barChartDetailedData.phoneService;
        barChartDataPointsPerTabId = data?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: 0,
          barsColor: '#EF9234',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.Misc:
        data = barChartDetailedData.misc;
        barChartDataPointsPerTabId = data?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: 0,
          barsColor: '#8BC1F7',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.Telegram:
        data = barChartDetailedData.telegram;
        barChartDataPointsPerTabId = data?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: 0,
          barsColor: '#FFADD6',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.Affiliate:
        barChartDataPointsPerTabId = affiliateData?.sales?.map((item) => ({
          category: item.date ?? '',
          firstAmount: 0,
          secondAmount: item.sharing ?? 0,
          barsColor: '#F6D173',
        }));
        break;
      case StatisticsDetailedCategoryExtEnum.OverAll:
        data = barChartDetailedData.overAll;
        barChartDataPointsPerTabId = data?.sales?.map((item, index) => ({
          category: item.date ?? '',
          firstAmount: item.amount ?? 0,
          secondAmount: affiliateData?.sales?.[index]?.sharing ?? 0,
          barsColor: '#1F93E9',
          stackedBarsColor: '#F6D173',
        }));
        break;
      default:
        break;
    }

    //set total turnover per tabId
    totalTurnoverForTab = data?.totalTurnover ?? 0;
    if (tabId === StatisticsDetailedCategoryExtEnum.OverAll) {
      totalTurnoverForTab =
        (data?.totalTurnover ?? 0) + (affiliateData?.totalSharing ?? 0);
    }
    if (tabId === StatisticsDetailedCategoryExtEnum.Affiliate) {
      totalTurnoverForTab = affiliateData?.totalSharing ?? 0;
    }
    setTotalTurnover(totalTurnoverForTab);

    //return the barchart data per tabId
    return barChartDataPointsPerTabId ?? [];
  }, [
    affiliateData?.sales,
    affiliateData?.totalSharing,
    barChartDetailedData.feed,
    barChartDetailedData.liveChat,
    barChartDetailedData.messenger,
    barChartDetailedData.misc,
    barChartDetailedData.overAll,
    barChartDetailedData.phoneService,
    barChartDetailedData.telegram,
    barChartDetailedData.videoLibrary,
    tabId,
  ]);

  const value = useMemo(
    () => ({
      data: barChartDataPointsPerTabId,
      totalTurnover: totalTurnover,
    }),
    [barChartDataPointsPerTabId, totalTurnover]
  );

  return (
    <BarChartContext.Provider value={value}>
      <BarChartBarProvider>{children}</BarChartBarProvider>
    </BarChartContext.Provider>
  );
};
