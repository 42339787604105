import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import {
  ApiLangEnum,
  DocumentSideEnum,
  DocumentTypeEnum,
  ProfileFieldTypePictureListFragment,
  ProfileFieldsEnum,
  UploadInfoFragment,
  useDeleteSedcardMutation,
  useGetDocumentUploadUrlLazyQuery,
  useGetUploadInfoForFieldLazyQuery,
} from '../generated/graphql';
import { useActiveApiLanguage } from '../hooks/useActiveApiLanguage';
import { createContext } from '../hooks/useContext';
import { MediaUploadError } from '../types/media';
import { asyncNoop, noop } from '../utils';
import Logger from '../utils/Logger';
import {
  abortableUploadBlob,
  mediaUploadErrorCodes,
  uploadImage,
} from '../utils/media';
import { checkProperlyFormattedUploadResponse } from '../utils/media/checkProperlyFormattedUploadResponse';
import { useMediaFlow } from './MediaFlowProvider';
import { useMediaProperties } from './MediaPropertiesProvider';
import { useMedia } from './MediaProvider';

export type MediaUploadContext = {
  readonly isUploading: boolean;
  readonly uploadProgress?: number;
  readonly receivedMediaId: number | null;
  readonly uploadError: MediaUploadError | null;
  readonly action: {
    readonly upload: (
      blob?: Blob,
      filename?: string,
      uploadUrl?: string | null,
      type?: number | null,
      umaId?: number | null
    ) => Promise<void>;
    readonly abortUpload?: () => void;
  };
};
export const [, useMediaUpload, mediaUploadContext] =
  createContext<MediaUploadContext>({
    name: 'MediaUploadContext',
    errorMessage:
      'useMediaUpload: `MediaUploadContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });
export type MediaUploadProviderOptions = {
  /**
   * Needed, to request the upload url for the relevant field
   */
  fieldName?: string;
  onUploadSuccess?: (id: number) => void;
  onUploadError?: (error: MediaUploadError) => void;
  preUploadCallback?: () => Promise<void>;
  transformUploadUrl?: (url: string) => string;
  children?: React.ReactNode;
};

const Provider: React.FC<MediaUploadProviderOptions> = ({
  fieldName,
  onUploadSuccess = noop,
  onUploadError = noop,
  preUploadCallback = asyncNoop,
  transformUploadUrl = (url) => url,
  children,
}) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [receivedMediaId, setReceivedMediaId] = React.useState<number | null>(
    null
  );
  const [uploadError, setUploadError] = React.useState<MediaUploadError | null>(
    null
  );

  const [getUploadInfo] = useGetUploadInfoForFieldLazyQuery({
    variables: {
      name: (fieldName ?? '') as ProfileFieldsEnum,
    },
  });

  const { t } = useTranslation(['mediaUpload']);
  const lang = useActiveApiLanguage();

  const extractErrorMessageByCode = React.useCallback(
    (
      receivedErrorCode: any,
      errorCodes?: (typeof mediaUploadErrorCodes)[ApiLangEnum]
    ) => {
      if (receivedErrorCode === null) {
        return null;
      }
      const foundError = Object.values<MediaUploadError>(errorCodes ?? []).find(
        (obj) => obj?.code === receivedErrorCode
      );

      return (
        foundError ?? {
          code: receivedErrorCode,
          description: t(
            'mediaUpload:error.UpsDaIstWohlEtwasSchiefGelaufenVersucheEsBitteErneut'
          ),
        }
      );
    },
    [t]
  );

  const upload = React.useCallback(
    async (
      blob?: Blob | null,
      filename?: string | null,
      uploadUrl?: string | null,
      type?: number | null,
      umaId?: number | null
    ) => {
      if (!blob) {
        return;
      }

      setIsUploading(true);
      setReceivedMediaId(null);
      let errorMapping = mediaUploadErrorCodes[lang];
      try {
        if (!uploadUrl) {
          const uploadInfo = await getUploadInfo()?.then(({ data, error }) => {
            if (error) {
              Logger.error(error);
              return null;
            }
            return (
              ((data?.profile.field as any)?.upload as UploadInfoFragment) ??
              null
            );
          });

          if (!uploadInfo?.url) {
            const newUploadError = extractErrorMessageByCode(null);
            setUploadError(newUploadError);
            setIsUploading(false);
            return;
          }
          uploadUrl = uploadInfo.url;
          if (uploadInfo?.errorCodes) {
            errorMapping =
              uploadInfo.errorCodes as (typeof mediaUploadErrorCodes)[ApiLangEnum];
          }
        }

        await preUploadCallback();
        const transformedUploadUrl = transformUploadUrl(uploadUrl!);
        const { id, errorCode } = await uploadImage(
          transformedUploadUrl,
          blob,
          filename,
          type,
          umaId
        );
        if (errorCode !== null) {
          const newUploadError = extractErrorMessageByCode(
            errorCode,
            errorMapping
          );
          setUploadError(newUploadError);
          setIsUploading(false);
          if (newUploadError) {
            onUploadError(newUploadError);
          }
          return;
        }
        setReceivedMediaId(id);
        setIsUploading(false);
        if (id) {
          onUploadSuccess(id);
        }
      } catch (e) {
        Logger.error(e);
        const newUploadError = extractErrorMessageByCode(null);
        setUploadError(newUploadError);
        setIsUploading(false);
        if (newUploadError) {
          onUploadError(newUploadError);
        }
      }
    },
    [
      lang,
      preUploadCallback,
      transformUploadUrl,
      getUploadInfo,
      extractErrorMessageByCode,
      onUploadError,
      onUploadSuccess,
    ]
  );

  const context: MediaUploadContext = {
    isUploading,
    receivedMediaId: receivedMediaId,
    uploadError,
    action: {
      upload,
    },
  };
  return <mediaUploadContext.Provider value={context} children={children} />;
};

const ProviderDocument: React.FC<{
  userId: number;
  documentType: DocumentTypeEnum;
  side?: DocumentSideEnum;
  onUploadStart?: () => void;
  onUploadSuccess?: (id: number) => void;
  onUploadError?: (error: MediaUploadError) => void;
  children?: React.ReactNode;
}> = ({
  userId,
  documentType,
  side,
  onUploadStart = noop,
  onUploadSuccess = noop,
  onUploadError = noop,
  children,
}) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [receivedMediaId, setReceivedMediaId] = React.useState<number | null>(
    null
  );
  const [uploadError, setUploadError] = React.useState<MediaUploadError | null>(
    null
  );

  const [uploadProgress, setUploadProgress] = React.useState(0);

  const [getDocumentUploadUrl] = useGetDocumentUploadUrlLazyQuery({
    variables: {
      userId,
      documentType,
      side,
    },
  });
  // const [getDocumentUploadUrl] = useGetUploadInfoForFieldLazyQuery({
  //   variables: {
  //     name: (fieldName ?? '') as ProfileFieldsEnum,
  //   },
  // });

  const { t } = useTranslation(['mediaUpload']);

  const extractErrorMessageByCode = React.useCallback(
    (
      receivedErrorCode: any,
      errorCodes?: { code: string; description: string }[]
    ) => {
      if (receivedErrorCode === null) {
        return null;
      }
      const foundError = Object.values<MediaUploadError>(errorCodes ?? []).find(
        (obj) => obj?.code === receivedErrorCode
      );

      return (
        foundError ?? {
          code: receivedErrorCode,
          description: t(
            'mediaUpload:error.UpsDaIstWohlEtwasSchiefGelaufenVersucheEsBitteErneut'
          ),
        }
      );
    },
    [t]
  );

  const abortUploadRef = React.useRef<(() => void) | null>(null);

  const upload = React.useCallback(
    async (blob?: Blob, fileName?: string) => {
      if (!blob) {
        return;
      }

      setIsUploading(true);
      setUploadProgress(0);
      setReceivedMediaId(null);
      try {
        const uploadUrl = await getDocumentUploadUrl()?.then(
          ({ data, error }) => {
            if (error) {
              Logger.error(error);
              return null;
            }
            return data?.documents.uploadUrl;
            //return (data?.profile.field as any)?.upload?.url ?? null;
          }
        );

        if (!uploadUrl) {
          const newUploadError = extractErrorMessageByCode(null);
          setUploadError(newUploadError);
          setIsUploading(false);
          return;
        }

        const u = new URL(uploadUrl);
        u.searchParams.set('json', 'true');
        const transformedUploadUrl = u.href;
        onUploadStart?.();
        const { abortUpload, uploadPromise } = abortableUploadBlob(
          transformedUploadUrl,
          blob,
          fileName,
          (progressInPercent) => setUploadProgress(progressInPercent)
        );
        abortUploadRef.current = abortUpload;
        const response = await uploadPromise;
        abortUploadRef.current = null;

        if (!checkProperlyFormattedUploadResponse(response)) {
          const newUploadError: MediaUploadError = {
            code: null,
            description: 'unkown error',
          };
          setUploadError(newUploadError);
          setIsUploading(false);
          if (newUploadError) {
            onUploadError(newUploadError);
          }
          return;
        }
        if (!response?.success) {
          const newUploadError: MediaUploadError = {
            code: null,
            description: response?.message ?? 'unknown error',
          };
          setUploadError(newUploadError);
          setIsUploading(false);
          if (newUploadError) {
            onUploadError(newUploadError);
          }
          return;
        }
        setReceivedMediaId(response.documentId);
        setIsUploading(false);
        if (response.documentId) {
          onUploadSuccess(response.documentId);
        }
      } catch (e) {
        Logger.error(e);
        const newUploadError = extractErrorMessageByCode(null);
        setUploadError(newUploadError);
        setIsUploading(false);
        if (newUploadError) {
          onUploadError(newUploadError);
        }
      }
    },
    [
      getDocumentUploadUrl,
      setIsUploading,
      setUploadError,
      extractErrorMessageByCode,
      onUploadError,
      onUploadSuccess,
      onUploadStart,
    ]
  );

  const abortUpload = React.useCallback(() => {
    setIsUploading(false);
    setReceivedMediaId(null);
    setUploadProgress(0);
    abortUploadRef.current?.();
  }, [setIsUploading]);

  const context: MediaUploadContext = {
    isUploading,
    uploadProgress,
    receivedMediaId: receivedMediaId,
    uploadError,
    action: {
      upload,
      abortUpload,
    },
  };
  return <mediaUploadContext.Provider value={context} children={children} />;
};

const ForPaymentDocument: React.FC<{
  fieldName: string;
  onUploadStart?: () => void;
  onUploadSuccess?: (id: number, filename?: string) => void;
  onUploadError?: (error: MediaUploadError) => void;
  children?: React.ReactNode;
}> = ({
  fieldName,
  onUploadStart = noop,
  onUploadSuccess = noop,
  onUploadError = noop,
  children,
}) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [receivedMediaId, setReceivedMediaId] = React.useState<number | null>(
    null
  );
  const [uploadError, setUploadError] = React.useState<MediaUploadError | null>(
    null
  );

  const [uploadProgress, setUploadProgress] = React.useState(0);

  const [getDocumentUploadUrl] = useGetUploadInfoForFieldLazyQuery({
    variables: {
      name: (fieldName ?? '') as ProfileFieldsEnum,
    },
  });

  const { t } = useTranslation(['mediaUpload']);

  const extractErrorMessageByCode = React.useCallback(
    (
      receivedErrorCode: any,
      errorCodes?: { code: string; description: string }[]
    ) => {
      if (receivedErrorCode === null) {
        return null;
      }
      const foundError = Object.values<MediaUploadError>(errorCodes ?? []).find(
        (obj) => obj?.code === receivedErrorCode
      );

      return (
        foundError ?? {
          code: receivedErrorCode,
          description: t(
            'mediaUpload:error.UpsDaIstWohlEtwasSchiefGelaufenVersucheEsBitteErneut'
          ),
        }
      );
    },
    [t]
  );

  const abortUploadRef = React.useRef<(() => void) | null>(null);

  const upload = React.useCallback(
    async (
      blob?: Blob,
      fileName?: string,
      uploadUrl?: string | null,
      type?: number | null,
      umaId?: number | null
    ) => {
      if (!blob) {
        return;
      }

      setIsUploading(true);
      setUploadProgress(0);
      setReceivedMediaId(null);
      try {
        const uploadUrl = await getDocumentUploadUrl()?.then(
          ({ data, error }) => {
            if (error) {
              Logger.error(error);
              return null;
            }
            // return data?.documents.uploadUrl;
            return (data?.profile.field as any)?.upload?.url ?? null;
          }
        );

        if (!uploadUrl) {
          const newUploadError = extractErrorMessageByCode(null);
          setUploadError(newUploadError);
          setIsUploading(false);
          return;
        }

        const u = new URL(uploadUrl);
        u.searchParams.set('json', 'true');
        const transformedUploadUrl = u.href;
        onUploadStart?.();
        const { abortUpload, uploadPromise } = abortableUploadBlob(
          transformedUploadUrl,
          blob,
          fileName,
          (progressInPercent) => setUploadProgress(progressInPercent)
        );
        abortUploadRef.current = abortUpload;
        const response = await uploadPromise;
        abortUploadRef.current = null;

        if (!checkProperlyFormattedUploadResponse(response)) {
          const newUploadError: MediaUploadError = {
            code: null,
            description: 'unkown error',
          };
          setUploadError(newUploadError);
          setIsUploading(false);
          if (newUploadError) {
            onUploadError(newUploadError);
          }
          return;
        }
        if (!response?.success) {
          const newUploadError: MediaUploadError = {
            code: null,
            description: response?.message ?? 'unknown error',
          };
          setUploadError(newUploadError);
          setIsUploading(false);
          if (newUploadError) {
            onUploadError(newUploadError);
          }
          return;
        }
        setReceivedMediaId(response.documentId);
        setIsUploading(false);
        if (response.documentId) {
          onUploadSuccess(response.documentId, fileName);
        }
      } catch (e) {
        Logger.error(e);
        const newUploadError = extractErrorMessageByCode(null);
        setUploadError(newUploadError);
        setIsUploading(false);
        if (newUploadError) {
          onUploadError(newUploadError);
        }
      }
    },
    [
      getDocumentUploadUrl,
      setIsUploading,
      setUploadError,
      extractErrorMessageByCode,
      onUploadError,
      onUploadSuccess,
      onUploadStart,
    ]
  );

  const abortUpload = React.useCallback(() => {
    setIsUploading(false);
    setReceivedMediaId(null);
    setUploadProgress(0);
    abortUploadRef.current?.();
  }, [setIsUploading]);

  const context: MediaUploadContext = {
    isUploading,
    uploadProgress,
    receivedMediaId: receivedMediaId,
    uploadError,
    action: {
      upload,
      abortUpload,
    },
  };
  return <mediaUploadContext.Provider value={context} children={children} />;
};

/**
 * !Must be enclosed by:
 * - **{@link MediaPropertiesProvider}**
 * - **{@link MediaFlowProvider}**
 *
 * Convenience {@link MediaUploadProvider}
 * Relies on set `named` property in an MediaPropertiesProvider to customize messages
 */
const BasedOnNamedProperty: React.FC<
  MediaUploadProviderOptions & { onPersist?: (id: number) => Promise<void> }
> = ({ onPersist, ...props }) => {
  const {
    action: { clearReplacement },
  } = useMedia();
  const { named } = useMediaProperties();
  const { onClose } = useMediaFlow();

  const { t } = useTranslation(['mediaUpload']);

  const onUploadSuccess: MediaUploadProviderOptions['onUploadSuccess'] =
    React.useCallback(
      (id: number) => {
        onClose();
        issueChakraToast({
          status: 'success',
          description: t('mediaUpload:toast.NamedErfolgreichHochgeladen', {
            named,
          }),
        });
        clearReplacement();
        onPersist?.(id).then();
      },
      [onClose, clearReplacement, t, named, onPersist]
    );

  const onUploadError: MediaUploadProviderOptions['onUploadError'] =
    React.useCallback((error: MediaUploadError) => {
      issueChakraToast({
        status: 'error',
        description: error?.description,
      });
    }, []);

  return (
    <Provider
      onUploadSuccess={onUploadSuccess}
      onUploadError={onUploadError}
      {...props}
    />
  );
};

/**
 * !Must be enclosed by:
 * - **{@link MediaPropertiesProvider}**
 * - **{@link MediaFlowProvider}**
 *
 *
 * Convenience {@link MediaUploadProvider}
 * extracting required information from `ProfileFieldTypePictureListFragment` and a `position` number
 *
 * Extends {@link BasedOnNamedProperty} to also use `named` property to customize messages
 */
const ForSedcard: React.FC<
  MediaUploadProviderOptions & {
    field?: ProfileFieldTypePictureListFragment | null;
    position: number;
    onPersist?: (id: number) => Promise<void>;
  }
> = ({ field, position, ...props }) => {
  const [deleteSedcardMutation] = useDeleteSedcardMutation();

  // After an upload, the returned image.src is not always already up-to-date.
  // To mitigate this we delete the pre-existing image
  const deletePreExistingSedcard = React.useCallback(async () => {
    const picture =
      field?.value?.find((entry) => entry.position === position) ?? null;
    if (!!picture) {
      const { umaId, umpId } = picture;
      await deleteSedcardMutation({ variables: { umaId, umpId } });
    }
  }, [field, deleteSedcardMutation, position]);

  return (
    <BasedOnNamedProperty
      fieldName={field?.name}
      preUploadCallback={deletePreExistingSedcard}
      transformUploadUrl={(url) => {
        const urlWithPosition = new URL(url);
        urlWithPosition.searchParams.set('position', position.toString());
        return urlWithPosition.toString();
      }}
      {...props}
    />
  );
};

// https://react-typescript-cheatsheet.netlify.app/docs/advanced/misc_concerns/#namespaced-components

/**
 * {@link Provider}
 * Handles the picture upload process
 * toasting success or error messages
 * based on received `uploadInfo`
 *
 * @see UploadInfoFragment
 */
export const MediaUploadProvider = Object.assign(Provider, {
  /** @see BasedOnNamedProperty */
  BasedOnNamedProperty,

  /** @see ForSedcard */
  ForSedcard,

  ForPaymentDocument,

  IdentityDocument: ProviderDocument,
});

export const MAPPING_PICTURE_TYPE = {
  mainProfilePicture: 21,
  titleProfilePicture: 22,
  shop: 20,
  sedcard: 15,
  profile: 15, // deprecated, now sedcard
  communityProfile: 15,
  flirtlifeProfile: 15,
  free: 14,
  campaign: 14,
  contest: 14,
  videoPreview16: 17,
  videoPreview18: 17,
  vxhpmediapool: 18,
};
