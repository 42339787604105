import { Box, useBreakpointValue } from '@chakra-ui/react';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

// Define types for pie chart data and arcs

interface ArcData {
  data: [string, number];
}

interface PieChartProps {
  chartData: Record<string, number>;
  colorMapping: Record<string, string>;
}

const PieChartT: React.FC<PieChartProps> = ({ chartData, colorMapping }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const widthAdj = useBreakpointValue({ base: '90%', md: '50%' });
  useEffect(() => {
    const handleResize = () => {
      if (!chartRef.current) return;

      const width = chartRef.current.clientWidth;
      const aspectRatio = 1; // Aspect ratio for the chart (1:1 for a square chart)
      const height = width * aspectRatio;
      const segmentWidth = 75; // Width of the segments in pixels
      const innerRadiusThreshold = 40; // Threshold for the inner radius

      // Calculate the outer radius based on the minimum dimension of the container
      const outerRadius = Math.min(width, height) / 2;
      // Calculate the inner radius based on the outer radius and segment width
      let innerRadius = outerRadius - segmentWidth;

      // Check if the inner radius is below the threshold
      if (innerRadius < innerRadiusThreshold) {
        innerRadius = innerRadiusThreshold;
      }

      // Remove any existing SVGs if re-rendering to avoid duplicates
      d3.select(chartRef.current).select('svg').remove();

      // Append the svg object to the div
      const svg = d3
        .select(chartRef.current)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      // Check if all values are zero
      const allZero = Object.values(chartData).every((value) => value === 0);

      const zeroData = { 'No data': 1 };

      if (allZero) {
        const pie = d3.pie<[string, number]>().value((d) => d[1]);
        const zeroDataReady = pie(Object.entries(zeroData));

        // Render a single gray pie segment as a placeholder
        svg
          .selectAll('whatever')
          .data(zeroDataReady)
          .join('path')
          .attr(
            'd',
            d3
              .arc<ArcData>()
              .innerRadius(innerRadius) // Adjusted inner radius
              .outerRadius(outerRadius) // Dynamic outer radius
              .cornerRadius(8)
          )
          .attr('fill', '#DBE5EE') // Explicitly cast color to string
          .attr('stroke', 'white')
          .style('stroke-width', '2px')
          .style('opacity', 0.7);
      } else {
        // Compute the position of each group on the pie
        const pie = d3.pie<[string, number]>().value((d) => d[1]);

        // Turn the data into a pie
        const data_ready = pie(Object.entries(chartData));

        // Build the pie chart: each part of the pie is a path that we build using the arc function
        svg
          .selectAll('whatever')
          .data(data_ready)
          .join('path')
          .attr(
            'd',
            d3
              .arc<ArcData>()
              .innerRadius(innerRadius) // Adjusted inner radius
              .outerRadius(outerRadius) // Dynamic outer radius
              .cornerRadius(8)
          )
          .attr('fill', (d: ArcData) => colorMapping[d.data[0]]) // Explicitly cast color to string
          .attr('stroke', 'white')
          .style('stroke-width', '2px')
          .style('opacity', 0.7);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [chartData, colorMapping]);

  return <Box ref={chartRef} id="my_dataviz" w={widthAdj} h={'100%'}></Box>;
};

export default PieChartT;
