import {
  CheckCircle,
  Error as ErrorIcon,
  Group,
  Schedule,
} from '@campoint/odi-ui-icons';
import {
  ContainerProps,
  Icon,
  TextProps,
  Tooltip,
  chakra,
  forwardRef,
  useDisclosure,
  usePrevious,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { FILE_STATES } from '@rpldy/uploady';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PostStatusEnum } from '../../../generated/feed';
import { ContestFragment } from '../../../generated/graphql';
import { FeedPostBrokenModal } from '../components/modal/FeedPostBrokenModal';
import {
  FeedPostRejectedModal,
  PhotoPostRejectedModal,
} from '../components/modal/FeedPostRejectedModal';
import { TempFeedPostPhotoPostProp } from '../components/post/component/types';

const FeedPostWrapperStatusLabel = forwardRef<
  TextProps & { icon: EmotionIcon },
  'span'
>((props, ref) => {
  const { icon, children, ...spanProps } = props;
  return (
    <chakra.span
      display={'inline-flex'}
      alignItems={'center'}
      textStyle={'caption'}
      fontWeight={400}
      ref={ref}
      {...spanProps}
    >
      <chakra.span aria-hidden paddingEnd={'1'}>
        {<Icon as={icon} boxSize={'6'} />}
      </chakra.span>
      <chakra.span>{children}</chakra.span>
    </chakra.span>
  );
});
export const FeaturedPostLabel = () => {
  const { t } = useTranslation(['feed']);
  return (
    <Tooltip
      label={t('feed:label.FeaturedPostDescription')}
      width={'full'}
      p={2}
      bg={{ base: 'white', md: 'steel' }}
      color={'black'}
      borderRadius={'8px'}
      placement="bottom-start"
      closeDelay={500}
    >
      <FeedPostWrapperStatusLabel
        icon={Group}
        children={t('feed:label.FeaturedPost')}
        color={'primary.500'}
      />
    </Tooltip>
  );
};
export const BrokenPostLabel: React.FC<{ onDeletePost: () => void }> = ({
  onDeletePost,
}) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <FeedPostWrapperStatusLabel
        icon={ErrorIcon}
        children={t('feed:text.Fehlgeschlagen')}
        color={'red.500'}
        cursor={'pointer'}
        onClick={onOpen}
      />
      <FeedPostBrokenModal
        isOpen={isOpen}
        onClose={onClose}
        onDeletePost={onDeletePost}
      />
    </>
  );
};
export const JustPublishedPostLabel = () => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostWrapperStatusLabel
      icon={CheckCircle}
      children={t('feed:text.LiveAufVISITX')}
      color={'lime.500'}
    />
  );
};

export const ScheduledPostLabel = () => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostWrapperStatusLabel
      icon={Schedule}
      children={t('feed:label.Eingeplant')}
      color={'primary.500'}
    />
  );
};
export const InVotingContestPostLabel = (contest: ContestFragment) => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedPostWrapperStatusLabel
      icon={Schedule}
      children={t('feed:label.VotingEndetAmDate', { date: contest.votingEnd })}
      color={'primary.500'}
    />
  );
};
const RejectedClipPostLabel: React.FC<{
  onDeletePost: () => void;
  rejectionReason?: string;
}> = ({ onDeletePost, rejectionReason }) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FeedPostWrapperStatusLabel
        icon={ErrorIcon}
        children={t('feed:text.Abgelehnt')}
        color={'red.500'}
        cursor={'pointer'}
        onClick={() => {
          onOpen();
        }}
      />
      <FeedPostRejectedModal
        isOpen={isOpen}
        onClose={onClose}
        rejectionReason={rejectionReason}
        onDeletePost={onDeletePost}
      />
    </>
  );
};
export const RejectedPhotoPostLabel: React.FC<
  {
    onDeletePost: () => void;
    rejectionReason?: string;
    isContest: boolean;
  } & TempFeedPostPhotoPostProp
> = ({
  multiPhotoPost = false,
  isContest = false,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  rejectionReason,
  postId,
  visiblePhotoId,
}) => {
  const { t } = useTranslation(['feed']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FeedPostWrapperStatusLabel
        icon={ErrorIcon}
        children={t('feed:text.Abgelehnt')}
        color={'red.500'}
        cursor={'pointer'}
        onClick={() => {
          if (!isContest) {
            onOpen();
          }
        }}
      />
      <PhotoPostRejectedModal
        multiPhotoPost={multiPhotoPost}
        isOpen={isOpen}
        onClose={onClose}
        rejectionReason={rejectionReason}
        onDeletePost={onDeletePost}
        onDeleteImage={onDeleteImage}
        onReplaceImage={onReplaceImage}
        postId={postId}
        visiblePhotoId={visiblePhotoId}
      />
    </>
  );
};

export const usePhotoPostWrapper = ({
  status,
  rejectionReason,
  isFeatured,
  isScheduled,
  contest,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  postId,
  visiblePhotoId,
  multiPhotoPost,
}: {
  status: PostStatusEnum;
  rejectionReason?: string | null | undefined;
  isFeatured?: boolean;
  isScheduled?: boolean;
  contest?: ContestFragment | null;
  onDeletePost: () => void;
} & TempFeedPostPhotoPostProp) => {
  const previusStatus = usePrevious(status);

  //for photo contests: check if the contest votingEnd is in the past
  const contestVotingEndInPast = React.useMemo(() => {
    if (!contest || !contest.votingEnd) return null;

    const todayDate = DateTime.local();
    const votingEndDate = DateTime.fromISO(contest.votingEnd);
    return todayDate > votingEndDate;
  }, [contest]);
  const isContestInVoting = contest && !contestVotingEndInPast && !isScheduled;

  //a post status can be OK but the photo can be rejected
  const visiblePhotoRejected = rejectionReason && rejectionReason !== '';

  //we test the visible photo rejected status here in case of photo posts
  const statusOK = status === PostStatusEnum.Ok && !visiblePhotoRejected;

  //is either primary, error color or undefined
  const COLOR_ERROR = 'red.500';
  const COLOR_PRIMARY = 'primary.500';

  const isRejected = status === PostStatusEnum.Rejected;

  const highlightColor: ContainerProps['borderColor'] | undefined =
    React.useMemo(() => {
      if (statusOK && (isFeatured || isScheduled || isContestInVoting)) {
        return COLOR_PRIMARY;
      }

      if (
        isRejected ||
        status === PostStatusEnum.Broken ||
        visiblePhotoRejected
      ) {
        return COLOR_ERROR;
      }

      return undefined;
    }, [
      statusOK,
      isFeatured,
      isScheduled,
      isContestInVoting,
      isRejected,
      status,
      visiblePhotoRejected,
    ]);

  const labelElement = React.useMemo(() => {
    if (visiblePhotoRejected || isRejected) {
      return (
        <RejectedPhotoPostLabel
          multiPhotoPost={multiPhotoPost}
          onDeletePost={onDeletePost}
          onDeleteImage={onDeleteImage}
          onReplaceImage={onReplaceImage}
          postId={postId}
          visiblePhotoId={visiblePhotoId}
          rejectionReason={rejectionReason ?? undefined}
          isContest={!!contest}
        />
      );
    }

    if (statusOK && previusStatus === PostStatusEnum.Transcoding) {
      return <JustPublishedPostLabel />;
    }

    if (isScheduled) {
      return <ScheduledPostLabel />;
    }

    if (statusOK && isFeatured) {
      return <FeaturedPostLabel />;
    }

    if (statusOK && isContestInVoting) {
      return <InVotingContestPostLabel {...contest} />;
    }
  }, [
    visiblePhotoRejected,
    isRejected,
    statusOK,
    previusStatus,
    isFeatured,
    isScheduled,
    isContestInVoting,
    multiPhotoPost,
    onDeletePost,
    onDeleteImage,
    onReplaceImage,
    postId,
    visiblePhotoId,
    rejectionReason,
    contest,
  ]);

  return {
    highlightColor,
    labelElement,
  };
};
export const useFeedPostWrapper = ({
  status,
  currentTusUploadStatus,
  rejectionReason,
  isFeatured,
  isScheduled,
  onDeletePost,
}: {
  status: PostStatusEnum;
  currentTusUploadStatus?: FILE_STATES | undefined;
  rejectionReason?: string | null | undefined;
  isFeatured?: boolean;
  isScheduled?: boolean;
  onDeletePost: () => void;
}) => {
  const previusStatus = usePrevious(status);

  //we test the visible photo rejected status here in case of photo posts
  const statusOK = status === PostStatusEnum.Ok;

  //is either primary, error color or undefined
  const COLOR_ERROR = 'red.500';
  const COLOR_PRIMARY = 'primary.500';

  const isRejected =
    status === PostStatusEnum.Rejected ||
    currentTusUploadStatus === FILE_STATES.ERROR ||
    currentTusUploadStatus === FILE_STATES.ABORTED ||
    currentTusUploadStatus === FILE_STATES.CANCELLED;

  const highlightColor: ContainerProps['borderColor'] | undefined =
    React.useMemo(() => {
      if (statusOK && (isFeatured || isScheduled)) {
        return COLOR_PRIMARY;
      }

      if (isRejected || status === PostStatusEnum.Broken) {
        return COLOR_ERROR;
      }

      return undefined;
    }, [statusOK, isFeatured, isScheduled, isRejected, status]);

  const labelElement = React.useMemo(() => {
    if (statusOK && previusStatus === PostStatusEnum.Transcoding) {
      return <JustPublishedPostLabel />;
    }

    if (statusOK && isFeatured) {
      return <FeaturedPostLabel />;
    }

    if (statusOK && isScheduled) {
      return <ScheduledPostLabel />;
    }

    if (status === PostStatusEnum.Broken) {
      return <BrokenPostLabel onDeletePost={onDeletePost} />;
    }

    if (isRejected) {
      return (
        <RejectedClipPostLabel
          onDeletePost={onDeletePost}
          rejectionReason={rejectionReason ?? undefined}
        />
      );
    }

    return undefined;
  }, [
    statusOK,
    previusStatus,
    isFeatured,
    isScheduled,
    status,
    isRejected,
    onDeletePost,
    rejectionReason,
  ]);

  return {
    highlightColor,
    labelElement,
  };
};
