import { TFunction } from 'i18next';
import React from 'react';

import VXWorldAvatar from '../../assets/images/feed/Avatar.png';
import { FskEnum } from '../../generated/feed';
import { useActiveApiLanguage } from '../../hooks/useActiveApiLanguage';
import { getFormattedAmount } from '../../utils/formattedAmount';

export const typeDependantModelName = (
  isFeaturedPost: boolean,
  modelName: string,
  featuredPostTitle: string
) => {
  if (isFeaturedPost) {
    return featuredPostTitle + modelName;
  }
  return modelName;
};

export const avatarURL = (
  isFeaturedPost: boolean,
  modelAvatar: string | undefined
) => {
  if (isFeaturedPost) {
    return VXWorldAvatar;
  }
  return modelAvatar;
};

export const getFeedPostTags = (
  t: TFunction<['feed'], undefined>,
  locale: string,
  fsk?: FskEnum,
  price?: number | null | undefined | string,
  tippingGoal?: boolean,
  photoContest?: boolean
): string[] => {
  let tags: string[] = [];

  if (fsk)
    tags.push(t('feed:text.Ab', { fsk: fsk === FskEnum.Fsk16 ? 16 : 18 }));
  if (price && typeof price === 'string') tags.push(price);
  if (price && typeof price === 'number') tags.push(getFormattedAmount(price, locale));
  if (tippingGoal) tags.push('Tipping Goal');
  if (photoContest) tags.push(t('feed:text.Wettbewerbsfoto'));
  return tags;
};

export const useGetRejectionReason = (
  deRejection: string | null | undefined,
  enRejection: string | null | undefined
) => {
  const apiLang = useActiveApiLanguage();
  const rejectionReason = React.useMemo(() => {
    switch (apiLang) {
      case 'de':
        return deRejection;
      default:
        return enRejection;
    }
  }, [deRejection, enRejection, apiLang]);
  return rejectionReason;
};
