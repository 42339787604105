import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionTitleRow,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  IconButton,
  Img,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';

import image from '../../../assets/images/wizard/model-release-form-welcome.svg';
import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../components/Layout/ResponsiveModal';
import { ScrollToTargetBlock } from '../../../components/Layout/ScrollToTargetBlock';
import { SectionCenterContainer } from '../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../components/Layout/UrlFragmentScrollToTarget';
import {
  RequiredErrorMessage,
  TooOldErrorMessage,
  TooYoungErrorMessage,
} from '../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { EnumSelectFieldHookForm } from '../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { HeadingWithOptionalToggleAndGuideDot } from '../../../components/shared/HeadingWithOptionalToggleAndGuideDot/HeadingWithOptionalToggleAndGuideDot';
import { HelpLink } from '../../../components/shared/HelpLink/HelpLink';
import { ClearableInputControl } from '../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { MediaPreviewBox } from '../../../components/shared/MediaPreviewBox/MediaPreviewBox';
import { MediaUploadCropperPortal } from '../../../components/shared/MediaUpload/MediaUpload';
import ENV from '../../../environments/environment';
import {
  ApiLangEnum,
  DocumentSideEnum,
  DocumentTypeEnum,
  ModelReleaseFormLanguageEnum,
  useGetCreateActorPageQuery,
  useInitModelReleaseFormMutation,
  useSaveActorMutation,
} from '../../../generated/graphql';
import { useActiveApiLanguage } from '../../../hooks/useActiveApiLanguage';
import { useAuth } from '../../../provider/AuthProvider';
import { MediaInputProvider } from '../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../provider/MediaPropertiesProvider';
import {
  MediaContext,
  MediaProvider,
  useMedia,
} from '../../../provider/MediaProvider';
import { useNavigationBlock } from '../../../provider/NavigationBlockProvider';
import { externalRoutes, routes } from '../../../routes/routesConfig';
import Logger from '../../../utils/Logger';
import { useDateFromTodayByYears, windowOpen } from '../../../utils/utils';
import { createStringValidationSchema } from '../../../utils/validation';
import { useDocumentUpload } from './hooks/useDocumentUpload';

export type PartnerKind = 'model' | 'partner';

const fieldName = {
  partnerKind: 'partnerKind',
  firstName: 'firstname',
  lastName: 'lastname',
  birtdate: 'birthday',
  mrfLanguage: 'mrfLanguage',
} as const;

interface FormValues {
  [fieldName.partnerKind]: string;
  [fieldName.firstName]: string;
  [fieldName.lastName]: string;
  [fieldName.birtdate]: string;
}

const minAge = 18;
const maxAge = 75;

export const CreateActorPage: React.FC = () => {
  const apiLang = useActiveApiLanguage();
  const [userid, setuserid] = React.useState<number | null>(null);
  const optionModel: PartnerKind = 'model';
  const optionPartner: PartnerKind = 'partner';

  const { t } = useTranslation(['realPersonDetail', 'realPerson']);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const birthdateMin = useDateFromTodayByYears(maxAge);
  const birthdateMax = useDateFromTodayByYears(minAge);

  const { data, loading } = useGetCreateActorPageQuery();
  const mappedData = React.useMemo(() => {
    const hasNoPreexistingUsers =
      !data?.account?.originator?.userId &&
      (data?.account?.actors?.length ?? 0) === 0;
    const hasReachedPendingUsersLimit = !data?.account.isAllowedToAddNewActor;
    return {
      shouldRedirectToList:
        hasReachedPendingUsersLimit || hasNoPreexistingUsers,
    };
  }, [data]);

  const [saveActor] = useSaveActorMutation();

  const [newUserId, setNewUserId] = React.useState<null | number>(null);

  const uploadDoc = useDocumentUpload();

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [fieldName.firstName]: createStringValidationSchema({
        isOptional: false,
        minLength: 2,
        maxLength: 32,
      }),
      [fieldName.lastName]: createStringValidationSchema({
        isOptional: false,
        minLength: 2,
        maxLength: 32,
      }),
      [fieldName.birtdate]: Yup.string()
        .required(() => <RequiredErrorMessage />)
        .test((value, testContext) => {
          if (!value) {
            // Let requried handle this case, skip here.
            return true;
          }
          const parsedDate = DateTime.fromISO(value);
          if (parsedDate < DateTime.fromISO(birthdateMin)) {
            return testContext.createError({
              message: () => <TooOldErrorMessage years={maxAge} />,
            });
          }
          if (parsedDate > DateTime.fromISO(birthdateMax)) {
            return testContext.createError({
              message: () => <TooYoungErrorMessage years={minAge} />,
            });
          }
          return true;
        }),
    });
  }, [birthdateMin, birthdateMax]);

  const hookForm = useForm<FormValues>({
    defaultValues: {
      [fieldName.firstName]: '',
      [fieldName.lastName]: '',
      [fieldName.birtdate]: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const [
    identityProofFrontOrEntireDocument,
    setIdentityProofFrontOrEntireDocument,
  ] = React.useState<null | MediaContext['media']>(null);
  const [
    identityProofFrontOrEntireDocumentError,
    setIdentityProofFrontOrEntireDocumentError,
  ] = React.useState<null | string>(null);

  const [identityProofBackDocument, setIdentityProofBackDocument] =
    React.useState<null | MediaContext['media']>(null);
  const [identityProofBackDocumentError, setIdentityProofBackDocumentError] =
    React.useState<null | string>(null);

  const [idShotDocument, setIdShotDocument] = React.useState<
    null | MediaContext['media']
  >(null);
  const [idShotDocumentError, setIdShotDocumentError] = React.useState<
    null | string
  >(null);

  const isDirty =
    hookForm.formState.isDirty ||
    !!identityProofFrontOrEntireDocument?.blob ||
    !!idShotDocument?.blob;

  const [submittedSuccessfully, setSubmittedSuccessfully] =
    React.useState(false);

  React.useEffect(() => {
    return registerDirtyFlag(!submittedSuccessfully && isDirty);
  }, [isDirty, submittedSuccessfully, registerDirtyFlag]);

  const issueFailedSubmitToast = React.useCallback(() => {
    issueChakraToast({
      status: 'error',
      description: t('realPersonDetail:toast.PersonHinzufugenFehlgeschlagen'),
    });
  }, [t]);
  const issueSuccessSubmitToast = React.useCallback(() => {
    issueChakraToast({
      status: 'success',
      description: t('realPersonDetail:toast.PersonErfolgreichHinzugefugt'),
    });
  }, [t]);

  const isLeadingActor = hookForm.watch(fieldName.partnerKind) === optionModel;

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const bithdayString = DateTime.fromISO(data[fieldName.birtdate]);

        setIdentityProofFrontOrEntireDocumentError(null);
        setIdentityProofBackDocumentError(null);
        setIdShotDocumentError(null);

        const result = await saveActor({
          variables: {
            data: {
              userId: newUserId,
              firstname: data[fieldName.firstName],
              lastname: data[fieldName.lastName],
              birthday: bithdayString.toISODate(),
              isLeadingActor,
            },
          },
        });

        const isSuccess = result?.data?.account?.saveActor?.success;

        if (!isSuccess) {
          issueFailedSubmitToast();
          Logger.error(result?.data?.account?.saveActor?.errorMessage);
          return;
        }

        const userId =
          result?.data?.account?.saveActor?.savedActor?.userId ?? null;
        setNewUserId(userId);

        if (!userId) {
          issueFailedSubmitToast();
          return;
        }

        setuserid(userId);

        const hasBack = !!identityProofBackDocument?.blob;

        const uploadResponse0 = await uploadDoc({
          documentToUpload: identityProofFrontOrEntireDocument,
          variables: {
            userId,
            documentType: DocumentTypeEnum.IdentityProof,
            side: !hasBack
              ? DocumentSideEnum.EntireDocument
              : DocumentSideEnum.Frontside,
          },
        });

        const uploadResponse1 = await uploadDoc({
          documentToUpload: identityProofBackDocument,
          variables: {
            userId,
            documentType: DocumentTypeEnum.IdentityProof,
            side: DocumentSideEnum.Backside,
          },
        });

        const uploadResponse2 = await uploadDoc({
          documentToUpload: idShotDocument,
          variables: {
            userId,
            documentType: DocumentTypeEnum.IdShot,
            side: DocumentSideEnum.EntireDocument,
          },
        });

        if (uploadResponse0.message) {
          setIdentityProofFrontOrEntireDocumentError(uploadResponse0.message);
          issueFailedSubmitToast();
          return;
        }

        if (hasBack && uploadResponse1.message) {
          setIdentityProofBackDocumentError(uploadResponse1.message);
          issueFailedSubmitToast();
          return;
        }

        if (uploadResponse2.message) {
          setIdShotDocumentError(uploadResponse2.message);
          issueFailedSubmitToast();
          return;
        }

        setSubmittedSuccessfully(true);

        if (isLeadingActor) onOpen();
        else {
          setTimeout(() => {
            history.push({
              pathname: routes.documentsActors.path,
            });
            issueSuccessSubmitToast();
          }, 200);
        }
      } catch (error) {
        Logger.error(error);
        issueFailedSubmitToast();
      }
    },
    [
      saveActor,
      newUserId,
      isLeadingActor,
      identityProofBackDocument,
      uploadDoc,
      identityProofFrontOrEntireDocument,
      idShotDocument,
      onOpen,
      issueFailedSubmitToast,
      history,
      issueSuccessSubmitToast,
    ]
  );

  const isButtonDisabled = React.useMemo(() => {
    return (
      !hookForm.formState.isValid ||
      !identityProofFrontOrEntireDocument?.blob ||
      !idShotDocument?.blob
    );
  }, [
    hookForm.formState.isValid,
    identityProofFrontOrEntireDocument,
    idShotDocument,
  ]);

  return (
    <FluentPageLayout isContentLoading={loading}>
      {mappedData.shouldRedirectToList && (
        <Redirect to={routes.documentsActors.path} />
      )}
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <HStack>
            <IconButton
              variant="unstyled"
              aria-label={'BackButtonEditPage'}
              icon={<Icon as={icons.ChevronLeft} boxSize="6" />}
              onClick={() => {
                history.push(routes.documentsActors.path);
              }}
            />
            <SubHeaderTitle>
              {t('realPersonDetail:heading.PersonHinzufugen')}
            </SubHeaderTitle>
          </HStack>
        </Container>
      </SubHeader>
      <Container maxW="container.xl" p={0} mb={8}>
        <VStack spacing={{ base: 4, lg: 8 }} alignItems={'stretch'}>
          <Section>
            <SectionHeader>
              <SectionCenterContainer>
                <SectionTitleRow>
                  <SectionTitle>
                    {t('realPersonDetail:heading.Altersverifizierung')}
                  </SectionTitle>
                </SectionTitleRow>
                <SectionDescription>
                  {t(
                    'realPersonDetail:text.MitDiesenAngabenVersicherstDuDassDeinPartnerVolljahrigIst'
                  )}
                </SectionDescription>
              </SectionCenterContainer>
            </SectionHeader>
            <SectionDivider isWidthRestricted />
            <FormProvider {...hookForm}>
              <form onSubmit={(e) => hookForm.handleSubmit(onValidSubmit)(e)}>
                <SectionBody>
                  <SectionCenterContainer>
                    <VStack
                      spacing={6}
                      divider={<Divider />}
                      alignItems={'stretch'}
                    >
                      <VStack spacing={6} alignItems={'stretch'}>
                        <EnumSelectFieldHookForm
                          label={t(
                            'realPersonDetail:text.WelcheRolleHatDeinPartner'
                          )}
                          name={fieldName.partnerKind}
                          isDisabled={false}
                        >
                          <option value={optionModel}>
                            {t('realPersonDetail:text.Model')}
                          </option>
                          <option value={optionPartner}>
                            {t('realPersonDetail:text.Drehpartner')}
                          </option>
                        </EnumSelectFieldHookForm>
                        <ClearableInputControl
                          label={t('realPerson:label.Vorname')}
                          placeholder={t(
                            'realPerson:placeholder.VornamenEingeben'
                          )}
                          name={fieldName.firstName}
                        />
                        <ClearableInputControl
                          label={t('realPerson:label.Nachname')}
                          placeholder={t(
                            'realPerson:placeholder.NachnamenEingeben'
                          )}
                          name={fieldName.lastName}
                        />
                        <ClearableInputControl
                          label={t('realPerson:label.Geburtsdatum')}
                          placeholder={t(
                            'realPerson:placeholder.GeburtsdatumEingeben'
                          )}
                          name={fieldName.birtdate}
                          type={'date'}
                          sx={{
                            '&::-webkit-date-and-time-value': {
                              textAlign: 'left',
                            },
                          }}
                          min={birthdateMin}
                          max={birthdateMax}
                        />
                      </VStack>
                      <MediaPropertiesProvider.ForIdentityProofFrontOrEntireDocument>
                        <MediaProvider
                          isEditable={!hookForm.formState.isSubmitting}
                          onReplacement={(media) => {
                            setIdentityProofFrontOrEntireDocument(media);
                            setIdentityProofFrontOrEntireDocumentError(null);
                          }}
                        >
                          <ScrollToTargetBlock
                            id={UrlFragment.IdentityProofFrontOrEntireDocument}
                          >
                            <HeadingWithOptionalToggleAndGuideDot
                              heading={t(
                                'realPersonDetail:heading.LadeEinFotoDerAusweisVorderseiteDeinesPartnersHoch'
                              )}
                              isRequired={true}
                              isRequiredButMissing={
                                !!identityProofFrontOrEntireDocumentError
                              }
                            />
                            <MissingDocumentErrorMessage2
                              error={identityProofFrontOrEntireDocumentError}
                            />
                            <MediaInputProvider accept={'DEFAULT_FOR_PICTURE'}>
                              <IdentityProofPreviewBox
                                isUploading={hookForm.formState.isSubmitting}
                              />
                              <MediaUploadCropperPortal />
                            </MediaInputProvider>
                          </ScrollToTargetBlock>
                        </MediaProvider>
                      </MediaPropertiesProvider.ForIdentityProofFrontOrEntireDocument>
                      <MediaPropertiesProvider.ForIdentityProofBackDocument>
                        <MediaProvider
                          isEditable={!hookForm.formState.isSubmitting}
                          onReplacement={(media) => {
                            setIdentityProofBackDocument(media);
                            setIdentityProofBackDocumentError(null);
                          }}
                        >
                          <ScrollToTargetBlock
                            id={UrlFragment.IdentityProofBackDocument}
                          >
                            <HeadingWithOptionalToggleAndGuideDot
                              heading={t(
                                'realPersonDetail:heading.LadeEinFotoDerAusweisRuckseiteDeinesPartnersHoch'
                              )}
                              isRequired={false}
                              isRequiredButMissing={
                                !!identityProofBackDocumentError
                              }
                            />
                            <MissingDocumentErrorMessage2
                              error={identityProofBackDocumentError}
                            />
                            <MediaInputProvider accept={'DEFAULT_FOR_PICTURE'}>
                              <IdentityProofPreviewBox
                                isUploading={hookForm.formState.isSubmitting}
                              />
                              <MediaUploadCropperPortal />
                            </MediaInputProvider>
                          </ScrollToTargetBlock>
                        </MediaProvider>
                      </MediaPropertiesProvider.ForIdentityProofBackDocument>
                      <MediaPropertiesProvider.ForIdShotDocument>
                        <MediaProvider
                          isEditable={!hookForm.formState.isSubmitting}
                          onReplacement={(media) => {
                            setIdShotDocument(media);
                            setIdShotDocumentError(null);
                          }}
                        >
                          <ScrollToTargetBlock id={UrlFragment.IdShotDocument}>
                            <HeadingWithOptionalToggleAndGuideDot
                              heading={t(
                                'realPersonDetail:heading.LassDeinenPartnerEinenIdShotAufnehmen'
                              )}
                              isRequired={true}
                              isRequiredButMissing={!!idShotDocumentError}
                            />

                            <Text
                              textStyle={'bodySm'}
                              color={'onSurface.mediumEmphasis'}
                            >
                              {t(
                                'realPersonDetail:text.EinFotoVonDeinemPartnerAufDemErSeinenAusweisGutLesbarNebenSeinGesichtHalt'
                              )}
                            </Text>
                            {/* <MissingDocumentErrorMessage2
                              error={idShotDocumentError}
                            /> */}
                            <MediaInputProvider
                              accept={'DEFAULT_FOR_PICTURE'}
                              capture={'user'}
                            >
                              <IdentityProofPreviewBox
                                isUploading={hookForm.formState.isSubmitting}
                              />
                              <MediaUploadCropperPortal />
                            </MediaInputProvider>
                          </ScrollToTargetBlock>
                        </MediaProvider>
                      </MediaPropertiesProvider.ForIdShotDocument>
                      <VStack spacing={6}>
                        <HelpLink
                          href={externalRoutes.vxModelsImageRules(apiLang)}
                        >
                          {t(
                            'realPersonDetail:text.WasMussIchBeimHochladenDesAusweisesBeachten'
                          )}
                        </HelpLink>
                        <Button
                          variant={'solid'}
                          isLoading={hookForm.formState.isSubmitting}
                          alignSelf={'center'}
                          onClick={(e) =>
                            hookForm.handleSubmit(onValidSubmit)(e)
                          }
                          isDisabled={isButtonDisabled}
                        >
                          {t('realPersonDetail:button.PersonHinzufugen')}
                        </Button>
                      </VStack>
                    </VStack>
                  </SectionCenterContainer>
                </SectionBody>
              </form>
            </FormProvider>
          </Section>
        </VStack>
      </Container>
      {userid && isLeadingActor && (
        <LeadingRoleMRFModal
          isOpen={isOpen}
          onClose={onClose}
          createdActor={true}
          userId={userid}
        />
      )}
    </FluentPageLayout>
  );
};

const MissingDocumentErrorMessage2: React.FC<{
  error?: string | null;
}> = ({ error }) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormErrorMessage>
        <FormErrorIcon />
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

const IdentityProofPreviewBox: React.FC<{ isUploading?: boolean }> = ({
  isUploading,
}) => {
  const { media } = useMedia();
  const hasMedia = !!media?.src;

  return (
    <Container p={4}>
      <MediaPreviewBox
        isUploading={isUploading}
        boxProps={{
          borderRadius: 'lg',
          border: 'solid',
          borderColor: !hasMedia ? 'black' : 'transparent',
          borderStyle: !hasMedia ? 'dashed' : 'solid',
          backgroundColor: 'surface',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        isAspectRatioDynamic
        restrictToHeight={{ base: '8.5rem', lg: '8.5rem' }}
        imageProps={{ borderRadius: 'lg', bg: 'gray.100' }}
      />
    </Container>
  );
};

export const LeadingRoleMRFModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  createdActor: boolean;
  userId: number;
}> = ({ isOpen, onClose, createdActor, userId }) => {
  const [loading, setloading] = React.useState(false);
  const { authUser } = useAuth();
  const history = useHistory();
  const hookForm = useForm({
    defaultValues: {
      [fieldName.mrfLanguage]:
        authUser?.lang === ApiLangEnum.De
          ? ModelReleaseFormLanguageEnum.De
          : ModelReleaseFormLanguageEnum.En,
    },
    mode: 'all',
  });
  const { t } = useTranslation(['language', 'mrfModal']);

  const getOrigin = () => window.location?.origin ?? ENV.HOST;

  const [addMRFMutation] = useInitModelReleaseFormMutation({});

  const toPaperlessOrCopyLink = React.useCallback(
    async (copyLink: boolean) => {
      setloading(true);
      let redirectUrl = `${getOrigin()}${
        routes.documentsActors.path
      }?actorAdded=true`;
      if (copyLink) redirectUrl = `${getOrigin()}${routes.home.path}`;
      try {
        const lang =
          hookForm.getValues(fieldName.mrfLanguage) ??
          ModelReleaseFormLanguageEnum.De;
        const resp = await addMRFMutation({
          variables: {
            userId,
            lang,
            redirectUrl,
          },
        });
        const url = resp.data?.documents?.initModelReleaseForm.formUrl;

        if (!url) return;
        if (!copyLink) {
          windowOpen(url, '_self');
        } else {
          navigator.clipboard.writeText(url);
          history.push({
            pathname: routes.documentsActors.path,
          });
          issueChakraToast({
            status: 'success',
            description: t('mrfModal:toast.LinkInZwischenablageKopiert'),
          });
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setTimeout(() => {
          setloading(false);
        }, 500);
      }
    },
    [addMRFMutation, history, hookForm, t, userId]
  );

  const extendedOnClose = React.useCallback(() => {
    if (createdActor) {
      history.replace(`${routes.documentsActors.path}?actorAdded=true`);
    } else {
      onClose();
    }
  }, [createdActor, history, onClose]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={extendedOnClose}>
      <ResponsiveModalOverlay onClick={extendedOnClose} />
      <ResponsiveModalContent
        maxW={'567px'}
        bgColor={'surface'}
        color={'coldGray.900'}
      >
        <ResponsiveModalStickyHeaderBox bg={'surface'}>
          <ResponsiveModalCloseButton onClick={extendedOnClose} />
        </ResponsiveModalStickyHeaderBox>
        <FormProvider {...hookForm}>
          <form>
            <ResponsiveModalBodyBox bg={'transparent'} mt={'20px'}>
              <VStack alignItems={'stretch'} textAlign={'center'}>
                <Img
                  src={image}
                  alt={t('mrfModal:img.HandschlagNachVertragsunterzeichnung')}
                  maxH={'150px'}
                />
                <Heading size={'lg'}>
                  {t('mrfModal:text.ModelReleaseFormAusfullen')}
                </Heading>
                <Heading size={'sm'}>
                  {t('mrfModal:text.DuHastFolgendeOptionen')}
                </Heading>
                <Text
                  color={'gray.500'}
                  fontWeight={'700'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                >
                  {t('mrfModal:text.ModelReleaseFormAusfullen')}
                </Text>
                <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                  marginBottom={'15px'}
                  mb={3}
                >
                  {t(
                    'mrfModal:text.NutzeDieseOptionWennDasFormularFurDichIstDuKannstEsDirektAusfullenUnXX'
                  )}
                </Text>
                <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  lineHeight={'10px'}
                  mb={3}
                >
                  {t('mrfModal:text.Oder')}
                </Text>
                <Text
                  color={'gray.500'}
                  fontWeight={'700'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                >
                  {t('mrfModal:text.MrfLinkKopieren')}
                </Text>
                <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                  mb={8}
                >
                  {t(
                    'mrfModal:text.NutzeDieseOptionWennDasFormularFurEineAnderePersonIstDieDasFormularAXX'
                  )}
                </Text>
                <EnumSelectFieldHookForm
                  label={t(
                    'mrfModal:text.InWelcherSpracheSollDasModelReleaseFormErstelltWerden'
                  )}
                  name={fieldName.mrfLanguage}
                  isDisabled={false}
                  mb={6}
                >
                  <option value={ModelReleaseFormLanguageEnum.De}>
                    {t('language:de')}
                  </option>
                  <option value={ModelReleaseFormLanguageEnum.En}>
                    {t('language:en')}
                  </option>
                </EnumSelectFieldHookForm>
                <Button
                  isLoading={loading}
                  alignSelf={'center'}
                  onClick={() => toPaperlessOrCopyLink(false)}
                  mb={2}
                  variant={'solid'}
                >
                  {t('mrfModal:button.ModelReleaseFormAusfullen')}
                </Button>
                <Button
                  isLoading={loading}
                  alignSelf={'center'}
                  variant={'link'}
                  rightIcon={<Icon as={icons.FilterNone} boxSize={'icon.sm'} />}
                  onClick={() => toPaperlessOrCopyLink(true)}
                >
                  {t('mrfModal:button.MRFLinkKopieren')}
                </Button>
              </VStack>
            </ResponsiveModalBodyBox>
          </form>
        </FormProvider>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
