// Based on https://github.com/kgnugur/formik-chakra-ui
import {
  Box,
  FormControl as ChakraFormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC } from 'react';

export interface BaseProps extends Omit<FormControlProps, 'label'> {
  name: string;
  label?: React.ReactNode;
  labelProps?: FormLabelProps;
  helperText?: React.ReactNode;
  helperTextPositionBottom?: boolean;
  helperTextProps?: FormHelperTextProps;
  errorMessageProps?: FormErrorMessageProps;
}

export const FormControlHeaderStack: React.FC<StackProps> = (props) => (
  <VStack alignItems={'start'} spacing={2} pb={3} {...props} />
);

export const FormControl: FC<BaseProps> = (props: BaseProps) => {
  const {
    children,
    name,
    label,
    labelProps,
    helperText,
    helperTextPositionBottom,
    helperTextProps,
    errorMessageProps,
    ...rest
  } = props;
  const [, { error, touched }] = useField(name);

  const isInvalid = !!error && touched;

  return (
    <ChakraFormControl isInvalid={isInvalid} {...rest}>
      <FormControlHeaderStack>
        {label && <FormLabel {...labelProps}>{label}</FormLabel>}
        {helperText && !helperTextPositionBottom && (
          <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
        )}
      </FormControlHeaderStack>
      {children}

      {/*Error container*/}
      <Box minH={5} mt={1}>
        {error && (
          <FormErrorMessage {...errorMessageProps}>{error}</FormErrorMessage>
        )}
      </Box>
      {helperText && helperTextPositionBottom && (
        <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
      )}
    </ChakraFormControl>
  );
};

export default FormControl;
