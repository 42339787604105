import { Button, Heading, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import payoutImage from '../../../../assets/images/shared/payout.svg';
import { FeatureName, useFeature } from '../../../../flags';
import { useWizardPayout as useWizardPayoutV1 } from '../../../../pages/Wizard/Payout/WizardPayoutContext';
import { useWizardPayout as useWizardPayoutV2 } from '../../../../pages/Wizard/PayoutV2/WizardPayoutContext';
import { useAccountStatus } from '../../../../provider/AccountStatusProvider/AccountStatusProvider';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../cards/UniversalCard/UniversalCard';

const SetupPayoutCard: React.FC = () => {
  const { t } = useTranslation(['payout']);
  const wizardPayoutV2Flag = useFeature(FeatureName.wizardPayoutV2);
  const wizardPayoutV1 = useWizardPayoutV1();
  const wizardPayoutV2 = useWizardPayoutV2();
  const { action } = useFinanceService();
  const { actions } = useAccountStatus();
  const onFinish = () => {
    action.triggerRefetch();
    actions.refresh();
  };

  const navigateToWizard = () => {
    if (wizardPayoutV2Flag) {
      wizardPayoutV2.setOnFinished(onFinish);
      wizardPayoutV2.setVisible(true);
    } else {
      wizardPayoutV1.setOnFinished(onFinish);
      wizardPayoutV1.setVisible(true);
    }
  };

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={payoutImage}
              alt={t(
                'payout:img.MadchenMitGeldInDenHandenSiehtWachsendeEinnahmen'
              )}
            />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t('payout:headline.RichteDeineAuszahlungEin')}
              </Heading>
              <Text
                size={{ base: 'sm', lg: 'md' }}
                textAlign={'center'}
                color={'gray.500'}
                p={0}
              >
                {t(
                  'payout:text.SoKannstDuZumNaechstenAuszahlungsterminDeinGeldErhalten'
                )}
              </Text>
            </VStack>
          </>
        }
        cardFooter={
          <Button
            variant={'solid'}
            children={t('payout:button.AuszahlungEinrichten')}
            onClick={navigateToWizard}
          />
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
export default SetupPayoutCard;
