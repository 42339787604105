import { Button, Divider, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const VideoPostCommentIndicator: React.FC<{
  commentCount: number;
  onClickOnCommentButton: () => void;
}> = ({ commentCount, onClickOnCommentButton }) => {
  const { t } = useTranslation(['videoLibrary']);

  return (
    <>
      <Divider />
      {commentCount > 0 ? (
        <Button
          variant="link"
          onClick={onClickOnCommentButton}
          color={'gray.900'}
          _hover={{ color: 'gray.300' }}
        >
          {t('videoLibrary:button.AlleKommentareAnsehen')}
        </Button>
      ) : (
        <Text color={'gray.300'}>
          {t('videoLibrary:button.KeinKommentarVorhanden')}
        </Text>
      )}
    </>
  );
};
