import React from 'react';

import { AbsoluteFullCenterLoadingSpinner } from '../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { FullscreenPageLayout } from '../../components/Layout/FullscreenPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';

type LoadingPageProps = {
  message?: string;
  variant?: 'light' | 'dark';
};

const LoadingPage: React.FC<LoadingPageProps> = ({
  message,
  variant = 'light',
}) => {
  return (
    <FullscreenPageLayout>
      <ScrollToTopOnMount />
      <div>
        <AbsoluteFullCenterLoadingSpinner
          message={message}
          color={variant === 'light' ? 'coldGray.900' : 'whiteAlpha.900'}
          bg={variant === 'light' ? 'lightBackground' : 'cold'}
        />
      </div>
    </FullscreenPageLayout>
  );
};

export default LoadingPage;
