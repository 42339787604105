import { Button, Container, HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { routes } from '../../../routes/routesConfig';

export const ProfileMenuSwitch: React.FC = () => {
  const { t } = useTranslation(['modelProfile']);
  const history = useHistory();
  const feedActive = history.location.pathname === routes.feed.path;
  const inactiveColor = 'lightBackground';

  const navigateToFeedPage = React.useCallback(() => {
    history.push(routes.feed.path);
  }, [history]);
  const navigateToProfilePage = React.useCallback(() => {
    history.push(routes.profileV2.path);
  }, [history]);

  return (
    <Container flexShrink={1} w={'min-content'} px={4} centerContent>
      <HStack
        bgColor={inactiveColor}
        border={'1px'}
        borderColor={'primary.500'}
        borderRadius={'100px'}
        gap={0}
      >
        <Button
          minW={'144px'}
          color={feedActive ? 'surface' : 'gray.9000'}
          variant={feedActive ? 'solid' : 'outline'}
          border={'none'}
          children={t('modelProfile:menuSwitch.VXFeed')}
          onClick={navigateToFeedPage}
        />
        <Button
          minW={'144px'}
          color={!feedActive ? 'surface' : 'gray.9000'}
          variant={!feedActive ? 'solid' : 'outline'}
          border={'none'}
          children={t('modelProfile:menuSwitch.VXModelprofil')}
          onClick={navigateToProfilePage}
        />
      </HStack>
    </Container>
  );
};
