import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const PasswordClearableInputControl: React.FC<
  {
    name: string;
    label: string;
    placeholder: string;
    labelProps?: FormLabelProps;
  } & InputProps
> = ({
  name,
  label,
  placeholder,
  labelProps,
  isDisabled,
  isReadOnly,
  ...rest
}) => {
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
    setValue,
    trigger,
    setFocus,
    watch,
  } = useFormContext();

  const [isFocused, setIsFocused] = React.useState(false);
  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);
  const [showPassword, setShowPassword] = React.useState(false);
  const value = watch(name);
  const isVisible = !isDisabled && !isReadOnly;
  const isClearIconVisible = value && isFocused;

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel children={label} pb={3} {...labelProps} />
      <InputGroup
        size="md"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <Input
          pr={'100px'}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          {...register(name)}
          {...rest}
        />
        {isVisible && (
          <InputRightElement
            right={'60px'}
            pointerEvents={isClearIconVisible ? 'unset' : 'none'}
          >
            {isClearIconVisible && (
              <IconButton
                size="sm"
                aria-label="clear"
                variant="ghost"
                color="onSurface.mediumEmphasis"
                colorScheme="gray"
                icon={<Icon as={icons.Close} boxSize="icon.md" />}
                onMouseDown={() => {
                  setValue(name, '');
                  trigger(name);
                  setTimeout(() => setFocus(name), 0);
                }}
                onTouchStart={() => {
                  setValue(name, '');
                  trigger(name);
                  setTimeout(() => setFocus(name), 0);
                }}
              />
            )}
          </InputRightElement>
        )}
        <InputRightElement width={'auto'}>
          <Divider
            orientation="vertical"
            h={'40px'}
            borderColor={isDisabled ? 'gray.200' : 'gray.400'}
          />

          <Box px={'4'}>
            {showPassword ? (
              <Icon
                as={icons.VisibilityOff}
                boxSize={'icon.md'}
                cursor={'pointer'}
                onClick={() => {
                  setShowPassword(false);
                }}
              />
            ) : (
              <Icon
                as={icons.Visibility}
                boxSize={'icon.md'}
                cursor={'pointer'}
                onClick={() => {
                  setShowPassword(true);
                }}
              />
            )}
          </Box>
        </InputRightElement>
      </InputGroup>
      {isInvalid && (
        <Box mt={3}>
          <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
        </Box>
      )}
    </FormControl>
  );
};
