import * as icons from '@campoint/odi-ui-icons';
import {
  Icon,
  IconButton,
  IconButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  DestructiveMenuModalItem,
  MenuModal,
  MenuModalContent,
  MenuModalItem,
  MenuModalItemList,
  MenuModalOverlay,
} from '../../../../components/shared/FeedPostMenu/FeedPostMenu';
import { ConfirmMediaDeletionDialog } from '../../../../components/shared/dialogs/ConfirmMediaDeletionDialog/ConfirmMediaDeletionDialog';

type VideoLibraryEntryMenuMediaType = 'video' | 'photoalbum';

export const VideoLibraryEntryMenu: React.FC<
  {
    mediaType: VideoLibraryEntryMenuMediaType;
    onClickOnEdit?: null | (() => void);
    onClickOnDelete: null | (() => void);
    directDelete?: boolean;
  } & Partial<IconButtonProps>
> = ({
  onClickOnEdit,
  onClickOnDelete,
  directDelete = false,
  mediaType,
  ...props
}) => {
  const { t } = useTranslation(['videoLibrary']);
  const menuDialogModal = useDisclosure();
  const confirmDeleteDialogModal = useDisclosure();

  const hasMenutItems = !!onClickOnEdit || !!onClickOnDelete;

  const toastMessageByMediaType = {
    video: {
      edit: t('videoLibrary:menuItem.VideoBearbeiten'),
      delete: t('videoLibrary:menuItem.VideoLoschen'),
    },
    photoalbum: {
      edit: t('videoLibrary:menuItem.AlbumBearbeiten'),
      delete: t('videoLibrary:menuItem.AlbumLoschen'),
    },
  };

  const editClickHandler = () => {
    onClickOnEdit?.();
    menuDialogModal.onClose();
  };

  const deleteClickHandler = () => {
    onClickOnDelete?.();
    menuDialogModal.onClose();
    confirmDeleteDialogModal.onClose();
  };

  return !hasMenutItems ? (
    <></>
  ) : (
    <>
      <IconButton
        aria-label={t('videoLibrary:label.MehrOptionen')}
        variant={'ghost'}
        icon={
          <Icon
            as={directDelete ? icons.DeleteOutline : icons.MoreHoriz}
            boxSize={'6'}
            color={directDelete ? 'primary.500' : 'coldGray.900'}
          />
        }
        onClick={
          directDelete
            ? confirmDeleteDialogModal.onOpen
            : menuDialogModal.onOpen
        }
        {...props}
      />

      <MenuModal
        isOpen={menuDialogModal.isOpen}
        onClose={menuDialogModal.onClose}
      >
        <MenuModalOverlay />
        <MenuModalContent>
          <MenuModalItemList>
            {!onClickOnEdit ? (
              <></>
            ) : (
              <MenuModalItem onClick={editClickHandler}>
                {toastMessageByMediaType[mediaType].edit}
              </MenuModalItem>
            )}
            {!onClickOnDelete ? (
              <></>
            ) : (
              <DestructiveMenuModalItem
                onClick={confirmDeleteDialogModal.onOpen}
              >
                {toastMessageByMediaType[mediaType].delete}
              </DestructiveMenuModalItem>
            )}
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
      <ConfirmMediaDeletionDialog
        deleteMedia={() => {
          deleteClickHandler();
        }}
        mediaType={mediaType}
        isOpen={confirmDeleteDialogModal.isOpen}
        onClose={confirmDeleteDialogModal.onClose}
      />
    </>
  );
};
