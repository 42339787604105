import {
  Maybe,
  MediaPictureFieldFragment,
  MediaPictureValueFragment,
  ProfileStatusEnum,
  UploadInfo,
} from '../generated/graphql';
import { CroppedImage } from '../types/CroppedImage';
import { ImageStatus } from '../types/ImageStatus';
import Logger from './Logger';

export async function uploadImage(
  url: string,
  imageData: Blob,
  fileName: string | null = null,
  type?: number | null,
  umaId?: number | null
) {
  try {
    const a = await fetch(url, {
      mode: 'cors',
      method: 'post',
      body: blobToFormData(imageData, fileName ?? undefined, type, umaId),
    });

    const responseBody = await a.text();

    const errorCode = Number.parseInt(responseBody.replace('errorcode=', ''));
    const id = Number.parseInt(responseBody.replace('id=', ''));

    return {
      id: Number.isNaN(id) ? null : id,
      errorCode: Number.isNaN(errorCode) ? null : errorCode,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function uploadDocument(
  url: string,
  documentData: Blob,
  fileName: string | null = null
) {
  try {
    const a = await fetch(url, {
      mode: 'cors',
      method: 'post',
      body: blobToFormData(documentData, fileName ?? undefined),
    });

    const responseBody = await a.text();

    const id = Number.parseInt(responseBody.replace('OK:', ''));

    return {
      id: Number.isNaN(id) ? null : id,
    };
  } catch (e) {
    Logger.error(e);

    return {
      id: null,
    };
  }
}

export function extractErrorCodeMap(uploadInfo?: UploadInfo) {
  const codes = uploadInfo?.errorCodes ?? {};
  const map = new Map<number, string>();

  // Variable `key` resulted in faulty transpiled JS, therefore it was renamed to `fieldKey`
  for (const fieldKey in codes) {
    const entry = codes[fieldKey];
    if (entry?.code && entry?.description) {
      map.set(entry.code, entry.description);
    }
  }
  return map;
}

export function mapImageStatusToImageViewStatus(imageStatus: ImageStatus) {
  switch (imageStatus) {
    case 'add':
      return 'add' as const;
    case 'reviewing':
      return 'reviewing' as const;
    case 'rejected':
      return 'rejected' as const;
    case 'loading':
    case 'accepted':
    case 'edit':
    default:
      return 'edit' as const;
  }
}

export function extractImageStatus(
  picture?: Maybe<{ status: ProfileStatusEnum }>
): ImageStatus {
  if (picture === undefined) {
    return 'loading';
  }
  if (picture === null) {
    return 'add';
  }
  switch (picture.status) {
    case ProfileStatusEnum.Rejected:
      return 'rejected';
    case ProfileStatusEnum.Accepted:
      return 'edit';
    case ProfileStatusEnum.Pending:
      return 'reviewing';
    case ProfileStatusEnum.Incomplete:
    case ProfileStatusEnum.Open:
    default:
      return 'add';
  }
}

export function extractImageData(
  fields?: Maybe<MediaPictureFieldFragment>,
  values?: Maybe<MediaPictureValueFragment>
) {
  const imageStatus = extractImageStatus(fields);
  const uploadErrorCodes = extractErrorCodeMap(fields?.upload);

  return {
    uploadUrl: fields?.upload.url ?? null,
    status: imageStatus ?? null,
    rejectReason: fields?.rejectReason ?? null,
    src: values?.image.src ?? null,
    uploadErrorCodes,
  };
}

export function extractStatusFrom(
  loading: boolean,
  imageStatus: ImageStatus,
  croppedImage?: CroppedImage | null
) {
  if (loading) {
    return 'loading';
  }
  return croppedImage ? 'edit' : imageStatus ?? undefined;
}

export const mediaUploadErrorCodes = {
  en: {
    AUTH_ERROR: {
      code: -1,
      description: 'SessionCheck/Auth failed',
    },
    DIMENSIONS_TOO_SMALL: {
      code: -5,
      description: 'Picture dimensions too small, min 520px x 520px',
    },
    FILE_DUPLICATE: {
      code: -8,
      description: 'File duplicate, already uploaded',
    },
    FILE_SIZE_EXCEEDED: {
      code: -4,
      description: 'File size exceeded',
    },
    FILE_TYPE_WRONG: {
      code: -3,
      description: 'Wrong file type',
    },
    ORACLE_ERROR_20100: {
      code: -7,
      description: 'Database error',
    },
    PICTURE_DEVIATION_ERROR: {
      code: -9,
      description:
        'Standard deviation of picture too small, e.g. plain black picture',
    },
    UMA_ID_NOT_FOUND: {
      code: -2,
      description: 'Album not found',
    },
  },
  de: {
    AUTH_ERROR: {
      code: -1,
      description: 'SessionCheck/Authorization fehlerhaft',
    },
    DIMENSIONS_TOO_SMALL: {
      code: -5,
      description: 'Bild-Dimensionen zu gering, min. 520px x 520px',
    },
    FILE_DUPLICATE: {
      code: -8,
      description: 'Duplikat, Datei bereits hochgeladen',
    },
    FILE_SIZE_EXCEEDED: {
      code: -4,
      description: 'Dateigr\u00f6\u00dfe \u00fcberschritten',
    },
    FILE_TYPE_WRONG: {
      code: -3,
      description: 'Nicht unterst\u00fctztes Dateiformat',
    },
    ORACLE_ERROR_20100: {
      code: -7,
      description: 'Datenbankfehler',
    },
    PICTURE_DEVIATION_ERROR: {
      code: -9,
      description: 'Bild zu einfarbig, z.B. nur schwarz',
    },
    UMA_ID_NOT_FOUND: {
      code: -2,
      description: 'Album nicht gefunden',
    },
  },
};

/**
 *
 * @param orientedStencil
 * @param isStencilVisible
 */
export const createStencilCssProps = (
  orientedStencil?: string,
  isStencilVisible = true
) =>
  ({
    backgroundImage:
      !isStencilVisible || !orientedStencil
        ? 'unset'
        : `repeating-linear-gradient( -45deg, white, white 4px, gray 4px, gray 8px), url(${orientedStencil})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center, center',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'screen',
    mixBlendMode: 'multiply',
  } as const);

export const isBlobVideo = (_?: File | Blob | null) =>
  !_ ? false : /^video\//.test(_.type);
export const isBlobUnknown = (_?: File | Blob | null) =>
  !_ ? false : /^application\/octet-stream/.test(_.type);
export const isBlobImage = (_?: File | Blob | null) =>
  !_ ? false : /^image\//.test(_.type);

function blobToFormData(
  blob: Blob,
  fileName?: string,
  type?: number | null,
  umaId?: number | null
) {
  const data = new FormData();
  if (type) {
    data.append('type', type.toString());
  }
  if (umaId) {
    data.append('umaId', umaId.toString());
  }
  data.append('file', blob, fileName);
  return data;
}

/***
 * Implementation might be replaced by `axios.post` request. But this causes issues in tests
 * "axios request.upload.addEventListener in not a function"
 * @see https://github.com/axios/axios/issues/1630
 * ```
 * const response = await axios.post<{ success: boolean; error?: string }>(
 *   url,
 *   blobToFormData(blob, fileName ?? undefined),
 *   {
 *     onUploadProgress: (progressEvent) => {
 *       const progress = Math.round(
 *         (progressEvent.loaded * 100) / progressEvent.total
 *       );
 *       onProgress(progress);
 *     },
 *   }
 * );
 * return response.data;
 * ```
 */
export async function uploadBlob(
  url: string,
  blob: Blob,
  fileName: string | undefined,
  onProgress?: (progressInPercent: number) => void
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    if (!!onProgress) {
      xhr.upload.onprogress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(progress);
      };
    }
    xhr.onload = () => {
      try {
        const body = JSON.parse(xhr.responseText);
        resolve(body);
      } catch {
        resolve(xhr.responseText);
      }
    };
    xhr.onerror = () => {
      try {
        reject(JSON.parse(xhr.responseText));
      } catch {
        reject(xhr.responseText);
      }
    };

    const formData = blobToFormData(blob, fileName ?? undefined);
    xhr.open('POST', url, true);
    xhr.send(formData);
  });
}

/***
 * Implementation might be replaced by `axios.post` request. But this causes issues in tests
 * "axios request.upload.addEventListener in not a function"
 * @see https://github.com/axios/axios/issues/1630
 * ```
 * const response = await axios.post<{ success: boolean; error?: string }>(
 *   url,
 *   blobToFormData(blob, fileName ?? undefined),
 *   {
 *     onUploadProgress: (progressEvent) => {
 *       const progress = Math.round(
 *         (progressEvent.loaded * 100) / progressEvent.total
 *       );
 *       onProgress(progress);
 *     },
 *   }
 * );
 * return response.data;
 * ```
 */
export function abortableUploadBlob(
  url: string,
  blob: Blob,
  fileName: string | undefined,
  onProgress?: (progressInPercent: number) => void
) {
  const xhr = new XMLHttpRequest();
  const abortUpload = () => {
    xhr.abort();
  };
  return {
    abortUpload,
    uploadPromise: new Promise((resolve, reject) => {
      if (!!onProgress) {
        xhr.upload.onprogress = (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(progress);
        };
      }
      xhr.onload = () => {
        try {
          const body = JSON.parse(xhr.responseText);
          resolve(body);
        } catch {
          resolve(xhr.responseText);
        }
      };
      xhr.onerror = () => {
        try {
          reject(JSON.parse(xhr.responseText));
        } catch {
          reject(xhr.responseText);
        }
      };

      const formData = blobToFormData(blob, fileName ?? undefined);
      xhr.open('POST', url, true);
      xhr.send(formData);
    }),
  } as const;
}
