import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ResponsiveModalProps } from '../../../Layout/ResponsiveModal';
import { DialogModal } from '../../DialogModal/DialogModal';

export type ConfirmCommentDeletionDialogProps = {
  commentId: string;
  deleteComment: (commentId: string) => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const ConfirmCommentDeletionDialog: React.FC<
  ConfirmCommentDeletionDialogProps
> = ({ onClose, commentId, deleteComment, ...rest }) => {
  const { t } = useTranslation(['general', 'feed']);
  const leastDestructiveRef = useRef(null);

  return (
    <DialogModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      headline={t('feed:heading.KommentarLoschen')}
      confirmButtonText={t('feed:button.KommentarLoschen')}
      onConfirm={() => {
        deleteComment(commentId);
        onClose();
      }}
      closeButtonText={t('feed:button.Abbrechen')}
      onCloseComplete={onClose}
      {...rest}
    />
  );
};
