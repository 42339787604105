import {
  AspectRatio,
  AspectRatioProps,
  Image,
  ImageProps,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';

export type IllustrationBoxProps = {
  image: Maybe<{ src: string; alt: string }>;
  aspectRatioProps?: AspectRatioProps;
  imageProps?: ImageProps;
};
/**
 * Shows an image in an aspect ratio enforcing box
 */
export const IllustrationBox: React.FC<IllustrationBoxProps> = ({
  image,
  aspectRatioProps,
  imageProps,
}) => {
  return (
    <AspectRatio ratio={16 / 9} bg={'lightBackground'} {...aspectRatioProps}>
      {image ? (
        <Image
          alt={image?.alt}
          src={image?.src}
          objectFit={'cover'}
          userSelect={'none'}
          {...imageProps}
        />
      ) : (
        // AspectRatio does not like to be empty,
        // so we give it something to chew on
        <></>
      )}
    </AspectRatio>
  );
};
