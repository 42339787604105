import React, { FC, useMemo, useState } from 'react';

import { TipEntryFragment, useGetTipsDataQuery } from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import { useExperienceIntentionFilterHelper } from '../hooks/useFilterHelper';
import { extractDefinedEntries } from '../utils/utils';
import { ExperienceIntentionFilterableListContext } from './FilterablListProvider';

export type FilterableTipListContext =
  ExperienceIntentionFilterableListContext<TipEntryFragment>;

export const [
  FilteredTipListProvider,
  useFilteredTipListContext,
  filteredTipListContext,
] = createContext<FilterableTipListContext>({
  name: 'FilteredTipListContext',
});

const useTipsMappedData = () => {
  const { data, loading, error } = useGetTipsDataQuery({
    fetchPolicy: 'no-cache',
  });

  const { entries, experienceTags, intentionTags } = useMemo(() => {
    const { tips } = data?.helpcenter ?? {};
    return {
      entries: extractDefinedEntries(tips?.entries),
      experienceTags: extractDefinedEntries(tips?.experienceTags),
      intentionTags: extractDefinedEntries(tips?.intentionTags),
    };
  }, [data]);

  return { loading, error, entries, experienceTags, intentionTags };
};

export const TipProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { entries, experienceTags, intentionTags, loading } =
    useTipsMappedData();

  const filterHelper = useExperienceIntentionFilterHelper<TipEntryFragment>({
    localStorageKey: 'Tips:Filter:Values',
    entries,
  });

  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);

  const context = useMemo(
    () => ({
      loading,
      isFilterSheetOpen,
      toggleFilterSheet: () => setIsFilterSheetOpen((prev) => !prev),
      filterCategory: {
        experienceTags,
        intentionTags,
      },
      ...filterHelper,
    }),
    [loading, isFilterSheetOpen, filterHelper, experienceTags, intentionTags]
  );

  return (
    <FilteredTipListProvider value={context}>
      {children}
    </FilteredTipListProvider>
  );
};
