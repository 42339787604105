import { ChakraComponent, chakra } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import * as React from 'react';

export const DateDisplay: ChakraComponent<
  'time',
  { date?: Maybe<Date | string>; format?: string }
> = ({ date, format = 'dd.MM.yyyy', ...rest }) => {
  const processedDate = React.useMemo(() => {
    if (!date) {
      return null;
    }
    const parsedDate = new Date(date);
    const datetime = DateTime.fromJSDate(parsedDate);

    if (!datetime.isValid) {
      return null;
    }

    return {
      datetime: datetime.toISODate(),
      displayString: datetime.toFormat(format),
    };
  }, [date, format]);

  if (!processedDate) {
    return <chakra.time {...rest}></chakra.time>;
  }

  return (
    <chakra.time dateTime={processedDate.datetime} {...rest}>
      {processedDate.displayString}
    </chakra.time>
  );
};
