import React from 'react';
import { useTranslation } from 'react-i18next';

import { FskEnum } from '../../../../generated/feed';
import { FeedPostTags } from '../../FeedPost/FeedPost';

export const FeedPostRemappedTags: React.FC<{
  tags: string[];
  fsk?: FskEnum;
}> = ({ tags, fsk = null }) => {
  const { t } = useTranslation(['feed']);

  const modifiedTags = React.useMemo(() => {
    if (!fsk) {
      return tags;
    }
    const fskTag16 = t('feed:text.Ab', { fsk: 16 });
    const fskTag18 = t('feed:text.Ab', { fsk: 18 });
    // Replace pre-exisitng fsk tags with image specific fsk rating
    return tags.reduce((acc, currentValue) => {
      if (![fskTag16, fskTag18].includes(currentValue)) {
        return [...acc, currentValue];
      }
      return [...acc, fsk === FskEnum.Fsk18 ? fskTag18 : fskTag16];
    }, [] as string[]);
  }, [fsk, t, tags]);

  return <FeedPostTags tags={modifiedTags} />;
};
