import { Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../../../components/Layout/ResponsiveModal';
import { MediaPickerButton } from '../../../../components/shared/MediaPickerButton/MediaPickerButton';
import { TempFeedPostPhotoPostProp } from '../post/component/types';
import {
  ErrorModalContent,
  ErrorModalContentReplaceImageButtonContainer,
} from './component/ErrorModalContent';

interface PostRejectedModalProps
  extends Omit<ResponsiveModalProps, 'children'> {
  onDeletePost: () => void;
  rejectionReason?: string;
}

export const PhotoPostRejectedModal: React.FC<
  PostRejectedModalProps & TempFeedPostPhotoPostProp
> = ({
  visiblePhotoId,
  postId,
  multiPhotoPost = false,
  onDeletePost,
  onDeleteImage,
  onReplaceImage,
  rejectionReason,
  onClose,
  ...modalProps
}) => {
  const { t } = useTranslation(['feed']);

  const replaceImageButton = (
    <ErrorModalContentReplaceImageButtonContainer
      onReplaceImage={onReplaceImage}
      onClose={onClose}
      postId={postId}
      visiblePhotoId={visiblePhotoId}
    >
      <MediaPickerButton children={t('feed:button.FotoErsetzen')} />
    </ErrorModalContentReplaceImageButtonContainer>
  );
  const deleteImageButton = (
    <Button
      variant={'ghost'}
      children={t('feed:button.FotoLoschen')}
      color={'red.500'}
      onClick={() => {
        onClose();
        onDeleteImage?.();
      }}
    />
  );
  const deletePostButton = (
    <Button
      variant={'ghost'}
      children={t('feed:button.BeitragLoschen')}
      color={'red.500'}
      onClick={() => {
        onClose();
        onDeletePost();
      }}
    />
  );

  const footerContent = (
    <FeedPhotoPostRejectedModalFooter
      multiPhotoPost={multiPhotoPost}
      deletePostButton={deletePostButton}
      replaceImageButton={replaceImageButton}
      deleteImageButton={deleteImageButton}
    />
  );

  return (
    <ResponsiveModal
      preferredSize="sm"
      preferredSizeBreakpoint="sm"
      onClose={onClose}
      {...modalProps}
    >
      <ResponsiveModalOverlay />
      <ErrorModalContent
        heading={t('feed:heading.DeinBeitragMussteAbgelehntWerden')}
        text={
          rejectionReason ??
          t(
            'feed:text.DasKannVieleGrundeHabenKontaktiereDenSupportUmNachvollziehenZuKonnenXX'
          )
        }
        footerContent={footerContent}
      />
    </ResponsiveModal>
  );
};

export const FeedPhotoPostRejectedModalFooter: React.FC<{
  multiPhotoPost: boolean;
  deletePostButton: React.ReactNode;
  deleteImageButton: React.ReactNode;
  replaceImageButton: React.ReactNode;
}> = ({
  multiPhotoPost,
  replaceImageButton,
  deleteImageButton,
  deletePostButton,
}) => {
  return (
    <VStack spacing={4}>
      {replaceImageButton}
      {multiPhotoPost ? deleteImageButton : deletePostButton}
    </VStack>
  );
};

export const FeedPostRejectedModal: React.FC<PostRejectedModalProps> = ({
  onDeletePost,
  rejectionReason,
  onClose,
  ...modalProps
}) => {
  const { t } = useTranslation(['feed']);
  const deletePostButton = (
    <Button
      variant={'ghost'}
      children={t('feed:button.BeitragLoschen')}
      color={'red.500'}
      onClick={() => {
        onClose();
        onDeletePost();
      }}
    />
  );

  const footerContent = deletePostButton;

  return (
    <ResponsiveModal
      preferredSize="sm"
      preferredSizeBreakpoint="sm"
      onClose={onClose}
      {...modalProps}
    >
      <ResponsiveModalOverlay />
      <ErrorModalContent
        heading={t('feed:heading.DeinBeitragMussteAbgelehntWerden')}
        text={
          rejectionReason ??
          t(
            'feed:text.DasKannVieleGrundeHabenKontaktiereDenSupportUmNachvollziehenZuKonnenXX'
          )
        }
        footerContent={footerContent}
      />
    </ResponsiveModal>
  );
};
