import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  TextareaProps,
  forwardRef,
} from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import { useField } from 'formik';
import React from 'react';

import { EncodedControlledCharacterCount } from '../../EncodedControlledCharacterCount/EncodedControlledCharacterCount';
import {
  FormControlHeaderStack,
  TextareaControlProps,
} from '../../FormikFormElements';
import { InlineGuideDot } from '../../GuideDot/GuideDot';

export type MultiTextareaV2TabProps = {
  name: string;
  label: React.ReactNode;
  textareaProps?: TextareaProps;
};

export type MultiTextareaV2Props = TextareaControlProps & {
  label: React.ReactNode;
  helperText: React.ReactNode;
  fields: MultiTextareaV2TabProps[];
};

// Tab sidescrolling issue
// https://github.com/chakra-ui/chakra-ui/issues/1852
export const TabListWithOverflowScroll = forwardRef<TabListProps, 'div'>(
  (props, ref) => (
    <TabList
      maxW={'full'}
      boxSizing={'border-box'}
      overflowX={'scroll'}
      border={'none'}
      overflowY={'hidden'}
      // ensures propper spacing for the border-bottom of the <Tap/>
      // when it is selected
      pb={'2px'}
      bg={'transparent'}
      sx={{
        scrollbarWith: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      {...props}
    />
  )
);

const MultiLangTab: React.FC<{ name: string; label: React.ReactNode }> = ({
  name,
  label,
}) => {
  const [, { error, touched: isTouched }] = useField(name);

  return (
    <Tab>
      <span>{label}</span>
      {error && isTouched && (
        <span>
          <InlineGuideDot bg={'error.500'} size={2.5} />
        </span>
      )}
    </Tab>
  );
};

const MultiLangTabPanel: React.FC<{
  name: string;
  textAriaProps?: TextareaProps;
  maxCharacterCount?: number;
}> = ({ name, textAriaProps, maxCharacterCount }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>();

  const [field, { error, touched: isTouched }] = useField(name);

  React.useLayoutEffect(() => {
    const adjustTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };
    textareaRef.current?.addEventListener('input', adjustTextareaHeight);
  }, [textareaRef]);

  const isInvalid = !!error && isTouched;

  return (
    <TabPanel p={0} minH={'8'}>
      <FormControl isInvalid={isInvalid}>
        <Textarea
          {...field}
          borderTopRadius={0}
          rows={3}
          resize={'none'}
          {...textAriaProps}
          ref={mergeRefs(textareaRef)}
        />
        <HStack minH={'8'}>
          <Box flexGrow={1}>
            {!!error && isTouched && (
              <FormErrorMessage>{(error as any) ?? ''}</FormErrorMessage>
            )}
          </Box>
          <EncodedControlledCharacterCount
            value={field?.value}
            maxCharacterCount={maxCharacterCount}
          />
        </HStack>
      </FormControl>
    </TabPanel>
  );
};

export const MultiTextareaV2: React.FC<MultiTextareaV2Props> = (props) => {
  const { fields, helperText, label, maxCharacterCount } = props;

  return (
    <FormControl>
      <FormControlHeaderStack>
        {label && <FormLabel>{label}</FormLabel>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControlHeaderStack>
      <Tabs isFitted maxW={'full'}>
        <TabListWithOverflowScroll>
          {fields.map((tab) => (
            <MultiLangTab key={tab.name} name={tab.name} label={tab.label} />
          ))}
        </TabListWithOverflowScroll>
        <TabPanels>
          {fields.map((tab) => (
            <MultiLangTabPanel
              key={tab.name}
              name={tab.name}
              maxCharacterCount={maxCharacterCount}
              textAriaProps={tab.textareaProps}
            />
          ))}
        </TabPanels>
      </Tabs>
    </FormControl>
  );
};
