import { NetworkStatus } from '@apollo/client';
import {
  Box,
  Button,
  Heading,
  HeadingProps,
  Image,
  Link,
  ModalHeader,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import UpdateDataDefaultImage from '../assets/images/shared/update-data-approved-all.svg';
import VXCashVisual from '../assets/images/shared/visuals-vxcash.webp';
import {
  PrimaryButton,
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
  SecondaryButton,
} from '../components/Layout/ResponsiveModal';
import { ErrorMessageWithLeadingIcon } from '../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { CheckboxSingleControl } from '../components/shared/FormikFormElements';
import Markdown from '../components/shared/Markdown/Markdown';
import {
  GetTermsAcceptDatesDocument,
  Maybe,
  SecurityRoleEnum,
  TermsAcceptanceTypeEnum,
  useAcceptTermsMutation,
  useCreateWebmasterMutation,
  useGetTermsAcceptDatesQuery,
} from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import { useTermMarkdown } from '../hooks/useTermMarkdown';
import LoadingPage from '../pages/LoadingPage/LoadingPage';
import { externalRoutes } from '../routes/routesConfig';
import { noop } from '../utils';
import { useAuth } from './AuthProvider';
import { useCookieConsent } from './CookieConsentProvider';

enum AdditionalTermsAcceptanceTypeEnum {
  VXCashTOSandPrivacy,
}

function LegalUpdateMustBeAcceptedErrorMessage() {
  const { t } = useTranslation(['legal']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t(
        'legal:error.AkzeptiereDieAnderungenUmVXModelsWeiterNutzenZuKonnen'
      )}
    />
  );
}

const LegalUpdateIllustration: React.FC<{
  src: string;
}> = (props) => {
  const { t } = useTranslation(['legal']);
  return (
    <Image
      alt={t('legal:imgAlt.ModelUberpruftAnderungen')}
      objectFit={'cover'}
      h={'160px'}
      {...props}
    />
  );
};

const LegalUpdateModalHeading: React.FC<HeadingProps> = (props) => (
  <Heading size={'xl'} textAlign={'center'} {...props} />
);

const LegalUpdateTermsAndConditionsModal: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const type = TermsAcceptanceTypeEnum.VxmodelsTermsAndConditions;
  const termsCtx = useTerms();
  const { t } = useTranslation(['legal']);
  const { markdownString, loading, action } = useTermMarkdown({
    type,
  });

  const disc = useDisclosure();
  const downloadPdfButtonRef = React.useRef(null);

  return (
    <Button
      variant={'link'}
      onClick={disc.onOpen}
      isLoading={loading}
      minW={'unset'}
    >
      {children}
      <ResponsiveModal
        isOpen={disc.isOpen}
        onClose={disc.onClose}
        initialFocusRef={downloadPdfButtonRef}
      >
        <ResponsiveModalOverlay bg={'blackAlpha.700'} />
        <ResponsiveModalContent>
          <ResponsiveModalStickyHeaderBox>
            <ModalHeader>
              <Heading
                size={'xl'}
                children={t('legal:heading.AllgemeineGeschaftsbedingungen')}
              />
              <ResponsiveModalCloseButton />
            </ModalHeader>
          </ResponsiveModalStickyHeaderBox>
          <ResponsiveModalBodyBox>
            <Markdown markdown={markdownString} />
          </ResponsiveModalBodyBox>
          <ResponsiveModalStickyFooterBox>
            <VStack p={4} minW={'48'}>
              <PrimaryButton
                minW={'48'}
                onClick={() => termsCtx.action.acceptTerm(type)}
                isLoading={termsCtx.loading || termsCtx.isAcceptMutationRunning}
              >
                {t('legal:button.JetztBestatigen')}
              </PrimaryButton>
              <SecondaryButton
                minW={'48'}
                onClick={() => action.openPdfInNewWindow()}
                ref={downloadPdfButtonRef}
              >
                {t('legal:button.AlsPDFDownloaden')}
              </SecondaryButton>
            </VStack>
          </ResponsiveModalStickyFooterBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </Button>
  );
};

const LegalUpdatePrivacyPolicyModalContent: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const type = TermsAcceptanceTypeEnum.VxmodelsPrivacyPolicy;
  const termsCtx = useTerms();
  const { t } = useTranslation(['legal']);
  const { markdownString, loading, action } = useTermMarkdown({
    type,
  });
  const cookieConsentCtx = useCookieConsent();
  const disc = useDisclosure();
  const downloadPdfButtonRef = React.useRef(null);

  return (
    <Button
      variant={'link'}
      onClick={disc.onOpen}
      isLoading={loading}
      minW={'unset'}
    >
      {children}
      <ResponsiveModal
        isOpen={disc.isOpen}
        onClose={disc.onClose}
        initialFocusRef={downloadPdfButtonRef}
      >
        <ResponsiveModalOverlay />
        <ResponsiveModalContent>
          <ResponsiveModalStickyHeaderBox>
            <ModalHeader>
              <Heading size={'xl'} children={t('legal:heading.Datenschutz')} />
              <ResponsiveModalCloseButton />
            </ModalHeader>
          </ResponsiveModalStickyHeaderBox>
          <ResponsiveModalBodyBox>
            <Markdown
              markdown={markdownString}
              linkFunctionRegister={{
                gaOptout: () => cookieConsentCtx.action.decline('analytics'),
              }}
            />
          </ResponsiveModalBodyBox>
          <ResponsiveModalStickyFooterBox>
            <VStack p={4} minW={'48'}>
              <PrimaryButton
                minW={'48'}
                onClick={() => termsCtx.action.acceptTerm(type)}
                isLoading={termsCtx.loading || termsCtx.isAcceptMutationRunning}
              >
                {t('legal:button.JetztBestatigen')}
              </PrimaryButton>
              <SecondaryButton
                minW={'48'}
                onClick={() => action.openPdfInNewWindow()}
                ref={downloadPdfButtonRef}
              >
                {t('legal:button.AlsPDFDownloaden')}
              </SecondaryButton>
            </VStack>
          </ResponsiveModalStickyFooterBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </Button>
  );
};

const EnforceLegalUpdateUserConsentModal: React.FC<{
  termsType: TermsAcceptanceTypeEnum | AdditionalTermsAcceptanceTypeEnum;
  heading: React.ReactNode;
  description?: React.ReactNode;
  checkBoxLabel: React.ReactNode;
  buttonText?: string;
}> = ({ termsType, heading, description, checkBoxLabel, buttonText }) => {
  const termsCtx = useTerms();
  const { t } = useTranslation(['legal']);

  // Switch up the used name, so the form resets itself on changed termsType
  const name = `accept ${termsType}`;
  const formik = useFormik({
    initialValues: { [name]: false },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      [name]: Yup.bool().oneOf([true], () => (
        <LegalUpdateMustBeAcceptedErrorMessage />
      )),
    }),
    onSubmit: () => {
      if (termsType === AdditionalTermsAcceptanceTypeEnum.VXCashTOSandPrivacy) {
        termsCtx.action.createWebmasterId();
      } else {
        termsCtx.action.acceptTerm(termsType);
      }
    },
  });

  return (
    <ResponsiveModal
      isOpen={true}
      // can not be closed to enforce legal update acceptance
      onClose={noop}
      closeOnOverlayClick={false}
      trapFocus={true}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalBodyBox
          as={VStack}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <VStack spacing={'6'}>
            <LegalUpdateIllustration
              src={
                termsType ===
                AdditionalTermsAcceptanceTypeEnum.VXCashTOSandPrivacy
                  ? VXCashVisual
                  : UpdateDataDefaultImage
              }
            />
            <VStack spacing={0}>
              <LegalUpdateModalHeading>{heading}</LegalUpdateModalHeading>
              {!!description && description}
              <FormikProvider value={formik}>
                <CheckboxSingleControl
                  label={checkBoxLabel}
                  name={name}
                  w={'unset'}
                />
              </FormikProvider>
            </VStack>
          </VStack>
          <VStack p={4}>
            <PrimaryButton
              minW={'48'}
              onClick={formik.submitForm}
              isDisabled={!formik.values[name]}
              isLoading={
                termsCtx.loading ||
                termsCtx.isAcceptMutationRunning ||
                termsCtx.isCreateWebmasterRunning
              }
            >
              {buttonText || t('legal:button.JetztBestatigen')}
            </PrimaryButton>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const LegalUpdateTermsAndConditionsCheckboxLabel = () => {
  const { t } = useTranslation(['legal']);
  return (
    <Trans
      t={t}
      i18nKey={'legal:label.IchErklareMichMitDenGeandertenAGBEinverstanden'}
      components={{
        legalTerm: <LegalUpdateTermsAndConditionsModal />,
      }}
    />
  );
};

const LegalUpdatePrivacyPolicyCheckboxLabel = () => {
  const { t } = useTranslation(['legal']);
  return (
    <Trans
      t={t}
      i18nKey={'legal:label.IchErklareMichMitDerGeandertenDSEEinverstanden'}
      components={{
        legalTerm: <LegalUpdatePrivacyPolicyModalContent />,
      }}
    />
  );
};

const LegalUpdateVXCashCheckboxLabel = () => {
  const { t } = useTranslation(['legal']);
  return (
    <Trans
      style={{ lineHeight: 1 }}
      t={t}
      i18nKey={
        'legal:label.IchHabeDieAGBUndDatenschutzerklarungVonVXCashZurKenntnisGenommen'
      }
      components={{
        vxcashTerms: (
          <Link
            href={externalRoutes.vxcashTerms()}
            rel="noreferrer"
            target="_blank"
          />
        ),
        vxcashPrivacy: (
          <Link
            href={externalRoutes.vxcashPrivacy()}
            rel="noreferrer"
            target="_blank"
          />
        ),
      }}
    />
  );
};

const FlowLegalReq: React.FC<{
  type: Maybe<TermsAcceptanceTypeEnum | AdditionalTermsAcceptanceTypeEnum>;
}> = ({ type }) => {
  const { t } = useTranslation(['legal']);
  switch (type) {
    case TermsAcceptanceTypeEnum.VxmodelsTermsAndConditions:
      return (
        <EnforceLegalUpdateUserConsentModal
          termsType={type}
          heading={t('legal:heading.WirHabenUnsereAGBAngepasst')}
          checkBoxLabel={<LegalUpdateTermsAndConditionsCheckboxLabel />}
        />
      );
    case TermsAcceptanceTypeEnum.VxmodelsPrivacyPolicy:
      return (
        <EnforceLegalUpdateUserConsentModal
          termsType={type}
          heading={t('legal:heading.WirHabenUnsereDSEAngepasst')}
          checkBoxLabel={<LegalUpdatePrivacyPolicyCheckboxLabel />}
        />
      );
    case AdditionalTermsAcceptanceTypeEnum.VXCashTOSandPrivacy:
      return (
        <EnforceLegalUpdateUserConsentModal
          termsType={type}
          heading={t('legal:heading.NeueVerdienstmoglichkeit')}
          description={
            <Box sx={{ my: 5 }}>
              <Trans
                t={t}
                i18nKey={
                  'legal:text.WirFreuenUnsDirMitteilenZuKonnenDassDuAbSofortAnUnseremPartnerprograXX'
                }
                components={{
                  vxcash: (
                    <Link
                      href={externalRoutes.vxcash()}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              />
            </Box>
          }
          checkBoxLabel={<LegalUpdateVXCashCheckboxLabel />}
          buttonText={t('legal:button.VXCashAktivieren')}
        />
      );
    default:
      return null;
  }
};

interface TermsContext {
  loading: boolean;
  isAcceptMutationRunning: boolean;
  isCreateWebmasterRunning: boolean;
  action: {
    acceptTerm: (type: TermsAcceptanceTypeEnum) => Promise<void>;
    createWebmasterId: () => Promise<void>;
  };
}
export const [, useTerms, termsContext] = createContext<TermsContext>({
  name: 'termsContext',
});
export const TermsProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const authCtx = useAuth();
  const { data, loading, networkStatus } = useGetTermsAcceptDatesQuery({
    skip: !authCtx.isAuthenticated,
    notifyOnNetworkStatusChange: true,
  });

  const isInitialyLoading =
    authCtx.isAuthenticated && networkStatus === NetworkStatus.loading;

  const [acceptTermsMutation, { loading: isAcceptMutationRunning }] =
    useAcceptTermsMutation({ refetchQueries: [GetTermsAcceptDatesDocument] });
  const [createWebmasterMutation, { loading: isCreateWebmasterRunning }] =
    useCreateWebmasterMutation({
      refetchQueries: [GetTermsAcceptDatesDocument],
    });

  const acceptTerm = React.useCallback(
    async (type: TermsAcceptanceTypeEnum) => {
      await acceptTermsMutation({
        variables: {
          type,
        },
      });
    },
    [acceptTermsMutation]
  );

  const createWebmasterId = React.useCallback(async () => {
    await createWebmasterMutation();
  }, [createWebmasterMutation]);

  const action = React.useMemo(() => {
    return {
      acceptTerm,
      createWebmasterId,
    };
  }, [acceptTerm, createWebmasterId]);

  const mappedData = React.useMemo(() => {
    // Only show the modal if model has no webmaster id and does not belong to a studio
    const showVXCashModal =
      !data?.account?.hasWebmasterId && !data?.account?.isStudio;

    // Prio: AGB, DSE, Hausordnung
    // Order of this array also defines their priority (first (highest) - last (lowest))
    const terms = [
      // AGB Allgemeine Geschäftsbedingungen
      data?.terms.termsAndConditions,
      // DSE Datenschutzerklärung
      data?.terms.privacyPolicy,
      {
        type: AdditionalTermsAcceptanceTypeEnum.VXCashTOSandPrivacy,
        hasAccepted: !showVXCashModal,
        hasAcceptedAnyVersion: !showVXCashModal,
        consentRequired: !showVXCashModal,
      },
    ];

    return {
      currentTypeToShow:
        terms?.filter((term) => !term?.hasAccepted)?.[0]?.type ?? null,
      isUpdatedTermAcceptanceRequired:
        terms.some((term) => !term?.hasAccepted && term?.consentRequired) &&
        // Only show the terms modal if the user is not an admin
        !authCtx.authUser?.roles.includes(SecurityRoleEnum.VxUserAdmin),
      terms,
    };
  }, [
    authCtx.authUser?.roles,
    data?.account?.hasWebmasterId,
    data?.account?.isStudio,
    data?.terms.privacyPolicy,
    data?.terms.termsAndConditions,
  ]);

  const context = React.useMemo(() => {
    return {
      loading,
      isAcceptMutationRunning,
      isCreateWebmasterRunning,
      ...mappedData,
      action,
    };
  }, [
    mappedData,
    loading,
    isAcceptMutationRunning,
    isCreateWebmasterRunning,
    action,
  ]);

  const isTermUpdateConsentRequiredToProceed =
    authCtx.isAuthenticated && context.isUpdatedTermAcceptanceRequired;

  if (isInitialyLoading) {
    return <LoadingPage />;
  }

  return (
    <termsContext.Provider value={context}>
      {/* only render children if no consent is required */}
      {props.children}
      {!isTermUpdateConsentRequiredToProceed ? null : (
        <FlowLegalReq type={mappedData.currentTypeToShow ?? null} />
      )}
    </termsContext.Provider>
  );
};
