import * as icons from '@campoint/odi-ui-icons';
import { Icon, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type StatisikType = 'Likes' | 'Tips';

export const NoInteractionList: React.FC<{ type: StatisikType }> = ({
  type,
}) => {
  const { t } = useTranslation(['feed']);
  return (
    <EmptyState
      children={t('feed:postStatistic.NochKeineTypeErhalten', { type })}
    />
  );
};

const EmptyState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <VStack pt={'12'} spacing={'4'} color={'steel'}>
      <Icon as={icons.Info} boxSize={'6'} />
      <Text
        maxW={'64'}
        fontSize={'2xl'}
        fontWeight={'bold'}
        textAlign={'center'}
        children={children}
      />
    </VStack>
  );
};

export const NoSalesList: React.FC = () => {
  const { t } = useTranslation(['feed']);
  return (
    <EmptyState children={t('feed:postStatistic.NochKeineVerkaufeVorhanden')} />
  );
};
