import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { ActorFragment } from '../../../../../generated/graphql';
import useHistoryWithStorage from '../../../../../hooks/useHistoryWithStorage';
import { routes } from '../../../../../routes/routesConfig';
import { extractDisplayNameFromAddress } from '../../../utils/helper';
import {
  RealPersonListItem,
  RealPersonListItemProps,
} from '../RealPersonListItem/RealPersonListItem';

export const ActorListItem: React.FC<
  RealPersonListItemProps & { actor: ActorFragment }
> = ({ actor, ...realPersonListItemProps }) => {
  const { t } = useTranslation(['realPerson']);
  const history = useHistoryWithStorage();
  const userId = actor.userId.toString();
  const intl = useIntl();
  const name = React.useMemo(() => {
    return extractDisplayNameFromAddress(actor?.address);
  }, [actor]);
  const description = React.useMemo(() => {
    const descriptionEntries = [
      actor.isLeadActor
        ? t('realPerson:text.Model')
        : t('realPerson:text.Drehpartner'),
    ];
    return intl.formatList(descriptionEntries, {
      style: 'short',
      type: 'conjunction',
    });
  }, [intl, t, actor.isLeadActor]);
  return (
    <RealPersonListItem
      onClick={() => {
        history.push(
          routes.documentsActorsDetail.path.replace(':userId', userId)
        );
      }}
      name={name}
      status={actor.personalDocumentsStatus}
      description={description}
      {...realPersonListItemProps}
    />
  );
};
