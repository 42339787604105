import { AspectRatio, Box, ContainerProps, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FeedPost,
  FeedPostBody,
  FeedPostButtonWrapper,
  FeedPostBuyButton,
  FeedPostCenteredButtons,
  FeedPostDataText,
  FeedPostDate,
  FeedPostHeader,
  FeedPostHeaderDataRow,
  FeedPostHeaderStack,
  FeedPostHeaderTitle,
  FeedPostMenu,
  FeedPostModelImage,
  FeedPostPin,
} from '../../../../../components/shared/FeedPost/FeedPost';
import { ScheduledForDateTimeBatch } from '../../../../../components/shared/ScheduledForDateTimeBatch/ScheduledForDateTimeBatch';
import {
  FeedPhotoMediaPictureFragment,
  FeedPhotoPostFragment,
  PostStatusEnum,
  PostTypeEnum,
} from '../../../../../generated/feed';
import {
  MediaContestTypeEnum,
  useGetContestDataQuery,
} from '../../../../../generated/graphql';
import { useFeedPostUploadProgress } from '../../../hooks/useFeedPostUploadProgress';
import { usePhotoPostWrapper } from '../../../hooks/useFeedPostWrapper';
import { PostFunctionProps } from '../../../posts';
import { avatarURL, useGetRejectionReason } from '../../../utils';
import { PostFooter } from './PostFooter';
import { FeedPostBrokenInlineAlert } from './PostInlineAlert';
import { TempFeedPostScheduleProp } from './types';

export const PhotoPostBaseStructure: React.FC<
  {
    containerProps?: ContainerProps;
    children?: React.ReactNode;
    tags: string[];
    formattedPrice: string;
    isPostOwnedByUser: boolean;
    visiblePhoto?: FeedPhotoMediaPictureFragment;
    isEditing?: boolean;
    setImageIndex?: () => void;
  } & TempFeedPostScheduleProp &
    FeedPhotoPostFragment &
    PostFunctionProps
> = ({
  id,
  status,
  model,
  created,
  pinned,
  price,
  priceCoins,
  formattedPrice,
  title,
  text,
  likes,
  comments,
  tips,
  purchases,
  type,
  photos,
  isPublished,
  publicationScheduledFor,
  tippingGoal,
  onClickOnPin,
  onClickOnLikes,
  onClickOnTips,
  onClickOnEdit,
  onClickOnDelete,
  onClickOnDeleteImage,
  onClickOnReplaceImage,
  children,
  containerProps,
  isPostOwnedByUser,
  visiblePhoto,
  setImageIndex,
  contest,
  isEditing,
}) => {
  const { t } = useTranslation(['feed']);
  const isFeaturedPost = type === PostTypeEnum.FpPhotoGallery;
  const isContestPost = type === PostTypeEnum.CpPhotoContest;

  const { data: photoContestData } = useGetContestDataQuery({
    variables: {
      contestId: Number(contest?.id),
      type: MediaContestTypeEnum.Photo,
    },
    skip: !isContestPost || !contest?.id,
  });

  const photoContest = React.useMemo(() => {
    return photoContestData?.media.contest;
  }, [photoContestData?.media.contest]);

  const uploadProgressElement = useFeedPostUploadProgress({
    status,
    postId: id,
    isEditing,
  });
  const multiPhotoPost = (photos?.length ?? 0) > 1;
  const rejectionReason = useGetRejectionReason(
    visiblePhoto?.deRejection,
    visiblePhoto?.enRejection
  );

  const isPinnable = status !== PostStatusEnum.Broken;
  const isEditable = status !== PostStatusEnum.Broken;
  //Info: for contest posts there are 3 states after uploading:
  //1. scheduled (voting not started),
  //2. published (voting started, live on vx),
  //3. voting ended
  const isScheduled = status === PostStatusEnum.Scheduled;

  const handelDeleteImage = () => {
    if (visiblePhoto?.id) {
      onClickOnDeleteImage(visiblePhoto.id, id);
      setImageIndex?.();
    }
  };

  const feedPostHeaderTitle = React.useMemo(() => {
    if (isFeaturedPost) return t('feed:label.FeaturedPostAvatar') + model.name;
    if (contest) return t('feed:label.AnWettbewerbTeilgenommen');
    return model.name;
  }, [contest, isFeaturedPost, model.name, t]);

  const { highlightColor, labelElement } = usePhotoPostWrapper({
    status,
    rejectionReason: rejectionReason,
    isFeatured: isFeaturedPost,
    isScheduled,
    onDeletePost: () => onClickOnDelete(id),
    onDeleteImage: handelDeleteImage,
    onReplaceImage: onClickOnReplaceImage,
    multiPhotoPost,
    visiblePhotoId: visiblePhoto?.id,
    contest: photoContest,
  });

  return (
    <FeedPost
      postId={id}
      highlightColor={highlightColor}
      labelElement={labelElement}
      {...containerProps}
    >
      <FeedPostHeader borderRadius={isFeaturedPost ? '8px 8px 0 0' : '0'}>
        {!contest ? (
          <FeedPostModelImage
            src={avatarURL(isFeaturedPost, model.avatar?.url)}
            srcSet={isFeaturedPost ? undefined : model.avatar?.srcSet}
          />
        ) : (
          <Text alignSelf={'start'}>📸</Text>
        )}
        <FeedPostHeaderStack>
          <FeedPostHeaderTitle children={feedPostHeaderTitle} />
          <FeedPostHeaderDataRow>
            {isScheduled && !!publicationScheduledFor ? (
              <ScheduledForDateTimeBatch dateTime={publicationScheduledFor} />
            ) : (
              <>
                <FeedPostDate children={created} />
                {multiPhotoPost && (
                  <FeedPostDataText
                    children={t('feed:text.Bilder', {
                      pictures: photos?.length,
                    })}
                  />
                )}
              </>
            )}
          </FeedPostHeaderDataRow>
        </FeedPostHeaderStack>
        {isPostOwnedByUser && (
          <FeedPostButtonWrapper>
            {!isPinnable ? (
              <></>
            ) : (
              <FeedPostPin
                isPinned={pinned}
                onClickOnPin={() => onClickOnPin(id, pinned)}
              />
            )}
            {!contest && (
              <FeedPostMenu
                onClickOnEdit={!isEditable ? null : onClickOnEdit}
                onClickOnDelete={() => onClickOnDelete(id)}
              />
            )}
          </FeedPostButtonWrapper>
        )}
      </FeedPostHeader>
      <FeedPostBody bgColor={!!uploadProgressElement ? 'steel' : 'steel'}>
        {status === PostStatusEnum.Broken ? (
          <FeedPostBrokenInlineAlert onDeletePost={() => onClickOnDelete(id)} />
        ) : (
          <AspectRatio
            ratio={4 / 5}
            maxH={600}
            /*
             * Workaround because the automatically generated before element
             * has a padding-bottom of 125% which blocks any element under it.
             */
            _before={{
              display: 'block',
              content: '""',
              height: 0,
              paddingBottom: '75%',
            }}
          >
            {uploadProgressElement ? (
              <Box>{uploadProgressElement}</Box>
            ) : (
              <Box>
                {children}
                {(price || priceCoins) && (
                  <FeedPostCenteredButtons>
                    {!rejectionReason && (
                      <FeedPostBuyButton
                        formattedPrice={formattedPrice}
                        priceCoins={priceCoins}
                      />
                    )}
                  </FeedPostCenteredButtons>
                )}
              </Box>
            )}
          </AspectRatio>
        )}
      </FeedPostBody>
      <PostFooter
        isPhotoPost={true}
        multiPhotoPost={multiPhotoPost}
        postId={id}
        visiblePhoto={visiblePhoto}
        status={status}
        likes={likes}
        comments={comments.totalCount}
        tips={tips.count}
        price={price}
        priceCoins={priceCoins}
        isPublished={isPublished}
        publicationScheduledFor={publicationScheduledFor}
        purchaseCount={purchases?.count ?? 0}
        tippingGoal={tippingGoal}
        onClickOnLikes={onClickOnLikes}
        onClickOnTips={onClickOnTips}
        onDeletePost={() => onClickOnDelete(id)}
        onDeleteImage={handelDeleteImage}
        contest={photoContest}
        onReplaceImage={onClickOnReplaceImage}
        headline={title}
        text={text}
        type={type}
        borderRadius={isFeaturedPost ? '0 0 8px 8px' : '0'}
        rejectionReason={rejectionReason}
      />
    </FeedPost>
  );
};
