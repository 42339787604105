import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import ENV from '../environments/environment';
import {
  GetOnboardingTaskStatusProviderQuery,
  ModelVerificationStatusEnum,
  ProfileStatusEnum,
  useGetEmailVerificationStatusLazyQuery,
  useGetOnboardingTaskStatusProviderLazyQuery,
} from '../generated/graphql';
import { noop } from '../utils';
import { secondsInMs } from '../utils/utils';
import { useAuth } from './AuthProvider';

const allPossibleValues = [
  'ageVerification',
  'modelProfile',
  'canGoOnline',
] as const;
type Values = (typeof allPossibleValues)[number];

const allPossibleStatus = ['accepted', 'rejected'] as const;
type Status = (typeof allPossibleStatus)[number];
type Difference = {
  name: Values;
  status: Status | null;
  previousStatus: Status | null;
};
type ToastTextRecord = Record<
  Values,
  { accepted: string; rejected: string | null }
>;
export type OnboardingTaskStatusRecord = Record<Values, Status | null>;

const initOnboardingTaskStatusValues: OnboardingTaskStatusRecord = {
  ageVerification: null,
  modelProfile: null,
  canGoOnline: null,
};

export type OnboardingTaskStatusContext = {
  actions: {
    setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
    refetchEmailVerificationStatus: () => void;
  };
  differences: Difference[];
  isEmailVerified: boolean;
  emailVerificationCheckLoading: boolean;
  status: OnboardingTaskStatusRecord;
};

export const initOnboardingTaskStatusContext: OnboardingTaskStatusContext = {
  actions: { setIsEmailVerified: noop, refetchEmailVerificationStatus: noop },
  differences: [],
  isEmailVerified: false,
  emailVerificationCheckLoading: false,
  status: initOnboardingTaskStatusValues,
};

export const onboardingTaskStatusContext =
  React.createContext<OnboardingTaskStatusContext>(
    initOnboardingTaskStatusContext
  );

export const OnboardingTaskStatusProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation(['onboardingTaskStatus']);
  const { isAuthenticated } = useAuth();

  const isContextWrittenOnce = useRef(false);

  const {
    isEmailVerified,
    actions: emailVerificationActions,
    emailVerificationCheckLoading,
  } = useEmailVerificationStatus();
  const { data, stopPolling } = useOnboardingStatus();

  const [context, setContext] = React.useState<OnboardingTaskStatusContext>({
    ...initOnboardingTaskStatusContext,
    actions: {
      setIsEmailVerified: emailVerificationActions.setIsEmailVerified,
      refetchEmailVerificationStatus:
        emailVerificationActions.refetchEmailVerificationStatus,
    },
  });

  const toasts = React.useMemo<ToastTextRecord>(
    () => ({
      ageVerification: {
        accepted: t(
          'onboardingTaskStatus:toast.AltersverifikationWurdeAkzeptiert'
        ),
        rejected: t(
          'onboardingTaskStatus:toast.AltersverifikationWurdeAbgelehnt'
        ),
      },
      canGoOnline: {
        accepted: t('onboardingTaskStatus:toast.DuBistBereitOnlineZuGehen'),
        rejected: null,
      },
      modelProfile: {
        accepted: t('onboardingTaskStatus:toast.ProfilWurdeAkzeptiert'),
        rejected: t('onboardingTaskStatus:toast.ProfilWurdeAbgelehnt'),
      },
    }),
    [t]
  );

  React.useEffect(() => {
    setContext((ctx) => ({
      ...ctx,
      isEmailVerified,
      emailVerificationCheckLoading,
    }));
  }, [isEmailVerified, emailVerificationCheckLoading]);

  const { status: oldRecord } = context;
  React.useEffect(() => {
    if (!data || !data.tour || !isAuthenticated) {
      return;
    }

    const newRecord = mapOnboardingStatusRecord(data);

    if (newRecord.canGoOnline === 'accepted') {
      stopPolling();
    }

    // Treat the first response as actual initializer
    if (!isContextWrittenOnce.current) {
      isContextWrittenOnce.current = true;
      setContext((ctx) => ({
        ...ctx,
        status: newRecord,
        differences: [],
      }));
      return;
    }
    const differences = extractContextDifferences(oldRecord, newRecord);
    if (differences.length === 0) {
      return;
    }

    setContext((ctx) => ({
      ...ctx,
      status: newRecord,
      differences,
    }));

    for (const { name, status } of differences) {
      if (!status) {
        continue;
      }
      const message = toasts[name][status];
      if (message) {
        issueChakraToast({
          description: message,
          status: status === 'rejected' ? 'error' : 'success',
        });
      }
    }
  }, [
    oldRecord,
    setContext,
    data,
    toasts,
    stopPolling,
    isAuthenticated,
    isEmailVerified,
  ]);

  return (
    <onboardingTaskStatusContext.Provider value={context}>
      {children}
    </onboardingTaskStatusContext.Provider>
  );
};

export const OnboardingTaskStatusMockProvider: React.FC<{
  context: OnboardingTaskStatusContext;
  children?: React.ReactNode;
}> = ({ context, children }) => {
  return (
    <onboardingTaskStatusContext.Provider value={context}>
      {children}
    </onboardingTaskStatusContext.Provider>
  );
};

export function useOnboardingTaskStatusContext() {
  return React.useContext(onboardingTaskStatusContext);
}

function mapOnboardingStatusRecord(
  result: GetOnboardingTaskStatusProviderQuery
): OnboardingTaskStatusRecord {
  return {
    ageVerification: extractAgeOnboardingStatus(result),
    modelProfile: extractModelProfile(result),
    canGoOnline: extractCanGoOnline(result),
  };
}

function extractContextDifferences(
  a: OnboardingTaskStatusRecord,
  b: OnboardingTaskStatusRecord
) {
  return allPossibleValues.reduce<Difference[]>((acc, property) => {
    return a[property] === b[property]
      ? acc
      : [
          ...acc,
          { name: property, status: b[property], previousStatus: a[property] },
        ];
  }, []);
}

function extractAgeOnboardingStatus(
  data: GetOnboardingTaskStatusProviderQuery
) {
  switch (data?.account?.modelVerificationStatus) {
    case ModelVerificationStatusEnum.Accepted:
      return 'accepted';
    case ModelVerificationStatusEnum.Rejected:
      return 'rejected';
    default:
      return null;
  }
}

function extractModelProfile(
  data: GetOnboardingTaskStatusProviderQuery
): 'accepted' | 'rejected' | null {
  const tour = extractTour(data);
  switch (tour?.status) {
    case ProfileStatusEnum.Accepted:
      return 'accepted';
    case ProfileStatusEnum.Rejected:
      return 'rejected';
    default:
      return null;
  }
}

function extractCanGoOnline(
  data: GetOnboardingTaskStatusProviderQuery
): 'accepted' | 'rejected' | null {
  const ageOnboarding = extractAgeOnboardingStatus(data);
  const tour = extractTour(data);
  if (typeof tour?.lastVerified === 'string' && ageOnboarding === 'accepted') {
    return 'accepted';
  }

  return null;
}

function extractTour(result: GetOnboardingTaskStatusProviderQuery) {
  const tour = result.tour;
  return tour.__typename === 'TourOnboardingV1' ? tour : null;
}

type UseEmailVerificationStatus = {
  isEmailVerified: boolean;
  emailVerificationCheckLoading: boolean;
  actions: {
    setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
    refetchEmailVerificationStatus: () => void;
  };
};

const useEmailVerificationStatus = (): UseEmailVerificationStatus => {
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const { isAuthenticated } = useAuth();

  const [checkEmailVerification, { data, error, loading, refetch }] =
    useGetEmailVerificationStatusLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const refetchEmailVerificationStatus = () => refetch();

  // Check the email status every time the Authenticated status changes. This status must be reset for each user.
  React.useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    checkEmailVerification();
  }, [isAuthenticated, checkEmailVerification]);

  // Setting the email status depending on the requested data
  React.useEffect(() => {
    if (loading) {
      return;
    }

    if (!data) {
      setIsEmailVerified(false);
      return;
    }

    setIsEmailVerified(data.account.isRegistrationComplete === true);
  }, [data, loading]);

  // If an error occurs, the email verification status must be set to false again, because at this point it cannot be ensured whether the email has been verified or not.
  React.useEffect(() => {
    if (!error) {
      return;
    }
    setIsEmailVerified(false);
  }, [error]);

  return {
    isEmailVerified: isEmailVerified,
    actions: { setIsEmailVerified, refetchEmailVerificationStatus },
    emailVerificationCheckLoading: loading,
  };
};

function useOnboardingStatus() {
  const [data, setData] = React.useState<
    GetOnboardingTaskStatusProviderQuery | undefined
  >(undefined);

  const [performGetOnboardingStatus, { stopPolling: stopQueryPolling }] =
    useGetOnboardingTaskStatusProviderLazyQuery({
      // https://github.com/apollographql/apollo-client/issues/5531
      notifyOnNetworkStatusChange: true,
      onCompleted: (queryData) => {
        setData(queryData);
      },
      pollInterval: secondsInMs(30),
      fetchPolicy: 'no-cache',
    });

  const { isAuthenticated } = useAuth();

  const stopPolling = React.useCallback(() => {
    stopQueryPolling();
  }, [stopQueryPolling]);

  const resetData = React.useCallback(() => {
    setData(undefined);
  }, [setData]);

  const startPolling = React.useCallback(() => {
    // When the polling is started, it must be ensured that no old data is stored anymore
    resetData();
    performGetOnboardingStatus();
  }, [performGetOnboardingStatus, resetData]);

  React.useEffect(() => {
    if (isAuthenticated) {
      if (ENV.ONBOARDING_TASK_STATUS_POLLING_IS_DISABLED) {
        return;
      }
      startPolling();
    }

    if (!isAuthenticated) {
      stopPolling();
      resetData();
    }
  }, [stopPolling, isAuthenticated, resetData, startPolling]);

  return { data, stopPolling };
}
