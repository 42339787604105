import {
  Box,
  Container,
  ContainerProps,
  Modal,
  ModalContent,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  FeedPhotoMediaPictureFragment,
  FskEnum,
} from '../../../generated/feed';
import { MediaPictureStatusEnum } from '../../../generated/graphql';
import { AbsoluteFullCenter } from '../../Layout/AbsoluteFullCenter';
import { ImageStatus } from '../ImageStatus/ImageStatus';
import { FeedPostRemappedTags } from './components/FeedPostRemappedTags';
import { ImageContent } from './components/ImageContent';
import { Navigation } from './components/Navigation';

export const ImageGallery: React.FC<
  {
    images: {
      __typename?: 'MediaPicture' | undefined;
      url: string;
      fsk: FskEnum;
      srcSet: string;
    }[];
    isDimmedDown?: boolean;
    tags: string[];
    incrementImageIndex: () => void;
    decrementImageIndex: () => void;
    imageIndex: number;
    visiblePhoto: FeedPhotoMediaPictureFragment;
  } & ContainerProps
> = ({
  images,
  isDimmedDown = false,
  tags,
  incrementImageIndex,
  decrementImageIndex,
  imageIndex,
  visiblePhoto,
  ...props
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => nextTrigger(),
    onSwipedRight: () => prevTrigger(),
  });

  const nextTrigger = () => {
    incrementImageIndex();
  };

  const prevTrigger = () => {
    decrementImageIndex();
  };

  const isRejected = visiblePhoto?.rejected ?? false;

  return (
    <>
      {isFullscreen ? (
        <Modal
          isOpen={isFullscreen}
          onClose={() => setIsFullscreen(false)}
          motionPreset={'none'}
        >
          <ModalContent {...handlers}>
            <Container
              position={'fixed'}
              maxW={'full'}
              h={'full'}
              w={'full'}
              left={0}
              top={0}
              right={0}
              bottom={0}
              background={'white'}
              p={'unset'}
            >
              <ImageContent
                tags={tags}
                images={images}
                currentImageIndex={imageIndex}
                pointerEvents={'none'}
                style={{ touchAction: 'none' }}
                sx={{
                  filter: isRejected ? 'brightness(0.4)' : 'unset',
                }}
              />
              {/* Must be orderd after Image Conten, so it appears above the image */}
              <FeedPostRemappedTags
                tags={tags}
                fsk={images[imageIndex]?.fsk ?? undefined}
              />
              <Navigation
                previousImageHandler={decrementImageIndex}
                nextImageHandler={incrementImageIndex}
                setIsFullscreen={setIsFullscreen}
                currentImageNumber={imageIndex + 1}
                totalImages={images.length}
                isFullscreen={isFullscreen}
              />
              {isRejected && (
                <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
                  <Box w={12}>
                    <ImageStatus status={MediaPictureStatusEnum.Rejected} />
                  </Box>
                </AbsoluteFullCenter>
              )}
            </Container>
          </ModalContent>
        </Modal>
      ) : (
        <Container
          position={'relative'}
          maxW={'full'}
          w={'full'}
          h={'full'}
          p={'unset'}
          {...handlers}
        >
          <ImageContent
            tags={tags}
            opacity={isDimmedDown ? 0.4 : 'unset'}
            images={images}
            currentImageIndex={imageIndex}
            sx={{
              filter: isRejected ? 'brightness(0.4)' : 'unset',
            }}
          />
          {/* Must be orderd after Image Conten, so it appears above the image */}
          <FeedPostRemappedTags
            tags={tags}
            fsk={images[imageIndex]?.fsk ?? undefined}
          />
          {!isFullscreen && (
            <Navigation
              previousImageHandler={decrementImageIndex}
              nextImageHandler={incrementImageIndex}
              setIsFullscreen={setIsFullscreen}
              currentImageNumber={imageIndex + 1}
              totalImages={images.length}
              isFullscreen={isFullscreen}
            />
          )}
          {isRejected && (
            <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
              <Box w={12}>
                <ImageStatus status={MediaPictureStatusEnum.Rejected} />
              </Box>
            </AbsoluteFullCenter>
          )}
        </Container>
      )}
    </>
  );
};
