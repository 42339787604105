import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

import { UrlFragment } from '../components/Layout/UrlFragmentScrollToTarget';
import { scrollIt } from '../utils/scrollIts';

interface UseScrollToElementOnHashMatchOptions {
  scrollTargetId: UrlFragment | `${UrlFragment}-${string}`;
  delay?: number;
  duration?: number;
  scrollIntoviewOption?: ScrollIntoViewOptions;
}

export const useScrollToElementOnHashMatch = <T extends HTMLElement>({
  scrollTargetId,
  delay = 250,
  duration = undefined,
  scrollIntoviewOption = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  },
}: UseScrollToElementOnHashMatchOptions) => {
  const { hash, search } = useLocation();
  const { replace } = useHistory();
  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    const element = ref.current;
    if (hash !== `#${scrollTargetId}` || !element) {
      return;
    }
    setTimeout(() => {
      if (!element) {
        return;
      }

      if (duration && !isNaN(duration)) {
        // Might be used to scroll softly over longer durations
        scrollIt(element.offsetTop - 100, duration, 'easeOutQuad');
      } else {
        // On no duration supplied, let the browser use his default behaviour
        element.scrollIntoView(scrollIntoviewOption);
      }
    }, delay);
    replace({ search, hash: undefined });
  }, [
    hash,
    delay,
    duration,
    scrollIntoviewOption,
    replace,
    scrollTargetId,
    search,
  ]);

  return ref;
};
