// TabsContext.tsx
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryParamState } from '../../../hooks/useQueryParamState';

export const StatisticsDetailedCategoryExtEnum = {
  OverAll: 'OVER_ALL',
  Affiliate: 'AFFILIATE',
  LiveChat: 'LIVE_CHAT',
  VideoLibrary: 'VIDEO_LIBRARY',
  Feed: 'FEED',
  Messenger: 'MESSENGER',
  PhoneService: 'PHONE_SERVICE',
  Telegram: 'TELEGRAM',
  Misc: 'MISC',
} as const;

export type StatisticsDetailedCategoryExtEnumType =
  (typeof StatisticsDetailedCategoryExtEnum)[keyof typeof StatisticsDetailedCategoryExtEnum];

type TabsContextType = {
  tabIds: StatisticsDetailedCategoryExtEnumType[];
  tabIndex: number;
  tabIdToName: (currentTab: StatisticsDetailedCategoryExtEnumType) => string;
  handleTabChange: (index: number) => void;
};

const TabsContext = createContext<TabsContextType | undefined>(undefined);

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabsContext must be used within a TabsProvider');
  }
  return context;
};

export const TabsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation(['statisticsPage']);
  const [tabId, setTabId] = useQueryParamState('tabId');

  const tabIdToName = useCallback(
    (currentTab: StatisticsDetailedCategoryExtEnumType) => {
      const tabIdToName: Record<StatisticsDetailedCategoryExtEnumType, string> =
        {
          [StatisticsDetailedCategoryExtEnum.OverAll]: t(
            'statisticsPage:tabs.Gesamt'
          ),
          [StatisticsDetailedCategoryExtEnum.LiveChat]: t(
            'statisticsPage:tabs.LiveChat'
          ),
          [StatisticsDetailedCategoryExtEnum.Messenger]: t(
            'statisticsPage:tabs.Messenger'
          ),
          [StatisticsDetailedCategoryExtEnum.Feed]: t(
            'statisticsPage:tabs.Feed'
          ),
          [StatisticsDetailedCategoryExtEnum.VideoLibrary]: t(
            'statisticsPage:tabs.VideoLibrary'
          ),
          [StatisticsDetailedCategoryExtEnum.Misc]: t(
            'statisticsPage:tabs.Misc'
          ),
          [StatisticsDetailedCategoryExtEnum.PhoneService]: t(
            'statisticsPage:tabs.PhoneService'
          ),
          [StatisticsDetailedCategoryExtEnum.Telegram]: t(
            'statisticsPage:tabs.Telegram'
          ),
          [StatisticsDetailedCategoryExtEnum.Affiliate]: t(
            'statisticsPage:tabs.Affiliate'
          ),
        };
      return tabIdToName[currentTab];
    },
    [t]
  );

  const tabIds = Object.values(StatisticsDetailedCategoryExtEnum);

  const tabIndex = useMemo(() => {
    const tabIdEnumValue = tabId as StatisticsDetailedCategoryExtEnumType;
    if (!tabId || !tabIds.includes(tabIdEnumValue)) {
      return 0;
    }
    const index = tabIds.indexOf(tabIdEnumValue);
    return index >= 0 ? index : 0;
  }, [tabId, tabIds]);

  const handleTabChange = useCallback(
    (index: number) => {
      const newTabId = tabIds[index] ?? tabIds[0];
      setTabId(newTabId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabIds]
  );

  useEffect(() => {
    if (!tabId) {
      setTabId(tabIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, tabIds]);

  const value = useMemo(
    () => ({
      tabIds,
      tabIndex,
      tabIdToName,
      handleTabChange,
    }),
    [tabIds, tabIndex, tabIdToName, handleTabChange]
  );

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
