export * from './LocalStorage';
export * from './Logger';

export const noop = () => {
  // noop
};

export const asyncNoop = async () => {
  // noop
};
