export const formatDEDate = (date?: Date) => {
  return new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

export const formatDEDateRange = (_: { oldest: Date; newest: Date }) => {
  return new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatRange(_.oldest, _.newest);
};
