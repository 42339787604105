import { Close, Edit } from '@campoint/odi-ui-icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { EncodedControlledCharacterCount } from '../../EncodedControlledCharacterCount/EncodedControlledCharacterCount';
import { FormControlHeaderStack } from '../../FormElements/FormControlHeaderStack/FormControlHeaderStack';

export const ClearableInputControl: React.FC<
  {
    name: string;
    label: React.ReactNode;
    labelTrailingHint?: React.ReactNode;
    text?: string;
    footerInfoText?: React.ReactNode;
    placeholder?: string;
    labelProps?: FormLabelProps;
    errorMessageExtraSpace?: boolean;
    readOnlyPlaceholder?: string;
  } & InputProps
> = ({
  name,
  label,
  labelTrailingHint,
  text,
  footerInfoText,
  placeholder,
  labelProps,
  errorMessageExtraSpace = true,
  isDisabled,
  isReadOnly,
  readOnlyPlaceholder,
  ...rest
}) => {
  const { register, formState, setValue, trigger, setFocus, getFieldState } =
    useFormContext();
  const { error } = getFieldState(name, formState);
  const { errors, touchedFields, isSubmitted } = formState;

  const inputGroupRef = React.useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = React.useState(false);

  // Using useWatch instead of watch for performance reasons
  // https://react-hook-form.com/docs/useform/watch <-- Down in "Rules" it says that useWatch might work better performance-wise
  const value = useWatch({ name });
  const isInvalid =
    !!errors[name] && (touchedFields[name] || isSubmitted) && !isReadOnly;
  const isVisible = !isDisabled && !isReadOnly && !(rest.type === 'date');
  const isClearIconVisible = value && isFocused;
  const disabledState = {
    bg: 'none',
    border: 'none',
    p: 0,
    _hover: {
      boxShadow: 'none',
    },
  };

  return (
    <FormControl isInvalid={isInvalid}>
      <FormControlHeaderStack>
        <HStack w={'full'}>
          <FormLabel children={label} flex={1} {...labelProps} />
          {labelTrailingHint}
        </HStack>
        {text && <FormHelperText>{text}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup
        ref={inputGroupRef}
        size="md"
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      >
        <Input
          type={'text'}
          placeholder={isReadOnly ? readOnlyPlaceholder : placeholder}
          isDisabled={isDisabled}
          _disabled={disabledState}
          isReadOnly={isReadOnly}
          _readOnly={disabledState}
          borderColor={'darkSteel'}
          // sets name, ref, onChange, onBlur
          {...register(name)}
          {...rest}
        />
        {isVisible && (
          <InputRightElement
            pointerEvents={isClearIconVisible ? 'unset' : 'none'}
          >
            {isClearIconVisible ? (
              <IconButton
                size="sm"
                aria-label="clear"
                variant="ghost"
                color="onSurface.mediumEmphasis"
                colorScheme="gray"
                icon={<Icon as={Close} boxSize="icon.md" />}
                onMouseDown={() => {
                  setValue(name, '');
                  trigger(name);
                  setTimeout(() => setFocus(name), 0);
                }}
                onTouchStart={() => {
                  setValue(name, '');
                  trigger(name);
                  setTimeout(() => setFocus(name), 0);
                }}
              />
            ) : (
              <Icon as={Edit} boxSize={'icon.md'} color={'primary.200'} />
            )}
          </InputRightElement>
        )}
      </InputGroup>

      {(isInvalid || rest.maxLength) && (
        <HStack minH={'8'}>
          <Box flexGrow={1}>
            {isInvalid && (
              <FormErrorMessage>{error?.message as any}</FormErrorMessage>
            )}
          </Box>
          {rest.maxLength && (
            <EncodedControlledCharacterCount
              value={value}
              maxCharacterCount={rest.maxLength}
            />
          )}
        </HStack>
      )}
      {footerInfoText}
    </FormControl>
  );
};
