import { StackProps, VStack } from '@chakra-ui/react';
import React from 'react';

export interface LayoutCenteringVStackProps {
  isWidthRestricted?: boolean;
  innerStackProps?: StackProps;
  outerStackProps?: StackProps;
  children: React.ReactNode;
}

export const LayoutCenteringVStack: React.FC<LayoutCenteringVStackProps> = ({
  children,
  isWidthRestricted = false,
  innerStackProps,
  outerStackProps,
}) => {
  return (
    <VStack
      w={'full'}
      px={{ base: 4, lg: 8 }}
      alignItems={'stretch'}
      {...outerStackProps}
    >
      <VStack
        w={'full'}
        spacing={0}
        maxW={isWidthRestricted ? 'container.md' : 'unset'}
        alignSelf={'center'}
        alignItems={'stretch'}
        {...innerStackProps}
      >
        {children}
      </VStack>
    </VStack>
  );
};
