import { Schedule } from '@campoint/odi-ui-icons';
import { Box, Icon, chakra } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const ScheduledForDateTimeBatch: React.FC<{ dateTime: string }> = ({
  dateTime,
  ...rest
}) => {
  const { t } = useTranslation(['general']);
  const date = React.useMemo(() => new Date(dateTime), [dateTime]);

  return (
    <Box
      display={'inline-block'}
      borderRadius={'md'}
      bg={'steel'}
      color={'primary.500'}
      p={1}
      textStyle={'labelXs'}
      lineHeight={'1'}
    >
      <Icon
        as={Schedule}
        boxSize={'icon.sm'}
        verticalAlign={'middle'}
        me={'1'}
      />
      <chakra.time dateTime={dateTime}>
        {t('general:time.dateTimeStamp', {
          dateTime: date,
        })}
      </chakra.time>
    </Box>
  );
};
