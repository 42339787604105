import React from 'react';
import * as Yup from 'yup';
import { DateSchema, NumberSchema, StringSchema } from 'yup';
import { AnyObject } from 'yup/lib/types';

import {
  EmailMalformedErrorMessage,
  EmptyOptionCantBeMixedErrorMessage,
  MaxCharacterCountErrorMessage,
  MaxDateErrorMessage,
  MaxLengthErrorMessage,
  MaxValueErrorMessage,
  MinChoicesErrorMessage,
  MinDateErrorMessage,
  MinLengthErrorMessage,
  MinValueErrorMessage,
  MissingTextErrorMessage,
  RequiredErrorMessage,
  UnClearableErrorMessage,
} from '../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { stringCharCount } from './stringCharCount';

type MaybeString = string | null | undefined;
type NullableStringSchema = StringSchema<MaybeString, AnyObject, MaybeString>;

type MaybeNumber = number | null | undefined;
type NullableNumberSchema = NumberSchema<MaybeNumber, AnyObject, MaybeNumber>;

type MaybeDate = Date | null | undefined;
type NullableDateSchema = DateSchema<MaybeDate, AnyObject, MaybeDate>;

Yup.addMethod(Yup.string, 'minEncoded', function (min, msg) {
  return this.test({
    name: 'minEncoded',
    exclusive: true,
    message: () => (typeof msg === 'function' ? msg({ min }) : msg),
    test: (value) => {
      // Allow for empty string
      if (!value) {
        return true;
      }
      return stringCharCount(value) >= min;
    },
  });
});

Yup.addMethod(Yup.string, 'maxEncoded', function (max, msg) {
  return this.test({
    name: 'maxEncoded',
    exclusive: true,
    message: () => (typeof msg === 'function' ? msg({ max }) : msg),
    test: (value) => {
      // Allow for empty string
      if (!value) {
        return true;
      }
      return stringCharCount(value) <= max;
    },
  });
});

export type CreateStringSchemaOptions = {
  /** @default undefined */
  value?: string | null;
  /** @default false */
  isOptional?: boolean | null;
  isNullable?: boolean | null;
  requiredMessage?: () => React.ReactNode;
  /** @default false */
  isEmail?: boolean | null;
  emailMessage?: () => React.ReactNode;
  /** @default undefined */
  minLength?: number | null;
  minLengthMessage?: ({ min }: { min: number }) => React.ReactNode;
  /** @default undefined */
  maxLength?: number | null;
  maxLengthMessage?: ({ max }: { max: number }) => React.ReactNode;

  /**
   * Field can not be cleared once it has a stored non-empty value
   *
   * "OnceSet" is determined by supplied "empty-ness" of {@link value}
   *
   * @default false
   */
  isUnClearableOnceSet?: boolean | null;
  unClearableMessage?: () => React.ReactNode;
};
export const createStringValidationSchema: (
  options: CreateStringSchemaOptions
) => NullableStringSchema = (options) => {
  const {
    value: initialValue = undefined,
    isOptional = false,
    requiredMessage = () => <RequiredErrorMessage />,
    isNullable = true,
    isEmail = false,
    emailMessage = () => <EmailMalformedErrorMessage />,
    minLength,
    minLengthMessage = ({ min }) => <MinLengthErrorMessage min={min} />,
    maxLength,
    maxLengthMessage = ({ max }) => <MaxLengthErrorMessage max={max} />,
    isUnClearableOnceSet = false,
    unClearableMessage = () => <UnClearableErrorMessage />,
  } = options;

  const isClearable = !isUnClearableOnceSet || !initialValue;
  const nullable = isClearable || isNullable || undefined;
  let schema = !nullable ? Yup.string() : Yup.string().nullable();
  schema = isOptional ? schema : schema.required(requiredMessage);
  schema = isClearable ? schema : schema.required(unClearableMessage);
  schema =
    typeof minLength !== 'number'
      ? schema
      : schema.when((value, oldSchema) => {
          if (isOptional && value === '') {
            return oldSchema;
          }
          return oldSchema.minEncoded(minLength, minLengthMessage);
        });
  schema =
    typeof maxLength !== 'number'
      ? schema
      : schema.maxEncoded(maxLength, maxLengthMessage);
  if (isEmail) {
    schema = schema.email(emailMessage);
  }

  return schema;
};

export type CreateNumberSchemaOptions = {
  /** @default undefined */
  value?: number | null;
  /** @default false */
  isOptional?: boolean | null;
  isNullable?: boolean | null;
  requiredMessage?: () => React.ReactNode;
  minValue?: number | null;
  minValueMessage?: ({ min }: { min: number }) => React.ReactNode;
  maxValue?: number | null;
  maxValueMessage?: ({ max }: { max: number }) => React.ReactNode;
  /**
   * Field can not be cleared once it has a stored non-empty value
   *
   * "OnceSet" is determined by supplied "empty-ness" of {@link value}
   *
   * @default false
   */
  isUnClearableOnceSet?: boolean | null;
  unClearableMessage?: () => React.ReactNode;
};
export const createNumberValidationSchema: (
  options: CreateNumberSchemaOptions
) => NullableNumberSchema = (options) => {
  const {
    value: initialValue = undefined,
    isOptional = false,
    requiredMessage = () => <RequiredErrorMessage />,
    isNullable = true,
    minValue,
    minValueMessage = ({ min }) => <MinValueErrorMessage min={min} />,
    maxValue,
    maxValueMessage = ({ max }) => <MaxValueErrorMessage max={max} />,
    isUnClearableOnceSet = false,
    unClearableMessage = () => <UnClearableErrorMessage />,
  } = options;

  const isClearable = !isUnClearableOnceSet || !initialValue;
  const nullable = isClearable || isNullable || undefined;
  let schema = !nullable ? Yup.number() : Yup.number().nullable();
  schema = isOptional ? schema : schema.required(requiredMessage);
  schema = isClearable ? schema : schema.required(unClearableMessage);
  schema =
    typeof minValue !== 'number'
      ? schema
      : schema.min(minValue, minValueMessage);
  schema =
    typeof maxValue !== 'number'
      ? schema
      : schema.max(maxValue, maxValueMessage);
  return schema;
};

export type CreateDateSchemaOptions = {
  /** @default false */
  isOptional?: boolean | null;
  isNullable?: boolean | null;
  requiredMessage?: () => React.ReactNode;
  minDate?: string | Date | null;
  minDateMessage?: ({ min }: { min: string | Date }) => React.ReactNode;
  maxDate?: string | Date | null;
  maxDateMessage?: ({ max }: { max: string | Date }) => React.ReactNode;
};
export const createDateValidationSchema: (
  options: CreateDateSchemaOptions
) => NullableDateSchema = (options) => {
  const {
    isOptional = false,
    requiredMessage = () => <RequiredErrorMessage />,
    isNullable = true,
    minDate,
    minDateMessage = ({ min }) => <MinDateErrorMessage min={min} />,
    maxDate,
    maxDateMessage = ({ max }) => <MaxDateErrorMessage max={max} />,
  } = options;

  const nullable = isNullable || undefined;
  let schema = !nullable ? Yup.date() : Yup.date().nullable();
  schema = isOptional ? schema : schema.required(requiredMessage);
  schema = !(typeof minDate === 'string' || minDate instanceof Date)
    ? schema
    : schema.min(minDate, minDateMessage);
  schema = !(typeof maxDate === 'string' || maxDate instanceof Date)
    ? schema
    : schema.max(maxDate, maxDateMessage);
  return schema;
};

export type CreateEnumSchemaOptions = {
  /** @default false */
  isOptional?: boolean | null;
  isNullable?: boolean | null;
  requiredMessage?: () => React.ReactNode;
};
export const createEnumValidationSchema: (
  options: CreateEnumSchemaOptions
) => NullableStringSchema = (options) => {
  const {
    isOptional = false,
    isNullable = true,
    requiredMessage = () => <RequiredErrorMessage />,
  } = options;

  const nullable = isNullable || undefined;
  let schema = !nullable ? Yup.string() : Yup.string().nullable();
  schema = isOptional ? schema : schema.required(requiredMessage);

  return schema;
};

export type CreateEnumListSchemaOptions = {
  /** @default false */
  isOptional?: boolean | null;
  isNullable?: boolean | null;
  requiredMessage?: () => React.ReactNode;
  minChoicesMessage?: ({ min }: { min: number }) => React.ReactNode;
  emptyOption?: string | null;
  emptyOptionMessage?: ({
    optionLabel,
  }: {
    optionLabel: string;
  }) => React.ReactNode;
  validValues?: { label: string; value: string }[];
};
export const createEnumListValidationSchema = (
  options: CreateEnumListSchemaOptions
) => {
  const {
    isOptional = false,
    isNullable = true,
    requiredMessage = () => <RequiredErrorMessage />,
    minChoicesMessage = ({ min }) => <MinChoicesErrorMessage choices={min} />,
    emptyOption = null,
    emptyOptionMessage = ({ optionLabel }) => (
      <EmptyOptionCantBeMixedErrorMessage optionLabel={optionLabel} />
    ),
    validValues = [],
  } = options;

  const nullable = isNullable || undefined;
  let schema = !nullable
    ? Yup.array().of(Yup.string())
    : Yup.array().nullable().of(Yup.string());
  schema = isOptional
    ? schema
    : schema.min(1, minChoicesMessage).required(requiredMessage);

  if (emptyOption !== null) {
    const emptyValueLabel =
      validValues.find((validValue) => validValue.value === emptyOption)
        ?.label ?? emptyOption;
    schema = schema.test(
      'emptyOption mixed with other options',
      (array, { createError, path }) => {
        const foundEmptyEntry = array?.includes(emptyOption);
        if (!!foundEmptyEntry && array?.length !== 1) {
          return createError({
            path,
            message: () =>
              emptyOptionMessage({
                optionLabel: emptyValueLabel,
              }),
          });
        }
        return true;
      }
    );
  }

  return schema;
};
export const createFeedCommentValidation: (options?: {
  maxLength?: number;
}) => NullableStringSchema = ({ maxLength = 500 } = {}) => {
  return createStringValidationSchema({
    isOptional: false,
    maxLength: maxLength,
    maxLengthMessage: () => <MaxCharacterCountErrorMessage />,
  }).test((value, testContext) => {
    // Should be more than just the "@Mention"
    const check = !!value && /^@\w+$/.test(value.trim());
    if (check) {
      return testContext.createError({
        message: () => <MissingTextErrorMessage />,
      });
    }

    return true;
  });
};
