// Based on https://github.com/kgnugur/formik-chakra-ui
import { Input, InputProps, forwardRef } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { BaseProps, FormControl } from '../FormControl';

export type InputControlProps = BaseProps & { inputProps?: InputProps };

export const InputControl = forwardRef<InputControlProps, 'input' | 'textarea'>(
  (props, ref) => {
    const { name, label, inputProps, children, ...rest } = props;
    const [field] = useField(name);

    return (
      <FormControl name={name} label={label} {...rest}>
        {children ?? <Input {...field} {...inputProps} ref={ref} />}
      </FormControl>
    );
  }
);

export default InputControl;
