import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntlProvider } from 'react-intl';

const IntlI18nProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  useEffect(() => {
    if (locale !== i18n.language) {
      setLocale(i18n.language);
    }
  }, [locale, i18n.language, setLocale]);

  return <IntlProvider locale={locale}>{children}</IntlProvider>;
};

export default IntlI18nProvider;
