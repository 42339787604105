import { Button, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import modelProfileReviewImage from '../../../../../assets/images/shared/model-profile-review.svg';
import { WizardIllustratedInstruction } from '../../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { useWizardProfile } from '../../WizardProfileContext';

export const WizardProfileOnboardingV1Finish: React.FC = () => {
  const { t } = useTranslation(['wizardProfile', 'general']);

  const wizardCtx = useWizardProfile();

  React.useEffect(() => {
    wizardCtx.wizardNextStepCallback({}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WizardParentModalStepLayout
      contentSection={
        <VStack spacing={'4'}>
          <WizardIllustratedInstruction
            illustrationSrc={modelProfileReviewImage}
            illustrationAlt={t('general:img.ZuBearbeitendesBild')}
            header={t('wizardProfile:finished.subHeaderVerified')}
          />
          <Button
            variant={'solid'}
            shadow={'none'}
            children={t('wizardProfile:finished.continueButtonText')}
            onClick={() => wizardCtx.wizardNextStepCallback()}
          />
        </VStack>
      }
    />
  );
};
