import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  AffiliateSalesListsFragment,
  DetailedSalesListsFragment,
  GetSalesListsQueryVariables,
  StatisticsEntryIntervalEnum,
  useGetSalesListsLazyQuery,
} from '../../../../generated/graphql';
import { useDateContext } from '../DateContext';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../TabContext';
import { BarChartBarTooltipProvider } from './BarChartBarTooltipProvider';

type BarChartBarContextType = {
  pickedTooltipDate: Maybe<string>;
  salesList: Maybe<DetailedSalesListsFragment>;
  affiliateSalesList: Maybe<AffiliateSalesListsFragment>;
  salesListDataLoading: boolean;
  handleBarClick: (category: string, key: string) => void;
};

const BarChartBarContext = createContext<BarChartBarContextType | undefined>(
  undefined
);

export const useBarChartBarContext = () => {
  const context = useContext(BarChartBarContext);
  if (!context) {
    throw new Error(
      'useBarChartBarContext must be used within a BarChartBarProvider'
    );
  }
  return context;
};

export const BarChartBarProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { startDate, endDate } = useDateContext();
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];
  const [salesList, setSalesList] =
    useState<Maybe<DetailedSalesListsFragment>>(null);
  const [affiliateSalesList, setAffiliateSalesList] =
    useState<Maybe<AffiliateSalesListsFragment>>(null);
  const [pickedTooltipDate, setPickedTooltipDate] =
    useState<Maybe<string>>(null);
  //used when we want to show different tooltip for each bar
  // const [tooltipKey, setTooltipKey] = useState<Maybe<string>>(null);

  const getSalesListsQueryVariables = (): GetSalesListsQueryVariables => {
    const baseVariables = {
      startDate,
      endDate,
      interval: StatisticsEntryIntervalEnum['1D'],
      pickedDate: pickedTooltipDate,
    };
    switch (tabId) {
      case StatisticsDetailedCategoryExtEnum.LiveChat:
        return { ...baseVariables, liveChat: true };
      case StatisticsDetailedCategoryExtEnum.Feed:
        return { ...baseVariables, feed: true };
      case StatisticsDetailedCategoryExtEnum.VideoLibrary:
        return { ...baseVariables, videoLibrary: true };
      case StatisticsDetailedCategoryExtEnum.Messenger:
        return { ...baseVariables, messenger: true };
      case StatisticsDetailedCategoryExtEnum.PhoneService:
        return { ...baseVariables, phoneService: true };
      case StatisticsDetailedCategoryExtEnum.Misc:
        return { ...baseVariables, other: true };
      case StatisticsDetailedCategoryExtEnum.Telegram:
        return { ...baseVariables, telegram: true };
      case StatisticsDetailedCategoryExtEnum.Affiliate:
        return { ...baseVariables, affiliate: true };
      case StatisticsDetailedCategoryExtEnum.OverAll:
        return { ...baseVariables, overall: true, affiliate: true };
      default:
        return { ...baseVariables };
    }
  };

  const [
    fetchSalesList,
    { data: salesListData, loading: salesListDataLoading },
  ] = useGetSalesListsLazyQuery({
    onCompleted: () => {
      setSalesList(() => salesListData?.statistics?.detailed);
      setAffiliateSalesList(() => salesListData?.affiliate?.detailed);
    },
  });

  const fetchTooltipData = () => {
    if (!pickedTooltipDate) return;

    const salesListQueryVariables = {
      variables: {
        ...getSalesListsQueryVariables(),
      },
    };

    fetchSalesList(salesListQueryVariables);
  };

  const handleBarClick = useCallback(
    (category: string, key: string) => {
      const baseDate = new Date(category).toISOString();
      const clickedDate = DateTime.fromISO(baseDate).toISODate();

      let newPickedDate = clickedDate;

      if (pickedTooltipDate && clickedDate === pickedTooltipDate) {
        return;
      }

      // setTooltipKey(key);
      setPickedTooltipDate(newPickedDate);
    },
    [pickedTooltipDate]
  );

  useEffect(() => {
    setPickedTooltipDate(null);
  }, [tabId]);

  useEffect(() => {
    if (pickedTooltipDate) {
      fetchTooltipData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedTooltipDate]);

  const value = useMemo(
    () => ({
      pickedTooltipDate,
      salesList,
      affiliateSalesList,
      salesListDataLoading,
      handleBarClick,
    }),
    [
      affiliateSalesList,
      handleBarClick,
      pickedTooltipDate,
      salesList,
      salesListDataLoading,
    ]
  );

  return (
    <BarChartBarContext.Provider value={value}>
      <BarChartBarTooltipProvider>{children}</BarChartBarTooltipProvider>
    </BarChartBarContext.Provider>
  );
};
