import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../../../assets/images/wizard/model-release-form-welcome.svg';
import { WizardIllustratedInstruction } from '../../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../../components/WizardStepFooter/WizardStepFooter';
import { useWizardMRF } from '../../WizardMRFContext';

export const WizardMRFModelReleaseFormV1Start: React.FC = () => {
  const { t } = useTranslation(['wizardMRF']);
  const wizardMRFCtx = useWizardMRF();
  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardIllustratedInstruction
          illustrationSrc={image}
          illustrationAlt={t(
            'wizardMRF:img.HandschlagNachVertragsunterzeichnung'
          )}
          header={t('wizardMRF:heading.ModelReleaseFormAusfullen')}
          body={t(
            'wizardMRF:text.UmDeineInhalteVeroffentlichenZuKonnenBenotigenWirDeinEinverstandnisDXX'
          )}
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardMRF:button.Weiter')}
          onContinueButtonClick={() => {
            wizardMRFCtx.wizardNextStepCallback();
          }}
          omitSkipButton
        />
      }
    />
  );
};
