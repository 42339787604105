import { Button, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
} from '../../../../../components/Layout/ResponsiveModal';
import { StepDotGroup } from '../../../../../components/shared/StepDotGroup/StepDotGroup';
import { useAuth } from '../../../../../provider/AuthProvider';
import { LocalStorageKeys } from '../../../../../types';
import { LocalStorage } from '../../../../../utils';
import { GMUModalStep1 } from './GMUModalStep1';
import { GMUModalStep2 } from './GMUModalStep2';
import { GMUStoerer } from './GMUStoerer';

export const GMUModal: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation(['gmumodal']);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [currentStep, setCurrentStep] = useState(1);
  const { DateTime } = require('luxon');

  useEffect(() => {
    if (
      isAuthenticated &&
      !(LocalStorage.getBoolean(LocalStorageKeys.GMU_MODAL_SHOWN) ?? false)
    ) {
      onOpen();
    }
  }, [DateTime, isAuthenticated, onOpen]);

  useEffect(() => {
    if (isOpen) {
      setCurrentStep(1);
    }
  }, [isOpen]);

  const onCloseWithLocalStorage = () => {
    LocalStorage.add(LocalStorageKeys.GMU_MODAL_SHOWN, true);
    onClose();
  };

  // Show the modal only after 03.11.2024 09:00:00
  const berlinTime = DateTime.now().setZone('Europe/Berlin');
  const releaseDate = DateTime.fromISO('2024-11-03T09:00:00', {
    zone: 'Europe/Berlin',
  });
  if (
    berlinTime > releaseDate ||
    window.location.href.includes('showGmuModal=true') // for testing
  ) {
    return (
      <>
        {isAuthenticated ? <GMUStoerer onOpen={onOpen} /> : null}
        <ResponsiveModal
          isOpen={isOpen}
          onClose={onCloseWithLocalStorage}
          preferredSize="xl"
        >
          <ResponsiveModalOverlay />
          <ResponsiveModalContent>
            <ResponsiveModalCloseButton />
            <ResponsiveModalBodyBox
              display={'flex'}
              justifyContent={'start'}
              mt={8}
            >
              {currentStep === 1 && <GMUModalStep1 />}
              {currentStep === 2 && <GMUModalStep2 onClose={onClose} />}
            </ResponsiveModalBodyBox>
            <ResponsiveModalStickyFooterBox flexDirection={'column'}>
              {currentStep === 1 && (
                <Button
                  onClick={() => setCurrentStep(2)}
                  variant="solid"
                  children={t('gmumodal:button.weiter')}
                />
              )}
              {currentStep === 2 && (
                <Button
                  onClick={onCloseWithLocalStorage}
                  variant="solid"
                  children={t('gmumodal:button.fensterSchliessen')}
                />
              )}
              <StepDotGroup
                currentStep={currentStep}
                totalSteps={2}
                mt={4}
                onStepClick={setCurrentStep}
              />
            </ResponsiveModalStickyFooterBox>
          </ResponsiveModalContent>
        </ResponsiveModal>
      </>
    );
  } else {
    return null;
  }
};
