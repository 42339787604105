import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  InputGroup,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
  Wrap,
} from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { OptionEntry } from '../../../../generated/graphql';
import { FormControlHeaderStack } from '../../FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { MultiSelectOption } from '../../FormikFormElements/MultiSelect/MultiSelectOptionList';

const SelectedTag: React.FC<TagProps> = (props) => (
  <Tag variant="outline" size={'lg'} fontSize={'md'} as={'li'} {...props} />
);

const alphabeticalLabel = (a: MultiSelectOption, b: MultiSelectOption) =>
  a.label.toLowerCase().localeCompare(b.label.toLowerCase());

export const MultiSelectControl: React.FC<{
  name: string;
  field: any;
  label: React.ReactNode;
  placeholder: string;
  labelTrailingHint?: React.ReactNode;
  footerInfoText?: React.ReactNode;
  labelProps?: FormLabelProps;
  text?: string;
  isDisabled?: boolean;
  isAllowedToAdd?: boolean;
  isReadOnly?: boolean;
}> = ({
  name,
  field,
  label,
  placeholder,
  labelTrailingHint,
  footerInfoText,
  labelProps,
  text,
  isDisabled,
  isReadOnly,
  isAllowedToAdd = true,
}) => {
  const {
    formState: { errors, touchedFields, isSubmitted },
    setValue,
  } = useFormContext();
  const currentSelection = useWatch({ name });

  const customOnChange = React.useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value;
      const value =
        selectedValue === null
          ? []
          : [...(currentSelection ?? []), selectedValue]?.filter(
              (v) => v !== null
            );
      setValue(name, value, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [currentSelection, name, setValue]
  );

  const availableOptions = React.useMemo(() => {
    const filteredOptions: OptionEntry[] = (field?.validValues ?? [])
      .filter(
        (o: any) => !(currentSelection as string[])?.includes(o.value) ?? true
      )
      .sort(alphabeticalLabel);
    return filteredOptions;
  }, [field?.validValues, currentSelection]);

  const emitRemoveOnChange = React.useCallback(
    (valueToRemove: string) => {
      const value =
        currentSelection?.filter((v: any) => v !== valueToRemove) ?? [];
      setValue(name, value, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [currentSelection, name, setValue]
  );

  const selectedElements = React.useMemo(() => {
    const currentValue = currentSelection ?? [];

    if (currentValue.length <= 0) {
      return null;
    }

    const selectedOptions = (field?.validValues ?? [])
      .filter?.((o: any) => currentValue.includes(o.value))
      .sort(alphabeticalLabel);

    return (
      <>
        {selectedOptions.map((selectedOption: OptionEntry) => (
          <SelectedTag key={selectedOption.label}>
            <TagLabel color={'gray.900'} borderColor={'gray.900'}>
              {selectedOption.label}
            </TagLabel>
            {!(isReadOnly || isDisabled) && (
              <TagCloseButton
                color={'gray.900'}
                onClick={() => emitRemoveOnChange(selectedOption.value)}
              />
            )}
          </SelectedTag>
        ))}
      </>
    );
  }, [currentSelection, field, emitRemoveOnChange, isReadOnly, isDisabled]);

  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);

  return !field ? null : (
    <FormControl
      isInvalid={isInvalid}
      isDisabled={isDisabled || !isAllowedToAdd}
      isReadOnly={isReadOnly}
    >
      <FormControlHeaderStack>
        <HStack w={'full'}>
          <FormLabel children={label} flex={1} {...labelProps} />
          {labelTrailingHint}
        </HStack>
        {text && <FormHelperText>{text}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup size="md" width={'full'}>
        <Select onChange={customOnChange} placeholder={placeholder}>
          {availableOptions.map((value: OptionEntry) => {
            return (
              <option key={value.value} value={value.value}>
                {value.label}
              </option>
            );
          })}
        </Select>
      </InputGroup>
      {errors[name] && (
        <FormErrorMessage display={'inline-block'}>
          {errors[name]?.message as string}
        </FormErrorMessage>
      )}
      {selectedElements && <Wrap mt={'4'}>{selectedElements}</Wrap>}
    </FormControl>
  );
};
