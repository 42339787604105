import * as React from 'react';
import { useTranslation } from 'react-i18next';

import passportBackBorderlessImagePlaceholderImage from '../assets/images/documentUpload/altersverifizierung-id-card-back.svg';
import passportFrontBorderlessImagePlaceholderImage from '../assets/images/documentUpload/altersverifizierung-id-card-front.svg';
import personWithPassportBorderlessImagePlaceholderImage from '../assets/images/documentUpload/altersverifizierung-id-shot.svg';
import profilePicturePlaceholderFSK12 from '../assets/images/imageUpload/avatar-fsk12-placeholder.svg';
import profilePicturePlaceholderFSK16 from '../assets/images/imageUpload/avatar-fsk16-placeholder.svg';
import profilePicturePlaceholderFSK18 from '../assets/images/imageUpload/avatar-fsk18-placeholder.svg';
import sedcardPicturePlaceholderFSK12 from '../assets/images/imageUpload/sedcard-fsk12-placeholder.svg';
import sedcardPicturePlaceholderFSK16 from '../assets/images/imageUpload/sedcard-fsk16-placeholder.svg';
import sedcardPicturePlaceholderFSK18 from '../assets/images/imageUpload/sedcard-fsk18-placeholder.svg';
import { createContext } from '../hooks/useContext';
import { ImageRatings } from '../pages/ModelProfileV2Page/ModelProfilePage/types';
import {
  Orientation,
  getOrientationFromImageDimension,
} from '../utils/imageCropper.utils';

export interface MediaPropertiesContext {
  targetDimensions: { width: number; height: number };
  readonly aspectRatio: number;
  stencil?: string;
  stencilPortraitFallback?: string;
  orientedStencil?: string;
  isStencilVisible: boolean;
  isOrientationFlipAllowed?: boolean;
  enforceTargetDimension?: boolean;
  enforceMinimalDimension?: boolean;
  minimalDimension: number;
  determineAspectRatioByInput?: boolean;
  orientation: 'portrait' | 'landscape' | null;
  isCircular: boolean;
  placeholderSrc?: string;
  named: string;
  readonly action: {
    toggleOrientation: () => void;
    setOrientation: (orientation: Orientation | null) => void;
    toggleStencil: () => void;
  };
}
export const [, useMediaProperties, mediaPropertiesContext] =
  createContext<MediaPropertiesContext>({
    name: 'MediaPropertiesContext',
    errorMessage:
      'useMediaProperties: `MediaPropertiesContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export type MediaPropertiesProviderOptions = {
  targetDimensions: { width: number; height: number };
  isOrientationFlipAllowed?: boolean;
  enforceTargetDimension?: boolean;
  enforceMinimalDimension?: boolean;
  determineAspectRatioByInput?: boolean;
  minimalDimension?: number;
  isStencilVisibleInitially?: boolean;
  isCircular?: boolean;
  placeholderSrc?: string;
  stencil?: string;
  stencilPortraitFallback?: string;
  named?: string;
  children?: React.ReactNode;
};

const Provider: React.FC<MediaPropertiesProviderOptions> = ({
  children,
  isOrientationFlipAllowed = false,
  enforceTargetDimension = false,
  enforceMinimalDimension = false,
  determineAspectRatioByInput = false,
  minimalDimension = 520,
  isStencilVisibleInitially,
  isCircular = false,
  stencil,
  stencilPortraitFallback,
  targetDimensions,
  named,
  placeholderSrc,
}) => {
  const { t } = useTranslation(['mediaUpload']);
  const [orientation, setOrientation] = React.useState(
    getOrientationFromImageDimension(targetDimensions)
  );

  const [isStencilVisible, setIsStencilVisible] = React.useState(
    isStencilVisibleInitially ?? !!stencil
  );

  const orientedStencil = React.useMemo(() => {
    if ('portrait' === orientation) {
      return stencilPortraitFallback ?? stencil;
    }
    return stencil;
  }, [orientation, stencil, stencilPortraitFallback]);

  const toggleStencil = React.useCallback(() => {
    setIsStencilVisible((prevState) => !prevState);
  }, [setIsStencilVisible]);

  const toggleOrientation = React.useCallback(() => {
    setOrientation((prevState) => {
      switch (prevState) {
        case 'landscape':
          return 'portrait';
        case 'portrait':
          return 'landscape';
        default:
          return prevState;
      }
    });
  }, [setOrientation]);

  const context: MediaPropertiesContext = {
    isOrientationFlipAllowed,
    aspectRatio: targetDimensions.width / targetDimensions.height,
    enforceTargetDimension,
    enforceMinimalDimension,
    determineAspectRatioByInput,
    minimalDimension,
    targetDimensions,
    orientedStencil,
    isStencilVisible,
    orientation,
    isCircular,
    named: named ?? t('mediaUpload:named.Bild'),
    placeholderSrc,
    action: {
      toggleOrientation,
      setOrientation,
      toggleStencil,
    },
  };
  return (
    <mediaPropertiesContext.Provider value={context} children={children} />
  );
};

export const ForTitlePicture: React.FC<
  Partial<MediaPropertiesProviderOptions>
> = (props) => {
  const { t } = useTranslation(['mediaUpload']);

  return (
    <Provider
      targetDimensions={{ width: 1300, height: 520 }}
      named={t('mediaUpload:named.Titelbild')}
      enforceMinimalDimension={true}
      {...props}
    />
  );
};

export const ForFeed: React.FC<Partial<MediaPropertiesProviderOptions>> = (
  props
) => {
  return (
    <Provider
      targetDimensions={{ width: 56, height: 70 }} // 4:5 aspect ratio
      {...props}
    />
  );
};

type ImageRatingProp = { imageRating: ImageRatings };

const getProfilePicturePlaceholderSrcForImageRating = (
  imageRating?: ImageRatings
) => {
  switch (imageRating) {
    case ImageRatings.FSK12:
      return profilePicturePlaceholderFSK12;
    case ImageRatings.FSK16:
      return profilePicturePlaceholderFSK16;
    case ImageRatings.FSK18:
      return profilePicturePlaceholderFSK18;
    default:
      return undefined;
  }
};

export const ForProfilePicture: React.FC<
  Partial<MediaPropertiesProviderOptions> & Partial<ImageRatingProp>
> = ({ imageRating, ...props }) => {
  const { t } = useTranslation(['mediaUpload']);

  return (
    <Provider
      targetDimensions={{ width: 520, height: 520 }}
      named={t('mediaUpload:named.Profilbild')}
      enforceMinimalDimension={true}
      isCircular={true}
      placeholderSrc={getProfilePicturePlaceholderSrcForImageRating(
        imageRating
      )}
      {...props}
    />
  );
};

const getSedcardPicturePlaceholderSrcForImageRating = (
  imageRating?: ImageRatings
) => {
  switch (imageRating) {
    case ImageRatings.FSK12:
      return sedcardPicturePlaceholderFSK12;
    case ImageRatings.FSK16:
      return sedcardPicturePlaceholderFSK16;
    case ImageRatings.FSK18:
      return sedcardPicturePlaceholderFSK18;
    default:
      return undefined;
  }
};

export const ForSedcardPicture: React.FC<
  Partial<MediaPropertiesProviderOptions> & { imageRating?: ImageRatings }
> = ({ imageRating, ...props }) => {
  const { t } = useTranslation(['mediaUpload']);

  return (
    <Provider
      targetDimensions={{ width: 800, height: 600 }}
      named={t('mediaUpload:named.SedcardBild')}
      enforceMinimalDimension={true}
      placeholderSrc={getSedcardPicturePlaceholderSrcForImageRating(
        imageRating
      )}
      {...props}
    />
  );
};

export const ForIdentityProofFrontOrEntireDocument: React.FC<
  Partial<MediaPropertiesProviderOptions> & { imageRating?: ImageRatings }
> = ({ imageRating, ...props }) => {
  const { t } = useTranslation(['document']);

  return (
    <Provider
      targetDimensions={{
        height: 864,
        width: 1368,
      }}
      placeholderSrc={passportFrontBorderlessImagePlaceholderImage}
      isOrientationFlipAllowed={true}
      determineAspectRatioByInput={true}
      named={t('document:named.AusweisVorderseite')}
      {...props}
    />
  );
};

export const ForIdentityProofBackDocument: React.FC<
  Partial<MediaPropertiesProviderOptions> & { imageRating?: ImageRatings }
> = ({ imageRating, ...props }) => {
  const { t } = useTranslation(['document']);

  return (
    <Provider
      targetDimensions={{
        height: 864,
        width: 1368,
      }}
      placeholderSrc={passportBackBorderlessImagePlaceholderImage}
      isOrientationFlipAllowed={true}
      determineAspectRatioByInput={true}
      named={t('document:named.AusweisRuckseite')}
      {...props}
    />
  );
};

export const ForIdShotDocument: React.FC<
  Partial<MediaPropertiesProviderOptions> & { imageRating?: ImageRatings }
> = ({ imageRating, ...props }) => {
  const { t } = useTranslation(['document']);

  return (
    <Provider
      targetDimensions={{
        height: 864,
        width: 1368,
      }}
      placeholderSrc={personWithPassportBorderlessImagePlaceholderImage}
      isOrientationFlipAllowed={true}
      determineAspectRatioByInput={true}
      named={t('document:named.IDShot')}
      {...props}
    />
  );
};

/**
 * Defines properties for a particular type of media like its:
 * - expected dimension
 * - shape (rect / circular)
 * - ("named") presentable name of media in this context
 *   usable in toasts and common descriptions
 * - placeholder src
 * - stencil src
 * - etc. {@link MediaPropertiesProviderOptions}
 */
export const MediaPropertiesProvider = Object.assign(Provider, {
  ForFeed,
  ForTitlePicture,
  ForProfilePicture,
  ForSedcardPicture,
  ForIdentityProofFrontOrEntireDocument,
  ForIdentityProofBackDocument,
  ForIdShotDocument,
});
