import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

export const ScrollToLastKnownPositionOnMount: FC<{ identifier: string }> = ({
  identifier,
}) => {
  const { hash } = useLocation();

  useLayoutEffect(() => {
    const scrollPositionKey = `scroll-pos-"${identifier}"`;
    const listener: EventListener = () => {
      const deviceOrientation = (window as any)?.orientation?.toString();
      if (deviceOrientation !== '0') {
        // Do not update for orientations other than portrait mode
        return;
      }
      const windowScrollPosition = [window.scrollX, window.scrollY];
      sessionStorage.setItem(
        scrollPositionKey,
        JSON.stringify(windowScrollPosition)
      );
    };
    try {
      window.addEventListener('scroll', listener);
      const scrollPositionEntry =
        sessionStorage.getItem(scrollPositionKey) ?? '[0,0]';
      const [x, y] = JSON.parse(scrollPositionEntry);

      if (hash === '') {
        window.scrollTo(x, y);
      } else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ block: 'center' });
          }
        }, 0);
      }
    } catch {
      window.scrollTo(0, 0);
    }
    return () => window.removeEventListener('scroll', listener);
  }, [identifier, hash]);

  return null;
};

export default ScrollToLastKnownPositionOnMount;
