import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import ListEmptyState from '../../../../assets/images/shared/list-empty-state.svg';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { AnimatedVXLogo } from '../../../../components/shared/AnimatedVXLogo/AnimatedVXLogo';
import { BlockerCard } from '../../../../components/shared/BlockerCard/BlockerCard';
import { FetchMoreIndicator } from '../../../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import { ImageGalleryTag } from '../../../../components/shared/ImageGallery/components/ImageGalleryTag';
import { MetricCountButton } from '../../../../components/shared/buttons/MetricCountButton/MetricCountButton';
import {
  PhotoalbumFragment,
  PhotoalbumStatusEnum,
  PhotoalbumTypeEnum,
} from '../../../../generated/graphql';
import { usePhotoCommenting } from '../../../../provider/VideoLibraryProvider/VideoLibraryCommentingProvider/VideoLibraryPhotoCommentingProvider';
import { useVideoLibraryPhotoModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryPhotoModalProvider/VideoLibraryPhotoModalProvider';
import { useVideoLibraryPhotoContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryPhotoProvider/VideoLibraryPhotoProvider';
import { useVideoLibraryPhotoStatisticModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryStatisticModalProvider/VideoLibraryPhotoStatisticModalProvider';
import { getFormattedAmount } from '../../../../utils/formattedAmount';
import { MediaStatisticBanner } from '../MediaStatisticBanner/MediaStatisticBanner';
import { TileStatusIndicator } from '../TileStatusIndicator';
import { VideoLibraryEntryMenu } from '../VideoLibraryEntryMenu/VideoLibraryEntryMenu';
import { VideoPostCommentIndicator } from '../VideoPostCommentIndicator/VideoPostCommentIndicator';

type VideoLibraryPageTabSectionPhotoProps = {};

export const VideoLibraryPageTabSectionPhoto: React.FC<
  VideoLibraryPageTabSectionPhotoProps
> = () => {
  const { t } = useTranslation(['videoLibrary']);
  const { photos, initialLoading, photosLoading, photosError, loadMore } =
    useVideoLibraryPhotoContext();

  const isPhotosEmpty = (photos?.length ?? 0) === 0;

  return (
    <Section minH={'300px'}>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow alignItems={'center'}>
            <SectionIcon as={icons.Movie} />
            <SectionTitle fontSize={{ base: '18px', lg: '24px' }}>
              {t('videoLibrary:heading.Fotos')}
            </SectionTitle>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        <VStack width={'full'} alignItems={'stretch'} spacing={4}>
          {initialLoading && (
            <Center>
              <Box w={'96px'} h={'96px'}>
                <AnimatedVXLogo />
              </Box>
            </Center>
          )}
          {isPhotosEmpty && (
            <BlockerCard
              image={ListEmptyState}
              heading={t('videoLibrary:heading.NochKeineFotosVorhanden')}
            />
          )}
          {!initialLoading && !isPhotosEmpty && (
            <SimpleGrid
              pb={'6'}
              gap={'4'}
              columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
            >
              {photos?.map((photoAlbum: PhotoalbumFragment) => {
                return (
                  <UploadHistoryListItem
                    entry={photoAlbum}
                    key={photoAlbum.albumId}
                  />
                );
              })}
              <FetchMoreIndicator
                mt={{ base: '8', lg: '12' }}
                loading={photosLoading}
                error={photosError}
                onIsVisible={loadMore}
              />
            </SimpleGrid>
          )}
        </VStack>
      </SectionBody>
    </Section>
  );
};

export const albumRejected = 'rejected';

const UploadHistoryListItem: React.FC<{ entry: PhotoalbumFragment }> = ({
  entry,
}) => {
  const { actions: statisticModalActions } =
    useVideoLibraryPhotoStatisticModalContext();
  const { onDelete } = useVideoLibraryPhotoContext();
  const { actions } = useVideoLibraryPhotoModalContext();
  const commentingCtx = usePhotoCommenting();

  const status =
    entry?.status === PhotoalbumStatusEnum.Rejected
      ? albumRejected
      : entry.releasedStatus;

  return (
    <Box
      overflow={'hidden'}
      minH={'350px'}
      border={'1px solid'}
      borderColor={'coldGray.100'}
      borderRadius={'6px'}
    >
      <Flex
        alignItems={'center'}
        bgColor={'white'}
        minH={'24px'}
        pl={4}
        pr={2}
        color={'caribbeanGreen.500'}
      >
        <TileStatusIndicator status={status} />
        <VideoLibraryEntryMenu
          mediaType={'photoalbum'}
          onClickOnEdit={() => {
            actions.openModal(entry?.albumId ?? null);
          }}
          onClickOnDelete={() => {
            onDelete(entry?.albumId);
          }}
        />
      </Flex>
      <PhotoPreviewWrapper entry={entry} />
      {entry.type === PhotoalbumTypeEnum.Shop && (
        <MediaStatisticBanner
          onClick={() => {
            statisticModalActions.showModal(entry?.albumId?.toString() ?? '');
          }}
        />
      )}
      <VStack alignItems={'start'} bgColor={'white'} p={'4'} spacing={'4'}>
        <VStack
          alignItems={'start'}
          w={'full'}
          minH={'64px'}
          overflow={'hidden'}
        >
          <Heading
            as={'h3'}
            lineHeight={'8'}
            fontSize={'xl'}
            color={'coldGray.900'}
          >
            {entry?.textCollection?.visitxTitles?.at(0)?.text}
          </Heading>
          <Text
            noOfLines={1}
            children={
              entry?.textCollection?.visitxDescriptions?.at(0)?.text ?? ''
            }
          />
        </VStack>
        <HStack justifyContent={'space-between'} spacing={30} w={'full'}>
          <MetricCountButton
            icon={icons.ThumbUpAlt}
            count={entry?.likesCount ?? 0}
          />
          {entry.type === PhotoalbumTypeEnum.Shop && (
            <>
              <MetricCountButton
                icon={icons.ModeComment}
                count={entry?.feedbacksCount ?? 0}
              />
              <MetricCountButton
                icon={icons.Payments}
                count={entry?.salesCount ?? 0}
              />
            </>
          )}
        </HStack>
        {entry.type === PhotoalbumTypeEnum.Shop && (
          <VideoPostCommentIndicator
            commentCount={entry.feedbacksCount ?? 0}
            onClickOnCommentButton={() => {
              if (entry.albumId) {
                commentingCtx.action.showModal(entry?.albumId.toString());
              }
            }}
          />
        )}
      </VStack>
    </Box>
  );
};

export const PhotoPreviewWrapper: React.FC<{ entry: PhotoalbumFragment }> = ({
  entry,
}) => {
  const { t } = useTranslation(['videoLibrary']);
  const locale = useIntl().locale;
  let photoPrice;
  if (entry?.price) photoPrice = getFormattedAmount(entry?.price, locale);

  const typeToText: { [type in PhotoalbumTypeEnum]: string } = {
    contest: t('videoLibrary:foto.Wettbewerb'),
    shop: t('videoLibrary:foto.Shop'),
    free: t('videoLibrary:foto.Free'),
    pool: t('videoLibrary:foto.Pool'),
  };

  return (
    <AspectRatio ratio={16 / 9}>
      <Box
        w={'full'}
        h={'full'}
        backgroundImage={`url(${entry?.previewPicture16?.image?.src})`}
        backgroundSize={'contain'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundColor={'#1D1915'}
      >
        <HStack
          position={'absolute'}
          top={0}
          left={0}
          p={'3'}
          spacing={2}
          pointerEvents={'none'}
          wrap={'wrap'}
        >
          {entry?.type && entry?.type !== PhotoalbumTypeEnum.Shop && (
            <ImageGalleryTag
              key={entry.type}
              children={typeToText[entry.type]}
            />
          )}
          {photoPrice && (
            <ImageGalleryTag key={photoPrice} children={photoPrice} />
          )}
          {entry?.pictureCountTotal && (
            <ImageGalleryTag
              key={entry?.pictureCountTotal}
              children={t('videoLibrary:label.CountBilder', {
                count: entry?.pictureCountTotal,
              })}
            />
          )}
        </HStack>
      </Box>
    </AspectRatio>
  );
};
