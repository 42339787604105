import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';

import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';
import { useFormattedAmount } from '../../../../utils/formattedAmount';

type PriceSliderProps = {
  name: string;
  steps: number[];
  isDisabled?: boolean;
  //we need to know if the content was created with coins or euro
  isCoinPost?: boolean;
};

export const PriceSliderParent = ({
  name,
  steps,
  isDisabled,
  isCoinPost = false,
}: PriceSliderProps) => {
  const { coinsEnabled } = useFlagsProviderV2();
  return coinsEnabled && isCoinPost ? (
    <PriceSliderCoin name={name} steps={steps} isDisabled={isDisabled} />
  ) : (
    <PriceSlider name={name} steps={steps} isDisabled={isDisabled} />
  );
};

export const PriceSlider = ({ name, steps, isDisabled }: PriceSliderProps) => {
  const { coinsEnabled } = useFlagsProviderV2();
  const [field, , { setValue }] = useField(name);
  const possiblePrices = steps;
  const formattedEuroAmount = useFormattedAmount(field.value);
  const minFormatted = useFormattedAmount(possiblePrices[0]);
  const maxFormatted = useFormattedAmount(
    possiblePrices[possiblePrices.length - 1]
  );
  const minCoins = `${possiblePrices[0]} VXC`;
  const maxCoins = `${possiblePrices[possiblePrices.length - 1]} VXC`;
  const formattedAmount =
    field.value > 0 && coinsEnabled
      ? `${field.value} VXC`
      : formattedEuroAmount;
  const thumbSize = 30;
  const min = 0;
  const max = possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(field.value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    setValue(
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)]
    );
  }

  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        name={field.name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={curIndex}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'sm'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedAmount}
          </SliderMark>
        </Box>
        <SliderMark value={min} mt={'4'} fontSize={'sm'}>
          {coinsEnabled ? minCoins : minFormatted}
        </SliderMark>
        <SliderMark value={max} mt={'4'} ml={'-14'} fontSize={'sm'}>
          {coinsEnabled ? maxCoins : maxFormatted}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};

export const PriceSliderCoin = ({
  name,
  steps,
  isDisabled,
}: PriceSliderProps) => {
  const { coinsEnabled } = useFlagsProviderV2();
  const [field, , { setValue }] = useField(name);
  const possiblePrices = steps;
  const formattedEuroAmount = useFormattedAmount(field.value);
  const formattedAmount =
    field.value > 0 && coinsEnabled
      ? `${field.value} VXC`
      : formattedEuroAmount;
  const min = 0;
  const max = possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(field.value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    setValue(
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)]
    );
  }

  const thumbSize = 60;
  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={3} pt={2} px={2}>
      <Slider
        name={field.name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack
            bg={isDisabled ? 'gray.500' : undefined}
            bgGradient={
              isDisabled ? undefined : 'linear(to-r, primary.to, primary.from)'
            }
          />
        </SliderTrack>
        <SliderThumb
          borderRadius={100}
          bgGradient={'linear(to-r, primary.from, primary.to)'}
          w={thumbSize + 'px'}
          minH={'26px'}
          whiteSpace="nowrap"
          overflow="visible"
          left={`${thumbMargin()}!important`}
        >
          <Text
            px={'6px'}
            fontSize={'sm'}
            color={'surface'}
            fontWeight={'medium'}
            whiteSpace="nowrap"
          >
            {formattedAmount}
          </Text>
        </SliderThumb>
      </Slider>
    </Box>
  );
};
