import { ChatbotProvider } from '@campoint/vxmodels-chatbot';

import { useAuth } from '../../../provider/AuthProvider';
import { VXMChatbotConfig } from './VXMChatbotUtils';

export const VXMChatbotProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const auth = useAuth();

  return (
    <ChatbotProvider
      config={VXMChatbotConfig}
      modelId={auth.authUser?.userId}
      token={auth.accessToken ?? ''}
    >
      {children}
    </ChatbotProvider>
  );
};
