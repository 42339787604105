import { Tag, TagLabel, Wrap, WrapProps } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';

import { TagEntryFragment } from '../../../generated/graphql';

export type TagGroupProps = {
  primary: Maybe<Maybe<TagEntryFragment>[]>;
  secondary: Maybe<Maybe<TagEntryFragment>[]>;
} & WrapProps;

const TagGroup: React.FC<TagGroupProps> = ({
  primary = [],
  secondary = [],
  ...rest
}) => (
  <Wrap spacing={2} {...rest} maxH={'100px'} overflow={'hidden'}>
    {(primary ?? []).map((tag) => (
      <Tag key={tag?.id} variant={'solidPill'} backgroundColor={'gray.300'}>
        <TagLabel children={tag?.value ?? ''} />
      </Tag>
    ))}

    {(secondary ?? []).map((tag) => (
      <Tag key={tag?.id} variant={'outlinePill'} color={'gray.300'}>
        <TagLabel children={tag?.value ?? ''} />
      </Tag>
    ))}
  </Wrap>
);

export default TagGroup;
