import * as React from 'react';

import { useIntersectionObserver } from './useIntersectionObserver';

/**
 * When the "margin bottom" line is crossed
 * the footer wont be fully visible (threshold < 1),
 * so the intersection triggers.
 *
 * In case the "modal bottom" line crosses the "margin bottom" line
 *
 * @example rootMargin
 * const offsetInPx: number = 8;
 * `0px 0px -${offsetInPx}px 0px`
 *
 * @example far
 * ┃ │element│ ┃
 * ┃ ╰───────╯ ┃ ←  element bottom < 642px
 * ┠───────────┨ ←   margin bottom = 642px
 * ┗━━━━━━━━━━━┛ ← viewport bottom = 650px
 *
 * @example touch
 * ┃ │       │ ┃
 * ┃ │element│ ┃
 * ┠─╰───────╯─┨ ←    modal bottom = 642px
 * ┗━━━━━━━━━━━┛ ← viewport bottom = 650px
 *
 * @example touch
 * ┃ │       │ ┃
 * ┃ │element│ ┃
 * ┠─│╌╌╌╌╌╌╌│─┨ ←   margin bottom = 642px
 * ┗━╰───────╯━┛ ← viewport bottom = 650px
 *
 * @example overshoot
 * ┃ │       │ ┃
 * ┃ │element│ ┃
 * ┠─│╌╌╌╌╌╌╌│─┨ ←   margin bottom = 642px
 * ┗━┿━━━━━━━┿━┛ ← viewport bottom = 650px
 *   ╰───────╯   ←  element bottom > 650px
 *
 * @example sections
 * ┃           ┃ ← isCloseOrBelowViewportBottom = false
 * ┠───────────┨ ← isCloseOrBelowViewportBottom = false
 * ┡━━━━━━━━━━━┩ ← isCloseOrBelowViewportBottom = true
 * ╎           ╎ ← isCloseOrBelowViewportBottom = true
 */
export const useViewportBottomCloseness = <Element extends HTMLElement>(
  ref: React.MutableRefObject<Element | null>
) => {
  const [isCloseOrBelowViewportBottom, setIsCloseOrBelowViewportBottom] =
    React.useState<boolean>(false);

  const offsetInPx: number = 8;

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(
        ([e]) => {
          const bottomDiff = Math.max(
            0,
            (e.rootBounds?.bottom ?? 0) - e.boundingClientRect.bottom
          );

          setIsCloseOrBelowViewportBottom(bottomDiff < offsetInPx);
        },
        {
          root: null, // <- intersect with viewport (is default)
          threshold: [1], // trigger when element is only partial visible in respect to margin
          rootMargin: `0px 0px -${offsetInPx}px 0px`,
        }
      ),
    [setIsCloseOrBelowViewportBottom]
  );

  useIntersectionObserver(observer, ref);

  return isCloseOrBelowViewportBottom;
};
