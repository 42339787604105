import { Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import createProfileImage from '../../../../../assets/images/shared/create-profile.svg';
import { WizardIllustratedInstruction } from '../../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { useWizardProfile } from '../../WizardProfileContext';

export const WizardProfileOnboardingV1Welcome: React.FC = () => {
  const wizardCtx = useWizardProfile();
  const { t } = useTranslation(['wizardProfile']);

  return (
    <WizardParentModalStepLayout
      contentSection={
        <VStack spacing={'4'}>
          <WizardIllustratedInstruction
            illustrationSrc={createProfileImage}
            illustrationAlt={t('wizardProfile:img.ModelPlatziertProfilbild')}
            header={t('wizardProfile:welcome.subHeader')}
            body={t('wizardProfile:welcome.introText')}
          />
          <VStack spacing={'2'}>
            <Button
              isLoading={wizardCtx.isUpdateMutationLoading}
              w={'full'}
              variant={'solid'}
              shadow={'none'}
              children={t('wizardProfile:welcome.createProfile')}
              onClick={() => wizardCtx.wizardNextStepCallback()}
            />
            <Button
              isDisabled={wizardCtx.isUpdateMutationLoading}
              w={'full'}
              shadow={'none'}
              children={t('wizardProfile:welcome.skipButton')}
              onClick={() => wizardCtx.wizardCloseCallback()}
            />
          </VStack>
        </VStack>
      }
    />
  );
};
