import * as icons from '@campoint/odi-ui-icons';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { t } from 'i18next';

import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import {
  ContestGoldenText,
  ContestIcon,
  ContestTileBase,
} from './ContestTileBase';

export const ContestTileEndedParticipated: typeof ContestTileBase = (props) => {
  const contest = props.contest;

  return (
    <ContestTileBase
      color={'white'}
      gap={0}
      {...props}
      contestBackground={props.contest.images.payturSignupImage}
    >
      <Box p={'10px'} textAlign={'left'} width={'inherit'}>
        <ContestIcon contest={contest} />
      </Box>
      <ContestGoldenText
        textAlign={'center'}
        fontWeight={600}
        fontSize={'40px'}
        mt={12}
      >
        {contest.video
          ? contest.video?.likesCount
          : contest.photoalbum?.likesCount ?? 0}
      </ContestGoldenText>
      <ContestGoldenText
        textAlign={'center'}
        fontWeight={600}
        fontSize={'40px'}
        mb={4}
      >
        {t('contest:heading.XXXLikes')}
      </ContestGoldenText>
      <Text
        textAlign={'center'}
        fontSize={'16px'}
        px={10}
        lineHeight={1.2}
        fontWeight={300}
      >
        {t(
          'contest:text.MitUnserenTippsKannstDuDasBeimNachstenWettbewerbToppen'
        )}
      </Text>
      <Button
        variant={'ghost'}
        color={'white'}
        mt={2}
        _hover={{
          background: 'transparent',
        }}
        onClick={props.goToMedia}
      >
        {t('contest:button.ZumVideo', {
          type:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Post')
              : t('contest:label.Video'),
        })}
        <Icon as={icons.ArrowOutward} ml={2} mt={'-4px'} size={'md'} />
      </Button>
    </ContestTileBase>
  );
};
