import { FetchResult } from '@apollo/client/link/core';
import { useDisclosure } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { FeedCommentModal } from '../components/shared/FeedComment/FeedCommentModal';
import {
  CreateEditCommentMutation,
  CreateInitialCommentMutation,
  CreateReplyCommentMutation,
  DeleteCommentMutation,
  FeedConversationFragment,
  FeedNodeFragment,
  FeedUserFragment,
  GetFeedPostCommentingProviderDocument,
  useCreateEditCommentMutation,
  useCreateInitialCommentMutation,
  useCreateReplyCommentMutation,
  useDeleteCommentMutation,
  useGetFeedPostCommentingProviderLazyQuery,
} from '../generated/feed';
import { createContext } from '../hooks/useContext';
import { useFeedPostQueryParams } from '../hooks/useQueryParamState';
import Logger from '../utils/Logger';
import { useAuth } from './AuthProvider';

export type PostCommentingContext = {
  readonly isLoading: boolean;
  readonly post: FeedNodeFragment;
  readonly hasInitialModelComment: boolean;
  /** un-submitted comments creates / edits exist */
  readonly isDirty: boolean;
  /** user which is the current "Creator" of comments */
  readonly account: Maybe<FeedUserFragment>;
  readonly conversations: FeedConversationFragment[];
  readonly isOpen: boolean;
  readonly action: {
    readonly showModal: (postId: string) => void;
    readonly closeModal: () => void;
    readonly addComment: (
      text: string
    ) => Promise<FetchResult<CreateInitialCommentMutation>>;
    readonly addReply: (
      text: string,
      commentId: string
    ) => Promise<FetchResult<CreateReplyCommentMutation>>;
    readonly editComment: (
      text: string,
      commentId: string
    ) => Promise<FetchResult<CreateEditCommentMutation>>;
    readonly registerOpenComment: () => void;
    readonly deleteComment: (
      commentId: string
    ) => Promise<FetchResult<DeleteCommentMutation>>;
  };
};
export const [, usePostCommenting, postCommenting] =
  createContext<PostCommentingContext>({
    name: 'PostCommentingContext',
    errorMessage:
      'useMedia: `PostCommentingContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });
type PostCommentingProviderOptions = {
  children?: React.ReactNode;
};

const feedCommentsModal = 'feedPostComments';

const Provider: React.FC<PostCommentingProviderOptions> = ({ children }) => {
  const { t } = useTranslation(['general']);
  // Can be used to block navigation from unsaved changes
  const [isDirty, setIsDirty] = React.useState(false);
  // Note: Might be beneficial to have a Map holding additional metadata
  const openCreateAndEditSet = React.useRef(new Set());

  const [modal, postId, setFeedPostQueryParams] = useFeedPostQueryParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, authUser } = useAuth();

  const [refetch, { data, loading: isLoading }] =
    useGetFeedPostCommentingProviderLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        Logger.error(error);
        issueChakraToast({
          status: 'error',
          description: t('general:toast.KommentareKonntenNichtGeladenWerden'),
        });
        onClose();
      },
    });

  const modelId = data?.post?.model.id;
  const userId = authUser?.userId;

  const refetchIfAllowed = React.useCallback(() => {
    const isFeedCommentsModal = modal === feedCommentsModal;
    if (isFeedCommentsModal && postId && !isOpen && isAuthenticated) {
      refetch({ variables: { postId } }).then(() => {
        if (modelId === userId) {
          onOpen();
        }
      });
    }
  }, [
    modal,
    postId,
    isOpen,
    isAuthenticated,
    refetch,
    modelId,
    userId,
    onOpen,
  ]);

  React.useEffect(() => {
    refetchIfAllowed();
  }, [refetchIfAllowed]);

  const post = React.useMemo(() => {
    return data?.post ?? ({} as FeedNodeFragment);
  }, [data?.post]);

  const [addCommentMutation] = useCreateInitialCommentMutation({
    refetchQueries: [GetFeedPostCommentingProviderDocument],
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        status: 'error',
      });
    },
  });

  const [addReplyMutation] = useCreateReplyCommentMutation({
    refetchQueries: [GetFeedPostCommentingProviderDocument],
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        status: 'error',
      });
    },
  });

  const [editCommentMutation] = useCreateEditCommentMutation({
    refetchQueries: [GetFeedPostCommentingProviderDocument],
    onCompleted: () => {
      issueChakraToast({
        description: t('general:toast.AnderungenWurdenGespeichert'),
        status: 'success',
      });
    },
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        status: 'error',
      });
    },
  });

  const [deleteCommentMutation] = useDeleteCommentMutation({
    refetchQueries: [GetFeedPostCommentingProviderDocument],
    onCompleted: () => {
      issueChakraToast({
        description: t('general:toast.KommentarWurdeGeloscht'),
        status: 'success',
      });
    },
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toasts.error'),
        status: 'error',
      });
    },
  });

  const addComment = React.useCallback(
    async (text: string) =>
      addCommentMutation({
        variables: {
          postId: postId!,
          text,
        },
      }),
    [addCommentMutation, postId]
  );

  const addReply = React.useCallback(
    async (text: string, commentId: string) =>
      addReplyMutation({
        variables: {
          postId: postId!,
          text,
          commentId,
        },
      }),
    [addReplyMutation, postId]
  );

  const editComment = React.useCallback(
    async (text: string, commentId: string) =>
      editCommentMutation({
        variables: {
          text,
          commentId,
        },
      }),
    [editCommentMutation]
  );

  const deleteComment = React.useCallback(
    async (commentId: string) =>
      deleteCommentMutation({
        variables: {
          commentId,
        },
      }),
    [deleteCommentMutation]
  );

  /**
   * let contained un-submitted comments creates / edits register in this provider,
   * so we know about how much of them currently exist
   *
   * @see isDirty
   */
  const registerOpenComment = React.useCallback(() => {
    const openQuestion = openCreateAndEditSet.current;
    // Simple solution to have a unique object reference,
    // that can be handled as such in a Set
    const uniqueObject = {};
    if (openQuestion) {
      openQuestion.add(uniqueObject);
    }
    setIsDirty(openCreateAndEditSet.current.size > 0);
    return () => {
      openQuestion.delete(uniqueObject);
      setIsDirty(openCreateAndEditSet.current.size > 0);
    };
  }, [openCreateAndEditSet, setIsDirty]);

  const account = React.useMemo(() => data?.account?.user ?? null, [data]);

  const conversations = React.useMemo(
    () => data?.post?.commentEntries.nodes ?? [],
    [data]
  );

  const hasInitialModelComment = React.useMemo(() => {
    return (
      conversations?.some(
        (conversation) => conversation.comment.user.id === account?.id
      ) ?? false
    );
  }, [conversations, account]);

  const action = React.useMemo(
    () => ({
      addComment,
      addReply,
      editComment,
      deleteComment,
      registerOpenComment,
      showModal: (postId: string) => {
        setFeedPostQueryParams({ modal: feedCommentsModal, postId });
        refetchIfAllowed();
      },
      closeModal: () => {
        setFeedPostQueryParams({ modal: null, postId: null });
        onClose();
      },
    }),
    [
      addComment,
      addReply,
      editComment,
      deleteComment,
      registerOpenComment,
      setFeedPostQueryParams,
      refetchIfAllowed,
      onClose,
    ]
  );

  const context: PostCommentingContext = React.useMemo(() => {
    return {
      post,
      isLoading,
      hasInitialModelComment,
      isDirty,
      conversations,
      account,
      action,
      isOpen,
    };
  }, [
    post,
    isLoading,
    hasInitialModelComment,
    isDirty,
    conversations,
    account,
    action,
    isOpen,
  ]);

  return (
    <postCommenting.Provider value={context}>
      {children}
      <FeedCommentModal />
    </postCommenting.Provider>
  );
};

export const PostCommentingProvider = Provider;
