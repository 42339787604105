import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { FeedPostStatisticsModal } from '../components/shared/FeedPostStatisticsModal/FeedPostStatisticsModal';
import {
  PostFragment,
  PostLikesFragment,
  PostSalesFragment,
  PostTipsFragment,
  useGetFeedPostStatisticsProviderLazyQuery,
} from '../generated/feed';
import { createContext } from '../hooks/useContext';
import { useFeedPostQueryParams } from '../hooks/useQueryParamState';
import Logger from '../utils/Logger';
import { useAuth } from './AuthProvider';

export type FeedPostStatisticsContext = {
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly post: PostFragment;
  readonly action: {
    readonly showModal: (postId: string) => void;
    readonly closeModal: () => void;
  };
  readonly postLikes: PostLikesFragment;
  readonly postTips: PostTipsFragment;
  readonly postSales: PostSalesFragment;
};

const feedStatisticsModal = 'feedPostStatistics';

export const [, useFeedPostStatistics, feedPostStatisticsContext] =
  createContext<FeedPostStatisticsContext>({
    name: 'FeedPostStatisticsContext',
    errorMessage:
      'useFeedPostStatistics: `FeedPostStatisticsContext` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const FeedPostStatisticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation(['feed']);
  const [modal, postId, setFeedPostQueryParams] = useFeedPostQueryParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, authUser } = useAuth();

  const [refetch, { data, loading: isLoading }] =
    useGetFeedPostStatisticsProviderLazyQuery({
      fetchPolicy: 'no-cache',
      onError: (error) => {
        Logger.error(error);
        issueChakraToast({
          status: 'error',
          description: t(
            'feed:toast.BeitragsstatistikKonnteNichtGeladenWerden'
          ),
        });
        onClose();
      },
    });

  const refetchIfAllowed = React.useCallback(() => {
    const isFeedStatisticsModal = modal === feedStatisticsModal;
    if (isFeedStatisticsModal && postId && !isOpen && isAuthenticated) {
      refetch({ variables: { postId } }).then(() => {
        if (data?.post?.model.id === authUser?.userId) {
          onOpen();
        }
      });
    }
  }, [
    authUser?.userId,
    data?.post?.model.id,
    isAuthenticated,
    isOpen,
    modal,
    onOpen,
    postId,
    refetch,
  ]);

  React.useEffect(() => {
    refetchIfAllowed();
  }, [refetchIfAllowed]);

  const post = React.useMemo(() => data?.post as PostFragment, [data]);
  const postLikes = React.useMemo(
    () => data?.postLikes as PostLikesFragment,
    [data]
  );
  const postTips = React.useMemo(
    () => data?.postTips as PostTipsFragment,
    [data]
  );
  const postSales = React.useMemo(
    () => data?.postSales as PostSalesFragment,
    [data]
  );

  const action = React.useMemo(
    () => ({
      showModal: (postId: string) => {
        setFeedPostQueryParams({ modal: feedStatisticsModal, postId });
        refetchIfAllowed();
      },
      closeModal: () => {
        setFeedPostQueryParams({ modal: null, postId: null });
        onClose();
      },
    }),
    [setFeedPostQueryParams, refetchIfAllowed, onClose]
  );

  const context: FeedPostStatisticsContext = React.useMemo(
    () => ({
      isLoading,
      isOpen,
      post,
      action,
      postLikes,
      postTips,
      postSales,
    }),
    [isLoading, isOpen, post, action, postLikes, postTips, postSales]
  );

  return (
    <feedPostStatisticsContext.Provider value={context}>
      <FeedPostStatisticsModal key={data?.post?.id} />
      {children}
    </feedPostStatisticsContext.Provider>
  );
};
