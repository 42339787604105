import {
  Close,
  Edit,
  ExpandLess,
  ExpandMore,
  Info,
} from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  Box,
  Button,
  Circle,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Img,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  ListItem,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spacer,
  SpacerProps,
  Switch,
  Text,
  UnorderedList,
  VStack,
  Wrap,
  chakra,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import DocumentsNeeded from '../../assets/images/videoLibrary/documentsNeeded.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import DatePicker from '../../components/shared/DatePicker/DatePicker';
import { MandatoryErrorMessageForCheckBox } from '../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { EnumSelectFieldHookForm } from '../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { FormControlHeaderStack } from '../../components/shared/FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { PreventImplicitFormSubmitOnEnter } from '../../components/shared/FormElements/PreventImplicitFormSubmitOnEnter/PreventImplicitFormSubmitOnEnter';
import { TagCloudControl } from '../../components/shared/FormElements/TagCloudControl/TagCloudControl';
import { TextAreaWithMaxCharCount } from '../../components/shared/FormElements/TextAreaWithMaxCharCount/TextAreaWithMaxCharCount';
import { GradientSpinner } from '../../components/shared/GradientSpinner/GradientSpinner';
import { CheckboxSingleControl } from '../../components/shared/HookFormForms/CheckboxSingleControl/CheckboxSingleControl';
import { ClearableInputControl } from '../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import VideoPlayer from '../../components/shared/VideoPlayer/VideoPlayer';
import { HTipCard } from '../../components/shared/cards/TipCard/TipCard';
import { ConfirmChangesDialog } from '../../components/shared/dialogs/ConfirmChangesDialog/ConfirmChangesDialog';
import {
  ConfirmPlannedVideoStatusChangeDialog,
  ConfirmPlannedVideoStatusChangeDialogProps,
} from '../../components/shared/dialogs/ConfirmPlannedVideoStatusChangeDialog/ConfirmPlannedVideoStatusChangeDialog';
import {
  GetContestDataDocument,
  GetVideoLibraryVideoUploadListDocument,
  GetVideoLibraryVideoUploadListEntryDocument,
  InputLangText,
  InputVideo,
  LanguagesEnum,
  Maybe,
  VideoFragment,
  VideoReleasedStatusEnum,
  VideoStatusEnum,
  VideoTypeEnum,
  useGetVideoLibraryPreviewPictureUploadUrlLazyQuery,
  useVideoLibraryUploadListEntryMutation,
} from '../../generated/graphql';
import { useBeforeUnload } from '../../hooks/useBeforeUnload';
import { useFlagsProviderV2 } from '../../provider/FlagsProviderV2';
import { useMinuteStamp } from '../../provider/MinuteStampProvider';
import { useVideoLibraryEntryModalContext } from '../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { useUploadyService } from '../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { TusUploadEntry } from '../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/videoUploadEnhancer';
import { LocalStorageKeys } from '../../types';
import { LocalStorage } from '../../utils';
import Logger from '../../utils/Logger';
import { coinsToEuro } from '../../utils/coinsToEuro';
import {
  getFormattedAmount,
  useFormattedAmount,
} from '../../utils/formattedAmount';
import { uploadImage } from '../../utils/media';
import {
  createDateValidationSchema,
  createStringValidationSchema,
} from '../../utils/validation';
import { VideoLibraryUploadListEntryPreviewPictureControl } from './VideoLibraryUploadListEntryPreviewPictureControl';

const fieldName = {
  title: 'title',
  description: 'description',
  tags: 'tags',
  price: 'price',
  discountEnabled: 'discountEnabled',
  discount: 'discount',
  discountDateRange: 'discountDateRange',
  online: 'online',
  publicationScheduledFor: 'publicationScheduledFor',
  confirmedOwndershipOfRequiredDocuments:
    'confirmedOwndershipOfRequiredDocuments',
  confirmedPublicationConsend: 'confirmedPublicationConsend',
  releasedStatus: 'releasedStatus',
  //...
} as const;

const fieldNameByTab = {
  0: [fieldName.title, fieldName.description, fieldName.tags],
  1: [
    fieldName.price,
    fieldName.discountEnabled,
    fieldName.discount,
    fieldName.discountDateRange,
    fieldName.online,
    fieldName.publicationScheduledFor,
    fieldName.releasedStatus,
  ],
  2: [
    fieldName.confirmedOwndershipOfRequiredDocuments,
    fieldName.confirmedPublicationConsend,
  ],
} as const;

type VideoLibraryEntryModalContent = {
  title: string;
  content: React.ReactNode;
};

export const VideoLibraryVideoUploadListEntryModal: React.FC = () => {
  const {
    isOpen,
    actions,
    video,
    videoLoading,
    editingUpload,
    editingUploadId,
    unsubmittedEdits,
  } = useVideoLibraryEntryModalContext();

  const {
    isOpen: isConfirmChangesDialogOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  const { allUploads } = useUploadyService();

  useEffect(() => {
    if (isOpen) {
      if (editingUpload) {
        if (editingUpload.error) {
          actions.closeModal();
        }
        if (editingUpload.rejectionReason) {
          issueChakraToast({
            description: editingUpload.rejectionReason,
            status: 'error',
          });
          actions.closeModal();
        }
      }
    }
  }, [isOpen, actions, editingUploadId, editingUpload, allUploads]);

  useBeforeUnload(isOpen && (!isEmpty(unsubmittedEdits) || !!editingUploadId));

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={() => {
        //hint
        if (isEmpty(unsubmittedEdits) && !editingUploadId) {
          actions.closeModal();
        } else {
          onOpen();
        }
      }}
      isVCentered={false}
      preferredSize="lg"
    >
      <ConfirmChangesDialog
        isOpen={isConfirmChangesDialogOpen}
        navigateBack={() => {
          actions.setUnsubmittedEdits({});
          onClose();
          actions.closeModal();
        }}
        onClose={onClose}
      />
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox bg={'surface'} minH={'56px'}>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        {(!videoLoading && video) || editingUpload ? (
          <ResponsiveModalContentBox
            video={video}
            editingUpload={editingUpload}
          />
        ) : (
          <AbsoluteFullCenterLoadingSpinner />
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const useVideoLibraryUploadListEntryScheduableTimeframe = () => {
  const { minuteStamp } = useMinuteStamp();

  return React.useMemo(() => {
    const currentMinute = DateTime.fromISO(minuteStamp);
    return {
      timezone: currentMinute.zone,
      min: currentMinute
        .plus({ minutes: 5 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      minEdit: currentMinute.plus({ minutes: 5 }).toISO({
        includeOffset: false,
        suppressSeconds: true,
      }),
      max: currentMinute
        .plus({ months: 3 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      checkDateEditable: (isoTimeStamp: string) => {
        return (
          (DateTime.fromISO(isoTimeStamp).diff(currentMinute, 'hours')?.hours ??
            0) > 1
        );
      },
    };
  }, [minuteStamp]);
};

const ResponsiveModalContentBox: React.FC<{
  video?: Maybe<VideoFragment> | undefined;
  editingUpload?: TusUploadEntry | null;
}> = ({ video, editingUpload }) => {
  const { t } = useTranslation(['videoEditModal', 'general']);
  const { coinsEnabled } = useFlagsProviderV2();
  const { actions, unsubmittedEdits, contest } =
    useVideoLibraryEntryModalContext();
  const scheduableTimeframe =
    useVideoLibraryUploadListEntryScheduableTimeframe();
  const initialValues = React.useMemo(() => {
    const setCorrectReleasedStatusField = (
      releasedStatus: VideoReleasedStatusEnum | null | undefined,
      isDateEditable: boolean | null | undefined
    ): VideoReleasedStatusEnum => {
      if (!releasedStatus) return VideoReleasedStatusEnum.Online;

      if (
        isDateEditable &&
        releasedStatus === VideoReleasedStatusEnum.Planned
      ) {
        return VideoReleasedStatusEnum.Planned;
      }
      return releasedStatus;
    };
    const formattedPlannedDate = video?.plannedReleaseDate
      ? DateTime.fromISO(video!.plannedReleaseDate).toISO({
          includeOffset: false,
        })
      : scheduableTimeframe.min;

    return {
      [fieldName.title]:
        video?.textCollection?.visitxTitles?.find(
          (title) => title?.lang?.toLowerCase() === 'de'
        )?.text ?? '',
      [fieldName.description]:
        video?.textCollection?.visitxDescriptions?.find(
          (description) => description?.lang?.toLowerCase() === 'de'
        )?.text ?? '',

      [fieldName.tags]:
        video?.tagCollection?.map((entry) => entry?.value) ?? [],
      [fieldName.price]: coinsEnabled
        ? video?.priceCoins && video?.priceCoins > 3
          ? video?.priceCoins
          : 3
        : video?.price ?? 0.49,
      [fieldName.discount]: coinsEnabled
        ? video?.discountCoins ?? 1
        : video?.discountPercent ?? 5,
      [fieldName.discountEnabled]: coinsEnabled
        ? video?.discountCoins ?? false
        : video?.discountPercent ?? false,
      [fieldName.discountDateRange]: [
        video?.discountStart ? new Date(video!.discountStart) : undefined,
        video?.discountEnd ? new Date(video!.discountEnd) : undefined,
      ],
      [fieldName.online]: true,
      [fieldName.publicationScheduledFor]: formattedPlannedDate,
      [fieldName.releasedStatus]: setCorrectReleasedStatusField(
        video?.releasedStatus,
        video?.isPlannedReleaseDateEditable
      ),
      [fieldName.confirmedOwndershipOfRequiredDocuments]:
        video?.allIDCardsUploaded ?? false,
      [fieldName.confirmedPublicationConsend]:
        video?.allModelReleaseFormsUploaded ?? false,
    };
  }, [video, scheduableTimeframe.min, coinsEnabled]);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [fieldName.title]: createStringValidationSchema({
        minLength: 1,
        maxLength: 60,
        isOptional: false,
      }),
      [fieldName.description]: createStringValidationSchema({
        maxLength: 2000,
        isOptional: false,
      }),

      [fieldName.publicationScheduledFor]: createDateValidationSchema({
        isOptional: false,
        minDate: scheduableTimeframe.min,
        maxDate: scheduableTimeframe.max,
      })
        .when([fieldName.releasedStatus], (releasedStatus, schema) =>
          releasedStatus !== VideoReleasedStatusEnum.Planned
            ? Yup.date()
            : schema
        )
        .typeError(
          t(
            'general:field.error.UngultigesDatumBitteNutzeDasKalenderIconAufDerRechtenSeite'
          )
        ),
      [fieldName.confirmedOwndershipOfRequiredDocuments]: Yup.boolean()
        .isTrue(() => <MandatoryErrorMessageForCheckBox />)
        .required(),
      [fieldName.confirmedPublicationConsend]: Yup.boolean()
        .isTrue(() => <MandatoryErrorMessageForCheckBox />)
        .required(),
    });
  }, [scheduableTimeframe.max, scheduableTimeframe.min, t]);

  const hookForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialValues, {
      keepDirtyValues: true,
      keepValues: true,
    });
  }, [hookForm, initialValues]);

  const videoJsOptions = {
    sources: [
      {
        src: video?.url,
        type: 'video/mp4',
      },
    ],
    poster: video?.previewPicture18?.image?.src,
  };

  /**
   * Handle Entry Update
   */

  const [updateUserVideoMutation, { loading: updateVideoLoading }] =
    useVideoLibraryUploadListEntryMutation({
      refetchQueries: [
        GetVideoLibraryVideoUploadListEntryDocument,
        GetContestDataDocument,
      ],
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });
  const { onDelete } = useUploadyService();
  const [getPreviewPictureUrl] =
    useGetVideoLibraryPreviewPictureUploadUrlLazyQuery();

  const onSubmit = React.useCallback(
    async (data: any) => {
      if (!video?.albumId) return;

      const uploadPreviewPictures = async ({
        albumId,
      }: {
        albumId: number;
      }) => {
        let initialResponse: {
          previewPicture16Id: Maybe<number>;
          previewPicture18Id: Maybe<number>;
        } = {
          previewPicture16Id: null,
          previewPicture18Id: null,
        };

        const previewBlob16 = unsubmittedEdits?.thumbnail16Replacement;
        const previewBlob18 = unsubmittedEdits?.thumbnail18Replacement;
        if (!previewBlob16 && !previewBlob18) return initialResponse;
        const uploadUrlResponse = await getPreviewPictureUrl({
          variables: {
            albumId: albumId,
          },
        });
        const uploadUrl =
          uploadUrlResponse?.data?.media?.videos?.pictureUploadUrl;

        if (!uploadUrl) return initialResponse;

        if (previewBlob16?.blob) {
          const { id } = await uploadImage(`${uploadUrl}`, previewBlob16.blob);
          initialResponse.previewPicture16Id = id ?? null;
        }
        if (previewBlob18?.blob) {
          const resp18 = await uploadImage(`${uploadUrl}`, previewBlob18.blob);
          initialResponse.previewPicture18Id = resp18.id ?? null;
        }
        return initialResponse;
      };

      const thumbnail16 = unsubmittedEdits?.thumbnail16Replacement;
      const { previewPicture16Id, previewPicture18Id } =
        await uploadPreviewPictures({
          albumId: video.albumId,
        });

      //If the upload didnt work we wont get an id back
      if (thumbnail16) {
        if (!previewPicture16Id && !thumbnail16.umpId) {
          issueChakraToast({
            description: t(
              'videoEditModal:toast.DasAb16VorschaubildIstEinDuplikatBitteNutzeEinAnderesBild'
            ),
            status: 'error',
          });
          return;
        }
      }
      //If the upload didnt work we wont get an id back
      const thumbnail18 = unsubmittedEdits?.thumbnail18Replacement;
      if (thumbnail18) {
        if (!previewPicture18Id && !thumbnail18.umpId) {
          issueChakraToast({
            description: t(
              'videoEditModal:toast.DasAb18VorschaubildIstEinDuplikatBitteNutzeEinAnderesBild'
            ),
            status: 'error',
          });
          return;
        }
      }

      const langs = [LanguagesEnum.De, LanguagesEnum.En, LanguagesEnum.Es];

      const isVideoFree = video?.type === VideoTypeEnum.VideoHotclip;

      // Sending the same data for all languages since sending empty strings for
      // other languages is not allowed by the mutation
      const titles: InputLangText[] = [
        {
          lang: LanguagesEnum.De,
          text: data[fieldName.title],
        },
        {
          lang: LanguagesEnum.En,
          text: data[fieldName.title],
        },
        {
          lang: LanguagesEnum.Es,
          text: data[fieldName.title],
        },
      ];
      const descriptions: InputLangText[] = langs.map((lang) => {
        return { lang: lang, text: data[fieldName.description] };
      });

      const price = isVideoFree ? null : data.price;
      const discount = !data.discountEnabled ? null : data.discount;

      try {
        const updateData: InputVideo = {
          albumId: video?.albumId ?? 0,
          titles: titles,
          descriptions: descriptions,
          tags: data.tags,
          priceCoins: coinsEnabled ? price : null,
          price: coinsEnabled ? null : price,
          discountCoins: coinsEnabled ? discount : null,
          discount: coinsEnabled ? null : discount,
          discountStart: data.discountEnabled
            ? data.discountDateRange?.[0] ?? null
            : null,
          discountEnd: data.discountEnabled
            ? data.discountDateRange?.[1] ?? null
            : null,
          releasedStatus: data.releasedStatus,
          plannedReleaseDate:
            data.releasedStatus === VideoReleasedStatusEnum.Planned &&
            !!data.publicationScheduledFor
              ? new Date(data.publicationScheduledFor)
              : null,
          previewPictureId16: previewPicture16Id ?? thumbnail16?.umpId,
          previewPictureId18: previewPicture18Id ?? thumbnail18?.umpId,
          allIDCardsUploaded: data.confirmedOwndershipOfRequiredDocuments,
          allModelReleaseFormsUploaded: data.confirmedPublicationConsend,
          contestId: contest?.id,
        };

        if (contest && contest.video?.albumId) {
          await onDelete(contest.video?.albumId, true);
        }

        updateUserVideoMutation({
          variables: {
            data: updateData,
          },
          onCompleted: () => {
            actions.setUnsubmittedEdits({});
            actions.closeModal();
            issueChakraToast({
              description: t('general:toast.AnderungenWurdenGespeichert'),
              status: 'success',
            });
            if (contest) {
              LocalStorage.add(
                LocalStorageKeys.PARTICIPATED_IN_VIDEO_CONTSEST_ID,
                contest.id
              );
            }
          },
          refetchQueries: [GetVideoLibraryVideoUploadListDocument],
          awaitRefetchQueries: true,
        });
      } catch (e) {
        Logger.error(e);
        issueChakraToast({
          description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
          status: 'error',
        });
      }
    },
    [
      actions,
      coinsEnabled,
      contest,
      getPreviewPictureUrl,
      onDelete,
      t,
      unsubmittedEdits?.thumbnail16Replacement,
      unsubmittedEdits?.thumbnail18Replacement,
      updateUserVideoMutation,
      video?.albumId,
      video?.type,
    ]
  );

  /**
   * Handle navigation in modal
   */
  const [tabActive, setTabActive] = React.useState(0);

  const tabContent: VideoLibraryEntryModalContent[] = [
    {
      title: t('videoEditModal:navBar.Details'),
      content: (
        <VideoLibraryEntryModalVideoDetailTab1
          video={video}
          editingUpload={editingUpload}
        />
      ),
    },
    {
      title: t('videoEditModal:navBar.Einstellungen'),
      content: (
        <VideoLibraryEntryModalVideoDetailTab2
          video={video}
          editingUpload={editingUpload}
        />
      ),
    },
    {
      title: t('videoEditModal:navBar.MRF'),
      content: <VideoLibraryEntryModalVideoDetailTab3 />,
    },
  ];

  // Todo: Find a way to make this work without using hookForm.watch()
  React.useEffect(() => {
    const subscription = hookForm.watch((data) => {
      const albumId = video?.albumId;
      if (albumId) {
        actions.setUnsubmittedEdits((prev) => ({
          ...prev,
          input: { ...(data as InputVideo), umaId: albumId },
        }));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [unsubmittedEdits, hookForm, video, actions]);

  const lastStep = tabActive === tabContent.length - 1;

  const onChangeTab = (newTab: number) => {
    const values = hookForm.getValues();
    const fieldNameListByTab = fieldNameByTab[tabActive as 0 | 1 | 2];
    fieldNameListByTab.forEach((name) => {
      hookForm.setValue(name, values[name], {
        shouldTouch: true,
      });
    });

    hookForm.trigger(fieldNameListByTab).then((isValid) => {
      if (isValid || newTab < tabActive) {
        setTabActive(newTab);
      }
    });
  };

  return (
    <>
      <VideoLibraryEntryModalNavbar
        tabActive={tabActive}
        setTabActive={onChangeTab}
        tabContent={tabContent}
      />
      {(!lastStep || !video) && (
        <>
          {video && video.url && (
            <AspectRatio ratio={16 / 9}>
              <VStack>
                <VideoPlayer options={videoJsOptions} formattedPrice={''} />
              </VStack>
            </AspectRatio>
          )}
          {video && !video.url && (
            <AspectRatio ratio={16 / 9}>
              <VStack
                backgroundColor={'steel'}
                color={'caribbeanGreen.500'}
                p={5}
              >
                <GradientSpinner
                  desiredSizeInPixel={50}
                  strokeWidthInPixel={5}
                />
                <Text textAlign={'center'}>
                  {t('videoEditModal:text.VideoWirdVerarbeitet')}
                </Text>
              </VStack>
            </AspectRatio>
          )}
          {!video && editingUpload && (
            <AspectRatio ratio={16 / 9}>
              <VStack backgroundColor={'steel'} color={'primary.500'}>
                <GradientSpinner
                  desiredSizeInPixel={50}
                  strokeWidthInPixel={5}
                />
                <Text>{Math.round(editingUpload.progress) + '%'}</Text>
              </VStack>
            </AspectRatio>
          )}
          <Text
            alignSelf={'end'}
            color={'darkSteel'}
            fontWeight={'normal'}
            fontSize={'sm'}
            pt={1}
            px={4}
          >
            {video?.albumId && 'Video-ID: ' + video.albumId}
            {!video?.albumId &&
              editingUpload &&
              'Request-ID: ' + editingUpload!.uploadRequestId}
          </Text>
        </>
      )}
      <ResponsiveModalBodyBox bg={'transparent'} pt={!lastStep ? 6 : 0}>
        <FormProvider {...hookForm}>
          <form>
            <PreventImplicitFormSubmitOnEnter />
            <VStack gap={6}>
              {tabContent[tabActive].content}
              {!lastStep ? (
                <Button
                  mt={2}
                  variant={'solid'}
                  type={'button'}
                  isLoading={
                    hookForm.formState.isSubmitting || updateVideoLoading
                  }
                  onClick={() => {
                    const newActiveTab = tabActive + 1;
                    onChangeTab(newActiveTab);
                  }}
                >
                  {t('videoEditModal:button.Weiter')}
                </Button>
              ) : (
                <Button
                  mt={2}
                  variant={'solid'}
                  type={'button'}
                  isLoading={
                    hookForm.formState.isSubmitting || updateVideoLoading
                  }
                  isDisabled={!video}
                  onClick={(e) => {
                    hookForm.handleSubmit(onSubmit)(e);
                  }}
                >
                  {!!editingUpload
                    ? t('general:button.save')
                    : t('videoEditModal:button.Aktualisieren')}
                </Button>
              )}
            </VStack>
          </form>
        </FormProvider>
      </ResponsiveModalBodyBox>
    </>
  );
};

type PriceSliderProps = {
  name: string;
  steps: number[];
  isDisabled?: boolean;
};

export const PriceSliderControlParent: React.FC<PriceSliderProps> = ({
  name,
  steps,
  isDisabled,
}) => {
  const { coinsEnabled } = useFlagsProviderV2();
  return coinsEnabled ? (
    <PriceSliderControlCoin name={name} steps={steps} isDisabled={isDisabled} />
  ) : (
    <PriceSliderControl name={name} steps={steps} isDisabled={isDisabled} />
  );
};

export const PriceSliderControl = ({
  name,
  steps,
  isDisabled,
}: PriceSliderProps) => {
  const { setValue } = useFormContext();

  const value: number = useWatch({ name });

  const possiblePrices = steps;
  const formattedEuroAmount = useFormattedAmount(value);
  const minFormatted = useFormattedAmount(possiblePrices[0]);
  const maxFormatted = useFormattedAmount(
    possiblePrices[possiblePrices.length - 1]
  );
  const thumbSize = 30;
  const min = 0;
  const max = possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    setValue(
      name,
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)],
      { shouldTouch: true }
    );
  }

  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        name={name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={curIndex}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'sm'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedEuroAmount}
          </SliderMark>
        </Box>
        <SliderMark value={min} mt={'4'} fontSize={'sm'}>
          {minFormatted}
        </SliderMark>
        <SliderMark value={max} mt={'4'} ml={'-14'} fontSize={'sm'}>
          {maxFormatted}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};
export const PriceSliderControlCoin = ({
  name,
  steps,
  isDisabled,
}: PriceSliderProps) => {
  const { setValue } = useFormContext();

  const value: number = useWatch({ name });

  const possiblePrices = steps;
  const formattedAmount = `${value} VXC`;
  const min = 0;
  const max =
    possiblePrices.length - 1 < min ? min + 1 : possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    //workaround so we disable the discount slider if the price is changed again
    if (name === 'price') {
      setValue('discountEnabled', false);
      setValue('discount', 1);
    }
    setValue(
      name,
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)],
      { shouldTouch: true }
    );
  }

  const thumbSize = 60;
  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={3} pt={8}>
      <Slider
        name={name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack
            bg={isDisabled ? 'gray.500' : undefined}
            bgGradient={
              isDisabled ? undefined : 'linear(to-r, primary.to, primary.from)'
            }
          />
        </SliderTrack>
        <SliderThumb
          borderRadius={100}
          bgGradient={'linear(to-r, primary.from, primary.to)'}
          w={thumbSize + 'px'}
          minH={'26px'}
          whiteSpace="nowrap"
          overflow="visible"
          left={`${thumbMargin()}!important`}
        >
          <Text
            px={'6px'}
            fontSize={'sm'}
            color={'surface'}
            fontWeight={'medium'}
            whiteSpace="nowrap"
          >
            {formattedAmount}
          </Text>
        </SliderThumb>
      </Slider>
    </Box>
  );
};

//beachte dass es für coins und euro unterschiedliche slider gibt
//bei dem einen werden prozente angezeigt und bei dem anderen nicht

type DiscountSliderControlType = {
  name: string;
  min: number;
  max: number;
  step: number;
  isDisabled?: boolean;
};

export const DiscountSliderControl = ({
  name,
  min,
  max,
  step,
  isDisabled,
}: DiscountSliderControlType) => {
  const { setValue } = useFormContext();

  const value: number = useWatch({ name });

  const formattedAmount = `${(value ?? min ?? 0).toFixed(0)} %`;
  const minSliderMark = `${(min ?? 0).toFixed(0)} %`;
  const maxSliderMark = `${(max ?? 0).toFixed(0)} %`;
  const thumbSize = 30;

  const thumbMargin = (): string => {
    const range = (value - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        isDisabled={isDisabled}
        defaultValue={value}
        min={min as any}
        max={max as any}
        step={step}
        focusThumbOnChange={false}
        onChange={(newValue) => {
          setValue(name, newValue, { shouldTouch: true });
        }}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={value}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'4px'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedAmount}
          </SliderMark>
        </Box>
        <SliderMark value={min} mt={'4'} fontSize={'sm'}>
          {minSliderMark}
        </SliderMark>
        <SliderMark value={max} mt={'4'} ml={'-12'} fontSize={'sm'}>
          {maxSliderMark}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};

const ClearableElement = forwardRef<Partial<IconButtonProps>, 'button'>(
  (props, ref) => (
    <IconButton
      ref={ref}
      me={2}
      size="sm"
      aria-label="clear"
      variant="ghost"
      color="onSurface.mediumEmphasis"
      colorScheme="gray"
      icon={<Icon as={Close} boxSize="icon.md" />}
      {...props}
    />
  )
);

const ClearableDatePickerInput = forwardRef<
  InputProps & {
    isClearShown: boolean;
    onClear: () => void;
  },
  'input'
>(({ isClearShown, onClear, ...inputProps }, ref) => {
  return (
    <InputGroup>
      <Input {...inputProps} ref={ref} />
      {isClearShown ? (
        <InputRightElement>
          <ClearableElement onClick={() => onClear()} />
        </InputRightElement>
      ) : (
        <InputRightElement pointerEvents={'none'}>
          <Icon as={Edit} boxSize={'icon.md'} color={'primary.300'} />
        </InputRightElement>
      )}
    </InputGroup>
  );
});

const DateRangePickerControl: React.FC<{
  isDisabled?: boolean;
  name: string;
  label: React.ReactNode;
  labelProps?: FormLabelProps;
  spacingProps?: SpacerProps;
}> = ({ name, label, labelProps, isDisabled, spacingProps }) => {
  const { t } = useTranslation(['videoEditModal', 'general']);
  const { setValue, setFocus } = useFormContext();
  const [startDate, endDate] = useWatch({ name });
  return (
    <FormControl isDisabled={isDisabled} {...spacingProps}>
      <FormControlHeaderStack>
        <FormLabel {...labelProps}>{label}</FormLabel>
      </FormControlHeaderStack>
      <DatePicker
        selectsRange={true as any}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setValue(name, update, { shouldTouch: true });
        }}
        disabled={isDisabled}
        isClearable={false}
        customInput={
          <ClearableDatePickerInput
            isClearShown={!!startDate || !!endDate}
            onClear={() => {
              setValue(name, [null, null], { shouldTouch: true });
              setFocus(name);
            }}
          />
        }
        popperPlacement="top-end"
        dateFormat={'dd.MM.yyyy'}
        placeholderText={t('videoEditModal:inputs.text.RabattzeitraumEingeben')}
      />
    </FormControl>
  );
};

const DateControl: React.FC<{
  name: string;
  label: React.ReactNode;
  helperText?: React.ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  inputProps?: InputProps;
}> = ({ name, label, helperText, isDisabled, isReadOnly, inputProps }) => {
  const { register, getFieldState, setFocus, formState, setValue } =
    useFormContext();
  const { error, isTouched } = getFieldState(name, formState);

  const value = useWatch({ name });
  const hasValue = !!value;

  return (
    <FormControl
      isInvalid={!!error && isTouched}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <FormControlHeaderStack>
        <FormLabel
          fontSize={'md'}
          fontWeight={'medium'}
          lineHeight={'5'}
          letterSpacing={'normal'}
          color={'coldGray.900'}
        >
          {label}
        </FormLabel>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup>
        <Input
          {...inputProps}
          type={'datetime-local'}
          {...register(name)}
          sx={{
            '::-webkit-datetime-edit': {
              visibility: hasValue ? 'unset' : 'hidden',
            },
          }}
        />
        {!isDisabled && hasValue && (
          <InputRightElement>
            <ClearableElement
              onClick={() => {
                setValue(name, null, { shouldTouch: true });
                setFocus(name);
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

const VideoLibraryEntryModalNavbar: React.FC<{
  tabActive: number;
  setTabActive: (newTab: number) => void;
  tabContent: VideoLibraryEntryModalContent[];
}> = ({ tabActive, setTabActive, tabContent }) => {
  return (
    <Wrap gap={2} alignSelf={'start'} w={'full'} p={4} pt={0}>
      {tabContent?.map((item, idx) => {
        const navBarColor = idx === tabActive ? 'gray.900' : 'gray.300';
        const navBarTextColor = idx === tabActive ? 'gray.900' : 'gray.300';
        const showDivider = idx < tabContent.length - 1;
        return (
          <HStack
            key={item.title}
            gap={2}
            color={navBarColor}
            flexGrow={showDivider ? 1 : 0}
          >
            <HStack onClick={() => setTabActive(idx)} cursor={'pointer'}>
              <Circle border={'1px'} size="16px" borderColor={navBarColor}>
                <Text fontSize={'12px'} fontWeight={'medium'}>
                  {idx + 1}
                </Text>
              </Circle>
              <Text fontSize={'12px'} fontWeight={700} color={navBarTextColor}>
                {item.title}
              </Text>
            </HStack>
            {showDivider && (
              <Divider
                flexGrow={1}
                w={'full'}
                border={'1px'}
                color={'darkSteel'}
              />
            )}
          </HStack>
        );
      })}
    </Wrap>
  );
};

const VideoLibraryEntryModalVideoDetailTab1: React.FC<{
  video?: Maybe<VideoFragment> | undefined;
  editingUpload?: TusUploadEntry | null;
}> = ({ video, editingUpload }) => {
  const { t } = useTranslation(['videoEditModal']);
  const maxTags = 10;
  const tags = (
    <TagCloudControl
      label={t('videoEditModal:inputs.heading.Tags', {
        tagCount: `(${
          useWatch({ name: fieldName.tags })?.length ?? 0
        }/${maxTags})`,
      })}
      isDisabled={(useWatch({ name: fieldName.tags })?.length ?? 0) >= maxTags}
      name={fieldName.tags}
    />
  );

  return (
    <VStack alignItems={'stretch'} w={'full'} divider={<Divider />} gap={4}>
      <VStack alignItems={'stretch'} w={'full'} gap={6}>
        <ClearableInputControl
          label={t('videoEditModal:inputs.heading.Titel')}
          name={fieldName.title}
          maxLength={60}
          placeholder={t('videoEditModal:inputs.text.TitelEingeben')}
        />
        <TextAreaWithMaxCharCount
          label={t('videoEditModal:inputs.heading.Beschreibung')}
          name={fieldName.description}
          maxCharacterCount={2000}
          textAriaProps={{
            rows: 3,
            placeholder: t('videoEditModal:inputs.text.BeschreibeDeinVideo'),
          }}
        />
      </VStack>
      <VideoLibraryUploadListEntryPreviewPictureControl />
      {video?.type !== VideoTypeEnum.VideoContest &&
        editingUpload?.type !== VideoTypeEnum.VideoContest &&
        tags}
    </VStack>
  );
};

interface VideoPriceCoinsConfig {
  min: number;
  max: number;
  steps: number[];
}

const VideoLibraryEntryModalVideoDetailTab2: React.FC<{
  video?: Maybe<VideoFragment> | undefined;
  editingUpload?: TusUploadEntry | null;
}> = ({ video, editingUpload }) => {
  const { register, resetField } = useFormContext();
  const scheduableTimeframe =
    useVideoLibraryUploadListEntryScheduableTimeframe();
  const { t } = useTranslation(['videoEditModal', 'contest', 'feed']);
  const locale = useIntl().locale;
  const { coinsEnabled } = useFlagsProviderV2();

  const plannedStatusChangeDisclosure = useDisclosure();

  const currentSelectedOption: VideoReleasedStatusEnum = useWatch({
    name: fieldName.releasedStatus,
  });

  const [previousReleasedStatus, setPreviousReleasedStatus] =
    React.useState<VideoReleasedStatusEnum>(currentSelectedOption);

  const isPlannedReleaseDateInPast = React.useMemo(() => {
    const todayDate = DateTime.local();
    const plannedReleaseDate = DateTime.fromISO(video?.plannedReleaseDate);
    return todayDate > plannedReleaseDate;
  }, [video]);

  const isPlannedSelected =
    currentSelectedOption === VideoReleasedStatusEnum.Planned;

  const onConfirmStatusChange = () => {
    plannedStatusChangeDisclosure.onClose();
  };

  const onDiscardStatusChange = () => {
    plannedStatusChangeDisclosure.onClose();
    resetField(fieldName.releasedStatus);
  };

  const confirmStatusChangeDialogOptions: ConfirmPlannedVideoStatusChangeDialogProps =
    {
      statusToChangeTo: currentSelectedOption,
      confirmChange: onConfirmStatusChange,
      discardChange: onDiscardStatusChange,
      isOpen: plannedStatusChangeDisclosure.isOpen,
      onClose: plannedStatusChangeDisclosure.onClose,
    };

  const handleStatusChange = () => {
    const isVideoRatingPending = video?.status === VideoStatusEnum.Rating;

    // Using allIDCardsUploaded in order to check if the video has been initially edited
    // as the value is always undefined/false when the video has not been edited
    const hasVideoBeenInitiallyEdited = video?.allIDCardsUploaded;

    const plannedToOnlineOrOffline =
      previousReleasedStatus === VideoReleasedStatusEnum.Planned &&
      currentSelectedOption !== VideoReleasedStatusEnum.Planned;

    /**
     * Modal will open if
     * 1.the video is has been approved by support
     * 2.the planned release date is in the future
     * 3.the video has been initially edited
     * 4.the user has changed the status from planned to something else
     * */
    const willModalOpen =
      !isVideoRatingPending &&
      !isPlannedReleaseDateInPast &&
      hasVideoBeenInitiallyEdited &&
      plannedToOnlineOrOffline;

    if (willModalOpen) {
      plannedStatusChangeDisclosure.onOpen();
    }
  };
  // Ignoring ESLint warning as `handleStatusChange` is defined outside `useEffect` and doesn't depend on state/props.
  // It won't change across re-renders, hence won't cause the effect to re-run.
  React.useEffect(() => {
    handleStatusChange();
    setPreviousReleasedStatus(currentSelectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedOption]);

  React.useEffect(() => {
    if (!isPlannedSelected) {
      resetField(fieldName.publicationScheduledFor);
    }
  }, [isPlannedSelected, resetField]);
  const isPriceAndDiscountHidden = video?.type === VideoTypeEnum.VideoHotclip;

  const isPlannedSelectable = () => {
    if (isPriceAndDiscountHidden) {
      return false;
    }
    if (!video?.isPlannedReleaseDateEditable) {
      return false;
    }
    return true;
  };

  const isOfflineSelectable = () => {
    if (video?.status === VideoStatusEnum.New) {
      return false;
    }
    if (!isPlannedReleaseDateInPast) {
      return false;
    }
    return true;
  };

  const isDiscountChecked = useWatch({
    name: fieldName.discountEnabled,
  });

  const priceConf: VideoPriceCoinsConfig = {
    min: 0.49,
    max: 19.99,
    steps: [
      0.49, 0.99, 1.49, 1.99, 2.49, 2.99, 3.49, 3.99, 4.49, 4.99, 5.49, 5.99,
      6.49, 6.99, 7.49, 7.99, 8.49, 8.99, 9.49, 9.99, 10.49, 10.99, 11.49,
      11.99, 12.49, 12.99, 13.49, 13.99, 14.49, 14.99, 15.49, 15.99, 16.49,
      16.99, 17.49, 17.99, 18.49, 18.99, 19.49, 19.99,
    ],
  };

  const coinsConf: VideoPriceCoinsConfig = {
    min: 3,
    max: 99,
    steps: [
      3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
      23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
      41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
      59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
      77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
      95, 96, 97, 98, 99,
    ],
  };

  const priceCoins = useWatch({ name: fieldName.price });
  const discountCoins = useWatch({ name: fieldName.discount });

  const coinsDiscountConf: VideoPriceCoinsConfig = React.useMemo(() => {
    return {
      min: 1,
      max: priceCoins - 1,
      steps: Array.from({ length: priceCoins - 1 }, (_, i) => i + 1),
    };
  }, [priceCoins]);

  return (
    <VStack alignItems={'stretch'} w={'full'} gap={6}>
      <FormControl hidden={isPriceAndDiscountHidden}>
        <FormLabel
          fontSize={'md'}
          fontWeight={'medium'}
          lineHeight={'5'}
          letterSpacing={'normal'}
          color={'coldGray.900'}
        >
          {t('videoEditModal:inputs.heading.Preis')}
        </FormLabel>
        <PriceSliderControlParent
          name={fieldName.price}
          steps={coinsEnabled ? coinsConf.steps : priceConf.steps}
        />
        {coinsEnabled && (
          <Text
            fontSize={'12px'}
            lineHeight={'16px'}
            color={'darkSteel'}
            fontWeight={'light'}
          >
            <Trans
              t={t}
              i18nKey={'feed:text.EntsprichtCaXEuro'}
              components={{
                price: (
                  <chakra.span fontWeight={'medium'}>
                    {`${getFormattedAmount(
                      coinsToEuro(Number(priceCoins)),
                      locale
                    )}`}
                  </chakra.span>
                ),
              }}
            />
          </Text>
        )}
      </FormControl>
      <Box>
        {(video?.type === VideoTypeEnum.VideoContest ||
          editingUpload?.type === VideoTypeEnum.VideoContest) && (
          <HTipCard
            text={t(
              'contest:text.NachEndeDesWettbewerbsWirdDeinVideoAutomatischEinShopvideoDanachKannXX'
            )}
          />
        )}
        <FormControl
          hidden={
            isPriceAndDiscountHidden ||
            video?.type === VideoTypeEnum.VideoContest ||
            editingUpload?.type === VideoTypeEnum.VideoContest
          }
        >
          <Divider />
          <HStack pt={6}>
            <FormLabel
              fontSize={'md'}
              fontWeight={'medium'}
              lineHeight={'5'}
              letterSpacing={'normal'}
              color={'coldGray.900'}
            >
              {t('videoEditModal:inputs.heading.Rabatt')}
            </FormLabel>
            <Spacer />
            <Switch
              isChecked={isDiscountChecked}
              size={'lg'}
              {...register(fieldName.discountEnabled)}
            />
          </HStack>
        </FormControl>
        <Box hidden={!isDiscountChecked}>
          {coinsEnabled ? (
            <>
              <PriceSliderControlCoin
                name={fieldName.discount}
                steps={coinsDiscountConf.steps}
              />
              <Text
                fontSize={'12px'}
                lineHeight={'16px'}
                color={'darkSteel'}
                fontWeight={'light'}
                pb={4}
              >
                <Trans
                  t={t}
                  i18nKey={'feed:text.GesamtbetragEntsprichtCaXEuro'}
                  components={{
                    price: (
                      <chakra.span fontWeight={'medium'}>
                        {`${getFormattedAmount(
                          coinsToEuro(Number(priceCoins - discountCoins)),
                          locale
                        )}`}
                      </chakra.span>
                    ),
                  }}
                />
              </Text>
            </>
          ) : (
            <DiscountSliderControl
              name={fieldName.discount}
              step={5}
              min={5}
              max={80}
            />
          )}
          <DateRangePickerControl
            name={fieldName.discountDateRange}
            label={t('videoEditModal:inputs.heading.RabattGultigkeit')}
            spacingProps={{
              pt: 2,
            }}
          />
        </Box>
      </Box>
      <VStack
        alignItems={'stretch'}
        w={'full'}
        gap={6}
        hidden={
          video?.type === VideoTypeEnum.VideoContest ||
          editingUpload?.type === VideoTypeEnum.VideoContest
        }
      >
        <Divider />
        <FormControl>
          <EnumSelectFieldHookForm
            label={''}
            name={fieldName.releasedStatus}
            isDisabled={false}
          >
            <option value={VideoReleasedStatusEnum.Online}>
              {t('videoEditModal:inputs.selectField.Online')}
            </option>
            {isOfflineSelectable() && (
              <option value={VideoReleasedStatusEnum.Offline}>
                {t('videoEditModal:inputs.selectField.Offline')}
              </option>
            )}
            {isPlannedSelectable() && (
              <option value={VideoReleasedStatusEnum.Planned}>
                {t('videoEditModal:inputs.selectField.VideoEinplanen')}
              </option>
            )}
          </EnumSelectFieldHookForm>
        </FormControl>
        <Box hidden={!isPlannedSelected}>
          <DateControl
            isDisabled={!isPlannedSelected}
            name={fieldName.publicationScheduledFor}
            label={t('videoEditModal:inputs.heading.Veroeffentlichungstermin')}
            helperText={t(
              'videoEditModal:inputs.text.NachFreigabeDesSupportsWirdDeinVideoAutomZumGewahltenZeitpunktVeroffeXX'
            )}
            inputProps={{
              min: scheduableTimeframe.min,
              max: scheduableTimeframe.max,
            }}
          />
        </Box>
        <ConfirmPlannedVideoStatusChangeDialog
          {...confirmStatusChangeDialogOptions}
        />
      </VStack>
    </VStack>
  );
};

const VideoLibraryEntryModalVideoDetailTab3: React.FC = () => {
  const { t } = useTranslation(['videoEditModal']);
  const documentsNeededDisc = useDisclosure();
  // get video from the context
  const { video } = useVideoLibraryEntryModalContext();

  return (
    <VStack alignItems={'stretch'} w={'full'}>
      <VStack
        border={'1px'}
        borderColor={'steel'}
        borderRadius={'8px'}
        p={0}
        mb={4}
      >
        <HStack
          w={'full'}
          borderBottom={documentsNeededDisc.isOpen ? '1px' : 'unset'}
          borderColor={'steel'}
          p={1}
          px={2}
          onClick={documentsNeededDisc.onToggle}
        >
          <HStack flex={1}>
            <Icon as={Info} />
            <Text fontWeight={'bold'}>
              {t('videoEditModal:inputs.text.BenotigteDokumente')}
            </Text>
          </HStack>
          {documentsNeededDisc.isOpen ? (
            <Icon as={ExpandLess} boxSize={'icon.md'} />
          ) : (
            <Icon as={ExpandMore} boxSize={'icon.md'} />
          )}
        </HStack>
        {documentsNeededDisc.isOpen && (
          <VStack px={4} pb={2}>
            <HStack gap={4}>
              <Img
                src={DocumentsNeeded}
                alt={t('videoEditModal:inputs.text.BenotigteDokumente')}
                minW={'65px'}
                minH={'75px'}
              />
              <UnorderedList
                fontSize={'xs'}
                fontWeight={'normal'}
                lineHeight={'20px'}
              >
                <ListItem>{t('videoEditModal:inputs.text.Ausweis')}</ListItem>
                <ListItem>{t('videoEditModal:inputs.text.IDShot')}</ListItem>
                <ListItem>
                  {t('videoEditModal:inputs.text.ModelReleaseFormMRF')}
                </ListItem>
              </UnorderedList>
            </HStack>
            <Text
              textAlign={'center'}
              pt={2}
              fontSize={'xs'}
              fontWeight={'normal'}
            >
              {t(
                'videoEditModal:inputs.text.JeweilsVomAccountInhaberModelHochzuladenUberDeinAccountmenu'
              )}
            </Text>
          </VStack>
        )}
      </VStack>
      <CheckboxSingleControl
        checkBoxProps={{ isDisabled: video?.allModelReleaseFormsUploaded }}
        name={fieldName.confirmedOwndershipOfRequiredDocuments}
        label={t(
          'videoEditModal:inputs.text.JaAlleAbgebildetenPersonenSindVolljaehrigUndIhreDokumenteSowieDasMRFLiegenVor'
        )}
      />
      <CheckboxSingleControl
        checkBoxProps={{ isDisabled: video?.allIDCardsUploaded }}
        name={fieldName.confirmedPublicationConsend}
        label={t(
          'videoEditModal:inputs.text.IchGarantiereDassAlleAbgebildetenPersonenMitIhrerDarstellungImContentSeinerVeroeffentlichungImInternetUndDerMoeglichkeitZumDownloadEinverstandenSindEineEntsprechendeVereinbarungLiegtVor'
        )}
      />
      <Text
        textStyle={'caption'}
        fontSize={'xs'}
        color={'darkSteel'}
        fontWeight={'medium'}
      >
        {t(
          'videoEditModal:inputs.text.PflichtangabeUmGesetzlichenAnforderungenZuEntsprechen'
        )}
      </Text>
    </VStack>
  );
};
