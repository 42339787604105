import { StackProps, VStack } from '@chakra-ui/react';

export interface LayoutHintsVStackProps {
  isWidthRestricted?: boolean;
  stackProps?: StackProps;
  children: React.ReactNode;
}

export const LayoutHintsVStack: React.FC<LayoutHintsVStackProps> = ({
  children,
  stackProps,
}) => {
  const spacing = 0;
  if (!children) return null;
  return (
    <VStack w={'full'} spacing={spacing} {...stackProps}>
      {children}
    </VStack>
  );
};
