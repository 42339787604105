import { useEffect } from 'react';

import { noop } from '../utils';
import { openDismissUnsavedChangesBrowserModal } from '../utils/utils';

/**
 * Custom hook for displaying a browser native prompt for the 'beforeunload' event.
 *
 * @param condition Condition which should be truthy to show the browser native prompt
 */
export const useBeforeUnload = (condition: boolean): void => {
  useEffect(() => {
    // Wrap the actual handler in a local function,
    // so multiple usages of this hook can not interfere in their event listening,
    // as the event handlers are independent
    const eventHandler = (event: BeforeUnloadEvent) =>
      openDismissUnsavedChangesBrowserModal(event);
    if (condition) {
      window.addEventListener('beforeunload', eventHandler);
      return () => window.removeEventListener('beforeunload', eventHandler);
    }
    return noop;
  }, [condition]);
};
