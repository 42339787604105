import * as React from 'react';

import { BlockerCard } from '../../../../components/shared/BlockerCard/BlockerCard';
import { useAccountStatus } from '../../../../provider/AccountStatusProvider/AccountStatusProvider';
import { useWizardProfile } from '../../../Wizard/Profile/WizardProfileContext';

export const ModelProfileSimpleInfoPage: React.FC<{
  image: string;
  heading: string;
  description: string;
  buttonText: string;
  imgAlt: string;
}> = ({ image, heading, description, buttonText, imgAlt }) => {
  const wizardCtx = useWizardProfile();
  const { actions } = useAccountStatus();

  return (
    <BlockerCard
      isBordered
      image={image}
      imageAlt={imgAlt}
      heading={heading}
      text={description}
      primaryButtonText={buttonText}
      onClickPrimary={() => {
        wizardCtx.setOnFinished(() => actions.refresh);
        wizardCtx.setVisible!(true);
      }}
    />
  );
};
