import { Divider, HStack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import React, { ReactNode, createContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { useGetSharingCoinsInfoQuery } from '../../../../generated/graphql';

type SharingInfoModalProps = {
  isInfoModalOpen: boolean;
  onInfoModalOpen: () => void;
  onInfoModalClose: () => void;
};

const SharingInfoModalContext = createContext<
  SharingInfoModalProps | undefined
>(undefined);

const SharingInfoModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();

  return (
    <SharingInfoModalContext.Provider
      value={{
        isInfoModalOpen,
        onInfoModalOpen,
        onInfoModalClose,
      }}
    >
      {children}
      <SharingInfoModal
        isInfoModalOpen={isInfoModalOpen}
        onInfoModalOpen={onInfoModalOpen}
        onInfoModalClose={onInfoModalClose}
      />
    </SharingInfoModalContext.Provider>
  );
};

const useSharingInfoModal = () => {
  const context = React.useContext(SharingInfoModalContext);
  if (context === undefined) {
    throw new Error(
      'useSharingInfoModal must be used within a SharingInfoModalProvider'
    );
  }
  return context;
};

export { useSharingInfoModal, SharingInfoModalProvider };

const SharingInfoModal: React.FC<SharingInfoModalProps> = ({
  isInfoModalOpen,
  onInfoModalClose,
}) => {
  const { t } = useTranslation(['statisticsPage']);
  const { data, loading } = useGetSharingCoinsInfoQuery();
  return (
    <ResponsiveModal
      isOpen={isInfoModalOpen}
      onClose={onInfoModalClose}
      preferredSize="xl"
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'surface'} p={4}>
          {loading ? null : (
            <VStack spacing={6} alignItems={'left'} p={4}>
              <VStack spacing={'8px'} alignItems={'left'}>
                <Text fontWeight={700} fontSize={'18px'} lineHeight={'20px'}>
                  {t('text.WieSiehtEineBeispielrechnungMitVXCoinsAus')}
                </Text>
                <Text
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  color={'gray.500'}
                >
                  {t('text.DieseBeispielRechnungsZeigtWieEineUserAufladungXX')}
                </Text>
              </VStack>
              <VStack spacing={3} alignItems={'left'}>
                {data?.sharing?.informations.map((item, index) => (
                  <React.Fragment key={index}>
                    <Divider />
                    <HStack justify={'space-between'}>
                      <Text
                        fontWeight={400}
                        fontSize={'14px'}
                        lineHeight={'20px'}
                      >
                        {item?.label}
                      </Text>
                      <Text
                        fontWeight={400}
                        fontSize={'14px'}
                        lineHeight={'20px'}
                      >
                        {item?.text}
                      </Text>
                    </HStack>
                  </React.Fragment>
                ))}
              </VStack>
              <Text
                fontWeight={400}
                lineHeight={'15px'}
                fontSize={'12px'}
                textAlign={'center'}
                color={'gray.500'}
              >
                {t('text.VergütungJe10XX')}
              </Text>
            </VStack>
          )}
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
