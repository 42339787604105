import { Center, CenterProps } from '@chakra-ui/react';
import React from 'react';

export const AbsoluteFullCenter: React.FC<CenterProps> = (props) => (
  <Center
    position={'absolute'}
    // unfortunatly we can't use inset: '0' here yet
    left={0}
    right={0}
    top={0}
    bottom={0}
    {...props}
  />
);
