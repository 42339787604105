import { MailOutline } from '@campoint/odi-ui-icons';
import { Alert, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from '../../../../../routes/routesConfig';
import { EmailVerificationRouterState } from '../VerifyEmailModal/VerifyEmailModal';

type EmailNotVerifiedHintProps = {
  email: string;
};

const EmailNotVerifiedHint: React.FC<EmailNotVerifiedHintProps> = (props) => {
  const { t } = useTranslation(['home']);
  const history = useHistory();

  const navigateToEmailVerification = () => {
    history.push(routes.home.virtualSubRoutes.verifyEmailModal, {
      source: 'home',
    } as EmailVerificationRouterState);
  };

  return (
    <Box onClick={navigateToEmailVerification} cursor={'pointer'}>
      <Alert maxW={'container.xl'} status={'error'} mx={'auto'}>
        <AlertIcon as={MailOutline} color={'error.highEmphasis'} />
        <AlertTitle>
          {t('home:emailNotVerifiedHint.text', {
            email: props.email,
          })}
        </AlertTitle>
      </Alert>
    </Box>
  );
};

export default EmailNotVerifiedHint;
