import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { MultiTextareaV2 } from '../../../../components/shared/FormElements/MultiTextareaV2/MultiTextareaV2';
import { ProfileFieldTypeMultilangStringFragment } from '../../../../generated/graphql';
import { enrichedMultiLangFieldData } from '../../../../utils/utils';
import { MultiLangLabelTextWithGuideDotV2 } from '../LabelTextWithGuideDot/LabelTextWithGuideDot';

export const OldMultiLangFieldWithGuideDotV2: React.FC<{
  field: Maybe<ProfileFieldTypeMultilangStringFragment>;
  label: string;
  placeholder?: string;
  helperText?: string;
}> = ({ field, label, placeholder, helperText }) => {
  if (!field) {
    return null;
  }

  const fields = enrichedMultiLangFieldData({
    field,
    placeholder,
  });

  return (
    <MultiTextareaV2
      label={
        <MultiLangLabelTextWithGuideDotV2 children={label} fields={fields} />
      }
      name={field.name}
      helperText={helperText}
      fields={fields}
    />
  );
};

export const OldMultiLangFieldV2: React.FC<{
  field: Maybe<ProfileFieldTypeMultilangStringFragment>;
  label: string;
  placeholder?: string;
  helperText?: string;
}> = ({ field, label, placeholder, helperText }) => {
  if (!field) {
    return null;
  }

  const fields = enrichedMultiLangFieldData({
    field,
    placeholder,
  });

  return (
    <MultiTextareaV2
      label={label}
      name={field.name}
      helperText={helperText}
      fields={fields}
    />
  );
};
