import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  HStack,
  Text,
  Textarea,
  TextareaProps,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import { useField } from 'formik';
import React from 'react';

import { BaseProps, FormControlHeaderStack } from '../FormControl';

export type TextareaControlProps = BaseProps & {
  textareaProps?: TextareaProps;
  labelProps?: FormLabelProps;
  helperTextProps?: FormHelperTextProps;
  maxCharacterCount?: number;
};

export const TextareaControl = forwardRef<TextareaControlProps, 'textarea'>(
  (props, ref) => {
    const {
      name,
      label,
      textareaProps,
      helperText,
      maxCharacterCount,
      isReadOnly,
      labelProps,
      helperTextProps,
      ...rest
    } = props;

    const textareaRef = React.useRef<HTMLTextAreaElement>();
    const [field, { error, touched }] = useField(name);

    React.useLayoutEffect(() => {
      const element = textareaRef.current;
      const adjustTextareaHeight = () => {
        if (element) {
          element.style.height = 'auto';
          element.style.height = `${element.scrollHeight}px`;
        }
      };
      element?.addEventListener('input', adjustTextareaHeight);

      return () => {
        element?.removeEventListener('input', adjustTextareaHeight);
      };
    }, [textareaRef]);

    return (
      <FormControl
        isInvalid={!!error && touched}
        isReadOnly={isReadOnly}
        minH={'8.315rem'}
        {...rest}
      >
        {(helperText || label) && (
          <FormControlHeaderStack>
            {label && <FormLabel {...labelProps}>{label}</FormLabel>}
            {helperText && (
              <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
            )}
          </FormControlHeaderStack>
        )}
        <Textarea
          {...field}
          resize="none"
          overflow="hidden"
          {...textareaProps}
          ref={mergeRefs(ref, textareaRef)}
        />
        <HStack pt={1} alignItems={'start'}>
          <VStack flex="1" align="flex-start">
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </VStack>
          {maxCharacterCount && !isReadOnly && (
            <Text
              fontSize="sm"
              fontWeight="light"
              color="onSurface.mediumEmphasis"
              pt={1}
            >
              {field?.value?.length ?? 0}/{maxCharacterCount}
            </Text>
          )}
        </HStack>
      </FormControl>
    );
  }
);
