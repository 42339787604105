import React, { useCallback, useState } from 'react';

import ENV from '../environments/environment';
import { noop } from '../utils';
import { isCookieCategoryAllowed } from '../utils/cookie';
import { gtag } from '../utils/gtag';
import { useAuth } from './AuthProvider';

export const googleTagManagerScriptId = 'google-tag-manager';

const googleTagManagerId = ENV.GOOGLE_TAG_MANAGER_ID;

export const optIn = () => {
  gtag('consent', 'update', {
    analytics_storage: 'granted',
  });
};
export const optOut = () => {
  gtag('consent', 'update', {
    analytics_storage: 'denied',
    wait_for_update: 500,
  });
};

export type GoogleAnalyticsContext = {
  actions: {
    initGoogleAnalytics: () => void;
  };
  readonly isGtagScriptInstalled: boolean;
};

export const initGoogleAnalyticsContext: GoogleAnalyticsContext = {
  actions: {
    initGoogleAnalytics: noop,
  },
  isGtagScriptInstalled: false,
};

export const googleAnalyticsContext =
  React.createContext<GoogleAnalyticsContext>(initGoogleAnalyticsContext);

const GoogleAnalyticsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isGtagScriptInstalled, setIsGtagScriptInstalled] =
    useState<boolean>(false);
  const { isVXAdminLoggedIn } = useAuth();

  const initGoogleAnalytics = useCallback(() => {
    const isAnalyticsAllowed = isCookieCategoryAllowed('analytics');
    const installAnalytics = isAnalyticsAllowed && !isVXAdminLoggedIn;

    if (isVXAdminLoggedIn) {
      console.info('The vx admin login turned off the tracking.');
    }

    if (!installAnalytics) {
      optOut();
    }

    if (installAnalytics) {
      optIn();
      installGoogleTagManagerScript(googleTagManagerId);

      setIsGtagScriptInstalled(true);
    }
  }, [isVXAdminLoggedIn]);

  return (
    <googleAnalyticsContext.Provider
      value={{
        actions: {
          initGoogleAnalytics,
        },
        isGtagScriptInstalled,
      }}
    >
      {children}
    </googleAnalyticsContext.Provider>
  );
};

export function useGoogleAnalyticsContext() {
  return React.useContext(googleAnalyticsContext);
}

export default GoogleAnalyticsProvider;

// Privacy controls in Google Analytics
// https://support.google.com/analytics/answer/9019185?hl=en#zippy=%2Cin-this-article
const installGoogleTagManagerScript = (trackingId: string) => {
  if (document.getElementById(googleTagManagerScriptId)) {
    return;
  }

  // Make sure the dataLayer property exists on the window (globally)
  const w = window as any;
  w.dataLayer = w.dataLayer || [];
  // And add gtm start event
  w.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });

  const { head } = document;
  const script = document.createElement('script');
  script.id = googleTagManagerScriptId;
  script.type = 'text/javascript';
  script.async = true;
  const url = new URL('https://www.googletagmanager.com/gtm.js');
  url.searchParams.set('id', trackingId);

  // Set known Google Tag Manager Environment for non-Prod
  const gtmAuth = ENV.GOOGLE_TAG_MANAGER_AUTH;
  const gtmPreview = ENV.GOOGLE_TAG_MANAGER_ENV;
  if (!ENV.PRODUCTION && !!gtmAuth && !!gtmPreview) {
    url.searchParams.set('gtm_auth', gtmAuth);
    url.searchParams.set('gtm_preview', gtmPreview);
    url.searchParams.set('gtm_cookies_win', 'x');
  }
  script.src = url.toString();
  head.insertBefore(script, head.firstChild);
};

export function useGoogleAnalytics() {
  return React.useContext(googleAnalyticsContext);
}
