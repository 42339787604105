import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Text,
  TextProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MediaPictureStatusEnum } from '../../../generated/graphql';
import { useMediaFlow } from '../../../provider/MediaFlowProvider';
import { useMediaInput } from '../../../provider/MediaInputProvider';
import { useMedia } from '../../../provider/MediaProvider';
import {
  MenuModal,
  MenuModalContent,
  MenuModalDownloadItem,
  MenuModalItem,
  MenuModalItemList,
  MenuModalOverlay,
} from '../FeedPostMenu/FeedPostMenu';
import { MediaPickerBox } from '../MediaPickerButton/MediaPickerButton';
import { DocumentPickerV3Status } from './DocumentPickerV3Status';

const DocumentPickerV3Name: React.FC<TextProps> = (textProps) => (
  <Text
    minW={0}
    maxW={'full'}
    textOverflow={'ellipsis'}
    overflow={'hidden'}
    whiteSpace={'nowrap'}
    {...textProps}
  />
);
export const DocumentPickerV3: React.FC<{
  named: string;
  uploadedFilename?: string;
  errorMessage?: string;
  isReadOnly?: boolean;
  expiresInDays?: number | null;
  isNewProofRequired?: boolean;
  newProofRequiredStatusRender?: (
    onOpenMediaFlow: () => void
  ) => React.ReactNode;
  expiringSoonStatusRender?: (onOpenMediaFlow: () => void) => React.ReactNode;
  expiredStatusRender?: (onOpenMediaFlow: () => void) => React.ReactNode;
}> = ({
  named,
  uploadedFilename,
  errorMessage,
  expiresInDays = null,
  isReadOnly,
  isNewProofRequired = false,
  newProofRequiredStatusRender,
  expiringSoonStatusRender,
  expiredStatusRender,
}) => {
  const { t } = useTranslation(['document', 'general', 'payout']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalFocusRef = React.useRef(null);
  const mediaFlowCtx = useMediaFlow();

  const { status, hasReplacement, initialSrc, media } = useMedia();

  const isExpired = expiresInDays === null ? false : expiresInDays <= 0;
  const isExpiringSoon =
    expiresInDays === null ? false : expiresInDays > 0 && expiresInDays <= 31;

  const borderColor = React.useMemo(() => {
    if (isReadOnly) {
      return 'coldGray.100';
    }
    if (hasReplacement) {
      return 'coldGray.100';
    }
    if (isNewProofRequired) {
      return 'error.500';
    }
    switch (status) {
      case MediaPictureStatusEnum.Rejected:
        return 'coldGray.100';

      case MediaPictureStatusEnum.Pending:
        return 'coldGray.100';

      case MediaPictureStatusEnum.Ok: {
        if (isExpired) {
          return 'error.500';
        }

        if (isExpiringSoon) {
          return 'caribbeanGreen.500';
        }

        return 'coldGray.100';
      }
      default:
        return 'coldGray.100';
    }
  }, [
    isReadOnly,
    hasReplacement,
    status,
    isExpired,
    isExpiringSoon,
    isNewProofRequired,
  ]);

  const onOpenMediaFlow = mediaFlowCtx.onOpen;
  const statusElement = React.useMemo(() => {
    if (isReadOnly) {
      return null;
    }
    if (hasReplacement || !!uploadedFilename) {
      return (
        <DocumentPickerV3Status
          icon={icons.Schedule}
          color={'caribbeanGreen.500'}
          text={t('general:status.Neu')}
        />
      );
    }
    if (isNewProofRequired) {
      return (
        newProofRequiredStatusRender?.(onOpenMediaFlow) ?? (
          <DocumentPickerV3Status
            icon={icons.Error}
            color={'error.500'}
            text={t('general:status.NeuerNachweisNotwendig')}
          />
        )
      );
    }
    switch (status) {
      case MediaPictureStatusEnum.Rejected:
        return null;
      case MediaPictureStatusEnum.Pending:
        return null;
      case MediaPictureStatusEnum.Ok: {
        if (isExpired) {
          return (
            expiredStatusRender?.(onOpenMediaFlow) ?? (
              <DocumentPickerV3Status
                icon={icons.Error}
                color={'error.500'}
                text={t('general:status.Abgelaufen')}
              />
            )
          );
        }

        if (isExpiringSoon) {
          return (
            expiringSoonStatusRender?.(onOpenMediaFlow) ?? (
              <DocumentPickerV3Status
                icon={icons.Error}
                color={'caribbeanGreen.500'}
                text={t('general:status.LauftInCountTagenAb', {
                  count: expiresInDays ?? 0,
                })}
              />
            )
          );
        }

        return null;
      }
      default:
        return null;
    }
  }, [
    isReadOnly,
    uploadedFilename,
    status,
    isExpired,
    isExpiringSoon,
    expiresInDays,
    hasReplacement,
    isNewProofRequired,
    t,
    newProofRequiredStatusRender,
    expiringSoonStatusRender,
    expiredStatusRender,
    onOpenMediaFlow,
  ]);

  // Might be changed to use mediaCtx?.media?.src
  const downloadUrl = initialSrc ?? '';

  const currentMediaFilename = media?.filename;
  const filename = React.useMemo(() => {
    if (!hasReplacement && !!uploadedFilename) {
      return uploadedFilename;
    }

    return currentMediaFilename ?? named;
  }, [hasReplacement, currentMediaFilename, uploadedFilename, named]);

  return (
    <>
      <FormControl isInvalid={!!errorMessage}>
        <Box
          w={'full'}
          p={2}
          pe={0}
          border={'1px'}
          borderRadius={'md'}
          borderColor={borderColor}
          flexShrink={1}
          minH={16}
          aria-label={named}
        >
          <HStack minH={'12'} flexShrink={1}>
            <Icon
              as={icons.DescriptionOutlined}
              boxSize={'icon.lg'}
              alignSelf={!statusElement ? 'center' : 'start'}
            />
            <VStack
              flexShrink={1}
              w={'full'}
              minW={0}
              alignItems={'start'}
              spacing={0}
            >
              <DocumentPickerV3Name>{filename}</DocumentPickerV3Name>
              {statusElement}
            </VStack>
            <IconButton
              aria-label={t('document:button.MehrOptionen')}
              variant={'ghost'}
              icon={
                <Icon
                  as={icons.MoreHoriz}
                  boxSize={'6'}
                  color={'coldGray.900'}
                />
              }
              onClick={onOpen}
            />
          </HStack>
        </Box>
        <FormErrorMessage>
          <FormErrorIcon />
          {errorMessage}
        </FormErrorMessage>
      </FormControl>
      <MenuModal
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={finalFocusRef}
      >
        <MenuModalOverlay
          bg="blackAlpha.300"
          backdropFilter="auto"
          backdropBlur={'base'}
        />
        <MenuModalContent>
          <MenuModalItemList>
            <MenuModalDownloadItem
              downloadUrl={downloadUrl}
              onClick={onClose}
              isDisabled={!downloadUrl}
            >
              {t('document:menuItem.Herunterladen')}
            </MenuModalDownloadItem>
            <MenuModalItem
              isDisabled={isReadOnly}
              onClick={() => {
                onClose();
                mediaFlowCtx.onOpen();
              }}
            >
              {t('document:menuItem.DateiAuswahlen')}
            </MenuModalItem>
            <MenuModalItem onClick={onClose}>
              {t('document:menuItem.Abbrechen')}
            </MenuModalItem>
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
    </>
  );
};

export const DocumentPickerV3ForWizard: React.FC<{
  named: string;
  uploadedFilename?: string;
  errorMessage?: React.ReactNode;
}> = ({ named, uploadedFilename, errorMessage }) => {
  const { t } = useTranslation(['document', 'general', 'payout']);
  const finalFocusRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isInvalid = !!errorMessage;

  const { status, hasReplacement, media, currentSrc } = useMedia();

  const { triggerFilePicker } = useMediaInput();

  const currentMediaFilename = media?.filename;
  const filename = React.useMemo(() => {
    if (!hasReplacement && !!uploadedFilename) {
      return uploadedFilename;
    }

    return currentMediaFilename ?? named;
  }, [hasReplacement, currentMediaFilename, uploadedFilename, named]);

  const statusElement = null;

  const borderColor = React.useMemo(() => {
    if (isInvalid) {
      return 'error.500';
    }
    if (hasReplacement) {
      return 'coldGray.100';
    }
    switch (status) {
      case MediaPictureStatusEnum.Rejected:
        return 'coldGray.100';

      case MediaPictureStatusEnum.Pending:
        return 'coldGray.100';

      case MediaPictureStatusEnum.Ok: {
        return 'coldGray.100';
      }
      default:
        return 'primary.500';
    }
  }, [isInvalid, hasReplacement, status]);

  return (
    <>
      <FormControl isInvalid={isInvalid}>
        <Box
          as={!currentSrc ? MediaPickerBox : undefined}
          display={'flex'}
          w={'full'}
          p={2}
          pe={0}
          border={'1px'}
          borderRadius={'md'}
          borderColor={borderColor}
          color={!!currentSrc ? 'inherit' : 'primary.500'}
          flexShrink={1}
          minH={16}
          aria-label={named}
          // cursor={currentSrc ? undefined : 'pointer'}
          // onClick={() => {
          //   if (!currentSrc) {
          //     triggerFilePicker();
          //   }
          // }}
        >
          {!currentSrc ? (
            <HStack minH={'12'} w={'full'}>
              <VStack
                flexShrink={1}
                w={'full'}
                alignItems={'center'}
                spacing={0}
              >
                <Icon as={icons.Upload} boxSize={'icon.md'} />
                <span>{t('document:button.DokumentHochladen')}</span>
              </VStack>
            </HStack>
          ) : (
            <HStack minH={'12'} flexShrink={1} w={'full'}>
              <Icon
                as={icons.DescriptionOutlined}
                boxSize={'icon.lg'}
                alignSelf={!statusElement ? 'center' : 'start'}
              />
              <VStack
                flexShrink={1}
                w={'full'}
                minW={0}
                alignItems={'start'}
                spacing={0}
              >
                <DocumentPickerV3Name>{filename}</DocumentPickerV3Name>
                {statusElement}
              </VStack>
              <IconButton
                aria-label={t('document:button.MehrOptionen')}
                variant={'ghost'}
                icon={
                  <Icon
                    as={icons.MoreHoriz}
                    boxSize={'6'}
                    color={'coldGray.900'}
                  />
                }
                onClick={onOpen}
              />
            </HStack>
          )}
        </Box>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
      <MenuModal
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={finalFocusRef}
      >
        <MenuModalOverlay
          bg="blackAlpha.300"
          backdropFilter="auto"
          backdropBlur={'base'}
        />
        <MenuModalContent>
          <MenuModalItemList>
            <MenuModalItem
              onClick={() => {
                onClose();
                triggerFilePicker();
              }}
            >
              {t('document:menuItem.DateiAuswahlen')}
            </MenuModalItem>
            <MenuModalItem onClick={onClose}>
              {t('document:menuItem.Abbrechen')}
            </MenuModalItem>
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
    </>
  );
};
