import React from 'react';
import { HStack, Button, Icon, VStack, Text } from '@chakra-ui/react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@campoint/odi-ui-icons';
import { useTranslation } from 'react-i18next';
import { useDateContext } from '../../provider/DateContext';

export const DetailedStatisticsDatePicker: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { yearInput, decrementYear, incrementYear, monthInput, decrementMonth, incrementMonth } = useDateContext();

  const months = t('statisticsPage:months', { returnObjects: true }) as string[];
  const formattedMonthValue = months[Number(monthInput.value) - 1];

  return (
    <VStack>
      <HStack h={'40px'}>
        {/* Year */}
        <HStack maxW={'200px'} borderRadius={'6px'} border={'1px'} borderColor={'steel'} backgroundColor={'surface'}>
          <Button borderColor={'transparent'} {...decrementYear}>
            <Icon as={KeyboardArrowLeft} boxSize={'icon.md'} />
          </Button>
          <Text
            textAlign="center"
            pointerEvents="none"
            _readOnly={{ bg: 'transparent' }}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderColor: 'transparent' }}
          >
            {yearInput.value}
          </Text>
          <Button borderColor={'transparent'} {...incrementYear}>
            <Icon as={KeyboardArrowRight} boxSize={'icon.md'} />
          </Button>
        </HStack>
        {/* Month */}
        <HStack maxW={'200px'} borderRadius={'6px'} border={'1px'} borderColor={'steel'} backgroundColor={'surface'}>
          <Button borderColor={'transparent'} {...decrementMonth}>
            <Icon as={KeyboardArrowLeft} boxSize={'icon.md'} />
          </Button>
          <Text
            textAlign="center"
            pointerEvents="none"
            _readOnly={{ bg: 'transparent' }}
            _focus={{ boxShadow: 'none' }}
            _hover={{ borderColor: 'transparent' }}
          >
            {formattedMonthValue}
          </Text>
          <Button borderColor={'transparent'} {...incrementMonth}>
            <Icon as={KeyboardArrowRight} boxSize={'icon.md'} />
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};