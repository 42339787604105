import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TermsPDFDownloadButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => {
    const { t } = useTranslation(['legal']);
    return (
      <Button
        target={'_blank'}
        children={t('legal:button.AlsPDFDownloaden')}
        ref={ref}
        {...props}
      />
    );
  }
);
