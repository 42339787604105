import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  PublicPageLayout,
  PublicPageMainSection,
  PublicPageMainSectionBox,
} from '../../components/Layout/PublicPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import { routes } from '../../routes/routesConfig';
import PasswordResetForm from './components/PasswordResetForm/PasswordResetForm';
import PasswordResetTriggeredHint from './components/PasswordResetTriggeredHint/PasswordResetTriggeredHint';

const PasswordResetPage: React.FC = () => {
  const history = useHistory();
  const [triggered, setTriggered] = useState(false);
  const { t } = useTranslation(['passwordReset']);

  const hideHint = () => setTriggered(false);
  const showHint = () => setTriggered(true);

  return (
    <PublicPageLayout
      pageTitle={t('passwordReset:navHeadline')}
      onClose={() => history.push(routes.login.path)}
    >
      <PublicPageMainSection>
        <ScrollToTopOnMount />
        <PublicPageMainSectionBox>
          {triggered ? (
            <PasswordResetTriggeredHint hideHint={hideHint} />
          ) : (
            <PasswordResetForm showHint={showHint} />
          )}
        </PublicPageMainSectionBox>
      </PublicPageMainSection>
    </PublicPageLayout>
  );
};

export default PasswordResetPage;
