import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Lock } from '@campoint/odi-ui-icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import {
  ErrorMessageWithLeadingIcon,
  MinLengthErrorMessage,
  PasswordMatchErrorMessage,
  RequiredErrorMessage,
  UniquePasswordErrorMessage,
} from '../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { PasswordClearableInputControl } from '../../../../components/shared/HookFormForms/PasswordClearableInputControl/PasswordClearableInputControl';
import {
  usePasswordSectionQuery,
  usePasswordUpdateMutation,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';

const fieldName = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmNewPassword: 'confirmNewPassword',
} as const;

interface FormValues {
  [fieldName.currentPassword]: string | null;
  [fieldName.newPassword]: string | null;
  [fieldName.confirmNewPassword]: string | null;
}

export const PasswordSection: React.FC<{}> = () => {
  const { t } = useTranslation(['accountPage', 'validation', 'passwordReset']);

  //  Is email address verified?
  const { data } = usePasswordSectionQuery();
  const isCurrentEmailAddressVerified = React.useMemo(() => {
    return data?.account.isRegistrationComplete;
  }, [data?.account.isRegistrationComplete]);

  // Mutations
  const [changePassword] = usePasswordUpdateMutation();

  const tryChangePassword = async (data: any) => {
    try {
      const response = await changePassword({
        variables: {
          currentPW: data[fieldName.currentPassword],
          newPW: data[fieldName.newPassword],
        },
      });

      const isMutationResultError =
        response.data?.auth?.changePassword?.__typename ===
        'PasswordSetResultFail';

      if (isMutationResultError) {
        return issueChakraToast({
          description: response.data?.auth?.changePassword?.message,
          status: 'error',
        });
      }

      issueChakraToast({
        description: response.data?.auth?.changePassword?.message,
        status: 'success',
      });

      hookForm.reset();
    } catch (error) {
      return issueChakraToast({
        description: t(
          'passwordReset:toast.PasswortKonnteNichtGeandertWerdenWendeDichAnDenSupport'
        ),
        status: 'error',
      });
    }
  };

  const passwordValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [fieldName.currentPassword]: !isCurrentEmailAddressVerified
          ? Yup.string()
          : Yup.string()
              .required(() => <RequiredErrorMessage />)
              .nullable()
              .transform((value) => (value === '' ? null : value)),

        [fieldName.newPassword]: !isCurrentEmailAddressVerified
          ? Yup.string()
          : Yup.string()
              .min(6, ({ min }) => <MinLengthErrorMessage min={min} />)
              .notOneOf([Yup.ref(fieldName.currentPassword)], () => (
                <UniquePasswordErrorMessage />
              ))
              .required(() => <RequiredErrorMessage />)
              .nullable()
              .transform((value) => (value === '' ? null : value)),
        [fieldName.confirmNewPassword]: !isCurrentEmailAddressVerified
          ? Yup.string()
          : Yup.string()
              .required(() => <RequiredErrorMessage />)
              .nullable()
              .oneOf([Yup.ref(fieldName.newPassword)], () => (
                <PasswordMatchErrorMessage />
              ))
              .transform((value) => (value === '' ? null : value)),
      }),
    [isCurrentEmailAddressVerified]
  );

  const hookForm = useForm<FormValues>({
    resolver: yupResolver(passwordValidationSchema),
    mode: 'all',
  });

  // Gray out "Save Changes" button if untouched & if invalid
  const formTouchedFields =
    Object.keys(hookForm.formState.touchedFields).length > 2;
  const isSavePasswordDisabled =
    (!hookForm.formState.isValid && !formTouchedFields) ||
    (hookForm.formState.isValid && !hookForm.formState.isDirty);

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);
  React.useEffect(() => {
    return registerDirtyFlag(hookForm.formState.isDirty, onOmitChanges);
  }, [
    hookForm,
    hookForm.formState,
    hookForm.formState.isDirty,
    onOmitChanges,
    registerDirtyFlag,
  ]);

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.PasswordSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Lock} />
            <SectionTitle
              children={t('accountPage:headline.PasswortSicherheit')}
            />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(tryChangePassword)}>
          <SectionBody>
            <SectionCenterContainer>
              <VStack spacing={6}>
                <VStack spacing={6} w={'full'}>
                  {!isCurrentEmailAddressVerified && (
                    <FormControl isInvalid>
                      <FormErrorMessage>
                        <ErrorMessageWithLeadingIcon>
                          {t(
                            'accountPage:text.BitteVerifiziereDeineEmailUmDeinPasswortZuAndern'
                          )}
                        </ErrorMessageWithLeadingIcon>
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  <PasswordClearableInputControl
                    label={t('accountPage:label.BisherigesPasswort')}
                    name={fieldName.currentPassword}
                    isDisabled={false}
                    placeholder={t(
                      'accountPage:placeholder.BisherigesPasswortEingeben'
                    )}
                    isReadOnly={!isCurrentEmailAddressVerified}
                  />
                </VStack>
                <VStack spacing={6} w={'full'}>
                  <PasswordClearableInputControl
                    label={t('accountPage:label.NeuesPasswort')}
                    name={fieldName.newPassword}
                    isDisabled={false}
                    placeholder={t(
                      'accountPage:placeholder.NeuesPasswortEingeben'
                    )}
                    isReadOnly={!isCurrentEmailAddressVerified}
                  />
                  <PasswordClearableInputControl
                    label={t('accountPage:label.NeuesPasswortWiederholen')}
                    name={fieldName.confirmNewPassword}
                    isDisabled={false}
                    placeholder={t(
                      'accountPage:placeholder.NeuesPasswortWiederholen'
                    )}
                    isReadOnly={!isCurrentEmailAddressVerified}
                  />
                </VStack>
                <Button
                  alignSelf={'center'}
                  children={t('accountPage:button.AnderungSpeichern')}
                  variant={'solid'}
                  type="submit"
                  isDisabled={isSavePasswordDisabled}
                  isLoading={hookForm.formState.isSubmitting}
                />
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
        </form>
      </FormProvider>
    </Section>
  );
};
