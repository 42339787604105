import { StackProps } from '@chakra-ui/react';
import React from 'react';

import { LayoutCenteringVStack } from '../../../../components/Layout/LayoutCenteringVStack';
import SetupPayoutCard from '../../../../components/shared/Payout/SetupPayoutCard/SetupPayoutCard';
import { CompleteOpenTasksCard } from '../../../../components/shared/cards/CompleteOpenTasksCard/CompleteOpenTasksCard';
import { useAccountStatus } from '../../../../provider/AccountStatusProvider/AccountStatusProvider';
import FinanceGoOnlineCard from '../card/GoOnlineCard/FinanceGoOnlineCard';

export const PriorToPayoutSetupSection: React.FC<{
  isSetupPayoutWizardActive: boolean;
  outerStackProps?: StackProps;
}> = ({ isSetupPayoutWizardActive, outerStackProps }) => {
  const {
    isAccountStatusLoading,
    incompletedOnboardingTaskCount,
    isAllowedToGoOnlineOnVXLive,
  } = useAccountStatus();

  if (isAccountStatusLoading) {
    return null;
  }

  if (!isSetupPayoutWizardActive) {
    return (
      <LayoutCenteringVStack outerStackProps={outerStackProps}>
        {isAllowedToGoOnlineOnVXLive ? (
          <FinanceGoOnlineCard />
        ) : (
          <CompleteOpenTasksCard
            remainingStepsCount={incompletedOnboardingTaskCount}
          />
        )}
      </LayoutCenteringVStack>
    );
  }
  return (
    <LayoutCenteringVStack outerStackProps={outerStackProps}>
      <SetupPayoutCard />
    </LayoutCenteringVStack>
  );
};
