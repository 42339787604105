import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { TaskAlt } from '@campoint/odi-ui-icons';
import {
  Box,
  Container,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import ExperienceIntentionImageCard from '../../components/shared/ExperienceIntentionImageCard/ExperienceIntentionImageCard';
import { ExperienceIntentionStatusIndicator } from '../../components/shared/ExperienceIntentionStatusIndicator/ExperienceIntentionStatusIndicator';
import { FilterToolRow } from '../../components/shared/FilterToolRow/FilterToolRow';
import { TipEntryFragment } from '../../generated/graphql';
import {
  TipDetailModalProvider,
  useTipDetailModal,
} from '../../provider/TipDetailModalProvider';
import {
  TipProvider,
  filteredTipListContext,
} from '../../provider/TipProvider';
import { TipFilterSheet } from './TipFilterSheet/TipFilterSheet';

export const TipsStatusIndicator: React.FC = () => {
  const { t } = useTranslation(['tipsPage']);

  return (
    <ExperienceIntentionStatusIndicator>
      <Icon as={TaskAlt} boxSize={'icon.md'} color={'white'} />
      <Text color={'white'}>{t('tipsPage:label.Gelesen')}</Text>
    </ExperienceIntentionStatusIndicator>
  );
};

const EntryCard: React.FC<{ entry: TipEntryFragment }> = ({ entry }) => {
  const tipDetailModalCtx = useTipDetailModal();

  return (
    <ExperienceIntentionImageCard
      key={entry.id}
      id={entry.id}
      entry={entry}
      onClick={() => tipDetailModalCtx.action.showTip(entry.id)}
      width={'full'}
      statusIndicator={entry.readAt && <TipsStatusIndicator />}
    />
  );
};

const TipsPage: React.FC = () => {
  const { t } = useTranslation(['tipsPage']);

  return (
    <TipProvider>
      <filteredTipListContext.Consumer>
        {(tipContext) => (
          <TipDetailModalProvider>
            <FluentPageLayout isContentLoading={tipContext.loading}>
              <ScrollToTopOnMount />
              <SubHeader>
                <Container px={0} maxW={'container.xl'}>
                  <SubHeaderTitle>
                    {t('tipsPage:headline.AlleTipps')}
                  </SubHeaderTitle>
                </Container>
              </SubHeader>
              <Container maxW="container.xl" p={0}>
                <VStack alignItems={'start'} spacing={'4'}>
                  <Box bg={'white'} w={'full'} p={3}>
                    <FilterToolRow
                      context={tipContext}
                      renderCountDisplay={(count) =>
                        t('tipsPage:text.CountTipps', {
                          count,
                        })
                      }
                    />
                    {tipContext.isFilterSheetOpen && (
                      <TipFilterSheet context={tipContext} />
                    )}
                  </Box>
                  <SimpleGrid
                    px={'4'}
                    pb={'6'}
                    gap={'4'}
                    columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
                  >
                    {tipContext.filteredEntries.map((entry) => (
                      <EntryCard key={entry.id} entry={entry} />
                    ))}
                  </SimpleGrid>
                </VStack>
              </Container>
            </FluentPageLayout>
          </TipDetailModalProvider>
        )}
      </filteredTipListContext.Consumer>
    </TipProvider>
  );
};

export default TipsPage;
