import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionTitleRow,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../../components/Layout/LayoutCenteringVStack';
import { LayoutHintsVStack } from '../../../components/Layout/LayoutHintsVStack';
import { ScrollToTargetBlock } from '../../../components/Layout/ScrollToTargetBlock';
import { ScrollToTargetInline } from '../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../components/Layout/UrlFragmentScrollToTarget';
import {
  RequiredErrorMessage,
  TooOldErrorMessage,
  TooYoungErrorMessage,
} from '../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { EnumSelectFieldHookForm } from '../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { GuideDot } from '../../../components/shared/GuideDot/GuideDot';
import { HeadingWithOptionalToggleAndGuideDot } from '../../../components/shared/HeadingWithOptionalToggleAndGuideDot/HeadingWithOptionalToggleAndGuideDot';
import { ClearableInputControl } from '../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { UpdateTaxInformationExpiredHintSlot } from '../../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import {
  DocumentSideEnum,
  DocumentStatusEnum,
  DocumentTypeEnum,
  useSaveActorMutation,
} from '../../../generated/graphql';
import useHistoryWithStorage from '../../../hooks/useHistoryWithStorage';
import { useDocumentDetailContext } from '../../../provider/DocumentDetailProvider/DocumentDetailProvider';
import { MediaFlowProvider } from '../../../provider/MediaFlowProvider';
import { MediaInputProvider } from '../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../provider/MediaPropertiesProvider';
import { MediaContext, MediaProvider } from '../../../provider/MediaProvider';
import { useNavigationBlock } from '../../../provider/NavigationBlockProvider';
import { routes } from '../../../routes/routesConfig';
import Logger from '../../../utils/Logger';
import { useDateFromTodayByYears } from '../../../utils/utils';
import { createStringValidationSchema } from '../../../utils/validation';
import { AgeVerificationCard } from '../ActorsDocumentsPage/components/AgeVerificationCard/AgeVerificationCard';
import { IdentityMediaFlow } from '../ActorsDocumentsPage/components/IdentityMediaFlow/IdentityMediaFlow';
import { IdentityProofPreviewBox } from '../ActorsDocumentsPage/components/IdentityProofPreviewBox/IdentityProofPreviewBox';
import {
  LeadingRoleMRFModal,
  PartnerKind,
} from '../CreateActorPage/CreateActorPage';
import { useDocumentUpload } from '../CreateActorPage/hooks/useDocumentUpload';
import { MrfDocumentView } from '../DocumentDetailPage/components/MrfDocumentView/MrfDocumentView';

const MissingDocumentErrorMessage: React.FC<{
  isShown: boolean;
}> = ({ isShown }) => {
  const { t } = useTranslation(['document']);

  return (
    <FormControl isInvalid={isShown}>
      <FormErrorMessage>
        <FormErrorIcon />
        {t('document:text.BitteLadeDasFehlendeDokumentHoch')}
      </FormErrorMessage>
    </FormControl>
  );
};

const MissingDocumentErrorMessage2: React.FC<{
  error?: string | null;
}> = ({ error }) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormErrorMessage>
        <FormErrorIcon />
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

const MissingDocumentNewMessage: React.FC<{}> = () => {
  const { t } = useTranslation(['general']);
  return (
    <HStack
      mt={1}
      lineHeight={'20px'}
      fontSize={'14px'}
      spacing={1}
      alignItems={'center'}
      flexShrink={1}
      maxW={'full'}
      color={'caribbeanGreen.500'}
    >
      <Icon as={icons.Schedule} boxSize={'16px'} />
      <Text
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        flexShrink={1}
        whiteSpace={'nowrap'}
        minW={'0'}
      >
        {t('general:status.Neu')}
      </Text>
    </HStack>
  );
};

const fieldName = {
  partnerKind: 'partnerKind',
  firstName: 'firstname',
  lastName: 'lastname',
  birthdate: 'birthday',
} as const;

interface FormValues {
  [fieldName.partnerKind]: string;
  [fieldName.firstName]: string;
  [fieldName.lastName]: string;
  [fieldName.birthdate]: string;
}

const minAge = 18;
const maxAge = 75;

export const ActorDocumentDetailPage: React.FC = () => {
  const optionModel: PartnerKind = 'model';
  const optionPartner: PartnerKind = 'partner';
  const birthdateMin = useDateFromTodayByYears(maxAge);
  const birthdateMax = useDateFromTodayByYears(minAge);

  const params = useParams<{ userId: string }>();
  const userIdNumber = Number(params.userId) ? Number(params.userId) : -1;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistoryWithStorage();
  const { t } = useTranslation([
    'document',
    'realPerson',
    'realPersonDetail',
    'general',
  ]);
  const {
    mappedAgeVerificationData,
    ageVerificationDataLoading,
    ageVerficicationDataRefetch,
    mappedMRFData,
    actor,
    idShotRequired,
  } = useDocumentDetailContext();

  const idShotRequiredForActor = idShotRequired(true);

  const spacing = { base: 4, lg: 8 };

  const areFieldsReadOnly = !actor?.isNew;

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [fieldName.firstName]: createStringValidationSchema({
        isOptional: false,
        minLength: 2,
        maxLength: 32,
      }),
      [fieldName.lastName]: createStringValidationSchema({
        isOptional: false,
        minLength: 2,
        maxLength: 32,
      }),
      [fieldName.birthdate]: Yup.string()
        .required(() => <RequiredErrorMessage />)
        .test((value, testContext) => {
          if (!value) {
            // Let requried handle this case, skip here.
            return true;
          }
          const parsedDate = DateTime.fromISO(value);
          if (parsedDate < DateTime.fromISO(birthdateMin)) {
            return testContext.createError({
              message: () => <TooOldErrorMessage years={maxAge} />,
            });
          }
          if (parsedDate > DateTime.fromISO(birthdateMax)) {
            return testContext.createError({
              message: () => <TooYoungErrorMessage years={minAge} />,
            });
          }
          return true;
        }),
    });
  }, [birthdateMin, birthdateMax]);

  const initialFormValues = React.useMemo(() => {
    const birthdayString = actor?.birthday
      ? DateTime.fromJSDate(new Date(actor?.birthday))
      : undefined;
    return {
      [fieldName.partnerKind]: actor?.isLeadActor ? optionModel : optionPartner,
      [fieldName.firstName]: actor?.address?.firstname ?? '',
      [fieldName.lastName]: actor?.address?.lastname ?? '',
      [fieldName.birthdate]: birthdayString?.toISODate() ?? '',
    };
  }, [
    actor?.address?.firstname,
    actor?.address?.lastname,
    actor?.birthday,
    actor?.isLeadActor,
  ]);

  const hookForm = useForm<FormValues>({
    defaultValues: {
      [fieldName.partnerKind]: '',
      [fieldName.firstName]: '',
      [fieldName.lastName]: '',
      [fieldName.birthdate]: '',
    },
    resolver: areFieldsReadOnly ? undefined : yupResolver(validationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialFormValues);
  }, [hookForm, initialFormValues]);

  const uploadDoc = useDocumentUpload();

  const [
    identityProofFrontOrEntireDocument,
    setIdentityProofFrontOrEntireDocument,
  ] = React.useState<null | MediaContext['media']>(null);
  const [
    identityProofFrontOrEntireDocumentError,
    setIdentityProofFrontOrEntireDocumentError,
  ] = React.useState<null | string>(null);
  const hasIdentityProofFrontOrEntireDocumentReplacement =
    !!identityProofFrontOrEntireDocument?.blob;

  const [identityProofBackDocument, setIdentityProofBackDocument] =
    React.useState<null | MediaContext['media']>(null);
  const [identityProofBackDocumentError, setIdentityProofBackDocumentError] =
    React.useState<null | string>(null);
  const hasIdentityProofBackDocumentReplacement =
    !!identityProofBackDocument?.blob;

  const [idShotDocument, setIdShotDocument] = React.useState<
    null | MediaContext['media']
  >(null);
  const [idShotDocumentError, setIdShotDocumentError] = React.useState<
    null | string
  >(null);
  const hasIdShotDocumentReplacement = !!idShotDocument?.blob;

  const issueFailedSubmitToast = React.useCallback(() => {
    issueChakraToast({
      status: 'error',
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
    });
  }, [t]);

  const issueSuccessSubmitToast = React.useCallback(() => {
    issueChakraToast({
      status: 'success',
      description: t('general:toast.AnderungenWurdenGespeichert'),
    });
  }, [t]);

  const [submittedSuccessfully, setSubmittedSuccessfully] =
    React.useState(false);

  const [saveActor] = useSaveActorMutation();

  const isDirty =
    hookForm.formState.isDirty ||
    !!hasIdentityProofFrontOrEntireDocumentReplacement ||
    !!hasIdentityProofBackDocumentReplacement ||
    !!hasIdShotDocumentReplacement;

  const isMissingRequiredIdentityProofFrontOrEntireReplacement =
    !hasIdentityProofFrontOrEntireDocumentReplacement &&
    (mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing ||
      mappedAgeVerificationData.documents?.identityProofFront?.status ===
        DocumentStatusEnum.Rejected);

  const isMissingRequiredIdentityProofBackReplacement =
    !hasIdentityProofBackDocumentReplacement &&
    (mappedAgeVerificationData.isIdentityProofBackRequiredButMissing ||
      mappedAgeVerificationData.documents?.identityProofBack?.status ===
        DocumentStatusEnum.Rejected);

  const isMissingRequiredIdShotReplacement =
    !hasIdShotDocumentReplacement &&
    (mappedAgeVerificationData.isIdShotRequiredButMissing ||
      mappedAgeVerificationData.documents?.idShot?.status ===
        DocumentStatusEnum.Rejected);

  const isFormSubmitDisabled =
    (!areFieldsReadOnly && !hookForm.formState.isValid) ||
    !isDirty ||
    isMissingRequiredIdShotReplacement ||
    isMissingRequiredIdentityProofBackReplacement ||
    isMissingRequiredIdentityProofFrontOrEntireReplacement;

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  React.useEffect(() => {
    return registerDirtyFlag(!submittedSuccessfully && isDirty);
  }, [isDirty, submittedSuccessfully, registerDirtyFlag]);

  const isLeadingActor = hookForm.watch(fieldName.partnerKind) === optionModel;

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      if (userIdNumber === -1) {
        issueFailedSubmitToast();
        return;
      }

      try {
        const birthdayString = DateTime.fromISO(data[fieldName.birthdate]);

        setIdentityProofFrontOrEntireDocumentError(null);
        setIdentityProofBackDocumentError(null);
        setIdShotDocumentError(null);

        if (!areFieldsReadOnly) {
          const result = await saveActor({
            variables: {
              data: {
                userId: userIdNumber,
                firstname: data[fieldName.firstName],
                lastname: data[fieldName.lastName],
                birthday: birthdayString.toISODate(),
                isLeadingActor,
              },
            },
          });

          const isSuccess = result?.data?.account?.saveActor?.success;

          if (!isSuccess) {
            issueFailedSubmitToast();
            Logger.error(result?.data?.account?.saveActor?.errorMessage);
            return;
          }
        }

        const hasBack = !!identityProofBackDocument?.blob;

        const uploadResponse0 =
          !hasIdentityProofFrontOrEntireDocumentReplacement
            ? undefined
            : await uploadDoc({
                documentToUpload: identityProofFrontOrEntireDocument,
                variables: {
                  userId: userIdNumber,
                  documentType: DocumentTypeEnum.IdentityProof,
                  side: !hasBack
                    ? DocumentSideEnum.EntireDocument
                    : DocumentSideEnum.Frontside,
                },
              });

        const uploadResponse1 = !hasIdentityProofBackDocumentReplacement
          ? undefined
          : await uploadDoc({
              documentToUpload: identityProofBackDocument,
              variables: {
                userId: userIdNumber,
                documentType: DocumentTypeEnum.IdentityProof,
                side: DocumentSideEnum.Backside,
              },
            });

        const uploadResponse2 = !hasIdShotDocumentReplacement
          ? undefined
          : await uploadDoc({
              documentToUpload: idShotDocument,
              variables: {
                userId: userIdNumber,
                documentType: DocumentTypeEnum.IdShot,
                side: DocumentSideEnum.EntireDocument,
              },
            });

        if (uploadResponse0?.message) {
          setIdentityProofFrontOrEntireDocumentError(uploadResponse0.message);
          issueFailedSubmitToast();
          return;
        }

        if (hasBack && uploadResponse1?.message) {
          setIdentityProofBackDocumentError(uploadResponse1.message);
          issueFailedSubmitToast();
          return;
        }

        if (uploadResponse2?.message) {
          setIdShotDocumentError(uploadResponse2.message);
          issueFailedSubmitToast();
          return;
        }
        await ageVerficicationDataRefetch();
        setIdentityProofFrontOrEntireDocument(null);
        setIdentityProofBackDocument(null);
        setIdShotDocument(null);
        issueSuccessSubmitToast();
      } catch (error) {
        Logger.error(error);
        issueFailedSubmitToast();
      }

      setSubmittedSuccessfully(true);
    },
    [
      userIdNumber,
      issueFailedSubmitToast,
      areFieldsReadOnly,
      identityProofBackDocument,
      hasIdentityProofFrontOrEntireDocumentReplacement,
      uploadDoc,
      identityProofFrontOrEntireDocument,
      hasIdentityProofBackDocumentReplacement,
      hasIdShotDocumentReplacement,
      idShotDocument,
      ageVerficicationDataRefetch,
      issueSuccessSubmitToast,
      saveActor,
      isLeadingActor,
    ]
  );

  return (
    <FluentPageLayout isContentLoading={ageVerificationDataLoading}>
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <HStack>
            <IconButton
              variant="unstyled"
              aria-label={'BackButtonEditPage'}
              icon={<Icon as={icons.ChevronLeft} boxSize="6" />}
              onClick={() => {
                history.push(routes.documentsActors.path);
              }}
            />
            <SubHeaderTitle>{mappedAgeVerificationData.name}</SubHeaderTitle>
          </HStack>
        </Container>
      </SubHeader>
      <Container maxW="container.xl" p={0}>
        <VStack w={'full'} spacing={spacing} mb={spacing}>
          <LayoutHintsVStack>
            <UpdateTaxInformationExpiredHintSlot
              outerStackProps={{ pt: spacing }}
            />
            {!mappedAgeVerificationData.isAgeVerificationWizardFinished && (
              <LayoutCenteringVStack outerStackProps={{ pt: spacing }}>
                <AgeVerificationCard />
              </LayoutCenteringVStack>
            )}
          </LayoutHintsVStack>
          {mappedAgeVerificationData.isAgeVerificationWizardFinished && (
            <Section>
              <SectionHeader>
                <SectionCenterContainer>
                  <SectionTitleRow>
                    <SectionTitle>
                      {t('realPersonDetail:heading.Altersverifizierung')}
                    </SectionTitle>
                  </SectionTitleRow>
                  <SectionDescription>
                    {t(
                      'realPersonDetail:text.MitDiesenAngabenVersicherstDuDassDeinPartnerVolljahrigIst'
                    )}
                  </SectionDescription>
                </SectionCenterContainer>
              </SectionHeader>
              <SectionDivider isWidthRestricted />
              <FormProvider {...hookForm}>
                <form onSubmit={(e) => hookForm.handleSubmit(onValidSubmit)(e)}>
                  <SectionBody>
                    <SectionCenterContainer>
                      <VStack
                        divider={<Divider />}
                        spacing={8}
                        alignItems={'stretch'}
                      >
                        <VStack spacing={6} alignItems={'stretch'}>
                          <EnumSelectFieldHookForm
                            label={t(
                              'realPersonDetail:text.WelcheRolleHatDeinPartner'
                            )}
                            name={fieldName.partnerKind}
                            isDisabled={areFieldsReadOnly}
                          >
                            <option value={optionModel}>
                              {t('realPersonDetail:text.Model')}
                            </option>
                            <option value={optionPartner}>
                              {t('realPersonDetail:text.Drehpartner')}
                            </option>
                          </EnumSelectFieldHookForm>
                          <ClearableInputControl
                            label={t('realPerson:label.Vorname')}
                            placeholder={t(
                              'realPerson:placeholder.VornamenEingeben'
                            )}
                            name={fieldName.firstName}
                            isReadOnly={areFieldsReadOnly}
                          />
                          <ClearableInputControl
                            label={t('realPerson:label.Nachname')}
                            placeholder={t(
                              'realPerson:placeholder.NachnamenEingeben'
                            )}
                            name={fieldName.lastName}
                            isReadOnly={areFieldsReadOnly}
                          />
                          <ClearableInputControl
                            label={t('realPerson:label.Geburtsdatum')}
                            placeholder={t(
                              'realPerson:placeholder.GeburtsdatumEingeben'
                            )}
                            name={fieldName.birthdate}
                            type={'date'}
                            sx={{
                              '&::-webkit-date-and-time-value': {
                                textAlign: 'left',
                              },
                            }}
                            min={birthdateMin}
                            max={birthdateMax}
                            isReadOnly={areFieldsReadOnly}
                          />
                        </VStack>

                        <MediaPropertiesProvider.ForIdentityProofFrontOrEntireDocument>
                          <MediaProvider.FromIdentityProofDocument
                            document={
                              mappedAgeVerificationData.documents
                                ?.identityProofFront
                            }
                            isRequiredButMissing={
                              mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing
                            }
                            pickedReplacement={
                              identityProofFrontOrEntireDocument
                            }
                            isEditable={!hookForm.formState.isSubmitting}
                          >
                            <MediaFlowProvider>
                              <ScrollToTargetBlock
                                id={
                                  UrlFragment.IdentityProofFrontOrEntireDocument
                                }
                              >
                                <HeadingWithOptionalToggleAndGuideDot
                                  heading={t(
                                    'realPersonDetail:heading.LadeEinFotoDerAusweisVorderseiteDeinesPartnersHoch'
                                  )}
                                  isRequired={
                                    mappedAgeVerificationData.isIdentityProofFrontRequired
                                  }
                                  isRequiredButMissing={
                                    (mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing &&
                                      !identityProofFrontOrEntireDocument?.blob) ||
                                    !!identityProofFrontOrEntireDocumentError
                                  }
                                />
                                <MissingDocumentErrorMessage
                                  isShown={
                                    mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing &&
                                    !identityProofFrontOrEntireDocument?.blob
                                  }
                                />
                                <MissingDocumentErrorMessage2
                                  error={
                                    identityProofFrontOrEntireDocumentError
                                  }
                                />
                                {hasIdentityProofFrontOrEntireDocumentReplacement && (
                                  <MissingDocumentNewMessage />
                                )}

                                <IdentityProofPreviewBox
                                  isUploading={hookForm.formState.isSubmitting}
                                />

                                <MediaInputProvider
                                  accept={'DEFAULT_FOR_PICTURE'}
                                >
                                  <IdentityMediaFlow
                                    skipSelfUpload={true}
                                    onMediaPick={(media) => {
                                      setIdentityProofFrontOrEntireDocument(
                                        media
                                      );
                                      setIdentityProofFrontOrEntireDocumentError(
                                        null
                                      );
                                    }}
                                  />
                                </MediaInputProvider>
                              </ScrollToTargetBlock>
                            </MediaFlowProvider>
                          </MediaProvider.FromIdentityProofDocument>
                        </MediaPropertiesProvider.ForIdentityProofFrontOrEntireDocument>
                        <MediaPropertiesProvider.ForIdentityProofBackDocument>
                          <MediaProvider.FromIdentityProofDocument
                            document={
                              mappedAgeVerificationData.documents
                                ?.identityProofBack
                            }
                            isRequiredButMissing={
                              mappedAgeVerificationData.isIdentityProofBackRequiredButMissing
                            }
                            pickedReplacement={identityProofBackDocument}
                            isEditable={!hookForm.formState.isSubmitting}
                          >
                            <MediaFlowProvider>
                              <ScrollToTargetBlock
                                id={UrlFragment.IdentityProofBackDocument}
                              >
                                <HeadingWithOptionalToggleAndGuideDot
                                  heading={t(
                                    'realPersonDetail:heading.LadeEinFotoDerAusweisRuckseiteDeinesPartnersHoch'
                                  )}
                                  isRequired={
                                    mappedAgeVerificationData.isIdentityProofBackRequired
                                  }
                                  isRequiredButMissing={
                                    (mappedAgeVerificationData.isIdentityProofBackRequiredButMissing &&
                                      !hasIdentityProofBackDocumentReplacement) ||
                                    !!identityProofBackDocumentError
                                  }
                                />
                                <MissingDocumentErrorMessage
                                  isShown={
                                    mappedAgeVerificationData.isIdentityProofBackRequiredButMissing &&
                                    !identityProofBackDocument?.blob
                                  }
                                />
                                <MissingDocumentErrorMessage2
                                  error={identityProofBackDocumentError}
                                />

                                {hasIdentityProofBackDocumentReplacement && (
                                  <MissingDocumentNewMessage />
                                )}

                                <IdentityProofPreviewBox
                                  isUploading={hookForm.formState.isSubmitting}
                                />

                                <MediaInputProvider
                                  accept={'DEFAULT_FOR_PICTURE'}
                                >
                                  <IdentityMediaFlow
                                    skipSelfUpload={true}
                                    onMediaPick={(media) => {
                                      setIdentityProofBackDocument(media);
                                      setIdentityProofBackDocumentError(null);
                                    }}
                                  />
                                </MediaInputProvider>
                              </ScrollToTargetBlock>
                            </MediaFlowProvider>
                          </MediaProvider.FromIdentityProofDocument>
                        </MediaPropertiesProvider.ForIdentityProofBackDocument>
                        {!mappedAgeVerificationData.documents?.idShot &&
                        !idShotRequiredForActor ? null : (
                          <MediaPropertiesProvider.ForIdShotDocument>
                            <MediaProvider.FromIdentityProofDocument
                              document={
                                mappedAgeVerificationData.documents?.idShot
                              }
                              isRequiredButMissing={
                                mappedAgeVerificationData.isIdShotRequiredButMissing
                              }
                              pickedReplacement={idShotDocument}
                              isEditable={!hookForm.formState.isSubmitting}
                            >
                              <MediaFlowProvider>
                                <ScrollToTargetBlock
                                  id={UrlFragment.IdShotDocument}
                                >
                                  <HeadingWithOptionalToggleAndGuideDot
                                    heading={t(
                                      'realPersonDetail:heading.LassDeinenPartnerEinenIdShotAufnehmen'
                                    )}
                                    isRequired={true}
                                    isRequiredButMissing={
                                      (mappedAgeVerificationData.isIdShotRequiredButMissing &&
                                        !idShotDocument?.blob) ||
                                      !!idShotDocumentError
                                    }
                                  />
                                  <Text
                                    textStyle={'bodySm'}
                                    color={'onSurface.mediumEmphasis'}
                                  >
                                    {t(
                                      'realPersonDetail:text.EinFotoVonDirAufDemDuDeinenAusweisGutLesbarNebenDeinGesichtHaltst'
                                    )}
                                  </Text>
                                  <MissingDocumentErrorMessage
                                    isShown={
                                      mappedAgeVerificationData.isIdShotRequiredButMissing &&
                                      !idShotDocument?.blob
                                    }
                                  />
                                  <MissingDocumentErrorMessage2
                                    error={idShotDocumentError}
                                  />

                                  {hasIdShotDocumentReplacement && (
                                    <MissingDocumentNewMessage />
                                  )}

                                  <IdentityProofPreviewBox
                                    isUploading={
                                      hookForm.formState.isSubmitting
                                    }
                                  />

                                  <MediaInputProvider
                                    accept={'DEFAULT_FOR_PICTURE'}
                                    capture={'user'}
                                  >
                                    <IdentityMediaFlow
                                      skipSelfUpload={true}
                                      onMediaPick={(media) => {
                                        setIdShotDocument(media);
                                        setIdShotDocumentError(null);
                                      }}
                                    />
                                  </MediaInputProvider>
                                </ScrollToTargetBlock>
                              </MediaFlowProvider>
                            </MediaProvider.FromIdentityProofDocument>
                          </MediaPropertiesProvider.ForIdShotDocument>
                        )}
                      </VStack>
                      <Button
                        mt={4}
                        isLoading={hookForm.formState.isSubmitting}
                        alignSelf={'center'}
                        onClick={(e) => hookForm.handleSubmit(onValidSubmit)(e)}
                        isDisabled={isFormSubmitDisabled}
                      >
                        {t('realPersonDetail:button.AnderungenSpeichern')}
                      </Button>
                    </SectionCenterContainer>
                  </SectionBody>
                </form>
              </FormProvider>
            </Section>
          )}
          {isLeadingActor && (
            <ActorDocumentDetailPageMRFSection
              modelReleaseForm={mappedMRFData.modelReleaseForm}
              onOpen={onOpen}
            />
          )}
        </VStack>
      </Container>
      <LeadingRoleMRFModal
        isOpen={isOpen}
        onClose={onClose}
        createdActor={false}
        userId={userIdNumber}
      />
    </FluentPageLayout>
  );
};

const ActorDocumentDetailPageMRFSection: React.FC<{
  modelReleaseForm: any;
  onOpen: () => void;
}> = ({ modelReleaseForm, onOpen }) => {
  const { t } = useTranslation(['document', 'payoutDocuments']);

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <ScrollToTargetInline id={UrlFragment.MrfDocument} />
          <HStack>
            <SectionTitle>
              {t('document:heading.ModelReleaseForm')}
            </SectionTitle>
            {!modelReleaseForm && (
              <>
                <Spacer />
                <GuideDot bg={'error.500'} isShown={true} />
              </>
            )}
          </HStack>
          <SectionDescription>
            {t(
              'document:text.MitDeinerUnterschriftBestatigstDuDieRechteAnAllenInhaltenSowieDieZusXX'
            )}
          </SectionDescription>
          <MissingDocumentErrorMessage isShown={!modelReleaseForm} />
        </SectionCenterContainer>
      </SectionHeader>
      <SectionBody>
        <SectionCenterContainer>
          <VStack w={'full'} alignItems={'stretch'}>
            {modelReleaseForm ? (
              <MrfDocumentView doc={modelReleaseForm} onRejected={onOpen} />
            ) : (
              <Button
                alignSelf={'center'}
                shadow={'none'}
                children={t(
                  'payoutDocuments:button.ModelReleaseFormAusfuellen'
                )}
                onClick={onOpen}
              />
            )}
          </VStack>
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};
