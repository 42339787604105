import {
  CountryEnum,
  ProfileFieldsEnum,
} from '../../../../../generated/graphql';

export namespace WPAddress {
  export const isModelOriginatorName =
    ProfileFieldsEnum.PayoutIsModelOriginator;
  export const firstNameName = ProfileFieldsEnum.PayoutAddressFirstname;
  export const lastNameName = ProfileFieldsEnum.PayoutAddressLastname;
  export const companyNameName = ProfileFieldsEnum.PayoutAddressCompanyname;
  export const streetName = ProfileFieldsEnum.PayoutAddressStreet;
  export const houseNumberName = ProfileFieldsEnum.PayoutAddressStreetNumber;
  export const cityName = ProfileFieldsEnum.PayoutAddressCity;
  export const postalCodeName = ProfileFieldsEnum.PayoutAddressPostalCode;
  export const countryName = ProfileFieldsEnum.PayoutAddressCountry;
  export const documentName = ProfileFieldsEnum.PayoutAddressDocument;
  export const documentIdName = ProfileFieldsEnum.PayoutAddressDocumentId;

  type SharedAddressInputs = {
    [streetName]: string;
    [houseNumberName]: string;
    [cityName]: string;
    [postalCodeName]: string;
    [countryName]: CountryEnum;
    [documentName]?: number;
    [documentIdName]?: number;
  };

  export type PersonalAddressInputs = {
    [firstNameName]: string;
    [lastNameName]: string;
  } & SharedAddressInputs;

  export type CompanyAddressInputs = {
    [companyNameName]: string;
  } & SharedAddressInputs;

  export const personalAddressInit: PersonalAddressInputs = {
    [firstNameName]: '',
    [lastNameName]: '',
    [streetName]: '',
    [houseNumberName]: '',
    [cityName]: '',
    [postalCodeName]: '',
    [countryName]: '' as CountryEnum,
  };

  export const companyAddressInit: CompanyAddressInputs = {
    [companyNameName]: '',
    [streetName]: '',
    [houseNumberName]: '',
    [cityName]: '',
    [postalCodeName]: '',
    [countryName]: '' as CountryEnum,
  };
}
