import {
  Box,
  BoxProps,
  ButtonGroup,
  Heading,
  HeadingProps,
  Img,
  ImgProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';

const UniversalCardContainer: React.FC<{
  children?: React.ReactNode;
}> = (props) => (
  <VStack
    layerStyle="card"
    boxShadow={'none'}
    spacing={4}
    p={4}
    textAlign="center"
    maxW={'600px'}
    {...props}
  />
);

const CardImage: React.FC<ImgProps> = (props) => (
  <Img sx={{ aspectRatio: '2/1' }} minH="24" {...props} />
);

const CardHeading: React.FC<HeadingProps> = (props) => (
  <Heading as="h5" size="lg" {...props} />
);

const CardText: React.FC<TextProps> = (props) => <Text {...props} />;

export type UniversalCardProps = {
  heading: string | JSX.Element;
  icon?: React.ReactNode;
  img?: { imgSrc: string; imgAltText?: string };
  text?: string;
  indicator?: React.ReactNode;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
};

export const UniversalCard: React.FC<UniversalCardProps & BoxProps> = ({
  icon,
  img,
  heading,
  text,
  indicator,
  primaryButton,
  secondaryButton,
  ...props
}) => {
  return (
    <UniversalCardContainer {...props}>
      {icon}
      {img?.imgSrc && <CardImage src={img.imgSrc} alt={img.imgAltText} p={0} />}
      <CardHeading fontSize={{ base: 'sm', lg: 'xl' }} children={heading} />
      {text && (
        <CardText
          children={text}
          fontSize={{ base: 'sm', lg: 'md' }}
          color={'gray.500'}
        />
      )}
      {indicator}
      {(primaryButton || secondaryButton) && (
        <ButtonGroup flexDir={'column'} gap={4} spacing={0}>
          {primaryButton}
          {secondaryButton}
        </ButtonGroup>
      )}
    </UniversalCardContainer>
  );
};

type UniversalCardSectionProps = {
  children: React.ReactNode;
};

export const UniversalCardSectionWithBorder: React.FC<
  BoxProps & UniversalCardSectionProps
> = ({ children }) => {
  return (
    <Box w={'full'}>
      <VStack
        border={'1px'}
        borderColor={'steel'}
        borderRadius={'lg'}
        bg={'surface'}
      >
        {children}
      </VStack>
    </Box>
  );
};

export const UniversalCardSection: React.FC<
  BoxProps & UniversalCardSectionProps
> = ({ children }) => {
  return (
    <Box w={'full'}>
      <VStack bg={'surface'}>{children}</VStack>
    </Box>
  );
};

export type ChakraUniversalCardProps = {
  cardBody: React.ReactNode;
  cardFooter?: React.ReactNode;
};

/**
 * Use Chakras Card Component to build the UniversalCard
 */
export const ChakraUniversalCard: React.FC<
  ChakraUniversalCardProps & BoxProps
> = ({ cardBody, cardFooter, ...props }) => {
  return (
    <VStack
      w={'full'}
      maxW={'calc(768px + 2 * 16px)'}
      bg={'white'}
      p={4}
      borderRadius={'lg'}
      gap={4}
      alignItems={'stretch'}
      {...props}
    >
      <VStack flexDirection={'column'} p={0} spacing={0} alignItems={'center'}>
        {cardBody}
      </VStack>
      {cardFooter && (
        <VStack
          flexDirection={'column'}
          p={0}
          spacing={0}
          alignItems={'center'}
        >
          {cardFooter}
        </VStack>
      )}
    </VStack>
  );
};
