import { Button, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import { ClearableInputControl } from '../../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { DateControl } from '../../../../../components/shared/HookFormForms/DateControl/DateControl';
import { useVerificationSaveActorMutation } from '../../../../../generated/graphql';
import { useMinuteStamp } from '../../../../../provider/MinuteStampProvider';
import {
  createDateValidationSchema,
  createStringValidationSchema,
} from '../../../../../utils/validation';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';

type WizardVerificationNameBirthdayProps = {
  goToNextStep: () => void;
  showLoader: boolean;
  userId: number | null;
};

const fieldName = {
  firstName: 'firstname',
  lastName: 'lastName',
  birthdate: 'birthdate',
} as const;

interface FormValues {
  [fieldName.firstName]: string;
  [fieldName.lastName]: string;
  [fieldName.birthdate]: string | Date;
}

const useWizardVerificationBirthdayScheduableTimeframe = () => {
  const { minuteStamp } = useMinuteStamp();

  return React.useMemo(() => {
    const currentMinute = DateTime.fromISO(minuteStamp);
    return {
      timezone: currentMinute.zone,
      max: currentMinute
        .minus({ year: 18 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
    };
  }, [minuteStamp]);
};

export const WizardVerificationNameBirthday: React.FC<
  WizardVerificationNameBirthdayProps
> = ({ goToNextStep, showLoader, userId }) => {
  const { t } = useTranslation([
    'accountPage',
    'general',
    'wizardVerification',
    'imagePicker',
    'mediaUpload',
  ]);
  const scheduableTimescale =
    useWizardVerificationBirthdayScheduableTimeframe();

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [fieldName.firstName]: createStringValidationSchema({
        isOptional: false,
        minLength: 2,
        maxLength: 50,
      }),
      [fieldName.lastName]: createStringValidationSchema({
        isOptional: false,
        minLength: 2,
        maxLength: 50,
      }),
      [fieldName.birthdate]: createDateValidationSchema({
        isOptional: false,
        maxDate: scheduableTimescale.max,
        maxDateMessage: ({ max }) => {
          return t('general:field.error.DuMusstMindestens18JahreAltSein');
        },
      }).typeError(
        t(
          'general:field.error.UngultigesDatumBitteNutzeDasKalenderIconAufDerRechtenSeite'
        )
      ),
    });
  }, [scheduableTimescale.max, t]);

  const hookForm = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const [saveActor] = useVerificationSaveActorMutation();

  const trySaveActor = async (data: any) => {
    const rawBirthdayValue = new Date(data[fieldName.birthdate]);
    const formattedBirthday = DateTime.fromJSDate(rawBirthdayValue).toISODate();

    try {
      await saveActor({
        variables: {
          data: {
            isLeadingActor: true,
            userId: userId,
            firstname: data[fieldName.firstName],
            lastname: data[fieldName.lastName],
            birthday: formattedBirthday,
          },
        },
      });
      goToNextStep();
    } catch (e) {
      issueChakraToast({
        status: 'error',
        description: t('wizardVerification:nameSubmitError'),
      });
    }
  };

  const isLoading = showLoader;

  return (
    <WizardParentModalStepLayout
      contentSection={
        <>
          <WizardSubHeader
            headerText={t('wizardVerification:nameAndBirthday.subHeader')}
            bottomMarginEnabled={false}
          />
          <FormProvider {...hookForm}>
            <VStack spacing={6} pt={'16px'}>
              <ClearableInputControl
                name={fieldName.firstName}
                label={t('accountPage:label.Vorname')}
              />
              <ClearableInputControl
                name={fieldName.lastName}
                label={t('accountPage:label.Nachname')}
              />
              <DateControl
                name={fieldName.birthdate}
                label={t('accountPage:label.Geburtsdatum')}
                max={scheduableTimescale.max}
              />
            </VStack>
          </FormProvider>
        </>
      }
      footerSection={
        <ButtonStack>
          <Button
            children={t('general:button.Weiter')}
            variant={'solid'}
            minW={'12rem'}
            isLoading={isLoading}
            isDisabled={isLoading || !hookForm.formState.isValid}
            onClick={hookForm.handleSubmit(trySaveActor)}
          />
        </ButtonStack>
      }
    />
  );
};
