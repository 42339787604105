import * as React from 'react';
import { CropperProps, MediaSize } from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { CroppedImage } from '../components/shared/ImageCropper/ImageCropper';
import { createContext } from '../hooks/useContext';
import { getCroppedImage } from '../utils/media/imagePickerHelper';
import {
  getOrientationFromImageDimension,
  getRotatedImageDimensions,
} from '../utils/imageCropper.utils';
import { sleep } from '../utils/utils';
import { useMediaProperties } from './MediaPropertiesProvider';

export type ImageCropperContext = {
  readonly isProcessing: boolean;
  readonly showGrid: boolean;
  readonly action: {
    readonly resetCrop: () => void;
    readonly rotateCW90: () => void;
    readonly generateCroppedImage: () => void;
    readonly cancel: () => void;
    readonly toggleGrid: () => void;
  };
  readonly cropperProps: Partial<CropperProps>;
  readonly croppedAreaPixels: Area | null;
};

export const [, useImageCropperContext, imageCropperContext] =
  createContext<ImageCropperContext>({
    errorMessage:
      'useImageCropperContext: `ImageCropperContext` is undefined. Seems you forgot to wrap component within the Provider',
    name: 'ImageCropperContext',
  });

export const ImageCropperProvider: React.FC<{
  src: string;
  onImage?: (image: CroppedImage | undefined | null) => void;
  children?: React.ReactNode;
  initialCroppedAreaPercentages?: Area;
}> = ({ src, onImage, initialCroppedAreaPercentages, children }) => {
  const { t } = useTranslation(['validation']);
  const [crop, setCrop] = React.useState<Point>(
    !!initialCroppedAreaPercentages
      ? {
          x: initialCroppedAreaPercentages.x,
          y: initialCroppedAreaPercentages.y,
        }
      : { x: 0, y: 0 }
  );
  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area | null>(
    null
  );
  const [mediaSize, setMediaSize] = React.useState<{
    width: number;
    height: number;
    naturalWidth: number;
    naturalHeight: number;
  } | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const [showGrid, setShowGrid] = React.useState(false);

  const toggleGrid = React.useCallback(() => {
    setShowGrid((prevState) => !prevState);
  }, [setShowGrid]);

  const resetCrop = React.useCallback(() => {
    setRotation(0);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  }, []);

  const rotateCW90 = React.useCallback(() => {
    setRotation((prevState) => (prevState + 90) % 360);
  }, [setRotation]);

  const snapRotation = React.useCallback(() => {
    setRotation((prevState) => (45 * Math.round(prevState * 45)) % 360);
  }, [setRotation]);

  const onCropComplete = React.useCallback<
    (croppedArea: Area, croppedAreaPixels: Area) => void
  >((croppedArea, croppedAreaPixelsParameter) => {
    setCroppedAreaPixels(croppedAreaPixelsParameter);
  }, []);

  const {
    targetDimensions: targetImageDimensions,
    orientation,
    isCircular,
    enforceTargetDimension: enforceTargetImageDimensions,
    enforceMinimalDimension,
    determineAspectRatioByInput,
    minimalDimension,
    action: { setOrientation },
  } = useMediaProperties();

  const orientedTargetImageDimensions = getRotatedImageDimensions(
    targetImageDimensions,
    orientation
  );

  const orientedMediaImageDimensions = React.useMemo(() => {
    if (!mediaSize) {
      return null;
    }
    return getRotatedImageDimensions(
      {
        width: mediaSize?.naturalWidth ?? 1,
        height: mediaSize?.naturalHeight ?? 1,
      },
      orientation
    );
  }, [mediaSize, orientation]);

  const aspectRatio = React.useMemo(() => {
    if (determineAspectRatioByInput && orientedMediaImageDimensions) {
      return (
        orientedMediaImageDimensions.width / orientedMediaImageDimensions.height
      );
    }

    return (
      orientedTargetImageDimensions.width / orientedTargetImageDimensions.height
    );
  }, [
    determineAspectRatioByInput,
    orientedTargetImageDimensions,
    orientedMediaImageDimensions,
  ]);

  const minZoom = 1.0;

  const onMediaLoaded = React.useCallback(
    (ms: MediaSize) => {
      if (determineAspectRatioByInput) {
        const determinedOrientation = getOrientationFromImageDimension(ms);
        setOrientation(determinedOrientation);
      }
      setMediaSize(ms);
    },
    [setMediaSize, setOrientation, determineAspectRatioByInput]
  );

  const cropperProps = React.useMemo<Partial<CropperProps>>(() => {
    return {
      image: src,
      minZoom,
      crop,
      initialCroppedAreaPercentages,
      rotation,
      zoom,
      aspect: aspectRatio,
      showGrid,
      cropShape: isCircular ? 'round' : 'rect',
      onCropChange: setCrop,
      onRotationChange: setRotation,
      onCropComplete: onCropComplete,
      onZoomChange: setZoom,
      onMediaLoaded: onMediaLoaded,
    };
  }, [
    minZoom,
    src,
    crop,
    rotation,
    zoom,
    showGrid,
    aspectRatio,
    isCircular,
    onCropComplete,
    onMediaLoaded,
    initialCroppedAreaPercentages,
  ]);

  const generateCroppedImage = React.useCallback(async () => {
    if (!croppedAreaPixels) {
      return;
    }
    setIsProcessing(true);
    // Wait a tick, so the processing state can propagate
    await sleep(0);
    const cropped = await getCroppedImage(
      src,
      enforceTargetImageDimensions ?? false,
      orientedTargetImageDimensions,
      crop,
      croppedAreaPixels,
      rotation
    );

    // Intercept pictures that ar cropped too small
    if (cropped) {
      const currentMinimalDimension = Math.min(
        cropped.size.width,
        cropped.size.height
      );

      if (
        enforceMinimalDimension &&
        currentMinimalDimension < minimalDimension
      ) {
        issueChakraToast({
          status: 'error',
          description: t(
            'validation:error.AusschnittZuKleinBreiteUndHoheMussenJeweilsMindMinSizePxBetragenAktuXX',
            {
              minSize: minimalDimension,
              width: cropped.size.width,
              height: cropped.size.height,
            }
          ),
        });
        setIsProcessing(false);
        return;
      }
    }

    onImage?.(cropped);
  }, [
    src,
    croppedAreaPixels,
    crop,
    rotation,
    enforceTargetImageDimensions,
    orientedTargetImageDimensions,
    onImage,
    t,
    enforceMinimalDimension,
    minimalDimension,
  ]);

  const cancel = React.useCallback(async () => {
    onImage?.(undefined);
  }, [onImage]);

  const action = React.useMemo(
    () => ({
      resetCrop,
      rotateCW90,
      generateCroppedImage,
      cancel,
      snapRotation,
      toggleGrid,
    }),
    [
      resetCrop,
      rotateCW90,
      generateCroppedImage,
      cancel,
      snapRotation,
      toggleGrid,
    ]
  );

  const context = React.useMemo<ImageCropperContext>(
    () => ({
      showGrid,
      isProcessing,
      action,
      cropperProps,
      croppedAreaPixels,
      orientation,
    }),
    [
      showGrid,
      isProcessing,
      action,
      cropperProps,
      croppedAreaPixels,
      orientation,
    ]
  );

  return <imageCropperContext.Provider value={context} children={children} />;
};
