import { TextareaProps, forwardRef, useMergeRefs } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';

import { AutoResizeTextarea } from '../AutoResizeTextarea/AutoResizeTextarea';

type FeedCommentTextareaProps = {
  commentAutoFocus?: boolean;
};

export const FeedCommentTextarea = forwardRef<
  TextareaProps & TextareaAutosizeProps & FeedCommentTextareaProps,
  'textarea'
>((props, ref) => {
  const ref2 = React.useRef<HTMLTextAreaElement>(null);
  const refs = useMergeRefs(ref, ref2);
  const { commentAutoFocus, ...rest } = props;

  const { t } = useTranslation(['feed']);

  React.useLayoutEffect(() => {
    // https://melvingeorge.me/blog/focus-input-tag-using-hooks-reactjs
    const textareaElement = ref2.current;
    if (!textareaElement) {
      return;
    }
    const end = textareaElement.value.length;
    if (!(commentAutoFocus ?? true)) {
      return;
    }
    textareaElement.focus();
    setTimeout(() => textareaElement.setSelectionRange(end, end), 0);
  }, [commentAutoFocus]);

  return (
    <AutoResizeTextarea
      ref={refs}
      {...rest}
      placeholder={rest.placeholder ?? t('feed:placeholder.Antworten')}
    />
  );
});
