import { chakra } from '@chakra-ui/react';
import React from 'react';

export const wizardImageTestingIds = {
  image: 'wizard-image',
};

type WizardImageProps = {
  /**
   * The optional maximum height of the image.
   * @default size.s160
   */
  maxHeight?: string;
  /**
   * The path of the image to be displayed
   */
  src: string;
  alt: string;
  /**
   * Flag if image should have a bottom margin
   */
  withBottomMargin?: boolean;
};

export const WizardImage: React.FC<WizardImageProps> = ({
  alt,
  src,
  withBottomMargin = false,
}) => (
  <chakra.img
    mb={withBottomMargin ? 8 : 0}
    maxW={'full'}
    maxHeight={'160px'}
    src={src}
    alt={alt}
  />
);
