import { Button, Heading, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import livestreamImage from '../../../../../assets/images/vxlive/livestream.svg';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../../../../components/shared/cards/UniversalCard/UniversalCard';
import { useVxLiveLink } from '../../../../../provider/VxLiveLinkProvider';

export const GoOnlineCard: React.FC = () => {
  const { t } = useTranslation('home');

  const {
    actions: { redirectToVxLive },
  } = useVxLiveLink();

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img src={livestreamImage} alt={t('imgAlt.ModelImLivestream')} />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t('heading.GeheJetztOnline')}
              </Heading>
              <Text
                size={{ base: 'sm', lg: 'md' }}
                textAlign={'center'}
                color={'gray.500'}
                p={0}
              >
                {t(
                  'text.GluckwunschDuHastAlleSchritteErledigtGeheJetztOnlineUmDeinErstesGeldXX'
                )}
              </Text>
            </VStack>
          </>
        }
        cardFooter={
          <Button
            variant={'solid'}
            children={t('button.OnlineGehen')}
            onClick={() => redirectToVxLive()}
          />
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
