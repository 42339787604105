import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  Heading,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  PublicPageLayout,
  PublicPageMainSection,
  PublicPageMainSectionBox,
} from '../../components/Layout/PublicPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import { RequiredErrorMessage } from '../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { ClearableInputControl } from '../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { PasswordClearableInputControl } from '../../components/shared/HookFormForms/PasswordClearableInputControl/PasswordClearableInputControl';
import ENV from '../../environments/environment';
import { LoginMutation, useLoginMutation } from '../../generated/graphql';
import { useAuth } from '../../provider/AuthProvider';
import { useGoogleAnalytics } from '../../provider/GoogleAnalyticsProvider';
import { externalRoutes, routes } from '../../routes/routesConfig';
import Logger from '../../utils/Logger';

const modelNameInputName = 'modelName';
const passwordInputName = 'password';

const SignInSchema = Yup.object().shape({
  [modelNameInputName]: Yup.string().required(() => <RequiredErrorMessage />),
  [passwordInputName]: Yup.string().required(() => <RequiredErrorMessage />),
});

type LoginFormValues = {
  [modelNameInputName]: string;
  [passwordInputName]: string;
};

const initialLoginFormValues: LoginFormValues = {
  [modelNameInputName]: '',
  [passwordInputName]: '',
};

function extractAuthResultInvalidCredentials(data?: LoginMutation) {
  const loginAuthResult = data?.auth?.login;
  return loginAuthResult?.__typename === 'AuthResultInvalidCredentials'
    ? loginAuthResult
    : null;
}

function extractAuthResultSuccessful(data?: LoginMutation) {
  const loginAuthResult = data?.auth?.login;
  return loginAuthResult?.__typename === 'AuthResultSuccessful'
    ? loginAuthResult
    : null;
}

const ChakraLink = chakra(Link);

const LoginPage: React.FC = () => {
  const authCtx = useAuth();
  const history = useHistory<{ originalTarget?: string }>();

  React.useEffect(() => {
    if (authCtx.isAuthenticated) {
      // Change to use URL from get parameter in the future
      history.push(routes.home.path);
    }
  }, [authCtx.isAuthenticated, history]);

  const { t, i18n } = useTranslation(['general', 'loginPage']);
  const { actions: googleAnalyticsActions } = useGoogleAnalytics();

  const hookForm = useForm({
    defaultValues: initialLoginFormValues,
    resolver: yupResolver(SignInSchema),
    mode: 'all',
  });

  const customHandleSubmit: SubmitHandler<LoginFormValues> = (data) => {
    performLogin({
      variables: {
        username: data[modelNameInputName],
        password: data[passwordInputName],
      },
    });
  };

  const loginCompleteHandler = React.useCallback(
    async (data: LoginMutation) => {
      const authResultInvalidCredentials =
        extractAuthResultInvalidCredentials(data);

      const authResultSuccessful = extractAuthResultSuccessful(data);

      if (authResultInvalidCredentials) {
        issueChakraToast({
          description: t('loginPage:toastInvalidCredentials'),
          status: 'error',
        });
      } else if (authResultSuccessful && authResultSuccessful.accessToken) {
        // Navigating away from the app when the model is not allowed to see the version
        if (ENV.ENVIRONMENT !== 'local' && authResultSuccessful.redirectUrl) {
          window.open(authResultSuccessful.redirectUrl, '_self');
          return undefined;
        }
        await authCtx.actions.login(
          authResultSuccessful.accessToken,
          authResultSuccessful.refreshToken
        );

        if (history.location.state?.originalTarget) {
          // In case a {ProtectedRoute} redirected back to login
          // it set the originalTarget state we can navigate to
          // on login success
          history.push({
            pathname: history.location.state.originalTarget,
            state: {
              originalTarget: undefined,
            },
          });
        } else {
          history.push(routes.home.path);
        }

        googleAnalyticsActions.initGoogleAnalytics();
      } else {
        issueChakraToast({
          description: t('general:toasts.error'),
          status: 'error',
        });
      }

      return undefined;
    },
    [authCtx, googleAnalyticsActions, t, history]
  );

  /** Handle network and other major errors */
  const loginRequestErrorHandler = React.useCallback(
    (error: ApolloError) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toasts.error'),
        status: 'error',
      });
    },
    [t]
  );

  const [performLogin, { loading: loginLoading }] = useLoginMutation({
    onCompleted: loginCompleteHandler,
    onError: loginRequestErrorHandler,
    fetchPolicy: 'no-cache',
  });

  const openLandingPage = useCallback(() => {
    window.open(externalRoutes.main(i18n.language), '_self');
  }, [i18n]);

  return (
    <PublicPageLayout
      pageTitle={t('loginPage:headerText')}
      onClose={openLandingPage}
    >
      <ScrollToTopOnMount />
      <PublicPageMainSection>
        <PublicPageMainSectionBox>
          <Heading size={'xl'} mb={6}>
            {t('loginPage:headline1')}
            <br />
            {t('loginPage:headline2')}
          </Heading>
          <FormProvider {...hookForm}>
            <FormControl
              as={'form'}
              onSubmit={hookForm.handleSubmit(customHandleSubmit)}
            >
              <VStack spacing={2}>
                <ClearableInputControl
                  label={t('general:emailOrModelName')}
                  name={modelNameInputName}
                  placeholder={t('general:emailOrModelNamePlaceholder')}
                  autoComplete={'username'}
                />
                <PasswordClearableInputControl
                  label={t('general:password')}
                  name={passwordInputName}
                  placeholder={t('general:passwordPlaceholder')}
                />
                <ChakraLink alignSelf={'start'} to={routes.passwordReset.path}>
                  {t('loginPage:resetPassword')}
                </ChakraLink>
                <Box>
                  <Button
                    mt={6}
                    variant={'solid'}
                    children={t('loginPage:logInButton')}
                    type="submit"
                    isDisabled={loginLoading}
                    isLoading={loginLoading}
                  />
                </Box>
              </VStack>
            </FormControl>
          </FormProvider>
          <Box mt="6" mb="4" textStyle="bodySm" textAlign="center">
            {Trans({
              t,
              i18nKey: 'loginPage:signUpText',
              components: { signup: <ChakraLink to="/signup" /> },
            })}
          </Box>
        </PublicPageMainSectionBox>
      </PublicPageMainSection>
    </PublicPageLayout>
  );
};

export default LoginPage;
