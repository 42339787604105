import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { MultiSelectControl } from '../../../../components/shared/FormikFormElements';
import { ProfileFieldTypeEnumListFragment } from '../../../../generated/graphql';
import { LabelTextWithGuideDot } from '../LabelTextWithGuideDot/LabelTextWithGuideDot';

export const EnumListFieldWithGuideDot: React.FC<{
  field: Maybe<ProfileFieldTypeEnumListFragment>;
  label: string;
  placeholder: string;
  emptyValue?: string | null;
  helperText?: string;
}> = ({ field, label, placeholder, emptyValue, helperText }) => {
  if (!field) {
    return null;
  }

  return (
    <MultiSelectControl
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      selectProps={{ placeholder }}
      optionList={field.validValues}
      emptyValue={emptyValue}
      helperText={helperText}
    />
  );
};

export const EnumListField: React.FC<{
  field: Maybe<ProfileFieldTypeEnumListFragment>;
  label: string;
  placeholder: string;
  emptyValue?: string | null;
  helperText?: string;
}> = ({ field, label, placeholder, emptyValue, helperText }) => {
  if (!field) {
    return null;
  }

  return (
    <MultiSelectControl
      label={label}
      name={field.name}
      selectProps={{ placeholder }}
      optionList={field.validValues}
      emptyValue={emptyValue}
      helperText={helperText}
    />
  );
};
