import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  HStack,
  Icon,
  ListItem,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyAmount } from '../../../../../../../components/shared/CurrencyAmount/CurrencyAmount';
import { NoticeModal } from '../../../../../../../components/shared/NoticeModal/NoticeModal';
import {
  FinancesInvoice,
  FinancesInvoiceStatusEnum,
} from '../../../../../../../generated/graphql';

type InvoiceListItemProps = {
  invoice: FinancesInvoice;
};

export const InvoiceListItem: React.FC<InvoiceListItemProps> = (props) => {
  const { t } = useTranslation(['withdrawl']);

  const invoiceFailedDisclosure = useDisclosure();

  const invoiceStatusIcon = React.useMemo(() => {
    switch (props.invoice.status) {
      case FinancesInvoiceStatusEnum.Waiting:
        return (
          <Icon
            as={icons.ScheduleOutlined}
            boxSize={'icon.md'}
            color={'warning.500'}
          />
        );
      case FinancesInvoiceStatusEnum.Ok:
        return (
          <Icon as={icons.CheckCircle} boxSize={'icon.md'} color={'lime.500'} />
        );
      default:
        return undefined;
    }
  }, [props.invoice.status]);

  const invoiceDatePrefix = React.useMemo(() => {
    switch (props.invoice.status) {
      case FinancesInvoiceStatusEnum.Waiting:
        return t('withdrawl:text.GeplantDate', {
          date: new Date(props.invoice.payoutDate),
        });
      case FinancesInvoiceStatusEnum.Ok:
        return t('withdrawl:text.AmDate', {
          date: new Date(props.invoice.payoutDate),
        });

      default:
        return undefined;
    }
  }, [props.invoice.status, props.invoice.payoutDate, t]);

  const invoiceHasError =
    props.invoice.status === FinancesInvoiceStatusEnum.Error;

  return (
    <ListItem
      p={4}
      // dirty workaround to have line item content align with container
      // But still have padding for the background color on _hover, _active etc.
      mx={-4}
      bg={'surface'}
      cursor={'pointer'}
      _hover={{
        bg: 'coldGray.50',
      }}
      _focus={{
        bg: 'coldGray.100',
      }}
      _active={{
        bg: 'coldGray.100',
      }}
      _disabled={{
        opacity: 0.4,
        cursor: 'not-allowed',
      }}
      onClick={invoiceHasError ? invoiceFailedDisclosure.onOpen : undefined}
    >
      <VStack
        alignItems={'stretch'}
        color={invoiceHasError ? 'error.500' : 'unset'}
      >
        <HStack alignItems={'start'} width={'full'}>
          <Icon as={icons.ReceiptLong} boxSize={'icon.md'} />
          <VStack
            flexGrow={1}
            alignItems={'stretch'}
            overflow={'hidden'}
            spacing={1}
          >
            <HStack>
              <Text
                as={'span'}
                textOverflow={'ellipsis'}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
                fontSize={'md'}
              >
                <CurrencyAmount amount={props.invoice.amount} />
              </Text>
            </HStack>
            {invoiceHasError ? null : (
              <Text color={'gray.500'} fontSize={'sm'}>
                {invoiceDatePrefix}
              </Text>
            )}
          </VStack>
          {invoiceStatusIcon}
        </HStack>
        {!invoiceHasError ? null : (
          <HStack>
            <Icon as={icons.Error} boxSize={'icon.md'} />
            <Text
              as={'span'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              whiteSpace={'nowrap'}
              fontSize={'md'}
              flexGrow={1}
            >
              {t('withdrawl:text.AuszahlungFehlgeschlagen')}
            </Text>
            <Icon as={icons.ChevronRight} boxSize={'icon.md'} />
            <NoticeModal
              isOpen={invoiceFailedDisclosure.isOpen}
              onClose={invoiceFailedDisclosure.onClose}
              modalHeading={t(
                'withdrawl:modal.DeineAuszahlungIstFehlgeschlagen'
              )}
              modalTextSlot={props.invoice.message}
              modalFooterSlot={(onClose) => (
                <Button onClick={onClose}>
                  {t('withdrawl:button.AllesKlar')}
                </Button>
              )}
              status={'error'}
            />
          </HStack>
        )}
      </VStack>
    </ListItem>
  );
};
