import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { EncodedControlledCharacterCount } from '../../EncodedControlledCharacterCount/EncodedControlledCharacterCount';
import { FormControlHeaderStack } from '../../FormElements/FormControlHeaderStack/FormControlHeaderStack';

export const TextAreaControl: React.FC<{
  name: string;
  label: React.ReactNode;
  placeholder?: string;
  labelProps?: FormLabelProps;
  labelTrailingHint?: React.ReactNode;
  textAriaProps?: TextareaProps;
  maxCharacterCount?: number;
  text?: string;
}> = ({
  name,
  label,
  placeholder,
  maxCharacterCount,
  labelProps,
  labelTrailingHint,
  text,
  textAriaProps = {},
}) => {
  const {
    formState: { errors, touchedFields, isSubmitted },
    register,
  } = useFormContext();

  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);

  const value = useWatch({ name });

  return (
    <FormControl isInvalid={isInvalid}>
      <FormControlHeaderStack>
        <HStack w={'full'}>
          <FormLabel children={label} flex={1} {...labelProps} />
          {labelTrailingHint}
        </HStack>
        {text && <FormHelperText>{text}</FormHelperText>}
      </FormControlHeaderStack>
      <Textarea
        rows={3}
        resize={'none'}
        placeholder={placeholder}
        borderColor={'darkSteel'}
        {...textAriaProps}
        {...register(name)}
      />
      <HStack minH={'8'}>
        <Box flexGrow={1}>
          {errors[name] && (
            <FormErrorMessage>
              {(errors[name]?.message as any) ?? ''}
            </FormErrorMessage>
          )}
        </Box>
        <EncodedControlledCharacterCount
          value={value}
          maxCharacterCount={maxCharacterCount}
        />
      </HStack>
    </FormControl>
  );
};
