import * as React from 'react';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { FeatureName, useFeature } from '../../../flags';
import {
  ContestFragment,
  InputVideo,
  Maybe,
  VideoFragment,
  VideoStatusEnum,
  useGetVideoByRequestIdQuery,
  useGetVideoLibraryVideoUploadListEntryQuery,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { VideoLibraryVideoUploadListEntryModal } from '../../../pages/VideoLibraryPage/VideoLibraryVideoUploadListEntryModal';
import { MediaContext } from '../../MediaProvider';
import { useUploadyService } from '../VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { TusUploadEntry } from '../VideoLibraryUploadyProvider/videoUploadEnhancer';

export interface IMediaAlbumEdits {
  input?: InputVideo;
  thumbnail16ReplacementUploadId?: string;
  thumbnail16Replacement?: MediaContext['media'];
  thumbnail18ReplacementUploadId?: string;
  thumbnail18Replacement?: MediaContext['media'];
  errors?: any;
}

interface VideoLibraryEntryModalContext {
  isOpen: boolean;
  video: Maybe<VideoFragment> | undefined;
  videoLoading: boolean;
  albumId: number | null;
  editingUpload?: TusUploadEntry | null | undefined;
  editingUploadId?: string | null;
  contest?: ContestFragment | null;
  unsubmittedEdits: IMediaAlbumEdits;
  actions: {
    openModal: (
      albumId: number | null,
      editingUploadId?: string | null,
      contest?: ContestFragment | null
    ) => void;
    closeModal: () => void;
    setUnsubmittedEdits: React.Dispatch<React.SetStateAction<IMediaAlbumEdits>>;
  };
}

export const [
  ,
  useVideoLibraryEntryModalContext,
  videoLibraryEntryModalContext,
] = createContext<VideoLibraryEntryModalContext>({
  name: 'videoLibraryEntryModalContext',
  errorMessage:
    'useVideoLibraryEntryModalContext: `videoLibraryEntryModalContext` is undefined. Seems you forgot to wrap component within the Provider',
  strict: true,
});

export const VideoLibraryEntryModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  /**
   * Modal handling
   */
  const [albumId, setAlbumId] = React.useState<number | null>(null);
  const [editingUploadId, setEditingUploadId] = React.useState<
    string | undefined | null
  >(null);
  const isOpen = React.useMemo(() => {
    return !!albumId || !!editingUploadId;
  }, [albumId, editingUploadId]);
  const [contest, setContest] = React.useState<
    ContestFragment | null | undefined
  >(null);
  const { allUploads } = useUploadyService();
  const editingUpload = allUploads.find(
    (upload) => upload.uploadRequestId === editingUploadId
  );

  /**
   * Get Upload List Entry data
   */
  const { data, loading } = useGetVideoLibraryVideoUploadListEntryQuery({
    variables: {
      albumId: albumId ?? undefined,
    },
    skip: !isOpen || !albumId || !videoLibraryFeatureFlag,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [video, setVideo] = React.useState<Maybe<VideoFragment> | undefined>(
    null
  );
  React.useEffect(() => {
    if (data?.media.videos.videoById) {
      setVideo(data.media.videos.videoById);
    }
  }, [data]);

  /**
   * Update Upload List Entry handling
   */

  const [unsubmittedEdits, setUnsubmittedEdits] =
    React.useState<IMediaAlbumEdits>({});

  const actions: VideoLibraryEntryModalContext['actions'] =
    React.useMemo(() => {
      return {
        setUnsubmittedEdits,
        openModal: (albumId, editingUploadId, contest) => {
          if (albumId && editingUploadId) {
            throw new Error(
              'albumId and editingUploadId are mutually exclusive.'
            );
          }
          setAlbumId(albumId);
          setEditingUploadId(editingUploadId);
          setContest(contest);
        },
        closeModal: () => {
          setAlbumId(null);
          setEditingUploadId(null);
          setContest(null);
          setVideo(null);
        },
      };
    }, []);

  /**
   * Poll the video id query if we're uploading or transcoding
   */
  const isTranscoding =
    !!video && !!video.status && video?.status === VideoStatusEnum.Transcoding;
  const shouldPollAfterUpload =
    !!editingUploadId &&
    !!editingUpload &&
    editingUpload?.progress === 100 &&
    albumId === null;
  const videoIdQuery = useGetVideoByRequestIdQuery({
    variables: {
      requestId: editingUploadId ?? '',
    },
    skip: !isOpen || !(isTranscoding || shouldPollAfterUpload),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    pollInterval: isTranscoding ? 5000 : 1000,
  });
  React.useEffect(() => {
    const video = videoIdQuery.data?.media.videos.videoById;
    if (video?.status === VideoStatusEnum.Rejected) {
      issueChakraToast({
        description: videoIdQuery.data?.media.videos.videoById?.rejectionReason,
        status: 'error',
      });
      actions.closeModal();
    } else if (video?.albumId) {
      setAlbumId(video?.albumId);
      setVideo(video);
    }
  }, [actions, contest, videoIdQuery]);

  const context: VideoLibraryEntryModalContext = React.useMemo(() => {
    return {
      isOpen,
      video,
      videoLoading: loading,
      albumId,
      editingUpload,
      editingUploadId,
      contest,
      unsubmittedEdits,
      actions,
    };
  }, [
    isOpen,
    video,
    loading,
    albumId,
    editingUpload,
    editingUploadId,
    contest,
    unsubmittedEdits,
    actions,
  ]);

  return (
    <videoLibraryEntryModalContext.Provider value={context}>
      {children}
      <VideoLibraryVideoUploadListEntryModal />
    </videoLibraryEntryModalContext.Provider>
  );
};
