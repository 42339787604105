import { ChevronLeft, ChevronRight } from '@campoint/odi-ui-icons';
import { Icon, IconButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ImageGalleryButton } from './ImageGalleryButton';

export const NavLeftButton: React.FC<Partial<IconButtonProps>> = (props) => {
  const { t } = useTranslation(['general']);

  return (
    <ImageGalleryButton
      aria-label={t('general:button.VorherigesBild')}
      icon={<Icon as={ChevronLeft} boxSize={'6'} pr={'0.5'} />}
      borderRadius={'full'}
      {...props}
    />
  );
};

export const NavRightButton: React.FC<Partial<IconButtonProps>> = (props) => {
  const { t } = useTranslation(['general']);

  return (
    <ImageGalleryButton
      aria-label={t('general:button.NaechstesBild')}
      icon={<Icon as={ChevronRight} boxSize={'6'} pl={'0.5'} />}
      borderRadius={'full'}
      {...props}
    />
  );
};
