import * as icons from '@campoint/odi-ui-icons';
import { ChatbotMessage } from '@campoint/vxmodels-chatbot';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { useActiveApiLanguage } from '../../../hooks/useActiveApiLanguage';
import { VXMChatbotMessageEntry } from './VXMChatbotMessageEntry';
import { VXMChatbotThinkingText } from './VXMChatbotThinkingText';
import { getHumanURL } from './VXMChatbotUtils';

export type ChatbotTimestamp = {
  timestamp: number;
};
export type ChatbotNeedHelp = {
  type: 'needHelp';
};
export type ChatbotIsThinking = {
  type: 'isThinking';
};
export type ChatbotHistoryEntry =
  | ChatbotMessage
  | ChatbotTimestamp
  | ChatbotNeedHelp
  | ChatbotIsThinking;

export const VXMChatbotHistoryEntry: React.FC<{
  entry: ChatbotHistoryEntry;
  modelPictureFSK16Avatar: string;
}> = ({ entry, modelPictureFSK16Avatar }) => {
  if ((entry as ChatbotMessage).text !== undefined) {
    // message bubble
    return (
      <VXMChatbotMessageEntry
        message={entry as ChatbotMessage}
        modelPictureFSK16Avatar={modelPictureFSK16Avatar}
      />
    );
  } else if ((entry as ChatbotTimestamp).timestamp !== undefined) {
    // timestamp
    return <VXMChatbotTimestampEntry entry={entry} />;
  } else if ((entry as ChatbotNeedHelp).type === 'needHelp') {
    // need help?
    return <VXMChatbotNeedHelpEntry />;
  } else if ((entry as ChatbotIsThinking).type === 'isThinking') {
    // is thinking
    return <VXMChatbotThinkingText />;
  }

  return <></>;
};

const VXMChatbotTimestampEntry: React.FC<{
  entry: ChatbotHistoryEntry;
}> = ({ entry }) => {
  const Intl = useIntl();

  return (
    <Text
      style={{
        display: 'block',
        textAlign: 'center',
        fontSize: '14px',
        margin: '20px',
      }}
      color={'gray.500'}
    >
      {Intl.formatDate((entry as ChatbotTimestamp).timestamp, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </Text>
  );
};

const VXMChatbotNeedHelpEntry: React.FC = () => {
  const { t } = useTranslation(['chatbot']);
  const lang = useActiveApiLanguage();

  return (
    <Box m={4} textAlign={'center'}>
      <Text color={'darkSteel'}>
        {t('chatbot:label.benotigstDuWeitereHilfe')}
      </Text>
      <Button
        variant={'link'}
        onClick={() => window.open(getHumanURL(lang), '_blank')}
        fontWeight={500}
        rightIcon={<Icon as={icons.ChevronRight} boxSize={'icon.md'} m={0} />}
      >
        {t('chatbot:label.mitSupportMitarbeiterSprechen')}
      </Button>
    </Box>
  );
};
