import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../../assets/images/wizard/verification-wait-state.svg';
import { WizardIllustratedInstruction } from '../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../components/WizardStepFooter/WizardStepFooter';
import { useWizardPayout } from '../WizardPayoutContext';

const WizardPayoutFinished: React.FC = () => {
  const { t } = useTranslation(['wizardPayout', 'general']);
  const wizardPayoutCtx = useWizardPayout();

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardIllustratedInstruction
          illustrationSrc={image}
          illustrationAlt={t('general:img.ZuBearbeitendesDokument')}
          header={t(
            'wizardPayout:heading.VielenDankDeineAngabenWerdenUberpruft'
          )}
          body={t(
            'wizardPayout:text.DuBekommstEineEMailSobaldDeineDokumenteUberpruftWurden'
          )}
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardPayout:button.AllesKlar')}
          onContinueButtonClick={() => {
            wizardPayoutCtx.wizardNextStepCallback();
          }}
          omitSkipButton
        />
      }
    />
  );
};

export default WizardPayoutFinished;
