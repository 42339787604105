import { Center, HStack, StackProps, forwardRef } from '@chakra-ui/react';

export const HSnapScrollContainer = forwardRef<StackProps, 'div'>(
  (props, ref) => (
    <HStack
      spacing={4}
      overflow="hidden"
      padding={4}
      alignItems={'stretch'}
      sx={{
        overflowX: 'scroll',
        scrollSnapType: 'x mandatory',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
      }}
      ref={ref}
      {...props}
    />
  )
);

/**
 * Can be used as an end element of a snap scroll container
 * It will add a little space at the end to indicate,
 * that the list realy ends here
 * and allows the shadow of the last element to have space to expand and thus not being cut off.
 * @see {HSnapScrollContainer}
 */
export const SnapScrollContainerEnd = () => <Center p={4}>{''}</Center>;
