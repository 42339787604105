import { Button, CloseButton, Heading, Text, VStack } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { FC, useMemo, useState } from 'react';
import ReactJoyride, {
  CallBackProps,
  STATUS,
  Step,
  TooltipRenderProps,
} from 'react-joyride';

import { StepDotGroup } from '../../components/shared/StepDotGroup/StepDotGroup';
import { createContext } from '../../hooks/useContext';
import { LocalStorage } from '../../utils';
import { useAccountStatus } from '../AccountStatusProvider/AccountStatusProvider';
import { useAuth } from '../AuthProvider';
import { useWelcomeTourSteps } from './tours';

interface WelcomeTourOptions {
  skipIfAlreadFinishedOnce?: boolean;
}
export interface TourContext {
  actions: {
    startWelcomeTour: (options?: WelcomeTourOptions) => void;
    setState: React.Dispatch<
      React.SetStateAction<{
        run: boolean;
        onJoyrideCallback: Maybe<(data: CallBackProps) => void>;
        steps: Step[];
      }>
    >;
  };
  getIsWelcomeTourHidden: () => boolean | null;
}

export const [, useTour, tourContext] = createContext<TourContext>({
  name: 'TourContext',
  errorMessage:
    'useTour: `TourContext` is undefined. Seems you forgot to wrap component within the Provider',
});

export const TourProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const authCtx = useAuth();

  const { wasOnlineOnVXLive, hasSomeFeedPost, hasAllOnboardingTasksCompleted } =
    useAccountStatus();

  const [{ run, onJoyrideCallback, steps: joyrideSteps }, setState] = useState({
    run: false,
    onJoyrideCallback: null as Maybe<(data: CallBackProps) => void>,
    steps: [] as Step[],
  });

  const handleJoyrideCallback = React.useCallback(
    (data: CallBackProps) => {
      onJoyrideCallback?.(data);
      if (
        ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status)
      ) {
        setState({ run: false, onJoyrideCallback: null, steps: [] });
      }
    },
    [onJoyrideCallback]
  );

  const welcomeTourSteps = useWelcomeTourSteps();

  const userId = authCtx.authUser?.userId;

  const getIsWelcomeTourHidden = React.useCallback(() => {
    if (wasOnlineOnVXLive && hasSomeFeedPost) {
      return true;
    }
    return LocalStorage.getBoolean(`hideWelcomeTour-${userId}` as any);
  }, [userId, wasOnlineOnVXLive, hasSomeFeedPost]);

  const startWelcomeTour = React.useCallback(
    ({ skipIfAlreadFinishedOnce = true } = {}) => {
      if (!hasAllOnboardingTasksCompleted) {
        return;
      }
      if (skipIfAlreadFinishedOnce && getIsWelcomeTourHidden()) {
        return;
      }
      setState({
        run: true,
        onJoyrideCallback: ({ status }) => {
          if (([STATUS.FINISHED] as string[]).includes(status)) {
            LocalStorage.add(`hideWelcomeTour-${userId}` as any, true);
          }
        },
        steps: welcomeTourSteps,
      });
    },
    [
      getIsWelcomeTourHidden,
      userId,
      welcomeTourSteps,
      hasAllOnboardingTasksCompleted,
    ]
  );

  const actions = useMemo(() => {
    return {
      setState,
      startWelcomeTour,
    };
  }, [startWelcomeTour]);

  const context: TourContext = useMemo(
    () => ({
      actions,
      getIsWelcomeTourHidden,
    }),
    [actions, getIsWelcomeTourHidden]
  );

  return (
    <tourContext.Provider value={context}>
      {children}
      <ReactJoyride
        callback={handleJoyrideCallback}
        run={run}
        steps={joyrideSteps}
        tooltipComponent={CustomTooltip}
        spotlightClicks={false}
        scrollOffset={150}
        continuous
        floaterProps={{
          style: {
            width: '100%',
          },
        }}
        styles={{
          spotlight: {
            border: '1px solid #ffffff78',
            borderRadius: '16px',
          },
          //chakra token "overlay"
          options: {
            zIndex: 1800,
          },
        }}
      />
    </tourContext.Provider>
  );
};

const CustomTooltip: React.FC<TooltipRenderProps> = ({
  continuous,
  index,
  step,
  primaryProps,
  tooltipProps,
  size,
  isLastStep,
  skipProps,
}) => {
  return (
    <VStack
      layerStyle={'card'}
      borderRadius={'5px'}
      boxSizing={'border-box'}
      maxW={'100%'}
      width={'290px'}
      padding={'16px'}
      position={'relative'}
      {...tooltipProps}
    >
      <CloseButton
        right={3}
        top={3}
        position={'absolute'}
        color={'darkSteel'}
        {...skipProps}
      />
      <Heading mt={8} whiteSpace={'pre-line'} textAlign={'center'} size={'lg'}>
        {step.title}
      </Heading>
      <Text
        mt={1}
        size={'md'}
        whiteSpace={'pre-line'}
        textAlign={'center'}
        color={'gray.500'}
      >
        {step.content}
      </Text>
      {continuous && (
        <Button mt={4} {...primaryProps}>
          {isLastStep ? 'Tour abschließen' : 'Weiter'}
        </Button>
      )}
      {!continuous && (
        <Button mt={4} {...primaryProps}>
          Tour abschließen
        </Button>
      )}
      <StepDotGroup mt={4} currentStep={index + 1} totalSteps={size} />
    </VStack>
  );
};
