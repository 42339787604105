import { Box, Button, Heading, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  PublicPageLayout,
  PublicPageMainSection,
  PublicPageMainSectionBox,
} from '../../components/Layout/PublicPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import { ErrorMessageWithLeadingIcon } from '../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { PasswordControl } from '../../components/shared/FormikFormElements';
import { useResetPasswordMutation } from '../../generated/graphql';
import { routes } from '../../routes/routesConfig';

const newPasswordName = 'newPassword';
const repeatedNewPasswordName = 'repeatedNewPasswordName';

interface Values {
  [newPasswordName]: string;
  [repeatedNewPasswordName]: string;
}

const PasswordResetVerifyPage: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();

  const history = useHistory();
  const { t } = useTranslation(['passwordReset', 'signUpPage']);

  const [resetPasswordMutation] = useResetPasswordMutation({});

  const onSubmit = async (values: Values) => {
    const result = await resetPasswordMutation({
      variables: {
        hash,
        password: values[repeatedNewPasswordName],
      },
    });

    switch (result.data?.auth?.setPassword?.__typename) {
      case 'PasswordSetResultSuccessful':
        issueChakraToast({
          status: 'success',
          description: t('passwordReset:toast.PasswortErfolgreichGeandert'),
        });
        history.push(routes.login.path);
        break;
      case 'PasswordSetResultFail':
        issueChakraToast({
          status: 'error',
          description: t(
            'passwordReset:toast.PasswortKonnteNichtGeandertWerdenWendeDichAnDenSupport'
          ),
        });
        break;
    }
  };

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [newPasswordName]: Yup.string()
        .min(6, () => (
          <ErrorMessageWithLeadingIcon
            children={t('signUpPage:formValidation.passwordLength')}
          />
        ))
        .required(() => (
          <ErrorMessageWithLeadingIcon
            children={t('signUpPage:formValidation.passwordRequired')}
          />
        )),
      [repeatedNewPasswordName]: Yup.string()
        .required(() => (
          <ErrorMessageWithLeadingIcon
            children={t(
              'signUpPage:formValidation.passwordConfirmationRequired'
            )}
          />
        ))
        .oneOf([Yup.ref(newPasswordName), null], () => (
          <ErrorMessageWithLeadingIcon
            children={t('signUpPage:formValidation.passwordsDoNotMatch')}
          />
        )),
    });
  }, [t]);

  return (
    <PublicPageLayout
      pageTitle={t('passwordReset:navHeadline')}
      onClose={() => history.push(routes.login.path)}
    >
      <ScrollToTopOnMount />
      <PublicPageMainSection>
        <PublicPageMainSectionBox>
          <Heading size={'xl'} mb={'4'}>
            {t('passwordReset:heading.PasswortAndern')}
          </Heading>
          <Formik
            initialValues={{
              [newPasswordName]: '',
              [repeatedNewPasswordName]: '',
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form>
                <VStack spacing={2}>
                  <PasswordControl
                    label={t('passwordReset:label.NeuesPasswort')}
                    labelProps={{
                      size: 'sm',
                    }}
                    name={newPasswordName}
                    inputProps={{
                      placeholder: t(
                        'passwordReset:placeholder.PasswortFestlegen'
                      ),
                      autoComplete: 'new-password',
                    }}
                  />

                  <PasswordControl
                    label={t('passwordReset:label.NeuesPasswort')}
                    labelProps={{
                      size: 'sm',
                    }}
                    name={repeatedNewPasswordName}
                    inputProps={{
                      placeholder: t(
                        'passwordReset:placeholder.PasswortWiederholen'
                      ),
                      autoComplete: 'new-password',
                    }}
                  />
                  <Box>
                    <Button
                      mt="8"
                      variant={'solid'}
                      type="submit"
                      children={t('passwordReset:button.PasswortZurucksetzen')}
                      isLoading={formik.isSubmitting}
                      isDisabled={!formik.isValid}
                    />
                  </Box>
                </VStack>
              </Form>
            )}
          </Formik>
        </PublicPageMainSectionBox>
      </PublicPageMainSection>
    </PublicPageLayout>
  );
};

export default PasswordResetVerifyPage;
