import { Button } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../../../components/Layout/ResponsiveModal';
import { ErrorModalContent } from './component/ErrorModalContent';

interface FeedPostBrokenModalProps
  extends Omit<ResponsiveModalProps, 'children'> {
  onDeletePost: () => void;
  rejectionReason?: string | null | undefined;
}

export const FeedPostBrokenModal: React.FC<FeedPostBrokenModalProps> = ({
  onDeletePost,
  rejectionReason,
  ...props
}) => {
  const { t } = useTranslation(['feed']);

  return (
    <ResponsiveModal preferredSize="sm" preferredSizeBreakpoint="sm" {...props}>
      <ResponsiveModalOverlay />
      <ErrorModalContent
        heading={
          rejectionReason
            ? t('feed:heading.DeinBeitragMussteAbgelehntWerden')
            : t('feed:heading.DerUploadIstFehlgeschlagen')
        }
        text={
          rejectionReason ??
          t(
            'feed:text.DasKannMehrereGrundeHabenStelleSicherDassDeinInternetGutIstOderDasDaXX'
          )
        }
        footerContent={
          <Button
            children={t('feed:button.BeitragLoschen')}
            onClick={onDeletePost}
          />
        }
      />
    </ResponsiveModal>
  );
};
