import { HStack, Tag, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';

export const VideoLibraryDiscountTag: React.FC<{
  discountPercent: number | null | undefined;
  discountStart?: any;
  discountEnd?: any;
  discountCoins?: number | null | undefined;
}> = ({ discountPercent, discountStart, discountEnd, discountCoins }) => {
  const { coinsEnabled } = useFlagsProviderV2();
  const formatDEDiscountDateRange = (_: { oldest: Date; newest: Date }) => {
    return new Intl.DateTimeFormat('de', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    }).formatRange(_.oldest, _.newest);
  };

  const { t } = useTranslation(['videoLibrary']);

  const discountTagDateRange = React.useMemo(() => {
    if (discountStart && discountEnd) {
      return `(${formatDEDiscountDateRange({
        oldest: DateTime.fromISO(discountStart).toJSDate(),
        newest: DateTime.fromISO(discountEnd).toJSDate(),
      }).replace(/–/g, ` ${t('videoLibrary:text.Bis')} `)})`;
    }
    return '';
  }, [t, discountEnd, discountStart]);

  const discountText = discountCoins && coinsEnabled ? `-${discountCoins} VXC` : `-${discountPercent ?? 0}%`

  return (
    <Tag
      minW={'unset'}
      minH={'unset'}
      h={'5'}
      paddingInlineStart={'1'}
      paddingInlineEnd={'1'}
      color={'coldGray.900'}
      bg={'caribbeanGreen.300'}
      textStyle={'labelXs'}
    >
      <HStack spacing={1} lineHeight={'20px'}>
        <Text fontWeight={700}>{discountText}</Text>
        {discountStart && discountEnd ? (
          <Text fontWeight={300}>{`${discountTagDateRange}`}</Text>
        ) : null}
      </HStack>
    </Tag>
  );
};
