import * as icon from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  HStack,
  Heading,
  HeadingProps,
  Icon,
  IconButton,
  IconButtonProps,
  Image,
  ImageProps,
  ListItemProps,
  StackProps,
  Text,
  TextProps,
  VStack,
  chakra,
  forwardRef,
} from '@chakra-ui/react';
import { Variants } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import avatarPlaceholder from '../../../assets/images/feed/AvatarPlaceholder.svg';

export enum AnimationDirection {
  TopToBottom = -1,
  BottomToTop = 1,
}

export const listVariants: Variants = {
  inactive: {},
  active: {
    transition: {
      when: 'afterChildren',
      delayChildren: 0.1,
      staggerChildren: 0.225,
    },
  },
  exit: {},
};

export const itemVariants: Variants = {
  inactive: {
    opacity: 0,
    y: 20,
  },
  active: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -20,
  },
};

export const CommentUsername: React.FC<HeadingProps> = (props) => (
  <Heading as="h3" size={'md'} fontSize={'md'} noOfLines={1} {...props} />
);
export const CommentText: React.FC<TextProps> = (props) => (
  <Text
    as="span"
    whitespace={'pre'}
    fontSize={'sm'}
    lineHeight={'base'}
    {...props}
  />
);
export const FeedCommentActionLink: React.FC<ButtonProps> = (props) => (
  <Button variant={'link'} fontWeight={'normal'} fontSize={'sm'} {...props} />
);
export const CommentFooter: React.FC<StackProps> = (props) => (
  <HStack alignItems={'center'} {...props} />
);
export const CommentAvatar: React.FC<ImageProps> = ({ src, ...rest }) => {
  const noSrc = !src || src === '';
  return (
    <Image
      src={noSrc ? avatarPlaceholder : src}
      alt={''}
      htmlWidth={'30px'}
      htmlHeight={'30px'}
      borderRadius={'full'}
      bgColor={'steel'}
      boxSize={'30px'}
      userSelect={'none'}
      draggable={false}
      {...rest}
    />
  );
};
export const CommentReplyToActionLink: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation(['feed']);
  return (
    <FeedCommentActionLink children={t('feed:button.Antworten')} {...props} />
  );
};

export const CommentBox: React.FC<StackProps> = (props) => (
  <HStack
    role={'comment'}
    spacing={'2.5'}
    alignItems={'start'}
    w={'full'}
    {...props}
  />
);

export const FeedCommentListItem: React.FC<ListItemProps> = (props) => (
  <chakra.li w={'full'} {...props} />
);

export const FeedCommentReplyListItem: React.FC<ListItemProps> = (props) => (
  <chakra.li w={'full'} {...props} />
);

export const CommentList: React.FC<StackProps> = (props) => {
  const { t } = useTranslation(['feed']);
  return (
    <VStack
      aria-label={t('feed:list.Kommentare')}
      spacing={8}
      alignItems={'stretch'}
      {...props}
    />
  );
};
export const CommentContent: React.FC<StackProps> = (props) => (
  <VStack
    spacing={'0'}
    alignItems={'stretch'}
    w={'full'}
    flexGrow={1}
    wordBreak={'break-word'}
    {...props}
  />
);
export const FeedActionMenuIconButton = forwardRef<
  Partial<IconButtonProps>,
  'button'
>((props, ref) => {
  const { t } = useTranslation(['feed']);
  return (
    <IconButton
      color={'coldGray.900'}
      variant={'link'}
      display={'block'}
      aria-label={t('feed:button.Aktionsmenu')}
      icon={<Icon as={icon.MoreHoriz} boxSize={'icon.md'} />}
      ref={ref}
      {...props}
    />
  );
});
export const FeedCommentSubmitIconButton = forwardRef<
  Partial<IconButtonProps>,
  'button'
>((props, ref) => {
  const { t } = useTranslation(['feed']);
  return (
    <IconButton
      colorScheme={props.isDisabled ? 'gray' : 'primary'}
      variant={'link'}
      aria-label={t('feed:button.Abschicken')}
      boxSize={10}
      icon={<Icon as={icon.Send} boxSize={'icon.lg'} />}
      ref={ref}
      {...props}
    />
  );
});
export const FeedCommentEditSubmitIconButton = forwardRef<
  Partial<IconButtonProps>,
  'button'
>((props, ref) => {
  const { t } = useTranslation(['feed']);
  return (
    <IconButton
      color={'lime.500'}
      variant={'link'}
      aria-label={t('feed:button.Abschicken')}
      icon={<Icon as={icon.Check} boxSize={'icon.md'} />}
      ref={ref}
      {...props}
    />
  );
});
export const FeedCommentEditCancelIconButton = forwardRef<
  Partial<IconButtonProps>,
  'button'
>((props, ref) => {
  const { t } = useTranslation(['feed']);
  return (
    <IconButton
      color={'error.500'}
      variant={'link'}
      aria-label={t('feed:button.AnderungenVerwerfen')}
      icon={<Icon as={icon.Close} boxSize={'icon.md'} />}
      ref={ref}
      {...props}
    />
  );
});
export const CommentReplyList: React.FC<StackProps> = (props) => {
  const { t } = useTranslation(['feed']);
  return (
    <chakra.div
      aria-label={t('feed:list.Antworten')}
      pl={10}
      py={4}
      style={{ position: 'relative' }}
      // variants={variants}
      {...props}
    />
  );
};

export const ShowAllReplies: React.FC<{ replyCount: number } & ButtonProps> = ({
  replyCount,
  ...props
}) => {
  const { t } = useTranslation(['feed']);

  return (
    <Box mb={5} display="flex" ml="40px">
      <Divider my={'4'} maxW="40px" mr={4} />
      <Button variant={'link'} {...props}>
        {replyCount > 1
          ? t('feed:button.AlleKommentareAnsehenPlural', {
              commentCount: replyCount,
            })
          : t('feed:button.AlleKommentareAnsehenSingular')}
      </Button>
    </Box>
  );
};

export const HideReplies: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation(['feed']);

  return (
    <Box mb={5} display="flex" ml="40px">
      <Divider my={'4'} maxW="40px" mr={4} />
      <Button variant={'link'} {...props}>
        {t('feed:button.KommentareEinklappen')}
      </Button>
    </Box>
  );
};
