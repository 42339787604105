import { Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';

import {
  VideoTypeEnum,
} from '../../../../../generated/graphql';
import { useUploadyService } from '../../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import {
  UploadButtonDropZone,
} from '../../../../VideoLibraryPage/VideoLibraryPage';
import { ContestTileBase, ContestTileHeaderImage } from './ContestTileBase';

export const ContestTileUploadDropZone: typeof ContestTileBase = (props) => {
  const contest = props.contest;
  const { tusUploadDataLoading } = useUploadyService();

  let content;
  if (tusUploadDataLoading) {
    content = <Spinner />;
  } else {
    content = (
      <>
        <Heading size="sm">
          {t('contest:heading.JetztVideoHochladen', {
            type: t('contest:label.Video'),
          })}
        </Heading>
        <Text fontWeight={'500'} color={'gray.300'} mx={10} my={2}>
          {t(
            'contest:text.DuKannst1VideoProWettbewerbEinreichenMind3MinutenLang'
          )}
        </Text>
        <UploadButtonDropZone params={{ type: VideoTypeEnum.VideoContest }} />
      </>
    );
  }

  return (
    <ContestTileBase {...props}>
      <ContestTileHeaderImage contest={contest} />
      <VStack
        p={3}
        textAlign={'center'}
        flex={1}
        justifyContent={'center'}
        lineHeight={1.3}
        gap={0}
      >
        {content}
      </VStack>
    </ContestTileBase>
  );
};
