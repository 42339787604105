import { Divider, DividerProps } from '@chakra-ui/react';
import React from 'react';

import {
  LayoutCenteringVStack,
  LayoutCenteringVStackProps,
} from './LayoutCenteringVStack';

export const SectionDivider: React.FC<{
  isWidthRestricted?: boolean;
  dividerProps?: DividerProps;
  layoutCenteringVStackProps?: LayoutCenteringVStackProps;
}> = ({ isWidthRestricted, dividerProps, layoutCenteringVStackProps }) => (
  <LayoutCenteringVStack
    isWidthRestricted={isWidthRestricted}
    {...layoutCenteringVStackProps}
  >
    <Divider size={'s'} color={'steel'} {...dividerProps} />
  </LayoutCenteringVStack>
);
