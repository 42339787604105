import { Box, chakra } from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React from 'react';

import { AuthUserPermissionEnum } from '../../generated/graphql';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { useAuth } from '../../provider/AuthProvider';
import { ShowCreateFeedPostModalNavButton } from '../../provider/FeedPostCreateModalProvider';
import {
  WelcomeTourStep,
  createWelcomeTourDataProps,
} from '../../provider/TourProvider/tours';
import { useVxLiveLink } from '../../provider/VxLiveLinkProvider';
import { routes } from '../../routes/routesConfig';
import { IconNavButton, IconNavButtonGroup } from '../shared/IconNav/iconNav';

type NavItemInfo = {
  id: number;
  icon: EmotionIcon;
  route: string;
  active?: boolean;
};

type MobileBottomNavProps = {
  navItemInfo: NavItemInfo[];
  feedFlag: boolean;
  isAllowedToUseFeed: boolean;
};

export const MobileBottomNav: React.FC<MobileBottomNavProps> = ({
  navItemInfo,
  feedFlag,
  isAllowedToUseFeed,
}: MobileBottomNavProps) => {
  const {
    actions: { redirectToVxLive },
  } = useVxLiveLink();
  const historyWithStorage = useHistoryWithStorage();
  const isCurrentUrl = (url: string) => window.location.pathname?.includes(url);
  const authCtx = useAuth();
  const renderMobileNav = navItemInfo.map((item, index) => {
    if (index === 1) {
      return (
        <React.Fragment key={item.id}>
          {authCtx.authUser?.permissions.includes(
            AuthUserPermissionEnum.FinanceView
          ) ? (
            <IconNavButton
              onClick={() => historyWithStorage.push(item.route)}
              icon={item.icon}
              isActiveRoute={item.active ?? isCurrentUrl(item.route)}
              isMobile={true}
              iconSize={5}
            />
          ) : (
            <Box boxSize={12} />
          )}
          {feedFlag && (
            <ShowCreateFeedPostModalNavButton
              mt={-4}
              size="lg"
              canUseFeed={isAllowedToUseFeed}
              {...createWelcomeTourDataProps(WelcomeTourStep.CreatePost)}
            />
          )}
        </React.Fragment>
      );
    }
    return (
      <IconNavButton
        key={item.id}
        onClick={() => {
          if (item.route === routes.vxLive.path) {
            redirectToVxLive();
          } else {
            historyWithStorage.push(item.route);
          }
        }}
        icon={item.icon}
        isActiveRoute={item.active ?? isCurrentUrl(item.route)}
        isMobile={true}
        iconSize={5}
        {...(item.id === 4
          ? createWelcomeTourDataProps(WelcomeTourStep.LiveCam)
          : {})}
      />
    );
  });
  return (
    <chakra.footer
      position={'fixed'}
      height={12}
      left={0}
      right={0}
      bottom={0}
      pb={'var(--sai-bottom, 0)'}
      bg={'surface'}
      display={'flex'}
      justifyContent={'center'}
      zIndex={200} //zIndex.navBar
      w={'full'}
      boxShadow={'0px -3px 15px 0px #00000021'}
    >
      <IconNavButtonGroup isMobile={true}>{renderMobileNav}</IconNavButtonGroup>
    </chakra.footer>
  );
};
