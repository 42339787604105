import { ChevronRight } from '@campoint/odi-ui-icons';
import { Icon, Link, LinkProps, chakra } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { externalRoutes } from '../../../../../../routes/routesConfig';

export const HelpcenterLink: React.FC<LinkProps> = (props) => {
  const { t, i18n } = useTranslation(['feed']);
  return (
    <Link
      href={externalRoutes.customerService(i18n.language)}
      isExternal
      rel="noreferrer"
      textColor={'secondary.highEmphasis'}
      display={'inline-flex'}
      alignItems={'center'}
      {...props}
    >
      <chakra.span>{t('feed:link.Helpcenter')}</chakra.span>
      <Icon as={ChevronRight} ps={'2.5'} boxSize={'icon.lg'} />
    </Link>
  );
};
