import { TabList, TabListProps, forwardRef } from '@chakra-ui/react';

export const TabListWithOverflowScroll = forwardRef<TabListProps, 'div'>(
  (props, ref) => (
    <TabList
      maxW={'full'}
      overflowX={'auto'}
      overflowY={'hidden'}
      // Inspired by
      // https://codesandbox.io/p/sandbox/chakra-ui-tabs-overflow-uutmw?file=%2Fsrc%2FApp.js%3A15%2C11-16%2C28
      sx={{
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': { display: 'none' },
        WebkitOverflowScrolling: 'touch',
        boxShadow: 'inset 0 -2px 0 var(--odi-colors-steel)',
        border: '0 none',
        pb: '2px',
      }}
      {...props}
    />
  )
);
