import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';

import { Maybe } from '../../../generated/graphql';

export type LabeledReadonlyFieldSelectProps = {
  label: string;
  field?: Maybe<{
    value?: Maybe<string>;
    quickpickValues: { value: string; label: string }[];
    validValues: { value: string; label: string }[];
  }>;
};

export const LabeledReadonlyFieldSelect: React.FC<
  LabeledReadonlyFieldSelectProps
> = ({ label, field }) => {
  const displayValue =
    field?.validValues.find(({ value }) => value === field?.value)?.label ?? '';
  return (
    <FormControl isReadOnly>
      <FormLabel mb={3} children={label} />
      <Input value={displayValue} mb={6} />
    </FormControl>
  );
};
