import { Tag, TagProps } from '@chakra-ui/react';

export const ImageGalleryTag: React.FC<TagProps> = (props) => (
  <Tag
    minW={'unset'}
    minH={'unset'}
    h={'5'}
    paddingInlineStart={'1'}
    paddingInlineEnd={'1'}
    bg={'blackAlpha.600'}
    color={'white'}
    textStyle={'labelXs'}
    {...props}
  />
);
