import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../../../../../assets/images/wizard/model-release-form-finished.svg';
import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { WizardIllustratedInstruction } from '../../../components/WizardIllustratedInstruction/WizardIllustratedInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';

const WizardPayoutFinishedPending: React.FC = () => {
  const { t } = useTranslation(['wizardPayout', 'general']);
  const wizardPayoutCtx = useWizardPayout();

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardIllustratedInstruction
          illustrationSrc={image}
          illustrationAlt={t('general:img.ZuBearbeitendesDokument')}
          header={t(
            'wizardPayout:heading.VielenDankDeineAngabenWerdenUberpruft'
          )}
          body={t(
            'wizardPayout:text.DuBekommstEineEMailSobaldDeineDokumenteUberpruftWurden'
          )}
        />
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.AllesKlar')}
            onClick={() => {
              wizardPayoutCtx.wizardCloseCallback();
            }}
          />
        </ButtonStack>
      }
    />
  );
};

export default WizardPayoutFinishedPending;
