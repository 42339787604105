import { Tab, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabListWithOverflowScroll } from '../../../../components/Layout/TabListWithOverflowScroll';
import { useQueryParamState } from '../../../../hooks/useQueryParamState';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { PayoutAssistantSection } from '../../section/PayoutAssistantSection/PayoutAssistantSection';
import { PayoutBillingSection } from '../../section/PayoutBillingSection/PayoutBillingSection';
import { PayoutWithdrawalsSection } from '../../section/PayoutWithdrawalsSection/PayoutWithdrawalsSection';

export const PayoutOverviewTabSection: React.FC = () => {
  const tabIds = React.useMemo(() => {
    return ['withdrawl', 'billing', 'assistant'];
  }, []);

  const [tabId, setTabId] = useQueryParamState('tabId');
  const { t } = useTranslation(['financePage']);

  const tabIndex = React.useMemo(() => {
    if (!tabId) {
      return 0;
    }
    const index = tabIds.indexOf(tabId);
    return index >= 0 ? index : 0;
  }, [tabId, tabIds]);

  const {
    isDirty,
    action: { showConfirmChangesDialog },
  } = useNavigationBlock();

  const handleTabChange = React.useCallback(
    (index: number) => {
      const newTabId = tabIds[index] ?? tabIds[0];
      if (!isDirty) {
        setTabId(newTabId);
      } else {
        showConfirmChangesDialog(() => {
          setTabId(newTabId);
        });
      }
    },
    [isDirty, showConfirmChangesDialog, tabIds, setTabId]
  );

  return (
    <>
      <Tabs
        defaultIndex={0}
        index={tabIndex}
        isFitted
        w={'full'}
        onChange={handleTabChange}
        size={{ base: 'sm', lg: 'md' }}
      >
        <TabListWithOverflowScroll>
          <Tab children={t('financePage:tab.Auszahlung')} />
          <Tab children={t('financePage:tab.Abrechnung')} />
          <Tab children={t('financePage:tab.AutomPayout')} />
        </TabListWithOverflowScroll>
        <TabPanels>
          <TabPanel p={0}>
            <PayoutWithdrawalsSection />
          </TabPanel>
          <TabPanel p={0}>
            <PayoutBillingSection />
          </TabPanel>
          <TabPanel p={0}>
            <PayoutAssistantSection />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
