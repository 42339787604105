import {
  Button,
  ButtonProps,
  Heading,
  VStack,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../components/Layout/ResponsiveModal';
import { IllustrationBox } from '../components/shared/IllustrationBox/IllustrationBox';
import Markdown from '../components/shared/Markdown/Markdown';
import { SkeletonTipModalContent } from '../components/shared/SkeletonTipModalContent/SkeletonTipModalContent';
import TagGroup from '../components/shared/TagGroup/TagGroup';
import {
  GetTipDetailDataDocument,
  GetTipSectionDataDocument,
  GetTipsDataDocument,
  GetVxLiveGoLivePageDataDocument,
  TipWithBodyEntryFragment,
  TipsTag,
  useGetTipDetailDataLazyQuery,
  useMarkTipAsReadMutation,
} from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import useIsMounted from '../hooks/useIsMounted';
import { useQueryParamState } from '../hooks/useQueryParamState';
import Logger from '../utils/Logger';
import { getApolloContext } from '../utils/apollo';

type TipDetailModalContextOptions = {
  modalStructure?: React.ReactNode;
  children?: React.ReactNode;
};

type TipDetailModalContext = {
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly tip: Maybe<TipWithBodyEntryFragment>;
  readonly action: {
    readonly showTip: (tipId: string) => void;
    readonly closeModal: () => void;
  };
};

const queryParamTipId = 'tipId' as const;

export const [, useTipDetailModal, tipDetailModalContext] =
  createContext<TipDetailModalContext>({
    name: 'TipDetailModalContext',
  });

const TipDetailModal = () => {
  const tipDetailModalCtx = useTipDetailModal();
  const { isLoading, tip, isOpen } = tipDetailModalCtx;
  const { i18n } = useTranslation();

  const apolloContext = getApolloContext(i18n);

  const [markAsRead] = useMarkTipAsReadMutation({
    refetchQueries: [
      {
        query: GetTipSectionDataDocument,
        context: apolloContext,
      },
      {
        query: GetVxLiveGoLivePageDataDocument,
        context: apolloContext,
      },
      {
        query: GetTipDetailDataDocument,
        context: apolloContext,
        variables: {
          id: tip?.id ?? '',
        },
      },
      {
        query: GetTipsDataDocument,
        context: apolloContext,
      },
    ],
    onError: (error) => Logger.error(error),
  });

  useEffect(() => {
    if (isLoading || !isOpen || !tip || tip.readAt) {
      return;
    }
    const { id } = tip;
    markAsRead({ variables: { id } }).then();
  }, [markAsRead, tip, isOpen, isLoading]);

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={tipDetailModalCtx.action.closeModal}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent maxW={'567px'}>
        <SkeletonTipModalContent isLoading={isLoading}>
          <ResponsiveModalStickyHeaderBox p={0}>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>
          <IllustrationBox image={tip?.headerImage} />
          <ResponsiveModalBodyBox>
            {tip && (
              <VStack gap={4} alignItems="start">
                <TagGroup
                  primary={tip?.experienceTags as TipsTag[]}
                  secondary={tip?.intentionTags as TipsTag[]}
                />
                <Heading size={'md'} mt={4}>
                  {tip?.title}
                </Heading>
                <Markdown markdown={tip?.body} />
              </VStack>
            )}
          </ResponsiveModalBodyBox>
        </SkeletonTipModalContent>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

export const TipDetailModalProvider: React.FC<TipDetailModalContextOptions> = ({
  modalStructure = <TipDetailModal />,
  children,
}) => {
  const isMounted = useIsMounted();

  const [tipId, setTipId] = useQueryParamState(queryParamTipId);

  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: !!tipId,
  });

  const [refetch, { data, loading }] = useGetTipDetailDataLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: () => onOpen(),
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        // fixme! translate toast message
        description: 'Tip konnte nicht geladen werden',
      });
      onClose();
    },
  });

  useEffect(() => {
    // Only once at mount
    if (!tipId) {
      return;
    }

    refetch({ variables: { id: tipId } }).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tip = React.useMemo(() => data?.helpcenter.tips?.tipById, [data]);

  const action = React.useMemo<TipDetailModalContext['action']>(
    () => ({
      showTip: (tipIdToShow: string) => {
        setTipId(tipIdToShow);
        refetch({ variables: { id: tipIdToShow } }).then(
          () => isMounted() && onOpen()
        );
      },
      closeModal: () => {
        setTipId(null);
        onClose();
      },
    }),
    [onClose, onOpen, isMounted, refetch, setTipId]
  );

  const context = React.useMemo<TipDetailModalContext>(
    () => ({
      isLoading: loading,
      isOpen,
      tipId,
      tip,
      action,
    }),
    [action, loading, isOpen, tipId, tip]
  );

  return (
    <tipDetailModalContext.Provider value={context}>
      {children}
      {modalStructure}
    </tipDetailModalContext.Provider>
  );
};

export const ShowTipButton = forwardRef<
  ButtonProps & { tipId: string },
  'button'
>(({ tipId, ...boxProps }, ref) => {
  const tipDetailModalCtx = useTipDetailModal();
  return (
    <Button
      ref={ref}
      isLoading={tipDetailModalCtx.isLoading && !tipDetailModalCtx.tip}
      onClick={() => tipDetailModalCtx.action.showTip(tipId)}
      {...boxProps}
    />
  );
});
