import { Box, ToastProps } from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import { CheckboxSingleControl } from '../../../../../components/shared/FormikFormElements';
import { MediaUploadCropperPortal } from '../../../../../components/shared/MediaUpload/MediaUpload';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { useSkipVerificationBacksideMutation } from '../../../../../generated/graphql';
import { MediaInputProvider } from '../../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { uploadDocument } from '../../../../../utils/media';
import { WizardIdentityProofPreviewBox } from '../../../components/WizardIdentityProofPreviewBox/WizardIdentityProofPreviewBox';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton } from '../../../components/styled';
import { WizardVerificationBasicProps } from '../../WizardVerification';

type WizardVerificationBackImageProps = WizardVerificationBasicProps;

export const WizardVerificationBackImage: React.FC<
  WizardVerificationBackImageProps
> = ({ goToNextStep, uploadUrl, showLoader, tourUserId }) => {
  const { t } = useTranslation([
    'general',
    'wizardVerification',
    'imagePicker',
    'mediaUpload',
  ]);
  const [newImageBlob, setNewImageBlob] = useState<null | Blob>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [skipUpload, { loading: skipLoading }] =
    useSkipVerificationBacksideMutation({
      variables: {
        userId: tourUserId,
      },
      onCompleted: () => {
        goToNextStep();
      },
      onError: (e) => {
        setIsUploading(false);
        issueChakraToast({
          status: 'error',
          description: t('wizardVerification:uploadError'),
        });
      },
    });

  const formik = useFormik({
    initialValues: { isEmpty: false },
    onSubmit: () => {
      // Do nothing
    },
  });

  const uploadImage = React.useCallback(async () => {
    if (!!uploadUrl && !!newImageBlob) {
      const errorToast: ToastProps = {
        status: 'error',
        description: t('wizardVerification:uploadError'),
      };
      try {
        setIsUploading(true);
        const result = await uploadDocument(uploadUrl, newImageBlob);
        if (result.id === null) {
          issueChakraToast(errorToast);
        } else {
          goToNextStep();
        }
      } catch {
        issueChakraToast(errorToast);
      } finally {
        setIsUploading(false);
      }
    }
  }, [newImageBlob, t, uploadUrl, setIsUploading, goToNextStep]);

  const handleContinue = React.useCallback(async () => {
    if (formik.values.isEmpty) {
      await skipUpload();
    } else {
      await uploadImage();
    }
  }, [formik, uploadImage, skipUpload]);

  const isLoading = isUploading || skipLoading || showLoader;

  return (
    <WizardParentModalStepLayout
      contentSection={
        <>
          <WizardSubHeader
            headerText={t('wizardVerification:backImage.subHeader')}
          />
          <MediaPropertiesProvider.ForIdentityProofBackDocument>
            <MediaProvider
              onReplacement={(m) => {
                setNewImageBlob(m?.blob ?? null);
              }}
            >
              <MediaInputProvider
                accept={'DEFAULT_FOR_PICTURE'}
                capture={'environment'}
              >
                <WizardIdentityProofPreviewBox isUploading={isLoading} />
                <MediaUploadCropperPortal />
              </MediaInputProvider>
            </MediaProvider>
          </MediaPropertiesProvider.ForIdentityProofBackDocument>
          <Box mt={'12'}>
            <FormikProvider value={formik}>
              <CheckboxSingleControl
                isDisabled={!!newImageBlob}
                name="isEmpty"
                children={t('wizardVerification:backImage.backEmpty')}
              />
            </FormikProvider>
          </Box>
          <Box mt={'12'}>
            <TipCard text={t('wizardVerification:backImage.hint')} />
          </Box>
        </>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('general:button.Weiter')}
            isDisabled={(!newImageBlob && !formik.values.isEmpty) || isLoading}
            isLoading={isLoading}
            onClick={handleContinue}
          />
        </ButtonStack>
      }
    />
  );
};
