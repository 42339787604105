import { Maybe } from 'graphql/jsutils/Maybe';
import { useEffect } from 'react';

import { useWizardPayoutBankDataBicByIbanLazyQuery } from '../generated/graphql';
import { useDebounce } from './useDebounce';
import useIsMounted from './useIsMounted';

export const useBicAutocomplete = (options: {
  disabled?: boolean;
  iban: Maybe<string>;
  onMatch?: (match: { iban: string; bic: string }) => void;
}) => {
  const isMounted = useIsMounted();
  const { disabled = false, iban, onMatch } = options;
  const debouncedIbanInput = useDebounce(iban ?? '', 500);

  const [getBicByIban] = useWizardPayoutBankDataBicByIbanLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!isMounted()) {
        return;
      }
      const bic = data?.finances?.bicByIban ?? null;
      if (!bic) {
        return;
      }
      onMatch?.({ iban: debouncedIbanInput, bic });
    },
  });

  useEffect(() => {
    // shortest possible iban is 15 chars for norway
    // @see https://www.iban.com/structure
    if (disabled || debouncedIbanInput.length < 15) {
      return;
    }

    getBicByIban({
      variables: {
        iban: debouncedIbanInput,
      },
    });
  }, [debouncedIbanInput, getBicByIban, disabled]);
};
