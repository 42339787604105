import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import FilterIcon from '../../../../components/shared/FilterIcon/FilterIcon';
import {
  CheckboxGroupControl,
  CheckboxGroupControlContainer,
  CheckboxGroupControlProps,
} from '../../../../components/shared/FormikFormElements';
import { FeatureName, useFeature } from '../../../../flags';
import { FeedPostCategoriesEnum } from '../../../../generated/feed';

const CustomCheckboxGroupControl = (props: CheckboxGroupControlProps) => (
  <CheckboxGroupControl px={{ base: 4, md: 8 }} py={4} {...props} />
);

export const FeedFilterToolButtons: React.FC<{
  feedFilters: FeedPostCategoriesEnum[];
  setFeedFilters: (filters: FeedPostCategoriesEnum[]) => void;
}> = ({ feedFilters, setFeedFilters, ...props }) => {
  const isFeedFilterFeatureEnabled = useFeature(FeatureName.feedFilter);
  const { t } = useTranslation(['feed', 'general']);

  const disc = useDisclosure();

  const fieldName = {
    filter: 'filter',
  };

  const formik = useFormik({
    initialValues: {
      [fieldName.filter]: feedFilters ?? [],
    },
    onSubmit: (values, formikHelpers) => {
      setFeedFilters(values.filter);

      formikHelpers.setSubmitting(false);
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const handleReset = () => {
    formik.setValues({
      [fieldName.filter]: [],
    });
    formik.handleSubmit();
  };

  const hasActiveFilter = formik.values.filter.length > 0;

  return !isFeedFilterFeatureEnabled ? (
    <></>
  ) : (
    <>
      <FilterIcon
        hasActiveFilter={hasActiveFilter}
        onFilterClick={disc.onOpen}
        isDisabled={false}
        onResetClick={handleReset}
        ariaLabelAddFilter={t('general:button.FilterHinzufugen')}
        ariaLabelResetFilter={t('general:button.FilterZurucksetzen')}
        ariaLabelEditFilter={t('general:button.FilterBearbeiten')}
      />
      <ResponsiveModal
        isOpen={disc.isOpen}
        onClose={disc.onClose}
        preferredSize="xl"
      >
        <ResponsiveModalOverlay />
        <ResponsiveModalContent>
          <ResponsiveModalStickyHeaderBox p={4}>
            <HStack>
              <Icon as={icons.Tune} verticalAlign={'middle'} />
              <Heading size={'lg'} flexGrow={'1'}>
                {t('feed:heading.FeedFilter')}
              </Heading>
            </HStack>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>
          <ResponsiveModalBodyBox p={0} m={0}>
            <FormikProvider value={formik}>
              <CheckboxGroupControlContainer name={fieldName.filter}>
                <VStack
                  spacing={0}
                  divider={<Divider />}
                  alignItems={'stretch'}
                >
                  <CustomCheckboxGroupControl
                    value={FeedPostCategoriesEnum.Published}
                  >
                    {t('feed:label.VeroffentlichteBeitrage')}
                  </CustomCheckboxGroupControl>
                  <CustomCheckboxGroupControl
                    value={FeedPostCategoriesEnum.Featured}
                  >
                    {t('feed:label.FeaturedPost')}
                  </CustomCheckboxGroupControl>
                  {/* <CustomCheckboxGroupControl
                    value={'2'}
                  >
                    {t('feed:label.GeplanteBeitrage')}
                  </CustomCheckboxGroupControl> */}
                  {/* <CustomCheckboxGroupControl value={'3'}>
                    {t('feed:label.SystemBeitrage')}
                  </CustomCheckboxGroupControl>
                  <CustomCheckboxGroupControl value={'4'}>
                    {t('feed:label.PaidContent')}
                  </CustomCheckboxGroupControl>
                  <CustomCheckboxGroupControl value={'5'}>
                    {t('feed:label.TippingGoal')}
                  </CustomCheckboxGroupControl> */}
                </VStack>
              </CheckboxGroupControlContainer>
            </FormikProvider>
          </ResponsiveModalBodyBox>
          <Flex as={ResponsiveModalStickyFooterBox} justifyContent={'center'}>
            <VStack alignItems={'stretch'}>
              <Button
                variant={'solid'}
                onClick={() => {
                  handleSubmit();
                  disc.onClose();
                }}
                isLoading={formik.isSubmitting}
              >
                {t('feed:button.BeitrageAnzeigen')}
              </Button>
              <Button
                onClick={() => {
                  handleReset();
                  disc.onClose();
                }}
                isLoading={formik.isSubmitting}
              >
                {t('general:button.FilterZurucksetzen')}
              </Button>
            </VStack>
          </Flex>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </>
  );
};
