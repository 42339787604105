import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { PromotingSelectControl } from '../../../../components/shared/FormikFormElements';
import { ProfileFieldTypeEnumFragment } from '../../../../generated/graphql';
import { LabelTextWithGuideDot } from '../LabelTextWithGuideDot/LabelTextWithGuideDot';

export const EnumSelectField: React.FC<{
  field: Maybe<ProfileFieldTypeEnumFragment>;
  label: string;
  placeholder?: string;
  optionLabels: {
    groupLabel: string;
    unPromotedGroupLabel: string;
  };
}> = ({
  field,
  label,
  placeholder,
  optionLabels: { groupLabel, unPromotedGroupLabel },
  ...rest
}) => {
  return !field ? null : (
    <PromotingSelectControl
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      optionListProps={{
        placeholder,
        groupLabel,
        options: field.validValues,
        promotedValues: field.quickpickValues.map((option) => option.value),
        unPromotedGroupLabel,
      }}
      {...rest}
    />
  );
};
