import {
  HStack,
  Icon,
  StackProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React from 'react';

import { NoticeModal, NoticeModalConfig } from '../NoticeModal/NoticeModal';
import { ChevronRightIcon } from '../icon/ChevronRightIcon';

export const DocumentPickerV3Status: React.FC<
  StackProps & {
    icon: EmotionIcon;
    text: React.ReactNode;
    noticeModalConfig?: NoticeModalConfig | null;
  }
> = ({ icon, text, noticeModalConfig, ...stackProps }) => {
  const disc = useDisclosure();
  const isInteractive = !!noticeModalConfig;
  return (
    <HStack
      lineHeight={'20px'}
      fontSize={'xs'}
      cursor={isInteractive ? 'pointer' : undefined}
      spacing={1}
      alignItems={'center'}
      flexShrink={1}
      maxW={'full'}
      onClick={isInteractive ? disc.onOpen : undefined}
      {...stackProps}
    >
      <Icon as={icon} boxSize={'14px'} />
      <Text
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        flexShrink={1}
        whiteSpace={'nowrap'}
        minW={'0'}
      >
        {text}
      </Text>
      {isInteractive && <ChevronRightIcon />}
      {!!noticeModalConfig && (
        <NoticeModal
          isOpen={disc.isOpen}
          onClose={disc.onClose}
          {...noticeModalConfig}
        />
      )}
    </HStack>
  );
};
