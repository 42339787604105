import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { Button, Divider, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import {
  FinancesPayoutAssistantRhythmItem,
  FinancesPayoutStatusEnum,
  GetFinanceServiceDocument,
  GetPayoutAssistantSectionDocument,
  useGetPayoutAssistantSectionQuery,
  useSetPayoutAssistantSettingsMutation,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';

export const PayoutAssistantSection: React.FC = () => {
  const { t } = useTranslation(['general', 'payoutAssistant']);

  const { data, loading: isAssistantDataLoading } =
    useGetPayoutAssistantSectionQuery();

  const [savePayoutAssistantSettings] = useSetPayoutAssistantSettingsMutation({
    refetchQueries: [
      GetPayoutAssistantSectionDocument,
      GetFinanceServiceDocument,
    ],
  });

  const intl = useIntl();

  const formatCurrency = React.useCallback(
    (value: number) => {
      return intl.formatNumber(value, {
        currency: 'EUR',
        style: 'currency',
        maximumFractionDigits: 0,
      });
    },
    [intl]
  );

  const fieldName = {
    payoutInterval: 'payoutInterval',
    minimumPayoutAmount: 'minimumPayoutAmount',
  } as const;

  type FormValues = {
    [fieldName.payoutInterval]: string;
    [fieldName.minimumPayoutAmount]: number | '';
  };

  const mappedData = React.useMemo(() => {
    const wasManualPayoutCreated =
      data?.finances.payoutStatus.status ===
      FinancesPayoutStatusEnum.PayoutAlreadyRequested;
    const currentRhythm = data?.finances.payoutAssistant.currentRhythm;
    const currentMinimumPayoutAmount =
      data?.finances.payoutAssistant.currentMinAmount;

    const isAssistantDeactivated = currentMinimumPayoutAmount === 0;

    const allowedRhythms = (
      data?.finances.payoutAssistant.allowedRhythms ?? []
    ).filter(Boolean) as FinancesPayoutAssistantRhythmItem[];
    const allowedPaymentAmounts = (
      data?.finances.payoutAssistant.allowedAmounts ?? []
    ).filter(Boolean) as number[];

    return {
      isAssistantDeactivated,
      wasManualPayoutCreated,
      currentRhythm,
      currentMinimumPayoutAmount,
      allowedRhythms,
      allowedPaymentAmounts,
    };
  }, [data]);

  const initialValues: FormValues = React.useMemo(() => {
    return {
      [fieldName.payoutInterval]: mappedData.isAssistantDeactivated
        ? ''
        : mappedData.currentRhythm?.id ?? '',
      [fieldName.minimumPayoutAmount]: mappedData.isAssistantDeactivated
        ? ''
        : mappedData.currentMinimumPayoutAmount ?? '',
    };
  }, [mappedData, fieldName.minimumPayoutAmount, fieldName.payoutInterval]);

  const hookForm = useForm<FormValues>({
    defaultValues: {
      [fieldName.payoutInterval]: '',
      [fieldName.minimumPayoutAmount]: 0,
    },
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const dirtyHookFormFields = React.useMemo(() => {
    const dirtyFields = hookForm.formState.dirtyFields;
    return dirtyFields;
  }, [hookForm.formState.dirtyFields]);

  const trySaveAssistantSettings = React.useCallback(
    async (values: any) => {
      const { payoutInterval, minimumPayoutAmount } = values;

      const result = await savePayoutAssistantSettings({
        variables: {
          targetRhythm: payoutInterval,
          targetAmount: Number(minimumPayoutAmount),
        },
      });

      if (result.data?.finances?.savePayoutAssistant.success) {
        issueChakraToast({
          status: 'success',
          title: t('general:toast.AnderungenWurdenGespeichert'),
        });
      } else {
        issueChakraToast({
          status: 'error',
          title: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        });
      }
    },
    [savePayoutAssistantSettings, t]
  );

  const isButtonDisabled = !mappedData.isAssistantDeactivated
    ? Object.keys(dirtyHookFormFields).length === 0
    : Object.keys(dirtyHookFormFields).length < 2;

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);
  React.useEffect(() => {
    return registerDirtyFlag(hookForm.formState.isDirty, onOmitChanges);
  }, [
    hookForm,
    hookForm.formState,
    hookForm.formState.isDirty,
    onOmitChanges,
    registerDirtyFlag,
  ]);

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.PayoutAssistant} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow lineHeight={'32px'}>
            <SectionIcon as={icons.Payments} />
            <SectionTitle
              children={t('payoutAssistant:heading.AutomAuszahlung')}
            />
          </SectionTitleRow>
          <Text size={'16px'} color={'gray.500'} pt={2}>
            {t(
              'payoutAssistant:subheading.DieAuszahlungErfolgJedenMonatAutmImGewahltenRhythmus'
            )}
          </Text>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      {/* If there is a manual payout that has been requested, show the user that the assistant can not be set up before canceling the payout.
      If there is no manual payout, show payout assistant. */}
      {mappedData.wasManualPayoutCreated ? (
        <SectionBody pos={'relative'} minH={'400px'}>
          <SectionCenterContainer>
            {isAssistantDataLoading ? (
              <AbsoluteFullCenterLoadingSpinner />
            ) : (
              <VStack gap={4}>
                <Icon as={icons.Info} boxSize={'icon.md'} p={0} />

                <VStack spacing="4">
                  <Heading size={'xl'} p={0} textAlign={'center'}>
                    {t(
                      'payoutAssistant:heading.AssistentKannNichtEingerichtetWerden'
                    )}
                  </Heading>
                  <Text
                    size={{ base: 'sm', lg: 'md' }}
                    textAlign={'center'}
                    color={'gray.500'}
                    p={0}
                  >
                    {t(
                      'payoutAssistant:text.EinrichtungDesAssistentenNichtMoglichDaDieAuszahlungManuellBeantragtXX'
                    )}
                  </Text>
                </VStack>
              </VStack>
            )}
          </SectionCenterContainer>
        </SectionBody>
      ) : (
        <FormProvider {...hookForm}>
          <form onSubmit={hookForm.handleSubmit(trySaveAssistantSettings)}>
            <SectionBody pos={'relative'} minH={'400px'}>
              <SectionCenterContainer>
                {isAssistantDataLoading ? (
                  <AbsoluteFullCenterLoadingSpinner />
                ) : (
                  <VStack divider={<Divider />} gap={6}>
                    <EnumSelectFieldHookForm
                      isDisabled={false}
                      label={t('payoutAssistant:label.Auszahlungsrhythmus')}
                      name={fieldName.payoutInterval}
                    >
                      <option value={''} hidden disabled>
                        {t(
                          'payoutAssistant:placeholder.WahleDeinenRhythmusAus'
                        )}
                      </option>
                      {mappedData.allowedRhythms.map((rhythm, index) => {
                        return (
                          <option key={rhythm.id} value={rhythm.id}>
                            {rhythm.label}
                          </option>
                        );
                      })}
                    </EnumSelectFieldHookForm>
                    <EnumSelectFieldHookForm
                      isDisabled={false}
                      label={t('payoutAssistant:label.Mindestbetrag')}
                      name={fieldName.minimumPayoutAmount}
                    >
                      <option value={''} hidden disabled>
                        {t(
                          'payoutAssistant:placeholder.WahleDeinenMindestbetrag'
                        )}
                      </option>

                      {mappedData.allowedPaymentAmounts.map((amount, index) => {
                        return (
                          <option key={amount} value={amount}>
                            {formatCurrency(amount)}
                          </option>
                        );
                      })}
                    </EnumSelectFieldHookForm>
                    <Button
                      variant={'solid'}
                      isDisabled={isButtonDisabled}
                      children={
                        !mappedData.isAssistantDeactivated
                          ? t('payoutAssistant:button.Speichern')
                          : t('payoutAssistant:button.AssistentAktivieren')
                      }
                      type="submit"
                    />
                  </VStack>
                )}
              </SectionCenterContainer>
            </SectionBody>
          </form>
        </FormProvider>
      )}
    </Section>
  );
};
