import {
  Textarea,
  TextareaProps,
  forwardRef,
  useMergeRefs,
} from '@chakra-ui/react';
import React from 'react';
import ResizeTextarea from 'react-textarea-autosize';
import { TextareaAutosizeProps } from 'react-textarea-autosize/dist/declarations/src';

// https://github.com/chakra-ui/chakra-ui/issues/670#issuecomment-969444392
export const AutoResizeTextarea = forwardRef<
  TextareaProps & TextareaAutosizeProps,
  'textarea'
>((props, ref) => {
  // We have to merge refs here so that the internal ref from ResizeTextarea is not blocked
  const refs = useMergeRefs(ref);
  return (
    <Textarea
      minH="unset"
      overflowY={'auto'}
      w="100%"
      resize="none"
      ref={refs}
      minRows={1}
      maxRows={3}
      as={ResizeTextarea}
      {...props}
    />
  );
});
