import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { PrivacyHint } from '../../../../components/shared/PrivacyHint/PrivacyHint';
import { useWizardPayoutBankDataQuery } from '../../../../generated/graphql';
import { useWizardOnContinueCallback } from '../../../../hooks/useWizardOnContinueCallback';
import { noop } from '../../../../utils';
import { createStringValidationSchema } from '../../../../utils/validation';
import { WPPayoutData } from '../../PayoutV2/step/PayoutData/WizardPayoutPayoutDataTypes';
import { WizardInstruction } from '../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../components/WizardStepFooter/WizardStepFooter';
import { useWizardPayout } from '../WizardPayoutContext';
import { WPBankData } from './WizardPayoutBankDataTypes';
import WizardPayoutBankDataBankTransferTab from './tabs/bankTransfer/WizardPayoutBankDataBankTransferTab';
import WizardPayoutBankDataPaxumTab from './tabs/paxum/WizardPayoutBankDataPaxumTab';

const bankTransferTabValue = 'bankTransferTab' as const;
const paxumTabValue = 'paxumTab' as const;

const tabs = [bankTransferTabValue, paxumTabValue] as const;
type TabId = (typeof tabs)[number];

export const WizardPayoutBankData: React.FC = () => {
  const { t } = useTranslation(['general', 'wizardPayout']);
  const wizard = useWizardPayout();
  // Updates the account data set in the previous step.
  const {
    data: payoutBankData,
    loading,
    error: payoutBankDataLoadingError,
  } = useWizardPayoutBankDataQuery();

  const isAccountFromGermany =
    payoutBankData?.account.isAccountFromGermany ?? true;
  const isAccountFromEU = payoutBankData?.account.isAccountFromEU ?? false;

  const isBankTransferDisabled = false;
  const isPaxumDisabled = isAccountFromGermany;
  const isPaxumPreselected = !isAccountFromGermany && !isAccountFromEU;
  // BE sends isAccountFromEU as false when isAccountFromGermany true
  // As a workaround always enable autofill, when a matching iban was found
  const isAutoQueryBicEnabled = true;

  const isSomePayoutMethodDisabled = isPaxumDisabled || isBankTransferDisabled;

  // fix paxum form not being selected / used when paxum is preselected
  useEffect(() => {
    if (!loading) {
      // Wait for all data to be be loaded to decie what the tab should be
      setSelectedTab(isPaxumPreselected ? paxumTabValue : bankTransferTabValue);
    }
  }, [loading, isPaxumPreselected]);

  useEffect(() => {
    if (payoutBankDataLoadingError) {
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
        status: 'error',
      });
      wizard.wizardCloseCallback();
    }
  }, [payoutBankDataLoadingError, t, wizard]);

  const [selectedTab, setSelectedTab] = useState<TabId>(
    isPaxumPreselected ? paxumTabValue : bankTransferTabValue
  );

  const bankTransferFormik = useFormik({
    initialValues: WPBankData.bankTransferFormInit,
    onSubmit: noop,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      [WPPayoutData.accountHolderInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutBankaccountOwner?.minLength ?? 1,
        maxLength: wizard.currentField?.payoutBankaccountOwner?.maxLength ?? 70,
      }),
      [WPPayoutData.ibanInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutIban?.minLength ?? 5,
        maxLength: wizard.currentField?.payoutIban?.maxLength ?? 34,
      }),
      [WPPayoutData.bicInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutBic?.minLength ?? 8,
        maxLength: wizard.currentField?.payoutBic?.maxLength ?? 11,
      }),
    }),
  });

  const paxumFormik = useFormik({
    initialValues: WPBankData.paxumFormInit,
    validateOnBlur: true,
    onSubmit: noop,
    validationSchema: Yup.object().shape({
      [WPBankData.paxumEmailInputName]: createStringValidationSchema({
        minLength: wizard.currentField?.payoutPaxumEmailAccount?.minLength ?? 1,
        maxLength:
          wizard.currentField?.payoutPaxumEmailAccount?.maxLength ?? 60,
        isEmail: true,
      }),
    }),
  });

  const onContinue = useWizardOnContinueCallback({
    wizard,
    currentSelected: selectedTab,
    formiks: {
      [bankTransferTabValue]: bankTransferFormik,
      [paxumTabValue]: paxumFormik,
    },
  });

  return (
    <WizardParentModalStepLayout
      isContentLoading={loading}
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t(
            'wizardPayout:heading.AufWelchesKontoSollenWirDeinGeldUberweisen'
          )}
          children={
            <Tabs
              defaultIndex={isPaxumPreselected ? 1 : 0}
              onChange={(index) => {
                setSelectedTab(tabs[index]);
              }}
              isFitted
            >
              <TabList
                display={isSomePayoutMethodDisabled ? 'none' : undefined}
              >
                <Tab isDisabled={isBankTransferDisabled}>
                  {t('wizardPayout:heading.Bankuberweisung')}
                </Tab>
                <Tab isDisabled={isPaxumDisabled}>
                  {t('wizardPayout:heading.Paxum')}
                </Tab>
              </TabList>
              <TabPanels pt={6} mb={8}>
                <TabPanel p={0}>
                  <WizardPayoutBankDataBankTransferTab
                    isIbanEnforced={isAccountFromGermany}
                    form={bankTransferFormik}
                    autoQueryBicEnabled={isAutoQueryBicEnabled}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <WizardPayoutBankDataPaxumTab form={paxumFormik} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          }
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardPayout:button.Weiter')}
          omitSkipButton
          onContinueButtonClick={onContinue}
        />
      }
    />
  );
};
