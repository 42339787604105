import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';

import { NotificationFragment } from '../../../../generated/notifications';
import Markdown from '../../Markdown/Markdown';
import { getIconAndColor } from '../utils';

export type NotificationAnnouncementItemProps = {
  notification: NotificationFragment;
};

export const NotificationAnnouncementItem: React.FC<
  NotificationAnnouncementItemProps
> = (props: NotificationAnnouncementItemProps) => {
  const { title, subtitle, picture, icon } = props.notification;
  const { iconType, color } = getIconAndColor(icon?.src);
  return (
    <Flex
      w="full"
      h="full"
      direction="column"
      p={4}
      overflow={'hidden'}
      textOverflow={'ellipsis'}
      gap={2}
    >
      <Flex
        w="full"
        direction="column"
        gap={2}
        minH={'100px'}
        border={'1px'}
        borderRadius={'8px'}
        borderColor={'primary.200'}
      >
        {/* Header */}
        <Flex
          direction="row"
          w={'full'}
          justify={'start'}
          align={'center'}
          fontSize={'sm'}
          minH={'24px'}
          p={2}
        >
          {iconType && color && (
            <Icon as={iconType} color={color} boxSize={4} mr={'6px'} />
          )}
          <Text fontWeight={'medium'} fontSize={'sm'} lineHeight={'20px'}>
            {title}
          </Text>
        </Flex>
        {picture && (
          <Box
            backgroundColor={'green'}
            h={'110px'}
            w={'full'}
            bgImage={picture?.src ?? ''}
            aria-label={picture.alt ?? ''}
            bgSize={'cover'}
            bgPosition={'center'}
          />
        )}
        {subtitle && (
          <Markdown
            containerProps={{
              px: 2,
              css: {
                overflow: 'hidden',
                WebkitLineClamp: '4',
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                textOverflow: 'ellipsis',
              },
            }}
            markdown={subtitle}
          />
        )}
        <Button
          p={2}
          variant={'link'}
          fontWeight={'medium'}
          fontSize={'sm'}
          color={'gray.300'}
          alignSelf={'start'}
        >
          Mehr anzeigen...
        </Button>
      </Flex>

      {/* 
          {iconType && color && (
            <Icon as={iconType} color={color} boxSize={4} mr={'6px'} />
          )}
          <Text
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            noOfLines={1}
            size={'sm'}
            color={'coldGray.300'}
          >
            {shortInfo && (
              <>
                {shortInfo} <span> | </span>{' '}
              </>
            )}
            <DateDisplay date={updatedAt} format={'dd.MM.yy'} />
          </Text>
        </Flex>
        {title && (
          <Text
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            noOfLines={2}
            alignSelf={'start'}
            size={'sm'}
            color={'coldGray.9000'}
          >
            {title}
          </Text>
        )}
      </Flex>
      {picture && (
        <Box
          h={'auto'}
          maxH={'60px'}
          w={'48px'}
          bgImage={picture?.src ?? ''}
          aria-label={picture.alt ?? ''}
          bgSize={'cover'}
          bgPosition={'center'}
          mr={2}
        />
      )}
      <RelativeBox
        attachment={<CornerGuideDot isShown={readAt == null} top={'0'} />}
        w={'min-content'}
        flex={1}
      /> */}
    </Flex>
  );
};
