import React from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { createContext } from '../hooks/useContext';
import { useCookieConsentLanguageOptions } from '../hooks/useCookieConsentLanguageOptions';
import Logger from '../utils/Logger';
import { useAuth } from './AuthProvider';
import { useGoogleAnalytics } from './GoogleAnalyticsProvider';

const cookieName = 'cc_cookie';
const cookieDomain =
  '.' + window.location.hostname.split('.').splice(-2).join('.');

export interface CookieConsentContext {
  readonly action: {
    decline(category: string | string[]): void;
  };
}

export const [, useCookieConsent, cookieConsentCtx] =
  createContext<CookieConsentContext>();

export const CookieConsentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const authCtx = useAuth();
  const langOptions = useCookieConsentLanguageOptions();
  const { actions: googleAnalyticsActions } = useGoogleAnalytics();

  const decline = React.useCallback((exclusions: string[]) => {
    const cc = CookieConsent.validConsent();
    if (!cc) {
      Logger.error('cookieConsent not valid');
      return;
    }

    const userPreferences = CookieConsent.getUserPreferences();

    CookieConsent.acceptCategory(
      userPreferences.acceptedCategories,
      exclusions
    );

    setTimeout(() => window.location.reload(), 0);
  }, []);

  const action = React.useMemo(() => {
    return {
      decline,
    };
  }, [decline]);

  const onConsent = () => {
    const domains = [
      '.beta.vxmodels.com',
      '.beta-rc.vxmodels.com',
      '.beta-develop.vxmodels.com',
      '.beta-stage1.vxmodels.com',
      '.beta-stage2.vxmodels.com',
      '.beta-stage3.vxmodels.com',
    ];

    // cleanup old cookies
    domains.forEach((domain) => {
      document.cookie = `cc_cookie=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
    });
  };

  React.useEffect(() => {
    if (authCtx.waitingForAuthStatus) {
      return;
    }

    CookieConsent.run({
      language: {
        default: langOptions.current_lang,
        translations: langOptions.languages,
      },
      cookie: { domain: cookieDomain, name: cookieName },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        functional: {},
        analytics: {},
      },
      guiOptions: {
        consentModal: {
          layout: 'box inline',
        },
      },
      onConsent,
      onChange: onConsent,
      disablePageInteraction: true,
      autoShow: true,
      autoClearCookies: true,
      revision: 1,
    });
  }, [authCtx.waitingForAuthStatus, googleAnalyticsActions, langOptions]);

  const context = React.useMemo(() => {
    return {
      action,
    };
  }, [action]);

  return <cookieConsentCtx.Provider value={context} children={children} />;
};
