import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import {
  Container,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useHistory } from 'react-router-dom';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../../components/Layout/LayoutCenteringVStack';
import { TabListWithOverflowScroll } from '../../../components/Layout/TabListWithOverflowScroll';
import { InlineGuideDot } from '../../../components/shared/GuideDot/GuideDot';
import ProfileHeader from '../../../components/shared/ProfileHeader/ProfileHeader';
import { ProfileMenuSwitch } from '../../../components/shared/ProfileMenuSwitch/ProfileMenuSwitch';
import { UpdateTaxInformationExpiredHintSlot } from '../../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { ModelProfileProgressCardGuideToggle } from '../../../components/shared/cards/ModelProfileProgressCard/ModelProfileProgressCard';
import { ConfirmChangesDialog } from '../../../components/shared/dialogs/ConfirmChangesDialog/ConfirmChangesDialog';
import { FeatureName, useFeature } from '../../../flags';
import { useBeforeUnload } from '../../../hooks/useBeforeUnload';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { routes } from '../../../routes/routesConfig';
import { noop } from '../../../utils';
import { useModelProfile } from '../provider/ModelProfileProvider';
import { AppearanceSection } from './section/AppearanceSection/AppearanceSection';
import { ModelProfileAboutMeSection } from './section/ModelProfileAboutMeSection/ModelProfileAboutMeSection';
import { OldModelProfileAboutMeSection } from './section/ModelProfileAboutMeSection/OldModelProfileAboutMeSection';
import { ModelProfileInterviewSection } from './section/ModelProfileInterviewSection/ModelProfileInterviewSection';
import { SedcardSection } from './section/SedcardSection/SedcardSection';

type TabData = { label: string; content: ReactElement; isIncomplete: boolean };

//shouldnt be touched -> needed for notification anchor
export enum ModelProfileTabIds {
  AboutMe = 'aboutMe',
  Appearance = 'appearance',
  Interview = 'interview',
  Sedcard = 'sedcard',
}

const ModelProfileTabs: React.FC<{
  data: TabData[];
}> = ({ data }) => {
  const history = useHistory();
  const {
    isGuideDotVisible,
    isDataDirty,
    actions: { clearMutationErrors },
  } = useModelProfile();
  useBeforeUnload(isDataDirty); // Opens a native browser prompt if unsaved changes exist and the site is closed or reloaded
  const [isConfirmChangesModalDisplayed, setIsConfirmChangesModalDisplayed] =
    useState(false);
  const [modalNavigateBackCallback, setModalNavigateBackCallback] =
    useState<() => void>(noop);
  const [tabId, setTabId] = useQueryParamState('tab');

  const historyUnblock = useRef<() => void>(noop);

  // Blocks navigation to another page if unsaved changes exist
  useEffect(() => {
    historyUnblock.current = history.block(({ pathname, search }): any => {
      if (isDataDirty && pathname !== routes.profileV2.path) {
        setIsConfirmChangesModalDisplayed(true);
        setModalNavigateBackCallback(() => {
          return () => {
            clearMutationErrors();
            historyUnblock.current();
            history.push(pathname);
            setIsConfirmChangesModalDisplayed(false);
          };
        });
        return false;
      }

      return true;
    });

    return () => {
      historyUnblock.current();
    };
  }, [isDataDirty, clearMutationErrors, history]);

  const tabIds: string[] = Object.values(ModelProfileTabIds);

  const tabIndex = React.useMemo(() => {
    if (!tabId) {
      return 0;
    }
    const index = tabIds.indexOf(tabId);
    return index >= 0 ? index : 0;
  }, [tabId, tabIds]);

  const handleTabChange = useCallback(
    (index: number) => {
      const newtabId = tabIds[index] ?? tabIds[0];
      if (!isDataDirty) {
        setTabId(newtabId);
      } else {
        setModalNavigateBackCallback(() => {
          return () => {
            clearMutationErrors();
            setIsConfirmChangesModalDisplayed(false);
            setTabId(newtabId);
          };
        });
        setIsConfirmChangesModalDisplayed(true);
      }
    },
    [tabIds, isDataDirty, setTabId, clearMutationErrors]
  );

  return (
    <>
      <ConfirmChangesDialog
        isOpen={isConfirmChangesModalDisplayed}
        navigateBack={modalNavigateBackCallback}
        onClose={() => setIsConfirmChangesModalDisplayed(false)}
      />
      <Tabs
        isManual // Prevents onChange to be fired twice. See: https://github.com/chakra-ui/chakra-ui/issues/5552
        isFitted
        isLazy
        index={tabIndex}
        onChange={handleTabChange}
        size={{ base: 'sm', lg: 'md' }}
      >
        <TabListWithOverflowScroll>
          {data.map((tab, index) => (
            <Tab key={index} whiteSpace={'nowrap'}>
              <span>
                {tab.label}
                {isGuideDotVisible && tab.isIncomplete && <InlineGuideDot />}
              </span>
            </Tab>
          ))}
        </TabListWithOverflowScroll>
        <TabPanels>
          {data.map((tab, index) => (
            <TabPanel key={index} p={0}>
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export const ModelProfilePage: React.FC<{
  blockerCard?: React.ReactNode | null;
}> = ({ blockerCard }) => {
  const isDeeplAllowed = useFeature(FeatureName.deeplImplementation);

  const { t } = useTranslation(['modelProfile', 'mediaUpload']);
  const modelProfileCtx = useModelProfile();
  const tabData: TabData[] = [
    {
      label: t('modelProfile:section.UberMich'),
      content: isDeeplAllowed ? (
        <ModelProfileAboutMeSection />
      ) : (
        <OldModelProfileAboutMeSection />
      ),
      isIncomplete: modelProfileCtx.completeness.isAboutMeSectionIncomplete,
    },
    {
      label: t('modelProfile:section.Aussehen'),
      content: <AppearanceSection />,
      isIncomplete: modelProfileCtx.completeness.isAppearanceSectionIncomplete,
    },
    {
      label: t('modelProfile:section.Interview'),
      content: <ModelProfileInterviewSection />,
      isIncomplete: modelProfileCtx.completeness.isInterviewSectionIncomplete,
    },
    {
      label: t('modelProfile:section.Sedcards'),
      content: <SedcardSection />,
      isIncomplete: modelProfileCtx.completeness.isSedcardSectionIncomplete,
    },
  ];

  const fields = modelProfileCtx?.mediaCollection?.fields ?? {};

  const username =
    modelProfileCtx?.aboutMeCollection?.fields?.modelUsername?.value ?? '';

  const { modelPictureTitleLandscape, modelPictureFSK16Avatar } = fields;

  const progressOverride = modelProfileCtx.completeness.progress / 100;

  return (
    <FluentPageLayout isContentLoading={modelProfileCtx.isInitDataLoading}>
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <SubHeaderTitle>
            {t('modelProfile:heading.Modelprofil')}
          </SubHeaderTitle>
        </Container>
      </SubHeader>
      <VStack
        maxW={'container.xl'}
        marginX={'auto'}
        alignItems={'stretch'}
        spacing={'8'}
      >
        <ProfileHeader
          modelPictureTitleLandscape={modelPictureTitleLandscape}
          modelPictureFSK16Avatar={modelPictureFSK16Avatar}
          username={username}
          isEditable={modelProfileCtx.isModelVerified}
        />
        <ProfileMenuSwitch />
        <UpdateTaxInformationExpiredHintSlot />
        {blockerCard ? (
          <LayoutCenteringVStack>{blockerCard}</LayoutCenteringVStack>
        ) : (
          <>
            <LayoutCenteringVStack>
              {progressOverride < 1 && (
                <ModelProfileProgressCardGuideToggle
                  progress={progressOverride}
                  isActivated={modelProfileCtx.isGuideDotVisible}
                  onToggleGuideDots={
                    modelProfileCtx.actions.toggleGuideDotVisibility
                  }
                />
              )}
            </LayoutCenteringVStack>
            <ModelProfileTabs data={tabData} />
          </>
        )}
      </VStack>
    </FluentPageLayout>
  );
};
