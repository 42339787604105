import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import {
  ModelProfileParentQuery,
  ModelProfileParentWizardFragment,
  ProfileStatusEnum,
  useModelProfileParentQuery,
} from '../../../generated/graphql';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { TipDetailModalProvider } from '../../../provider/TipDetailModalProvider';
import { routes } from '../../../routes/routesConfig';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { ModelProfilePage } from '../ModelProfilePage/ModelProfilePage';
import { ModelProfileCompleteProfileAcceptedImageInfoPage } from '../infoPages/ModelProfileCompleteProfileAcceptedImageInfoPage/ModelProfileCompleteProfileAcceptedImageInfoPage';
import { ModelProfileCompleteProfileInfoPage } from '../infoPages/ModelProfileCompleteProfileInfoPage/ModelProfileCompleteProfileInfoPage';
import { ModelProfileCompleteProfilePendingImageInfoPage } from '../infoPages/ModelProfileCompleteProfilePendingImageInfoPage/ModelProfileCompleteProfilePendingImageInfoPage';
import { ModelProfileCompleteProfileRejectImageInfoPage } from '../infoPages/ModelProfileCompleteProfileRejectImageInfoPage/ModelProfileCompleteProfileRejectImageInfoPage';
import { ModelProfileCreateProfileInfoPage } from '../infoPages/ModelProfileCreateProfileInfoPage/ModelProfileCreateProfileInfoPage';
import { ModelProfileRejectedProfileInfoPage } from '../infoPages/ModelProfileRejectedProfileInfoPage/ModelProfileRejectedProfileInfoPage';
import { ModelProfileProvider } from '../provider/ModelProfileProvider';

const extractProfileCollection = (data?: ModelProfileParentQuery) => {
  const collection = data?.profile.collection;
  return collection?.__typename === 'ProfileCollectionMobileProfileV1'
    ? collection
    : null;
};

const extractOnboardingWizardCollection = (data: ModelProfileParentQuery) => {
  const collection = data?.tour;
  return collection?.__typename === 'TourOnboardingV1' ? collection : null;
};

// The status of the tour cannot be used because, for example, the state untouched does not exist.
// Only the status incomplete exists. It does not matter whether the wizard has never been started.
type OnboardingWizardStatus =
  | 'untouched'
  | 'incomplete'
  | 'complete'
  | 'verified';

export type ModelProfileParentData = {
  avatarStatus: ProfileStatusEnum | null;
  onboardingWizardStatus: OnboardingWizardStatus;
};

function getOnboardingWizardStatus(
  onboardingWizardCollection: ModelProfileParentWizardFragment
): OnboardingWizardStatus {
  if (onboardingWizardCollection.lastVerified !== null) {
    return 'verified';
  } else if (onboardingWizardCollection.completeness.percent <= 0) {
    return 'untouched';
  } else if (onboardingWizardCollection.completeness.percent >= 100) {
    return 'complete';
  } else {
    return 'incomplete';
  }
}

const getModelProfileParentData = (
  data: ModelProfileParentQuery
): ModelProfileParentData | null => {
  const profileCollection = extractProfileCollection(data);
  const OnboardingWizardCollection = extractOnboardingWizardCollection(data);

  // The profile collection can be null with the queried data.
  // However, the onboarding wizard collection must be there. If no data is returned here, an error has occurred in the backend.
  if (!OnboardingWizardCollection) {
    return null;
  }

  return {
    avatarStatus: profileCollection?.fields.modelPictureAvatar?.status ?? null,
    onboardingWizardStatus: getOnboardingWizardStatus(
      OnboardingWizardCollection
    ),
  };
};

// To get the different model profile urls// To get the different model profile urls
const getModelProfileUrl = (
  modelProfileParentData: ModelProfileParentData
): string => {
  if (modelProfileParentData.onboardingWizardStatus === 'verified') {
    /** Normal editable profile page if wizard was completed and all accepted */
    return routes.profileV2.path;
  } else if (modelProfileParentData.onboardingWizardStatus === 'untouched') {
    /* Create a profile if it does not exist */
    return routes.profileV2.virtualSubRoutes.createProfile;
  } else if (
    modelProfileParentData.avatarStatus === ProfileStatusEnum.Incomplete
  ) {
    /* If profile is incomplete */
    return routes.profileV2.virtualSubRoutes.completeProfile;
  } else if (
    /* If profile picture is pending and basic info is incomplete */
    modelProfileParentData.avatarStatus === ProfileStatusEnum.Pending &&
    modelProfileParentData.onboardingWizardStatus === 'incomplete'
  ) {
    return routes.profileV2.virtualSubRoutes.pendingCompleteProfile;
  } else if (
    /* If profile picture is rejected and basic info is incomplete */
    modelProfileParentData.avatarStatus === ProfileStatusEnum.Rejected &&
    modelProfileParentData.onboardingWizardStatus === 'incomplete'
  ) {
    return routes.profileV2.virtualSubRoutes.rejectedCompleteProfile;
  } else if (
    /* If profile picture is rejected and basic info is complete */
    modelProfileParentData.avatarStatus === ProfileStatusEnum.Rejected &&
    modelProfileParentData.onboardingWizardStatus === 'complete'
  ) {
    return routes.profileV2.virtualSubRoutes.rejected;
  } else if (
    /* If profile picture is accepted but basic info is incomplete */
    modelProfileParentData.avatarStatus === ProfileStatusEnum.Accepted &&
    modelProfileParentData.onboardingWizardStatus === 'incomplete'
  ) {
    return routes.profileV2.virtualSubRoutes.acceptedCompleteProfile;
  } else {
    /** Normal editable profile page */
    return routes.profileV2.path;
  }
};

export const ModelProfileParent: React.FC = () => {
  const [modelProfileUrl, setModelProfileUrl] = useState<string | null>(null);
  const history = useHistory();
  const queryParams = useQueryParams();

  const { data, loading, error } = useModelProfileParentQuery();

  useEffect(() => {
    if (data) {
      const modelProfileParentData = getModelProfileParentData(data);

      // Display the error page if the data could not be loaded correctly.
      if (!modelProfileParentData) {
        setModelProfileUrl(routes.error.path);
        return;
      }

      setModelProfileUrl(getModelProfileUrl(modelProfileParentData));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setModelProfileUrl(routes.error.path);
    }
  }, [error]);

  // This is still necessary to make the state-depending routing in this component work
  useEffect(() => {
    if (modelProfileUrl) {
      // Only passes the url query params when the route is /profile, since none of the other routes take any query params
      // Param handling is then done in the ModelProfilePage component
      history.replace({
        pathname: modelProfileUrl,
        search:
          modelProfileUrl === routes.profileV2.path
            ? queryParams.toString()
            : undefined,
      });
    }
  }, [modelProfileUrl, history, queryParams]);

  if (loading) {
    return (
      <FluentPageLayout>
        <LoadingPage />
      </FluentPageLayout>
    );
  }

  // Decides which page content is currently displayed.
  let blockerCard = null;
  switch (modelProfileUrl) {
    case routes.profileV2.virtualSubRoutes.createProfile:
      blockerCard = <ModelProfileCreateProfileInfoPage />;
      break;
    case routes.profileV2.virtualSubRoutes.completeProfile:
      blockerCard = <ModelProfileCompleteProfileInfoPage />;
      break;
    case routes.profileV2.virtualSubRoutes.pendingCompleteProfile:
      blockerCard = <ModelProfileCompleteProfilePendingImageInfoPage />;
      break;
    case routes.profileV2.virtualSubRoutes.rejectedCompleteProfile:
      blockerCard = <ModelProfileCompleteProfileRejectImageInfoPage />;
      break;
    case routes.profileV2.virtualSubRoutes.rejected:
      blockerCard = <ModelProfileRejectedProfileInfoPage />;
      break;
    case routes.profileV2.virtualSubRoutes.acceptedCompleteProfile:
      blockerCard = <ModelProfileCompleteProfileAcceptedImageInfoPage />;
      break;
    default:
      break;
  }

  return (
    <TipDetailModalProvider>
      <ModelProfileProvider>
        <ModelProfilePage blockerCard={blockerCard} />
      </ModelProfileProvider>
    </TipDetailModalProvider>
  );
};
