import * as icons from '@campoint/odi-ui-icons';
import { Box, Icon } from '@chakra-ui/react';

export const GMUStoerer: React.FC<{
  onOpen?: () => void;
}> = ({ onOpen }) => {
  return (
    <Box
      position={'fixed'}
      right={0}
      width={'42px'}
      top={'calc((100dvh / 2) + 50px)'}
      transform={'translateY(-50%)'}
      height={'238px'}
      borderLeftRadius={'10px'}
      border={'1px solid #F52764'}
      cursor={'pointer'}
      zIndex={99}
      backgroundColor={'#F86893'}
      color={'white'}
      onClick={onOpen}
    >
      <Box
        transform={'rotate(-90deg) translate(-100%, 0)'}
        transformOrigin={'top left'}
        width={'220px'}
        height={'20px'}
        m={'7px'}
        fontWeight={300}
        textAlign={'center'}
      >
        <Icon as={icons.VxModelsLogo} height={'24px'} width={'24px'} />
        <Icon as={icons.VxModelsName} height={'30px'} width={'110px'} />
        goes 2025!
      </Box>
    </Box>
  );
};
