// to disable the focus state that is triggered by pressing with the mouse
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChakraToastContainer } from './components/Layout/ChakraToastContainer';
import './fonts';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import { useAuth } from './provider/AuthProvider';
import Router from './routes/Router';

function App() {
  const { waitingForAuthStatus } = useAuth();
  const { t } = useTranslation('loadingPage');

  const showLoadingPage = waitingForAuthStatus;

  if (showLoadingPage) {
    return <LoadingPage message={t('loadingText')} variant={'light'} />;
  }

  return (
    <>
      <Router />
      <ChakraToastContainer />
    </>
  );
}

export default App;
