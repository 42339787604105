import { InputProps } from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';
import * as React from 'react';

import { useBoolean } from '../../../../hooks/useBoolean';
import { createContext } from '../../../../hooks/useContext';
import { ClearableInputGroupProps } from './ClearableInputGroup';

export type UseClearableInputProps = ClearableInputGroupProps;

export type UseClearableInput = {
  isFocused: boolean;
  onClearInput: () => void;
  //addons
  showRightAddonChildren: boolean;
  showClearableAddon: boolean;
  // field prop getter
  getClearableInputFieldProps: (props?: InputProps, ref?: any) => InputProps;
};

export function useClearableInput(
  props: UseClearableInputProps
): UseClearableInput {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useBoolean();
  const { isClearable = true, isReadOnly = false, isDisabled = false } = props;

  const [internalInputValue, setInternalInputValue] = React.useState<string>(
    props.value ?? ''
  );
  // uses the props provided input value over the internalInputValue
  const inputValue = props.value ?? internalInputValue;

  const showClearableAddon = React.useMemo(
    () =>
      (isFocused || inputValue !== '') &&
      isClearable &&
      !isReadOnly &&
      !isDisabled,
    [isClearable, inputValue, isDisabled, isFocused, isReadOnly]
  );

  const showRightAddonChildren = React.useMemo(
    () => !showClearableAddon,
    [showClearableAddon]
  );

  const onClearInput = React.useCallback(() => {
    setInternalInputValue('');
    props.onChange?.('');
    inputRef?.current?.focus();
  }, [props]);

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback((e) => {
      setInternalInputValue(e.currentTarget.value);
    }, []);

  const onKeyPress: React.KeyboardEventHandler = (e) => {
    const charCode = e.key;
    if (!charCode.match(/^\d+$/)) {
      e.preventDefault();
    }
  };

  const getClearableInputFieldProps = React.useCallback(
    (componentProps?: InputProps, forwardedRef = null) => {
      return {
        isDisabled: props.isDisabled,
        isInvalid: props.isInvalid,
        isReadOnly: props.isReadOnly,
        isRequired: props.isRequired,
        name: props.name,

        ...componentProps,
        onFocus: callAllHandlers(
          setIsFocused.on,
          componentProps?.onFocus,
          props.onFocus
        ),
        onKeyPress: callAllHandlers((e: any) => {
          if (componentProps?.type === 'number') {
            onKeyPress(e);
          }
        }, props.onKeyPress),
        onBlur: callAllHandlers(
          setIsFocused.off,
          componentProps?.onBlur,
          props?.onBlur
        ),
        onChange: callAllHandlers(
          (e: any) => {
            props.onChange?.(e.currentTarget.value, e);
          },
          onChangeInput,
          componentProps?.onChange
        ),
        value: inputValue,
        ref: mergeRefs(forwardedRef, inputRef),
      };
    },
    [props, setIsFocused.on, setIsFocused.off, onChangeInput, inputValue]
  );
  return {
    getClearableInputFieldProps,
    onClearInput,
    showClearableAddon,
    showRightAddonChildren,
    isFocused,
  };
}

type FunctionArguments<T extends Function> = T extends (...args: infer R) => any
  ? R
  : never;
function callAllHandlers<T extends (event: any) => void>(
  ...fns: (T | undefined)[]
) {
  return function func(event: FunctionArguments<T>[0]) {
    fns.some((fn) => {
      fn?.(event);
      return event?.defaultPrevented;
    });
  };
}

export const [ClearableInputProvider, useClearableInputContext] =
  createContext<UseClearableInput>({
    strict: false,
  });
