import { Maybe } from 'graphql/jsutils/Maybe';

import {
  AddressFragment,
  DocumentStatusEnum,
  MediaPictureStatusEnum,
} from '../../../generated/graphql';

export function documentStatus(
  _: DocumentStatusEnum | undefined
): MediaPictureStatusEnum | DocumentStatusEnum.Missing | undefined {
  switch (_) {
    case DocumentStatusEnum.Checking:
      return MediaPictureStatusEnum.Pending;
    case DocumentStatusEnum.Ok:
      return MediaPictureStatusEnum.Ok;
    case DocumentStatusEnum.Rejected:
      return MediaPictureStatusEnum.Rejected;
    case DocumentStatusEnum.Missing:
      return DocumentStatusEnum.Missing;
    default:
      return undefined;
  }
}

export function extractDisplayNameFromAddress(
  _: Maybe<AddressFragment>
): Maybe<string> {
  if (_?.isCompany) {
    return _?.companyName ?? null;
  } else {
    return [_?.firstname, _?.lastname].filter(Boolean).join(' ') || null;
  }
}
