import { Maybe } from 'graphql/jsutils/Maybe';

/**
 * Helper to extractFromUnion values from graphql union by specifying a `__typename` (suggested)
 * @param _
 * @param __typename
 *
 * @example
 * const { data } = useGetModeProfileAppearanceSectionQuery({});
 * const typedFields = extractFromUnion(data?.profile?.appearance, 'ProfileCollectionMobileAppearanceV1')
 *         ?.fields
 */
export function extractFromUnion<
  Union extends { __typename?: string },
  Typename extends Union['__typename']
>(_: Maybe<Union>, __typename: Typename) {
  return _ as Maybe<Union & { __typename: Typename }>;
}
