import {
  Section,
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { LayoutCenteringVStack } from '../../../../components/Layout/LayoutCenteringVStack';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import {
  ApiLangEnum,
  useLanguageSectionQuery,
  useLanguageUpdateMutation,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { mutationResultErrorHandling } from '../../../../utils/utils';

// Define field name for the dropdown field
const fieldName = {
  accountLanguage: 'accountLanguage',
} as const;

export const LanguageSection: React.FC<{}> = () => {
  // Get the translation function from i18n and define namespaces
  const {
    t,
    i18n: { changeLanguage: i18nChangeLanguage },
  } = useTranslation(['accountPage', 'language']);

  // Destructuring the client from the mutation hook in order to refetch queries below
  const [changeLanguage, { client: apolloClient }] =
    useLanguageUpdateMutation();

  // Get the current language from the backend and set variable
  const { data } = useLanguageSectionQuery();

  const currentLanguage: string | undefined = React.useMemo(() => {
    return data?.account?.language;
  }, [data?.account?.language]);

  // Initialize hookForm with default values defined in the "initialValues" variable
  const initialValues = React.useMemo(() => {
    return {
      [fieldName.accountLanguage]:
        currentLanguage === 'DE' ? ApiLangEnum.De : ApiLangEnum.En,
    };
  }, [currentLanguage]);

  const hookForm = useForm({
    defaultValues: initialValues,
    mode: 'all',
  });

  // Reset the form when the initialValues change in order to compensate for data coming in late
  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  // Define the mutation function and execute it on form submit, then refetch queries, then issue toasts to the user
  // depending on if the mutation was successful or not
  const tryChangeLanguage = async (data: any) => {
    try {
      const response = await changeLanguage({
        variables: {
          language: data[fieldName.accountLanguage],
        },
      });

      const isMutationResultError =
        response.data?.account?.saveLanguage?.success === !true;

      if (isMutationResultError) {
        mutationResultErrorHandling(response.data?.account?.saveLanguage);

        return;
      }

      await i18nChangeLanguage(data[fieldName.accountLanguage]);

      // using the refetchQueries function to refetch all active queries with the client from the mutation hook
      // This is in order to reload the page in the chosen language
      await apolloClient.refetchQueries({
        include: 'active',
      });

      issueChakraToast({
        status: 'success',
        description: t('accountPage:toast.SpracheErfolgreichGeandert'),
      });
    } catch (error) {
      return issueChakraToast({
        description: t(
          'accountPage:toast.SpracheKonnteNichtGeandertWerdenWendeDichAnDenSupport'
        ),
        status: 'error',
      });
    }
  };

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);
  React.useEffect(() => {
    return registerDirtyFlag(hookForm.formState.isDirty, onOmitChanges);
  }, [
    hookForm,
    hookForm.formState,
    hookForm.formState.isDirty,
    onOmitChanges,
    registerDirtyFlag,
  ]);

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.LanguageSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={icons.Language} />
            <SectionTitle
              children={t('accountPage:headline.AccountEinstellungen')}
            />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(tryChangeLanguage)}>
          <SectionBody>
            <SectionCenterContainer>
              <VStack spacing={6}>
                <EnumSelectFieldHookForm
                  label={t('accountPage:label.Sprache')}
                  name={fieldName.accountLanguage}
                  isDisabled={false}
                >
                  <option value={ApiLangEnum.De}>{t('language:de')}</option>
                  <option value={ApiLangEnum.En}>{t('language:en')}</option>
                </EnumSelectFieldHookForm>
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          {/* Added py solely because without it, changing the padding won't work. Also, using only py leads to
          larger padding in cases where the window is really large  */}
          <SectionFooter py={0} pt={0} pb={6}>
            <LayoutCenteringVStack>
              <Button
                alignSelf={'center'}
                children={t('accountPage:button.AnderungSpeichern')}
                variant={'solid'}
                type="submit"
                // Gray out the button if the form has not been changed yet or is submitting OR if the value is changed back to the initial value
                isDisabled={!hookForm.formState.isDirty}
                isLoading={hookForm.formState.isSubmitting}
              />
            </LayoutCenteringVStack>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};
