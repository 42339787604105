import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Img,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import verificationImage from '../../../../../assets/images/vxlive/user-verify-age.svg';
import { CenterContainer } from '../../../../../components/Layout/CenterContainer';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../../../../components/shared/cards/UniversalCard/UniversalCard';
import { useWizardVerification } from '../../../../Wizard/Verification/WizardVerification';

export const AgeVerificationCardNew = () => {
  const { t } = useTranslation(['payoutDocuments']);
  const verifictionCtx = useWizardVerification();

  return (
    <Card
      variant={'outline'}
      bg={'surface'}
      borderColor={'primary.200'}
      wordBreak={'break-word'}
      textAlign={'center'}
    >
      <CardBody display={'flex'} flexDirection={'column'}>
        <CenterContainer spacing={'2'}>
          <Image
            htmlWidth={'320px'}
            htmlHeight={'160px'}
            alignSelf={'center'}
            objectFit={'cover'}
            src={verificationImage}
            alt={t(
              'payoutDocuments:imgAlt.IllustrationEinerPersonDerenGesichtImFokusStehtDanebenEinKreisMitFraXX'
            )}
          />
          <Heading size={'xl'}>
            {t('payoutDocuments:heading.Altersverifizierung')}
          </Heading>
          <Text color={'gray.500'}>
            {t(
              'payoutDocuments:text.BitteFuhreEineAltersverifizierungDurchDamitWirSicherSeinKonnenDasDuMXX'
            )}
          </Text>
        </CenterContainer>
      </CardBody>
      <CardFooter flexDirection={'column'}>
        <CenterContainer>
          <Button
            children={t('payoutDocuments:button.AltersverifizierungStarten')}
            onClick={() => verifictionCtx.setVisible(true)}
          />
        </CenterContainer>
      </CardFooter>
    </Card>
  );
};

export const AgeVerificationCard = () => {
  const { t } = useTranslation(['payoutDocuments']);
  const verifictionCtx = useWizardVerification();

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={verificationImage}
              alt={t(
                'payoutDocuments:imgAlt.IllustrationEinerPersonDerenGesichtImFokusStehtDanebenEinKreisMitFraXX'
              )}
            />
            <VStack mt={'4'} spacing={'4'}>
              <Heading size={'xl'} p={0} textAlign={'center'}>
                {t('payoutDocuments:heading.Altersverifizierung')}
              </Heading>
              <Text textAlign={'center'} color={'gray.500'} p={0}>
                {t(
                  'payoutDocuments:text.BitteFuhreEineAltersverifizierungDurchDamitWirSicherSeinKonnenDasDuMXX'
                )}
              </Text>
            </VStack>
          </>
        }
        cardFooter={
          <Button
            variant={'solid'}
            shadow={'none'}
            children={t('payoutDocuments:button.AltersverifizierungStarten')}
            onClick={() => verifictionCtx.setVisible(true)}
          />
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
