import { Container } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';

import userVerificationSuccess from '../../../assets/images/vxlive/user-verification-success.svg';
import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../../components/Layout/LayoutCenteringVStack';
import { LayoutHintsVStack } from '../../../components/Layout/LayoutHintsVStack';
import ScrollToTopOnMount from '../../../components/Layout/ScrollToTopOnMount';
import { BlockerCard } from '../../../components/shared/BlockerCard/BlockerCard';
import { UpdateTaxInformationExpiredHintSlot } from '../../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import {
  TipEntryFragment,
  useGetVxLiveGoLivePageDataQuery,
} from '../../../generated/graphql';
import {
  TipDetailModalProvider,
  useTipDetailModal,
} from '../../../provider/TipDetailModalProvider';
import { useVxLiveLink } from '../../../provider/VxLiveLinkProvider';
import { LocalStorageKeys } from '../../../types';
import { extractDefinedEntries } from '../../../utils/utils';

const GoOnlineAndEnableVXLiveChatCard: React.FC<{
  tip: Maybe<TipEntryFragment>;
}> = ({ tip }) => {
  const { t } = useTranslation(['vxLive']);
  const tipDetailModalCtx = useTipDetailModal();
  const {
    actions: { redirectToVxLive },
  } = useVxLiveLink();
  return (
    <BlockerCard
      isBordered
      image={userVerificationSuccess}
      heading={t('vxLive:goOnline.headline')}
      text={t('vxLive:goOnline.bodyText')}
      secondaryButtonText={t('vxLive:goOnline.readRules')}
      onClickSecondary={
        !tip ? undefined : () => tipDetailModalCtx.action.showTip(tip.id)
      }
      primaryButtonText={t('vxLive:goOnline.goOnlineButton')}
      onClickPrimary={() => {
        sessionStorage.setItem(LocalStorageKeys.MODEL_ACCESSED_CHAT, 'true');
        redirectToVxLive();
      }}
    />
  );
};

const VxLiveGoLivePage: React.FC = () => {
  // load data
  const { data: queryData, loading: contentLoading } =
    useGetVxLiveGoLivePageDataQuery({ fetchPolicy: 'cache-first' });

  const tipEntries = React.useMemo(
    () => extractDefinedEntries(queryData?.helpcenter?.tips?.entries),
    [queryData]
  );

  const tipToShow = tipEntries.find((tip) => tip.id === 'tip-4');
  const spacing = { base: 4, lg: 8 };
  return (
    <TipDetailModalProvider>
      <FluentPageLayout isContentLoading={contentLoading}>
        <ScrollToTopOnMount />
        <Container maxW={'container.xl'} px={0} mb={{ base: 4, lg: 8 }}>
          <LayoutHintsVStack>
            <UpdateTaxInformationExpiredHintSlot
              outerStackProps={{ pt: spacing }}
            />
            <LayoutCenteringVStack outerStackProps={{ pt: spacing }}>
              <GoOnlineAndEnableVXLiveChatCard tip={tipToShow} />
            </LayoutCenteringVStack>
          </LayoutHintsVStack>
        </Container>
      </FluentPageLayout>
    </TipDetailModalProvider>
  );
};

export default VxLiveGoLivePage;
