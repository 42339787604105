import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { Settings } from '@campoint/odi-ui-icons';
import { Container, Flex, Icon, IconButton, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../components/Layout/LayoutCenteringVStack';
import ScrollToLastKnownPositionOnMount from '../../components/Layout/ScrollToLastKnownPositionOnMount';
import PayoutDataCheckPendingCard from '../../components/shared/Payout/PayoutDataCheckPendingCard/PayoutDataCheckPendingCard';
import PayoutDataCheckRejectedCard from '../../components/shared/Payout/PayoutDataCheckRejectedCard/PayoutDataCheckRejectedCard';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { UpdateTaxInformationExpiresSoonHintSlot } from '../../components/shared/UpdateTaxInformationExpiresSoonHintSlot/UpdateTaxInformationExpiresSoonHintSlot';
import { FeatureName, useFeature } from '../../flags';
import { ProfileStatusEnum } from '../../generated/graphql';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { useAuth } from '../../provider/AuthProvider';
import { useFinanceService } from '../../provider/FinanceService/FinanceService';
import { NavigationBlockProvider } from '../../provider/NavigationBlockProvider';
import { routes } from '../../routes/routesConfig';
import { IncomeStatsTile } from '../Home/section/StatisticSection/StatsTile/StatsTile';
import { NextPayoutCardSection } from './components/NextPayoutSection/NextPayoutCardSection';
import { OldNextPayoutCardSection } from './components/NextPayoutSection/OldNextPayoutCardSection';
import { OldPayoutOverviewTabSection } from './components/PayoutOverviewTabSection/OldPayoutOverviewTabSection';
import { PayoutOverviewTabSection } from './components/PayoutOverviewTabSection/PayoutOverviewTabSection';
import { PriorToPayoutSetupSection } from './components/PriorToPayoutSetupSection/PriorToPayoutSetupSection';
import { OldPayoutWithdrawalsSection } from './section/PayoutWithdrawalsSection/OldPayoutWithdrawalsSection';

const FinancePage: React.FC = () => {
  const { t } = useTranslation(['financePage', 'statistic']);
  const historyWithStorage = useHistoryWithStorage();
  const { isMasterAccount } = useAuth();
  const documentPreferredForFinanceDocumentFlag = useFeature(
    FeatureName.documentPreferredForFinanceDocument
  );
  const newFinancePageActive = useFeature(FeatureName.financePageNew);
  const documentFlag = useFeature(FeatureName.document);
  const {
    loading,
    payableAmount,
    isFirstPayout,
    nextPaymentDate,
    payoutStatus,
    hasEnoughAmountForPayout,
    minimalPayableAmount,
    payoutWizardStatus,
    action: {
      triggerRefetch,
      triggerInvoiceRefetch,
      requestWithdrawal,
      cancelWithdrawal,
    },
  } = useFinanceService();

  React.useEffect(() => {
    triggerRefetch();
    triggerInvoiceRefetch();
  }, [triggerRefetch, triggerInvoiceRefetch]);

  const incomeStatsTile = (
    <LayoutCenteringVStack>
      <IncomeStatsTile
        headline={t('statistic:heading.DeinVerdienst')}
        payableAmount={payableAmount}
        showIncomeIndicator={false}
      />
    </LayoutCenteringVStack>
  );

  const isPayoutWizardAccepted =
    payoutWizardStatus === ProfileStatusEnum.Accepted;
  const isPayoutWizardActiveOverride = payableAmount >= 0.5;
  const spacing = { base: 4, lg: 8 };
  return (
    <NavigationBlockProvider>
      <FluentPageLayout isContentLoading={loading}>
        <ScrollToLastKnownPositionOnMount identifier={'/finance'} />
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <Flex alignItems={'center'}>
              <SubHeaderTitle flex={1}>
                {t('financePage:headline.DeineFinanzen')}
              </SubHeaderTitle>
              {isMasterAccount &&
                documentFlag &&
                documentPreferredForFinanceDocumentFlag && (
                  <IconButton
                    variant="unstyled"
                    aria-label={'BackButtonEditPage'}
                    icon={<Icon as={Settings} boxSize="6" />}
                    onClick={() => {
                      historyWithStorage.push(routes.documentsPayout.path);
                    }}
                  />
                )}
            </Flex>
          </Container>
        </SubHeader>
        <Container maxW="container.xl" p={0}>
          <VStack spacing={spacing} w={'full'} my={spacing}>
            {isMasterAccount ? (
              <>
                <UpdateTaxInformationExpiredHintSlot />
                <UpdateTaxInformationExpiresSoonHintSlot />
                {incomeStatsTile}
                {payoutWizardStatus === ProfileStatusEnum.Incomplete && (
                  <PriorToPayoutSetupSection
                    isSetupPayoutWizardActive={isPayoutWizardActiveOverride}
                  />
                )}
                {payoutWizardStatus === ProfileStatusEnum.Pending && (
                  <LayoutCenteringVStack>
                    <PayoutDataCheckPendingCard />
                  </LayoutCenteringVStack>
                )}
                {payoutWizardStatus === ProfileStatusEnum.Rejected && (
                  <LayoutCenteringVStack>
                    <PayoutDataCheckRejectedCard reason={''} />
                  </LayoutCenteringVStack>
                )}
                {isPayoutWizardAccepted &&
                  (newFinancePageActive ? (
                    <NextPayoutCardSection
                      isFirstPayout={isFirstPayout}
                      nextPaymentDate={nextPaymentDate}
                      status={payoutStatus}
                      onRequestWithdrawal={requestWithdrawal}
                      onCancelWithdrawal={cancelWithdrawal}
                      hasEnoughAmountForPayout={hasEnoughAmountForPayout}
                      minimalPayableAmount={minimalPayableAmount}
                    />
                  ) : (
                    <OldNextPayoutCardSection
                      isFirstPayout={isFirstPayout}
                      nextPaymentDate={nextPaymentDate}
                      status={payoutStatus}
                      onRequestWithdrawal={requestWithdrawal}
                      onCancelWithdrawal={cancelWithdrawal}
                      hasEnoughAmountForPayout={hasEnoughAmountForPayout}
                      minimalPayableAmount={minimalPayableAmount}
                    />
                  ))}

                {isPayoutWizardAccepted ? (
                  newFinancePageActive ? (
                    <PayoutOverviewTabSection />
                  ) : documentPreferredForFinanceDocumentFlag ? (
                    <OldPayoutWithdrawalsSection />
                  ) : (
                    <OldPayoutOverviewTabSection />
                  )
                ) : null}
              </>
            ) : (
              //permission $userData->canViewFinanceAsNotMaster();
              incomeStatsTile
            )}
          </VStack>
        </Container>
      </FluentPageLayout>
    </NavigationBlockProvider>
  );
};
export default FinancePage;
