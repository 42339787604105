import { Button, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ChangeMailSectionProps = {
  actionButtonDisabled: boolean;
  buttonAction: () => void;
};

const ChangeMailSection: React.FC<ChangeMailSectionProps> = (props) => {
  const { t } = useTranslation(['home']);

  return (
    <VStack spacing="2" align="start" pt="6">
      <Text
        as="div"
        textStyle={'bodyMd'}
        color="onSurface.highEmphasis"
        fontWeight="medium"
        children={t('home:text.DieEMailAdresseIstFalsch')}
      />
      <Button
        variant="link"
        isDisabled={props.actionButtonDisabled}
        children={t('home:button.EMailAdresseAndern')}
        onClick={props.buttonAction}
      />
    </VStack>
  );
};

export default ChangeMailSection;
