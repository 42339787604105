import { Heading, Text, VStack, VisuallyHidden } from '@chakra-ui/react';
import React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../Layout/ResponsiveModal';
import { IconStatus, StatusIcon } from '../StatusIcon/StatusIcon';

export interface NoticeModalConfig {
  status?: IconStatus;
  isCloseButtonHidden?: boolean;
  modalHeading: React.ReactNode;
  modalTextSlot: React.ReactNode;
  modalHelpLinkSlot?: React.ReactNode;
  modalBodyBottomSlot?:
    | React.ReactNode
    | ((onClose: () => void) => React.ReactNode);
  modalFooterSlot?:
    | React.ReactNode
    | ((onClose: () => void) => React.ReactNode);
}

interface NoticeModalProps
  extends NoticeModalConfig,
    Omit<ResponsiveModalProps, 'children'> {}

export const NoticeModal: React.FC<NoticeModalProps> = ({
  status,
  isCloseButtonHidden = false,
  modalHeading,
  modalTextSlot,
  modalHelpLinkSlot,
  modalBodyBottomSlot,
  modalFooterSlot,
  ...modalProps
}) => {
  return (
    <ResponsiveModal {...modalProps}>
      <ResponsiveModalOverlay />
      <ResponsiveModalContent alignItems={'center'} p={'24px 16px'}>
        <VStack
          alignSelf={'center'}
          textAlign={'center'}
          flex={1}
          justifyContent={'center'}
          spacing={'12px'}
        >
          <VisuallyHidden>{modalHeading}</VisuallyHidden>
          {status ? <StatusIcon status={status} /> : null}
          <Heading size={'xl'}>{modalHeading}</Heading>
          {!isCloseButtonHidden && <ResponsiveModalCloseButton />}
          {modalTextSlot && (
            <Text whiteSpace={'pre-wrap'}>{modalTextSlot}</Text>
          )}
          {modalHelpLinkSlot}
          {typeof modalBodyBottomSlot === 'function'
            ? modalBodyBottomSlot(modalProps.onClose)
            : modalBodyBottomSlot}
        </VStack>
        {typeof modalFooterSlot === 'function'
          ? modalFooterSlot(modalProps.onClose)
          : modalFooterSlot}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
