import * as icons from '@campoint/odi-ui-icons';
import {
  HStack,
  Heading,
  Icon,
  StackProps,
  VStack,
  VisuallyHidden,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../../components/Layout/ResponsiveModal';
import { ChevronRightIcon } from '../../../../../components/shared/icon/ChevronRightIcon';

interface DocumentPickerRejectedMessageProps {
  message: React.ReactNode;
  modalHeading: React.ReactNode;
  modalTextSlot: React.ReactNode;
  modalHelpLinkSlot: React.ReactNode;
  modalFooterSlot?: React.ReactNode;
}

export const DocumentPickerRejectedMessage: React.FC<
  DocumentPickerRejectedMessageProps & StackProps
> = ({
  message,
  modalHeading,
  modalTextSlot,
  modalHelpLinkSlot,
  modalFooterSlot,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <HStack
        onClick={onOpen}
        color={'error.500'}
        cursor={'pointer'}
        {...props}
      >
        <Icon as={icons.Error} boxSize={'icon.md'} />
        <span>{message}</span>
        <ChevronRightIcon />
      </HStack>
      <ResponsiveModal isOpen={isOpen} onClose={onClose} isVCentered>
        <ResponsiveModalOverlay />
        <ResponsiveModalContent
          textAlign={'center'}
          p={'24px 16px'}
          justifyContent={'center'}
        >
          <VisuallyHidden>{modalHeading}</VisuallyHidden>
          <ResponsiveModalCloseButton />
          <VStack alignItems={'start'} spacing={'22px'}>
            <Icon as={icons.Error} boxSize={'icon.lg'} color={'error.500'} />
            <Heading size={'2xl'}>{modalHeading}</Heading>
            {modalTextSlot}
            {modalHelpLinkSlot}
          </VStack>
          {!modalFooterSlot ? null : modalFooterSlot}
        </ResponsiveModalContent>
      </ResponsiveModal>
    </>
  );
};
