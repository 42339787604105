import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileFieldTypePictureFragment } from '../../../generated/graphql';
import { usePageHeaderContext } from '../../../pages/StatisticsPage/provider/PageHeaderContext';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../../../pages/StatisticsPage/provider/TabContext';
import { ProfilePicture } from './components/ProfilePicture/ProfilePicture';

export const ProfileHeaderGoToVXId = 'visit-x-profile-button';

export type StatisticsProfileHeaderProps = {
  isEditable?: boolean;
  modelPictureFSK16Avatar: ProfileFieldTypePictureFragment | undefined | null;
  username: string;
};

/**
 * This component is needed for the model profile and the feed.
 * @constructor
 */
const StatisticsProfileHeader: React.FC<StatisticsProfileHeaderProps> = ({
  modelPictureFSK16Avatar,
  username,
  isEditable = false,
}) => {
  const { t } = useTranslation(['statisticsPage']);
  const { primary, secondary } = usePageHeaderContext();
  const { tabIds, tabIndex } = useTabsContext();
  const tabIdToHeaderText = {
    [StatisticsDetailedCategoryExtEnum.VideoLibrary]: {
      primary: t('statisticsPage:header.Verkaufe'),
      secondary: t('statisticsPage:header.ContentLikes'),
    },
    [StatisticsDetailedCategoryExtEnum.Messenger]: {
      primary: t('statisticsPage:header.Geschenke'),
      secondary: t('statisticsPage:header.ContentVerkaufe'),
    },
    [StatisticsDetailedCategoryExtEnum.LiveChat]: {
      primary: t('statisticsPage:header.LiveRanking'),
      secondary: t('statisticsPage:header.Onlinezeit'),
    },
    [StatisticsDetailedCategoryExtEnum.Misc]: {
      primary: t('statisticsPage:header.Gewinne'),
      secondary: t('statisticsPage:header.Wettbewerb'),
    },
    [StatisticsDetailedCategoryExtEnum.Feed]: {
      primary: t('statisticsPage:header.Verkaufe'),
      secondary: t('statisticsPage:header.Tippings'),
    },
    [StatisticsDetailedCategoryExtEnum.Telegram]: {
      primary: t('statisticsPage:header.AktiveAbos'),
      secondary: t('statisticsPage:header.VIPUser'),
    },
    [StatisticsDetailedCategoryExtEnum.PhoneService]: {
      primary: t('statisticsPage:header.TelefonateMonat'),
      secondary: t('statisticsPage:header.TelefonzeitMonat'),
    },
    [StatisticsDetailedCategoryExtEnum.OverAll]: {
      primary: t('statisticsPage:header.VerdienstVX'),
      secondary: t('statisticsPage:header.AffiliateUmsatz'),
    },
    [StatisticsDetailedCategoryExtEnum.Affiliate]: {
      primary: t('statisticsPage:header.GeworbeneUser'),
      secondary: t('statisticsPage:header.GeworbeneModels'),
    },
  };
  return (
    <Container maxW={'full'} p={4} gap={4} bgColor={'surface'}>
      <Flex w={'full'} flexWrap={'wrap'} flexDirection={'column'}>
        <HStack maxW={'full'} alignItems={'flex-end'} gap={'7'} flexGrow={1}>
          <Box flexShrink={0}>
            <ProfilePicture
              field={modelPictureFSK16Avatar}
              isEditable={isEditable}
            />
          </Box>
          <Flex overflowX={'hidden'} flexGrow={1} alignSelf={'center'}>
            <Heading fontSize={'2xl'} noOfLines={1} wordBreak={'break-word'}>
              {username}
            </Heading>
          </Flex>
        </HStack>
        <Container minW={'full'} p={0}>
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={'stretch'}
            justifyContent={'center'}
            gap={0}
          >
            <HStack
              minW={{ base: 'full', lg: '320px' }}
              minH={'68px'}
              justifyContent={'space-evenly'}
            >
              {primary && secondary && (
                <>
                  <StatisticsProfileHeaderInfoTiles
                    value={primary}
                    name={tabIdToHeaderText[tabIds[tabIndex]].primary}
                  />
                  <Divider
                    orientation={'vertical'}
                    border={'1px'}
                    h={'36px'}
                    color={'steel'}
                  />
                  <StatisticsProfileHeaderInfoTiles
                    value={secondary}
                    name={tabIdToHeaderText[tabIds[tabIndex]].secondary}
                  />
                </>
              )}
            </HStack>
          </Flex>
        </Container>
      </Flex>
    </Container>
  );
};

export default StatisticsProfileHeader;

const StatisticsProfileHeaderInfoTiles: React.FC<{
  value: string | undefined;
  name: string;
}> = ({ value, name }) => {
  return (
    <VStack gap={0}>
      <Text as={Heading} fontSize={'md'} lineHeight={'20px'}>
        {value}
      </Text>
      <Text fontSize={'12px'} lineHeight={'14px'} fontWeight={'light'}>
        {name}
      </Text>
    </VStack>
  );
};
