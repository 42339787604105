// https://jestjs.io/docs/manual-mocks#using-with-es-module-imports
import heic2any from 'heic2any';

export async function convertHEICFile(file: File) {
  return await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: 0.9,
  });
}
