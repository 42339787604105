import * as React from 'react';

import {
  CurrentStepWizardPayoutFragment,
  InputTourPayoutV1,
  ProfileFieldTypeEnum,
  ProfileFieldTypeString,
  ProfileFieldTypeUploadDocument,
  ProfileFieldsEnum,
  WizardPayoutAccountPrefillFieldsFragment,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { WizardContext, WizardStepsConfig } from '../utils/types';

export const possibleStepIds = [
  'PayoutV1Welcome' as const,
  'PayoutV1BankInformation' as const,
  'PayoutV1ProofOfAddress' as const,
  'PayoutV1SalesTaxObligation' as const,
  'PayoutV1Finish' as const,
] as const;

export namespace WizardPayoutTypes {
  export type StepIds = typeof possibleStepIds;
  export type StepId = StepIds[number];
  export type StepsConfig = WizardStepsConfig<StepId>;
  export type Step = CurrentStepWizardPayoutFragment;
  export type Addition = {
    isAccountFromGermany: boolean;
    isAccountFromCH: boolean;
    isAccountFromEU: boolean;
    accountPrefillField: WizardPayoutAccountPrefillFieldsFragment | null;
  };
  export type Context = WizardContext<Step, InputTourPayoutV1, Field> &
    Addition;

  export type Field =
    {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.PayoutBankaccountOwner
      | ProfileFieldsEnum.PayoutIban
      | ProfileFieldsEnum.PayoutBic
      | ProfileFieldsEnum.PayoutPaxumEmailAccount
      | ProfileFieldsEnum.PayoutAddressStreet
      | ProfileFieldsEnum.PayoutAddressStreetNumber
      | ProfileFieldsEnum.PayoutAddressPostalCode
      | ProfileFieldsEnum.PayoutAddressCity
      | ProfileFieldsEnum.PayoutAddressCompanyname
      | ProfileFieldsEnum.PayoutAddressFirstname
      | ProfileFieldsEnum.PayoutAddressLastname
      | ProfileFieldsEnum.PayoutTaxId
      | ProfileFieldsEnum.PayoutTaxId
      | 'payoutTaxTin'
    ]: Partial<ProfileFieldTypeString>
  } & {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.PayoutTransactionType
      | ProfileFieldsEnum.PayoutAddressCountry
      | ProfileFieldsEnum.PayoutAddressCountry
      | 'payoutTaxTinCountry'
    ]: Partial<ProfileFieldTypeEnum>
  } & {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.PayoutAddressDocument
      | ProfileFieldsEnum.PayoutTaxDocument
    ]: Partial<ProfileFieldTypeUploadDocument>
  };

  export type FieldError = {
    [key in keyof Field]: React.ReactNode;
  };
}

export const [WizardPayoutContextProvider, useWizardPayout] =
  createContext<WizardPayoutTypes.Context>();
