import { Heading, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AbsoluteFullCenterLoadingSpinner } from '../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import {
  ResponsiveModal,
  ResponsiveModalBodyBlankBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
  ResponsiveModalStickySubHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import {
  CommentList,
  itemVariants,
  listVariants,
} from '../../components/shared/FeedComment/shared';
import { FetchMoreIndicator } from '../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import { ConfirmCommentDiscardDialog } from '../../components/shared/dialogs/ConfirmCommentDiscardDialog/ConfirmCommentDiscardDialog';
import { usePhotoCommenting } from '../../provider/VideoLibraryProvider/VideoLibraryCommentingProvider/VideoLibraryPhotoCommentingProvider';
import { MediaRef } from './components/MediaRefBanner/MediaRefBanner';
import { VideoLibraryCommentConversation } from './components/VideoLibraryCommentConversation';

export const PhotoCommentModal: React.FC<{}> = () => {
  const discardChangesDialog = useDisclosure({ defaultIsOpen: false });
  const photoCommentingContext = usePhotoCommenting();
  const {
    action,
    photoalbum,
    isOpen,
    isLoading,
    comments,
    commentsLoading,
    commentsError,
    loadMoreComments,
  } = photoCommentingContext;
  const { t } = useTranslation(['photoCommentingModal']);

  const onConditionClose = React.useCallback(() => {
    if (photoCommentingContext.isDirty) {
      discardChangesDialog.onOpen();
    } else {
      action.closeModal();
    }
  }, [photoCommentingContext.isDirty, discardChangesDialog, action]);

  const onDiscardChanges = React.useCallback(() => {
    discardChangesDialog.onClose();
    action.closeModal();
  }, [action, discardChangesDialog]);

  const [scrollDirection, setScrollDirection] = React.useState<
    'down' | 'up' | null
  >(null);

  const lastScrollY = React.useRef(0);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [showHeaderShadow, setShowHeaderShadow] = React.useState(true);

  const updateScrollDirection: React.UIEventHandler<HTMLDivElement> = (e) => {
    const scrollY = e.currentTarget.scrollTop ?? 0;
    const direction = scrollY > lastScrollY.current ? 'down' : 'up';
    if (direction !== scrollDirection) {
      setScrollDirection(direction);
    }
    toggleHeaderShadow(scrollY);
    lastScrollY.current = scrollY > 0 ? scrollY : 0;
  };

  const toggleHeaderShadow = (scrollY: number) => {
    //auf desktop ab < 64 -> isTop true
    const shadowHeight = isMobile ? 0 : 64;
    if (scrollY > shadowHeight && showHeaderShadow) {
      setShowHeaderShadow(false);
    }
    if (scrollY <= shadowHeight) {
      setShowHeaderShadow(true);
    }
  };

  const headerRef = React.useRef<HTMLDivElement>(null);
  const extendedHeaderRef = React.useRef<HTMLDivElement>(null);
  const headerHeight = headerRef.current?.clientHeight;
  const subHeaderHeight = (extendedHeaderRef.current?.clientHeight || 0) * -1;

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onConditionClose}>
      <ConfirmCommentDiscardDialog
        navigateBack={onDiscardChanges}
        isOpen={discardChangesDialog.isOpen}
        onClose={discardChangesDialog.onClose}
      />
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        maxW={'567px'}
        containerProps={{ onScroll: updateScrollDirection }}

        /**
        * todo: decide how/if maxHeight is used here
        ```
        maxHeight={{ base: 'unset', lg: '830px' }}
        overflowY={{ base: 'unset', lg: 'scroll' }}
        ```
        */
      >
        <ResponsiveModalStickyHeaderBox
          ref={headerRef}
          scrollDirection={scrollDirection}
        >
          <Heading
            as={'h1'}
            children={t('photoCommentingModal:heading.Kommentare')}
            size={'md'}
            fontSize={'md'}
            letterSpacing={'wide'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalStickySubHeaderBox
          ref={extendedHeaderRef}
          scrollDirection={scrollDirection}
          showHeaderShadow={showHeaderShadow}
          headerHeight={headerHeight ?? 0}
          subHeaderHeight={subHeaderHeight ?? 0}
        >
          <MediaRef media={photoalbum} />
          <ResponsiveModalBodyBlankBox p="4">
            {/* Create a comment */}
          </ResponsiveModalBodyBlankBox>
        </ResponsiveModalStickySubHeaderBox>
        <ResponsiveModalBodyBlankBox px="4" pb="4">
          {isLoading ? (
            <AbsoluteFullCenterLoadingSpinner />
          ) : (
            <CommentList>
              <AnimatePresence initial={true}>
                <motion.div
                  key={photoalbum?.albumId}
                  initial="inactive"
                  animate="active"
                  variants={listVariants}
                  exit="exit"
                >
                  <AnimatePresence initial={false} exitBeforeEnter={false}>
                    {comments.map((comment) => {
                      return (
                        <motion.div
                          key={comment.id + 'listItem'}
                          variants={itemVariants}
                          initial="inactive"
                          animate="active"
                          exit="exit"
                        >
                          <VideoLibraryCommentConversation
                            comment={comment}
                            addReply={action.addReply}
                            registerOpenComment={action.registerOpenComment}
                          />
                        </motion.div>
                      );
                    })}
                  </AnimatePresence>
                </motion.div>
              </AnimatePresence>
              {loadMoreComments && (
                <FetchMoreIndicator
                  mt={{ base: '8', lg: '12' }}
                  loading={commentsLoading}
                  error={commentsError}
                  onIsVisible={loadMoreComments}
                />
              )}
            </CommentList>
          )}
        </ResponsiveModalBodyBlankBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
