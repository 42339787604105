import { HStack, StackProps, VStack } from '@chakra-ui/react';

import { useSafeAriaInset } from '../../../../hooks/useSafeAriaInset';
import {
  EnterFullscreenButton,
  ExitFullscreenButton,
} from './FullscreenButtons';
import { ImageGalleryTag } from './ImageGalleryTag';
import { NavLeftButton, NavRightButton } from './NavButtons';

export const Navigation: React.FC<
  {
    previousImageHandler: () => void;
    nextImageHandler: () => void;
    currentImageNumber: number;
    totalImages: number;
    setIsFullscreen: (state: boolean) => void;
    isFullscreen: boolean;
  } & StackProps
> = ({
  previousImageHandler,
  nextImageHandler,
  currentImageNumber,
  totalImages,
  setIsFullscreen,
  isFullscreen,
  ...props
}) => {
  const { margins: safeAreaInsetMargins } = useSafeAriaInset({
    surroundMargin: 'full',
  });

  return (
    <HStack
      position={'absolute'}
      justifyContent={'space-between'}
      maxH={'full'}
      maxW={'full'}
      p={'3'}
      top={0}
      left={0}
      right={0}
      bottom={0}
      {...safeAreaInsetMargins}
      {...props}
    >
      <NavLeftButton onClick={previousImageHandler} />
      <VStack justifyContent={'space-between'} h={'full'} alignItems={'end'}>
        <ImageGalleryTag children={`${currentImageNumber}/${totalImages}`} />
        <NavRightButton onClick={nextImageHandler} />
        {!isFullscreen && (
          <EnterFullscreenButton
            onClick={() => {
              setIsFullscreen(true);
            }}
          />
        )}
        {isFullscreen && (
          <ExitFullscreenButton onClick={() => setIsFullscreen(false)} />
        )}
      </VStack>
    </HStack>
  );
};
