import { CSSObject, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

export const ButtonGroup: React.FC<FlexProps> = (props) => {
  const buttonStyles: CSSObject = {
    '> *': {
      minWidth: 'min-content !important',
      flexGrow: '1',
      flexShrink: '0',
    },
  };
  return (
    <Flex
      wrap={'wrap'}
      rowGap={2}
      columnGap={2}
      sx={{ ...buttonStyles }}
      w={{ base: 'full', sm: 'fit-content' }}
      minW={{ base: 'full', sm: 'xs' }}
      {...props}
    />
  );
};
