import {
  InputElementProps,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react';

export const ClearableInputRightElement = forwardRef<
  InputElementProps & { isDisabled?: boolean },
  'div'
>(({ isDisabled, ...rest }, ref) => {
  return (
    <InputRightElement
      ref={ref}
      width={'unset'}
      minW={'60px'}
      px={'4'}
      borderLeft={'1px solid'}
      borderColor={isDisabled ? 'gray.200' : 'gray.400'}
      {...rest}
    />
  );
});
