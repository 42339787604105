import Logger from '../Logger';

export async function getImageDimensions(
  src: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolved, rejected) => {
    try {
      const image: HTMLImageElement = new Image();
      image.onload = () => {
        resolved({
          width: image.naturalWidth,
          height: image.naturalHeight,
        });
      };
      image.src = src;
    } catch (er) {
      Logger.error(er);
      rejected({ width: 0, height: 0 });
    }
  });
}
