import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { SecurityRoleEnum } from '../generated/graphql';
import { AuthState, useAuth } from '../provider/AuthProvider';
import { RouteConfig, routes } from '../routes/routesConfig';

/**
 * Array of roles, one of which is required to access the route (=> logical OR).
 * If no role is required, use `NONE`.
 */
export type RequiredRole =
  | ReadonlyArray<SecurityRoleEnum | 'LOGGED_OUT'>
  | 'NONE';

export interface ProtectedRouteProps extends RouteProps {
  routeConfig: RouteConfig;
  /** Required path so it works inside a switch */
  path: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  routeConfig,
  ...routeProps
}) => {
  const auth = useAuth();
  const location = useLocation();

  const hasRole = hasRequiredRole(routeConfig.requiredRole, auth);
  const defaultRedirectOnRoleError = auth.isAuthenticated
    ? routes.home.path
    : routes.login.path;

  return (
    <Route {...routeProps}>
      {hasRole ? (
        children
      ) : (
        <Redirect
          to={{
            pathname:
              routeConfig.redirectToOnRoleError ?? defaultRedirectOnRoleError,
            search: location.search,
            state: {
              // fixme: workaround to omit /logout from originalTarget
              originalTarget:
                location.pathname === routes.logout.path
                  ? undefined
                  : `${location.pathname}${location.search}${location.hash}`,
            },
          }}
        />
      )}
    </Route>
  );
};

/**
 * Checks, whether the current user has the correct role to access a route
 * @param requiredRole The role / roles required to access a route
 * @param auth The current authentication state as provided by the context
 * @returns Whether the user has permission to access the route
 */
function hasRequiredRole(requiredRole: RequiredRole, auth: AuthState): boolean {
  // If no role is required at all, let them pass
  if (requiredRole === 'NONE') {
    return true;
  }
  // If we are logged out and we need the logged out role, let them pass
  if (requiredRole.includes('LOGGED_OUT') && !auth.isAuthenticated) {
    return true;
  }
  // If they have one of the required roles, let them pass
  for (const role of auth.authUser?.roles ?? []) {
    if (requiredRole.includes(role)) {
      return true;
    }
  }

  return false;
}
