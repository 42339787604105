import React, { FC, useMemo, useState } from 'react';

import {
  IncomeSourceEntryFragment,
  useGetIncomeSourcesDataQuery,
} from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import { useExperienceIntentionFilterHelper } from '../hooks/useFilterHelper';
import { extractDefinedEntries } from '../utils/utils';
import { ExperienceIntentionFilterableListContext } from './FilterablListProvider';

export type FilterableIncomeSourceListContext =
  ExperienceIntentionFilterableListContext<IncomeSourceEntryFragment>;

export const [
  FilteredIncomeSourceListProvider,
  useFilteredIncomeSourceListContext,
  filteredIncomeSourceListContext,
] = createContext<FilterableIncomeSourceListContext>({
  name: 'FilteredIncomeSourceListContext',
});

const useIncomeSourceMappedData = () => {
  const { data, loading, error } = useGetIncomeSourcesDataQuery({
    fetchPolicy: 'no-cache',
  });

  const { entries, experienceTags, intentionTags } = useMemo(() => {
    const { incomeSources } = data?.helpcenter ?? {};
    return {
      entries: extractDefinedEntries(incomeSources?.entries),
      experienceTags: extractDefinedEntries(incomeSources?.experienceTags),
      intentionTags: extractDefinedEntries(incomeSources?.intentionTags),
    };
  }, [data]);

  return { loading, error, entries, experienceTags, intentionTags };
};

export const IncomeSourceProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { entries, experienceTags, intentionTags, loading } =
    useIncomeSourceMappedData();

  const filterHelper =
    useExperienceIntentionFilterHelper<IncomeSourceEntryFragment>({
      localStorageKey: 'IncomeSource:Filter:Values',
      entries,
    });

  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);

  const context = useMemo(
    () => ({
      loading,
      isFilterSheetOpen,
      toggleFilterSheet: () => setIsFilterSheetOpen((prev) => !prev),
      filterCategory: {
        experienceTags,
        intentionTags,
      },
      ...filterHelper,
    }),
    [loading, isFilterSheetOpen, filterHelper, experienceTags, intentionTags]
  );

  return (
    <FilteredIncomeSourceListProvider value={context}>
      {children}
    </FilteredIncomeSourceListProvider>
  );
};
