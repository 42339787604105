import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../../pages/Home/Home';
import { VerifyEmailModal } from '../../pages/Home/components/Home/VerifyEmailModal/VerifyEmailModal';
import { ProtectedRoute } from '../../utils/ProtectedRoute';
import { routes } from '../routesConfig';

/**
 * A separate router for nested routes below the /home route
 */
const HomeRouter: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute routeConfig={routes.home} path={routes.home.path}>
        <Home />
        <Switch>
          <Route
            path={[routes.home.virtualSubRoutes.verifyEmailModal]}
            exact={false}
          >
            <VerifyEmailModal />
          </Route>
        </Switch>
      </ProtectedRoute>
    </Switch>
  );
};

export default HomeRouter;
