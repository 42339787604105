import { ApolloError } from '@apollo/client';
import { Box, Button, Heading, Link, VStack, chakra } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link as routerDomLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  PublicPageLayout,
  PublicPageMainSection,
  PublicPageMainSectionBox,
} from '../../components/Layout/PublicPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import { ErrorMessageWithLeadingIcon } from '../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { CheckboxSingleControl } from '../../components/shared/HookFormForms/CheckboxSingleControl/CheckboxSingleControl';
import { ClearableInputControl } from '../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { PasswordClearableInputControl } from '../../components/shared/HookFormForms/PasswordClearableInputControl/PasswordClearableInputControl';
import {
  LoginMutation,
  SignUpMutation,
  useLoginMutation,
  useSignUpMutation,
} from '../../generated/graphql';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useAuth } from '../../provider/AuthProvider';
import { externalRoutes, routes } from '../../routes/routesConfig';
import Logger from '../../utils/Logger';
import { getCookie } from '../../utils/cookie';

const emailInputName = 'email';
const passwordConfirmationInputName = 'passwordConfirmation';
const passwordInputName = 'password';
const policyCheckboxName = 'acceptPolicies';

type RegisterFormValues = {
  [emailInputName]: string;
  [passwordConfirmationInputName]: string;
  [passwordInputName]: string;
  [policyCheckboxName]: boolean;
};

const initialRegisterFormValues: RegisterFormValues = {
  [emailInputName]: '',
  [passwordConfirmationInputName]: '',
  [passwordInputName]: '',
  [policyCheckboxName]: false,
};

const USER_REFERER_COOKIE_NAME = 'userReferer' as const;

const ChakraRouterDomLink = chakra(routerDomLink);

const SignUpPage: React.FC = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const { t, i18n } = useTranslation(['general', 'signUpPage']);
  // Remember invalid emails so they keep failing the validation
  const [invalidEmails, setInvalidEmails] = React.useState([] as string[]);
  const authCtx = useAuth();

  const userRefererFromCookie: string | null = React.useMemo(
    () => getCookie(USER_REFERER_COOKIE_NAME) || null,
    []
  );

  // Wirbt ein Model, sollte der Link den sie verteilt mit dem Studio Hash versehen sein
  // Das geworbene Model kann eindeutig dem Webmaster/Studio zu geordnet werden
  // - Das ist wichtig, um die Prämien fürs Werben korrekt auszuzahlen
  const studioHash = queryParams.get('studioHash');

  const signUpSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [emailInputName]: Yup.string()
          .required(() => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.emailRequired')}
            />
          ))
          .notOneOf(invalidEmails, () => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.invalidEmail')}
            />
          ))
          .email(() => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.emailFormat')}
            />
          )),

        [passwordInputName]: Yup.string()
          .required(() => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.passwordRequired')}
            />
          ))
          .min(6, () => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.passwordLength')}
            />
          )),

        [passwordConfirmationInputName]: Yup.string()
          .required(() => (
            <ErrorMessageWithLeadingIcon
              children={t(
                'signUpPage:formValidation.passwordConfirmationRequired'
              )}
            />
          ))
          .oneOf([Yup.ref(passwordInputName), null], () => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.passwordsDoNotMatch')}
            />
          )),

        [policyCheckboxName]: Yup.boolean()
          // Both `required` and `oneOf` are necessary for initial validation and subsequent
          .required(() => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.acceptPolicies')}
            />
          ))
          .oneOf([true], () => (
            <ErrorMessageWithLeadingIcon
              children={t('signUpPage:formValidation.acceptPolicies')}
            />
          )),
      }),
    [invalidEmails, t]
  );

  const customHandleSubmit: SubmitHandler<RegisterFormValues> = (data) => {
    signUp({
      variables: {
        email: data[emailInputName],
        password: data[passwordInputName],
        userReferer: userRefererFromCookie,
        studioHash: studioHash,
      },
    }).then();
  };

  const hookForm = useForm({
    defaultValues: initialRegisterFormValues,
    resolver: yupResolver(signUpSchema),
    mode: 'all',
  });

  const { handleSubmit } = hookForm;

  const loginCompleteHandler = React.useCallback(
    async (data: LoginMutation) => {
      if (
        data.auth?.login?.__typename === 'AuthResultSuccessful' &&
        data.auth?.login?.accessToken
      ) {
        const { accessToken, refreshToken } = data.auth.login;
        await authCtx.actions.login(accessToken, refreshToken, true);
      } else {
        // The automatic login somehow failed, redirect to the login
        history.push(routes.login.path);
      }
    },

    [history, authCtx]
  );

  const ErrorHandler = () =>
    React.useCallback((error: ApolloError) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toasts.error'),
        status: 'error',
      });
    }, []);

  /** Handle network and other major errors */
  const [performLogin, { loading: loginLoading }] = useLoginMutation({
    onCompleted: loginCompleteHandler,
    onError: ErrorHandler,
    fetchPolicy: 'no-cache',
  });

  // End of the login section
  const signUpRequestCompleteHandler = React.useCallback(
    (data: SignUpMutation) => {
      // Handle invalid field
      if (data && data.auth?.signup?.__typename === 'AuthResultSuccessful') {
        performLogin({
          variables: {
            username: hookForm.getValues(emailInputName),
            password: hookForm.getValues(passwordInputName),
          },
        });
      } else if (data && data.auth?.signup?.__typename === 'SignupResultFail') {
        const fields: any[] = data.auth?.signup?.invalidFields ?? [];
        for (const field of fields) {
          switch (field.name) {
            case 'email':
              hookForm.setError(emailInputName, {
                type: 'manual',
                message: (
                  <ErrorMessageWithLeadingIcon>
                    {field.errorMessages
                      .map((msg: string) => `${msg}.`)
                      .join('\n')}
                  </ErrorMessageWithLeadingIcon>
                ) as any,
              });
              setInvalidEmails((state) => [
                ...state,
                hookForm.getValues(emailInputName),
              ]);
              break;
            case 'password':
              hookForm.setError(passwordInputName, {
                type: 'manual',
                message: (
                  <ErrorMessageWithLeadingIcon>
                    {field.errorMessages
                      .map((msg: string) => `${msg}.`)
                      .join('\n')}
                  </ErrorMessageWithLeadingIcon>
                ) as any,
              });
              break;
          }
        }

        issueChakraToast({
          description: t('signUpPage:toasts.invalidFields'),
          status: 'error',
        });
      }
    },
    [performLogin, t, hookForm]
  );

  const [signUp, { loading: signUpLoading }] = useSignUpMutation({
    onCompleted: signUpRequestCompleteHandler,
    onError: ErrorHandler,
    fetchPolicy: 'no-cache',
  });

  const openLandingPage = React.useCallback(() => {
    window.open(externalRoutes.main(i18n.language), '_self');
  }, [i18n]);

  return (
    <PublicPageLayout
      pageTitle={t('signUpPage:headerText')}
      onClose={openLandingPage}
    >
      <ScrollToTopOnMount />
      <PublicPageMainSection>
        <PublicPageMainSectionBox>
          <Heading size={'xl'} mb={2}>
            {t('signUpPage:headline')}
          </Heading>
          <Box color="onSurface.mediumEmphasis" mb={6}>
            {t('signUpPage:subHeadline1')}
            <br />
            {t('signUpPage:subHeadline2')}
          </Box>

          <FormProvider {...hookForm}>
            <form onSubmit={handleSubmit(customHandleSubmit)}>
              <VStack spacing={2}>
                <ClearableInputControl
                  label={t('general:email')}
                  name={emailInputName}
                  placeholder={t('signUpPage:emailPlaceholder')}
                />
                <PasswordClearableInputControl
                  label={t('general:password')}
                  name={passwordInputName}
                  placeholder={t('general:passwordPlaceholder')}
                  autoComplete={'section-signup new-password'}
                />
                <PasswordClearableInputControl
                  label={t('signUpPage:passwordConfirmation')}
                  name={passwordConfirmationInputName}
                  placeholder={t('signUpPage:passwordConfirmationPlaceholder')}
                  autoComplete={'section-signup new-password'}
                  mb={4}
                />
                <CheckboxSingleControl
                  name={policyCheckboxName}
                  label={Trans({
                    t,
                    i18nKey:
                      studioHash === null || studioHash === ''
                        ? `signUpPage:policyLabel`
                        : `signUpPage:policyLabelWithoutVxCash`, // non-originators do not join VXCash
                    components: {
                      terms: (
                        <Link
                          color={'primary.500'}
                          href={externalRoutes.terms()}
                          rel="noreferrer"
                          target="_blank"
                        />
                      ),
                      privacy: (
                        <Link
                          color={'primary.500'}
                          href={externalRoutes.privacy()}
                          rel="noreferrer"
                          target="_blank"
                        />
                      ),
                      vxcashTerms: (
                        <Link
                          color={'primary.500'}
                          href={externalRoutes.vxcashTerms()}
                          rel="noreferrer"
                          target="_blank"
                        />
                      ),
                      vxcashPrivacy: (
                        <Link
                          color={'primary.500'}
                          href={externalRoutes.vxcashPrivacy()}
                          rel="noreferrer"
                          target="_blank"
                        />
                      ),
                      vxcash: (
                        <Link
                          color={'primary.500'}
                          href={externalRoutes.vxcash()}
                          rel="noreferrer"
                          target="_blank"
                        />
                      ),
                    },
                  })}
                />
                <Box>
                  <Button
                    mt="6"
                    type="submit"
                    variant={'solid'}
                    children={t('signUpPage:registerButton')}
                    isDisabled={signUpLoading || loginLoading}
                  />
                </Box>
              </VStack>
            </form>
          </FormProvider>
          <Box mt="6" mb="4" textStyle="bodySm" textAlign="center">
            {Trans({
              t,
              i18nKey: `signUpPage:alreadyHaveAccount`,
              components: { login: <ChakraRouterDomLink to="/login" /> },
            })}
          </Box>
        </PublicPageMainSectionBox>
      </PublicPageMainSection>
    </PublicPageLayout>
  );
};

export default SignUpPage;
