import { TextProps, chakra } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMinuteStamp } from '../../../provider/MinuteStampProvider';
import { DateDisplay } from '../DateDisplay/DateDisplay';

const AgeIndicatorText: React.FC<TextProps> = (props) => (
  <chakra.span
    color={'darkSteel'}
    fontSize={'sm'}
    fontWeight={'400'}
    children={'-'}
    {...props}
  />
);

export type AgeIndicatorProps = { created?: string } & TextProps;

export const AgeIndicator: React.FC<AgeIndicatorProps> = ({
  created,
  ...rest
}) => {
  const { t } = useTranslation(['feed']);
  const { minuteStamp } = useMinuteStamp();
  const date = DateTime.fromISO(created ?? '');

  if (!date.isValid) {
    // Fallback to `'-'` when no date can parsed
    return <AgeIndicatorText children={'-'} {...rest} />;
  }

  const currentMinute = DateTime.fromISO(minuteStamp);

  const diffs = date.diff(currentMinute, ['minute', 'hour', 'day']).toObject();
  let text: React.ReactNode = t('feed:text.Age', {
    context: 'now' as const,
  });

  if (diffs.days && diffs.days <= -7) {
    // Date
    text = <DateDisplay date={created} />;
  } else if (diffs.days && diffs.days < 0) {
    // 1 Day - 6 Days
    text = t('feed:text.Age', {
      context: 'days' as const,
      count: Math.floor(Math.abs(diffs.days)),
    });
  } else if (diffs.hours && diffs.hours < 0) {
    // 1H - 6H
    text = t('feed:text.Age', {
      context: 'hours' as const,
      count: Math.floor(Math.abs(diffs.hours)),
    });
  } else if (diffs.minutes && diffs.minutes <= -1) {
    // 1min - 59min
    text = t('feed:text.Age', {
      context: 'minutes' as const,
      count: Math.floor(Math.abs(diffs.minutes)),
    });
  }

  return <AgeIndicatorText children={text} {...rest} />;
};
