import * as icon from '@campoint/odi-ui-icons';
import {
  HStack,
  Heading,
  HeadingProps,
  Icon,
  IconProps,
  Image,
  ImageProps,
  StackProps,
  Text,
  TextProps,
  VStack,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

import fallbackImage from '../../../../assets/images/feed/TextPostPreviewImage.svg';
import { ZStack } from '../../../../components/Layout/ZStack';
import {
  Maybe,
  PhotoalbumFragment,
  VideoFragment,
} from '../../../../generated/graphql';

const MediaRefHeading: React.FC<HeadingProps> = (props) => (
  <Heading
    as="h3"
    size={'none'}
    textStyle={'labelSm'}
    noOfLines={1}
    {...props}
  />
);
const MediaRefContent: React.FC<StackProps> = (props) => (
  <VStack spacing={'0'} alignItems={'start'} {...props} />
);
const MediaRefText: React.FC<TextProps> = (props) => (
  <Text as="span" textStyle={'labelXs'} noOfLines={1} {...props} />
);
const MediaRefBox: React.FC<StackProps> = (props) => (
  <HStack
    px={4}
    py={2}
    spacing={4}
    alignItems={'start'}
    bg={'steel'}
    role={'group'}
    {...props}
  />
);
const VideoRefMediaPreview: React.FC<ImageProps> = (props) => (
  <Image
    fit={'cover'}
    borderRadius={'base'}
    bgColor={'steel'}
    boxSize={10}
    alt={''}
    {...props}
  />
);

const CorneredIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon
    boxSize={'18px'}
    alignSelf={'end'}
    color={'white'}
    ref={ref}
    {...props}
  />
));

const MediaRefImage: React.FC<{
  src: string;
}> = ({ src }) => {
  return (
    <ZStack bg={'coldGray.900'} borderRadius={'base'}>
      <VideoRefMediaPreview src={src} />
      <CorneredIcon as={icon.Movie} />
    </ZStack>
  );
};

type MediaTypeToContentData = {
  src: string;
  title: string;
  description: string;
};

export const MediaRef: React.FC<{
  media?: Maybe<VideoFragment | PhotoalbumFragment> | undefined;
}> = ({ media }) => {
  let mediaTypeToContentData: MediaTypeToContentData | null = null;

  if (media?.__typename === 'Video') {
    mediaTypeToContentData = {
      src: media?.pictures?.at(0)?.image.src ?? '',
      title: media?.textCollection?.visitxTitles?.at(0)?.text ?? '',
      description: media?.textCollection?.visitxDescriptions?.at(0)?.text ?? '',
    };
  }

  if (media?.__typename === 'Photoalbum') {
    mediaTypeToContentData = {
      src:
        media?.previewPicture16?.image?.src ??
        media?.previewPicture18?.image?.src ??
        '',
      title: media?.textCollection?.visitxTitles?.at(0)?.text ?? '',
      description: media?.textCollection?.visitxDescriptions?.at(0)?.text ?? '',
    };
  }

  if (!mediaTypeToContentData) return null;

  return (
    <MediaRefBox>
      <MediaRefImage src={mediaTypeToContentData.src ?? fallbackImage} />
      <MediaRefContent>
        <MediaRefHeading children={mediaTypeToContentData.title} />
        <MediaRefText children={mediaTypeToContentData.description} />
      </MediaRefContent>
    </MediaRefBox>
  );
};
