import React from 'react';

/**
 https://stackoverflow.com/a/51507806
 Prevent implicit submission of the form
 (That is: Do not submit form on pressing 'Enter' key)
 */
export const PreventImplicitFormSubmitOnEnter = () => (
  <button
    type="submit"
    disabled
    style={{ display: 'none' }}
    aria-hidden="true"
  />
);
