import * as Apollo from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useApolloMultiClient } from '../provider/ApolloMultiClientProvider';
import { useAuth } from '../provider/AuthProvider';
import { ApolloLinkContext } from './ApolloClient';

/* Re-export the types */
export * from '@apollo/client';

/**
 * Wrapper for apollo's `useQuery` which injects the i18n and auth user into the apollo context
 */
export function useQuery<
  TData = any,
  TVariables extends Apollo.OperationVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
  options?: Apollo.QueryHookOptions<TData, TVariables>
): Apollo.QueryResult<TData, TVariables> {
  const { i18n } = useTranslation();
  const { logout } = useAuth().actions;
  const ctx = useApolloMultiClient();
  const client = ctx.getClient(options?.context?.clientName);
  const newOptions: Apollo.QueryHookOptions<TData, TVariables> = {
    ...options,
    context: { i18n, logout } as ApolloLinkContext,
    client,
  };
  return Apollo.useQuery<TData, TVariables>(query, newOptions);
}

/**
 * Wrapper for apollo's `useLazyQuery` which injects the i18n and auth user into the apollo context
 */
export function useLazyQuery<
  TData = any,
  TVariables extends Apollo.OperationVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
  options?: Apollo.LazyQueryHookOptions<TData, TVariables>
): Apollo.QueryTuple<TData, TVariables> {
  const { i18n } = useTranslation();
  const { logout } = useAuth().actions;
  const ctx = useApolloMultiClient();
  const client = ctx.getClient(options?.context?.clientName);
  const newOptions: Apollo.LazyQueryHookOptions<TData, TVariables> = {
    ...options,
    context: { i18n, logout } as ApolloLinkContext,
    client,
  };
  return Apollo.useLazyQuery<TData, TVariables>(query, newOptions);
}

/**
 * Wrapper for apollo's `useMutation` which injects the i18n and auth user into the apollo context
 */
export function useMutation<
  TData = any,
  TVariables extends Apollo.OperationVariables = Apollo.OperationVariables
>(
  mutation: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
  options?: Apollo.MutationHookOptions<TData, TVariables>
): Apollo.MutationTuple<TData, TVariables> {
  const { i18n } = useTranslation();
  const { logout } = useAuth().actions;
  const ctx = useApolloMultiClient();
  const client = ctx.getClient(options?.context?.clientName);
  const newOptions: Apollo.MutationHookOptions<TData, TVariables> = {
    ...options,
    context: { i18n, logout } as ApolloLinkContext,
    client,
  };
  return Apollo.useMutation<TData, TVariables>(mutation, newOptions);
}

/**
 * Wrapper for apollo's `useSubscription` which injects the i18n and auth user into the apollo context
 */
export function useSubscription<
  TData = any,
  TVariables extends Apollo.OperationVariables = Apollo.OperationVariables
>(
  subscription:
    | Apollo.DocumentNode
    | Apollo.TypedDocumentNode<TData, TVariables>,
  options?: Apollo.SubscriptionHookOptions<TData, TVariables>
) {
  const { i18n } = useTranslation();
  const { logout } = useAuth().actions;
  const ctx = useApolloMultiClient();
  const client = ctx.getClient(options?.context?.clientName);
  const newOptions: Apollo.SubscriptionHookOptions<TData, TVariables> = {
    ...options,
    context: { i18n, logout } as ApolloLinkContext,
    client,
  };
  return Apollo.useSubscription<TData, TVariables>(subscription, newOptions);
}

export function useSuspence<
  TData = any,
  TVariables extends Apollo.OperationVariables = Apollo.OperationVariables
>(
  query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
  options?: Apollo.SuspenseQueryHookOptions<TData, TVariables>
) {
  const { i18n } = useTranslation();
  const { logout } = useAuth().actions;
  const ctx = useApolloMultiClient();
  const client = ctx.getClient(options?.context?.clientName);
  const newOptions: Apollo.SuspenseQueryHookOptions<TData, TVariables> = {
    ...options,
    context: { i18n, logout } as ApolloLinkContext,
    client,
  };
  return Apollo.useSuspenseQuery<TData, TVariables>(query, newOptions);
}
