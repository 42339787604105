import { ContainerProps } from '@chakra-ui/react';
import React from 'react';

import { ImageWithFullscreen } from '../../../../../components/shared/ImageWithFullscreen/ImageWithFullscreen';
import { FeatureName, useFeature } from '../../../../../flags';
import { FeedPhotoPostFragment } from '../../../../../generated/feed';
import { PostFunctionProps } from '../../../posts';
import { PhotoPostBaseStructure } from '../component/PhotoPostBaseStructure';
import { TempFeedPostScheduleProp } from '../component/types';

export const PhotoPost: React.FC<
  {
    containerProps?: ContainerProps;
    tags?: string[];
    formattedPrice?: string;
    isPostOwnedByUser?: boolean;
    isEditing?: boolean;
  } & TempFeedPostScheduleProp &
    FeedPhotoPostFragment &
    PostFunctionProps
> = ({
  containerProps,
  tags = [],
  formattedPrice = '',
  isPostOwnedByUser = true,
  isEditing,
  ...props
}) => {
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);
  const isMediaDimmedDown =
    feedTippingGoalFlag &&
    !!props.tippingGoal &&
    props.tippingGoal?.achieved === false;

  return (
    <PhotoPostBaseStructure
      {...props}
      visiblePhoto={props.photos?.at(0)!}
      containerProps={containerProps}
      tags={tags}
      formattedPrice={formattedPrice}
      isPostOwnedByUser={isPostOwnedByUser}
      isEditing={isEditing}
    >
      <ImageWithFullscreen
        isDimmedDown={isMediaDimmedDown}
        fsk={props.photos?.[0]?.fsk}
        tags={tags}
        image={props.photos?.[0]}
      />
    </PhotoPostBaseStructure>
  );
};
