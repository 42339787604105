import { useTranslation } from 'react-i18next';

import illustrationFillModelProfile from '../../../../assets/images/shared/create-profile.svg';
import { ModelProfileSimpleInfoPage } from '../../layout/ModelProfileSimpleInfoPage/ModelProfileSimpleInfoPage';

export const ModelProfileCreateProfileInfoPage: React.FC = () => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <ModelProfileSimpleInfoPage
      heading={t('modelProfile:heading.LegeDeineSeiteAn')}
      description={t(
        'modelProfile:text.ErstelleInWenigenSchrittenDeinePersonlicheModelseiteBeiVISITX'
      )}
      buttonText={t('modelProfile:button.ModelprofilAnlegen')}
      image={illustrationFillModelProfile}
      imgAlt={t('modelProfile:imgAlt.ProfilWirdUmEinProfilbildErweitert')}
    />
  );
};
