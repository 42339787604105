import { CacheSwitch } from 'react-router-cache-route';
import { Redirect } from 'react-router-dom';

import VxLiveGoLivePage from '../../pages/VxLivePage/GoLivePage/VxLiveGoLivePage';
import VXLiveMissingRequirementsPage from '../../pages/VxLivePage/MissingRequirementsPage/MissingRequirementsPage';
import VxLivePage from '../../pages/VxLivePage/VxLivePage';
import { ProtectedRoute } from '../../utils/ProtectedRoute';
import { routes } from '../routesConfig';

const VxLiveRouter: React.FC = () => {
  return (
    <CacheSwitch>
      <ProtectedRoute
        routeConfig={routes.vxLive}
        path={routes.vxLive.path}
        exact
      >
        <VxLivePage />
      </ProtectedRoute>
      <ProtectedRoute
        routeConfig={routes.vxLiveGoOnline}
        path={routes.vxLiveGoOnline.path}
        exact
      >
        <VxLiveGoLivePage />
      </ProtectedRoute>
      <ProtectedRoute
        routeConfig={routes.vxLiveMissingRequirements}
        path={routes.vxLiveMissingRequirements.path}
        exact
      >
        <VXLiveMissingRequirementsPage />
      </ProtectedRoute>
      <Redirect to={routes.vxLive.path} />
    </CacheSwitch>
  );
};

export default VxLiveRouter;
