import * as React from 'react';
import { useIntl } from 'react-intl';

export const CurrencyAmount: React.FC<{ amount: number; currency?: 'EUR' }> = ({
  amount,
  currency = 'EUR',
}) => {
  const intl = useIntl();
  const formattedAmount = intl
    .formatNumberToParts(amount, {
      currency,
      style: 'currency',
    })
    .map(({ type, value }) => {
      if (type === 'fraction' && amount === 0) {
        return '–';
      }
      return value;
    })
    .join('');
  return <>{formattedAmount}</>;
};
