import {
  Box,
  BoxProps,
  Heading,
  Image,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import ReactMarkdown, { Components, uriTransformer } from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const MarkdownContainer: React.FC<BoxProps> = (props) => (
  <Box
    maxWidth={'full'}
    sx={{
      'p + p, p + ul, ul + p, ul + ol, ul + ul, ol + p': {
        mt: '1rem',
      },
      'li > p + p': {
        mt: '1rem',
      },
    }}
    {...props}
  />
);

// adwa
const transformLinkUri = (uri: string) => {
  // eslint-disable-next-line no-script-url
  const jsMatchPrefix = 'javascript:';
  if (uri.toLowerCase().startsWith(jsMatchPrefix)) {
    return '#void';
  }

  return uriTransformer(uri);
};

type LinkFunctionRegister = Record<string, Function>;
const Markdown: React.FC<{
  markdown?: string;
  linkFunctionRegister?: LinkFunctionRegister;
  containerProps?: BoxProps;
}> = ({ markdown, linkFunctionRegister = {}, containerProps }) => {
  const spacing = (n: number) => {
    if (n >= 100) {
      return '5ch';
    }
    if (n >= 10) {
      return '4ch';
    }
    return '3ch';
  };

  const h2: Components['h2'] = ({ children }) => (
    <Heading size={'md'} mt={4} mb={1}>
      {children}
    </Heading>
  );

  const components: Components = {
    h1: h2,
    h2: h2,
    h3: h2,
    h4: h2,
    h5: h2,
    h6: h2,
    p: ({ children }) => <Text>{children}</Text>,
    strong: ({ children }) => (
      <Text as={'strong'} fontWeight={'bold'}>
        {children}
      </Text>
    ),
    ol: ({ children }) => (
      <OrderedList
        ml={spacing(
          React.Children.toArray(children).filter((child) =>
            React.isValidElement(child)
          ).length
        )}
      >
        {children}
      </OrderedList>
    ),
    ul: ({ children }) => <UnorderedList ml={6}>{children}</UnorderedList>,
    li: ({ children }) => <ListItem>{children}</ListItem>,
    img: ({ src, alt }) => (
      <Image src={src} alt={alt} my={5} w={'100%'} maxH={72} />
    ),
    a: ({ children, href, node }) => {
      const fn = extractMarkdownLinkFunction(
        linkFunctionRegister,
        node.properties?.href
      );

      return (
        <Link
          href={href}
          textStyle={'linkMd'}
          color={'secondary.highEmphasis'}
          onClick={
            !fn
              ? undefined
              : (e: any) => {
                  e.preventDefault();
                  // @ts-ignore
                  fn?.();
                }
          }
        >
          {children}
        </Link>
      );
    },
  };

  return (
    <MarkdownContainer {...containerProps}>
      {!markdown ? null : (
        <ReactMarkdown
          components={components}
          children={markdown}
          transformLinkUri={transformLinkUri}
          rehypePlugins={[rehypeRaw]}
        />
      )}
    </MarkdownContainer>
  );
};

function extractMarkdownLinkFunction(
  functionRegister: LinkFunctionRegister,
  href?: unknown
): Function | null {
  // eslint-disable-next-line no-script-url
  const jsPrefix = 'javascript:';
  if (
    typeof href !== 'string' ||
    !href ||
    !href.toLowerCase().startsWith(jsPrefix)
  ) {
    return null;
  }
  const functionName = href.substring(jsPrefix.length);
  return functionRegister[functionName] ?? null;
}

export default Markdown;
