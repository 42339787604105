import { Container } from '@chakra-ui/react';
import React from 'react';

import { MediaPreviewBox } from '../../../../components/shared/MediaPreviewBox/MediaPreviewBox';
import { useMedia } from '../../../../provider/MediaProvider';

export const WizardIdentityProofPreviewBox: React.FC<{
  isUploading?: boolean;
}> = ({ isUploading = false }) => {
  const { media } = useMedia();
  const hasMedia = !!media?.src;
  return (
    <Container p={4}>
      <MediaPreviewBox
        boxProps={{
          borderRadius: 'lg',
          border: 'solid',
          borderColor: !hasMedia ? 'black' : 'transparent',
          borderStyle: !hasMedia ? 'dashed' : 'solid',
          backgroundColor: 'surface',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        isUploading={isUploading}
        isAspectRatioDynamic
        restrictToHeight={{ base: '8.5rem', lg: '8.5rem' }}
        imageProps={{ borderRadius: 'lg', bg: 'gray.100' }}
      ></MediaPreviewBox>
    </Container>
  );
};
