import { imageMimeTypes, videoMimeTypes } from './supportedMimeTypes';

const androidCaptureUserFallbackMimeTypeList = [
  ...videoMimeTypes,
  imageMimeTypes.join(','),
].join(',');

export const isFileAccepted = (
  mimeType: string,
  accept: string | undefined
) => {
  // Android requires a very special accept
  if (accept?.startsWith('capture=user')) {
    return androidCaptureUserFallbackMimeTypeList.includes(mimeType);
  }

  if (accept?.includes('image/*') && mimeType.includes('image/')) {
    return true;
  }
  if (accept?.includes('video/*') && mimeType.includes('video/')) {
    return true;
  }

  return accept?.includes(mimeType) ?? false;
};
