import * as React from 'react';

/**
 * On mobile keyboards and url bars do not always affect the "vh" unit.
 * Thus, one has to use the `visualViewport` to detect and behave according to its changes
 * @see https://developer.mozilla.org/en-US/docs/Web/API/VisualViewport
 *
 * Visualization:
 * ```
 * ┌────────┐ ┐
 * ┢━━━━━━━━┪ │
 * ┃ visual ┃ 100vh
 * ┃viewport┃ │
 * ┡━━━━━━━━┩ │
 * │     <- Taken by keyboard
 * └────────┘ ┘
 * ```
 */
export const useDynamicVisualViewport = () => {
  const viewport = globalThis.visualViewport;
  const [dynamicVisualViewport, setDynamicVisualViewport] = React.useState({
    width: globalThis.innerWidth,
    height: globalThis.innerHeight,
    offsetLeft: 0,
    offsetTop: 0,
    pageLeft: 0,
    pageTop: 0,
    scale: 1,
  });

  React.useEffect(() => {
    const handler: EventListenerOrEventListenerObject = (
      event: VisualViewportEventMap['resize']
    ) => {
      // @ts-ignore
      const { width, height, offsetLeft, offsetTop, pageLeft, pageTop, scale } =
        event.target;
      const result = {
        width,
        height,
        offsetLeft,
        offsetTop,
        pageLeft,
        pageTop,
        scale,
      };
      setDynamicVisualViewport(result);
    };
    viewport?.addEventListener('resize', handler);
    return () => viewport?.removeEventListener('resize', handler);
  }, [setDynamicVisualViewport, viewport]);

  return { dynamicVisualViewport } as const;
};
