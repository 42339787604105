//Wird vom Support geprüft
import * as icons from '@campoint/odi-ui-icons';
import {
  HStack,
  Heading,
  Icon,
  VStack,
  VisuallyHidden,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import {
  ResponsiveModal,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../../components/Layout/ResponsiveModal';
import { ChevronRightIcon } from '../../../../../components/shared/icon/ChevronRightIcon';

interface DocumentPickerPendingMessageProps {
  message: React.ReactNode;
  modalHeading: React.ReactNode;
  modalTextSlot: React.ReactNode;
  modalHelpLinkSlot: React.ReactNode;
}

export const DocumentPickerPendingMessage: React.FC<
  DocumentPickerPendingMessageProps
> = ({ message, modalHeading, modalTextSlot, modalHelpLinkSlot }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <HStack onClick={onOpen} color={'warning.500'} cursor={'pointer'}>
        <Icon as={icons.Schedule} boxSize={'icon.md'} />
        <span>{message}</span>
        <ChevronRightIcon />
      </HStack>
      <ResponsiveModal isOpen={isOpen} onClose={onClose} isVCentered>
        <ResponsiveModalOverlay />
        <ResponsiveModalContent
          textAlign={'center'}
          p={'24px 16px'}
          justifyContent={'center'}
        >
          <VisuallyHidden>{modalHeading}</VisuallyHidden>
          <ResponsiveModalCloseButton />
          <VStack spacing={'12px'}>
            <Icon
              as={icons.Schedule}
              boxSize={'icon.lg'}
              color={'warning.500'}
            />
            <Heading size={'xl'}>{modalHeading}</Heading>
            {modalTextSlot}
            {modalHelpLinkSlot}
          </VStack>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </>
  );
};
