import { FormikProvider, useFormik } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputList } from '../../../../../components/Spacer/InputsList/InputList';
import {
  ErrorMessageWithLeadingIcon,
  TooOldErrorMessage,
  TooYoungErrorMessage,
} from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { ClearableInputControl } from '../../../../../components/shared/FormikFormElements';
import { FormikDateInput } from '../../../../../components/shared/FormikInput/Date/FormikDateInput';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { ProfileFieldsEnum } from '../../../../../generated/graphql';
import { useFormikInitialProps } from '../../../../../hooks/useFormikInitialProps';
import { useWizardOnContinueCallback } from '../../../../../hooks/useWizardOnContinueCallback';
import { useToday } from '../../../../../provider/TodayProvider';
import { calculateAge } from '../../../../../test/date-utils';
import { noop } from '../../../../../utils';
import { onFocusScrollIntoCenter } from '../../../../../utils/onFocusScrollIntoCenter';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardStepFooter } from '../../../components/WizardStepFooter/WizardStepFooter';
import { useWizardMRF } from '../../WizardMRFContext';

const firstNameFieldName = ProfileFieldsEnum.MrfFirstname;
const lastNameFieldName = ProfileFieldsEnum.MrfLastname;
const birthdateFieldName = ProfileFieldsEnum.MrfBirthdate;
const birthplaceFieldName = ProfileFieldsEnum.MrfBirthplace;

const RequiredAgeErrorMessage = () => {
  const { t } = useTranslation(['wizardMRF']);
  return (
    <ErrorMessageWithLeadingIcon
      children={t('wizardMRF:error.BitteGibDeinAlterAn')}
    />
  );
};

const minimumAge = 18;
const maximumAge = 75;

export const WizardMRFModelReleaseFormV1PersonalData: React.FC = () => {
  const { t } = useTranslation(['wizardMRF']);
  const { todayDayStamp } = useToday();
  const wizard = useWizardMRF();
  const { currentField, accountPrefillField } = wizard;

  const initDate = React.useMemo<string>(() => {
    const prefillDate =
      accountPrefillField?.accountBirthdate?.value ?? undefined;
    return !prefillDate
      ? ''
      : DateTime.fromISO(prefillDate, { zone: 'UTC' }).toISODate();
  }, [accountPrefillField]);

  const maxDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: minimumAge }).toISODate(),
    [todayDayStamp]
  );

  const minDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: maximumAge }).toISODate(),
    [todayDayStamp]
  );

  const prefillValues = React.useMemo(() => {
    return {
      [firstNameFieldName]: accountPrefillField?.accountFirstname?.value ?? '',
      [lastNameFieldName]: accountPrefillField?.accountLastname?.value ?? '',
      [birthdateFieldName]: initDate,
      [birthplaceFieldName]: '',
    };
  }, [initDate, accountPrefillField]);

  const { initialValues, initialTouched } =
    useFormikInitialProps(prefillValues);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [birthdateFieldName]: Yup.string()
        .required(() => <RequiredAgeErrorMessage />)
        .test((value, testContext) => {
          if (value && calculateAge(value) < minimumAge) {
            return testContext.createError({
              message: () => <TooYoungErrorMessage years={minimumAge} />,
            });
          }
          if (value && calculateAge(value) > maximumAge) {
            return testContext.createError({
              message: () => <TooOldErrorMessage years={maximumAge} />,
            });
          }
          return true;
        }),
      [firstNameFieldName]: createStringValidationSchema({
        ...currentField[firstNameFieldName],
      }),
      [lastNameFieldName]: createStringValidationSchema({
        ...currentField[lastNameFieldName],
      }),
      [birthplaceFieldName]: createStringValidationSchema({
        ...currentField[birthplaceFieldName],
      }),
    });
  }, [currentField]);

  const formik = useFormik({
    initialTouched,
    initialValues,
    onSubmit: noop,
    enableReinitialize: true,
    validationSchema,
  });

  const onContinue = useWizardOnContinueCallback({
    wizard,
    currentSelected: 'formik',
    formiks: { formik },
  });

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t('wizardMRF:heading.EinPaarPersonlicheDatenVonDir')}
          children={
            <form
              aria-busy={formik.isValidating}
              aria-label={t('wizardMRF:heading.EinPaarPersonlicheDatenVonDir')}
            >
              <FormikProvider value={formik}>
                <InputList mt={0}>
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    name={firstNameFieldName}
                    label={t('wizardMRF:label.Vorname')}
                    placeholder={t('wizardMRF:placeholder.VornamenEingeben')}
                  />
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    name={lastNameFieldName}
                    label={t('wizardMRF:label.Nachname')}
                    placeholder={t('wizardMRF:placeholder.NachnamenEingeben')}
                  />
                  <FormikDateInput
                    name={birthdateFieldName}
                    label={t('wizardMRF:label.DeinGeburtstag')}
                    placeholder={t('wizardMRF:placeholder.GeburtstagEingeben')}
                    min={minDate}
                    max={maxDate}
                  />
                  <ClearableInputControl
                    onFocus={onFocusScrollIntoCenter}
                    name={birthplaceFieldName}
                    label={t('wizardMRF:label.Geburtsort')}
                    placeholder={t('wizardMRF:placeholder.GeburtsortEingeben')}
                  />
                </InputList>
              </FormikProvider>
            </form>
          }
        />
      }
      footerSection={
        <WizardStepFooter
          continueButtonText={t('wizardMRF:button.Weiter')}
          onContinueButtonClick={onContinue}
          disableContinueButton={!formik.isValid}
          omitSkipButton
        />
      }
    />
  );
};
