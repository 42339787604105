import React from 'react';

import { useFinanceService } from '../../../provider/FinanceService/FinanceService';
import { LayoutCenteringVStack } from '../../Layout/LayoutCenteringVStack';
import { UpdateTaxInformationCard } from '../Payout/UpdateTaxInformation/UpdateTaxInformationCard';

export const UpdateTaxInformationExpiresSoonHintSlot = () => {
  const {
    isPayoutTaxUpdateVisible,
    isPayoutTaxUpdatePending,
    isPayoutTaxUpdateRejected,
    isPayoutTaxDocumentExpiringSoon,
    daysTillPayoutTaxDocumentExpires,
  } = useFinanceService();

  const isShown =
    isPayoutTaxUpdateVisible &&
    !(isPayoutTaxUpdatePending || isPayoutTaxUpdateRejected) &&
    isPayoutTaxDocumentExpiringSoon;

  return !isShown ? null : (
    <LayoutCenteringVStack>
      <UpdateTaxInformationCard
        daysLeft={daysTillPayoutTaxDocumentExpires ?? 0}
      />
    </LayoutCenteringVStack>
  );
};
