import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import {
  MediaSaleFragment,
  useGetVideoLibraryUploadListEntrySalesLazyQuery,
} from '../../generated/graphql';
import Logger from '../../utils/Logger';

export const useFetchAndLoadMoreSales = (
  fetchMoreSalesCount: number,
  onClose: () => void
) => {
  const { t } = useTranslation(['general']);
  const [
    refetchSales,
    {
      data: salesData,
      loading: salesLoading,
      error: salesError,
      fetchMore: fetchMoreSales,
    },
  ] = useGetVideoLibraryUploadListEntrySalesLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t('general:toast.DatenKonntenNichtGeladenWerden'),
      });
      onClose();
    },
    notifyOnNetworkStatusChange: true,
  });

  const sales = useMemo(() => {
    return (salesData?.media?.sales?.edges?.map((e) => e?.node) ??
      []) as MediaSaleFragment[];
  }, [salesData?.media?.sales?.edges]);

  const loadMoreSales = useCallback(() => {
    const hasNextPage = salesData?.media.sales.pageInfo?.hasNextPage;

    if (hasNextPage && !salesError) {
      const lastSale =
        salesData?.media?.sales?.edges[
          salesData?.media?.sales?.edges?.length - 1
        ];
      fetchMoreSales({
        variables: {
          count: fetchMoreSalesCount,
          after: lastSale?.cursor ?? '',
        },
      });
    }
  }, [
    salesData?.media.sales.pageInfo?.hasNextPage,
    salesData?.media.sales?.edges,
    salesError,
    fetchMoreSales,
    fetchMoreSalesCount,
  ]);

  return {
    refetchSales,
    sales,
    salesLoading,
    salesError,
    loadMoreSales,
  };
};
