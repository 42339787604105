import { FormikContextType, setNestedObjectValues } from 'formik';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { MultiTextareaV2TabProps } from '../components/shared/FormElements/MultiTextareaV2/MultiTextareaV2';
import {
  ContestFragment,
  LanguagesEnum,
  Maybe,
  MutationResultFragment,
  PhotoalbumFragment,
  ProfileFieldTypeMultilangStringFragment,
  VideoFragment,
} from '../generated/graphql';
import { useToday } from '../provider/TodayProvider';
import Logger from './Logger';

export const secondsInMs = (_: number) => 1000 * _;

export const createValidationFnFromSchema = (schema: Yup.AnySchema) => {
  return async (value: any) => {
    try {
      await schema.validate(value);
      return undefined;
    } catch (error) {
      if (error instanceof ValidationError) {
        return error.message;
      }
      Logger.error(error);
      return 'Error';
    }
  };
};

export const createProfileFieldTypeStringValidationSchema = ({
  field,
  textInCaseOfError,
}: {
  field: { minLength?: null | number; maxLength?: null | number } | undefined;
  textInCaseOfError: Partial<{
    required: string;
    minLength: (minLength: number) => string;
    maxLength: (maxLength: number) => string;
  }>;
}) => {
  const { minLength, maxLength } = field ?? {};
  return Yup.string()
    .concat(
      !textInCaseOfError.required
        ? Yup.string()
        : Yup.string().required(textInCaseOfError.required)
    )
    .concat(
      !textInCaseOfError.minLength || typeof minLength !== 'number'
        ? Yup.string()
        : Yup.string().min(minLength, textInCaseOfError.minLength(minLength))
    )
    .concat(
      !textInCaseOfError.maxLength || typeof maxLength !== 'number'
        ? Yup.string()
        : Yup.string().max(maxLength, textInCaseOfError.maxLength(maxLength))
    );
};
export const formikValidateAndSetTouched = async (
  form: FormikContextType<any>
) => {
  try {
    const validationErrors = await form.validateForm();
    form.setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      form.setTouched(setNestedObjectValues(validationErrors, true));
      return;
    }
  } catch (err) {
    //Do nothing
  }
};

export const validateAndTouchFormik = async (
  form: FormikContextType<any>
): Promise<boolean> => {
  const validationErrors = await form.validateForm();
  if (Object.keys(validationErrors).length > 0) {
    form.setErrors(validationErrors);
    await form.setTouched(setNestedObjectValues(validationErrors, true), false);
    return false;
  }
  return true;
};

export const isMutationResultError = (result?: Maybe<MutationResultFragment>) =>
  result?.success == null || result.success === false;

export const mutationResultErrorHandling = (
  result?: Maybe<MutationResultFragment>
) => {
  if (result?.message) {
    issueChakraToast({
      description: result.message,
      status: 'error',
    });
  }
};

/**
 * Useful for filtering undefined values from lists
 * @example
 * [undefined, 0, null, {}].filter(isDefined); // [0, {}]
 **/
export function isDefined<T>(val: T): val is NonNullable<T> {
  return !(val === undefined || val === null);
}

export function parseISODateStamp(stamp?: string | null): Date | null {
  return stamp ? DateTime.fromISO(stamp).toJSDate() : null;
}

export const createQueryParamsFromObject = (
  params?: {
    [key: string]: string;
  },
  path = ''
): string => {
  if (params) {
    return `${path}?${new URLSearchParams(params).toString()}`;
  }

  if (path) {
    return path;
  }

  return '';
};

export const windowOpen: (typeof window)['open'] = (...args) =>
  window.open(...args);

export function propertiesToBoolean<K extends string>(_: { [key in K]: any }) {
  return Object.entries(_).reduce(
    (acc, [name, value]) => ({ ...acc, [name]: !!value }),
    {} as { [key in K]: boolean }
  );
}

export function extractDefinedEntries<T>(array?: Maybe<T>[] | null) {
  return (array ?? []).filter(isDefined) as Exclude<T, null | undefined>[];
}

/**
 * Used with `as const` it can enforce a shape,
 * but instead of having the general type like a `string` for a property like `payoutAddressFirstname`,
 * it sets a narrower const value like `"Hildegard"` shwing up in its reference.
 *
 * Also, this allows to jump to the exact definition of the value,
 * instead of jumping to the Interface with the general type
 *
 * @see InputTourPayoutV2
 * @example
 * const createShapeEnforcer = <Shape extends object>() => <O extends Shape>(_:O) => _
 * const createInputTourPayoutV2ShapedConst = createShapeEnforcer<InputTourPayoutV2>()
 *
 * const res = createInputTourPayoutV2ShapedConst({
 *   payoutAddressFirstname: 'Hildegard',
 *   payoutAddressLastname: 'Müller',
 *   payoutAddressStreet: 'Fahrstraße',
 *   payoutAddressStreetNumber: '59',
 *   payoutAddressCity: 'Hainburg',
 *   payoutAddressPostalCode: '63512',
 *   payoutAddressCountry: CountryEnum.De,
 *   payoutAddressDocument: 5678,
 * } as const)
 *
 * typeof res // {readonly payoutAddressFirstname: "Hildegard", readonly payoutAddressLastname: "Müller", ...rest }
 * typeof res.payoutAddressFirstname // "Hildegard"
 */
export const createShapeEnforcer =
  <Shape extends object>() =>
  <O extends Shape>(_: O) =>
    _;
export const sleep = async (ms = 0) =>
  new Promise((resolve) => setTimeout(resolve, ms));
export const openDismissUnsavedChangesBrowserModal = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

export const multiLangFieldStrings = (
  name: string
): { de: string; en: string } => {
  return {
    de: `${name}-${LanguagesEnum.De}`,
    en: `${name}-${LanguagesEnum.En}`,
  };
};

export const enrichedMultiLangFieldData = ({
  field,
  placeholder,
}: {
  field: ProfileFieldTypeMultilangStringFragment;
  placeholder?: string;
}): MultiTextareaV2TabProps[] => {
  const { langs, maxLength } = field;

  return langs.map((lang) => ({
    label: lang.label,
    name: `${field.name}-${lang.name}`,
    maxCharacterCount: maxLength,
    textareaProps: {
      placeholder,
      isDisabled: field.disabled,
      isReadOnly: field.readOnly,
    },
  }));
};

type DeepPartialReadonly<T> = T extends (infer R)[]
  ? DeepReadonlyArray<R>
  : T extends Function
  ? T
  : T extends object
  ? DeepPartialReadonlyObject<Partial<T>>
  : T;

interface DeepReadonlyArray<T> extends ReadonlyArray<DeepPartialReadonly<T>> {}

type DeepPartialReadonlyObject<T> = {
  readonly [P in keyof T]: DeepPartialReadonly<T[P]>;
};

export const scrollToElement = (elementId: string) => {
  document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
};

export const useDateFromTodayByYears = (yearsFromToday: number): string => {
  const { todayDayStamp } = useToday();

  return DateTime.fromISO(todayDayStamp)
    .minus({ years: yearsFromToday })
    .toISODate();
};

export const toPassword = (input: string): string => {
  return input.replaceAll(/./g, '•');
};

export const getSrcSet = (
  media?: ContestFragment | VideoFragment | PhotoalbumFragment | null
): string | undefined => {
  if (!media) return undefined;
  if (media.__typename === 'MediaContest') {
    if (media.video) return getSrcSet(media.video);
    if (media.photoalbum) return getSrcSet(media.photoalbum);
  }
  if (media.__typename === 'Video') {
    return (
      media.previewPicture16?.image.srcset ||
      media.previewPicture18?.image.srcset ||
      media.pictures?.at(0)?.image.srcset
    );
  }
  if (media.__typename === 'Photoalbum') {
    return (
      media.previewPicture16?.image.srcset ||
      media.previewPicture18?.image.srcset
    );
  }
  return undefined;
};
