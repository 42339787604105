export const imageMimeTypes = [
  'image/jpeg',
  // Supported via heic2any conversion to jpeg
  //'image/heic',
  // Supported via heic2any conversion to jpeg
  //'image/heif',
  'image/png',
  'image/webp',
] as const;

export const videoMimeTypes = ['video/*'];

export function supportsVideoType(type: string) {
  const video = document.createElement('video');
  /**
   * probably   Media of the type indicated by the mediaType parameter is probably playable on this device.
   * maybe      Not enough information is available to determine for sure whether or not the media will play until playback is actually attempted.
   * ""         Media of the given type definitely can't be played on the current device.
   */
  return video.canPlayType(type) !== '';
}

let supportedVideoMimeTypes: string[] | null = null;

/**
 * Tries to determine the list of supported video mimetype for the current device
 */
export function getSupportedVideoMimeTypes() {
  if (supportedVideoMimeTypes) {
    return supportedVideoMimeTypes;
  }
  supportedVideoMimeTypes = videoMimeTypes.filter(supportsVideoType);
  return supportedVideoMimeTypes;
}
