import { SecurityRoleEnum } from '../generated/graphql';
import { RequiredRole } from '../utils/ProtectedRoute';
import { createShapeEnforcer } from '../utils/utils';

export interface RouteConfig {
  path: string;
  redirectToOnRoleError?: string;
  requiredRole: RequiredRole;
  /** Sub-routes which are not actually parsed by the router
   * but only 'virtually' by the component */
  virtualSubRoutes?: { [key: string]: string };
}

export type RouteReferrerState = Partial<{ referrer: string }>;

const routeShapes = createShapeEnforcer<Record<string, RouteConfig>>();

export const routes = routeShapes({
  account: {
    path: '/account',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  home: {
    path: '/home',
    requiredRole: [SecurityRoleEnum.VxUser],
    virtualSubRoutes: {
      usp: '/home/usp',
      uspId: '/home/usp/:uspId',
      verifyEmailModal: '/home/verify-email',
      welcomeModal: '/home/welcome',
    },
  },
  logout: {
    path: '/logout',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  documentsActors: {
    path: '/documents/actors',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  documentsActorsCreate: {
    path: '/documents/create-actor',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  documentsActorsDetail: {
    path: '/documents/actors/:userId',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  documentsPayout: {
    path: '/documents/payout',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  emailVerified: {
    path: '/email-verified',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  error: {
    path: '/error',
    requiredRole: 'NONE',
  },
  feed: {
    path: '/feed',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  feedPost: {
    path: '/feed/post/:postId',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  featureFlag: {
    path: '/featureFlags',
    requiredRole: [SecurityRoleEnum.VxUserInternalAccount],
  },
  featureRequest: {
    path: '/feature-request',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  finance: {
    path: '/finance',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  financeTax: {
    path: '/finance/tax',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  financePaymentData: {
    path: '/finance/payment-data',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  help: {
    path: '/help',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  houseRules: {
    path: '/house-rules',
    requiredRole: 'NONE',
  },
  imprint: {
    path: '/imprint',
    requiredRole: 'NONE',
  },
  incomeSources: {
    path: '/income-sources',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  login: {
    path: '/login',
    // Login page should decide itself where to route to, e.g. with url parameter
    requiredRole: 'NONE',
  },
  passwordReset: {
    path: '/passwordreset',
    requiredRole: 'NONE',
  },
  passwordResetVerify: {
    path: '/passwordreset/verify/:hash',
    requiredRole: 'NONE',
  },
  privacyPolicy: {
    path: '/privacy-policy',
    requiredRole: 'NONE',
  },
  profileV2: {
    path: '/profile',
    requiredRole: [SecurityRoleEnum.VxUser],
    virtualSubRoutes: {
      acceptedCompleteProfile: '/profile/accepted-complete-profile',
      createProfile: '/profile/create-profile',
      completeProfile: '/profile/complete-profile',
      pendingCompleteProfile: '/profile/pending-complete-profile',
      rejected: '/profile/rejected',
      rejectedCompleteProfile: '/profile/rejected-complete-profile',
    },
  },
  redirect: {
    path: '/redirect',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  register: {
    path: '/signup',
    requiredRole: ['LOGGED_OUT'],
  },
  statisticEdit: {
    path: '/statistic-edit',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  statistics: {
    path: '/statistics',
    requiredRole: [SecurityRoleEnum.VxUserMaster],
  },
  termsAndConditions: {
    path: '/terms-and-conditions',
    requiredRole: 'NONE',
  },
  tips: {
    path: '/tips',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  vxLive: {
    path: '/vxlive',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  videoLibrary: {
    path: '/video-library',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  /**
   * @deprecated open vxlive.net in a new tab instead
   */
  vxLiveChat: {
    path: '/vxlive/chat',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  vxLiveMissingRequirements: {
    path: '/vxlive/missing-requirements',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
  vxLiveGoOnline: {
    path: '/vxlive/go-online',
    requiredRole: [SecurityRoleEnum.VxUser],
  },
} as const);

export const externalRoutes = {
  accountPassword: () => `/camtool/account/passwords`,
  helpCenter: (langCode: string) =>
    `https://helpcenter.vxmodels.com/hc/${langCode === 'de' ? 'de' : 'en-us'}`,
  customerService: (langCode: string) =>
    `https://helpcenter.vxmodels.com/hc/${langCode}`,
  houseRules: () => `/camtool/legal/houserule`,
  imprint: (langCode: string) =>
    `https://www.vxmodels.com/${langCode}/impressum`,
  main: (langCode: string) => `https://www.vxmodels.com/${langCode}`,
  passwordReset: (langCode: string) =>
    `https://www.vxmodels.com/${langCode}/passwordreset`,
  privacy: () => 'https://beta.vxmodels.com/privacy-policy',
  terms: () => 'https://beta.vxmodels.com/terms-and-conditions',
  vxcashTerms: () => 'https://www.vxcash.net/webmaster/DE/info/agb/',
  vxcashPrivacy: () => 'https://www.vxcash.net/webmaster/DE/info/datenschutz/',
  vxcash: () => 'https://www.vxcash.net/',
  vxLive: () => `https://www.vxlive.net`,
  vxModelsProfile: (langCode: string, modelName: string) =>
    `https://www.visit-x.net/${langCode}/amateur/${modelName}`,
  vxModelsImageRules: (langCode: string) =>
    langCode === 'de'
      ? //todo add english article
        'https://helpcenter.vxmodels.com/hc/de/articles/12436698845714-Warum-werden-meine-Bilder-abgelehnt'
      : 'https://vxmodels.zendesk.com/hc/en-us/articles/4407125454482-Reasons-for-refusal-for-photos',
  vxModelsImageQuality: (langCode: string) =>
    langCode === 'de'
      ? 'https://support.vxmodels.com/hc/de/articles/360011061279-Qualit%C3%A4t-deiner-Bilder-und-Videos'
      : 'https://support.vxmodels.com/hc/en-us/articles/360011061279-Quality-of-your-pictures-and-videos',
  vxModelsZendeskOverview: (langCode: string) =>
    langCode === 'de'
      ? 'https://vxmodels.zendesk.com/hc/de'
      : 'https://vxmodels.zendesk.com/hc/en-us',
  helpCenterArticleWannBinIchKleinunternehmer: (langCode: string) =>
    langCode === 'de'
      ? 'https://helpcenter.vxmodels.com/hc/de/articles/12455896610706-Wann-bin-ich-Kleinunternehmer'
      : // todo: https://campoint.atlassian.net/browse/VXM-3537 add actual english article,
        //  instead of showing the helpcenter start page
        'https://helpcenter.vxmodels.com/hc/en-us',
  helpCenterArticleAllesZurUmsatzsteuerpflicht: (langCode: string) =>
    langCode === 'de'
      ? 'https://helpcenter.vxmodels.com/hc/de/articles/12455911572370-Wann-bin-ich-umsatzsteuerpflichtig'
      : // todo: https://campoint.atlassian.net/browse/VXM-3537 add actual english article,
        //  instead of showing the helpcenter start page
        'https://helpcenter.vxmodels.com/hc/en-us',
};
