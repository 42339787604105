import {
  Section,
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { DocumentPickerV3 } from '../../../../components/shared/DocumentPickerV3/DocumentPickerV3';
import { DocumentPickerV3Status } from '../../../../components/shared/DocumentPickerV3/DocumentPickerV3Status';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { FormControlHeaderStack } from '../../../../components/shared/FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { PromotingOptionList } from '../../../../components/shared/FormikFormElements';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  StatusDone,
  StatusPending,
} from '../../../../components/shared/StatusIndicator/StatusIndicator';
import {
  CountryEnum,
  GetPayoutDocumentsAddressSectionDocument,
  ProfileStatusEnum,
  UploadDocument,
  useGetPayoutDocumentsAddressSectionQuery,
  useUpdatePayoutDocumentsAddressSectionMutation,
} from '../../../../generated/graphql';
import { useExpiresInDays } from '../../../../hooks/useExpiresInDays';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { MediaFlowProvider } from '../../../../provider/MediaFlowProvider';
import { MediaInputProvider } from '../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../provider/MediaProvider';
import { MediaUploadProvider } from '../../../../provider/MediaUploadProvider';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import Logger from '../../../../utils/Logger';
import { createStringValidationSchema } from '../../../../utils/validation';
import { getFieldInitialErrors } from '../../../ModelProfileV2Page/ModelProfilePage/utils';
import { DocumentMediaFlow } from '../components/DocumentMediaFlow/DocumentMediaFlow';
import { SubmitChangesButtonWithConfirmation } from '../components/SubmitChangesButtonWithConfirmation/SubmitChangesButtonWithConfirmation';
import { PaymentExpiredAlert } from '../components/alert/PaymentExpiredAlert/PaymentExpiredAlert';
import { PaymentRejectedAlert } from '../components/alert/PaymentRejectedAlert/PaymentRejectedAlert';

export type PayoutAddressSectionProps = {
  payoutAddress?: Maybe<{
    status: Maybe<ProfileStatusEnum>;
    rejectReason: Maybe<string>;
    rejectReasonTitle: Maybe<string>;
  }>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  city: Maybe<string>;
  company: Maybe<string>;
  country: Maybe<string>;
  houseNumber: Maybe<string>;
  street: Maybe<string>;
  zip: Maybe<string>;
  document: Maybe<Partial<UploadDocument>>;
  isCompany: Maybe<boolean>;
};

const fieldName = {
  isCompany: 'isCompany',
  companyName: 'companyName',
  firstName: 'firstName',
  lastName: 'lastName',
  street: 'streer',
  streetNumber: 'streetNumber',
  postalCode: 'postalCode',
  city: 'city',
  country: 'country',
  document: 'document',
} as const;

interface FormValues {
  [fieldName.isCompany]: boolean;
  [fieldName.companyName]: string;
  [fieldName.firstName]: string;
  [fieldName.lastName]: string;
  [fieldName.street]: string;
  [fieldName.streetNumber]: string;
  [fieldName.postalCode]: string;
  [fieldName.city]: string;
  [fieldName.country]: CountryEnum;
  [fieldName.document]: number | null;
}

const PayoutDocumentsAddressSection: React.FC = () => {
  const { t } = useTranslation([
    'payout',
    'wizardPayout',
    'imagePicker',
    'document',
    'general',
  ]);

  const issueSuccesToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.AnderungenWurdenGespeichert'),
      status: 'success',
    });
  }, [t]);

  const issueErrorToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      status: 'error',
    });
  }, [t]);

  const { data, loading } = useGetPayoutDocumentsAddressSectionQuery();

  const isAccountFromCH = React.useMemo(() => {
    return data?.account.isAccountFromCH ?? false;
  }, [data?.account.isAccountFromCH]);

  const initialValues = React.useMemo<FormValues>(() => {
    const fields = data?.payment?.billingAddress;

    return {
      [fieldName.isCompany]: fields?.isCompany?.value ?? false,
      [fieldName.companyName]: fields?.companyname?.value ?? '',
      [fieldName.firstName]: fields?.firstname?.value ?? '',
      [fieldName.lastName]: fields?.lastname?.value ?? '',
      [fieldName.street]: fields?.street?.value ?? '',
      [fieldName.streetNumber]: fields?.streetNumber?.value ?? '',
      [fieldName.postalCode]: fields?.postalCode?.value ?? '',
      [fieldName.city]: fields?.city?.value ?? '',
      [fieldName.country]: (fields?.country?.value ?? '') as CountryEnum,
      [fieldName.document]: fields?.document?.value?.docId ?? null,
    };
  }, [data]);

  const validationSchema = React.useMemo(() => {
    const fields = data?.payment?.billingAddress;
    const isCompany = !!data?.payment?.billingAddress?.isCompany?.value;

    return Yup.object().shape({
      [fieldName.companyName]: !isCompany
        ? Yup.string()
        : createStringValidationSchema({
            ...fields?.companyname,
          }),
      [fieldName.firstName]: isCompany
        ? Yup.string()
        : createStringValidationSchema({
            ...fields?.firstname,
          }),
      [fieldName.lastName]: isCompany
        ? Yup.string()
        : createStringValidationSchema({
            ...fields?.lastname,
          }),
      [fieldName.street]: createStringValidationSchema(fields?.street ?? {}),
      [fieldName.streetNumber]: createStringValidationSchema(
        fields?.streetNumber ?? {}
      ),
      [fieldName.postalCode]: createStringValidationSchema(
        fields?.postalCode ?? {}
      ),
      [fieldName.city]: createStringValidationSchema(fields?.city ?? {}),
      [fieldName.country]: createStringValidationSchema({}),
    });
  }, [data]);

  const hookForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const documentExpiration = useExpiresInDays({
    expireAt: data?.payment?.billingAddress?.document?.value?.expireDate,
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [loading, hookForm, initialValues]);

  const financeServiceCtx = useFinanceService();
  const { isPayoutAddressUpdateEditAllowed } = financeServiceCtx;

  const {
    onOmitChangesTriggerCount,
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const onOmitChanges = React.useCallback(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const validValues = data?.payment?.billingAddress?.country?.validValues;
  const quickpickValues =
    data?.payment?.billingAddress?.country?.quickpickValues;
  const optionListProps = useMemo(
    () => ({
      options: validValues ?? [],
      groupLabel: t('general:optgroup.Lander'),
      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
      placeholder: t('payout:placeholder.LandEingeben'),
      promotedValues: (quickpickValues ?? []).map(({ value }) => value),
    }),
    [t, validValues, quickpickValues]
  );

  const [updatePayoutDocumentsAddressSection] =
    useUpdatePayoutDocumentsAddressSectionMutation({
      refetchQueries: [GetPayoutDocumentsAddressSectionDocument],
    });

  const onInvalidSubmit: SubmitErrorHandler<FormValues> = (errors) => {
    Logger.error(errors);
  };

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const newDocId = data[fieldName.document];
        if (!newDocId) {
          issueErrorToast();
          return;
        }

        const result = await updatePayoutDocumentsAddressSection({
          variables: {
            data: data[fieldName.isCompany]
              ? {
                  isCompany: true,
                  companyname: data[fieldName.companyName],
                  street: data[fieldName.street],
                  streetNumber: data[fieldName.streetNumber],
                  postalCode: data[fieldName.postalCode],
                  city: data[fieldName.city],
                  country: data[fieldName.country],
                  document: newDocId,
                }
              : {
                  isCompany: false,
                  firstname: data[fieldName.firstName],
                  lastname: data[fieldName.lastName],
                  street: data[fieldName.street],
                  streetNumber: data[fieldName.streetNumber],
                  postalCode: data[fieldName.postalCode],
                  city: data[fieldName.city],
                  country: data[fieldName.country],
                  document: newDocId,
                },
          },
        });

        const errors = getFieldInitialErrors(
          (result?.data?.payment?.billingAddress
            ?.updateBillingAddress as any) ?? null,
          'unkown error'
        );

        if (!!errors) {
          Object.entries(errors).forEach(([key, entry]) => {
            hookForm.setError(key as any, {
              type: 'manual',
              message: entry as any,
            });
          });
          issueErrorToast();
        } else {
          issueSuccesToast();
        }
      } catch (error) {
        Logger.error(error);
        issueErrorToast();
      }
    },
    [
      hookForm,
      updatePayoutDocumentsAddressSection,
      issueSuccesToast,
      issueErrorToast,
    ]
  );

  const isFormDirty = hookForm.formState.isDirty;
  const overallStatusWithoutDirty = React.useMemo<string | undefined>(() => {
    const statusIsOk = data?.payment?.billingAddress?.status === 'accepted';
    if (statusIsOk && documentExpiration.isExpired) {
      return 'expired';
    }
    if (statusIsOk && documentExpiration.isExpiring) {
      return 'expiring';
    }
    return data?.payment?.billingAddress?.status ?? undefined;
  }, [data, documentExpiration]);
  const overallStatus = React.useMemo<string | undefined>(() => {
    if (isFormDirty) {
      return 'dirty';
    }
    return overallStatusWithoutDirty;
  }, [overallStatusWithoutDirty, isFormDirty]);

  const isInitiallyCompany = initialValues[fieldName.isCompany];

  const isDirtyButMissingDocument =
    hookForm.formState.isDirty &&
    !hookForm.formState.dirtyFields[fieldName.document];

  const currentDocIdValue = hookForm.watch(fieldName.document);
  const [lastUploadedFile, setLastUploadedFile] = React.useState<null | {
    id: string | number;
    filename?: string;
  }>(null);
  const uploadedFilename = React.useMemo(() => {
    if (
      lastUploadedFile?.id !== currentDocIdValue ||
      initialValues[fieldName.document] === currentDocIdValue
    ) {
      return null;
    }
    return lastUploadedFile?.filename ?? null;
  }, [lastUploadedFile, currentDocIdValue, initialValues]);

  React.useEffect(() => {
    return registerDirtyFlag(isFormDirty, onOmitChanges);
  }, [isFormDirty, registerDirtyFlag, onOmitChanges]);

  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const isNameReadOnly =
    overallStatusWithoutDirty === 'accepted' ||
    overallStatusWithoutDirty === 'expiring' ||
    overallStatusWithoutDirty === 'expired';

  if (loading) {
    return null;
  }

  const statusPending = overallStatus === 'pending';
  const isDisabled =
    !isPayoutAddressUpdateEditAllowed || loading || statusPending;

  const statusIndicator = statusPending ? (
    <StatusPending text={t('general:status.InPrufung')} />
  ) : overallStatus === 'accepted' ? (
    <StatusDone text={t('general:status.Verifiziert')} />
  ) : null;

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.BillingAddressSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <HStack w={'full'}>
              <SectionIcon as={icons.AccountBalance} />
              <SectionTitle children={t('payout:headline.Rechnungsadresse')} />
              {statusIndicator}
            </HStack>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}>
          <SectionBody>
            <SectionCenterContainer spacing={4}>
              {overallStatus === 'rejected' && (
                <PaymentRejectedAlert
                  text={
                    // || use intentional, as title can come as "",
                    // which should also fallback to predefined generic title
                    data?.payment?.billingAddress?.rejectReasonTitle ||
                    undefined
                  }
                  noticeModalConfig={{
                    status: 'error',
                    // || use intentional, as title can come as "",
                    // which should also fallback to predefined generic title
                    modalHeading:
                      data?.payment?.billingAddress?.rejectReasonTitle ||
                      t('payout:text.DeineNeueRechnungsadresseWurdeAbgelehnt'),
                    modalTextSlot: t('payout:text.GrundReason', {
                      reason: data?.payment?.billingAddress?.rejectReason ?? '',
                    }),
                  }}
                />
              )}
              {overallStatus === 'expired' && (
                <PaymentExpiredAlert
                  noticeModalConfig={{
                    status: 'error',
                    modalHeading: t('payout:headline.NeuerNachweisNotwendig'),
                    modalTextSlot: t(
                      'payout:text.DamitWirDeineRechnungsadresseVerifizierenKonnenBenotigenWirEinenNeueXX'
                    ),
                  }}
                />
              )}
              <VStack spacing={6} divider={<Divider />} alignItems={'stretch'}>
                {isInitiallyCompany && (
                  <ClearableInputControl
                    label={t('payout:label.Firmenname')}
                    placeholder={t('payout:placeholder.FirmennamenEingeben')}
                    name={fieldName.companyName}
                    isReadOnly={isNameReadOnly}
                    isDisabled={isDisabled}
                  />
                )}
                {!isInitiallyCompany && (
                  <ClearableInputControl
                    label={t('payout:label.Vorname')}
                    placeholder={t('payout:placeholder.VornamenEingeben')}
                    name={fieldName.firstName}
                    isReadOnly={isNameReadOnly}
                    isDisabled={isDisabled}
                  />
                )}
                {!isInitiallyCompany && (
                  <ClearableInputControl
                    label={t('payout:label.Nachname')}
                    placeholder={t('payout:placeholder.NachnamenEingeben')}
                    name={fieldName.lastName}
                    isReadOnly={isNameReadOnly}
                    isDisabled={isDisabled}
                  />
                )}
                <ClearableInputControl
                  label={t('payout:label.Strasse')}
                  placeholder={t('payout:placeholder.StrasseEingeben')}
                  name={fieldName.street}
                  isDisabled={isDisabled}
                />
                <ClearableInputControl
                  label={t('payout:label.Hausnummer')}
                  placeholder={t('payout:placeholder.HausnummerEingeben')}
                  name={fieldName.streetNumber}
                  isDisabled={isDisabled}
                />
                <ClearableInputControl
                  label={t('payout:label.Postleitzahl')}
                  placeholder={t('payout:placeholder.PostleitzahlEingeben')}
                  name={fieldName.postalCode}
                  isDisabled={isDisabled}
                />
                <ClearableInputControl
                  label={t('payout:label.Stadt')}
                  placeholder={t('payout:placeholder.StadtEingeben')}
                  name={fieldName.city}
                  isDisabled={isDisabled}
                />
                <EnumSelectFieldHookForm
                  label={t('payout:label.Land')}
                  name={fieldName.country}
                  isDisabled={isDisabled}
                >
                  <PromotingOptionList {...optionListProps} />
                </EnumSelectFieldHookForm>
                <Box>
                  <FormControl>
                    <FormControlHeaderStack>
                      <FormLabel pointerEvents={'none'} cursor={'default'}>
                        {t('payout:headline.NachweisDerRechnungsadresse')}
                      </FormLabel>
                      <FormHelperText>
                        {t(
                          'payout:text.LadeEinDokumentHochDasDeineAdresseZeigtZBEineEnergierechnungSteuerbeXX'
                        )}
                      </FormHelperText>
                    </FormControlHeaderStack>
                    <MediaPropertiesProvider
                      targetDimensions={{ width: 520, height: 520 }}
                      determineAspectRatioByInput={true}
                      named={t('document:named.Adressnachweis')}
                    >
                      <MediaProvider.FromUploadDocument
                        key={`${onOmitChangesTriggerCount}|${initialValues?.document}`}
                        document={data?.payment?.billingAddress?.document}
                      >
                        <MediaFlowProvider clearReplacementsOnClose={true}>
                          <MediaUploadProvider.ForPaymentDocument
                            fieldName={'paymentBillingAddressDocument'}
                            onUploadSuccess={(id, filename) => {
                              hookForm.setValue(fieldName.document, id, {
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                              setLastUploadedFile({ id, filename });
                              setUploadError(null);
                            }}
                            onUploadStart={() => {
                              setUploadError(null);
                            }}
                            onUploadError={(error) => {
                              setUploadError(error.description);
                            }}
                          >
                            <MediaInputProvider accept={'DEFAULT_FOR_DOCUMENT'}>
                              <VStack alignItems={'stretch'} w={'full'}>
                                <DocumentPickerV3
                                  uploadedFilename={
                                    uploadedFilename ?? undefined
                                  }
                                  isReadOnly={isDisabled}
                                  isNewProofRequired={isDirtyButMissingDocument}
                                  expiresInDays={
                                    documentExpiration.daysTillExpired
                                  }
                                  errorMessage={uploadError ?? undefined}
                                  named={t('document:named.Adressnachweis')}
                                  expiringSoonStatusRender={(
                                    onOpenMediaFlow
                                  ) => (
                                    <DocumentPickerV3Status
                                      icon={icons.Error}
                                      color={'caribbeanGreen.500'}
                                      text={t(
                                        'general:status.LauftInCountTagenAb',
                                        {
                                          count:
                                            documentExpiration.daysTillExpired ??
                                            0,
                                        }
                                      )}
                                      noticeModalConfig={{
                                        status: 'hint',
                                        modalHeading: t(
                                          'general:status.LauftInCountTagenAb',
                                          {
                                            count:
                                              documentExpiration.daysTillExpired ??
                                              0,
                                          }
                                        ),
                                        modalTextSlot: t(
                                          'payout:text.DamitWirDeineRechnungsadresseVerifizierenKonnenBenotigenWirEinenNeueXX'
                                        ),
                                        modalBodyBottomSlot: (
                                          onCloseNoticeModal: () => void
                                        ) => (
                                          <Button
                                            onClick={() => {
                                              onCloseNoticeModal();
                                              onOpenMediaFlow();
                                            }}
                                          >
                                            {t(
                                              'document:button.ErneutHochladen'
                                            )}
                                          </Button>
                                        ),
                                      }}
                                    />
                                  )}
                                  expiredStatusRender={(onOpenMediaFlow) => (
                                    <DocumentPickerV3Status
                                      icon={icons.Error}
                                      color={'error.500'}
                                      text={t(
                                        'general:status.NeuerNachweisNotwendig'
                                      )}
                                      noticeModalConfig={{
                                        status: 'error',
                                        modalHeading: t(
                                          'payout:headline.NeuerNachweisNotwendig'
                                        ),
                                        modalTextSlot: t(
                                          'payout:text.DamitWirDeineRechnungsadresseVerifizierenKonnenBenotigenWirEinenNeueXX'
                                        ),
                                        modalBodyBottomSlot: (
                                          onCloseNoticeModal: () => void
                                        ) => (
                                          <Button
                                            alignSelf={'center'}
                                            onClick={() => {
                                              onCloseNoticeModal();
                                              onOpenMediaFlow();
                                            }}
                                          >
                                            {t(
                                              'document:button.ErneutHochladen'
                                            )}
                                          </Button>
                                        ),
                                      }}
                                    />
                                  )}
                                  newProofRequiredStatusRender={(
                                    onOpenMediaFlow
                                  ) => (
                                    <DocumentPickerV3Status
                                      icon={icons.Error}
                                      color={'error.500'}
                                      text={t(
                                        'general:status.NeuerNachweisNotwendig'
                                      )}
                                      noticeModalConfig={{
                                        status: 'error',
                                        modalHeading: t(
                                          'payout:headline.NeuerNachweisNotwendig'
                                        ),
                                        modalTextSlot: t(
                                          'payout:text.DamitWirDeineRechnungsadresseVerifizierenKonnenBenotigenWirEinenNeueXX'
                                        ),
                                        modalBodyBottomSlot: (
                                          onCloseNoticeModal: () => void
                                        ) => (
                                          <Button
                                            alignSelf={'center'}
                                            onClick={() => {
                                              onCloseNoticeModal();
                                              onOpenMediaFlow();
                                            }}
                                          >
                                            {t(
                                              'document:button.ErneutHochladen'
                                            )}
                                          </Button>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </VStack>
                              <DocumentMediaFlow />
                            </MediaInputProvider>
                          </MediaUploadProvider.ForPaymentDocument>
                        </MediaFlowProvider>
                      </MediaProvider.FromUploadDocument>
                    </MediaPropertiesProvider>
                  </FormControl>
                </Box>
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          <SectionDivider isWidthRestricted />
          <SectionFooter>
            <SectionCenterContainer>
              <VStack gap={6}>
                {isAccountFromCH && <ContactSupportTaxInformationBox />}
                <SubmitChangesButtonWithConfirmation
                  onConfirmedSubmit={(e) => {
                    hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)(e);
                  }}
                  isLoading={hookForm.formState.isSubmitting}
                  isDisabled={
                    !hookForm.formState.isValid ||
                    !hookForm.formState.dirtyFields[fieldName.document]
                  }
                />
              </VStack>
            </SectionCenterContainer>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};

export default PayoutDocumentsAddressSection;

export const ContactSupportTaxInformationBox: React.FC<{
  boxProps?: BoxProps;
}> = ({ boxProps }) => {
  const { t } = useTranslation(['general']);
  return (
    <Box
      border={'1px'}
      borderColor={'steel'}
      borderRadius={'8px'}
      py={4}
      px={2}
      {...boxProps}
    >
      <HStack gap={2}>
        <Icon
          as={icons.VxModelsLogo}
          boxSize={'icon.md'}
          color={'primary.500'}
        />
        <Text fontWeight={'medium'} fontSize={'14px'}>
          {t(
            'general:text.KontaktiereDenSupportWennDuUmsatzsteuerpflichtigBist'
          )}
        </Text>
      </HStack>
    </Box>
  );
};
