import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Mail } from '@campoint/odi-ui-icons';
import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  StatusDone,
  StatusError,
} from '../../../../components/shared/StatusIndicator/StatusIndicator';
import {
  EmailSectionDocument,
  MutationResultFragment,
  useEmailSectionEmailUpdateMutation,
  useEmailSectionQuery,
  useEmailSectionRegistrationUpdateMutation,
  useEmailSectionResendRegistrationMailMutation,
  useEmailSectionResendVerificationMailMutation,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import { extractFromUnion } from '../../../../utils/extractor';
import {
  isMutationResultError,
  mutationResultErrorHandling,
} from '../../../../utils/utils';
import LoadingPage from '../../../LoadingPage/LoadingPage';

const fieldName = {
  currentEmail: 'currentEmail',
  newEmail: 'newEmail',
} as const;

interface FormValues {
  [fieldName.currentEmail]: string;
  [fieldName.newEmail]: string;
}

export const EmailSection: React.FC<{}> = () => {
  const { t } = useTranslation(['accountPage', 'general', 'validation']);

  const { data, loading, error } = useEmailSectionQuery();

  const [
    changeEmailAndSendRegistrationMail,
    { loading: sendRegistrationMailLoading },
  ] = useEmailSectionRegistrationUpdateMutation({
    refetchQueries: [EmailSectionDocument],
  });

  const [resendRegistrationEmail] =
    useEmailSectionResendRegistrationMailMutation({
      refetchQueries: [EmailSectionDocument],
    });

  const [
    changeEmailAndSendVerificationMail,
    { loading: sendVerificationMailLoading },
  ] = useEmailSectionEmailUpdateMutation({
    refetchQueries: [EmailSectionDocument],
  });

  const [resendVerificationMail] =
    useEmailSectionResendVerificationMailMutation({
      refetchQueries: [EmailSectionDocument],
    });

  const mappedData = React.useMemo(() => {
    const fields = extractFromUnion(
      data?.profile?.collection,
      'ProfileCollectionAccountV1'
    )?.fields;

    const emailAddress = data?.account?.contact?.currentEmailAddress;

    const isRegistrationComplete =
      data?.account.isRegistrationComplete ?? false;
    const isEmailAddressVerified =
      data?.account.contact.isCurrentEmailAddressVerified;
    return {
      emailAddress,
      isRegistrationComplete,
      isEmailAddressVerified,
      fields,
    };
  }, [data]);

  const { minLength, maxLength } =
    mappedData?.fields?.accountContactEmailAdress ?? {};

  const getMinLengthValidation = React.useCallback(() => {
    if (typeof minLength !== 'number') {
      return Yup.string();
    }
    return Yup.string().min(
      minLength,
      t('general:field.error.minLength', { minLength }) ?? undefined
    );
  }, [minLength, t]);

  const getMaxLengthValidation = React.useCallback(() => {
    if (typeof maxLength !== 'number') {
      return Yup.string();
    }
    return Yup.string().max(
      maxLength,
      t('general:field.error.maxLength', { maxLength }) ?? undefined
    );
  }, [maxLength, t]);

  const validationSchema = React.useMemo(() => {
    const emailSchema = Yup.string()
      .email(t('validation:error.FalschesFormat') ?? undefined)
      .concat(getMinLengthValidation())
      .concat(getMaxLengthValidation())
      .nullable()
      .transform((value) => (value === '' ? null : value));

    return Yup.object().shape({
      [fieldName.currentEmail]: emailSchema,
      [fieldName.newEmail]: emailSchema.test((value, testContext) => {
        if (value === mappedData.emailAddress) {
          return testContext.createError({
            message: t(
              'accountPage:error.NeueEMailMussSichVonDerAltenUnterscheiden'
            ),
          });
        }
        return true;
      }),
    });
  }, [mappedData, getMinLengthValidation, getMaxLengthValidation, t]);

  const initialValues = React.useMemo(() => {
    return {
      [fieldName.currentEmail]: mappedData.emailAddress ?? '',
      [fieldName.newEmail]: '',
    };
  }, [mappedData]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const changeAndVerifyEmail = React.useCallback(
    async (data: any) => {
      const newEmail = mappedData.isEmailAddressVerified
        ? data[fieldName.newEmail]
        : data[fieldName.currentEmail];
      try {
        let res: Maybe<MutationResultFragment>;
        if (!mappedData.isRegistrationComplete) {
          res = (
            await changeEmailAndSendRegistrationMail({
              variables: {
                email: newEmail,
              },
            })
          ).data?.account?.changeEmailAddressAndSendRegistrationMail;
        } else {
          res = (
            await changeEmailAndSendVerificationMail({
              variables: {
                email: newEmail,
              },
            })
          ).data?.account?.changeEmailAddressAndSendVerificationMail;
        }

        if (isMutationResultError(res)) {
          mutationResultErrorHandling(res);
          return;
        }

        issueChakraToast({
          description: t('accountPage:toast.VerifizierungsEMailWurdeGesendet'),
          status: 'success',
        });
      } catch (error) {
        issueChakraToast({
          description: t('accountPage:toast.EMailKonnteNichtGeandertWerden'),
          status: 'error',
        });
      }
    },
    [
      changeEmailAndSendRegistrationMail,
      changeEmailAndSendVerificationMail,
      mappedData,
      t,
    ]
  );

  const resendMailHandler = React.useCallback(async () => {
    try {
      let res: Maybe<MutationResultFragment>;
      if (!mappedData.isRegistrationComplete) {
        res = (await resendRegistrationEmail()).data?.account
          ?.resendRegistrationMail;
      } else {
        res = (await resendVerificationMail()).data?.account
          ?.resendVerificationMail;
      }

      if (isMutationResultError(res)) {
        mutationResultErrorHandling(res);
        return;
      }

      issueChakraToast({
        description: t('accountPage:toast.EMailWurdeVersendet'),
        status: 'success',
      });
    } catch (error) {
      issueChakraToast({
        description: t('accountPage:toast.EMailKonnteNichtVersendetWerden'),
        status: 'error',
      });
    }
  }, [mappedData, resendRegistrationEmail, resendVerificationMail, t]);

  const emailFormValue = useWatch({
    name: fieldName.currentEmail,
    control: hookForm.control,
  });
  const newEmailFormValue = useWatch({
    name: fieldName.newEmail,
    control: hookForm.control,
  });

  const disableSaveEmail =
    !hookForm.formState.isDirty ||
    (!emailFormValue && !newEmailFormValue) ||
    !hookForm.formState.isValid;

  const disableResendMail =
    hookForm.formState.dirtyFields[fieldName.currentEmail] ||
    !emailFormValue ||
    !hookForm.formState.isValid;

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);
  React.useEffect(() => {
    return registerDirtyFlag(hookForm.formState.isDirty, onOmitChanges);
  }, [
    hookForm,
    hookForm.formState,
    hookForm.formState.isDirty,
    onOmitChanges,
    registerDirtyFlag,
  ]);

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.EmailSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Mail} />
            <SectionTitle children={t('accountPage:headline.EMailAdresse')} />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(changeAndVerifyEmail)}>
          <SectionBody>
            <SectionCenterContainer>
              {(loading || error) && <LoadingPage />}
              {!loading && (
                <VStack spacing={6}>
                  <ClearableInputControl
                    name={fieldName.currentEmail}
                    label={t('accountPage:label.EmailAdresse')}
                    labelTrailingHint={
                      mappedData.isEmailAddressVerified ? (
                        <StatusDone text={t('general:status.Verifiziert')} />
                      ) : (
                        <StatusError
                          text={t('general:status.NichtVerifiziert')}
                        />
                      )
                    }
                    footerInfoText={
                      !mappedData.isEmailAddressVerified && (
                        <HStack mt={3}>
                          <Icon
                            as={icons.Error}
                            color={'coldGray.700'}
                            alignSelf={'start'}
                            boxSize={'icon.sm'}
                          />
                          <Text fontSize={'14px'} fontWeight={'normal'}>
                            {t(
                              'accountPage:text.BitteKlickeAufDenLinkDenDuPerMailErhaltenHast'
                            )}
                          </Text>
                        </HStack>
                      )
                    }
                    placeholder={t(
                      'accountPage:placeholder.EmailAdresseEingeben'
                    )}
                    isReadOnly={mappedData.isEmailAddressVerified}
                  />
                  {mappedData.isEmailAddressVerified && (
                    <ClearableInputControl
                      name={fieldName.newEmail}
                      label={t('accountPage:label.NeueEmailAdresse')}
                      placeholder={t(
                        'accountPage:placeholder.NeueEmailAdresseEingeben'
                      )}
                    />
                  )}
                  {!mappedData.isEmailAddressVerified && (
                    <VStack alignSelf={'start'} alignItems={'start'}>
                      <Heading size={'sm'} textAlign={'left'}>
                        {t('accountPage:text.DuHastKeineEmailErhalten')}
                      </Heading>
                      <Text>
                        {t('accountPage:text.SchauInDeinemSpamOrdnerNachOder')}
                        <chakra.span
                          color={
                            disableResendMail ? 'primary.200' : 'primary.500'
                          }
                          fontWeight={'bold'}
                          cursor={'pointer'}
                          onClick={() => {
                            if (!disableResendMail) {
                              resendMailHandler();
                            }
                          }}
                        >
                          {' '}
                          {t('accountPage:text.EmailErneutSenden')}
                        </chakra.span>
                      </Text>
                    </VStack>
                  )}
                  <Button
                    alignSelf={'center'}
                    variant={'solid'}
                    children={t('accountPage:button.AnderungSpeichern')}
                    type="submit"
                    isDisabled={disableSaveEmail}
                    isLoading={
                      sendRegistrationMailLoading || sendVerificationMailLoading
                    }
                  />
                </VStack>
              )}
            </SectionCenterContainer>
          </SectionBody>
        </form>
      </FormProvider>
    </Section>
  );
};
