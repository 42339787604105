import {
  Icon,
  InputGroup,
  InputLeftElement,
  Select,
  SelectProps,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
  Wrap,
  forwardRef,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { useField } from 'formik';
import React, { ChangeEvent } from 'react';

import { BaseProps, FormControl } from '../FormControl';
import {
  MultiSelectOption,
  MultiSelectOptionList,
} from './MultiSelectOptionList';

export type MultiSelectControlProps = BaseProps & {
  icon?: EmotionIcon;
  emptyValue?: string | null;
  optionList: MultiSelectOption[];
  selectProps?: SelectProps;
};

const SelectedTag: React.FC<TagProps> = (props) => (
  <Tag variant="outline" size={'lg'} fontSize={'md'} as={'li'} {...props} />
);

const alphabeticalLabel = (a: MultiSelectOption, b: MultiSelectOption) =>
  a.label.toLowerCase().localeCompare(b.label.toLowerCase());

export const MultiSelectControl = forwardRef<MultiSelectControlProps, 'select'>(
  (props, ref) => {
    const {
      name,
      label,
      selectProps,
      emptyValue = null,
      optionList,
      isDisabled,
      isReadOnly,
      icon,
      ...rest
    } = props;

    const [field, , fieldHelper] = useField<string[] | null>(name);
    const currentValue = React.useMemo(() => {
      return field.value ?? [];
    }, [field.value]);

    const onChange = React.useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const value =
          selectedValue === emptyValue
            ? [emptyValue]
            : [...(currentValue ?? []), selectedValue]?.filter(
                (v) => v !== emptyValue
              );
        fieldHelper.setTouched(true);
        field.onChange({
          target: {
            name,
            value,
          },
        });
      },
      [name, field, currentValue, fieldHelper, emptyValue]
    );

    const emitRemoveOnChange = React.useCallback(
      (valueToRemove: string) => {
        fieldHelper.setTouched(true);
        field.onChange({
          target: {
            name,
            value: currentValue?.filter((v) => v !== valueToRemove) ?? [],
          },
        });
      },
      [currentValue, field, fieldHelper, name]
    );

    const availableOptions = React.useMemo(
      () =>
        optionList
          .filter((o) => !field.value?.includes(o.value) ?? true)
          .sort(alphabeticalLabel),
      [optionList, field.value]
    );

    const selectedElements = React.useMemo(() => {
      if ((currentValue?.length ?? 0) <= 0) {
        return null;
      }

      const selectedOptions =
        optionList
          .filter((o) => currentValue.includes(o.value))
          .sort(alphabeticalLabel) ?? [];

      return (
        <>
          {selectedOptions.map((selectedOption) => (
            <SelectedTag key={selectedOption.label}>
              <TagLabel color={'gray.900'} borderColor={'gray.900'}>
                {selectedOption.label}
              </TagLabel>
              <TagCloseButton
                color={'gray.900'}
                isDisabled={isDisabled || isReadOnly}
                onClick={() => emitRemoveOnChange(selectedOption.value)}
              />
            </SelectedTag>
          ))}
        </>
      );
    }, [currentValue, optionList, isDisabled, isReadOnly, emitRemoveOnChange]);

    return (
      <FormControl
        name={name}
        label={label}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        {...rest}
      >
        <InputGroup>
          {icon && <InputLeftElement children={<Icon as={icon} />} />}
          <Select
            ref={ref}
            {...selectProps}
            sx={icon && { paddingInlineStart: 10 }}
            onChange={onChange}
            pointerEvents={isReadOnly ? 'none' : 'all'}
          >
            <MultiSelectOptionList options={availableOptions} />
          </Select>
        </InputGroup>

        {selectedElements && <Wrap mt={'4'}>{selectedElements}</Wrap>}
      </FormControl>
    );
  }
);
