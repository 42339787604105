import { FormikContextType, FormikProvider } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InputList } from '../../../../../../components/Spacer/InputsList/InputList';
import { ClearableInputControl } from '../../../../../../components/shared/FormikFormElements';
import { useWizardPayoutBankDataBicByIbanLazyQuery } from '../../../../../../generated/graphql';
import { useDebounce } from '../../../../../../hooks/useDebounce';
import { WPBankData } from '../../WizardPayoutBankDataTypes';

type WizardPayoutBankDataBankTransferTabProps = {
  form: FormikContextType<WPBankData.BankTransferFormValues>;
  autoQueryBicEnabled: boolean;
  isIbanEnforced?: boolean;
};

const WizardPayoutBankDataBankTransferTab: React.FC<
  WizardPayoutBankDataBankTransferTabProps
> = ({ form, autoQueryBicEnabled, isIbanEnforced }) => {
  const { t } = useTranslation(['payout']);

  const debouncedIbanInput = useDebounce(
    form.values[WPBankData.ibanInputName],
    250
  );

  const [getBicByIban] = useWizardPayoutBankDataBicByIbanLazyQuery({
    onCompleted: (data) => {
      if (data?.finances?.bicByIban) {
        form.setFieldValue(WPBankData.bicInputName, data?.finances?.bicByIban);
      }
    },
  });

  useEffect(() => {
    if (!autoQueryBicEnabled) {
      return;
    }

    // shortest possible iban is 15 chars for norway
    // @see https://www.iban.com/structure
    if (debouncedIbanInput.length < 15) {
      return;
    }

    getBicByIban({
      variables: {
        iban: debouncedIbanInput,
      },
    });
  }, [debouncedIbanInput, autoQueryBicEnabled, getBicByIban]);

  return (
    <FormikProvider value={form}>
      <InputList>
        <ClearableInputControl
          name={WPBankData.accountHolderInputName}
          label={t('payout:label.Kontoinhaber')}
          placeholder={t('payout:placeholder.NamenEingeben')}
          inputProps={{
            placeholder: t('payout:placeholder.NamenEingeben'),
          }}
        />
        <ClearableInputControl
          name={WPBankData.ibanInputName}
          label={
            isIbanEnforced
              ? t('payout:label.IBAN')
              : t('payout:label.Kontonummer')
          }
          placeholder={
            isIbanEnforced
              ? t('payout:placeholder.IBANEingeben')
              : t('payout:placeholder.KontonummerEingeben')
          }
          inputProps={{
            placeholder: isIbanEnforced
              ? t('payout:placeholder.IBANEingeben')
              : t('payout:placeholder.KontonummerEingeben'),
          }}
        />
        <ClearableInputControl
          name={WPBankData.bicInputName}
          label={
            isIbanEnforced
              ? t('payout:label.BIC')
              : t('payout:label.BICSWIFTCode')
          }
          placeholder={
            isIbanEnforced
              ? t('payout:placeholder.BICEingeben')
              : t('payout:placeholder.BICSWIFTCodeEingeben')
          }
          inputProps={{
            placeholder: isIbanEnforced
              ? t('payout:placeholder.BICEingeben')
              : t('payout:placeholder.BICSWIFTCodeEingeben'),
          }}
        />
      </InputList>
    </FormikProvider>
  );
};

export default WizardPayoutBankDataBankTransferTab;
