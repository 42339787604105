// Base on https://github.com/kgnugur/formik-chakra-ui
import { Switch, SwitchProps, forwardRef } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { BaseProps, FormControl } from '../FormControl';

export type SwitchControlProps = BaseProps & { switchProps?: SwitchProps };

export const SwitchControl = forwardRef<SwitchControlProps, 'input'>(
  (props, ref) => {
    const { name, label, switchProps, children, ...rest } = props;
    const [field] = useField(name);

    return (
      <FormControl name={name} label={label} {...rest}>
        {children ?? <Switch {...field} {...switchProps} ref={ref} />}
      </FormControl>
    );
  }
);

export default SwitchControl;
