import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { PaymentsOutlined } from '@campoint/odi-ui-icons';
import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import React, { Fragment } from 'react';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { useTranslation } from 'react-i18next';
import { SharingInfosEnum, useGetPhoneTelegramSharingStatisticsQuery } from '../../../../generated/graphql';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { DateTime } from 'luxon';

type DetailedStatisticsTelegramIncomeProps = {};

export const DetailedStatisticsTelegramIncome: React.FC<
  DetailedStatisticsTelegramIncomeProps
> = () => {
  const { t } = useTranslation(['statisticsPage']);
  const IncomeText: React.FC<{ text: string }> = ({ text }) => {
    return <Text flex={1} fontWeight={'normal'} fontSize={'sm'} textAlign={'left'}>{text}</Text>
  }

  const { data, loading } = useGetPhoneTelegramSharingStatisticsQuery({ variables: { type: SharingInfosEnum.Telegram } });

  const sharingInfo = React.useMemo(() => data?.sharing?.informations, [data]);

  const currentDate = DateTime.now().toFormat('MM / yyyy');

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow>
            <SectionIcon as={PaymentsOutlined} />
            <SectionTitle flex={1}>{t('header.Verdienst')}</SectionTitle>
            <Text alignSelf={'center'} color={'darkSteel'} fontWeight={'medium'} fontSize={'xs'}>{currentDate}</Text>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          {loading ? <AbsoluteFullCenterLoadingSpinner /> : (
            <VStack
              gap={4}
            >
              <Box p={4} w={'full'} border={'1px'} borderColor={'steel'} borderRadius={'8px'} >
                <HStack m={4} minH={'20px'} >
                  <Text flex={1} fontWeight={'medium'}>{t('text.Service')}</Text>
                  <Text flex={1} fontWeight={'medium'}>{t('text.Verdienst')}</Text>
                </HStack>
                <Divider size={'s'} color={'steel'} />
                {sharingInfo?.map((info, index) => {

                  return (
                    <Fragment key={index}>
                      <HStack m={4} minH={'20px'} key={index} >
                        <IncomeText text={info?.label ?? ''} />
                        <IncomeText text={info?.text ?? ''} />
                      </HStack>
                      <Divider size={'s'} color={'steel'} />
                    </Fragment>
                  )
                }
                )}
                <Text textAlign={'center'} fontWeight={'normal'} color={'darkSteel'} fontSize={'xs'} px={4} py={6}>
                  {t('text.SieheHilfeSupport')}
                </Text>
              </Box>
            </VStack>
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};