import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FormControlHeaderStack } from '../FormControlHeaderStack/FormControlHeaderStack';

export const EnumSelectFieldHookForm: React.FC<
  {
    name: string;
    label: React.ReactNode;
    helperText?: React.ReactNode;
    children: React.ReactNode;
  } & Partial<SelectProps>
> = ({ name, label, helperText, children, ...rest }) => {
  const {
    register,
    formState: { errors, touchedFields, isSubmitted },
  } = useFormContext();

  const currentSelectedValue = useWatch({ name });
  const isCurrentValuePlaceholder = currentSelectedValue === '';

  const error = errors[name];
  const isInvalid = !!error && (touchedFields[name] || isSubmitted);
  return (
    <FormControl isInvalid={isInvalid}>
      <FormControlHeaderStack>
        <FormLabel children={label} />
        {helperText && <FormHelperText children={helperText} />}
      </FormControlHeaderStack>
      <InputGroup size="md" width={'full'}>
        <Select
          _focus={{ color: 'coldGray.900' }}
          {...register(name)}
          {...rest}
          color={isCurrentValuePlaceholder ? 'gray.200' : undefined}
          _disabled={{
            boxShadow: 'none',
            background: 'coldGray.100',
            color: 'gray.500',
            opacity: 1,
          }}
        >
          {children}
        </Select>
      </InputGroup>
      {isInvalid && (
        <Box minH={5} mt={1}>
          <FormErrorMessage>{error?.message as string}</FormErrorMessage>
        </Box>
      )}
    </FormControl>
  );
};
