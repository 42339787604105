import { ApolloError } from '@apollo/client';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { FeatureName, useFeature } from '../../../flags';
import {
  GetVideoLibraryPhotoUploadListDocument,
  Maybe,
  MediaPictureInListFragment,
  PhotoalbumFragment,
  PhotoalbumReleasedStatusEnum,
  useGetPhotoLibraryUploadListEntryDetailsLazyQuery,
  useGetPhotoLibraryUploadListEntryLazyQuery,
  useVideoLibraryDeletePhotoMutation,
  useVideoLibraryUpdatePhotoAlbumStatusMutation,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { VideoLibraryPhotoUploadListEntryModal } from '../../../pages/VideoLibraryPage/VideoLibraryPhotoUploadListEntryModal';
import Logger from '../../../utils/Logger';
import { useAuth } from '../../AuthProvider';

export type OnDeletePhotoType = {
  albumId?: number;
  pictureId?: number;
};

export type OnUpdatePhotoType = {
  status?: PhotoalbumReleasedStatusEnum;
};

interface VideoLibraryPhotoModalContext {
  isOpen: boolean;
  photosData: Maybe<MediaPictureInListFragment>[];
  initialLoading: boolean;
  photosDataLoading: boolean;
  photosDataError: ApolloError | undefined;
  hasNextPage?: boolean;
  photoAlbum: PhotoalbumFragment;
  albumId: number | null;
  actions: {
    openModal: (albumId: number | null) => void;
    closeModal: () => void;
    onDelete: ({ albumId, pictureId }: OnDeletePhotoType) => void;
    onUpdate: ({ status }: OnUpdatePhotoType) => void;
    loadMore: () => void;
  };
}

export const [
  ,
  useVideoLibraryPhotoModalContext,
  videoLibraryPhotoModalContext,
] = createContext<VideoLibraryPhotoModalContext>({
  name: 'videoLibraryPhotoModalContext',
  errorMessage:
    'useVideoLibraryPhotoModalContext: `videoLibraryPhotoModalContext` is undefined. Seems you forgot to wrap component within the Provider',
  strict: true,
});

export const VideoLibraryPhotoModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation(['videoLibrary', 'general', 'photoEditModal']);
  const [deletePhoto] = useVideoLibraryDeletePhotoMutation();
  const [updateStatus] = useVideoLibraryUpdatePhotoAlbumStatusMutation();
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [photos, setPhotos] = React.useState<
    Maybe<MediaPictureInListFragment>[]
  >([]);

  /**
   * Modal handling
   */
  const [albumId, setAlbumId] = React.useState<number | null>(null);

  /**
   * Get Upload List Photo data
   */
  const fetchMorePhotosCount = 10;

  const [refetchPhoto, { data, loading, error, fetchMore }] =
    useGetPhotoLibraryUploadListEntryLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        Logger.error(error);
        issueChakraToast({
          status: 'error',
          description: t('general:toast.DatenKonntenNichtGeladenWerden'),
        });
        onClose();
      },
      notifyOnNetworkStatusChange: true,
    });

  const hasNextPage = React.useMemo(
    () => data?.media?.photoalbums?.pictureList?.pageInfo?.hasNextPage,
    [data?.media?.photoalbums?.pictureList?.pageInfo?.hasNextPage]
  );

  const loadMorePhotos = React.useCallback(() => {
    if (hasNextPage && !error) {
      const lastPhoto =
        data?.media?.photoalbums?.pictureList.edges[
          data?.media?.photoalbums?.pictureList.edges.length - 1
        ];
      fetchMore({
        variables: {
          count: fetchMorePhotosCount,
          after: lastPhoto?.cursor ?? '',
        },
      });
    }
  }, [
    data?.media?.photoalbums?.pictureList.edges,
    error,
    fetchMore,
    hasNextPage,
  ]);

  /**
   * Get photo detail data
   */

  const [refetchPhotoDetails, { data: detailData }] =
    useGetPhotoLibraryUploadListEntryDetailsLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        Logger.error(error);
        issueChakraToast({
          status: 'error',
          description: t('general:toast.DatenKonntenNichtGeladenWerden'),
        });
      },
      notifyOnNetworkStatusChange: true,
    });

  const photoAlbum = React.useMemo(
    () =>
      (detailData?.media?.photoalbums?.photoalbum ?? {}) as PhotoalbumFragment,
    [detailData?.media?.photoalbums?.photoalbum]
  );

  /**
   * Load initial data since we dont have the albumId on mount
   */
  const initialQuery = React.useCallback(() => {
    if (!videoLibraryFeatureFlag) return;
    refetchPhoto({
      variables: {
        albumId: Number(albumId),
        after: '',
        first: fetchMorePhotosCount,
      },
    });
    refetchPhotoDetails({
      variables: {
        albumId: Number(albumId),
      },
    });
  }, [albumId, refetchPhoto, refetchPhotoDetails, videoLibraryFeatureFlag]);

  const deletePhotoListEntry = (pictureId: number) => {
    setPhotos((prevPhotos) =>
      prevPhotos.filter((photo) => photo?.umpId !== pictureId)
    );
  };

  const onDelete = React.useCallback(
    ({ albumId, pictureId }: OnDeletePhotoType) => {
      if (!albumId || !pictureId) return;
      try {
        deletePhoto({
          variables: {
            albumId: albumId,
            pictureId: pictureId,
          },
          onCompleted: () => {
            deletePhotoListEntry(pictureId);
            issueChakraToast({
              status: 'success',
              description: t('videoLibrary:toast.FotoErfolgreichGeloscht'),
            });
          },
        });
      } catch (e) {
        Logger.error(e);
        issueChakraToast({
          description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
          status: 'error',
        });
      }
    },
    [deletePhoto, t]
  );

  const onUpdate = React.useCallback(
    ({ status }: OnUpdatePhotoType) => {
      if (!albumId || !status) return;
      try {
        updateStatus({
          variables: {
            albumId: albumId,
            status: status,
          },
          onCompleted: () => {
            issueChakraToast({
              status: 'success',
              description: t('photoEditModal:toast.AlbumErfolgreichGeandert'),
            });
            onClose();
          },
          refetchQueries: [GetVideoLibraryPhotoUploadListDocument],
        });
      } catch (e) {
        Logger.error(e);
        issueChakraToast({
          description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
          status: 'error',
        });
      }
    },
    [albumId, t, updateStatus]
  );

  const onClose = () => {
    setAlbumId(null);
  };

  React.useEffect(() => {
    if (!albumId) return;

    if (isAuthenticated) {
      initialQuery();
    }
  }, [albumId, initialQuery, isAuthenticated]);

  React.useEffect(() => {
    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading]);

  React.useEffect(() => {
    const fetchedPhotos = (data?.media.photoalbums.pictureList.edges.map(
      (edge) => edge?.node
    ) ?? []) as Maybe<MediaPictureInListFragment>[];
    if (fetchedPhotos) {
      setPhotos(fetchedPhotos);
    }
  }, [data?.media.photoalbums.pictureList.edges]);

  const actions: VideoLibraryPhotoModalContext['actions'] =
    React.useMemo(() => {
      return {
        openModal: (albumId) => setAlbumId(albumId),
        closeModal: onClose,
        onDelete,
        onUpdate,
        loadMore: loadMorePhotos,
      };
    }, [loadMorePhotos, onDelete, onUpdate]);

  const context: VideoLibraryPhotoModalContext = React.useMemo(() => {
    return {
      isOpen: !!albumId,
      photosData: photos,
      initialLoading: initialLoading,
      photosDataLoading: loading,
      photosDataError: error,
      hasNextPage,
      photoAlbum: photoAlbum,
      albumId,
      actions,
    };
  }, [
    albumId,
    photos,
    initialLoading,
    loading,
    error,
    hasNextPage,
    photoAlbum,
    actions,
  ]);

  return (
    <videoLibraryPhotoModalContext.Provider value={context}>
      {children}
      <VideoLibraryPhotoUploadListEntryModal />
    </videoLibraryPhotoModalContext.Provider>
  );
};
