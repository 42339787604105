/**
 * Uses codepoint split to count characters
 *
 * @example
 *   👍 = 1 Buchstabe
 *   👍🏻 = 2 Buchstaben
 *   👨‍👩‍👧‍👦 = 7 Buchstaben
 * @param text
 */
export function stringCharCount(text?: string | null): number {
  if (!text) {
    return 0;
  }

  return Array.from(text).length;
}
