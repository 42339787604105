import { FILE_STATES } from '@rpldy/shared';
import {
  Batch,
  BatchItem,
  CreateOptions,
  UPLOADER_EVENTS,
  UploaderEnhancer,
} from '@rpldy/uploady';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { UrlFragment } from '../../../components/Layout/UrlFragmentScrollToTarget';
import {
  GetVideoLibraryTusUploadDataQuery,
  VideoTypeEnum,
  useGetVideoLibraryTusUploadDataLazyQuery,
} from '../../../generated/graphql';
import useIsMounted from '../../../hooks/useIsMounted';
import Logger from '../../../utils/Logger';
import {
  ImageMetaData,
  VideoMetaData,
  loadMediaMetaData,
} from '../../../utils/media/mediaMetaData';
import { unusedTabId } from './VideoLibraryUploadyProvider';

export type TusUploadEntry = {
  id: string;
  status: FILE_STATES;
  progress: number;
  uploadRequestId: string | null;
  metaData: ImageMetaData | VideoMetaData | null;
  originalName: string;
  error?: string;
  rejectionReason?: string;
  type?: VideoTypeEnum;
  umaId?: string;
};

type TusUploadEntryUpdate = Partial<TusUploadEntry> &
  Required<Pick<TusUploadEntry, 'id'>>;

export function useTusVideoUploadEnhancer(
  tabId: string,
  setTabId: (newState: string | null) => void
) {
  const isMounted = useIsMounted();
  const history = useHistory();
  const { t } = useTranslation(['videoLibrary']);
  const [getTusUploadData, { loading: tusUploadDataLoading }] =
    useGetVideoLibraryTusUploadDataLazyQuery();
  const [tusUploads, setTusUploads] = React.useState<TusUploadEntry[]>([]);
  const tusUploadDataCache = React.useRef(
    new Map<string, GetVideoLibraryTusUploadDataQuery>()
  );

  const hasUnfinishedUploads = React.useMemo(() => {
    return tusUploads.some(
      (upload) =>
        upload.status === FILE_STATES.UPLOADING ||
        upload.status === FILE_STATES.FINISHED
    );
  }, [tusUploads]);

  const updateSingleTusUpload = React.useCallback(
    (update: TusUploadEntryUpdate) => {
      if (update.error) {
        issueChakraToast({
          description: update.error,
          status: 'error',
        });
      }
      if (!isMounted()) {
        return;
      }
      setTusUploads((prevState) => {
        const foundIndex = prevState.findIndex((e) => e.id === update.id);
        return [
          ...prevState.slice(0, foundIndex),
          {
            ...prevState[foundIndex],
            ...update,
          },
          ...prevState.slice(foundIndex + 1),
        ];
      });
    },
    [setTusUploads, isMounted]
  );

  const updateMultipleTusUploads = React.useCallback(
    (updates: TusUploadEntryUpdate[]) => {
      if (!isMounted()) {
        return;
      }
      setTusUploads((prevState) => {
        return prevState.map((prevEntry) => {
          const foundUpdate = updates.find(
            (update) => update.id === prevEntry.id
          );
          if (foundUpdate) {
            return { ...prevEntry, ...foundUpdate };
          } else {
            return prevEntry;
          }
        });
      });
    },
    [setTusUploads, isMounted]
  );

  const addMultipleTusUploads = React.useCallback(
    (addition: TusUploadEntry[]) => {
      if (!isMounted()) {
        return;
      }
      setTusUploads((prevState) => {
        //when a retry happens multiple upload elements can have the same id
        // -> old upload needs to be cleaned out
        /**
         * Before Retry
         * batchId: "batch-1"
         * id: "batch-1.item-1"
         *
         * After Retry
         * batchId: "batch-2"
         * id: "batch-1.item-1"
         */

        const newIds = addition.map((entry) => entry.id);

        return [
          ...addition,
          ...prevState.filter(
            (filterEntry) => !newIds?.includes(filterEntry.id)
          ),
        ];
      });
    },
    [setTusUploads, isMounted]
  );

  const filterTusUploads = React.useCallback(
    (filterFn: (entry: TusUploadEntry) => boolean) => {
      if (!isMounted()) {
        return;
      }
      setTusUploads((prevState) => {
        return prevState.filter(filterFn);
      });
    },
    [setTusUploads, isMounted]
  );

  const enhancer: UploaderEnhancer = React.useMemo(() => {
    return (uploader) => {
      uploader.on(
        UPLOADER_EVENTS.BATCH_ADD,
        (batch: Batch, options: CreateOptions) => {
          if (tabId !== unusedTabId) {
            //set hash
            setTabId(unusedTabId);
            history.replace({
              hash: UrlFragment.UploadItem,
            });
          }
          const batchItemsMetadataLoadingPromises = batch.items.map((item) => {
            return loadMediaMetaData(item.file as File)
              .then((metaData) => {
                return { id: item.id, metaData } as TusUploadEntryUpdate;
              })
              .catch((error) => {
                Logger.error(error);
                return { id: item.id, metaData: null } as TusUploadEntryUpdate;
              });
          });

          Promise.all(batchItemsMetadataLoadingPromises)
            .then((entries) => {
              updateMultipleTusUploads(entries);
            })
            .then(() => {
              uploader.upload();
            })
            .catch((error) => {
              Logger.error(error);
            });

          const type = options?.params?.type as VideoTypeEnum;
          const umaId = options?.params?.albumId?.toString();

          addMultipleTusUploads(
            batch.items.map<TusUploadEntry>((item) => {
              return {
                id: item.id,
                originalName: item.file.name,
                status: item.state,
                progress: 0,
                metaData: null,
                uploadRequestId: null,
                type,
                umaId,
              };
            })
          );
        }
      );

      uploader.on(UPLOADER_EVENTS.ITEM_ABORT, (item: BatchItem) => {
        updateSingleTusUpload({
          id: item.id,
          status: item.state,
        });
      });

      uploader.on(UPLOADER_EVENTS.ITEM_ERROR, (item: BatchItem) => {
        if (
          typeof item?.uploadResponse === 'string' &&
          item.uploadResponse?.includes('The string to be encoded')
        ) {
          updateSingleTusUpload({
            id: item.id,
            status: item.state,
            error: t('videoLibrary:uploadErrors.DateinameNichtZulassig'),
          });
          return false;
        }
        if (typeof item?.uploadResponse === 'object') {
          updateSingleTusUpload({
            id: item.id,
            status: item.state,
            error:
              item.uploadResponse?.reason ??
              t('videoLibrary:uploadErrors.UnbekannterFehler'),
          });
          return false;
        }

        updateSingleTusUpload({
          id: item.id,
          status: item.state,
          error: t('videoLibrary:uploadErrors.UnbekannterFehler'),
        });
      });

      uploader.on(UPLOADER_EVENTS.ITEM_CANCEL, (item: BatchItem) => {
        updateSingleTusUpload({
          id: item.id,
          status: item.state,
        });
      });

      uploader.on(UPLOADER_EVENTS.ITEM_FINISH, (item: BatchItem) => {
        const isDuplicateFile =
          item.uploadResponse.message === 'TUS server has file';

        if (isDuplicateFile) {
          updateSingleTusUpload({
            id: item.id,
            status: FILE_STATES.ERROR,
            error: t('videoLibrary:uploadErrors.KeineDuplikateErlaubt'),
          });

          return;
        }
        updateSingleTusUpload({
          id: item.id,
          status: item.state,
        });
      });

      uploader.on(UPLOADER_EVENTS.ITEM_FINALIZE, (item: BatchItem) => {
        updateSingleTusUpload({
          id: item.id,
          status: item.state,
        });
      });

      uploader.on(UPLOADER_EVENTS.ITEM_PROGRESS, (item: BatchItem) => {
        updateSingleTusUpload({
          id: item.id,
          status: item.state,
          progress: item.completed,
        });
      });

      uploader.on(
        UPLOADER_EVENTS.REQUEST_PRE_SEND,
        async ({ items, options }) => {
          //currently works with only one item beeing uploaded
          const itemId = items[0].id;
          const type = options?.params?.type as VideoTypeEnum;
          const albumId = Number(options?.params?.albumId);

          if (!tusUploadDataCache.current.has(itemId)) {
            const { data } = await getTusUploadData({
              variables: { type, albumId },
            });
            if (data) tusUploadDataCache.current.set(itemId, data);
          }

          const chachedTusUploadData = tusUploadDataCache.current.get(itemId);

          updateMultipleTusUploads(
            items.map((item: any) => ({
              id: item.id,
              status: item.state,
              uploadRequestId:
                chachedTusUploadData?.media.videos.tusUploadData?.metaData
                  ?.requestId ?? null,
              umaId:
                chachedTusUploadData?.media.videos.tusUploadData?.metaData
                  ?.umaId,
            }))
          );
          const returnObject = {
            options: {
              destination: {
                url: chachedTusUploadData?.media.videos.tusUploadData?.url,
                headers: {
                  ...options?.destination?.headers,
                },
              },
              params: {
                ...chachedTusUploadData?.media.videos.tusUploadData?.metaData,
                filename: items[0].file.name,
                filetype: items[0].file.type,
              },
            },
          };
          return returnObject;
        }
      );

      uploader.on(
        UPLOADER_EVENTS.ITEM_START,
        async (item: BatchItem, options: CreateOptions) => {
          const metaData = await loadMediaMetaData(item.file as File).catch(
            (error) => {
              Logger.error(error);
              return null;
            }
          );
          const type = options?.params?.type as VideoTypeEnum;
          const isForFeed = type === VideoTypeEnum.VideoFeed;

          //for feed videos we want to allow every video type
          if (isForFeed) {
            return true;
          }

          //for other types we want to check the video metadata (shop, contests, etc.)
          if (!metaData) {
            updateSingleTusUpload({
              id: item.id,
              status: FILE_STATES.ERROR,
              error: t('videoLibrary:uploadErrors.DateiformatNichtZulassig'),
            });
            return false;
          }

          if (metaData.type !== 'video') {
            updateSingleTusUpload({
              id: item.id,
              status: FILE_STATES.ERROR,
              error: t('videoLibrary:uploadErrors.DateiformatNichtZulassig'),
            });
            return false;
          }

          //duration in seconds
          const minDuration = type === VideoTypeEnum.VideoContest ? 180 : 60;
          if (metaData.duration < minDuration) {
            const min = Math.floor(minDuration / 60);
            const sec = minDuration % 60;
            updateSingleTusUpload({
              id: item.id,
              status: FILE_STATES.ERROR,
              error: t(
                'videoLibrary:uploadErrors.VideolangeMussMindestens1MinutenBetragen',
                {
                  min: sec === 0 ? min : `${min}:${sec}`,
                }
              ),
            });
            return false;
          }

          if (metaData.duration > 12600) {
            updateSingleTusUpload({
              id: item.id,
              status: FILE_STATES.ERROR,
              error: t(
                'videoLibrary:uploadErrors.VideolangeDarfMaximal330StundenBetragen'
              ),
            });
            return false;
          }

          if (metaData.sizeInBytes > 5e10) {
            updateSingleTusUpload({
              id: item.id,
              status: FILE_STATES.ERROR,
              error: t('videoLibrary:uploadErrors.Maximal50GBErlaubt'),
            });
            return false;
          }
          return true;
        }
      );

      return uploader;
    };
  }, [
    addMultipleTusUploads,
    getTusUploadData,
    history,
    setTabId,
    t,
    tabId,
    updateMultipleTusUploads,
    updateSingleTusUpload,
  ]);

  return [
    enhancer,
    tusUploads,
    filterTusUploads,
    hasUnfinishedUploads,
    tusUploadDataLoading,
  ] as const;
}
