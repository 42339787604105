import { ApolloError } from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core';
import { useDisclosure } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import {
  CreateReplyCommentMutation,
  GetPhotoCommentingProviderDocument,
  MediaCommentFragment,
  PhotoalbumFragment,
  PhotoalbumTypeEnum,
  useCreateReplyCommentMutation,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { useMediaModalQueryParams } from '../../../hooks/useQueryParamState';
import { PhotoCommentModal } from '../../../pages/VideoLibraryPage/VideoLibraryPhotoCommentingModal';
import Logger from '../../../utils/Logger';
import { useAuth } from '../../AuthProvider';
import { useFetchAndLoadMoreComments } from '../useFetchAndLoadMoreComments';
import { useFetchPhotoalbum } from '../useFetchPhotoalbum';

export type PhotoCommentingContext = {
  readonly isLoading: boolean;
  readonly photoalbum: Maybe<PhotoalbumFragment> | undefined;
  readonly isDirty: boolean;
  readonly comments: MediaCommentFragment[];
  readonly commentsLoading: boolean;
  readonly commentsError: ApolloError | undefined;
  readonly loadMoreComments: () => void;
  readonly isOpen: boolean;
  readonly action: {
    readonly showModal: (albumId: string) => void;
    readonly closeModal: () => void;
    readonly addReply: (
      text: string,
      commentId: number
    ) => Promise<FetchResult<CreateReplyCommentMutation>>;
    readonly registerOpenComment: () => void;
  };
};
export const [, usePhotoCommenting, photoCommenting] =
  createContext<PhotoCommentingContext>({
    name: 'PhotoCommentingProvider',
    errorMessage:
      'useMedia: `PhotoCommentingProvider` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });
type PhotoCommentingProviderOptions = {
  children?: React.ReactNode;
};

const photoCommentingModal = 'photoCommenting';

const Provider: React.FC<PhotoCommentingProviderOptions> = ({ children }) => {
  const { t } = useTranslation(['general']);
  // Can be used to block navigation from unsaved changes
  const [isDirty, setIsDirty] = React.useState(false);
  // Note: Might be beneficial to have a Map holding additional metadata
  const openCreateAndEditSet = React.useRef(new Set());

  const [modal, albumId, setVideoQueryParams] = useMediaModalQueryParams();
  const { onClose } = useDisclosure();
  const { isAuthenticated } = useAuth();
  const isPhotoCommentingModal = modal === photoCommentingModal;

  /**
   * Get Upload List Entry data
   */
  const { photoalbum, loading } = useFetchPhotoalbum(
    Number(albumId),
    onClose,
    !isPhotoCommentingModal
  );

  /**
   * Get Comments data
   */
  const fetchMoreCommentCount = 10;

  const {
    getCommentingData,
    comments,
    commentsLoading,
    commentsError,
    loadMoreComments,
  } = useFetchAndLoadMoreComments(fetchMoreCommentCount, onClose);

  /**
   * Load initial comment data
   */
  const initialQuery = React.useCallback(() => {
    getCommentingData({
      variables: {
        albumId: Number(albumId),
        first: fetchMoreCommentCount,
        cursor: '',
      },
    });
  }, [albumId, getCommentingData]);

  /**
   * Reply to comments
   */
  const [addReplyMutation] = useCreateReplyCommentMutation({
    refetchQueries: [GetPhotoCommentingProviderDocument],
    onError: (error) => {
      Logger.error(error);
      issueChakraToast({
        description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        status: 'error',
      });
    },
  });

  const addReply = React.useCallback(
    async (text: string, commentId: number) =>
      addReplyMutation({
        variables: {
          text,
          commentId,
        },
      }),
    [addReplyMutation]
  );

  /**
   * let contained un-submitted comments creates / edits register in this provider,
   * so we know about how much of them currently exist
   *
   * @see isDirty
   */
  const registerOpenComment = React.useCallback(() => {
    const openQuestion = openCreateAndEditSet.current;
    // Simple solution to have a unique object reference,
    // that can be handled as such in a Set
    const uniqueObject = {};
    if (openQuestion) {
      openQuestion.add(uniqueObject);
    }
    setIsDirty(openCreateAndEditSet.current.size > 0);
    return () => {
      openQuestion.delete(uniqueObject);
      setIsDirty(openCreateAndEditSet.current.size > 0);
    };
  }, [openCreateAndEditSet, setIsDirty]);

  React.useEffect(() => {
    if (!albumId || !photoalbum) return;

    if (
      photoalbum.type === PhotoalbumTypeEnum.Shop &&
      isPhotoCommentingModal &&
      isAuthenticated
    ) {
      initialQuery();
    } else {
      setVideoQueryParams({ modal: null, albumId: null });
    }
  }, [
    initialQuery,
    albumId,
    setVideoQueryParams,
    modal,
    t,
    isPhotoCommentingModal,
    isAuthenticated,
    photoalbum,
  ]);

  const action = React.useMemo(
    () => ({
      showModal: (albumId: string) => {
        setVideoQueryParams({ modal: photoCommentingModal, albumId });
      },
      closeModal: () => {
        setVideoQueryParams({ modal: null, albumId: null });
        onClose();
      },
      addReply,
      registerOpenComment,
    }),
    [addReply, registerOpenComment, setVideoQueryParams, onClose]
  );

  const context: PhotoCommentingContext = React.useMemo(() => {
    return {
      photoalbum,
      isLoading: loading,
      comments,
      commentsLoading,
      commentsError,
      loadMoreComments,
      isDirty,
      action,
      isOpen: !!albumId && isPhotoCommentingModal,
    };
  }, [
    photoalbum,
    loading,
    comments,
    commentsLoading,
    commentsError,
    loadMoreComments,
    isDirty,
    action,
    albumId,
    isPhotoCommentingModal,
  ]);

  return (
    <photoCommenting.Provider value={context}>
      {children}
      <PhotoCommentModal />
    </photoCommenting.Provider>
  );
};

export const PhotoCommentingProvider = Provider;
