import { AspectRatio, Heading, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import VideoPlayer from '../../../../../components/shared/VideoPlayer/VideoPlayer';
import { useActiveApiLanguage } from '../../../../../hooks/useActiveApiLanguage';

export const GMUModalStep1: React.FC = () => {
  const { t } = useTranslation(['gmumodal']);
  const lang = useActiveApiLanguage();
  const [v] = useState('?v=' + Date.now());
  const dir =
    'https://vx.vxcdn.org/public/specials/vxmodels/mobile/2024/Coins-Clip/';

  return (
    <>
      <Heading as="h2" size="xl" textAlign={'center'}>
        {t('gmumodal:heading.vxModelsGoes2025')}
      </Heading>
      <Text textAlign={'center'} color={'gray.700'} fontSize={'14px'} mt={3}>
        {t('gmumodal:text.wirFeiern25JahreVisitx')}
      </Text>
      <Text textAlign={'center'} color={'gray.700'} fontSize={'14px'} mb={4}>
        {t('gmumodal:text.mitNeuenFeaturesPerformancebasiertemSharing')}
      </Text>
      <AspectRatio ratio={16 / 9} borderRadius={'10px'} overflow={'hidden'}>
        <VStack>
          <VideoPlayer
            formattedPrice={''}
            options={{
              disablePictureInPicture: true,
              sources: [
                {
                  src:
                    lang === 'de'
                      ? dir + 'VXModels_Coin_Clip_de.mp4' + v
                      : dir + 'VXModels_Coin_Clip_en.mp4' + v,
                  type: 'video/mp4',
                },
              ],
              poster:
                'https://vx.vxcdn.org/public/specials/vxmodels/mobile/2024/Coins-Clip/VXModels-Preview-2025.jpeg',
            }}
          />
        </VStack>
      </AspectRatio>
    </>
  );
};
