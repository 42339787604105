import { Heading } from '@chakra-ui/react';
import React from 'react';

type WizardSubHeaderProps = {
  /**
   * Header text to be displayed
   */
  headerText: string;
  /**
   * Whether the bottom margin is enabled
   */
  bottomMarginEnabled?: boolean;
};

export const WizardSubHeader: React.FC<WizardSubHeaderProps> = ({
  headerText,
  bottomMarginEnabled = true,
}) => (
  <Heading size={'xl'} mb={bottomMarginEnabled ? 6 : 0} textAlign={'start'}>
    {headerText}
  </Heading>
);
