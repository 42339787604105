import * as icon from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  Button,
  ButtonGroup,
  ButtonProps,
  Center,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  StackProps,
  forwardRef,
} from '@chakra-ui/react';
import * as React from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';

import { useImageCropperContext } from '../../../provider/ImageCropperProvider';
import { useMediaProperties } from '../../../provider/MediaPropertiesProvider';
import { noop } from '../../../utils';
import { createStencilCssProps } from '../../../utils/media';
import { ButtonStack } from '../../Layout/ButtonStack';
import { PopoverContainerMidBox } from '../../Layout/Popover';
import {
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../Layout/ResponsiveModal';

export type CroppedImage = {
  type: string;
  blob: Blob;
  blobSrc: string;
  size: {
    width: number;
    height: number;
  };
};

const ToolbarRow: React.FC<StackProps> = (props) => (
  <HStack
    w="full"
    maxW={'container.lg'}
    justifyContent={'flex-end'}
    {...props}
  />
);

class CustomCropper extends Cropper {
  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('orientationchange', this.computeSizes, true);
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener('orientationchange', this.computeSizes, true);
  }
}

export const CropEditArea: React.FC<{ maxAvailableHeight: string }> = ({
  maxAvailableHeight,
}) => {
  const { cropperProps, isProcessing } = useImageCropperContext();
  const { isStencilVisible, orientedStencil } = useMediaProperties();

  const ref = React.useRef<Cropper>(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    /**
     * This is necessary as the {@link import('react-easy-crop').CropperProps } `contain` option
     * does not work well with vertical shaped containers
     *
     * We need to calculate the max available height of the container
     * and trigger a {@link computeSizes} when it changes
     *
     * Mostly relevant for containers with bigger `height` then `width` ("vertical shaped")
     */
    ref.current.computeSizes();
  }, [maxAvailableHeight]);

  return (
    <CustomCropper
      // satisfy type constraint Required<{crop, onCropChange}>,
      // will be overridden by `{...cropperProps}`
      crop={{ x: 0, y: 0 }}
      onCropChange={noop}
      {...cropperProps}
      ref={ref}
      style={{
        mediaStyle: { background: 'black' },
        containerStyle: {
          width: 'unset',
          height: 'unset',
          // unfortunatly we can't use inset: '1rem' here yet
          left: '1rem',
          right: '1rem',
          top: '1rem',
          bottom: '1rem',
          overflow: 'visible',
          pointerEvents: isProcessing ? 'none' : 'unset',
          opacity: isProcessing ? '0.5' : '1',
        },
        cropAreaStyle: {
          boxShadow: `0 0 0 9999em, 0 0 1rem`,
          border: 'none',
          ...createStencilCssProps(orientedStencil, isStencilVisible),
        },
      }}
    />
  );
};

const ToolbarIconButton: React.FC<IconButtonProps> = (props) => {
  const { isProcessing } = useImageCropperContext();
  return (
    <IconButton
      isDisabled={isProcessing}
      colorScheme={'gray'}
      color={'white'}
      variant={'angel'}
      {...props}
    />
  );
};

const RotateCWIconButton: React.FC<Partial<IconButtonProps>> = (props) => {
  const { action } = useImageCropperContext();
  const { t } = useTranslation(['mediaCropper']);
  return (
    <ToolbarIconButton
      onClick={action.rotateCW90}
      aria-label={t('mediaCropper:button.QuelleUm90GradImUhrzeigersinnDrehen')}
      icon={<Icon as={icon.RotateRight} boxSize="icon.md" />}
      {...props}
    />
  );
};

const FlipOrientationIconButton: React.FC<Partial<IconButtonProps>> = (
  props
) => {
  const { orientation, isOrientationFlipAllowed, action } =
    useMediaProperties();
  const { t } = useTranslation(['mediaCropper']);
  const label =
    orientation === 'landscape'
      ? t('mediaCropper:button.OrientierungHochkantAusrichten')
      : t('mediaCropper:button.OrientierungHorizontalAusrichten');
  return !isOrientationFlipAllowed || !orientation ? null : (
    <ToolbarIconButton
      onClick={action.toggleOrientation}
      aria-label={label}
      icon={<Icon as={icon.ScreenRotation} boxSize="icon.md" />}
      {...props}
    />
  );
};

const ToggleStencilIconButton: React.FC<Partial<IconButtonProps>> = (props) => {
  const { isStencilVisible, orientedStencil, action } = useMediaProperties();
  const { t } = useTranslation(['mediaCropper']);

  const label = isStencilVisible
    ? t('mediaCropper:button.SchabloneAusblenden')
    : t('mediaCropper:button.SchabloneAnzeigen');
  return !orientedStencil ? null : (
    <ToolbarIconButton
      onClick={action.toggleStencil}
      aria-label={label}
      icon={<Icon as={icon.Texture} boxSize="icon.md" />}
      {...props}
    />
  );
};

const CropButton: React.FC<ButtonProps> = (props) => {
  const { isProcessing, action } = useImageCropperContext();
  const { t } = useTranslation(['mediaCropper']);
  return (
    <Button
      onClick={action.generateCroppedImage}
      isLoading={isProcessing}
      color={'surface'}
      children={t('mediaCropper:button.Zuschneiden')}
      minW={'12rem'}
      {...props}
    />
  );
};

const CancelCropButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { isProcessing, action } = useImageCropperContext();
  const { t } = useTranslation(['mediaCropper']);
  return (
    <Button
      onClick={action.cancel}
      isDisabled={isProcessing}
      children={t('mediaCropper:button.Abbrechen')}
      colorScheme={'gray'}
      color={'white'}
      variant={'angel'}
      {...props}
      ref={ref}
    />
  );
});

export const ImageCropper: React.FC = () => {
  const [measuredHeight, setMeasuredHeight] = React.useState('1000px');
  const hPadding = 56 + 120;

  React.useLayoutEffect(() => {
    function updateSize() {
      setMeasuredHeight(`${window.innerHeight - hPadding}px`);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  });

  const focusRef = React.useRef(null);

  return (
    <ResponsiveModal
      isOpen={true}
      onClose={noop}
      initialFocusRef={focusRef}
      closeOnOverlayClick={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        border={'none'}
        borderRadius={'none'}
        shadow={'2xl'}
        sx={{
          maxW: '567px',
        }}
      >
        <PopoverContainerMidBox
          overflow={'hidden'}
          background={'black'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <ToolbarRow p={2} bg={'black'} zIndex={'overlay'}>
            <ButtonGroup>
              <ToggleStencilIconButton />
              <FlipOrientationIconButton />
              <RotateCWIconButton />
            </ButtonGroup>
          </ToolbarRow>
          <Center maxH={`calc(100svh - ${hPadding}px)`} flexGrow={1} w={'full'}>
            <AspectRatio
              ratio={1}
              style={{
                /**
                 * This extra container enforces, that on small / portrait oriented screen sizes
                 * the available space is always enough to show the crop area in either orientation
                 *
                 * Desired behaviour:
                 * ```
                 * ┌────┐ ┐      ┌─┲━━━━┱─┐ ┐
                 * ┢━━━━┪ │      │ ┃div ┃ │height
                 * ┃div ┃height  └─┺━━━━┹─┘ ┘
                 * ┡━━━━┩ │
                 * └────┘ ┘
                 * ```
                 */
                width: `min(${measuredHeight}, 100%)`,
                maxHeight: '100%',
                position: 'relative',
              }}
            >
              <CropEditArea maxAvailableHeight={measuredHeight} />
            </AspectRatio>
          </Center>
          <ButtonStack p={4} direction={'column'} alignItems={'center'}>
            <CropButton />
            <CancelCropButton ref={focusRef} />
          </ButtonStack>
        </PopoverContainerMidBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
