import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames#examples
 *
 * @example ```
 * const regionNames = useRegionNames();
 * regionNames.of('DE') // "Deutschland" / "Germany"
 * ```
 */
export const useRegionNames = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  return React.useMemo(() => {
    return new Intl.DisplayNames([locale], { type: 'region' });
  }, [locale]);
};
