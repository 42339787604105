import {
  FormControl,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  InputGroup,
  Wrap,
  WrapItem,
  useRadioGroup,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';

import { FormControlHeaderStack } from '../../FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { OptionButton } from '../../FormikFormElements';

export const EnumRadioField: React.FC<{
  name: string;
  field: any;
  label: React.ReactNode;
  labelTrailingHint?: React.ReactNode;
  footerInfoText?: React.ReactNode;
  labelProps?: FormLabelProps;
  text?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}> = ({
  name,
  field,
  label,
  labelProps,
  text,
  labelTrailingHint,
  footerInfoText,
  isReadOnly,
  isDisabled,
}) => {
  const {
    control,
    formState: { errors, touchedFields, isSubmitted },
  } = useFormContext();

  const { field: controlField } = useController({
    name,
    control,
  });

  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);

  const { getRadioProps } = useRadioGroup();

  return !field ? null : (
    <FormControl
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
    >
      <FormControlHeaderStack>
        <HStack w={'full'}>
          <FormLabel children={label} flex={1} {...labelProps} />
          {labelTrailingHint}
        </HStack>
        {text && <FormHelperText>{text}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup size="md">
        <Controller
          render={() => (
            <Wrap {...controlField}>
              {field?.validValues?.map((option: any) => {
                const radio = getRadioProps(option);
                const checked = controlField.value === option.value;
                return (
                  <WrapItem key={option.value}>
                    <OptionButton
                      multiple={false}
                      value={option.value}
                      key={option.value}
                      {...radio}
                      isChecked={checked}
                      isDisabled={isDisabled || isReadOnly}
                    >
                      {option.label}
                    </OptionButton>
                  </WrapItem>
                );
              })}
            </Wrap>
          )}
          name={name}
          control={control}
        />
      </InputGroup>
      {footerInfoText}
    </FormControl>
  );
};
