import { Box } from '@chakra-ui/react';

import { ContestPhotoUpload } from '../ContestPhotoUpload';
import { ContestTileBase, ContestTileHeaderImage } from './ContestTileBase';

export const ContestTileUploadAddPhotos: typeof ContestTileBase = (props) => {
  return (
    <ContestTileBase {...props}>
      <ContestTileHeaderImage contest={props.contest} />
      <Box
        p={3}
        overflowY={'auto'}
        lineHeight={1.2}
        flex={'1 0'}
        display={'flex'}
      >
        <ContestPhotoUpload mode="tile" />
      </Box>
    </ContestTileBase>
  );
};
