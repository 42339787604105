import * as icons from '@campoint/odi-ui-icons';
import { Box, Icon, VStack, chakra } from '@chakra-ui/react';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { CharacterCountInputControl } from '../../../../../components/shared/CharacterCountInputControl';
import { ErrorMessageWithLeadingIcon } from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { useCheckUserNameValidityMutation } from '../../../../../generated/graphql';
import { useAuth } from '../../../../../provider/AuthProvider';
import Logger from '../../../../../utils/Logger';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton, SecondaryButton } from '../../WizardProfile';
import { useWizardProfile } from '../../WizardProfileContext';

const modelNameInputName = 'modelName';

type ContentSectionProps = {
  formik: FormikContextType<any>;
};

const ContentSection: React.FC<ContentSectionProps> = ({ formik }) => {
  const { t } = useTranslation(['modelProfile', 'wizardProfile']);

  const wizardCtx = useWizardProfile();
  const { modelUsername } = wizardCtx?.currentField ?? {};

  return (
    <FormikProvider value={formik}>
      <WizardSubHeader
        headerText={t('modelProfile:fields.modelName.wizardPageTitle')}
        bottomMarginEnabled={false}
      />
      <Form>
        <Box mt={8}>
          <CharacterCountInputControl
            name={modelNameInputName}
            label={t('modelProfile:fields.modelName.label')}
            placeholder={t('modelProfile:fields.modelName.placeholder')}
            inputProps={{ autoComplete: 'off' }}
            field={modelUsername}
          />
        </Box>
      </Form>
      <Box mt={4} color={'warning.500'} textStyle={'bodyMd'} display={'flex'}>
        <Icon as={icons.Warning} boxSize={'icon.md'} />
        <Box m={0} ml={2}>
          <chakra.strong fontWeight={'bold'}>
            {t(`wizardProfile:name.note1`)}{' '}
          </chakra.strong>
          {t(`wizardProfile:name.note2`)}
        </Box>
      </Box>
    </FormikProvider>
  );
};

const HintSection: React.FC = () => {
  const { t } = useTranslation(['general', 'modelProfile']);
  return <TipCard text={t('modelProfile:fields.modelName.hint')} />;
};

const forbiddenModelNamePhrases = [
  'kid',
  'kind',
  'lolita',
  'peda',
  'pedo',
  'pre-teen',
  'teen',
  'visit',
  'vx',
];

const useUsernameValidityTest = () => {
  const { t } = useTranslation(['modelProfile']);
  const [checkUserName] = useCheckUserNameValidityMutation();
  return React.useCallback<Yup.TestFunction<string | undefined, AnyObject>>(
    async (value, testContext) => {
      if (value) {
        for (const forbiddenPhrase of forbiddenModelNamePhrases) {
          if (value?.includes(forbiddenPhrase) || value?.startsWith('vxm_')) {
            return testContext.createError({
              message: t('modelProfile:fields.modelName.errors.invalidPhrase', {
                phrase: forbiddenPhrase,
              }),
            });
          }
        }
        try {
          const checkResult = await checkUserName({
            variables: { userName: value },
          });

          const [firstError] =
            checkResult.data?.profile?.validate?.__typename ===
            'ProfileFieldTypeValidationString'
              ? checkResult.data?.profile?.validate?.errors ?? []
              : [];
          if (firstError) {
            return testContext.createError({
              message: () => (
                <ErrorMessageWithLeadingIcon children={firstError.message} />
              ),
            });
          }
        } catch (e) {
          Logger.error(e);
        }
      }
      return true;
    },
    [t, checkUserName]
  );
};

export const WizardProfileOnboardingV1Username: React.FC = () => {
  const { t } = useTranslation(['modelProfile', 'general']);
  const { actions, accessToken } = useAuth();

  const wizardCtx = useWizardProfile();
  const { modelUsername } = wizardCtx?.currentField ?? {};

  const initialValues = React.useMemo(
    () => ({
      [modelNameInputName]: modelUsername?.value ?? '',
    }),
    [modelUsername]
  );

  const usernameValidityTest = useUsernameValidityTest();
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [modelNameInputName]: Yup.string()
          .required(() => (
            <ErrorMessageWithLeadingIcon
              children={t('modelProfile:fields.modelName.errors.required')}
            />
          ))
          .matches(/^[a-zA-Z0-9-]*$/, () => (
            <ErrorMessageWithLeadingIcon
              children={t('modelProfile:fields.modelName.errors.invalidChar')}
            />
          ))
          .matches(/^[^-].*[^-]$/, () => (
            <ErrorMessageWithLeadingIcon
              children={t(
                'modelProfile:fields.modelName.errors.noHyphenStartEnd'
              )}
            />
          ))
          .matches(/^(?!.*-{2,}.*).*$/, () => (
            <ErrorMessageWithLeadingIcon
              children={t(
                'modelProfile:fields.modelName.errors.noConsecutiveHyphens'
              )}
            />
          ))
          .test(usernameValidityTest),
      }),
    [t, usernameValidityTest]
  );

  const onSubmit = React.useCallback(
    async (values: typeof initialValues) => {
      await wizardCtx.wizardNextStepCallback({
        modelUsername: values[modelNameInputName],
      });

      // force refresh username
      actions.loginWithToken(accessToken!);
    },
    [accessToken, actions, wizardCtx]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <WizardParentModalStepLayout
      contentSection={<ContentSection formik={formik} />}
      footerSection={
        <VStack spacing={'2'}>
          <PrimaryButton
            isDisabled={!(formik.isValid && formik.dirty)}
            children={t('general:button.continue')}
            onClick={formik.submitForm}
          />
          <SecondaryButton
            children={t('general:button.skip')}
            onClick={() => wizardCtx.wizardNextStepCallback()}
          />
        </VStack>
      }
      hintSection={<HintSection />}
    />
  );
};
