import * as icons from '@campoint/odi-ui-icons';
import {
  Button,
  ButtonProps,
  Heading,
  HeadingProps,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import verifyEmailImage from '../assets/images/email/verify-email.svg';
import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import {
  PrimaryButton,
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../components/Layout/ResponsiveModal';
import { ClearableInputControl } from '../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  GetMandatoryEmaiLVerificationProviderDataDocument,
  GetTermsAcceptDatesDocument,
  useChangeEmailAndResendRegistrationMailMutation,
  useGetMandatoryEmaiLVerificationProviderDataQuery,
  useResendEmailVerificationMutation,
} from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import LoadingPage from '../pages/LoadingPage/LoadingPage';
import { noop } from '../utils';
import { extractFromUnion } from '../utils/extractor';
import { mutationResultErrorHandling } from '../utils/utils';
import { useAuth } from './AuthProvider';

export interface EmailVerifyContext {
  isInitiallyLoading: boolean;
  isEmailVerificationRequiredToProceed: boolean;
  isVerificationMutationRunning: boolean;
  currentEmail: string;
  newEmailMaxLength: number;
  newEmailMinLength: number;
  isRegistrationEmailVerified: boolean;
  action: {
    resendVerificationEmail: () => Promise<void>;
  };
}
export const [, useEmailVerify, emailVerifyContext] =
  createContext<EmailVerifyContext>({
    name: 'verifyEmailContext',
  });

const VerifyEmailIllustration = () => {
  const { t } = useTranslation(['home']);
  return (
    <Image
      src={verifyEmailImage}
      alt={t('home:imgAlt.EmailVerifizieren')}
      objectFit={'cover'}
      h={'160px'}
    />
  );
};

const fieldName = {
  newEmail: 'newEmail',
} as const;

interface EmailChangeFormValues {
  [fieldName.newEmail]: string;
}

const LogoutButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation(['emailVerificationModal']);
  const authCtx = useAuth();
  return (
    <Button
      rightIcon={<Icon as={icons.ExitToApp} boxSize={'icon.md'} />}
      variant={'ghost'}
      alignSelf={'end'}
      color={'primary.300'}
      onClick={() => {
        authCtx.actions.logout();
      }}
      iconSpacing={1}
      {...props}
    >
      <Text size={'16px'} lineHeight={'24px'} fontWeight={600}>
        {t('emailVerificationModal:button.Ausloggen')}
      </Text>
      {/* <Icon as={icons.Close} boxSize={'icon.md'} /> */}
    </Button>
  );
};

const VerifyEmailModalHeading: React.FC<HeadingProps> = (props) => (
  <Heading size={'sm'} {...props} />
);

const ForceVerifyEmailModal: React.FC<{}> = () => {
  const verifyCtx = useEmailVerify();
  const onResendEmailVerification = verifyCtx.action.resendVerificationEmail;

  const [isEmailChangeActive, setIsEmailChangeActive] = React.useState(false);

  const { newEmailMaxLength, newEmailMinLength, currentEmail } =
    useEmailVerify();

  const onEmailChangeToggle = () => {
    setIsEmailChangeActive(true);
  };

  const { t } = useTranslation([
    'general',
    'accountPage',
    'emailVerificationModal',
    'validation',
  ]);

  const getMinLengthValidation = React.useCallback(() => {
    if (typeof newEmailMinLength !== 'number') {
      return Yup.string();
    }
    return Yup.string().min(
      newEmailMinLength,
      t('general:field.error.minLength', {
        minLength: newEmailMinLength ?? undefined,
      })
    );
  }, [newEmailMinLength, t]);

  const getMaxLengthValidation = React.useCallback(() => {
    if (typeof newEmailMaxLength !== 'number') {
      return Yup.string();
    }
    return Yup.string().max(
      newEmailMaxLength,
      t('general:field.error.maxLength', {
        maxLength: newEmailMaxLength ?? undefined,
      })
    );
  }, [newEmailMaxLength, t]);

  const validationSchema = React.useMemo(() => {
    const emailSchema = Yup.string()
      .email(t(`validation:error.FalschesFormat`) ?? undefined)
      .concat(getMinLengthValidation())
      .concat(getMaxLengthValidation())
      .nullable()
      .transform((value) => (value === '' ? null : value));

    return Yup.object().shape({
      [fieldName.newEmail]: emailSchema,
    });
  }, [getMinLengthValidation, getMaxLengthValidation, t]);

  const hookForm = useForm<EmailChangeFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { [fieldName.newEmail]: '' },
    mode: 'all',
  });

  const [changeEmailAndSendRegistrationMail, { loading: changeEmailLoading }] =
    useChangeEmailAndResendRegistrationMailMutation({
      refetchQueries: [GetMandatoryEmaiLVerificationProviderDataDocument],
    });

  const emailChangeSubmitHandler = async (data: any) => {
    try {
      const response = await changeEmailAndSendRegistrationMail({
        variables: {
          email: data[fieldName.newEmail],
        },
      });

      const isMutationResultError =
        response.data?.account?.changeEmailAddressAndSendRegistrationMail
          ?.success === !true;

      if (isMutationResultError) {
        mutationResultErrorHandling(
          response.data?.account?.changeEmailAddressAndSendRegistrationMail
        );
        return;
      }
      issueChakraToast({
        description: t('accountPage:toast.VerifizierungsEMailWurdeGesendet'),
        status: 'success',
      });
    } catch (error) {
      return issueChakraToast({
        description: t('accountPage:toast.EMailKonnteNichtGeandertWerden'),
        status: 'error',
      });
    }
  };

  return (
    <ResponsiveModal
      isOpen={true}
      // can not be closed to enforce email verification
      onClose={noop}
      closeOnOverlayClick={false}
      trapFocus={true}
      preferredSize="xl"
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalBodyBox
          as={VStack}
          justifyContent={'center'}
          alignItems={'center'}
          p={0}
        >
          <FormProvider {...hookForm}>
            <form
              onSubmit={hookForm.handleSubmit(emailChangeSubmitHandler)}
              style={{ width: '100%' }}
            >
              <VStack spacing={'24px'} py={6} px={4}>
                <LogoutButton />
                <VerifyEmailIllustration />
                <VStack spacing={4} textAlign={'center'} py={4}>
                  <VerifyEmailModalHeading
                    fontSize={'24px'}
                    lineHeight={'32px'}
                    fontWeight={700}
                  >
                    {t(
                      'emailVerificationModal:heading.HiBitteBestatigeDeineEmail'
                    )}
                  </VerifyEmailModalHeading>
                  <Text fontWeight={400} color={'gray.500'}>
                    <Trans
                      ns="emailVerificationModal"
                      i18nKey={
                        'text.KlickeBitteAufDenAktivierungslinkDenDuPerEMailAnEmailErhaltenHast' as const
                      }
                      values={{ email: currentEmail }}
                    />

                    {/* {t(
                      'emailVerificationModal:text.KlickeBitteAufDenAktivierungslinkDenDuPerEMailAnEmailErhaltenHast',
                      {
                        email: currentEmail,
                      }
                    )} */}
                  </Text>
                  <Text fontWeight={500} color={'gray.500'}>
                    {t('emailVerificationModal:text.LinkNichtErhalten')}
                  </Text>
                  <PrimaryButton
                    minW={'48'}
                    onClick={onResendEmailVerification}
                    isLoading={verifyCtx.isVerificationMutationRunning}
                  >
                    {t('emailVerificationModal:button.ErneutSenden')}
                  </PrimaryButton>
                </VStack>
                <VStack
                  textAlign={'center'}
                  py={4}
                  spacing={'4px'}
                  alignItems={'stretch'}
                  width={'100%'}
                >
                  <Text
                    fontWeight={500}
                    color={'coldGray.900'}
                    lineHeight={'20px'}
                  >
                    {t('emailVerificationModal:text.Tippfehler')}
                  </Text>
                  <Text
                    fontWeight={400}
                    color={'coldGray.900'}
                    lineHeight={'20px'}
                  >
                    {t(
                      'emailVerificationModal:text.HierKannstDuDeineEmailAndern'
                    )}
                  </Text>
                  {isEmailChangeActive ? null : (
                    <Button
                      fontSize={'14px'}
                      lineHeight={'24px'}
                      variant="link"
                      children={t('emailVerificationModal:button.EmailAndern')}
                      onClick={onEmailChangeToggle}
                    />
                  )}
                  {!isEmailChangeActive ? null : (
                    <VStack
                      spacing={4}
                      textAlign={'center'}
                      width={'100%'}
                      p={4}
                    >
                      <ClearableInputControl
                        px={4}
                        py={2}
                        name={fieldName.newEmail}
                        label={null}
                        placeholder={t(
                          'emailVerificationModal:placeholder.NeueEmailEingeben'
                        )}
                      />
                      <Button
                        variant={'ghost'}
                        minW={'48'}
                        type="submit"
                        children={t(
                          'emailVerificationModal:button.AnderungSpeichern'
                        )}
                        isLoading={changeEmailLoading}
                      />
                    </VStack>
                  )}
                </VStack>
              </VStack>
            </form>
          </FormProvider>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

export const MandatoryEmailVerificationProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const { t } = useTranslation(['accountPage']);
  const authCtx = useAuth();

  const verificationMailSuccessfullySentToast = () => {
    issueChakraToast({
      description: t('accountPage:toast.VerifizierungsEMailWurdeGesendet'),
      status: 'success',
    });
  };

  const { data, loading: isInitiallyLoading } =
    useGetMandatoryEmaiLVerificationProviderDataQuery({
      skip: !authCtx.isAuthenticated,
    });
  const [
    resendEmailVerificationMutation,
    { loading: isVerificationMutationRunning },
  ] = useResendEmailVerificationMutation({
    refetchQueries: [GetTermsAcceptDatesDocument],
    onCompleted: verificationMailSuccessfullySentToast,
  });

  const resendVerificationEmail = React.useCallback(async () => {
    await resendEmailVerificationMutation({});
  }, [resendEmailVerificationMutation]);

  const action = React.useMemo(() => {
    return {
      resendVerificationEmail,
    };
  }, [resendVerificationEmail]);

  const mappedData = React.useMemo(() => {
    const isRegistrationEmailVerified =
      data?.account.isRegistrationComplete ?? false;
    const fields = extractFromUnion(
      data?.profile?.collection,
      'ProfileCollectionAccountV1'
    )?.fields;

    const currentEmail = data?.account?.contact?.emailAddress ?? '';

    const newEmailMaxLength =
      fields?.accountContactEmailAdress?.maxLength ?? 90;
    const newEmailMinLength = fields?.accountContactEmailAdress?.minLength ?? 6;

    return {
      currentEmail,
      newEmailMaxLength,
      newEmailMinLength,
      isRegistrationEmailVerified,
    };
  }, [data]);

  const isEmailVerificationRequiredToProceed =
    authCtx.isAuthenticated &&
    !mappedData.isRegistrationEmailVerified &&
    mappedData.isRegistrationEmailVerified !== undefined;

  const context = React.useMemo<EmailVerifyContext>(() => {
    return {
      isEmailVerificationRequiredToProceed,
      isInitiallyLoading,
      isVerificationMutationRunning,
      ...mappedData,
      action,
    };
  }, [
    mappedData,
    isInitiallyLoading,
    isEmailVerificationRequiredToProceed,
    isVerificationMutationRunning,
    action,
  ]);

  if (isInitiallyLoading) {
    return <LoadingPage />;
  }

  return (
    <emailVerifyContext.Provider value={context}>
      {/* only render children if no email verification is required */}
      {props.children}
      {isEmailVerificationRequiredToProceed ? <ForceVerifyEmailModal /> : null}
    </emailVerifyContext.Provider>
  );
};
