import {
  VideoFragment,
  VideoStatusEnum,
  VideoTypeEnum,
} from '../generated/graphql';

export const isVideoTypeShopOrContest = (video: VideoFragment) => {
  const allowedTypes: VideoTypeEnum[] = [
    VideoTypeEnum.VideoContest,
    VideoTypeEnum.VideoShop,
  ];
  const allowedStatus: VideoStatusEnum[] = [
    VideoStatusEnum.Online,
    VideoStatusEnum.Offline,
  ];
  let allowed = false;
  if (video.type && video.status) {
    allowed =
      allowedTypes.includes(video.type) && allowedStatus.includes(video.status);
  }
  return allowed;
};
