import { theme as odiTheme } from '@campoint/odi-ui/theme';
import { defineStyleConfig, extendTheme } from '@chakra-ui/react';

const theme = extendTheme(
  {
    styles: {
      global: {
        '*': {
          WebkitTapHighlightColor: 'transparent',
          '--sai-top':
            'var(--android-safe-area-inset-top, env(safe-area-inset-top, 0px))',
          '--sai-bottom':
            'var(--android-safe-area-inset-bottom, env(safe-area-inset-bottom, 0px))',
          '--sai-left':
            'var(--android-safe-area-inset-legt, env(safe-area-inset-left, 0px))',
          '--sai-right':
            'var(--android-safe-area-inset-right, env(safe-area-inset-right, 0px))',
          '--view-w':
            'calc(var(--full-w, 100vw) - var(--sai-left, 0) - var(--sai-right, 0))',
          '--view-h':
            'calc(var(--full-h, 100vh) - var(--sai-top, 0) - var(--sai-bottom, 0))',
          '--view-content-h': 'calc(var(--view-h, 100vh) - 48px - 48px - 8px)',
          // Add 0.5rem so the safari 15 navbar can be collapsed
          // even when the content is itself not scrollable
          // 1px was not enough in some circumstances
          '--view-scroll-h': 'calc(var(--view-h, 100vh) + 0.5rem)',
        },
        'body.chakra-ui-light': {
          marginRight: ' 0 !important',
          paddingRight: '0 !important',
        },
        '*::placeholder': {
          color: 'gray.300',
        },
        '*:read-only:not(:disabled)::placeholder': {
          color: 'gray.300',
        },
      },
    },
    components: {
      Tooltip: defineStyleConfig({
        baseStyle: {
          zIndex: 'modal',
        },
      }),
    },
  },
  odiTheme
);

export default theme;
