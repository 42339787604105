import {
  FormControlOptions,
  HTMLChakraProps,
  InputGroup,
  ThemingProps,
  forwardRef,
  omitThemingProps,
  useFormControlContext,
} from '@chakra-ui/react';
import React, { SyntheticEvent } from 'react';

import { ClearableInput } from './ClearableInput';
import { ClearableInputClearElement } from './ClearableInputClearElement';
import {
  ClearableInputProvider,
  useClearableInput,
} from './use-clearable-input';

export type ClearableInputGroupProps = Omit<
  HTMLChakraProps<'input'>,
  'onChange'
> &
  ThemingProps<'Input'> &
  FormControlOptions & {
    isClearable?: boolean;
    groupProps?: HTMLChakraProps<'div'>;

    // has only no event if clearable is pressed
    onChange?: (value: string, nativeEvent?: SyntheticEvent) => void;
    children?: React.ReactNode;
    value?: string;
  };

export const ClearableInputGroup = forwardRef<ClearableInputGroupProps, 'div'>(
  (props, ref) => {
    const formControlContext = useFormControlContext();
    const { children, groupProps, isClearable, ...rest } = props;
    const inputProps = omitThemingProps(rest);
    const providerValue = useClearableInput({
      isClearable,
      ...formControlContext,
      ...inputProps,
    });
    const { onChange, ...inputPropsWithoutOnChange } = inputProps;
    return (
      <ClearableInputProvider value={providerValue}>
        <InputGroup ref={ref} {...groupProps}>
          {children ?? [
            <ClearableInput
              key="input"
              {...inputPropsWithoutOnChange}
              ref={ref}
            />,
            <ClearableInputClearElement key="right-element" />,
          ]}
        </InputGroup>
      </ClearableInputProvider>
    );
  }
);
