import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { PublicPageLayout } from '../../../components/Layout/PublicPageLayout';
import Markdown from '../../../components/shared/Markdown/Markdown';
import { useCmsMarkdown } from '../../../hooks/useCmsMarkdown';
import { useAuth } from '../../../provider/AuthProvider';
import { routes } from '../../../routes/routesConfig';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { PageSection } from '../components/PageSection/PageSection';
import { PageSubHeader } from '../components/PageSubHeader/PageSubHeader';

const PublicImprintPage = () => {
  const { t } = useTranslation(['legal']);
  const history = useHistory();
  const { loading, markdownString } = useCmsMarkdown({
    id: 'imprint',
  });
  const navigateToLogin = () => history.push(routes.login.path);

  return (
    <PublicPageLayout
      pageTitle={t('legal:heading.Impressum')}
      isContentLoading={loading}
      onClose={navigateToLogin}
    >
      <PageContainer>
        <PageSection>
          <Markdown markdown={markdownString} />
        </PageSection>
      </PageContainer>
    </PublicPageLayout>
  );
};

const AuthenticatedImprintPage = () => {
  const { t } = useTranslation(['legal']);
  const { loading, markdownString } = useCmsMarkdown({
    id: 'imprint',
  });

  return (
    <FluentPageLayout isContentLoading={loading}>
      <PageSubHeader children={t('legal:heading.Impressum')} />
      <PageContainer>
        <PageSection>
          <Markdown markdown={markdownString} />
        </PageSection>
      </PageContainer>
    </FluentPageLayout>
  );
};

export const ImprintPage = () => {
  const authCtx = useAuth();

  return !authCtx.isAuthenticated ? (
    <PublicImprintPage />
  ) : (
    <AuthenticatedImprintPage />
  );
};
