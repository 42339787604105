import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { MediaFile } from '../types/media';
import Logger from '../utils/Logger';
import { isFileAccepted } from '../utils/media/IsFileAccepted';
import { convertHEICFile } from '../utils/media/convertHEICFile';
import { getImageDimensions } from '../utils/media/getImageDimensions';
import {
  imageMimeTypes,
  videoMimeTypes,
} from '../utils/media/supportedMimeTypes';
import { verifyJpegFormat } from '../utils/media/verifyJpegFormat';

function canHandleFile(file: File) {
  const { type } = file;
  //video accept all types of video/*
  if (type.includes(videoMimeTypes.join(',').replace('*', ''))) {
    return true;
  }

  //exception for unknown file types -> we expect this to be a video for now
  if (type === '') {
    return true;
  }

  return [...imageMimeTypes, 'application/pdf'].includes(type);
}

interface UseMediaPickerProps {
  accept?: string;
  typesToBeCroppedTest?: RegExp;
  onUnacceptedFileType?: () => void;
  issueWrongDimensionToast?: boolean;
}

export const useMediaPicker = ({
  accept,
  typesToBeCroppedTest = /^image\/*/,
  onUnacceptedFileType,
  issueWrongDimensionToast,
}: UseMediaPickerProps) => {
  const [isCropperShown, setShowCropper] = React.useState(false);
  const [mediaFile, setMediaFile] = React.useState<MediaFile | null>(null);
  const [isProcessingInput, setIsProcessingInput] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { t } = useTranslation(['general', 'mediaUpload']);

  const onFileChange = React.useCallback(
    async (e: any) => {
      if (!e.target.files && e.target.files.length <= 0) {
        return;
      }

      try {
        setIsProcessingInput(true);
        let file: File = e.target.files[0];

        // Convert HEIC files to jpeg
        // unfortunatly file.type of some HEIC files comes as "",
        // so not possible to rely on the mime-type
        if (
          file &&
          (/^image\/(heic|heif)/.test(file.type) ||
            /\.(heic|heif)$/i.test(file.name))
        ) {
          const convertedBlob = await convertHEICFile(file);
          const convertedHEICFile = new File(
            [...[convertedBlob].flat()],
            file.name,
            {
              type: 'image/jpeg',
              lastModified: file.lastModified,
            }
          );
          file = convertedHEICFile;
        }

        // receiving image/jpeg as mimetype with a mismatch in the file byte signature (FF D8) indicates,
        // that we have the whatsapp saved video issue at hand.
        // https://campoint.atlassian.net/browse/VXM-3437
        if (file.type === 'image/jpeg') {
          const checkResult = await verifyJpegFormat(file);
          if (!checkResult.isValidJpeg) {
            if (
              !checkResult.estimatedFileExtension ||
              !checkResult.estimatedMimeType
            ) {
              issueChakraToast({
                description: t(
                  'mediaUpload:toast.VonWhatsAppGespeichertesVideoBitteInDerFotosAppExportierenUndAlsDateXX'
                ),
                status: 'error',
                duration: 4_000,
              });
              return;
            }

            Logger.warn({
              ev: 'converting whats-app saved video',
              checkResult,
            });
            const arrayBuffer = await file.arrayBuffer();
            const convertedFile = new File(
              [new Uint8Array(arrayBuffer)],
              file.name.replace('.jpeg', checkResult.estimatedFileExtension),
              {
                type: checkResult.estimatedMimeType,
                lastModified: file.lastModified,
              }
            );
            file = convertedFile;
          }
        }
        // isFileAccepted() has special handling for Android and its accept='captrue=user'
        if (!!accept && !isFileAccepted(file.type, accept)) {
          const filenameParts = file.name.split('.');
          const fileType = `.${filenameParts[filenameParts.length - 1] ?? ''}`;
          issueChakraToast({
            description: `${t(
              'general:toast.DateiformatAnDieserStelleNichtErlaubt'
            )} (${fileType})`,
            status: 'error',
          });
          onUnacceptedFileType?.();
          return;
        }
        // Filter and stop processing for type that we can not handle in the client
        if (!canHandleFile(file)) {
          const filenameParts = file.name.split('.');
          const fileType = `.${filenameParts[filenameParts.length - 1] ?? ''}`;
          issueChakraToast({
            description: `${t(
              'general:toast.DateiformatNichtUnterstutzt'
            )} (${fileType})`,
            status: 'error',
          });
          return;
        }

        if (file && /^image\/*/.test(file.type)) {
          const dimensions = await getImageDimensions(
            URL.createObjectURL(file)
          );
          const wrongDimension =
            dimensions && (dimensions?.height < 520 || dimensions?.width < 520);

          setError(wrongDimension ? true : false);
          if (wrongDimension) {
            setShowCropper(false);
            setError(true);
            if (issueWrongDimensionToast) {
              issueChakraToast({
                status: 'error',
                description: t('mediaUpload:toast.PictureFalscheAufloesung'),
              });
            }
            return;
          }
        }
        setMediaFile({
          originalSrc: URL.createObjectURL(file),
          originalFile: file,
        });

        if (!typesToBeCroppedTest.test(file.type)) {
          return;
        }
        setShowCropper(true);
      } finally {
        setIsProcessingInput(false);
      }
    },
    [
      setMediaFile,
      typesToBeCroppedTest,
      accept,
      t,
      onUnacceptedFileType,
      issueWrongDimensionToast,
    ]
  );

  const closeCropper = React.useCallback(
    () => setShowCropper(false),
    [setShowCropper]
  );

  return {
    onFileChange,
    mediaFile,
    setMediaFile,
    closeCropper,
    isCropperShown,
    isProcessingInput,
    error,
  } as const;
};
