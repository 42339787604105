import { Box, BoxProps, Heading, HeadingProps, chakra } from '@chakra-ui/react';
import * as React from 'react';

const Headline: React.FC<HeadingProps> = (props) => (
  <chakra.section mt="8" mx="0" mb="4">
    <Heading as="h1" size="xl" m="0" {...props} />
  </chakra.section>
);

const Caption: React.FC<BoxProps> = (props) => (
  <Box textStyle={'caption'} color="onSurface.highEmphasis" {...props} />
);

export const PasswordResetShared = {
  Headline,
  Caption,
};
