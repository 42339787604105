import { VxModelsLogo, VxModelsName, Warning } from '@campoint/odi-ui-icons';
import {
  Button,
  Center,
  CenterProps,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FullscreenPageLayout } from '../../components/Layout/FullscreenPageLayout';
import ScrollToTopOnMount from '../../components/Layout/ScrollToTopOnMount';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { routes } from '../../routes/routesConfig';

const ErrorPageButtonFooter: React.FC = () => {
  const history = useHistory();
  const historyWithStorage = useHistoryWithStorage();
  const { t } = useTranslation(['errorPage']);

  const routeToStart = () => history.push(routes.home.path);
  const routeToSupport = () => historyWithStorage.push(routes.help.path);

  return (
    <VStack gap="4" alignItems="center">
      <Button
        minW={'184px'}
        variant={'solid'}
        children={t('errorPage:primaryButton')}
        onClick={routeToStart}
      />
      <Button
        minW={'184px'}
        children={t('errorPage:supportButton')}
        onClick={routeToSupport}
      />
    </VStack>
  );
};
const BrandLogo: React.FC<CenterProps> = (props) => (
  <Center role="banner" {...props}>
    <Icon as={VxModelsLogo} boxSize="icon.xl" color="vxModels.500" />
    <Icon as={VxModelsName} h="icon.xl" w="24" color="inherit" />
  </Center>
);
const ErrorPage: React.FC = () => {
  const { t } = useTranslation(['errorPage']);
  return (
    <FullscreenPageLayout>
      <ScrollToTopOnMount />
      <VStack
        left={0}
        right={0}
        top={0}
        bottom={0}
        position={'absolute'}
        height="100%"
        justifyContent="space-between"
      >
        <BrandLogo mt={4} />
        <VStack mb={8} h={'full'}>
          <VStack mb={4} h={'full'} justifyContent={'center'} gap={2}>
            <Icon as={Warning} h="icon.xl" w="24" color="inherit" />
            <Heading fontSize={'2xl'} lineHeight={'32px'} textAlign={'center'}>
              {t('errorPage:headline')}
            </Heading>
            <Text
              color={'gray.500'}
              fontWeight={'normal'}
              fontSize={'md'}
              lineHeight={'24px'}
              textAlign={'center'}
            >
              {t('errorPage:subHeadline')}
            </Text>
          </VStack>
          <ErrorPageButtonFooter />
        </VStack>
      </VStack>
    </FullscreenPageLayout>
  );
};

export default ErrorPage;
