import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { useGetPayoutTimeTableQuery } from '../../../../generated/graphql';
import {
  formatDEDate,
  formatDEDateRange,
} from '../../../../utils/formatDEDate';

const TableHeader: React.FC<TableColumnHeaderProps> = (props) => {
  return (
    <Th
      verticalAlign={'top'}
      pl={0}
      pr={1}
      pb={4}
      width={'50%'}
      fontSize={'14px'}
      fontWeight={500}
      lineHeight={'20px'}
      textColor={'coldGray.900'}
      letterSpacing={'0px'}
      textTransform={'unset'}
      {...props}
    />
  );
};

const TableData: React.FC<TableCellProps> = (props) => {
  return <Td py={2} pl={0} pr={2} {...props} />;
};

export const UpcomingPayoutDatesModalButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useGetPayoutTimeTableQuery();

  const mappedData = React.useMemo(() => {
    const payoutTimeTable = data?.finances.payoutTimeTable;

    return {
      payoutTimeTable,
    };
  }, [data]);

  const { t } = useTranslation(['withdrawl']);

  // Texts and values might differ from classic. This is intended and should *NOT* be "fixed".

  return (
    <Button
      variant={'ghost'}
      onClick={onOpen}
      leftIcon={<Icon as={icons.DateRange} boxSize={'icon.md'} />}
      fontSize={'14px'}
      lineHeight={'24px'}
      fontWeight={600}
    >
      {t('withdrawl:button.NachsteTermine')}
      <ResponsiveModal isOpen={isOpen} onClose={onClose} preferredSize="xl">
        <ResponsiveModalOverlay />
        <ResponsiveModalContent maxW={'567px'}>
          <ResponsiveModalStickyHeaderBox shadow={'unset'}>
            <Heading as={'h1'} size={'lg'}>
              {t('withdrawl:modal.Auszahlungstermine')}
            </Heading>
            <ResponsiveModalCloseButton />
          </ResponsiveModalStickyHeaderBox>

          <ResponsiveModalBodyBox>
            <HStack
              spacing={0}
              border={'1px'}
              borderColor={'coldGray.100'}
              borderRadius={'8px'}
              color={'caribbeanGreen.500'}
              m={4}
            >
              <HStack align={'top'} gap={2}>
                <Box p={4} pr={0}>
                  <Icon as={icons.LightBulbOutlined} boxSize={'icon.md'} />
                </Box>

                <Heading
                  size={'xs'}
                  fontWeight={400}
                  lineHeight={5}
                  p={4}
                  pl={0}
                >
                  {t(
                    'withdrawl:modal.BeantragungDerAuszahlungKannJeweilsBisMitteDesMonats15UndEndeDesMonaXX'
                  )}
                </Heading>
              </HStack>
            </HStack>

            <TableContainer p={4} whiteSpace={'unset'}>
              <Table variant={'simple'} maxWidth={'full'} overflow={'hidden'}>
                <Thead>
                  <Tr>
                    <TableHeader pr={4}>
                      {t('withdrawl:modal.Abrechnungszeitraum')}
                    </TableHeader>
                    <TableHeader pr={5}>
                      {t('withdrawl:modal.AuszahlungAm')}
                    </TableHeader>
                  </Tr>
                </Thead>
                <Tbody fontSize={'14px'}>
                  {mappedData.payoutTimeTable?.map((item, index) => {
                    return (
                      <Tr key={`payoutDate-${index}`}>
                        <TableData>
                          {formatDEDateRange({
                            oldest: DateTime.fromISO(
                              item?.billingFromDate
                            ).toJSDate(),
                            newest: DateTime.fromISO(
                              item?.billingEndDate
                            ).toJSDate(),
                          }).replace(/–/g, ' - ')}
                        </TableData>
                        <TableData>
                          {formatDEDate(
                            DateTime.fromISO(item?.payoutDate).toJSDate()
                          )}
                        </TableData>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ResponsiveModalBodyBox>
        </ResponsiveModalContent>
      </ResponsiveModal>
    </Button>
  );
};
