import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import {
  GetProfileHeaderStatisticsDocument,
  InputProfileCollectionMobileAboutMeV1,
  InputProfileCollectionMobileAppearanceV1,
  InputProfileCollectionMobileInterviewV1,
  InputProfileCollectionMobileMediaV1,
  ModelProfileCompletenessFragment,
  ModelProfileProviderDocument,
  ProfileCollectionMobileAboutMeV1Fragment,
  ProfileCollectionMobileAppearanceV1Fragment,
  ProfileCollectionMobileInterviewV1Fragment,
  ProfileCollectionMobileMediaV1Fragment,
  ProfileFieldValidationSetFragment,
  ProfileFieldsEnum,
  SaveProfileDataMutation,
  useModelProfileProviderQuery,
  useSaveProfileDataMutation,
} from '../../../generated/graphql';
import { noop } from '../../../utils';
import Logger from '../../../utils/Logger';
import { extractFromUnion } from '../../../utils/extractor';
import {
  extractProfileProviderAboutMeMutationCollection,
  extractProfileProviderAppearanceMutationCollection,
  extractProfileProviderInterviewMutationCollection,
  extractProfileProviderMediaMutationCollection,
} from './utils';

/**
 * Context to manage model profile specific states
 */

type SaveProfileDataProps = {
  aboutMeData?: InputProfileCollectionMobileAboutMeV1;
  mediaData?: InputProfileCollectionMobileMediaV1;
  appearanceData?: InputProfileCollectionMobileAppearanceV1;
  interviewData?: InputProfileCollectionMobileInterviewV1;
};

export type ModelProfileCompleteness = {
  progress: number;
  isAboutMeSectionIncomplete: boolean;
  isAppearanceSectionIncomplete: boolean;
  isInterviewSectionIncomplete: boolean;
  isSedcardSectionIncomplete: boolean;
};

export type ModelProfileContext = {
  actions: {
    toggleGuideDotVisibility: () => void;
    saveProfileData: (data: SaveProfileDataProps) => Promise<void>;
    clearMutationErrors: () => void;
    setIsDataDirty: Dispatch<SetStateAction<boolean>>;
  };
  readonly isGuideDotVisible: boolean;
  readonly isSaveDataLoading: boolean;
  readonly isInitDataLoading: boolean;
  readonly isDataDirty: boolean;
  readonly completeness: ModelProfileCompleteness;
  readonly aboutMeCollection: Maybe<ProfileCollectionMobileAboutMeV1Fragment>;
  readonly aboutMeCollectionError: Maybe<ProfileFieldValidationSetFragment>;
  readonly appearanceCollection: Maybe<ProfileCollectionMobileAppearanceV1Fragment>;
  readonly appearanceCollectionError: Maybe<ProfileFieldValidationSetFragment>;
  readonly interviewCollection: Maybe<ProfileCollectionMobileInterviewV1Fragment>;
  readonly interviewCollectionError: Maybe<ProfileFieldValidationSetFragment>;
  readonly mediaCollection: Maybe<ProfileCollectionMobileMediaV1Fragment>;
  readonly mediaCollectionError: Maybe<ProfileFieldValidationSetFragment>;
  readonly isModelVerified: boolean;
};

export const initModelProfileContextValues: ModelProfileContext = {
  actions: {
    toggleGuideDotVisibility: noop,
    saveProfileData: () => Promise.resolve(),
    clearMutationErrors: noop,
    setIsDataDirty: noop,
  },
  isGuideDotVisible: false,
  isSaveDataLoading: false,
  isInitDataLoading: false,
  isDataDirty: false,
  isModelVerified: false,
  aboutMeCollection: null,
  aboutMeCollectionError: null,
  appearanceCollection: null,
  appearanceCollectionError: null,
  interviewCollection: null,
  interviewCollectionError: null,
  mediaCollection: null,
  mediaCollectionError: null,
  completeness: {
    progress: 0,
    isAboutMeSectionIncomplete: false,
    isAppearanceSectionIncomplete: false,
    isInterviewSectionIncomplete: false,
    isSedcardSectionIncomplete: false,
  },
};

export const modelProfileContext = React.createContext<ModelProfileContext>(
  initModelProfileContextValues
);

type CollectionCompletenessData = {
  aboutMe?: ModelProfileCompletenessFragment;
  appearance?: ModelProfileCompletenessFragment;
  interview?: ModelProfileCompletenessFragment;
  media?: ModelProfileCompletenessFragment;
  progress?: number;
};

export const ModelProfileProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation(['general']);

  const {
    data,
    loading: initDataLoading,
    error,
    refetch,
  } = useModelProfileProviderQuery({ fetchPolicy: 'no-cache' });

  const [updatedDataToIgnore, setUpdatedDataToIgnore] =
    React.useState<Maybe<SaveProfileDataMutation>>(null);
  const [
    saveData,
    { data: updatedData, loading: saveDataLoading, error: updateError },
  ] = useSaveProfileDataMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [
      ModelProfileProviderDocument,
      GetProfileHeaderStatisticsDocument,
    ],
  });
  const [isGuideDotVisible, setIsGuideDotVisible] = useState(
    initModelProfileContextValues.isGuideDotVisible
  );

  const isModelVerified = data?.account.isModelVerified ?? false;

  useEffect(() => {
    if (!!error || !!updateError) {
      Logger.error({ error, updateError });
    }
  }, [error, updateError]);

  // Whether the data in a section is dirty. Is needed to show the confirm changes modal.
  const [isDataDirty, setIsDataDirty] = useState(
    initModelProfileContextValues.isDataDirty
  );
  const { completeness, actions: completenessActions } =
    useModelProfileCompleteness();

  const aboutMeCollection = React.useMemo(() => {
    const initialCollection = extractFromUnion(
      data?.profile?.aboutMeCollection,
      'ProfileCollectionMobileAboutMeV1'
    );
    return initialCollection ?? null;
  }, [data]);

  const aboutMeCollectionError = React.useMemo(() => {
    if (updatedData === updatedDataToIgnore) {
      return null;
    }

    const updatedCollection = extractFromUnion(
      updatedData?.profile?.aboutMeCollection,
      'ProfileCollectionMobileAboutMeV1Mutation'
    );
    const fieldValidationSet = extractFromUnion(
      updatedCollection?.update,
      'ProfileFieldValidationSet'
    );

    return fieldValidationSet ?? null;
  }, [updatedData, updatedDataToIgnore]);

  const appearanceCollection = React.useMemo(() => {
    const initialCollection = extractFromUnion(
      data?.profile?.appearanceCollection,
      'ProfileCollectionMobileAppearanceV1'
    );
    return initialCollection ?? null;
  }, [data]);

  const appearanceCollectionError = React.useMemo(() => {
    if (updatedData === updatedDataToIgnore) {
      return null;
    }

    const updatedCollection = extractFromUnion(
      updatedData?.profile?.appearanceCollection,
      'ProfileCollectionMobileAppearanceV1Mutation'
    );
    const fieldValidationSet = extractFromUnion(
      updatedCollection?.update,
      'ProfileFieldValidationSet'
    );

    return fieldValidationSet ?? null;
  }, [updatedData, updatedDataToIgnore]);

  const interviewCollection = React.useMemo(() => {
    const initialCollection = extractFromUnion(
      data?.profile?.interviewCollection,
      'ProfileCollectionMobileInterviewV1'
    );
    return initialCollection ?? null;
  }, [data]);

  const interviewCollectionError = React.useMemo(() => {
    if (updatedData === updatedDataToIgnore) {
      return null;
    }

    const updatedCollection = extractFromUnion(
      updatedData?.profile?.interviewCollection,
      'ProfileCollectionMobileInterviewV1Mutation'
    );
    const fieldValidationSet = extractFromUnion(
      updatedCollection?.update,
      'ProfileFieldValidationSet'
    );

    return fieldValidationSet ?? null;
  }, [updatedData, updatedDataToIgnore]);

  const mediaCollection = React.useMemo(() => {
    const initialCollection = extractFromUnion(
      data?.profile?.mediaCollection,
      'ProfileCollectionMobileMediaV1'
    );
    return initialCollection ?? null;
  }, [data]);

  const mediaCollectionError = React.useMemo(() => {
    if (updatedData === updatedDataToIgnore) {
      return null;
    }

    const updatedCollection = extractFromUnion(
      updatedData?.profile?.mediaCollection,
      'ProfileCollectionMobileMediaV1Mutation'
    );
    const fieldValidationSet = extractFromUnion(
      updatedCollection?.update,
      'ProfileFieldValidationSet'
    );

    return fieldValidationSet ?? null;
  }, [updatedData, updatedDataToIgnore]);

  // Load init data
  useEffect(() => {
    if (!data) {
      return;
    }

    const mapping = {
      interview: interviewCollection?.completeness,
      media: mediaCollection?.completeness,
      appearance: appearanceCollection?.completeness,
      aboutMe: aboutMeCollection?.completeness,
      progress: data?.profile?.completeness ?? 0,
    };

    // Set completeness data in order to calculate the progress
    completenessActions.updateCompletenessData(mapping);
  }, [
    data,
    completenessActions,
    interviewCollection,
    mediaCollection,
    appearanceCollection,
    aboutMeCollection,
  ]);

  const saveProfileData = React.useCallback(
    async (dataToSave: SaveProfileDataProps) => {
      let newCollectionData;
      try {
        newCollectionData = await saveData({
          variables: {
            aboutMeData: dataToSave.aboutMeData ?? {},
            appearanceData: dataToSave.appearanceData ?? {},
            interviewData: dataToSave.interviewData ?? {},
            mediaData: dataToSave.mediaData ?? {},
          },
        });

        const aboutMeCollection =
          extractProfileProviderAboutMeMutationCollection(newCollectionData);
        const mediaCollection =
          extractProfileProviderMediaMutationCollection(newCollectionData);
        const interviewCollection =
          extractProfileProviderInterviewMutationCollection(newCollectionData);
        const appearanceCollection =
          extractProfileProviderAppearanceMutationCollection(newCollectionData);

        const someCollectionHasErrors = [
          aboutMeCollection,
          mediaCollection,
          interviewCollection,
          appearanceCollection,
        ].some(
          (collection) =>
            collection?.update?.__typename === 'ProfileFieldValidationSet' &&
            collection?.update.hasErrors
        );

        if (!someCollectionHasErrors) {
          refetch().then();
          issueChakraToast({
            status: 'success',
            description: t('general:toast.AnderungenWurdenGespeichert'),
          });
        } else {
          issueChakraToast({
            status: 'error',
            description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
          });
        }
      } catch (error) {
        issueChakraToast({
          status: 'error',
          description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        });
      }
    },
    [saveData, t, refetch]
  );

  const toggleGuideDotVisibility = React.useCallback(
    () => setIsGuideDotVisible((prevState) => !prevState),
    [setIsGuideDotVisible]
  );

  const clearMutationErrors = React.useCallback(() => {
    setUpdatedDataToIgnore(updatedData);
  }, [updatedData, setUpdatedDataToIgnore]);

  const actions: ModelProfileContext['actions'] = React.useMemo(
    () => ({
      toggleGuideDotVisibility,
      saveProfileData,
      clearMutationErrors,
      setIsDataDirty,
    }),
    [
      toggleGuideDotVisibility,
      saveProfileData,
      clearMutationErrors,
      setIsDataDirty,
    ]
  );

  const context: ModelProfileContext = React.useMemo(
    () => ({
      actions,
      isGuideDotVisible,
      isSaveDataLoading: saveDataLoading,
      isInitDataLoading: initDataLoading,
      isDataDirty,
      completeness,
      aboutMeCollection,
      aboutMeCollectionError,
      appearanceCollection,
      appearanceCollectionError,
      interviewCollection,
      interviewCollectionError,
      mediaCollection,
      mediaCollectionError,
      isModelVerified,
    }),
    [
      actions,
      isGuideDotVisible,
      saveDataLoading,
      initDataLoading,
      isDataDirty,
      completeness,
      aboutMeCollection,
      aboutMeCollectionError,
      appearanceCollection,
      appearanceCollectionError,
      interviewCollection,
      interviewCollectionError,
      mediaCollection,
      mediaCollectionError,
      isModelVerified,
    ]
  );

  return (
    <modelProfileContext.Provider value={context}>
      {children}
    </modelProfileContext.Provider>
  );
};

export function useModelProfile() {
  return React.useContext(modelProfileContext);
}

const useModelProfileCompleteness = () => {
  const [completenessData, setCompletenessData] =
    useState<CollectionCompletenessData | null>(null);
  // Completeness states
  // const [progress, setProgress] = useState(0);
  const [isAboutMeSectionIncomplete, setIsAboutMeSectionIncomplete] =
    useState(false);
  const [isAppearanceSectionIncomplete, setIsAppearanceSectionIncomplete] =
    useState(false);
  const [isInterviewSectionIncomplete, setIsInterviewSectionIncomplete] =
    useState(false);
  const [isSedcardSectionIncomplete, setIsSedcardSectionIncomplete] =
    useState(false);

  const actions = useMemo(() => {
    return {
      updateCompletenessData: setCompletenessData,
    };
  }, [setCompletenessData]);

  const resetCompletenessStates = useCallback(() => {
    setIsAboutMeSectionIncomplete(false);
    setIsAppearanceSectionIncomplete(false);
    setIsInterviewSectionIncomplete(false);
    setIsSedcardSectionIncomplete(false);
    // setProgress(0);
  }, []);

  useEffect(() => {
    if (
      !completenessData ||
      !completenessData.aboutMe ||
      !completenessData.appearance ||
      !completenessData.media ||
      !completenessData.interview
    ) {
      resetCompletenessStates();
      return;
    }
    // Get counts of total fields for each collection
    const interviewTotalFieldsCount = 3;
    // const mediaTotalFieldsCount = 3;
    // const aboutMeTotalFieldsCount = completenessData.aboutMe.total;
    // const appearanceTotalFieldsCount = completenessData.appearance.total;

    // const fieldsCountTotal =
    //   interviewTotalFieldsCount +
    //   mediaTotalFieldsCount +
    //   aboutMeTotalFieldsCount +
    //   appearanceTotalFieldsCount;

    // Get counts of missing fields for each collection
    // const mediaMissingFieldsCount = getMediaMissingFieldsCount(
    //   completenessData.media.missingFields
    // );
    const aboutMeMissingFieldsCount = completenessData.aboutMe.missing;
    const appearanceMissingFieldsCount = completenessData.appearance.missing;
    const interviewMissingFieldsCount = getInterviewMissingFieldsCount(
      interviewTotalFieldsCount,
      completenessData.interview.complete
    );
    // const missingFieldsCountTotal =
    //   mediaMissingFieldsCount +
    //   aboutMeMissingFieldsCount +
    //   appearanceMissingFieldsCount +
    //   interviewMissingFieldsCount;

    // Calculate progress
    // const filledFieldsCountTotal = fieldsCountTotal - missingFieldsCountTotal;
    // setProgress(filledFieldsCountTotal / fieldsCountTotal);

    // Set incomplete states for each section
    setIsAboutMeSectionIncomplete(aboutMeMissingFieldsCount !== 0);
    setIsAppearanceSectionIncomplete(appearanceMissingFieldsCount !== 0);
    setIsInterviewSectionIncomplete(interviewMissingFieldsCount !== 0);
    setIsSedcardSectionIncomplete(
      completenessData.media.missingFields.includes(
        ProfileFieldsEnum.ModelPictureFsk16Sedcard
      )
    );
  }, [completenessData, resetCompletenessStates]);

  return {
    completeness: {
      progress: completenessData?.progress ?? 0,
      isAboutMeSectionIncomplete,
      isAppearanceSectionIncomplete,
      isInterviewSectionIncomplete,
      isSedcardSectionIncomplete,
    },
    actions,
  };
};

// The number of missing fields for calculating the progress.
// const getMediaMissingFieldsCount = (
//   missingFields: ProfileFieldsEnum[]
// ): number => {
//   let missingFieldsCount = 0;

//   if (missingFields.includes(ProfileFieldsEnum.ModelPictureFsk16Avatar)) {
//     ++missingFieldsCount;
//   }

//   if (missingFields.includes(ProfileFieldsEnum.ModelPictureTitleLandscape)) {
//     ++missingFieldsCount;
//   }

//   if (missingFields.includes(ProfileFieldsEnum.ModelPictureFsk16Sedcard)) {
//     ++missingFieldsCount;
//   }

//   return missingFieldsCount;
// };

const getInterviewMissingFieldsCount = (
  neededFieldsCount: number,
  totalCompletedFieldsCount: number
): number => {
  const completedFieldsCount =
    totalCompletedFieldsCount > neededFieldsCount
      ? neededFieldsCount
      : totalCompletedFieldsCount;

  return neededFieldsCount - completedFieldsCount;
};
