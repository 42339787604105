import {
  ProfileFieldsEnum,
  TourDataWizardProfileFragment,
  WizardStepFieldFragment,
} from '../../../generated/graphql';

export function extractTourStepField<
  F extends {
    [key in ProfileFieldsEnum[number]]: Partial<WizardStepFieldFragment>;
  }
>(_: TourDataWizardProfileFragment | null | undefined): Partial<F> {
  const list = _?.currentStep?.fields ?? [];
  if (list.length <= 0) {
    return {};
  }

  return list.reduce<Partial<F>>((acc, field) => {
    if (!field?.name) {
      return acc;
    }
    return { ...acc, [field?.name]: field };
  }, {});
}
