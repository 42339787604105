import { ApolloError } from '@apollo/client';
import { noop } from '@chakra-ui/utils';
import { UPLOADER_EVENTS, UploaderEnhancer } from '@rpldy/uploady';
import React from 'react';

import { FeatureName, useFeature } from '../../../flags';
import {
  VideoFragment,
  VideoGroupEnum,
  VideoStatusEnum,
  useGetVideoLibraryVideoUploadListQuery,
} from '../../../generated/graphql';
import { useAuth } from '../../AuthProvider';
import { useDeleteMedia } from '../useDeleteMedia';
import { TusUploadEntry } from './videoUploadEnhancer';

export type TusVideoUploadHistoryEnhancer = {
  enhancer: UploaderEnhancer;
  videos: VideoFragment[];
  initialLoading: boolean;
  videosLoading: boolean;
  videosError: ApolloError | undefined;
  totalCountForSelectedMediaTypes: number;
  loadMore: () => void;
  onDelete: (albumId: number, hideToast?: boolean) => Promise<void>;
};

export function useTusVideoUploadHistoryEnhancer(
  hasUnfinishedUploads: boolean,
  filterTusUploads: any
) {
  const [videos, setVideos] = React.useState<VideoFragment[]>([]);
  const [totalCountForSelectedMediaTypes, setTotalCountForSelectedMediaTypes] =
    React.useState<number>(0);
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
  const authCtx = useAuth();
  const fetchMoreVideoCount = 20;

  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  const skip = !authCtx.isAuthenticated || !videoLibraryFeatureFlag;

  const skipOnOnlyNew = React.useMemo(() => {
    return (
      videos.every((video) => video.status !== VideoStatusEnum.Transcoding) &&
      !hasUnfinishedUploads
    );
  }, [hasUnfinishedUploads, videos]);

  const skipPollingRefetch = skip || skipOnOnlyNew;

  /**
   * When a video is uploaded and then added to the first position of the array, the last video item disappears.
   * This is just a workaround, if the list ist longer than the fetchMoreVideoCount, the last item will still disappear.
   */
  const refetchCount = React.useMemo(() => {
    return videos.length < fetchMoreVideoCount
      ? fetchMoreVideoCount
      : videos.length;
  }, [videos.length]);

  const {
    data,
    refetch,
    fetchMore,
    loading: videosLoading,
    error: videosError,
  } = useGetVideoLibraryVideoUploadListQuery({
    variables: {
      count: fetchMoreVideoCount,
      cursor: '',
      group: VideoGroupEnum.Unused,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip,
    onCompleted: (onCompletedData) => {
      const requestIds =
        onCompletedData?.media?.videos?.videosList?.edges?.map(
          (item) => item?.node.uploadRequestId
        ) ?? [];

      //filter all TusUploads which are not in the upload history
      filterTusUploads((entry: TusUploadEntry) => {
        return !requestIds.includes(entry.uploadRequestId);
      });
    },
  });

  const loadMore = React.useCallback(() => {
    const hasNextPage = data?.media?.videos.videosList.pageInfo?.hasNextPage;

    if (hasNextPage && !videosError) {
      const lastPost =
        data?.media?.videos?.videosList?.edges[
          data?.media?.videos?.videosList?.edges?.length - 1
        ];
      fetchMore({
        variables: {
          count: fetchMoreVideoCount,
          cursor: lastPost?.cursor,
        },
      });
    }
  }, [
    data?.media?.videos?.videosList?.pageInfo?.hasNextPage,
    data?.media?.videos?.videosList?.edges,
    videosError,
    fetchMore,
  ]);

  const onDeleteSuccess = (albumId: number) => {
    setVideos((prevVideos: VideoFragment[]) =>
      prevVideos.filter((item) => item.albumId !== albumId)
    );
    setTotalCountForSelectedMediaTypes((prevCount) => prevCount - 1);
  };

  const onDelete = useDeleteMedia({
    onSuccess: onDeleteSuccess,
    mediaType: 'video',
  });

  React.useEffect(() => {
    const fetchedVideos = (data?.media?.videos?.videosList?.edges?.map(
      (el) => el?.node
    ) ?? []) as VideoFragment[];
    if (fetchedVideos) {
      setVideos(fetchedVideos);
    }
  }, [data?.media?.videos?.videosList?.edges]);

  React.useEffect(() => {
    setTotalCountForSelectedMediaTypes(
      data?.media?.videos?.videosList?.totalCount ?? 0
    );
  }, [data?.media?.videos?.videosList?.totalCount]);

  React.useEffect(() => {
    if (!videosLoading) {
      setInitialLoading(false);
    }
  }, [videosLoading]);

  const enhancer: UploaderEnhancer = React.useMemo(() => {
    return (uploader) => {
      uploader.on(UPLOADER_EVENTS.ITEM_FINISH, () => {
        //we need a 3s latency for the BE
        setTimeout(() => {
          if (skip) {
            return;
          }
          refetch({
            cursor: '',
            count: refetchCount,
          }).then();
        }, 3000);
      });
      return uploader;
    };
  }, [refetch, refetchCount, skip]);

  React.useEffect(() => {
    if (skipPollingRefetch) {
      return noop;
    }
    const onFetchMore = () => {
      refetch({
        cursor: '',
        count: refetchCount,
      }).then();
    };

    const interval = setInterval(onFetchMore, 30_000);
    return () => clearInterval(interval);
  }, [refetch, refetchCount, skipPollingRefetch]);

  return [
    enhancer,
    videos,
    initialLoading,
    videosLoading,
    videosError,
    totalCountForSelectedMediaTypes,
    loadMore,
    onDelete,
  ] as const;
}
