import { Box, useModalContext } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';

export const ScrollElementToTopOnMount: FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { dialogRef } = useModalContext();

  useEffect(() => {
    dialogRef.current?.parentElement?.scrollTo?.(0, 0);
  }, [ref, dialogRef]);

  return <Box ref={ref} display={'none'} />;
};
