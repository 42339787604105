import { ChevronRight } from '@campoint/odi-ui-icons';
import {
  HeadingProps,
  Icon,
  Link,
  LinkProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaProperties } from '../../../provider/MediaPropertiesProvider';
import { useMedia } from '../../../provider/MediaProvider';
import { externalRoutes } from '../../../routes/routesConfig';
import { MainHeading, SubHeading } from '../heading';

type variantKey = 'replace' | 'firstPick' | 'rejected' | 'check';

export interface ModalHeadingProps extends HeadingProps {
  variant: variantKey;
}

export interface ModalTextProps extends TextProps {
  variant: variantKey;
}

export const MediaUploadModalHeading: React.FC<ModalHeadingProps> = ({
  variant,
  ...props
}) => {
  const { named } = useMediaProperties();
  const { t } = useTranslation(['mediaUpload']);

  const findHeadingTransKey = React.useMemo(() => {
    switch (variant) {
      case 'replace':
        return t('mediaUpload:heading.NamedErsetzen', { named });
      case 'firstPick':
        return t('mediaUpload:heading.NamedHochladen', { named });
      case 'rejected':
        return t('mediaUpload:heading.NamedWurdeAbgelehnt', { named });
      case 'check':
        return t('mediaUpload:heading.PasstAlles');
      default:
        return '';
    }
  }, [variant, named, t]);

  return (
    <MainHeading
      lineHeight={'32px'}
      fontSize={'24px'}
      fontWeight={700}
      children={findHeadingTransKey}
      {...props}
    />
  );
};

export const MediaUploadModalText: React.FC<ModalTextProps> = ({
  variant,
  ...props
}) => {
  const { named } = useMediaProperties();
  const { rejectionReason } = useMedia();
  const { t } = useTranslation(['mediaUpload']);

  const findHeadingTransKey = React.useMemo(() => {
    switch (variant) {
      case 'replace':
        return t(
          'mediaUpload:text.GestalteDeinProfilDurchEinNamedNochAttraktiver',
          { named }
        );
      case 'firstPick':
        return t(
          'mediaUpload:text.GestalteDeinProfilDurchEinNamedNochAttraktiver',
          { named }
        );
      case 'rejected':
        return t('mediaUpload:text.AblehnungsgrundReason', {
          reason: rejectionReason ?? '',
        });
      case 'check':
        return t('mediaUpload:text.DannLadeEsJetztHoch');
      default:
        return '';
    }
  }, [variant, named, t, rejectionReason]);

  return (
    <Text
      fontSize={'14px'}
      fontWeight={400}
      lineHeight={'20px'}
      children={findHeadingTransKey}
      {...props}
    />
  );
};

export const ReplaceHeading: React.FC = (props) => {
  const { named } = useMediaProperties();
  const { t } = useTranslation(['mediaUpload']);
  return (
    <MainHeading
      children={t('mediaUpload:heading.NamedErsetzen', { named })}
      {...props}
    />
  );
};
export const RejectedHeading: React.FC = (props) => {
  const { named } = useMediaProperties();
  const { t } = useTranslation(['mediaUpload']);
  return (
    <MainHeading
      children={t('mediaUpload:heading.NamedWurdeAbgelehnt', { named })}
      {...props}
    />
  );
};
export const UploadHeading: React.FC = (props) => {
  const { named } = useMediaProperties();
  const { t } = useTranslation(['mediaUpload']);
  return (
    <MainHeading
      children={t('mediaUpload:heading.NamedHochladen', { named })}
      {...props}
    />
  );
};
export const CheckHeading: React.FC = (props) => {
  const { t } = useTranslation(['mediaUpload']);
  return (
    <MainHeading children={t('mediaUpload:heading.PasstAlles')} {...props} />
  );
};
export const CheckText: React.FC = (props) => {
  const { named } = useMediaProperties();
  const { t } = useTranslation(['mediaUpload']);
  return (
    <Text
      children={t('mediaUpload:text.BistDuZufriedenMitDeinemNeuenNamed', {
        named,
      })}
      {...props}
    />
  );
};
export const EncourageUploadText: React.FC = (props) => {
  const { t } = useTranslation(['mediaUpload']);
  return (
    <Text children={t('mediaUpload:text.DannLadeEsJetztHoch')} {...props} />
  );
};
export const MentionApproveProcessText = () => {
  const { t } = useTranslation(['mediaUpload']);
  return (
    <Text>
      {t(
        'mediaUpload:text.LadeEinNeuesBildHochSobaldEsVonUnsFreigegebenWurdeWirdDeinAltesBildDXX'
      )}
    </Text>
  );
};
export const RejectionReasonText: React.FC<TextProps> = ({ ...props }) => {
  const { rejectionReason } = useMedia();
  const { t } = useTranslation(['mediaUpload']);
  return (
    <Text
      children={t('mediaUpload:text.AblehnungsgrundReason', {
        reason: rejectionReason ?? '',
      })}
      {...props}
    />
  );
};
export const LinkToPictureRequirements: React.FC<LinkProps> = (props) => {
  const { i18n } = useTranslation();

  const hrefForImageRules = externalRoutes.vxModelsImageRules(i18n.language);
  return (
    <Link
      fontSize={'sm'}
      href={hrefForImageRules}
      isExternal
      rel="noreferrer"
      textColor={'secondary.highEmphasis'}
      {...props}
    />
  );
};
export const FaqPart: React.FC = () => {
  const { t } = useTranslation(['general', 'mediaUpload']);
  return (
    <>
      <SubHeading mt={8}>
        {t('mediaUpload:heading.WelcheBedingungenMussMeinFotoErfullen')}
      </SubHeading>
      <LinkToPictureRequirements>
        {t('mediaUpload:text.BedingungenFurFotos')}
        <Icon as={ChevronRight} boxSize={'icon.md'} />
      </LinkToPictureRequirements>
    </>
  );
};

export const FaqLink: React.FC = () => {
  const { t } = useTranslation(['general', 'mediaUpload']);
  return (
    <>
      <LinkToPictureRequirements fontSize={'16px'} fontWeight={'600'}>
        {t('mediaUpload:text.UnsereContentRegeln')}
        <Icon as={ChevronRight} boxSize={'icon.md'} />
      </LinkToPictureRequirements>
    </>
  );
};
