import * as React from 'react';

import ENV from '../../../environments/environment';
import {
  CurrentStepWizardMrfFragment,
  InputTourModelReleaseFormV1,
  ProfileFieldTypeDate,
  ProfileFieldTypeEnum,
  ProfileFieldTypeString,
  ProfileFieldsEnum,
  WizardMrfAccountPrefillFieldsFragment,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { routes } from '../../../routes/routesConfig';
import { WizardContext, WizardStepsConfig } from '../utils/types';

export const possibleStepIds = [
  'ModelReleaseFormV1Start' as const,
  'ModelReleaseFormV1PersonalData' as const,
  'ModelReleaseFormV1FullAddress' as const,
  'ModelReleaseFormV1Signing' as const,
  'ModelReleaseFormV1Finish' as const,
] as const;

const getOrigin = () => window.location?.origin ?? ENV.HOST;

export const MRFHash = '#mrfFinish';

export const paperlessMrfRedirectUrl = `${getOrigin()}${
  routes.home.path + MRFHash
}`;

export namespace WizardMRFContext {
  export type StepIds = typeof possibleStepIds;
  export type StepId = StepIds[number];
  export type StepsConfig = WizardStepsConfig<StepId>;
  export type Step = CurrentStepWizardMrfFragment;
  export type Addition = {
    accountPrefillField: WizardMrfAccountPrefillFieldsFragment | null;
  };
  export type Context = WizardContext<
    Step,
    InputTourModelReleaseFormV1,
    Field
  > &
    Addition;
  // prettier-ignore
  export type Field = {
    [key in
      | ProfileFieldsEnum.MrfFirstname
      | ProfileFieldsEnum.MrfLastname
      | ProfileFieldsEnum.MrfBirthplace
      | ProfileFieldsEnum.MrfStreet
      | ProfileFieldsEnum.MrfStreetNumber
      | ProfileFieldsEnum.MrfCity
      | ProfileFieldsEnum.MrfPostalCode
    ]: Partial<ProfileFieldTypeString>;
  }
  // prettier-ignore
  & {
    [key in
      | ProfileFieldsEnum.MrfBirthdate
    ]: Partial<ProfileFieldTypeDate>;
  }
  // prettier-ignore
  & {
    [key in
      | ProfileFieldsEnum.MrfCountry
    ]: Partial<ProfileFieldTypeEnum>;
  }
  // prettier-ignore
  & {
    [key in
      | ProfileFieldsEnum.MrfForm
    ]: Partial<{ __typename: 'ProfileFieldTypeFormManagerPaperlessForm', form?: { formUrl?: string}}>;
  };

  export type FieldError = {
    [key in keyof Field]: React.ReactNode;
  };

  export type Input = InputTourModelReleaseFormV1;
}

export const [WizardMRFContextProvider, useWizardMRF] =
  createContext<WizardMRFContext.Context>();
