import { useIntl } from 'react-intl';

export function useFormattedAmount(amount: number, currency: string = 'EUR') {
  const intl = useIntl();
  const formattedAmount = intl
    .formatNumberToParts(amount, {
      currency,
      style: 'currency',
    })
    .map(({ type, value }) => {
      if (type === 'fraction' && amount === 0) {
        return '–';
      }
      return value;
    })
    .join('');
  return formattedAmount;
}

export function getFormattedAmount(
  amount: number,
  locale: string = 'de-DE',
  currency: string = 'EUR'
) {
  const options: Intl.NumberFormatOptions = { style: 'currency', currency };
  const numberFormat = new Intl.NumberFormat(locale, options);
  const parts = numberFormat
    .formatToParts(amount)
    .map(({ type, value }) => {
      if (type === 'fraction' && amount === 0) {
        return '–';
      }
      return value;
    })
    .join('');
  return parts;
}

export function getFormattedStatisticsAmount(
  amount: number,
  locale: string = 'de-DE'
) {
  if (locale === 'de') {
    const numberFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'EUR',
    });
    const parts = numberFormat
      .formatToParts(amount)
      .map(({ type, value }) => {
        if (type === 'fraction' && amount === 0) {
          return '–';
        }
        if (type === 'fraction' && Number.isInteger(amount) && amount !== 0) {
          return ''; // Remove fraction part if the amount is an integer and not 0
        }
        if (type === 'decimal' && Number.isInteger(amount) && amount !== 0) {
          return ''; // Remove decimal part if the amount is an integer and not 0
        }
        return value;
      })
      .join('');
    return parts;
  } else {
    const formattedAmount = Number.isInteger(amount)
      ? amount === 0
        ? '0.-'
        : amount.toString()
      : amount.toFixed(2);
    return `${formattedAmount} €`;
  }
}

export function getFormattedCoins(amount: number, locale: string = 'de-DE') {
  return new Intl.NumberFormat(locale).format(amount);
}
