import { Bolt } from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  Heading,
  Icon,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { t } from 'i18next';

import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import { ContestTileBase, ContestTileHeaderImage } from './ContestTileBase';

export const ContestTileTips: typeof ContestTileBase = (props) => {
  return (
    <ContestTileBase {...props}>
      <ContestTileHeaderImage contest={props.contest} />
      <Box p={3}>
        <Heading size="sm">
          {t('contest:heading.WasSolltestDuBeachten')}
        </Heading>
        <UnorderedList pl={3}>
          <ListItem>{t('contest:text.DasMottoMussErkennbarSein')}</ListItem>
          <ListItem>
            {props.contest.type === MediaContestTypeEnum.Video
              ? t('contest:text.QuerformatVideoInGuterQualitat')
              : t('contest:text.ManSollteDichErkennenKonnen')}
          </ListItem>
          <ListItem>{t('contest:text.MindFSK16GerneFSK18')}</ListItem>
          <ListItem>
            {props.contest.type === MediaContestTypeEnum.Video
              ? t('contest:text.MitPartnerOderToy')
              : t('contest:text.MaxEinePersonAufDemFotoDu')}
          </ListItem>
        </UnorderedList>
        <Divider my={3} />
        <Heading size="xs">
          <Icon as={Bolt} size={'14px'} color={'caribbeanGreen.500'} />
          {t('contest:heading.Tipps')}
        </Heading>
        <UnorderedList pl={3}>
          <ListItem>{t('contest:text.TrageGerneVXMerchandise')}</ListItem>
          <ListItem>
            {t('contest:text.WahleEinenBildausschnittDerDichGutZeigt')}
          </ListItem>
        </UnorderedList>
      </Box>
    </ContestTileBase>
  );
};
