import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { useGetRedirectPageDataQuery } from '../../generated/graphql';
import { routes } from '../../routes/routesConfig';
import LoadingPage from '../LoadingPage/LoadingPage';

/**
 * @param {string} externalRoute link to VxDesktop without base url e.g. /camtool/...
 * @param {string} internalRoute link to VxMobile
 * @return {*} query param string
 */
export function buildRedirectPageQueryParams(
  externalRoute: string,
  internalRoute: string
) {
  return `?redirectUrl=${externalRoute}&returnUrl=${internalRoute}`;
}

const RedirectPage: React.FC = () => {
  const history = useHistory();
  // check for url params
  const { search } = useLocation();
  const redirectUrl = new URLSearchParams(search).get('redirectUrl');
  const returnUrl = new URLSearchParams(search).get('returnUrl');

  // check if url params are available or route to error page
  if (!redirectUrl || !returnUrl) {
    history.push(routes.error.path);
  }

  // load redirect url from backend
  const { data, error, loading } = useGetRedirectPageDataQuery({
    fetchPolicy: 'no-cache',
    variables: {
      redirectUrl: redirectUrl!,
    },
  });

  // handle redirect logic
  useEffect(() => {
    const route = data?.app.autologinLinkVXModels;
    if (route) {
      // make sure to replace the current window history so a "back command"
      // will return to our return url instead of the redirect url
      window.history.replaceState({}, '', returnUrl);

      // route to external url
      window.location.assign(route);

      //!Remark: The following 2 lines don't work in safari as the new window
      //is blocked as popup
      //window.open(route, '_blank');
      //history.push(returnUrl!);
    }
    if (error) {
      history.push(returnUrl!);
    }
  }, [data, history, error, returnUrl]);

  return loading && !error && !data ? (
    <LoadingPage />
  ) : (
    <Redirect to={routes.home.path} />
  );
};
export default RedirectPage;
