import { ChevronRight, Error } from '@campoint/odi-ui-icons';
import { Alert, AlertIcon, AlertTitle, Icon } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FeatureName, useFeature } from '../../../../flags';
import { routes } from '../../../../routes/routesConfig';
import { UrlFragment } from '../../../Layout/UrlFragmentScrollToTarget';

export const UpdateTaxInformationHint: React.FC = () => {
  const { t } = useTranslation(['payout']);
  const history = useHistory();
  const documentFlag = useFeature(FeatureName.document);
  const documentPreferredForFinanceDocumentFlag = useFeature(
    FeatureName.documentPreferredForFinanceDocument
  );

  const navigateToPayoutTaxSection = () => {
    if (documentFlag && documentPreferredForFinanceDocumentFlag) {
      history.push(
        `${routes.documentsPayout.path}#${UrlFragment.TaxEditSection}`
      );
    } else {
      history.push(
        `${routes.finance.path}?tapId=${'vat'}#${UrlFragment.TaxEditSection}`
      );
    }
  };

  return (
    <Alert
      maxW={'container.xl'}
      status={'error'}
      mx={'auto'}
      cursor={'pointer'}
      onClick={navigateToPayoutTaxSection}
      p={{ base: 2, lg: 4 }}
    >
      <AlertIcon>
        <Icon as={Error} boxSize={'icon.md'} color={'error.highEmphasis'} />
      </AlertIcon>
      <AlertTitle flex={1}>
        {t('payout:hint.AktualisiereDeineAngabenZurSteuerlichenEinordnung')}
      </AlertTitle>
      <Icon
        mr={0}
        as={ChevronRight}
        boxSize={'icon.md'}
        color={'error.highEmphasis'}
      />
    </Alert>
  );
};
