import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { PublicPageLayout } from '../../../components/Layout/PublicPageLayout';
import Markdown from '../../../components/shared/Markdown/Markdown';
import { TermsAcceptanceTypeEnum } from '../../../generated/graphql';
import { useCmsMarkdown } from '../../../hooks/useCmsMarkdown';
import { useTermMarkdown } from '../../../hooks/useTermMarkdown';
import { useAuth } from '../../../provider/AuthProvider';
import { routes } from '../../../routes/routesConfig';
import { TermsPDFDownloadButton } from '../components/FileDownloadButton/TermsPDFDownloadButton';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { PageSection } from '../components/PageSection/PageSection';
import { PageSubHeader } from '../components/PageSubHeader/PageSubHeader';

const PublicTermsAndConditionsPage = () => {
  const { t } = useTranslation(['legal']);
  const history = useHistory();
  const { loading, markdownString } = useCmsMarkdown({
    id: 'terms-models',
  });
  const navigateToLogin = () => history.push(routes.login.path);

  return (
    <PublicPageLayout
      pageTitle={t('legal:heading.AGB')}
      isContentLoading={loading}
      onClose={navigateToLogin}
    >
      <PageContainer>
        <PageSection>
          <Markdown markdown={markdownString} />
        </PageSection>
      </PageContainer>
    </PublicPageLayout>
  );
};

const AuthenticatedTermsAndConditionsPage = () => {
  const { t } = useTranslation(['legal']);
  const { loading, markdownString, action } = useTermMarkdown({
    type: TermsAcceptanceTypeEnum.VxmodelsTermsAndConditions,
  });

  return (
    <FluentPageLayout isContentLoading={loading}>
      <PageSubHeader children={t('legal:heading.AGB')} />
      <PageContainer>
        <PageSection>
          <Markdown markdown={markdownString} />
          <TermsPDFDownloadButton
            mt={'4'}
            onClick={() => action.openPdfInNewWindow()}
          />
        </PageSection>
      </PageContainer>
    </FluentPageLayout>
  );
};

export const TermsAndConditionsPage = () => {
  const authCtx = useAuth();

  return !authCtx.isAuthenticated ? (
    <PublicTermsAndConditionsPage />
  ) : (
    <AuthenticatedTermsAndConditionsPage />
  );
};
