import { Box, Text } from '@chakra-ui/react';
import { t } from 'i18next';

import place1 from '../assets/Plätze/Place1.svg';
import place2 from '../assets/Plätze/Place2.svg';
import place3 from '../assets/Plätze/Place3.svg';
import place4 from '../assets/Plätze/Place4.svg';
import place5 from '../assets/Plätze/Place5.svg';
import place6 from '../assets/Plätze/Place6.svg';
import place7 from '../assets/Plätze/Place7.svg';
import place8 from '../assets/Plätze/Place8.svg';
import place9 from '../assets/Plätze/Place9.svg';
import place10 from '../assets/Plätze/Place10.svg';
import {
  ContestGoldenText,
  ContestIcon,
  ContestMask,
  ContestTileBase,
} from './ContestTileBase';

const PlaceImgs = [
  null,
  place1,
  place2,
  place3,
  place4,
  place5,
  place6,
  place7,
  place8,
  place9,
  place10,
];

export const ContestTileEndedWon: typeof ContestTileBase = (props) => {
  const contest = props.contest;
  const place = contest.userPlace ?? 0;
  const wonAmount =
    contest.prizes.find((prize) => prize?.place === place)?.amount ?? 0;

  return (
    <ContestTileBase
      color={'white'}
      gap={0}
      lineHeight={1.2}
      {...props}
      contestBackground={props.contest.images.payturSignupImage}
    >
      <Box p={'10px'} textAlign={'left'} width={'inherit'} mb={5}>
        <ContestIcon contest={contest} />
      </Box>

      {PlaceImgs[place] && <ContestMask height={94} src={PlaceImgs[place]!} />}
      <ContestGoldenText fontSize={'40px'} fontWeight={500}>
        {wonAmount}€
      </ContestGoldenText>

      <Text
        textAlign={'center'}
        fontWeight={600}
        fontSize={'16px'}
        mt={0}
        mb={4}
        px={4}
      >
        {t('contest:heading.Wow')}
        <ContestGoldenText as="span" display={'inline'} px={0} fontWeight={600}>
          {contest.video
            ? contest.video?.likesCount
            : contest.photoalbum?.likesCount ?? 0}
          &nbsp;
          {t('contest:heading.XXXLikes')}
        </ContestGoldenText>
        {place === 1 && t('contest:heading.DamitBistDuAufDemXXXPlatz1')}
        {place === 2 && t('contest:heading.DamitBistDuAufDemXXXPlatz2')}
        {place === 3 && t('contest:heading.DamitBistDuAufDemXXXPlatz3')}
        {place === 4 && t('contest:heading.DamitBistDuAufDemXXXPlatz4')}
        {place === 5 && t('contest:heading.DamitBistDuAufDemXXXPlatz5')}
        {place === 6 && t('contest:heading.DamitBistDuAufDemXXXPlatz6')}
        {place === 7 && t('contest:heading.DamitBistDuAufDemXXXPlatz7')}
        {place === 8 && t('contest:heading.DamitBistDuAufDemXXXPlatz8')}
        {place === 9 && t('contest:heading.DamitBistDuAufDemXXXPlatz9')}
        {place === 10 && t('contest:heading.DamitBistDuAufDemXXXPlatz10')}
      </Text>

      <Text textAlign={'center'} px={4} lineHeight={1.6}>
        {t('contest:text.DieAuszahlungErfolgtMitDerNachstenAbrechnung')}
      </Text>
    </ContestTileBase>
  );
};
