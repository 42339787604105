import {
  Box,
  Button,
  Radio,
  RadioGroup,
  StackDivider,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  ToastProps,
  VStack,
} from '@chakra-ui/react';
import {
  ErrorMessage,
  FormikContextType,
  FormikProvider,
  useFormik,
} from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import {
  DocumentPicker,
  PickedDocument,
} from '../../../../../components/shared/DocumentPicker/DocumentPicker';
import { RequiredUploadErrorMessage } from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { ClearableInputControl } from '../../../../../components/shared/FormikFormElements';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { ProfileFieldsEnum } from '../../../../../generated/graphql';
import { useFormikErrorSetter } from '../../../../../hooks/useFormikErrorSetter';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { routes } from '../../../../../routes/routesConfig';
import { noop } from '../../../../../utils';
import { uploadDocument } from '../../../../../utils/media';
import { validateAndTouchFormik } from '../../../../../utils/utils';
import { createStringValidationSchema } from '../../../../../utils/validation';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';

const taxObligationRONotObligatedValue = 'notTaxObligated' as const;
const taxObligationROObligatedValue = 'taxObligated' as const;
const taxObligationRONotSureValue = 'notSureIfTaxObligated' as const;

const taxNumberInputName = ProfileFieldsEnum.PayoutTaxId;
const taxDocumentUploadedName = ProfileFieldsEnum.PayoutTaxDocument;

const tabList = [
  taxObligationRONotObligatedValue,
  taxObligationROObligatedValue,
  taxObligationRONotSureValue,
] as const;
type TabId = (typeof tabList)[number];

/**
 * info: Will be skipped for non-eu models
 */
export const WizardPayoutTaxData: React.FC = () => {
  const { t } = useTranslation(['general', 'wizardPayout']);
  const [pickedDocument, setPickedDocument] = useState<null | PickedDocument>();
  const [isUploading, setIsUploading] = useState(false);

  const wizardPayoutCtx = useWizardPayout();
  const { isAccountFromGermany, currentField, currentFieldError } =
    wizardPayoutCtx;

  const isUploadRequired = isAccountFromGermany;
  const uploadUrl = currentField?.payoutTaxDocument?.upload?.url;

  const taxDetailsFormValidationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [taxNumberInputName]: createStringValidationSchema({
          minLength: currentField?.payoutTaxId?.minLength,
          maxLength: currentField?.payoutTaxId?.maxLength,
        }),
        [taxDocumentUploadedName]: isUploadRequired
          ? Yup.boolean().oneOf([true], () => <RequiredUploadErrorMessage />)
          : Yup.boolean(),
      }),
    [isUploadRequired, currentField]
  );

  const taxDetailsForm = useFormik({
    initialErrors: currentFieldError as any,
    initialValues: {
      [taxNumberInputName]: '',
      // Only a helper field that is set as soon as a document has been uploaded. Facilitates the validation of whether the id and the document exist.
      [taxDocumentUploadedName]: false,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: taxDetailsFormValidationSchema,
    onSubmit: noop,
  });

  const setTaxIdErrors = useFormikErrorSetter(taxDetailsForm);

  React.useEffect(() => {
    if (Object.keys(currentFieldError).length > 0) {
      setTaxIdErrors(currentFieldError as any).then();
    }
  }, [currentFieldError, setTaxIdErrors]);

  const [selectedTab, setSelectedTab] = useState<TabId | null>(null);

  const { setFieldValue: setTaxDetailsFieldValue } = taxDetailsForm;
  const setDocumentHandler = useCallback(
    (document: null | PickedDocument) => {
      setPickedDocument(document);
      setTaxDetailsFieldValue(taxDocumentUploadedName, !!document);
    },
    [setPickedDocument, setTaxDetailsFieldValue]
  );

  const { wizardNextStepCallback } = wizardPayoutCtx;

  const onContinue = useCallback(async () => {
    switch (selectedTab) {
      case 'taxObligated': {
        const errorToast: ToastProps = {
          status: 'error',
          description: t(
            'general:toast.EsGabEinenFehlerBeimHochladenDesDokuments'
          ),
        };
        if (!uploadUrl) {
          issueChakraToast(errorToast);
          return;
        }

        if (!pickedDocument && isUploadRequired) {
          issueChakraToast(errorToast);
          return;
        }

        const docId: {
          payoutTaxDocument?: number;
          payoutTaxDocumentId?: number;
        } = {};
        if (pickedDocument) {
          try {
            setIsUploading(true);
            const result = await uploadDocument(uploadUrl, pickedDocument.blob);
            if (result.id === null) {
              issueChakraToast(errorToast);
              return;
            }
            docId.payoutTaxDocument = result.id;
            docId.payoutTaxDocumentId = result.id;
          } catch {
            issueChakraToast(errorToast);
          } finally {
            setIsUploading(false);
          }
        }

        const { payoutTaxDocument, ...rest } = taxDetailsForm.values;
        await validateAndTouchFormik(taxDetailsForm).then((isValid) => {
          if (isValid) {
            wizardNextStepCallback({
              payoutIsTaxable: true,
              ...docId,
              ...rest,
            });
          }
        });

        return;
      }
      case 'notTaxObligated': {
        wizardNextStepCallback({
          payoutIsTaxable: false,
        });
        return;
      }
      default:
        return;
    }
  }, [
    t,
    wizardNextStepCallback,
    selectedTab,
    taxDetailsForm,
    uploadUrl,
    pickedDocument,
    isUploadRequired,
  ]);

  // Whether the continue button is disabled
  const disableContinueButton =
    selectedTab === 'notSureIfTaxObligated' || selectedTab === null;

  // The content to be displayed depending on the selection of the radio button

  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t('wizardPayout:heading.BistDuUmsatzsteuerpflichtig')}
        >
          <VStack divider={<StackDivider my={8} />}>
            <RadioGroup
              w="full"
              py={4}
              defaultChecked={false}
              onChange={(value: TabId) => setSelectedTab(value)}
            >
              <VStack spacing={4} alignItems="start">
                <Radio value={taxObligationRONotObligatedValue}>
                  {t('wizardPayout:radio.NeinIchBinKleinunternehmer')}
                </Radio>
                <Radio value={taxObligationROObligatedValue}>
                  {t('wizardPayout:radio.JaIchBinUmsatzsteuerpflichtig')}
                </Radio>
                <Radio value={taxObligationRONotSureValue}>
                  {t('wizardPayout:radio.IchBinMirNichtSicher')}
                </Radio>
              </VStack>
            </RadioGroup>
            {selectedTab && (
              <Tabs index={tabList.indexOf(selectedTab)} w="full">
                <TabPanels pt={2} mb={8}>
                  <TabPanel
                    p={0}
                    aria-label={t(
                      'wizardPayout:radio.NeinIchBinKleinunternehmer'
                    )}
                  >
                    <TaxNotObligatedContent />
                  </TabPanel>
                  <TabPanel
                    p={0}
                    aria-busy={isUploading || taxDetailsForm.isValidating}
                    aria-label={t(
                      'wizardPayout:radio.JaIchBinUmsatzsteuerpflichtig'
                    )}
                  >
                    <TaxObligatedContent
                      form={taxDetailsForm}
                      setDocumentHandler={setDocumentHandler}
                      isUploadRequired={isUploadRequired}
                      isUploading={isUploading}
                    />
                  </TabPanel>
                  <TabPanel
                    p={0}
                    aria-label={t('wizardPayout:radio.IchBinMirNichtSicher')}
                  >
                    <TaxNotSureIfObligatedContent />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </VStack>
        </WizardInstruction>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            isDisabled={disableContinueButton}
            children={t('wizardPayout:button.Weiter')}
            onClick={onContinue}
          />
        </ButtonStack>
      }
    />
  );
};

type TaxObligatedProps = {
  form: FormikContextType<any>;
  setDocumentHandler: (document: null | PickedDocument) => void;
  isUploadRequired: boolean;
  isUploading?: boolean;
};

const TaxObligatedContent: React.FC<TaxObligatedProps> = ({
  setDocumentHandler,
  isUploadRequired,
  form,
  isUploading,
}) => {
  const { t } = useTranslation(['wizardPayout', 'general', 'payout']);

  return (
    <FormikProvider value={form}>
      <VStack spacing={4} alignItems="stretch" w="full">
        <ClearableInputControl
          name={taxNumberInputName}
          label={t('payout:label.SteuernummerUmsatzsteuerID')}
          inputProps={{
            placeholder: t('payout:placeholder.SteuernummerUstIdNrEingeben'),
          }}
        />

        {isUploadRequired && (
          <Box>
            <MediaPropertiesProvider
              targetDimensions={{ width: 1000, height: 1400 }}
              isOrientationFlipAllowed={true}
            >
              <DocumentPicker
                label={t('wizardPayout:label.UmsatzsteuernachweisHochladen')}
                cancelCropButtonLabel={t('general:documentUpload.cancel')}
                cropButtonLabel={t('general:documentUpload.crop')}
                allowOrientationFlip={true}
                targetImageDimensions={{ width: 1000, height: 1400 }}
                uploadingLabel={t('general:documentUpload.uploadingLabel')}
                onDocument={setDocumentHandler}
                isUploading={isUploading}
              />
            </MediaPropertiesProvider>
            <ErrorMessage name={taxDocumentUploadedName}>
              {(msg) => <Text color="error.highEmphasis">{msg}</Text>}
            </ErrorMessage>
          </Box>
        )}
      </VStack>
    </FormikProvider>
  );
};

const TaxNotObligatedContent = () => {
  const { t } = useTranslation(['wizardPayout']);
  return <Text>{t('wizardPayout:text.DuMusstNichtsWeiterAngeben')}</Text>;
};

const TaxNotSureIfObligatedContent = () => {
  const { t } = useTranslation(['wizardPayout']);
  const history = useHistory();

  return (
    <VStack spacing={4} w="full" alignItems="start">
      <Text whiteSpace={'pre-line'}>
        {t(
          'wizardPayout:text.DuBistUmsatzsteuerpflichtWennDuSinktDerUmsatzEinesUnternehmersUnterDXX'
        )}
      </Text>
      <Text fontWeight="bold">
        {t('wizardPayout:text.DuMochtestLieberSicherGehen')}
      </Text>
      <Button
        variant="link"
        onClick={() => {
          history.push(routes.help.path);
        }}
      >
        {t('wizardPayout:text.KontaktiereUnserenSupport')}
      </Button>
    </VStack>
  );
};
