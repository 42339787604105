import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Notifications } from '@campoint/odi-ui-icons';
import { VStack } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  ProfileFieldsEnum,
  useAccountPersonalSectionQuery,
} from '../../../../generated/graphql';
import { extractFromUnion } from '../../../../utils/extractor';
import LoadingPage from '../../../LoadingPage/LoadingPage';

const fieldName = {
  firstName: ProfileFieldsEnum.AccountFirstname,
  lastName: ProfileFieldsEnum.AccountLastname,
  birthdate: ProfileFieldsEnum.AccountBirthdate,
} as const;

interface FormValues {
  [fieldName.firstName]: string;
  [fieldName.lastName]: string;
  [fieldName.birthdate]: string | Date;
}

export const PersonalSectionHookForm: React.FC<{}> = () => {
  const { t } = useTranslation(['general', 'accountPage']);

  const { data, loading, error } = useAccountPersonalSectionQuery();

  const fields = React.useMemo(() => {
    return extractFromUnion(
      data?.profile?.collection,
      'ProfileCollectionAccountV1'
    )?.fields;
  }, [data]);

  const initialValues = React.useMemo<FormValues>(() => {
    return {
      [fieldName.firstName]: fields?.accountFirstname?.value ?? '',
      [fieldName.lastName]: fields?.accountLastname?.value ?? '',
      [fieldName.birthdate]: !fields?.accountBirthdate?.value
        ? ''
        : DateTime.fromISO(fields?.accountBirthdate?.value).toISODate(),
    };
  }, [fields]);

  const fieldsMissing = React.useMemo(() => {
    return !fields?.accountFirstname?.value || !fields?.accountLastname?.value;
  }, [fields?.accountFirstname?.value, fields?.accountLastname?.value]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'all',
  });

  const birthdateValue = useWatch({
    name: fieldName.birthdate,
    control: hookForm.control,
  });
  const hasBirthdateValue = !!birthdateValue;

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  if (fieldsMissing) return null;

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.PersonalSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Notifications} />
            <SectionTitle
              children={t('accountPage:headline.PersonlicheDaten')}
            />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <SectionBody>
          <SectionCenterContainer>
            {(loading || error) && <LoadingPage />}
            {!loading && (
              <VStack spacing={6}>
                <ClearableInputControl
                  name={fieldName.firstName}
                  label={t('accountPage:label.Vorname')}
                  isReadOnly={true}
                />
                <ClearableInputControl
                  name={fieldName.lastName}
                  label={t('accountPage:label.Nachname')}
                  isReadOnly={true}
                />
                <ClearableInputControl
                  name={fieldName.birthdate}
                  label={t('accountPage:label.Geburtsdatum')}
                  isReadOnly={true}
                  type={'date'}
                  sx={{
                    '&::-webkit-date-and-time-value': {
                      textAlign: 'left',
                    },
                    '&::-webkit-datetime-edit': {
                      visibility: hasBirthdateValue ? 'unset' : 'hidden',
                    },
                  }}
                />
              </VStack>
            )}
          </SectionCenterContainer>
        </SectionBody>
      </FormProvider>
    </Section>
  );
};
