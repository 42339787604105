import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

import { WizardImage } from '../WizardImage/WizardImage';

export type WizardIllustratedInstructionProps = {
  illustrationSrc: string;
  illustrationAlt: string;
  header: string;
  body?: React.ReactNode;
  noticeAboveHeader?: React.ReactNode;
  withBottomMargin?: boolean;
};

export const WizardIllustratedInstruction: React.FC<
  WizardIllustratedInstructionProps
> = ({
  illustrationSrc,
  illustrationAlt,
  noticeAboveHeader,
  header,
  body,
  withBottomMargin,
}) => {
  return (
    <Flex
      mt={8}
      alignItems={'center'}
      flexDirection={'column'}
      textAlign={'center'}
    >
      {!!noticeAboveHeader && noticeAboveHeader}
      <WizardImage
        alt={illustrationAlt}
        src={illustrationSrc}
        withBottomMargin={
          withBottomMargin !== undefined ? withBottomMargin : false
        }
      />
      <Heading size={'md'} children={header} textAlign="center" />
      {body}
    </Flex>
  );
};
