import * as React from 'react';

import {
  CurrentStepWizardProfileFragment,
  InputTourOnboardingV1,
  ProfileFieldTypeDate,
  ProfileFieldTypeEnum,
  ProfileFieldTypeString,
  ProfileFieldsEnum,
  ProfileWizardMultiLangStringFragment,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { WizardContext, WizardStepsConfig } from '../utils/types';

export const possibleStepIds = [
  'OnboardingV1Welcome',
  'OnboardingV1Gender',
  'OnboardingV1Avatar',
  'OnboardingV1Username',
  'OnboardingV1Motto',
  'OnboardingV1Birthdate',
  'OnboardingV1Finish',
] as const;

export namespace WizardProfileTypes {
  export type StepIds = typeof possibleStepIds;
  export type StepId = StepIds[number];
  export type StepsConfig = WizardStepsConfig<StepId>;
  export type Step = CurrentStepWizardProfileFragment;
  export type Input = InputTourOnboardingV1;
  export type Addition = {};
  export type Context = WizardContext<Step, Input, Field> &
    Addition & {
      isInitialQueryLoading: boolean;
      isUpdateMutationLoading: boolean;
    };

  //https://bobbyhadz.com/blog/typescript-array-element-type
  type ArrElement<ArrType extends readonly unknown[]> =
    ArrType extends readonly (infer ElementType)[] ? ElementType : never;

  export type MappedProfileFieldTypePicture = ArrElement<
    CurrentStepWizardProfileFragment['fields']
  > & {
    __typename: 'ProfileFieldTypePicture';
  };

  // prettier-ignore
  export type Field = {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.ModelUsername
    ]: Partial<ProfileFieldTypeString>
  } & {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.ModelMotto
    ]: ProfileWizardMultiLangStringFragment
  } & {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.ModelGender
      | ProfileFieldsEnum.ModelSexuality
    ]: Partial<ProfileFieldTypeEnum>
  } & {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.ModelPictureAvatar
    ]: Partial<MappedProfileFieldTypePicture>
  } & {
    // prettier-ignore
    [key in
      | ProfileFieldsEnum.ModelBirthdate
    ]: Partial<ProfileFieldTypeDate>
  };

  export type FieldError = {
    [key in keyof Field]: React.ReactNode;
  };

  export type State = {
    totalSteps: number;
    currentStep: Step | null;
    currentField: Partial<Field>;
    currentFieldError: Partial<FieldError>;
    isVisible: boolean;
    onFinished: (() => void) | undefined;
  } & Addition;
}

export const [
  WizardProfileContextProvider,
  useWizardProfile,
  wizardProfileContext,
] = createContext<WizardProfileTypes.Context>();
