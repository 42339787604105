import {
  Section,
  SectionBody,
  SectionButton,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { ChevronLeft, ChevronRight, PieChart } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Icon,
  IconButton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { CurrencyAmount } from '../../../../components/shared/CurrencyAmount/CurrencyAmount';
import {
  AuthUserPermissionEnum,
  useGetStatisticsSectionQuery,
} from '../../../../generated/graphql';
import { useTimeframe } from '../../../../hooks/useTimeframe';
import { useAuth } from '../../../../provider/AuthProvider';
import { useStatistic } from '../../../../provider/StatisticProvider';
import { routes } from '../../../../routes/routesConfig';
import { getFormattedAmount } from '../../../../utils/formattedAmount';
import roundToHundredPercent from '../../../../utils/roundToHundredPercent';
import { StatisticsDetailedCategoryExtEnum } from '../../../StatisticsPage/provider/TabContext';
import PieChartT from './components/PieChart';

interface PieChartLegendProps {
  chartData: Record<string, number>;
  colorMapping: Record<string, string>;
  sharingLevels: { chat: number | undefined; shop: number | undefined };
}

const PieChartLegend: React.FC<PieChartLegendProps> = ({
  chartData,
  colorMapping,
  sharingLevels,
}) => {
  const { t } = useTranslation(['statistic']);
  const locale = useIntl().locale;

  // 1.000,23 €
  const formatCurrency = (amount: number) => {
    if (amount === 0) {
      return '0 €';
    }

    return `${amount.toFixed(2)} €`;
  };

  type TranslationKeys =
    | 'statistic:heading.LiveChat'
    | 'statistic:heading.VideoLibrary'
    | 'statistic:heading.Feed'
    | 'statistic:heading.Messenger'
    | 'statistic:heading.PhoneService'
    | 'statistic:heading.Misc'
    | 'statistic:heading.Affiliate'
    | 'statistic:heading.Telegram';

  const mapSharingLevels = (key: string) => {
    if (key === 'VideoLibrary' || key === 'Feed') {
      return sharingLevels.shop;
    }
    return sharingLevels.chat;
  };

  const percentages = roundToHundredPercent(chartData);

  type RouteMappingKey = keyof typeof StatisticsDetailedCategoryExtEnum;

  const location = useLocation();
  const history = useHistory();

  const goToStatistics = (key: RouteMappingKey) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tabId', StatisticsDetailedCategoryExtEnum[key]);
    window.scrollTo(0, 0);
    history.push({
      pathname: routes.statistics.path,
      search: queryParams.toString(),
    });
  };

  function toFixedWithConditionalComma(
    number: number,
    decimals: number,
    locale: string
  ): string {
    let result = number.toFixed(decimals);

    if (locale === 'de') {
      result = result.replace('.', ',');
    }

    return result;
  }

  return (
    <VStack alignItems={'flex-start'} alignSelf={'flex-start'} gap={'24px'}>
      <VStack
        alignItems={'flex-start'}
        justify={'space-between'}
        alignSelf={'flex-start'}
        pl={4}
        pr={4}
        w={'full'}
        gap={'24px'}
      >
        {Object.entries(chartData).map(([key, value], index) => (
          <HStack
            as={Button}
            variant={'unstyled'}
            key={key}
            spacing={'6px'}
            alignItems="flex-start"
            onClick={() => {
              goToStatistics(key as RouteMappingKey);
            }}
          >
            <Box
              w={'16px'}
              h={'16px'}
              borderRadius={'4px'}
              bg={colorMapping[key]}
            />
            <VStack alignItems={'flex-start'}>
              <HStack justifyItems={'flex-start'} gap={1}>
                <Heading
                  fontSize={'16px'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                >
                  {t(`statistic:heading.${key}` as TranslationKeys)}
                </Heading>
                {key === 'Misc' ||
                key === 'PhoneService' ||
                key === 'Affiliate' ||
                key === 'Telegram' ? null : (
                  <Box
                    bg={'whiteAlpha.900'}
                    border={'1px'}
                    borderRadius={'100px'}
                    px={'8px'}
                    borderColor={'gray.300'}
                  >
                    <Text
                      lineHeight={'14px'}
                      fontSize={'10px'}
                      fontWeight={'400'}
                      textColor={'gray.300'}
                    >
                      {`${t('statistic:text.Stufe', {
                        level: mapSharingLevels(key),
                      })}`}
                    </Text>
                  </Box>
                )}
              </HStack>

              <Text fontSize={'12px'} lineHeight={'8px'} fontWeight={'400'}>
                {`${t('statistic:text.Verdienst')}: ${
                  value === 0
                    ? formatCurrency(value)
                    : getFormattedAmount(value, locale)
                } (${
                  percentages[index] <= 0
                    ? 0
                    : toFixedWithConditionalComma(percentages[index], 2, locale)
                } %)`}
              </Text>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

const StatisticSection: React.FC = () => {
  const { authUser, isMasterAccount } = useAuth();
  const financeStatsPermission = authUser?.permissions.includes(
    AuthUserPermissionEnum.FinanceStatsView
  );

  const history = useHistory();
  const { t } = useTranslation(['statistic']);
  const timeFrame = useTimeframe();
  const availableTimeFrames = ['day', 'week', 'month'] as const;
  type TimeFrameType = (typeof availableTimeFrames)[number];
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>(0);
  const timeFrameMapping: Record<
    TimeFrameType,
    { startDate: string; endDate: string }
  > = {
    day: {
      startDate: timeFrame.today.toISODate(),
      endDate: timeFrame.today.toISODate(),
    },
    week: {
      startDate: timeFrame.firstDayOfWeek.toISODate(),
      endDate: timeFrame.today.toISODate(),
    },
    month: {
      startDate: timeFrame.firstDayOfMonth.toISODate(),
      endDate: timeFrame.today.toISODate(),
    },
  };

  const { lastUpdatedAt } = useStatistic(true);

  const selectedTimeFrameString = availableTimeFrames[selectedTimeFrame];
  const { data, loading } = useGetStatisticsSectionQuery({
    variables: {
      startDate: timeFrameMapping[selectedTimeFrameString].startDate,
      endDate: timeFrameMapping[selectedTimeFrameString].endDate,
      todayDate: timeFrame.today.toISO(),
    },
  });

  const availableTimeFramesString: Record<TimeFrameType, string> = {
    day: t('statistic:heading.Heute'),
    week: t('statistic:heading.DieseWoche'),
    month: t('statistic:heading.DieserMonat'),
  };

  const mappedData = React.useMemo(() => {
    return {
      total:
        (data?.statistics.detailed.overAll?.totalTurnover ?? 0) +
        (data?.affiliate.detailed.totalSharing ?? 0),
      chartData: {
        Affiliate: data?.affiliate.detailed.totalSharing ?? 0,
        LiveChat: data?.statistics.detailed.liveChat?.totalTurnover ?? 0,
        VideoLibrary:
          data?.statistics.detailed.videoLibrary?.totalTurnover ?? 0,
        Feed: data?.statistics.detailed.feed?.totalTurnover ?? 0,
        Messenger: data?.statistics.detailed.messenger?.totalTurnover ?? 0,
        PhoneService:
          data?.statistics.detailed.phoneService?.totalTurnover ?? 0,
        Telegram: data?.statistics.detailed.telegram?.totalTurnover ?? 0,
        Misc: data?.statistics.detailed.misc?.totalTurnover ?? 0,
      },
      currentLevels: {
        chat: data?.sharing.chatStatus.currentStep,
        shop: data?.sharing.shopStatus.currentStep,
      },
    };
  }, [
    data?.statistics.detailed.overAll?.totalTurnover,
    data?.statistics.detailed.liveChat?.totalTurnover,
    data?.statistics.detailed.videoLibrary?.totalTurnover,
    data?.statistics.detailed.feed?.totalTurnover,
    data?.statistics.detailed.messenger?.totalTurnover,
    data?.statistics.detailed.phoneService?.totalTurnover,
    data?.statistics.detailed.telegram?.totalTurnover,
    data?.statistics.detailed.misc?.totalTurnover,
    data?.affiliate.detailed.totalSharing,
    data?.sharing.chatStatus.currentStep,
    data?.sharing.shopStatus.currentStep,
  ]);

  const chartColorMapping = {
    LiveChat: '#75E0A3',
    VideoLibrary: '#00AAE7',
    Feed: '#8481DD',
    Messenger: '#F86893',
    PhoneService: '#EF9234',
    Misc: '#8BC1F7',
    Affiliate: '#F6D173',
    Telegram: '#FFADD6',
  };

  const chartDataByTimeFrame = mappedData;

  if (!isMasterAccount) {
    return null;
  }
  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={PieChart} />
            <SectionTitle>{t('statistic:heading.Statistiken')}</SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t('statistic:text.HeuteUmTimeAktualisiert', {
              time: lastUpdatedAt,
            })}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionBody>
        <SectionCenterContainer spacing={4}>
          {financeStatsPermission && (
            <VStack spacing={8} w={'full'}>
              <HStack w={'full'}>
                <IconButton
                  isDisabled={selectedTimeFrame <= 0}
                  aria-label={t('statistic:button.ZuKleineremZeitraum')}
                  icon={<Icon as={ChevronLeft} boxSize={'icon.md'} />}
                  onClick={() => setSelectedTimeFrame((prev) => prev - 1)}
                />
                <Center flexGrow={1}>
                  <Heading size={'xl'}>
                    {availableTimeFramesString[selectedTimeFrameString]}
                  </Heading>
                </Center>
                <IconButton
                  isDisabled={
                    selectedTimeFrame >= availableTimeFrames.length - 1
                  }
                  aria-label={t('statistic:button.ZuGrosseremZeitraum')}
                  icon={<Icon as={ChevronRight} boxSize={'icon.md'} />}
                  onClick={() => setSelectedTimeFrame((prev) => prev + 1)}
                />
              </HStack>

              {loading && (
                <VStack
                  spacing={4}
                  w={'full'}
                  h={'50vh'}
                  position={'relative'}
                  justify={'center'}
                >
                  <AbsoluteFullCenterLoadingSpinner />
                </VStack>
              )}
              <VStack
                spacing={4}
                w={'full'}
                justify={'center'}
                h={loading ? '0' : 'full'}
                visibility={loading ? 'hidden' : 'visible'}
              >
                <Heading size={'md'} color={'onSurface.mediumEmphasis'}>
                  {t('statistic:heading.VerdienstGesamt')}
                </Heading>
                <Heading
                  size={'3xl'}
                  as={'span'}
                  role="status"
                  aria-live="polite"
                >
                  <CurrencyAmount amount={chartDataByTimeFrame.total} />
                </Heading>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  w={'90%'}
                  justify={'center'}
                  alignItems={'center'}
                  gap={'24px'}
                >
                  <PieChartT
                    colorMapping={chartColorMapping}
                    chartData={chartDataByTimeFrame.chartData}
                  />
                  <PieChartLegend
                    sharingLevels={chartDataByTimeFrame.currentLevels}
                    colorMapping={chartColorMapping}
                    chartData={chartDataByTimeFrame.chartData}
                  />
                </Stack>
                <SectionButton
                  mt={'24px'}
                  alignSelf={{ base: 'center', md: 'center' }}
                  children={t('statistic:button.ZuDenStatistiken')}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(routes.statistics.path);
                  }}
                />
              </VStack>
            </VStack>
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

export default StatisticSection;
