import { AgeIndicator } from '../../../components/shared/AgeIndicator/AgeIndicator';
import { CommentHighlightMentions } from '../../../components/shared/FeedComment/CommentHighlightMentions';
import {
  CommentAvatar,
  CommentBox,
  CommentContent,
  CommentFooter,
  CommentReplyToActionLink,
  CommentText,
  CommentUsername,
} from '../../../components/shared/FeedComment/shared';

export type CommentFromOtherUserProps = {
  avatarSrc?: string;
  username: string;
  text?: string;
  created?: string;
  hasOpenReplyTo?: boolean;
  onReplyTo: () => void;
};

export const CommentFromOtherUser: React.FC<CommentFromOtherUserProps> = ({
  avatarSrc,
  username,
  text = '',
  created = '',
  hasOpenReplyTo = false,
  onReplyTo,
}) => {
  return (
    <CommentBox>
      <CommentAvatar src={avatarSrc} />
      <CommentContent>
        <CommentUsername children={username} />
        <CommentText>
          <CommentHighlightMentions children={text} />
        </CommentText>
        <CommentFooter>
          <AgeIndicator created={created} />
          {!hasOpenReplyTo && <CommentReplyToActionLink onClick={onReplyTo} />}
        </CommentFooter>
      </CommentContent>
    </CommentBox>
  );
};
