import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  HStack,
  Switch,
  SwitchProps,
  Text,
  chakra,
} from '@chakra-ui/react';
import { useField } from 'formik';
import * as React from 'react';

const SwitchInlineControl: React.FC<{
  name: string;
  label?: string;
  labelProps?: FormLabelProps;
  switchProps?: SwitchProps;
  formControlProps?: FormControlProps;
  description?: string;
}> = ({
  name,
  label,
  labelProps,
  switchProps,
  formControlProps,
  description,
}) => {
  const [field] = useField({ name });

  return (
    <FormControl {...formControlProps}>
      <HStack w={'full'}>
        <chakra.label
          flexGrow={1}
          textStyle={'bodyMd'}
          children={label}
          {...labelProps}
          as={FormLabel}
        />
        <Switch
          size={'lg'}
          {...field}
          {...switchProps}
          isChecked={field.value}
        />
      </HStack>
      {field.value && description && (
        <Text fontSize={'14px'} color={'darkSteel'} pt={2}>
          {description}
        </Text>
      )}
    </FormControl>
  );
};

export default SwitchInlineControl;
