import { ApolloError } from '@apollo/client';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';

import avatarPlaceholder from '../../../../assets/images/feed/AvatarPlaceholder.svg';
import { AgeIndicator } from '../../AgeIndicator/AgeIndicator';
import { FetchMoreIndicator } from '../../FetchMoreIndicator/FetchMoreIndicator';
import { NoInteractionList } from './EmptyState';

export interface Like {
  userId: Maybe<string>;
  avatarUrl: Maybe<string>;
  username: Maybe<string>;
  likedAt: Maybe<string>;
}

export const LikesTab: React.FC<{
  likes: Like[];
  likesLoading?: boolean;
  likesError?: ApolloError | undefined;
  loadMoreLikes?: () => void;
}> = ({ likes, likesLoading = false, likesError, loadMoreLikes }) => {
  const isLikesEmpty = (likes?.length ?? 0) === 0;
  if (isLikesEmpty) return <NoInteractionList type="Likes" />;
  return (
    <VStack spacing={'4'}>
      <>
        {likes.map((like) => {
          return <LikeLineItem like={like} key={like.userId} />;
        })}
        {loadMoreLikes && (
          <FetchMoreIndicator
            mt={{ base: '8', lg: '12' }}
            loading={likesLoading}
            error={likesError}
            onIsVisible={loadMoreLikes}
          />
        )}
      </>
    </VStack>
  );
};

const LikeLineItem: React.FC<{ like: Like }> = ({ like }) => {
  const { username, likedAt, avatarUrl } = like;
  const { t } = useTranslation(['feed']);

  return (
    <Box width={'full'}>
      <HStack spacing={'2.5'}>
        <Image
          alignSelf={'start'}
          borderRadius={'full'}
          borderColor={'steel'}
          w={'30px'}
          h={'30px'}
          src={avatarUrl || avatarPlaceholder}
        />
        <VStack alignItems={'start'} spacing={0} lineHeight={'6'}>
          <Text color={'coldGray.900'} fontSize={'md'} fontWeight={'medium'}>
            {username}
          </Text>
          <Text color={'coldGray.900'} fontSize={'sm'} fontWeight={'normal'}>
            {t('feed:postStatistic.HatDeinenBeitragGeliked')}
          </Text>
          <AgeIndicator created={likedAt ?? ''} />
        </VStack>
      </HStack>
    </Box>
  );
};
