import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export const CharacterCountIndicator: React.FC<{
  count: number;
  maxCount: number;
}> = (props) => {
  const isCharacterLimitExceeded = props.count > props.maxCount;
  return (
    <Box color={'gray.500'} mt={1} textStyle={'caption'}>
      <Text
        as={'span'}
        color={isCharacterLimitExceeded ? 'error.500' : 'unset'}
        fontWeight={isCharacterLimitExceeded ? '400' : 'unset'}
      >
        {props.count}/{props.maxCount}
      </Text>
    </Box>
  );
};
