import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { PrivacyHint } from '../../../../../components/shared/PrivacyHint/PrivacyHint';
import { windowOpen } from '../../../../../utils/utils';
import { WizardInstruction } from '../../../components/WizardInstruction/WizardInstruction';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';

export const WizardPayoutOriginatorMrfForm: React.FC = () => {
  const { t } = useTranslation(['wizardPayout', 'payout']);
  const wizardPayoutCtx = useWizardPayout();

  const url = wizardPayoutCtx.currentField.mrfOriginatorForm?.form?.formUrl;

  const navigateToPaperlessIo = React.useCallback(() => {
    if (url) {
      windowOpen(url, '_self');
    }
  }, [url]);
  return (
    <WizardParentModalStepLayout
      contentSection={
        <WizardInstruction
          noticeAboveHeader={<PrivacyHint />}
          header={t(
            'wizardPayout:heading.FehltNochEineUnterschriftFursModelReleaseForm'
          )}
          children={t(
            'wizardPayout:text.KlickeAufDenButtonUmDeineAngabenMitDeinerUnterschriftZuBestatigenDafXX'
          )}
        />
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.Weiter')}
            onClick={navigateToPaperlessIo}
          />
        </ButtonStack>
      }
    />
  );
};
