import * as icon from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Icon,
  IconProps,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FeedPostBrokenModal } from '../../modal/FeedPostBrokenModal';

const ChevronRightIcon: React.FC<IconProps> = (props) => (
  <Icon as={icon.ChevronRight} boxSize={'icon.md'} {...props} />
);
const FeedPostInlineAlert: React.FC<
  AlertProps & { title: React.ReactNode }
> = ({ title, ...alertProps }) => {
  return (
    <Alert
      status={'error'}
      cursor={'pointer'}
      minH={'100px'}
      borderRadius={'none'}
      borderLeftWidth={'0'}
      borderRightWidth={'0'}
      {...alertProps}
    >
      <AlertIcon />
      <AlertTitle flexGrow={1}>{title}</AlertTitle>
      <ChevronRightIcon />
    </Alert>
  );
};

interface FeedPostBrokenInlineAlertProps extends AlertProps {
  onDeletePost: () => void;
  rejectionReason?: string | null | undefined;
}
export const FeedPostBrokenInlineAlert: React.FC<
  FeedPostBrokenInlineAlertProps
> = ({ onDeletePost, rejectionReason, ...alertProps }) => {
  const { t } = useTranslation(['feed']);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <FeedPostInlineAlert
        title={
          rejectionReason
            ? t('feed:heading.Abgelehnt')
            : t('feed:heading.ErstellenFehlgeschlagen')
        }
        onClick={onOpen}
        {...alertProps}
      />
      <FeedPostBrokenModal
        rejectionReason={rejectionReason}
        isOpen={isOpen}
        onClose={onClose}
        onDeletePost={onDeletePost}
      />
    </>
  );
};
