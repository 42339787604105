import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { ClearableNumberInputWithUnits } from '../../../../components/shared/FormikFormElements';
import { ProfileFieldTypeIntFragment } from '../../../../generated/graphql';
import { LabelTextWithGuideDot } from '../LabelTextWithGuideDot/LabelTextWithGuideDot';

export const NumberField: React.FC<{
  field: Maybe<ProfileFieldTypeIntFragment>;
  label: string;
  placeholder: string;
  rightAddon?: string;
}> = ({ field, label, placeholder, rightAddon = null }) => {
  return !field ? null : (
    <ClearableNumberInputWithUnits
      label={<LabelTextWithGuideDot children={label} name={field.name} />}
      name={field.name}
      inputProps={{
        autoComplete: 'off',
        type: 'number',
        placeholder,
      }}
      units={rightAddon ?? ''}
      // prevent inputs to be changed by wheel inputs on desktop clients
      // as onWheel and onScroll fire concurrently
      // https://github.com/mui/material-ui/issues/7960#issuecomment-1076959490
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
    />
  );
};
