// Base on https://github.com/kgnugur/formik-chakra-ui/blob/master/src/form-checkbox/checkbox-single.tsx
import { Checkbox, CheckboxProps, forwardRef } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

import { BaseProps, FormControl } from '../../FormControl';

export type CheckboxSingleControlProps = BaseProps & {
  checkBoxProps?: CheckboxProps;
  children?: React.ReactNode;
};

export const CheckboxSingleControl = forwardRef<
  CheckboxSingleControlProps,
  'input'
>((props, ref) => {
  const { name, label, children, checkBoxProps, ...rest } = props;
  const [field, { error, touched }] = useField(name);
  const isChecked = field.value;

  return (
    <FormControl name={name} {...rest}>
      <Checkbox
        {...field}
        id={name}
        isInvalid={!!error && touched}
        isChecked={isChecked}
        ref={ref}
        {...checkBoxProps}
      >
        {label}
        {children}
      </Checkbox>
    </FormControl>
  );
});
