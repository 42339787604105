import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ResponsiveModalProps } from '../../../Layout/ResponsiveModal';
import { DialogModal } from '../../DialogModal/DialogModal';

export type DeleteMediaType = 'video' | 'photoalbum' | 'photo' | 'post';

export type ConfirmMediaDeletionDialogProps = {
  deleteMedia: () => void;
  mediaType: DeleteMediaType | null;
  descriptionOverride?: string;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const ConfirmMediaDeletionDialog: React.FC<
  ConfirmMediaDeletionDialogProps
> = ({ deleteMedia, onClose, mediaType, descriptionOverride, ...rest }) => {
  const { t } = useTranslation(['videoLibrary', 'feed']);
  const leastDestructiveRef = useRef(null);

  const toastMessageByMediaType = {
    video: {
      heading: t('videoLibrary:heading.VideoWirklichUnwiederruflichLoschen'),
      primary: t('videoLibrary:button.VideoLoschen'),
    },
    photoalbum: {
      heading: t(
        'videoLibrary:heading.FotoAlbumWirklichUnwiederruflichLoschen'
      ),
      primary: t('videoLibrary:button.FotoAlbumLoschen'),
    },
    photo: {
      heading: t('videoLibrary:heading.FotoWirklichUnwiederruflichLoschen'),
      primary: t('videoLibrary:button.FotoLoschen'),
    },
    post: {
      heading: t('feed:heading.BeitragWirklichUnwiderruflichLoschen'),
      primary: t('feed:button.BeitragLoschen'),
    },
  };

  if (!mediaType) {
    onClose();
    return null;
  }

  return (
    <DialogModal
      headline={toastMessageByMediaType[mediaType].heading}
      text={
        descriptionOverride ??
        t(
          'videoLibrary:text.KundenDieDenContentBereitsGekauftHabenSehenIhnWeiterhin'
        )
      }
      confirmButtonText={toastMessageByMediaType[mediaType].primary}
      closeButtonText={t('videoLibrary:button.Abbrechen')}
      onConfirm={deleteMedia}
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      {...rest}
    />
  );
};
