import {
  OdiCard,
  OdiCardDescription,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardIcon,
  OdiCardTitle,
  OdiCardTop,
} from '@campoint/odi-ui';
import { LightBulbOutlined } from '@campoint/odi-ui-icons';
import { HStack, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import shield from '../../../../assets/images/shared/shield.svg';

export type TipCardProps = {
  text?: string;
  contentInsteadOfText?: React.ReactNode;
  buttonFooter?: React.ReactNode;
};

export const TipCard: React.FC<TipCardProps> = ({
  text,
  contentInsteadOfText,
  buttonFooter,
}) => {
  const { t } = useTranslation(['general']);
  return (
    <OdiCard>
      <OdiCardHeader color={'caribbeanGreen.500'}>
        <OdiCardTop children={<OdiCardIcon as={LightBulbOutlined} />} />
        <OdiCardTitle children={t('general:heading.UnserTipp')} />
        <OdiCardDescription
          children={contentInsteadOfText ?? <Text children={text} />}
        />
      </OdiCardHeader>
      {buttonFooter && <OdiCardFooter>{buttonFooter}</OdiCardFooter>}
    </OdiCard>
  );
};

export const HTipCard: React.FC<TipCardProps> = ({
  text,
  contentInsteadOfText,
  buttonFooter,
}) => {
  return (
    <OdiCard flexDirection={'row'} color={'caribbeanGreen.500'} gap={0}>
      <OdiCardTop
        children={<OdiCardIcon as={LightBulbOutlined} />}
        flex={'0 0'}
        p={3}
      />
      <OdiCardDescription
        color={'inherit'}
        pl={0}
        flex={'1 0'}
        textAlign={'left'}
        justifyContent={'start'}
        lineHeight={1.3}
        children={contentInsteadOfText ?? <Text children={text} />}
      />
      {buttonFooter && <OdiCardFooter>{buttonFooter}</OdiCardFooter>}
    </OdiCard>
  );
};

export const ShieldCard: React.FC<TipCardProps> = ({ text }) => {
  return (
    <HStack
      minH={'70px'}
      gap={0}
      w={'full'}
      maxW={['container.lg', 'full']}
      pb={6}
    >
      <Image w={'30px'} h={'30px'} src={shield} mr={2} />
      <Text
        color={'caribbeanGreen.500'}
        fontWeight={'normal'}
        fontSize={'sm'}
        lineHeight={1.2}
      >
        {text}
      </Text>
    </HStack>
  );
};
