import { FormControl, FormErrorMessage, useDisclosure } from '@chakra-ui/react';
import { FormikHelpers } from 'formik/dist/types';
import React from 'react';

import { usePostCommenting } from '../../../provider/PostCommentingProvider';
import { noop } from '../../../utils';
import { CharacterCountIndicator } from '../CharacterCountIndicator/CharacterCountIndicator';
import { ConfirmCommentEditChangesDialog } from '../dialogs/ConfirmCommentEditChangesDialog/ConfirmCommentEditChangesDialog';
import {
  FeedCommentEditGrid,
  FeedCommentGridItemCount,
  FeedCommentGridItemError,
  FeedCommentGridItemInput,
  FeedCommentGridItemSlot,
} from './FeedCommentGrid';
import { FeedCommentTextarea } from './FeedCommentTextarea';
import {
  CommentAvatar,
  CommentBox,
  CommentContent,
  CommentUsername,
  FeedCommentEditCancelIconButton,
  FeedCommentEditSubmitIconButton,
} from './shared';
import {
  CommentTextFormikValues,
  useCommentTextFormik,
} from './useCommentTextFormik';

export type FeedCommentEditProps = {
  avatarSrc?: string;
  username?: string;
  initialText?: string;
  onDiscard: () => void;
  onSubmit: (
    values: CommentTextFormikValues,
    formikHelpers: FormikHelpers<CommentTextFormikValues>
  ) => void | Promise<void>;
};

export const FeedCommentEdit: React.FC<FeedCommentEditProps> = ({
  avatarSrc,
  username,
  initialText = '',
  onSubmit,
  onDiscard,
}) => {
  const ctx = usePostCommenting();

  const {
    currentCharacterCount,
    maxCharacterCount,
    textareaFieldError,
    textareaFieldProps,
    formik,
  } = useCommentTextFormik({
    initialText,
    onSubmit,
  });

  const isDirty = formik.dirty;

  React.useEffect(
    () => (!isDirty ? noop : ctx.action.registerOpenComment()),
    [isDirty, ctx.action]
  );

  const confirmChangesDisclosure = useDisclosure();

  return (
    <CommentBox>
      <CommentAvatar src={avatarSrc} />
      <CommentContent>
        <CommentUsername children={username} />
        <FormControl isInvalid={!!textareaFieldError}>
          <FeedCommentEditGrid>
            <FeedCommentGridItemInput>
              <FeedCommentTextarea {...textareaFieldProps} />
            </FeedCommentGridItemInput>
            <FeedCommentGridItemSlot>
              <FeedCommentEditCancelIconButton
                isDisabled={formik.isSubmitting}
                onClick={!isDirty ? onDiscard : confirmChangesDisclosure.onOpen}
              />
              <FeedCommentEditSubmitIconButton
                isDisabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  currentCharacterCount === 0 ||
                  !isDirty
                }
                onClick={formik.submitForm}
              />
            </FeedCommentGridItemSlot>
            <FeedCommentGridItemError>
              <FormErrorMessage>{textareaFieldError}</FormErrorMessage>
            </FeedCommentGridItemError>
            <FeedCommentGridItemCount>
              <CharacterCountIndicator
                count={currentCharacterCount}
                maxCount={maxCharacterCount}
              />
            </FeedCommentGridItemCount>
          </FeedCommentEditGrid>
        </FormControl>
      </CommentContent>
      <ConfirmCommentEditChangesDialog
        isOpen={confirmChangesDisclosure.isOpen}
        onClose={confirmChangesDisclosure.onClose}
        navigateBack={onDiscard}
      />
    </CommentBox>
  );
};
