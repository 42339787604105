import {
  Section,
  SectionBody,
  SectionDescription,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Textsms } from '@campoint/odi-ui-icons';
import { Divider, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SectionCenterContainer } from '../../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../../components/Layout/SectionDivider';
import { TextAreaControl } from '../../../../../components/shared/HookFormForms/TextareaControl/TextareaControl';
import {
  ProfileFieldTypeMultilangStringFragment,
  ProfileFieldsEnum,
} from '../../../../../generated/graphql';
import { removeCarriageReturnsInText } from '../../../Texts/utils';
import { InterviewProgressCard } from '../../../components/InterviewProgressCard/InterviewProgressCard';
import { LabelTextWithGuideDotHookForm } from '../../../components/LabelTextWithGuideDot/LabelTextWithGuideDot';
import { useModelProfile } from '../../../provider/ModelProfileProvider';
import {
  extractDirtyValues,
  extractInitialValuesFromFields,
  extractValidationSchema,
} from '../../../provider/utils';
import { useFieldInitialErrors } from '../../utils';
import { SubmitButton } from '../ModelProfileAboutMeSection/ModelProfileAboutMeSection';

export const ModelProfileInterviewSection: React.FC = () => {
  const [isSetup, setIsSetup] = React.useState(false);
  const minimumCompletedInterviewQuestions = 3;

  const [t] = useTranslation(['language', 'modelProfile']);
  const unknownError = t('modelProfile:fields.unknownError.unknownError');

  const {
    interviewCollection,
    interviewCollectionError,
    isSaveDataLoading,
    actions: { saveProfileData, setIsDataDirty },
  } = useModelProfile();

  const completeness = interviewCollection?.completeness;

  const fields = useMemo(
    () => interviewCollection?.fields ?? {},
    [interviewCollection]
  );

  const initialErrors = useFieldInitialErrors(
    interviewCollectionError,
    unknownError,
    true
  );

  const {
    modelInterviewPartner,
    modelInterviewIsland,
    modelInterviewEnterprise,
    modelInterviewHobbies,
    modelInterviewSexualPosition,
    modelInterviewSexualFantasies,
    modelInterviewSexNotOk,
    modelInterviewSexOk,
    modelInterviewEroticExperience,
  } = fields;

  const initialValues = useMemo(
    () => extractInitialValuesFromFields(fields, true),
    [fields]
  );

  const validationSchema = React.useMemo(
    () =>
      extractValidationSchema(fields, {
        modelInterviewPartner: {
          isOptional: true,
        },
        modelInterviewIsland: {
          isOptional: true,
        },
        modelInterviewEnterprise: {
          isOptional: true,
        },
        modelInterviewHobbies: {
          isOptional: true,
        },
        modelInterviewSexualPosition: {
          isOptional: true,
        },
        modelInterviewSexualFantasies: {
          isOptional: true,
        },
        modelInterviewSexNotOk: {
          isOptional: true,
        },
        modelInterviewSexOk: {
          isOptional: true,
        },
        modelInterviewEroticExperience: {
          isOptional: true,
        },
      }),
    [fields]
  );

  const hookForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
    resetOptions: {
      keepDirtyValues: true,
      keepIsSubmitted: true,
    },
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);

    // Workaround for act warning
    // wait until intital async validation of hook-form is completed before moving on.
    // https://react-hook-form.com/advanced-usage#TestingForm
    // https://github.com/orgs/react-hook-form/discussions/4232
    setTimeout(() => setIsSetup(true), 0);
  }, [hookForm, initialValues, setIsSetup]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (isSaveDataLoading) {
        return;
      }

      const currentModelInterviewData = {
        modelInterviewPartner: data.modelInterviewPartner,
        modelInterviewIsland: data.modelInterviewIsland,
        modelInterviewEnterprise: data.modelInterviewEnterprise,
        modelInterviewHobbies: data.modelInterviewHobbies,
        modelInterviewSexualPosition: data.modelInterviewSexualPosition,
        modelInterviewSexualFantasies: data.modelInterviewSexualFantasies,
        modelInterviewSexNotOk: data.modelInterviewSexNotOk,
        modelInterviewSexOk: data.modelInterviewSexOk,
        modelInterviewEroticExperience: data.modelInterviewEroticExperience,
      };

      const dirtyValues = extractDirtyValues(
        currentModelInterviewData,
        initialValues
      );

      const multiLangFields = Object.values(fields).map(
        (field: ProfileFieldTypeMultilangStringFragment | string | null) => {
          if (typeof field === 'string') {
            return field;
          }
          return field?.name;
        }
      );
      multiLangFields.forEach((multiLangField) => {
        if (!multiLangField) return;
        if (multiLangField in dirtyValues) {
          dirtyValues[multiLangField] = [
            {
              // make sure we never send null
              text: removeCarriageReturnsInText(data[multiLangField]) ?? '',
              translate: true,
            },
          ];
        }
      });

      saveProfileData({
        interviewData: dirtyValues,
      });
    },
    [fields, initialValues, isSaveDataLoading, saveProfileData]
  );

  React.useEffect(() => {
    if (initialErrors) {
      Object.keys(initialErrors).forEach((name) => {
        hookForm.setError(name, {
          message: initialErrors[name as ProfileFieldsEnum] as any,
        });
      });
    }
  }, [hookForm, initialErrors]);

  React.useEffect(() => {
    setIsDataDirty(hookForm.formState.isDirty);
    return () => {
      setIsDataDirty(false);
    };
  }, [hookForm.formState.isDirty, setIsDataDirty]);

  return (
    <Section aria-busy={isSaveDataLoading}>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Textsms} />
            <SectionTitle fontSize={'xl'}>
              {t('modelProfile:heading.Interview')}
            </SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t(
              'modelProfile:text.DasInterviewGibtBesuchernDeinesProfilsEinAusfuhrlichesBildVonDirSoStXX'
            )}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(onSubmit)}>
          <SectionBody>
            <SectionCenterContainer>
              <VStack spacing={8} divider={<Divider size={'s'} />}>
                {completeness?.complete !== undefined &&
                completeness?.complete < minimumCompletedInterviewQuestions ? (
                  <InterviewProgressCard
                    requiredCount={minimumCompletedInterviewQuestions}
                    fulfilledCount={completeness?.complete}
                    borderColor={'primary.200'}
                  />
                ) : null}
                <TextAreaControl
                  name={modelInterviewPartner?.name ?? 'modelInterviewPartner'}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewPartner?.name ?? 'modelInterviewPartner'
                      }
                      children={t(
                        'modelProfile:text.WieSollteDeinTraumpartnerAussehen'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={modelInterviewPartner?.maxLength ?? 250}
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={modelInterviewIsland?.name ?? 'modelInterviewIsland'}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewIsland?.name ?? 'modelInterviewIsland'
                      }
                      children={t(
                        'modelProfile:text.WasWurdestDuAufEineEinsameInselMitnehmen'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={modelInterviewIsland?.maxLength ?? 250}
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={
                    modelInterviewEnterprise?.name ?? 'modelInterviewIsland'
                  }
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewEnterprise?.name ?? 'modelInterviewIsland'
                      }
                      children={t(
                        'modelProfile:text.WasWolltestDuSchonImmerMalMachen'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={modelInterviewEnterprise?.maxLength ?? 250}
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={modelInterviewHobbies?.name ?? 'modelInterviewHobbies'}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewHobbies?.name ?? 'modelInterviewHobbies'
                      }
                      children={t(
                        'modelProfile:text.WasMachstDuWennDuGeradeNichtOnlineBist'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={modelInterviewHobbies?.maxLength ?? 250}
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={
                    modelInterviewSexualPosition?.name ??
                    'modelInterviewSexualPosition'
                  }
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewSexualPosition?.name ??
                        'modelInterviewSexualPosition'
                      }
                      children={t(
                        'modelProfile:text.WasIstDeineLieblingsstellung'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={
                    modelInterviewSexualPosition?.maxLength ?? 250
                  }
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={
                    modelInterviewSexualFantasies?.name ??
                    'modelInterviewSexualFantasies'
                  }
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewSexualFantasies?.name ??
                        'modelInterviewSexualFantasies'
                      }
                      children={t(
                        'modelProfile:text.WelcheSexphantasienHastDu'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={
                    modelInterviewSexualFantasies?.maxLength ?? 250
                  }
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={
                    modelInterviewSexNotOk?.name ?? 'modelInterviewSexNotOk'
                  }
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewSexNotOk?.name ?? 'modelInterviewSexNotOk'
                      }
                      children={t(
                        'modelProfile:text.WasMagstDuBeimSexUberhauptNicht'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={modelInterviewSexNotOk?.maxLength ?? 250}
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={modelInterviewSexOk?.name ?? 'modelInterviewSexOk'}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={modelInterviewSexOk?.name ?? 'modelInterviewSexOk'}
                      children={t('modelProfile:text.WasMagstDuBeimSex')}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={modelInterviewSexOk?.maxLength ?? 250}
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
                <TextAreaControl
                  name={
                    modelInterviewEroticExperience?.name ??
                    'modelInterviewEroticExperience'
                  }
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelInterviewEroticExperience?.name ??
                        'modelInterviewEroticExperience'
                      }
                      children={t(
                        'modelProfile:text.WasWarDeinAufregendstesErotischesErlebnis'
                      )}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.BeschreibungEingeben'
                  )}
                  maxCharacterCount={
                    modelInterviewEroticExperience?.maxLength ?? 250
                  }
                  textAriaProps={{
                    rows: 3,
                    role: 'textbox',
                  }}
                />
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          <SectionDivider isWidthRestricted />
          <SectionFooter>
            <SectionCenterContainer>
              <SubmitButton
                isSetup={isSetup}
                isSaveDataLoading={isSaveDataLoading}
              />
            </SectionCenterContainer>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};
