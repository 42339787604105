import { Box, Center, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PercentProgressSpinner } from '../../../../../../components/shared/GradientSpinner/GradientSpinner';
import {
  UploadJobEntityType,
  useUploadManagerJob,
} from '../../../../../../provider/UploadManagerProvider';
import { useUploadyService } from '../../../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';

export const FeedUploadProgress: React.FC<{ postId: string }> = ({
  postId,
}) => {
  const { t } = useTranslation(['feed']);
  const uploadingJob = useUploadManagerJob(UploadJobEntityType.POST, postId);

  return (
    <Center bg={'steel'} color={'primary.500'}>
      <VStack>
        <PercentProgressSpinner
          percentProgress={uploadingJob?.progress ?? undefined}
          percentProgressLabelColor={'gray.900'}
        />
        <Box>{t('feed:text.BeitragWirdHochgeladen')}</Box>
      </VStack>
    </Center>
  );
};

export const FeedTusUploadProgress: React.FC<{
  umaId: string | null | undefined;
}> = ({ umaId }) => {
  const { t } = useTranslation(['feed']);
  const { feedUploads } = useUploadyService();
  const currentUpload = feedUploads.find(
    (upload) => Number(upload.umaId) === Number(umaId)
  );
  const currentUploadProgress = currentUpload?.progress;

  return (
    <Center bg={'steel'} color={'primary.500'}>
      <VStack>
        <PercentProgressSpinner
          percentProgress={currentUploadProgress}
          percentProgressLabelColor={'gray.900'}
        />
        <Box>{t('feed:text.BeitragWirdHochgeladen')}</Box>
      </VStack>
    </Center>
  );
};

export const FeedPostEdited: React.FC = () => {
  const { t } = useTranslation(['feed']);
  return (
    <Center bg={'steel'} color={'primary.500'}>
      <VStack>
        <PercentProgressSpinner percentProgress={undefined} />
        <Box>{t('feed:heading.BeitragWirdVorbereitet')}</Box>
      </VStack>
    </Center>
  );
};
