import { OdiCard, OdiCardProps } from '@campoint/odi-ui';
import {
  Box,
  BoxProps,
  Circle,
  Heading,
  HeadingProps,
  Icon,
  IconProps,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';

export const LargeIconTextContainer: React.FC<StackProps> = (props) => (
  <VStack spacing={0} alignItems={'start'} {...props} />
);

export const LargeIconCardTitle: React.FC<HeadingProps> = (props) => (
  <Heading size={'xl'} as={'h3'} {...props} />
);

export const LargeIconCardSubTitle: React.FC<HeadingProps> = (props) => (
  <Heading size={'xs'} letterSpacing={'0.03em'} {...props} />
);

export const LargeIconCardDescription: React.FC<TextProps> = (props) => (
  <Text {...props} />
);

type LargeIconCardIconProps = {
  icon: EmotionIcon | undefined;
  iconContainerProps?: BoxProps;
  iconProps?: IconProps;
};

export const LargeIconCardDot: React.FC<BoxProps> = (props) => (
  <Circle
    size={4}
    position={'absolute'}
    top={2}
    right={2}
    bg={'success.highEmphasis'}
    {...props}
  />
);

export const LargeIconCardIcon: React.FC<LargeIconCardIconProps> = ({
  icon,
  iconContainerProps,
  iconProps,
}) => (
  <Box
    w={14}
    h={14}
    bg={'steel'}
    borderRadius="lg"
    p={3}
    flexShrink={'0'}
    {...iconContainerProps}
  >
    <Icon as={icon} boxSize={'full'} color={'darkSteel'} {...iconProps} />
  </Box>
);

export const LargeIconCard: React.FC<OdiCardProps> = (props) => (
  <OdiCard
    borderColor={'steel'}
    py={6}
    px={4}
    alignItems={'center'}
    flexDir={'row'}
    gap={4}
    {...props}
  />
);
