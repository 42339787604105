import { Box, BoxProps, forwardRef } from '@chakra-ui/react';

export const PageSection = forwardRef<BoxProps, 'section'>((props, ref) => (
  <Box
    as={'section'}
    bg={'surface'}
    p={{ base: 4, lg: 8 }}
    ref={ref}
    {...props}
  />
));
