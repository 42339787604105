import { useFormik } from 'formik';
import { FormikConfig } from 'formik/dist/types';
import * as Yup from 'yup';

import { noop } from '../../../utils';
import { createFeedCommentValidation } from '../../../utils/validation';

const commentFieldName = 'text' as const;

export type CommentTextFormikValues = { [commentFieldName]: string };
type UseCommentTextFormikOptions = {
  initialText?: string;
  maxLength?: number;
} & Partial<FormikConfig<CommentTextFormikValues>>;
export const useCommentTextFormik = (options: UseCommentTextFormikOptions) => {
  const { initialText = '', maxLength = 500, ...formikConfig } = options;
  const formik = useFormik({
    initialValues: {
      [commentFieldName]: initialText,
    },
    validationSchema: Yup.object().shape({
      [commentFieldName]: createFeedCommentValidation({
        maxLength,
      }),
    }),
    validateOnChange: true,
    validateOnBlur: false,
    // should be overridden by user in formikConfig
    onSubmit: noop,
    ...formikConfig,
  });

  return {
    textareaFieldProps: formik.getFieldProps(commentFieldName),
    textareaFieldError: formik.errors?.[commentFieldName],
    currentCharacterCount: formik.values?.[commentFieldName]?.length ?? 0,
    currentValue: formik.values?.[commentFieldName] ?? '',
    maxCharacterCount: maxLength,
    formik,
  };
};
