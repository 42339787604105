import {
  OdiCard,
  OdiCardCloseButton,
  OdiCardDescription,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardIcon,
  OdiCardProps,
  OdiCardTitle,
  OdiCardTop,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { Button, Icon, Text, VStack } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import { MediaPreviewBox } from '../../../../../components/shared/MediaPreviewBox/MediaPreviewBox';
import { MediaUploadCropperPortal } from '../../../../../components/shared/MediaUpload/MediaUpload';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { useActiveApiLanguage } from '../../../../../hooks/useActiveApiLanguage';
import { MediaInputProvider } from '../../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { externalRoutes } from '../../../../../routes/routesConfig';
import { extractErrorCodeMap, uploadImage } from '../../../../../utils/media';
import { ImageRatings } from '../../../../ModelProfileV2Page/ModelProfilePage/types';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { useWizardProfile } from '../../WizardProfileContext';

const HintSection: React.FC = () => {
  const { t } = useTranslation(['general', 'wizardProfile']);
  return <TipCard text={t('wizardProfile:image.hintText')} />;
};

const RejectCard: React.FC<
  OdiCardProps & {
    rejectReason: React.ReactNode;
    onClose?: () => void;
  }
> = ({ rejectReason, onClose, ...cardProps }) => {
  const apiLang = useActiveApiLanguage();
  const { t } = useTranslation(['general', 'wizardProfile']);

  const openVxModelsZendeskImageRules = React.useCallback(() => {
    window.open(externalRoutes.vxModelsImageRules(apiLang), '_blank');
  }, [apiLang]);

  return (
    <OdiCard {...cardProps}>
      <OdiCardHeader>
        <OdiCardTop
          children={<OdiCardIcon as={icons.Error} color={'error.500'} />}
        />
        <OdiCardTitle children={t('wizardProfile:image.rejectedHeader')} />
        <OdiCardDescription children={<Text children={rejectReason} />} />
        <OdiCardCloseButton
          aria-label={t('general:button.Schliessen')}
          onClick={onClose}
        />
      </OdiCardHeader>
      <OdiCardFooter>
        <Button onClick={openVxModelsZendeskImageRules}>
          {t('general:button.MehrErfahren')}
        </Button>
      </OdiCardFooter>
    </OdiCard>
  );
};
export const WizardProfileOnboardingV1Avatar: React.FC = () => {
  const wizardCtx = useWizardProfile();
  const { modelPictureAvatar } = wizardCtx?.currentField ?? {};

  const { t } = useTranslation([
    'wizardProfile',
    'imageUpload',
    'imagePicker',
    'wizardPayout',
    'general',
  ]);
  const [imageBlob, setImageBlob] = useState<null | Blob>();
  const [isUploading, setIsUploading] = useState(false);

  const [showRejectModal, setShowRejectModal] = useState(true);

  const performSkipStep = React.useCallback(async () => {
    wizardCtx.wizardNextStepCallback();
  }, [wizardCtx]);

  const rejectReason = modelPictureAvatar?.rejectReason ?? null;

  const uploadUrl = modelPictureAvatar?.upload?.url ?? null;
  const preExistingImageSrc = modelPictureAvatar?.image?.image?.src ?? null;

  const uploadErrorCodes = extractErrorCodeMap(modelPictureAvatar?.upload);

  const uploadImageCallback = useCallback(async () => {
    if (!!uploadUrl && imageBlob) {
      try {
        setIsUploading(true);
        const { errorCode } = await uploadImage(uploadUrl, imageBlob);
        if (errorCode) {
          issueChakraToast({
            status: 'error',
            description:
              uploadErrorCodes.get(errorCode) ?? t('imageUpload:error.generic'),
          });
        } else {
          wizardCtx.wizardNextStepCallback();
        }
      } catch {
        issueChakraToast({
          status: 'error',
          // fixme! translate toast error
          description: 'Error uploading image',
        });
      } finally {
        setIsUploading(false);
      }
    }
  }, [uploadUrl, imageBlob, setIsUploading, wizardCtx, t, uploadErrorCodes]);

  const imageEditOrAddStatus = preExistingImageSrc ? 'edit' : 'add';
  const imageStatus = isUploading ? 'loading' : imageEditOrAddStatus;

  return (
    <WizardParentModalStepLayout
      contentSection={
        <>
          <WizardSubHeader headerText={t('wizardProfile:image.header')} />

          {rejectReason && showRejectModal && (
            <RejectCard
              mb={8}
              rejectReason={rejectReason}
              onClose={() => setShowRejectModal(false)}
            />
          )}

          <MediaProvider
            isEditable={true}
            onReplacement={(media) => {
              setImageBlob(media?.blob ?? null);
            }}
            initialSrc={modelPictureAvatar?.image?.image?.src}
            initialSrcSet={modelPictureAvatar?.image?.image?.srcset}
            status={modelPictureAvatar?.image?.status}
          >
            <MediaPropertiesProvider.ForProfilePicture
              imageRating={ImageRatings.FSK16}
            >
              <MediaInputProvider
                accept={'DEFAULT_FOR_PICTURE'}
                capture={'user'}
              >
                <MediaPreviewBox
                  isUploading={isUploading}
                  aspectRatioProps={{ w: { base: '144px', lg: '144px' } }} // 24 base
                  iconButtonProps={{
                    icon:
                      imageStatus === 'edit' ? (
                        <Icon as={icons.Edit} boxSize={'icon.md'} />
                      ) : (
                        <Icon as={icons.AddPhoto} boxSize={'icon.md'} />
                      ),
                  }}
                />
                <MediaUploadCropperPortal />
              </MediaInputProvider>
            </MediaPropertiesProvider.ForProfilePicture>
          </MediaProvider>
        </>
      }
      hintSection={<HintSection />}
      footerSection={
        <VStack spacing={'2'}>
          <Button
            isLoading={isUploading}
            isDisabled={!imageBlob || isUploading}
            w={'full'}
            variant={'solid'}
            shadow={'none'}
            children={t('general:button.Weiter')}
            onClick={uploadImageCallback}
          />
          <Button
            isDisabled={isUploading}
            w={'full'}
            shadow={'none'}
            children={t('general:button.skip')}
            onClick={performSkipStep}
          />
        </VStack>
      }
    />
  );
};
