import { Stack, StackProps } from '@chakra-ui/react';
import * as React from 'react';

export const ButtonStack: React.FC<StackProps> = (props) => {
  return (
    <Stack
      direction={{ base: 'column', sm: 'row-reverse' }}
      alignItems={'stretch'}
      maxW={'container.lg'}
      flexWrap={'wrap-reverse'}
      w={'full'}
      {...props}
    />
  );
};
