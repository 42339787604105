import { ApolloError } from '@apollo/client';
import React from 'react';

import { FeatureName, useFeature } from '../../../flags';
import {
  VideoFragment,
  VideoGroupEnum,
  useGetVideoLibraryVideoUploadListQuery,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { useAuth } from '../../AuthProvider';
import { useDeleteMedia } from '../useDeleteMedia';

export type VideoLibraryVideoContext = {
  videos: VideoFragment[];
  marketingVideos: VideoFragment[];
  initialLoading: boolean;
  videosLoading: boolean;
  videosError: ApolloError | undefined;
  loadMore: () => void;
  onDelete: (albumId: number) => void;
};

export const [, useVideoLibraryVideoContext, videoLibraryVideoContext] =
  createContext<VideoLibraryVideoContext>({
    name: 'VideoLibraryVideoProvider',
    errorMessage:
      'useVideoLibraryVideoContext: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const VideoLibraryVideoProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const authCtx = useAuth();
  const fetchMoreVideoCount = 20;
  const [videos, setVideos] = React.useState<VideoFragment[]>([]);
  const [initialLoading, setInitialLoading] = React.useState(true);

  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  const skip = !authCtx.isAuthenticated || !videoLibraryFeatureFlag;

  const {
    data,
    fetchMore,
    loading: videosLoading,
    error: videosError,
  } = useGetVideoLibraryVideoUploadListQuery({
    variables: {
      count: fetchMoreVideoCount,
      cursor: '',
      group: VideoGroupEnum.Released,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const {
    data: marketingData,
    loading: marketingLoading,
    refetch: marketingRefetch,
  } = useGetVideoLibraryVideoUploadListQuery({
    variables: {
      count: fetchMoreVideoCount,
      cursor: '',
      group: VideoGroupEnum.Marketing,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const marketingVideos = React.useMemo(() => {
    return (marketingData?.media?.videos?.videosList?.edges?.map((el) => {
      return el?.node;
    }) ?? []) as VideoFragment[];
  }, [marketingData?.media?.videos?.videosList?.edges]);

  const loadMore = React.useCallback(() => {
    const hasNextPage = data?.media?.videos.videosList.pageInfo?.hasNextPage;

    if (hasNextPage && !videosError) {
      const lastPost =
        data?.media?.videos?.videosList?.edges[
          data?.media?.videos?.videosList?.edges?.length - 1
        ];
      fetchMore({
        variables: {
          count: fetchMoreVideoCount,
          cursor: lastPost?.cursor,
        },
      });
    }
  }, [
    data?.media?.videos.videosList.pageInfo?.hasNextPage,
    data?.media?.videos.videosList?.edges,
    videosError,
    fetchMore,
  ]);

  const onDeleteSuccess = (albumId: number) => {
    setVideos((prevVideos: VideoFragment[]) =>
      prevVideos.filter((item) => item.albumId !== albumId)
    );
    if (marketingVideos.some((video) => video.albumId === albumId)) {
      marketingRefetch();
    }
  };

  const onDelete = useDeleteMedia({
    onSuccess: onDeleteSuccess,
    mediaType: 'video',
  });

  React.useEffect(() => {
    if (!videosLoading && !marketingLoading) {
      setInitialLoading(false);
    }
  }, [marketingLoading, videosLoading]);

  React.useEffect(() => {
    const fetchedVideos = (data?.media?.videos?.videosList?.edges?.map(
      (el) => el?.node
    ) ?? []) as VideoFragment[];
    if (fetchedVideos) {
      setVideos(fetchedVideos);
    }
  }, [data?.media?.videos?.videosList?.edges, videosLoading]);

  const context: VideoLibraryVideoContext = React.useMemo(() => {
    return {
      videos,
      marketingVideos,
      initialLoading,
      videosLoading,
      videosError,
      loadMore,
      onDelete,
    };
  }, [
    initialLoading,
    loadMore,
    marketingVideos,
    onDelete,
    videos,
    videosError,
    videosLoading,
  ]);

  return (
    <videoLibraryVideoContext.Provider value={context}>
      {children}
    </videoLibraryVideoContext.Provider>
  );
};
