import {
  Section,
  SectionBody,
  SectionDescription,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { AccountCircle } from '@campoint/odi-ui-icons';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { SectionCenterContainer } from '../../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../../components/Layout/SectionDivider';
import {
  MaxChoicesErrorMessage,
  MinChoicesErrorMessage,
  RequiredErrorMessage,
  TooOldErrorMessage,
  TooYoungErrorMessage,
} from '../../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { FormControlHeaderStack } from '../../../../../components/shared/FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { InlineGuideDot } from '../../../../../components/shared/GuideDot/GuideDot';
import { ClearableInputControl } from '../../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { EnumRadioField } from '../../../../../components/shared/HookFormForms/EnumRadioField/EnumRadioField';
import { EnumSelectField } from '../../../../../components/shared/HookFormForms/EnumSelectField/EnumSelectField';
import { MultiSelectControl } from '../../../../../components/shared/HookFormForms/MultiSelectControl/MutliSelectControl';
import { TextAreaControl } from '../../../../../components/shared/HookFormForms/TextareaControl/TextareaControl';
import { TipBanner } from '../../../../../components/shared/cards/TipBanner/TipBanner';
import { ProfileFieldsEnum } from '../../../../../generated/graphql';
import { useToday } from '../../../../../provider/TodayProvider';
import { calculateAge } from '../../../../../test/date-utils';
import { removeCarriageReturnsInText } from '../../../Texts/utils';
import { LabelTextWithGuideDotHookForm } from '../../../components/LabelTextWithGuideDot/LabelTextWithGuideDot';
import { useModelProfile } from '../../../provider/ModelProfileProvider';
import {
  extractDirtyValues,
  extractInitialValuesFromFields,
  extractValidationSchema,
} from '../../../provider/utils';
import { useFieldInitialErrors } from '../../utils';

const minimumAge = 18;
const maximumAge = 75;

export const SubmitButton: React.FC<{
  isSetup?: boolean;
  isSaveDataLoading?: boolean;
}> = ({ isSetup, isSaveDataLoading }) => {
  const [t] = useTranslation(['general', 'modelProfile', 'validation']);
  const { isValid, isDirty, isSubmitting, isValidating } = useFormState();
  return (
    <Button
      data-is-setup={isSetup}
      alignSelf={'center'}
      variant={'solid'}
      type={'submit'}
      children={t('modelProfile:button.SpeichernVeroffentlichen')}
      isDisabled={!(isValid && isDirty)}
      isLoading={isSubmitting || isValidating || isSaveDataLoading}
    />
  );
};
const MAX_SPOKEN_LANGUAGE_COUNT = 3;

export const ModelProfileAboutMeSection: React.FC = () => {
  const [t] = useTranslation(['general', 'modelProfile', 'validation']);
  const unknownError = t('modelProfile:fields.unknownError.unknownError');
  const [isSetup, setIsSetup] = React.useState(false);

  const {
    aboutMeCollection,
    aboutMeCollectionError,
    isSaveDataLoading,
    isGuideDotVisible,
    actions: { saveProfileData, setIsDataDirty },
  } = useModelProfile();

  const { todayDayStamp } = useToday();

  const maxDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: minimumAge }).toISODate(),
    [todayDayStamp]
  );

  const minDate: string = React.useMemo(
    () =>
      DateTime.fromISO(todayDayStamp).minus({ years: maximumAge }).toISODate(),
    [todayDayStamp]
  );

  const fields = useMemo(
    () => aboutMeCollection?.fields ?? {},
    [aboutMeCollection]
  );

  const initialErrors = useFieldInitialErrors(
    aboutMeCollectionError,
    unknownError,
    true
  );

  const {
    modelChatPractices,
    modelLanguage,
    modelGender,
    modelSexuality,
    modelRelationshipState,
    modelCountry,
    modelPostalCode,
    modelCity,
    modelUsername,
    modelBirthdate,
    modelMotto,
    modelAboutMe,
  } = fields;

  const initialValues = useMemo(
    () => extractInitialValuesFromFields(fields, true),
    [fields]
  );

  const validationSchema = useMemo(() => {
    /**
     * The implementation of the 'createEnumListValidationSchema' function currently results in a buggy validation.
     * Therefore, the modelLanguage & modelBirthdate & modelPostalCode & modelCity field validation isn't created via the 'extractValidationSchema' function.
     */
    const { modelLanguage, modelBirthdate, ...rest } = fields;

    /*
     * Jira Task https://campoint.atlassian.net/browse/VXMB-858
     * TODO Backend has to define valide Validation length for modelMotto, modelAboutMe and username
     * it is just a work around to extract some field and set minLength etc.
     */
    const overallSchema = extractValidationSchema(
      rest,
      {
        modelChatPractices: {
          isOptional: true,
        },
        modelRelationshipState: {
          isOptional: true,
        },
        modelUsername: { isOptional: true, minLength: 4 },
        modelPostalCode: {
          isOptional: true,
          isUnClearableOnceSet: true,
        },
        modelCity: {
          isOptional: true,
          isUnClearableOnceSet: true,
        },
        modelMotto: {
          isOptional: !fields?.modelMotto?.value,
        },
        modelAboutMe: {
          isOptional: true,
        },
      },
      true
    );

    const extraSchema = Yup.object().shape({
      modelLanguage: Yup.array()
        .min(1, ({ min }) => <MinChoicesErrorMessage choices={min} />)
        .max(MAX_SPOKEN_LANGUAGE_COUNT, ({ max }) => (
          <MaxChoicesErrorMessage choices={max} />
        )),
      modelBirthdate: Yup.string().test((value, testContext) => {
        if (value === '') {
          return testContext.createError({
            message: () => <RequiredErrorMessage />,
          });
        }
        if (value && calculateAge(value) < minimumAge) {
          return testContext.createError({
            message: () => <TooYoungErrorMessage years={minimumAge} />,
          });
        }
        if (value && calculateAge(value) > maximumAge) {
          return testContext.createError({
            message: () => <TooOldErrorMessage years={maximumAge} />,
          });
        }
        return true;
      }),
    });

    return overallSchema.concat(extraSchema);
  }, [fields]);

  const hookForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
    resetOptions: {
      keepDirtyValues: true,
      keepIsSubmitted: true,
    },
  });

  const currentSpokenLanguageCount =
    useWatch({
      name: modelLanguage?.name ?? 'modelLanguage',
      control: hookForm.control,
    })?.length ?? 0;

  React.useEffect(() => {
    hookForm.reset(initialValues);

    // Workaround for act warning
    // wait until intital async validation of hook-form is completed before moving on.
    // https://react-hook-form.com/advanced-usage#TestingForm
    // https://github.com/orgs/react-hook-form/discussions/4232
    setTimeout(() => setIsSetup(true), 0);
  }, [hookForm, initialValues, setIsSetup]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (isSaveDataLoading) {
        return;
      }

      const currentModelAboutMeData = {
        modelBirthdate: data.modelBirthdate,
        modelLanguage: data.modelLanguage,
        modelChatPractices: data.modelChatPractices,
        modelSexuality: data.modelSexuality,
        modelGender: data.modelGender,
        modelRelationshipState: data.modelRelationshipState,
        modelCountry: data.modelCountry,
        modelCity: data.modelCity,
        modelPostalCode: data.modelPostalCode,
        modelAboutMe: data.modelAboutMe,
        modelMotto: data.modelMotto,
      };

      const dirtyValues = extractDirtyValues(
        currentModelAboutMeData,
        initialValues
      );

      const multiLangFields = ['modelAboutMe', 'modelMotto'];
      multiLangFields.forEach((multiLangField) => {
        if (multiLangField in dirtyValues) {
          dirtyValues[multiLangField] = [
            {
              // make sure we never send null
              text: removeCarriageReturnsInText(data[multiLangField]) ?? '',
              translate: true,
            },
          ];
        }
      });

      saveProfileData({
        aboutMeData: dirtyValues,
      });
    },
    [initialValues, isSaveDataLoading, saveProfileData]
  );

  const hasSomeAddressField =
    !!modelCity || !!modelCountry || !!modelPostalCode;

  const modelBirthdateValue = useWatch({
    name: modelBirthdate?.name ?? 'modelBirthdate',
    control: hookForm.control,
  });

  const isSomeAddressFieldMissing = useWatch({
    name: [
      modelCountry?.name ?? 'modelCountry',
      modelPostalCode?.name ?? 'modelPostalCode',
      modelCity?.name ?? 'modelCity',
    ],
    control: hookForm.control,
  }).some((val: string) => val === '');

  React.useEffect(() => {
    if (initialErrors) {
      Object.keys(initialErrors).forEach((name) => {
        hookForm.setError(name, {
          message: initialErrors[name as ProfileFieldsEnum] as any,
        });
      });
    }
  }, [hookForm, initialErrors]);

  React.useEffect(() => {
    setIsDataDirty(hookForm.formState.isDirty);
    return () => {
      setIsDataDirty(false);
    };
  }, [hookForm.formState.isDirty, setIsDataDirty]);

  return (
    <Section aria-busy={isSaveDataLoading || isSetup}>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={AccountCircle} />
            <SectionTitle>{t('modelProfile:heading.UberMich')}</SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t(
              'modelProfile:text.DieseInformationenSiehtDeinKundeAufDenErstenBlickWennErDeinProfilBesXX'
            )}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(onSubmit)}>
          <SectionBody>
            <SectionCenterContainer>
              <VStack spacing={6} divider={<Divider />}>
                <VStack spacing={6} w={'full'} alignItems={'stretch'}>
                  <ClearableInputControl
                    name={modelUsername?.name ?? 'modelUsername'}
                    label={t('modelProfile:label.MeinNameAlsModel')}
                    placeholder={t(
                      'modelProfile:placeholder.ModelnameEingeben'
                    )}
                    isReadOnly={true}
                  />
                  <TipBanner
                    text={t(
                      'modelProfile:text.WillstDuDeinenNamenAndernBitteWendeDichAnDenSupport'
                    )}
                  />
                </VStack>
                <EnumRadioField
                  name={modelGender?.name ?? 'modelGender'}
                  isDisabled={modelGender?.disabled ?? false}
                  isReadOnly={modelGender?.readOnly ?? false}
                  field={modelGender}
                  label={t('modelProfile:label.IchBin')}
                />
                <EnumRadioField
                  name={modelSexuality?.name ?? 'modelSexuality'}
                  isDisabled={modelSexuality?.disabled ?? false}
                  isReadOnly={modelSexuality?.readOnly ?? false}
                  field={modelSexuality}
                  label={t('modelProfile:label.MeineOrientierung')}
                />
                {modelBirthdate && (
                  <VStack w={'full'} alignItems={'stretch'}>
                    <ClearableInputControl
                      isDisabled={modelBirthdate?.disabled ?? false}
                      isReadOnly={modelBirthdate?.readOnly ?? false}
                      name={modelBirthdate?.name ?? 'modelBirthdate'}
                      label={
                        <LabelTextWithGuideDotHookForm
                          name={modelBirthdate?.name ?? 'modelBirthdate'}
                          children={t(
                            'modelProfile:label.MeinGeburtsdatumAlsModel'
                          )}
                        />
                      }
                      text={t(
                        'modelProfile:text.WahleAmBestenDeinEchtesGeburtsdatumFallsDuDasNichtMochtestWeicheNichXX'
                      )}
                      type={'date'}
                      sx={{
                        '&::-webkit-date-and-time-value': {
                          textAlign: 'left',
                        },
                      }}
                      min={minDate}
                      max={maxDate}
                    />
                    <Text>
                      {Trans({
                        t,
                        i18nKey: 'modelProfile:text.DeinModelalter',
                        values: {
                          age: modelBirthdateValue
                            ? calculateAge(modelBirthdateValue)
                            : '--',
                        },
                        components: {
                          strong: <Text as={'strong'} fontWeight={'medium'} />,
                        },
                      })}
                    </Text>
                  </VStack>
                )}
                <TextAreaControl
                  name={modelMotto?.name ?? 'modelMotto'}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={modelMotto?.name ?? 'modelMotto'}
                      children={t(
                        'modelProfile:label.SoBegrusseIchBesucherAufMeinerVISITXSeite'
                      )}
                    />
                  }
                  placeholder={t('modelProfile:placeholder.BegrussungEingeben')}
                  maxCharacterCount={modelMotto?.maxLength ?? 50}
                  textAriaProps={{
                    rows: 3,
                  }}
                  text={t(
                    'modelProfile:text.MitWelchemSatzKonntestDuPotentielleKundenAufDeinerSeiteWillkommenHeiXX'
                  )}
                />
                <TextAreaControl
                  name={modelAboutMe?.name ?? 'modelAboutMe'}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={modelAboutMe?.name ?? 'modelAboutMe'}
                      children={t('modelProfile:label.EinPaarSatzeUberMich')}
                    />
                  }
                  placeholder={t(
                    'modelProfile:placeholder.ErzahleEtwasUberDich'
                  )}
                  maxCharacterCount={modelAboutMe?.maxLength ?? 1000}
                  textAriaProps={{
                    rows: 3,
                  }}
                  text={t(
                    'modelProfile:text.UberlegeDirWieDuDichAlsModelPrasentierenMochtestBeschreibeDichSoDassXX'
                  )}
                />
                <EnumRadioField
                  name={
                    modelRelationshipState?.name ?? 'modelRelationshipState'
                  }
                  field={modelRelationshipState}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={
                        modelRelationshipState?.name ?? 'modelRelationshipState'
                      }
                      children={t('modelProfile:label.MeinBeziehungsstatus')}
                    />
                  }
                  text={t(
                    'modelProfile:text.DuMusstNichtZwingendDieWahrheitSagenWichtigIstNurDassDichKundenNichtXX'
                  )}
                />
                {hasSomeAddressField && (
                  <VStack spacing={6} w={'full'} alignItems={'stretch'}>
                    <FormControl>
                      <FormControlHeaderStack>
                        <FormLabel
                          children={
                            <>
                              {t('modelProfile:label.MeinArbeitsplatz')}
                              <InlineGuideDot
                                ml={1}
                                isShown={
                                  isGuideDotVisible && isSomeAddressFieldMissing
                                }
                              />
                            </>
                          }
                        />
                        <FormHelperText
                          children={t(
                            'modelProfile:text.WoWohnstDuAlsModelMancheKundenStellenSichGerneVorDichInEchtZuTreffen'
                          )}
                        />
                      </FormControlHeaderStack>
                    </FormControl>
                    <EnumSelectField
                      name={modelCountry?.name ?? 'modelCountry'}
                      field={modelCountry}
                      label={
                        <LabelTextWithGuideDotHookForm
                          name={modelCountry?.name ?? 'modelCountry'}
                          children={t('modelProfile:label.Land')}
                        />
                      }
                      placeholder={t('modelProfile:placeholder.LandAuswahlen')}
                      optionLabels={{
                        groupLabel: t('general:optgroup.Lander'),
                        unPromotedGroupLabel: t(
                          'general:optgroup.WeitereLander'
                        ),
                      }}
                    />
                    <ClearableInputControl
                      name={modelPostalCode?.name ?? 'modelPostalCode'}
                      label={
                        <LabelTextWithGuideDotHookForm
                          name={modelPostalCode?.name ?? 'modelPostalCode'}
                          children={t('modelProfile:label.Postleitzahl')}
                        />
                      }
                      placeholder={t(
                        'modelProfile:placeholder.PostleitzahlEingeben'
                      )}
                    />
                    <ClearableInputControl
                      name={modelCity?.name ?? 'modelCity'}
                      label={
                        <LabelTextWithGuideDotHookForm
                          name={modelCity?.name ?? 'modelCity'}
                          children={t('modelProfile:label.Stadt')}
                        />
                      }
                      placeholder={t('modelProfile:placeholder.StadtEingeben')}
                    />
                    <TipBanner
                      text={t(
                        'modelProfile:text.ZuDeinerEigenenSicherheitEmpfehlenWirDirNichtDeinenWahrenStandortPreXX'
                      )}
                    />
                  </VStack>
                )}
                <MultiSelectControl
                  name={modelLanguage?.name ?? 'modelLanguage'}
                  field={modelLanguage}
                  // Can be disabled in case it reached its max limit
                  isDisabled={modelLanguage?.disabled ?? false}
                  isAllowedToAdd={
                    currentSpokenLanguageCount < MAX_SPOKEN_LANGUAGE_COUNT
                  }
                  isReadOnly={modelLanguage?.readOnly ?? false}
                  label={
                    <LabelTextWithGuideDotHookForm
                      name={modelLanguage?.name ?? 'modelLanguage'}
                      children={t(
                        'modelProfile:label.IchSprecheFolgendeSprachenSlot',
                        {
                          slot: `(${currentSpokenLanguageCount}/${MAX_SPOKEN_LANGUAGE_COUNT})`,
                        }
                      )}
                    />
                  }
                  placeholder={t('modelProfile:placeholder.SpracheAuswahlen')}
                  text={t(
                    'modelProfile:text.SprachenDieDuHierAuswahlstSolltestDuImLivechatUndImGesprachMitDemKunXX'
                  )}
                />
                <MultiSelectControl
                  name={modelChatPractices?.name ?? 'modelChatPractices'}
                  field={modelChatPractices}
                  isDisabled={modelChatPractices?.disabled ?? false}
                  isReadOnly={modelChatPractices?.readOnly ?? false}
                  label={t('modelProfile:label.ChatPraktiken')}
                  placeholder={t(
                    'modelProfile:placeholder.WahleDeineChatPraktikenAus'
                  )}
                  text={t(
                    'modelProfile:text.WasKannManBeiDirImChatErlebenDenkDaranDassMancheKundenDichDanachAussXX'
                  )}
                />
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          <SectionDivider isWidthRestricted />
          <SectionFooter>
            <SectionCenterContainer>
              <SubmitButton
                isSetup={isSetup}
                isSaveDataLoading={isSaveDataLoading}
              />
            </SectionCenterContainer>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};
