import { DoneAll, Error, Schedule } from '@campoint/odi-ui-icons';
import { HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';

export const StatusDone: React.FC<{
  text?: string;
  stackProps?: StackProps;
}> = ({ text, stackProps }) => {
  return (
    <HStack spacing={1} color={'lime.500'} {...stackProps}>
      <Icon as={DoneAll} boxSize={'icon.sm'} />
      <Text fontSize={'12px'} fontWeight={'medium'}>
        {text}
      </Text>
    </HStack>
  );
};
export const StatusPending: React.FC<{
  text?: string;
  stackProps?: StackProps;
}> = ({ text, stackProps }) => {
  return (
    <HStack spacing={1} color={'warning.500'} {...stackProps}>
      <Icon as={Schedule} boxSize={'icon.sm'} />
      <Text fontSize={'12px'} fontWeight={'medium'}>
        {text}
      </Text>
    </HStack>
  );
};
export const StatusError: React.FC<{
  text?: string;
  icon?: EmotionIcon;
  stackProps?: StackProps;
}> = ({ text, icon, stackProps }) => {
  return (
    <HStack spacing={1} color={'error.500'} {...stackProps}>
      <Icon as={icon ?? Error} boxSize={'icon.sm'} />
      <Text fontSize={'12px'} fontWeight={'medium'}>
        {text}
      </Text>
    </HStack>
  );
};
