import { OdiCard } from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  HTMLChakraProps,
  Icon,
  Image,
  Img,
  Text,
  keyframes,
} from '@chakra-ui/react';
import React from 'react';

import { MediaContestTypeEnum } from '../../../../../generated/graphql';
import { ContestFragment } from '../../../../../generated/graphql';
import { CONTEST_TILE_HEIGHT, CONTEST_TILE_WIDTH } from '../ContestUtils';

export type ContestTileBaseProps = HTMLChakraProps<'section'> & {
  contest: ContestFragment;
  positionInStack: number;
  contestBackground?: string | null | undefined;
  contestBackgroundGradient?: boolean | null | undefined;
  isMobile?: boolean;
  goToMedia: () => void;
};
export const ContestTileBase: React.FC<ContestTileBaseProps> = ({
  contest,
  positionInStack,
  contestBackground,
  contestBackgroundGradient = false,
  isMobile = false,
  goToMedia,
  ...props
}) => {
  const secondBackground = !contestBackgroundGradient
    ? 'rgba(0, 0, 0, 0.8)'
    : 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 1) 100%)';
  const background = contestBackground
    ? {
        background: 'url(' + contestBackground + '), ' + secondBackground,
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    : {};
  return (
    <OdiCard
      width={CONTEST_TILE_WIDTH}
      height={CONTEST_TILE_WIDTH}
      minWidth={CONTEST_TILE_WIDTH}
      minHeight={CONTEST_TILE_HEIGHT}
      borderColor={'steel'}
      padding={0}
      gap={0}
      overflow={'hidden'}
      scrollSnapStop={'always'}
      position={isMobile ? 'unset' : 'absolute'}
      display={'flex'}
      scrollSnapAlign={'center'}
      ml={isMobile ? 'unset' : positionInStack * 15 + 'px'}
      transform={`scale(${1 - Math.abs(positionInStack) * 0.05})`}
      zIndex={isMobile ? 'unset' : positionInStack + 10} // ensure zIndex is more than 0
      transition={'all 0.5s ease-out'}
      {...background}
      {...props}
    ></OdiCard>
  );
};

export const ContestTileHeaderImage: React.FC<{ contest: ContestFragment }> = ({
  contest,
}) => {
  return (
    <Img
      // placeholder image until new image format is added to CT
      src={contest.images.titleImage ?? ''}
      height={'70px'}
      width={'100%'}
    />
  );
};

const goldenTextAnim = keyframes`
  0% {
    background-position: 100% 100%, center center;
    mask-position: 100% 100%, 0 0;
  }
  100% {
    background-position: 0 0, center center;
    mask-position: 0 0, 0 0;
  }
`;
export const ContestGoldenText: React.FC<HTMLChakraProps<'p'>> = (props) => {
  return (
    <Text
      background={
        'linear-gradient(-40deg, transparent 45%, rgba(255, 255, 255, 0.5) 50%, transparent 55%), linear-gradient(#fcbd35 33%, #e09b5f 66%)'
      }
      backgroundSize={'300% 300%'}
      backgroundClip={'text'}
      animation={`${goldenTextAnim} 3s infinite ease-out`}
      p={2}
      {...props}
    />
  );
};
export const ContestMask: React.FC<HTMLChakraProps<'img'>> = (props) => {
  return (
    <Box>
      <Box
        style={{
          position: 'absolute',
          maskImage:
            'linear-gradient(-40deg, transparent 45%, black 50%, transparent 55%)',
          maskSize: '300% 300%',
          maskRepeat: 'no-repeat',
        }}
        animation={`${goldenTextAnim} 3s infinite ease-out`}
      >
        <Image
          style={{
            filter: 'grayscale(100%) brightness(1000)',
          }}
          {...props}
        />
      </Box>
      <Image {...props} />
    </Box>
  );
};

export const ContestIcon: React.FC<{ contest: ContestFragment }> = ({
  contest,
}) => {
  if (contest.type === MediaContestTypeEnum.Photo)
    return (
      <Icon
        as={icons.Image}
        boxSize="icon.lg"
        filter={'drop-shadow(0 0 2px rgba(0, 0, 0, 0.6))'}
      />
    );
  if (contest.type === MediaContestTypeEnum.Video)
    return (
      <Icon
        as={icons.Movie}
        boxSize="icon.lg"
        filter={'drop-shadow(0 0 2px rgba(0, 0, 0, 0.6))'}
      />
    );
  return null;
};
