import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../../Layout/ResponsiveModal';

export type ConfirmChangesDialogProps = {
  navigateBack: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const ConfirmChangesDialog: React.FC<ConfirmChangesDialogProps> = ({
  navigateBack,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(['general']);
  const leastDestructiveRef = useRef(null);
  return (
    <ResponsiveModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      preferredSize="sm"
      preferredSizeBreakpoint="sm"
      {...rest}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent justifyContent={'center'} p={'24px 16px'}>
        <Heading
          size="xl"
          as="h2"
          textAlign={'center'}
          children={t('general:heading.UngespeicherteAnderungen')}
        />
        <Text textAlign={'center'} p={'16px 0 32px 0'}>
          {t(
            'general:text.DeineAnderungenWurdenNochNichtGespeichertMochtestDuWirklichAbbrechen'
          )}
        </Text>
        <VStack minH="6rem" width={'fit-content'} m={'0 auto'} gap={'16px'}>
          <Button
            variant={'solid'}
            width={'full'}
            children={t('general:button.AnderungenVerwerfen')}
            onClick={navigateBack}
          />
          <Button
            width={'full'}
            children={t('general:button.WeiterBearbeiten')}
            onClick={onClose}
            ref={leastDestructiveRef}
          />
        </VStack>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
