import * as icons from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  Box,
  Button,
  Divider,
  FormLabel,
  HStack,
  Heading,
  Icon,
  SimpleGrid,
  Spacer,
  Switch,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AbsoluteFullCenter } from '../../components/Layout/AbsoluteFullCenter';
import { AbsoluteFullCenterLoadingSpinner } from '../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import { TagCloudControl } from '../../components/shared/FormElements/TagCloudControl/TagCloudControl';
import { TextAreaWithMaxCharCount } from '../../components/shared/FormElements/TextAreaWithMaxCharCount/TextAreaWithMaxCharCount';
import { ImageGalleryTag } from '../../components/shared/ImageGallery/components/ImageGalleryTag';
import { ImageStatus } from '../../components/shared/ImageStatus/ImageStatus';
import { ConfirmMediaDeletionDialog } from '../../components/shared/dialogs/ConfirmMediaDeletionDialog/ConfirmMediaDeletionDialog';
import {
  MediaPictureStatusEnum,
  PhotoalbumReleasedStatusEnum,
  PhotoalbumTypeEnum,
} from '../../generated/graphql';
import {
  OnDeletePhotoType,
  useVideoLibraryPhotoModalContext,
} from '../../provider/VideoLibraryProvider/VideoLibraryPhotoModalProvider/VideoLibraryPhotoModalProvider';

const fieldName = {
  title: 'albumTitle',
  description: 'albumDescription',
  tags: 'albumTags',
  onlineEnabled: 'albumOnlineEnabled',
} as const;

interface FormValues {
  [fieldName.title]: string;
  [fieldName.description]: string;
  [fieldName.tags]: string[];
  [fieldName.onlineEnabled]: boolean;
}

export const VideoLibraryPhotoUploadListEntryModal: React.FC = () => {
  const { t } = useTranslation(['photoEditModal']);
  const {
    isOpen,
    actions,
    photosData,
    initialLoading,
    photosDataLoading,
    hasNextPage,
    photoAlbum,
  } = useVideoLibraryPhotoModalContext();

  const [photoToDelete, setphotoToDelete] = React.useState<OnDeletePhotoType>(
    {}
  );
  const confirmDeleteDialogModal = useDisclosure();

  const initialValues = React.useMemo<FormValues>(() => {
    return {
      [fieldName.title]:
        photoAlbum?.textCollection?.visitxTitles?.at(0)?.text ?? '',
      [fieldName.description]:
        photoAlbum?.textCollection?.visitxDescriptions?.at(0)?.text ?? '',
      [fieldName.tags]:
        photoAlbum?.tagCollection?.map((tag) => tag?.value ?? '') ?? [],
      [fieldName.onlineEnabled]:
        photoAlbum?.releasedStatus === PhotoalbumReleasedStatusEnum.Online ??
        false,
    };
  }, [
    photoAlbum?.textCollection?.visitxTitles,
    photoAlbum?.textCollection?.visitxDescriptions,
    photoAlbum?.tagCollection,
    photoAlbum?.releasedStatus,
  ]);

  const onSubmit = async (data: any) => {
    const updateData = {
      online: data[fieldName.onlineEnabled],
    };

    actions.onUpdate({
      status: updateData.online
        ? PhotoalbumReleasedStatusEnum.Online
        : PhotoalbumReleasedStatusEnum.Offline,
    });
  };

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const maxTabs = 10;

  const onlineEnabled = hookForm.watch(fieldName.onlineEnabled);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={actions.closeModal}>
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox bg={'surface'} minH={'56px'}>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'transparent'}>
          {!initialLoading && photosData ? (
            <>
              <HStack>
                <Icon
                  as={icons.AddPhoto}
                  boxSize={'icon.sm'}
                  color={'primary'}
                />
                <Heading size={'sm'} fontWeight={'medium'} lineHeight={'20px'}>
                  {'Shop Album'}
                </Heading>
              </HStack>
              <Box color={'gray.500'} textStyle={'caption'}>
                <Text fontWeight={'normal'} lineHeight={'20px'}>
                  {t(
                    'photoEditModal:inputs.text.FurDieVeroffentlichungMussenMin4FotosInEinemAlbumSein'
                  )}
                </Text>
              </Box>
              <SimpleGrid columns={2} spacing={4} mt={6}>
                {photosData.map((photo) => {
                  return (
                    <AspectRatio ratio={16 / 9} key={photo?.umpId}>
                      <Box
                        w={'full'}
                        h={'full'}
                        backgroundImage={`url(${photo?.image.src})`}
                        backgroundSize={'contain'}
                        backgroundPosition={'center'}
                        backgroundRepeat={'no-repeat'}
                        backgroundColor={'#1D1915'}
                      >
                        <HStack
                          position={'absolute'}
                          top={0}
                          left={0}
                          p={'1'}
                          spacing={2}
                          alignItems={'start'}
                          w={'full'}
                        >
                          <HStack wrap={'wrap'} pointerEvents={'none'}>
                            {photo?.fsk && (
                              <ImageGalleryTag
                                key={photo?.umpId}
                                children={photo.fsk}
                              />
                            )}
                          </HStack>
                          {(photoAlbum.type !== PhotoalbumTypeEnum.Shop ||
                            photo?.status ===
                              MediaPictureStatusEnum.Rejected) && (
                            <Box
                              flex={1}
                              display={'flex'}
                              justifyContent={'flex-end'}
                              alignItems={'flex-start'}
                            >
                              <Icon
                                cursor={'pointer'}
                                color={'surface'}
                                as={icons.Close}
                                boxSize={'icon.md'}
                                onClick={() => {
                                  setphotoToDelete({
                                    albumId: photo?.umaId,
                                    pictureId: photo?.umpId,
                                  });
                                  confirmDeleteDialogModal.onOpen();
                                }}
                              />
                            </Box>
                          )}
                        </HStack>
                        <AbsoluteFullCenter
                          userSelect={'none'}
                          pointerEvents={'none'}
                        >
                          <ImageStatus status={photo?.status} />
                        </AbsoluteFullCenter>
                      </Box>
                    </AspectRatio>
                  );
                })}
              </SimpleGrid>
              {hasNextPage && !photosDataLoading && (
                <HStack justifyContent={'center'} mt={6}>
                  <Button
                    rightIcon={<Icon as={icons.Loop} boxSize={'icon.md'} />}
                    variant="link"
                    onClick={() => actions.loadMore()}
                  >
                    {t('photoEditModal:button.MehrAnzeigen')}
                  </Button>
                </HStack>
              )}
              {photosDataLoading && (
                <AbsoluteFullCenterLoadingSpinner
                  position={'relative'}
                  mt={6}
                />
              )}
              <Divider mt={6} />
              <FormProvider {...hookForm}>
                <VStack
                  mt={6}
                  alignItems={'stretch'}
                  w={'full'}
                  divider={<Divider />}
                  gap={4}
                >
                  <VStack alignItems={'stretch'} w={'full'} gap={6}>
                    <TextAreaWithMaxCharCount
                      label={t('photoEditModal:inputs.heading.Titel')}
                      name={fieldName.title}
                      maxCharacterCount={60}
                      textAriaProps={{
                        rows: 1,
                        placeholder: t(
                          'photoEditModal:inputs.text.TitelEingeben'
                        ),
                        isReadOnly: true,
                        cursor: 'not-allowed',
                      }}
                    />
                    <TextAreaWithMaxCharCount
                      label={t('photoEditModal:inputs.heading.Beschreibung')}
                      name={fieldName.description}
                      maxCharacterCount={200}
                      textAriaProps={{
                        rows: 3,
                        placeholder: t(
                          'photoEditModal:inputs.text.BeschreibeDeinAlbum'
                        ),
                        isReadOnly: true,
                        cursor: 'not-allowed',
                      }}
                    />
                  </VStack>
                  <TagCloudControl
                    label={t('photoEditModal:inputs.heading.Tags', {
                      tagCount: `(${
                        initialValues[fieldName.tags].length ?? 0
                      }/${maxTabs})`,
                    })}
                    isReadOnly={true}
                    name={fieldName.tags}
                    inputProps={{
                      pointerEvents: 'all',
                      cursor: 'not-allowed',
                    }}
                  />
                  <HStack>
                    <FormLabel
                      fontSize={'md'}
                      fontWeight={'medium'}
                      lineHeight={'5'}
                      letterSpacing={'normal'}
                      color={'coldGray.900'}
                    >
                      {t('photoEditModal:inputs.heading.Online')}
                    </FormLabel>
                    <Spacer />
                    <Switch
                      isChecked={onlineEnabled}
                      size={'lg'}
                      {...hookForm.register(fieldName.onlineEnabled)}
                    />
                  </HStack>
                  <Button
                    mt={2}
                    variant={'solid'}
                    type={'button'}
                    isLoading={hookForm.formState.isSubmitting}
                    onClick={(e) => {
                      hookForm.handleSubmit(onSubmit)(e);
                    }}
                  >
                    {t('photoEditModal:button.Aktualisieren')}
                  </Button>
                </VStack>
              </FormProvider>
            </>
          ) : (
            <AbsoluteFullCenterLoadingSpinner />
          )}
        </ResponsiveModalBodyBox>
        <ConfirmMediaDeletionDialog
          mediaType={'photo'}
          deleteMedia={() => {
            if (photoToDelete?.pictureId && photoToDelete.albumId) {
              actions.onDelete({
                albumId: photoToDelete.albumId,
                pictureId: photoToDelete.pictureId,
              });
            }
            confirmDeleteDialogModal.onClose();
          }}
          isOpen={confirmDeleteDialogModal.isOpen}
          onClose={() => {
            setphotoToDelete({});
            confirmDeleteDialogModal.onClose();
          }}
        />
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
