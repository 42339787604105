import { Provider } from '@rollbar/react';
import Rollbar from 'rollbar';

import ENV from '../environments/environment';

const isStorybookEnv = process.env['STORYBOOK'] === 'true';

const rollbarConfig = {
  accessToken: ENV.ROLLBAR_SECRET,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: ENV.ENVIRONMENT,
  enabled: ENV.PRODUCTION && !isStorybookEnv,
  payload: {
    client: {
      javascript: {
        code_version: ENV.PACKAGE_VERSION,
        source_map_enabled: true,
      },
    },
  },
};

export const rollbarInstance = new Rollbar(rollbarConfig);

export const RollbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <Provider instance={rollbarInstance}>{children}</Provider>;
