import React from 'react';
import { useParams } from 'react-router-dom';

import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { useGetDocumentsPageSubRouterQuery } from '../../../generated/graphql';
import { DocumentDetailProvider } from '../../../provider/DocumentDetailProvider/DocumentDetailProvider';
import { ActorDocumentDetailPage } from '../ActorDocumentDetailPage/ActorDocumentDetailPage';
import { DocumentDetailPage } from '../DocumentDetailPage/DocumentDetailPage';

export const DocumentsPageSubRouter: React.FC = () => {
  const params = useParams<{ userId: string }>();
  const parsedUserId = Number(params.userId);
  const userIdNumber = isNaN(parsedUserId) ? -1 : parsedUserId;

  const { data, loading } = useGetDocumentsPageSubRouterQuery({
    variables: {
      userId: userIdNumber,
    },
  });

  const redirectCase = React.useMemo(() => {
    const isHostAccount = userIdNumber === data?.account?.userId;

    if (isHostAccount) {
      return 'hostAccount';
    }

    const isOriginatorAccount =
      userIdNumber === data?.account?.originator?.userId;
    if (isOriginatorAccount) {
      return 'originatorAccount';
    }

    const isLeadActorAndNotOriginator =
      !data?.account?.originator && data?.account?.actor?.isLeadActor;

    if (isLeadActorAndNotOriginator) {
      return 'leadActorAndNotOriginator';
    }

    const leadActorIsOnlyActor =
      isLeadActorAndNotOriginator &&
      data?.account?.actors?.length === 1 &&
      data?.account?.actors?.[0]?.isLeadActor &&
      userIdNumber === data?.account?.actor?.userId;

    if (leadActorIsOnlyActor) {
      return 'leadActorIsOnlyActor';
    }

    const isActorMatchingUserId = userIdNumber === data?.account?.actor?.userId;
    if (isActorMatchingUserId) {
      return 'actor';
    }

    return null;
  }, [data, userIdNumber]);

  if (loading) {
    return <FluentPageLayout isContentLoading={loading} />;
  }

  if (
    redirectCase === 'actor' ||
    redirectCase === 'leadActorAndNotOriginator'
  ) {
    return (
      <DocumentDetailProvider userIdNumber={userIdNumber}>
        <ActorDocumentDetailPage />
      </DocumentDetailProvider>
    );
  }

  return (
    <DocumentDetailProvider userIdNumber={userIdNumber}>
      <DocumentDetailPage />
    </DocumentDetailProvider>
  );
};
