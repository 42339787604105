import { ApolloError } from '@apollo/client';
import React, { createContext, useContext, useMemo } from 'react';

import {
  AffiliateDetailsFragment,
  AffiliateEntryIntervalEnum,
  DetailedStatisticsFragment,
  GetDetailedStatisticsQueryVariables,
  StatisticsEntryIntervalEnum,
  useGetDetailedStatisticsQuery,
} from '../../../generated/graphql';
import { BarChartProvider } from './BarChart/BarChartContext';
import { DateProvider, useDateContext } from './DateContext';
import { PageHeaderProvider } from './PageHeaderContext';
import { SharingProvider } from './SharingContext';
import {
  StatisticsDetailedCategoryExtEnum,
  StatisticsDetailedCategoryExtEnumType,
  TabsProvider,
  useTabsContext,
} from './TabContext';
import { UserListProvider } from './UserListContext';

export const StatisticsPageProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <DateProvider>
      <TabsProvider>
        <StatisticsPageStatisticsProvider>
          {children}
        </StatisticsPageStatisticsProvider>
      </TabsProvider>
    </DateProvider>
  );
};

type StatisticsPageStatisticsContextProps = {
  data: DetailedStatisticsFragment;
  affiliateData: AffiliateDetailsFragment;
  loading: boolean;
  error: ApolloError | undefined;
};

const StatisticsPageStatisticsContext = createContext<
  StatisticsPageStatisticsContextProps | undefined
>(undefined);

/**
 *
 * This is currently used to get the detailed statistics for the barchart and header section.
 */
export const StatisticsPageStatisticsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { startDate, endDate } = useDateContext();
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];

  const getDetailedStatisticsQueryVariables = (
    tabId: StatisticsDetailedCategoryExtEnumType
  ): GetDetailedStatisticsQueryVariables => {
    const baseVariables = {
      startDate,
      endDate,
      interval: StatisticsEntryIntervalEnum['1D'],
      affiliateInterval: AffiliateEntryIntervalEnum['1D'],
    };
    switch (tabId) {
      case StatisticsDetailedCategoryExtEnum.LiveChat:
        return { ...baseVariables, liveChat: true };
      case StatisticsDetailedCategoryExtEnum.Feed:
        return { ...baseVariables, feed: true };
      case StatisticsDetailedCategoryExtEnum.VideoLibrary:
        return { ...baseVariables, videoLibrary: true };
      case StatisticsDetailedCategoryExtEnum.Messenger:
        return { ...baseVariables, messenger: true };
      case StatisticsDetailedCategoryExtEnum.PhoneService:
        return { ...baseVariables, phoneService: true };
      case StatisticsDetailedCategoryExtEnum.Misc:
        return { ...baseVariables, other: true };
      case StatisticsDetailedCategoryExtEnum.Telegram:
        return { ...baseVariables, telegram: true };
      case StatisticsDetailedCategoryExtEnum.Affiliate:
        return { ...baseVariables, affiliate: true };
      case StatisticsDetailedCategoryExtEnum.OverAll:
        return { ...baseVariables, overall: true, affiliate: true };
      default:
        return { ...baseVariables };
    }
  };

  const {
    data: barChartData,
    loading: barChartDataLoading,
    error: barChartDataError,
  } = useGetDetailedStatisticsQuery({
    variables: {
      ...getDetailedStatisticsQueryVariables(
        tabId as StatisticsDetailedCategoryExtEnumType
      ),
    },
  });

  const barChartDetailedData: DetailedStatisticsFragment = useMemo(() => {
    return barChartData?.statistics?.detailed ?? {};
  }, [barChartData?.statistics?.detailed]);

  const barChartAffiliateDetailedData: AffiliateDetailsFragment =
    useMemo(() => {
      return barChartData?.affiliate?.detailed ?? {};
    }, [barChartData?.affiliate?.detailed]);

  const value = useMemo(
    () => ({
      data: barChartDetailedData,
      affiliateData: barChartAffiliateDetailedData,
      loading: barChartDataLoading,
      error: barChartDataError,
    }),
    [
      barChartDetailedData,
      barChartAffiliateDetailedData,
      barChartDataLoading,
      barChartDataError,
    ]
  );

  return (
    <StatisticsPageStatisticsContext.Provider value={value}>
      <BarChartProvider>
        <PageHeaderProvider>
          <SharingProvider>
            <UserListProvider>{children}</UserListProvider>
          </SharingProvider>
        </PageHeaderProvider>
      </BarChartProvider>
    </StatisticsPageStatisticsContext.Provider>
  );
};

export const useStatisticsPageStatistics = () => {
  const context = useContext(StatisticsPageStatisticsContext);
  if (context === undefined) {
    throw new Error(
      'useStatisticsPageStatistics must be used within a StatisticsPageStatisticsProvider'
    );
  }
  return context;
};
