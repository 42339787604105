import { HStack, HTMLChakraProps, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { DateTime, Duration } from 'luxon';
import React from 'react';

export const Countdown: React.FC<
  HTMLChakraProps<'div'> & {
    time: DateTime;
    stopAtZero?: boolean;
    updateInterval?: number;
  }
> = ({ time, stopAtZero = true, updateInterval = 1000, ...props }) => {
  const [timeLeft, setTimeLeft] = React.useState(
    time.diffNow(['days', 'hours', 'minutes', 'seconds'])
  );
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newTime = time.diffNow(['days', 'hours', 'minutes', 'seconds']);
      setTimeLeft(
        newTime < Duration.fromMillis(0) && stopAtZero
          ? Duration.fromMillis(0)
          : newTime
      );
    }, updateInterval);
    return () => clearInterval(interval);
  }, [stopAtZero, time, updateInterval]);

  return (
    <HStack gap={5} textTransform={'uppercase'} lineHeight={0.8} {...props}>
      <VStack>
        <Text fontSize={'2.5em'} fontWeight={600}>
          {timeLeft.days}
        </Text>
        <Text fontWeight={100}>
          {t('countdown:label.days', { count: timeLeft.days })}
        </Text>
      </VStack>
      <VStack>
        <Text fontSize={'2.5em'} fontWeight={600}>
          {timeLeft.hours}
        </Text>
        <Text fontWeight={100}>
          {t('countdown:label.hours', { count: timeLeft.hours })}
        </Text>
      </VStack>
      <VStack>
        <Text fontSize={'2.5em'} fontWeight={600}>
          {timeLeft.minutes}
        </Text>
        <Text fontWeight={100}>
          {t('countdown:label.minutes', { count: timeLeft.minutes })}
        </Text>
      </VStack>
    </HStack>
  );
};
