import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  Box,
  BoxProps,
  Center,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import ListEmptyState from '../../../../assets/images/shared/list-empty-state.svg';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { AnimatedVXLogo } from '../../../../components/shared/AnimatedVXLogo/AnimatedVXLogo';
import { BlockerCard } from '../../../../components/shared/BlockerCard/BlockerCard';
import { FetchMoreIndicator } from '../../../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import { ImageGalleryTag } from '../../../../components/shared/ImageGallery/components/ImageGalleryTag';
import VideoPlayer from '../../../../components/shared/VideoPlayer/VideoPlayer';
import { MetricCountButton } from '../../../../components/shared/buttons/MetricCountButton/MetricCountButton';
import { VideoFragment, VideoTypeEnum } from '../../../../generated/graphql';
import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';
import { useVideoCommenting } from '../../../../provider/VideoLibraryProvider/VideoLibraryCommentingProvider/VideoLibraryCommentingProvider';
import { useVideoLibraryEntryModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { useVideoLibraryVideoStatisticModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryStatisticModalProvider/VideoLibraryVideoStatisticModalProvider';
import { useVideoLibraryVideoContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryVideoProvider/VideoLibraryVideoProvider';
import { getFormattedAmount } from '../../../../utils/formattedAmount';
import { isVideoTypeShopOrContest } from '../../../../utils/isVideoTypeShopOrContest';
import { getLengthInMinutes } from '../../../../utils/videoLength';
import { MediaStatisticBanner } from '../MediaStatisticBanner/MediaStatisticBanner';
import { TileStatusIndicator } from '../TileStatusIndicator';
import { VideoLibraryDiscountTag } from '../VideoLibraryDiscountTag/VideoLibraryDiscountTag';
import { VideoLibraryEntryMenu } from '../VideoLibraryEntryMenu/VideoLibraryEntryMenu';
import { VideoPostCommentIndicator } from '../VideoPostCommentIndicator/VideoPostCommentIndicator';

type VideoLibraryPageTabSectionVideoProps = {
  playingVideoId: string | null;
  setPlayingVideoId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const VideoLibraryPageTabSectionVideo: React.FC<
  VideoLibraryPageTabSectionVideoProps
> = ({ playingVideoId, setPlayingVideoId }) => {
  const { t } = useTranslation(['videoLibrary']);
  const { videos, initialLoading, videosLoading, videosError, loadMore } =
    useVideoLibraryVideoContext();
  const isVideosEmpty = (videos?.length ?? 0) === 0;

  return (
    <Section minH={'300px'}>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow alignItems={'center'}>
            <SectionIcon as={icons.Movie} />
            <SectionTitle fontSize={{ base: '18px', lg: '24px' }}>
              {t('videoLibrary:heading.Videos')}
            </SectionTitle>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        <VStack width={'full'} alignItems={'stretch'} spacing={4}>
          {initialLoading && (
            <Center>
              <Box w={'96px'} h={'96px'}>
                <AnimatedVXLogo />
              </Box>
            </Center>
          )}
          {isVideosEmpty && (
            <BlockerCard
              image={ListEmptyState}
              heading={t('videoLibrary:heading.NochKeineVideosVorhanden')}
            />
          )}
          {!initialLoading && !isVideosEmpty && (
            <SimpleGrid
              pb={'6'}
              gap={'4'}
              columns={{ base: 1, sm: 2, md: 3, xl: 4 }}
              overflowX={'auto'}
              flexWrap={'nowrap'}
            >
              {videos.map((mediaAlbum: VideoFragment) => {
                const id = 'video-' + mediaAlbum.videoId;
                return (
                  <UploadHistoryListItem
                    video={mediaAlbum}
                    key={mediaAlbum.videoId}
                    onPlay={() => setPlayingVideoId(id)}
                    paused={playingVideoId !== id}
                  />
                );
              })}
              <FetchMoreIndicator
                mt={{ base: '8', lg: '12' }}
                loading={videosLoading}
                error={videosError}
                onIsVisible={loadMore}
              />
            </SimpleGrid>
          )}
        </VStack>
      </SectionBody>
    </Section>
  );
};

export const UploadHistoryListItem: React.FC<{
  video: VideoFragment;
  boxProps?: BoxProps;
  onPlay: () => void;
  paused: boolean;
  isVip30?: boolean;
  contestStartForStatus?: any;
}> = ({ video, boxProps, onPlay, paused, isVip30, contestStartForStatus }) => {
  const { actions: entryModalActions } = useVideoLibraryEntryModalContext();
  const { actions: statisticModalActions } =
    useVideoLibraryVideoStatisticModalContext();
  const commentingCtx = useVideoCommenting();
  const { onDelete } = useVideoLibraryVideoContext();
  const { t } = useTranslation(['videoLibrary']);

  return (
    <Box
      overflow={'hidden'}
      border={'1px solid'}
      borderColor={'steel'}
      borderRadius={'6px'}
      {...boxProps}
    >
      <Flex
        alignItems={'center'}
        bgColor={'white'}
        minH={'24px'}
        pl={4}
        pr={2}
        color={'lime.500'}
      >
        <TileStatusIndicator
          status={video?.releasedStatus}
          plannedReleaseDate={video?.plannedReleaseDate}
          contestStartForStatus={contestStartForStatus}
        />
        <VideoLibraryEntryMenu
          mediaType={'video'}
          onClickOnDelete={() => {
            onDelete(video?.albumId);
          }}
          onClickOnEdit={() => {
            if (!video.isEditable) {
              if (isVip30) {
                issueChakraToast({
                  status: 'error',
                  description: t(
                    'videoLibrary:toast.DasVideoKannGeradeNichtBearbeitetWerdenDaEsTeilDerVIP30AktionIst'
                  ),
                });
              } else {
                issueChakraToast({
                  status: 'error',
                  description: t(
                    'videoLibrary:toast.DasVideoKannGeradeNichtBearbeitetWerden'
                  ),
                });
              }
              return;
            }
            entryModalActions.openModal(video?.albumId ?? null);
          }}
        />
      </Flex>
      <VideoPlayerWrapper
        upload={video}
        paused={paused ?? false}
        onPlay={onPlay}
      />
      {isVideoTypeShopOrContest(video) && (
        <MediaStatisticBanner
          onClick={() => {
            statisticModalActions.showModal(video?.albumId?.toString() ?? '');
          }}
        />
      )}
      <VStack alignItems={'start'} bgColor={'white'} p={'4'} spacing={'4'}>
        <VStack alignItems={'start'} w={'full'} overflow={'hidden'}>
          <Text
            lineHeight={'8'}
            fontSize={'md'}
            color={'coldGray.900'}
            fontWeight={'normal'}
          >
            {video?.textCollection?.visitxTitles?.at(0)?.text}
          </Text>
        </VStack>
        <HStack justifyContent={'space-between'} spacing={30} w={'full'}>
          <MetricCountButton
            icon={icons.ThumbUpAlt}
            count={video?.likesCount ?? 0}
          />
          {isVideoTypeShopOrContest(video) && (
            <>
              <MetricCountButton
                icon={icons.ModeComment}
                count={video?.feedbacksCount ?? 0}
              />
              <MetricCountButton
                icon={icons.Payments}
                count={video?.salesCount ?? 0}
              />
            </>
          )}
        </HStack>
        {isVideoTypeShopOrContest(video) && (
          <VideoPostCommentIndicator
            commentCount={video.feedbacksCount ?? 0}
            onClickOnCommentButton={() => {
              if (video.albumId) {
                commentingCtx.action.showModal(video?.albumId.toString());
              }
            }}
          />
        )}
      </VStack>
    </Box>
  );
};

const VideoPlayerWrapper: React.FC<{
  upload: VideoFragment;
  onPlay: () => void;
  paused: boolean;
}> = ({ upload, onPlay, paused }) => {
  const locale = useIntl().locale;
  const { t } = useTranslation(['videoLibrary']);
  const { coinsEnabled } = useFlagsProviderV2();

  const videoLength = getLengthInMinutes(upload?.length ?? 0);
  let videoPrice;
  if (upload?.price) videoPrice = getFormattedAmount(upload?.price, locale);
  if (upload?.priceCoins)
    videoPrice = coinsEnabled && upload?.priceCoins > 0 ? `${upload?.priceCoins} VXC` : videoPrice;

  const videoJsOptions = {
    sources: [
      {
        src: upload?.url,
        type: 'video/mp4',
      },
    ],
    poster: upload?.previewPicture18?.image?.src,
  };

  const isOnDiscount =
    upload?.isDiscountVisible && (upload?.discountPercent || upload?.discountCoins) ? true : false;

  const typeToText: { [type in Partial<VideoTypeEnum>]: string } = {
    videoContest: t('videoLibrary:video.Wettbewerb'),
    videoCultbabes: t('videoLibrary:video.Cultbabes'),
    videoHotclip: t('videoLibrary:video.FreeHotclip'),
    videoFeed: t('videoLibrary:video.Feed'),
    videoMessengerOffer: t('videoLibrary:video.MessengerOffer'),
    videoShop: t('videoLibrary:video.Shop'),
    videoPool: t('videoLibrary:video.Pool'),
    videoWelcomeclip: t('videoLibrary:video.WillkommenClip'),
  };

  return (
    <AspectRatio ratio={16 / 9}>
      <Box>
        <VideoPlayer
          options={videoJsOptions}
          formattedPrice={''}
          paused={paused}
          onPlay={onPlay}
        />
        <HStack
          position={'absolute'}
          top={0}
          left={0}
          p={'3'}
          spacing={2}
          pointerEvents={'none'}
          wrap={'wrap'}
        >
          {/* currently only Free/shop videos are shown in the library */}
          {upload.type && upload.type !== VideoTypeEnum.VideoShop && (
            <ImageGalleryTag
              key={upload.type}
              children={typeToText[upload.type]}
            />
          )}
          {/* currently only Free videos are shown in the library */}
          {videoPrice && upload.type !== VideoTypeEnum.VideoHotclip && (
            <ImageGalleryTag key={videoPrice} children={videoPrice} />
          )}
          {/* TODO add discount coins value here */}
          {isOnDiscount && (
            <VideoLibraryDiscountTag
              discountPercent={upload?.discountPercent}
              discountStart={upload?.discountStart}
              discountEnd={upload?.discountEnd}
              discountCoins={upload?.discountCoins}
            />
          )}
        </HStack>
        <HStack
          position={'absolute'}
          p={'3'}
          bottom={0}
          left={0}
          pointerEvents={'none'}
          wrap={'wrap'}
        >
          <Text
            color={'white'}
            fontSize={'small'}
            lineHeight={'20px'}
            fontWeight={'medium'}
          >
            {videoLength}
          </Text>
        </HStack>
      </Box>
    </AspectRatio>
  );
};
