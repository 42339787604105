import { FetchResult } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as Yup from 'yup';

import {
  LanguagesEnum,
  ModelProfileCompletenessFragment,
  ModelProfileProviderQuery,
  ProfileFieldTypeDateFragment,
  ProfileFieldTypeEnumFragment,
  ProfileFieldTypeEnumListFragment,
  ProfileFieldTypeIntFragment,
  ProfileFieldTypeMultilangStringFragment,
  ProfileFieldTypeStringFragment,
  ProfileFieldsEnum,
  SaveProfileDataMutation,
} from '../../../generated/graphql';
import {
  CreateEnumListSchemaOptions,
  CreateEnumSchemaOptions,
  CreateNumberSchemaOptions,
  CreateStringSchemaOptions,
  createDateValidationSchema,
  createEnumListValidationSchema,
  createEnumValidationSchema,
  createNumberValidationSchema,
  createStringValidationSchema,
} from '../../../utils/validation';
import { removeCarriageReturnsInText } from '../Texts/utils';

type SaveDataMutationResponse = FetchResult<SaveProfileDataMutation>;

export const extractProfileProviderMediaMutationCollection = (
  data?: SaveDataMutationResponse
) => {
  const collection = data?.data?.profile?.mediaCollection;
  return collection?.__typename === 'ProfileCollectionMobileMediaV1Mutation'
    ? collection
    : null;
};

export const extractProfileProviderInterviewMutationCollection = (
  data?: SaveDataMutationResponse
) => {
  const collection = data?.data?.profile?.interviewCollection;
  return collection?.__typename === 'ProfileCollectionMobileInterviewV1Mutation'
    ? collection
    : null;
};

export const extractProfileProviderAppearanceMutationCollection = (
  data?: SaveDataMutationResponse
) => {
  const collection = data?.data?.profile?.appearanceCollection;
  return collection?.__typename ===
    'ProfileCollectionMobileAppearanceV1Mutation'
    ? collection
    : null;
};

export const extractProfileProviderAboutMeMutationCollection = (
  data?: SaveDataMutationResponse
) => {
  const collection = data?.data?.profile?.aboutMeCollection;
  return collection?.__typename === 'ProfileCollectionMobileAboutMeV1Mutation'
    ? collection
    : null;
};

export const extractProfileProviderMediaCollection = (
  data?: ModelProfileProviderQuery
) => {
  const collection = data?.profile.mediaCollection;
  return collection?.__typename === 'ProfileCollectionMobileMediaV1'
    ? collection
    : null;
};

export const extractProfileProviderInterviewCollection = (
  data?: ModelProfileProviderQuery
) => {
  const collection = data?.profile.interviewCollection;
  return collection?.__typename === 'ProfileCollectionMobileInterviewV1'
    ? collection
    : null;
};

export const extractProfileProviderAppearanceCollection = (
  data?: ModelProfileProviderQuery
) => {
  const collection = data?.profile.appearanceCollection;
  return collection?.__typename === 'ProfileCollectionMobileAppearanceV1'
    ? collection
    : null;
};

export const extractProfileProviderAboutMeCollection = (
  data?: ModelProfileProviderQuery
) => {
  const collection = data?.profile.aboutMeCollection;
  return collection?.__typename === 'ProfileCollectionMobileAboutMeV1'
    ? collection
    : null;
};

type ProgressData = ModelProfileCompletenessFragment | undefined;

type CalculateProgressDataProps = {
  aboutMe: ProgressData;
  interview: ProgressData;
  media: ProgressData;
  appearance: ProgressData;
};

export const calculateProgress = (
  progress: CalculateProgressDataProps
): number | null => {
  // Count of needed interview answers to reach the 100%
  const interviewQuestionsNeeded = 3;

  // If there are missing data, we can not calculate the progress
  if (
    !progress.media ||
    !progress.interview ||
    !progress.appearance ||
    !progress.aboutMe
  ) {
    return null;
  }
  const totalFieldsCount =
    progress.media.total +
    progress.appearance.total +
    progress.aboutMe.total +
    interviewQuestionsNeeded;

  const tempInterviewAnswerCount =
    progress.interview.missing -
    progress.interview.total +
    interviewQuestionsNeeded;

  const missingInterviewAnswers =
    tempInterviewAnswerCount < 0 ? 0 : tempInterviewAnswerCount;

  const totalMissingFieldsCount =
    progress.media.missing +
    progress.aboutMe.missing +
    progress.appearance.missing +
    missingInterviewAnswers;

  const totalFilledFieldsCount = totalFieldsCount - totalMissingFieldsCount;

  return totalFilledFieldsCount / totalFieldsCount;
};

type ProfileFieldTypeFragmentUnion =
  | ProfileFieldTypeEnumListFragment
  | ProfileFieldTypeEnumFragment
  | ProfileFieldTypeDateFragment
  | ProfileFieldTypeIntFragment
  | ProfileFieldTypeStringFragment
  | ProfileFieldTypeMultilangStringFragment;
type ProfileFieldRecord = Partial<
  Record<ProfileFieldsEnum, Maybe<ProfileFieldTypeFragmentUnion>>
>;

export function extractInitialValuesFromFields(
  fields: Maybe<ProfileFieldRecord>,
  isTranslated?: boolean
) {
  const initialValues: any = {};

  // Variable `key` resulted in faulty transpiled JS, therefore it was renamed to `fieldKey`
  for (const fieldKey in fields) {
    const field = fields[fieldKey as ProfileFieldsEnum];
    switch (field?.__typename) {
      case 'ProfileFieldTypeInt':
        initialValues[fieldKey] = field?.value ?? undefined;
        break;
      case 'ProfileFieldTypeEnum':
        initialValues[fieldKey] = field?.value ?? '';
        break;
      case 'ProfileFieldTypeDate':
        initialValues[fieldKey] = field?.value ?? '';
        break;
      case 'ProfileFieldTypeEnumList':
        initialValues[fieldKey] = field?.value ?? [];
        break;
      case 'ProfileFieldTypeString':
        initialValues[fieldKey] = field?.value ?? '';
        break;
      case 'ProfileFieldTypeMultiLangString':
        if (isTranslated) {
          initialValues[`${fieldKey}`] =
            removeCarriageReturnsInText(field?.value?.origText) ?? '';
          break;
        }
        let requiredLanguages: string[] = [];
        field.langs.forEach((lang) => {
          requiredLanguages = [...requiredLanguages, lang.name];
        });
        const languageCodes = [
          // we need to make sure we generate entry for the required languages,
          // even if there are no entries for them
          ...requiredLanguages,
          // Add all remaining languages
          ...(field.value?.langs?.filter(
            (lang) => !requiredLanguages.includes(lang)
          ) ?? []),
        ];
        for (const languageCode of languageCodes) {
          const foundEntry = field?.value?.texts?.find(
            ({ lang }) => lang === languageCode
          );
          initialValues[`${fieldKey}-${languageCode}`] = foundEntry?.text ?? '';
        }
        break;
      default:
        break;
    }
  }

  return initialValues;
}

type MapProfileFieldTypeToValidationOptions<T> =
  T extends ProfileFieldTypeIntFragment
    ? CreateNumberSchemaOptions
    : T extends ProfileFieldTypeEnumFragment
    ? CreateEnumSchemaOptions
    : T extends ProfileFieldTypeEnumListFragment
    ? CreateEnumListSchemaOptions
    : T extends ProfileFieldTypeStringFragment
    ? CreateStringSchemaOptions
    : T extends ProfileFieldTypeMultilangStringFragment
    ? CreateStringSchemaOptions
    : never;
type OptionValue<T extends object> = {
  [key in keyof T]: MapProfileFieldTypeToValidationOptions<T[key]>;
};

export function extractValidationSchema<
  F extends Partial<{
    [key in ProfileFieldsEnum]: Maybe<ProfileFieldTypeFragmentUnion>;
  }>
>(fields: Maybe<F>, overrides?: OptionValue<F>, isTranslated?: boolean) {
  if (!fields) {
    return Yup.object().shape({});
  }
  const shape: any = {};

  // Variable `key` resulted in faulty transpiled JS, therefore it was renamed to `fieldKey`
  for (const fieldKey in fields) {
    const field = fields[fieldKey as ProfileFieldsEnum];
    const override: any = overrides?.[fieldKey] ?? {};
    switch (field?.__typename) {
      case 'ProfileFieldTypeInt':
        shape[fieldKey] = createNumberValidationSchema({
          ...field,
          ...override,
        });
        break;
      case 'ProfileFieldTypeDate':
        shape[fieldKey] = createDateValidationSchema({ ...field, ...override });
        break;
      case 'ProfileFieldTypeEnum':
        shape[fieldKey] = createEnumValidationSchema({ ...field, ...override });
        break;
      case 'ProfileFieldTypeEnumList':
        shape[fieldKey] = createEnumListValidationSchema({
          ...field,
          ...override,
        });
        break;
      case 'ProfileFieldTypeString':
        shape[fieldKey] = createStringValidationSchema({
          ...field,
          ...override,
        });
        break;
      case 'ProfileFieldTypeMultiLangString':
        if (isTranslated) {
          shape[`${fieldKey}`] = createStringValidationSchema({
            ...field,
            ...override,
          });
        } else {
          for (const lang of field.langs) {
            shape[`${fieldKey}-${lang.name}`] = createStringValidationSchema({
              ...field,
              ...override,
            });
          }
        }
        break;
      default:
        break;
    }
  }

  return Yup.object().shape(shape);
}

/**
 * assume multilang values to have `${key}-${LanguagesEnum}` entries
 * and depend on their order in initialValues to reconstruct the array
 * that should be send to the key in the mutation
 */
export const extractDirtyValues = (
  values: Record<string, any>,
  initialValues: Record<string, any>
) => {
  const keys = Object.keys(initialValues);
  return Object.fromEntries(
    Object.entries(values).filter(([key, value]) => {
      let initialValue = initialValues[key];
      let newValue = value;
      const keyStart = `${key}-`;
      const hasLanguageEntries = keys.some((entry) =>
        entry.startsWith(keyStart)
      );
      if (hasLanguageEntries) {
        const foo = keys
          .filter((entry) => entry.startsWith(keyStart))
          .map((entry) => entry.replace(keyStart, '') as LanguagesEnum);
        initialValue = Object.entries<string | null>(initialValues)
          .filter(([key2, _]) => key2.startsWith(keyStart))
          .map(([key2, value]) => {
            return {
              lang: key2.replace(keyStart, '') as LanguagesEnum,
              text: value,
            };
          })
          .reduce((acc, curr) => {
            return [...acc, curr];
          }, [] as { lang: LanguagesEnum; text: string | null }[]);
        newValue = (newValue as { lang: LanguagesEnum }[])?.sort(
          (a, b) => foo.indexOf(a.lang) - foo.indexOf(b.lang)
        );
      }
      // "Cheep" deep equal
      return JSON.stringify(initialValue) !== JSON.stringify(newValue);
    })
  );
};
