import { useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import {
  ContestFragment,
  MediaContestTypeEnum,
} from '../../../../generated/graphql';
import { ContestPhotoProvider } from '../../../../provider/ContestPhotoProvider';
import { MediaFlowProvider } from '../../../../provider/MediaFlowProvider';
import { MediaProvider } from '../../../../provider/MediaProvider';
import { MediaUploadProvider } from '../../../../provider/MediaUploadProvider';
import { useVideoLibraryEntryModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { useUploadyService } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { LocalStorageKeys } from '../../../../types';
import { LocalStorage } from '../../../../utils';
import { ContestSectionDesktop } from './ContestSectionDesktop';
import { ContestSectionMobile } from './ContestSectionMobile';
import { ContestPhaseEnum, getContestPhase } from './ContestUtils';

const alreadyOpened: string[] = [];
export const ContestSection: React.FC<{
  contest: ContestFragment;
}> = ({ contest }) => {
  const isMobile = !useBreakpointValue({ base: false, md: true });

  // open modal
  const { contestUploads } = useUploadyService();
  const { isOpen, actions } = useVideoLibraryEntryModalContext();
  useEffect(() => {
    contestUploads.forEach((upload) => {
      if (
        upload.uploadRequestId &&
        !alreadyOpened.includes(upload.uploadRequestId) &&
        !isOpen
      ) {
        alreadyOpened.push(upload.uploadRequestId);
        actions.openModal(null, upload.uploadRequestId, contest);
      }
    });
  }, [actions, contestUploads, isOpen, contest]);

  // show contest end only once, then remove the keyy
  const phase = getContestPhase(contest);
  if (phase === ContestPhaseEnum.Ended) {
    if (contest.type === MediaContestTypeEnum.Video) {
      LocalStorage.remove(LocalStorageKeys.PARTICIPATED_IN_VIDEO_CONTSEST_ID);
    } else {
      LocalStorage.remove(LocalStorageKeys.PARTICIPATED_IN_PHOTO_CONTSEST_ID);
    }
  }

  return (
    <MediaProvider>
      <MediaFlowProvider>
        <MediaUploadProvider
          onUploadError={(error) => {
            issueChakraToast({
              status: 'error',
              title: error.description,
            });
          }}
        >
          <ContestPhotoProvider>
            {isMobile ? (
              <ContestSectionMobile contest={contest} />
            ) : (
              <ContestSectionDesktop contest={contest} />
            )}
          </ContestPhotoProvider>
        </MediaUploadProvider>
      </MediaFlowProvider>
    </MediaProvider>
  );
};
