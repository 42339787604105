import { type StackProps, VStack } from '@chakra-ui/react';

export const SectionCenterContainer: React.FC<StackProps> = (props) => (
  <VStack
    w={'full'}
    spacing={0}
    maxW={'container.md'}
    alignSelf={'center'}
    alignItems={'stretch'}
    {...props}
  />
);
