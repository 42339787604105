import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import {
  Check,
  Info,
  Lock,
  Movie,
  Paid,
  PaymentsOutlined,
  ThumbUp,
  VolumeUp,
  VxLive,
  VxModelsLogo,
} from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  ModalHeader,
  Text,
  TextProps,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import exclusiveSharingImg from '../../../../assets/images/statistics/exclusive-sharing.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { HSnapScrollContainer } from '../../../../components/Layout/HSnapScrollContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../../../components/Layout/ResponsiveModal';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import {
  GetSharingStatisticsDocument,
  SharingStepFragment,
  SharingTypeEnum,
  useGetProfileProgressQuery,
  useRequestNextSharingLevelMutation,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { externalRoutes } from '../../../../routes/routesConfig';
import {
  getFormattedAmount,
  getFormattedCoins,
} from '../../../../utils/formattedAmount';
import { useSharingContext } from '../../provider/SharingContext';
import {
  SharingInfoModalProvider,
  useSharingInfoModal,
} from './SharingInfoModalProvider';

enum SharingStatus {
  ApplyForSecondLevel = 'ApplyForSecondLevel',
  ApplyForThirdLevel = 'ApplyForThirdLevel',
  AutomaticallyNextLevel = 'AutomaticallyNextLevel',
  RejectedForNextLevel = 'RejectedForNextLevel',
  RequestedForNextLevel = 'RequestedForNextLevel',
  LockedForNextLevel = 'LockedForNextLevel',
  HighestLevelReached = 'HighestLevelReached',
  ExclusiveSharing = 'ExclusiveSharing',
}

export const DetailedStatisticsSharingParent: React.FC = () => {
  const [sharingStatus, setSharingStatus] =
    React.useState<SharingStatus | null>(null);
  const { sharingStatistics, loading } = useSharingContext();
  const {
    currentStep,
    isStepVerificationRequested,
    isStepVerificationRejected,
    isAllowedForSecondStep,
    isAllowedForThirdStep,
    lockedStep,
    isPremium,
    steps,
  } = sharingStatistics;

  const rejectedForNextLevel = isStepVerificationRejected;
  const requestedNextLevel = isStepVerificationRequested;
  const lockedForNextLevel = lockedStep
    ? (currentStep ?? 0) + 1 >= lockedStep
    : false;
  const highestStepReached = currentStep === steps?.at(steps?.length - 1)?.step;

  const canApplyForNextLevel = !rejectedForNextLevel && !lockedForNextLevel;

  const needsToApplyForSecondStep =
    currentStep === 1 ? !isAllowedForSecondStep : false;
  const needsToApplyForThirdStep =
    currentStep === 2 ? !isAllowedForThirdStep : false;

  React.useEffect(() => {
    if (loading) return;

    if (rejectedForNextLevel) {
      setSharingStatus(SharingStatus.RejectedForNextLevel);
    } else if (requestedNextLevel) {
      setSharingStatus(SharingStatus.RequestedForNextLevel);
    } else if (lockedForNextLevel) {
      setSharingStatus(SharingStatus.LockedForNextLevel);
    } else if (isPremium) {
      setSharingStatus(SharingStatus.ExclusiveSharing);
    } else if (highestStepReached) {
      setSharingStatus(SharingStatus.HighestLevelReached);
    } else if (canApplyForNextLevel && needsToApplyForSecondStep) {
      setSharingStatus(SharingStatus.ApplyForSecondLevel);
    } else if (canApplyForNextLevel && needsToApplyForThirdStep) {
      setSharingStatus(SharingStatus.ApplyForThirdLevel);
    } else {
      setSharingStatus(SharingStatus.AutomaticallyNextLevel);
    }
  }, [
    loading,
    isPremium,
    lockedForNextLevel,
    needsToApplyForSecondStep,
    needsToApplyForThirdStep,
    rejectedForNextLevel,
    requestedNextLevel,
    canApplyForNextLevel,
    highestStepReached,
  ]);

  return (
    <DetailedStatisticsSharing
      sharingStatus={sharingStatus}
      loading={loading ?? false}
      steps={steps}
      currentStep={currentStep}
    />
  );
};

type DetailedStatisticsSharingProps = {
  sharingStatus: Maybe<SharingStatus>;
  loading: boolean;
  steps: Maybe<SharingStepFragment[]>;
  currentStep: Maybe<number>;
};

const DetailedStatisticsSharing: React.FC<DetailedStatisticsSharingProps> = ({
  sharingStatus,
  loading,
  steps,
  currentStep,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isLocked =
    sharingStatus === SharingStatus.LockedForNextLevel ||
    sharingStatus === SharingStatus.HighestLevelReached ||
    sharingStatus === SharingStatus.ExclusiveSharing;
  const isExclusive = sharingStatus === SharingStatus.ExclusiveSharing;

  const currentStepSharingItem = React.useMemo(() => {
    switch (sharingStatus) {
      case SharingStatus.ApplyForSecondLevel:
        return <ApplyForSecondLevelSection />;
      case SharingStatus.ApplyForThirdLevel:
        return <ApplyForThirdLevelSection />;
      case SharingStatus.AutomaticallyNextLevel:
        return <AutomaticallyNextLevelSection />;
      case SharingStatus.RejectedForNextLevel:
      case SharingStatus.RequestedForNextLevel:
        return <RejectedOrRequestedForNextLevelSection />;
      case SharingStatus.LockedForNextLevel:
      case SharingStatus.HighestLevelReached:
        return <LockedForNextLevelSection />;
      case SharingStatus.ExclusiveSharing:
        return <ExclusiveModelSharingBox />;
      default:
        return <></>;
    }
  }, [sharingStatus]);

  React.useEffect(() => {
    if (ref.current) {
      const containerWidth = ref.current.offsetWidth;
      const stepWidth = ref.current.scrollWidth / 5; // Assuming there are always 5 steps
      const scrollPosition =
        currentStep && currentStep > 1
          ? (currentStep - 1) * stepWidth - containerWidth / 2 + stepWidth / 2
          : 0;
      ref.current.scrollLeft = scrollPosition;
    }
  }, [currentStep]);

  const currentDate = DateTime.now().toFormat('MM / yyyy');
  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={PaymentsOutlined} />
            <SectionTitle flex={1}>Sharing</SectionTitle>
            <Text
              alignSelf={'center'}
              color={'darkSteel'}
              fontWeight={'medium'}
              fontSize={'xs'}
            >
              {currentDate}
            </Text>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted={true} />
      <SectionBody>
        <SharingInfoModalProvider>
          <SectionCenterContainer spacing={4}>
            {loading ? (
              <AbsoluteFullCenterLoadingSpinner />
            ) : !isExclusive ? (
              <HSnapScrollContainer ref={ref}>
                {steps?.map((step, index) => {
                  const isCurrentStep = currentStep === step.step;
                  return (
                    <VStack
                      key={index}
                      gap={4}
                      minW={56}
                      minH={'168px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      m={isCurrentStep ? 4 : 0}
                    >
                      {isCurrentStep ? (
                        currentStepSharingItem
                      ) : (
                        <InfoLevelSection
                          step={step}
                          currentStep={currentStep ?? 0}
                          isLocked={isLocked}
                        />
                      )}
                    </VStack>
                  );
                })}
              </HSnapScrollContainer>
            ) : (
              <VStack
                gap={4}
                minW={56}
                minH={'168px'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {currentStepSharingItem}
              </VStack>
            )}
          </SectionCenterContainer>
        </SharingInfoModalProvider>
      </SectionBody>
    </Section>
  );
};

const LevelSectionSharingHeader: React.FC<{
  text?: string;
  textProps?: TextProps;
  hasInfo?: boolean;
}> = ({ text, textProps, hasInfo = true }) => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { progressPercent } = sharingStatistics;
  const { onInfoModalOpen } = useSharingInfoModal();

  return (
    <HStack
      position={'relative'}
      align={'center'}
      justifyContent={'center'}
      w={'full'}
    >
      <Text
        pt={'6px'}
        pb={'4px'}
        fontWeight={'extrabold'}
        fontSize={'14px'}
        lineHeight={'14px'}
        color={'surface'}
        textAlign={'center'}
        {...textProps}
      >
        {text ?? t('text.AktuellesSharingXX', { sharing: progressPercent })}
      </Text>
      {hasInfo && (
        <IconButton
          position={'absolute'}
          aria-label={'Info'}
          variant={'unstyled'}
          height={'unset'}
          width={'unset'}
          right={'0'}
          onClick={onInfoModalOpen}
        >
          <Icon
            p={0}
            as={Info}
            boxSize={'icon.sm'}
            color={'white'}
            bg={'transparent'}
          />
        </IconButton>
      )}
    </HStack>
  );
};

const ApplyForSecondLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sharingStatistics } = useSharingContext();
  const { nextProgressPercent, sharingCategory } = sharingStatistics;

  const [requestNextSharingLevel, { loading: requestNextLevelLoading }] =
    useRequestNextSharingLevelMutation();
  const onRequestNextLevel = () => {
    if (!sharingCategory) return;

    try {
      const type =
        sharingCategory === 'SHOP'
          ? SharingTypeEnum.Shop
          : SharingTypeEnum.Chat;
      const date = DateTime.now();
      requestNextSharingLevel({
        variables: { type, date },
        refetchQueries: [GetSharingStatisticsDocument],
      });
      if (isOpen) onClose();
      issueChakraToast({
        status: 'success',
        description: t('toast.FreischaltungErfolgreichBeantragt'),
      });
    } catch (error) {
      if (isOpen) onClose();
      issueChakraToast({
        status: 'error',
        description: t('toast.FreischaltungNichtMoglich'),
      });
    }
  };

  return (
    <VStack gap={4}>
      <VStack
        gap={0}
        minH={'136px'}
        w={'full'}
        px={'1px'}
        pb={'1px'}
        bgGradient={'linear(to-r, primary.from, primary.to)'}
        borderRadius={'8px'}
      >
        <LevelSectionSharingHeader />
        <AuthorizedForNextLevelBox />
      </VStack>
      <Center height="32px">
        <Divider orientation="vertical" color={'steel'} />
      </Center>
      <VStack gap={2}>
        <Box
          h={'22px'}
          w={'22px'}
          borderRadius={'100px'}
          bgColor={'gray.200'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon boxSize={'12px'} as={Lock} color={'surface'} />
        </Box>
        <VStack gap={1}>
          <Text
            fontWeight={'light'}
            fontSize={'sm'}
            lineHeight={'14px'}
            color={'gray.200'}
          >
            {t('text.NachsteStufe')}
          </Text>
          <Box bgColor={'gray.200'} borderRadius={'8px'} px={'6px'} py={'1px'}>
            <Text
              fontWeight={'black'}
              fontSize={'10px'}
              lineHeight={'14px'}
              color={'surface'}
            >
              {t('text.XXSharing', { sharing: nextProgressPercent })}
            </Text>
          </Box>
        </VStack>
      </VStack>
      <Button
        onClick={onOpen}
        isLoading={requestNextLevelLoading}
        variant={'outline'}
        fontWeight={'bold'}
        fontSize={'md'}
        lineHeight={'24px'}
      >
        {t('button.XXSharingFreischalten', {
          sharing: nextProgressPercent,
        })}
      </Button>
      <DetailedStatisticsSharingModalStepOne
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRequestNextLevel={onRequestNextLevel}
      />
    </VStack>
  );
};

const ApplyForThirdLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sharingStatistics } = useSharingContext();
  const { nextProgressPercent, sharingCategory } = sharingStatistics;

  const [requestNextSharingLevel, { loading: requestNextLevelLoading }] =
    useRequestNextSharingLevelMutation();
  const onRequestNextLevel = () => {
    if (!sharingCategory) return;

    try {
      const type =
        sharingCategory === 'SHOP'
          ? SharingTypeEnum.Shop
          : SharingTypeEnum.Chat;
      const date = DateTime.now();
      requestNextSharingLevel({
        variables: { type, date },
        refetchQueries: [GetSharingStatisticsDocument],
      });
      if (isOpen) onClose();
      issueChakraToast({
        status: 'success',
        description: t('toast.FreischaltungErfolgreichBeantragt'),
      });
    } catch (error) {
      if (isOpen) onClose();
      issueChakraToast({
        status: 'error',
        description: t('toast.FreischaltungNichtMoglich'),
      });
    }
  };

  return (
    <VStack gap={4}>
      <VStack
        gap={0}
        minH={'136px'}
        w={'full'}
        px={'1px'}
        pb={'1px'}
        bgGradient={'linear(to-r, primary.from, primary.to)'}
        borderRadius={'8px'}
      >
        <LevelSectionSharingHeader />
        <AuthorizedForNextLevelBox />
      </VStack>
      <Center height="32px">
        <Divider orientation="vertical" color={'steel'} />
      </Center>
      <VStack gap={2}>
        <Box
          h={'22px'}
          w={'22px'}
          borderRadius={'100px'}
          bgColor={'gray.200'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon boxSize={'12px'} as={Lock} color={'surface'} />
        </Box>
        <VStack gap={1}>
          <Text
            fontWeight={'light'}
            fontSize={'sm'}
            lineHeight={'14px'}
            color={'gray.200'}
          >
            {t('text.NachsteStufe')}
          </Text>
          <Box bgColor={'gray.200'} borderRadius={'8px'} px={'6px'} py={'1px'}>
            <Text
              fontWeight={'black'}
              fontSize={'10px'}
              lineHeight={'14px'}
              color={'surface'}
            >
              {t('text.XXSharing', { sharing: nextProgressPercent })}
            </Text>
          </Box>
        </VStack>
      </VStack>
      <Button
        onClick={onOpen}
        isLoading={requestNextLevelLoading}
        variant={'outline'}
        fontWeight={'bold'}
        fontSize={'md'}
        lineHeight={'24px'}
      >
        {t('button.XXSharingFreischalten', {
          sharing: nextProgressPercent,
        })}
      </Button>
      <DetailedStatisticsSharingModalStepTwo
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRequestNextLevel={onRequestNextLevel}
      />
    </VStack>
  );
};

const AutomaticallyNextLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { nextProgressPercent } = sharingStatistics;
  return (
    <VStack gap={4}>
      <VStack
        gap={0}
        minH={'136px'}
        w={'full'}
        px={'1px'}
        pb={'1px'}
        bgGradient={'linear(to-r, primary.from, primary.to)'}
        borderRadius={'8px'}
      >
        <LevelSectionSharingHeader />
        <AuthorizedForNextLevelBox />
      </VStack>
      <Center height="32px">
        <Divider orientation="vertical" color={'steel'} />
      </Center>
      <VStack gap={2}>
        <Box
          h={'22px'}
          w={'22px'}
          borderRadius={'100px'}
          bgColor={'gray.200'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon boxSize={'12px'} as={Check} color={'surface'} />
        </Box>
        <VStack gap={1}>
          <Text
            fontWeight={'light'}
            fontSize={'sm'}
            lineHeight={'14px'}
            color={'gray.200'}
          >
            {t('text.NachsteStufe')}
          </Text>
          <Box bgColor={'gray.200'} borderRadius={'8px'} px={'6px'} py={'1px'}>
            <Text
              fontWeight={'black'}
              fontSize={'10px'}
              lineHeight={'14px'}
              color={'surface'}
            >
              {t('text.XXSharing', { sharing: nextProgressPercent })}
            </Text>
          </Box>
          <Text
            fontWeight={'light'}
            fontSize={'10px'}
            lineHeight={'17px'}
            color={'gray.200'}
          >
            {t('text.AutmAufstiegSobaldZielErreichtIst')}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};

const RejectedOrRequestedForNextLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { nextProgressPercent, isStepVerificationRequested } =
    sharingStatistics;
  const buttonText = isStepVerificationRequested
    ? t('button.InPrufung')
    : t('button.FreischaltungNichtMoglich');
  return (
    <VStack gap={4} w={'full'}>
      <VStack
        gap={0}
        minH={'136px'}
        w={'full'}
        px={'1px'}
        pb={'1px'}
        bgGradient={'linear(to-r, primary.from, primary.to)'}
        borderRadius={'8px'}
      >
        <LevelSectionSharingHeader />
        <UnauthorizedForNextLevelBox />
      </VStack>
      <Center height="32px">
        <Divider orientation="vertical" color={'steel'} />
      </Center>
      <VStack gap={2}>
        <Box
          h={'22px'}
          w={'22px'}
          borderRadius={'100px'}
          bgColor={'gray.200'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon boxSize={'12px'} as={Lock} color={'surface'} />
        </Box>
        <VStack gap={1}>
          <Text
            fontWeight={'light'}
            fontSize={'sm'}
            lineHeight={'14px'}
            color={'gray.200'}
          >
            {t('text.NachsteStufe')}
          </Text>
          <Box bgColor={'gray.200'} borderRadius={'8px'} px={'6px'} py={'1px'}>
            <Text
              fontWeight={'black'}
              fontSize={'10px'}
              lineHeight={'14px'}
              color={'surface'}
            >
              {t('text.XXSharing', { sharing: nextProgressPercent })}
            </Text>
          </Box>
        </VStack>
      </VStack>
      <Button
        onClick={() => {}}
        variant={'unstyled'}
        cursor={'not-allowed'}
        fontWeight={'bold'}
        fontSize={'md'}
        lineHeight={'24px'}
        color={'error.500'}
      >
        {buttonText}
      </Button>
    </VStack>
  );
};

const LockedForNextLevelSection: React.FC = () => {
  return (
    <VStack
      gap={0}
      minH={'full'}
      w={'full'}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <UnauthorizedForNextLevelBox />
    </VStack>
  );
};

const InfoLevelSection: React.FC<{
  step: SharingStepFragment;
  currentStep: number;
  isLocked: boolean;
}> = ({ step, currentStep, isLocked }) => {
  const locale = useIntl().locale;
  const { t } = useTranslation(['statisticsPage']);
  const isNextLevelInfo = step.step > currentStep;
  return (
    <VStack
      opacity={isNextLevelInfo ? 1 : 0.75}
      gap={0}
      minH={'136px'}
      w={'full'}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader
        text={
          isNextLevelInfo ? t('text.NachsteStufe') : t('text.VorherigeStufe')
        }
        textProps={{ fontSize: '13px' }}
      />
      <VStack
        bgColor={'surface'}
        w={'full'}
        flex={1}
        borderRadius={'0px 0px 8px 8px'}
      >
        <VStack flex={1} gap={0} justifyContent={'center'}>
          <VStack gap={1}>
            <Text
              color={'darkSteel'}
              fontWeight={'light'}
              fontSize={'xs'}
              lineHeight={'12px'}
            >
              {t('text.StufeXX', { step: step.step })}
            </Text>
            <Text fontWeight={'semibold'} fontSize={'22px'} lineHeight={'22px'}>
              {t('text.XXSharing', { sharing: step.stepPercent })}
            </Text>
          </VStack>
          {isLocked && isNextLevelInfo && (
            <Button
              onClick={() => {}}
              variant={'unstyled'}
              cursor={'not-allowed'}
              fontWeight={'bold'}
              fontSize={'md'}
              lineHeight={'24px'}
              color={'error.500'}
            >
              {t('button.FreischaltungNichtMoglich')}
            </Button>
          )}
        </VStack>
        <Text
          fontWeight={'light'}
          fontSize={'10px'}
          lineHeight={'12px'}
          letterSpacing={'0.03em'}
          pb={'6px'}
        >
          {t('text.Faktor10VXCXXEuro', {
            factor: getFormattedAmount(step.stepFactor ?? 0, locale),
          })}
        </Text>
      </VStack>
    </VStack>
  );
};

const AuthorizedForNextLevelBox: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const locale = useIntl().locale;
  const { sharingStatistics } = useSharingContext();
  const { nextStepMax, currentCoins, currentStepFactor, currentStep } =
    sharingStatistics;

  const coinsLeftInStep =
    nextStepMax && currentCoins ? nextStepMax - currentCoins : nextStepMax ?? 0;
  const coinsLeftInStepPercent =
    currentCoins && nextStepMax ? (currentCoins / nextStepMax) * 100 : 0;
  return (
    <VStack
      bgColor={'surface'}
      w={'full'}
      flex={1}
      borderRadius={'0px 0px 8px 8px'}
    >
      <HStack flex={1} w={{ base: 'full', md: 'fit-content' }} p={4}>
        <Box w={'50px'} h={'50px'}>
          <CircularProgress
            value={coinsLeftInStepPercent}
            color={'caribbeanGreen.200'}
          >
            <CircularProgressLabel>
              <Icon as={VxModelsLogo} color={'steel'} />
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
        <VStack alignItems={'flex-start'} h={'full'} w={'full'}>
          <Text fontWeight={'light'} fontSize={'10px'} lineHeight={'12px'}>
            {t('text.StufeXX', { step: currentStep })}
          </Text>
          <HStack>
            <Text fontWeight={'semibold'} lineHeight={'22px'} fontSize={'xl'}>
              <Trans
                t={t}
                i18nKey={'text.AktuellXXVonXXCoins'}
                values={{ current: currentCoins }}
                components={{
                  maxCoins: (
                    <chakra.span
                      fontWeight={'light'}
                      lineHeight={'22px'}
                      fontSize={'13px'}
                    >
                      {nextStepMax} VXC
                    </chakra.span>
                  ),
                }}
              />
            </Text>
          </HStack>
          <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
            {coinsLeftInStep <= 0 ? (
              t('text.ZielErreicht')
            ) : (
              <Trans
                t={t}
                i18nKey={'text.NurXXCoinsZurNachstenStufe'}
                values={{ current: currentCoins }}
                components={{
                  coins: (
                    <chakra.span
                      color={'caribbeanGreen.200'}
                      fontWeight={'bold'}
                      fontSize={'xs'}
                      lineHeight={'14px'}
                    >
                      {coinsLeftInStep} VXCoins
                    </chakra.span>
                  ),
                }}
              />
            )}
          </Text>
        </VStack>
      </HStack>
      <Text
        fontWeight={'light'}
        fontSize={'10px'}
        lineHeight={'12px'}
        letterSpacing={'0.03em'}
        pb={'6px'}
      >
        {t('text.Faktor10VXCXXEuro', {
          factor: getFormattedAmount(currentStepFactor ?? 0, locale),
        })}
      </Text>
    </VStack>
  );
};
const UnauthorizedForNextLevelBox: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const intl = useIntl();
  const { sharingStatistics } = useSharingContext();
  const { currentCoins, currentStepFactor } = sharingStatistics;
  return (
    <VStack
      bgColor={'surface'}
      w={'full'}
      flex={1}
      borderRadius={'0px 0px 8px 8px'}
    >
      <VStack p={4} flex={1} justifyContent={'center'}>
        <Text
          color={'darkSteel'}
          fontWeight={'light'}
          fontSize={'xs'}
          lineHeight={'12px'}
        >
          {t('text.VerdienstDiesenMonat')}
        </Text>
        <Text fontWeight={'semibold'} fontSize={'22px'} lineHeight={'22px'}>
          {getFormattedCoins(currentCoins ?? 0, intl.locale)} VXCoins
        </Text>
      </VStack>
      <Text
        fontWeight={'light'}
        fontSize={'10px'}
        lineHeight={'12px'}
        letterSpacing={'0.03em'}
        pb={'6px'}
      >
        {t('text.Faktor10VXCXXEuro', {
          factor: getFormattedAmount(currentStepFactor ?? 0, intl.locale),
        })}
      </Text>
    </VStack>
  );
};

type DetailedStatisticsSharingModalProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRequestNextLevel: () => void;
};

const DetailedStatisticsSharingModalStepOne: React.FC<
  DetailedStatisticsSharingModalProps
> = ({ isOpen, onClose, onRequestNextLevel }) => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation(['statisticsPage']);
  const [isChecked, setIsChecked] = React.useState(false);
  const { sharingStatistics } = useSharingContext();
  const {
    currentCoins,
    nextStepMax,
    isStepVerificationEnabled,
    sharingCategory,
  } = sharingStatistics;
  const sharingType =
    sharingCategory === 'SHOP' ? SharingTypeEnum.Shop : SharingTypeEnum.Chat;
  const coinsLeftInStep =
    nextStepMax && currentCoins ? nextStepMax - currentCoins : nextStepMax ?? 0;
  const coinsLeftInStepPercent =
    currentCoins && nextStepMax ? (currentCoins / nextStepMax) * 100 : 0;

  const { data } = useGetProfileProgressQuery();

  const profileCompleteness = React.useMemo(() => {
    return data?.profile.completeness ?? 0;
  }, [data]);
  const profileCompletenessMax = 100;
  const profileCompletenessLeft = profileCompletenessMax - profileCompleteness;
  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ModalHeader>
          <ResponsiveModalCloseButton />
        </ModalHeader>
        <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
          <VStack p={4} gap={4} flex={1}>
            <VStack gap={1}>
              <Text
                fontWeight={'semibold'}
                fontSize={'md'}
                lineHeight={'20px'}
                textAlign={'center'}
              >
                {t(
                  'text.HerzlichenGluckwunschDuKannstDichJetztFurSharingStufe2Bewerben'
                )}
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'light'}
                fontSize={'14px'}
                lineHeight={'19px'}
                color={'gray.900'}
              >
                {t(
                  'text.DuQualifizierstDichMitDeinerPerformanceErstmaligFurSharingStufe2VXApXX'
                )}
              </Text>
            </VStack>
            {/* Level Progress */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Box w={'50px'} h={'50px'}>
                <CircularProgress
                  value={coinsLeftInStepPercent}
                  color={'caribbeanGreen.200'}
                >
                  <CircularProgressLabel>
                    <Icon as={VxModelsLogo} color={'steel'} />
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
              <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                <HStack>
                  <Text
                    fontWeight={'semibold'}
                    lineHeight={'22px'}
                    fontSize={'xl'}
                  >
                    <Trans
                      t={t}
                      i18nKey={'text.AktuellXXVonXXCoins'}
                      values={{ current: currentCoins }}
                      components={{
                        maxCoins: (
                          <chakra.span
                            fontWeight={'light'}
                            lineHeight={'22px'}
                            fontSize={'13px'}
                          >
                            {nextStepMax} VXC
                          </chakra.span>
                        ),
                      }}
                    />
                  </Text>
                </HStack>
                <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
                  {coinsLeftInStep <= 0 ? (
                    t('text.ZielErreicht')
                  ) : (
                    <Trans
                      t={t}
                      i18nKey={'text.NurXXCoinsZurNachstenStufe'}
                      values={{ current: currentCoins }}
                      components={{
                        coins: (
                          <chakra.span
                            color={'caribbeanGreen.200'}
                            fontWeight={'bold'}
                            fontSize={'xs'}
                            lineHeight={'14px'}
                          >
                            {coinsLeftInStep} VXCoins
                          </chakra.span>
                        ),
                      }}
                    />
                  )}
                </Text>
              </VStack>
            </HStack>
            {/* Profile Progress */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Box w={'50px'} h={'50px'}>
                <CircularProgress
                  value={profileCompleteness}
                  color={'caribbeanGreen.200'}
                >
                  <CircularProgressLabel>
                    <Icon as={VxModelsLogo} color={'steel'} />
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
              <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                <HStack>
                  <Text
                    fontWeight={'semibold'}
                    lineHeight={'22px'}
                    fontSize={'xl'}
                  >
                    <Trans
                      t={t}
                      i18nKey={'text.AktuellXXVonXXModelprofil'}
                      values={{ current: profileCompleteness }}
                      components={{
                        maxPercent: (
                          <chakra.span
                            fontWeight={'light'}
                            lineHeight={'22px'}
                            fontSize={'13px'}
                          >
                            {profileCompletenessMax} % {t('text.Modelprofil')}
                          </chakra.span>
                        ),
                      }}
                    />
                  </Text>
                </HStack>
                <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
                  {profileCompletenessLeft > 0 ? (
                    <Trans
                      t={t}
                      i18nKey={'text.NurXXPercentZurVollstandigkeit'}
                      components={{
                        percent: (
                          <chakra.span
                            color={'caribbeanGreen.200'}
                            fontWeight={'bold'}
                            fontSize={'xs'}
                            lineHeight={'14px'}
                          >
                            {profileCompletenessLeft} %
                          </chakra.span>
                        ),
                      }}
                    />
                  ) : (
                    t('text.ZielErreicht')
                  )}
                </Text>
              </VStack>
            </HStack>
            {/* 4 Videos */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Movie} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.Veroffentliche4VideosImShop')}
                </Text>
              </HStack>
            )}
            {/* HD Videos */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Movie} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.MeineVideosSindInHD')}
                </Text>
              </HStack>
            )}
            {/* HD Streaming */}
            {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VxLive} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchStreameInHD')}
                </Text>
              </HStack>
            )}
            {/* Sound Up */}
            {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VolumeUp} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchSendeImmerMitTon')}
                </Text>
              </HStack>
            )}
            {/* Profilstandards */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Icon boxSize={'icon.md'} as={VxModelsLogo} />
              <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                <Trans
                  t={t}
                  i18nKey={'text.MeinProfilEntsprichtDenVISITXProfilstandards'}
                  components={{
                    underline: (
                      <Link
                        isExternal
                        href={
                          externalRoutes.helpCenter(lang) +
                          '/articles/12421556043666-Wie-sieht-ein-optimales-Modelprofil-aus'
                        }
                      >
                        {t('text.Profilstandarts')}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </HStack>
            {/* Approval */}
            <HStack alignItems={'start'}>
              <Checkbox
                pt={1}
                onChange={(e) => setIsChecked(e.target.checked)}
                isChecked={isChecked}
                isDisabled={!isStepVerificationEnabled}
                _disabled={{
                  '.chakra-checkbox__control': {
                    borderColor: 'gray.200',
                  },
                }}
              />
              <Text color={isStepVerificationEnabled ? 'black' : 'gray.200'}>
                {t('text.IchErfulleDieseKriterienUndBeantrageDieNachsteStufe')}
              </Text>
            </HStack>
            <Button
              onClick={onRequestNextLevel}
              variant={'solid'}
              isDisabled={!isChecked}
            >
              {t('button.Bewerben')}
            </Button>
            <Text
              textAlign={'center'}
              color={'gray.500'}
              fontSize={'14px'}
              lineHeight={'19px'}
              mt={4}
            >
              {t(
                'text.SofernDuDieAnforderungenErfullstWirdFurDeineAbrechnungDasJeweilsHoheXX'
              )}
            </Text>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

type DetailedStatisticsSharingModalStepTwoProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRequestNextLevel: () => void;
};

const DetailedStatisticsSharingModalStepTwo: React.FC<
  DetailedStatisticsSharingModalStepTwoProps
> = ({ isOpen, onClose, onRequestNextLevel }) => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation(['statisticsPage']);
  const [isChecked, setIsChecked] = React.useState(false);
  const { sharingStatistics } = useSharingContext();
  const {
    currentCoins,
    nextStepMax,
    isStepVerificationEnabled,
    sharingCategory,
  } = sharingStatistics;
  const sharingType =
    sharingCategory === 'SHOP' ? SharingTypeEnum.Shop : SharingTypeEnum.Chat;
  const coinsLeftInStep =
    nextStepMax && currentCoins ? nextStepMax - currentCoins : nextStepMax ?? 0;
  const coinsLeftInStepPercent =
    currentCoins && nextStepMax ? (currentCoins / nextStepMax) * 100 : 0;

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ModalHeader>
          <ResponsiveModalCloseButton />
        </ModalHeader>
        <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
          <VStack p={4} gap={4} flex={1}>
            <VStack gap={1}>
              <Text
                fontWeight={'semibold'}
                fontSize={'md'}
                lineHeight={'20px'}
                textAlign={'center'}
              >
                {t(
                  'statisticsPage:text.GluckwunschZurQualifizierungFurSharingStufe3DuKannstDichJetztBewerbXX'
                )}
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'light'}
                fontSize={'14px'}
                lineHeight={'19px'}
                color={'gray.900'}
              >
                {t(
                  'text.DuQualifizierstDichMitDeinerSteigendenPerformanceErstmaligFurSharingXX'
                )}
              </Text>
            </VStack>
            {/* Level Progress */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Box w={'50px'} h={'50px'}>
                <CircularProgress
                  value={coinsLeftInStepPercent}
                  color={'caribbeanGreen.200'}
                >
                  <CircularProgressLabel>
                    <Icon as={VxModelsLogo} color={'steel'} />
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
              <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                <HStack>
                  <Text
                    fontWeight={'semibold'}
                    lineHeight={'22px'}
                    fontSize={'xl'}
                  >
                    <Trans
                      t={t}
                      i18nKey={'text.AktuellXXVonXXCoins'}
                      values={{ current: currentCoins }}
                      components={{
                        maxCoins: (
                          <chakra.span
                            fontWeight={'light'}
                            lineHeight={'22px'}
                            fontSize={'13px'}
                          >
                            {nextStepMax} VXC
                          </chakra.span>
                        ),
                      }}
                    />
                  </Text>
                </HStack>
                <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
                  {coinsLeftInStep <= 0 ? (
                    t('text.ZielErreicht')
                  ) : (
                    <Trans
                      t={t}
                      i18nKey={'text.NurXXCoinsZurNachstenStufe'}
                      values={{ current: currentCoins }}
                      components={{
                        coins: (
                          <chakra.span
                            color={'caribbeanGreen.200'}
                            fontWeight={'bold'}
                            fontSize={'xs'}
                            lineHeight={'14px'}
                          >
                            {coinsLeftInStep} VXCoins
                          </chakra.span>
                        ),
                      }}
                    />
                  )}
                </Text>
              </VStack>
            </HStack>
            {/* Platforms */}
            {/* {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VxLive} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchSendeAufMax5AnderenPlattformen')}
                </Text>
              </HStack>
            )} */}
            {/* Profilstandards */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VxModelsLogo} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  <Trans
                    t={t}
                    i18nKey={
                      'text.MeinProfilEntsprichtDenVISITXProfilstandards'
                    }
                    components={{
                      underline: (
                        <Link
                          isExternal
                          href={
                            externalRoutes.helpCenter(lang) +
                            '/articles/12421556043666-Wie-sieht-ein-optimales-Modelprofil-aus'
                          }
                        >
                          {t('text.Profilstandarts')}
                        </Link>
                      ),
                    }}
                  />
                </Text>
              </HStack>
            )}
            {/* Price Chat */}
            {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Paid} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t(
                    'text.IchSendeAufAnderenPlattformenNichtZuEinemGunstigerenPreis'
                  )}
                </Text>
              </HStack>
            )}
            {/* Price Shop */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Movie} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchVerkaufeMeineVideosBeiVisitXZumBestenPreis')}
                </Text>
              </HStack>
            )}
            {/* Thumb Up */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Icon boxSize={'icon.md'} as={ThumbUp} />
              <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                {t('text.MeineSocialMediaLinksSindImProfilHinterlegt')}
              </Text>
            </HStack>
            {/* Fair-Play Regeln */}
            {/* <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Icon boxSize={'icon.md'} as={Forum} />
              <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                {t('text.IchBeachteInJederSessionDieChatFairPlayRegeln')}
              </Text>
            </HStack> */}
            {/* Approval */}
            <HStack alignItems={'start'}>
              <Checkbox
                pt={1}
                onChange={(e) => setIsChecked(e.target.checked)}
                isChecked={isChecked}
                isDisabled={!isStepVerificationEnabled}
                _disabled={{
                  '.chakra-checkbox__control': {
                    borderColor: 'gray.200',
                  },
                }}
              />
              <Text color={isStepVerificationEnabled ? 'black' : 'gray.200'}>
                {t('text.IchErfulleDieseKriterienUndBeantrageDieNachsteStufe')}
              </Text>
            </HStack>
            <Button
              onClick={onRequestNextLevel}
              variant={'solid'}
              isDisabled={!isChecked}
            >
              {t('button.Bewerben')}
            </Button>
            <Text
              textAlign={'center'}
              color={'gray.500'}
              fontSize={'14px'}
              lineHeight={'19px'}
              mt={4}
            >
              {t(
                'text.SofernDuDieAnforderungenErfullstWirdFurDeineAbrechnungDasJeweilsHoheXX'
              )}
            </Text>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

// There is no way for this to be displayed currently
const ExclusiveModelSharingBox: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  return (
    <VStack
      gap={0}
      minH={'full'}
      w={'full'}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader
        text={t('text.VertraglichVereinbartesSharing')}
        hasInfo={false}
      />
      <VStack
        bgColor={'surface'}
        w={'full'}
        flex={1}
        borderRadius={'0px 0px 8px 8px'}
        spacing={4}
        pt={4}
      >
        <VStack flex={1} justifyContent={'center'} spacing={4}>
          <Text fontWeight={'light'} fontSize={'md'} lineHeight={'14px'}>
            {t('text.HerzlichenGluckwunschDuBist')}
          </Text>
          <Image
            src={exclusiveSharingImg}
            alt={t('text.DuBistHighPerformer')}
            w={'102px'}
            h={'68px'}
            objectFit={'cover'}
          />
        </VStack>
        <Text
          fontWeight={'light'}
          fontSize={'10px'}
          lineHeight={'12px'}
          letterSpacing={'0.03em'}
          pb={'6px'}
        >
          {t('text.WahrendDesVereinbartenVertragszeitraums')}
        </Text>
      </VStack>
    </VStack>
  );
};
