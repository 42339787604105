import { Box, BoxProps, Center, VStack } from '@chakra-ui/react';
import React from 'react';

import { AnimatedVXLogo } from '../shared/AnimatedVXLogo/AnimatedVXLogo';

type AbsoluteFullCenterLoadingSpinnerProps = {
  message?: React.ReactNode;
} & BoxProps;
export const AbsoluteFullCenterLoadingSpinner: React.FC<
  AbsoluteFullCenterLoadingSpinnerProps
> = ({ message, ...boxProps }) => (
  <Center
    position={'absolute'}
    // unfortunatly we can't use inset: '0' here yet
    left={0}
    right={0}
    top={0}
    bottom={0}
    {...boxProps}
  >
    <VStack spacing={4}>
      <Box w={'96px'} h={'96px'}>
        <AnimatedVXLogo />
      </Box>
      {message && <Box children={message} />}
    </VStack>
  </Center>
);
