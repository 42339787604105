import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { ErrorMessageWithLeadingIcon } from '../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import {
  ProfileField,
  ProfileFieldsEnum,
  TourValidationFragment,
  WizardStepFieldFragment,
} from '../../../generated/graphql';

export function extractTourStepField<
  F extends {
    [key in ProfileFieldsEnum[number]]: Partial<WizardStepFieldFragment>;
  }
>(fields?: Maybe<Maybe<Partial<ProfileField> | undefined>[]>): Partial<F> {
  if (!fields) {
    return {};
  }

  return fields.reduce<Partial<F>>((acc, field) => {
    if (!field?.name) {
      return acc;
    }
    return { ...acc, [field?.name]: field };
  }, {});
}

export function extractCurrentFieldError<
  F extends {
    [key in ProfileFieldsEnum[number]]: React.ReactNode;
  }
>(
  _: Maybe<TourValidationFragment['data']>,
  unknownError: string | null = null
): Partial<F> {
  const list = _ ?? [];
  if (!_) {
    return {};
  }

  return list.reduce<Partial<F>>((acc, entry) => {
    if (entry?.isValid) {
      return acc;
    }
    const fieldName = entry?.name;

    // Special multilang handling
    if (entry?.__typename === 'ProfileFieldTypeValidationMultiLangString') {
      const multiLangErrors = entry?.multiLangErrors ?? [];
      // todo: consider impact of multiple entries per lang for now last error of a lang wins
      const errorsMessages = multiLangErrors.reduce(
        (multiLangAcc, multiLangError) => ({
          ...multiLangAcc,
          [`${fieldName}-${multiLangError.lang}`]: (
            <ErrorMessageWithLeadingIcon
              children={multiLangError?.message ?? unknownError}
            />
          ),
        }),
        {}
      );

      return { ...acc, ...errorsMessages };
    }

    const error = entry?.errors?.[0]?.message ?? unknownError;
    if (!fieldName || !error) {
      return acc;
    }

    return {
      ...acc,
      [fieldName]: <ErrorMessageWithLeadingIcon children={error} />,
    };
  }, {});
}
