import { Button, ButtonProps, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useRef } from 'react';

import {
  ResponsiveModal,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../Layout/ResponsiveModal';

export type DialogModalProps = {
  headline: string;
  text?: string;
  confirmButtonText: string;
  confirmButtonProps?: ButtonProps & { params?: any };
  closeButtonText: string;
  onConfirm: (e: React.MouseEvent) => void;
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const DialogModal: React.FC<DialogModalProps> = ({
  headline,
  text,
  confirmButtonText,
  confirmButtonProps,
  closeButtonText,
  onConfirm,
  onClose,
  ...rest
}) => {
  const leastDestructiveRef = useRef(null);
  return (
    <ResponsiveModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      preferredSize="sm"
      preferredSizeBreakpoint="sm"
      {...rest}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        role="dialog"
        justifyContent={'center'}
        p={'24px 16px'}
      >
        <Heading
          as={'h2'}
          size={'lg'}
          children={headline}
          textAlign={'center'}
        />
        {text && (
          <Text textAlign={'center'} pt={'8px'}>
            {text}
          </Text>
        )}
        <VStack spacing={'16px'} width={'fit-content'} m={'0 auto'} pt={'32px'}>
          <Button
            width={'full'}
            variant={'solid'}
            children={confirmButtonText}
            onClick={onConfirm}
            {...confirmButtonProps}
          />
          <Button
            width={'full'}
            children={closeButtonText}
            onClick={onClose}
            ref={leastDestructiveRef}
          />
        </VStack>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
