// DateContext.tsx
import { ButtonProps, InputProps, useNumberInput } from '@chakra-ui/react';
import React, { createContext, useContext, useMemo } from 'react';

type DateContextType = {
  yearInput: InputProps;
  incrementYear: ButtonProps;
  decrementYear: ButtonProps;
  monthInput: InputProps;
  incrementMonth: ButtonProps;
  decrementMonth: ButtonProps;
  startDate: string;
  endDate: string;
};

const DateContext = createContext<DateContextType | undefined>(undefined);

export const useDateContext = () => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDateContext must be used within a DateProvider');
  }
  return context;
};

export const DateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const defaultYear = new Date().getFullYear();
  const minYear = defaultYear - 3;

  const {
    getInputProps: getYearInputProps,
    getIncrementButtonProps: getYearIncrementButtonProps,
    getDecrementButtonProps: getYearDecrementButtonProps,
  } = useNumberInput({
    step: 1,
    defaultValue: defaultYear,
    min: minYear,
    max: defaultYear,
    precision: 0,
  });

  const incrementYear = getYearIncrementButtonProps();
  const decrementYear = getYearDecrementButtonProps();
  const yearInput = getYearInputProps({ readOnly: true });

  const defaultMonth = new Date().getMonth() + 1;
  const maxMonth = 12;
  const {
    getInputProps: getMonthInputProps,
    getIncrementButtonProps: getMonthIncrementButtonProps,
    getDecrementButtonProps: getMonthDecrementButtonProps,
  } = useNumberInput({
    step: 1,
    defaultValue: defaultMonth,
    min: 1,
    max: maxMonth,
    precision: 0,
  });

  const incrementMonth = getMonthIncrementButtonProps();
  const decrementMonth = getMonthDecrementButtonProps();
  const monthInput = getMonthInputProps({ readOnly: true });

  const startDate = `${yearInput.value}-${String(monthInput.value).padStart(
    2,
    '0'
  )}-01`;
  const lastDayOfMonth = new Date(
    yearInput.value,
    monthInput.value,
    0
  ).getDate();
  const endDate = `${yearInput.value}-${String(monthInput.value).padStart(
    2,
    '0'
  )}-${String(lastDayOfMonth).padStart(2, '0')}`;

  const value = useMemo(
    () => ({
      yearInput,
      incrementYear,
      decrementYear,
      monthInput,
      incrementMonth,
      decrementMonth,
      startDate,
      endDate,
    }),
    [
      yearInput,
      incrementYear,
      decrementYear,
      monthInput,
      incrementMonth,
      decrementMonth,
      startDate,
      endDate,
    ]
  );

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};
