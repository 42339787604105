import {
  Box,
  Input,
  StyleProps,
  UseCheckboxProps,
  UseRadioProps,
  isStyleProp,
  useCheckbox,
  useRadio,
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import { SystemStyleObject } from '@chakra-ui/theme-tools';
import { objectFilter } from '@chakra-ui/utils';
import React from 'react';

export type RadioOptionButtonProps = {
  multiple: false;
  children: React.ReactNode;
} & StyleProps &
  UseRadioProps;

export type CheckboxOptionButtonProps = {
  multiple: true;
  children: React.ReactNode;
} & UseCheckboxProps &
  StyleProps;

export type OptionButtonProps =
  | RadioOptionButtonProps
  | CheckboxOptionButtonProps;

const styles: SystemStyleObject = {
  px: 5,
  py: 3,
  h: 14,
  color: 'onSurface.mediumEmphasis',
  w: 'full',
  display: 'inline-flex',
  whiteSpace: 'nowrap',
  appearance: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  bg: 'transparent',
  border: '1px',
  borderStyle: 'solid',
  borderColor: 'onSurface.layoutHelperLight',
  borderRadius: 'lg',
  cursor: 'pointer',

  _focus: {
    borderColor: 'secondary.highEmphasis',
    outlineStyle: 'solid',
    outlineColor: 'secondary.lowEmphasis',
    outlineWidth: '2px',
  },

  _hover: {
    borderColor: 'primary.300',
    color: 'primary.300',
  },

  _checked: {
    borderColor: 'secondary.highEmphasis',
    color: 'secondary.highEmphasis',
    _disabled: {
      borderColor: 'secondary.lowEmphasis',
      color: 'secondary.mediumEmphasis',
    },
  },

  _disabled: {
    cursor: 'not-allowed',
    borderColor: 'onSurface.layoutHelper',
    color: 'onSurface.lowEmphasis',
  },
};

export const OptionButton = (props: OptionButtonProps) => {
  const { getInputProps: getRadioInputProps, getCheckboxProps: getRadioProps } =
    useRadio(!props.multiple ? props : {});
  const { getInputProps: getCheckboxInputProps, getCheckboxProps } =
    useCheckbox(props.multiple ? props : {});

  const input = props.multiple ? getCheckboxInputProps() : getRadioInputProps();
  const styleProps = objectFilter(props, (_, prop) => isStyleProp(prop));
  const checkbox = props.multiple ? getCheckboxProps() : getRadioProps();

  return (
    <Box as="label" {...styleProps} _checked={{ bg: 'none' }}>
      <Input {...input} _checked={{ bg: 'none' }} />
      <chakra.div sx={styles} {...checkbox} _checked={{ bg: 'green' }}>
        {props.children}
      </chakra.div>
    </Box>
  );
};
