import { Button, ButtonProps, ToastProps, forwardRef } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import {
  CurrentStepWizardProfileFragment,
  Maybe,
  TourDataWizardProfileFragment,
  useGetWizardProfileDataQuery,
  useUpdateWizardProfileDataMutation,
} from '../../../generated/graphql';
import Logger from '../../../utils/Logger';
import { WizardParentModalLayout } from '../components/WizardParentModalLayout/WizardParentModalLayout';
import { useWizardNavigateToNextStepCallback } from '../utils/hooks';
import { extractCurrentFieldError } from '../utils/utils';
import {
  WizardProfileContextProvider,
  WizardProfileTypes,
  possibleStepIds,
} from './WizardProfileContext';
import { WizardProfileOnboardingV1Avatar } from './step/OnboardingV1Avatar/WizardProfileOnboardingV1Avatar';
import { WizardProfileOnboardingV1Birthdate } from './step/OnboardingV1Birthdate/WizardProfileOnboardingV1Birthdate';
import { WizardProfileOnboardingV1Finish } from './step/OnboardingV1Finish/WizardProfileOnboardingV1Finish';
import { WizardProfileOnboardingV1Gender } from './step/OnboardingV1Gender/WizardProfileOnboardingV1Gender';
import { WizardProfileOnboardingV1Motto } from './step/OnboardingV1Motto/WizardProfileOnboardingV1Motto';
import { WizardProfileOnboardingV1Username } from './step/OnboardingV1Username/WizardProfileOnboardingV1Username';
import { WizardProfileOnboardingV1Welcome } from './step/OnboardingV1Welcome/WizardProfileOnboardingV1Welcome';
import { extractTourStepField } from './utils';

// Only for testing

export type WizardProfileBasicProps = {
  /**
   * Sets the current step in the wizard. Is needed for the wizard progress bar
   */
  setNextStep: React.Dispatch<
    React.SetStateAction<CurrentStepWizardProfileFragment | null>
  >;

  currentStep: CurrentStepWizardProfileFragment | null;

  closeWizard?: () => void;
};

export const possibleWizardProfileStepIds = [
  'OnboardingV1Welcome',
  'OnboardingV1Gender',
  'OnboardingV1Avatar',
  'OnboardingV1Username',
  'OnboardingV1Motto',
  'OnboardingV1Birthdate',
  'OnboardingV1Finish',
] as const;

export type WizardProfileStepId = (typeof possibleWizardProfileStepIds)[number];

export type WizardProfileFieldMeta = Maybe<
  Partial<{
    minLength?: Maybe<number>;
    maxLength?: Maybe<number>;
  }>
>;

export const wizardProfileSteps: WizardProfileTypes.StepsConfig = {
  OnboardingV1Welcome: {
    element: <WizardProfileOnboardingV1Welcome />,
  },
  OnboardingV1Gender: {
    element: <WizardProfileOnboardingV1Gender />,
  },
  OnboardingV1Avatar: {
    element: <WizardProfileOnboardingV1Avatar />,
  },
  OnboardingV1Username: {
    element: <WizardProfileOnboardingV1Username />,
  },
  OnboardingV1Motto: {
    element: <WizardProfileOnboardingV1Motto />,
  },
  OnboardingV1Birthdate: {
    element: <WizardProfileOnboardingV1Birthdate />,
  },
  OnboardingV1Finish: {
    element: <WizardProfileOnboardingV1Finish />,
  },
};

export const PrimaryButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button w={'full'} variant={'solid'} ref={ref} {...props} />
));

export const SecondaryButton = forwardRef<ButtonProps, 'button'>(
  (props, ref) => <Button w={'full'} ref={ref} {...props} />
);

const defaultState = {
  totalSteps: possibleStepIds.length,
  currentStep: null,
  currentField: {},
  currentFieldError: {},
  isVisible: false,
  onFinished: undefined,
};
export const WizardProfile: React.FC<{
  children?: React.ReactNode;
  isVisibleDefault?: boolean;
}> = ({ children, isVisibleDefault = false }) => {
  const { t } = useTranslation(['wizardProfile', 'validation']);

  const toast = React.useMemo(
    () => ({
      forInitialQueryError: {
        status: 'error',
        description: t('wizardProfile:toast.updateDataError'),
      } as ToastProps,
      forUpdateMutationError: {
        status: 'error',
        description: t('wizardProfile:toast.generalError'),
      } as ToastProps,
    }),
    [t]
  );

  const [state, setState] = useState<WizardProfileTypes.State>({
    ...defaultState,
    isVisible: isVisibleDefault,
  });
  const { totalSteps, currentStep, isVisible, onFinished } = state;

  const setVisible = React.useCallback(
    (isVisible: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isVisible,
      }));
    },
    [setState]
  );
  const setOnFinished = React.useCallback(
    (onFinished?: () => void) => {
      setState((prevState) => ({
        ...prevState,
        onFinished,
      }));
    },
    [setState]
  );

  const wizardCloseCallback = useCallback(() => {
    setState({
      ...defaultState,
    });
  }, []);
  const navigateToNextWizardStepCallback = useWizardNavigateToNextStepCallback(
    wizardProfileSteps,
    () => {
      wizardCloseCallback();
      onFinished?.();
    }
  );

  const setTourStepData = useCallback(
    (
      tourData?: TourDataWizardProfileFragment | null,
      addition?: Partial<WizardProfileTypes.Addition>
    ) => {
      const step = tourData?.currentStep ?? null;

      setState((prevState) => ({
        ...prevState,
        totalSteps: tourData?.totalSteps ?? possibleStepIds.length,
        currentStep: step,
        currentField: extractTourStepField(tourData),
        currentFieldError: extractCurrentFieldError(
          tourData?.validate?.data,
          t('validation:error.UnbekannterFehler')
        ),
        ...addition,
      }));

      if (tourData) {
        navigateToNextWizardStepCallback(tourData.currentStep?.id ?? null);
      }
    },
    [setState, navigateToNextWizardStepCallback, t]
  );

  const { loading: isInitialQueryLoading } = useGetWizardProfileDataQuery({
    onCompleted: (data) => {
      if (data?.tour?.__typename !== 'TourOnboardingV1') {
        return;
      }
      setTourStepData(data?.tour);
    },
    onError: (error) => {
      Logger.error(error);
      issueChakraToast(toast.forInitialQueryError);
      wizardCloseCallback();
    },
    skip: !isVisible,
  });

  const [updateStepData, { loading: isUpdateMutationLoading }] =
    useUpdateWizardProfileDataMutation({
      onError: (error) => {
        Logger.error(error);
        issueChakraToast(toast.forUpdateMutationError);
        wizardCloseCallback();
      },
    });

  const wizardNextStepCallback = React.useCallback(
    async (input?: WizardProfileTypes.Input, ignoreResults = false) => {
      if (!currentStep?.id) {
        return;
      }
      try {
        await updateStepData({
          variables: {
            step: currentStep.id,
            data: input ?? {},
          },
          onCompleted: ignoreResults
            ? undefined
            : (data) => {
                if (data?.tour?.__typename !== 'TourOnboardingV1Mutation') {
                  return;
                }

                setTourStepData(data?.tour?.update);
              },
        });
      } catch (error) {
        Logger.error(error);
      }
    },
    [updateStepData, currentStep, setTourStepData]
  );

  const contextForSteps = React.useMemo<WizardProfileTypes.Context>(() => {
    return {
      ...state,
      isInitialQueryLoading,
      isUpdateMutationLoading,
      wizardCloseCallback,
      wizardNextStepCallback,
      setVisible,
      setOnFinished,
    };
  }, [
    state,
    isInitialQueryLoading,
    isUpdateMutationLoading,
    wizardCloseCallback,
    wizardNextStepCallback,
    setVisible,
    setOnFinished,
  ]);

  return (
    <WizardProfileContextProvider value={contextForSteps}>
      {isVisible && (
        <WizardParentModalLayout
          headerText={t('wizardProfile:headerText')}
          totalSteps={totalSteps}
          currentStep={currentStep?.pos ?? 1}
          loading={isInitialQueryLoading}
          onCloseClick={wizardCloseCallback}
        >
          {currentStep &&
            wizardProfileSteps[
              currentStep!.id as keyof typeof wizardProfileSteps
            ]?.element}
        </WizardParentModalLayout>
      )}
      {children}
    </WizardProfileContextProvider>
  );
};
