import {
  Box,
  Container,
  ContainerProps,
  Modal,
  ModalContent,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import {
  FeedPhotoMediaPictureFragment,
  FskEnum,
} from '../../../generated/feed';
import { MediaPictureStatusEnum } from '../../../generated/graphql';
import { AbsoluteFullCenter } from '../../Layout/AbsoluteFullCenter';
import { FeedPostRemappedTags } from '../ImageGallery/components/FeedPostRemappedTags';
import {
  EnterFullscreenButton,
  ExitFullscreenButton,
} from '../ImageGallery/components/FullscreenButtons';
import {
  BackgroundImageContainer,
  GalleryImage,
} from '../ImageGallery/components/ImageContent';
import { ImageStatus } from '../ImageStatus/ImageStatus';

const SingleImageContent: React.FC<
  {
    image?: Maybe<FeedPhotoMediaPictureFragment>;
    fsk: Maybe<FskEnum>;
    tags: string[];
  } & ContainerProps
> = ({ image, fsk, tags, ...props }) => {
  const isRejected = image?.rejected ?? false;
  return (
    <BackgroundImageContainer
      backgroundImageProps={{
        'aria-hidden': true,
        loading: 'lazy',
        src: image?.url,
        srcSet: image?.srcSet,
        sizes: '(max-width: 800px) 100vw, 800px',
      }}
      sx={{
        filter: isRejected ? 'brightness(0.4)' : 'unset',
      }}
      {...props}
    >
      <GalleryImage
        loading="lazy"
        src={image?.url}
        srcSet={image?.srcSet}
        fsk={fsk}
        tags={tags}
        maxW={'full'}
        maxH={'full'}
        sizes="(max-width: 800px) 100vw, 800px"
      />
    </BackgroundImageContainer>
  );
};

export const ImageWithFullscreen: React.FC<
  {
    image?: Maybe<FeedPhotoMediaPictureFragment>;
    fsk: Maybe<FskEnum>;
    tags: string[];
    isDimmedDown?: boolean;
  } & ContainerProps
> = ({ image, fsk, tags, isDimmedDown = false, ...props }) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  return (
    <>
      <Container
        position={'relative'}
        maxW={'full'}
        w={'full'}
        h={'full'}
        p={'unset'}
        {...props}
      >
        <SingleImageContent
          opacity={isDimmedDown ? 0.4 : 'unset'}
          image={image}
          fsk={fsk}
          tags={tags}
        />
        <FeedPostRemappedTags tags={tags} fsk={fsk ?? undefined} />
        {!isFullscreen && (
          <EnterFullscreenButton
            position={'absolute'}
            right={'3'}
            bottom={'3'}
            onClick={() => setIsFullscreen(true)}
          />
        )}
        {image?.rejected && (
          <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
            <Box w={12}>
              <ImageStatus status={MediaPictureStatusEnum.Rejected} />
            </Box>
          </AbsoluteFullCenter>
        )}
      </Container>

      <Modal
        isOpen={isFullscreen}
        onClose={() => setIsFullscreen(false)}
        motionPreset={'none'}
      >
        <ModalContent>
          <Container
            position={'fixed'}
            maxW={'full'}
            h={'full'}
            w={'full'}
            left={0}
            top={0}
            right={0}
            bottom={0}
            background={'white'}
            p={'unset'}
          >
            <SingleImageContent image={image} fsk={fsk} tags={tags} />
            {/* Must be orderd after Image Conten, so it appears above the image */}
            <FeedPostRemappedTags tags={tags} fsk={fsk ?? undefined} />
            <ExitFullscreenButton
              position={'absolute'}
              right={'3'}
              bottom={'3'}
              onClick={() => setIsFullscreen(false)}
            />
            {image?.rejected && (
              <AbsoluteFullCenter userSelect={'none'} pointerEvents={'none'}>
                <Box w={12}>
                  <ImageStatus status={MediaPictureStatusEnum.Rejected} />
                </Box>
              </AbsoluteFullCenter>
            )}
          </Container>
        </ModalContent>
      </Modal>
    </>
  );
};
