import {
  UseDisclosureProps,
  UseDisclosureReturn,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import { createContext } from '../hooks/useContext';
import { useMedia } from './MediaProvider';

export type MediaFlowContext = UseDisclosureReturn;
export const [, useMediaFlow, mediaFlowContext] =
  createContext<MediaFlowContext>({
    name: 'MediaFlowContext',
    errorMessage:
      'useMedia: `MediaFlowContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: false,
  });

type MediaFlowProviderOptions = UseDisclosureProps & {
  clearReplacementsOnClose?: boolean;
  children?: React.ReactNode;
};

/**
 * Handles a {@link MediaFlowModal} open state and exposes action to change it
 */
export const MediaFlowProvider: React.FC<MediaFlowProviderOptions> = ({
  clearReplacementsOnClose = true,
  children,
  ...disclosureProps
}) => {
  const mediaCtx = useMedia();
  const context: MediaFlowContext = useDisclosure({
    onClose() {
      if (clearReplacementsOnClose) mediaCtx.action.clearReplacement();
    },
    ...disclosureProps,
  });
  return <mediaFlowContext.Provider value={context} children={children} />;
};
