import {
  Button,
  ButtonGroup,
  Heading,
  Img,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import {
  ChakraUniversalCard,
  UniversalCardSection,
  UniversalCardSectionWithBorder,
} from '../cards/UniversalCard/UniversalCard';

type BlockerCardProps = {
  isBordered?: boolean;
  image: string;
  imageAlt?: string;
  heading: string | JSX.Element;
  text?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onClickPrimary?: () => void;
  onClickSecondary?: () => void;
};

export const BlockerCard: React.FC<BlockerCardProps> = (props) => {
  const {
    isBordered = false,
    image,
    imageAlt,
    heading,
    text,
    primaryButtonText,
    secondaryButtonText,
    onClickPrimary,
    onClickSecondary,
  } = props;

  const content = (
    <ChakraUniversalCard
      cardBody={
        <>
          <Img src={image} alt={imageAlt ?? ''} />
          <VStack mt="4" spacing="4">
            <Heading size={{ base: 'lg', lg: 'xl' }} p={0} textAlign={'center'}>
              {heading}
            </Heading>
            <Text
              size={{ base: 'sm', lg: 'md' }}
              textAlign={'center'}
              color={'gray.500'}
              p={0}
            >
              {text}
            </Text>
          </VStack>
        </>
      }
      cardFooter={
        <ButtonGroup flexDir={'column'} gap={4} spacing={0}>
          {primaryButtonText && onClickPrimary && (
            <Button
              w={'64'}
              maxW={'full'}
              variant={'solid'}
              shadow={'none'}
              children={primaryButtonText}
              onClick={onClickPrimary}
            />
          )}
          {secondaryButtonText && onClickSecondary && (
            <Button
              w={'64'}
              maxW={'full'}
              children={secondaryButtonText}
              onClick={onClickSecondary}
            />
          )}
        </ButtonGroup>
      }
    />
  );
  return isBordered ? (
    <UniversalCardSectionWithBorder>{content}</UniversalCardSectionWithBorder>
  ) : (
    <UniversalCardSection>{content}</UniversalCardSection>
  );
};
