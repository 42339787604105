import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { ChevronRight } from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import UploadButton from '@rpldy/upload-button';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import Markdown from '../../../../components/shared/Markdown/Markdown';
import { StepDotGroup } from '../../../../components/shared/StepDotGroup/StepDotGroup';
import {
  ContestFragment,
  MediaContestTypeEnum,
  VideoStatusEnum,
  VideoTypeEnum,
} from '../../../../generated/graphql';
import { usePhotoContest } from '../../../../provider/ContestPhotoProvider';
import { useContest } from '../../../../provider/ContestProvider';
import { routes } from '../../../../routes/routesConfig';
import { ContestTileBase } from './ContestTile/ContestTileBase';
import { ContestTileEndedParticipated } from './ContestTile/ContestTileEndedParticipated';
import { ContestTileEndedParticipatedTips } from './ContestTile/ContestTileEndedParticipatedTips';
import { ContestTileEndedWon } from './ContestTile/ContestTileEndedWon';
import { ContestTilePrizes } from './ContestTile/ContestTilePrizes';
import { ContestTileTips } from './ContestTile/ContestTileTips';
import { ContestTileUploadAddPhotos } from './ContestTile/ContestTileUploadAddPhotos';
import { ContestTileUploadApproved } from './ContestTile/ContestTileUploadApproved';
import { ContestTileUploadStarted } from './ContestTile/ContestTileUploadStarted';
import {
  ContestTileUploadPending,
  ContestTileUploadRejected,
} from './ContestTile/ContestTileUploadStatus';
import { ContestTileUploadVoteStartsIn } from './ContestTile/ContestTileUploadVoteStartsIn';
import { ContestTileVoting } from './ContestTile/ContestTileVoting';
import {
  CONTEST_TILE_HEIGHT,
  CONTEST_TILE_WIDTH,
  ContestPhaseEnum,
  contestHasSubmitted,
  getContestPhase,
  goToMedia,
  shouldShowContestApproved,
  shouldShowContestPending,
  shouldShowContestRejected,
} from './ContestUtils';
import trophy from './assets/Trophy_Icon_Desktop_Home.png';

export const ContestSectionDesktop: React.FC<{
  contest: ContestFragment;
}> = ({ contest }) => {
  const { photoModalDisclosure } = usePhotoContest();
  const location = useLocation();
  const history = useHistory();
  const [tileIndex, setTileIndex] = React.useState(0);
  const nextTile = () => {
    setTileIndex((tileIndex + 1) % tiles!.length);
  };

  useEffect(() => {
    const videoRejected = contest.video?.status === VideoStatusEnum.Rejected;
    if (videoRejected) {
      setTileIndex(3);
    }
  }, [contest.video?.status]);
  useEffect(() => {
    const photoRejected = contest.photoalbum?.pictureCountRejected ?? 0;
    if (photoRejected > 0) {
      setTileIndex(1);
    }
  }, [contest.photoalbum?.pictureCountRejected]);

  const { initialPhotoContestId, initialVideoContestId } = useContest();

  // to calculate the positionInStack, replace x with the number of tiles
  // and n with the index of the tile
  // positionInStack={-((x + n - tileIndex) % x)}
  const buildTileSet = <T extends typeof ContestTileBase | null>(
    tileType: T[]
  ) => {
    return tileType
      .filter((tile) => !!tile)
      .map((tile, index, filteredArray) => {
        return React.createElement(tile!, {
          key: index,
          contest,
          positionInStack: -(
            (filteredArray.length + index - tileIndex) %
            filteredArray.length
          ),
          goToMedia: () => goToMedia(contest, location, history),
        });
      });
  };

  // determine tiles to show
  let tiles: React.ReactElement[] | null = null;
  const hasSubmitted = contestHasSubmitted(contest);
  switch (getContestPhase(contest)) {
    case ContestPhaseEnum.Upload:
      let status = null;
      if (shouldShowContestPending(contest)) {
        status = ContestTileUploadPending;
      } else if (shouldShowContestRejected(contest)) {
        status = ContestTileUploadRejected;
      } else if (shouldShowContestApproved(contest)) {
        status = ContestTileUploadApproved;
      }
      if (hasSubmitted) {
        tiles = buildTileSet([
          ContestTileUploadVoteStartsIn,
          contest.type === MediaContestTypeEnum.Photo
            ? ContestTileUploadAddPhotos
            : null,
          ContestTilePrizes,
          status,
        ]);
      } else {
        tiles = buildTileSet([
          ContestTileUploadStarted,
          ContestTilePrizes,
          ContestTileTips,
          status,
        ]);
      }
      break;
    case ContestPhaseEnum.Vote:
      if (hasSubmitted) {
        let additionalUploadTile = null;
        if (shouldShowContestPending(contest)) {
          additionalUploadTile = ContestTileUploadPending;
        } else if (shouldShowContestRejected(contest)) {
          additionalUploadTile = ContestTileUploadRejected;
        }
        tiles = buildTileSet([
          ContestTileVoting,
          ContestTilePrizes,
          additionalUploadTile,
        ]);
      }
      break;
    case ContestPhaseEnum.Ended:
      const showEnded =
        (contest.type === MediaContestTypeEnum.Photo &&
          contest.id === initialPhotoContestId) ||
        (contest.type === MediaContestTypeEnum.Video &&
          contest.id === initialVideoContestId);
      if (showEnded) {
        if (hasSubmitted) {
          if (
            !!contest.userPlace &&
            contest.userPlace <= contest.prizes.length
          ) {
            tiles = buildTileSet([ContestTileEndedWon]);
          } else {
            tiles = buildTileSet([
              ContestTileEndedParticipated,
              ContestTileEndedParticipatedTips,
            ]);
          }
        }
      }
      break;
  }
  if (tiles == null || tiles.length === 0) {
    return null;
  }

  return (
    <Section>
      <SectionCenterContainer>
        <HStack>
          {/* Contest Info */}
          <Box>
            <SectionHeader pb={0} gap={0}>
              <SectionTitleRow alignItems={'center'}>
                <Image src={trophy} boxSize="icon.md" />
                <SectionTitle>{t('contest:heading.Wettbewerb')}</SectionTitle>
              </SectionTitleRow>
              <SectionDescription>
                {t(
                  'contest:heading.WettbewerbeHelfenDirAufmerksamkeitFurDichUndDeinProfilZuGenerieren'
                )}
              </SectionDescription>
            </SectionHeader>
            <SectionBody display={'block'} fontSize={'14px'}>
              <Heading
                as={'h3'}
                fontSize={'16px'}
                fontWeight={'semibold'}
                pb={3}
                lineHeight={1.2}
              >
                {contest.title}
              </Heading>
              <Markdown markdown={contest.description} />
              <Text fontWeight={'500'} py={5}>
                {t('contest:text.AchteAufUnsere')}
                <Button
                  variant={'link'}
                  fontSize={'1em'}
                  onClick={() => history.push(routes.houseRules.path)}
                >
                  {t('contest:button.ContentRegeln')}
                </Button>
                .
              </Text>
              {!hasSubmitted ? (
                contest.type === MediaContestTypeEnum.Video ? (
                  <Button
                    as={UploadButton}
                    params={{ type: VideoTypeEnum.VideoContest }}
                    type={'button'}
                  >
                    {t('contest:button.AnWettbewerbTeilnehmen')}
                  </Button>
                ) : (
                  <Button
                    type={'button'}
                    onClick={photoModalDisclosure.onOpen}
                    isDisabled={hasSubmitted}
                  >
                    {t('contest:button.AnWettbewerbTeilnehmen')}
                  </Button>
                )
              ) : (
                // UploadButton can't be disabled, apparently
                <Button type={'button'} isDisabled={true}>
                  <Icon as={icons.Check} boxSize={'icon.md'} mr={2} />
                  {t('contest:button.BereitsTeilgenommen')}
                </Button>
              )}
            </SectionBody>
          </Box>

          {/* Tiles */}
          <HStack my={6}>
            <VStack>
              <Box
                width={CONTEST_TILE_WIDTH}
                height={CONTEST_TILE_HEIGHT}
                m={2}
              >
                {tiles.map((tile: React.ReactNode) => tile)}
              </Box>
              {tiles.length > 1 && (
                <StepDotGroup
                  totalSteps={tiles.length}
                  currentStep={tileIndex + 1}
                />
              )}
            </VStack>
            {tiles.length > 1 && (
              <IconButton
                aria-label="next"
                as={ChevronRight}
                color={'white'}
                onClick={nextTile}
                backgroundColor={'primary.200'}
                border={'none'}
                size={'xs'}
                cursor={'pointer'}
              />
            )}
          </HStack>
        </HStack>
      </SectionCenterContainer>
    </Section>
  );
};
