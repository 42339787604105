import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import React from 'react';

export type RelativeBoxProps = { attachment?: React.ReactNode } & BoxProps;
/**
 * Wraps a component with a `position: relative;` container,
 * so that the "attachment" can be positioned absolutely in this container
 *
 * Usually this is used to place a "Dot" in the upper right corner
 *
 * @example
 * // ╭────────────────────╴● <- <CornerBubble/>
 * // │ <WrappedComponent/> │
 * // ╰─────────────────────╯
 * <RelativeBox attachment={<CornerDot isShown={showDot} />}>
 *   <WrappedComponent />
 * </RelativeBox>
 */
export const RelativeBox = forwardRef<RelativeBoxProps, 'div'>(
  ({ attachment = null, children, ...boxProps }, ref) => (
    <Box position={'relative'} {...boxProps} ref={ref}>
      {children}
      {attachment}
    </Box>
  )
);
