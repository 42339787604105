import { DateTime } from 'luxon';
import React from 'react';

import { useMinuteStamp } from '../provider/MinuteStampProvider';

interface UseExpiresInDaysOptions {
  expireAt?: string;
  daysToTreatAsExpiring?: number;
}

interface UseExpiresInDaysResult {
  daysTillExpired: null | number;
  isExpiring: boolean;
  isExpired: boolean;
}
export const useExpiresInDays = ({
  daysToTreatAsExpiring = 31,
  expireAt,
}: UseExpiresInDaysOptions) => {
  const { minuteStamp } = useMinuteStamp();

  return React.useMemo<UseExpiresInDaysResult>(() => {
    const unexpieringResult = {
      daysTillExpired: null,
      isExpired: false,
      isExpiring: false,
    };
    if (!expireAt) {
      return unexpieringResult;
    }
    const minute = DateTime.fromISO(minuteStamp);
    const expireTime = DateTime.fromISO(expireAt);

    if (!minute.isValid || !expireTime.isValid) {
      return unexpieringResult;
    }

    const timeDifference = expireTime.diff(minute, 'days');
    const daysTillExpired = Math.ceil(timeDifference.days);

    const isExpiring = daysTillExpired <= daysToTreatAsExpiring;
    const isExpired = daysTillExpired <= 0;
    return {
      daysTillExpired,
      isExpiring,
      isExpired,
    };
  }, [minuteStamp, expireAt, daysToTreatAsExpiring]);
};
