import { FormikContextType, FormikProvider } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { InputList } from '../../../../../../../components/Spacer/InputsList/InputList';
import { ClearableInputControl } from '../../../../../../../components/shared/FormikFormElements';
import { useWizardPayoutBankDataBicByIbanLazyQuery } from '../../../../../../../generated/graphql';
import { useDebounce } from '../../../../../../../hooks/useDebounce';
import { WPPayoutData } from '../../WizardPayoutPayoutDataTypes';

type WizardPayoutPayoutDataBankTransferTabProps = {
  form: FormikContextType<WPPayoutData.BankTransferFormValues>;
  autoQueryBicEnabled: boolean;
  isIbanEnforced?: boolean;
};

const WizardPayoutPayoutDataBankTransferTab: React.FC<
  WizardPayoutPayoutDataBankTransferTabProps
> = ({ form, autoQueryBicEnabled, isIbanEnforced }) => {
  const { t } = useTranslation(['payout']);

  const debouncedIbanInput = useDebounce(
    form.values[WPPayoutData.ibanInputName],
    250
  );

  const [getBicByIban] = useWizardPayoutBankDataBicByIbanLazyQuery({
    onCompleted: (data) => {
      if (data?.finances?.bicByIban) {
        form.setFieldValue(
          WPPayoutData.bicInputName,
          data?.finances?.bicByIban
        );
      }
    },
  });

  useEffect(() => {
    if (!autoQueryBicEnabled) {
      return;
    }

    // shortest possible iban is 15 chars for norway
    // @see https://www.iban.com/structure
    if (debouncedIbanInput.length < 15) {
      return;
    }

    getBicByIban({
      variables: {
        iban: debouncedIbanInput,
      },
    });
  }, [debouncedIbanInput, autoQueryBicEnabled, getBicByIban]);

  return (
    <FormikProvider value={form}>
      <InputList>
        <ClearableInputControl
          name={WPPayoutData.accountHolderInputName}
          label={t('payout:label.Kontoinhaber')}
          inputProps={{
            placeholder: t('payout:placeholder.NamenEingeben'),
          }}
        />
        <ClearableInputControl
          name={WPPayoutData.ibanInputName}
          label={
            isIbanEnforced
              ? t('payout:label.IBAN')
              : t('payout:label.Kontonummer')
          }
          inputProps={{
            placeholder: isIbanEnforced
              ? t('payout:placeholder.IBANEingeben')
              : t('payout:placeholder.KontonummerEingeben'),
          }}
        />
        <ClearableInputControl
          name={WPPayoutData.bicInputName}
          label={
            isIbanEnforced
              ? t('payout:label.BIC')
              : t('payout:label.BICSWIFTCode')
          }
          inputProps={{
            placeholder: isIbanEnforced
              ? t('payout:placeholder.BICEingeben')
              : t('payout:placeholder.BICSWIFTCodeEingeben'),
          }}
        />
      </InputList>
    </FormikProvider>
  );
};

export default WizardPayoutPayoutDataBankTransferTab;
