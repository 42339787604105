import { useTranslation } from 'react-i18next';

import illustrationImageRejected from '../../../../assets/images/shared/model-profile-rejected.svg';
import { ModelProfileSimpleInfoPage } from '../../layout/ModelProfileSimpleInfoPage/ModelProfileSimpleInfoPage';

export const ModelProfileRejectedProfileInfoPage: React.FC = () => {
  const { t } = useTranslation(['modelProfile']);
  return (
    <ModelProfileSimpleInfoPage
      heading={t('modelProfile:heading.DeinBildWurdeAbgelehnt')}
      description={t(
        'modelProfile:text.LeiderWurdeDeinProfilbildAbgelehntBitteLadeEinAnderesBildHoch'
      )}
      buttonText={t('modelProfile:button.AnderesBildHochladen')}
      image={illustrationImageRejected}
      imgAlt={t('modelProfile:imgAlt.ModelAvatarWurdeAbgelehnt')}
    />
  );
};
