import ENV from '../../../environments/environment';
import {
  GetWizardPayoutDataV2Query,
  ProfileFieldsEnum,
  TourDataWizardPayoutV2Fragment,
  WizardStepFieldFragment,
} from '../../../generated/graphql';
import { routes } from '../../../routes/routesConfig';

export const getOrigin = () => window.location?.origin ?? ENV.HOST;
export const PayoutV2ContinueHash = '#payoutV2Continue';
export const paperlessOriginatorMrfRedirectUrl = `${getOrigin()}${
  routes.home.path + PayoutV2ContinueHash
}`;

export function extractTourStepField<
  F extends {
    [key in ProfileFieldsEnum[number]]: Partial<WizardStepFieldFragment>;
  }
>(_: TourDataWizardPayoutV2Fragment | null | undefined): Partial<F> {
  const list = _?.currentStep?.fields ?? [];
  if (list.length <= 0) {
    return {};
  }

  return list.reduce<Partial<F>>((acc, field) => {
    if (!field?.name) {
      return acc;
    }
    return { ...acc, [field?.name]: field };
  }, {});
}

export function extractFields(data?: GetWizardPayoutDataV2Query) {
  const collection = data?.profile.collection;
  return collection?.__typename === 'ProfileCollectionAccountV1'
    ? collection.fields
    : null;
}
