import { FormikHelpers, FormikTouched, FormikValues } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import React from 'react';
import { isElement } from 'react-dom/test-utils';

export function propertiesToBoolean2<
  Values extends FormikValues = FormikValues
>(structure: FormikErrors<Values> | Values): FormikTouched<Values> {
  return Object.entries(structure).reduce(
    (acc, [name, value]) => ({
      ...acc,
      [name]:
        typeof value === 'object'
          ? // An object can either be a nested object with other fields (proceed recursively),
            // or a React.Element indicating it containing an error
            // @see ErrorMessageWithIcon
            isElement(value) || propertiesToBoolean2(value)
          : !!value,
    }),
    {} as FormikTouched<Values>
  );
}

export function useFormikErrorSetter<Value extends FormikValues = FormikValues>(
  formik: FormikHelpers<Value>
) {
  const { setErrors, setTouched } = formik;

  return React.useCallback(
    async (error: FormikErrors<Value>) => {
      const touched = propertiesToBoolean2(error);

      setErrors(error);
      // Do not evaluate form after setting touched,
      // to prevent local evaluation to override previously set backend errors
      await setTouched(touched, false);
    },
    [setErrors, setTouched]
  );
}
