import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { FeedConversationFragment } from '../../../generated/feed';
import { usePostCommenting } from '../../../provider/PostCommentingProvider';
import Logger from '../../../utils/Logger';
import { ConfirmCommentDeletionDialog } from '../dialogs/ConfirmCommentDeletionDialog/ConfirmCommentDeletionDialog';
import { CommentCreate } from './CommentCreate';
import { CommentFromUser } from './CommentFromUser';
import { FeedCommentEdit } from './FeedCommentEdit';
import { FeedCommentFromOtherUser } from './FeedCommentFromOtherUser';
import { FeedCommentReply } from './FeedCommentReply';
import {
  CommentReplyList,
  HideReplies,
  ShowAllReplies,
  itemVariants,
  listVariants,
} from './shared';

export const ConversationBox: React.FC<BoxProps> = (props) => (
  <Box as={'div'} height="100%" {...props} />
);

export type FeedConversationProps = {
  conversation: FeedConversationFragment;
};

export const Variants = {
  inactive: {
    height: '0',
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  active: {
    height: '100%',
    transition: {
      duration: 0.5,
      delay: 0.6,
    },
  },
};

/**
 * Manages the state of e conversation
 * * comment edit state
 * * new replies
 * * listing replies
 */
export const FeedConversation: React.FC<FeedConversationProps> = ({
  conversation,
}) => {
  const ctx = usePostCommenting();
  const { comment, replies } = conversation;
  const hasReplies = replies && replies.length > 0 && replies.length <= 3;
  const hasMoreThanThreeReplies = replies && replies.length > 3;
  const replyTo = useDisclosure();
  const edit = useDisclosure();
  const confirmDeletionDialog = useDisclosure({ defaultIsOpen: false });
  const [commentIdToDelete, setCommentIdToDelete] = React.useState('');
  const showReplies = useDisclosure();
  const [newReplyIds, setNewReplyIds] = React.useState<string[]>([]);
  const repliesVisible =
    (newReplyIds.length > 0 && hasMoreThanThreeReplies) ||
    hasReplies ||
    replyTo.isOpen ||
    showReplies.isOpen;

  const commentIsFromAccount = comment.user.id === ctx.account?.id;

  const openConfirmDeletionDialog = (commentId: string) => {
    setCommentIdToDelete(commentId);
    confirmDeletionDialog.onOpen();
  };

  return (
    <ConversationBox>
      <ConfirmCommentDeletionDialog
        isOpen={confirmDeletionDialog.isOpen}
        onClose={confirmDeletionDialog.onClose}
        commentId={commentIdToDelete}
        deleteComment={ctx.action.deleteComment}
      />
      {!commentIsFromAccount && (
        <FeedCommentFromOtherUser
          key={comment.id}
          avatarSrc={comment.user.avatar?.url}
          username={comment.user.name ?? ''}
          text={comment.text}
          created={comment.created}
          hasOpenReplyTo={replyTo.isOpen}
          onReplyTo={replyTo.onOpen}
          commentId={comment.id}
          openConfirmDeletionDialog={openConfirmDeletionDialog}
          tipped={comment.tipped}
        />
      )}
      {commentIsFromAccount &&
        (!edit.isOpen ? (
          <CommentFromUser
            key={comment.id}
            avatarSrc={comment.user.avatar?.url}
            username={comment.user.name ?? ''}
            text={comment.text}
            created={comment.created}
            onEdit={edit.onOpen}
            commentId={comment.id}
            openConfirmDeletionDialog={openConfirmDeletionDialog}
          />
        ) : (
          <FeedCommentEdit
            key={`${comment.id}-edit`}
            avatarSrc={comment.user.avatar?.url}
            username={comment.user.name ?? ''}
            initialText={comment.text}
            onDiscard={edit.onClose}
            onSubmit={async (values, formikHelpers) => {
              const text = values.text;
              try {
                await ctx.action.editComment(text, comment.id);
                edit.onClose();
              } catch (error) {
                Logger.error(error);
                formikHelpers.setSubmitting(false);
              }
            }}
          />
        ))}
      {repliesVisible && (
        <CommentReplyList>
          {replyTo.isOpen && (
            <CommentCreate
              avatarSrc={ctx.account?.avatar?.url}
              username={ctx.account?.name}
              repliesToCommentId={comment.id}
              onClose={replyTo.onClose}
              onSubmit={async (values, formikHelpers) => {
                const text = values.text;
                try {
                  const result = await ctx.action.addReply(text, comment.id);
                  if (result.errors) {
                    Logger.error(result.errors);
                  } else {
                    replyTo.onClose();
                    setNewReplyIds((prevVal) =>
                      !result.data?.vxmodels?.commentPost?.id
                        ? prevVal
                        : [...prevVal, result.data?.vxmodels?.commentPost?.id]
                    );
                  }
                } catch (error) {
                  Logger.error(error);
                  formikHelpers.setSubmitting(false);
                }
              }}
              registerOpenComment={ctx.action.registerOpenComment}
            />
          )}
          <AnimatePresence initial={true}>
            <motion.div
              key={comment.id + 'replylist'}
              initial="inactive"
              animate="active"
              variants={listVariants}
              exit="exit"
            >
              <AnimatePresence initial={true}>
                {newReplyIds.length > 0 &&
                  replies.length > 0 &&
                  replies
                    .filter((reply) => newReplyIds.includes(reply.id))
                    .map((reply) => (
                      <motion.div
                        variants={itemVariants}
                        key={reply.id + 'motion-div'}
                      >
                        <FeedCommentReply
                          key={`${reply.id}-replyWrapper`}
                          reply={reply}
                          openConfirmDeletionDialog={openConfirmDeletionDialog}
                        />
                      </motion.div>
                    ))}

                {(hasReplies || showReplies.isOpen) &&
                  replies
                    .filter((reply) => !newReplyIds.includes(reply.id))
                    .map((reply) => (
                      <motion.div
                        variants={itemVariants}
                        key={reply.id + 'motion-div'}
                      >
                        <FeedCommentReply
                          key={`${reply.id}-replyWrapper`}
                          reply={reply}
                          openConfirmDeletionDialog={openConfirmDeletionDialog}
                        />
                      </motion.div>
                    ))}
              </AnimatePresence>
            </motion.div>
          </AnimatePresence>
        </CommentReplyList>
      )}

      {newReplyIds.length !== replies.length &&
        hasMoreThanThreeReplies &&
        !showReplies.isOpen && (
          <ShowAllReplies
            replyCount={replies.length}
            onClick={showReplies.onOpen}
          />
        )}

      {hasMoreThanThreeReplies && showReplies.isOpen && (
        <HideReplies onClick={showReplies.onClose} />
      )}
    </ConversationBox>
  );
};
