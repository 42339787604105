import { useTranslation } from 'react-i18next';

import VXFeedFilteredEmptyStateImage from '../../../../../../../assets/images/shared/list-empty-state.svg';
import { BlockerCard } from '../../../../../../../components/shared/BlockerCard/BlockerCard';

export const NoPostsIfFilteredBlockedCard = () => {
  const { t } = useTranslation(['feed']);

  return (
    <BlockerCard
      image={VXFeedFilteredEmptyStateImage}
      heading={t('feed:noPostsIfFilteredBlockerCard.heading')}
      text={t('feed:noPostsIfFilteredBlockerCard.text')}
    />
  );
};
