import { debounce } from 'debounce';
import { FormikContextType } from 'formik';
import { useEffect, useMemo } from 'react';

export const useDebouncedFormikValidation = (
  formik: FormikContextType<any>,
  delay = 100
) => {
  const { values, validateForm } = formik;

  const debouncedValidate = useMemo(
    () => debounce(validateForm, delay),
    [validateForm, delay]
  );

  useEffect(() => {
    debouncedValidate(values)?.then();
    return () => debouncedValidate.clear();
  }, [values, debouncedValidate]);
};
