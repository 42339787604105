import { Box } from '@chakra-ui/react';
import { FormikContextType, FormikProvider } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ClearableInputControl } from '../../../../../../components/shared/FormikFormElements';
import { TipCard } from '../../../../../../components/shared/cards/TipCard/TipCard';
import { WPBankData } from '../../WizardPayoutBankDataTypes';

type WizardPayoutBankDataPaxumTabProps = {
  form: FormikContextType<WPBankData.PaxumFormValues>;
};

const WizardPayoutBankDataPaxumTab: React.FC<
  WizardPayoutBankDataPaxumTabProps
> = ({ form }) => {
  const { t } = useTranslation(['wizardPayout', 'payout']);

  return (
    <FormikProvider value={form}>
      <ClearableInputControl
        helperText={t(
          'wizardPayout:text.VergewissereDichBitteKeinenTippfehlerGemachtZuHaben'
        )}
        label={t(`payout:label.EMailAdresseDeinesPaxumAccounts`)}
        name={WPBankData.paxumEmailInputName}
        inputProps={{
          autoComplete: 'off',
          placeholder: t(`payout:placeholder.PaxumEMailEingeben`),
          type: 'email',
        }}
      />
      <Box w={'full'} mt={12}>
        <TipCard
          text={t(
            'wizardPayout:text.WennSieNichtInDerSEPAZoneLebenEmpfehlenWirIhnenPaxumZuWahlenAufDieseXX'
          )}
        />
      </Box>
    </FormikProvider>
  );
};

export default WizardPayoutBankDataPaxumTab;
