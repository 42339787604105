import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import {
  Container,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { TabListWithOverflowScroll } from '../../components/Layout/TabListWithOverflowScroll';
import StatisticsProfileHeader from '../../components/shared/StatisticsProfileHeader/StatisticsProfileHeader';
import { useGetStatisticsPageQuery } from '../../generated/graphql';
import { extractFromUnion } from '../../utils/extractor';
import { DetailedStatisticsBarChart } from './components/DetailedStatisticsBarChart/DetailedStatisticsBarChart';
import { DetailedStatisticsPhoneIncome } from './components/DetailedStatisticsPhoneIncome/DetailedStatisticsPhoneIncome';
import { DetailedStatisticsSharingParent } from './components/DetailedStatisticsSharings/DetailedStatisticsSharings';
import { DetailedStatisticsTelegramIncome } from './components/DetailedStatisticsTelegramIncome/DetailedStatisticsTelegramIncome';
import { DetailedStatisticsUserList } from './components/DetailedStatisticsUserList/DetailedStatisticsUserList';
import {
  StatisticsDetailedCategoryExtEnum,
  StatisticsDetailedCategoryExtEnumType,
  useTabsContext,
} from './provider/TabContext';

const StatisticsPage: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { tabIds, handleTabChange, tabIndex, tabIdToName } = useTabsContext();

  const { data } = useGetStatisticsPageQuery();

  const mediaCollection = extractFromUnion(
    data?.profile?.mediaCollection,
    'ProfileCollectionMobileMediaV1'
  );
  const aboutMeCollection = extractFromUnion(
    data?.profile?.aboutMeCollection,
    'ProfileCollectionMobileAboutMeV1'
  );

  const avatarImg = mediaCollection?.fields.modelPictureFSK16Avatar;
  const username = aboutMeCollection?.fields?.modelUsername?.value ?? '';

  const spacing = { base: 4, lg: 8 };

  const tabIdToTabContent: Record<
    StatisticsDetailedCategoryExtEnumType,
    React.ReactNode
  > = {
    [StatisticsDetailedCategoryExtEnum.OverAll]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.Affiliate]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.LiveChat]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
        <DetailedStatisticsSharingParent />
        <DetailedStatisticsUserList />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.VideoLibrary]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
        <DetailedStatisticsSharingParent />
        <DetailedStatisticsUserList />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.Feed]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
        <DetailedStatisticsSharingParent />
        <DetailedStatisticsUserList />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.Messenger]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
        <DetailedStatisticsSharingParent />
        <DetailedStatisticsUserList />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.PhoneService]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
        <DetailedStatisticsPhoneIncome />
        <DetailedStatisticsUserList />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.Telegram]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
        <DetailedStatisticsTelegramIncome />
        <DetailedStatisticsUserList />
      </VStack>
    ),
    [StatisticsDetailedCategoryExtEnum.Misc]: (
      <VStack spacing={spacing}>
        <DetailedStatisticsBarChart />
      </VStack>
    ),
  };

  return (
    <FluentPageLayout>
      <SubHeader>
        <Container maxW="container.xl" p={0}>
          <SubHeaderTitle>{t('statisticsPage:headerText')}</SubHeaderTitle>
        </Container>
      </SubHeader>
      <VStack
        maxW={'container.xl'}
        marginX={'auto'}
        alignItems={'stretch'}
        spacing={{ base: '4', lg: '8' }}
      >
        <StatisticsProfileHeader
          modelPictureFSK16Avatar={avatarImg}
          isEditable={false}
          username={username}
        />
      </VStack>
      <Container maxW="container.xl" p={0}>
        <VStack spacing={spacing} w={'full'} my={spacing}>
          <Tabs
            defaultIndex={0}
            index={tabIndex}
            isFitted
            w={'full'}
            onChange={handleTabChange}
            size={{ base: 'sm', lg: 'md' }}
          >
            <TabListWithOverflowScroll>
              {tabIds.map((tab) => (
                <Tab key={tab}>{tabIdToName(tab)}</Tab>
              ))}
            </TabListWithOverflowScroll>
            <TabPanels>
              {tabIds.map((tab) => (
                <TabPanel p={0} key={tab}>
                  {tabIdToTabContent[tab]}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </VStack>
      </Container>
    </FluentPageLayout>
  );
};

export default StatisticsPage;
