import { Heading, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import dataCheckPendingImage from '../../../../assets/images/finance/get-paid-review.svg';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../cards/UniversalCard/UniversalCard';

const PayoutDataCheckPendingCard: React.FC = () => {
  const { t } = useTranslation(['payout', 'general']);

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={dataCheckPendingImage}
              alt={t('general:img.ZuBearbeitendesDokument')}
            />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t('payout:headline.DeineAuszahlungsdatenWerdenUberpruft')}
              </Heading>
              <Text
                size={{ base: 'sm', lg: 'md' }}
                textAlign={'center'}
                color={'gray.500'}
                p={0}
              >
                {t(
                  'payout:text.DuBekommstEineEMailSobaldDeineDokumenteUberpruftWurden'
                )}
              </Text>
            </VStack>
          </>
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
export default PayoutDataCheckPendingCard;
