import {
  Section,
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { AccountBalance } from '@campoint/odi-ui-icons';
import { Box, Divider, HStack, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { RadioGroupHookControl } from '../../../../components/shared/HookFormForms/RadioGroupHookCrontrol/RadioGroupHookControl';
import {
  StatusDone,
  StatusPending,
} from '../../../../components/shared/StatusIndicator/StatusIndicator';
import { TipCard } from '../../../../components/shared/cards/TipCard/TipCard';
import {
  FinancesPayoutMethodEnum,
  GetPayoutDocumentsPayoutSectionDocument,
  useGetPayoutDocumentsPayoutSectionQuery,
  useUpdatePayoutDocumentsPayoutSectionMutation,
} from '../../../../generated/graphql';
import { useBicAutocomplete } from '../../../../hooks/useBicAutocomplete';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import Logger from '../../../../utils/Logger';
import { createStringValidationSchema } from '../../../../utils/validation';
import { getFieldInitialErrors } from '../../../ModelProfileV2Page/ModelProfilePage/utils';
import { SubmitChangesButtonWithConfirmation } from '../components/SubmitChangesButtonWithConfirmation/SubmitChangesButtonWithConfirmation';
import { PaymentRejectedAlert } from '../components/alert/PaymentRejectedAlert/PaymentRejectedAlert';

const fieldName = {
  payoutMethod: 'paymentPayoutDataPayoutMethod',
  bankaccountOwner: 'paymentPayoutDataBankaccountOwner',
  iban: 'paymentPayoutDataBankIban',
  bic: 'paymentPayoutDataBankBic',
  paxumEmail: 'paymentPayoutDataPaxumEmailAccount',
} as const;

interface FormValues {
  [fieldName.payoutMethod]: FinancesPayoutMethodEnum;
  [fieldName.bankaccountOwner]: string;
  [fieldName.iban]: string;
  [fieldName.bic]: string;
  [fieldName.paxumEmail]: string;
}

export const PayoutDocumentsPayoutSection: React.FC = () => {
  const { t } = useTranslation([
    'payout',
    'general',
    'wizardPayout',
    'document',
  ]);

  const issueSuccesToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.AnderungenWurdenGespeichert'),
      status: 'success',
    });
  }, [t]);

  const issueErrorToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      status: 'error',
    });
  }, [t]);

  const { data, loading } = useGetPayoutDocumentsPayoutSectionQuery();

  const [updatePayoutDocumentsPayoutSection] =
    useUpdatePayoutDocumentsPayoutSectionMutation({
      refetchQueries: [GetPayoutDocumentsPayoutSectionDocument],
    });

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const initialValues = React.useMemo<FormValues>(() => {
    const fields = data?.payment?.payoutData;

    return {
      [fieldName.payoutMethod]: (fields?.payoutMethod?.value ??
        FinancesPayoutMethodEnum.Bank) as FinancesPayoutMethodEnum,
      [fieldName.bankaccountOwner]: fields?.bankAccountOwner?.value ?? '',
      [fieldName.iban]: fields?.bankIban?.value ?? '',
      [fieldName.bic]: fields?.bankBic?.value ?? '',
      [fieldName.paxumEmail]: fields?.paxumEmailAccount?.value ?? '',
    };
  }, [data]);

  const validationSchema = React.useMemo(() => {
    const fields = data?.payment?.payoutData;

    return Yup.object().shape({
      [fieldName.bankaccountOwner]: createStringValidationSchema({
        ...fields?.bankAccountOwner,
      }).when(fieldName.payoutMethod, (method, schema) => {
        return method !== FinancesPayoutMethodEnum.Bank ? Yup.string() : schema;
      }),
      [fieldName.iban]: createStringValidationSchema({
        ...fields?.bankIban,
      }).when(fieldName.payoutMethod, (method, schema) => {
        return method !== FinancesPayoutMethodEnum.Bank ? Yup.string() : schema;
      }),
      [fieldName.bic]: createStringValidationSchema({
        ...fields?.bankBic,
      }).when(fieldName.payoutMethod, (method, schema) => {
        return method !== FinancesPayoutMethodEnum.Bank ? Yup.string() : schema;
      }),
      [fieldName.paxumEmail]: createStringValidationSchema({
        ...fields?.paxumEmailAccount,
        isEmail: true,
      }).when(fieldName.payoutMethod, (method, schema) => {
        return method !== FinancesPayoutMethodEnum.Paxum
          ? Yup.string()
          : schema;
      }),
    });
  }, [data]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);

  const isFormDirty = hookForm.formState.isDirty;

  React.useEffect(() => {
    return registerDirtyFlag(isFormDirty, onOmitChanges);
  }, [isFormDirty, onOmitChanges, registerDirtyFlag]);

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [loading, hookForm, initialValues]);

  const currentIbanValue = hookForm.watch(fieldName.iban);

  useBicAutocomplete({
    disabled: !hookForm.formState.touchedFields[fieldName.iban],
    iban: currentIbanValue,
    onMatch: ({ iban, bic }) => {
      if (iban !== currentIbanValue || !bic) {
        return;
      }
      hookForm.setValue(fieldName.bic, bic, {
        shouldTouch: true,
        shouldValidate: true,
      });
    },
  });

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const payoutMethod = data[fieldName.payoutMethod];
        if (
          ![
            FinancesPayoutMethodEnum.Bank,
            FinancesPayoutMethodEnum.Paxum,
          ].includes(payoutMethod)
        ) {
          Logger.error('unsupported payoutMethod');
          return;
        }

        const result = await updatePayoutDocumentsPayoutSection({
          variables:
            payoutMethod === FinancesPayoutMethodEnum.Bank
              ? {
                  data: {
                    payoutMethod: FinancesPayoutMethodEnum.Bank,
                    bankAccountOwner: data[fieldName.bankaccountOwner],
                    bankIban: data[fieldName.iban],
                    bankBic: data[fieldName.bic],
                  },
                }
              : {
                  data: {
                    payoutMethod: FinancesPayoutMethodEnum.Paxum,
                    paxumEmailAccount: data[fieldName.paxumEmail],
                  },
                },
        });

        const errors = getFieldInitialErrors(
          (result?.data?.payment?.payoutData?.updatePayoutData as any) ?? null,
          'unkown error'
        );

        if (!!errors) {
          Object.entries(errors).forEach(([key, entry]) => {
            hookForm.setError(key as any, {
              type: 'manual',
              message: entry as any,
            });
          });
          issueErrorToast();
        } else {
          issueSuccesToast();
        }
      } catch (error) {
        Logger.error(error);
        issueErrorToast();
      }
    },
    [
      hookForm,
      updatePayoutDocumentsPayoutSection,
      issueSuccesToast,
      issueErrorToast,
    ]
  );

  const onInvalidSubmit: SubmitErrorHandler<FormValues> = React.useCallback(
    (error) => {
      Logger.error(error);
    },
    []
  );

  const showPaxum = data?.payment?.payoutData?.showPaxum;
  const isIbanEnforced = !showPaxum;
  const showBankAccount = data?.payment?.payoutData?.showBankAccount;
  const showTransactionTypeRadios = React.useMemo(() => {
    if (!showPaxum) {
      return false;
    }
    if (!showBankAccount) {
      return false;
    }

    return true;
  }, [showPaxum, showBankAccount]);

  // Beware! These might be still loading
  const isBankTransferDisabled = !showBankAccount;
  const isPaxumDisabled = !showPaxum;

  const currentTransactionTypeValue = hookForm.watch(fieldName.payoutMethod);

  const overallStatus = React.useMemo<string | undefined>(() => {
    if (isFormDirty) {
      return 'dirty';
    }
    return data?.payment?.payoutData?.status ?? undefined;
  }, [data, isFormDirty]);

  if (loading) {
    return null;
  }

  const statusPending = overallStatus === 'pending';
  const isDisabled = loading || statusPending;
  const isSubmittingDisabled =
    isDisabled || !hookForm.formState.isValid || !isFormDirty;

  const statusIndicator = statusPending ? (
    <StatusPending text={t('general:status.InPrufung')} />
  ) : overallStatus === 'accepted' ? (
    <StatusDone text={t('general:status.Verifiziert')} />
  ) : null;

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.PayoutSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <HStack w={'full'}>
              <SectionIcon as={AccountBalance} />
              <SectionTitle children={t('payout:headline.DahinGehtDeinGeld')} />
              {statusIndicator}
            </HStack>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}>
          <SectionBody>
            <SectionCenterContainer>
              <VStack alignItems={'stretch'} spacing={8}>
                {overallStatus === 'rejected' && (
                  <PaymentRejectedAlert
                    text={
                      // || use intentional, as title can come as "",
                      // which should also fallback to predefined generic title
                      data?.payment?.payoutData?.rejectReasonTitle || undefined
                    }
                    noticeModalConfig={{
                      status: 'error',
                      // || use intentional, as title can come as "",
                      // which should also fallback to predefined generic title
                      modalHeading:
                        data?.payment?.payoutData?.rejectReasonTitle ||
                        t(
                          'payout:text.DeineNeueAuszahlungsmethodeWurdeAbgelehnt'
                        ),
                      modalTextSlot: t('payout:text.GrundReason', {
                        reason: data?.payment?.payoutData?.rejectReason ?? '',
                      }),
                    }}
                  />
                )}
                <VStack
                  divider={<Divider />}
                  spacing={8}
                  alignItems={'stretch'}
                >
                  {showTransactionTypeRadios && (
                    <RadioGroupHookControl
                      name={fieldName.payoutMethod}
                      options={[
                        {
                          isDisabled: isBankTransferDisabled || isDisabled,
                          children: t('payout:radio.Bankuberweisung'),
                          value: FinancesPayoutMethodEnum.Bank,
                        },
                        {
                          isDisabled: isPaxumDisabled || isDisabled,
                          children: t('payout:radio.Paxum'),
                          value: FinancesPayoutMethodEnum.Paxum,
                        },
                      ]}
                    />
                  )}
                  {showBankAccount &&
                    currentTransactionTypeValue ===
                      FinancesPayoutMethodEnum.Bank && (
                      <VStack
                        spacing={6}
                        divider={<Divider />}
                        alignItems={'stretch'}
                      >
                        <ClearableInputControl
                          label={t('payout:label.Kontoinhaber')}
                          placeholder={t('payout:placeholder.NamenEingeben')}
                          name={fieldName.bankaccountOwner}
                          isDisabled={isDisabled}
                        />

                        <ClearableInputControl
                          label={
                            isIbanEnforced
                              ? t('payout:label.IBAN')
                              : t('payout:label.Kontonummer')
                          }
                          placeholder={
                            isIbanEnforced
                              ? t('payout:placeholder.IBANEingeben')
                              : t('payout:placeholder.KontonummerEingeben')
                          }
                          name={fieldName.iban}
                          isDisabled={isDisabled}
                        />
                        <ClearableInputControl
                          label={
                            isIbanEnforced
                              ? t('payout:label.BIC')
                              : t('payout:label.BICSWIFTCode')
                          }
                          placeholder={
                            isIbanEnforced
                              ? t('payout:placeholder.BICEingeben')
                              : t('payout:placeholder.BICSWIFTCodeEingeben')
                          }
                          name={fieldName.bic}
                          isDisabled={isDisabled}
                        />
                      </VStack>
                    )}
                  {showPaxum &&
                    currentTransactionTypeValue ===
                      FinancesPayoutMethodEnum.Paxum && (
                      <VStack
                        spacing={6}
                        divider={<Divider />}
                        alignItems={'stretch'}
                      >
                        <ClearableInputControl
                          label={t(
                            'payout:label.EMailAdresseDeinesPaxumAccounts'
                          )}
                          text={t(
                            'wizardPayout:text.VergewissereDichBitteKeinenTippfehlerGemachtZuHaben'
                          )}
                          placeholder={t(
                            'payout:placeholder.PaxumEMailEingeben'
                          )}
                          name={fieldName.paxumEmail}
                          isDisabled={isDisabled}
                          autoComplete={'off'}
                          type={'email'}
                        />
                        <Box w={'full'} mt={12}>
                          <TipCard
                            text={t(
                              'wizardPayout:text.WennSieNichtInDerSEPAZoneLebenEmpfehlenWirIhnenPaxumZuWahlenAufDieseXX'
                            )}
                          />
                        </Box>
                      </VStack>
                    )}
                </VStack>
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          <SectionDivider isWidthRestricted />
          <SectionFooter>
            <SectionCenterContainer>
              <SubmitChangesButtonWithConfirmation
                onConfirmedSubmit={(e) => {
                  hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)(e);
                }}
                isDisabled={isSubmittingDisabled}
                isLoading={hookForm.formState.isSubmitting}
              />
            </SectionCenterContainer>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};
