export const getProcessEnv = (key: string): ProcessEnv =>
  new ProcessEnv(key, process.env[key]);

export class ProcessEnv {
  constructor(public name: string, public rawValue: string | undefined) {}

  /**
   * Convert the variable to an integer number. Throws an error if it is not convertible
   * to a number or does not exist. Does not throw an error if a `defaultValue` is given.
   * @param defaultValue Default value if environment variable is not set
   */
  asInt(defaultValue?: number): number {
    let parsedValue = parseInt(this.rawValue || '', 10);
    // Check that it only contains valid characters
    if (!(this.rawValue || '').match(/^[0-9-]+$/)) {
      parsedValue = NaN;
    }

    if (!isNaN(parsedValue)) {
      return parsedValue;
    }

    if (defaultValue === undefined) {
      throw new Error(
        `Environment variable ${this.name} must be an integer but is '${this.rawValue}'`
      );
    }
    return defaultValue;
  }

  /**
   * Convert the variable to a float number. Throws an error if it is not convertible
   * to a number or does not exist. Does not throw an error if a `defaultValue` is given.
   * @param defaultValue Default value if environment variable is not set
   */
  asFloat(defaultValue?: number): number {
    let parsedValue = parseFloat(this.rawValue || '');
    // Check that it only contains valid characters
    if (!(this.rawValue || '').match(/^[0-9-\\.]+$/)) {
      parsedValue = NaN;
    }

    if (!isNaN(parsedValue)) {
      return parsedValue;
    }

    if (defaultValue === undefined) {
      throw new Error(
        `Environment variable ${this.name} must be a float but is '${this.rawValue}'`
      );
    }
    return defaultValue;
  }

  /**
   * Convert the variable to a boolean. Throws an error if it is not `true` or `false`
   * or does not exist. Does not throw an error if a `defaultValue` is given.
   * @param defaultValue Default value if environment variable is not set
   */
  asBoolean(defaultValue?: boolean): boolean {
    let value = this.rawValue;

    // We can't rely on truthy and falsy here
    value = (value || '').trim().toLowerCase();
    if (value === 'true' || value === 'false') {
      return value === 'true';
    }

    if (defaultValue === undefined) {
      throw new Error(
        `Environment variable ${this.name} must be a boolean but is '${this.rawValue}'`
      );
    }
    return defaultValue;
  }

  /**
   * Convert the variable to a string. Throws an error if it is empty or does not exist.
   * Does not throw an error if a `defaultValue` is given.
   * @param defaultValue Default value if environment variable is not set
   */
  asString(defaultValue?: string): string {
    if (this.rawValue) {
      return this.rawValue;
    }

    if (defaultValue === undefined) {
      throw new Error(
        `Environment variable ${this.name} must be a string but is '${this.rawValue}'`
      );
    }
    return defaultValue;
  }

  /**
   * Convert the variable to a string. Throws an error if it is empty or does not exist.
   * Does not throw an error if a `defaultValue` is given.
   * @param possibleStrings
   * @param defaultValue Default value if environment variable is not set
   */
  asOneOfStrings<T extends readonly string[]>(
    possibleStrings: T,
    defaultValue?: T[number]
  ): T[number] {
    if (
      this.rawValue !== undefined &&
      possibleStrings.includes(this.rawValue)
    ) {
      return this.rawValue;
    }

    if (defaultValue === undefined) {
      throw new Error(
        `Environment variable ${
          this.name
        } must be one of the strings ['${possibleStrings.join(
          "', '"
        )}'] but is '${this.rawValue}'`
      );
    }
    return defaultValue;
  }
}
