import { Box, ToastProps } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import passportFrontImagePlaceholderImage from '../../../../../assets/images/imageUpload/passport-front-placeholder.svg';
import { ButtonStack } from '../../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../../components/Layout/ChakraToastContainer';
import { CroppedImage } from '../../../../../components/shared/ImageCropper/ImageCropper';
import { ImagePickerV2 } from '../../../../../components/shared/ImagePickerV2/ImagePickerV2';
import { TipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import { MediaInputProvider } from '../../../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { uploadDocument } from '../../../../../utils/media';
import { WizardParentModalStepLayout } from '../../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { WizardSubHeader } from '../../../components/WizardSubHeader/WizardSubHeader';
import { PrimaryButton } from '../../../components/styled';
import { useWizardPayout } from '../../WizardPayoutContext';

export const WizardPayoutOriginatorIDCardFront = () => {
  const wizardPayoutCtx = useWizardPayout();
  const uploadUrl =
    wizardPayoutCtx.currentField?.originatorIdentityProofFront?.upload?.url ??
    undefined;

  const { t } = useTranslation([
    'general',
    'wizardPayout',
    'wizardVerification',
    'imagePicker',
  ]);
  const [image, setImage] = useState<null | CroppedImage>();
  const [isUploading, setIsUploading] = useState(false);

  const { wizardNextStepCallback } = wizardPayoutCtx;

  const uploadImage = React.useCallback(async () => {
    if (!uploadUrl || !image?.blob) {
      return;
    }
    const errorToast: ToastProps = {
      status: 'error',
      description: t('wizardVerification:uploadError'),
    };

    try {
      setIsUploading(true);
      const result = await uploadDocument(uploadUrl, image.blob);
      if (result.id === null) {
        issueChakraToast(errorToast);
      } else {
        wizardNextStepCallback({
          originatorIdentityProofFront: result.id,
        });
      }
    } catch {
      issueChakraToast(errorToast);
    } finally {
      setIsUploading(false);
    }
  }, [image, t, uploadUrl, setIsUploading, wizardNextStepCallback]);

  const isLoading = isUploading;

  return (
    <WizardParentModalStepLayout
      contentSection={
        <>
          <WizardSubHeader
            headerText={t('wizardVerification:frontImage.subHeader')}
          />
          <MediaPropertiesProvider
            targetDimensions={{
              height: 864,
              width: 1368,
            }}
            determineAspectRatioByInput={true}
            isOrientationFlipAllowed={true}
            placeholderSrc={passportFrontImagePlaceholderImage}
          >
            <MediaInputProvider
              accept={'image/png,image/jpeg,image/webp,application/pdf'}
            >
              <ImagePickerV2
                cancelCropButtonLabel={t('imagePicker:cancel')}
                cropButtonLabel={t('imagePicker:crop')}
                onImage={setImage}
                status={isLoading ? 'loading' : 'add'}
                placeholder={passportFrontImagePlaceholderImage}
                targetImageDimensions={{
                  height: 864,
                  width: 1368,
                }}
                allowOrientationFlip={true}
                accept={'image/png,image/jpeg,image/webp,application/pdf'}
              />
            </MediaInputProvider>
          </MediaPropertiesProvider>
          <Box mt={12}>
            <TipCard text={t('wizardVerification:frontImage.hint')} />
          </Box>
        </>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            children={t('wizardPayout:button.Weiter')}
            onClick={uploadImage}
            isDisabled={!image || isLoading}
          />
        </ButtonStack>
      }
    />
  );
};
