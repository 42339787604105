import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApiLangEnum } from '../generated/graphql';

const possiblelanguages = [ApiLangEnum.De, ApiLangEnum.En] as const;

/**
 * Maps current i18n.language to an ApiLangEnum value
 * @example
 * ```
 * const lang = useActiveApiLanguage()
 * ```
 */
export const useActiveApiLanguage = () => {
  const { i18n } = useTranslation();

  return React.useMemo(
    () =>
      possiblelanguages.find((entry) => entry === i18n.language) ??
      ApiLangEnum.De,
    [i18n.language]
  );
};
