import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterableListContext } from '../../../provider/FilterablListProvider';
import FilterIcon from '../FilterIcon/FilterIcon';

type FilterToolRowProps = {
  context: FilterableListContext<any, any, any>;
  renderCountDisplay: (count: number) => React.ReactNode;
  children?: React.ReactNode;
};
export const FilterToolRow: React.FC<FilterToolRowProps> = ({
  context,
  renderCountDisplay,
  children,
}) => {
  const { t } = useTranslation(['general']);
  const {
    hasActiveFilter,
    resetFilter,
    toggleFilterSheet,
    filteredEntries,
    isFilterSheetOpen,
  } = context;
  return (
    <Flex
      w={'full'}
      justifyContent={'space-between'}
      alignItems={'center'}
      fontWeight={'bold'}
    >
      {renderCountDisplay(filteredEntries.length)}
      {children}
      <FilterIcon
        hasActiveFilter={hasActiveFilter}
        onFilterClick={toggleFilterSheet}
        onResetClick={resetFilter}
        ariaLabelEditFilter={t('general:button.FilterBearbeiten')}
        ariaLabelResetFilter={t('general:button.FilterZurucksetzen')}
        ariaLabelAddFilter={t('general:button.FilterHinzufugen')}
        isDisabled={isFilterSheetOpen}
      />
    </Flex>
  );
};
