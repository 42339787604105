import { StyleProps, chakra } from '@chakra-ui/react';
import { Duration } from 'luxon';
import * as React from 'react';

const VideoLengthFromSeconds: React.FC<
  { durationInSeconds: number } & StyleProps
> = ({ durationInSeconds, ...rest }) => {
  if (durationInSeconds == null) {
    return null;
  }
  const duration = Duration.fromObject({ seconds: durationInSeconds });
  const durationStamp = duration.toISO();
  return (
    <chakra.time
      dateTime={durationStamp}
      color={'whiteAlpha.900'}
      textShadow={`0 0 4px #000`}
      textStyle={'caption'}
      pr={1}
      {...rest}
    >
      {duration.toFormat('m:ss')}
    </chakra.time>
  );
};

export default VideoLengthFromSeconds;
