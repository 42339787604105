import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  InputGroup,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControlHeaderStack } from '../../FormElements/FormControlHeaderStack/FormControlHeaderStack';
import {
  PromotingOptionList,
  PromotingOptionListProps,
} from '../../FormikFormElements/PromotingSelect';

export const EnumSelectField: React.FC<{
  name: string;
  field: any;
  label: React.ReactNode;
  placeholder?: string;
  labelTrailingHint?: React.ReactNode;
  footerInfoText?: React.ReactNode;
  labelProps?: FormLabelProps;
  text?: string;
  optionLabels: {
    groupLabel: string;
    unPromotedGroupLabel: string;
  };
}> = ({
  name,
  field,
  label,
  placeholder,
  labelTrailingHint,
  footerInfoText,
  labelProps,
  text,
  optionLabels: { groupLabel, unPromotedGroupLabel },
  ...rest
}) => {
  const {
    formState: { errors, touchedFields, isSubmitted },
  } = useFormContext();

  const isInvalid = !!errors[name] && (touchedFields[name] || isSubmitted);

  return !field ? null : (
    <FormControl isInvalid={isInvalid}>
      <FormControlHeaderStack>
        <HStack w={'full'}>
          <FormLabel children={label} flex={1} {...labelProps} />
          {labelTrailingHint}
        </HStack>
        {text && <FormHelperText>{text}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup size="md" width={'full'}>
        <PromotingSelectControl
          name={name}
          optionListProps={{
            placeholder,
            groupLabel,
            options: field.validValues,
            promotedValues: field.quickpickValues.map(
              (option: any) => option.value
            ),
            unPromotedGroupLabel,
          }}
          {...rest}
        />
      </InputGroup>
      {errors[name] && (
        <FormErrorMessage>{errors[name]?.message as string}</FormErrorMessage>
      )}
    </FormControl>
  );
};

type PromotingSelectControlProps = {
  name: string;
  selectProps?: SelectProps;
  optionListProps?: PromotingOptionListProps;
};

const PromotingSelectControl: React.FC<PromotingSelectControlProps> = (
  props
) => {
  const { name, optionListProps } = props;

  const { register } = useFormContext();

  return (
    <Select {...register(name)}>
      {optionListProps && <PromotingOptionList {...optionListProps} />}
    </Select>
  );
};
