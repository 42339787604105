import * as icon from '@campoint/odi-ui-icons';
import {
  Button,
  ButtonProps,
  Divider,
  Icon,
  IconButton,
  ModalContent,
  ModalContentProps,
  ModalOverlayProps,
  StackProps,
  VStack,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../Layout/ResponsiveModal';

export const MenuModalItem = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    role={'menuitem'}
    variant={'ghost'}
    color={'coldGray.900'}
    _hover={{
      bg: 'blackAlpha.50',
      _disabled: {
        bg: 'initial',
      },
    }}
    _active={{
      bg: 'blackAlpha.200',
    }}
    h={14}
    fontWeight={'normal'}
    ref={ref}
    {...props}
  />
));

export const MenuModalDownloadItem = forwardRef<
  ButtonProps & { downloadUrl: string },
  'a'
>(({ downloadUrl, ...props }, ref) => (
  <MenuModalItem
    as={props.isDisabled ? 'button' : 'a'}
    href={downloadUrl}
    rel={'noreferrer'}
    target={'_blank'}
    ref={ref}
    {...props}
  />
));

export const DestructiveMenuModalItem = forwardRef<ButtonProps, 'button'>(
  (props, ref) => <MenuModalItem color={'error.500'} ref={ref} {...props} />
);

export const MenuModal: React.FC<ResponsiveModalProps> = (props) => (
  // TODO refactor into new file
  <ResponsiveModal
    autoFocus={false}
    preferredSize="xs"
    preferredSizeBreakpoint="base"
    {...props}
  />
);

export const MenuModalOverlay = forwardRef<ModalOverlayProps, 'div'>(
  (props, ref) => <ResponsiveModalOverlay ref={ref} {...props} />
);

export const MenuModalContent = forwardRef<ModalContentProps, 'dialog'>(
  (props, ref) => <ModalContent ref={ref} {...props} />
);

export const MenuModalItemList: React.FC<StackProps> = (props) => (
  <VStack
    divider={<Divider />}
    alignItems={'stretch'}
    spacing={0}
    // Needed for proper border radius for focus outline
    __css={{
      '> *:first-of-type:not(:last-of-type)': { borderBottomRadius: 0 },
      '> *:not(:first-of-type):not(:last-of-type)': { borderRadius: 0 },
      '> *:not(:first-of-type):last-of-type': { borderTopRadius: 0 },
    }}
    {...props}
  />
);

export const FeedPostMenuExampleUsage: React.FC<{
  onEditPost?: () => void;
  onDeletePost?: () => void;
}> = ({ onEditPost, onDeletePost }) => {
  const { t } = useTranslation(['feed']);
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <IconButton
        variant={'ghost'}
        icon={<Icon as={icon.MoreHoriz} boxSize={'icon.md'} />}
        aria-label={t('feed:label.MehrOptionen')}
        onClick={onOpen}
      />
      <MenuModal isOpen={isOpen} onClose={onClose}>
        <MenuModalOverlay />
        <MenuModalContent>
          <MenuModalItemList>
            <MenuModalItem onClick={onEditPost}>
              {t('feed:menuItem.BeitragBearbeiten')}
            </MenuModalItem>
            <DestructiveMenuModalItem onClick={onDeletePost}>
              {t('feed:menuItem.BeitragLoschen')}
            </DestructiveMenuModalItem>
          </MenuModalItemList>
        </MenuModalContent>
      </MenuModal>
    </>
  );
};
