import { DMenuButton, Dropdown } from '@campoint/odi-ui';
import {
  AssignmentInd,
  Close,
  Home,
  Menu,
  PermContactCalendar,
  Savings,
  VxLive,
  VxModelsLogo,
  VxModelsName,
} from '@campoint/odi-ui-icons';
import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Container,
  ContainerProps,
  HTMLChakraProps,
  Icon,
  Spacer,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FeatureName, useFeature } from '../../flags';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { buildRedirectPageQueryParams } from '../../pages/RedirectPage/RedirectPage';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { externalRoutes, routes } from '../../routes/routesConfig';
import { NewsCenterPopper } from '../shared/NewsCenterPopper/NewsCenterPopper';
import { DesktopNavButtonGroup } from './DesktopNavButtonGroup';
import { DropdownMenuList } from './DropdownMenuList';
import { MobileBottomNav } from './MobileBottomNav';

export interface ContentPageLayoutProps {
  isContentLoading?: boolean;
  hideHeaderOnMobile?: boolean;
  children?: React.ReactNode;
}

/** Helper type to provide better typings for selecting the current item */
interface NavBarItem {
  icon: EmotionIcon;
  openInNewTab: boolean;
  url: string;
}
/** Helper function which infers keys and restricts values to NavBarItem */
const asNavBarItems = <T extends unknown>(navItem: {
  [K in keyof T]: NavBarItem;
}) => navItem;
const navBarItems = asNavBarItems({
  home: {
    icon: Home,
    openInNewTab: false,
    url: routes.home.path,
  },
  vxLive: {
    icon: VxLive,
    openInNewTab: false,
    url: routes.vxLive.path,
  },
  finance: {
    icon: Savings,
    openInNewTab: false,
    url: routes.finance.path,
  },
  profile: {
    icon: AssignmentInd,
    openInNewTab: false,
    url: routes.profileV2.path,
  },
});

type SafeAreaInsetSurroundOption =
  | 'x'
  | 'y'
  | 'l'
  | 'r'
  | 't'
  | 'b'
  | 'xb'
  | 'xt'
  | 'yl'
  | 'yr'
  | 'full'
  | 'none';

type NavItemInfo = {
  id: number;
  icon: EmotionIcon;
  route: string;
  active?: boolean;
};

const BrandLogo: React.FC<CenterProps> = (props) => (
  <Center role="banner" {...props}>
    <Icon as={VxModelsLogo} boxSize="icon.lg" color="vxModels.500" />
    <Icon as={VxModelsName} h="icon.lg" w="24" color="inherit" />
  </Center>
);

const PageHeader: React.FC<HTMLChakraProps<'header'>> = (props) => (
  <chakra.header
    layerStyle={'header'}
    pt={'calc(env(safe-area-inset-top, 0px))'}
    position="fixed"
    w={'full'}
    zIndex={12}
    {...props}
  />
);

const PageSafeAreaInset: React.FC<
  { surround: SafeAreaInsetSurroundOption } & BoxProps
> = ({ surround, ...rest }) => {
  const paddings = {
    pl: ['l', 'x', 'xb', 'xt', 'yl', 'full'].includes(surround)
      ? 'env(safe-area-inset-left, 0px)'
      : undefined,
    pr: ['r', 'x', 'xb', 'xt', 'yr', 'full'].includes(surround)
      ? 'env(safe-area-inset-right, 0px)'
      : undefined,
    pt: ['t', 'y', 'yl', 'yr', 'xt', 'full'].includes(surround)
      ? 'env(safe-area-inset-top, 0px)'
      : undefined,
    pb: ['b', 'y', 'yl', 'yr', 'xb', 'full'].includes(surround)
      ? 'env(safe-area-inset-bottom, 0px)'
      : undefined,
  };
  return <Box {...paddings} {...rest} />;
};

const PageContainer: React.FC<
  { surround?: SafeAreaInsetSurroundOption } & ContainerProps
> = ({ surround = 'x', ...rest }) => {
  return (
    <PageSafeAreaInset surround={surround}>
      <Container px={0} maxW={'container.xl'} {...rest} />
    </PageSafeAreaInset>
  );
};

export const FluentPageLayout: React.FC<ContentPageLayoutProps> = ({
  children,
  isContentLoading = false,
  hideHeaderOnMobile = false,
}) => {
  const feedFlag = useFeature(FeatureName.feed);
  const newscenterFlag = useFeature(FeatureName.newscenter);
  const history = useHistory();
  const historyWithStorage = useHistoryWithStorage();
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });
  const hideHeaderMobile = isMobileViewport && hideHeaderOnMobile;
  const isCurrentUrl = (url: string) => window.location.pathname?.includes(url);
  const brandLogoClick = () => historyWithStorage.push(routes.home.path);
  const { t, i18n } = useTranslation(['navigation', 'home', 'general']);

  const { isAllowedToPostOnFeed } = useAccountStatus();

  // can be deleted as soon as the feed feature flag is deleted
  navBarItems.profile.url =
    feedFlag === true ? routes.feed.path : routes.profileV2.path;
  const navItemInfo: NavItemInfo[] = [
    { id: 1, icon: Home, route: routes.home.path },
    { id: 2, icon: Savings, route: routes.finance.path },
    {
      id: 3,
      icon: PermContactCalendar,
      route: feedFlag === true ? routes.feed.path : routes.profileV2.path,
      // Is needed because this icon should show as active if the model profile or the feed is the active page
      active:
        isCurrentUrl(routes.feed.path) || isCurrentUrl(routes.profileV2.path),
    },
    {
      id: 4,
      icon: VxLive,
      route: routes.vxLive.path,
    },
  ];

  const routeToVxDesktop = useCallback(() => {
    history.push({
      pathname: routes.redirect.path,
      search: buildRedirectPageQueryParams(
        externalRoutes.main(i18n.language),
        routes.home.path
      ),
    });
  }, [history, i18n.language]);

  return (
    <>
      {/* This is the Top Navbar (both mobile- and desktop- viewport)*/}
      {!hideHeaderMobile && (
        <PageHeader>
          <Center as={PageContainer}>
            <BrandLogo pl={[2, 2, 2, 2, 0]} onClick={brandLogoClick} />
            <Spacer />
            {/* Extended Top Navbar on desktop viewport */}
            {!isMobileViewport && (
              <DesktopNavButtonGroup
                feedFlag={feedFlag}
                isAllowedToUseFeed={isAllowedToPostOnFeed}
                navBarItems={navBarItems}
                isCurrentUrl={isCurrentUrl}
              />
            )}
            {newscenterFlag && <NewsCenterPopper />}
            <Dropdown
              gutter={0}
              closeOnSelect={false}
              closeOnBlur={true}
              placement={'bottom-end'}
              aria-label={'menuSheet'}
            >
              {({ isOpen, onClose }) => {
                return (
                  <>
                    <DMenuButton
                      h={'58px'}
                      /*
                      https://github.com/chakra-ui/chakra-ui/issues/6173#issuecomment-1247459629
                      This approach is used to prevent the hover state to appear on touch devices.
                      E.g. when closing the fullscreen modal the touch event is propagated (for
                      unknown reasons) and triggers the hover state of the dropdown menu button.
                      */
                      sx={{
                        '@media(hover: none)': {
                          _hover: {
                            color: 'inherit',
                            backgroundColor: 'inherit',
                          },
                          _active: {
                            color: 'black',
                            backgroundColor: 'white',
                          },
                        },
                      }}
                    >
                      {isOpen ? (
                        <Icon as={Close} w={6} h={6} mr={2} />
                      ) : (
                        <Icon as={Menu} w={6} h={6} mr={2} />
                      )}
                      {t('navigation:dropdownMenu.menu')}
                    </DMenuButton>
                    <DropdownMenuList
                      routeToVxDesktop={routeToVxDesktop}
                      onClose={onClose}
                    />
                  </>
                );
              }}
            </Dropdown>
          </Center>
        </PageHeader>
      )}
      {/* This is the bottom navbar on mobile viewport */}
      {isMobileViewport && (
        <MobileBottomNav
          navItemInfo={navItemInfo}
          feedFlag={feedFlag}
          isAllowedToUseFeed={isAllowedToPostOnFeed}
        />
      )}
      {isContentLoading ? (
        <Container maxW="container.xl">
          <LoadingPage />
        </Container>
      ) : (
        <>
          {!hideHeaderMobile ? (
            <Box paddingTop={'58px'} paddingBottom={'50px'} px={0}>
              {children}
            </Box>
          ) : (
            <Box>{children}</Box>
          )}
        </>
      )}
    </>
  );
};
