import { FormikHelpers } from 'formik';
import * as React from 'react';

import { WizardContext } from '../pages/Wizard/utils/types';
import { gtag } from '../utils/gtag';
import { useFormikErrorSetter } from './useFormikErrorSetter';

type Props<K extends string> = {
  currentSelected: K;
  formiks: { [key in K]: FormikHelpers<any> & { values: any } };
  wizard: WizardContext;
};

export function useWizardOnContinueCallback<Key extends string>({
  currentSelected,
  formiks,
  wizard,
}: Props<Key>) {
  const { currentFieldError, wizardNextStepCallback } = wizard;
  const selectedFormik = React.useMemo(
    () => formiks[currentSelected],
    [currentSelected, formiks]
  );

  const setFormikErrors = useFormikErrorSetter(selectedFormik);

  React.useEffect(() => {
    if (Object.keys(currentFieldError).length > 0) {
      setFormikErrors(currentFieldError).then();
    }
  }, [currentFieldError, setFormikErrors]);

  const { currentStep } = wizard;

  const stepId = React.useMemo(
    () => `${currentStep?.id ?? '?'}`,
    [currentStep]
  );

  return React.useCallback(async () => {
    const errors = await selectedFormik.validateForm();

    if (Object.keys(errors).length > 0) {
      gtag('event', 'wizard-step-errors', {
        stepId,
        errors,
      });
      await setFormikErrors(errors);
    } else {
      wizardNextStepCallback(selectedFormik.values);
    }
  }, [selectedFormik, wizardNextStepCallback, stepId, setFormikErrors]);
}
