import React, { useEffect } from 'react';

import { useVxLiveLink } from '../../../provider/VxLiveLinkProvider';

/**
 * @deprecated Open vxlive.net in a new tab instead
 */
const VxLiveChatPage: React.FC = () => {
  const {
    isLoading,
    actions: { redirectToVxLive },
  } = useVxLiveLink();

  // just redirect if someone saved this site as a bookmark for example
  useEffect(() => {
    if (!isLoading) {
      redirectToVxLive('_self');
    }
  }, [isLoading, redirectToVxLive]);

  return null;
};

export default VxLiveChatPage;
