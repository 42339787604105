import React from 'react';

import { useIntersectionObserver } from './useIntersectionObserver';

export const useIsVisibleInViewport = (
  ref: React.MutableRefObject<HTMLElement | null>
) => {
  const [isVisibleInViewport, setIsVisibleInViewport] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(
        ([e]) => {
          setIsVisibleInViewport(e.isIntersecting);
        },
        {
          root: null, // <- intersect with viewport (is default)
          threshold: [1], // trigger when element is only partial visible in respect to margin
        }
      ),
    [setIsVisibleInViewport]
  );

  useIntersectionObserver(observer, ref);

  return isVisibleInViewport;
};
