import { VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { FeatureName, useFeature } from '../../../flags';
import { PostTypeEnum } from '../../../generated/feed';
import { useFeedPostEditModalContext } from '../../../provider/FeedPostEditModalProvider';
import {
  ReplaceImageHandlerProps,
  useFeedService,
} from '../../../provider/FeedService/FeedService';
import { getFormattedAmount } from '../../../utils/formattedAmount';
import { ClipPost } from '../components/post/ClipPost/ClipPost';
import { PhotoGalleryPost } from '../components/post/PhotoGalleryPost/PhotoGalleryPost';
import { PhotoPost } from '../components/post/PhotoPost/PhotoPost';
import { TextPost } from '../components/post/TextPost/TextPost';
import { PostFunctionProps } from '../posts';
import { getFeedPostTags } from '../utils';
import { useFlagsProviderV2 } from '../../../provider/FlagsProviderV2';

export const FeedScroller = () => {
  const {
    feedPosts,
    feedPostContainerId,
    postsEditing,
    action,
    setPostToCommentOnId,
  } = useFeedService();
  const { t } = useTranslation(['feed']);
  const locale = useIntl().locale;
  const feedPostEditCtx = useFeedPostEditModalContext();
  const { coinsEnabled } = useFlagsProviderV2();

  const postFunctions: PostFunctionProps = React.useMemo(
    () => ({
      onClickOnPin: (postId, isPinned) =>
        action.pinClickHandler(postId, isPinned),
      onClickOnEdit: () => { },
      onClickOnDelete: (postId) => action.deleteClickHandler(postId),
      onClickOnDeleteImage: (imageId: string, postId: string) =>
        action.deleteImageHandler(imageId, postId),
      onClickOnReplaceImage: (props: ReplaceImageHandlerProps) =>
        action.replaceImageHandler(props),
      onClickOnComments: (postId) => {
        setPostToCommentOnId(postId);
      },
    }),
    [action, setPostToCommentOnId]
  );

  const feedPaidContentFlag = useFeature(FeatureName.feedPaidContent);
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);

  return (
    <VStack
      id={feedPostContainerId}
      scrollMarginTop={'24'}
      spacing={{ base: '8' }}
    >
      {feedPosts.map((post) => {
        const priceToDisplay = feedPaidContentFlag ? coinsEnabled && post.priceCoins ?
          `${post.priceCoins} VXC` :
          post.price :
          null;

        const tags: string[] = getFeedPostTags(
          t,
          locale,
          post.fsk,
          priceToDisplay,
          feedTippingGoalFlag ? !!post?.tippingGoal : false,
          post.type === PostTypeEnum.CpPhotoContest
        );

        let formattedPrice = '';
        if (post.price || post.priceCoins) formattedPrice = post.priceCoins ?
          `${post.priceCoins} VXC` :
          getFormattedAmount(post.price, locale);

        const isPostEdited = Array.from(postsEditing).includes(post.id);

        switch (post.__typename) {
          case 'TextPost':
            return (
              <TextPost
                key={post.id}
                tags={tags}
                publicationScheduledFor={null}
                {...post}
                {...postFunctions}
                onClickOnEdit={() => {
                  feedPostEditCtx.action.openModal(post);
                }}
              />
            );
          case 'ClipPost':
            return (
              <ClipPost
                key={post.id}
                tags={tags}
                formattedPrice={formattedPrice}
                publicationScheduledFor={null}
                {...post}
                {...postFunctions}
                onClickOnEdit={() => {
                  feedPostEditCtx.action.openModal(post);
                }}
              />
            );
          case 'PhotosPost':
            if ((post?.photos?.length ?? 0) > 1) {
              return (
                <PhotoGalleryPost
                  key={post.id}
                  tags={tags}
                  formattedPrice={formattedPrice}
                  isEditing={isPostEdited}
                  {...post}
                  {...postFunctions}
                  onClickOnEdit={() => {
                    feedPostEditCtx.action.openModal(post);
                  }}
                />
              );
            }
            return (
              <PhotoPost
                key={post.id}
                tags={tags}
                formattedPrice={formattedPrice}
                publicationScheduledFor={null}
                isEditing={isPostEdited}
                {...post}
                {...postFunctions}
                onClickOnEdit={() => {
                  feedPostEditCtx.action.openModal(post);
                }}
              />
            );
          default:
            return null;
        }
      })}
    </VStack>
  );
};
