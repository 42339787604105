import { Duration as LuxonDuration } from 'luxon';
import { useIntl } from 'react-intl';

import Logger from '../utils/Logger';

enum StatisticTypes {
  Count = 'StatisticsEntrySingleNumber',
  Currency = 'StatisticsEntrySingleCurrency',
  Duration = 'StatisticsEntrySingleDuration',
  Percent = 'StatisticsEntrySinglePercent',
}

// Width of number char in px
const numberWidth = 12;

// Maximum available width in px
let maxWidth: number;

/**
 * Custom hook which formats statistic values
 */
const useFormat = () => {
  const lang = useIntl().locale;

  return {
    /**
     * Formats the number accordingly to its respective type.
     * @param value Initial raw statistic value
     * @param maxSpace The maximum available space in px
     * @param type The statistic type
     * @param currency String for the currency (e.g. 'EUR', 'JPY', etc.)
     */
    formatValue: (
      value: number | string,
      maxSpace: number,
      type: string,
      currency?: string
    ) => {
      try {
        value = Number(value);
        maxWidth = maxSpace;
        const shorten = isShorteningNecessary(value);

        switch (type) {
          case StatisticTypes.Count:
            return numberFormatting('decimal', value, lang, shorten);
          case StatisticTypes.Currency:
            return numberFormatting(
              'currency',
              value,
              lang,
              shorten,
              currency ?? 'EUR'
            );
          case StatisticTypes.Duration:
            return durationFormatting(value);
          case StatisticTypes.Percent:
            return numberFormatting('percent', value, lang, shorten);
          default:
            Logger.warn(`Statistic type '${type}' does not exist.`);
            return '0';
        }
      } catch (error) {
        Logger.error(error);
        return '0';
      }
    },
  };
};

export default useFormat;

/**
 * Checks if a value fits inside the available space.
 * @param currentValue The value, either the initial raw value or already formatted
 * @returns Boolean whether the value needs to be shortened
 */
const isShorteningNecessary = (currentValue: number | string): boolean => {
  const maxNumberOfChars = Math.floor(maxWidth / numberWidth);
  const currentNumberOfChars = currentValue.toString().length;
  return currentNumberOfChars > maxNumberOfChars;
};

/**
 * Formats the time in seconds.
 * @param seconds Duration in seconds
 * @returns String of the formatted time with units
 */
const durationFormatting = (seconds: number): string => {
  const d = LuxonDuration.fromObject({ seconds });
  const secondsInAnHour = 60 * 60;
  let formattedValue =
    seconds >= secondsInAnHour
      ? d.toFormat("h' h' mm' m'")
      : d.toFormat("mm' m' ss' s'");

  const isFormattedValueTooLong = isShorteningNecessary(formattedValue);

  if (isFormattedValueTooLong) {
    formattedValue = d.toFormat("h' h'");
  }

  return formattedValue;
};

/**
 * Formats the number accordingly to its respective type.
 * @param style Number formatting style
 * @param value Value to be formatted
 * @param lang User language
 * @param shorten Whether the value should be shortened
 * @param currency String for the currency (e.g. 'EUR', 'JPY', etc.)
 * @returns String of the formatted number with unit if needed
 */
const numberFormatting = (
  style: 'decimal' | 'percent' | 'currency',
  value: number,
  lang: string,
  shorten: boolean,
  currency?: string
): string => {
  const formatNumber = (compact: boolean) =>
    Intl.NumberFormat(lang, {
      style,
      notation: compact ? 'compact' : 'standard',
      currency,
      //from rollbar: maximumFractionDigits value is out of range
      //https://stackoverflow.com/questions/41045270/range-error-with-tolocalestring-with-maximumnumber-of-digits-0
      maximumFractionDigits: currency && !compact ? 2 : 0,
      minimumFractionDigits: 0,
    }).format(value);

  let formattedValue = formatNumber(shorten);

  if (!shorten) {
    const isFormattedValueTooLong = isShorteningNecessary(formattedValue);

    if (isFormattedValueTooLong) {
      formattedValue = formatNumber(true);
    }
  }

  return formattedValue;
};
