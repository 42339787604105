import {
  OdiCard,
  OdiCardDescription,
  OdiCardFooter,
  OdiCardHeader,
  OdiCardIcon,
  OdiCardProps,
  OdiCardTitle,
  OdiCardTop,
} from '@campoint/odi-ui';
import { Info } from '@campoint/odi-ui-icons';
import { Heading, Progress, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type InterviewProgressCardProps = {
  requiredCount: number;
  fulfilledCount: number;
} & OdiCardProps;
export const InterviewProgressCard: React.FC<InterviewProgressCardProps> = ({
  requiredCount,
  fulfilledCount,
  ...cardProps
}) => {
  const { t } = useTranslation(['modelProfile']);
  const missingCount = Math.max(requiredCount - fulfilledCount, 0);

  return (
    <OdiCard {...cardProps}>
      <OdiCardHeader>
        <OdiCardTop children={<OdiCardIcon as={Info} />} />
        <OdiCardTitle>
          {t('modelProfile:heading.BeantworteMindestensCountFragen', {
            count: requiredCount,
          })}
        </OdiCardTitle>
        <OdiCardDescription>
          {t(
            'modelProfile:text.DamitDeinProfilAlsVollstandigGiltMusstDuNochCountFragenBeantworten',
            {
              count: missingCount,
            }
          )}
        </OdiCardDescription>
      </OdiCardHeader>

      <OdiCardFooter as={VStack}>
        <Heading size="md">{`${fulfilledCount}/${requiredCount}`}</Heading>
        <Progress
          value={fulfilledCount}
          min={0}
          max={requiredCount}
          size={'sm'}
          w={'full'}
        />
      </OdiCardFooter>
    </OdiCard>
  );
};
