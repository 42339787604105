import { ApolloError } from '@apollo/client';
import { Info } from '@campoint/odi-ui-icons';
import {
  Button,
  Heading,
  Icon,
  Image,
  UseDisclosureProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../components/Layout/ResponsiveModal';
import Markdown from '../components/shared/Markdown/Markdown';
import { getGtagEventParamsFromNotification } from '../components/shared/NewsCenterPopper/NewsCenterPopper';
import {
  GetAllNotificationsDocument,
  GetImportantNotificationsDocument,
  GetNewsNotificationsDocument,
  GetNotificationsCountDocument,
  NotificationFragment,
  NotificationsFilterPresetEnum,
  useGetAllNotificationsQuery,
  useGetImportantNotificationsQuery,
  useGetNewsNotificationsQuery,
  useGetNotificationsCountQuery,
  useMarkReadMutation,
} from '../generated/notifications';
import { useActiveApiLanguage } from '../hooks/useActiveApiLanguage';
import { createContext } from '../hooks/useContext';
import Logger from '../utils/Logger';
import { gtag } from '../utils/gtag';
import { useAuth } from './AuthProvider';

export type NewsCenterNotification = {
  cursor: string;
  node: NotificationFragment;
};

export type NewsCenterNotificationNode = {
  [node: string]: NotificationFragment;
};

export type NewsCenterContext = {
  selectedNotification: NotificationFragment | null;
  setSelectedNotification: React.Dispatch<
    React.SetStateAction<NotificationFragment | null>
  >;
  hasUnreadNotifications: boolean;
  hasImportantUnreadNotifications: boolean;
  hasUnreadNewsNotifications: boolean;
  allCountDataRefetch: any;
  importantCountDataRefetch: any;
  newsCountDataRefetch: any;
  importantNotificationEdges: NewsCenterNotification[];
  importantLoading: boolean;
  importantFetchMore: any;
  importantHasNextPage: boolean;
  importantError: ApolloError | undefined;
  allNotificationEdges: NewsCenterNotification[];
  allLoading: boolean;
  allFetchMore: any;
  allHasNextPage: boolean;
  allError: ApolloError | undefined;
  newsNotificationEdges: NewsCenterNotification[];
  newsLoading: boolean;
  newsFetchMore: any;
  newsHasNextPage: boolean;
  newsError: ApolloError | undefined;
  listTabIndex: number;
  setListTabIndex: React.Dispatch<React.SetStateAction<number>>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  onOpenAnncouncement: () => void;
};

export const [, useNewsCenter, newsCenterContext] =
  createContext<NewsCenterContext>({
    name: 'NewsCenterContext',
    strict: true,
    errorMessage:
      'useNewsCenter: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });
export const NewsCenterProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const {
    isOpen: isAnnounceMentOpen,
    onOpen: onOpenAnnouncement,
    onClose: onCloseAnnouncement,
  } = useDisclosure();
  const lang = useActiveApiLanguage();
  const { isAuthenticated } = useAuth();
  const [isInitialDelayDone, setIsInitialDelayDone] = React.useState(false);

  // To mitigate showing notifications of previously logged in users:
  // initialy prevent queries to run until a delay is through
  // todo: Investigate route cause of cancelled requests and uncleared caches
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsInitialDelayDone(true);
    }, 250);
    return () => clearTimeout(timeoutId);
  }, [setIsInitialDelayDone]);

  const skip = !isAuthenticated || !isInitialDelayDone;

  const [selectedNotification, setSelectedNotification] =
    React.useState<NotificationFragment | null>(null);
  const [listTabIndex, setListTabIndex] = React.useState<number>(0);
  const {
    data: dataImportant,
    loading: importantLoading,
    fetchMore: importantFetchMore,
    error: importantError,
  } = useGetImportantNotificationsQuery({
    variables: { lang: lang, count: 8, cursor: '' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: false,
    skip,
    onError: (error) => {
      Logger.error(error);
    },
  });
  const { importantNotificationEdges, importantHasNextPage } =
    React.useMemo(() => {
      return {
        importantNotificationEdges: dataImportant?.notifications?.pagination
          ?.edges as NewsCenterNotification[],
        importantHasNextPage:
          dataImportant?.notifications?.pagination?.pageInfo.hasNextPage ??
          false,
      };
    }, [dataImportant]);

  const {
    data: dataAll,
    loading: allLoading,
    fetchMore: allFetchMore,
    error: allError,
  } = useGetAllNotificationsQuery({
    variables: { lang: lang, count: 8, cursor: '' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: false,
    skip,
    onError: (error) => {
      Logger.error(error);
    },
  });
  const { allNotificationEdges, allHasNextPage } = React.useMemo(() => {
    return {
      allNotificationEdges: dataAll?.notifications?.pagination
        ?.edges as NewsCenterNotification[],
      allHasNextPage:
        dataAll?.notifications?.pagination?.pageInfo.hasNextPage ?? false,
    };
  }, [dataAll]);

  const {
    data: dataNews,
    loading: newsLoading,
    fetchMore: newsFetchMore,
    error: newsError,
  } = useGetNewsNotificationsQuery({
    variables: { lang: lang, count: 8, cursor: '' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip,
    onError: (error) => {
      Logger.error(error);
    },
    pollInterval: 300_000, //30 minutes
    onCompleted: () => {
      const unreadNews = newsNotificationEdges?.find(
        (edge) => edge.node.readAt === null
      );

      if (unreadNews && !isAnnounceMentOpen) {
        setSelectedNotification(unreadNews.node);
        onOpenAnnouncement();
      }
    },
  });
  const { newsNotificationEdges, newsHasNextPage } = React.useMemo(() => {
    return {
      newsNotificationEdges: dataNews?.notifications?.pagination
        ?.edges as NewsCenterNotification[],
      newsHasNextPage:
        dataNews?.notifications?.pagination?.pageInfo.hasNextPage ?? false,
    };
  }, [
    dataNews?.notifications?.pagination?.edges,
    dataNews?.notifications?.pagination?.pageInfo.hasNextPage,
  ]);

  const { data: newsCountData, refetch: newsCountDataRefetch } =
    useGetNotificationsCountQuery({
      variables: { preset: NotificationsFilterPresetEnum.NewsCenterUnread },
      skip,
      onError: (error) => {
        Logger.error(error);
      },
    });
  const hasUnreadNewsNotifications = React.useMemo(() => {
    return (newsCountData?.notifications?.pagination?.totalCount ?? 0) > 0;
  }, [newsCountData?.notifications?.pagination?.totalCount]);

  const { data: importantCountData, refetch: importantCountDataRefetch } =
    useGetNotificationsCountQuery({
      variables: { preset: NotificationsFilterPresetEnum.ImportantUnread },
      skip,
      onError: (error) => {
        Logger.error(error);
      },
    });
  const hasImportantUnreadNotifications = React.useMemo(() => {
    return (importantCountData?.notifications?.pagination?.totalCount ?? 0) > 0;
  }, [importantCountData]);

  const { data: allCountData, refetch: allCountDataRefetch } =
    useGetNotificationsCountQuery({
      variables: { preset: NotificationsFilterPresetEnum.AllUnread },
      skip,
      onError: (error) => {
        Logger.error(error);
      },
    });
  const hasUnreadNotifications = React.useMemo(() => {
    return (allCountData?.notifications?.pagination?.totalCount ?? 0) > 0;
  }, [allCountData]);

  const context = React.useMemo<NewsCenterContext>(
    () => ({
      isOpen,
      selectedNotification,
      setSelectedNotification,
      listTabIndex,
      setListTabIndex,
      importantNotificationEdges,
      importantLoading,
      importantFetchMore,
      importantHasNextPage,
      importantError,
      allNotificationEdges,
      allFetchMore,
      allLoading,
      allHasNextPage,
      allError,
      newsNotificationEdges,
      newsFetchMore,
      newsLoading,
      newsHasNextPage,
      newsError,
      hasUnreadNotifications,
      allCountDataRefetch,
      hasImportantUnreadNotifications,
      importantCountDataRefetch,
      hasUnreadNewsNotifications,
      newsCountDataRefetch,
      onOpen,
      onClose,
      onToggle,
      onOpenAnncouncement: onOpenAnnouncement,
    }),
    [
      isOpen,
      selectedNotification,
      listTabIndex,
      importantNotificationEdges,
      importantLoading,
      importantFetchMore,
      importantHasNextPage,
      importantError,
      allNotificationEdges,
      allFetchMore,
      allLoading,
      allHasNextPage,
      allError,
      newsNotificationEdges,
      newsFetchMore,
      newsLoading,
      newsHasNextPage,
      newsError,
      hasUnreadNotifications,
      allCountDataRefetch,
      hasImportantUnreadNotifications,
      importantCountDataRefetch,
      hasUnreadNewsNotifications,
      newsCountDataRefetch,
      onOpen,
      onClose,
      onToggle,
      onOpenAnnouncement,
    ]
  );

  return (
    <newsCenterContext.Provider value={context}>
      {children}
      <NewsCenterAnnouncementModal
        isOpen={isAnnounceMentOpen}
        onClose={() => {
          onCloseAnnouncement();
          setSelectedNotification(null);
        }}
        selectedNotification={selectedNotification}
      />
    </newsCenterContext.Provider>
  );
};

const NewsCenterAnnouncementModal = ({
  isOpen,
  onClose,
  selectedNotification,
}: {
  selectedNotification: NotificationFragment | null;
} & UseDisclosureProps) => {
  const { t } = useTranslation('newsCenter');

  const [markReadMutation] = useMarkReadMutation({
    variables: {
      id: selectedNotification?.id ?? '',
    },
    // Cause queries to refetch after marking notifiaction as read
    // todo: might be refactored to be encapsulated in the NewsCenterProvider
    refetchQueries: [
      GetNotificationsCountDocument,
      GetImportantNotificationsDocument,
      GetAllNotificationsDocument,
      GetNewsNotificationsDocument,
    ],
    onError: (error) => Logger.error(error),
    onCompleted: () => {
      if (selectedNotification) {
        gtag(
          'event',
          'Notification_MarkAsRead',
          getGtagEventParamsFromNotification(selectedNotification)
        );
      }
    },
  });

  return (
    <ResponsiveModal
      isOpen={isOpen ?? false}
      onClose={() => {
        onClose?.();
      }}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent maxW={'567px'} bgColor={'surface'}>
        <ResponsiveModalStickyHeaderBox bg={'surface'}>
          <Heading
            as="h1"
            children={selectedNotification?.shortInfo}
            size={'sm'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'transparent'}>
          {selectedNotification?.picture && (
            <Image
              w={'full'}
              h={'140px'}
              flexShrink={0}
              alignSelf={'start'}
              fit={'cover'}
              src={selectedNotification?.picture?.src}
              srcSet={selectedNotification?.picture?.srcset}
              sizes={'500px'}
              alt={selectedNotification?.picture?.alt ?? ''}
            />
          )}
          <VStack p={5} textAlign={'center'} flexGrow={1}>
            <Icon as={Info} boxSize={6} />
            <Heading size={'lg'}>{selectedNotification?.title}</Heading>
            {selectedNotification?.subtitle && (
              <Markdown
                markdown={selectedNotification?.subtitle}
                containerProps={{
                  css: {
                    flexGrow: 1,
                  },
                }}
              />
            )}
          </VStack>
        </ResponsiveModalBodyBox>
        <ResponsiveModalStickyFooterBox>
          <Button
            variant={'solid'}
            onClick={() => {
              markReadMutation();
              onClose?.();
            }}
          >
            {t('button.AlsGelesenMarkieren')}
          </Button>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
