// useDeleteMedia.ts
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { useVideoLibraryDeleteMediaMutation } from '../../generated/graphql';
import Logger from '../../utils/Logger';

type MediaType = 'video' | 'photo';

type DeleteMediaProps = {
  onSuccess: (albumId: number) => void;
  mediaType: MediaType;
};

export const useDeleteMedia = ({ onSuccess, mediaType }: DeleteMediaProps) => {
  const { t } = useTranslation(['videoLibrary', 'general']);
  const [deleteMediaMutation] = useVideoLibraryDeleteMediaMutation();

  const toastMessageByMediaType = {
    video: {
      success: t('videoLibrary:toast.VideoErfolgreichGeloscht'),
      error: t('videoLibrary:toast.LoschenFehlgeschlagen'),
      connectionError: t('general:toast.DatenKonntenNichtGespeichertWerden'),
    },
    photo: {
      success: t('videoLibrary:toast.FotoErfolgreichGeloscht'),
      error: t('videoLibrary:toast.LoschenFehlgeschlagen'),
      connectionError: t('general:toast.DatenKonntenNichtGespeichertWerden'),
    },
  };

  const onDelete = useCallback(
    async (mediaId: number, hideToast: boolean = false) => {
      try {
        const response = await deleteMediaMutation({
          variables: {
            albumId: mediaId,
          },
        });
        if (response.data?.media?.deleteMedia) {
          if (!hideToast) {
            issueChakraToast({
              status: 'success',
              description: toastMessageByMediaType[mediaType].success,
            });
          }
          onSuccess(mediaId);
        } else {
          if (!hideToast) {
            issueChakraToast({
              status: 'error',
              description: toastMessageByMediaType[mediaType].error,
            });
          }
        }
      } catch (e) {
        Logger.error(e);
        issueChakraToast({
          description: toastMessageByMediaType[mediaType].connectionError,
          status: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteMediaMutation, mediaType, onSuccess]
  );

  return onDelete;
};
