import { Box, chakra } from '@chakra-ui/react';

import { ImageStatus } from '../../../types/ImageStatus';
import { AnimatedVXLogo } from '../AnimatedVXLogo/AnimatedVXLogo';

type Props = {
  status?: ImageStatus;
  label?: React.ReactNode;
};

export type ImageStatusIconProps = Props;

export const ImageStatusIcon: React.FC<{
  status?: ImageStatus;
  label?: React.ReactNode | null;
}> = ({ status, label = null }) => {
  return (
    <Box
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
      pointerEvents={'none'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {status && <ImageStatusSymbol status={status} />}
      {label && <chakra.label>{label}</chakra.label>}
    </Box>
  );
};

export const ImageStatusSymbol: React.FC<{
  status: ImageStatus;
}> = ({ status }) => {
  switch (status) {
    case 'loading':
      return (
        <chakra.span display={'inline-block'} boxSize={6}>
          <AnimatedVXLogo />
        </chakra.span>
      );
    case 'reviewing':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <rect fill="#FF933C" width="36" height="36" rx="18" />
            <path
              d="M17.99 8C12.47 8 8 12.48 8 18s4.47 10 9.99 10C23.52 28 28 23.52 28 18S23.52 8 17.99 8ZM18 26c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Zm-.22-13h-.06c-.4 0-.72.32-.72.72v4.72c0 .35.18.68.49.86l4.15 2.49c.34.2.78.1.98-.24a.71.71 0 0 0-.25-.99l-3.87-2.3v-4.54c0-.4-.32-.72-.72-.72Z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    case 'rejected':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path fill="#EB0000" d="M0 10 10 0h16l10 10v16L26 36H10L0 26z" />
            <path
              d="M18 19a2.507 2.507 0 0 1-2.5-2.5v-5c0-1.375 1.125-2.5 2.5-2.5s2.5 1.125 2.5 2.5v5c0 1.375-1.125 2.5-2.5 2.5Zm0 7.004a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
              fill="#FFF"
            />
          </g>
        </svg>
      );
    case 'accepted':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 36 36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="m35.62 16.687-2.46-2.825a2 2 0 0 1-.482-1.496l.342-3.732a2 2 0 0 0-1.547-2.132l-3.609-.824a2 2 0 0 1-1.279-.937l-1.891-3.218A2 2 0 0 0 22.177.7l-3.384 1.46a2 2 0 0 1-1.586 0L13.821.7a2 2 0 0 0-2.516.82L9.417 4.723a2 2 0 0 1-1.283.936l-3.602.812A2 2 0 0 0 2.98 8.604l.343 3.748a2 2 0 0 1-.48 1.492L.376 16.688a2 2 0 0 0 .002 2.622l2.46 2.829a2 2 0 0 1 .483 1.494l-.342 3.75a2 2 0 0 0 1.546 2.132l3.611.825a2 2 0 0 1 1.278.934l1.888 3.202a2 2 0 0 0 2.52.82l3.383-1.47a2 2 0 0 1 1.59-.003l3.383 1.46a2 2 0 0 0 2.516-.82l1.89-3.206a2 2 0 0 1 1.278-.935l3.61-.824a2 2 0 0 0 1.547-2.132l-.342-3.732a2 2 0 0 1 .483-1.496l2.458-2.825a2 2 0 0 0 0-2.626Z"
              fill="#8CD062"
              fillRule="nonzero"
            />
            <path
              d="m13.557 25.08-4.036-4.242a1.812 1.812 0 0 1 0-2.491l.119-.124a1.661 1.661 0 0 1 2.408 0l2.73 2.863 8.735-9.119a1.661 1.661 0 0 1 2.409 0l.118.124c.662.689.662 1.802 0 2.491L16 25.08c-.696.689-1.764.689-2.443 0Z"
              fill="#FFF"
            />
          </g>
        </svg>
      );
    default:
      return null;
  }
};
