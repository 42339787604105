import { DateTime } from 'luxon';
import * as React from 'react';

export type Today = {
  todayDayStamp: string;
};

export const initTodayValues: Today = {
  todayDayStamp: DateTime.now().toISODate(),
};

export const todayContext = React.createContext<Today>(initTodayValues);

export const TodayProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [todayDayStamp, setTodayDayStamp] = React.useState<string>(
    DateTime.now().toISODate()
  );

  React.useEffect(() => {
    const timer = setInterval(() => {
      const newTodayDayStamp = DateTime.now().toISODate();
      if (newTodayDayStamp !== todayDayStamp) {
        setTodayDayStamp(newTodayDayStamp);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [todayDayStamp, setTodayDayStamp]);

  return (
    <todayContext.Provider
      value={{
        todayDayStamp,
      }}
    >
      {children}
    </todayContext.Provider>
  );
};

export const TodayMockProvider: React.FC<{
  dayStamp: string;
  children?: React.ReactNode;
}> = ({ dayStamp, children }) => {
  return (
    <todayContext.Provider
      value={{
        todayDayStamp: dayStamp,
      }}
    >
      {children}
    </todayContext.Provider>
  );
};

export function useToday() {
  return React.useContext(todayContext);
}
