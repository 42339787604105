import { DateTime } from 'luxon';
import * as React from 'react';

import { useToday } from '../provider/TodayProvider';

export const useTimeframe = () => {
  const { todayDayStamp } = useToday();
  return React.useMemo(() => {
    const today = DateTime.fromISO(todayDayStamp);

    const firstDayOfWeek = today.startOf('week');
    const firstDayOfPreviousWeek = firstDayOfWeek.minus({ week: 1 });
    const firstDayOfNextWeek = firstDayOfWeek.plus({ week: 1 });

    const firstDayOfMonth = today.startOf('month');
    const firstDayOfPreviousMonth = firstDayOfMonth.minus({ month: 1 });
    const firstDayOfNextMonth = firstDayOfMonth.plus({ month: 1 });

    const firstDayOfQuarter = today.startOf('quarter');
    const firstDayOfPreviousQuarter = firstDayOfQuarter.minus({ quarter: 1 });
    const firstDayOfNextQuarter = firstDayOfQuarter.plus({ quarter: 1 });

    const firstDayOfYear = today.startOf('year');
    const firstDayOfPreviousYear = firstDayOfYear.minus({ year: 1 });
    const firstDayOfNextYear = firstDayOfYear.plus({ year: 1 });

    return {
      today,

      firstDayOfPreviousWeek,
      firstDayOfWeek,
      firstDayOfNextWeek,

      firstDayOfMonth,
      firstDayOfPreviousMonth,
      firstDayOfNextMonth,

      firstDayOfQuarter,
      firstDayOfPreviousQuarter,
      firstDayOfNextQuarter,

      firstDayOfYear,
      firstDayOfPreviousYear,
      firstDayOfNextYear,
    };
  }, [todayDayStamp]);
};
