import ENV from '../environments/environment';
import { rollbarInstance } from '../provider/RollbarProvider';

export let enableLogs = false;

/**
 * Logger that logs only in development builds
 */
class Logger {
  /**
   * Set if logs are enabled
   * @param enable Whether logs are now enabled or disabled
   */
  static setEnableLogs(enable: boolean) {
    enableLogs = enable;
  }

  /**
   * If it's a production build a console.log is logged
   * @param log Log that should be logged
   */
  static log(...log: any[]) {
    if (!ENV.PRODUCTION) {
      /* tslint:disable */
      console.log(...log);
      /* tslint:enable */
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.log(...log);
    }
  }

  /**
   * If it's a production build a console.error is logged
   * @param error Error that should be logged
   */
  static error(...errors: any[]) {
    // Check if it's production environment once
    const isProduction = ENV.PRODUCTION;

    if (!isProduction) {
      // Log errors to console in non-production environments
      console.error(...errors);
    } else if (rollbarInstance) {
      /* 
        Case: "Item sent with null or missing arguments." 
        Solution found on: https://github.com/rollbar/rollbar.js/issues/975
      */
      errors.forEach((error) => {
        if (error instanceof Error) {
          rollbarInstance.error(error);
        } else if (typeof error === 'string') {
          rollbarInstance.error(new Error(error));
        } else {
          rollbarInstance.error('Error', { extra: error });
        }
      });
    }
  }

  /**
   * If it's a production build a console.warn is logged
   * @param warn Warning that should be logged
   */
  static warn(...warn: any[]) {
    if (!ENV.PRODUCTION) {
      /* tslint:disable */
      console.warn(...warn);
      /* tslint:enable */
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.warning(...warn);
    }
  }

  /**
   * If it's a production build display an alert
   * @param message Message that should be displayed
   */
  static alert(message?: any) {
    if (!ENV.PRODUCTION || enableLogs) {
      alert(message);
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.debug(...message);
    }
  }

  /**
   * If it's a production build return the console.table function
   * @returns {any} console.table function if it's not a production build
   */
  static table(data: any) {
    if (!ENV.PRODUCTION || enableLogs) {
      console.table(data);
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.debug(...data);
    }
  }

  /**
   * If it's a production build return the console.group function
   * @returns {any} console.group function if it's not a production build
   */
  static group(title: string) {
    if (!ENV.PRODUCTION || enableLogs) {
      console.group(title);
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.debug(title);
    }
  }

  /**
   * If it's a production build return the console.groupCollapsed function
   * @returns {any} console.groupCollapsed function if it's not a production build
   */
  static groupCollapsed(title: string) {
    if (!ENV.PRODUCTION || enableLogs) {
      console.groupCollapsed(title);
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.debug(title);
    }
  }

  /**
   * If it's a production build return the console.groupEnd function
   * @returns {any} console.groupEnd function if it's not a production build
   */
  static groupEnd() {
    if (!ENV.PRODUCTION || enableLogs) {
      console.groupEnd();
    }
    if (rollbarInstance && ENV.PRODUCTION) {
      rollbarInstance.debug('End of group');
    }
  }
}

export default Logger;
