import { Button, Heading, Img, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import goOnlineImage from '../../../../../assets/images/shared/go-online-chatting.svg';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../../../../../components/shared/cards/UniversalCard/UniversalCard';
import { useVxLiveLink } from '../../../../../provider/VxLiveLinkProvider';

const FinanceGoOnlineCard: React.FC = () => {
  const { t } = useTranslation('financePage');
  const {
    actions: { redirectToVxLive },
  } = useVxLiveLink();

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={goOnlineImage}
              alt={t('img.ModelChattetMitKundenAmSmartphone')}
              p={0}
              maxH={'123px'}
            />
            <VStack mt="4" spacing="4">
              <Heading
                size={{ base: 'lg', lg: 'xl' }}
                p={0}
                textAlign={'center'}
              >
                {t('headline.VerdieneDeinErstesGeld')}
              </Heading>
            </VStack>
          </>
        }
        cardFooter={
          <Button
            variant={'solid'}
            children={t('button.OnlineGehen')}
            onClick={() => redirectToVxLive()}
          />
        }
      />
    </UniversalCardSectionWithBorder>
  );
};
export default FinanceGoOnlineCard;
