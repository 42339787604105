import { Button, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
} from '../../Layout/ResponsiveModal';
import { StepDotGroup } from '../StepDotGroup/StepDotGroup';

export type USPModalSteps = {
  image: { src: string; alt?: string };
  heading: string;
  text: string;
};

export const USPModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  closeButtonText: string;
  continueButtonText: string;
  steps: USPModalSteps[];
}> = ({ isOpen, onClose, closeButtonText, continueButtonText, steps }) => {
  const { t } = useTranslation(['general', 'feed', 'usp']);
  const [currentUspStep, setCurrentUspStep] = useState(1);
  const [sliderDirection, setSliderDirection] = useState(0);

  const variants = {
    initial: (direction: number) => {
      return { x: 200 * direction, opacity: 0 };
    },
    animate: { x: 0, opacity: 1 },
    exit: (direction: number) => {
      return { x: 200 * direction, opacity: 0 };
    },
  };

  const isLastStep = currentUspStep === steps.length;

  const getCurrentStepContent = (step: number) => steps[step - 1];

  const { image, heading, text } = getCurrentStepContent(currentUspStep);

  const closeModal = () => {
    setCurrentUspStep(1);
    onClose();
  };

  const nextStep = () => {
    if (currentUspStep < steps.length) {
      setSliderDirection(1);
      setCurrentUspStep((step) => step + 1);
    }
  };

  const prevStep = () => {
    if (currentUspStep === 1) {
      return;
    } else {
      setSliderDirection(-1);
      setCurrentUspStep((step) => step - 1);
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={closeModal}>
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalCloseButton />
        <ResponsiveModalBodyBox
          display={'flex'}
          justifyContent={'center'}
          px={'4'}
          py={'8'}
        >
          <VStack spacing={'8'}>
            <AnimatePresence initial={false} custom={sliderDirection}>
              <HStack>
                <motion.div
                  key={currentUspStep}
                  custom={sliderDirection}
                  variants={variants}
                  animate={'animate'}
                  initial={'initial'}
                  exit={'exit'}
                  transition={{ duration: 0.5, default: { ease: 'easeInOut' } }}
                  drag="x"
                  dragConstraints={{ right: 0, left: 0 }}
                  dragElastic={0.1}
                  onDragEnd={(ev, info) => {
                    if (info.offset.x < -100) {
                      nextStep();
                    }
                    if (info.offset.x > 100) {
                      prevStep();
                    }
                    return;
                  }}
                >
                  <VStack color={'coldGray.900'} spacing={4}>
                    <Image w={'286px'} h={'172px'} src={image.src} />
                    <Heading
                      size={{ base: 'sm', lg: 'xl' }}
                      p={0}
                      textAlign={'center'}
                      children={heading}
                    />
                    <Text
                      size={{ base: 'sm', lg: 'md' }}
                      textAlign={'center'}
                      color={'gray.500'}
                      p={0}
                      children={text}
                    />
                  </VStack>
                </motion.div>
              </HStack>
            </AnimatePresence>
            {!isLastStep && (
              <StepDotGroup
                currentStep={currentUspStep}
                totalSteps={steps.length}
              />
            )}
            <VStack spacing={4}>
              {isLastStep ? (
                <Button
                  w={'64'}
                  maxW={'full'}
                  variant={'solid'}
                  shadow={'none'}
                  children={closeButtonText}
                  onClick={closeModal}
                />
              ) : (
                <Button
                  w={'64'}
                  maxW={'full'}
                  variant={'solid'}
                  shadow={'none'}
                  children={continueButtonText}
                  onClick={nextStep}
                />
              )}

              {!isLastStep && (
                <Button
                  w={'64'}
                  variant="daemon"
                  width={'full'}
                  shadow={'none'}
                  children={t('usp:button.Uberspringen')}
                  onClick={closeModal}
                />
              )}
            </VStack>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
