import * as icons from '@campoint/odi-ui-icons';
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { HSnapScrollContainer } from '../../../../components/Layout/HSnapScrollContainer';
import { StepDotGroup } from '../../../../components/shared/StepDotGroup/StepDotGroup';
import {
  ContestFragment,
  MediaContestTypeEnum,
} from '../../../../generated/graphql';
import { useContest } from '../../../../provider/ContestProvider';
import { ContestTileBase } from './ContestTile/ContestTileBase';
import { ContestTileDescription } from './ContestTile/ContestTileDescription';
import { ContestTileEndedParticipated } from './ContestTile/ContestTileEndedParticipated';
import { ContestTileEndedParticipatedTips } from './ContestTile/ContestTileEndedParticipatedTips';
import { ContestTileEndedWon } from './ContestTile/ContestTileEndedWon';
import { ContestTilePrizes } from './ContestTile/ContestTilePrizes';
import { ContestTileTips } from './ContestTile/ContestTileTips';
import { ContestTileUploadAddPhotos } from './ContestTile/ContestTileUploadAddPhotos';
import { ContestTileUploadDropZone } from './ContestTile/ContestTileUploadDropZone';
import { ContestTileUploadStarted } from './ContestTile/ContestTileUploadStarted';
import {
  ContestTileUploadPending,
  ContestTileUploadRejected,
} from './ContestTile/ContestTileUploadStatus';
import { ContestTileUploadVoteStartsIn } from './ContestTile/ContestTileUploadVoteStartsIn';
import { ContestTileVoting } from './ContestTile/ContestTileVoting';
import {
  CONTEST_TILE_WIDTH,
  ContestPhaseEnum,
  contestHasSubmitted,
  getContestPhase,
  goToMedia,
  shouldShowContestApproved,
  shouldShowContestPending,
  shouldShowContestRejected,
} from './ContestUtils';

let scrollLock = false;
export const ContestSectionMobile: React.FC<{
  contest: ContestFragment;
}> = ({ contest }) => {
  const [tileIndex, setTileIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const history = useHistory();
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!scrollLock) {
      setTileIndex(Math.round(e.currentTarget.scrollLeft / CONTEST_TILE_WIDTH));
    }
  };
  const setTileIndexAndScroll = (index: number) => {
    if (ref && ref.current && !scrollLock) {
      scrollLock = true;
      ref.current.scrollTo({
        left: index * CONTEST_TILE_WIDTH,
        behavior: 'smooth',
      });
      setTimeout(() => {
        setTileIndex(index);
        scrollLock = false;
      }, 300);
    }
  };

  useEffect(() => {
    if (shouldShowContestRejected(contest)) {
      setTileIndexAndScroll(4);
    }
  }, [contest]);
  useEffect(() => {
    const photoRejected = contest.photoalbum?.pictureCountRejected ?? 0;
    if (photoRejected > 0) {
      setTileIndexAndScroll(1);
    }
  }, [contest.photoalbum?.pictureCountRejected]);

  const { initialPhotoContestId, initialVideoContestId } = useContest();

  // mobile: positionInStack={(x - tileIndex)}
  const buildTileSet = <T extends typeof ContestTileBase | null>(
    tileType: T[]
  ) => {
    return tileType
      .filter((tile) => !!tile)
      .map((tile, index, filteredArray) => {
        return React.createElement(tile!, {
          key: index,
          contest,
          positionInStack: index - tileIndex,
          goToMedia: () => goToMedia(contest, location, history),
          onClick: () => setTileIndexAndScroll(index),
          isMobile: true,
        });
      });
  };

  // determine tiles to show
  let tiles: React.ReactElement[] | null = null;
  const hasSubmitted = contestHasSubmitted(contest);
  switch (getContestPhase(contest)) {
    case ContestPhaseEnum.Upload:
      let status = null;
      if (shouldShowContestPending(contest)) {
        status = ContestTileUploadPending;
      } else if (shouldShowContestRejected(contest)) {
        status = ContestTileUploadRejected;
      } else if (!shouldShowContestApproved(contest)) {
        if (!hasSubmitted) {
          if (contest.type === MediaContestTypeEnum.Video) {
            status = ContestTileUploadDropZone;
          } else {
            status = ContestTileUploadAddPhotos;
          }
        }
      }
      if (hasSubmitted) {
        tiles = buildTileSet([
          ContestTileUploadVoteStartsIn,
          contest.type === MediaContestTypeEnum.Photo
            ? ContestTileUploadAddPhotos
            : null,
          ContestTileDescription,
          ContestTilePrizes,
          status,
        ]);
      } else {
        tiles = buildTileSet([
          ContestTileUploadStarted,
          ContestTileDescription,
          ContestTilePrizes,
          ContestTileTips,
          status,
        ]);
      }
      break;
    case ContestPhaseEnum.Vote:
      if (hasSubmitted) {
        let additionalUploadTile = null;
        if (shouldShowContestPending(contest)) {
          additionalUploadTile = ContestTileUploadPending;
        } else if (shouldShowContestRejected(contest)) {
          additionalUploadTile = ContestTileUploadRejected;
        }
        tiles = buildTileSet([
          ContestTileVoting,
          ContestTileDescription,
          ContestTilePrizes,
          additionalUploadTile,
        ]);
      }
      break;
    case ContestPhaseEnum.Ended:
      const showEnded =
        (contest.type === MediaContestTypeEnum.Photo &&
          contest.id === initialPhotoContestId) ||
        (contest.type === MediaContestTypeEnum.Video &&
          contest.id === initialVideoContestId);
      if (showEnded) {
        if (hasSubmitted) {
          if (
            !!contest.userPlace &&
            contest.userPlace <= contest.prizes.length
          ) {
            tiles = buildTileSet([ContestTileEndedWon]);
          } else {
            tiles = buildTileSet([
              ContestTileEndedParticipated,
              ContestTileEndedParticipatedTips,
            ]);
          }
        }
      }
      break;
  }
  if (!tiles || tiles.length === 0) {
    return null;
  }

  return (
    <VStack>
      {getContestPhase(contest) !== ContestPhaseEnum.Ended &&
        (!hasSubmitted || shouldShowContestRejected(contest) ? (
          <HStack color={'primary.500'}>
            <Icon as={icons.Schedule} boxSize="icon.md" />
            <Text fontWeight={500}>
              {t('contest:label.NeuerWettbewerbGestartet')}
            </Text>
          </HStack>
        ) : (
          <HStack color={'lime.500'}>
            <Icon as={icons.Check} boxSize="icon.md" />
            <Text fontWeight={500}>{t('contest:label.Teilgenommen')}</Text>
          </HStack>
        ))}
      <Flex width={'100vw'} scrollBehavior={'smooth'}>
        <HSnapScrollContainer
          ref={ref}
          scrollSnapStop={'always'}
          scrollBehavior={'smooth'}
          pr={`calc((100vw - ${CONTEST_TILE_WIDTH}px) / 2)`}
          py={0}
          gap={0}
          onScroll={onScroll}
        >
          {tiles.map((tile) => tile)}
        </HSnapScrollContainer>
      </Flex>
      {tiles.length > 1 && (
        <StepDotGroup totalSteps={tiles.length} currentStep={tileIndex + 1} />
      )}
    </VStack>
  );
};
