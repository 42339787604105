import * as icons from '@campoint/odi-ui-icons';
import {
  AspectRatio,
  AspectRatioProps,
  Box,
  Center,
  Circle,
  HStack,
  Heading,
  Icon,
  IconButton,
  List,
  Portal,
  Text,
  VStack,
  usePrevious,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import videoFsk16PreviewPlaceholder from '../../assets/images/imageUpload/video-fsk16-preview-placeholder.svg';
import videoFsk18PreviewPlaceholder from '../../assets/images/imageUpload/video-fsk18-preview-placeholder.svg';
import {
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import { AnimatedVXLogo } from '../../components/shared/AnimatedVXLogo/AnimatedVXLogo';
import { HelpLink } from '../../components/shared/HelpLink/HelpLink';
import { ImageCropper } from '../../components/shared/ImageCropper/ImageCropper';
import { ImageGalleryTag } from '../../components/shared/ImageGallery/components/ImageGalleryTag';
import { MediaPickerBox } from '../../components/shared/MediaPickerButton/MediaPickerButton';
import { HTipCard } from '../../components/shared/cards/TipCard/TipCard';
import {
  ChakraUniversalCard,
  UniversalCardSection,
} from '../../components/shared/cards/UniversalCard/UniversalCard';
import {
  MediaPictureFragment,
  useGetVideoLibraryUploadListEntryPreviewPicturesLazyQuery,
} from '../../generated/graphql';
import { languageQueryKey } from '../../i18n';
import { ImageCropperProvider } from '../../provider/ImageCropperProvider';
import {
  MediaFlowProvider,
  useMediaFlow,
} from '../../provider/MediaFlowProvider';
import {
  MediaInputProvider,
  useMediaInput,
} from '../../provider/MediaInputProvider';
import {
  MediaPropertiesProvider,
  useMediaProperties,
} from '../../provider/MediaPropertiesProvider';
import {
  MediaContext,
  MediaProvider,
  useMedia,
} from '../../provider/MediaProvider';
import { useVideoLibraryEntryModalContext } from '../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { externalRoutes } from '../../routes/routesConfig';
import { MediaFlowModal } from '../ModelProfileV2Page/components/MediaFlowModal/MediaFlowModal';

export const VideoLibraryUploadListEntryPreviewPictureControl: React.FC =
  () => {
    const { t } = useTranslation(['videoEditModal', 'videoThumbnailModal']);
    const { video, unsubmittedEdits, actions } =
      useVideoLibraryEntryModalContext();

    const [selectedFsk16PictureId, setSelectedFsk16PictureId] = React.useState(
      video?.previewPicture16?.umpId
    );
    const [selectedFsk18PictureId, setSelectedFsk18PictureId] = React.useState(
      video?.previewPicture18?.umpId
    );

    const onMediaPreview16 = React.useCallback(
      (m: MediaContext['media']) => {
        if (!m.blob || !m.src) {
          return;
        }

        actions.setUnsubmittedEdits((prev) => ({
          ...prev,
          thumbnail16Replacement: m,
        }));
      },
      [actions]
    );

    const onMediaPreview18 = React.useCallback(
      (m: MediaContext['media']) => {
        if (!m.blob || !m.src) {
          return;
        }

        actions.setUnsubmittedEdits((prev) => ({
          ...prev,
          thumbnail18Replacement: m,
        }));
      },
      [actions]
    );

    return (
      <Box>
        <Heading
          fontSize={'md'}
          fontWeight={'medium'}
          lineHeight={'5'}
          letterSpacing={'normal'}
          color={'coldGray.900'}
          pb={3}
        >
          {t('videoEditModal:inputs.heading.Vorschaubilder')}
        </Heading>
        <HStack w={'full'} gap={4}>
          <VideoLibraryUploadListEntryPreviewPicture
            heading={t('videoThumbnailModal:heading.U16AufreizendSoftCore')}
            onMedia={onMediaPreview16}
            placeholderSrc={videoFsk16PreviewPlaceholder}
            isFsk18={false}
            setSelectedPictureId={setSelectedFsk16PictureId}
            selectedPictureId={selectedFsk16PictureId}
            initialPictureId={video?.previewPicture16?.umpId}
            initialPictureSrc={video?.previewPicture16?.image.src}
            cachedReplacement={unsubmittedEdits?.thumbnail16Replacement}
          />
          <VideoLibraryUploadListEntryPreviewPicture
            heading={'Fsk18'}
            onMedia={onMediaPreview18}
            placeholderSrc={videoFsk18PreviewPlaceholder}
            isFsk18={true}
            setSelectedPictureId={setSelectedFsk18PictureId}
            selectedPictureId={selectedFsk18PictureId}
            initialPictureId={video?.previewPicture18?.umpId}
            initialPictureSrc={video?.previewPicture18?.image.src}
            cachedReplacement={unsubmittedEdits?.thumbnail18Replacement}
          />
        </HStack>
        <HelpLink
          pt={4}
          href={externalRoutes.vxModelsImageQuality(languageQueryKey)}
        >
          {t('videoEditModal:inputs.text.SoErstellstDuDasPerfekteVorschaubild')}
        </HelpLink>
      </Box>
    );
  };

/**
 * !Must be enclosed by:
 * - **{@link MediaInputProvider}**
 */
const MediaUploadFileInputCropperPortal: React.FC = () => {
  const { action } = useMedia();
  const { mediaFile, isCropperShown, closeCropper } = useMediaInput();
  const { onClose } = useMediaFlow();

  return mediaFile?.originalSrc && isCropperShown ? (
    <Portal>
      <ImageCropperProvider
        src={mediaFile?.originalSrc}
        onImage={(c) => {
          if (c) {
            action.setReplacement({
              src: c.blobSrc,
              blob: c.blob,
              filename: mediaFile?.originalFile?.name ?? null,
              key: uuidv4(),
              size: c.size,
            });
          }
          closeCropper();
          if (c) onClose();
        }}
      >
        <ImageCropper />
      </ImageCropperProvider>
    </Portal>
  ) : null;
};

/**
 * !Must be enclosed by:
 * - **{@link MediaProvider}**
 */
const MediaUploadSrcSelectCropperPortal: React.FC<{
  src: string | null | undefined;
  setSrcToCrop: React.Dispatch<React.SetStateAction<string | null>>;
  umpId?: number;
}> = ({ src, setSrcToCrop, umpId }) => {
  const { action } = useMedia();
  const { onClose } = useMediaFlow();
  return src ? (
    <Portal>
      <ImageCropperProvider
        src={src}
        onImage={(c) => {
          if (c) {
            action.setReplacement({
              src: c.blobSrc,
              blob: c.blob,
              filename: null,
              key: uuidv4(),
              size: c.size,
              umpId,
            });
          }
          setSrcToCrop(null);
          if (c) onClose();
        }}
      >
        <ImageCropper />
      </ImageCropperProvider>
    </Portal>
  ) : null;
};

const VideoLibraryUploadListEntryPreviewPicture: React.FC<{
  heading?: string;
  placeholderSrc: string;
  onMedia: (media: MediaContext['media']) => void;
  initialPictureId?: Maybe<number>;
  initialPictureSrc?: Maybe<string>;
  selectedPictureId?: Maybe<number>;
  setSelectedPictureId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  cachedReplacement?: MediaContext['media'];
  isFsk18: boolean;
}> = ({
  heading,
  initialPictureId,
  placeholderSrc,
  onMedia,
  isFsk18,
  initialPictureSrc,
  cachedReplacement,
}) => {
  const { t } = useTranslation(['videoLibrary', 'videoThumbnailModal']);

  const { albumId } = useVideoLibraryEntryModalContext();

  //preview pictures list could get updated during edit modal open state
  //-> reload list when this modal opens?
  const [refetch, { data, loading: isLoading }] =
    useGetVideoLibraryUploadListEntryPreviewPicturesLazyQuery();

  const [srcToCrop, setSrcToCrop] = React.useState<string | null>(null);
  const [umpId, setUmpId] = React.useState<number | undefined>();

  const previewPictures = React.useMemo(() => {
    return data?.media?.videos?.videoById?.pictures;
  }, [data?.media?.videos?.videoById?.pictures]);

  const onSelectPreviewPicture = (umpId: number) => {
    const src = previewPictures?.find((pic) => pic?.umpId === umpId)?.image.src;

    if (!src) return;

    setSrcToCrop(src);
    setUmpId(() => umpId);
  };

  const loadPreviewPictures = () => {
    if (albumId) {
      refetch({ variables: { albumId: albumId ?? undefined } });
    }
  };

  return (
    <MediaPropertiesProvider
      targetDimensions={{ width: 1920, height: 1080 }}
      placeholderSrc={placeholderSrc}
    >
      <MediaProvider initialSrc={initialPictureSrc ?? undefined}>
        <MediaFilePicked
          onMedia={onMedia}
          cachedReplacement={cachedReplacement}
        />
        <MediaInputProvider>
          <MediaFlowProvider clearReplacementsOnClose={false}>
            <MediaUploadFileInputCropperPortal />
            <MediaUploadSrcSelectCropperPortal
              src={srcToCrop}
              setSrcToCrop={setSrcToCrop}
              umpId={umpId}
            />
            <MediaFlowModal isVCentered={false}>
              <ResponsiveModalOverlay />
              <ResponsiveModalContent>
                <ResponsiveModalStickyHeaderBox bg={'surface'}>
                  <Heading as="h1" children={heading} size={'sm'} />
                  <ResponsiveModalCloseButton />
                </ResponsiveModalStickyHeaderBox>
                <ResponsiveModalBodyBox pt={0}>
                  <UniversalCardSection>
                    <ChakraUniversalCard
                      py={6}
                      px={4}
                      //this content is like in BlockerCard.tsx for example -> for consistency reasons
                      //should also be consistent with upload box in VideoLibraryPage
                      cardBody={
                        <VStack gap={0}>
                          <Heading
                            fontSize={'16px'}
                            fontWeight={'medium'}
                            h={'min'}
                            p={0}
                            size={'sm'}
                            textAlign={'center'}
                          >
                            {t(
                              'videoThumbnailModal:heading.VorschaubildHochladen'
                            )}
                          </Heading>
                          <Text
                            color={'gray.500'}
                            fontSize={'14px'}
                            p={0}
                            textAlign={'center'}
                          >
                            {t(
                              'videoThumbnailModal:text.ZeigeSoVielDuWillstWeckeAberNeugierUndRegeDieFantasieAn'
                            )}
                          </Text>
                        </VStack>
                      }
                      cardFooter={
                        <VStack gap={4}>
                          <MediaPickerBox
                            isDropzoneEnabled
                            borderWidth={'1px'}
                            borderColor={'steel'}
                            borderStyle={'dashed'}
                            borderRadius={'md'}
                            bg={'surface'}
                            w={'min-content'}
                            sx={{
                              '.drag-over &': {
                                borderColor: `primary.500`,
                              },
                            }}
                            cursor={'pointer'}
                          >
                            <VStack
                              textAlign={'center'}
                              px={'40px'}
                              py={'16px'}
                            >
                              <Circle
                                size={'32px'}
                                border={'1px'}
                                borderColor={'primary.500'}
                              >
                                <Icon
                                  as={icons.Upload}
                                  height={'15px'}
                                  width={'15px'}
                                  color={'primary.500'}
                                />
                              </Circle>
                              <Text
                                fontSize={'12px'}
                                color={'primary.500'}
                                minW={'100px'}
                              >
                                {t(
                                  'videoThumbnailModal:text.DragDropOderBildAuswahlen'
                                )}
                              </Text>
                            </VStack>
                          </MediaPickerBox>
                        </VStack>
                      }
                    />
                  </UniversalCardSection>
                  <Center>
                    <Text color={'darkSteel'}>
                      {t('videoThumbnailModal:text.Oder')}
                    </Text>
                  </Center>
                  <Heading pt={6} size={'sm'}>
                    {t('videoThumbnailModal:heading.VorschaubildAuswaehlen')}
                  </Heading>
                  {(previewPictures?.length === 0 || albumId === null) && (
                    <Box mt={3}>
                      <HTipCard
                        text={t(
                          'videoThumbnailModal:text.DasVideoWirdNochVerarbeitetAutomatischGenerierteVorschaubilderSindNoXX'
                        )}
                      />
                    </Box>
                  )}
                  <VideoPreviewList
                    entries={
                      (previewPictures?.filter(Boolean) ??
                        []) as MediaPictureFragment[]
                    }
                    selectedUmpId={initialPictureId ?? null}
                    onSelectPreviewPicture={onSelectPreviewPicture}
                    isLoading={isLoading}
                  />
                </ResponsiveModalBodyBox>
              </ResponsiveModalContent>
            </MediaFlowModal>
            <VideoPreviewMediaPreviewBox
              loadPreviewPictures={loadPreviewPictures}
              isFsk18={isFsk18}
            />
          </MediaFlowProvider>
        </MediaInputProvider>
      </MediaProvider>
    </MediaPropertiesProvider>
  );
};

const MediaFilePicked: React.FC<{
  onMedia: (media: MediaContext['media']) => void;
  cachedReplacement?: MediaContext['media'];
  children?: React.ReactNode;
}> = ({ onMedia, cachedReplacement, children }) => {
  const { hasReplacement, media, action } = useMedia();
  const previousMedia = usePrevious(media);
  React.useEffect(() => {
    if (hasReplacement && media !== previousMedia) {
      onMedia(media);
    }
  }, [hasReplacement, media, action, onMedia, previousMedia]);

  React.useEffect(() => {
    if (!hasReplacement && cachedReplacement) {
      action.setReplacement(cachedReplacement);
    }
  }, [hasReplacement, cachedReplacement, action]);

  return <>{children}</>;
};

const VideoPreviewMediaPreviewBoxTags: React.FC<{ tags?: string[] }> = ({
  tags,
}) => (
  <HStack
    position={'absolute'}
    p={1}
    top={0}
    left={0}
    spacing={'0.5'}
    pointerEvents={'none'}
  >
    {tags?.map((tag) => (
      <ImageGalleryTag key={tag} children={tag} />
    ))}
  </HStack>
);

const VideoPreviewMediaPreviewBox: React.FC<{
  loadPreviewPictures: () => void;
  isFsk18: boolean;
}> = ({ loadPreviewPictures, isFsk18 }) => {
  const { onOpen } = useMediaFlow();
  const { t } = useTranslation(['videoEditModal']);
  const { placeholderSrc } = useMediaProperties();
  const { currentSrc } = useMedia();
  const fskTag = currentSrc
    ? t('videoEditModal:inputs.text.Ab', { fsk: isFsk18 ? 18 : 16 })
    : null;
  return (
    <AspectRatio ratio={16 / 9} w={'full'}>
      <Box
        borderRadius={'lg'}
        border={!currentSrc ? '1px' : 'none'}
        borderColor={'steel'}
        bg={'steel'}
        position={'relative'}
        style={{
          backgroundImage: !currentSrc
            ? `url(${placeholderSrc})`
            : `url(${currentSrc})`,
          backgroundSize: 'cover',
        }}
        onClick={() => {
          loadPreviewPictures();
          onOpen();
        }}
      >
        <IconButton
          size={'sm'}
          position={'absolute'}
          variant={'solid'}
          bottom={1}
          right={1}
          icon={<Icon as={icons.AddPhoto} boxSize={'icon.md'} />}
          aria-label={'Edit Thumbnail'}
        />
        <VideoPreviewMediaPreviewBoxTags tags={fskTag ? [fskTag] : undefined} />
      </Box>
    </AspectRatio>
  );
};

const VideoPreviewList: React.FC<{
  selectedUmpId: number | null;
  onSelectPreviewPicture: (umpId: number) => void;
  entries: MediaPictureFragment[];
  isLoading: boolean;
}> = ({ selectedUmpId, onSelectPreviewPicture, entries, isLoading }) => {
  return (
    <VStack as={List} gap={6} alignItems={'stretch'} pt={4}>
      {!isLoading ? (
        entries?.map((picture) => (
          <VideoPreviewListItem
            key={picture.umpId}
            isSelected={picture.umpId === selectedUmpId}
            picture={picture}
            onClick={() => {
              if (picture?.umpId) {
                onSelectPreviewPicture(picture.umpId);
              }
            }}
          />
        ))
      ) : (
        <Center>
          <Box w={'96px'} h={'96px'}>
            <AnimatedVXLogo />
          </Box>
        </Center>
      )}
    </VStack>
  );
};

const VideoPreviewListItem: React.FC<
  { isSelected: boolean; picture: MediaPictureFragment } & AspectRatioProps
> = ({ isSelected, picture, ...aspectRatioProps }) => {
  const selectedColor = isSelected ? 'primary.500' : 'white';
  return (
    <AspectRatio
      ratio={16 / 9}
      as={'li'}
      w={'full'}
      bgImage={picture?.image.src}
      bgSize={'cover'}
      {...aspectRatioProps}
    >
      <Box position={'relative'}>
        <Circle
          bg={selectedColor}
          size={'32px'}
          m={3}
          outline={'1px'}
          outlineOffset={'1px'}
          outlineColor={selectedColor}
          position={'absolute'}
          top={2}
          right={2}
        >
          {isSelected && (
            <Icon as={icons.Done} color={'white'} boxSize={'icon.md'} />
          )}
        </Circle>
      </Box>
    </AspectRatio>
  );
};
