import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MissingRequirementsImage from '../../../../../../../assets/images/shared/missing-requirements-blocker.svg';
import { UrlFragment } from '../../../../../../../components/Layout/UrlFragmentScrollToTarget';
import { BlockerCard } from '../../../../../../../components/shared/BlockerCard/BlockerCard';
import { routes } from '../../../../../../../routes/routesConfig';

export const FeedStillBlockedCard = () => {
  const history = useHistory();
  const { t } = useTranslation(['general', 'feed']);

  const navigateToTaskSectiononHome = () => {
    history.push({
      pathname: routes.home.path,
      hash: UrlFragment.Tasks,
    });
  };

  return (
    <BlockerCard
      isBordered
      image={MissingRequirementsImage}
      heading={
        <>
          <span children={t('feed:heading.BaldVerfugbar')} />
          <br />
          <span children={'VXFeed'} />
        </>
      }
      text={t(
        'feed:text.SchliesseZuerstAlleSchritteAbSodassDuDirektDeinenErstenBeitragImVXFeXX'
      )}
      primaryButtonText={t('feed:button.SchritteAbschliessen')}
      onClickPrimary={navigateToTaskSectiononHome}
    />
  );
};
