import { Circle, SquareProps } from '@chakra-ui/react';
import React from 'react';

export type GuideDotProps = { isShown?: boolean } & SquareProps;
export const GuideDot: React.FC<GuideDotProps> = ({
  isShown = true,
  ...rest
}) => (
  <Circle
    bg={'caribbeanGreen.500'}
    size={3}
    pointerEvents={'none'}
    role={'presentation'}
    visibility={isShown ? 'visible' : 'hidden'}
    {...rest}
  />
);

/**
 * @example
 * // <InlineDot/>
 * //     ↓
 * // Text°
 * <span>Text<InlineDot isShown={showDot} /><span/>
 */
export const InlineGuideDot: React.FC<GuideDotProps> = (props) => (
  <GuideDot
    display="inline-block"
    verticalAlign="top"
    size={3}
    m={'0.5'}
    {...props}
  />
);

/**
 * @example
 * // ╭────────────────────╴● <- <CornerGuideDot/>
 * // │  <WrappedButton />  │
 * // ╰─────────────────────╯
 * <RelativeBox
 *   attachment={<CornerGuideDot isShown={showDot} />}
 *   w={'min-content}
 * >
 *   <WrappedButton />
 * </RelativeBox>
 */
export const CornerGuideDot: React.FC<GuideDotProps> = (props) => (
  <GuideDot
    display={'block'}
    position={'absolute'}
    right={'-1'}
    top={'-1'}
    size={3}
    {...props}
  />
);

/**
 * @example
 * // ╭────────────────────╴● <- <BigCornerGuideDot/>
 * // │                     │
 * // │   <WrappedCard />   │
 * // │                     │
 * // │                     │
 * // ╰─────────────────────╯
 * <RelativeBox attachment={<BigCornerGuideDot isShown={showDot} />}>
 *   <WrappedCard />
 * </RelativeBox>
 */
export const BigCornerGuideDot: React.FC<GuideDotProps> = (props) => (
  <CornerGuideDot right={'-2.5'} top={'-2.5'} size={5} {...props} />
);
