import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ExperienceIntentionFilterSheet } from '../../../components/shared/FilterSheet/FilterSheet';
import { FilterableTipListContext } from '../../../provider/TipProvider';

export const TipFilterSheet: React.FC<{
  context: FilterableTipListContext;
}> = ({ context }) => {
  const { t } = useTranslation(['tipsPage', 'general']);

  return (
    <ExperienceIntentionFilterSheet
      context={context}
      renderCountDisplay={(count) =>
        t('tipsPage:button.CountTippsAnzeigen', {
          count,
        })
      }
      renderCountDisplayReset={t('general:button.FilterZurucksetzen')}
      experienceHeading={t('tipsPage:headline.WieErfahrenBistDuBereits')}
      experienceTabTitle={t('tipsPage:headline.Erfahrung')}
      intentionHeading={t('tipsPage:headline.WasMochtestDuErreichen')}
      intentionTabTitle={t('tipsPage:headline.Ziel')}
    />
  );
};
