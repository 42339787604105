import { Grid, GridProps } from '@chakra-ui/react';
import React from 'react';

export type InputListProps = GridProps;

export const InputList: React.FC<InputListProps> = ({ children, ...props }) => {
  return (
    <Grid gap={4} w={'100%'} alignItems={'inherit'} {...props}>
      {children}
    </Grid>
  );
};
