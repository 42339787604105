import {
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  chakra,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabListWithOverflowScroll } from '../../../../components/Layout/TabListWithOverflowScroll';
import { InlineGuideDot } from '../../../../components/shared/GuideDot/GuideDot';
import { useQueryParamState } from '../../../../hooks/useQueryParamState';
import { useFinanceService } from '../../../../provider/FinanceService/FinanceService';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import PayoutAddressSection from '../../section/PayoutAddressSection/PayoutAddressSection';
import { PayoutMethodSection } from '../../section/PayoutMethodSection/PayoutMethodSection';
import PayoutTaxSection from '../../section/PayoutTaxSection/PayoutTaxSection';
import { OldPayoutWithdrawalsSection } from '../../section/PayoutWithdrawalsSection/OldPayoutWithdrawalsSection';

export const OldPayoutOverviewTabSection: React.FC = () => {
  const {
    isPayoutTaxUpdateVisible,
    isPayoutAddressUpdateVisible,
    isPayoutMethodUpdateVisible,
    isPayoutWizardFinished,
    isPayoutTaxDocumentExpired,
  } = useFinanceService();

  const isPayoutWizardCompleted = isPayoutWizardFinished;

  const isVatTabPanelVisible =
    isPayoutWizardCompleted && isPayoutTaxUpdateVisible;

  const isPaymentTabPanelVisible =
    isPayoutWizardCompleted &&
    (isPayoutAddressUpdateVisible || isPayoutMethodUpdateVisible);

  const tapIds = React.useMemo(() => {
    return [
      'billing',
      ...(isVatTabPanelVisible ? ['vat'] : []),
      ...(isPaymentTabPanelVisible ? ['payment'] : []),
    ];
  }, [isVatTabPanelVisible, isPaymentTabPanelVisible]);

  const [tapId, setTabId] = useQueryParamState('tapId');
  const { t } = useTranslation(['financePage']);

  const tapIndex = React.useMemo(() => {
    if (!tapId) {
      return 0;
    }
    const index = tapIds.indexOf(tapId);
    return index >= 0 ? index : 0;
  }, [tapId, tapIds]);

  const {
    isDirty,
    action: { showConfirmChangesDialog },
  } = useNavigationBlock();

  const handleTabChange = React.useCallback(
    (index: number) => {
      const newTapId = tapIds[index] ?? tapIds[0];
      if (!isDirty) {
        setTabId(newTapId);
      } else {
        showConfirmChangesDialog(() => {
          setTabId(newTapId);
        });
      }
    },
    [isDirty, showConfirmChangesDialog, tapIds, setTabId]
  );

  return (
    <>
      <Tabs
        defaultIndex={0}
        index={tapIndex}
        isFitted
        w={'full'}
        onChange={handleTabChange}
        size={{ base: 'sm', lg: 'md' }}
      >
        <TabListWithOverflowScroll hidden={tapIds.length <= 1}>
          <Tab children={t('financePage:tab.Auszahlung')} />
          {isVatTabPanelVisible && (
            <Tab>
              <chakra.span whiteSpace={'nowrap'}>
                {t('financePage:tab.VAT')}
                {isPayoutTaxDocumentExpired && (
                  <InlineGuideDot bg={'error.500'} />
                )}
              </chakra.span>
            </Tab>
          )}
          {isPaymentTabPanelVisible && (
            <Tab
              fontSize={{ base: 'sm', lg: 'xl' }}
              children={t('financePage:tab.Payment')}
            />
          )}
        </TabListWithOverflowScroll>
        <TabPanels>
          <TabPanel p={0}>
            <OldPayoutWithdrawalsSection />
          </TabPanel>
          {isVatTabPanelVisible && (
            <TabPanel p={0}>
              {isPayoutTaxUpdateVisible && <PayoutTaxSection />}
            </TabPanel>
          )}
          {isPaymentTabPanelVisible && (
            <TabPanel p={0}>
              <VStack spacing={8} alignItems={'stretch'}>
                {isPayoutMethodUpdateVisible && <PayoutMethodSection />}
                {isPayoutAddressUpdateVisible && <PayoutAddressSection />}
              </VStack>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};
