import * as H from 'history';
import { DateTime } from 'luxon';

import {
  ContestFragment,
  MediaContestTypeEnum,
  VideoStatusEnum,
} from '../../../../generated/graphql';
import { videosTabId } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { routes } from '../../../../routes/routesConfig';

export const CONTEST_TILE_WIDTH = 290;
export const CONTEST_TILE_HEIGHT = 370;

export enum ContestPhaseEnum {
  Upload = 'Upload',
  Vote = 'Vote',
  Ended = 'Ended',
  Unkonwn = 'Unkonwn',
}

export const shouldShowContestPending = (contest: ContestFragment): boolean => {
  return (
    contest.type === MediaContestTypeEnum.Video &&
    contest.video !== null &&
    (contest.video!.status === VideoStatusEnum.Rating ||
      contest.video!.status === VideoStatusEnum.Transcoding)
  );
};
export const shouldShowContestRejected = (
  contest: ContestFragment
): boolean => {
  return (
    contest.type === MediaContestTypeEnum.Video &&
    contest.video !== null &&
    contest.video!.status === VideoStatusEnum.Rejected
  );
};
export const shouldShowContestApproved = (
  contest: ContestFragment
): boolean => {
  return (
    contest.type === MediaContestTypeEnum.Video &&
    contest.video !== null &&
    (contest.video!.status === VideoStatusEnum.New ||
      contest.video!.status === VideoStatusEnum.Online)
  );
};

export const contestHasSubmitted = (contest: ContestFragment): boolean => {
  return (
    contest.video !== null || (contest.photoalbum?.pictureCountTotal ?? 0) > 0
  );
};

export const getContestPhase = (contest: ContestFragment): ContestPhaseEnum => {
  if (
    contest.uploadEnd &&
    DateTime.now() < DateTime.fromISO(contest.uploadEnd)
  ) {
    return ContestPhaseEnum.Upload;
  }

  if (
    contest.votingEnd &&
    DateTime.now() < DateTime.fromISO(contest.votingEnd)
  ) {
    return ContestPhaseEnum.Vote;
  }

  if (
    contest.votingEnd &&
    DateTime.now() >= DateTime.fromISO(contest.votingEnd)
  ) {
    return ContestPhaseEnum.Ended;
  }

  return ContestPhaseEnum.Unkonwn;
};

export const goToMedia = (
  contest: ContestFragment,
  location: H.Location,
  history: H.History
) => {
  if (contest.type === MediaContestTypeEnum.Video) {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tabId', videosTabId);
    history.push({
      pathname: routes.videoLibrary.path,
      search: queryParams.toString(),
    });
  } else {
    history.push(routes.feed.path);
  }
};
