import { throttle } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { useMarkPostAsUploadedMutation } from '../generated/feed';
import { useCallbackRegister } from '../hooks/useCallbackRegister';
import { createContext } from '../hooks/useContext';
import useIsMounted from '../hooks/useIsMounted';
import Logger from '../utils/Logger';
import { uploadBlob } from '../utils/media';

export enum UploadJobEntityType {
  POST = 'post',
  VIDEO = 'video',
  IMAGE = 'image',
}

export type UploadJobEntity = {
  readonly type: UploadJobEntityType;
  readonly id: string;
};

export type UploadJobHandle = {
  readonly id: string;
  readonly entity: UploadJobEntity;
};

export type UploadJobPayload = {
  thumbnail?: Blob | null;
  mediaFiles: { uploadUrl: string; blob: Blob }[];
};

export type CreateUploadJob = {
  entity: UploadJobEntity;
  payload: UploadJobPayload;
};

export type UploadJob = UploadJobHandle & {
  progress: number;
  payload: UploadJobPayload;
  success: boolean | null;
};

export type UploadManagerContext = {
  readonly jobs: UploadJob[];
  readonly action: {
    readonly registerOnJobAdded: (
      fn: (jobHandle: UploadJobHandle) => void
    ) => void;
    readonly registerOnJobUpdated: (
      fn: (jobHandle: UploadJobHandle) => void
    ) => void;
    readonly registerOnJobFinished: (
      fn: (jobHandle: UploadJobHandle) => void
    ) => void;
    readonly queueUploadJob: (job: CreateUploadJob) => void | Promise<any>;
    readonly clearQueue: () => void;
    readonly cleanQueueOfFinishedJobs: () => void;
  };
};

export const [, useUploadManager, uploadManagerContext] =
  createContext<UploadManagerContext>({
    name: 'UploadManagerContext',
    errorMessage:
      'useUploadManager: `uploadManagerContext` is undefined. Seems you forgot to wrap component within the Provider',
  });

export const UploadManagerProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation(['general']);
  const [markAsUploaded] = useMarkPostAsUploadedMutation({
    onError: (error) => {
      Logger.error(error);
    },
  });

  const [registerOnJobAdded, notifyOnJobAdded] =
    useCallbackRegister<(jobHandle: UploadJobHandle) => void>();
  const [registerOnJobUpdated, notifyOnJobUpdated] =
    useCallbackRegister<(jobHandle: UploadJobHandle) => void>();
  const [registerOnJobFinished, notifyOnJobFinished] =
    useCallbackRegister<(jobHandle: UploadJobHandle) => void>();

  const [jobs, setJobs] = React.useState<UploadJob[]>([]);

  const addJob = React.useCallback(
    (job: UploadJob) => {
      setJobs((prevState) => [...prevState, job]);
    },
    [setJobs]
  );

  const updateJob = React.useCallback(
    (jobId: string, update: (oldJob: UploadJob) => UploadJob) => {
      if (!isMounted()) {
        return;
      }
      setJobs((prevState) => {
        const foundJob = prevState.find((job) => job.id === jobId) ?? null;
        if (!foundJob) {
          return prevState;
        }
        const indexOfFoundJob = prevState.indexOf(foundJob);
        const updatedJob = update(foundJob);
        prevState.splice(indexOfFoundJob, 1, updatedJob);
        return [...prevState];
      });
    },
    [setJobs, isMounted]
  );

  const clearQueue = React.useCallback(() => {
    if (!isMounted()) {
      return;
    }
    setJobs([]);
  }, [setJobs, isMounted]);

  const cleanQueueOfFinishedJobs = React.useCallback(() => {
    if (!isMounted()) {
      return;
    }
    setJobs((prevState) => [
      ...prevState.filter((job) => job.success === null),
    ]);
  }, [setJobs, isMounted]);

  const queueUploadJob = React.useCallback(
    async ({ entity, payload }: CreateUploadJob) => {
      const jobId = uuidv4();
      const jobHandle = {
        id: jobId,
        entity,
      };

      const throttledJobProgressUpdate = throttle((totalProgress: number) => {
        updateJob(jobQueued.id, (oldJob) => ({
          ...oldJob,
          progress: totalProgress,
        }));
        notifyOnJobUpdated(jobHandle);
      }, 250);

      const jobQueued: UploadJob = {
        ...jobHandle,
        payload,
        progress: 0,
        success: null,
      };

      addJob(jobQueued);
      notifyOnJobAdded(jobHandle);

      const progressArray = Array.from({
        length: payload.mediaFiles.length,
      }).map(() => ({
        result: null as any,
        progress: 0,
      }));

      try {
        const allUploadPromises = payload.mediaFiles.map((mediaFile, index) => {
          const newUrl = new URL(mediaFile.uploadUrl);
          //we need this for unknown file types -> BE needs the file extension
          const filename = (mediaFile.blob as any).name;
          const fileExtension = filename ? `.${filename.split('.').pop()}` : '';
          // Should not cause issues being added to video urls as well
          newUrl.searchParams.set('json', 'true');
          return uploadBlob(
            newUrl.toString(),
            mediaFile.blob,
            `${entity.type}-${entity.id}-${index}-of${payload.mediaFiles.length}${fileExtension}`,
            (progressInPercent) => {
              progressArray[index].progress = progressInPercent;
              const totalProgress =
                progressArray.reduce((acc, curr) => acc + curr.progress, 0) /
                progressArray.length;

              throttledJobProgressUpdate(totalProgress);
            }
          )
            .then((result) => {
              progressArray[index].result = result;
            })
            .catch((error) => Logger.error(error));
        });

        await Promise.all([...allUploadPromises]);

        const allUploadsSucceded = progressArray.every(
          (entry) => !!entry?.result?.success
        );

        if (!entity.id) return;

        if (!allUploadsSucceded) {
          //this most likely happens when the user tried
          //to upload a post with a wrong video format
          issueChakraToast({
            status: 'error',
            description: t('general:toast.DateiformatNichtUnterstutzt'),
          });

          updateJob(jobQueued.id, (oldJob) => ({
            ...oldJob,
            success: false,
          }));
          notifyOnJobUpdated(jobHandle);
        } else {
          const result = await markAsUploaded({
            variables: {
              postId: entity.id,
            },
          });

          updateJob(jobQueued.id, (oldJob) => ({
            ...oldJob,
            progress: 100,
            success:
              result.data?.vxmodels?.setUploadCompleted?.success ?? false,
          }));
          notifyOnJobUpdated(jobHandle);
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        throttledJobProgressUpdate.cancel();
        notifyOnJobFinished(jobHandle);
      }
      return {};
    },
    [
      addJob,
      notifyOnJobAdded,
      updateJob,
      notifyOnJobUpdated,
      t,
      markAsUploaded,
      notifyOnJobFinished,
    ]
  );

  const action: UploadManagerContext['action'] = React.useMemo(() => {
    return {
      registerOnJobAdded,
      registerOnJobUpdated,
      registerOnJobFinished,
      queueUploadJob,
      clearQueue,
      cleanQueueOfFinishedJobs,
    };
  }, [
    registerOnJobAdded,
    registerOnJobUpdated,
    registerOnJobFinished,
    queueUploadJob,
    clearQueue,
    cleanQueueOfFinishedJobs,
  ]);

  const context: UploadManagerContext = React.useMemo(() => {
    return {
      jobs,
      action,
    };
  }, [action, jobs]);

  return <uploadManagerContext.Provider value={context} children={children} />;
};

export const useUploadManagerJob = (
  entityType: UploadJobEntityType,
  entityId: string
) => {
  const { jobs } = useUploadManager();
  return React.useMemo(() => {
    return (
      jobs.find(
        (job) => job.entity.type === entityType && job.entity.id === entityId
      ) ?? null
    );
  }, [jobs, entityId, entityType]);
};

export const useUploadManagerJobsByType = (entityType: UploadJobEntityType) => {
  const { jobs } = useUploadManager();
  return React.useMemo(
    () => jobs.find((job) => job.entity.type === entityType) ?? null,
    [jobs, entityType]
  );
};
