import React, { FC, memo, useEffect } from 'react';

export const animatedVXLogoTestingIds = {
  svg: 'svg',
  animatedPolyLine: 'animatedPolyLine',
};

const PAUSE = 500;
const LENGTH = 1400;

export const AnimatedVXLogo: FC<{ className?: string }> = memo(
  ({ className }) => {
    const ref = React.createRef<SVGPolylineElement>();
    const animationFrameRef = React.useRef<number>();
    const startTime = new Date().getTime() - LENGTH - PAUSE;

    const update = React.useCallback(() => {
      const offset = new Date().getTime() - startTime;
      const pos = (offset % (2 * LENGTH + PAUSE)) - LENGTH - PAUSE;
      const val = Math.min(Math.abs(pos), LENGTH);
      if (ref.current) {
        ref.current.style.strokeDashoffset = val.toString();
      }
      animationFrameRef.current = window.requestAnimationFrame(update);
    }, [startTime, ref]);

    useEffect(() => {
      update();

      return () => {
        if (animationFrameRef.current) {
          window.cancelAnimationFrame(animationFrameRef.current);
        }
      };
    }, [update]);

    const createStops = (stops: React.SVGProps<SVGStopElement>[]) =>
      stops.map((props, index) => <stop key={index} {...props} />);

    return (
      <div>
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 341.07 246.61"
        >
          <defs>
            <linearGradient
              id="Gradient_1"
              x1="230.4"
              y1="161.51"
              x2="293.05"
              y2="210.73"
              gradientUnits="userSpaceOnUse"
            >
              {createStops([
                { stopColor: '#9e3026', offset: 0 },
                { stopColor: '#a93329', offset: 0.02 },
                { stopColor: '#c43b2f', offset: 0.09 },
                { stopColor: '#da4234', offset: 0.17 },
                { stopColor: '#eb4737', offset: 0.27 },
                { stopColor: '#f64a3a', offset: 0.38 },
                { stopColor: '#f64a3a', offset: 0.55 },
                { stopColor: '#ff4d3c', offset: 1 },
              ])}
            </linearGradient>
            <linearGradient
              id="Gradient_2"
              x1="203.76"
              y1="47.71"
              x2="150.49"
              y2="8"
              gradientUnits="userSpaceOnUse"
            >
              {createStops([
                { stopColor: '#9e3026', offset: 0 },
                { stopColor: '#bc392d', offset: 0.1 },
                { stopColor: '#d44032', offset: 0.21 },
                { stopColor: '#e74637', offset: 0.33 },
                { stopColor: '#f54a3a', offset: 0.47 },
                { stopColor: '#fd4c3b', offset: 0.65 },
                { stopColor: '#ff4d3c', offset: 1 },
              ])}
            </linearGradient>

            <mask id="mask_1">
              <polyline
                fill="none"
                ref={ref}
                fillOpacity="0"
                stroke="#fff"
                strokeWidth="26.8"
                strokeDasharray={LENGTH}
                points="115.09 39.98 170.09 117.98 243.09 13.98 315.09 13.98 170.09 222.98 26.09 13.98 170.09 12.98 307.09 210.98 235.09 211.98 207.09 171.98"
              />
            </mask>
          </defs>
          <path
            fill="url(#Gradient_1)"
            fillRule="evenodd"
            mask="url(#mask_1)"
            d="M222.74,170.34,243,199.66h40L242.8,141.49l16.34-23.34L334,226.35H229l-22.48-32.52Z"
          />

          <polygon
            fill="url(#Gradient_2)"
            fillRule="evenodd"
            mask="url(#mask_1)"
            points="177.52 0.01 206.83 42.47 190.74 66.15 163.51 26.77 177.52 0.01"
          />

          <polygon
            fill="#ff4d3c"
            fillRule="evenodd"
            mask="url(#mask_1)"
            points="341.07 0 235.98 0 170.5 94.8 141.95 53.51 109.48 53.51 170.5 141.75 249.99 26.76 290.04 26.76 170.5 199.65 50.99 26.81 163.5 26.76 164.29 27.89 178.41 1.3 177.51 0 0 0.03 170.5 246.61 341.07 0"
          />
        </svg>
      </div>
    );
  }
);
AnimatedVXLogo.displayName = 'AnimatedVXLogo';
