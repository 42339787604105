import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetDocumentUploadUrlQueryVariables,
  useGetDocumentUploadUrlLazyQuery,
} from '../../../../generated/graphql';
import { MediaContext } from '../../../../provider/MediaProvider';
import { uploadBlob } from '../../../../utils/media';
import { checkProperlyFormattedUploadResponse } from '../../../../utils/media/checkProperlyFormattedUploadResponse';

export const useDocumentUpload = () => {
  const { t } = useTranslation(['validation', 'mediaUpload']);
  const [getDocumentUploadUrl] = useGetDocumentUploadUrlLazyQuery({
    fetchPolicy: 'no-cache',
    context: {
      // https://github.com/apollographql/apollo-link/issues/517#issuecomment-549058688
      // fixme: BEWARE! concurrent request not working!
      queryDeduplication: false,
    },
  });
  const uploadedDocIds = React.useRef(new Map<any, number>());

  return React.useCallback(
    async ({
      documentToUpload,
      shouldReuploadKnownDocument = false,
      variables,
    }: {
      documentToUpload?: MediaContext['media'] | null;
      variables: GetDocumentUploadUrlQueryVariables;
      shouldReuploadKnownDocument?: boolean;
    }) => {
      const foundPreviousUploadDocumentId =
        uploadedDocIds.current.get(documentToUpload);
      if (!shouldReuploadKnownDocument && !!foundPreviousUploadDocumentId) {
        return { success: true, documentId: foundPreviousUploadDocumentId };
      }

      if (!documentToUpload?.blob) {
        return {
          success: false,
          message: t(
            'mediaUpload:error.UpsDaIstWohlEtwasSchiefGelaufenVersucheEsBitteErneut'
          ),
        };
      }

      const response = await getDocumentUploadUrl({ variables });

      const uploadUrl = response?.data?.documents?.uploadUrl;
      if (!uploadUrl) {
        return {
          success: false,
          message: t(
            'mediaUpload:error.UpsDaIstWohlEtwasSchiefGelaufenVersucheEsBitteErneut'
          ),
        };
      }

      // Transform uploadUrl to receive json response
      const extendedUploadURL = new URL(uploadUrl);
      extendedUploadURL.searchParams.set('json', 'true');

      const uploadResult = await uploadBlob(
        extendedUploadURL.href,
        documentToUpload?.blob,
        documentToUpload?.filename ?? undefined
      );

      if (!checkProperlyFormattedUploadResponse(uploadResult)) {
        return {
          success: false,
          message: t(
            'mediaUpload:error.UpsDaIstWohlEtwasSchiefGelaufenVersucheEsBitteErneut'
          ),
        };
      }

      if (uploadResult.success) {
        const documentId = uploadResult.documentId;
        uploadedDocIds.current.set(documentToUpload, documentId);

        return { success: true, documentId };
      } else {
        return {
          success: false,
          message:
            uploadResult.message || t('validation:error.UnbekannterFehler'),
        };
      }
    },
    [getDocumentUploadUrl, t]
  );
};
