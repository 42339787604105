import { DateTime } from 'luxon';
import * as React from 'react';

export type MinuteContext = {
  minuteStamp: string;
};

function sampleMinuteStamp() {
  return DateTime.now().startOf('minute').toISO();
}

export const initTodayValues: MinuteContext = {
  minuteStamp: sampleMinuteStamp(),
};

export const todayContext = React.createContext<MinuteContext>(initTodayValues);

export const MinuteStampProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [minuteStamp, setMinuteStamp] = React.useState<string>(
    sampleMinuteStamp()
  );

  React.useEffect(() => {
    const timer = setInterval(() => {
      const newMinuteStamp = sampleMinuteStamp();
      setMinuteStamp((prevState) =>
        newMinuteStamp !== prevState ? newMinuteStamp : prevState
      );
    }, 1_000);
    return () => clearInterval(timer);
  }, [setMinuteStamp]);

  const context = React.useMemo(
    () => ({
      minuteStamp,
    }),
    [minuteStamp]
  );

  return <todayContext.Provider value={context} children={children} />;
};

export const MinuteStampMockProvider: React.FC<{
  minuteStamp: string;
  children?: React.ReactNode;
}> = ({ minuteStamp, children }) => {
  return (
    <todayContext.Provider
      value={{
        minuteStamp,
      }}
    >
      {children}
    </todayContext.Provider>
  );
};

export function useMinuteStamp() {
  return React.useContext(todayContext);
}
