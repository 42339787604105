import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  HStack,
  Heading,
  Icon,
  IconButton,
  List,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import {
  FinancesInvoiceFragment,
  useGetInvoicesListQuery,
  useGetPayoutWithdrawalsSectionQuery,
} from '../../../../generated/graphql';
import { useToday } from '../../../../provider/TodayProvider';
import { InvoiceListItem } from '../../components/Payout/InvoicePicker/components/InvoiceListItem/InvoiceListItem';
import { FinanceYourWithdrawalsEmptyYear } from '../../components/Payout/InvoicePicker/components/YourWithdrawalsEmptyState/FinanceYourWithdrawalsEmptyState';
import { UpcomingPayoutDatesModalButton } from '../../components/UpcomingPayoutDatesModalButton/UpcomingPayoutDatesModalButton';
import { prepareYearBuckets } from '../../utils/prepareYearBuckets';

export const PayoutWithdrawalsSection: React.FC = () => {
  const { t } = useTranslation(['withdrawl']);

  const { todayDayStamp } = useToday();
  const currentYear = DateTime.fromISO(todayDayStamp).year;
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const { data, loading } = useGetPayoutWithdrawalsSectionQuery({
    fetchPolicy: 'no-cache',
  });

  const mappedData = React.useMemo(() => {
    const todayDate = DateTime.fromISO(todayDayStamp);

    const accountSignUpYear = DateTime.fromISO(
      data?.account.signupDate ?? todayDayStamp
    );

    const maxPayoutDate = DateTime.fromISO(
      data?.finances.invoices.maxDate ?? todayDayStamp
    );
    const minPayoutDate = DateTime.fromISO(
      data?.finances.invoices.minDate ?? todayDayStamp
    );

    const yearValues = prepareYearBuckets(
      todayDate,
      accountSignUpYear.toJSDate(),
      minPayoutDate.toJSDate(),
      maxPayoutDate.toJSDate()
    );

    const newestYear = yearValues.at(-1);
    const oldestYear = yearValues.at(0);

    return {
      newestYear,
      oldestYear,
      yearValues,
      accountSignUpYear,
    };
  }, [data, todayDayStamp]);

  const selectNextYear = () => {
    const currentIndex = mappedData.yearValues.indexOf(selectedYear);
    const nextIndex = currentIndex + 1;
    const isValidIndex =
      nextIndex < mappedData.yearValues.length && nextIndex >= 0;

    if (isValidIndex) {
      setSelectedYear(mappedData.yearValues[nextIndex]);
    }
  };
  const selectPreviousYear = () => {
    const currentIndex = mappedData.yearValues.indexOf(selectedYear);
    const nextIndex = currentIndex - 1;
    const isValidIndex =
      nextIndex < mappedData.yearValues.length && nextIndex >= 0;

    if (isValidIndex) {
      setSelectedYear(mappedData.yearValues[nextIndex]);
    }
  };

  const isInvoiceDataLoading = loading;

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={icons.ReceiptLong} />
            <SectionTitle children={t('withdrawl:heading.DeineAuszahlungen')} />
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody pos={'relative'} minH={'400px'}>
        <SectionCenterContainer>
          {isInvoiceDataLoading ? (
            <AbsoluteFullCenterLoadingSpinner />
          ) : (
            <Box minH={'32rem'}>
              <Box mb={4}>
                <VStack alignItems={'stretch'}>
                  <HStack>
                    <IconButton
                      aria-label={'Previous Year'}
                      icon={<Icon as={icons.ChevronLeft} boxSize="icon.md" />}
                      onClick={selectPreviousYear}
                      isDisabled={selectedYear === mappedData.oldestYear}
                    />
                    <Heading textAlign={'center'} flexGrow={1} size={'xl'}>
                      {selectedYear}
                    </Heading>
                    <IconButton
                      aria-label={'Next Year'}
                      icon={<Icon as={icons.ChevronRight} boxSize="icon.md" />}
                      onClick={selectNextYear}
                      isDisabled={selectedYear === mappedData.newestYear}
                    />
                  </HStack>
                </VStack>
              </Box>
              <InvoiceList year={selectedYear} currentYear={currentYear} />
            </Box>
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

const InvoiceList: React.FC<{
  year: number;
  currentYear: number;
}> = ({ year, currentYear }) => {
  const { data, loading } = useGetInvoicesListQuery({
    variables: {
      year,
    },
    fetchPolicy: 'no-cache',
  });

  const { t } = useTranslation(['withdrawl']);

  const mappedData = React.useMemo(() => {
    const invoicesList = (data?.finances.invoices.invoices ?? []).filter(
      Boolean
    ) as FinancesInvoiceFragment[];

    const isEmpty = invoicesList.length <= 0;

    return {
      invoicesList,
      isEmpty,
    };
  }, [data]);

  if (loading) {
    return (
      <Box position={'relative'} height={'250px'}>
        <AbsoluteFullCenterLoadingSpinner />
      </Box>
    );
  }
  if (mappedData.isEmpty) {
    return (
      <VStack gap={2}>
        <FinanceYourWithdrawalsEmptyYear />
        {year === currentYear ? <UpcomingPayoutDatesModalButton /> : null}
      </VStack>
    );
  }

  return (
    <VStack as={List} divider={<Divider />} alignItems={'stretch'} spacing={1}>
      <HStack justifyContent={'space-between'}>
        <Text as={Heading} fontSize={'18px'} lineHeight={'40px'}>
          {t('withdrawl:text.Historie')}
        </Text>
        {year === currentYear ? <UpcomingPayoutDatesModalButton /> : null}
      </HStack>

      {mappedData.invoicesList.map((invoice, index) => {
        return <InvoiceListItem invoice={invoice} key={`invoice-${index}`} />;
      })}
    </VStack>
  );
};
