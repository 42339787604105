import React from 'react';

import { PostStatusEnum } from '../../../generated/feed';
import { FeedTranscodingProgress } from '../components/post/component/FeedTranscodingProgress/FeedTranscodingProgress';
import {
  FeedPostEdited,
  FeedTusUploadProgress,
  FeedUploadProgress,
} from '../components/post/component/FeedUploadProgress/FeedUploadProgress';

export const useFeedPostUploadProgress = ({
  status,
  postId,
  umaId,
  isTus = false,
  isEditing = false,
}: {
  postId: string;
  umaId?: string | null | undefined;
  status: PostStatusEnum;
  isTus?: boolean;
  isEditing?: boolean;
}) => {
  return React.useMemo(() => {
    switch (status) {
      case PostStatusEnum.Uploading:
        return isTus ? (
          <FeedTusUploadProgress umaId={umaId} />
        ) : (
          <FeedUploadProgress postId={postId} />
        );

      case PostStatusEnum.Transcoding:
        return <FeedTranscodingProgress />;

      case PostStatusEnum.Ok:
        if (isEditing) {
          return <FeedPostEdited />;
        }
        return undefined;

      default:
        return undefined;
    }
  }, [status, isTus, umaId, postId, isEditing]);
};
