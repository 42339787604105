import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoReleasedStatusEnum } from '../../../../generated/graphql';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../../Layout/ResponsiveModal';

export type ConfirmPlannedVideoStatusChangeDialogProps = {
  statusToChangeTo: VideoReleasedStatusEnum;
  confirmChange: () => void;
  discardChange: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'leastDestructiveRef'>;

export const ConfirmPlannedVideoStatusChangeDialog: React.FC<
  ConfirmPlannedVideoStatusChangeDialogProps
> = ({ statusToChangeTo, confirmChange, discardChange, onClose, ...rest }) => {
  const { t } = useTranslation(['videoEditModal']);
  const leastDestructiveRef = useRef(null);
  return (
    <ResponsiveModal
      onClose={onClose}
      initialFocusRef={leastDestructiveRef}
      {...rest}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <Heading
            as={'h2'}
            size={'lg'}
            lineHeight={'8'}
            children={
              statusToChangeTo === VideoReleasedStatusEnum.Online
                ? t('videoEditModal:heading.VideoVeroffentlichen')
                : t('videoEditModal:heading.VideoOfflineSetzen')
            }
          />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox py={0}>
          <Text>
            {statusToChangeTo === VideoReleasedStatusEnum.Online
              ? t(
                  'videoEditModal:text.MochtestDuDiesesVideoWirklichVeroffentlichenKundenKonnenEsDannDirektXX'
                )
              : t(
                  'videoEditModal:text.MochtestDuDiesesVideoWirklichOfflineSetzen'
                )}
          </Text>
        </ResponsiveModalBodyBox>

        <ResponsiveModalStickyFooterBox>
          <VStack minH={'5.5rem'} spacing={'2'}>
            <Button
              width={'full'}
              children={
                statusToChangeTo === VideoReleasedStatusEnum.Online
                  ? t('videoEditModal:button.Fortfahren')
                  : t('videoEditModal:button.OfflineSetzen')
              }
              onClick={confirmChange}
            />
            <Button
              width={'full'}
              children={t('videoEditModal:button.Verwerfen')}
              onClick={discardChange}
              ref={leastDestructiveRef}
            />
          </VStack>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
